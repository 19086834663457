/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearCoursesRequest } from '@/store/courses/actions';
import { getMaterialStatistics } from '@/store/statistics/actions';

import { selectCourses } from '@/store/courses/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Skeleton, Table } from 'antd';

import { filtersCoursesList } from './helpers';
import { ColumnsType, useColumnsValues } from './useColumnsValues';

import { isCorporateCourseWithoutApprove } from '@shared/utils/topic-utils';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

const CoursesTopicMemberstable = ({
  courses,
  isLoading,
  withEvents,
  topicId,
  changeSelect,
  changeSelectAll,
  currentStatus,
  changeCoursesFilter,
  changePage,
  checkTopicTypeId,
}) => {
  const [coursesTree, setCoursesTree] = useState([]);
  const [columnsValue, setColumnsValue] = useColumnsValues();
  const [onceLoad, changeOnceLoad] = useState(true);

  const { t } = useTranslation('topicDetailsDialog');
  const dispatch = useDispatch();

  const filterList = useSelector(selectStudents);
  const courseSelector = useSelector(selectCourses);

  const PAGE_SIZE = 19;

  const onChangeTable = (pagination, filters) => {
    Object.keys(filters).forEach(name => {
      changeCoursesFilter({ name: name, value: filters[name]?.[0] });
    });
    changePage(pagination?.current || 0);
  };

  useEffect(() => {
    return () => {
      //courses = [] --- костыль - Что бы исправить баг с отображением старых данных в таблице до загрузки новых
      dispatch(clearCoursesRequest());
    };
  }, []);

  useEffect(() => {
    if (courses) {
      // TODO: рассмотреть возвожность отрефакторить род. к-нт, чтобы рендерить этот к-нт 1 раз
      const mngrs = filtersCoursesList(filterList);
      const tableType = withEvents ? ColumnsType.WITH_EVENT : ColumnsType.COURSES;

      setColumnsValue(
        tableType,
        mngrs,
        currentStatus,
        filterList?.fullnameManager,
        filterList.isLoadingFullnameManager,
        checkTopicTypeId
      );

      if (Array.isArray(courses)) {
        const processedCourses = courses.map(item => {
          if (tableType === ColumnsType.WITH_EVENT && item?.costCourseEvent >= 0) {
            return {
              ...item,
              cost: item?.costCourseEvent || 0,
            };
          }

          if (item.plan.status === 'WAITING' && item.plan.topic?.cost >= 0) {
            return {
              ...item,
              cost: item.plan?.topic?.cost,
            };
          }

          return item;
        });

        const notDeletedFinished = processedCourses.filter(
          item => item.plan.status !== 'FINISHED' || item.plan.user.status !== 'DELETED'
        );

        setCoursesTree(notDeletedFinished);

        if (topicId && onceLoad) {
          changeOnceLoad(false);
          dispatch(getMaterialStatistics({ topicId }));
        }
      }
    }
  }, [courses, filterList?.fullnameManager, filterList.isLoadingFullnameManager]);

  const rowSelection = {
    onSelect: (record, selected) => {
      const event = record && {
        value: selected,
      };

      changeSelect({
        ...event,
        status: record.plan.status,
        courseId: record.id,
        plan: record.plan,
        planId: record.plan.id,
        id: record.plan.user.id,
        name: `${record.plan.user.lastName} ${record.plan.user.firstName}`,
        info: record?.info,
      });
    },
    onSelectAll: selectedRowKeys => {
      const event = { value: selectedRowKeys };
      changeSelectAll(event);
    },
    getCheckboxProps: record => ({
      // disabled: record.plan.status === 'FINISHED', // Column configuration not to be checked
      name: record.plan.status,
    }),
  };

  const pagination =
    courseSelector?.coursesTopicTotal > PAGE_SIZE
      ? {
          current: courseSelector.coursesTopicPageNumber + 1,
          total: courseSelector.coursesTopicTotal,
          pageSize: courseSelector.coursesTopicPageSize,
          size: 'medium',
          showSizeChanger: false,
          responsive: true,
        }
      : false;

  if (courses && columnsValue.length) {
    return (
      <Table
        className={css['ant-table']}
        locale={{
          emptyText: t('noNewRequests'),
          filterConfirm: t('Ok'),
          filterReset: t('resetSearch'),
        }}
        onChange={onChangeTable}
        rowKey={record => record.id.toString()}
        columns={columnsValue}
        rowSelection={rowSelection}
        rowClassName={record => {
          const exiredStyle = isCorporateCourseWithoutApprove(record?.plan?.topic?.type.id)
            ? record.isExpiredDate
            : record.plan.isExpiredDate;
          return exiredStyle && css['ant-table--expiredDate'];
        }}
        dataSource={coursesTree}
        pagination={pagination}
        loading={isLoading}
        scroll={{ x: true }}
      />
    );
  }

  return <Skeleton paragraph={{ rows: 4 }} />;
};

CoursesTopicMemberstable.propTypes = {
  courses: PropTypes.array,
  isLoading: PropTypes.bool,
  withEvents: PropTypes.bool,
  topicId: PropTypes.string,
  changeSelect: PropTypes.func,
  changeSelectAll: PropTypes.func,
  currentStatus: PropTypes.any,
  changeCoursesFilter: PropTypes.func,
  changePage: PropTypes.func,
};

export default CoursesTopicMemberstable;
