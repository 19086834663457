import {
  CHANGE_TEMPLATE_BY_ID_FAIL,
  CHANGE_TEMPLATE_BY_ID_START,
  CHANGE_TEMPLATE_BY_ID_SUCCESS,
  CREATE_TEMPLATE_BY_ID_FAIL,
  CREATE_TEMPLATE_BY_ID_START,
  CREATE_TEMPLATE_BY_ID_SUCCESS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_START,
  GET_CHANNELS_SUCCESS,
  GET_MESSAGE_GROUP_FAIL,
  GET_MESSAGE_GROUP_START,
  GET_MESSAGE_GROUP_SUCCESS,
  GET_TEMPLATE_BY_ID_FAIL,
  GET_TEMPLATE_BY_ID_START,
  GET_TEMPLATE_BY_ID_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
  GET_THEMES_FAIL,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
} from './constants';

const initialState = {
  // Loadings
  loadingThemes: false,
  loadingTemplates: false,
  loadingChannels: false,
  loadingTemplate: false,
  loadingMessageGroup: false,

  // Data
  channels: [],
  templates: [],
  themes: [],
  messageGroup: [],
  template: {},
  pagination: {
    size: 0,
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  },
};

/* eslint-disable */
export default (state = initialState, { type, response, action }) => {
  switch (type) {
    case CHANGE_TEMPLATE_BY_ID_FAIL:
    case CREATE_TEMPLATE_BY_ID_FAIL:
    case GET_TEMPLATE_BY_ID_FAIL:
      return {
        ...state,
        loadingTemplate: false,
      };

    case CHANGE_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingTemplate: false,
        templates: state.templates.map(template => {
          if (template?.messageTemplateId === response?.messageTemplateId) {
            const correctChannels = response.channels.filter(channel => channel.active);

            return {
              ...template,
              channels: correctChannels,
            };
          }

          return template;
        }),
      };

    case CREATE_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingTemplate: false,
        templates: state.templates.map(template => {
          if (template?.messageTemplateId === action?.id) {
            const correctChannels = response.channels.filter(channel => channel.active);

            return {
              ...template,
              channels: correctChannels,
              messageTemplateId: response?.messageTemplateId,
            };
          }

          return template;
        }),
      };

    case GET_THEMES_START:
      return {
        ...state,
        loadingThemes: true,
      };

    case GET_MESSAGE_GROUP_START:
      return {
        ...state,
        loadingMessageGroup: true,
      };

    case CHANGE_TEMPLATE_BY_ID_START:
    case CREATE_TEMPLATE_BY_ID_START:
    case GET_TEMPLATES_START:
    case GET_TEMPLATE_BY_ID_START:
      return {
        ...state,
        loadingTemplate: true,
      };

    case GET_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingTemplate: false,
        template: response,
      };

    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: response?.content,
        loadingTemplates: false,
        pagination: {
          size: response?.size,
          totalPages: response?.totalPages,
          totalElements: response?.totalElements,
          currentPage: response?.number,
        },
      };

    case GET_THEMES_SUCCESS:
      return {
        ...state,
        themes: response,
        loadingThemes: false,
      };

    case GET_THEMES_FAIL:
      return {
        ...state,
        loadingTemplates: false,
      };

    case GET_MESSAGE_GROUP_SUCCESS:
      // временное решение, как сделают бэк вернуть как было
      const filter360 = response?.length > 0 && response.filter(i => i.value !== 'SCORE_360');

      return {
        ...state,
        messageGroup: filter360, //response,
        loadingMessageGroup: false,
      };

    case GET_MESSAGE_GROUP_FAIL:
      return {
        ...state,
        loadingMessageGroup: false,
      };

    case GET_TEMPLATES_FAIL:
      return {
        ...state,
        loadingThemes: false,
      };

    case GET_CHANNELS_START:
      return {
        ...state,
        loadingChannels: true,
      };

    case GET_CHANNELS_SUCCESS:
      return {
        ...state,
        loadingChannels: false,
        channels: response,
      };

    case GET_CHANNELS_FAIL:
      return {
        ...state,
        loadingChannels: false,
      };

    default:
      return {
        ...state,
      };
  }
};
