/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { DATE_FORMAT } from '../../../constants';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export const ProjectDate = props => {
  const [dateString, setDateString] = useState(null);

  useEffect(() => {
    if (props.dateString && props.dateString !== null && props.dateString.length > 0) {
      if (dayjs(props.dateString, DATE_FORMAT, false).isValid()) {
        setDateString(dayjs(props.dateString));
      }
    }
  }, [props.dateString]);

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return <span>{dateString === null ? '' : dateString.format(DATE_FORMAT).toString()}</span>;
  };

  return render();
};

ProjectDate.propTypes = {
  dateString: PropTypes.string,
};

export default ProjectDate;
