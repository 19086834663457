import React, { createContext, useEffect, useState } from 'react';

import { register } from '@shared/serviceWorkers/serviceWorkerRegistration';

export const ServiceWorkerContext = createContext();

export const ServiceWorkerProvider = ({ children }) => {
  const [offlineReady, setOfflineReady] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [registration, setRegistration] = useState(null);

  useEffect(() => {
    register({
      onUpdate: reg => {
        setNeedRefresh(true);
        setRegistration(reg);
      },
      onSuccess: reg => {
        setOfflineReady(true);
        setRegistration(reg);
      },
      onError: error => {
        console.error('SW registration error:', error);
      },
    });
  }, []);

  useEffect(() => {
    if (registration) {
      const interval = setInterval(() => {
        console.log('Checking for SW update');
        registration.update();
      }, 20 * 1000);

      return () => clearInterval(interval);
    }
  }, [registration]);

  return (
    <ServiceWorkerContext.Provider value={{ offlineReady, needRefresh }}>{children}</ServiceWorkerContext.Provider>
  );
};
