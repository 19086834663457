/* eslint-disable */
import React, { Component } from 'react';

import { Col, notification, Row, Spin } from 'antd';
import MaterialCard from '@/components/MaterialCard';

import _get from 'lodash/get';
import PropTypes from 'prop-types';

export class RecommendationList extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    deleteUserTopic: PropTypes.func,
    getFile: PropTypes.func,
    domainCompany: PropTypes.object,
    corpCourseBackground: PropTypes.string,
    skills: PropTypes.array,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      isFavs: [],
      isInPlan: [],
      isLoading: true,
      noticed: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { currentUser } = this.props;
    const { isFavs, isInPlan } = this.state;

    let skills = this.skillsToArrayIds();

    skills = JSON.stringify(skills);
    this.setState({ isLoading: true });
  }

  /**
   *
   * @returns {Array}
   */
  skillsToArrayIds = () => {
    const { skills } = this.props;
    let skillsIds = [];
    if (Array.isArray(skills) && skills.length) {
      skills.map(skill => {
        skillsIds.push(skill.key);
      });
    }

    return skillsIds;
  };

  onStudyPlanClick = () => {};

  /**
   *
   * @param topic
   * @returns {*}
   */
  drawTopic = topic => {
    const { currentUser, deleteUserTopic, externalLogin, createUserTopic, getFile, corpCourseBackground } = this.props;

    const { isFavs, isInPlan } = this.state;

    const openDate = _get(this.props, 'openDate.openDate');
    const topicId = topic.id;

    return (
      <div style={{ margin: '10px' }}>
        <MaterialCard
          topic={topic}
          userTopicId={topicId}
          onDeleteClick={deleteUserTopic}
          currentUser={currentUser}
          onStudyPlanClick={this.onStudyPlanClick}
          domainCompany={currentUser.domainCompany}
          roles={[]}
        />
      </div>
    );
  };

  showNotice = () => {
    const { t } = this.props;

    notification.success({
      placement: 'topRight',
      duration: 4.5,
      message: t('note'),
      description: t('recommendationAlert'),
    });

    clearInterval(this.notice);
  };

  createNotice = () => {
    if (typeof this.notice === 'undefined') {
      this.notice = window.setInterval(this.showNotice, 1000);
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const { topics, isLoading, noticed } = this.state;
    const { t } = this.props;
    return (
      <>
        <Row>
          {isLoading && (
            <Col
              style={{
                textAlign: 'center',
                minHeight: '15rem',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin size='large' tip={t('loading')} />
            </Col>
          )}
          {Array.isArray(topics) &&
            topics.map(topic => {
              return <Col span={6}>{this.drawTopic(topic)}</Col>;
            })}
        </Row>

        {this.createNotice()}
      </>
    );
  }
}

export default RecommendationList;
