import {
  GET_CHECKLIST_POKA_FAIL,
  GET_CHECKLIST_POKA_START,
  GET_CHECKLIST_POKA_SUCCESS,
  GET_SHORT_POKA_FAIL,
  GET_SHORT_POKA_START,
  GET_SHORT_POKA_SUCCESS,
  GET_STATE_POKA_FAIL,
  GET_STATE_POKA_START,
  GET_STATE_POKA_SUCCESS,
  PUT_CHECKLIST_POKA_FAIL,
  PUT_CHECKLIST_POKA_START,
  PUT_CHECKLIST_POKA_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Получение короткой версии Пока топик
export const getShortPokaTopic = id => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/topic/short/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SHORT_POKA_START, GET_SHORT_POKA_SUCCESS, GET_SHORT_POKA_FAIL],
});

// Получение чеклистов поки
export const getChecklistPoka = studyPlan => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/plan/checklist/${studyPlan}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CHECKLIST_POKA_START, GET_CHECKLIST_POKA_SUCCESS, GET_CHECKLIST_POKA_FAIL],
});

// Получение состояния Поки топика
export const getStatePoka = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/state${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATE_POKA_START, GET_STATE_POKA_SUCCESS, GET_STATE_POKA_FAIL],
});

// Обновление чеклистов поки
export const putChecklistPoka = (blockId, planId, body, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/${blockId}/checklist/validation/${planId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_CHECKLIST_POKA_START, PUT_CHECKLIST_POKA_SUCCESS, PUT_CHECKLIST_POKA_FAIL],
  onSuccess,
});
