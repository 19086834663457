import {
  GET_EXECUTIVE_TOPIC_TASK_FAIL,
  GET_EXECUTIVE_TOPIC_TASK_START,
  GET_EXECUTIVE_TOPIC_TASK_SUCCESS,
  GET_EXPORT_EXECUTIVE_TOPIC_FAIL,
  GET_EXPORT_EXECUTIVE_TOPIC_START,
  GET_EXPORT_EXECUTIVE_TOPIC_SUCCESS,
  GET_TOPIC_EXECUTIVE_INFO_FAIL,
  GET_TOPIC_EXECUTIVE_INFO_START,
  GET_TOPIC_EXECUTIVE_INFO_SUCCESS,
  POST_EXECUTIVE_TOPIC_APPROVE_FAIL,
  POST_EXECUTIVE_TOPIC_APPROVE_START,
  POST_EXECUTIVE_TOPIC_APPROVE_SUCCESS,
  POST_EXECUTIVE_TOPIC_COMMENT_FAIL,
  POST_EXECUTIVE_TOPIC_COMMENT_START,
  POST_EXECUTIVE_TOPIC_COMMENT_SUCCESS,
  POST_EXECUTIVE_TOPIC_FAIL,
  POST_EXECUTIVE_TOPIC_START,
  POST_EXECUTIVE_TOPIC_SUCCESS,
  POST_UPLOAD_EXECUTIVE_REVIEW_FILE_FAIL,
  POST_UPLOAD_EXECUTIVE_REVIEW_FILE_START,
  POST_UPLOAD_EXECUTIVE_REVIEW_FILE_SUCCESS,
  PUT_EXECUTIVE_TOPIC_INFO_FAIL,
  PUT_EXECUTIVE_TOPIC_INFO_START,
  PUT_EXECUTIVE_TOPIC_INFO_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const storeExecutiveTopicInfo = (
  fileUuid,
  topicId,
  results,
  duration,
  activity,
  indicators,
  needfile,
  onSuccess,
  onError
) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/executive`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    activityInfo: activity,
    fileUuids: fileUuid,
    indicatorInfo: indicators,
    duration: duration,
    requiredFile: needfile,
    resultInfo: results,
    topicId: topicId,
  },
  types: [POST_EXECUTIVE_TOPIC_START, POST_EXECUTIVE_TOPIC_SUCCESS, POST_EXECUTIVE_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const approveExecutiveTask = (planId, userId, comment, rate, lvl, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/executive/approve`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    studyPlanId: planId,
    userId: userId,
    text: comment,
    rating: rate,
    endorsementLevel: lvl,
  },
  types: [POST_EXECUTIVE_TOPIC_APPROVE_START, POST_EXECUTIVE_TOPIC_APPROVE_SUCCESS, POST_EXECUTIVE_TOPIC_APPROVE_FAIL],
  onSuccess,
  onError,
});

export const uploadExecutiveReviewFile = (formData, studyPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/executive/review/file${getQueryString({ studyPlanId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    'Content-Type': 'multipart/form-data',
  },
  body: formData,
  requestType: 'formData',
  types: [
    POST_UPLOAD_EXECUTIVE_REVIEW_FILE_START,
    POST_UPLOAD_EXECUTIVE_REVIEW_FILE_SUCCESS,
    POST_UPLOAD_EXECUTIVE_REVIEW_FILE_FAIL,
  ],
  onSuccess,
  onError,
});

export const commentExecutiveTask = (userId, studyPlanId, comment, lvl, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/executive/comment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    studyPlanId: studyPlanId,
    text: comment,
    userId: userId,
    endorsementLevel: lvl,
  },
  types: [POST_EXECUTIVE_TOPIC_COMMENT_START, POST_EXECUTIVE_TOPIC_COMMENT_SUCCESS, POST_EXECUTIVE_TOPIC_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const getExecutiveTopicInfo = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/executive/${getQueryString({
    topicId: topicId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_EXECUTIVE_INFO_START, GET_TOPIC_EXECUTIVE_INFO_SUCCESS, GET_TOPIC_EXECUTIVE_INFO_FAIL],
  onSuccess,
  onError,
});

export const getExecutiveTopicTask = (studyPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/executive/${studyPlanId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_EXECUTIVE_TOPIC_TASK_START, GET_EXECUTIVE_TOPIC_TASK_SUCCESS, GET_EXECUTIVE_TOPIC_TASK_FAIL],
  onSuccess,
  onError,
});

export const updateExecutiveTopicInfo = (data, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/executive`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  types: [PUT_EXECUTIVE_TOPIC_INFO_START, PUT_EXECUTIVE_TOPIC_INFO_SUCCESS, PUT_EXECUTIVE_TOPIC_INFO_FAIL],
  onSuccess,
  onError,
});

export const exportExecutiveTopic = (filename, topicIds, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/executive/export${getQueryString({
    filename,
    topicIds,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'blob',
  types: [GET_EXPORT_EXECUTIVE_TOPIC_START, GET_EXPORT_EXECUTIVE_TOPIC_SUCCESS, GET_EXPORT_EXECUTIVE_TOPIC_FAIL],
  onSuccess,
  onError,
});
