/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { D360GetProject } from '@/store/d360/d360_project/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Card, Spin, Tabs, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FiPackage, FiSlack, FiTrendingUp, FiUsers } from 'react-icons/fi';

import { Content } from '../../../../components/Layout';
import { PROJECT_STATUS_DRAFT } from '../../constants';
import Assignment from '../Assignment/Assignment';
import Criteria from '../Criteria/Criteria';
import Info from '../Info/Info';
import { Reports } from '../Reports/index';
import Scales from '../Scales/Scales';
import State from '../State/State';

import css from '../../css/index.module.scss';

import { PROJECT_360_DELETE_ACTIVE_USER, PROJECT_360_UPDATE } from '@/constants/permissions';

const TAB_PROJECT = 'TAB_PROJECT';
const TAB_SCALES = 'TAB_SCALES';
const TAB_CRITERIA = 'TAB_CRITERIA';
const TAB_ASSIGNS = 'TAB_ASSIGNS';
const TAB_STATUS = 'TAB_STATUS';
const TAB_REPORT = 'TAB_IREPORT';

export const Project = () => {
  const { t } = useTranslation('D360_Project');
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { project, loading } = useSelector(state => state.d360Project);
  const { currentUser } = useSelector(selectUsers);

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    // Загружаем проект
    if (params?.id) {
      dispatch(D360GetProject(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    if (typeof project !== 'undefined') {
      if (project?.d360_status_id === PROJECT_STATUS_DRAFT) {
        setEditable(currentUser?.authorities.includes(PROJECT_360_UPDATE));
      } else {
        setEditable(false);
      }
    }
  }, [project]);

  const tabs = [
    {
      key: TAB_PROJECT,
      label: t('project'),
      icon: <FiPackage />,
      children: <Info project={project} editable={editable} />,
    },
    {
      key: TAB_SCALES,
      label: t('scales'),
      icon: <FiTrendingUp />,
      children: <Scales project={project} editable={editable} />,
    },
    {
      key: TAB_CRITERIA,
      label: t('criteria'),
      icon: <FiSlack />,
      children: <Criteria project={project} editable={editable} />,
    },
    {
      key: TAB_ASSIGNS,
      label: t('assigns'),
      icon: <FiUsers />,
      children: (
        <Assignment
          project={project}
          editable={editable || currentUser?.authorities.includes(PROJECT_360_DELETE_ACTIVE_USER)}
        />
      ),
    },
    {
      key: TAB_STATUS,
      label: t('status'),
      icon: <FiUsers />,
      children: <State />,
    },
    {
      key: TAB_REPORT,
      label: t('ireports'),
      icon: <FiUsers />,
      children: <Reports />,
    },
  ];

  const renderTitle = text => {
    if (text?.length > 159) {
      return (
        <Tooltip title={text}>
          <div className={css.TabsHeaderTitle}>{text}</div>
        </Tooltip>
      );
    } else {
      return <div className={css.TabsHeaderTitle}>{text}</div>;
    }
  };

  const render = () => {
    return (
      <Content>
        <Spin spinning={loading} wrapperClassName={css.SpinTabs}>
          <Card className={css.Tabs}>
            <div className={css.TabsHeader}>
              {renderTitle(project?.name)}
              <div className={css.TabsClose}></div>
              <Button icon={<CloseOutlined />} onClick={() => navigate('/d360')} />
            </div>
            {typeof project !== 'undefined' ? <Tabs items={tabs} /> : null}
          </Card>
        </Spin>
      </Content>
    );
  };

  return render();
};

export default Project;
