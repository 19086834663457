import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import {
  ADD_POKA_TYPICAL_POSITION_FAIL,
  ADD_POKA_TYPICAL_POSITION_START,
  ADD_POKA_TYPICAL_POSITION_SUCCESS,
  CLEAR_ERROR_TEXT_POSITION,
  DELETE_POKA_TYPICAL_POSITION_FAIL,
  DELETE_POKA_TYPICAL_POSITION_START,
  DELETE_POKA_TYPICAL_POSITION_SUCCESS,
  EDIT_POKA_TYPICAL_POSITION_FAIL,
  EDIT_POKA_TYPICAL_POSITION_START,
  EDIT_POKA_TYPICAL_POSITION_SUCCESS,
  GET_MISMATCH_FAIL,
  GET_MISMATCH_START,
  GET_MISMATCH_SUCCESS,
  GET_POKA_POSITION_TEMPLATE_START,
  GET_POSITION_TEMPLATE_FAIL,
  GET_POSITION_TEMPLATE_SUCCESS,
  GET_TYPICAL_POSITIONS_LIST_FAIL,
  GET_TYPICAL_POSITIONS_LIST_START,
  GET_TYPICAL_POSITIONS_LIST_SUCCESS,
  IMPORT_POKA_POSITION_FILE_FAIL,
  IMPORT_POKA_POSITION_FILE_START,
  IMPORT_POKA_POSITION_FILE_SUCCESS,
  LOAD_POKA_TYPICAL_POSITIONS_FAIL,
  LOAD_POKA_TYPICAL_POSITIONS_START,
  LOAD_POKA_TYPICAL_POSITIONS_SUCCESS,
  SAVE_POKA_TYPICAL_POSITION_FILTERS,
  SAVE_POKA_TYPICAL_POSITION_SORT,
} from '@/store/poka_typicalPositions/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersImport = {
  Accept: '*/*',
};

const defaultToken = '';

// Получение таблицы типовых должностей
export const loadTypicalPositions = (params, body = {}) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/position${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [LOAD_POKA_TYPICAL_POSITIONS_START, LOAD_POKA_TYPICAL_POSITIONS_SUCCESS, LOAD_POKA_TYPICAL_POSITIONS_FAIL],
});

// Добавление типовой должности
export const createTypicalPosition = (body, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/position/add`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [ADD_POKA_TYPICAL_POSITION_START, ADD_POKA_TYPICAL_POSITION_SUCCESS, ADD_POKA_TYPICAL_POSITION_FAIL],
  onSuccess,
});

// Редактирование типовой должности
export const editTypicalPosition = (id, body, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/position/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [EDIT_POKA_TYPICAL_POSITION_START, EDIT_POKA_TYPICAL_POSITION_SUCCESS, EDIT_POKA_TYPICAL_POSITION_FAIL],
  onSuccess,
});

// Удаление типовой должности
export const deleteTypicalPosition = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/poka/position/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_POKA_TYPICAL_POSITION_START, DELETE_POKA_TYPICAL_POSITION_SUCCESS, DELETE_POKA_TYPICAL_POSITION_FAIL],
  onSuccess,
});

// Получение списка типовых должностей с фильтром
export const getTypicalPositionsList = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/position${getQueryString({ ...params })}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPICAL_POSITIONS_LIST_START, GET_TYPICAL_POSITIONS_LIST_SUCCESS, GET_TYPICAL_POSITIONS_LIST_FAIL],
});

// Получение флага несоответствия
export const getMismatch = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/position/mismatch`,
  headers,
  types: [GET_MISMATCH_START, GET_MISMATCH_SUCCESS, GET_MISMATCH_FAIL],
});

// Сохранение фильтров типовой должности
export const saveTypicalPositionFilters = value => ({
  type: SAVE_POKA_TYPICAL_POSITION_FILTERS,
  value,
});

// Сохранение сортировки типовой должности
export const saveTypicalPositionSort = value => ({
  type: SAVE_POKA_TYPICAL_POSITION_SORT,
  value,
});

// получаем файл примера Типовых должностей
export const getPokaPositionTemplate = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/position/import/example`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_POKA_POSITION_TEMPLATE_START, GET_POSITION_TEMPLATE_SUCCESS, GET_POSITION_TEMPLATE_FAIL],
  onSuccess,
  onError,
});

export const importPokaPosition = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/position/import`,
  headers: {
    ...headersImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [IMPORT_POKA_POSITION_FILE_START, IMPORT_POKA_POSITION_FILE_SUCCESS, IMPORT_POKA_POSITION_FILE_FAIL],
});

export const clearErrorTextPokaPosition = () => ({
  type: CLEAR_ERROR_TEXT_POSITION,
});
