/* eslint-disable */
import { API_D360_URL } from '../service_api';
import {
  D360_CREATE_SCORE_FAIL,
  D360_CREATE_SCORE_START,
  D360_CREATE_SCORE_SUCCESS,
  D360_DELETE_SCORE_FAIL,
  D360_DELETE_SCORE_START,
  D360_DELETE_SCORE_SUCCESS,
  D360_GET_SCORE_FAIL,
  D360_GET_SCORE_START,
  D360_GET_SCORE_SUCCESS,
  D360_GET_SCORES_FAIL,
  D360_GET_SCORES_START,
  D360_GET_SCORES_SUCCESS,
  D360_UPDATE_SCORE_FAIL,
  D360_UPDATE_SCORE_START,
  D360_UPDATE_SCORE_SUCCESS,
} from './constants';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

// POST
export const D360CreateScore = (projectId, scaleId, indicator, value, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/score`,
    body: {
      indicator: indicator,
      value: value,
      d360_project_id: projectId,
      d360_scale_id: scaleId,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_SCORE_START, D360_CREATE_SCORE_SUCCESS, D360_CREATE_SCORE_FAIL],
    onSuccess,
    onError,
  };
};

// PUT
export const D360UpdateScore = (projectId, scaleId, scoreId, indicator, value, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/score`,
    body: {
      id: scoreId,
      indicator: indicator,
      value: value,
      d360_project_id: projectId,
      d360_scale_id: scaleId,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_UPDATE_SCORE_START, D360_UPDATE_SCORE_SUCCESS, D360_UPDATE_SCORE_FAIL],
    onSuccess,
    onError,
  };
};

// DELETE
export const D360DeleteScore = (projectId, scaleId, scoreId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/score${getQueryString({
      d360_project_id: projectId,
      d360_scale_id: scaleId,
      id: scoreId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DELETE_SCORE_START, D360_DELETE_SCORE_SUCCESS, D360_DELETE_SCORE_FAIL],
    onSuccess,
    onError,
  };
};

// GET
export const D360GetScores = (projectId, scaleId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/scores${getQueryString({
      d360_project_id: projectId,
      d360_scale_id: scaleId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_SCORES_START, D360_GET_SCORES_SUCCESS, D360_GET_SCORES_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

 import {
    D360CreateScore,
    D360UpdateScore,
    D360DeleteScore,
    D360GetScores,
   } from '@/store/d360_scores/actions';

 **************************************************************/
