export const BONUS_READ = 'BONUS_READ';
export const BONUS_UPDATE = 'BONUS_UPDATE';

export const BANNERS_READ = 'BANNERS_READ';
export const BANNERS_ACCESS = 'BANNERS_ACCESS';

export const COMPANY_USERS_COUNT_READ = 'COMPANY_USERS_COUNT_READ';

export const COMPILATION_CREATE = 'COMPILATION_CREATE';
export const COMPILATION_UPDATE = 'COMPILATION_UPDATE';
export const COMPILATION_DELETE = 'COMPILATION_DELETE';

export const THEME_READ = 'THEME_READ';
export const THEME_CREATE = 'THEME_CREATE';
export const THEME_UPDATE = 'THEME_UPDATE';
export const THEME_DELETE = 'THEME_DELETE';

export const TOPIC_EXPORT = 'TOPIC_EXPORT';
export const TOPIC_CREATE = 'TOPIC_CREATE';
export const TOPIC_UPDATE = 'TOPIC_UPDATE';
export const TOPIC_DELETE = 'TOPIC_DELETE';
export const TOPIC_MANAGE = 'TOPIC_MANAGE';

export const TOPIC_BLOCK_CREATE = 'TOPIC_BLOCK_CREATE';
export const TOPIC_BLOCK_UPDATE = 'TOPIC_BLOCK_UPDATE';
export const TOPIC_BLOCK_DELETE = 'TOPIC_BLOCK_DELETE';

export const TOPIC_BLOCK_EXECUTIVE_TASK_CREATE = 'TOPIC_BLOCK_EXECUTIVE_TASK_CREATE';
export const TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE = 'TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE';
export const TOPIC_BLOCK_EXECUTIVE_TASK_DELETE = 'TOPIC_BLOCK_EXECUTIVE_TASK_DELETE';

export const SETTINGS_ROLE_READ = 'SETTINGS_ROLE_READ';
export const SETTINGS_ROLE_UPDATE = 'SETTINGS_ROLE_UPDATE';

export const ROLE_READ = 'ROLE_READ';
export const ROLE_CREATE = 'ROLE_CREATE';
export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_DELETE = 'ROLE_DELETE';

export const WEBINAR_TOKEN_READ = 'WEBINAR_TOKEN_READ';
export const WEBINAR_TOKEN_UPDATE = 'WEBINAR_TOKEN_UPDATE';

export const TYPEFORM_TOKEN_READ = 'TYPEFORM_TOKEN_READ';
export const TYPEFORM_TOKEN_UPDATE = 'TYPEFORM_TOKEN_UPDATE';

export const BITRIX_WEBHOOK_READ = 'BITRIX_WEBHOOK_READ';
export const BITRIX_WEBHOOK_UPDATE = 'BITRIX_WEBHOOK_UPDATE';

export const QUARTZ_READ = 'QUARTZ_READ';
export const QUARTZ_TEST = 'QUARTZ_TEST';

export const DATALENS_READ = 'DATALENS_READ';

export const EXPORT_LOG_MATERIAL = 'EXPORT_LOG_MATERIAL';
export const EXPORT_MENTOR = 'EXPORT_MENTOR';
export const EXPORT_MENTEE = 'EXPORT_MENTEE';
export const EXPORT_LOG_SYSTEM = 'EXPORT_LOG_SYSTEM';
export const EXPORT_TOPIC_BLOCK_RESULTS = 'EXPORT_TOPIC_BLOCK_RESULTS';
export const EXPORT_TYPEFORM_BLOCK_RESULTS = 'EXPORT_TYPEFORM_BLOCK_RESULTS';
export const EXPORT_USERS_PLAN = 'EXPORT_USERS_PLAN';
export const EXPORT_USERS_TOPIC_VISIBILITY = 'EXPORT_USERS_TOPIC_VISIBILITY';
export const EXPORT_MASS_FILTER_USERS = 'EXPORT_MASS_FILTER_USERS';
export const EXPORT_TOPIC_EXECUTIVE_RESULTS = 'EXPORT_TOPIC_EXECUTIVE_RESULTS';
export const EXPORT_USERS_FROM_EVENT = 'EXPORT_USERS_FROM_EVENT';

export const USER_BLOCK = 'USER_BLOCK';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_EXPORT = 'USER_EXPORT';
export const USER_SIGN_UP_LOAD = 'USER_SIGN_UP_LOAD';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_INFO_READ = 'USER_INFO_READ';
export const USER_SIGN_IN_ANOTHER_USER = 'USER_SIGN_IN_ANOTHER_USER';

export const INCOME_CREATE = 'INCOME_CREATE';

export const TRAJECTORY_USERS_MANAGE = 'TRAJECTORY_USERS_MANAGE';

export const CONVENTUS_RESULTS_EXPORT = 'CONVENTUS_RESULTS_EXPORT';

export const MENTOR_READ_BY_ADMIN = 'MENTOR_READ_BY_ADMIN';
export const MENTOR_CREATE_BY_ADMIN = 'MENTOR_CREATE_BY_ADMIN';
export const MENTOR_UPDATE_BY_ADMIN = 'MENTOR_UPDATE_BY_ADMIN';
export const MENTOR_SETTINGS_BY_ADMIN = 'MENTOR_SETTINGS_BY_ADMIN';

export const MENTEE_READ_BY_ADMIN = 'MENTEE_READ_BY_ADMIN';
export const MENTEE_STATUS_UPDATE_BY_ADMIN = 'MENTEE_STATUS_UPDATE_BY_ADMIN';

export const MASS_FILTER_READ = 'MASS_FILTER_READ';
export const MASS_FILTER_CREATE = 'MASS_FILTER_CREATE';
export const MASS_FILTER_DELETE = 'MASS_FILTER_DELETE';
export const MASS_FILTER_INTERACT = 'MASS_FILTER_INTERACT';

export const CUSTOM_FIELD_CREATE = 'CUSTOM_FIELD_CREATE';
export const CUSTOM_FIELD_UPDATE = 'CUSTOM_FIELD_UPDATE';
export const CUSTOM_FIELD_DELETE = 'CUSTOM_FIELD_DELETE';

export const SKILL_READ = 'SKILL_READ';
export const SKILL_CREATE = 'SKILL_CREATE';
export const SKILL_UPDATE = 'SKILL_UPDATE';
export const SKILL_DELETE = 'SKILL_DELETE';

export const DEPARTMENT_INTERNAL_READ = 'DEPARTMENT_INTERNAL_READ';
export const DEPARTMENT_INTERNAL_CREATE = 'DEPARTMENT_INTERNAL_CREATE';
export const DEPARTMENT_INTERNAL_UPDATE = 'DEPARTMENT_INTERNAL_UPDATE';
export const DEPARTMENT_INTERNAL_DELETE = 'DEPARTMENT_INTERNAL_DELETE';

export const FEE_BASED_COURSE_EVENT_RESEND_MAIL = 'FEE_BASED_COURSE_EVENT_RESEND_MAIL';
export const FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN = 'FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN';
export const FEE_BASED_COURSE_REQUEST_EXPORT = 'FEE_BASED_COURSE_REQUEST_EXPORT';
export const FEE_BASED_COURSE_REQUEST_UPDATE = 'FEE_BASED_COURSE_REQUEST_UPDATE';

export const TOPIC_VISIBILITY_FILTER_READ = 'TOPIC_VISIBILITY_FILTER_READ';
export const TOPIC_VISIBILITY_FILTER_CREATE = 'TOPIC_VISIBILITY_FILTER_CREATE';
export const TOPIC_VISIBILITY_FILTER_DELETE = 'TOPIC_VISIBILITY_FILTER_DELETE';

export const TOPIC_EXECUTIVE_TASKS_UPDATE = 'TOPIC_EXECUTIVE_TASKS_UPDATE';
export const TOPIC_EXECUTIVE_TASKS_DELETE = 'TOPIC_EXECUTIVE_TASKS_DELETE';

export const FUNCTION_INTERNAL_READ = 'FUNCTION_INTERNAL_READ';
export const FUNCTION_INTERNAL_CREATE = 'FUNCTION_INTERNAL_CREATE';
export const FUNCTION_INTERNAL_UPDATE = 'FUNCTION_INTERNAL_UPDATE';
export const FUNCTION_INTERNAL_DELETE = 'FUNCTION_INTERNAL_DELETE';

export const TAG_READ = 'TAG_READ';
export const TAG_CREATE = 'TAG_CREATE';
export const TAG_UPDATE = 'TAG_UPDATE';
export const TAG_DELETE = 'TAG_DELETE';

export const CAREER_POSITION_READ = 'CAREER_POSITION_READ';
export const CAREER_POSITION_CREATE = 'CAREER_POSITION_CREATE';
export const CAREER_POSITION_UPDATE = 'CAREER_POSITION_UPDATE';
export const CAREER_POSITION_DELETE = 'CAREER_POSITION_DELETE';

export const TOPIC_EXECUTIVE_ANSWER_APPROVE = 'TOPIC_EXECUTIVE_ANSWER_APPROVE';
export const TOPIC_EXECUTIVE_ANSWER_COMMENT = 'TOPIC_EXECUTIVE_ANSWER_COMMENT';

export const COMPETENCE_READ = 'COMPETENCE_READ';
export const COMPETENCE_CREATE = 'COMPETENCE_CREATE';
export const COMPETENCE_UPDATE = 'COMPETENCE_UPDATE';
export const COMPETENCE_DELETE = 'COMPETENCE_DELETE';

export const TOPIC_CHAPTER_CREATE = 'TOPIC_CHAPTER_CREATE';
export const TOPIC_CHAPTER_UPDATE = 'TOPIC_CHAPTER_UPDATE';
export const TOPIC_CHAPTER_DELETE = 'TOPIC_CHAPTER_DELETE';

export const STUDY_PLAN_CREATE_BY_ADMIN = 'STUDY_PLAN_CREATE_BY_ADMIN';
export const STUDY_PLAN_UPDATE_BY_ADMIN = 'STUDY_PLAN_UPDATE_BY_ADMIN';
export const STUDY_PLAN_IMPORT = 'STUDY_PLAN_IMPORT';

export const TOPIC_EXECUTIVE_TASKS_CREATE = 'TOPIC_EXECUTIVE_TASKS_CREATE';

export const FILE_READ = 'FILE_READ';

export const AUTHOR_READ = 'AUTHOR_READ';
export const AUTHOR_CREATE = 'AUTHOR_CREATE';
export const AUTHOR_UPDATE = 'AUTHOR_UPDATE';
export const AUTHOR_DELETE = 'AUTHOR_DELETE';

export const COMPANY_READ = 'COMPANY_READ';

export const ROOT_CHANGE_PASSWORD = 'ROOT_CHANGE_PASSWORD';

export const ACCESS_TO_ADMIN_MATERIALS = 'ACCESS_TO_ADMIN_MATERIALS';
export const ACCESS_TO_ADMIN_APPROVE = 'ACCESS_TO_ADMIN_APPROVE';
export const ACCESS_TO_ADMIN_COLLECTIONS = 'ACCESS_TO_ADMIN_COLLECTIONS';
export const ACCESS_TO_ADMIN_USERS = 'ACCESS_TO_ADMIN_USERS';
export const ACCESS_TO_ADMIN_PROFILE = 'ACCESS_TO_ADMIN_PROFILE';
export const ACCESS_TO_ADMIN_MENTORING = 'ACCESS_TO_ADMIN_MENTORING';
export const ACCESS_TO_ADMIN_EVENT_CALENDAR = 'ACCESS_TO_ADMIN_EVENT_CALENDAR';
export const ACCESS_TO_ADMIN_MATERIALS_LOGGING = 'ACCESS_TO_ADMIN_MATERIALS_LOGGING';
export const ACCESS_TO_ADMIN_SYSTEM_LOGGING = 'ACCESS_TO_ADMIN_SYSTEM_LOGGING';
export const ACCESS_TO_ADMIN_SYSTEM_SETTINGS = 'ACCESS_TO_ADMIN_SYSTEM_SETTINGS';
export const ACCESS_TO_ADMIN_ACCESS_SETTINGS = 'ACCESS_TO_ADMIN_ACCESS_SETTINGS';
export const ACCESS_TO_ADMIN_DIRECTORY = 'ACCESS_TO_ADMIN_DIRECTORY';

export const ACCESS_TO_ADMIN_360 = 'ACCESS_TO_ADMIN_360';
export const PROJECT_360_CREATE = 'PROJECT_360_CREATE';
export const PROJECT_360_DELETE = 'PROJECT_360_DELETE';
export const PROJECT_360_UPDATE = 'PROJECT_360_UPDATE';
export const PROJECT_360_ACTIVATE = 'PROJECT_360_ACTIVATE';
export const PROJECT_360_DEACTIVATE = 'PROJECT_360_DEACTIVATE';
export const PROJECT_360_DELETE_ACTIVE_USER = 'PROJECT_360_DELETE_ACTIVE_USER';

export const CERTIFICATES_READ = 'CERTIFICATES_READ';
export const CERTIFICATES_UPDATE = 'CERTIFICATES_UPDATE';
export const ACCESS_TO_ADMIN_FROM_USERS = 'ACCESS_TO_ADMIN_FROM_USERS';

export const LDAP_CONFIGURE = 'LDAP_CONFIGURE';

export const ACCESS_TO_ADMIN_SHOP = 'ACCESS_TO_ADMIN_SHOP';
export const PRODUCT_ADMIN_CREATE = 'PRODUCT_ADMIN_CREATE';
export const PRODUCT_ADMIN_UPDATE = 'PRODUCT_ADMIN_UPDATE';
export const PRODUCT_ADMIN_UPDATE_STATUS = 'PRODUCT_ADMIN_UPDATE_STATUS';
export const PRODUCT_ADMIN_GET_ORDER_STATUS_LIST_BY_ID = 'PRODUCT_ADMIN_GET_ORDER_STATUS_LIST_BY_ID';

export const ACCESS_TO_TRACKS = 'ACCESS_TO_TRACKS';
export const ACCESS_TO_NEWS = 'ACCESS_TO_NEWS';
export const TRACK_CREATE = 'TRACK_CREATE';
export const TRACK_ADD = 'TRACK_ADD';
export const TRACK_UPDATE = 'TRACK_UPDATE';
export const TRACK_DELETE = 'TRACK_DELETE';
export const TRACK_READ = 'TRACK_READ';
export const TRACK_PLAN_EDITE = 'TRACK_PLAN_EDITE';

export const NOTIFICATION_READ = 'NOTIFICATION_READ';
export const NOTIFICATION_BODY_READ = 'NOTIFICATION_BODY_READ';
export const NOTIFICATION_UPDATE_TO_READ = 'NOTIFICATION_UPDATE_TO_READ';
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const ACCESS_TO_NOTIFICATION_ADMIN = 'ACCESS_TO_NOTIFICATION_ADMIN';
export const ACCESS_TO_COMPANY_MESSAGES_ADMIN = 'ACCESS_TO_COMPANY_MESSAGES_ADMIN';

export const NEWS_VISIBILITY_FILTER_CREATE = 'NEWS_VISIBILITY_FILTER_CREATE';
export const NEWS_VISIBILITY_FILTER_DELETE = 'NEWS_VISIBILITY_FILTER_DELETE';
export const NEWS_VISIBILITY_FILTER_READ = 'NEWS_VISIBILITY_FILTER_READ';
export const NEWS_ADMIN_UPDATE_STATUS = 'NEWS_ADMIN_UPDATE_STATUS';
export const NEWS_ADMIN_UPDATE = 'NEWS_ADMIN_UPDATE';
export const NEWS_CREATE = 'NEWS_CREATE';

export const QUESTION_BANK_CREATE = 'QUESTION_BANK_CREATE';
export const QUESTION_BANK_FILTER = 'QUESTION_BANK_FILTER';
export const QUESTION_BANK_UPDATE = 'QUESTION_BANK_UPDATE';
export const QUESTION_BANK_DELETE = 'QUESTION_BANK_DELETE';
export const QUESTION_BANK_IMPORT = 'QUESTION_BANK_IMPORT';

export const ACCESS_TO_QUESTION_BANK = 'ACCESS_TO_QUESTION_BANK';

export const FEE_BASED_COURSE_EVENT_CREATE = 'FEE_BASED_COURSE_EVENT_CREATE';
export const FEE_BASED_COURSE_EVENT_UPDATE = 'FEE_BASED_COURSE_EVENT_UPDATE';

export const TOPIC_PIN_INTERACT = 'TOPIC_PIN_INTERACT';

export const REWARD_ACCESS = 'REWARD_ACCESS';

export const USER_CREATE_USING_FORM = 'USER_CREATE_USING_FORM';

export const CAREER_PLAN_ACCESS = 'CAREER_PLAN_ACCESS';

export const ACCESS_TO_ADMIN_TOPIC_INSPECTION = 'ACCESS_TO_ADMIN_TOPIC_INSPECTION';

export const COMPETENCE_GROUP_READ = 'COMPETENCE_GROUP_READ';
export const COMPETENCE_GROUP_CREATE = 'COMPETENCE_GROUP_CREATE';
export const COMPETENCE_GROUP_UPDATE = 'COMPETENCE_GROUP_UPDATE';
export const COMPETENCE_GROUP_DELETE = 'COMPETENCE_GROUP_DELETE';
