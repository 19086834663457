import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getBlockStatisticsExport, getResultStatistic, getStatisticsStatus } from '@/store/lms/actions';
import { getStudyingStatistics } from '@/store/statistics/actions';

import { selectBlocks } from '@/store/lms/selectors';
import { selectAllStatistics } from '@/store/statistics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Row, Spin } from 'antd';
import { Card } from '@/components/Layout';
import { DownloadOutlined } from '@ant-design/icons';

import AllStatistics from '../AllStatistics/AllStatistics';
import SortTable from '../Materials/SortTable';
import { Blocks } from './Blocks/Blocks';
import ChapterPanel from './Blocks/ChapterPanel';
import ResultTable from './Blocks/Results/ResultsTable';
import ResultsFilter from './Blocks/Results/ResultsTable/ResultsFilter';

import { isExecutiveTask } from '@shared/utils/topic-utils';

import PropTypes from 'prop-types';

import css from './LMS.module.scss';

import { EXPORT_TOPIC_BLOCK_RESULTS, EXPORT_TOPIC_EXECUTIVE_RESULTS } from '@/constants/permissions';

export const LMS = ({ canEdit, getFile, changeTestStatus, subCompanies, topicTypeId }) => {
  const { t } = useTranslation('lms');
  const dispatch = useDispatch();
  const { id } = useParams();

  const DEFAULT_FILTER = {
    fullName: '',
    status: undefined,
    blockTypeId: undefined,
    inspectionMode: false,
    topicId: id,
    subCompanies: subCompanies?.length > 0 ? subCompanies : undefined,
    topicTypeId,
    startDate: undefined,
    endDate: undefined,
    sort: 'addedDate,desc',
  };

  const [sortOrder, setSortOrder] = useState('desc');
  const [filterTable, setFilterTable] = useState({ ...DEFAULT_FILTER });

  const { currentUser } = useSelector(selectUsers);
  const { isExporting } = useSelector(selectBlocks);
  const { isStudyingLoading, isStudyingError, studyingStatistics } = useSelector(selectAllStatistics);

  useEffect(() => {
    dispatch(getResultStatistic(filterTable));
    dispatch(getStatisticsStatus());
    dispatch(getStudyingStatistics(id));
  }, [dispatch]);

  const exportBlockStatistics = () => {
    const fileName = `study_results.csv`;

    dispatch(
      getBlockStatisticsExport(filterTable, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();

        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 2000);
      })
    );
  };

  const handleResetFilter = () => {
    setFilterTable({ ...DEFAULT_FILTER });
    dispatch(getResultStatistic({ ...DEFAULT_FILTER }));
  };

  const handleChangeSort = newHandleSort => {
    setFilterTable(prevFilter => {
      const prevSort = (prevFilter.sort || '').split(',')?.[0] || '';
      const newSort = (newHandleSort || '').split(',')?.[0] || '';

      setSortOrder(prevOrder => {
        let sortOrder = prevOrder;
        if (prevSort === newSort) {
          sortOrder = prevOrder === 'asc' ? 'desc' : 'asc';
        }

        return sortOrder;
      });

      return { ...prevFilter, sort: newHandleSort };
    });
  };

  useEffect(() => {
    dispatch(getResultStatistic(filterTable));
  }, [filterTable.sort]);

  return (
    <>
      <Row gutter={6} className={css.LMS}>
        <Col span={15} className={css['LMS-chapters']}>
          <Card className={css['LMS-mainChapter']}>
            <ChapterPanel topicId={id} canModify={canEdit} topicTypeId={topicTypeId} />
          </Card>
        </Col>
        <Col span={24} className={css['LMS-block']}>
          <div className={css['LMS-mainBlocks']}>
            <Blocks topicId={id} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AllStatistics
            key='AllStatistics--studying'
            isStatisticsLoading={isStudyingLoading}
            isStatisticsError={isStudyingError}
            data={studyingStatistics}
          />

          <ResultsFilter filterTable={filterTable} setFilterTable={setFilterTable} resetFilter={handleResetFilter} />

          <div className={css['LMS-download-block']}>
            <SortTable t={t} activeSort={filterTable.sort} handleChangeSort={handleChangeSort} order={sortOrder} />
            {(isExecutiveTask(filterTable.topicTypeId)
              ? currentUser.authorities.includes(EXPORT_TOPIC_EXECUTIVE_RESULTS)
              : currentUser.authorities.includes(EXPORT_TOPIC_BLOCK_RESULTS)) && (
              <Button
                type='primary'
                size='large'
                icon={<DownloadOutlined />}
                loading={isExporting}
                disabled={isExporting}
                onClick={exportBlockStatistics}
              >
                <Spin className={css['LMS-export-spinner']} spinning={isExporting} />
                {t('download')}
              </Button>
            )}
          </div>

          <div className={css['LMS-resultBlocks']}>
            <ResultTable
              getFile={getFile}
              changeTestStatus={changeTestStatus}
              filterTable={filterTable}
              setFilterTable={setFilterTable}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

LMS.propTypes = {
  canEdit: PropTypes.bool,
  getFile: PropTypes.func,
  changeTestStatus: PropTypes.func,
  subCompanies: PropTypes.array,
  topicTypeId: PropTypes.number,
};
