import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearErrorTextPokaPosition } from '@/store/poka_typicalPositions/actions';

import { selectPokaTypicalPositions } from '@/store/poka_typicalPositions/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';

import { DELETE, EDIT } from '../constants';

import PropTypes from 'prop-types';

import css from '../PositionAssignmentDirectory.module.scss';

const ModalPositionAssignment = ({ open, onCancel, title, onOk, positionData, onOkText }) => {
  const { t } = useTranslation('PositionAssignmentDirectory');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { position } = useSelector(selectStudents);
  const { errorText } = useSelector(selectPokaTypicalPositions);

  useEffect(() => {
    if (positionData?.mode === EDIT && positionData && Object.keys(positionData)?.length > 0) {
      form.setFieldValue('typicalPosition', positionData?.typicalPosition);
      form.setFieldValue('position', positionData?.positions);
    }
  }, [open, positionData]);

  useEffect(() => {
    if (errorText) {
      setTimeout(() => {
        dispatch(clearErrorTextPokaPosition());
        form.validateFields();
      }, 1500);
    }
  }, [errorText]);

  const handleSubmit = data => onOk({ modelPositionName: data.typicalPosition, positions: data.position });

  return (
    <Modal open={open} onCancel={onCancel} title={title} footer={null} className={css['Modal']} width='800px' centered>
      {positionData?.mode === DELETE ? (
        // Контент для удаления
        <div>
          <p className={css['Modal-deleteText']}>{t('deleteWarning')}</p>
          <Row justify='end' gutter={20}>
            <Col>
              <Button onClick={onCancel}>{t('cancel')}</Button>
            </Col>
            <Col>
              <Button type='primary' onClick={onOk}>
                {onOkText}
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        // Форма для создания или редактирования
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label={t('typicalPosition')}
                colon={false}
                name='typicalPosition'
                required
                rules={[
                  {
                    required: true,
                    message: t('requiredPosition'),
                  },
                ]}
                validateStatus={errorText ? 'error' : 'success'}
              >
                <Input placeholder={t('placeholderCreatePosition')} allowClear maxLength={50} showCount />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('selectPosition')}
                colon={false}
                name='position'
                rules={[
                  {
                    required: true,
                    message: t('requiredPosition'),
                  },
                ]}
                validateStatus={errorText ? 'error' : 'success'}
              >
                <Select mode='multiple' placeholder={t('placeholderSelectPosition')} allowClear>
                  {Array.isArray(position) &&
                    position.length > 0 &&
                    position.map(item => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {errorText && <div className={css['Modal-errorText']}>{errorText}</div>}
          </Row>

          <Row justify='end' gutter={22}>
            <Col>
              <Button onClick={onCancel}>{t('cancel')}</Button>
            </Col>
            <Col>
              <Button htmlType='submit' type='primary'>
                {onOkText}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

ModalPositionAssignment.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  positionData: PropTypes.shape({
    mode: PropTypes.string,
    typicalPosition: PropTypes.string,
    positions: PropTypes.arrayOf(PropTypes.string),
  }),
  onOkText: PropTypes.string,
};

export default ModalPositionAssignment;
