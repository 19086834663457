export const GET_WEBINAR_STATS_START = 'GET_WEBINAR_STATS_START';
export const GET_WEBINAR_STATS_SUCCESS = 'GET_WEBINAR_STATS_SUCCESS';
export const GET_WEBINAR_STATS_FAIL = 'GET_WEBINAR_STATS_FAIL';

export const CREATE_WEBINAR_REGISTER_START = 'CREATE_WEBINAR_REGISTER_START';
export const CREATE_WEBINAR_REGISTER_SUCCESS = 'CREATE_WEBINAR_REGISTER_SUCCESS';
export const CREATE_WEBINAR_REGISTER_FAIL = 'CREATE_WEBINAR_REGISTER_FAIL';

export const GET_WEBINAR_RECORD_START = 'GET_WEBINAR_RECORD_START';
export const GET_WEBINAR_RECORD_SUCCESS = 'GET_WEBINAR_RECORD_SUCCESS';
export const GET_WEBINAR_RECORD_FAIL = 'GET_WEBINAR_RECORD_FAIL';

export const CREATE_WEBINAR_START = 'CREATE_WEBINAR_START';
export const CREATE_WEBINAR_SUCCESS = 'CREATE_WEBINAR_SUCCESS';
export const CREATE_WEBINAR_FAIL = 'CREATE_WEBINAR_FAIL';

export const CREATE_WEBINAR_CONVERT_START = 'CREATE_WEBINAR_CONVERT_START';
export const CREATE_WEBINAR_CONVERT_SUCCESS = 'CREATE_WEBINAR_CONVERT_SUCCESS';
export const CREATE_WEBINAR_CONVERT_FAIL = 'CREATE_WEBINAR_CONVERT_FAIL';

export const GET_WEBINAR_CHECK_START = 'GET_WEBINAR_CHECK_START';
export const GET_WEBINAR_CHECK_SUCCESS = 'GET_WEBINAR_CHECK_SUCCESS';
export const GET_WEBINAR_CHECK_FAIL = 'GET_WEBINAR_CHECK_FAIL';
