/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { Avatar, Button, Checkbox, Modal } from 'antd';

import { COVER_IMAGE_TYPES } from '@shared/constants/image-types';

import classNames from 'classnames';
import { FastAverageColor } from 'fast-average-color';
import PropTypes from 'prop-types';

import css from './ImageInput.module.scss';

import 'react-image-crop/dist/ReactCrop.css';

import { CloseOutlined } from '@ant-design/icons';

import css2 from '../FileInput/FileInput.module.scss';

import galleryImage from '@/assets/images/gallery.png';

const ImageInput = props => {
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState(props.imageSrc || '');
  const [isOpen, setIsOpen] = useState(false);
  const [isAspect, setIsAspect] = useState(true);
  const [error, setError] = useState(false);
  const [defFile, setDefFile] = useState(null);
  const [crop, setCrop] = useState({ unit: 'px', x: 10, y: 10, aspect: 17 / 9, width: 45, minWidth: 5, minHeight: 10 });
  const [sizes, setSizes] = useState({});
  const [color, setColor] = useState(null);
  const [modalError, setModalError] = useState(false);

  const inputRef = useRef(null);
  const nodeImgRef = useRef(null);

  useEffect(() => {
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [imageSrc]);

  const setInputRef = input => {
    if (input) {
      input.setAttribute('accept', COVER_IMAGE_TYPES.join(', '));
      inputRef.current = input;
    }
  };

  const openFileDialog = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = event => {
    event.preventDefault();
    const file = event.target.files[0];
    setDefFile(file);
    if (file) {
      if (!COVER_IMAGE_TYPES.includes(file.type) || file.size > 10000000) {
        setModalError(true);
        setIsAspect(true);
      } else {
        setImage(URL.createObjectURL(file));
        setIsOpen(true);
      }
    }
    event.target.value = null;
  };

  const onCancel = () => {
    setModalError(false);
    openFileDialog();
  };

  const onImageLoaded = image => {
    nodeImgRef.current = image;
    setSizes({
      naturalWidth: image.naturalWidth,
      naturalHeight: image.naturalHeight,
    });
  };

  const getCroppedImg = () => {
    const image = nodeImgRef.current;

    const canvas = document.createElement('canvas');
    canvas.width = Math.round((sizes.naturalWidth * crop.width) / 100);
    canvas.height = Math.round((sizes.naturalHeight * crop.height) / 100);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      (sizes.naturalWidth * crop.x) / 100,
      (sizes.naturalHeight * crop.y) / 100,
      (sizes.naturalWidth * crop.width) / 100,
      (sizes.naturalHeight * crop.height) / 100,
      0,
      0,
      (sizes.naturalWidth * crop.width) / 100,
      (sizes.naturalHeight * crop.height) / 100
    );

    canvas.toBlob(blob => {
      const croppedImageUrl = URL.createObjectURL(blob);
      setImageSrc(croppedImageUrl);
      const formData = new FormData();
      formData.append('file', blob);
      props.onChange(formData, props.name, defFile, croppedImageUrl, color);
      getPalette(croppedImageUrl);
      resetModal();
    }, 'image/jpeg');
  };

  const handleChangeAspect = event => {
    setIsAspect(!event.target.checked);
    setCrop(prevCrop => ({
      ...prevCrop,
      aspect: !event.target.checked ? 17 / 9 : null,
    }));
  };

  const deleteImage = () => {
    setImageSrc('');
    props.onChange(null, props.name);
  };

  const resetModal = () => {
    setIsOpen(false);
    setImage('');
    setCrop({ unit: 'px', x: 10, y: 10, aspect: 17 / 9, width: 45, minWidth: 5, minHeight: 10 });
    setSizes({});
    setColor(null);
    setError(false);
  };

  const getPalette = imageSrc => {
    if (!imageSrc) return;

    const canvas = document.createElement('canvas');
    canvas.width = 30;
    canvas.height = 30;
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, 30, 30);
      new FastAverageColor()
        .getColorAsync(canvas)
        .then(color => {
          setColor(color.hex);
        })
        .catch(() => setColor(null));
    };
  };

  const content = imageSrc ? (
    <div className={css['ImageInput-content']}>
      <div className={css['ImageInput-block']}>
        <Avatar className={css['ImageInput-avatar']} src={imageSrc} id='uploadedImage' />
        <CloseOutlined className={css['ImageInput-avatar-delete']} onClick={deleteImage} id='deleteImage' />
      </div>
      <Button
        className={classNames({ [css.modificate]: props.modificate })}
        type='link'
        size='large'
        htmlFor='cover-img-file-input'
        onClick={openFileDialog}
        disabled={props.isLoadingFile || props.isLoadingFileImage}
      >
        {props.t('choose')}
      </Button>
    </div>
  ) : (
    <Button
      className={classNames({ [css.modificate]: props.modificate })}
      disabled={props.isLoadingFile || props.isLoadingFileImage}
      type='link'
      size='large'
      htmlFor='cover-img-file-input'
      onClick={openFileDialog}
    >
      {props.t('choose')}
    </Button>
  );

  return (
    <div className={classNames(css.ImageInput, props.className)}>
      {content}
      <input type='file' id='cover-img-file-input' ref={setInputRef} onChange={handleFileChange} />
      <Modal open={isOpen} onCancel={resetModal} footer={null}>
        <div data-qa='imageCropModal'>
          <div className={css['modal-content__title']}>{props.t('loading')}</div>
          <ReactCrop
            src={image}
            crop={crop}
            onChange={setCrop}
            onImageLoaded={onImageLoaded}
            className={css.ReactCrop}
          />
          <Checkbox
            onChange={handleChangeAspect}
            checked={!isAspect}
            label={props.t('ratio')}
            data-qa='imagePropCheckbox'
          >
            {props.t('ratio')}
          </Checkbox>
          <div className={css['modal-content__footer']}>
            <Button
              disabled={props.isLoadingFileImage}
              onClick={getCroppedImg}
              type='primary'
              size='large'
              data-qa='saveImageBtn'
            >
              {props.t('save')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        modificate
        fileModal
        open={modalError}
        onOk={onCancel}
        onCancel={() => setModalError(false)}
        okText={props.t('add')}
      >
        <div className={css2['App-card-label']}>{props.t('cover')}</div>
        <div className={css2['App-card-info']}>
          <img src={galleryImage} alt='' />
          <div className={css2['App-card-info-item']}>
            <div className={css2['App-card-info-item-block']}>
              <h2>{props.t('images')}</h2>
              <div className={css2['App-card-info-little']}>{props.t('max')}</div>
            </div>
            <h3>{props.t('imagesTypes')}</h3>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ImageInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  fileInfo: PropTypes.object,
  fileLoading: PropTypes.bool,
  imageSrc: PropTypes.string,
  isLoadingFile: PropTypes.bool,
  isLoadingFileImage: PropTypes.bool,
  loadAvatarUser: PropTypes.func,
  modificate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  setColor: PropTypes.func,
  user: PropTypes.object,
};

export default withTranslation('imageInput')(ImageInput);
