/* eslint-disable */
import React, { useState } from 'react';
// Localization
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Redux
import { D360CreateScale } from '@/store/d360/d360_scales/actions';
import { D360CreateScore } from '@/store/d360/d360_scores/actions';

import { Checkbox, Col, Modal, Row } from 'antd';
import { CgImport } from 'react-icons/cg';

import Templates from './templates.json';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

export const ScaleImporter = props => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const { t } = useTranslation('D360_ScaleImporter');

  const onCancel = () => {
    if (props && props.onCancel && typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const onSubmit = () => {
    if (props && props.onSubmit && typeof props.onSubmit === 'function') {
      if (selectedTemplates && Array.isArray(selectedTemplates)) {
        selectedTemplates.map(index => {
          let template = Templates[index];
          let scaleInfo = { name: template.name, description: template.description };

          props.D360CreateScale(
            props.project.id,
            scaleInfo,
            scale => {
              if (scale && scale.data && scale.data.id && template.values && Array.isArray(template.values)) {
                template.values.map(score => {
                  props.D360CreateScore(
                    props.project.id,
                    scale.data.id,
                    score.indicator,
                    score.value,
                    success => {
                      // noop
                    },
                    error => {
                      console.log('Error', error);
                    }
                  );
                });
              }
            },
            error => {
              console.log('Error: ', error);
            }
          );

          props.onSubmit();
        });
      }
    }
  };

  const handleChange = e => {
    setSelectedTemplates(e);
  };

  const render = () => {
    return (
      <Modal title={t('title')} open={props.open} onCancel={onCancel} onOk={onSubmit} width={640} destroyOnClose>
        <Row className={css.Notes}>
          <Col span={24}>
            <CgImport size='3rem' className={css.NotesIcon} />
            <p className={css.NotesText}>{t('description')}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox.Group className={css['Checkbox-group']} onChange={handleChange}>
              {Templates &&
                Array.isArray(Templates) &&
                Templates.map((template, index) => {
                  return (
                    <div className={css['Checkbox-group-check']} key={index}>
                      <Checkbox value={index}>{template.name}</Checkbox>
                    </div>
                  );
                })}
            </Checkbox.Group>
          </Col>
        </Row>
      </Modal>
    );
  };

  return render();
};

ScaleImporter.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  D360CreateScale,
  D360CreateScore,
};

/**
 * Экспорт компонента.
 */
export default connect(null, mapDispatchToProps)(ScaleImporter);
