export const SAVE_NEWS_FILTERS = 'SAVE_NEWS_FILTERS';

export const CLEAR_NEWS_FILTERS = 'CLEAR_NEWS_FILTERS';

export const CLEAR_NEWS_ID = 'CLEAR_NEWS_ID';

export const CLEAR_NEWS_VISIBILITY = 'CLEAR_NEWS_VISIBILITY';

export const GET_STATUS_START = 'GET_STATUS_START';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL';

export const POST_NEWS_FILTERS_START = 'POST_NEWS_FILTERS_START';
export const POST_NEWS_FILTERS_SUCCESS = 'POST_NEWS_FILTERS_SUCCESS';
export const POST_NEWS_FILTERS_FAIL = 'POST_NEWS_FILTERS_FAIL';

export const PUT_NEWS_START = 'PUT_NEWS_START';
export const PUT_NEWS_SUCCESS = 'PUT_NEWS_SUCCESS';
export const PUT_NEWS_FAIL = 'PUT_NEWS_FAIL';

export const GET_NEWS_ID_START = 'GET_NEWS_ID_START';
export const GET_NEWS_ID_SUCCESS = 'GET_NEWS_ID_SUCCESS';
export const GET_NEWS_ID_FAIL = 'GET_NEWS_ID_FAIL';

export const PUT_STATUS_NEWS_START = 'PUT_STATUS_NEWS_START';
export const PUT_STATUS_NEWS_SUCCESS = 'PUT_STATUS_NEWS_SUCCESS';
export const PUT_STATUS_NEWS_FAIL = 'PUT_STATUS_NEWS_FAIL';

export const CREATE_NEWS_START = 'CREATE_NEWS_START';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';

export const UPDATE_NEWS_VISIBILITY_START = 'UPDATE_NEWS_VISIBILITY_START';
export const UPDATE_NEWS_VISIBILITY_SUCCESS = 'UPDATE_NEWS_VISIBILITY_SUCCESS';
export const UPDATE_NEWS_VISIBILITY_FAIL = 'UPDATE_NEWS_VISIBILITY_FAIL';

export const GET_NEWS_VISIBILITY_START = 'GET_NEWS_VISIBILITY_START';
export const GET_NEWS_VISIBILITY_SUCCESS = 'GET_NEWS_VISIBILITY_SUCCESS';
export const GET_NEWS_VISIBILITY_FAIL = 'GET_NEWS_VISIBILITY_FAIL';

export const PUT_NEWS_VISIBILITY_START = 'PUT_NEWS_VISIBILITY_START';
export const PUT_NEWS_VISIBILITY_SUCCESS = 'PUT_NEWS_VISIBILITY_SUCCESS';
export const PUT_NEWS_VISIBILITY_FAIL = 'PUT_NEWS_VISIBILITY_FAIL';

export const DELETE_NEWS_VISIBILITY_START = 'DELETE_NEWS_VISIBILITY_START';
export const DELETE_NEWS_VISIBILITY_SUCCESS = 'DELETE_NEWS_VISIBILITY_SUCCESS';
export const DELETE_NEWS_VISIBILITY_FAIL = 'DELETE_NEWS_VISIBILITY_FAIL';

export const SAVE_SORT_NEWS = 'SAVE_SORT_NEWS';
