import { GET_POSITION_INTERNAL_FAIL, GET_POSITION_INTERNAL_START, GET_POSITION_INTERNAL_SUCCESS } from './constants';

import getQueryString from '@/utils/get-query-string';
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPositionInternal = (size = 99999) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/position${getQueryString({ size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSITION_INTERNAL_START, GET_POSITION_INTERNAL_SUCCESS, GET_POSITION_INTERNAL_FAIL],
});
