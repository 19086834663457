/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFileSizes } from '@/store/files/selectors';

import { Button, Dropdown, Form, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import SingleFileInput from '@/components/FileInput/SingleFileInput';
import { EllipsisOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import Utils from '@/Utils';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';
import presentation_icon from '@/assets/images/presentation.svg';

export const Presentation = ({
  data,
  editBlock,
  deleteBlock,
  forceClose,
  createPresentation,
  uploadPresentation,
  deletePresentation,
  getBlocks,
  topicId,
  getChapters,
  canEdit,
  currentUser,
  topicTypeId,
  showSaveModal,
}) => {
  const { t } = useTranslation('presentation');
  const [fileName, setFileName] = useState(
    (data.presentation && data.presentation.file && data.presentation.file.name) || ''
  );
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(true);
  const [presentationID, setPresentationID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileSizes = useSelector(selectFileSizes);
  const presentationFileSize = fileSizes.filter(item => item.uploadSizeType === 'PRESENTATION')[0];

  const header = useMemo(
    () => (
      <div className={css['Block-header']}>
        <img className={css['Block-header-item']} src={presentation_icon} alt='' />
        <div className={css['Block-header-name']}>
          <Tooltip title={data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(data.name, 55)}
          </Tooltip>
        </div>
      </div>
    ),
    [data.name]
  );

  const saveButtonHandler = useCallback(
    e => {
      e.stopPropagation();
      if (fileName) {
        createPresentation(data.id, presentationID, () => {
          setDirty(true);
          getChapters(topicId);
        });
      } else if (data.presentation.id) {
        if (data.presentation.id) {
          deletePresentation(data.presentation.id, () => {
            getBlocks([topicId]);
            getChapters(topicId);
          });
        }
        setDirty(true);
      }
    },
    [data, fileName, dirty, createPresentation, deletePresentation, getBlocks, topicId]
  );

  const handleEditClick = useCallback(() => {
    editBlock(data.id);
  }, [editBlock]);

  const handleDeleteClick = useCallback(() => {
    deleteBlock(data.id);
  }, [deleteBlock]);

  const saveModal = useCallback(
    e => {
      showSaveModal({
        id: presentationID,
        delId: data.id,
        funcSave: () => saveButtonHandler(e),
        funcCancel: () => {
          setPresentationID(data.presentation.id);
          setFileName(data.presentation.file.name);
          setDirty(true);
        },
      });
    },
    [showSaveModal, presentationID, saveButtonHandler, fileName]
  );

  const dropDownMenu = [
    ...(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={handleEditClick}>
                {t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={handleDeleteClick}>
                {t('remove')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const extra = useMemo(
    () => (
      <div onClick={e => e.stopPropagation()}>
        <Button
          onClick={saveModal}
          className={css['Block-header-save']}
          disabled={dirty || !canEdit || isLoading || (!fileName && !data.presentation)}
          type='primary'
          data-qa='saveBlockBtn'
        >
          {t('save')}
        </Button>
        {(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
          currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
          (topicTypeId === 25 &&
            (currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
              currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
          <Dropdown
            menu={{ items: dropDownMenu }}
            placement='bottomRight'
            overlayClassName={css['Dropdown-menu']}
            trigger={['click']}
          >
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    ),
    [deleteBlock, editBlock, saveButtonHandler, dirty, canEdit]
  );

  const onChange = useCallback(
    file => {
      if (!file) {
        setFileName(null);
        setDirty(false);
        setPresentationID(null);
      } else {
        uploadPresentation(
          file,
          success => {
            setPresentationID(success.id);
            setIsLoading(false);
            setDirty(false);
            setFileName(success.file.name);
          },
          fail => {
            setError(true);
            setFileName(null);
            setIsLoading(false);
          }
        );
      }
    },
    [fileName, dirty, error, presentationID]
  );

  return (
    <div className={css.Block}>
      <CollapseLMS header={header} data={data} extra={extra} forceClose={forceClose}>
        <div className={css['Block-layoutPDF']}>
          <Form.Item label={t('PDFfile')} wrapperCol={{ span: 21 }}>
            <SingleFileInput
              type='pdf'
              loading={isLoading}
              setLoading={setIsLoading}
              onChange={onChange}
              name={fileName}
              disable={!canEdit}
              error={error}
              scormFileSize={presentationFileSize}
            />
          </Form.Item>
        </div>
      </CollapseLMS>
      {error && (
        <ConfirmationModal
          open
          onOk={() => {
            setError(false);
            setIsLoading(false);
          }}
          onCancel={() => {
            setError(false);
            setIsLoading(false);
          }}
          okText='Ок'
          zIndex={1006}
          title={t('UplError')}
          confirmationText={t('NOTPDF')}
        />
      )}
    </div>
  );
};
