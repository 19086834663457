import {
  CREATE_CHAT_MENTOR_GROUP_FAIL,
  CREATE_CHAT_MENTOR_GROUP_START,
  CREATE_CHAT_MENTOR_GROUP_SUCCESS,
} from '@/store/chat/constants';

const initialState = {};

export default (state = initialState, { type }) => {
  switch (type) {
    case CREATE_CHAT_MENTOR_GROUP_START:
    case CREATE_CHAT_MENTOR_GROUP_SUCCESS:
    case CREATE_CHAT_MENTOR_GROUP_FAIL:
      return state;

    default:
      return state;
  }
};
