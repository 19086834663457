// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-mz_dOr.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-UFcd2E .admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-label-jD9Wwf[data-value="Montserrat"]:before, .admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-mz_dOr.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-UFcd2E .admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-item-urTON8[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-Montserrat-d4aKRF {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__header-EGttn5 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__editBtns-UE6DqA {
  justify-content: flex-end;
  margin-bottom: 20px;
  display: flex;
}

.admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__editBtns-UE6DqA button {
  width: 34px;
  height: 34px;
  margin-left: 10px;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/ThemesEditor/ThemesEditorPage.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAOA;EAJE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;AACf;;AAMA;EAHE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.ThemesEditor__header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.ThemesEditor__editBtns {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n}\n.ThemesEditor__editBtns button {\n  width: 34px;\n  height: 34px;\n  padding: 5px;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-mz_dOr`,
	"qlPicker": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-mz_dOr`,
	"ql-font": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-UFcd2E`,
	"qlFont": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-UFcd2E`,
	"ql-picker-label": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-label-jD9Wwf`,
	"qlPickerLabel": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-label-jD9Wwf`,
	"ql-picker-item": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-item-urTON8`,
	"qlPickerItem": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-picker-item-urTON8`,
	"ql-font-Montserrat": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-Montserrat-d4aKRF`,
	"qlFontMontserrat": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ql-font-Montserrat-d4aKRF`,
	"ThemesEditor__header": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__header-EGttn5`,
	"themesEditorHeader": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__header-EGttn5`,
	"ThemesEditor__editBtns": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__editBtns-UE6DqA`,
	"themesEditorEditBtns": `admin-src-pages-ThemesEditor-ThemesEditorPage-module__ThemesEditor__editBtns-UE6DqA`
};
export default ___CSS_LOADER_EXPORT___;
