/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360CreateRespondent, D360DeleteRespondent, D360GetRespondents } from '@/store/d360/d360_respondents/actions';

import { selectD360Respondents } from '@/store/d360/d360_respondents/selectors';

import { Modal, Tabs } from 'antd';

import {
  RESPONDENT_TYPE_COLLEAGUE,
  RESPONDENT_TYPE_CROSS,
  RESPONDENT_TYPE_MANAGER,
  RESPONDENT_TYPE_SELF,
  RESPONDENT_TYPE_SUBORDINATE,
  RESPONDENT_TYPES,
} from '../../../constants';
import RespondentsTable from './RespondentsTable';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { TabPane } = Tabs;

export const Respondents = props => {
  const { t } = useTranslation('D360_Respondents');

  const [measurableId, setMeasurableId] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.projectId && props.measurableId) {
      if (props.measurableId !== measurableId || props.open) {
        props.D360GetRespondents(props.projectId, props.measurableId);
        setMeasurableId(props.measurableId);
      }
    }
  }, [props.measurableId, props.open]);

  useEffect(() => {
    if (props.loading && props.loading !== loading) {
      setLoading(props.loading);
    }
  }, [props.respondents, props.loading]);

  const onCancel = () => {
    if (props && props.onCancel && typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const onSubmit = () => {
    if (props && props.onSubmit && typeof props.onSubmit === 'function') {
      props.onSubmit();
    }
  };

  const handleDelete = (respondents, removeScore) => {
    if (respondents && Array.isArray(respondents)) {
      for (const respondent of respondents) {
        props.D360DeleteRespondent(props.projectId, props.measurableId, respondent.id, removeScore);
      }
    }
  };

  const renderTabPane = respondentType => {
    let tabName = '';

    switch (respondentType) {
      case RESPONDENT_TYPE_MANAGER:
        tabName = t('tabManagers');
        break;
      case RESPONDENT_TYPE_COLLEAGUE:
        tabName = t('tabColleagues');
        break;
      case RESPONDENT_TYPE_SUBORDINATE:
        tabName = t('tabSubordinates');
        break;
      case RESPONDENT_TYPE_CROSS:
        tabName = t('tabOther');
        break;
      case RESPONDENT_TYPE_SELF:
        tabName = t('tabSelf');
        break;
    }

    return (
      <TabPane key={respondentType} tab={tabName} className={css.TabsTab}>
        <RespondentsTable
          data={props.respondents.filter(respondent => respondent.d360_respondent_type_id === respondentType)}
          loading={props.loading}
          respondentType={respondentType}
          editable={props.editable}
          projectId={props.projectId}
          measurableId={props.measurableId}
          rowKey={record => record.id}
          tabName={tabName}
          onDelete={handleDelete}
        />
      </TabPane>
    );
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <Modal
        open={props.open}
        title={t('title')}
        centered={true}
        onCancel={onCancel}
        onOk={onSubmit}
        okText={t('save')}
        cancelText={t('cancel')}
        width={800}
      >
        <Tabs type='card'>
          {RESPONDENT_TYPES.map(respondentType => {
            return renderTabPane(respondentType);
          })}
        </Tabs>
      </Modal>
    );
  };

  return render();
};

Respondents.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  measurableId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

const mapStateToProps = createSelector([selectD360Respondents], D360Respondents => ({
  respondents: D360Respondents.respondents,
  loading: D360Respondents.loading,
}));

const mapDispatchToProps = {
  D360CreateRespondent,
  D360DeleteRespondent,
  D360GetRespondents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Respondents);
