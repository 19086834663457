export const CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_START = 'CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_START';
export const CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS = 'CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS';
export const CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_FAIL = 'CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_FAIL';

export const GET_CUSTOM_BY_ALL_NOTIFICATIONS_START = 'GET_CUSTOM_BY_ALL_NOTIFICATIONS_START';
export const GET_CUSTOM_BY_ALL_NOTIFICATIONS_SUCCESS = 'GET_CUSTOM_BY_ALL_NOTIFICATIONS_SUCCESS';
export const GET_CUSTOM_BY_ALL_NOTIFICATIONS_FAIL = 'GET_CUSTOM_BY_ALL_NOTIFICATIONS_FAIL';

export const GET_CUSTOM_BY_ONE_NOTIFICATION_START = 'GET_CUSTOM_BY_ONE_NOTIFICATION_START';
export const GET_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS = 'GET_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS';
export const GET_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL = 'GET_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL';

export const DELETE_CUSTOM_BY_ONE_NOTIFICATION_START = 'DELETE_CUSTOM_BY_ONE_NOTIFICATION_START';
export const DELETE_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS = 'DELETE_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS';
export const DELETE_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL = 'DELETE_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL';

export const PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_START = 'PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_START';
export const PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS = 'PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS';
export const PUBLISH_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL = 'PUBLISH_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL';
