/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

import { getMassSelectionCount, getMassSelectionUsers } from '@/store/courses/actions';
import { getFile } from '@/store/files/actions';
import {
  exportUsersTopicVisibility,
  getCountSettingsVisibility,
  getSettingsVisibility,
} from '@/store/settings_visibility/actions';
import { getAlpinaLink } from '@/store/topics/actions';

import { selectCourses } from '@/store/courses/selectors';
import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Checkbox, Col, Row, Tooltip } from 'antd';
import { Card } from '@/components/Layout';
import { MaterialCost } from '@/components/MaterialCard/MaterialCost';
import MoreButtonComponent from '@/components/MoreButtonComponent';
import Rate from '@/components/Rate';
import Spinner from '@/components/Spinner';
import TypeBadge from '@/components/TypeBadge';
import { CheckOutlined } from '@ant-design/icons';
import { FiUsers } from 'react-icons/fi';

import FieldItem from '../FieldItem';
import FieldItemFile from '../FieldItemFile';

import CustomList from '@shared/components/CustomList';
import { ADMIN_NAME } from '@shared/constants/admin-name';
import {
  isConference,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourseOrConf,
  isEvent,
  isExecutiveTask,
  isSkills,
} from '@shared/utils/topic-utils';
import { isYouTube } from '@shared/utils/validate-utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _find from 'lodash/find';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from '../TopicDetailsDialog.module.scss';

import {
  EXPORT_USERS_PLAN,
  EXPORT_USERS_TOPIC_VISIBILITY,
  MASS_FILTER_INTERACT,
  STUDY_PLAN_CREATE_BY_ADMIN,
  TOPIC_VISIBILITY_FILTER_READ,
} from '@/constants/permissions';
import { PUBLIC_FIELDS, TOPIC_TYPES } from '@/constants/topic-types';

const isFieldEmpty = field => {
  if (typeof field === 'boolean') {
    return false;
  }
  if (Array.isArray(field)) {
    return !field.length;
  }
  return !field;
};

const TopicContent = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const { topic } = useSelector(selectTopics);
  const { currentUser } = useSelector(selectUsers);
  const { eventList, countMassSelection, isLoading } = useSelector(selectCourses);
  const { loading, count, settingsVisibility } = useSelector(selectSettingsVisibility);
  const [domainSwap, setDomainSwap] = useState('');
  const [badgeDto, setBadgeDto] = useState({});

  useEffect(() => {
    if (topic?.id) {
      dispatch(getSettingsVisibility(topic.id)); // настройки видимости для топика
      dispatch(getMassSelectionCount(topic.id)); // счетик массового назначения
    }
  }, [topic]);

  useEffect(() => {
    if (Object.keys(settingsVisibility).length > 0) {
      dispatch(getCountSettingsVisibility(settingsVisibility)); // счетик настройки видимости
    }
  }, [settingsVisibility]);

  useEffect(() => {
    if (topic?.publicUrl) {
      const url = new URL(topic.publicUrl);
      url.host = currentUser.domainCompany.domain;
      setDomainSwap(url.toString());
    }
  }, [topic]);

  useEffect(() => {
    if (topic?.rewardsDto?.length > 0) {
      const badge = topic.rewardsDto.find(i => i.id && i.name);
      setBadgeDto(badge);
    } else {
      setBadgeDto({});
    }
  }, [topic?.rewardsDto]);

  const categories =
    topic &&
    topic.categories &&
    topic.categories.map(cat => (
      <div key={cat.id} className={css['DetailsModal-label-category']}>
        {cat.name}
      </div>
    ));

  const description =
    topic && topic.description ? (
      <div data-qa='adminMaterialDetailDescription' className={css['DetailsModal-description']}>
        {topic.description}
      </div>
    ) : null;
  const cover =
    topic && topic.coverFileUuid ? (
      <div
        className={classNames(css['DetailsModal-cover'], {
          [css.hasBg]: topic.coverColor && topic.coverColor.length > 0,
        })}
        style={{ backgroundColor: topic.coverColor }}
      >
        <img
          className={css['DetailsModal-image']}
          src={props.imgState}
          alt='image'
          data-qa='adminMaterialDetailImage'
        />
      </div>
    ) : null;

  const video =
    topic && isYouTube(_get(topic, 'links[0].url')) ? (
      <YouTube
        className={css['Details-video']}
        videoId={topic.links && topic.links[0].url.substring(topic.links[0].url.indexOf('be/') + 3)}
      />
    ) : !props.imgState ? null : topic && topic.links && !!topic.links.length ? (
      <div
        className={classNames(css['DetailsModal-cover'], {
          [css.hasBg]: topic && topic.coverColor && topic.coverColor.length > 0,
        })}
        style={{ backgroundColor: topic.coverColor }}
      >
        <a href={topic && topic.links[0].url} className={classNames(css['DetailsModal-cover--link'])} target='_blank'>
          <input type='image' className={css['DetailsModal-image']} src={props.imgState} alt='image' />
        </a>
      </div>
    ) : (
      <div
        className={classNames(css['DetailsModal-cover'], {
          [css.hasBg]: topic && topic.coverColor && topic.coverColor.length > 0,
        })}
      >
        <img className={css['DetailsModal-image']} src={props.imgState} alt='image' />
      </div>
    );

  const topicTypeId = topic?.type?.id;
  const isEventType = isEvent(topicTypeId) || isConference(topicTypeId);

  const createdBy = (topic && topic.creBy) || ADMIN_NAME;
  const modifiedBy = (topic && topic.modBy) || ADMIN_NAME;
  const mediaContent = topic && topic.type && topic.type.id === 4 ? video : cover;
  const topicTags = topic && topic.tags && topic.tags.map(tag => <div key={tag.id}>{tag.name}</div>);
  const topicSkills = topic && topic.skills && topic.skills.map(tag => <div key={tag.id}>{tag.name}</div>);
  const topicType = topic && topic.type && _find(TOPIC_TYPES(t), type => type.id === topic.type.id);
  const typeBadge = topic && topicType ? <TypeBadge type={topicType.code} name={topic.typeLabel?.name || ''} /> : null;

  const eventTimeEnd = topic?.endDate && topic?.endTime && dayjs(`${topic.endDate}T${topic.endTime}+03:00`).format();
  const isEndedEvent = isEventType && !dayjs().isBefore(eventTimeEnd);

  const countActiveEvents = !isConference(topicTypeId)
    ? true
    : (eventList || []).filter(event => event && dayjs().isSameOrBefore(dayjs(event.endTime))).length;

  const renderCost = (text, idx, value) => {
    return (
      <div key={idx} className={css['DetailsModal-card-item']}>
        <div data-qa='adminMaterialDetailCoast' className={css['DetailsModal-label']}>
          {text}
        </div>
        <div>
          <MaterialCost currentUser={currentUser} topic={topic} value={value} />
        </div>
      </div>
    );
  };

  const topicTypeFields =
    topicType &&
    topicType.fields &&
    topicType.fields
      .map((field, idx) => {
        const fieldObject = PUBLIC_FIELDS(t)[field.type] || {};
        const fieldName = fieldObject.responseField || fieldObject.apiField;

        if (!topic[fieldName] || topic[fieldName].length === 0) return null;

        if (
          topic &&
          topic.subType &&
          (topic.subType.id === 21 || topic.subType.id === 18) &&
          field.type === 'meetPlace'
        ) {
          return (
            <div key={idx} className={css['DetailsModal-card-item']}>
              <div className={css['DetailsModal-label']}>{t('location')}</div>
              <div>{t('online')}</div>
            </div>
          );
        }
        if (field.type === 'schedule' && isConference(topic.type.id)) {
          return null;
        }

        if (field.type === 'costMin') {
          return renderCost(t('costMin'), idx, 'costMin');
        }

        if (field.type === 'cost') {
          return renderCost(t('cost'), idx, 'cost');
        }

        return (
          fieldName &&
          (fieldName === 'files' ? (
            <FieldItemFile
              typeId={topic.type && topic.type.id}
              key={fieldName}
              field={fieldObject}
              value={topic[fieldName]}
              getFile={(uuid, onSuccess) => dispatch(getFile(uuid, onSuccess))}
            />
          ) : (
            <FieldItem
              topic={topic}
              typeId={topic.type && topic.type.id}
              key={fieldName}
              field={fieldObject}
              value={topic[fieldName]}
              getFile={(uuid, onSuccess) => dispatch(getFile(uuid, onSuccess))}
              getAlpinaLink={getAlpinaLink}
            />
          ))
        );
      })
      .filter(field => field !== null);

  const blobTemplate = blob => {
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.click();
  };

  const exportMassSelectionUsers = () => {
    dispatch(getMassSelectionUsers(topic.id, blobTemplate));
  };

  const exportTopicVisibility = () => {
    dispatch(exportUsersTopicVisibility(topic.id, blobTemplate));
  };

  const addToPlanDisabled = isEventType && (!countActiveEvents || topic?.isTopicFull);
  const btnAddToPlan = (
    <Button
      onClick={props.showUsersModal}
      disabled={addToPlanDisabled}
      className={css['DetailsModal-card-button']}
      type='primary'
      data-qa='adminMaterialDetailAddToPlanBtn'
    >
      {t('addToPlan')}
    </Button>
  );

  return (
    <>
      {topic && (
        <>
          <Row gutter={1} className={classNames(css.DetailsModal, css['DetailsModal-tab'])}>
            <Col span={15} className={css['DetailsModal-col']}>
              <Card className={css['DetailsModal-main']}>
                {mediaContent}
                {description}
              </Card>
            </Col>
            <Col span={9} className={css['DetailsModal-col']}>
              <Card className={css['DetailsModal-card']}>
                <Row className={css['DetailsModal-card-row']}>
                  <Col span={8}>{t('addedToPlan')}</Col>
                  <Col span={6} className={css['DetailsModal-card-blockCount']}>
                    <span>
                      <FiUsers className={css['DetailsModal-card-icon']} />
                    </span>
                    <span
                      data-qa='adminTopicBlockPlanCount'
                      className={
                        css[
                          currentUser.authorities.includes(EXPORT_USERS_PLAN)
                            ? 'DetailsModal-card-count'
                            : 'DetailsModal-card-count--black'
                        ]
                      }
                      onClick={
                        countMassSelection !== 0 && currentUser.authorities.includes(EXPORT_USERS_PLAN)
                          ? exportMassSelectionUsers
                          : () => {}
                      }
                    >
                      {/* кол-во Массового назначения  */}
                      {isLoading ? <Spinner small /> : countMassSelection || 0}
                    </span>
                  </Col>

                  {(currentUser.authorities.includes(MASS_FILTER_INTERACT) ||
                    currentUser.authorities.includes(STUDY_PLAN_CREATE_BY_ADMIN)) && (
                    <Col span={10}>
                      {props.isAdminPlanMan &&
                        !isEndedEvent &&
                        (addToPlanDisabled ? (
                          <Tooltip
                            title={isEventType && topic?.isTopicFull ? t('topicFull') : ''}
                            className={css['DetailsModal-card-tooltip']}
                          >
                            {btnAddToPlan}
                          </Tooltip>
                        ) : (
                          btnAddToPlan
                        ))}
                    </Col>
                  )}
                </Row>
                <Row className={`${css['DetailsModal-card-visibility']} ${css['DetailsModal-card-row']}`}>
                  <Col span={8}>{t('visibility')}</Col>
                  <Col span={6} className={css['DetailsModal-card-blockCount']}>
                    <span>
                      <FiUsers className={css['DetailsModal-card-icon']} />
                    </span>
                    <span
                      data-qa='visibilityCount'
                      className={
                        css[
                          currentUser.authorities.includes(EXPORT_USERS_TOPIC_VISIBILITY)
                            ? 'DetailsModal-card-count'
                            : 'DetailsModal-card-count--black'
                        ]
                      }
                      onClick={
                        topic.visible && currentUser.authorities.includes(EXPORT_USERS_TOPIC_VISIBILITY)
                          ? exportTopicVisibility
                          : () => {}
                      }
                    >
                      {/* кол-во Настройки видимости  */}
                      {loading ? <Spinner small /> : topic.visible ? count : 0}
                    </span>
                  </Col>
                  <Col span={10}>
                    {currentUser.authorities.includes(TOPIC_VISIBILITY_FILTER_READ) && (
                      <Button
                        data-qa='settingsVisibilityTopic'
                        onClick={props.showModalSettingsVisible}
                        className={css['DetailsModal-card-button']}
                        type='primary'
                      >
                        {t('settingsVisibilityTopic')}
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className={css['DetailsModal-card-item-large']}>
                    {((Utils.isManager(currentUser) && isExecutiveTask(topic?.type?.id)) ||
                      Utils.isAdminOrSuperAdmin(currentUser)) && (
                      <div className={classNames(css['DetailsModal-card-item'], css['buttons-box'])}>
                        <Button
                          className={css['DetailsModal-card-button']}
                          onClick={props.showTopicCreatingTemplateDialog}
                          type='primary'
                        >
                          {t('createTemplate')}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card>

              <Card className={classNames(css['DetailsModal-card'], css['DetailsModal-card-blockButton'])}>
                <div className={classNames(css['DetailsModal-card-item'], css['buttons-box'])}>
                  {topic.status !== 'Удален' && topic.scope === 'UNMODERATE' && props.editable && (
                    <Button
                      onClick={props.changeScope}
                      className={css['DetailsModal-card-blockButton-btn']}
                      data-qa='adminTopicDetailApproveModerationBtn'
                    >
                      {t('approveMaterial')}
                    </Button>
                  )}
                  {topic.scope !== 'UNMODERATE' && props.editable && (
                    <Button
                      className={css['DetailsModal-btn-success']}
                      ghost
                      type='primary'
                      data-qa='adminTopicDetailPassedModerationBtn'
                    >
                      <CheckOutlined className={css['DetailsModal-icon']} />
                      {t('passedModeration')}
                    </Button>
                  )}
                </div>
                <div className={classNames(css['DetailsModal-card-item'], css['buttons-box'])}>
                  {(props.editable || (topic.careers && !!topic.careers.length)) &&
                    currentUser.domainCompany.hasTrajectory && (
                      <Button
                        onClick={props.showITODDialog}
                        className={css['DetailsModal-card-blockButton-ipr']}
                        data-qa='adminTopicDetailDiagnosticBtn'
                      >
                        {t('settings')}
                      </Button>
                    )}
                </div>
                {(isCorpOrExtCourseOrConf(topicTypeId) || isCorporateCourseWithoutApprove(topicTypeId)) && (
                  <div className={css['DetailsModal-card-ignore']}>
                    <Checkbox onChange={props.changeIndependent} checked={props.checkBoxValue}>
                      {t('ignore')}
                    </Checkbox>
                  </div>
                )}
              </Card>

              <Card className={css['DetailsModal-card']}>
                <div className={css['DetailsModal-card-item']} data-qa='adminMaterialDetailId'>
                  <div className={css['DetailsModal-label']}>{'ID'}</div>
                  {topic.id}
                </div>
                {!isFieldEmpty(typeBadge) && (
                  <div className={css['DetailsModal-card-item']} data-qa='adminMaterialDetailTypeBadge'>
                    <div className={css['DetailsModal-label']}>{t('type')}</div>
                    {typeBadge}
                  </div>
                )}
                {!isFieldEmpty(topicTags) && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('tags')}</div>
                    <div data-qa='adminMaterialDetailTags' className={css['DetailsModal-topic-tags']}>
                      {topicTags}
                    </div>
                  </div>
                )}
                {isFieldEmpty(topicSkills) ? null : (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('skills')}</div>
                    <div data-qa='adminMaterialDetailSkills' className={css['DetailsModal-topic-tags']}>
                      {topicSkills}
                    </div>
                  </div>
                )}
                {!isFieldEmpty(topic.difficulty) && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('difficulty')}</div>
                    <Rate count={3} value={topic.difficulty} disabled />
                  </div>
                )}
                {!isFieldEmpty(topic.jobLevels) && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('jobLevel')}</div>
                    <MoreButtonComponent elements={topic.jobLevels} value='name' />
                  </div>
                )}
                {!isFieldEmpty(topic.jobFamilies) && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('function')}</div>
                    <MoreButtonComponent elements={topic.jobFamilies} value='name' maxElements={1} />
                  </div>
                )}
                {/* <div className={css['DetailsModal-card-item']}>
                  <div data-qa='materialDetailCategoryLabel' className={css['DetailsModal-label']}>
                    {t('category')}
                  </div>
                  {categories}
                </div> !!!!!! В рамках задачи KAM-4507 закомментировать поля категории !!!!!! */}
                {!isFieldEmpty(topic.level && topic.level.name) && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('levels')}</div>
                    <div data-qa='materialDetailLevel'>{topic.level && topic.level.name}</div>
                  </div>
                )}
                {!isFieldEmpty(topic.scope) && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-labelScope']} id='internalUseText'>
                      {topic.scope === 'INTERNAL'
                        ? t('internalUse')
                        : topic.scope === 'UNMODERATE' && t('inModeration')}
                    </div>
                  </div>
                )}

                {badgeDto?.id && badgeDto?.name && (
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('badge')}</div>
                    <div className={css['DetailsModal-badge']}>
                      <Link data-qa='materialDetailBadge' to={`/badge/${badgeDto.id}`}>
                        {Utils.trString(badgeDto.name, 25)}
                      </Link>
                    </div>
                  </div>
                )}

                {!isFieldEmpty(topic.publicUrl) && (
                  <div className={css['DetailsModal-card-copy']}>
                    <Button
                      className={css['DetailsModal-card-button']}
                      type='primary'
                      onClick={() => window.open(domainSwap, '_blank')}
                      data-qa='materialDetailLink'
                    >
                      {t('showMaterial')}
                    </Button>
                  </div>
                )}
              </Card>

              {topicTypeFields && topicTypeFields.length > 0 && (
                <Card className={css['DetailsModal-card']} data-qa='materialBookAddress'>
                  {topicTypeFields}
                  {props.addressBookList()}
                </Card>
              )}

              <Card className={css['DetailsModal-card']}>
                <div className={css['DetailsModal-card-item']}>
                  <div className={css['DetailsModal-label']}>{t('created')}</div>
                  <div>
                    <div data-qa='materialDetailAuthor' className={css['DetailsModal-topic-author']}>
                      {`${createdBy.firstName || ''} ${createdBy.lastName || ''}`}
                    </div>
                    <div>{dayjs(topic.createdDate).format('DD.MM.YYYY').toString()}</div>
                  </div>
                </div>
                <div className={css['DetailsModal-card-item']}>
                  <div className={css['DetailsModal-label']}>{t('edited')}</div>
                  <div>
                    <div data-qa='materialDetailEditor' className={css['DetailsModal-topic-author']}>
                      {`${modifiedBy.firstName || ''} ${modifiedBy.lastName || ''}`}
                    </div>
                    <div>{dayjs(topic.lastModifiedDate).format('DD.MM.YYYY').toString()}</div>
                  </div>
                </div>
                <div className={css['DetailsModal-card-item']}>
                  <div className={css['DetailsModal-label']}>{t('views')}</div>
                  <div data-qa='materialDetailViews'>{props.countView || 0}</div>
                </div>
              </Card>
              {isSkills(topic?.type?.id) && (
                <Card className={css['DetailsModal-card']}>
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('pokaAbilityCategory')}</div>
                    <CustomList data={topic?.poka?.categories} objectKey='label' />
                  </div>
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('site')}</div>
                    <CustomList data={topic?.poka?.sites} objectKey='name' />
                  </div>
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('targetAssessment')}</div>
                    <div>{topic?.poka?.endorsementLevel?.label}</div>
                  </div>
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('passagePriority')}</div>
                    <div>
                      {topic?.poka?.endorsementPriority + ' '}
                      {t('day')}
                    </div>
                  </div>
                  <div className={css['DetailsModal-card-item']}>
                    <div className={css['DetailsModal-label']}>{t('cyclicity')}</div>
                    {topic?.poka?.cyclicity ? (
                      <div>
                        {topic.poka.cyclicity + ' '}
                        {t('day')}
                      </div>
                    ) : (
                      t('never')
                    )}
                  </div>
                </Card>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

TopicContent.propTypes = {
  t: PropTypes.func,
  imgState: PropTypes.string,
  countView: PropTypes.number,
  isAdminPlanMan: PropTypes.bool,
  topicTypeId: PropTypes.number,
  showUsersModal: PropTypes.func,
  isAdmin: PropTypes.bool,
  editable: PropTypes.bool,
  changeScope: PropTypes.func,
  showModalSettingsVisible: PropTypes.func,
  showITODDialog: PropTypes.func,
  showTopicCreatingTemplateDialog: PropTypes.func,
  changeIndependent: PropTypes.func,
  addressBookList: PropTypes.func,
  checkBoxValue: PropTypes.bool,
};

export default TopicContent;
