import {
  ADD_INACTIVE_BADGE,
  APPOINTS_BADGE_FAIL,
  APPOINTS_BADGE_START,
  APPOINTS_BADGE_SUCCESS,
  CHANGE_STATUS_BADGE_FAIL,
  CHANGE_STATUS_BADGE_START,
  CHANGE_STATUS_BADGE_SUCCESS,
  CLEAR_BADGE_FILTERS,
  CLEAR_BADGE_INFO,
  CLEAR_BADGE_INFO_FILTERS,
  CLEAR_INACTIVE_BADGE,
  CREATE_BADGE_FAIL,
  CREATE_BADGE_START,
  CREATE_BADGE_SUCCESS,
  DELETE_BADGE_FAIL,
  DELETE_BADGE_START,
  DELETE_BADGE_SUCCESS,
  DELETE_MASS_USER_BADGE_FAIL,
  DELETE_MASS_USER_BADGE_START,
  DELETE_MASS_USER_BADGE_SUCCESS,
  DELETE_USER_BADGE_FAIL,
  DELETE_USER_BADGE_START,
  DELETE_USER_BADGE_SUCCESS,
  GET_ACTIONS_BADGE_FAIL,
  GET_ACTIONS_BADGE_START,
  GET_ACTIONS_BADGE_SUCCESS,
  GET_APPOINT_BADGE_FAIL,
  GET_APPOINT_BADGE_START,
  GET_APPOINT_BADGE_SUCCESS,
  GET_BADGE_ID_FAIL,
  GET_BADGE_ID_START,
  GET_BADGE_ID_SUCCESS,
  GET_BADGES_FAIL,
  GET_BADGES_START,
  GET_BADGES_SUCCESS,
  GET_EXAMPLE_BADGE_CSV_FAIL,
  GET_EXAMPLE_BADGE_CSV_START,
  GET_EXAMPLE_BADGE_CSV_SUCCESS,
  GET_ISSUE_FAIL,
  GET_ISSUE_START,
  GET_ISSUE_SUCCESS,
  GET_LIST_BADGE_FAIL,
  GET_LIST_BADGE_START,
  GET_LIST_BADGE_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  IMPORT_BADGE_USERS_FAIL,
  IMPORT_BADGE_USERS_START,
  IMPORT_BADGE_USERS_SUCCESS,
  PUT_BADGE_TOPIC_FAIL,
  PUT_BADGE_TOPIC_START,
  PUT_BADGE_TOPIC_SUCCESS,
  SAVE_BADGE_FILTERS,
  SAVE_BADGE_INFO_FILTERS,
  SAVE_BADGE_INFO_SORT,
  SAVE_BADGE_SORT,
  UPDATE_BADGE_FAIL,
  UPDATE_BADGE_START,
  UPDATE_BADGE_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import { headersForImport } from '@/constants/global';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// удалить награду/ид стейт
export const clearBadgeInfo = () => ({
  type: CLEAR_BADGE_INFO,
});

// сохранить фильтры награды
export const saveBadgeFilters = value => ({
  type: SAVE_BADGE_FILTERS,
  value,
});

// удалить фильтры наград
export const clearBadgeFilters = () => ({
  type: CLEAR_BADGE_FILTERS,
});

// удалить фильтры награды/ид
export const clearBadgeInfoFilters = () => ({
  type: CLEAR_BADGE_INFO_FILTERS,
});

// сохранить фильтры награды/ид
export const saveBadgeInfoFilters = value => ({
  type: SAVE_BADGE_INFO_FILTERS,
  value,
});

// сохранить сортировку награды/ид
export const saveBadgeInfoSort = value => ({
  type: SAVE_BADGE_INFO_SORT,
  value,
});

// сохранить сортировку наград
export const saveBadgeSort = value => ({
  type: SAVE_BADGE_SORT,
  value,
});

// сохранить инактивную награду
export const addInactiveBadge = value => ({
  type: ADD_INACTIVE_BADGE,
  value,
});

// удалить инактивную награду
export const clearInactiveBadge = () => ({
  type: CLEAR_INACTIVE_BADGE,
});

// получить данные в таблицу наград
export const getBadges = (body, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/reward/table${getQueryString({ page, size: 20, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [GET_BADGES_START, GET_BADGES_SUCCESS, GET_BADGES_FAIL],
  onSuccess,
  onError,
});

// создать награду
export const createBadge = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/reward`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_BADGE_START, CREATE_BADGE_SUCCESS, CREATE_BADGE_FAIL],
  onSuccess,
  onError,
});

// обновить награду/ид
export const updateBadgeId = (badgeId, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/reward/${badgeId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [UPDATE_BADGE_START, UPDATE_BADGE_SUCCESS, UPDATE_BADGE_FAIL],
  onSuccess,
  onError,
});

// получить детально награду/ид
export const getBadgeId = (badgeId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/reward/${badgeId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BADGE_ID_START, GET_BADGE_ID_SUCCESS, GET_BADGE_ID_FAIL],
  onSuccess,
  onError,
});

// получение статусов наград
export const getStatusBadge = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/reward/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATUS_START, GET_STATUS_SUCCESS, GET_STATUS_FAIL],
  onSuccess,
  onError,
});

// изменить статус награды активный или неактивный
export const changeStatusBadgeId = (badgeId, active, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/reward/status/${badgeId}${getQueryString({ active })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [CHANGE_STATUS_BADGE_START, CHANGE_STATUS_BADGE_SUCCESS, CHANGE_STATUS_BADGE_FAIL],
  onSuccess,
  onError,
});

// назначить награды пользователям
export const postAppointBadge = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/reward/assign`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [APPOINTS_BADGE_START, APPOINTS_BADGE_SUCCESS, APPOINTS_BADGE_FAIL],
  onSuccess,
  onError,
});

// получение данных в таблицу Награда/ид
export const getAppointBadges = (rewardId, body, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/reward/recipient${getQueryString({ rewardId, page, sort, size: 20 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [GET_APPOINT_BADGE_START, GET_APPOINT_BADGE_SUCCESS, GET_APPOINT_BADGE_FAIL],
  onSuccess,
  onError,
});

// удаление награды
export const deleteBadgeId = (badgeId, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/reward/${badgeId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [DELETE_BADGE_START, DELETE_BADGE_SUCCESS, DELETE_BADGE_FAIL],
  onSuccess,
  onError,
});

// импорт файла
export const importBadgeUsers = (rewardId, blob, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/reward/import${getQueryString({ rewardId })}`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: blob,
  requestType: 'formData',
  types: [IMPORT_BADGE_USERS_START, IMPORT_BADGE_USERS_SUCCESS, IMPORT_BADGE_USERS_FAIL],
  onSuccess,
  onError,
});

// скачать пример для импорта
export const getExampleBadgeCSV = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/reward/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_EXAMPLE_BADGE_CSV_START, GET_EXAMPLE_BADGE_CSV_SUCCESS, GET_EXAMPLE_BADGE_CSV_FAIL],
  onSuccess,
  onError,
});

// получение типа выдачи
export const getIssueBadge = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/reward/add_type`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ISSUE_START, GET_ISSUE_SUCCESS, GET_ISSUE_FAIL],
  onSuccess,
  onError,
});

// получение списка наград
export const getListBadges = (body, size, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/reward/dictionary${getQueryString({ size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [GET_LIST_BADGE_START, GET_LIST_BADGE_SUCCESS, GET_LIST_BADGE_FAIL],
  onSuccess,
  onError,
});

// получить действия наград для присвоения
export const getActionsBadge = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/reward/action`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ACTIONS_BADGE_START, GET_ACTIONS_BADGE_SUCCESS, GET_ACTIONS_BADGE_FAIL],
  onSuccess,
  onError,
});

// Привязка наград к материалу
export const badgeBindTopic = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/reward/bind`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_BADGE_TOPIC_START, PUT_BADGE_TOPIC_SUCCESS, PUT_BADGE_TOPIC_FAIL],
  onSuccess,
  onError,
});

// удаление награды у пользователя (единичное)
export const deleteBadgeUser = (body, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/reward/remove/single`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [DELETE_USER_BADGE_START, DELETE_USER_BADGE_SUCCESS, DELETE_USER_BADGE_FAIL],
  onSuccess,
  onError,
});

// массовое удаление награды у пользователя (единичное)
export const deleteMassBadgeUser = (body, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/reward/remove/bulk`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [DELETE_MASS_USER_BADGE_START, DELETE_MASS_USER_BADGE_SUCCESS, DELETE_MASS_USER_BADGE_FAIL],
  onSuccess,
  onError,
});
