import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getAppointBadges, getBadgeId, getIssueBadge, saveBadgeInfoFilters } from '@/store/badge/actions';
import { getDepartment, getFunction } from '@/store/students/actions';

import { selectBadge } from '@/store/badge/selectors';

import { Button, Col, Layout, Row, Spin, Tooltip, Tour } from 'antd';
import { Content } from '@/components/Layout';
import ModalBadge from '@/components/ModalBadge';
import { CloseOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

import BadgeFilter from './Content/BadgeFilter';
import BadgeInfo from './Content/BadgeInfo';
import BadgeTable from './Content/BadgeTable';

import css from './BadgeDetail.module.scss';

const BadgeDetail = () => {
  const { t } = useTranslation('badgeDetail');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const { badgeId, isLoadingBadgeId } = useSelector(selectBadge);

  const [isModalBadge, setIsModalBadge] = useState({
    open: false,
    imageUuid: '',
  });

  useEffect(() => {
    return () => {
      dispatch(saveBadgeInfoFilters()); // чистим стор от фильтров
    };
  }, [dispatch]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getBadgeId(params?.id));
      dispatch(getAppointBadges(params?.id, {}, 0));
    }
  }, [params.id]);

  useEffect(() => {
    dispatch(getFunction());
    dispatch(getDepartment());
    dispatch(getIssueBadge());
  }, [dispatch]);

  const refSingle = useRef(null);
  const refImport = useRef(null);
  const refFilter = useRef(null);
  const refTable = useRef(null);
  const [openTour, setOpenTour] = useState(false);

  const steps = [
    {
      title: t('selectTourList'),
      description: t('selectTourListInfo'),
      target: () => refSingle.current,
      placement: 'left',
    },
    {
      title: t('importTour'),
      description: t('importTourInfo'),
      target: () => refImport.current,
      placement: 'left',
    },
    {
      title: t('filtersTour'),
      description: t('filtersTourInfo'),
      target: () => refFilter.current,
    },
    {
      title: t('tableTour'),
      description: t('tableTourInfo'),
      target: () => refTable.current,
    },
  ];

  const editBadge = () => {
    if (badgeId?.id) {
      setIsModalBadge({ open: true, imageUuid: badgeId.imageUuid });
      dispatch(getBadgeId(badgeId.id));
    }
  };

  const closePage = () => navigate('/badge', { replace: true });

  return (
    <Layout>
      <Content>
        <Spin spinning={isLoadingBadgeId} size='large'>
          <div className={css['BadgeDetail']}>
            <Row justify='space-between' className={css['BadgeDetail-header']}>
              <Col>
                <h1>{badgeId.name}</h1>
              </Col>
              <Col>
                <div className={css['BadgeDetail-header-actions']}>
                  <Tooltip title={t('infoTour')}>
                    <InfoCircleOutlined
                      onClick={() => setOpenTour(true)}
                      className={css['BadgeDetail-header-actions-info']}
                    />
                  </Tooltip>
                  <Tooltip title={t('edit')}>
                    <Button
                      className={css['BadgeDetail-header-actions-edit']}
                      onClick={editBadge}
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                  <Tooltip title={t('close')}>
                    <Button icon={<CloseOutlined />} onClick={closePage} />
                  </Tooltip>
                </div>
              </Col>
            </Row>

            <BadgeInfo refSingle={refSingle} refImport={refImport} />
            <div ref={refFilter}>
              <BadgeFilter />
            </div>
            <div ref={refTable}>
              <BadgeTable />
            </div>

            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
          </div>
        </Spin>

        {isModalBadge.open && (
          <ModalBadge
            open={isModalBadge.open}
            onCancel={() => setIsModalBadge({ open: false, imageUuid: '' })}
            isEdit
            badgePhotoUuid={isModalBadge.imageUuid}
            isBadgeDetail
          />
        )}
      </Content>
    </Layout>
  );
};

export default BadgeDetail;
