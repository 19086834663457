export const LOAD_POKA_TYPICAL_POSITIONS_START = 'LOAD_POKA_TYPICAL_POSITIONS_START';
export const LOAD_POKA_TYPICAL_POSITIONS_SUCCESS = 'LOAD_POKA_TYPICAL_POSITIONS_SUCCESS';
export const LOAD_POKA_TYPICAL_POSITIONS_FAIL = 'LOAD_POKA_TYPICAL_POSITIONS_FAIL';

export const ADD_POKA_TYPICAL_POSITION_START = 'ADD_POKA_TYPICAL_POSITION_START';
export const ADD_POKA_TYPICAL_POSITION_SUCCESS = 'ADD_POKA_TYPICAL_POSITION_SUCCESS';
export const ADD_POKA_TYPICAL_POSITION_FAIL = 'ADD_POKA_TYPICAL_POSITION_FAIL';

export const EDIT_POKA_TYPICAL_POSITION_START = 'EDIT_POKA_TYPICAL_POSITION_START';
export const EDIT_POKA_TYPICAL_POSITION_SUCCESS = 'EDIT_POKA_TYPICAL_POSITION_SUCCESS';
export const EDIT_POKA_TYPICAL_POSITION_FAIL = 'EDIT_POKA_TYPICAL_POSITION_FAIL';

export const DELETE_POKA_TYPICAL_POSITION_START = 'DELETE_POKA_TYPICAL_POSITION_START';
export const DELETE_POKA_TYPICAL_POSITION_SUCCESS = 'DELETE_POKA_TYPICAL_POSITION_SUCCESS';
export const DELETE_POKA_TYPICAL_POSITION_FAIL = 'DELETE_POKA_TYPICAL_POSITION_FAIL';

export const GET_TYPICAL_POSITIONS_LIST_START = 'GET_TYPICAL_POSITIONS_LIST_START';
export const GET_TYPICAL_POSITIONS_LIST_SUCCESS = 'GET_TYPICAL_POSITIONS_LIST_SUCCESS';
export const GET_TYPICAL_POSITIONS_LIST_FAIL = 'GET_TYPICAL_POSITIONS_LIST_FAIL';

export const SAVE_POKA_TYPICAL_POSITION_FILTERS = 'SAVE_POKA_TYPICAL_POSITION_FILTERS';

export const SAVE_POKA_TYPICAL_POSITION_SORT = 'SAVE_POKA_TYPICAL_POSITION_SORT';

export const GET_MISMATCH_START = 'GET_MISMATCH_START';
export const GET_MISMATCH_SUCCESS = 'GET_MISMATCH_SUCCESS';
export const GET_MISMATCH_FAIL = 'GET_MISMATCH_FAIL';

export const GET_POKA_POSITION_TEMPLATE_START = 'GET_POKA_POSITION_TEMPLATE_START';
export const GET_POSITION_TEMPLATE_SUCCESS = 'GET_POSITION_TEMPLATE_SUCCESS';
export const GET_POSITION_TEMPLATE_FAIL = 'GET_POSITION_TEMPLATE_FAIL';

export const IMPORT_POKA_POSITION_FILE_START = 'IMPORT_POKA_POSITION_FILE_START';
export const IMPORT_POKA_POSITION_FILE_SUCCESS = 'IMPORT_POKA_POSITION_FILE_SUCCESS';
export const IMPORT_POKA_POSITION_FILE_FAIL = 'IMPORT_POKA_POSITION_FILE_FAIL';

export const CLEAR_ERROR_TEXT_POSITION = 'CLEAR_ERROR_TEXT_POSITION';
