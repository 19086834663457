export const defaultToken = '';
export const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const GET_CERTIFICATE_START = 'GET_CERTIFICATE_START';
export const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS';
export const GET_CERTIFICATE_FAIL = 'GET_CERTIFICATE_FAIL';

export const CREATE_CERTIFICATE_START = 'CREATE_CERTIFICATE_START';
export const CREATE_CERTIFICATE_SUCCESS = 'CREATE_CERTIFICATE_SUCCESS';
export const CREATE_CERTIFICATE_FAIL = 'CREATE_CERTIFICATE_FAIL';

export const EDIT_CERTIFICATE_START = 'EDIT_CERTIFICATE_START';
export const EDIT_CERTIFICATE_SUCCESS = 'EDIT_CERTIFICATE_SUCCESS';
export const EDIT_CERTIFICATE_FAIL = 'EDIT_CERTIFICATE_FAIL';

export const DELETE_CERTIFICATE_START = 'DELETE_CERTIFICATE_START';
export const DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS';
export const DELETE_CERTIFICATE_FAIL = 'DELETE_CERTIFICATE_FAIL';

export const GET_TEMPLATES_START = 'GET_TEMPLATE_START';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATE_FAIL';

export const CREATE_TEMPLATE_START = 'CREATE_TEMPLATE_START';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAIL = 'CREATE_TEMPLATE_FAIL';

export const CLEAR_CERTIFICATES = 'CLEAR_CERTIFICATES';
