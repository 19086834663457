// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-M7NviV.admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-bwdALH .admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-label-qwHpu3[data-value="Montserrat"]:before, .admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-M7NviV.admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-bwdALH .admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-item-BJnbZB[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-Montserrat-v9Svwt {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters__caption-in2Edj {
  cursor: pointer;
  color: #00f;
}

.admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters-drawer-yMjF8Q {
  background-color: #fff !important;
}

.admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters-drawer-yMjF8Q .ant-drawer-header {
  padding: 16px 24px !important;
}

.admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters-drawer-yMjF8Q .ant-drawer-body {
  padding: 24px !important;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MassSelection/components/SavedFilters/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,eAAe;EACf,WAAW;AACb;;AAMA;EAHE,iCAAiC;AACnC;;AAKA;EAFE,6BAA6B;AAC/B;;AAIA;EADE,wBAAwB;AAC1B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.SavedFilters__caption {\n  cursor: pointer;\n  color: blue;\n}\n.SavedFilters-drawer {\n  background-color: #fff !important;\n}\n.SavedFilters-drawer :global .ant-drawer-header {\n  padding: 16px 24px !important;\n}\n.SavedFilters-drawer :global .ant-drawer-body {\n  padding: 24px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-M7NviV`,
	"qlPicker": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-M7NviV`,
	"ql-font": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-bwdALH`,
	"qlFont": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-bwdALH`,
	"ql-picker-label": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-label-qwHpu3`,
	"qlPickerLabel": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-label-qwHpu3`,
	"ql-picker-item": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-item-BJnbZB`,
	"qlPickerItem": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-picker-item-BJnbZB`,
	"ql-font-Montserrat": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-Montserrat-v9Svwt`,
	"qlFontMontserrat": `admin-src-components-MassSelection-components-SavedFilters-style-module__ql-font-Montserrat-v9Svwt`,
	"SavedFilters__caption": `admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters__caption-in2Edj`,
	"savedFiltersCaption": `admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters__caption-in2Edj`,
	"SavedFilters-drawer": `admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters-drawer-yMjF8Q`,
	"savedFiltersDrawer": `admin-src-components-MassSelection-components-SavedFilters-style-module__SavedFilters-drawer-yMjF8Q`
};
export default ___CSS_LOADER_EXPORT___;
