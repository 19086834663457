import {
  ADD_MASS_TRACK_FAIL,
  ADD_MASS_TRACK_START,
  ADD_MASS_TRACK_SUCCESS,
  ADD_TRACK_FAIL,
  ADD_TRACK_START,
  ADD_TRACK_SUCCESS,
  CHANGE_STATUS_TRACK_FAIL,
  CHANGE_STATUS_TRACK_START,
  CHANGE_STATUS_TRACK_SUCCESS,
  CLEAR_TRACK_DETAILS,
  CLEAR_TRACKS_APPOINT_FILTERS,
  CLEAR_TRACKS_FILTERS,
  CREATE_TRACK_FAIL,
  CREATE_TRACK_START,
  CREATE_TRACK_SUCCESS,
  DELETE_TRACK_FAIL,
  DELETE_TRACK_START,
  DELETE_TRACK_SUCCESS,
  DELETE_USER_TRACK_FAIL,
  DELETE_USER_TRACK_START,
  DELETE_USER_TRACK_SUCCESS,
  EXPORT_TRACK_USERS_FAIL,
  EXPORT_TRACK_USERS_START,
  EXPORT_TRACK_USERS_SUCCESS,
  EXPORT_USERS_MASS_TRACK_FAIL,
  EXPORT_USERS_MASS_TRACK_START,
  EXPORT_USERS_MASS_TRACK_SUCCESS,
  GET_CONSECUTIVE_FAIL,
  GET_CONSECUTIVE_START,
  GET_CONSECUTIVE_SUCCESS,
  GET_PROGRESS_TRACK_FAIL,
  GET_PROGRESS_TRACK_START,
  GET_PROGRESS_TRACK_SUCCESS,
  GET_STATUS_TRACK_FAIL,
  GET_STATUS_TRACK_START,
  GET_STATUS_TRACK_SUCCESS,
  GET_TRACK_COUNT_FAIL,
  GET_TRACK_COUNT_START,
  GET_TRACK_COUNT_SUCCESS,
  GET_TRACK_ID_FAIL,
  GET_TRACK_ID_START,
  GET_TRACK_ID_SUCCESS,
  GET_TRACK_STAT_USER_FAIL,
  GET_TRACK_STAT_USER_START,
  GET_TRACK_STAT_USER_SUCCESS,
  POST_APPOINT_FILTERS_FAIL,
  POST_APPOINT_FILTERS_START,
  POST_APPOINT_FILTERS_SUCCESS,
  POST_TRACKS_FILTERS_FAIL,
  POST_TRACKS_FILTERS_START,
  POST_TRACKS_FILTERS_SUCCESS,
  SAVE_TRACKS_APPOINT_FILTERS,
  SAVE_TRACKS_FILTERS,
  TRANSFER_USER_TRACK_FAIL,
  TRANSFER_USER_TRACK_START,
  TRANSFER_USER_TRACK_SUCCESS,
  UPDATE_CONSECUTIVE_FAIL,
  UPDATE_CONSECUTIVE_START,
  UPDATE_CONSECUTIVE_SUCCESS,
  UPDATE_TRACK_FAIL,
  UPDATE_TRACK_START,
  UPDATE_TRACK_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const saveTrackFilters = value => ({
  type: SAVE_TRACKS_FILTERS,
  value,
});

export const clearTrackFilters = () => ({
  type: CLEAR_TRACKS_FILTERS,
});

export const clearTrackDetails = () => ({
  type: CLEAR_TRACK_DETAILS,
});

export const saveTrackAppointFilters = value => ({
  type: SAVE_TRACKS_APPOINT_FILTERS,
  value,
});

export const clearTrackAppointFilters = () => ({
  type: CLEAR_TRACKS_APPOINT_FILTERS,
});

export const getConsecutive = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/consecutive`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CONSECUTIVE_START, GET_CONSECUTIVE_SUCCESS, GET_CONSECUTIVE_FAIL],
  onSuccess,
  onError,
});

export const postTracksFilters = (params, page, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/tracks${getQueryString({ size: 20, page })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_TRACKS_FILTERS_START, POST_TRACKS_FILTERS_SUCCESS, POST_TRACKS_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const createTrack = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/track`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CREATE_TRACK_START, CREATE_TRACK_SUCCESS, CREATE_TRACK_FAIL],
  onSuccess,
  onError,
});

export const updateTrack = (trackId, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/track/${trackId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [UPDATE_TRACK_START, UPDATE_TRACK_SUCCESS, UPDATE_TRACK_FAIL],
  onSuccess,
  onError,
});

export const deleteTrack = (trackId, processId, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/track/${trackId}${getQueryString({ processId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [DELETE_TRACK_START, DELETE_TRACK_SUCCESS, DELETE_TRACK_FAIL],
  onSuccess,
  onError,
});

export const changeStatusTrack = (id, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/track/status/update/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CHANGE_STATUS_TRACK_START, CHANGE_STATUS_TRACK_SUCCESS, CHANGE_STATUS_TRACK_FAIL],
  onSuccess,
  onError,
});

export const getTrackDetails = (trackId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/${trackId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TRACK_ID_START, GET_TRACK_ID_SUCCESS, GET_TRACK_ID_FAIL],
  onSuccess,
  onError,
});

export const updateConsecutive = (trackId, isConsecutive, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/track/${trackId}/consecutive${getQueryString({ isConsecutive })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [UPDATE_CONSECUTIVE_START, UPDATE_CONSECUTIVE_SUCCESS, UPDATE_CONSECUTIVE_FAIL],
  onSuccess,
  onError,
});

export const postAppointFilters = (trackId, params, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/topic/plan/participant/track/${trackId}${getQueryString({ size: 20, page, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_APPOINT_FILTERS_START, POST_APPOINT_FILTERS_SUCCESS, POST_APPOINT_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const trackAddToPlanMass = (trackId, params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/filter/add/track${getQueryString({ trackId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [ADD_MASS_TRACK_START, ADD_MASS_TRACK_SUCCESS, ADD_MASS_TRACK_FAIL],
  onSuccess,
  onError,
});

export const trackAddToPlan = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/plan/add/track`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [ADD_TRACK_START, ADD_TRACK_SUCCESS, ADD_TRACK_FAIL],
  onSuccess,
  onError,
});

export const getTrackCountUsers = (trackId, params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/count/users/track/${trackId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [GET_TRACK_COUNT_START, GET_TRACK_COUNT_SUCCESS, GET_TRACK_COUNT_FAIL],
  onSuccess,
  onError,
});

export const exportTrackUsers = (trackId, params, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/track/${trackId}/result/export`, // export table
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_TRACK_USERS_START, EXPORT_TRACK_USERS_SUCCESS, EXPORT_TRACK_USERS_FAIL],
  onSuccess,
});

export const deleteUserTrack = (params, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/user/topic/plan/remove/track`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [DELETE_USER_TRACK_START, DELETE_USER_TRACK_SUCCESS, DELETE_USER_TRACK_FAIL],
  onSuccess,
  onError,
});

export const transferUserTrack = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/admin/plan/reschedule/track`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [TRANSFER_USER_TRACK_START, TRANSFER_USER_TRACK_SUCCESS, TRANSFER_USER_TRACK_FAIL],
  onSuccess,
  onError,
});

export const exportMassUsersTrack = (trackId, params, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/export/users/track/${trackId}`, // экспорт массового назначения
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_USERS_MASS_TRACK_START, EXPORT_USERS_MASS_TRACK_SUCCESS, EXPORT_USERS_MASS_TRACK_FAIL],
  onSuccess,
});

export const getStatusTracks = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/study-plan-status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATUS_TRACK_START, GET_STATUS_TRACK_SUCCESS, GET_STATUS_TRACK_FAIL],
  onSuccess,
  onError,
});

export const getTrackProgress = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/mass/progress/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_PROGRESS_TRACK_START, GET_PROGRESS_TRACK_SUCCESS, GET_PROGRESS_TRACK_FAIL],
  onSuccess,
  onError,
});

export const getStatUser = (trackId, userId, page, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/${trackId}/statistic${getQueryString({ userId, page, size: 10 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TRACK_STAT_USER_START, GET_TRACK_STAT_USER_SUCCESS, GET_TRACK_STAT_USER_FAIL],
  onSuccess,
  onError,
});
