// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-vZBzTY.admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-APdoqe .admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-label-PQPcu5[data-value="Montserrat"]:before, .admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-vZBzTY.admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-APdoqe .admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-item-rIQRZm[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-Montserrat-tsDGcP {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-ManageCourse-components-EventSelectModal-module__eventSelect-body-EtodXQ {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 10px 16px;
}

.admin-src-pages-ManageCourse-components-EventSelectModal-module__header-oNdQlM {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  display: flex;
}

.admin-src-pages-ManageCourse-components-EventSelectModal-module__header-text-iBy8_u, .admin-src-pages-ManageCourse-components-EventSelectModal-module__header-year-JMv643 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}

.admin-src-pages-ManageCourse-components-EventSelectModal-module__header-arrows-x4SCfo {
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.admin-src-pages-ManageCourse-components-EventSelectModal-module__buttons-ycwEIf {
  justify-content: space-evenly;
  margin-top: 40px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/ManageCourse/components/EventSelectModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;AACzB;;AAQA;EALE,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAOA;EAJE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAcA;EAXE,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAcA;EAXE,6BAA6B;EAC7B,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.eventSelect-body {\n  background-color: #fff;\n  padding: 24px 10px 16px;\n  border-radius: 8px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  margin-bottom: 22px;\n  justify-content: space-between;\n}\n.header-text {\n  font-weight: 500;\n  line-height: 1.2;\n  font-size: 20px;\n  font-weight: bold;\n  color: black;\n}\n.header-year {\n  font-weight: 800;\n  line-height: 1.2;\n  font-size: 20px;\n  font-weight: bold;\n  color: black;\n}\n.header-arrows {\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-evenly;\n  margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-vZBzTY`,
	"qlPicker": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-vZBzTY`,
	"ql-font": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-APdoqe`,
	"qlFont": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-APdoqe`,
	"ql-picker-label": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-label-PQPcu5`,
	"qlPickerLabel": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-label-PQPcu5`,
	"ql-picker-item": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-item-rIQRZm`,
	"qlPickerItem": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-picker-item-rIQRZm`,
	"ql-font-Montserrat": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-Montserrat-tsDGcP`,
	"qlFontMontserrat": `admin-src-pages-ManageCourse-components-EventSelectModal-module__ql-font-Montserrat-tsDGcP`,
	"eventSelect-body": `admin-src-pages-ManageCourse-components-EventSelectModal-module__eventSelect-body-EtodXQ`,
	"eventSelectBody": `admin-src-pages-ManageCourse-components-EventSelectModal-module__eventSelect-body-EtodXQ`,
	"header": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-oNdQlM`,
	"header-text": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-text-iBy8_u`,
	"headerText": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-text-iBy8_u`,
	"header-year": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-year-JMv643`,
	"headerYear": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-year-JMv643`,
	"header-arrows": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-arrows-x4SCfo`,
	"headerArrows": `admin-src-pages-ManageCourse-components-EventSelectModal-module__header-arrows-x4SCfo`,
	"buttons": `admin-src-pages-ManageCourse-components-EventSelectModal-module__buttons-ycwEIf`
};
export default ___CSS_LOADER_EXPORT___;
