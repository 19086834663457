import React, { useState } from 'react';

import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

export const Search = props => {
  const [text, setText] = useState(props.text || '');

  const onReset = () => {
    props.onReset(props.column);
    props.fnClose();
  };

  const onSubmit = () => {
    props.onSubmit(props.column, text);
    props.fnClose();
  };

  return (
    <div style={{ padding: 10 }}>
      <Input
        placeholder='Введите название проекта...'
        defaultValue={props.text || ''}
        value={text}
        onChange={e => setText(e.target.value)}
        onPressEnter={onSubmit}
        allowClear
      />
      <Button
        type='primary'
        onClick={onSubmit}
        icon={<SearchOutlined />}
        size='small'
        style={{ marginTop: 10, marginRight: 10 }}
      >
        Поиск
      </Button>
      <Button onClick={onReset} size='small'>
        Сброс
      </Button>
    </div>
  );
};

Search.propTypes = {
  column: PropTypes.string.isRequired,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  fnClearFilters: PropTypes.func.isRequired,
  fnClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default Search;
