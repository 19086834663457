/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { approveExecutiveTask, uploadExecutiveReviewFile } from '@/store/executive/actions';

import { Menu, notification, Upload } from 'antd';

import { ExecutiveRejectCommentModal } from '../ExecutiveRejectCommentModal';
import ApproveModal from './components/ApproveModal';

import {
  isAnyCourseOrConference,
  isConference,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourse,
  isExecutiveTask,
  isSkills,
  isTestBooks,
} from '@shared/utils/topic-utils';

import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './ManageCourse.module.scss';

import {
  FEE_BASED_COURSE_REQUEST_UPDATE,
  FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN,
  TOPIC_EXECUTIVE_ANSWER_APPROVE,
  TOPIC_EXECUTIVE_ANSWER_COMMENT,
} from '@/constants/permissions';
import {
  APPROVED,
  APPROVED_BY_MANAGER,
  APPROVED_BY_USER,
  EXPIRED,
  FINISHED,
  IN_PROGRESS,
  ON_REGISTRATION,
  REJECTED,
  REJECTED_BY_MANAGER,
  REJECTED_BY_USER,
  SCHEDULED,
  WAITING,
} from '@/constants/statuses';

export class ActionButton extends Component {
  static propTypes = {
    addUserEventHandler: PropTypes.func,
    changeCoursesStatus: PropTypes.func,
    courses: PropTypes.object,
    deleteCourses: PropTypes.func,
    finishCourses: PropTypes.func,
    isAdmin: PropTypes.bool,
    status: PropTypes.string,
    toggleModalCalendars: PropTypes.func,
    toggleModalTest: PropTypes.func,
    typeTopicId: PropTypes.number,
    withTransfer: PropTypes.bool,
    showOrganized: PropTypes.bool,
    topicId: PropTypes.number,
    planId: PropTypes.number,
    onClose: PropTypes.func,
    topic: PropTypes.object,
    changeToComplitedSingle: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      planId: 0,
      visibleModalApprove: false,
      isCurrent:
        isCorpOrExtCourse(props.topic?.type?.id) ||
        isCorporateCourseWithoutApprove(props.topic?.type?.id) ||
        isConference(props.topic?.type?.id)
          ? props.courses?.isCurrent
          : props.courses?.plan?.isCurrent,
    };

    this.changeComplitedAdminStatus = props.t('notComplete');
  }

  isExecutiveTaskOrSkills = isExecutiveTask(this.props.typeTopicId) || isSkills(this.props.typeTopicId);

  showModalApprove = () => {
    this.setState({ visibleModalApprove: true });
    this.props.onClose();
  };

  approveExecutiveTask = (comment, rate) => {
    this.props.approveExecutiveTask(this.props.courses.id, this.props.courses.plan.user.id, comment, rate, '', () => {
      this.setState({ visibleModalApprove: false });
      this.props.updateTable();
    });
  };

  deleteCourses = () => {
    this.props.onClose();
    this.props.deleteCourses(this.props.courses);
  };

  changeCoursesStatusRejected = () => {
    this.props.onClose();
    this.props.changeCoursesStatus(REJECTED, this.props.courses);
  };

  finishCourses = () => {
    this.props.onClose();
    this.props.finishCourses(this.props.courses);
  };

  toggleModalCalendars = () => {
    this.props.onClose();
    this.props.toggleModalCalendars(this.props.courses);
  };

  toggleModalTest = () => {
    const userInfo = this.props.courses.plan.user || this.props.courses.plan;
    this.props.onClose();
    this.props.toggleModalTestResults(userInfo, this.props.courses);
  };

  addUserEventHandler = () => {
    this.props.onClose();
    this.props.addUserEventHandler(this.props.courses);
  };

  changeToComplitedSingle = () => {
    this.props.onClose();
    this.props.changeToComplitedSingle(this.props.courses);
  };

  changeCoursesStatusApproved = () => {
    this.props.onClose();
    this.props.changeCoursesStatus(APPROVED, this.props.courses);
  };

  changeCoursesStatusApprovedTrue = () => {
    this.props.onClose();
    this.props.changeCoursesStatus(APPROVED, this.props.courses, true);
  };

  changeCoursesNoStatus = () => {
    this.props.onClose();
    this.props.changeCoursesStatus('', this.props.courses, true);
  };

  changeCoursesStatusApprovedByUser = () => {
    this.props.onClose();
    this.props.changeCoursesStatus(APPROVED_BY_USER, this.props.courses);
  };

  changeCoursesStatusApprovedByManager = () => {
    this.props.onClose();
    this.props.changeCoursesStatus(APPROVED_BY_MANAGER, this.props.courses);
  };

  changeCoursesStatusRejectedByManager = () => {
    this.props.onClose();
    this.props.changeCoursesStatus(REJECTED_BY_MANAGER, this.props.courses);
  };

  renderForExecutiveTopic() {
    const { status, courses, withTransfer, typeTopicId, t, currentUser, topic } = this.props;
    const changeComplitedStatus = courses?.info?.nameAdmin === this.changeComplitedAdminStatus && (
      <Menu.Item>
        <a onClick={this.changeToComplitedSingle}>{t('compliteStudy')}</a>
      </Menu.Item>
    );

    const transfer = withTransfer &&
      status !== 'ON_REGISTRATION' &&
      !isConference(_get(this.props, 'courses.plan.topic.type.id')) &&
      this.state.isCurrent && (
        <Menu.Item>
          <a onClick={this.toggleModalCalendars}>{t('reschedule')}</a>
        </Menu.Item>
      );

    const { plan } = courses;

    const addToTest = plan?.showStudyResultsButton && (
      <Menu.Item>
        <a onClick={this.toggleModalTest}>
          {this.isExecutiveTaskOrSkills ? t('seeExecutiveResults') : t('seeResults')}
        </a>
      </Menu.Item>
    );

    const transferIfInprogress = status === 'IN_PROGRESS' && !topic?.type?.approved && !topic?.type?.withEvents;
    const deleteIfInprogress =
      (status === 'IN_PROGRESS' && !courses.plan.addedByAdmin && !topic?.type?.withEvents) ||
      isSkills(this.props.topic?.type.id);
    const deleteInSchedult =
      status === 'SCHEDULED' && (isExecutiveTask(this.props.topic?.type.id) || isSkills(this.props.topic?.type.id));

    const uploadReviewFile = info => {
      const formData = new FormData();
      formData.append('file', info.file);
      this.props.uploadExecutiveReviewFile(
        formData,
        this.props.courses.id,
        () => {
          notification.success({
            message: this.props.t('fileUploadedSuccessfully'),
            duration: 1.5,
            placement: 'bottomRight',
          });
        },
        () => {
          notification.error({
            message: this.props.t('fileUploadedError'),
            duration: 1.5,
            placement: 'bottomRight',
          });
        }
      );
    };

    if (status === 'WAITING') {
      return (
        <>
          <div className={css.Dropdown}>
            <ApproveModal
              open={this.state.visibleModalApprove}
              onCancel={() => this.setState({ visibleModalApprove: false })}
              onSubmit={(comment, rate) => this.approveExecutiveTask(comment, rate)}
            />
            <Menu>
              {!isSkills(typeTopicId) && (
                <>
                  <Menu.Item>
                    <a onClick={this.showModalApprove}>{t('executiveAcceptTask')}</a>
                  </Menu.Item>
                  <Menu.Item>
                    <Upload accept='.jpg,.jpeg,.png,.svg,.pdf' maxCount={1} customRequest={uploadReviewFile}>
                      <a onClick={() => this.props.onClose()}>{t('addFileToReview')}</a>
                    </Upload>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      onClick={
                        currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                          ? this.changeCoursesStatusRejected
                          : this.changeCoursesStatusRejectedByManager
                      }
                    >
                      {t('executiveDeclineTask')}
                    </a>
                  </Menu.Item>
                </>
              )}
              {isSkills(typeTopicId) && (
                <Menu.Item>
                  <Link to={`/validation/${topic.id}/${currentUser.id}/${plan.id}`}>{t('goToValidation')}</Link>
                </Menu.Item>
              )}
              <Menu.Item>
                <a onClick={this.toggleModalTest}>{t('seeExecutiveResults')}</a>
              </Menu.Item>
            </Menu>
          </div>
          <ExecutiveRejectCommentModal onCancel={this.cancelDeleteComment} onSubmit={this.submitDeleteComment} t={t} />
        </>
      );
    }

    if (status === 'REJECTED_BY_MANAGER') {
      return (
        <div className={css.Dropdown}>
          <Menu>
            <Menu.Item>
              <a onClick={this.toggleModalTest}>{t('seeExecutiveResults')}</a>
            </Menu.Item>
          </Menu>
        </div>
      );
    }

    if (status === 'SCHEDULED' || transferIfInprogress) {
      const deleteCheck = (!transferIfInprogress && isTestBooks(typeTopicId)) || deleteIfInprogress || deleteInSchedult;
      return (
        <div className={css.Dropdown}>
          <Menu>
            {transfer}
            {deleteCheck && this.state.isCurrent && (
              <Menu.Item>
                <a onClick={this.deleteCourses}>{t('delete')}</a>
              </Menu.Item>
            )}
          </Menu>
        </div>
      );
    }

    if (plan?.showStudyResultsButton && status === 'FINISHED') {
      return (
        <div className={css.Dropdown}>
          <Menu>
            {!isSkills(typeTopicId) && (
              <Menu.Item>
                <Upload accept='.jpg,.jpeg,.png,.svg,.pdf' maxCount={1} customRequest={uploadReviewFile}>
                  <a onClick={() => this.props.onClose()}>{t('addFileToReview')}</a>
                </Upload>
              </Menu.Item>
            )}
            {addToTest}
            {changeComplitedStatus}
          </Menu>
        </div>
      );
    }

    if (status === 'FINISHED') {
      return null;
    }

    return null;
  }

  render() {
    const { status, courses, withTransfer, typeTopicId, topic, currentUser, t } = this.props;

    if (this.isExecutiveTaskOrSkills) {
      return this.renderForExecutiveTopic();
    }
    const changeComplitedStatus = courses?.info?.nameAdmin === this.changeComplitedAdminStatus && (
      <Menu.Item>
        <a onClick={this.changeToComplitedSingle}>{t('compliteStudy')}</a>
      </Menu.Item>
    );

    const transfer = withTransfer &&
      status !== 'ON_REGISTRATION' &&
      !isConference(_get(this.props, 'courses.plan.topic.type.id')) &&
      this.state.isCurrent && (
        <Menu.Item>
          <a onClick={this.toggleModalCalendars}>{t('reschedule')}</a>
        </Menu.Item>
      );

    const isAddToEvent = isAnyCourseOrConference(typeTopicId);

    const addToEvent = ((isAddToEvent && (status === 'APPROVED' || status === 'REJECTED_BY_USER')) ||
      (isCorporateCourseWithoutApprove(typeTopicId) && status === 'PLANNED')) && (
      <>
        <Menu.Item>
          <a onClick={this.addUserEventHandler}>{t('addToEvent')}</a>
        </Menu.Item>
        {this.state.isCurrent && (
          <Menu.Item>
            <a onClick={this.deleteCourses}>{t('delete')}</a>
          </Menu.Item>
        )}
      </>
    );

    const { plan } = courses;

    const addToTest = plan.showStudyResultsButton && (
      <Menu.Item>
        <a onClick={this.toggleModalTest}>
          {this.isExecutiveTaskOrSkills ? t('seeExecutiveResults') : t('seeResults')}
        </a>
      </Menu.Item>
    );

    const transferIfInprogress = status === 'IN_PROGRESS' && !topic?.type?.approved && !topic?.type?.withEvents;

    const delInProgress = status === 'IN_PROGRESS' && !topic?.type?.withEvents && !courses.plan.addedByAdmin;

    if (typeTopicId === 8 && status !== 'PLANNED') {
      return (
        <div className={css.Dropdown}>
          <Menu>{addToTest}</Menu>
        </div>
      );
    }

    if (isCorporateCourseWithoutApprove(typeTopicId) && status === 'REJECTED_BY_USER') {
      return (
        <div className={css.Dropdown}>
          <Menu>
            {transfer}
            <Menu.Item>
              <a onClick={this.addUserEventHandler}>{t('addToEvent')}</a>
            </Menu.Item>
            {this.state.isCurrent && (
              <Menu.Item>
                <a onClick={this.deleteCourses}>{t('delete')}</a>
              </Menu.Item>
            )}
          </Menu>
        </div>
      );
    }

    if (typeTopicId === 8 && status === 'PLANNED' && this.state.isCurrent) {
      return (
        <>
          <div className={css.Dropdown}>
            <Menu>
              <Menu.Item>
                <a onClick={this.deleteCourses}>{t('delete')}</a>
              </Menu.Item>
            </Menu>
          </div>
        </>
      );
    }

    if (status === 'APPROVED_BY_MANAGER') {
      return (
        <div className={css.Dropdown}>
          <Menu>
            {currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE) && (
              <Menu.Item>
                <a
                  onClick={
                    currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                      ? this.changeCoursesStatusApproved
                      : this.changeCoursesStatusApprovedByManager
                  }
                >
                  {t('approve')}
                </a>
              </Menu.Item>
            )}
            {this.props.showOrganized && (
              <Menu.Item>
                <a onClick={this.changeCoursesStatusApprovedTrue}>{t('approveAndOrganize')}</a>
              </Menu.Item>
            )}
            {currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE) && (
              <Menu.Item>
                <a
                  onClick={
                    currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                      ? this.changeCoursesStatusRejected
                      : this.changeCoursesStatusRejectedByManager
                  }
                >
                  {t('deny')}
                </a>
              </Menu.Item>
            )}
            {this.state.isCurrent && (
              <Menu.Item>
                <a onClick={this.deleteCourses}>{t('delete')}</a>
              </Menu.Item>
            )}
            {transfer}
            {status !== WAITING && addToEvent}
            {addToTest}
          </Menu>
        </div>
      );
    }

    if (status === WAITING && this.isExecutiveTaskOrSkills) {
      return (
        <>
          <div className={css.Dropdown}>
            <Menu>
              {currentUser.authorities.includes(TOPIC_EXECUTIVE_ANSWER_APPROVE) && (
                <Menu.Item>
                  <a onClick={this.showModalApprove}>{t('executiveAcceptTask')}</a>
                </Menu.Item>
              )}
              {currentUser.authorities.includes(TOPIC_EXECUTIVE_ANSWER_COMMENT) && (
                <Menu.Item>
                  <a
                    onClick={
                      currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                        ? this.changeCoursesStatusRejected
                        : this.changeCoursesStatusRejectedByManager
                    }
                  >
                    {t('executiveDeclineTask')}
                  </a>
                </Menu.Item>
              )}
              <Menu.Item>
                <a onClick={this.toggleModalTest}>{t('seeExecutiveResults')}</a>
              </Menu.Item>
            </Menu>
          </div>
          <ExecutiveRejectCommentModal onCancel={this.cancelDeleteComment} onSubmit={this.submitDeleteComment} t={t} />
        </>
      );
    }

    if (status === WAITING && this.isExecutiveTaskOrSkills) {
      return (
        <div className={css.Dropdown}>
          <Menu>
            {currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE) && (
              <Menu.Item>
                <a
                  onClick={
                    currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                      ? this.changeCoursesStatusApproved
                      : this.changeCoursesStatusApprovedByManager
                  }
                >
                  {t('approve')}
                </a>
              </Menu.Item>
            )}
            {this.props.showOrganized && (
              <Menu.Item>
                <a onClick={this.changeCoursesStatusApprovedTrue}>{t('approveAndOrganize')}</a>
              </Menu.Item>
            )}
            {currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE) && (
              <Menu.Item>
                <a
                  onClick={
                    currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                      ? this.changeCoursesStatusRejected
                      : this.changeCoursesStatusRejectedByManager
                  }
                >
                  {t('deny')}
                </a>
              </Menu.Item>
            )}
            {this.state.isCurrent && (
              <Menu.Item>
                <a onClick={this.deleteCourses}>{t('delete')}</a>
              </Menu.Item>
            )}
            {transfer}
            {status !== WAITING && addToEvent}
            {addToTest}
          </Menu>
        </div>
      );
    }

    if (status === REJECTED_BY_MANAGER || status === REJECTED) {
      return (
        <div className={css.Dropdown}>
          <Menu>
            {currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE) && (
              <Menu.Item>
                <a
                  onClick={
                    currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                      ? this.changeCoursesStatusApproved
                      : this.changeCoursesStatusApprovedByManager
                  }
                >
                  {t('approve')}
                </a>
              </Menu.Item>
            )}
            {this.props.showOrganized && (
              <Menu.Item>
                <a onClick={this.changeCoursesStatusApprovedTrue}>{t('approveAndOrganize')}</a>
              </Menu.Item>
            )}
            {this.state.isCurrent && (
              <Menu.Item>
                <a onClick={this.deleteCourses}>{t('delete')}</a>
              </Menu.Item>
            )}
            {transfer}
            {addToEvent}
            {addToTest}
          </Menu>
        </div>
      );
    }

    if (status === APPROVED || (status === REJECTED_BY_USER && !isCorporateCourseWithoutApprove(typeTopicId))) {
      return (
        <div className={css.Dropdown}>
          <Menu>
            <Menu.Item>
              <a
                onClick={
                  currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_UPDATE_BY_ADMIN)
                    ? this.changeCoursesStatusRejected
                    : this.changeCoursesStatusRejectedByManager
                }
              >
                {t('deny')}
              </a>
            </Menu.Item>
            {this.props.showOrganized && (
              <Menu.Item>
                <a onClick={this.changeCoursesNoStatus}>{t('organize')}</a>
              </Menu.Item>
            )}
            {transfer}
            {addToEvent}
            {addToTest}
          </Menu>
        </div>
      );
    }

    if (status === ON_REGISTRATION) {
      return (
        <div className={css.Dropdown}>
          <Menu>
            <Menu.Item>
              <a onClick={this.changeCoursesStatusApprovedByUser}>{t('approveRegistration')}</a>
            </Menu.Item>
            {transfer}
          </Menu>
        </div>
      );
    }

    if (delInProgress) {
      return (
        <>
          <div className={css.Dropdown}>
            <Menu>
              {transfer}
              {addToTest}
              {this.state.isCurrent && (
                <Menu.Item>
                  <a onClick={this.deleteCourses}>{t('delete')}</a>
                </Menu.Item>
              )}
            </Menu>
          </div>
        </>
      );
    }

    if (status === SCHEDULED || transferIfInprogress) {
      return (
        <>
          <div className={css.Dropdown}>
            <Menu>
              {transfer}
              {addToTest}
              {!transferIfInprogress && isTestBooks(typeTopicId) && this.state.isCurrent && (
                <Menu.Item>
                  <a onClick={this.deleteCourses}>{t('delete')}</a>
                </Menu.Item>
              )}
            </Menu>
          </div>
        </>
      );
    }

    if (status === EXPIRED) {
      return (
        <div className={css.Dropdown}>
          <Menu>
            <Menu.Item>
              <a onClick={this.finishCourses}>{t('didntGo')}</a>
            </Menu.Item>
            {addToTest}
            {changeComplitedStatus}
          </Menu>
        </div>
      );
    }

    if (plan.showStudyResultsButton && (status === EXPIRED || status === IN_PROGRESS || status === FINISHED)) {
      return (
        <>
          <div className={css.Dropdown}>
            <Menu>
              {addToTest}
              {!isSkills(typeTopicId) && changeComplitedStatus}
            </Menu>
          </div>
        </>
      );
    }

    if (
      status === 'WAITING_BY_USER' ||
      status === 'APPROVED_BY_USER' ||
      status === 'IN_PROGRESS' ||
      status === 'EXPIRED' ||
      status === 'FINISHED'
    ) {
      return null;
    }

    if (withTransfer) {
      return (
        <div className={css.Dropdown}>
          <Menu>
            {transfer}
            {addToEvent}
          </Menu>
        </div>
      );
    }
  }
}

const mapStateToProps = null;

const mapActionsToProps = {
  approveExecutiveTask,
  uploadExecutiveReviewFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('actionButton')(ActionButton));
