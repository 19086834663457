import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getStringValue } from '@/store/students/actions';

import { selectFields } from '@/store/pcfields/selectors';
import { selectStudents } from '@/store/students/selectors';

import { DatePicker, Form, Input, InputNumber, Select, Switch, TimePicker } from 'antd';

import { FIELD_TYPES, TYPES } from '../constants';

import dayjs from 'dayjs';

import style from '../style.module.scss';

const Inputs = props => {
  const dispatch = useDispatch();

  const [isRange, setIsRange] = useState(false);

  const { stringValues } = useSelector(selectStudents);
  const { fields } = useSelector(selectFields);
  const { t } = useTranslation('MVFilters');

  const commonDatePickerProps = {
    getValueProps: value => ({ value: value ? dayjs(value, 'YYYY-MM-DD') : null }),
    getValueFromEvent: value => value.format('YYYY-MM-DD'),
  };

  const type = useMemo(() => {
    return fields.find(field => field.id === props.customFieldId)?.fieldType.type || '';
  }, [props.customFieldId, fields]);

  useEffect(() => {
    if (type === FIELD_TYPES.TYPE_STRING) {
      dispatch(getStringValue(props.customFieldId));
    }
  }, [props.customFieldId]);

  useEffect(() => {
    props.form.setFieldsValue({
      customFields: {
        [props.name]: {
          type: isRange ? TYPES.RANGE : TYPES[type],
          valueFrom: undefined,
          valueTo: undefined,
          valueConcrete: undefined,
        },
      },
    });
  }, [isRange, type]);

  switch (type) {
    case FIELD_TYPES.TYPE_DATE:
      return (
        <>
          {isRange ? (
            <div className={style['inputs__range']}>
              <Form.Item name={[props.name, 'valueFrom']} {...commonDatePickerProps}>
                <DatePicker className={style['inputs__element']} />
              </Form.Item>
              <Form.Item name={[props.name, 'valueTo']} {...commonDatePickerProps}>
                <DatePicker className={style['inputs__element']} />
              </Form.Item>
            </div>
          ) : (
            <Form.Item name={[props.name, 'valueConcrete']} {...commonDatePickerProps}>
              <DatePicker className={style['inputs__element']} />
            </Form.Item>
          )}
          <Form.Item layout='horizontal' label={t('customFieldRange')}>
            <Switch checked={isRange} onChange={setIsRange} />
          </Form.Item>
        </>
      );

    case FIELD_TYPES.TYPE_DATETIME:
      return (
        <>
          {isRange ? (
            <div className={style['inputs__range']}>
              <Form.Item name={[props.name, 'valueFrom']}>
                <DatePicker
                  showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                  className={style['inputs__element']}
                />
              </Form.Item>
              <Form.Item name={[props.name, 'valueTo']}>
                <DatePicker
                  showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                  className={style['inputs__element']}
                />
              </Form.Item>
            </div>
          ) : (
            <Form.Item name={[props.name, 'valueConcrete']}>
              <DatePicker
                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                className={style['inputs__element']}
              />
            </Form.Item>
          )}
          <Form.Item layout='horizontal' label={t('customFieldRange')}>
            <Switch checked={isRange} onChange={setIsRange} />
          </Form.Item>
        </>
      );

    case FIELD_TYPES.TYPE_NUMERIC:
      return (
        <Form.Item name={[props.name, 'valueConcrete']}>
          <InputNumber className={style['inputs__element']} placeholder={t('inputNumberPlaceholder')} />
        </Form.Item>
      );
    case FIELD_TYPES.TYPE_TIME:
      return (
        <Form.Item name={[props.name, 'valueConcrete']}>
          <TimePicker className={style['inputs__element']} />
        </Form.Item>
      );
    case FIELD_TYPES.TYPE_STRING:
      return (
        <Form.Item name={[props.name, 'valueConcrete']}>
          <Select
            className={style['inputs__element']}
            placeholder={t('selectPlaceholder')}
            options={stringValues.map(item => ({ label: item, value: item }))}
          />
        </Form.Item>
      );

    case FIELD_TYPES.TYPE_TEXT:
      return (
        <Form.Item name={[props.name, 'valueConcrete']}>
          <Input.TextArea placeholder={t('textAreaPlaceholder')} autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      );

    case FIELD_TYPES.TYPE_BOOLEAN:
      return (
        <Form.Item name={[props.name, 'valueConcrete']}>
          <Switch />
        </Form.Item>
      );
  }
};

export default memo(Inputs);
