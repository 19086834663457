/* eslint-disable */
import {
  CREATE_DEPARTMENT_FAIL,
  CREATE_DEPARTMENT_START,
  CREATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAIL,
  DELETE_DEPARTMENT_START,
  DELETE_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_FAIL,
  EDIT_DEPARTMENT_START,
  EDIT_DEPARTMENT_SUCCESS,
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_LIMIT_COST_FAIL,
  GET_DEPARTMENTS_LIMIT_COST_START,
  GET_DEPARTMENTS_LIMIT_COST_SUCCESS,
  GET_DEPARTMENTS_START,
  GET_DEPARTMENTS_SUCCESS,
  GET_GLOBAL_DEPARTMENTS_FAIL,
  GET_GLOBAL_DEPARTMENTS_START,
  GET_GLOBAL_DEPARTMENTS_SUCCESS,
  GET_TOTAL_DEPARTMENTS_LIMIT_COST_FAIL,
  GET_TOTAL_DEPARTMENTS_LIMIT_COST_START,
  GET_TOTAL_DEPARTMENTS_LIMIT_COST_SUCCESS,
} from './actions';

const initialState = {
  departments: null,
  departmentsTotal: null,
  departmentsPageSize: null,
  departmentsPageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  globalDepartments: {
    departments: null,
    departmentsTotal: null,
    departmentsPageSize: null,
    departmentsPageNumber: null,
    isLoading: false,
    error: false,
  },
  departmentCost: null,
  departmentsTotalCost: null,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_DEPARTMENTS_START:
      return {
        ...state,
        departments: null,
        departmentsTotal: null,
        departmentsPageSize: null,
        departmentsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: response,
        departmentsTotal: Number(headers.get('X-Total-Elements')) || 0,
        departmentsPageSize: Number(headers.get('X-Page-Size')) || 0,
        departmentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_DEPARTMENTS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_GLOBAL_DEPARTMENTS_START:
      return {
        ...state,
        globalDepartments: {
          departments: null,
          departmentsTotal: null,
          departmentsPageSize: null,
          departmentsPageNumber: null,
          isLoading: true,
          error: false,
        },
      };

    case GET_GLOBAL_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        globalDepartments: {
          departments: response,
          departmentsTotal: Number(headers.get('X-Total-Elements')) || 0,
          departmentsPageSize: Number(headers.get('X-Page-Size')) || 0,
          departmentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoading: false,
        },
      };

    case GET_GLOBAL_DEPARTMENTS_FAIL:
      return {
        ...state,
        globalDepartments: { ...state.global, error: true, isLoading: false },
      };

    case CREATE_DEPARTMENT_START:
      return { ...state, isLoading: true, creationError: false };

    case CREATE_DEPARTMENT_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_DEPARTMENT_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_DEPARTMENT_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_DEPARTMENT_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_DEPARTMENT_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_DEPARTMENT_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_DEPARTMENT_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_DEPARTMENT_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    case GET_DEPARTMENTS_LIMIT_COST_FAIL:
      return { ...state, departmentCost: 0, isLoading: false };

    case GET_DEPARTMENTS_LIMIT_COST_START:
      return { ...state, isLoading: true };

    case GET_DEPARTMENTS_LIMIT_COST_SUCCESS:
      return { ...state, departmentCost: response?.limitCost, isLoading: false };

    case GET_TOTAL_DEPARTMENTS_LIMIT_COST_FAIL:
      return { ...state, departmentsTotalCost: 0, isLoading: false };

    case GET_TOTAL_DEPARTMENTS_LIMIT_COST_START:
      return { ...state, isLoading: true };

    case GET_TOTAL_DEPARTMENTS_LIMIT_COST_SUCCESS:
      return { ...state, departmentsTotalCost: response?.limitCost, isLoading: false };

    default:
      return state;
  }
};
