/* eslint-disable */
import React from 'react';

import { Layout } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Layout.module.scss';

function CustomLayout({ className, children, ...props }) {
  return (
    <Layout className={classNames(css.Layout, className)} {...props}>
      {children}
    </Layout>
  );
}

CustomLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default CustomLayout;
