import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Select } from 'antd';

import _ from 'lodash';
import PropTypes from 'prop-types';

const { Option } = Select;
const Roles = ['manager', 'colleague', 'subordinate', 'others'];

export const RoleWindow = props => {
  const [role, setRole] = useState(Roles[0]);
  const [disabledOk, setDisabledOk] = useState(true);
  const { t } = useTranslation('D360_Finder');

  useEffect(() => {
    _.isNil(role) ? setDisabledOk(true) : setDisabledOk(false);
  }, [role]);

  const handleCancel = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const handleOK = () => {
    if (typeof props.onOk === 'function') {
      props.onOk(role);
    }
  };

  const footer = (
    <div>
      <Button.Group>
        <Button type='default' onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <Button type='primary' onClick={handleOK} disabled={disabledOk}>
          {t('AddWithRoleButton')}
        </Button>
      </Button.Group>
    </div>
  );

  return (
    <Modal open={props.open} title={t('manualAssignTitle')} onCancel={props.onCancel} onOk={props.onOk} footer={footer}>
      <Select defaultValue={Roles[0]} onSelect={e => setRole(e)} style={{ width: '100%' }}>
        {Roles.map((v, i) => {
          return (
            <Option value={v} key={`role_${i}`}>
              {t(v)}
            </Option>
          );
        })}
      </Select>
    </Modal>
  );
};

RoleWindow.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
