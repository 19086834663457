import {
  POST_EXISTING_MATERIAL_FAIL,
  POST_EXISTING_MATERIAL_START,
  POST_EXISTING_MATERIAL_SUCCESS,
  PUT_EXISTING_MATERIAL_FAIL,
  PUT_EXISTING_MATERIAL_START,
  PUT_EXISTING_MATERIAL_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { POST, PUT, REST_API } from '@shared/constants/rest-api';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const setExistingMaterial = (blockId, topicId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${blockId}/existingMaterial`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    existingTopicId: topicId,
  },
  types: [POST_EXISTING_MATERIAL_START, POST_EXISTING_MATERIAL_SUCCESS, POST_EXISTING_MATERIAL_FAIL],
  onSuccess,
  onError,
});

export const updateExistingMaterial = (blockId, topicId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/existingMaterial`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    existingTopicId: topicId,
  },
  types: [PUT_EXISTING_MATERIAL_START, PUT_EXISTING_MATERIAL_SUCCESS, PUT_EXISTING_MATERIAL_FAIL],
  onSuccess,
  onError,
});
