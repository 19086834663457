/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_USER_INCOME_START = 'GET_USER_INCOME_START';
export const GET_USER_INCOME_SUCCESS = 'GET_USER_INCOME_SUCCESS';
export const GET_USER_INCOME_FAIL = 'GET_USER_INCOME_FAIL';
export const PUT_USER_WRITEOFFCOIN_START = 'PUT_USER_WRITEOFFCOIN_START';
export const PUT_USER_WRITEOFFCOIN_SUCCESS = 'PUT_USER_WRITEOFFCOIN_SUCCESS';
export const PUT_USER_WRITEOFFCOIN_FAIL = 'PUT_USER_WRITEOFFCOIN_FAIL';
export const GET_TOPIC_INCOME_START = 'GET_TOPIC_INCOME_START';
export const GET_TOPIC_INCOME_SUCCESS = 'GET_TOPIC_INCOME_SUCCESS';
export const GET_TOPIC_INCOME_FAIL = 'GET_TOPIC_INCOME_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getUserIncome = (userId = '', page = '', topicId, sort = 'date,desc') => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/income${getQueryString({ sort, userId, page, topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_INCOME_START, GET_USER_INCOME_SUCCESS, GET_USER_INCOME_FAIL],
});

export const getUserIncome2 = ({
  userId,
  size = 1,
  sort = 'id,desc',
  topicId,
  incomeType = '',
  bonus = '',
  onSuccess,
}) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/income${getQueryString({
    sort,
    userId,
    topicId,
    size,
    bonus,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  incomeType,
  redirectTo: '',
  onSuccess,
  types: [GET_USER_INCOME_START, GET_USER_INCOME_SUCCESS, GET_USER_INCOME_FAIL],
});

export const getTopicIncome = ({ sort = 'id,desc', topicId, bonus = '', onSuccess }) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/income${getQueryString({ sort, topicId, bonus })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  // incomeType,
  redirectTo: '',
  onSuccess,
  types: [GET_TOPIC_INCOME_START, GET_TOPIC_INCOME_SUCCESS, GET_TOPIC_INCOME_FAIL],
});

export const writeOffCoin = (userId, qty, comment, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/income/`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    comment,
    qty,
    userId,
  },
  types: [PUT_USER_WRITEOFFCOIN_START, PUT_USER_WRITEOFFCOIN_SUCCESS, PUT_USER_WRITEOFFCOIN_FAIL],
  onSuccess,
  onError,
});
