import {
  CREATE_POST_FAIL,
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  DELETE_POST_FAIL,
  DELETE_POST_START,
  DELETE_POST_SUCCESS,
  EDIT_POST_FAIL,
  EDIT_POST_START,
  EDIT_POST_SUCCESS,
  GET_CONVENTUS_FAIL,
  GET_CONVENTUS_START,
  GET_CONVENTUS_SUCCESS,
  GET_POSTS_FAIL,
  GET_POSTS_START,
  GET_POSTS_SUCCESS,
} from './actions';

const initialState = {
  posts: null,
  conventusRoles: null,
  postsTotal: null,
  postsPageSize: null,
  postsPageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
}; /* eslint-disable */ /* eslint-disable */

/* eslint-disable */ export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_POSTS_START:
      return {
        ...state,
        posts: null,
        postsTotal: null,
        postsPageSize: null,
        postsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_POSTS_SUCCESS:
      return {
        ...state,
        posts: response,
        postsTotal: Number(headers.get('X-Total-Elements')) || 0,
        postsPageSize: Number(headers.get('X-Page-Size')) || 0,
        postsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_POSTS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_CONVENTUS_START:
      return {
        ...state,
        conventusRoles: null,
        isLoading: true,
        error: false,
      };

    case GET_CONVENTUS_SUCCESS:
      return {
        ...state,
        conventusRoles: response,
        isLoading: false,
      };

    case GET_CONVENTUS_FAIL:
      return { ...state, error: true, isLoading: false };

    case CREATE_POST_START:
      return { ...state, isLoading: true, creationError: false };

    case CREATE_POST_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_POST_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_POST_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_POST_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_POST_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_POST_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_POST_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_POST_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    default:
      return state;
  }
};
