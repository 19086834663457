import {
  DELETE_GROUP_FAIL,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
  GET_GROUPS_FAIL,
  GET_GROUPS_START,
  GET_GROUPS_SUCCESS,
  POST_GROUPS_FAIL,
  POST_GROUPS_START,
  POST_GROUPS_SUCCESS,
  PUT_GROUPS_FAIL,
  PUT_GROUPS_START,
  PUT_GROUPS_SUCCESS,
  SAVE_GROUPS_FILTERS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// получение групп компетенций
export const getGroups = (name, page, size, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence/group${getQueryString({ name, page, size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GROUPS_START, GET_GROUPS_SUCCESS, GET_GROUPS_FAIL],
  onSuccess,
  onError,
});

// создание групп компетенций
export const createGroups = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/competence/group`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [POST_GROUPS_START, POST_GROUPS_SUCCESS, POST_GROUPS_FAIL],
  onSuccess,
  onError,
});

// обновление групп компетенций
export const updateGroup = (id, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/competence/group/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_GROUPS_START, PUT_GROUPS_SUCCESS, PUT_GROUPS_FAIL],
  onSuccess,
  onError,
});

// удаление групп компетенций
export const deleteGroup = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/competence/group/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [DELETE_GROUP_START, DELETE_GROUP_SUCCESS, DELETE_GROUP_FAIL],
  onSuccess,
  onError,
});

// сохранение фильтров групп компетенций
export const saveGroupFilters = value => ({
  type: SAVE_GROUPS_FILTERS,
  value,
});
