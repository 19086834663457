import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

import { MAX_VALUE_COST } from '@/constants/global';

const formatterRegex = /\B(?=(\d{3})+(?!\d))/g;

const CurrencyInput = ({ name, onChange, className, maxLength, ...props }) => {
  const { t } = useTranslation('currencyInput');

  const [value, setValue] = React.useState(props.value ?? null);

  const handleChange = value => handleChangeValue(value);
  const handleBlur = () => handleChangeValue(value ?? 0);

  const handleChangeValue = value => {
    onChange({ value, name });
    setValue(value);
  };

  const formatter = value => `${value} ${t('currencyRub')}`.replace(formatterRegex, ' ');

  return (
    <InputNumber
      value={value}
      onChange={handleChange}
      formatter={formatter}
      defaultValue={0}
      min={0}
      onBlur={handleBlur}
      className={classNames(styles['currency-input'], className)}
      name
      maxLength={maxLength + 8}
      max={MAX_VALUE_COST}
    />
  );
};

CurrencyInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};

export default CurrencyInput;
