/* eslint-disable */
import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';

import { Avatar, Button } from 'antd';

import Checkbox from '../Checkbox';
import Modal from '../Modal';

import { COVER_IMAGE_TYPES } from '@shared/constants/image-types';

import classNames from 'classnames';
import { FastAverageColor } from 'fast-average-color';
import PropTypes from 'prop-types';

import css2 from '../FileInput/FileInput.module.scss';
import css from './ImageInput.module.scss';

import 'react-image-crop/dist/ReactCrop.css';

import { withTranslation } from 'react-i18next';

import { CloseOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import ImageModal from './ImageModal';

import _ from 'lodash';

import galleryImage from '@/assets/images/gallery.png';

const defaultAspectWidth = 17;
const defaultAspectHeight = 9;

class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: props.imageSrc || '',
      nodeImg: null,
      previewImage: null,
      isOpen: false,
      isAspect: true,
      imageUrl: null,
      crop: {
        x: 10,
        y: 10,
        aspect: props.aspectWidth / props.aspectHeight || defaultAspectWidth / defaultAspectHeight,
        width: 45,
      },
      newImg: null,
      maskClosable: true,
      palette: [],
      error: false,
      croppedImageUrl: null,
      sizes: {},
      image: '',
      imageModalVisible: false,
      emptyImageName: false,
      fileName: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageSrc && this.props.imageSrc !== prevProps.imageSrc) {
      const imageSrc = _.isString(this.props.imageSrc)
        ? this.props.imageSrc
        : window.URL.createObjectURL(this.props.imageSrc);

      this.setState({ imageSrc });
    }
    if (
      this.props.isLoadingFileImage !== prevProps.isLoadingFileImage &&
      !this.props.isLoadingFileImage &&
      this.state.croppedImageUrl
    ) {
      const imageSrc = window.URL.createObjectURL(this.state.croppedImageUrl);

      this.setState({
        imageSrc,
        isOpen: false,
        croppedImageUrl: null,
        isAspect: true,
        crop: {
          x: 10,
          y: 10,
          aspect: this.props.aspectWidth / this.props.aspectHeight || defaultAspectWidth / defaultAspectHeight,
          width: 45,
        },
        sizes: {},
        modalError: false,
      });
    }
  }

  setInputRef = input => {
    const a = COVER_IMAGE_TYPES.join(', ');
    input && input.setAttribute('accept', a);
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  handleFileChange = event => {
    event.preventDefault();
    const file = event.target.files[0];
    this.getImgSize(window.URL.createObjectURL(event.target.files[0]));
    if (file) {
      const fileName = file.name.trim();

      if (!COVER_IMAGE_TYPES.includes(file.type) || file.size > 10000000) {
        this.setState({ modalError: true, isAspect: true, emptyImageName: true });
      } else {
        this.setState(
          {
            isAspect: true,
            image: window.URL.createObjectURL(event.target.files[0]),
            imageUrl: event.target.files[0],
            defFile: file,
            isOpen: !this.props.noCrop,
            fileName,
          },
          () => {
            if (this.props.noCrop) {
              this.uploadFile();
            }
          }
        );
      }
    }
    event.target.value = null;
  };

  onCancel = () => {
    this.hideConfirmDialog();
    this.openFileDialog();
  };

  hideConfirmDialog = () => {
    this.setState({ modalError: false, emptyImageName: false });
  };

  getImgSize = img => {
    const i = new Image();
    i.src = img;
    const that = this;
    i.onload = function () {
      that.setState({
        crop: {
          ...that.state.crop,
          height: (that.state.crop.width / that.state.crop.aspect) * (i.width / i.height),
          width:
            (that.state.crop.width / that.state.crop.aspect) *
            (i.width / i.height) *
            (that.state.crop.aspect / (i.width / i.height)),
        },
        sizes: {
          naturalWidth: i.width,
          naturalHeight: i.height,
        },
      });
    };
  };

  onChangeCrop = crop => {
    if (crop.width < 40 && this.state.isAspect) {
      this.setState({ maskClosable: false });
      return;
    }
    this.setState({ maskClosable: false, crop });
  };

  onCropComplete = async image => {
    const croppedImageUrl = await this.getCroppedImg(this.state.nodeImg || image);
    croppedImageUrl && this.setState({ croppedImageUrl });
    setTimeout(() => {
      this.setState({ maskClosable: true });
    }, 200);
  };

  handleFileDelete = () => {
    const { onChange, name } = this.props;
    this.setState({ imageSrc: '' });
    onChange(null, name);
  };

  onImageLoaded = async image => {
    this.setState({ nodeImg: image });
    await this.onCropComplete(image);
  };

  getCroppedImg = image => {
    if (image && (this.state.sizes.naturalWidth || image.naturalWidth)) {
      const canvas = document.createElement('canvas');
      const pixelCrop = this.state.crop;

      if (!pixelCrop.height) {
        const defaultHeight =
          (this.state.crop.width / this.state.crop.aspect) * (image.naturalWidth / image.naturalHeight);
        pixelCrop.height = defaultHeight;
      }

      canvas.width = Math.round(((this.state.sizes.naturalWidth ?? image.naturalWidth) * pixelCrop.width) / 100);
      canvas.height = Math.round(((this.state.sizes.naturalHeight ?? image.naturalHeight) * pixelCrop.height) / 100);
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        ((this.state.sizes.naturalWidth ?? image.naturalWidth) * pixelCrop.x) / 100,
        ((this.state.sizes.naturalHeight ?? image.naturalHeight) * pixelCrop.y) / 100,
        ((this.state.sizes.naturalWidth ?? image.naturalWidth) * pixelCrop.width) / 100,
        ((this.state.sizes.naturalHeight ?? image.naturalHeight) * pixelCrop.height) / 100,
        0,
        0,
        ((this.state.sizes.naturalWidth ?? image.naturalWidth) * pixelCrop.width) / 100,
        ((this.state.sizes.naturalHeight ?? image.naturalHeight) * pixelCrop.height) / 100
      );

      const base64 = canvas.toDataURL('image/jpeg');

      const blobBin = atob(base64.split(',')[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

      this.getPalette(base64);

      return new Promise(resolve => resolve(file));
    }
  };

  uploadFile = () => {
    const { onChange, name } = this.props;
    const img = (this.props.noCrop && this.state.imageUrl) || this.state.croppedImageUrl;
    const formData = new FormData();
    formData.append('file', img, this.state.fileName);
    onChange(formData, name, this.state.defFile, img, this.state.color);

    this.setState({
      isOpen: false,
      isAspect: false,
      fileName: '',
      crop: {
        x: 10,
        y: 10,
        aspect: this.props.aspectWidth / this.props.aspectHeight || defaultAspectWidth / defaultAspectHeight,
        width: 45,
      },
    });
  };

  resetModal = () =>
    this.setState({
      previewImage: null,
      isOpen: !this.state.isOpen,
      imageUrl: null,
      croppedImageUrl: null,
      newImg: null,
      color: null,
      error: false,
      isAspect: true,
      crop: {
        x: 10,
        y: 10,
        aspect: this.props.aspectWidth / this.props.aspectHeight || defaultAspectWidth / defaultAspectHeight,
        width: 45,
      },
      sizes: {},
    });

  handleChange = status => {
    this.setState({
      isAspect: !(status && status.value),
      crop: {
        ...this.state.crop,
        x: 10,
        y: 10,
        aspect: !(status && status.value)
          ? this.props.aspectWidth / this.props.aspectHeight || defaultAspectWidth / defaultAspectHeight
          : null,
        width: 45,
        height: !(status && status.value)
          ? ((((this.state.sizes.naturalWidth * 0.45) / this.props.aspectWidth || defaultAspectWidth) *
              this.props.aspectHeight || defaultAspectHeight) /
              this.state.sizes.naturalHeight) *
            100
          : this.state.crop.height,
      },
    });
  };

  getPalette = imageSrc => {
    if (!imageSrc) {
      return;
    }
    const canvas = document.createElement('canvas');
    const pixelCrop = this.state.crop;
    canvas.height = 30;
    canvas.width = 30;
    const ctx = canvas.getContext('2d');

    const sourceImage = new Image();
    sourceImage.width = (this.state.sizes.naturalWidth * pixelCrop.width) / 100;
    sourceImage.height = (this.state.sizes.naturalHeight * pixelCrop.height) / 100;
    sourceImage.src = imageSrc;
    sourceImage.onload = () => {
      ctx.drawImage(
        sourceImage,
        0,
        sourceImage.height * 0.4,
        100,
        (this.state.sizes.naturalHeight * pixelCrop.height) / 100,
        0,
        0,
        sourceImage.width,
        sourceImage.height
      );
      setTimeout(() => {
        new FastAverageColor()
          .getColorAsync(canvas)
          .then(color => {
            this.setState({
              color: color.hex,
            });
          })
          .catch(e => {
            this.setState({ color: null });
            //console.error(e);
          });
      }, 1000);
    };
  };

  openImageModal = () => {
    this.setState({
      scrollX: window.scrollX,
      scrollY: window.scrollY,
      imageModalVisible: true,
    });
  };

  closeImageModal = () => {
    this.setState({ imageModalVisible: false });
    scrollTo(this.state.scrollX, this.state.scrollY);
  };

  render() {
    const { className, dataQa, t, isQuestion } = this.props;
    const { imageSrc } = this.state;
    const content = imageSrc ? (
      <div
        className={classNames(css['ImageInput-content'], {
          [css.reverse]: this.props.reverse,
          [css.short]: this.props.short,
        })}
      >
        <div className={css['ImageInput-block']}>
          <Avatar className={css['ImageInput-avatar']} src={this.state.imageSrc} />

          {/* если это раздел тестов в топике */}
          {isQuestion ? (
            <>
              <EyeOutlined className={css['ImageInput-avatar-delete']} onClick={this.openImageModal} />
              {!this.props.disabled && (
                <DeleteOutlined className={css['ImageInput-avatar-delete-trash']} onClick={this.handleFileDelete} />
              )}
            </>
          ) : (
            !this.props.disabled && (
              <CloseOutlined className={css['ImageInput-avatar-delete']} onClick={this.handleFileDelete} />
            )
          )}
        </div>
        <Button
          className={classNames({ [css.modificate]: this.props.modificate })}
          htmlFor='cover-img-file-input'
          onClick={this.openFileDialog}
          disabled={this.props.isLoadingFile || this.props.isLoadingFileImage || this.props.disabled}
        >
          {t('edit')}
        </Button>
      </div>
    ) : (
      <div
        className={classNames(css['ImageInput-content'], {
          [css.reverse]: this.props.reverse,
          [css.short]: this.props.short,
        })}
      >
        {!this.props.company && (
          <div className={css['ImageInput-comment']}>
            {t('formats')} <br />
            {t('recommended')}
          </div>
        )}
        <Button
          className={classNames({ [css.modificate]: this.props.modificate, [css.error]: this.props.errorStatus })}
          disabled={this.props.isLoadingFile || this.props.isLoadingFileImage || this.props.disabled}
          htmlFor='cover-img-file-input'
          onClick={this.openFileDialog}
        >
          {this.props.label ? this.props.label : this.props.company ? t('choose') : t('chooseCover')}
        </Button>
      </div>
    );

    const imageModal = imageSrc ? (
      <ImageModal visible={this.state.imageModalVisible} imageSrc={imageSrc} onCancel={this.closeImageModal} />
    ) : null;

    const errorModalContent = this.state.emptyImageName ? (
      <>
        <div className={css2['App-card-label']}>{this.props.t('cover')}</div>
        <div className={css2['App-card-info']}>
          <img src={galleryImage} alt='' />
          <div className={css2['App-card-info-item']}>
            <div className={css2['App-card-info-item-block']}>
              <h2>{this.props.t('images')}</h2>
              <div className={css2['App-card-info-little']}>{this.props.t('max')}</div>
            </div>
            <h3>{this.props.t('imagesTypes')}</h3>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className={css2['App-card-label']}>{this.props.company ? t('types') : t('cover')}</div>
        <div className={css2['App-card-info']}>
          <img src={galleryImage} alt='' />
          <div className={css2['App-card-info-item']}>
            <div className={css2['App-card-info-item-block']}>
              <h2>{t('images')}</h2>
              <div className={css2['App-card-info-little']}>{t('max')}</div>
            </div>
            <h3>{this.props.company ? 'svg, jpg, png, gif' : 'jpg, png, gif'}</h3>
          </div>
        </div>
      </>
    );

    return (
      <div className={classNames(css.ImageInput, className)}>
        {content}
        <input
          type='file'
          // Можно
          // выбрать
          // следующие
          // типы
          // файлов
          data-qa={dataQa}
          id={this.props.id || 'cover-img-file-input'}
          ref={this.setInputRef}
          onChange={this.handleFileChange}
        />
        <Modal
          open={this.state.isOpen}
          onCancel={this.resetModal}
          closableControl
          maskClosable={this.state.maskClosable}
          hideFooter
          headerFixToModal
        >
          <div className={css['modal-content--box']}>
            <div className={css['modal-content__title']}>{t('loading')}</div>
            <ReactCrop
              src={this.state.image}
              crop={this.state.crop}
              onChange={this.onChangeCrop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              minWidth={40}
              {...(!this.state.isAspect && {
                minWidth: 5,
                minHeight: 5,
              })}
              className={css.ReactCrop}
              keepSelection
            />
            <p className={css['modal-content__note']}>
              {this.state.error ? <div className={css['modal-content__note--error']}>{this.state.error}</div> : ''}
            </p>
            <div>
              {this.props.isAspectOption && (
                <Checkbox
                  onChange={this.handleChange}
                  name='aspect'
                  value={!this.state.isAspect}
                  label={t('ratio')}
                  big
                />
              )}
            </div>
            <div className={css['modal-content__footer']}>
              <Button data-qa={`${dataQa}-saveBtn`} disabled={this.props.isLoadingFileImage} onClick={this.uploadFile}>
                {t('save')}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          modalCategory
          fileModal
          open={this.state.modalError}
          onOk={this.onCancel}
          onCancel={this.hideConfirmDialog}
          okText={t('add')}
        >
          {errorModalContent}
        </Modal>

        {imageModal}
      </div>
    );
  }
}

ImageInput.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  fileInfo: PropTypes.object,
  imageSrc: PropTypes.any,
  isAspectOption: PropTypes.bool,
  isLoadingFile: PropTypes.bool,
  isLoadingFileImage: PropTypes.bool,
  loadAvatarUser: PropTypes.func,
  modificate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  reverse: PropTypes.bool,
  company: PropTypes.bool,
  user: PropTypes.object,
  id: PropTypes.string,
  noCrop: PropTypes.bool,
  short: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  aspectWidth: PropTypes.number,
  aspectHeight: PropTypes.number,
  errorStatus: PropTypes.bool,
};

export default withTranslation('imageInput')(ImageInput);
