import { GET_IMPORTUSERS_FAIL, GET_IMPORTUSERS_START, GET_IMPORTUSERS_SUCCESS } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

// получаем файл юзеров
export const getImportUsers = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/service/file/export/user-load`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_IMPORTUSERS_START, GET_IMPORTUSERS_SUCCESS, GET_IMPORTUSERS_FAIL],
  onSuccess,
  onError,
});
