import React from 'react';

import { Tag } from 'antd';

const BlockStatusTag = ({ record }) => {
  switch (record?.blockStatus) {
    case 'NOT_STARTED':
      return <Tag color='red'>{record.blockStatusName}</Tag>;

    case 'FINISHED':
      return <Tag color='green'>{record.blockStatusName}</Tag>;

    case 'WAITING':
      return <Tag color='gold'>{record.blockStatusName}</Tag>;

    case 'REJECTED':
      return <Tag color='red'>{record.blockStatusName}</Tag>;

    default:
      return <Tag>{record.blockStatusName}</Tag>;
  }
};

export default BlockStatusTag;
