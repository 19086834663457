import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getInspectionStatus, getTopicInspection } from '@/store/inspection/actions';

import { selectInspection } from '@/store/inspection/selectors';

import { Layout, Table, Tag, Typography } from 'antd';
import Filter from '@/components/Filter';
import { Content } from '@/components/Layout';

import { setColorTag } from './constants';
import Sort from './parts/Sort';

import style from './style.module.scss';

const Inspector = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation('inspectors');
  const { inspectionStatus, isLoading, topicInspection, pagination } = useSelector(selectInspection);

  const currentSearchParams = useMemo(() => {
    const initialValues = {
      body: {},
      query: {},
    };

    for (const [key, value] of searchParams.entries()) {
      if (key === 'sort' || key === 'page') {
        initialValues.query[key] = value;
      } else {
        initialValues.body[key] = value;
      }
    }

    return initialValues;
  }, [searchParams]);

  const pageNumber = parseInt(searchParams.get('page') ?? pagination.getTopicInspection.pageNumber);

  const onGetTopicInspection = () => dispatch(getTopicInspection(currentSearchParams.body, currentSearchParams.query));

  useEffect(() => {
    dispatch(getInspectionStatus());
  }, []);

  useEffect(() => {
    onGetTopicInspection();
  }, [searchParams]);

  const filter = [
    {
      label: t('labelFilters.0'),
      placeholder: t('placeholderFilters.0'),
      name: 'generalStatus',
      inputs: 'select',
      options: inspectionStatus.map(({ value, name }) => ({ value, label: name })),
    },
    { label: t('labelFilters.1'), placeholder: t('placeholderFilters.1'), name: 'topicName', inputs: 'input' },
  ];

  const onChangePage = page => {
    const params = new URLSearchParams(searchParams);
    params.set('page', (page - 1).toString());
    setSearchParams(params);
  };

  const paginationBlock = {
    current: pageNumber + 1,
    pageSize: pagination.getTopicInspection.pageSize,
    total: pagination.getTopicInspection.total,
    showSizeChanger: false,
    align: 'center',
    responsive: true,
    hideOnSinglePage: true,
    onChange: onChangePage,
  };

  const columns = [
    {
      title: t('nameMaterial'),
      dataIndex: 'topicName',
      key: 'topicName',
      width: '60%',
      render: (name, { topicId }) => (
        <Typography.Text
          className={style['inspector__link']}
          onClick={() => navigate(`/topic/${topicId}?inspectionMode=true`)}
        >
          {name}
        </Typography.Text>
      ),
    },
    {
      title: t('typeInspection'),
      dataIndex: 'inspectionType',
      key: 'inspectionType',
      render: ({ name }) => <Typography.Text>{name}</Typography.Text>,
    },
    {
      title: t('statusInspection'),
      dataIndex: 'generalStatus',
      key: 'generalStatus',
      render: ({ name, value }) => <Tag color={setColorTag(value)}>{t(name)}</Tag>,
    },
  ];

  return (
    <Layout>
      <Content>
        <h1>{t('caption')}</h1>
        <Filter filters={filter} />
        <Sort />
        <Table
          loading={isLoading.getTopicInspection}
          dataSource={topicInspection}
          columns={columns}
          pagination={paginationBlock}
        />
      </Content>
    </Layout>
  );
};

export default Inspector;
