import React from 'react';

import DOMPurify from 'dompurify';
import showdown from 'showdown';

function MarkdownViewer({ children, className }) {
  const rawHTML = children;
  const converter = new showdown.Converter();
  const html = converter.makeHtml(rawHTML);
  const sanitizedHTML = DOMPurify.sanitize(html);

  return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>;
}

export default MarkdownViewer;
