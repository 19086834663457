/* eslint-disable */
import {
  CREATE_ROLE_FAIL,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_START,
  DELETE_ROLE_SUCCESS,
  GET_ALL_ROLES_FAIL,
  GET_ALL_ROLES_START,
  GET_ALL_ROLES_SUCCESS,
  GET_GROUP_PERMISSIONS_ALL_FAIL,
  GET_GROUP_PERMISSIONS_ALL_START,
  GET_GROUP_PERMISSIONS_ALL_SUCCESS,
  GET_ROLE_BY_ID_FAIL,
  GET_ROLE_BY_ID_START,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLES_DEFAULT_FAIL,
  GET_ROLES_DEFAULT_START,
  GET_ROLES_DEFAULT_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
} from './constance';

const initialState = {
  roles: [],
  rolesDefault: [],
  allRoles: [],
  isLoading: false,
  role: {},
  defaultPermissionsIds: [],
  groupAll: [],
  paginationRole: {
    totalElements: 0,
    currentPage: 0,
    size: 0,
    totalPages: 0,
  },
  paginationPermission: {
    totalElements: 0,
    currentPage: 0,
    size: 0,
    totalPages: 0,
  },
  isError: false,
};

function sortGroup(x, y) {
  return x.name.localeCompare(y.name);
}

export default (state = initialState, { type, response, action }) => {
  switch (type) {
    case GET_ALL_ROLES_START:
    case CREATE_ROLE_START:
    case UPDATE_ROLE_START:
    case DELETE_ROLE_START:
    case GET_ROLE_BY_ID_START:
    case GET_GROUP_PERMISSIONS_ALL_START:
    case GET_ROLES_START:
    case GET_ROLES_DEFAULT_START:
      return { ...state, isLoading: true, isError: false };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roles: response?.content,
        paginationRole: {
          totalElements: response.totalElements,
          currentPage: response.number,
          size: response.size,
        },
      };

    case GET_ROLES_DEFAULT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rolesDefault: response?.content,
      };

    case CREATE_ROLE_SUCCESS:
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allRoles: response?.content,
      };

    case GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultPermissionsIds: response.permissions.map(perm => perm.id),
      };

    case GET_GROUP_PERMISSIONS_ALL_SUCCESS:
      return { ...state, groupAll: response.sort(sortGroup) };

    case GET_GROUP_PERMISSIONS_ALL_FAIL:
    case GET_ALL_ROLES_FAIL:
    case CREATE_ROLE_FAIL:
    case UPDATE_ROLE_FAIL:
    case DELETE_ROLE_FAIL:
    case GET_ROLE_BY_ID_FAIL:
    case GET_ROLES_FAIL:
    case GET_ROLES_DEFAULT_FAIL:
      return { ...state, isError: true, isLoading: false };

    default:
      return { ...state };
  }
};
