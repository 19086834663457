// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-Ot6AZj.admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-R75oG1 .admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-label-qiiJfe[data-value="Montserrat"]:before, .admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-Ot6AZj.admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-R75oG1 .admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-item-tXApjx[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-Montserrat-VG6EEA {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-ProfileSetting-ProfileSettings-module__input-IJhUlH {
  width: 400px;
}

.admin-src-pages-ProfileSetting-ProfileSettings-module__language-ef4aCf {
  margin-top: 20px !important;
}

.admin-src-pages-ProfileSetting-ProfileSettings-module__Label-card-_XlrET {
  margin-top: 32px !important;
}

.admin-src-pages-ProfileSetting-ProfileSettings-module__Card-select-DnFdww {
  width: 15em;
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/ProfileSetting/ProfileSettings.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,YAAY;AACd;;AAQA;EALE,2BAA2B;AAC7B;;AAQA;EALE,2BAA2B;AAC7B;;AAQA;EALE,WAAW;EACX,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.input {\n  width: 400px;\n}\n\n.language {\n  margin-top: 20px !important;\n}\n\n.Label-card {\n  margin-top: 32px !important;\n}\n\n.Card-select {\n  height: 40px;\n  width: 15em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-Ot6AZj`,
	"qlPicker": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-Ot6AZj`,
	"ql-font": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-R75oG1`,
	"qlFont": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-R75oG1`,
	"ql-picker-label": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-label-qiiJfe`,
	"qlPickerLabel": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-label-qiiJfe`,
	"ql-picker-item": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-item-tXApjx`,
	"qlPickerItem": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-picker-item-tXApjx`,
	"ql-font-Montserrat": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-Montserrat-VG6EEA`,
	"qlFontMontserrat": `admin-src-pages-ProfileSetting-ProfileSettings-module__ql-font-Montserrat-VG6EEA`,
	"input": `admin-src-pages-ProfileSetting-ProfileSettings-module__input-IJhUlH`,
	"language": `admin-src-pages-ProfileSetting-ProfileSettings-module__language-ef4aCf`,
	"Label-card": `admin-src-pages-ProfileSetting-ProfileSettings-module__Label-card-_XlrET`,
	"labelCard": `admin-src-pages-ProfileSetting-ProfileSettings-module__Label-card-_XlrET`,
	"Card-select": `admin-src-pages-ProfileSetting-ProfileSettings-module__Card-select-DnFdww`,
	"cardSelect": `admin-src-pages-ProfileSetting-ProfileSettings-module__Card-select-DnFdww`
};
export default ___CSS_LOADER_EXPORT___;
