const emailPoka = [
  'svetlana.gubarkova@effem.com',
  'irina.ukhan@effem.com',
  'artem.morozov@effem.com',
  'elena.parfenova@effem.com',
  'sergey.baskakov@effem.com',
  'julia.chegodaeva@effem.com',
  'kristina.sharapova@effem.com',
  'anastasiya.zaykina@effem.com',
  'kirill.koroteev@effem.com',
  'alexander.trotsko@effem.com',
  'dmitry.perov@effem.com',
  'vladimir.rusakov@effem.com',
  'irina.obydennaia@effem.com',
  'ekaterina.beshentseva@effem.com',
  'mariya.skachko@effem.com',
  'anna.sokoreva@effem.com',
  'i.karelsky@kampus.team',
  'daqert@gmail.com',
  'aleksandr.rykov@effem.com',
  'g.michurov@tandp.ru',
  'm.kotovskaya@tandp.ru',
  'd.gerbutova@kampus.team',
  's.matsakov@tandp.ru',
  // 'f.nadbitov@tandp.ru',
];

// export const devDomain = ['test'];
const devDomain = ['kam-6644.k-ampus.dev', 'mars.kampus.local'];

const hidePathPoka = [
  '/skills',
  '/area-directory',
  '/position-assignment',
  '/approver-directory',
  'poka',
  'pokaskills', // для юзер части planning?tab=pokaskills
  'Навыки', // для юзер части БЗ
  'Skills', // для юзер части БЗ
];

export default class UtilsPoka {
  static showPokaElems(name, userDomain, email) {
    return hidePathPoka.includes(name) && !devDomain.includes(userDomain) && !emailPoka.includes(email);
  }
}
