import { API_D360_URL } from '../service_api';
import {
  D360_ACTIVE_PROJECT_FAIL,
  D360_ACTIVE_PROJECT_START,
  D360_ACTIVE_PROJECT_SUCCESS,
  D360_COPY_PROJECT_FAIL,
  D360_COPY_PROJECT_START,
  D360_COPY_PROJECT_SUCCESS,
  D360_CREATE_PROJECT_FAIL,
  D360_CREATE_PROJECT_START,
  D360_CREATE_PROJECT_SUCCESS,
  D360_DEACTIVATE_PROJECT_FAIL,
  D360_DEACTIVATE_PROJECT_START,
  D360_DEACTIVATE_PROJECT_SUCCESS,
  D360_DELETE_PROJECT_FAIL,
  D360_DELETE_PROJECT_START,
  D360_DELETE_PROJECT_SUCCESS,
  D360_GET_PROJECTS_FAIL,
  D360_GET_PROJECTS_START,
  D360_GET_PROJECTS_SUCCESS,
  D360_HEALTH_PROJECT_FAIL,
  D360_HEALTH_PROJECT_START,
  D360_HEALTH_PROJECT_SUCCESS,
  D360_UPDATE_PROJECT_FAIL,
  D360_UPDATE_PROJECT_START,
  D360_UPDATE_PROJECT_SUCCESS,
} from './constants';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

// POST
export const D360CreateProject = ({ name, description, file_uuid, start, finish }, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/project`,
    body: {
      name: name,
      description: description,
      file_uuid: file_uuid,
      start: start,
      finish: finish,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_PROJECT_START, D360_CREATE_PROJECT_SUCCESS, D360_CREATE_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

// PUT
export const D360UpdateProject = (
  { id, name, description, file_uuid, start, finish, status_id },
  onSuccess,
  onError
) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/project`,
    body: {
      id: id,
      name: name,
      description: description,
      file_uuid: file_uuid,
      start: start,
      finish: finish,
      status_id: status_id,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_UPDATE_PROJECT_START, D360_UPDATE_PROJECT_SUCCESS, D360_UPDATE_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

// Активирует проект 360
export const D360ActiveProject = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/project/activate`,
    body: {
      id: id,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_ACTIVE_PROJECT_START, D360_ACTIVE_PROJECT_SUCCESS, D360_ACTIVE_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

// Деактивирует проект 360
export const D360DeactivateProject = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/project/deactivate`,
    body: {
      id: id,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DEACTIVATE_PROJECT_START, D360_DEACTIVATE_PROJECT_SUCCESS, D360_DEACTIVATE_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

// Копирует проект 360
export const D360CopyProject = (projectId, name, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/project/copy`,
    body: {
      id: projectId,
      name: name,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_COPY_PROJECT_START, D360_COPY_PROJECT_SUCCESS, D360_COPY_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

// Удаляет проект 360
export const D360DeleteProject = ({ id }, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/project${getQueryString({
      id: id,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DELETE_PROJECT_START, D360_DELETE_PROJECT_SUCCESS, D360_DELETE_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

// Возвращает все доступные проекты
export const D360GetProjects = (
  page = 1,
  pageSize = 10,
  sortColumn = 'id',
  sortOrder = 'DESC',
  filterColumn = 'id',
  filterValue = '',
  onSuccess,
  onError
) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/projects${getQueryString({
      page: page,
      pageSize: pageSize,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      filterColumn: filterColumn,
      filterValue: filterValue,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_PROJECTS_START, D360_GET_PROJECTS_SUCCESS, D360_GET_PROJECTS_FAIL],
    onSuccess,
    onError,
  };
};

export const D360HealthProject = (projectId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/project/health${getQueryString({
      id: projectId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_HEALTH_PROJECT_START, D360_HEALTH_PROJECT_SUCCESS, D360_HEALTH_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};
