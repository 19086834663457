/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createPost, deletePost, editPost, getConventusRoles, getPosts } from '@/store/posts/actions';

import { selectPosts } from '@/store/posts/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Form, Input, Pagination } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { Card, Content } from '@/components/Layout';

import PostCreationDialog from './PostCreationDialog';
import PostItem from './PostItem';

import debounce from '@shared/utils/debounce';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { CAREER_POSITION_CREATE } from '@/constants/permissions';

export class PostDirectory extends Component {
  static propTypes = {
    createPost: PropTypes.func,
    creationError: PropTypes.bool,
    deletePost: PropTypes.func,
    editPost: PropTypes.func,
    editingError: PropTypes.bool,
    getPosts: PropTypes.func,
    isLoading: PropTypes.bool,
    posts: PropTypes.array,
    conventusRoles: PropTypes.array,
    postsPageNumber: PropTypes.number,
    postsPageSize: PropTypes.number,
    postsTotal: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchName: '',
      page: 1,
    };
    this.getPostsDebounced = debounce(props.getPosts, 1000);
    this.props.getPosts();
    this.props.getConventusRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchString !== this.state.searchString) {
      this.getPostsDebounced(this.state.searchString, 0, () => {
        this.setState({ searchName: this.state.searchString });
      });
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changePage = page => {
    this.setState({ page });
    this.props.getPosts(this.state.searchString, page - 1);
  };

  showDeleteConfirmation = post => this.setState({ deleteConfirmationVisible: true, activePost: post });

  hideDeleteConfirmation = () => this.setState({ deleteConfirmationVisible: false });

  handlePostDeletion = () =>
    this.props.deletePost(this.state.activePost && this.state.activePost.id, {
      onSuccess: () => {
        this.hideDeleteConfirmation();
        this.props.getPosts(this.state.searchString, this.state.page - 1);
      },
      onError: this.hideDeleteConfirmation,
    });

  showPostCreationDialog = () => this.setState({ postCreationVisible: true });

  hidePostCreationDialog = () => this.setState({ postCreationVisible: false });

  handlePostCreation = ({ name, roleId }) => {
    this.props.createPost(name, roleId, () => {
      this.hidePostCreationDialog();
      this.props.getPosts(this.state.searchString, this.state.page - 1);
    });
  };

  showPostEditingDialog = post => this.setState({ postEditingVisible: true, activePost: post });

  hidePostEditingDialog = () => this.setState({ postEditingVisible: false, activePost: null });

  handlePostEditing = ({ name, roleId }) => {
    this.props.editPost(name, roleId, this.state.activePost && this.state.activePost.id, () => {
      this.hidePostEditingDialog();
      this.props.getPosts(this.state.searchString, this.state.page - 1);
    });
  };

  render() {
    const {
      posts,
      postsPageSize,
      postsTotal,
      postsPageNumber,
      creationError,
      editingError,
      conventusRoles,
      currentUser,
      t,
    } = this.props;
    const postItems = posts.map(post => (
      <PostItem
        currentUser={currentUser}
        key={post.id}
        post={post}
        onEdit={this.showPostEditingDialog}
        onDelete={this.showDeleteConfirmation}
        t={t}
      />
    ));
    const pagination = (
      <Pagination
        current={postsPageNumber + 1}
        total={postsTotal}
        pageSize={postsPageSize}
        onChange={this.changePage}
        className={css['pagination']}
        showSizeChanger={false}
        responsive={true}
        hideOnSinglePage={true}
      />
    );

    return (
      <Content className={css.TagDirectory}>
        <h1>{t('title')}</h1>
        <div className={css['TagItem-container']}>
          <Form className={css['TagItem-form']}>
            <Form.Item
              label={t('search')}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              className={css['TagItem-form-search']}
            >
              <Input
                placeholder={t('name')}
                dataqa='postSearchInput'
                name='searchString'
                value={this.state.searchString}
                onChange={this.handleChange}
              />
            </Form.Item>
          </Form>
          {currentUser.authorities.includes(CAREER_POSITION_CREATE) && (
            <Button
              className={css['TagItem-button']}
              onClick={this.showPostCreationDialog}
              data-qa='addNewPostBtn'
              type='primary'
            >
              {t('addName')}
            </Button>
          )}
        </div>
        <Card>
          {postItems && postItems.length ? (
            postItems
          ) : !this.props.isLoading && this.state.searchString ? (
            <div className={css['TagItem-noFound']}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('findError', { searchName: this.state.searchName }),
                }}
              />
            </div>
          ) : null}
          {pagination}
        </Card>
        {this.state.postCreationVisible ? (
          <PostCreationDialog
            open
            onOk={this.handlePostCreation}
            onCancel={this.hidePostCreationDialog}
            title={t('addName')}
            descriptionText={t('type')}
            okText={t('add')}
            error={creationError}
            errorText={t('exist')}
            roles={conventusRoles}
            t={t}
          />
        ) : null}
        {this.state.postEditingVisible ? (
          <PostCreationDialog
            open
            onOk={this.handlePostEditing}
            onCancel={this.hidePostEditingDialog}
            title={t('editName')}
            descriptionText={t('newOne')}
            okText={t('edit')}
            error={editingError}
            errorText={t('exist')}
            post={this.state.activePost}
            roles={conventusRoles}
            t={t}
          />
        ) : null}
        <ConfirmationModal
          open={this.state.deleteConfirmationVisible}
          onOk={this.handlePostDeletion}
          onCancel={this.hideDeleteConfirmation}
          title={t('deleteName')}
          okText={t('delete')}
          confirmationText={t('confirm')}
        />
      </Content>
    );
  }
}

const mapStateToProps = createSelector([selectPosts, selectUsers], (posts, users) => ({
  posts: posts.posts || [],
  currentUser: users.currentUser,
  conventusRoles: posts.conventusRoles || [],
  creationError: posts.creationError || false,
  editingError: posts.editingError || false,
  postsTotal: posts.postsTotal,
  postsPageSize: posts.postsPageSize,
  postsPageNumber: posts.postsPageNumber || 0,
  isLoading: posts.isLoading,
}));

const mapActionsToProps = {
  getPosts,
  getConventusRoles,
  createPost,
  editPost,
  deletePost,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('postDirectory')(PostDirectory));
