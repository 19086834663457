/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';

import { Layout } from 'antd';
import MarkdownViewer from '@/components/MarkdownViewer';

import NotFound from '../NotFound';

import css from './ServerError.module.scss';

import load from '@/assets/images/waitng-icon.png';

const { Content } = Layout;

const ServerError = () => {
  const error = useRouteError();
  const errorStatus = error?.action || error?.status;
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    console.error(error);
  }, [error]);

  const { t } = useTranslation(['serverError']);
  const reload = () => {
    document.location.reload();
  };

  useEffect(() => {
    switch (errorStatus) {
      case 401:
        navigate('/user-login/');
        break;
      case 504:
        setTitle('');
        setContent(t('unAvailableVPN'));
        break;
      case 503:
      case 502:
        setTitle(t('unAvailable'));
        setContent(<MarkdownViewer>{t('newFeatures')}</MarkdownViewer>);
        break;
      case 501:
        setTitle(t('timeout1'));
        setContent(t('timeout2'));
        break;
      default:
        setTitle('');
        setContent(<MarkdownViewer>{t('unexpectedError')}</MarkdownViewer>);
        break;
    }
  }, [errorStatus]);

  if (errorStatus === 404) {
    return <NotFound />;
  }

  return (
    <Layout className={css.ServerError}>
      <Content className={css.LoginContent}>
        <div className={css['App-card-item']}>
          <div className={css['App-card-item-imageBlock']}>
            <img src={load} alt='' />
          </div>
          <div className={css['App-card-item-text']}>
            <div className={css['App-card-item-text__title']}>{title}</div>
            <div className={css['App-card-item-text__content']}>{content}</div>
          </div>
          <div className={css['App-card-item-link']} onClick={reload}>
            {t('tryAgain')}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default ServerError;
