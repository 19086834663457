/* eslint-disable */

// REACT, REDUX
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// ACTIONS
import {
  clearErrorText,
  createCompetence,
  deleteCompetence,
  editCompetence,
  getCompetenceWithSkills,
} from '@/store/competence/actions';

// SELECTORS
import { selectCompetence } from '@/store/competence/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Input, Row, Table, Tag, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { Card, Content } from '@/components/Layout';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import AddCompetenceModal from './AddCompetenceModal';
import { MAX_TAGS_LENGTH } from './constants';
import FilterCompetence from './Content/FilterCompetence';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

// UI
import css from './index.module.scss';

import { COMPETENCE_CREATE, COMPETENCE_DELETE, COMPETENCE_UPDATE } from '@/constants/permissions';

const { Search } = Input;
const { Column } = Table;

export class CompetenceDirectory extends Component {
  static propTypes = {
    createCompetence: PropTypes.func,
    deleteCompetence: PropTypes.func,
    editCompetence: PropTypes.func,
    clearErrorText: PropTypes.func,
    getCompetence: PropTypes.func,
    competence: PropTypes.array,
    total: PropTypes.number,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
    creationError: PropTypes.bool,
    editingError: PropTypes.bool,
    isLoading: PropTypes.bool,
    competenceFilters: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      competenceModalVisible: false,
      competencies: this.props.competence || [],
      record: {},
      isDelete: false,
    };

    this.props.getCompetenceWithSkills(0);
  }

  getTableData(competencies) {
    let data = [];
    if (Array.isArray(competencies)) {
      for (let i = 0; i < competencies.length; i++) {
        let competence = competencies[i];

        if (typeof competence === 'object') {
          if (competence.id && competence.name) {
            data[i] = {
              key: competence.id,
              competence: competence.name,
              skills: competence.skills || [],
              isHardSkill: competence.isHardSkill || false,
              groups: competence.groups?.map(item => item.id),
              groupIds: competence.groups,
            };
          }
        }
      }
    }

    return data;
  }

  AddCompetenceModalSubmit(record, skillIds, groups) {
    if (record && record.key && Number(record.key) > 0) {
      this.props.editCompetence(
        record.key,
        record.competence,
        skillIds,
        record.isHardSkill,
        () => {
          this.setState({ competenceModalVisible: false });
          this.props.getCompetenceWithSkills(this.props.pageNumber, this.props.competenceFilters);
        },
        groups
      );
    } else {
      this.props.createCompetence(
        record.competence,
        skillIds,
        record.isHardSkill,
        () => {
          this.setState({ competenceModalVisible: false });
          this.props.getCompetenceWithSkills(this.props.pageNumber, this.props.competenceFilters);
        },
        () => {},
        groups
      );
    }
  }

  handleModalClose() {
    this.setState({
      competenceModalVisible: false,
    });
  }

  isValidRecord(record) {
    if (typeof record === 'object' && typeof record.key !== 'undefined') {
      return true;
    }

    return false;
  }

  handleChangePage(page) {
    this.props.getCompetenceWithSkills(Number(page) - 1, this.props.competenceFilters);
  }

  getSkillName(name) {
    let sliced = name?.slice(0, 30);
    if (sliced.length < name.length) {
      sliced += '...';
    }

    return sliced;
  }

  onDeleteComp(record) {
    if (this.isValidRecord(record)) {
      this.props.deleteCompetence(
        record.key,
        () => {
          this.props.getCompetenceWithSkills(this.props.pageNumber, this.props.competenceFilters);
          this.setState({ isDelete: false, record: {} });
        },
        err => {
          console.error('Error:' + err);
        }
      );
    }
  }

  showMoreLabel(arr) {
    if (arr.length > 3) {
      arr.splice(0, 3);

      return (
        <div className={css['Column-more']}>
          <Tooltip
            title={
              <div className={css['MoreTag-container']}>
                {arr?.map(item => (
                  <Tag key={item.id} className={css['MoreTag-tag']} data-qa='skillName'>
                    {Utils.trString(item.name, MAX_TAGS_LENGTH)}
                  </Tag>
                ))}
              </div>
            }
            color='white'
          >
            <span>{this.props.t('more', { count: arr.length })}</span>
          </Tooltip>
        </div>
      );
    }

    return null;
  }

  render() {
    const { t, competence, isLoading, total, pageSize, pageNumber, currentUser } = this.props;
    const { competenceModalVisible, record } = this.state;

    return (
      <Content className={css.DictionaryDirectory}>
        <Row>
          <Col span={12}>
            <h1 className={css['DictionaryItem-title']}>{t('pageTitle')}</h1>
          </Col>
          <Col span={12}>
            <div className={css['DictionaryItem-container']}>
              <>
                <Button className={css['DictionaryItem-button-import']} onClick={this.showImportDialog}>
                  {t('importCompetenceButton')}
                </Button>
                {currentUser.authorities.includes(COMPETENCE_CREATE) && (
                  <Button
                    className={css['DictionaryItem-button']}
                    onClick={() => {
                      this.setState({
                        record: {
                          competence: '',
                          key: null,
                          skills: [],
                          isHardSkill: false,
                          groups: [],
                        },
                        competenceModalVisible: true,
                      });
                    }}
                    type='primary'
                    data-qa='createCompetenceBtn'
                  >
                    {t('addCompetenceButton')}
                  </Button>
                )}
              </>
            </div>
          </Col>
        </Row>
        <div>
          <FilterCompetence />
        </div>
        <Card className={css['Card-table']}>
          <div className={css['DictionaryItem-noFound']}>
            <Table
              data-qa='competenceItemsList'
              id='competenceItem'
              locale={{ emptyText: t('nodata') }}
              ellipsis={true}
              bordered={false}
              dataSource={this.getTableData(competence)}
              className={css['DictionaryItem-table']}
              loading={isLoading}
              pagination={{
                pageSize: pageSize,
                defaultCurrent: 0,
                current: pageNumber + 1,
                showSizeChanger: false,
                responsive: true,
                hideOnSinglePage: true,
                total: total,
                onChange: (p, s) => this.handleChangePage(p, s),
                style: {
                  padding: '10px',
                },
              }}
            >
              <Column
                title={t('competenceColumn')}
                dataIndex='competence'
                key='competence'
                filterSearch={true}
                width={'20%'}
              />
              <Column
                title={t('skillsColumn')}
                dataIndex='skills'
                key='skills'
                width={'35%'}
                render={skills => {
                  const skillsCopy = [...skills];
                  return (
                    <>
                      {skillsCopy.slice(0, 3).map(skill => (
                        <Tag key={skill.id} className={css['MoreTag-tag']} data-qa='skillName'>
                          <Tooltip title={skill.name}>{this.getSkillName(skill.name)}</Tooltip>
                        </Tag>
                      ))}
                      {skillsCopy.length > 3 && this.showMoreLabel(skillsCopy)}
                    </>
                  );
                }}
              />
              <Column
                title={t('groupsColumn')}
                dataIndex='groupIds'
                key='groupIds'
                width={'35%'}
                render={groupIds => {
                  const groupIdsCopy = Array.isArray(groupIds) ? [...groupIds] : [];
                  return (
                    <>
                      {groupIdsCopy.slice(0, 3).map(group => (
                        <Tag key={group.id} className={css['MoreTag-tag']} color='blue' data-qa='skillName'>
                          <Tooltip title={group.name}>{this.getSkillName(group.name)}</Tooltip>
                        </Tag>
                      ))}
                      {groupIdsCopy.length > 3 && this.showMoreLabel(groupIdsCopy)}
                    </>
                  );
                }}
              />
              <Column
                title={t('actionsColumn')}
                key='actions'
                align='center'
                width={'10%'}
                render={(text, record) => (
                  <>
                    {currentUser.authorities.includes(COMPETENCE_UPDATE) && (
                      <Tooltip title={t('editButton')} placement='left'>
                        <EditOutlined
                          type='primary'
                          data-qa='editCompetenceBtn'
                          className={css['DictionaryItem-icon-edit']}
                          onClick={() => {
                            if (this.isValidRecord(record)) {
                              this.setState({
                                record: record,
                                competenceModalVisible: true,
                              });
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                    {currentUser.authorities.includes(COMPETENCE_DELETE) && (
                      <Tooltip title={t('deleteButton')}>
                        <DeleteOutlined
                          data-qa='deleteCompetenceBtn'
                          className={css['DictionaryItem-icon-delete']}
                          onClick={() => this.setState({ isDelete: true, record })}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              />
            </Table>
          </div>
        </Card>
        {competenceModalVisible && (
          <AddCompetenceModal
            onCancel={() => {
              this.handleModalClose();
            }}
            onSubmit={(c, s, g) => {
              this.AddCompetenceModalSubmit(c, s, g);
            }}
            errorAlertText={this.props.errorText}
            clearErrorText={this.props.clearErrorText}
            open={competenceModalVisible}
            locale={t}
            record={record}
          />
        )}
        {this.state.isDelete && (
          <ConfirmationModal
            open={this.state.isDelete}
            onOk={() => this.onDeleteComp(record)}
            onCancel={() => this.setState({ isDelete: false, record: {} })}
            title={t('deleteName')}
            okText={t('delete')}
            confirmationText={t('confirm')}
          />
        )}
      </Content>
    );
  }
}

const mapStateToProps = createSelector([selectCompetence, selectUsers], (competencies, users) => ({
  competence: competencies.competence || [],
  total: competencies.total,
  pageSize: competencies.pageSize,
  pageNumber: competencies.pageNumber || 0,
  creationError: competencies.creationError || false,
  errorText: competencies.errorText,
  editingError: competencies.editingError || false,
  isLoading: competencies.isLoading,
  currentUser: users.currentUser,
  competenceFilters: competencies.competenceFilters,
}));

const mapActionsToProps = {
  getCompetenceWithSkills,
  createCompetence,
  editCompetence,
  deleteCompetence,
  clearErrorText,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('CompetenceDirectory')(CompetenceDirectory));
