// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-TimeInput-TimeInput-module__ql-picker-L0Ajjh.admin-src-components-TimeInput-TimeInput-module__ql-font-FUOwF6 .admin-src-components-TimeInput-TimeInput-module__ql-picker-label-US_7YN[data-value="Montserrat"]:before, .admin-src-components-TimeInput-TimeInput-module__ql-picker-L0Ajjh.admin-src-components-TimeInput-TimeInput-module__ql-font-FUOwF6 .admin-src-components-TimeInput-TimeInput-module__ql-picker-item-X0ytR8[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-TimeInput-TimeInput-module__ql-font-Montserrat-CPfZHi {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-TimeInput-TimeInput-module__TimeInput-U4HE2z {
  display: flex;
}

.admin-src-components-TimeInput-TimeInput-module__TimeInput-input-huDWr7 {
  color: #000;
  background-image: none;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100px;
  height: 33px;
  margin-right: 12px;
  padding: 0 17px;
  font-size: 13px;
  display: inline-block;
  position: relative;
}

.admin-src-components-TimeInput-TimeInput-module__TimeInput-input-huDWr7:hover, .admin-src-components-TimeInput-TimeInput-module__TimeInput-input-huDWr7:focus {
  border-color: var(--personalColor);
  box-shadow: none;
  outline: 0;
}

.admin-src-components-TimeInput-TimeInput-module__TimeInput-input-huDWr7::placeholder {
  color: #0006;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/TimeInput/TimeInput.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,aAAa;AACf;;AAOA;EAJE,WAAW;EACX,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;;AAMA;EAHE,kCAAkC;EAClC,gBAAgB;EAChB,UAAU;AACZ;;AAKA;EAFE,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.TimeInput {\n  display: flex;\n}\n.TimeInput-input {\n  position: relative;\n  display: inline-block;\n  padding: 0 17px;\n  margin-right: 12px;\n  width: 100px;\n  height: 33px;\n  font-size: 13px;\n  color: #000;\n  background-image: none;\n  border: 1px solid #dadada;\n  border-radius: 8px;\n}\n.TimeInput-input:hover, .TimeInput-input:focus {\n  border-color: var(--personalColor);\n  box-shadow: none;\n  outline: 0;\n}\n.TimeInput-input::placeholder {\n  color: rgba(0, 0, 0, 0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-TimeInput-TimeInput-module__ql-picker-L0Ajjh`,
	"qlPicker": `admin-src-components-TimeInput-TimeInput-module__ql-picker-L0Ajjh`,
	"ql-font": `admin-src-components-TimeInput-TimeInput-module__ql-font-FUOwF6`,
	"qlFont": `admin-src-components-TimeInput-TimeInput-module__ql-font-FUOwF6`,
	"ql-picker-label": `admin-src-components-TimeInput-TimeInput-module__ql-picker-label-US_7YN`,
	"qlPickerLabel": `admin-src-components-TimeInput-TimeInput-module__ql-picker-label-US_7YN`,
	"ql-picker-item": `admin-src-components-TimeInput-TimeInput-module__ql-picker-item-X0ytR8`,
	"qlPickerItem": `admin-src-components-TimeInput-TimeInput-module__ql-picker-item-X0ytR8`,
	"ql-font-Montserrat": `admin-src-components-TimeInput-TimeInput-module__ql-font-Montserrat-CPfZHi`,
	"qlFontMontserrat": `admin-src-components-TimeInput-TimeInput-module__ql-font-Montserrat-CPfZHi`,
	"TimeInput": `admin-src-components-TimeInput-TimeInput-module__TimeInput-U4HE2z`,
	"timeInput": `admin-src-components-TimeInput-TimeInput-module__TimeInput-U4HE2z`,
	"TimeInput-input": `admin-src-components-TimeInput-TimeInput-module__TimeInput-input-huDWr7`,
	"timeInputInput": `admin-src-components-TimeInput-TimeInput-module__TimeInput-input-huDWr7`
};
export default ___CSS_LOADER_EXPORT___;
