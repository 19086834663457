import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { activeStatus, changePositionTopic } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Card, Image, Popconfirm, Popover, Tooltip, Typography } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

import { ACTIVE, DISABLED } from '@shared/constants/topic-statuses';
import useImage from '@shared/hooks/useImage';
import { isCorpOrExtCourse } from '@shared/utils/topic-utils';

import cn from 'classnames';

import style from './style.module.scss';

import { TOPIC_DELETE, TOPIC_EXECUTIVE_TASKS_DELETE, TOPIC_PIN_INTERACT } from '@/constants/permissions';

const MAX_NAME_LENGTH = 20;

const Topic = props => {
  const imageSrc = useImage(props.coverFileUuid, 'topic');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation('topic');
  const { currentUser } = useSelector(selectUsers);
  const { pinnedTopicsCount } = useSelector(selectTopics);

  const isHidden = () => {
    if (props.visible) return <EyeOutlined className={cn(style['topic__icon'], style['topic__icon_visible'])} />;

    return <EyeInvisibleOutlined className={style['topic__icon']} />;
  };

  const redirectToTopic = () => navigate(`/topic/${props.id}`);

  const renderInfoElement = (name, value) => {
    return (
      <div className={style['info__element']}>
        <Typography.Text className={style['info__name']}>{name}</Typography.Text>
        {value.length > MAX_NAME_LENGTH ? (
          <Tooltip title={value}>
            <Typography.Text className={style['info__value']}>{value}</Typography.Text>
          </Tooltip>
        ) : (
          <Typography.Text className={style['info__value']}>{value}</Typography.Text>
        )}
      </div>
    );
  };

  const coverImage = (
    <Image
      onClick={redirectToTopic}
      className={style['topic__image']}
      alt={props.name}
      src={imageSrc}
      preview={false}
    />
  );

  const setPinTopic = pinPosition => dispatch(changePositionTopic(props.id, pinPosition));

  const deleteButton = (
    <Tooltip title={t('delete')}>
      <Popconfirm
        title={t('delete')}
        description={t('deleteConfirm')}
        okText={t('yes')}
        cancelText={t('no')}
        onConfirm={() => props.onDeleteTopic(props.id)}
        icon={
          <InfoCircleOutlined
            className={cn(style['topic__icon'], style['topic__icon_warning'])}
            id='topicDeleteButton'
          />
        }
        id='topicCardPopconfirm'
      >
        <DeleteOutlined className={cn(style['topic__icon'], style['topic__icon_danger'])} />
      </Popconfirm>
    </Tooltip>
  );

  const numbers = useMemo(() => Array.from({ length: pinnedTopicsCount }, (_, i) => i + 1), [pinnedTopicsCount]);

  const popoverContent = () => {
    return (
      <div className={style['popover']}>
        <Typography.Text className={style['popover__element']} onClick={() => setPinTopic(0)}>
          {t('unhook')}
        </Typography.Text>
        {numbers.map(number => (
          <Typography.Text className={style['popover__element']} onClick={() => setPinTopic(number)} key={number}>
            {number}
          </Typography.Text>
        ))}
      </div>
    );
  };

  const pinButton = () => {
    if (props.position) {
      return (
        <div className={style['topic__pin']}>
          <div className={style['topic__pin-element']}>{props.position}</div>
          <Popover content={popoverContent} trigger='click'>
            <PaperClipOutlined className={style['topic__icon']} />
          </Popover>
        </div>
      );
    } else {
      return (
        <Tooltip title={t('hook')}>
          <PaperClipOutlined className={style['topic__icon']} onClick={() => setPinTopic(pinnedTopicsCount + 1)} />
        </Tooltip>
      );
    }
  };

  const deletable = props.status === ACTIVE || props.status === DISABLED;

  const getActions = () => {
    const actions = [];

    if (props.visible && currentUser.authorities.includes(TOPIC_PIN_INTERACT) && deletable) {
      actions.push(pinButton());
    }

    if (isCorpOrExtCourse(props.type.id) && deletable) {
      actions.push(activateButton());
    }

    if (
      (currentUser.authorities.includes(TOPIC_DELETE) ||
        (props.type.id === 25 && currentUser.authorities.includes(TOPIC_EXECUTIVE_TASKS_DELETE))) &&
      deletable &&
      currentUser.domainCompany.global === props.global
    )
      actions.push(deleteButton);

    return actions.length ? actions : null;
  };

  const activateButton = () => {
    const description = (
      <div className={style['popconfirm__description']}>
        <Typography.Text>{t('deactivateDescription.0')}</Typography.Text>
        <Typography.Text>{t('deactivateDescription.1')}</Typography.Text>
      </div>
    );

    const isActive = props.status === ACTIVE;
    if (isActive) {
      return (
        <Tooltip title={t('deactivate')}>
          <Popconfirm
            title={t('deactivateTitle')}
            description={description}
            okText={t('yes')}
            cancelText={t('no')}
            onConfirm={() => dispatch(activeStatus(props.id, DISABLED))}
            icon={<InfoCircleOutlined className={cn(style['topic__icon'], style['topic__icon_warning'])} />}
          >
            <CloseCircleOutlined className={style['topic__icon']} title={t('deactivate')} />
          </Popconfirm>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={t('activate')}>
        <CheckCircleOutlined
          className={style['topic__icon']}
          onClick={() => dispatch(activeStatus(props.id, ACTIVE))}
        />
      </Tooltip>
    );
  };

  return (
    <Card className={style['topic']} cover={coverImage} actions={getActions()} id='topicCard'>
      <div onClick={redirectToTopic}>
        <Tooltip title={t(props.visible ? 'visible' : 'hidden')}>
          <div className={style['topic__eye']}>{isHidden()}</div>
        </Tooltip>
        {isCorpOrExtCourse(props.type.id) && props.status === DISABLED && (
          <Tooltip title={t('topicIsDeactivate')}>
            <div className={style['topic__lock']}>
              <LockOutlined className={style['topic__icon']} />
            </div>
          </Tooltip>
        )}
        {props.name?.length > MAX_NAME_LENGTH ? (
          <Tooltip title={props.name}>
            <div>
              <Card.Meta title={props.name} id='topicCardName' />
            </div>
          </Tooltip>
        ) : (
          <Card.Meta title={props.name} id='topicCardName' />
        )}
        <div className={style['info']}>
          {renderInfoElement(t('type'), props.type.name)}
          {renderInfoElement(t('loaded'), `${props.creBy?.firstName} ${props.creBy?.lastName}`)}
        </div>
      </div>
    </Card>
  );
};

export default memo(Topic);
