import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Table } from 'antd';

import _ from 'lodash';
import PropTypes from 'prop-types';

function TypeformModal(props) {
  const data = [];
  const { t } = useTranslation('typeform');

  if (!_.isEmpty(props.answers.answersResponse)) {
    // Отфилтрованные все ответы пользователя по responseId
    const userAnswers = props.answers.answersResponse.content.filter(item => {
      return item.responseId === props.currentKey;
    });

    // Массив со всеми вопросами из typeform
    const questions = props.answers.form.fieldsInfo.map(item => {
      return item;
    });

    // Разброс данных в data
    if (!_.isEmpty(userAnswers)) {
      userAnswers[0].answers.forEach(item => {
        switch (item.field.type) {
          case 'EMAIL':
          case 'TEXT':
          case 'SHORT_TEXT':
            questions.forEach(i => {
              if (item.field.id === i.id) {
                data.push({
                  key: i.id,
                  question: i.title,
                  answer: item.value,
                });
              }
            });
            break;
          case 'MULTIPLE_CHOICE':
          case 'CHOICE':
            questions.forEach(i => {
              if (item.field.id === i.id) {
                data.push({
                  key: i.id,
                  question: i.title,
                  answer: item.choice.value,
                });
              }
            });
            break;
          default:
            break;
        }
      });
    }
  }

  // Колонки для таблицы
  const columns = [
    {
      title: `${t('question')}`,
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: `${t('answer')}`,
      dataIndex: 'answer',
      key: 'answer',
    },
  ];

  return (
    <Modal width={720} title={t('more')} open={props.visible} onOk={props.onOk} onCancel={props.onCancel}>
      <Table dataSource={data} columns={columns} pagination={false} />
    </Modal>
  );
}

TypeformModal.propTypes = {
  answers: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentKey: PropTypes.string.isRequired,
};

export default TypeformModal;
