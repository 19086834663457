import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const MoreButtonComponent = ({ elements, Component, value, maxElements = 2 }) => {
  const { t } = useTranslation('constants');

  const [visible, setVisible] = React.useState(false);

  const renderElements = arr => {
    return arr.map((elem, index) => {
      if (Component) {
        return <Component key={index}>{elem[value]}</Component>;
      } else {
        return (
          <span className={styles.more__value} key={index}>
            {elem[value]}
            {arr.length - 1 !== index ? ',' : null}
          </span>
        );
      }
    });
  };

  const renderSmall = () => (
    <>
      {renderElements(elements?.slice(0, maxElements))}
      {elements?.length > maxElements && (
        <span onClick={() => setVisible(true)} className={styles.more}>
          {t('more', { count: elements?.length - maxElements })}
        </span>
      )}
    </>
  );

  const renderBig = () => (
    <>
      {renderElements(elements)}
      <span onClick={() => setVisible(false)} className={styles.more}>
        {t('hidden')}
      </span>
    </>
  );

  return visible ? renderBig() : renderSmall();
};

MoreButtonComponent.propTypes = {
  elements: PropTypes.array,
  value: PropTypes.string,
  maxElements: PropTypes.number,
  Component: PropTypes.node,
};

export default MoreButtonComponent;
