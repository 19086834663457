import { API_URL } from '@shared/constants/config';
import { DELETE, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import {
  DELETE_POKA_APPROVER_FAIL,
  DELETE_POKA_APPROVER_START,
  DELETE_POKA_APPROVER_SUCCESS,
  LOAD_POKA_APPROVERS_FAIL,
  LOAD_POKA_APPROVERS_START,
  LOAD_POKA_APPROVERS_SUCCESS,
  POST_POKA_APPROVER_FAIL,
  POST_POKA_APPROVER_START,
  POST_POKA_APPROVER_SUCCESS,
  PUT_POKA_APPROVER_FAIL,
  PUT_POKA_APPROVER_START,
  PUT_POKA_APPROVER_SUCCESS,
  SAVE_POKA_APPROVER_DATA,
  SAVE_POKA_APPROVERS_FILTERS,
  SAVE_POKA_APPROVERS_SORT,
} from '@/store/poka_approvers/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Получение таблицы одобряющих
export const loadPokaApprovers = (params, body = {}) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/approver${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [LOAD_POKA_APPROVERS_START, LOAD_POKA_APPROVERS_SUCCESS, LOAD_POKA_APPROVERS_FAIL],
});

// создание одобряющего
export const createPokaApprover = (body, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/approver/add`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [POST_POKA_APPROVER_START, POST_POKA_APPROVER_SUCCESS, POST_POKA_APPROVER_FAIL],
  onSuccess,
});

// обновление одобряющего
export const updatePokaApprover = (id, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/approver/edit/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_POKA_APPROVER_START, PUT_POKA_APPROVER_SUCCESS, PUT_POKA_APPROVER_FAIL],
  onSuccess,
  onError,
});

// удаление одобряющего
export const deletePokaApprover = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/poka/approver/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [DELETE_POKA_APPROVER_START, DELETE_POKA_APPROVER_SUCCESS, DELETE_POKA_APPROVER_FAIL],
  onSuccess,
  onError,
});

// Сохранение фильтров одобряющих
export const savePokaApproversFilters = value => ({
  type: SAVE_POKA_APPROVERS_FILTERS,
  value,
});

// Сохранение сортировки одобряющих
export const savePokaApproversSort = value => ({
  type: SAVE_POKA_APPROVERS_SORT,
  value,
});

// Сохранение редактируемого одобряющего
export const saveEditingPokaApprover = value => ({
  type: SAVE_POKA_APPROVER_DATA,
  value,
});
