export const D360_CREATE_CRITERIA_START = 'D360_CREATE_CRITERIA_START';
export const D360_CREATE_CRITERIA_SUCCESS = 'D360_CREATE_CRITERIA_SUCCESS';
export const D360_CREATE_CRITERIA_FAIL = 'D360_CREATE_CRITERIA_FAIL';

export const D360_UPDATE_COMPETENCE_COMMENT_START = 'D360_UPDATE_COMPETENCE_COMMENT_START';
export const D360_UPDATE_COMPETENCE_COMMENT_SUCCESS = 'D360_UPDATE_COMPETENCE_COMMENT_SUCCESS';
export const D360_UPDATE_COMPETENCE_COMMENT_FAIL = 'D360_UPDATE_COMPETENCE_COMMENT_FAIL';

export const D360_UPDATE_SKILL_COMMENT_START = 'D360_UPDATE_SKILL_COMMENT_START';
export const D360_UPDATE_SKILL_COMMENT_SUCCESS = 'D360_UPDATE_SKILL_COMMENT_SUCCESS';
export const D360_UPDATE_SKILL_COMMENT_FAIL = 'D360_UPDATE_SKILL_COMMENT_FAIL';

export const D360_DELETE_CRITERIA_START = 'D360_DELETE_CRITERIA_START';
export const D360_DELETE_CRITERIA_SUCCESS = 'D360_DELETE_CRITERIA_SUCCESS';
export const D360_DELETE_CRITERIA_FAIL = 'D360_DELETE_CRITERIA_FAIL';

export const D360_GET_CRITERIA_START = 'D360_GET_CRITERIA_START';
export const D360_GET_CRITERIA_SUCCESS = 'D360_GET_CRITERIA_SUCCESS';
export const D360_GET_CRITERIA_FAIL = 'D360_GET_CRITERIA_FAIL';
