/* eslint-disable */
// @ts-nocheck
import { ApiService } from './api-service';

export class UsersService extends ApiService {
  getUsers(params: TGetUsersParams): Promise<any[]> {
    return this.get('/users', params);
  }
}

type TGetUsersParams = {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  companyId?: string;
};

export default new UsersService();
