/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getLocales, setUserLanguage } from '@/store/locales/actions';
import { changeEmailNotification, getUser } from '@/store/users/actions';

import { selectLocales } from '@/store/locales/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Checkbox } from 'antd';
import { Card, Content } from '@/components/Layout';
import Select from '@/components/Select';

import { changeLanguage } from '@shared/utils/changeLanguage';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import css from './ProfileSettings.module.scss';

class ProfileSetting extends Component {
  static propTypes = {
    changeEmailNotification: PropTypes.func,
    currentUser: PropTypes.object,
    getUser: PropTypes.func,
    t: PropTypes.func,
    userError: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      notificationEmail: this.props.currentUser && this.props.currentUser.notifyEnable,
      currentLanguage: this.props.currentUser.language,
    };
  }

  componentDidMount() {
    this.props.getLocales();
  }

  handleChangeNotification = event => {
    const curUser = this.props.currentUser;
    curUser.notifyEnable = event?.target?.checked;
    this.props.changeEmailNotification(curUser, response => {
      this.setState({ notificationEmail: response.notifyEnable });
    });
  };

  localesOptions = () =>
    this.props.locales &&
    this.props.locales.map(data => ({
      id: data.language,
      value: data.language,
      name: data.languageDesc,
    }));

  changeLocale = ({ value }) => {
    changeLanguage(i18n, dayjs, value);

    this.props.setUserLanguage(value);
    this.setState({ currentLanguage: value });
  };

  render() {
    const { t } = this.props;
    const { notificationEmail } = this.state;

    return (
      <Content>
        <h1>{t('title.0')}</h1>
        <Card>
          <Checkbox checked={notificationEmail} onChange={this.handleChangeNotification}>
            {t('notification')}
          </Checkbox>
          {/* <Checkbox value={notificationEmail} onChange={this.handleChangeNotification} label={t('notification')} /> */}
        </Card>
        <h1 className={css.language}>{t('title.1')}</h1>
        <Card>
          <Select
            name='locales'
            className={css['Card-select']}
            onChange={this.changeLocale}
            value={this.state.currentLanguage}
            options={this.localesOptions()}
            disabled={this.localesOptions().length < 2}
            // modificate
            white
            size='large'
          />
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = createSelector([usersSelect, selectLocales], (adminuUsers, { locales }) => ({
  currentUser: adminuUsers.currentUser || '',
  userError: adminuUsers.error,
  locales,
}));

const mapActionsToProps = {
  changeEmailNotification,
  getUser,
  getLocales,
  setUserLanguage,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('profileSetting')(ProfileSetting));
