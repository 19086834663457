/* eslint-disable */

/* deprecated, use api-service/axios */

const specialChars = '\\/`#&*%=^{}[]()|"<>№₽?';
const specialCharsTable = {};
// @ts-ignore
[...specialChars].forEach(c => (specialCharsTable[c] = escape(c)));

// eslint-disable-next-line no-useless-escape
const reSpecialChars = /[\\\/`#&*%=^{}\[\]\(\)\|"<>№₽\?]/g;
const reHasSpecialChars = RegExp(reSpecialChars.source);

export function safeText(query) {
  let string = query;
  try {
    string = decodeURI(query);
  } catch (e) {
    string = query;
  }

  return string && reHasSpecialChars.test(string)
    ? string.replace(reSpecialChars, chr => specialCharsTable[chr])
    : string;
}

function prepareQueryString(queryObject) {
  return Object.keys(queryObject)
    .map(key => {
      if (typeof queryObject[key] === 'undefined' || queryObject[key] === null) {
        return '';
      }
      const value = queryObject[key].toString();
      return value ? `${key}=${value}` : '';
    })
    .filter(item => item)
    .join('&');
}

export function getUrlParams(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};
  hashes.forEach(hash => {
    const [key, val] = hash.split('=');
    try {
      params[key] = decodeURIComponent(val);
    } catch (e) {
      params[key] = val;
    }
  });

  return params;
}

export const parseAllSearchParams = URL => {
  const searchParams = getUrlParams(URL) || {};

  return searchParams;
};

export const parseSearchParams = URL => {
  const searchParams = getUrlParams(URL) || {};

  if (searchParams.searchTag) {
    return {
      itemId: searchParams.searchTag,
      itemTag: 'TAG',
      type: 'TAG',
    };
  }

  const { itemTag } = searchParams;
  const { itemId } = searchParams;
  const { type } = searchParams;
  const { search } = searchParams;

  return {
    itemId,
    itemTag,
    type,
    searchResult: search,
  };
};

export default function (queryObject) {
  if (!queryObject) {
    return '';
  }

  const queryString = prepareQueryString(queryObject);
  const searchParams = new URLSearchParams(queryString);

  let query = '';
  // @ts-ignore
  for (const params of searchParams.entries()) {
    const [key, value] = params;

    query += value ? `${key}=${safeText(value)}&` : '';
  }
  query = query.slice(0, -1);

  return query && `?${query}`;
}
