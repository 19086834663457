import _isObject from 'lodash/isObject';

export class StorageService {
  constructor() {
    this.storage = localStorage;
  }

  setItem(key, value) {
    value = value && _isObject(value) ? JSON.stringify(value) : String(value);
    this.storage.setItem(key, value);
  }

  getItem(key) {
    const item = this.storage.getItem(key);
    try {
      return item && JSON.parse(item);
    } catch {
      return item;
    }
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
