export const DEPARTMENTS = 'departments';
export const FUNCTIONS = 'functions';
export const POSITIONS = 'positions';
export const LOCATIONS = 'locations';
export const ROLES = 'roles';
export const COMPANIES = 'companies';
export const CUSTOM_FIELDS = 'customField';
export const DELETE_CUSTOM_FIELDS = 'deleteCustomField';
export const ADD_CUSTOM_FIELDS = 'addCustomField';
export const ERROR = 'error';
export const SUCCESS = 'success';
export const COMPANY_NULL = 'Без компании';
export const ALL = 'all';
export const WARNING = 'warning';
