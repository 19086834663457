// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-mP5i6l.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-VUf45k .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-label-J1Nn9q[data-value="Montserrat"]:before, .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-mP5i6l.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-VUf45k .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-item-mc869Q[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-Montserrat-N91k3v {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__AddToBankModal_text-T1_Ho7 {
  white-space: pre-wrap;
  margin-bottom: 16px;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__AddToBankModal_select-A6w_zb {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Topics/TopicDetailsDialog/LMS/Blocks/AddToBankModal/AddToBankModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,qBAAqB;EACrB,mBAAmB;AACrB;;AAMA;EAHE,WAAW;AACb","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.AddToBankModal_text {\n  white-space: pre-wrap;\n  margin-bottom: 16px;\n}\n.AddToBankModal_select {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-mP5i6l`,
	"qlPicker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-mP5i6l`,
	"ql-font": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-VUf45k`,
	"qlFont": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-VUf45k`,
	"ql-picker-label": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-label-J1Nn9q`,
	"qlPickerLabel": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-label-J1Nn9q`,
	"ql-picker-item": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-item-mc869Q`,
	"qlPickerItem": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-picker-item-mc869Q`,
	"ql-font-Montserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-Montserrat-N91k3v`,
	"qlFontMontserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__ql-font-Montserrat-N91k3v`,
	"AddToBankModal_text": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__AddToBankModal_text-T1_Ho7`,
	"addToBankModalText": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__AddToBankModal_text-T1_Ho7`,
	"AddToBankModal_select": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__AddToBankModal_select-A6w_zb`,
	"addToBankModalSelect": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddToBankModal-AddToBankModal-module__AddToBankModal_select-A6w_zb`
};
export default ___CSS_LOADER_EXPORT___;
