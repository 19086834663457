import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';
import CustomMail from '@/components/MassSelection/components/CustomMail';
import { CustomFields, GlobalFilters, UsersCount } from '@/components/MVFilters';
import { FileTextOutlined, SettingOutlined } from '@ant-design/icons';

import { MASS } from '../Assignment/constants';
import Inspectors from '../Inspectors';
import Footer from './components/Footer';
import SavedFilters from './components/SavedFilters';
import StartOfJob from './components/StartOfJob';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import style from './style.module.scss';

const MassSelection = props => {
  const { t } = useTranslation('newMassSelection');
  const globalFilters = useRef(null);
  const customFields = useRef(null);
  const formValuesRef = useRef({ mode: 3, concreteDate: dayjs().format('YYYY-MM-DD') });

  const [openSavedFilters, setOpenSavedFilters] = useState(false);
  const [openCustomMail, setOpenCustomMail] = useState(false);

  const [isError, setIsError] = useState(false);
  const [saveFilter, setSaveFilter] = useState(false);
  const [isInspectors, setIsInspectors] = useState({ visible: false, deadlineDate: null, inspectors: [] });
  const [isPeriodicAssignment, setIsPeriodicAssignment] = useState(false);
  const [openSave, setOpenSave] = useState(false);

  const handleExportUsers = () => {
    props.exportSelectedUsers(formValuesRef.current, downloadFile);
  };

  const footer = (
    <Footer
      getCountUsers={props.getCountUsers}
      onMassOk={props.onMassOk}
      formValuesRef={formValuesRef}
      isLoadingMass={props.isLoadingMass}
      countSelectedUsers={props.countSelectedUsers}
      exportUsers={handleExportUsers}
      isInspectors={isInspectors}
      isPeriodicAssignment={isPeriodicAssignment}
      setIsError={setIsError}
      setSaveFilter={setSaveFilter}
      saveFilter={saveFilter}
      openSave={openSave}
      setOpenSave={setOpenSave}
    />
  );

  useEffect(() => {
    if (props.getCountUsers) props.getCountUsers(formValuesRef.current);
  }, []);

  useEffect(() => {
    if (props.isTab === MASS) props.setFooter(footer);
  }, [
    props.isTab,
    formValuesRef,
    formValuesRef.current,
    props.countSelectedUsers,
    isPeriodicAssignment,
    saveFilter,
    openSave,
    isInspectors,
  ]);

  useEffect(() => {
    setIsPeriodicAssignment(formValuesRef.current?.isPeriodicAssignment);
  }, [formValuesRef.current?.isPeriodicAssignment]);

  const handleValuesChange = (changedValues, allValues) => {
    const [key] = Object.keys(changedValues);
    const resetStartJobFields = {
      plusDaysDeadline: undefined,
      postpone: undefined,
      plusDaysStudyPlan: undefined,
      startOfJobTo: undefined,
      launchDate: undefined,
    };

    if (key === 'mode') {
      formValuesRef.current = {
        ...formValuesRef.current,
        mode: changedValues[key],
        concreteDate: changedValues[key] === 3 ? dayjs().format('YYYY-MM-DD') : undefined,
        startOfJobFrom: changedValues[key] === 3 ? undefined : dayjs().format('YYYY-MM-DD'),
        ...resetStartJobFields,
      };
    } else {
      formValuesRef.current = { ...formValuesRef.current, ...allValues };
    }

    if (props.getCountUsers) props.getCountUsers(formValuesRef.current);

    if (Object.keys(changedValues)?.includes('isPeriodicAssignment')) {
      setIsPeriodicAssignment(changedValues?.isPeriodicAssignment);
    }
  };

  const downloadFile = blob => {
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.click();
  };

  const onSubmitEmail = email => {
    formValuesRef.current = { ...formValuesRef.current, ...email };
    setOpenCustomMail(false);
  };

  return (
    <div className={style['mass-selection']}>
      <Typography.Title className={style['mass-selection__title']} level={2}>
        {props.titleModalMass}
      </Typography.Title>
      <GlobalFilters ref={globalFilters} formValues={formValuesRef.current} onValuesChange={handleValuesChange} />
      <CustomFields ref={customFields} formValues={formValuesRef.current} onValuesChange={handleValuesChange} />
      <StartOfJob
        onValuesChange={handleValuesChange}
        isTopic={props.isTopic}
        isError={isError}
        saveFilter={saveFilter}
        formValuesRef={formValuesRef}
        openSave={openSave}
        isInspectors={isInspectors}
      />
      {!!props.getCountUsers && (
        <UsersCount
          isLoadingCountSelectedUsers={props.isLoadingCountSelectedUsers}
          exportUsers={handleExportUsers}
          countSelectedUsers={props.countSelectedUsers}
          titleSelectedUser={props.titleSelectedUser}
        />
      )}
      <div className={style['mass-selection__settings']}>
        <div className={style['mass-selection__block']} onClick={() => setOpenSavedFilters(true)}>
          <FileTextOutlined className={style['mass-selection__icon']} />
          <Typography.Text>{t('savedFilters')}</Typography.Text>
        </div>
        <div className={style['mass-selection__block']} onClick={() => setOpenCustomMail(true)}>
          <SettingOutlined className={style['mass-selection__icon']} />
          <Typography.Text>{t('settingTemplate')}</Typography.Text>
        </div>
      </div>
      {!props.hideInspectors && (
        <Inspectors
          setIsInspectors={setIsInspectors}
          isInspectors={isInspectors}
          isPeriodicAssignment={isPeriodicAssignment}
        />
      )}
      <SavedFilters
        open={openSavedFilters}
        onClose={() => setOpenSavedFilters(false)}
        getSavedFilters={props.getSavedFilters}
      />
      <CustomMail open={openCustomMail} onClose={() => setOpenCustomMail(false)} onSubmit={onSubmitEmail} />
    </div>
  );
};

MassSelection.propTypes = {
  isTab: PropTypes.string,
};

export default MassSelection;
