import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteQuestion, getQuestionsList, setActiveSort, setIsSort } from '@/store/questionBank/actions';

import { selectQuestionBank } from '@/store/questionBank/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Modal, Popover, Table, Tag, Tooltip } from 'antd';
import { QUESTION_TYPES, QUESTIONS_TABLE_SIZE, QUESTIONS_TABLE_SIZE_MODAL } from '@/pages/QuestionBank/constants';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import Utils from '@/Utils';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './BankTable.module.scss';

import { QUESTION_BANK_DELETE, QUESTION_BANK_UPDATE } from '@/constants/permissions';

const MAX_QUESTION_LENGTH = 45;
const MAX_TAGS_RENDER = 3;

const BankTable = ({ changeEditModal, filters, canSelect, rowSelection }) => {
  const { t } = useTranslation('QuestionBank');
  const { bankList, isLoading, isSort, activeSort } = useSelector(selectQuestionBank);
  const { currentUser } = useSelector(selectUsers);
  const dispatch = useDispatch();
  const pageable = canSelect ? QUESTIONS_TABLE_SIZE_MODAL : QUESTIONS_TABLE_SIZE;

  const renderCategories = categories => {
    if (Array.isArray(categories) && !_.isEmpty(categories)) {
      // Вплывающее окно с остальными тегами
      const popoverContent = categories.slice(MAX_TAGS_RENDER, categories.length).map(category => (
        <Tag key={category.id} className={css['BankTable-tag']}>
          {category.name}
        </Tag>
      ));

      return (
        <>
          {categories.map(
            (category, index) =>
              MAX_TAGS_RENDER > index && (
                <Tooltip key={category.id} title={category.name.length > MAX_QUESTION_LENGTH && category.name}>
                  <Tag className={css['BankTable-tag']}>{Utils.trString(category.name, MAX_QUESTION_LENGTH)}</Tag>
                </Tooltip>
              )
          )}
          {MAX_TAGS_RENDER < categories.length && (
            <Popover content={<div className={css['BankTable-tag-popover']}>{popoverContent}</div>}>
              <Button type='link' size='small'>
                {t('more', { count: categories.length - MAX_TAGS_RENDER })}
              </Button>
            </Popover>
          )}
        </>
      );
    }
  };

  const renderType = actions => {
    if (actions.type === 1) {
      if (actions.multipleAnswers) return actions.type;

      return 0; // Возвращаем 0 для массива в locales, чтобы он взял текст одиночный вариант ответа
    }

    return actions.type;
  };

  const columns = [
    {
      title: t('question'),
      dataIndex: 'question',
      key: 'question',
      width: '35%',
      render: text => (
        <Tooltip title={text.length > MAX_QUESTION_LENGTH && text}>{Utils.trString(text, MAX_QUESTION_LENGTH)}</Tooltip>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'answerType',
      key: 'answerType',
      width: '25%',
      render: (_, { actions }) => t(`answerType.${renderType(actions)}`),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
      width: '25%',
      render: (text, record) => renderCategories(record.category),
    },
    {
      ...(!canSelect && {
        title: t('actions'),
        key: 'actions',
        width: '15%',
        render: (text, { actions }) => (
          <span>
            {currentUser?.authorities?.includes(QUESTION_BANK_UPDATE) && (
              <Tooltip placement='top' title={t('edit')}>
                <FormOutlined
                  className={css['BankTable-actions-edit']}
                  onClick={() => changeEditModal(true, actions)}
                />
              </Tooltip>
            )}

            {currentUser?.authorities?.includes(QUESTION_BANK_DELETE) && (
              <Tooltip placement='top' title={t('delete')}>
                <DeleteOutlined
                  className={css['BankTable-actions-delete']}
                  onClick={() =>
                    Modal.confirm({
                      title: t('deleteQuestionTitle'),
                      content: t('deleteQuestionText'),
                      okText: t('deleteBtn'),
                      okType: 'danger',
                      onOk: () => {
                        dispatch(
                          deleteQuestion(actions.id, () => {
                            dispatch(getQuestionsList(filters, undefined, isSort, pageable));
                          })
                        );
                      },
                    })
                  }
                />
              </Tooltip>
            )}
          </span>
        ),
      }),
    },
  ];

  const dataSource = useMemo(
    () =>
      Array.isArray(bankList?.list) &&
      !_.isEmpty(bankList?.list) &&
      bankList?.list.map(item => {
        const type =
          item.type === QUESTION_TYPES.single && item.multipleAnswers === true ? QUESTION_TYPES.multiple : item.type;

        return {
          key: item.id,
          question: item.question,
          answerType: type,
          category: item.questionCategories,
          actions: item,
        };
      }),
    [bankList?.list]
  );

  const onChangeTable = pagination => {
    dispatch(getQuestionsList(filters, pagination.current - 1, isSort, pageable));
  };

  const sortDate = () => {
    dispatch(setActiveSort({ id: !activeSort.id, aZ: false, zA: false }));
    dispatch(setIsSort(!activeSort.id ? 'id,desc' : ''));
    !activeSort.id
      ? dispatch(getQuestionsList(filters, bankList.pagination.number, 'id,desc', pageable))
      : dispatch(getQuestionsList(filters, bankList.pagination.number, '', pageable));
  };

  const sortQuestionName = (value, sortBy) => {
    dispatch(setActiveSort({ id: false, aZ: value === 'asc', zA: value === 'desc' }));

    dispatch(setIsSort(!activeSort[sortBy] ? `question,${value}` : ''));

    !activeSort[sortBy]
      ? dispatch(getQuestionsList(filters, bankList.pagination.number, `question,${value}`, pageable))
      : dispatch(getQuestionsList(filters, bankList.pagination.number, ``, pageable));
  };

  return (
    <div className={classNames(!canSelect && css['BankTable'])}>
      <div className={css['BankTable-sort']}>
        <span className={css['BankTable-sort-text']}>{t('sort')}</span>
        <span>{t('sortQuestionName')}</span>
        <span
          className={classNames(
            css['BankTable-sort-action'],
            css['BankTable-sort-action-az'],
            activeSort.aZ && css['BankTable-sort-active']
          )}
          onClick={() => sortQuestionName('asc', 'aZ')}
        >
          {t('aZ')}
        </span>
        <span
          className={classNames(css['BankTable-sort-action'], activeSort.zA && css['BankTable-sort-active'])}
          onClick={() => sortQuestionName('desc', 'zA')}
        >
          {t('zA')}
        </span>

        <span className={css['BankTable-sort-vertical']}></span>

        <span
          className={classNames(css['BankTable-sort-action'], activeSort.id && css['BankTable-sort-active'])}
          onClick={sortDate}
        >
          {t('sortDate')}
        </span>
      </div>
      <Table
        key='bankTable'
        columns={columns}
        dataSource={dataSource || []}
        loading={isLoading}
        pagination={{
          pageSize: bankList.pagination.size,
          total: bankList.pagination.totalElements,
          current: bankList.pagination.number + 1,
          showSizeChanger: false,
          responsive: true,
        }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      />
    </div>
  );
};

BankTable.propTypes = {
  changeEditModal: PropTypes.func,
  filters: PropTypes.object,
  canSelect: PropTypes.bool,
  rowSelection: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BankTable;
