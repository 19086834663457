import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addCustomQuestion } from '@/store/lms/actions';
import { getBankCategories } from '@/store/questionBank/actions';

import { selectBlocks } from '@/store/lms/selectors';
import { selectQuestionBank } from '@/store/questionBank/selectors';

import { Alert, Form, message, Modal, Select } from 'antd';
import { MAX_CATEGORY_LENGTH, MAX_CATEGORY_TAGS } from '@/pages/QuestionBank/constants';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './AddToBankModal.module.scss';

const { Item } = Form;
const { Option } = Select;

const AddToBankModal = ({ data, setData }) => {
  const { t } = useTranslation('AddToBankModal');
  const dispatch = useDispatch();
  const { categories, isLoadingCategories } = useSelector(selectQuestionBank);
  const { isLoadingCustom } = useSelector(selectBlocks);

  const [search, setSearch] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState([]);

  // При закрытии модального окна
  const onClose = () => setData({ ...data, visible: false });

  // Отдельная функция для получения категорий через debounce
  const handleGetBankCategories = category => dispatch(getBankCategories(category));

  // Запрос с debounce на получение категорий
  const getBankCategoriesDebounced = useCallback(_.debounce(handleGetBankCategories, 500), []);

  // Первоначальный запрос на категории и очистка данных при закрытии модального окна
  useEffect(() => {
    if (data.visible === true) {
      dispatch(getBankCategories());
    } else {
      setSearch('');
      setSelectedCategory([]);
    }
  }, [data.visible]);

  // Поиск по категориям
  const handleSearchCategory = val => {
    const trimVal = val?.trim();
    if (trimVal?.length <= MAX_CATEGORY_LENGTH) {
      setSearch(trimVal);
      getBankCategoriesDebounced(trimVal);
    }
  };

  // Генерация категорий
  const optionsCetegory = useMemo(
    () =>
      categories.map(category => (
        <Option key={category.id} label={category.name}>
          {category.name}
        </Option>
      )),
    [categories, search]
  );

  // При создании новой категории
  const onCreateNewCategory = () => {
    getBankCategoriesDebounced('');
    setSearch('');
  };

  // Изменение категорий
  const onChangeCategory = value => {
    if (Array.isArray(value) && value.length <= MAX_CATEGORY_TAGS) {
      setSelectedCategory(value);
    } else {
      message.error(t('maxTags', { max: MAX_CATEGORY_TAGS }));
    }
  };

  // Добавление вопроса в банк
  const onAddQuestionInBank = () => {
    // Корректные данные для категорий
    const questionCategory = selectedCategory.map(category => {
      if (category.key === category.label) {
        // Новая категория
        return {
          id: null,
          name: category.label,
        };
      } else {
        // Существующая категория
        return {
          id: +category.key,
          name: category.label,
        };
      }
    });

    // Запрос на добавление вопроса в банк
    dispatch(
      addCustomQuestion(data.questionId, questionCategory, data.blockId, () => {
        onClose();
        message.success(t('successAdded'));
      })
    );
  };

  return (
    <Modal
      title={t('title')}
      open={data.visible}
      onCancel={onClose}
      width={640}
      okText={t('okText')}
      onOk={onAddQuestionInBank}
      okButtonProps={{
        disabled: selectedCategory.length < 1 || isLoadingCustom,
      }}
    >
      <Alert className={css['AddToBankModal_text']} description={t('content')} closable />
      <Form layout='vertical'>
        <Item label={t('category')}>
          <Select
            className={css['AddToBankModal_select']}
            placeholder={t('categoryPlaceholder')}
            onSearch={handleSearchCategory}
            optionFilterProp='children'
            optionLabelProp='label'
            value={selectedCategory}
            loading={isLoadingCategories}
            maxTagTextLength={40} // Влияет только на отображение в select'e
            autoClearSearchValue
            mode='multiple'
            labelInValue
            showSearch
            // showArrow
            onChange={onChangeCategory}
            disabled={isLoadingCustom}
            notFoundContent={selectedCategory.length === MAX_CATEGORY_TAGS && t('maxTags', { max: MAX_CATEGORY_TAGS })}
          >
            {search &&
              Array.isArray(selectedCategory) &&
              selectedCategory.length < MAX_CATEGORY_TAGS &&
              !_.some(categories, { name: search }) &&
              !isLoadingCategories && (
                <Option key={search} onClick={() => onCreateNewCategory()} label={search}>
                  {search}
                  {t('newCategory')}
                </Option>
              )}
            {optionsCetegory}
          </Select>
        </Item>
      </Form>
    </Modal>
  );
};

AddToBankModal.propTypes = {
  data: PropTypes.shape({
    questionId: PropTypes.number,
    visible: PropTypes.bool,
  }),
  setData: PropTypes.func,
};

export default AddToBankModal;
