/* eslint-disable */
import { defaultMaterialImage, materialIcon } from '../constants/topic-image';
import { TopicTypes } from '../constants/topics';

import _curry from 'lodash/curry';
import _isFunction from 'lodash/isFunction';

export const checkTopicType = _curry(
  (types, type) => !!types.find(item => (_isFunction(item) ? item(type) : item === type))
);

export const isCourse = type => type === TopicTypes.Course;

export const isBook = type => type === TopicTypes.Book;

export const isSkills = type => type === TopicTypes.Skills;

export const isVideo = type => type === TopicTypes.Video;

export const isApplication = type => type === TopicTypes.Application;

export const isArticle = type => type === TopicTypes.Article;

export const isEvent = type => type === TopicTypes.Event;

export const isConference = type => type === TopicTypes.Conference;

export const isCorporateCourse = type => type === TopicTypes.CorporateCourse;

export const isExternalCourse = type => type === TopicTypes.ExternalCourse;

export const isExecutiveTask = type => type === TopicTypes.ExecutiveTask;

export const isCorporateCourseWithoutApprove = type => type === TopicTypes.CorporateCourseWithoutApprove;

export const isTestBooks = checkTopicType([isBook, isVideo, isApplication, isArticle, isCourse]);

export const isTestEvent = checkTopicType([isEvent]);

export const isTestCourses = checkTopicType([isConference, isCorporateCourse, isExternalCourse]);

export const isAnyCourse = checkTopicType([isCourse, isCorporateCourse, isExternalCourse]);

export const isAnyCourseOrConference = checkTopicType([isCourse, isCorporateCourse, isExternalCourse, isConference]);

export const isCorpOrExtCourse = checkTopicType([isCorporateCourse, isExternalCourse]);

export const isCorpOrExtCourseOrConf = checkTopicType([isCorporateCourse, isExternalCourse, isConference]);

export const isCorpCourseWithOrWithoutApprove = checkTopicType([isCorporateCourse, isCorporateCourseWithoutApprove]);

export const isCorpAllCourse = checkTopicType([isCorporateCourse, isCorporateCourseWithoutApprove, isExternalCourse]);

export const isEventOrConference = checkTopicType([isEvent, isConference]);

export const isConfOrAllCourse = checkTopicType([
  isCorporateCourse,
  isCorporateCourseWithoutApprove,
  isExternalCourse,
  isConference,
]);

export const isCorpOrExtCourseWithOrWithoutApp = checkTopicType([
  isCorporateCourse,
  isExternalCourse,
  isCorporateCourseWithoutApprove,
]);

export const isAnyCorporateOrExternalCourse = checkTopicType([
  isCorporateCourse,
  isCorporateCourseWithoutApprove,
  isExternalCourse,
]);

export const isExecutiveOrSkill = checkTopicType([isSkills, isExecutiveTask]);

export const getDefaultMaterialImage = id => defaultMaterialImage[id] || defaultMaterialImage['404'];

export const getMaterialIcon = id => materialIcon[id];

export const isOnlineSubType = id => id === 21 || id === 18;

export const isOfflineSubType = id => id === 22 || id === 19;

export const getWithoutSchedule = (typeId, withoutSchedule) => {
  switch (typeId) {
    case TopicTypes.Video:
    case TopicTypes.Book:
    case TopicTypes.Application:
    case TopicTypes.Course:
    case TopicTypes.Article:
      return true;
    default:
      return withoutSchedule;
  }
};

export const shouldCheckPreviousStudyPlans = topic => {
  if (!topic || !topic.type) {
    return false;
  }

  const result =
    isAnyCourseOrConference(topic.type.id) &&
    topic.type.approved &&
    topic.type.withEvents &&
    topic.cost > 0 &&
    topic.topicMandatory === 'OPTIONAL';

  return result;
};
