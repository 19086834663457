/* eslint-disable */
import {
  CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_FAIL,
  CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_START,
  CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS,
  DELETE_CUSTOM_BY_ONE_NOTIFICATION_START,
  DELETE_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
  DELETE_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  GET_CUSTOM_BY_ALL_NOTIFICATIONS_FAIL,
  GET_CUSTOM_BY_ALL_NOTIFICATIONS_START,
  GET_CUSTOM_BY_ALL_NOTIFICATIONS_SUCCESS,
  GET_CUSTOM_BY_ONE_NOTIFICATION_START,
  GET_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
  GET_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_START,
  PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
  PUBLISH_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
} from './constants';

const initialState = {
  customByAllNotifications: [],
  totalCountNotifications: 0,
  isByAllNotificationsLoading: false,
  isAllByNotificationsError: false,

  customByOneNotification: {},
  isByOneNotificationLoading: false,
  isByOneNotificationError: false,

  isCreateOrUpdateLoading: false,
  isCreateOrUpdateError: false,

  isDeleteLoading: false,
  isDeleteError: false,

  isPublishLoading: false,
  isPublishError: false,
};

export default (state = initialState, { type, response, action }) => {
  switch (type) {
    // +++++ Создание или обновления уведомления +++++
    case CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_START:
      return { ...state, isCreateOrUpdateLoading: true, isCreateOrUpdateError: false };

    case CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS:
      let reBuildCustomByAllNotifications = state.customByAllNotifications;

      if (action.isCreate) {
        reBuildCustomByAllNotifications = [response.notification, ...reBuildCustomByAllNotifications];
      } else {
        const index = state.customByAllNotifications.findIndex(el => el.id === response.id);
        reBuildCustomByAllNotifications[index] = {
          ...reBuildCustomByAllNotifications[index],
          subject: response.subject,
          message: response.message,
          activeTill: response.activeTill,
          url: response.url,
        };
      }

      return { ...state, isCreateOrUpdateLoading: false, customByAllNotifications: reBuildCustomByAllNotifications };

    case CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_FAIL:
      return { ...state, isCreateOrUpdateLoading: false, isCreateOrUpdateError: true };
    // ----- Создание или обновления уведомления -----

    // +++++ Получение всех уведомлений +++++
    case GET_CUSTOM_BY_ALL_NOTIFICATIONS_START:
      return { ...state, isByAllNotificationsLoading: true, isAllByNotificationsError: false };

    case GET_CUSTOM_BY_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isByAllNotificationsLoading: false,
        customByAllNotifications: response.page,
        totalCountNotifications: response.totalCount - 1,
      };

    case GET_CUSTOM_BY_ALL_NOTIFICATIONS_FAIL:
      return { ...state, isByAllNotificationsLoading: false, isAllByNotificationsError: true };
    // ----- Получение всех уведомлений -----

    // +++++ Получение одного уведомления по ИД +++++
    case GET_CUSTOM_BY_ONE_NOTIFICATION_START:
      return { ...state, isByOneNotificationLoading: true, isByOneNotificationError: false };

    case GET_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS:
      return { ...state, isByOneNotificationLoading: false, customByOneNotification: response };

    case GET_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL:
      return { ...state, isByOneNotificationLoading: false, isByOneNotificationError: true };
    // ----- Получение одного уведомления по ИД -----

    // +++++ Удаление одного уведомления по ИД +++++
    case DELETE_CUSTOM_BY_ONE_NOTIFICATION_START:
      return { ...state, isDeleteLoading: true, isByAllNotificationsLoading: true, isDeleteError: false };

    case DELETE_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS:
      const updateCustomByAllNotifications = state.customByAllNotifications.splice(
        state.customByAllNotifications.findIndex(el => el.id === action.paramId),
        1
      );

      return {
        ...state,
        isDeleteLoading: false,
        isByAllNotificationsLoading: false,
        customByAllNotification: updateCustomByAllNotifications,
      };

    case DELETE_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL:
      return { ...state, isDeleteLoading: false, isByAllNotificationsLoading: false, isDeleteError: true };
    // ----- Удаление одного уведомления по ИД -----

    // +++++ Получение одного уведомления по ИД +++++
    case PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_START:
      return { ...state, isPublishLoading: true, isPublishError: false };

    case PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS:
      const updateAfterPublished = state.customByAllNotifications;
      const findIndex = updateAfterPublished.findIndex(el => el.id === action.paramId);
      if (findIndex !== -1) updateAfterPublished[findIndex].status = 'PUBLISHED';
      return { ...state, isPublishLoading: false, customByAllNotifications: updateAfterPublished };

    case PUBLISH_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL:
      return { ...state, isPublishLoading: false, isPublishError: true };
    // ----- Получение одного уведомления по ИД -----

    default:
      return state;
  }
};
