export const GET_SHORT_POKA_START = 'GET_SHORT_POKA_START';
export const GET_SHORT_POKA_SUCCESS = 'GET_SHORT_POKA_SUCCESS';
export const GET_SHORT_POKA_FAIL = 'GET_SHORT_POKA_FAIL';

export const GET_CHECKLIST_POKA_START = 'GET_CHECKLIST_POKA_START';
export const GET_CHECKLIST_POKA_SUCCESS = 'GET_CHECKLIST_POKA_SUCCESS';
export const GET_CHECKLIST_POKA_FAIL = 'GET_CHECKLIST_POKA_FAIL';

export const PUT_CHECKLIST_POKA_START = 'PUT_CHECKLIST_POKA_START';
export const PUT_CHECKLIST_POKA_SUCCESS = 'PUT_CHECKLIST_POKA_SUCCESS';
export const PUT_CHECKLIST_POKA_FAIL = 'PUT_CHECKLIST_POKA_FAIL';

export const GET_STATE_POKA_START = 'GET_STATE_POKA_START';
export const GET_STATE_POKA_SUCCESS = 'GET_STATE_POKA_SUCCESS';
export const GET_STATE_POKA_FAIL = 'GET_STATE_POKA_FAIL';
