/* eslint-disable */
import {
  CHANGE_STATUS_FAIL,
  CHANGE_STATUS_START,
  CHANGE_STATUS_SUCCESS,
  CLEAR_MENTEE_FILTERS,
  EXPORT_MENTEE_FAIL,
  EXPORT_MENTEE_START,
  EXPORT_MENTEE_SUCCESS,
  GET_MENTEE_FAIL,
  GET_MENTEE_START,
  GET_MENTEE_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  POST_MENTEE_FILTERS_FAIL,
  POST_MENTEE_FILTERS_START,
  POST_MENTEE_FILTERS_SUCCESS,
  SAVE_MENTEE_FILTERS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  menteeFilters: [],
  menteeLoading: false,
  menteeTable: null,
  menteeTotal: null,
  menteePageSize: null,
  menteePageNumber: null,
  menteeCurrentPage: null,
  status: [],
  exportMenteeLoading: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_MENTEE_START:
    case GET_STATUS_START:
    case CHANGE_STATUS_START:
      return { ...state, isLoading: true };

    case POST_MENTEE_FILTERS_START:
      return { ...state, isLoading: true, menteeLoading: true };

    case EXPORT_MENTEE_START:
      return { ...state, exportMenteeLoading: true };

    case EXPORT_MENTEE_SUCCESS:
      return { ...state, exportMenteeLoading: false };

    case GET_MENTEE_SUCCESS:
      return { ...state, isLoading: false, mentee: response };

    case GET_STATUS_SUCCESS:
      return { ...state, isLoading: false, status: response };

    case POST_MENTEE_FILTERS_SUCCESS:
      return {
        ...state,
        menteeLoading: false,
        menteeTable: response.content,
        menteeTotal: response.totalElements,
        menteePageSize: response.size,
        menteePageNumber: response.totalPages,
        menteeCurrentPage: response.number,
      };

    case CHANGE_STATUS_SUCCESS:
      return { ...state, isLoading: false };

    case EXPORT_MENTEE_FAIL:
      return { ...state, exportMenteeLoading: false, isError: true };

    case GET_MENTEE_FAIL:
    case GET_STATUS_FAIL:
    case CHANGE_STATUS_FAIL:
      return { ...state, isLoading: false, isError: true };

    case POST_MENTEE_FILTERS_FAIL:
      return { ...state, isLoading: false, isError: true, menteeLoading: false };

    case SAVE_MENTEE_FILTERS:
      const filters = { ...state.menteeFilters, filters: value };
      return { ...state, menteeFilters: filters };

    case CLEAR_MENTEE_FILTERS:
      const clearFilters = { ...state.menteeFilters, filters: value };
      return { ...state, menteeFilters: clearFilters };

    default:
      return { ...state };
  }
};
