// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-gZa2zo.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-ojTOMu .admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-label-W0kKWx[data-value="Montserrat"]:before, .admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-gZa2zo.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-ojTOMu .admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-item-bcobPU[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-Montserrat-s48QNE {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-gLh7h2 {
  margin-top: 17px;
}

.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-lTbyTr {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-datePicker-E6hJ93 {
  margin-left: 8px;
}

.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-check-isItLn {
  margin: 24px 0;
}

.admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-footer-YDHTuw {
  justify-content: flex-end;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/VarDirectory/Tabs/SettingRequests/SettingRequests.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AAMA;EAHE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;;AAKA;EAFE,gBAAgB;AAClB;;AAIA;EADE,cAAc;AAChB;;AAGA;EAAE,yBAAyB;EACzB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.SettingRequests {\n  margin-top: 17px;\n}\n.SettingRequests-content {\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 20px;\n}\n.SettingRequests-content-datePicker {\n  margin-left: 8px;\n}\n.SettingRequests-content-check {\n  margin: 24px 0;\n}\n.SettingRequests-content-footer {\n  display: flex;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-gZa2zo`,
	"qlPicker": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-gZa2zo`,
	"ql-font": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-ojTOMu`,
	"qlFont": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-ojTOMu`,
	"ql-picker-label": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-label-W0kKWx`,
	"qlPickerLabel": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-label-W0kKWx`,
	"ql-picker-item": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-item-bcobPU`,
	"qlPickerItem": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-picker-item-bcobPU`,
	"ql-font-Montserrat": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-Montserrat-s48QNE`,
	"qlFontMontserrat": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__ql-font-Montserrat-s48QNE`,
	"SettingRequests": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-gLh7h2`,
	"settingRequests": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-gLh7h2`,
	"SettingRequests-content": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-lTbyTr`,
	"settingRequestsContent": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-lTbyTr`,
	"SettingRequests-content-datePicker": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-datePicker-E6hJ93`,
	"settingRequestsContentDatePicker": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-datePicker-E6hJ93`,
	"SettingRequests-content-check": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-check-isItLn`,
	"settingRequestsContentCheck": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-check-isItLn`,
	"SettingRequests-content-footer": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-footer-YDHTuw`,
	"settingRequestsContentFooter": `admin-src-pages-VarDirectory-Tabs-SettingRequests-SettingRequests-module__SettingRequests-content-footer-YDHTuw`
};
export default ___CSS_LOADER_EXPORT___;
