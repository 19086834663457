export const POKA_STATUS = {
  FIRST_PASS: 'FIRST_PASS', //  В процессе освоения Желтый
  REPASS: 'REPASS', // В процессе подтверждения Желтый
  EXPIRED: 'EXPIRED', // Просрочен Красный
  FINISHED: 'FINISHED', // Освоен Зеленый
};

export const TAG_POKA_STATUS = {
  FIRST_PASS: 'yellow',
  REPASS: 'yellow',
  EXPIRED: 'red',
  FINISHED: 'success',
};

export const POKA_PIE_SIZES = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};
