export const D360_CREATE_SCORE_START = 'D360_CREATE_SCORE_START';
export const D360_CREATE_SCORE_SUCCESS = 'D360_CREATE_SCORE_SUCCESS';
export const D360_CREATE_SCORE_FAIL = 'D360_CREATE_SCORE_FAIL';

export const D360_UPDATE_SCORE_START = 'D360_UPDATE_SCORE_START';
export const D360_UPDATE_SCORE_SUCCESS = 'D360_UPDATE_SCORE_SUCCESS';
export const D360_UPDATE_SCORE_FAIL = 'D360_UPDATE_SCORE_FAIL';

export const D360_DELETE_SCORE_START = 'D360_DELETE_SCORE_START';
export const D360_DELETE_SCORE_SUCCESS = 'D360_DELETE_SCORE_SUCCESS';
export const D360_DELETE_SCORE_FAIL = 'D360_DELETE_SCORE_FAIL';

export const D360_GET_SCORE_START = 'D360_GET_SCORE_START';
export const D360_GET_SCORE_SUCCESS = 'D360_GET_SCORE_SUCCESS';
export const D360_GET_SCORE_FAIL = 'D360_GET_SCORE_FAIL';

export const D360_GET_SCORES_START = 'D360_GET_SCORES_START';
export const D360_GET_SCORES_SUCCESS = 'D360_GET_SCORES_SUCCESS';
export const D360_GET_SCORES_FAIL = 'D360_GET_SCORES_FAIL';
