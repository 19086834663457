// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-AddressBook-AddressBook-module__ql-picker-xrauDJ.admin-src-components-AddressBook-AddressBook-module__ql-font-t9wX9P .admin-src-components-AddressBook-AddressBook-module__ql-picker-label-HaPtvk[data-value="Montserrat"]:before, .admin-src-components-AddressBook-AddressBook-module__ql-picker-xrauDJ.admin-src-components-AddressBook-AddressBook-module__ql-font-t9wX9P .admin-src-components-AddressBook-AddressBook-module__ql-picker-item-fndojM[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-AddressBook-AddressBook-module__ql-font-Montserrat-xe0RG3 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-AddressBook-AddressBook-module__AddressBookList-QhNTrM {
  border: 1px solid #ecf0f0;
  max-height: 200px;
  margin-top: 30px;
  padding: 10px;
  overflow-y: scroll;
}

.admin-src-components-AddressBook-AddressBook-module__AddressBookListItem-fJXzt2 {
  border-radius: 4px;
  padding: 0 10px;
}

.admin-src-components-AddressBook-AddressBook-module__AddressBookListItem-fJXzt2:hover {
  background-color: #ecf0f0;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/AddressBook/AddressBook.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;;AAOA;EAJE,kBAAkB;EAClB,eAAe;AACjB;;AAOA;EAJE,yBAAyB;AAC3B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.AddressBookList {\n  margin-top: 30px;\n  max-height: 200px;\n  overflow-y: scroll;\n  padding: 10px;\n  border: 1px solid #ecf0f0;\n}\n\n.AddressBookListItem {\n  padding: 0 10px;\n  border-radius: 4px;\n}\n\n.AddressBookListItem:hover {\n  background-color: #ecf0f0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-AddressBook-AddressBook-module__ql-picker-xrauDJ`,
	"qlPicker": `admin-src-components-AddressBook-AddressBook-module__ql-picker-xrauDJ`,
	"ql-font": `admin-src-components-AddressBook-AddressBook-module__ql-font-t9wX9P`,
	"qlFont": `admin-src-components-AddressBook-AddressBook-module__ql-font-t9wX9P`,
	"ql-picker-label": `admin-src-components-AddressBook-AddressBook-module__ql-picker-label-HaPtvk`,
	"qlPickerLabel": `admin-src-components-AddressBook-AddressBook-module__ql-picker-label-HaPtvk`,
	"ql-picker-item": `admin-src-components-AddressBook-AddressBook-module__ql-picker-item-fndojM`,
	"qlPickerItem": `admin-src-components-AddressBook-AddressBook-module__ql-picker-item-fndojM`,
	"ql-font-Montserrat": `admin-src-components-AddressBook-AddressBook-module__ql-font-Montserrat-xe0RG3`,
	"qlFontMontserrat": `admin-src-components-AddressBook-AddressBook-module__ql-font-Montserrat-xe0RG3`,
	"AddressBookList": `admin-src-components-AddressBook-AddressBook-module__AddressBookList-QhNTrM`,
	"addressBookList": `admin-src-components-AddressBook-AddressBook-module__AddressBookList-QhNTrM`,
	"AddressBookListItem": `admin-src-components-AddressBook-AddressBook-module__AddressBookListItem-fJXzt2`,
	"addressBookListItem": `admin-src-components-AddressBook-AddressBook-module__AddressBookListItem-fJXzt2`
};
export default ___CSS_LOADER_EXPORT___;
