/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Popover, Select, Tag, Tooltip, Typography } from 'antd';
import { renderIcon, setColorTag } from '@/pages/Inspector/constants';
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

import ActionButtonsMenu from './ActionButtonsMenu';
import CommentsBlock from './CommentsBlock';
import ShowCourseStatus from './ShowCourseStatus';

import { isConfOrAllCourse } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import _ from 'lodash';

import css from './styles.module.scss';

import { REJECTED, REJECTED_BY_MANAGER } from '@/constants/statuses';

export const ColumnsType = {
  WITH_EVENT: 'WITH_EVENT',
  COURSES: 'COURSES',
};

export const useColumnsValues = () => {
  const { t } = useTranslation(['topicDetailsDialog', 'constants']);

  const [value, setState] = useState([]);

  const renderDates = val => {
    const dates = item => item && dayjs(item).format('DD.MM.YYYY');
    const topicTypeId = val?.plan?.topic?.type?.id;
    const endDates = val?.courseEvent?.endDate;
    const startDates = val.plan.startDate;

    if (isConfOrAllCourse(topicTypeId)) {
      if (endDates) {
        return dates(endDates);
      } else {
        return dates(startDates);
      }
    } else {
      return dates(startDates);
    }
  };

  const setColumnsValue = (
    columnsType,
    filter,
    currentStatus,
    managers,
    isLoadingFullnameManager,
    checkTopicTypeId
  ) => {
    const columnsWithInspector = [
      {
        title: t('inspect'),
        dataIndex: 'inspection',
        align: 'center',
        render: (value, record) => {
          const data = value ?? record.plan.inspection;
          return (
            <Popover content={popoverContent(data?.status, data?.deadlineDate, data?.inspectors)}>
              {renderIcon(data?.status.value)}
            </Popover>
          );
        },
      },
    ];

    const baseColumns = [
      {
        title: t('fullName'),
        dataIndex: 'fullName',
        className: css['columns--fullname'],
        width: '220px',
        fixed: 'left',
        render: (_text, record) => (
          <>
            {record.plan.user.firstName || ''} <br /> {record.plan.user.lastName || ''}
          </>
        ),
      },
      {
        title: t('status2'),
        dataIndex: 'status',
        width: '150px',
        render: (ext, record) => (
          <ShowCourseStatus
            statusValue={record.plan.status}
            mentionValue={record.plan.mention}
            info={record.info || record.plan.info}
          />
        ),
      },
      {
        title: () => (
          <div className={css['columns--message']}>
            <span className={css['columns-text']}>{t('progress')}</span>
            <Tooltip title={t('progressTooltip')}>
              <QuestionCircleOutlined className={css['columns-icon']} />
            </Tooltip>
          </div>
        ),
        dataIndex: 'progress',
        width: '90px',
        filterMultiple: false,
        render: (ext, record) => {
          return <b>{(record?.plan?.progress ?? record?.progress) || 0}%</b>;
        },
      },
      {
        title: checkTopicTypeId ? t('trainingStartDate') : t('date/month'),
        className: css['columns--date'],
        width: '8.5em',
        dataIndex: 'plan.startDate',
        render: (txt, val) => <span>{renderDates(val)}</span>,
      },
      {
        width: '5em',
        title: t('spentTime'),
        dataIndex: 'plan.spentTime',
        filterMultiple: false,
        render: (ext, record) => (
          <span className={css['time-off']}>
            <b>{record.plan.spentTime || record.spentTime || 0}</b> ч.
          </span>
        ),
      },
      {
        title: t('division'),
        dataIndex: 'function',
        render: (ext, record) => record.plan.user.function,
      },
      {
        title: t('department'),
        dataIndex: 'department',
        filterSearch: true,
        filterMultiple: false,
        render: (ext, record) => record.plan.user.department,
      },
      {
        title: t('manager'),
        dataIndex: 'managerId',
        render: (ext, record) => record.plan.user?.managers[0]?.fullName,
      },
      ...columnsWithInspector,
      {
        width: '5em',
        align: 'center',
        title: () => (
          <div className={css['columns--message']}>
            <CommentOutlined />
          </div>
        ),
        dataIndex: 'plan.comments',
        render: (text, record) => <>{record.plan.id && <CommentsBlock courses={record} />}</>,
      },
    ];

    const columns = [
      {
        title: (
          <Tooltip title={t('action')}>
            <ArrowRightOutlined className={css['columns--action']} />
          </Tooltip>
        ),
        width: '50px',
        dataIndex: 'operation',
        align: 'center',
        fixed: 'right',
        render: (text, record) => <ActionButtonsMenu isAdminPlanMan courses={record} />,
      },
    ];

    const columnsWithEvent = [
      {
        title: t('approval'),
        dataIndex: 'approval',
        width: '5%',
        align: 'center',
        render: (ext, record) => {
          const topicTypeId = record.plan.topic && record.plan.topic.type.id;
          const statusCourse = record.plan.status;

          return (
            <>
              {record.plan.topic && topicTypeId !== 25 && (
                <>
                  {statusCourse === 'WAITING' ? (
                    <span>
                      <QuestionOutlined />
                    </span>
                  ) : statusCourse === REJECTED_BY_MANAGER || statusCourse === REJECTED ? (
                    <span>
                      <CloseOutlined />
                    </span>
                  ) : (
                    <span>
                      <CheckOutlined />
                    </span>
                  )}
                </>
              )}
            </>
          );
        },
      },
      {
        title: t('approved'),
        dataIndex: 'cost',
        width: '5%',
        align: 'center',
      },
      {
        title: t('unspentBudget'),
        dataIndex: 'reserve',
        width: '4%',
        align: 'center',
      },
    ];

    const popoverContent = (status, deadline, inspectors) => (
      <div className={css['popover__content']}>
        <Tag color={setColorTag(status?.value)}>
          {renderIcon(status?.value, true)}
          {status?.name}
        </Tag>
        <div className={css['popover__element']}>
          <Typography.Text className={css['popover__caption']}>{t('inspectDeadline')}</Typography.Text>
          <Typography.Text className={css['popover__date']}>{dayjs(deadline).format('DD.MM.YYYY')}</Typography.Text>
        </div>
        <Typography.Text className={css['popover__caption']}>{t('inspectors')}</Typography.Text>
        <div className={css['popover__inspectors']}>
          {inspectors?.map(({ name }, index) => (
            <Typography.Text className={css['popover__inspector']} key={index}>
              {name}
            </Typography.Text>
          ))}
        </div>
      </div>
    );

    if (columnsType === ColumnsType.WITH_EVENT) {
      setState([...baseColumns, ...columnsWithEvent, ...columns]);
    } else {
      setState([...baseColumns, ...columns]);
    }
  };

  return [value, setColumnsValue];
};
