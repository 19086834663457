// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-id0PaB.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-doVbwz .admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-label-LMHUzC[data-value="Montserrat"]:before, .admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-id0PaB.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-doVbwz .admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-item-Qk6sjw[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-Montserrat-JDbe3D {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-CCVjuF {
  font-size: 20px;
  line-height: 1;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-spin-jyxhSe {
  justify-content: center;
  display: flex;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-cf-mccVhU {
  margin-top: 8px;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-option-GDqWAo {
  color: var(--personalColor);
  font-weight: bolder;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-alert-xTJIT2 {
  width: 100%;
  margin-bottom: 16px;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-switch-CGQ3bf {
  margin-bottom: 8px;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-switch-text-k4ksB9 {
  margin-left: 16px;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-UxA2Fq {
  justify-content: flex-end;
  margin-top: 28px;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-button-Ca3pjP {
  margin-bottom: 16px;
}

.admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-button-Ca3pjP:nth-child(2) {
  margin: 0 16px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/TemplateBlockFilters/TemplateBlockFilters.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,eAAe;EACf,cAAc;AAChB;;AAMA;EAHE,uBAAuB;EACvB,aAAa;AACf;;AAKA;EAFE,eAAe;AACjB;;AAIA;EADE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAGA;EAAE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AACA;EAEE,iBAAiB;AACnB;;AAAA;EAGE,yBAAyB;EACzB,gBAAgB;AAClB;;AADA;EAIE,mBAAmB;AACrB;;AAFA;EAKE,cAAc;AAChB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.TemplateBlockFilters {\n  font-size: 20px;\n  line-height: 1;\n}\n.TemplateBlockFilters-spin {\n  display: flex;\n  justify-content: center;\n}\n.TemplateBlockFilters-cf {\n  margin-top: 8px;\n}\n.TemplateBlockFilters-option {\n  color: var(--personalColor);\n  font-weight: bolder;\n}\n.TemplateBlockFilters-alert {\n  margin-bottom: 16px;\n  width: 100%;\n}\n.TemplateBlockFilters-switch {\n  margin-bottom: 8px;\n}\n.TemplateBlockFilters-switch-text {\n  margin-left: 16px;\n}\n.TemplateBlockFilters-footer {\n  justify-content: flex-end;\n  margin-top: 28px;\n}\n.TemplateBlockFilters-footer-button {\n  margin-bottom: 16px;\n}\n.TemplateBlockFilters-footer-button:nth-child(2) {\n  margin: 0 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-id0PaB`,
	"qlPicker": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-id0PaB`,
	"ql-font": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-doVbwz`,
	"qlFont": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-doVbwz`,
	"ql-picker-label": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-label-LMHUzC`,
	"qlPickerLabel": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-label-LMHUzC`,
	"ql-picker-item": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-item-Qk6sjw`,
	"qlPickerItem": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-picker-item-Qk6sjw`,
	"ql-font-Montserrat": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-Montserrat-JDbe3D`,
	"qlFontMontserrat": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__ql-font-Montserrat-JDbe3D`,
	"TemplateBlockFilters": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-CCVjuF`,
	"templateBlockFilters": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-CCVjuF`,
	"TemplateBlockFilters-spin": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-spin-jyxhSe`,
	"templateBlockFiltersSpin": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-spin-jyxhSe`,
	"TemplateBlockFilters-cf": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-cf-mccVhU`,
	"templateBlockFiltersCf": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-cf-mccVhU`,
	"TemplateBlockFilters-option": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-option-GDqWAo`,
	"templateBlockFiltersOption": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-option-GDqWAo`,
	"TemplateBlockFilters-alert": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-alert-xTJIT2`,
	"templateBlockFiltersAlert": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-alert-xTJIT2`,
	"TemplateBlockFilters-switch": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-switch-CGQ3bf`,
	"templateBlockFiltersSwitch": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-switch-CGQ3bf`,
	"TemplateBlockFilters-switch-text": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-switch-text-k4ksB9`,
	"templateBlockFiltersSwitchText": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-switch-text-k4ksB9`,
	"TemplateBlockFilters-footer": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-UxA2Fq`,
	"templateBlockFiltersFooter": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-UxA2Fq`,
	"TemplateBlockFilters-footer-button": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-button-Ca3pjP`,
	"templateBlockFiltersFooterButton": `admin-src-components-TemplateBlockFilters-TemplateBlockFilters-module__TemplateBlockFilters-footer-button-Ca3pjP`
};
export default ___CSS_LOADER_EXPORT___;
