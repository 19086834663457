/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Avatar, Tooltip } from 'antd';
import Checkbox from '@/components/Checkbox';

import ChangePassword from './ChangePassword/ChangePassword';
import EditUser from './EditUserDialog';
import { More } from './More';
import PortraitPlaceholder from './PortraitPlaceholder.png';
import { isLDAPLogin } from './utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './Users.module.scss';

import { USER_INFO_READ } from '@/constants/permissions';
import LDAP_OFF from '@/assets/images/LDAP_off.svg';
import LDAP_ON from '@/assets/images/LDAP_on.svg';
import lock from '@/assets/images/lock.svg';

class StudentRow extends Component {
  static propTypes = {
    domainCompany: PropTypes.object,
    dataqa: PropTypes.string,
    getFile: PropTypes.func,
    rolesLDAP: PropTypes.bool,
    showITOD: PropTypes.bool,
    showCoin: PropTypes.func.isRequired,
    showProfile: PropTypes.func.isRequired,
    student: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    selectUser: PropTypes.func,
    toggleLocked: PropTypes.func,
    blockUser: PropTypes.func,
    toggleUserTrajectory: PropTypes.func,
    toggleUserLocked: PropTypes.func,
    changeUser: PropTypes.func,
    getStudents: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: PortraitPlaceholder,
      locked: props.student.locked,
      managerModalVisible: false,
      role: this.props.currentUser?.role,
      changePasswordModalVisible: false,
      changeUserError: '',
    };
  }

  componentDidMount() {
    const photoUuid = this.props.student.localPhotoUuid || this.props.student.photoUuid;
    if (photoUuid) {
      this.props.getFile(photoUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.student.localPhotoUuid && this.props.student.localPhotoUuid !== prevProps.student.localPhotoUuid) {
      this.props.getFile(this.props.student.localPhotoUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    } else if (this.props.student.photoUuid && this.props.student.photoUuid !== prevProps.student.photoUuid) {
      this.props.getFile(this.props.student.photoUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    }

    if (prevProps.student.locked !== this.props.student.locked) {
      this.setState({
        locked: this.props.student.locked,
      });
    }
  }

  isSuperAdmin = () => {
    return this.props.currentUser && Utils.isSuperAdmin(this.props.currentUser);
  };

  showProfile = () => {
    const { student, showProfile } = this.props;
    showProfile({ ...student, locked: this.state.locked });
  };

  toggleLocked = () => {
    const { student } = this.props;
    this.props.toggleLocked(student.id, !this.state.locked, res => {
      this.setState({
        locked: res.locked,
      });
    });
  };

  toggleSelectUser = e => {
    this.props.selectUser(e, this.props.student.id);
  };

  changeManagerModalVisibility = () => {
    this.setState({ managerModalVisible: !this.state.managerModalVisible });
  };

  changeStudent = data => {
    this.props.changeUser(
      data,
      () => {
        this.props.getStudents();
      },
      error => {
        this.setState({ changeUserError: error.message });
      }
    );
  };

  changePasswordModalVisibility = () => {
    this.setState({ changePasswordModalVisible: !this.state.changePasswordModalVisible });
  };

  render() {
    const { student, selectedUsers, showITOD, t, dataqa } = this.props;
    const DELETED_USER = _.eq(_.get(student, 'status'), 'DELETED');
    const ldapLogin = isLDAPLogin(this.props);
    const hasAdditionalInfo = _.get(this.props, 'domainCompany.hasAdditionalInfo');
    const hasManager = _.get(this.props, 'domainCompany.hasManager');

    return (
      <div data-qa={`${dataqa}`} className={css['Students-table-body-item']}>
        {showITOD && (
          <div className={css['Students-table-body-item-check']}>
            <Checkbox
              value={!!selectedUsers.find(x => x === student.id)}
              name='selectUser'
              onChange={this.toggleSelectUser}
            />
          </div>
        )}
        {ldapLogin && this.props.rolesLDAP && (
          <div className={css['Students-table-body-item-ldap']} style={{ position: 'relative' }}>
            <Tooltip
              placement='top'
              title={!this.state.locked ? t('can') : t('cant')}
              overlayStyle={{ wordBreak: 'break-all' }}
            >
              <div
                className={classNames(css['Lock-svg'], {
                  [css['Lock-active']]: !this.state.locked,
                })}
                onClick={this.toggleLocked}
              >
                <img src={this.state.locked ? LDAP_OFF : LDAP_ON} alt='' />
              </div>
            </Tooltip>
          </div>
        )}
        <div className={css['Students-table-body-item-avatar']}>
          <Avatar className={classNames({ [css.DeleteUser]: DELETED_USER })} src={this.state.imageSrc} />
          {DELETED_USER && (
            <Tooltip title={t('deleted')} overlayStyle={{ wordBreak: 'break-all' }}>
              <div
                data-qa='adminUsersUserBlocked'
                className={classNames(css['DeleteUser-key'], css['DeleteUser-key-fix8'])}
              >
                <img key='deleteUser' src={lock} alt='' />
              </div>
            </Tooltip>
          )}
        </div>
        <Tooltip title={`${student.lastName} ${student.firstName}`} overlayStyle={{ wordBreak: 'break-all' }}>
          <a
            data-qa={`${dataqa}Name`}
            className={css['Students-table-body-item-name']}
            onClick={this.props.currentUser.authorities.includes(USER_INFO_READ) ? this.showProfile : null}
          >
            {`${student.lastName || ''} ${student.firstName || ''}`}
          </a>
        </Tooltip>
        <Tooltip title={student.login} overlayStyle={{ wordBreak: 'break-all' }}>
          <div className={classNames(css['Students-table-body-item-field'])}>
            <span data-qa={`${dataqa}Login`} className={css['Students-table-body-overflow']}>
              {student.login}
            </span>
          </div>
        </Tooltip>
        {hasManager && (
          <Tooltip title={student.manager} overlayStyle={{ wordBreak: 'break-all' }}>
            <div className={classNames(css['Students-table-body-item-field'])}>
              <span data-qa={`${dataqa}Manager`} className={css['Students-table-body-overflow']}>
                {student.manager}
              </span>
            </div>
          </Tooltip>
        )}
        {hasAdditionalInfo && (
          <>
            <Tooltip title={student.position} overlayStyle={{ wordBreak: 'break-all' }}>
              <div className={css['Students-table-body-item-field']}>
                {' '}
                <span data-qa={`${dataqa}Position`} className={css['Students-table-body-overflow']}>
                  {student.position}
                </span>
              </div>
            </Tooltip>
            <Tooltip title={student.department} overlayStyle={{ wordBreak: 'break-all' }}>
              <div className={css['Students-table-body-item-field']}>
                <span data-qa={`${dataqa}Department`} className={css['Students-table-body-overflow']}>
                  {student.department}
                </span>
              </div>
            </Tooltip>
            <Tooltip title={student['function']} overlayStyle={{ wordBreak: 'break-all' }}>
              <div className={css['Students-table-body-item-field']}>
                {' '}
                <span data-qa={`${dataqa}Function`} className={css['Students-table-body-overflow']}>
                  {student['function']}
                </span>
              </div>
            </Tooltip>
          </>
        )}
        <div data-qa='adminUsersActionBtn' className={css['Students-table-body-item-action']}>
          {/* Просьба о том, чтобы убрать кнопку действия у руководителя (именно у роли руководитель) */}
          {this.props.currentUser.roleId !== 3 && (
            <More
              {...this.props}
              changeManagerModalVisibility={this.changeManagerModalVisibility}
              changePasswordModalVisibility={this.changePasswordModalVisibility}
            />
          )}
          {this.state.managerModalVisible && (
            <EditUser
              student={this.props.student}
              hasAdditionalInfo={hasAdditionalInfo}
              hasManager={hasManager}
              changeManagerModalVisibility={this.changeManagerModalVisibility}
              changeUser={this.changeStudent}
              isSuperAdmin={this.isSuperAdmin()}
              changeUserError={this.state.changeUserError}
            />
          )}
          {this.state.changePasswordModalVisible && (
            <ChangePassword
              user={this.props.student}
              changePasswordModalVisibility={this.changePasswordModalVisibility}
              open={this.state.changePasswordModalVisible}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('studentRow')(StudentRow);
