import {
  POST_UPLOAD_EXECUTIVE_REVIEW_FILE_FAIL,
  POST_UPLOAD_EXECUTIVE_REVIEW_FILE_START,
  POST_UPLOAD_EXECUTIVE_REVIEW_FILE_SUCCESS,
} from './constance';

const initialState = {
  isLoading: false,
  error: false,
};

/* eslint-disable */
export default (state = initialState, { type }) => {
  switch (type) {
    case POST_UPLOAD_EXECUTIVE_REVIEW_FILE_START:
      return { ...state, isLoading: true, error: false };

    case POST_UPLOAD_EXECUTIVE_REVIEW_FILE_SUCCESS:
      return { ...state, isLoading: false };

    case POST_UPLOAD_EXECUTIVE_REVIEW_FILE_FAIL:
      return { ...state, error: true, isLoading: false };

    default:
      return state;
  }
};
