import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateConsecutive } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Checkbox } from 'antd';
import TrackTopics from '@/components/TrackTopics';

import css from '../TrackDetail.module.scss';

import { TRACK_UPDATE } from '@/constants/permissions';

const TabContentSettings = () => {
  const { t } = useTranslation('trackDetail');
  const dispatch = useDispatch();

  const { trackDetails } = useSelector(selectTracks);
  const { currentUser } = useSelector(selectUsers);

  const renderTopics =
    trackDetails?.topics &&
    Array.isArray(trackDetails.topics) &&
    trackDetails.topics.length > 0 &&
    trackDetails.topics
      .sort((a, b) => a?.position - b?.position)
      .map((i, index) => {
        return <TrackTopics topicData={i} key={i.id} trackIndex={index} />;
      });

  const changeConsecutive = e => {
    if (trackDetails?.id && e) {
      let check = e.target?.checked;
      dispatch(updateConsecutive(trackDetails.id, check));
    }
  };

  return (
    <div className={css['Track-details-tabs-settings']}>
      <div className={css['Track-details-tabs-settings-check']}>
        <h2 className={css['Track-details-tabs-settings-check-title']}>{t('settings')}</h2>
        {currentUser?.authorities.includes(TRACK_UPDATE) && (
          <Checkbox defaultChecked={trackDetails?.consecutive} onChange={changeConsecutive}>
            {t('consecutive')}
          </Checkbox>
        )}
      </div>

      <div className={css['Track-details-tabs-settings-materials']}>
        <h2 className={css['Track-details-tabs-settings-materials-title']}>{t('materials')}</h2>
        {renderTopics}
      </div>
    </div>
  );
};

export default TabContentSettings;
