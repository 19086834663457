/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getRoles } from '@/store/access/actions';
import { getSubcompanies } from '@/store/companies/actions';
import { exportUsersModalVisibility, saveSettingsVisibility } from '@/store/settings_visibility/actions';
import { getDepartment, getFunction, getLocation, getPosition } from '@/store/students/actions';

import { selectAccess } from '@/store/access/selectors';
import { selectCompanies } from '@/store/companies/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Alert, Button, Col, Form, Row, Select, Spin, Switch, Tooltip } from 'antd';

import {
  ADD_CUSTOM_FIELDS,
  ALL,
  COMPANIES,
  COMPANY_NULL,
  CUSTOM_FIELDS,
  DELETE_CUSTOM_FIELDS,
  DEPARTMENTS,
  FUNCTIONS,
  LOCATIONS,
  POSITIONS,
  ROLES,
} from './constants';
import CustomFieldFilter from './CustomFieldFilter/CustomFieldFilter';
import UsersMaterial from './UsersMaterial/UsersMaterial';

import Utils from '@/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './TemplateBlockFilters.module.scss';

import {
  NEWS_VISIBILITY_FILTER_CREATE,
  NEWS_VISIBILITY_FILTER_DELETE,
  TOPIC_VISIBILITY_FILTER_CREATE,
  TOPIC_VISIBILITY_FILTER_DELETE,
} from '@/constants/permissions';
import { LEN_SHOW_TOOLTIP } from '@/constants/tooltip';

const { Option } = Select;

const TemplateBlockFilters = props => {
  const { t } = useTranslation('modalSettingsVisibility');
  const dispatch = useDispatch();

  const { functionStudent, department, position, location } = useSelector(selectStudents);
  const { subCompanies } = useSelector(selectCompanies);
  const { currentUser } = useSelector(selectUsers);
  const { company } = useSelector(selectLogin);
  const { roles } = useSelector(selectAccess);
  const { isExportLoading } = useSelector(selectSettingsVisibility);

  const [params, setParams] = useState({
    customFields: [{}],
  });
  const [init, setInit] = useState(false);
  const [initCount, setInitCount] = useState(false);
  const isSuperAdmin = currentUser && Utils.isSuperAdmin(currentUser);
  const currentCompany = currentUser?.company ? currentUser?.company : COMPANY_NULL;
  const [isVisible, setIsVisible] = useState(true);
  const [isData, setIsData] = useState({});
  // const [successExportUsers, setSuccessExportUsers] = useState(false);

  const previousController = useRef(null);

  const hasSubcompanies = company?.hasSubcompanies;

  useEffect(() => {
    dispatch(getFunction());
    dispatch(getDepartment());
    dispatch(getPosition());
    dispatch(getLocation());
    dispatch(getSubcompanies());
    dispatch(getRoles({ size: 500 }));

    return () => {
      // чистим стор при размонтировании
      if (props.clearStoreData && typeof props.clearStoreData === 'function') {
        props.clearStoreData();
      }

      if (previousController.current) {
        setParams([{}]);
        previousController.current.abort();
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!params.customFields && successExportUsers) {
  //     params.customFields = [{}];
  //   }
  // }, [successExportUsers]);

  useEffect(() => {
    if (!init && props.getSettings) {
      setInit(true);
      props.getSettings(props.elemId, res => {
        if (
          !res.functions &&
          !res.departments &&
          !res.positions &&
          !res.locations &&
          !res.companies &&
          !res.roles &&
          res.customFields?.length === 0 &&
          !isSuperAdmin &&
          hasSubcompanies
        ) {
          props.getCountSettings({ companies: [currentCompany] });
        } else {
          props.getCountSettings(res);
        }
      });
    }
  }, [init]);

  useEffect(() => {
    // заполняем поля из бэка
    if (props.storeData && Object.keys(props.storeData).length > 0) {
      const data = Object.keys(props.storeData).reduce((acc, key) => {
        const keyData = props.storeData[key];
        if (Array.isArray(keyData) && keyData.length) {
          acc[key] = [...keyData];
        }
        return acc;
      }, {});

      if (data.companies && !isSuperAdmin) {
        data.companies = Array.from(new Set([...data.companies, currentCompany]));
      }

      if (hasSubcompanies && !data.companies && !isSuperAdmin) data.companies = [currentCompany];

      setParams(e => ({ ...e, ...data }));

      setInitCount(false);
    }
  }, [props.storeData, subCompanies, hasSubcompanies, currentCompany, isSuperAdmin]);

  useEffect(() => {
    if (!initCount && Object.keys(params).length >= 1) {
      setInitCount(true);
      let copyData = _.cloneDeep(params);

      copyData.customFields = copyData?.customFields?.filter(
        item => item?.valueConcrete || item.valueFrom || item.valueTo
      );

      const newCustomFields =
        copyData?.customFields?.length > 0 &&
        copyData.customFields
          .filter(el => {
            // убрать пустые объекты
            if (Object.keys(el).length === 0) return false;
            return true;
          })
          .reduce((acc, item) => {
            if (Array.isArray(item.valueConcrete)) {
              const values = item.valueConcrete;

              for (const value of values) {
                acc.push({
                  customFieldId: item.customFieldId,
                  type: item.type,
                  valueConcrete: value,
                });
              }
            } else {
              acc.push(item);
            }

            return acc;
          }, []);

      const backParams = {
        ...copyData,
        customFields: newCustomFields,
      };

      const sendParams = Object.keys(backParams).reduce((acc, key) => {
        const keyData = backParams[key];
        if (Array.isArray(keyData) && keyData.length) {
          acc[key] = [...keyData];
        }
        return acc;
      }, {});

      if (hasSubcompanies && !isSuperAdmin) {
        sendParams.companies = [currentCompany];
      }

      if (props.getCountSettings) {
        props.getCountSettings(sendParams);
      }

      // записываем в стор настройку кастомных полей из бэка
      if (props.isSettingsVisibility) {
        dispatch(saveSettingsVisibility(sendParams));
      }

      setIsData(sendParams);
    }
  }, [params, initCount]);

  const onSumbit = () => props.onSubmitData(isData, isVisible);

  const changeStateSelect = (event, name, value) => {
    let customField;
    setInitCount(false);
    switch (name) {
      case DEPARTMENTS:
        setParams({ ...params, departments: event.includes(ALL) ? department : event });
        break;

      case FUNCTIONS:
        setParams({ ...params, functions: event.includes(ALL) ? functionStudent : event });
        break;

      case POSITIONS:
        setParams({ ...params, positions: event.includes(ALL) ? position : event });
        break;

      case LOCATIONS:
        setParams({ ...params, locations: event.includes(ALL) ? location : event });
        break;

      case COMPANIES:
        setParams({ ...params, companies: event.includes(ALL) ? subCompanies : event });
        break;

      case ROLES:
        let rolesNames = roles?.map(i => i.name);
        setParams({ ...params, roles: event.includes(ALL) ? rolesNames : event });
        break;

      case CUSTOM_FIELDS:
        customField = [...params.customFields];
        customField[event] = value;
        setParams({ ...params, customFields: customField });
        break;

      case DELETE_CUSTOM_FIELDS:
        if (params.customFields.length === 1) {
          setParams({ ...params, customFields: [{}] });
          return;
        }

        customField = [...params.customFields];
        customField.splice(event, 1);
        setParams({ ...params, customFields: customField });
        break;

      case ADD_CUSTOM_FIELDS:
        if (_.isEmpty(params) || Object.keys(params.customFields[0])?.length === 0) {
          setParams({ ...params, customFields: [{}] });
          return;
        }

        customField = [...params.customFields, value];
        setParams({ ...params, customFields: customField });
        break;
    }
  };

  const exportUsers = () => {
    if (
      Array.isArray(params.customFields) &&
      params.customFields[0] &&
      Object.keys(params.customFields[0])?.length === 0
    ) {
      delete params.customFields;
    }

    const paramsOrData = Object.keys(isData).length ? isData : params;
    const controller = new AbortController();

    previousController.current = controller;

    dispatch(
      exportUsersModalVisibility(paramsOrData, controller.signal, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.click();

        // setParams([{}]);
        // setSuccessExportUsers(true);
      })
    );
  };

  const generateTemplateButtons = () => {
    let arr = [
      {
        name: props.cancelText || t('cancelText'),
        onclick: props.onClose,
      },
    ];

    (currentUser.authorities.includes(TOPIC_VISIBILITY_FILTER_DELETE) ||
      currentUser.authorities.includes(NEWS_VISIBILITY_FILTER_DELETE)) &&
      arr.push({
        name: props.resetText || t('resetText'),
        onclick: props.onResetSettings,
        type: 'primary',
      });

    (currentUser.authorities.includes(TOPIC_VISIBILITY_FILTER_CREATE) ||
      currentUser.authorities.includes(NEWS_VISIBILITY_FILTER_CREATE)) &&
      arr.push({
        name: props.saveText || t('saveText'),
        onclick: onSumbit,
        type: 'primary',
      });

    return arr;
  };

  const footerModal = generateTemplateButtons().map(item => (
    <Button
      data-qa={item.name}
      key={item.name}
      onClick={item.onclick}
      type={item.type}
      disabled={item.disabled}
      size='large'
      className={css['TemplateBlockFilters-footer-button']}
    >
      {item.name}
    </Button>
  ));

  const itemToOption = (value, index) => {
    const showTooltip = value.length > LEN_SHOW_TOOLTIP;
    return (
      <Option value={value} key={index}>
        {showTooltip ? (
          <Tooltip title={value} placement='topLeft'>
            {value}
          </Tooltip>
        ) : (
          value
        )}
      </Option>
    );
  };

  const optionRoles =
    roles?.length &&
    roles.map(item => {
      return (
        <Option key={item.id} value={item.name}>
          {item.name}
        </Option>
      );
    });

  const templateSelect = [
    {
      label: t('companies'),
      name: COMPANIES,
      options: subCompanies && subCompanies.map(itemToOption),
      value: hasSubcompanies ? (isSuperAdmin ? params.companies : currentCompany) : params.companies,
      disabled: hasSubcompanies && !isSuperAdmin ? true : false,
      dataQa: 'adminTopicsMassSelectionModalCompanyName',
    },
    {
      label: t('department'),
      name: DEPARTMENTS,
      options: department && department.map(itemToOption),
      value: params.departments ? params.departments : undefined,
      dataQa: 'adminTopicsMassSelectionModalDepartmentsName',
    },
    {
      label: t('position'),
      name: POSITIONS,
      options: position && position.map(itemToOption),
      value: params.positions ? params.positions : undefined,
      dataQa: 'adminTopicsMassSelectionModalPositionsName',
    },
    {
      label: t('functions'),
      name: FUNCTIONS,
      options: functionStudent && functionStudent.map(itemToOption),
      value: params.functions ? params.functions : undefined,
      dataQa: 'adminTopicsMassSelectionModalFunctionsName',
    },
    {
      label: t('roles'),
      name: ROLES,
      options: optionRoles,
      value: params.roles ? params.roles : undefined,
      dataQa: 'adminTopicsMassSelectionModalRolesName',
    },
    {
      label: t('location'),
      name: LOCATIONS,
      options: location && location.map(itemToOption),
      value: params.locations ? params.locations : undefined,
      dataQa: 'adminTopicsMassSelectionModalLocationsName',
    },
  ];

  const renderSelect = templateSelect.map((item, index) => (
    <Form.Item label={item.label} colon={false} key={index}>
      <Select
        dropdownRender={optionElements => <span data-qa={`${item.dataQa}-list`}>{optionElements}</span>}
        mode='multiple'
        data-qa={item.dataQa}
        placeholder={item.label}
        name={item.name}
        onChange={value => changeStateSelect(value, item.name)}
        key={`${item.name}--${index}`}
        value={item.value}
        disabled={item.disabled}
        // className={
        //   item.value && item.value !== undefined && item.value.toString().length
        //     ? classNames(css['TemplateBlockFilters'], css['TemplateBlockFilters-focus'])
        //     : css['TemplateBlockFilters']
        // }
        allowClear
        size='large'
      >
        <Option value={ALL} className={css['TemplateBlockFilters-option']}>
          {t('addAll')}
        </Option>
        {item.options}
      </Select>
    </Form.Item>
  ));

  return (
    <Spin className={css['TemplateBlockFilters-spin']} spinning={props.loaderUpdate || isExportLoading} size='large'>
      {props.showSwitchVisible && (
        <>
          {props.visibleElement && (
            <Row>
              <Alert message={props.descriptionVisible} type='warning' className={css['TemplateBlockFilters-alert']} />
            </Row>
          )}
          <Row className={css['TemplateBlockFilters-switch']}>
            <Col span={1}>
              <Switch
                data-qa='switcher'
                defaultChecked={isVisible}
                onClick={e => setIsVisible(e)}
                disabled={!props.canEdit}
              />
            </Col>
            <Col span={3} className={css['TemplateBlockFilters-switch-text']}>
              {t('visibility')}
            </Col>
          </Row>
        </>
      )}

      <Form layout='vertical'>
        <Col>{renderSelect}</Col>
        {props.showCustomFields && (
          <Col className={css['TemplateBlockFilters-cf']}>
            <CustomFieldFilter state={params.customFields} onChange={changeStateSelect} />
          </Col>
        )}
      </Form>

      {props.showUsersMaterial && (
        <UsersMaterial
          exportUsers={exportUsers}
          count={props.count}
          loading={props.loading}
          text={t('downloadUsersMaterial')}
          // title={t('usersMaterial')} // пропс нужен
          title={props.titleUsersMaterial}
        />
      )}
      {!props.isHiddenButtons && (
        <Row type='flex' justify='end' className={css['TemplateBlockFilters-footer']}>
          {footerModal}
        </Row>
      )}
    </Spin>
  );
};

TemplateBlockFilters.propTypes = {
  canEdit: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isHiddenButtons: PropTypes.bool,
  isSettingsVisibility: PropTypes.bool, // Настройка видимости
  exportUsersVisibility: PropTypes.bool, // Включить экспорт
  // exportUsersVisibility: PropTypes.func, // экспорт из usersMaterial
  // successExportUsers: PropTypes.bool, // Перемычка для экспорта usersMaterial, чтобы возвращать первоначальное сотсояние customField: [{}]
  // funcCallbackExport: PropTypes.func, // для экспорта usersMaterial чтобы переключить successExportUsers
  getSettings: PropTypes.func, // получение сохраненных настроек
  elemId: PropTypes.number, // id Элемента
  getCountSettings: PropTypes.func, // получение счетчика в usersMaterial
  storeData: PropTypes.any, // {} настройки для видимости
  count: PropTypes.number, // кол-во пользователей в UsersMaterial
  loading: PropTypes.bool, // loading в UsersMaterial
  showUsersMaterial: PropTypes.bool, // отображать UsersMaterial
  showCustomFields: PropTypes.bool, // отображать CustomField
  showSwitchVisible: PropTypes.bool, // отображать блок с видимостью Switch
  onSubmitData: PropTypes.func, // отправка данных
  onClose: PropTypes.func, // закрытие модалки
  onResetSettings: PropTypes.func, // сбросить настройки видимости
  loaderUpdate: PropTypes.bool, // spin общего контента
  cancelText: PropTypes.string, // текст для отмены
  resetText: PropTypes.string, // текст для сброса
  saveText: PropTypes.string, // текст для сохранения
  isNews: PropTypes.bool, // Видимость Новостей
  titleUsersMaterial: PropTypes.string, // UsersMaterial заголовок
  descriptionVisible: PropTypes.string, // заголовок switcher
  clearStoreData: PropTypes.func, // функция для очистки данных из стора
  visibleElement: PropTypes.bool, // булева видимости элемента
};

export default TemplateBlockFilters;
