/* eslint-disable */
import {
  CHANGE_STATUS_PRODUCT_FAIL,
  CHANGE_STATUS_PRODUCT_START,
  CHANGE_STATUS_PRODUCT_SUCCESS,
  CLEAR_PRODUCTS_VISIBILITY,
  CLEAR_SHOP_FILTERS,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_START,
  CREATE_PRODUCT_SUCCESS,
  EXPORT_PRODUCTS_FAIL,
  EXPORT_PRODUCTS_START,
  EXPORT_PRODUCTS_SUCCESS,
  GET_DELIVERY_FAIL,
  GET_DELIVERY_START,
  GET_DELIVERY_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_VISIBILITY_FAIL,
  GET_PRODUCTS_VISIBILITY_START,
  GET_PRODUCTS_VISIBILITY_SUCCESS,
  GET_RANGE_COST_FAIL,
  GET_RANGE_COST_START,
  GET_RANGE_COST_SUCCESS,
  POST_PRODUCTS_FILTERS_FAIL,
  POST_PRODUCTS_FILTERS_START,
  POST_PRODUCTS_FILTERS_SUCCESS,
  POST_PRODUCTS_VISIBILITY_FAIL,
  POST_PRODUCTS_VISIBILITY_START,
  POST_PRODUCTS_VISIBILITY_SUCCESS,
  SAVE_SHOP_FILTERS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  rangeCost: [],
  products: [],
  delivery: [],
  isLoadingTable: false,
  productsTable: null,
  productsTotal: null,
  productsPageSize: null,
  productsPageNumber: null,
  productsCurrentPage: null,
  shopFilters: [],
  visibility: [],
  exportLoading: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_RANGE_COST_START:
    case GET_PRODUCTS_START:
    case GET_DELIVERY_START:
    case CREATE_PRODUCT_START:
    case UPDATE_PRODUCT_START:
    case CHANGE_STATUS_PRODUCT_START:
    case POST_PRODUCTS_VISIBILITY_START:
    case GET_PRODUCTS_VISIBILITY_START:
      return { ...state, isLoading: true };

    case POST_PRODUCTS_FILTERS_START:
      return { ...state, isLoading: true, isLoadingTable: true };

    case EXPORT_PRODUCTS_START:
      return { ...state, exportLoading: true };

    case GET_RANGE_COST_SUCCESS:
      return { ...state, isLoading: false, rangeCost: response };

    case GET_PRODUCTS_SUCCESS:
      return { ...state, isLoading: false, products: response };

    case GET_DELIVERY_SUCCESS:
      return { ...state, isLoading: false, delivery: response };

    case GET_PRODUCTS_VISIBILITY_SUCCESS:
      return { ...state, isLoading: false, visibility: response };

    case CREATE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
    case CHANGE_STATUS_PRODUCT_SUCCESS:
    case POST_PRODUCTS_VISIBILITY_SUCCESS:
      return { ...state, isLoading: false };

    case EXPORT_PRODUCTS_SUCCESS:
      return { ...state, exportLoading: false };

    case POST_PRODUCTS_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingTable: false,
        productsTable: response.content,
        productsTotal: response.totalElements,
        productsPageSize: response.size,
        productsPageNumber: response.totalPages,
        productsCurrentPage: response.number,
      };

    case GET_RANGE_COST_FAIL:
    case GET_PRODUCTS_FAIL:
    case GET_DELIVERY_FAIL:
    case CREATE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case CHANGE_STATUS_PRODUCT_FAIL:
    case POST_PRODUCTS_VISIBILITY_FAIL:
    case GET_PRODUCTS_VISIBILITY_FAIL:
      return { ...state, isLoading: false, isError: true };

    case POST_PRODUCTS_FILTERS_FAIL:
      return { ...state, isLoading: false, isError: true, isLoadingTable: false };

    case EXPORT_PRODUCTS_FAIL:
      return { ...state, exportLoading: false, isError: true };

    case SAVE_SHOP_FILTERS:
      const filters = { ...state.shopFilters, filters: value };
      return { ...state, shopFilters: filters };

    case CLEAR_SHOP_FILTERS:
      const clearFilters = { ...state.shopFilters, filters: value };
      return { ...state, shopFilters: clearFilters };

    case CLEAR_PRODUCTS_VISIBILITY:
      return { ...state, visibility: [] };

    default:
      return { ...state };
  }
};
