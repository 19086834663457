/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCompanies } from '@/store/companies/actions';

import { selectCompanies } from '@/store/companies/selectors';

import { Button, Layout } from 'antd';
import CompanyCard from '@/components/CompanyCard';
import { Card, Content } from '@/components/Layout';
import { PlusCircleOutlined } from '@ant-design/icons';

import CreateCompany from './CreateModal/CreateCompany';
import IntegrationsCompany from './IntegrationModal/IntegrationsCompany';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Companies.module.scss';

const { Sider } = Layout;

const FILTER = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  BLOCKED: 'BLOCKED',
};

class Companies extends Component {
  static propTypes = {
    getCompanies: PropTypes.func,
    companies: PropTypes.array,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      filter: FILTER.ACTIVE,
      modal: {
        show: false,
        showIntegration: false,
        company: null,
      },
    };
    props.getCompanies();
  }

  handleChangeFilter = data => {
    this.setState({ filter: data });
    this.props.getCompanies(data);
  };

  getCompaniesCallback = () => this.props.getCompanies(this.state.filter);

  handleCloseModal = () => {
    this.setState({
      modal: { show: false, showIntegrations: false, company: null },
    });
  };

  render() {
    const { companies, isLoading, t } = this.props;
    const {
      filter,
      modal: { show, company, showIntegration },
    } = this.state;
    const compilationsList = companies.map(item => (
      <div key={item.id}>
        <CompanyCard
          onEdit={() => this.setState({ modal: { show: true, company: item.id } })}
          onEditIntegrations={() =>
            this.setState({
              modal: { showIntegration: true, company: item.id },
            })
          }
          company={item}
        />
      </div>
    ));
    return (
      <Layout>
        <Layout>
          <Content className={css.Company}>
            <div className={css.Company__button}>
              <h1>{t('companies')}</h1>
              <Button
                className={css.Company__btnmargin}
                icon={<PlusCircleOutlined />}
                onClick={() => this.setState({ modal: { show: true, company: null } })}
                type='primary'
                size='large'
              >
                {t('createCompany')}
              </Button>
            </div>

            <div className={css.Company__filter}>
              {t('filter')}
              <div
                className={classNames(css.Sort, {
                  [css['Sort-active']]: filter === FILTER.ACTIVE,
                })}
                onClick={() => this.handleChangeFilter(FILTER.ACTIVE)}
              >
                {t('active')}
              </div>
              |
              <div
                className={classNames(css.Sort, {
                  [css['Sort-active']]: filter === FILTER.INACTIVE,
                })}
                onClick={() => this.handleChangeFilter(FILTER.INACTIVE)}
              >
                {t('expired')}
              </div>
              |
              <div
                className={classNames(css.Sort, {
                  [css['Sort-active']]: filter === FILTER.BLOCKED,
                })}
                onClick={() => this.handleChangeFilter(FILTER.BLOCKED)}
              >
                {t('deactivated')}
              </div>
            </div>
            <div>
              <Card style={{ borderRadius: 10 }} className={css.Company__block}>
                {companies.length > 0 ? (
                  <div>{compilationsList}</div>
                ) : (
                  !isLoading && companies.length === 0 && <div className={css.notFound}>{t('noResult')}</div>
                )}
              </Card>
            </div>
          </Content>
        </Layout>

        {showIntegration && (
          <IntegrationsCompany
            onClose={this.handleCloseModal}
            company={companies.find(({ id }) => id === company)}
            getCompanies={this.getCompaniesCallback}
            open={showIntegration}
          />
        )}
        {show && (
          <CreateCompany
            onClose={this.handleCloseModal}
            company={companies.find(({ id }) => id === company)}
            getCompanies={this.getCompaniesCallback}
            open={show}
          />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = createSelector(selectCompanies, companies => ({
  companies: companies.companies,
  isLoading: companies.isLoading,
}));

const mapActionsToProps = {
  getCompanies,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('companies')(Companies));
