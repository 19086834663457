/* eslint-disable */

/* шаблоны сертификатов  */
import {
  CLEAR_BANNERS_TOP_BODY,
  CREATE_BANNER_TOP_BODY_FAIL,
  CREATE_BANNER_TOP_BODY_START,
  CREATE_BANNER_TOP_BODY_SUCCESS,
  DELETE_BANNER_TOP_BODY_FAIL,
  DELETE_BANNER_TOP_BODY_START,
  DELETE_BANNER_TOP_BODY_SUCCESS,
  EDIT_BANNER_TOP_BODY_FAIL,
  EDIT_BANNER_TOP_BODY_START,
  EDIT_BANNER_TOP_BODY_SUCCESS,
  GET_BANNER_TOP_BODY_FAIL,
  GET_BANNER_TOP_BODY_START,
  GET_BANNER_TOP_BODY_SUCCESS,
  GET_BANNERS_TOP_BODY_FAIL,
  GET_BANNERS_TOP_BODY_START,
  GET_BANNERS_TOP_BODY_SUCCESS,
  SHOW_BANNER_TOP_BODY_FAIL,
  SHOW_BANNER_TOP_BODY_START,
  SHOW_BANNER_TOP_BODY_SUCCESS,
} from './constants';

const initialState = {
  bannersTopBody: [],
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_BANNERS_TOP_BODY_START:
    case GET_BANNER_TOP_BODY_START:
    case CREATE_BANNER_TOP_BODY_START:
    case EDIT_BANNER_TOP_BODY_START:
    case SHOW_BANNER_TOP_BODY_START:
    case DELETE_BANNER_TOP_BODY_START:
      return { ...state, isLoading: true, error: false };

    case GET_BANNERS_TOP_BODY_SUCCESS:
      return {
        ...state,
        bannersTopBody: response.content,
        total: Number(headers.get('X-Total-Elements')) || 0,
        pageSize: Number(headers.get('X-Page-Size')) || 0,
        pageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_BANNER_TOP_BODY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_BANNER_TOP_BODY_SUCCESS:
      return { ...state, bannersTopBody: [...state.bannersTopBody, response], isLoading: false, error: false };

    case EDIT_BANNER_TOP_BODY_SUCCESS:
      let updateBanners = state.bannersTopBody;
      updateBanners[updateBanners.findIndex(e => e.id === response.id)] = response;
      return {
        ...state,
        bannersTopBody: updateBanners,
        isLoading: false,
      };

    case DELETE_BANNER_TOP_BODY_SUCCESS:
      return { ...state, isLoading: false };

    case SHOW_BANNER_TOP_BODY_SUCCESS:
      let updateBanners2 = state.bannersTopBody;
      if (response.showBanner) {
        updateBanners2.forEach(e => (e.showBanner = false));
      }
      updateBanners2[updateBanners2.findIndex(e => e.id === response.id)] = response;
      return {
        ...state,
        bannersTopBody: updateBanners2,
        isLoading: false,
        error: false,
      };

    case GET_BANNER_TOP_BODY_FAIL:
    case GET_BANNERS_TOP_BODY_FAIL:
    case CREATE_BANNER_TOP_BODY_FAIL:
    case EDIT_BANNER_TOP_BODY_FAIL:
    case DELETE_BANNER_TOP_BODY_FAIL:
    case SHOW_BANNER_TOP_BODY_FAIL:
      return { ...state, isLoading: false, error: true };

    case CLEAR_BANNERS_TOP_BODY:
      let updateAfterDelete = state.bannersTopBody;
      updateAfterDelete.splice(
        updateAfterDelete.findIndex(e => e.id === response.id),
        1
      );
      return { ...state, bannersTopBody: updateAfterDelete };

    default:
      return state;
  }
};
