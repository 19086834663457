import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getRoles } from '@/store/access/actions';
import { getSubcompanies } from '@/store/companies/actions';
import { createFileImage } from '@/store/files/actions';
import {
  clearShopVisibility,
  createProduct,
  getProductsVisibility,
  postProductsFilters,
  postProductsVisibility,
  updateProduct,
} from '@/store/product/actions';
import { getDepartment, getFunction, getLocation, getPosition } from '@/store/students/actions';

import { selectAccess } from '@/store/access/selectors';
import { selectCompanies } from '@/store/companies/selectors';
import { selectFiles } from '@/store/files/selectors';
import { selectShop } from '@/store/product/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Alert, Button, Col, Input, InputNumber, message, Modal, Select, Spin, Switch, Upload } from 'antd';
import ImageBlock from '@/components/ImageBlock';
import { HiOutlineEmojiHappy, HiOutlineExclamationCircle, HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import { EDIT, IMAGE_TYPE } from '../constants';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../Products.module.scss';

const { Option } = Select;
const { TextArea } = Input;

const ModalProduct = props => {
  const { t } = useTranslation('products');
  const dispatch = useDispatch();

  const { position, department, functionStudent, location } = useSelector(selectStudents);
  const { subCompanies } = useSelector(selectCompanies);
  const { delivery, productsCurrentPage, shopFilters, visibility, isLoading } = useSelector(selectShop);
  const { isLoadingImage } = useSelector(selectFiles);
  const { roles } = useSelector(selectAccess);

  useEffect(() => {
    return () => {
      dispatch(clearShopVisibility());
    };
  }, []);

  useEffect(() => {
    if (props.isProduct?.obj?.productId && props.isProduct?.action === EDIT) {
      dispatch(getProductsVisibility(props.isProduct.obj.productId));
    }
  }, [dispatch, props.isProduct?.obj?.productId, props.isProduct?.action]);

  const [publishedData, setPublishedData] = useState({
    companies: [],
    departments: [],
    positions: [],
    functions: [],
    locations: [],
    roles: [],
    productId: props.isProduct?.obj?.productId || null,
  });

  useEffect(() => {
    const data = Object.keys(visibility).reduce((acc, key) => {
      const keyData = visibility[key];
      if (Array.isArray(keyData) && keyData.length) {
        acc[key] = [...keyData];
      }
      return acc;
    }, {});

    setPublishedData(e => ({ ...e, ...data }));
  }, [visibility, props.isProduct.open]);

  const [isSelectPhoto, setIsSelectPhoto] = useState(false);
  const [isCreateProduct, setIsCreateProduct] = useState({
    isCreate: false,
    isSuccess: false,
    open: false,
  });

  const [data, setData] = useState({
    name: props.isProduct?.obj?.productName || '',
    description: props.isProduct?.obj?.productDesc || '',
    cost: props.isProduct?.obj?.productCost || null,
    count: props.isProduct?.obj?.productAmount || null,
    deliveryId: {
      id: props.isProduct?.obj?.productTypeDelivery?.id || null,
      name: props.isProduct?.obj?.productTypeDelivery?.name || '',
    },
    photoUuid: props.isProduct?.obj?.productPhoto || '',
    published: props.isProduct?.obj?.productPublished || undefined,
    id: props.isProduct?.obj?.productId || null,
  });

  useEffect(() => {
    if (props.isProduct?.action === EDIT) {
      dispatch(getSubcompanies());
      dispatch(getDepartment());
      dispatch(getFunction());
      dispatch(getPosition());
      dispatch(getLocation());
      dispatch(getRoles({ size: 500 }));
    }
  }, [dispatch]);

  const [isError, setIsError] = useState({
    name: false,
    description: false,
    cost: false,
    count: false,
    photoUuid: false,
    deliveryId: false,
  });

  const [fileList, setFileList] = useState([]);

  const convertedData = arr => {
    let copyArray = _.cloneDeep(arr);

    for (let key in copyArray) {
      if (!copyArray[key] || (copyArray[key].hasOwnProperty('id') && !copyArray[key].id)) {
        delete copyArray[key];
      } else if (copyArray[key]?.id) {
        copyArray[key] = copyArray[key].id;
      }

      if (Array.isArray(copyArray[key]) && copyArray[key].length === 0) {
        delete copyArray[key];
      }

      if (copyArray[key]?.length && _.isString(copyArray[key])) {
        copyArray[key] = copyArray[key].trim();
      }
    }

    copyArray.published = data.published;

    return copyArray;
  };

  const renderOption = arr => {
    return (
      arr?.length &&
      arr.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      })
    );
  };

  const optionRoles =
    roles?.length &&
    roles.map(item => {
      return (
        <Option key={item.id} value={item.name}>
          {item.name}
        </Option>
      );
    });

  const optionDelivery =
    delivery?.length &&
    delivery.map(item => {
      return (
        <Option value={item.name} key={item.id}>
          {item.name}
        </Option>
      );
    });

  const onCreateProduct = () => {
    let copyData = convertedData(data);

    dispatch(
      createProduct(
        copyData,
        () => {
          setIsCreateProduct({ ...isCreateProduct, isCreate: false, isSuccess: true });
        },
        err => {
          if (err.message) message.warning(err.message.split('"')[1]);
        }
      )
    );
  };

  const onUpdateProduct = () => {
    let copyDataUpdate = convertedData(data);

    const isValid = validationData();
    if (isValid) {
      dispatch(
        updateProduct(copyDataUpdate, () => {
          dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, productsCurrentPage));
          props.onClose();
        })
      );

      if (data.published && props.isProduct?.obj?.productId) {
        let copyPublishedData = convertedData(publishedData);
        dispatch(postProductsVisibility(copyPublishedData));
      }
    }
  };

  const onChangeUpload = e => {
    if (e && IMAGE_TYPE.includes(e.type)) {
      e.fileList?.length > 0 && setIsSelectPhoto(true);
    }
  };

  const removePhotoUuid = () => {
    setIsSelectPhoto(false);
    setData({ ...data, photoUuid: '' });
    setFileList(null);
  };

  const uploadProductImage = info => {
    if (IMAGE_TYPE.includes(info.file.type)) {
      const formData = new FormData();

      setFileList([
        {
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
        },
      ]);
      setIsSelectPhoto(true);

      formData.append('file', info.file);
      if (formData) {
        dispatch(
          createFileImage(
            formData,
            res => {
              if (res.uuid) setData({ ...data, photoUuid: res.uuid });
            },
            err => {
              setIsSelectPhoto(false);
              setFileList(null);
              message.error(err?.message || t('imageUploadError'));
            }
          )
        );
      }
    } else {
      setIsSelectPhoto(false);
      message.warning(t('infoUploadImage'));
    }
  };

  const goToShop = () => {
    dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, productsCurrentPage));
    props.onClose();
  };

  const validationData = () => {
    let copyData = convertedData(data);

    if (
      !copyData.name ||
      !copyData.description ||
      !copyData.cost ||
      !copyData.count ||
      !copyData.photoUuid ||
      !copyData.deliveryId
    ) {
      setIsError({
        name: !copyData.name,
        description: !copyData.description,
        cost: !copyData.cost,
        count: !copyData.count,
        photoUuid: !copyData.photoUuid,
        deliveryId: !copyData.deliveryId,
      });
      return false;
    } else {
      setIsError({
        name: !copyData.name,
        description: !copyData.description,
        cost: !copyData.cost,
        count: !copyData.count,
        photoUuid: !copyData.photoUuid,
        deliveryId: !copyData.deliveryId,
      });
      return true;
    }
  };

  const nextStepCreate = () => {
    const isValid = validationData();
    if (isValid) setIsCreateProduct({ ...isCreateProduct, isCreate: true, open: true });
    // setIsCreateProduct({ ...isCreateProduct, isCreate: true, open: true });
  };

  const footerModal = (
    <div className={css['Modal-footer']}>
      {(isError.name ||
        isError.description ||
        isError.cost ||
        isError.count ||
        isError.photoUuid ||
        isError.deliveryId) && (
        <div className={css['Modal-footer-error']}>
          <HiOutlineExclamationCircle className={css['Modal-footer-error-icon']} />
          <span className={css['Modal-footer-error-desc']}>{t('errorDesc')}</span>
        </div>
      )}
      <div>
        <Button onClick={props.onClose} key='cancel' size='large'>
          {t('cancel')}
        </Button>
        {props.isProduct.action === EDIT ? (
          <Button
            onClick={onUpdateProduct}
            key='update'
            size='large'
            type='primary'
            className={css['Modal-isCreate-footer-yes']}
          >
            {t('update')}
          </Button>
        ) : (
          <Button
            onClick={nextStepCreate}
            key='save'
            size='large'
            type='primary'
            className={css['Modal-isCreate-footer-yes']}
          >
            {t('save')}
          </Button>
        )}
      </div>
    </div>
  );

  const titleModal = (
    <div className={css['Modal-title']}>
      {props.isProduct.action === EDIT ? t('editModalProduct') : t('createModalProduct')}
    </div>
  );

  const fieldsCreate = (
    <>
      {/* Название */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductName')}</Col>
        <Col span={16}>
          <Input
            size='large'
            onChange={e => setData({ ...data, name: e.target.value })}
            value={data.name}
            className={classNames([css['Modal-block-area']], {
              [css['Modal-block-area-error']]: isError.name,
            })}
            maxLength={150}
            placeholder={t('modalProductNameDesc')}
          />
        </Col>
      </div>

      {/* Описание */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductDesc')}</Col>
        <Col span={16}>
          <TextArea
            value={data.description}
            rows={4}
            onChange={e => setData({ ...data, description: e.target.value })}
            className={classNames([css['Modal-block-area']], {
              [css['Modal-block-area-error']]: isError.description,
            })}
            placeholder={t('modalProductDescText')}
            maxLength={4096}
          />
        </Col>
      </div>

      {/* Стоимость */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductCost')}</Col>
        <Col span={16}>
          <InputNumber
            min={1}
            className={classNames([css['Modal-block-area']], {
              [css['Modal-block-area-error']]: isError.cost,
            })}
            size='large'
            value={data.cost}
            onChange={e => setData({ ...data, cost: e })}
            placeholder={t('modalProductCostDesc')}
          />
        </Col>
      </div>

      {/* Количество */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductAmount')}</Col>
        <Col span={16}>
          <InputNumber
            min={1}
            className={classNames([css['Modal-block-area']], {
              [css['Modal-block-area-error']]: isError.count,
            })}
            size='large'
            value={data.count}
            onChange={e => setData({ ...data, count: e })}
            placeholder={t('modalProductAmountDesc')}
          />
        </Col>
      </div>

      {/* Способ доставки товара */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductDelivery')}</Col>
        <Col span={16}>
          <Select
            size='large'
            onChange={(e, item) => setData({ ...data, deliveryId: { id: +item?.key, name: e } })}
            value={data?.deliveryId?.name || undefined}
            className={classNames(css['Modal-block-select'], [css['Modal-block-area']], {
              [css['Modal-block-area-error']]: isError.deliveryId,
            })}
            showSearch
            allowClear
            placeholder={t('modalProductDeliveryDesc')}
          >
            {optionDelivery}
          </Select>
        </Col>
      </div>

      {/* Фото товара */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductPhoto')}</Col>
        <Col span={16}>
          <div className={css['Modal-block-upload']}>
            <Alert message={t('infoUploadImage')} type='info' closable />
          </div>
          {isLoadingImage ? (
            <div className={css['Modal-block-spin']}>
              <Spin spinning={isLoadingImage} />
            </div>
          ) : (
            <Upload
              fileList={fileList}
              accept='image/jpg, image/jpeg, image/png'
              listType='picture'
              onRemove={removePhotoUuid}
              onChange={onChangeUpload}
              customRequest={uploadProductImage}
              className={classNames([css['Modal-block-area']], [css['Modal-block-area-upload']], {
                [css['Modal-block-area-error']]: isError.photoUuid,
              })}
            >
              <Button disabled={isSelectPhoto} type='link' size='large'>
                {t('modalChoosePhoto')}
              </Button>
            </Upload>
          )}

          {props.isProduct.action === EDIT && !isSelectPhoto && (
            <div>
              <ImageBlock photoUuid={props.isProduct.obj.productPhoto} okText={t('close')} />
            </div>
          )}
        </Col>
      </div>
    </>
  );

  const templateFieldsPublished = [
    {
      // компания
      label: t('modalProductCompany'),
      desc: t('modalProductCompanyDesc'),
      className: css['Modal-block-select'],
      onChange: e => setPublishedData({ ...publishedData, companies: e }),
      option: renderOption(subCompanies),
      value: publishedData.companies,
    },
    {
      // отдел
      label: t('modalProductDepartment'),
      desc: t('modalProductDepartmentDesc'),
      className: css['Modal-block-select'],
      size: 'large',
      onChange: e => setPublishedData({ ...publishedData, departments: e }),
      option: renderOption(department),
      value: publishedData.departments,
    },
    {
      // должность
      label: t('modalProductPosition'),
      desc: t('modalProductPositionDesc'),
      className: css['Modal-block-select'],
      size: 'large',
      onChange: e => setPublishedData({ ...publishedData, positions: e }),
      option: renderOption(position),
      value: publishedData.positions,
    },
    {
      // функция
      label: t('modalProductFunction'),
      desc: t('modalProductFunctionDesc'),
      className: css['Modal-block-select'],
      onChange: e => setPublishedData({ ...publishedData, functions: e }),
      option: renderOption(functionStudent),
      value: publishedData.functions,
    },
    {
      // локация
      label: t('modalProductLocation'),
      desc: t('modalProductLocationDesc'),
      className: css['Modal-block-select'],
      onChange: e => setPublishedData({ ...publishedData, locations: e }),
      option: renderOption(location),
      value: publishedData.locations,
    },
    {
      // роли
      label: t('modalProductRoles'),
      desc: t('modalProductRolesDesc'),
      className: css['Modal-block-select'],
      onChange: e => setPublishedData({ ...publishedData, roles: e }),
      option: optionRoles,
      value: publishedData.roles,
    },
  ];

  const renderFieldsPublished = templateFieldsPublished.map((item, index) => {
    return (
      <div key={index} className={css['Modal-block']}>
        <Col span={8}>{item.label}</Col>
        <Col span={16}>
          <Select
            mode='multiple'
            onChange={item.onChange}
            className={item.className}
            notFoundContent={t('noData')}
            value={item.value}
            size='large'
            allowClear
            showSearch
            placeholder={item.desc}
          >
            {item.option}
          </Select>
        </Col>
      </div>
    );
  });

  const fieldsEdit = (
    <>
      {/* Автор продукта */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductAuthor')}</Col>
        <Col span={16}>
          <Input size='large' value={props.isProduct?.obj?.productAuthor} disabled />
        </Col>
      </div>

      {/* Редактор продукта */}
      {props.isProduct?.obj?.productChangeAuthor && (
        <div className={css['Modal-block']}>
          <Col span={8}>{t('modalProductChanger')}</Col>
          <Col span={16}>
            <Input size='large' value={props.isProduct?.obj?.productChangeAuthor} disabled />
          </Col>
        </div>
      )}

      {/* Опубликовано */}
      <div className={css['Modal-block']}>
        <Col span={8}>{t('modalProductPublished')}</Col>
        <Col span={16}>
          <Switch onClick={e => setData({ ...data, published: e })} defaultChecked={data.published} />
        </Col>
      </div>

      {data.published && renderFieldsPublished}
    </>
  );

  const contentIsCreate = (
    <div className={css['Modal-isCreate']}>
      {isCreateProduct.isCreate ? (
        <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
      ) : (
        <HiOutlineEmojiHappy color='var(--personalColor)' size={168} />
      )}
      <div className={css['Modal-isCreate-title']}>
        {isCreateProduct.isCreate ? t('modalIsCreate') : t('modalCreated')}
      </div>
      <div className={css['Modal-isCreate-desc']}>
        {isCreateProduct.isCreate ? t('modalIsCreateDesc') : t('modalCreatedDesc')}
      </div>
      <div className={css['Modal-isCreate-footer']}>
        {isCreateProduct.isCreate ? (
          <>
            <Button
              onClick={() => setIsCreateProduct({ open: false, isCreate: false, isSuccess: false })}
              key='backCreate'
              size='large'
            >
              {t('backCreate')}
            </Button>
            <Button
              className={css['Modal-isCreate-footer-yes']}
              onClick={onCreateProduct}
              key='yesCreate'
              size='large'
              type='primary'
            >
              {t('yesCreate')}
            </Button>
          </>
        ) : (
          <Button onClick={goToShop} key='backShop2' size='large'>
            {t('backShop')}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      className={css['Modal']}
      title={!isCreateProduct.isCreate && !isCreateProduct.isSuccess ? titleModal : null}
      open={props.isProduct.open}
      onCancel={props.onClose}
      footer={!isCreateProduct.isCreate && !isCreateProduct.isSuccess ? footerModal : null}
      width={!isCreateProduct.isCreate && !isCreateProduct.isSuccess ? 808 : 600}
    >
      <Spin spinning={isLoading || isLoadingImage}>
        <div className={css['Modal']}>
          {!isCreateProduct.isCreate && !isCreateProduct.isSuccess && (
            <div>
              {fieldsCreate}
              {props.isProduct.action === EDIT && fieldsEdit}
            </div>
          )}

          {isCreateProduct.open && contentIsCreate}
        </div>
      </Spin>
    </Modal>
  );
};

ModalProduct.propTypes = {
  onClose: PropTypes.func,
  isProduct: PropTypes.object,
};

export default ModalProduct;
