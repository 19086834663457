import { CheckCircleOutlined, CloseCircleOutlined, IssuesCloseOutlined } from '@ant-design/icons';

import cn from 'classnames';

import style from './style.module.scss';

export const WAITING = 'WAITING';
export const NOT_WAITING = 'NOT_WAITING';
export const CHECKED = 'CHECKED';

export const setColorTag = status => {
  switch (status) {
    case WAITING:
      return 'orange';
    case CHECKED:
      return 'green';
    case NOT_WAITING:
      return 'blue';
  }
};

export const renderIcon = (status, small = false) => {
  const classNames = cn(style['icon'], style[`icon--${status}`], { [style['icon--small']]: small });

  switch (status) {
    case WAITING:
      return <IssuesCloseOutlined className={classNames} />;
    case CHECKED:
      return <CheckCircleOutlined className={classNames} />;
    case NOT_WAITING:
      return <CloseCircleOutlined className={classNames} />;
  }
};
