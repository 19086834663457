import {
  CLEAR_STUDYING_STATISTICS,
  GET_MATERIAL_STATISTICS_FAIL,
  GET_MATERIAL_STATISTICS_START,
  GET_MATERIAL_STATISTICS_SUCCESS,
  GET_STUDYING_STATISTICS_FAIL,
  GET_STUDYING_STATISTICS_START,
  GET_STUDYING_STATISTICS_SUCCESS,
  PUT_NOTIFICATION_REMINDER_FAIL,
  PUT_NOTIFICATION_REMINDER_START,
  PUT_NOTIFICATION_REMINDER_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getMaterialStatistics = ({ topicId, showDeletedUsers }, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/${topicId}/statistics${getQueryString({
    showDeletedUsers,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MATERIAL_STATISTICS_START, GET_MATERIAL_STATISTICS_SUCCESS, GET_MATERIAL_STATISTICS_FAIL],
  onSuccess,
  onError,
});

export const getStudyingStatistics = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/${topicId}/block-statistics`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STUDYING_STATISTICS_START, GET_STUDYING_STATISTICS_SUCCESS, GET_STUDYING_STATISTICS_FAIL],
  onSuccess,
  onError,
});

export const putNotificationReminder = (topicId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${topicId}/remind`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [PUT_NOTIFICATION_REMINDER_START, PUT_NOTIFICATION_REMINDER_SUCCESS, PUT_NOTIFICATION_REMINDER_FAIL],
  onSuccess,
});

export const getClearStatistics = () => ({
  type: CLEAR_STUDYING_STATISTICS,
});
