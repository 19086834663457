/* eslint-disable */
import React, { Component } from 'react';

import { Rate } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Rate.module.scss';

export default class CustomRate extends Component {
  static propTypes = {
    className: PropTypes.string,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    name: PropTypes.string,
    noChange: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.string,
    value: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
  };

  static sizes = {
    lg: 'lg',
    md: 'md',
  };

  getCharacter = {
    circle: (
      <svg width='7' height='7' viewBox='0 0 24 24'>
        <path fill='currentColor' d='M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2' />
      </svg>
    ),
  };

  handleChange = value => {
    const { onChange, name } = this.props;
    onChange({ name, value });
  };

  render() {
    const { icon, className, dark, noChange, disabled, size, ...rest } = this.props;
    const character = this.getCharacter[icon] || (
      <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'>
        <path
          fill='currentColor'
          d='M5 7.886 1.91 9.511 2.5 6.07 0 3.633l3.455-.502L5 0l1.545 3.13L10 3.634 7.5 6.07l.59 3.44z'
        />
      </svg>
    );

    return (
      <Rate
        {...rest}
        allowHalf={disabled}
        disabled={disabled}
        className={classNames(
          css.Rate,
          className,
          { [css.dark]: dark },
          { [css.noChange]: noChange },
          { [css[size]]: size }
        )}
        onChange={this.handleChange}
        character={character}
      />
    );
  }
}
