/* eslint-disable */
import { useCallback, useMemo, useState } from 'react';

import { Button, Form, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import css from './CompanyCreationDialog.module.scss';

export const Footer = ({ company, submit, activate, t }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const isBlocked = useMemo(() => company && company.status === 'BLOCKED', [company]);

  const handleSubmit = useCallback(() => {
    activate();
    setShowConfirm(false);
  }, [activate]);

  return (
    <div>
      <div className={css['footer']}>
        <Button onClick={submit} type='primary' size='large'>
          {company ? t('edit') : t('create')}
        </Button>
        {company &&
          (!isBlocked ? (
            <Button onClick={() => setShowConfirm(true)} className={css.deactivate} size='large'>
              <CloseOutlined />
              <span className={css.removeCompilation}>{t('deactivate')}</span>
            </Button>
          ) : (
            <Button onClick={() => setShowConfirm(true)} size='large'>
              <CheckOutlined />
              <span className={css.removeCompilation}>{t('activate')}</span>
            </Button>
          ))}
      </div>
      {showConfirm && (
        <Modal
          title={isBlocked ? t('activateCompany') : t('deactivateCompany')}
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
          footer={null}
          width={550}
        >
          <Form className={css['Add-modal-form']}>
            <div className={css['Add-modal']}>
              <div>{!isBlocked ? t('deactivateDescription') : t('activateDescription')}</div>
              <div className={css['innerFooter']}>
                <Button onClick={handleSubmit} size='large' type='primary'>
                  {isBlocked ? t('activate') : t('deactivate')}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
};
