export const ACTIONS_STATUS = ['EXPIRED', 'FINISHED'];
export const IN_PROGRESS = 'IN_PROGRESS';

export const TAB_KEY_DESCRIPTION = 'description';
export const TAB_KEY_CONTENT_SETTINGS = 'contentSettings';
export const TAB_KEY_APPOINT = 'appoint';

export const TYPE_ADD_MASS = 'ADD_MASS';
export const TYPE_ADD_MANUAL = 'ADD_MANUAL';

export const STATUS_IN_PROCESS = 'IN_PROCESS';
export const STATUS_ON_UPDATE = 'ON_UPDATE';
