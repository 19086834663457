/* eslint-disable */
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getVersion } from '@/store/version/actions';

import { selectUsers } from '@/store/users/selectors';
import { selectVersion } from '@/store/version/selectors';

import { Col, Layout, Row, Tabs } from 'antd';
import { Content } from '@/components/Layout';

import TabApiSettings from './Tabs/Api';
import TabBanners from './Tabs/Banners';
import TabCetrificates from './Tabs/Certificates';
import TabPlanSettings from './Tabs/Plan';
import SettingRequests from './Tabs/SettingRequests';
import TabSystemSettings from './Tabs/System';
import TabUsersSettings from './Tabs/Users';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import css from './Tabs/TabsVarDirectory.module.scss';

import { BANNERS_ACCESS, CERTIFICATES_READ, QUARTZ_READ } from '@/constants/permissions';

dayjs.extend(localizedFormat);

export const VarDirectory = () => {
  const { t } = useTranslation('varDirectory');
  const { currentUser } = useSelector(selectUsers);
  const { version } = useSelector(selectVersion);
  const dispatch = useDispatch();

  const tabs = useMemo(() => {
    return [
      {
        key: 'settingRequests',
        label: t('settingRequests'),
        children: <SettingRequests />,
      },
      // временно оставляем так, чтобы разобраться с ЛДАП после удалить директорию
      // {
      //   key: 'profileSettings',
      //   label: t('profileSettings'),
      //   children: <TabSystemSettings />,
      // },
      {
        key: 'generalSettings',
        label: t('generalSettings'),
        children: <TabUsersSettings />,
      },
      ...[
        currentUser.authorities.includes(CERTIFICATES_READ)
          ? {
              key: 'certificateSettings',
              label: t('certificateSettings'),
              children: <TabCetrificates />,
            }
          : {},
      ],
      ...[
        currentUser.authorities.includes(BANNERS_ACCESS)
          ? {
              key: 'bannerSettings',
              label: t('bannerSettings'),
              children: <TabBanners />,
            }
          : {},
      ],

      {
        key: 'apiSettings',
        label: t('apiSettings'),
        children: <TabApiSettings />,
      },
      ...[
        currentUser.authorities.includes(QUARTZ_READ)
          ? {
              key: 'planSettings',
              label: t('planSettings'),
              children: <TabPlanSettings />,
            }
          : {},
      ],
    ];
  }, []);

  useEffect(() => {
    dispatch(getVersion());
  }, []);

  return (
    <Layout>
      <Content>
        <div className={css['VarDirectory-head']}>
          <h1>{t('systemSettings')}</h1>
          <span className={css['VarDirectory-txt']}>
            ({version?.version}, {version?.buildTime && dayjs(version.buildTime).format('DD.MM.YYYY, HH:mm')})
          </span>
        </div>
        <Row>
          <Col span={24}>
            <Tabs className={css['Tabs-list']} items={tabs} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default VarDirectory;
