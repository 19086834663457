/* eslint-disable */

// Экшены
import {
  GET_REPORT_PDF_FAIL,
  GET_REPORT_PDF_START,
  GET_REPORT_PDF_SUCCESS,
  GET_SCORE_FAIL,
  GET_SCORE_START,
  GET_SCORE_SUCCESS,
  GET_SETTINGSTQ_FAIL,
  GET_SETTINGSTQ_START,
  GET_SETTINGSTQ_SUCCESS,
  POST_SETTINGSTQ_FAIL,
  POST_SETTINGSTQ_START,
  POST_SETTINGSTQ_SUCCESS,
  PUT_SETTINGSTQ_FAIL,
  PUT_SETTINGSTQ_START,
  PUT_SETTINGSTQ_SUCCESS,
} from './constants';

import { API_TQ } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

// Дефолтный токен для запросов
const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

// получаем настройки компании
export const getSettingsTQ = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/settings/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SETTINGSTQ_START, GET_SETTINGSTQ_SUCCESS, GET_SETTINGSTQ_FAIL],
  onSuccess,
  onError,
});

// создаем настройки компании
export const createSettingsTQ = (data, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_TQ}/api/settings`,
  body: data,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [POST_SETTINGSTQ_START, POST_SETTINGSTQ_SUCCESS, POST_SETTINGSTQ_FAIL],
  onSuccess,
  onError,
});

// обновляем настройки компании
export const updateSettingsTQ = (data, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_TQ}/api/settings/${data.company_id}`,
  body: data,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [PUT_SETTINGSTQ_START, PUT_SETTINGSTQ_SUCCESS, PUT_SETTINGSTQ_FAIL],
  onSuccess,
  onError,
});

// получаем пдф
export const getReportPdf = (student_id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/report/${student_id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_REPORT_PDF_START, GET_REPORT_PDF_SUCCESS, GET_REPORT_PDF_FAIL],
  onSuccess,
  onError,
});

// получаем результат тестирования
export const getScore = (userId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/score/user${getQueryString({
    userId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SCORE_START, GET_SCORE_SUCCESS, GET_SCORE_FAIL],
  onSuccess,
  onError,
});
