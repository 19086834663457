/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { editCompanies } from '@/store/companies/actions';

import { Button, Form, Modal } from 'antd';
import CustomInput from '@/components/Input';

import DatePicker from '@shared/components/DatePicker';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../CreateModal/CompanyCreationDialog.module.scss';

class IntegrationsCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alpinaDomain: '',
      alpinaOpenKey: '',
      alpinaSecretKey: '',
      alpinaDemoExpirationDate: '',
      errors: {},
      diagnosticId: this.props.company.diagnosticType.id,
    };
    if (this.props.company && this.props.company.alpinaCompany) {
      const {
        alpinaCompany: { alpinaDomain, alpinaOpenKey, alpinaSecretKey, alpinaDemoExpirationDate },
      } = this.props.company;
      this.state = {
        alpinaDomain,
        alpinaOpenKey,
        alpinaSecretKey,
        alpinaDemoExpirationDate,
        errors: {},
        diagnosticId: this.props.company.diagnosticType.id,
      };
    }
  }

  handleChange = event => {
    const { name, value } = event.target || event;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    if (this.validate()) {
      const { providers, topicTypes, ...clearedData } = this.props.company;
      const { alpinaDemoExpirationDate, alpinaDomain, alpinaOpenKey, alpinaSecretKey, diagnosticId } = this.state;
      clearedData.providerVMs = providers;
      clearedData.topicTypes = topicTypes.map(type => type.id);
      clearedData.alpinaCompany = {
        alpinaDomain: alpinaDomain || null,
        alpinaOpenKey: alpinaOpenKey || null,
        alpinaSecretKey: alpinaSecretKey || null,
        alpinaDemoExpirationDate: null,
      };
      clearedData.diagnosticId = diagnosticId;
      clearedData.disableDeletingFromPlan = true;
      if (this.state.alpinaDemoExpirationDate) {
        clearedData.alpinaCompany.alpinaDemoExpirationDate =
          alpinaDemoExpirationDate.length > 10
            ? dayjs(alpinaDemoExpirationDate).format('YYYY-MM-DD')
            : alpinaDemoExpirationDate;
      }

      this.props.editCompanies(clearedData, () => {
        this.props.getCompanies();
        this.props.onClose();
      });
    }
  };

  validate = () => {
    const { errors, ...data } = this.state;
    const hasErrors = {};
    const hasData = Object.values(data).find(value => !!value);
    if (hasData) {
      Object.keys(data).forEach(value => {
        if (!data[value]) {
          hasErrors[value] = this.props.t('require');
        }
      });
    }
    this.setState({ errors: hasErrors });
    return _.isEmpty(hasErrors);
  };

  render() {
    const { alpinaDemoExpirationDate, alpinaDomain, alpinaOpenKey, alpinaSecretKey, errors } = this.state;

    const footerModal = (
      <Button onClick={this.handleSubmit} type='primary' size='large'>
        {this.props.t('save')}
      </Button>
    );

    return (
      <Modal
        title={this.props.t('integration')}
        open={this.props.open}
        onCancel={this.props.onClose}
        footer={footerModal}
        width={850}
      >
        <Form className={css['Add-modal-form']} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <div className={css['Add-modal']}>
            <div className={css['Add-modal-title']}>{this.props.t('alpina')}</div>
            <Form.Item
              label={this.props.t('domain')}
              validateStatus={errors.alpinaDomain ? 'error' : ''}
              help={errors.alpinaDomain && this.props.t('require')}
              colon={false}
              required
            >
              <CustomInput
                name='alpinaDomain'
                placeholder={this.props.t('domain2')}
                onChange={this.handleChange}
                value={alpinaDomain}
                maxLength={50}
                large
              />
            </Form.Item>
            <Form.Item
              label='API Key'
              validateStatus={errors.alpinaOpenKey ? 'error' : ''}
              help={errors.alpinaDomain && this.props.t('require')}
              colon={false}
              required
            >
              <CustomInput
                name='alpinaOpenKey'
                placeholder='aa11b011ba01abaa1a10aa01abb1aa10'
                onChange={this.handleChange}
                value={alpinaOpenKey}
                maxLength={50}
                large
              />
            </Form.Item>
            <Form.Item
              label='API Secret'
              validateStatus={errors.alpinaSecretKey ? 'error' : ''}
              help={errors.alpinaDomain && this.props.t('require')}
              colon={false}
              required
            >
              <CustomInput
                name='alpinaSecretKey'
                placeholder='aa11b011ba01abaa1a10aa01abb1aa10'
                onChange={this.handleChange}
                value={alpinaSecretKey}
                maxLength={50}
                large
              />
            </Form.Item>
            <Form.Item
              label={this.props.t('trial')}
              validateStatus={errors.alpinaDemoExpirationDate ? 'error' : ''}
              help={errors.alpinaDomain && this.props.t('require')}
              colon={false}
              required
            >
              <DatePicker
                onChange={this.handleChange}
                name='alpinaDemoExpirationDate'
                value={alpinaDemoExpirationDate}
                placeholder={dayjs().add(7, 'days')}
                modificate
                readOnly
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

IntegrationsCompany.propTypes = {
  editCompanies: PropTypes.func,
  open: PropTypes.bool,
};

const mapActionsToProps = {
  editCompanies,
};

export default connect(null, mapActionsToProps)(withTranslation('integrationsCompany')(IntegrationsCompany));
