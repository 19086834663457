/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Input, Result, Row, Select } from 'antd';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

export const StepResults = props => {
  const { t } = useTranslation('D360_StepResults');

  const subTitle = () => {
    return (
      <div className={css.StepScreenWindowDiv}>
        <Row>
          <Col>{t('chosenCompetence')}:&nbsp;</Col>
          <Col>
            <b>{props.competence.name}</b>
          </Col>
        </Row>
        <Row>
          <Col>{t('chosenSkill')}:&nbsp;</Col>
          <Col>
            <b>{props.skill && props.skill.name ? props.skill.name : t('allSkills')}</b>
          </Col>
        </Row>
        <Row>
          <Col>{t('chosenScale')}:&nbsp;</Col>
          <Col>
            <b>{props.scale.name}</b>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.StepScreenWindow}>
        <Result status='success' title={t('successText')} subTitle={subTitle()} />
      </div>
    );
  };

  return render();
};

StepResults.propTypes = {
  competence: PropTypes.object.isRequired,
  skill: PropTypes.any,
  scale: PropTypes.object.isRequired,
};

export default StepResults;
