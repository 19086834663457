import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { getDelivery, getRangeCost } from '@/store/product/actions';

import { Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import Scores from '@/components/ScoresShop';

import Filters from './Content/Filters';
import TableProducts from './Content/TableProducts';

import css from './Products.module.scss';

const { Title } = Typography;

const Products = () => {
  const { t } = useTranslation('products');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubcompanies());
    dispatch(getRangeCost());
    dispatch(getDelivery());
  }, [dispatch]);

  const [isSort, setIsSort] = useState('');

  const [activeSort, setActiveSort] = useState({
    count: false,
    cost: false,
  });

  const [data, setData] = useState({
    subCompanyName: '',
    deliveryIds: {
      ids: [],
      name: '',
    },
    costRangeIds: {
      ids: [],
      name: '',
    },
    productId: {
      ids: [],
      name: '',
    },
    published: {
      name: t('all'),
      value: undefined,
    },
  });

  return (
    <Layout>
      <Content>
        <div className={css['Products']}>
          <div className={css['Products-header']}>
            <Title>{t('title')}</Title>
          </div>
          <Scores />
          <div className={css['Products-main']}>
            <Filters
              data={data}
              setData={setData}
              isSort={isSort}
              setIsSort={setIsSort}
              setActiveSort={setActiveSort}
            />
            <TableProducts
              setIsSort={setIsSort}
              activeSort={activeSort}
              setActiveSort={setActiveSort}
              isSort={isSort}
            />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Products;
