/* eslint-disable */

import {
  CHANGE_BOOK_ADDRESSES_FAIL,
  CHANGE_BOOK_ADDRESSES_START,
  CHANGE_BOOK_ADDRESSES_SUCCESS,
  CLEAR_BOOKS_INUSE,
  FREE_BOOK_FAIL,
  FREE_BOOK_START,
  FREE_BOOK_SUCCESS,
  GET_ALL_BOOK_ADDRESSES_FAIL,
  GET_ALL_BOOK_ADDRESSES_START,
  GET_ALL_BOOK_ADDRESSES_SUCCESS,
  GET_BOOK_ADDRESSES_FAIL,
  GET_BOOK_ADDRESSES_START,
  GET_BOOK_ADDRESSES_SUCCESS,
  GET_BOOKS_INUSE_FAIL,
  GET_BOOKS_INUSE_START,
  GET_BOOKS_INUSE_SUCCESS,
  SAVE_BOOK_ADDRESSES_FAIL,
  SAVE_BOOK_ADDRESSES_START,
  SAVE_BOOK_ADDRESSES_SUCCESS,
} from './actions';

const initialState = {
  allAddresses: [],
  addresses: [],
  addressesInUse: [],
  bookInUseByUserId: {},
  total: 0,
  pageSize: 20,
  pageNumber: 0,
  currentPage: 0,
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_BOOK_ADDRESSES_START:
    case SAVE_BOOK_ADDRESSES_START:
    case GET_BOOKS_INUSE_START:
    case GET_ALL_BOOK_ADDRESSES_START:
    case CHANGE_BOOK_ADDRESSES_START:
    case FREE_BOOK_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_BOOK_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: response,
        isLoading: false,
        error: false,
      };

    case SAVE_BOOK_ADDRESSES_FAIL:
    case GET_BOOKS_INUSE_FAIL:
    case GET_BOOK_ADDRESSES_FAIL:
    case GET_ALL_BOOK_ADDRESSES_FAIL:
    case CHANGE_BOOK_ADDRESSES_FAIL:
    case FREE_BOOK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case SAVE_BOOK_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: [...state.addresses, response],
        isLoading: false,
        error: false,
      };

    case GET_BOOKS_INUSE_SUCCESS:
      const bookInUseByUserId = response.reduce((acc, item) => {
        if (!item.userId) return acc;

        acc[item.userId] = item;
        return acc;
      }, {});

      return {
        ...state,
        bookInUseByUserId,
        addressesInUse: response,
        isLoading: false,
        error: false,
      };

    case GET_ALL_BOOK_ADDRESSES_SUCCESS:
      return {
        ...state,
        allAddresses: response.content,
        total: response.totalElements,
        pageSize: response.size,
        pageNumber: response.totalPages,
        currentPage: response.number,
        isLoading: false,
        error: false,
      };

    case CHANGE_BOOK_ADDRESSES_SUCCESS:
    case FREE_BOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case CLEAR_BOOKS_INUSE:
      return {
        ...state,
        addressesInUse: [],
        bookInUseByUserId: {},
        isLoading: false,
        error: false,
      };

    default:
      return state;
  }
};
