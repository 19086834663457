/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import {
  D360CopyScale,
  D360CreateScale,
  D360DeleteScale,
  D360GetScales,
  D360UpdateScale,
} from '@/store/d360/d360_scales/actions';

import { selectD360Scales } from '@/store/d360/d360_scales/selectors';

import { Button, Col, Input, Modal, Row, Table } from 'antd';
import { CgImport } from 'react-icons/cg';
import { FiTrendingUp } from 'react-icons/fi';

// Части компонента
import ScaleEdit from './parts/ScaleEdit';
import ScaleImporter from './parts/ScaleImporter';
import ScaleMenu from './parts/ScaleMenu';
import ScaleScores from './parts/ScaleScores';

// Redux
import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from '../../css/index.module.scss';

const ButtonGroup = Button.Group;

const pageSizeOptions = ['10', '50', '100', '500', '1000', '10000'];

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const Scales = props => {
  const [modal, contextHolder] = Modal.useModal();
  /**
   * Показать/скрыть окно создания/редактирование шкалы.
   */
  const [showEditScale, setShowEditScale] = useState(false);

  /**
   * Список всех шкал.
   */
  const [tableData, setTableData] = useState([]);

  /**
   * Текущая выбранная шкала.
   */
  const [currentScale, setCurrentScale] = useState(null);

  /**
   * Показать/скрыть окно создания/редактирование баллов.
   */
  const [showScaleScores, setShowScaleScores] = useState(false);

  /**
   * Идентификатор проекта.
   */
  const [projectId, setProjectId] = useState(null);

  /**
   * Показать/скрыть окно импорта шкал
   */
  const [showImportWindow, setShowImportWindow] = useState(false);

  const dispatch = useDispatch();
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  /**
   * Объект локализации
   */
  const { t } = useTranslation('D360_Scales');

  /**
   * Инициализация компонента.
   */
  useEffect(() => {
    if (props && props.project && props.project.id) {
      setProjectId(props.project.id);
      props.D360GetScales(props.project.id);
    }
  }, [props.project]);

  /**
   * Загрузка данных.
   */
  useEffect(() => {
    let data = [];
    if (props.scales && Array.isArray(props.scales)) {
      props.scales.forEach(scale => {
        data.push({ ...scale, key: scale.id });
      });
    }

    setTableData(data);
  }, [props.scales]);

  /**
   * Редактирование баллов.
   */
  const openScore = scale => {
    setCurrentScale(scale);
    setShowScaleScores(true);
  };

  /**
   * Копировать шкалу.
   */
  const copyScale = scale => {
    let name = scale.name;
    return modal.confirm({
      title: t('copyScaleTitle'),
      content: (
        <>
          <p>{t('copyScaleText')}</p>
          <br />
          <Input
            maxLength={255}
            defaultValue={name}
            onChange={e => {
              name = e.target.value;
            }}
          />
        </>
      ),
      okText: t('copyScaleOkButton'),
      cancelText: t('copyScaleCancelButton'),
      okType: 'primary',
      onOk() {
        scale.newname = name;
        dispatch(
          D360CopyScale(
            projectId,
            scale.id,
            name,
            () => {
              // no code on success
            },
            () => {
              return modal.confirm({
                title: t('scaleAlreadyExistTitle'),
                content: t('scaleAlreadyExistText'),
                okText: t('OK'),
                okType: 'danger',
                cancelButtonProps: { style: { display: 'none' } },
              });
            }
          ) // end of D360CopyScale
        ); // end of dispatch
      }, // end of onOK
    }); // end of confirm
  };

  /**
   * Удалить шкалу.
   */
  const deleteScale = scale => {
    return modal.confirm({
      title: t('confirmDeleteTitle'),
      content: t('confirmDeleteText'),
      okText: t('confirmDeleteOkButton'),
      cancelText: t('confirmDeleteCancelButton'),
      okType: 'danger',
      onOk() {
        props.D360DeleteScale(props.project.id, scale.id);
      },
    });
  };

  /**
   * Создать шкалу.
   */
  const createScale = () => {
    setCurrentScale({});
    setShowEditScale(true);
  };

  /**
   * Изменить шкалу.
   *
   * @param scale
   */
  const editScale = scale => {
    setCurrentScale(scale);
    setShowEditScale(true);
  };

  /**
   * Столбцы таблицы.
   *
   * @returns {*[]}
   */
  const tableColumns = () => {
    return [
      {
        title: t('columnName'),
        key: 'name',
        dataIndex: 'name',
        width: 350,
        render: (name, record) => {
          return (
            <ScaleMenu
              name={name}
              scale={record}
              onClick={openScore}
              onCopy={copyScale}
              onDelete={deleteScale}
              onEdit={editScale}
            />
          );
        },
      },
      {
        title: t('columnDescription'),
        key: 'description',
        dataIndex: 'description',
      },
    ];
  };

  /**
   *
   * @param scale
   */
  const handleEditScaleSubmit = scale => {
    // Update
    if (scale.id) {
      props.D360UpdateScale(projectId, scale);

      // Create
    } else {
      props.D360CreateScale(projectId, scale);
    }

    setShowEditScale(false);
  };

  const createFromTemplate = () => {};

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <>
        <div>
          <Row className={css.Header}>
            <Col span={12}>
              <h2 className={css.HeaderTitle}>
                <FiTrendingUp />
                &nbsp;{t('windowTitle')}
              </h2>
            </Col>

            {props.editable && (
              <Col span={12} align='right'>
                <ButtonGroup>
                  <Button type='primary' onClick={createScale}>
                    {t('addButton')}
                  </Button>
                  <Button onClick={() => setShowImportWindow(true)}>
                    <CgImport />
                    &nbsp;{t('importScales')}
                  </Button>
                </ButtonGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={tableColumns()}
                dataSource={tableData}
                bordered={true}
                loading={props.loading}
                size='middle'
                rowKey={e => e.id}
                pagination={{
                  defaultPageSize: 10,
                  pageSize: paginationPageSize,
                  pageSizeOptions: pageSizeOptions,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onShowSizeChange: (c, s) => {
                    setPaginationPageSize(s);
                  },
                  responsive: true,
                }}
              />
            </Col>
          </Row>
        </div>

        <ScaleScores
          open={showScaleScores}
          onSubmit={() => setShowScaleScores(false)}
          project={props.project}
          scale={currentScale}
          editable={props.editable}
        />

        <ScaleEdit
          open={showEditScale}
          onCancel={() => setShowEditScale(false)}
          onSubmit={handleEditScaleSubmit}
          scale={currentScale}
        />

        <ScaleImporter
          onCancel={() => setShowImportWindow(false)}
          onSubmit={() => setShowImportWindow(false)}
          open={showImportWindow}
          project={props.project}
        />

        {contextHolder}
      </>
    );
  };

  /**
   * Запуск компонента.
   */
  return render();
};

/**
 * Типизация свойств компонента.
 *
 * @type {{project: *}}
 */
Scales.propTypes = {
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
};

/**
 * Состояния в редаксе.
 *
 * @param state
 * @returns {{scales: Array, loading: *}}
 */
const mapStateToProps = createSelector([selectD360Scales], D360Scales => ({
  scales: D360Scales.scales,
  loading: D360Scales.loading,
}));

/**
 * Экшены редакса.
 *
 * @type {{D360CreateScale: *, D360UpdateScale: *, D360DeleteScale: *, D360GetScales: *}}
 */
const mapDispatchToProps = {
  D360CreateScale,
  D360UpdateScale,
  D360DeleteScale,
  D360GetScales,
};

/**
 * Экспорт компонента.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Scales);
