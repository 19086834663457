import {
  GET_TOPIC_TYPE_LABEL_FAIL,
  GET_TOPIC_TYPE_LABEL_START,
  GET_TOPIC_TYPE_LABEL_SUCCESS,
  GET_TYPE_LABEL_FAIL,
  GET_TYPE_LABEL_START,
  GET_TYPE_LABEL_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getTypeLabels = (approved, isSkills, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/label${getQueryString({ approved })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPE_LABEL_START, GET_TYPE_LABEL_SUCCESS, GET_TYPE_LABEL_FAIL],
  onSuccess,
  onError,
  approved,
  isSkills,
});

export const getTopicTypeLabel = (id, approved, withEvents, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/label/${id}${getQueryString({
    approved,
    withEvents,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_TYPE_LABEL_START, GET_TOPIC_TYPE_LABEL_SUCCESS, GET_TOPIC_TYPE_LABEL_FAIL],
  onSuccess,
  onError,
});
