/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createOrUpdateCustomNotifications } from '@/store/newNotifications/actions';
import { getCountSettingsVisibility } from '@/store/settings_visibility/actions';

import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';

import { Alert, Button, Checkbox, Col, Form, Input, message, Row, Spin } from 'antd';
import TemplateBlockFilters from '@/components/TemplateBlockFilters';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../Notifications.module.scss';

const { TextArea } = Input;

export const CreateOrUpdateModal = props => {
  const { closeModal, isCreate, itemNotification, disabled } = props;
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();
  const [isSending, setSending] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(itemNotification);

  const { count, stateSettingsVisibility, loading } = useSelector(selectSettingsVisibility);

  const getMaxLength = name => {
    switch (name) {
      case 'message':
        return 300;
      case 'url':
        return 256;
      case 'subject':
        return 150;
      default:
        return null;
    }
  };

  const onSendNotification = fieldsValue => {
    if (count === 0 && isCreate) {
      message.error({
        content: t('byFilterNoUser'),
        duration: 2,
        top: 200,
      });
      return;
    }

    let fieldCurrent = {};

    if (isCreate) {
      let { customFields, ...allFilters } = stateSettingsVisibility.filters;

      fieldCurrent = {
        ...allFilters,
        ...fieldsValue,
      };

      if (!_.isEmpty(customFields)) fieldCurrent = { ...fieldCurrent, customFields };
    } else {
      fieldCurrent = {
        id: currentNotification.id,
        ...fieldsValue,
      };
    }

    setSending(true);
    dispatch(
      createOrUpdateCustomNotifications(
        isCreate,
        fieldCurrent,
        () => {
          setSending(false);
          closeModal();
        },
        error => {
          setSending(false);
          closeModal();
          message.error(error);
        }
      )
    );
  };

  const customInputField = name => {
    const CustomInput = name === 'message' ? TextArea : Input;
    let extraProperty = {};
    let isRequired = name === 'url';

    if (name === 'message') {
      extraProperty.autoSize = { minRows: 5, maxRows: 10 };
    }

    return (
      <Form.Item
        label={t(name)}
        name={name}
        className={classNames(css['Notifications-block-field'], css[`Notifications-block-field__${name}`])}
        rules={[
          { type: 'string', required: !isRequired, message: t('requiredError') },
          { max: getMaxLength(name), message: t('maxLengthError') + getMaxLength(name) },
          name === 'url' && { type: 'url', message: t('linkError') },
        ]}
      >
        <CustomInput
          className={classNames(
            css['Notifications-block-field__input'],
            css[`Notifications-block-field__input--${name}`]
          )}
          onChange={e => setCurrentNotification({ ...currentNotification, [name]: e?.target?.value })}
          {...extraProperty}
        />
      </Form.Item>
    );
  };

  return (
    <Spin spinning={isSending}>
      <div className={css['Notifications']}>
        {isCreate && (
          <div className={css['Notifications-block--create-left']}>
            <h2>{t('notificationFilters')}</h2>
            <div
              className={classNames(
                css['Notifications-block--filters'],
                count === 0 && css['Notifications-block--filters__error']
              )}
            >
              <TemplateBlockFilters
                isSettingsVisibility
                showCustomFields
                showUsersMaterial
                isHiddenButtons
                titleUsersMaterial={t('filterTitle')}
                count={count}
                loading={loading}
                loaderUpdate={isSending}
                exportUsersVisibility
                getCountSettings={e => dispatch(getCountSettingsVisibility(e))}
              />
              {count === 0 && <Alert message={t('filterError')} style={{ margin: '1em 0' }} type='error' />}
            </div>
          </div>
        )}

        <Form
          className={css[`Notifications-block${isCreate ? '--create-right' : ''}`]}
          layout='vertical'
          onFinish={onSendNotification}
          fields={[
            {
              name: ['url'],
              value: currentNotification.url,
            },
            {
              name: ['subject'],
              value: currentNotification.subject,
            },
            {
              name: ['message'],
              value: currentNotification.message,
            },
          ]}
        >
          {customInputField('url', currentNotification.url)}
          {customInputField('subject', currentNotification.subject)}
          {customInputField('message', currentNotification.message)}
          {isCreate && (
            <Form.Item
              name='published'
              valuePropName='checked'
              className={classNames(
                css['Notifications-block-field'],
                css[`Notifications-block-field__input--published`]
              )}
            >
              <Checkbox>{t('input--published')}</Checkbox>
            </Form.Item>
          )}

          <Row>
            <Col style={{ textAlign: 'right' }}>
              <Button
                type='primary'
                htmlType='submit'
                className={css['Notifications__btn']}
                key='onSendButton'
                disabled={disabled}
              >
                {isCreate ? t('sendCreate') : t('sendUpdate')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

CreateOrUpdateModal.propTypes = {
  closeModal: PropTypes.func,
  isCreate: PropTypes.bool,
  itemNotification: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CreateOrUpdateModal;
