/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form, Input, Modal } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

class TagCreationDialog extends Component {
  static propTypes = {
    descriptionText: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    okText: PropTypes.string,
    onOk: PropTypes.func,
    tag: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      tag: props.tag || '',
      dirty: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    let tagError = '';

    if (value.includes(';')) {
      tagError = this.props.t('invalidCharacter');
    } else if (!value) {
      tagError = this.props.require || this.props.t('require');
    }
    this.setState({ [name]: value, tagError });
  };

  handleSubmit = () => {
    const { tag, tagError } = this.state;

    if (tagError === this.props.t('invalidCharacter') || tagError === this.props.require) {
      return;
    }

    if (tag) {
      this.setState({
        tagError: '',
        dirty: true,
      });
      this.props.onOk(tag.trim());
    } else {
      this.setState({ tagError: this.props.require });
    }
  };

  render() {
    const { descriptionText, okText, error, errorText, ...props } = this.props;

    return (
      <Modal {...props} footer={null}>
        <p>{descriptionText}</p>
        <Form>
          <Form.Item
            className={classNames({ error: this.state.tagError || error })}
            help={this.state.tagError || (error && this.state.dirty ? errorText : '')}
            validateStatus={this.state.tagError || (error && this.state.dirty) ? 'error' : ''}
          >
            <Input
              maxLength={200}
              name='tag'
              data-qa='test'
              value={this.state.tag}
              onChange={this.handleChange}
              id='InputTagName'
            />
          </Form.Item>
          <Button type='primary' onClick={this.handleSubmit} htmlType='submit' id='ConfirmTagName'>
            {okText}
          </Button>
        </Form>
      </Modal>
    );
  }
}

export default withTranslation(['tagsTable'])(TagCreationDialog);
