/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { deleteMassFilter, disableMassFilter, enableMassFilter } from '@/store/massfilter/actions';

import { selectMassfilters } from '@/store/massfilter/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Drawer, Table } from 'antd';

import SaveFilterModal from './SaveFilterModal/SaveFilterModal';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './style.module.scss';

import { MASS_FILTER_DELETE, MASS_FILTER_INTERACT } from '@/constants/permissions';

const SavedFilters = props => {
  const { t, deleteMassFilter, enableMassFilter, disableMassFilter, getSavedFilters } = props;

  const [currentSelected, setCurrentSelected] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (getSavedFilters && typeof getSavedFilters === 'function') {
      getSavedFilters();
    }
  }, []);

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const filterName = JSON.parse(record.filter).filterName;
        return (
          <span
            className={css['SavedFilters__caption']}
            onClick={() => {
              setCurrentSelected(record);
              setShowInfo(true);
            }}
          >
            {filterName}
          </span>
        );
      },
    },

    {
      title: t('dateCreated'),
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        const onDate = dayjs(record.createdDate.split('T')[0]).format('DD.MM.YYYY');
        return <span>{onDate}</span>;
      },
    },
    {
      title: t('active'),
      dataIndex: 'active',
      key: 'active',
      render: (text, record) =>
        props.currentUser?.authorities.includes(MASS_FILTER_INTERACT) && (
          <Button
            type={record.autoAddToPlan ? 'default' : 'primary'}
            onClick={() => {
              activeDeactive(record);
            }}
          >
            {record.autoAddToPlan ? t('deactivate') : t('activate')}
          </Button>
        ),
    },
    {
      title: t('delete'),
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) =>
        props.currentUser?.authorities.includes(MASS_FILTER_DELETE) && (
          <Button
            type='danger'
            onClick={() => {
              onDelete(record.id);
            }}
          >
            {t('deleted')}
          </Button>
        ),
    },
  ];

  const onDelete = filterId => {
    deleteMassFilter(filterId, () => getSavedFilters());
  };

  const activeDeactive = record => {
    const check = record.autoAddToPlan;
    if (check) {
      onDisable(record);
    } else {
      onEnable(record);
    }
  };

  const onEnable = record => {
    const filterId = record.id;
    enableMassFilter(filterId, () => getSavedFilters());
  };

  const onDisable = record => {
    const filterId = record.id;
    disableMassFilter(filterId, () => getSavedFilters());
  };

  const renderRow = () => {
    const { massFilters } = props;
    if (massFilters && Array.isArray(massFilters)) {
      return massFilters.map(item => ({ ...item, key: item.id }));
    }

    return [];
  };

  return (
    <Drawer
      className={css['SavedFilters-drawer']}
      title={t('titleSavedFilters')}
      placement='left'
      open={props.open}
      onClose={props.onClose}
      width={750}
    >
      <Table columns={columns} dataSource={renderRow()} pagination={false} />
      {showInfo && <SaveFilterModal onCancel={() => setShowInfo(false)} field={currentSelected} />}
    </Drawer>
  );
};

const mapActionToProps = {
  deleteMassFilter,
  enableMassFilter,
  disableMassFilter,
};

const mapStateToProps = createSelector([selectMassfilters, selectUsers], (filters, users) => ({
  massFilters: filters.massFilters,
  currentUser: users.currentUser,
}));

SavedFilters.propTypes = {
  t: PropTypes.func,
  enableMassFilter: PropTypes.func,
  getSavedFilters: PropTypes.func,
  currentUser: PropTypes.object,
  open: PropTypes.bool,
};

export default connect(mapStateToProps, mapActionToProps)(withTranslation('massSelection')(SavedFilters));
