/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360GetScales } from '@/store/d360/d360_scales/actions';

import { selectD360Scales } from '@/store/d360/d360_scales/selectors';

import { Button, Col, Modal, Row, Steps } from 'antd';

import StepCompetences from './StepCompetences';
import StepResults from './StepResults';
import StepScales from './StepScales';
import StepSkills from './StepSkills';

// Selectors
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { Step } = Steps;

export const EditCriteria = props => {
  const [currentStep, setCurrentStep] = useState(0);

  const [competence, setCompetence] = useState(null);
  const [skill, setSkill] = useState(null);
  const [competenceDescription, setCompetenceDescription] = useState(null);
  const [skillDescription, setSkillDescription] = useState(null);

  const [scale, setScale] = useState(null);

  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [disabledPrevButton, setDisabledPrevButton] = useState(true);
  const [disabledFinishButton, setDisabledFinishButton] = useState(true);
  const { t } = useTranslation('D360_EditCriteria');

  useEffect(() => {
    setDisabledPrevButton(true);
    setDisabledFinishButton(true);
    setDisabledNextButton(true);

    if (currentStep === 0) {
      if (typeof competence === 'object' && competence && competence.id) {
        setDisabledNextButton(false);
      }
    } else if (currentStep === 1) {
      setDisabledPrevButton(false);
      if ((typeof skill === 'object' && skill && skill.id) || skill === null) {
        setDisabledNextButton(false);
      }
    } else if (currentStep === 2) {
      setDisabledPrevButton(false);
      if (typeof scale === 'object' && scale && scale.id) {
        setDisabledNextButton(false);
      }
    } else if (currentStep === 3) {
      setDisabledPrevButton(true);
      setDisabledNextButton(true);
      if (
        typeof competence === 'object' &&
        competence &&
        competence.id &&
        (skill === null || (typeof skill === 'object' && skill && skill.id)) &&
        typeof scale === 'object' &&
        scale &&
        scale.id
      ) {
        setDisabledFinishButton(false);
      }
    }
  }, [currentStep, competence, skill, scale]);

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const onFinish = () => {
    if (props && props.onSubmit && typeof props.onSubmit === 'function') {
      setCurrentStep(0);
      props.onSubmit({
        competence: competence,
        competenceDescription: competenceDescription,
        skill: skill,
        skillDescription: skillDescription,
        scale: scale,
      });
    }
  };

  const resetWindow = () => {
    setCurrentStep(0);
  };

  const onCancel = () => {
    if (props && props.onCancel && typeof props.onCancel === 'function') {
      resetWindow();
      props.onCancel();
    }
  };

  const steps = [{ title: t('competence') }, { title: t('skill') }, { title: t('scale') }, { title: t('summary') }];

  const windowFooter = () => {
    return (
      <Row>
        <Col span={9} align='left'>
          <Button onClick={onCancel}>Отмена</Button>
        </Col>
        <Col span={15} align='right' className={css['ButtonSuccess']}>
          <Button onClick={onPrev} disabled={disabledPrevButton}>
            Назад
          </Button>
          <Button
            onClick={onFinish}
            disabled={disabledFinishButton}
            className={classNames({ [css['ButtonSuccess-succes']]: !disabledFinishButton })}
          >
            Завершить
          </Button>
          <Button type='primary' onClick={onNext} disabled={disabledNextButton}>
            Далее
          </Button>
        </Col>
      </Row>
    );
  };

  const handleSelectCompetence = (competence, description) => {
    setCompetence(competence);
    if (description && description.length) {
      setCompetenceDescription(description);
    }
  };

  const handleSelectScale = scale => {
    setScale(scale);
  };

  const handleSelectSkill = (skill, description) => {
    setSkill(skill);
    if (description && description.length) {
      setSkillDescription(description);
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <Modal
        title='Новый критерий'
        width={640}
        open={props.open}
        footer={windowFooter()}
        destroyOnClose
        onCancel={onCancel}
      >
        {currentStep === 0 && <StepCompetences onSelect={handleSelectCompetence} />}
        {currentStep === 1 && (
          <StepSkills projectId={props.project.id} competence={competence} onSelect={handleSelectSkill} />
        )}
        {currentStep === 2 && <StepScales project={props.project} onSelect={handleSelectScale} />}
        {currentStep === 3 && <StepResults competence={competence} skill={skill} scale={scale} />}
        <Steps current={currentStep} size='small'>
          {steps.map(step => (
            <Step key={step.title} title={step.title} />
          ))}
        </Steps>
      </Modal>
    );
  };

  return render();
};

EditCriteria.propTypes = {
  project: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = createSelector([selectD360Scales], D360Scales => ({
  scales: D360Scales.scales,
  loading: D360Scales.loading,
}));

const mapDispatchToProps = {
  D360GetScales,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCriteria);
