import { HARD } from '@/pages/Topics/TopicCreationDialog';

export const TOPIC_SUBTYPES = (t: (data: string) => string) => [
  {
    name: t('constants:online'),
    active: true,
    id: 12,
    typeId: 1,
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'startDate', required: false },
      { type: 'startTime', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 13,
    typeId: 1,
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'topicClass', required: false },
      { type: 'durationCourses', required: true },
      { type: 'startDate', required: false },
      { type: 'startTime', required: false },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    name: t('constants:online'),
    active: true,
    id: 18,
    typeId: 17,
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      // { type: 'webinarPlatform', required: false },
      // { type: 'entryPlatform', required: false },
      // { type: 'percentSitIn', required: false },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 19,
    typeId: 17,
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'topicClass', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    name: t('constants:online'),
    active: true,
    id: 21,
    typeId: 20,
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'durationCourses', required: true },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'company', required: false },
      // { type: 'webinarPlatform', required: false },
      // { type: 'entryPlatform', required: false },
      // { type: 'percentSitIn', required: false },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 22,
    typeId: 20,
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'topicClass', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    name: t('constants:mixed'),
    active: false,
    id: 14,
    typeId: 1,
    fields: [
      { type: 'links', required: true },
      { type: 'teachers', required: false },
      { type: 'durationCourses', required: false },
      { type: 'startDate', required: true },
      { type: 'startTime', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    name: t('constants:article'),
    active: false,
    id: 9,
    typeId: 2,
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'issued', required: false },
    ],
  },
  {
    name: t('constants:eBook'),
    active: false,
    id: 10,
    typeId: 2,
    fields: [
      { type: 'files', requiredIfEmpty: 'links' },
      { type: 'links', requiredIfEmpty: 'files' },
      { type: 'authors', required: true },
      { type: 'publisher', required: false },
      { type: 'issued', required: false },
    ],
  },
  {
    name: t('constants:paperBook'),
    active: false,
    id: 11,
    typeId: 2,
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: true },
      { type: 'publisher', required: false },
      { type: 'qiwiLibrary', required: true },
      { type: 'addressBook', required: true },
      { type: 'issued', required: false },
    ],
  },
  {
    name: t('constants:online'),
    active: true,
    id: 36,
    typeId: 35,
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 37,
    typeId: 35,
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'topicClass', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    name: t('constants:event'),
    active: false,
    fields: [
      { type: 'name', required: true },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
    ],
  },
];

export const TOPIC_TYPES = (t: (data: string) => string) => [
  {
    id: 1,
    name: t('constants:course'),
    active: true,
    code: 'course',
    fields: [
      { type: 'links' },
      { type: 'files' },
      { type: 'authors' },
      { type: 'topicClass' },
      { type: 'startDate' },
      { type: 'startTime' },
      { type: 'durationCourses' },
      { type: 'company' },
      { type: 'meetPlace' },
    ],
  },
  {
    id: 2,
    name: t('constants:text'),
    active: false,
    code: 'text',
    fields: [
      { type: 'files' },
      { type: 'links' },
      { type: 'authors' },
      { type: 'publisher' },
      { type: 'qiwiLibrary' },
      { type: 'addressBook' },
      { type: 'issued' },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 3,
    name: t('constants:audio'),
    active: false,
    code: 'audio',
    fields: [
      { type: 'files', required: true },
      { type: 'authors', required: false },
      { type: 'company', required: false },
      { type: 'duration', required: true },
      { type: 'issued', required: false },
    ],
  },
  {
    id: 4,
    name: t('constants:video'),
    active: true,
    code: 'video',
    fields: [
      { type: 'files' },
      { type: 'links' },
      { type: 'authors', required: false },
      { type: 'company', required: false },
      { type: 'hoursDuration', required: true },
    ],
  },
  {
    id: 5,
    name: t('constants:game'),
    active: true,
    code: 'game',
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'cost', required: false },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 6,
    name: t('constants:img'),
    active: false,
    code: 'img',
    fields: [
      { type: 'files', required: true },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 7,
    name: t('constants:presa'),
    active: false,
    code: 'presa',
    fields: [
      { type: 'files', requiredIfEmpty: 'links' },
      { type: 'links', requiredIfEmpty: 'files' },
      { type: 'authors', required: false },
      { type: 'company', required: false },
      { type: 'duration', required: false },
      { type: 'issued', required: false },
    ],
  },
  {
    id: 8,
    name: t('constants:event'),
    active: true,
    code: 'event',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'company', required: false },
      { type: 'comment', required: false },
      { type: 'webinarPlatform', required: false },
      { type: 'entryPlatform', required: false },
      { type: 'percentSitIn', required: false },
      { type: 'webinarType', required: false },
      { type: 'participantsCount', required: false, hideOnEdit: false },
      { type: 'schedule', required: true },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 15,
    name: t('constants:article'),
    active: true,
    code: 'text',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'year', required: false },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 16,
    name: t('constants:book'),
    active: true,
    code: 'book',
    fields: [
      { type: 'files', required: false },
      { type: 'links', required: false },
      { type: 'authors', required: false },
      { type: 'publisher', required: false },
      { type: 'qiwiLibrary', required: false },
      { type: 'addressBook', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'year', required: false },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 17,
    name: t('constants:corp'),
    active: true,
    code: 'cost',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'topicClass', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
      // { type: 'webinarPlatform', required: false },
      // { type: 'entryPlatform', required: false },
      // { type: 'percentSitIn', required: false },
    ],
  },
  {
    id: 20,
    name: t('constants:outer'),
    active: true,
    code: 'external',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'topicClass', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
      // { type: 'webinarPlatform', required: false },
      // { type: 'entryPlatform', required: false },
      // { type: 'percentSitIn', required: false },
    ],
  },
  {
    id: 23,
    name: t('constants:conference'),
    active: true,
    code: 'conference',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'company', required: false },
      { type: 'header', text: t('constants:addEvent'), hideOnEdit: true },
      { type: 'eventName', required: true, hideOnEdit: true },
      { type: 'participantsCount', required: false, hideOnEdit: true },
      { type: 'manager', required: false, hideOnEdit: true },
      { type: 'schedule', required: true, hideOnEdit: true },
      { type: 'comment', required: false, hideOnEdit: true },
      { type: 'hoursDuration', required: false },
      // { type: 'webinarPlatform', required: false },
      // { type: 'entryPlatform', required: false },
      // { type: 'percentSitIn', required: false },
    ],
  },
  {
    id: 25,
    name: t('constants:executive'),
    active: true,
    code: 'executive',
    fields: [
      { type: 'files', required: false },
      { type: 'executive_duration', required: true },
      { type: 'executive_activity', required: true },
      { type: 'executive_results', required: true },
      { type: 'executive_indicators', required: false },
      { type: 'executive_needfile', required: false },
    ],
  },
  {
    id: 35,
    name: t('constants:corp'),
    active: true,
    code: 'cost',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'costMin', required: false },
      { type: 'cost', required: true },
      { type: 'topicClass', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    id: 38,
    name: t('constants:pokaskills'),
    active: true,
    code: 'pokaSkills',
    fields: [
      { type: 'files', required: false },
      { type: 'durationCourses', required: false },
      { type: 'poka_category', required: true },
      { type: 'poka_site', required: true },
      { type: 'poka_assessment', required: true },
      { type: 'poka_revision', required: false },
      { type: 'poka_priority', required: true },
      { type: 'poka_cyclicality', required: false },
      { type: 'poka_approval', required: false },
      { type: 'poka_assessors', required: true },
    ],
  },
];

export const COMMON_FIELDS = [
  { apiField: 'name', required: true },
  { apiField: 'typeId', required: true },
  { apiField: 'description', required: true },
  { apiField: 'coverFileUuid', required: false },
  { apiField: 'tagNames', required: false },
  { apiField: 'qiwi', required: true },
  { apiField: 'topicCompetenceCategory', required: true },
  { apiField: 'categoryIds', required: false },
  { apiField: 'topicMandatory', required: true },
  { apiField: 'levelId', required: true },
  { apiField: 'languageId', required: true },
  { apiField: 'typeLabelId', required: true },
];

export const ITOD_FIELDS = [
  {
    apiField: 'careers',
    required: true,
    option: HARD,
  },
  {
    apiField: 'merits',
    required: true,
  },
];

export const COMPILATION_FIELD = [
  { apiField: 'name', required: true },
  { apiField: 'shortDescription', required: true },
  { apiField: 'fullDescription', required: true },
  { apiField: 'themes', required: true },
  { apiField: 'cover', required: true },
  { apiField: 'managerId', required: false },
  { apiField: 'career', required: false },
];

export const EVENT_FIELD = [
  { apiField: 'name', required: true },
  { apiField: 'count', required: false },
  { apiField: 'minCount', required: false },
  { apiField: 'fullName', required: false },
  { apiField: 'cost', required: true },
];

export const EVENT_PERIOD_FIELD = [
  { apiField: 'startTime', required: true },
  { apiField: 'endTime', required: true },
  { apiField: 'date', required: true },
];

export const FIELDS = (t: (data: string) => string) => ({
  files: {
    apiField: 'fileUuids',
    responseField: 'files',
    type: 'files',
    label: t('constants:files'),
  },
  links: {
    apiField: 'links',
    type: 'tagsInput',
    label: t('constants:links'),
  },
  lectors: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:lectors'),
  },
  videoStyle: {
    apiField: 'videoTypeId',
    responseField: 'videoType',
    type: 'videoStyleselector',
    label: t('constants:videoTypeId'),
  },
  topicClass: {
    apiField: 'topicClassId',
    responseField: 'topicClass',
    type: 'topicClassSelector',
    label: t('constants:topicClassId'),
  },
  company: {
    apiField: 'publisher',
    type: 'input',
    label: t('constants:company'),
  },
  duration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'day',
    label: t('constants:duration'),
  },
  schedule: {
    apiField: 'dates',
    type: 'schedule',
    label: t('constants:dates'),
  },
  durationCourses: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:dur'),
  },
  weeksDuration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'week',
    label: t('constants:duration'),
  },
  hoursDuration: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:duration'),
  },
  issued: {
    apiField: 'issued',
    type: 'calendar',
    label: t('constants:issued'),
  },
  costMin: {
    apiField: 'costMin',
    type: 'minNumberInput',
    label: t('constants:costMin'),
  },
  cost: {
    apiField: 'cost',
    type: 'numberInput',
    label: t('constants:cost'),
  },
  year: {
    apiField: 'issued',
    type: 'year',
    label: t('constants:year'),
  },
  publisher: {
    apiField: 'publisher',
    type: 'input',
    label: t('constants:publisher'),
  },
  authors: {
    apiField: 'authors',
    type: 'authorsInput',
    label: t('constants:authors'),
  },
  teachers: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:teacher'),
  },
  startDate: {
    apiField: 'startDate',
    type: 'calendar',
    label: t('constants:startDate'),
  },
  startTime: {
    apiField: 'startTime',
    type: 'dateTimeInput',
    label: t('constants:startTime'),
  },
  meetPlace: {
    apiField: 'meetPlace',
    type: 'input',
    label: t('constants:meetPlace'),
  },
  executive_duration: {
    apiField: 'executive_duration',
    type: 'executive_duration',
    label: t('constants:executive_duration'),
  },
  executive_results: {
    apiField: 'executive_results',
    type: 'executive_results',
    label: t('constants:executive_results'),
  },
  executive_activity: {
    apiField: 'executive_activity',
    type: 'executive_activity',
    label: t('constants:executive_activity'),
    rows: 3,
  },
  executive_indicators: {
    apiField: 'executive_indicators',
    type: 'executive_indicators',
    label: t('constants:executive_indicators'),
    rows: 3,
  },
  executive_needfile: {
    apiField: 'executive_needfile',
    type: 'executive_needfile',
    label: t('constants:executive_needfile'),
  },
  comment: {
    apiField: 'comment',
    type: 'comment',
    label: t('constants:comment'),
  },
  qiwiLibrary: {
    apiField: 'qiwiLibrary',
    type: 'checkbox',
    label: t('constants:qiwiLibrary'),
  },
  addressBook: {
    apiField: 'addressBook',
    type: 'addressBookInput',
    label: t('constants:addressBook'),
  },
  participantsCount: {
    apiField: 'participantsCount',
    responseField: 'topicLimit',
    type: 'participantsCount',
    label: t('constants:participantsCount'),
  },
  manager: {
    apiField: 'managerId',
    type: 'manager',
    label: t('constants:managerId'),
  },
  eventName: {
    apiField: 'eventName',
    label: t('constants:eventName'),
  },
  webinarPlatform: {
    apiField: 'webinarPlatform',
    type: 'webinarPlatform',
    label: t('constants:webinarPlatform'),
  },
  entryPlatform: {
    apiField: 'entryPlatform',
    type: 'entryPlatform',
    label: t('constants:entryPlatform'),
  },
  percentSitIn: {
    apiField: 'percentSitIn',
    type: 'percentSitIn',
    label: t('constants:percentSitIn'),
  },
  webinarType: {
    apiField: 'webinarType',
    type: 'webinarType',
    label: t('constants:webinarType'),
  },
  poka_category: {
    apiField: 'categories',
    type: 'poka_category',
    label: t('constants:poka_category'),
  },
  poka_site: {
    apiField: 'sites',
    type: 'poka_site',
    label: t('constants:poka_site'),
  },
  poka_assessment: {
    apiField: 'endorsementLevel',
    type: 'poka_assessment',
    label: t('constants:poka_assessment'),
  },
  poka_revision: {
    apiField: 'revision',
    type: 'poka_revision',
    label: t('constants:poka_revision'),
  },
  poka_priority: {
    apiField: 'endorsementPriority',
    type: 'poka_priority',
    label: t('constants:poka_priority'),
  },
  poka_cyclicality: {
    apiField: 'cyclicity',
    type: 'poka_cyclicality',
    label: t('constants:poka_cyclicality'),
  },
  poka_approval: {
    apiField: 'withoutValidation',
    type: 'poka_approval',
    label: t('constants:poka_approval'),
  },
  poka_assessors: {
    apiField: 'assessors',
    type: 'poka_assessors',
    label: t('constants:poka_assessors'),
  },

  header: {},
});

export const PUBLIC_FIELDS = (t: (data: string) => string) => ({
  files: {
    apiField: 'fileUuids',
    responseField: 'files',
    type: 'files',
    label: t('constants:files'),
  },
  links: {
    apiField: 'links',
    type: 'tagsInput',
    label: t('constants:links'),
  },
  lectors: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:lectors'),
  },
  videoStyle: {
    apiField: 'videoTypeId',
    responseField: 'videoType',
    type: 'videoStyleselector',
    label: t('constants:videoTypeId'),
  },
  topicClass: {
    apiField: 'topicClassId',
    responseField: 'topicClass',
    type: 'topicClassSelector',
    label: t('constants:videoTypeId'),
  },
  company: {
    apiField: 'publisher',
    type: 'input',
    label: t('constants:company'),
  },
  schedule: {
    apiField: 'dates',
    type: 'schedule',
    label: t('constants:dates'),
  },
  durationCourses: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:dur'),
  },
  duration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'day',
    label: t('constants:duration'),
  },
  weeksDuration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'week',
    label: t('constants:duration'),
  },
  hoursDuration: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:duration'),
  },
  issued: {
    apiField: 'issued',
    type: 'calendar',
    label: t('constants:issued'),
  },
  costMin: {
    apiField: 'costMin',
    type: 'minNumberInput',
    label: t('constants:costMin'),
  },
  cost: {
    apiField: 'cost',
    type: 'numberInput',
    label: t('constants:cost'),
  },
  year: {
    apiField: 'issued',
    type: 'year',
    label: t('constants:year'),
  },
  publisher: {
    apiField: 'publisher',
    type: 'input',
    label: t('constants:publisher'),
  },
  authors: {
    apiField: 'authors',
    type: 'authorsInput',
    label: t('constants:authors'),
  },
  teachers: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:teacher'),
  },
  startDate: {
    apiField: 'startDate',
    type: 'calendar',
    label: t('constants:startDate'),
  },
  startTime: {
    apiField: 'startTime',
    type: 'dateTimeInput',
    label: t('constants:startTime'),
  },
  meetPlace: {
    apiField: 'meetPlace',
    type: 'input',
    label: t('constants:meetPlace'),
  },
  qiwiLibrary: {
    apiField: 'qiwiLibrary',
    type: 'checkbox',
    label: t('constants:qiwiLibrary'),
  },
  addressBook: {
    apiField: 'addressBook',
    type: 'addressBookInput',
    label: t('constants:addressBook'),
  },
  executive_duration: {
    apiField: 'duration',
    type: 'executive_duration',
    label: t('constants:executive_duration'),
  },
});

export const COMPILATIONS_TYPE = (t: (data: string) => string) => [
  {
    name: t('constants:title'),
    type: 'COMPILATION',
  },
  {
    name: t('constants:expert'),
    type: 'EXPERT',
  },
  {
    name: t('constants:skill'),
    type: 'SKILL',
  },
];

export const COMPILATIONS_TYPE_CONST = [
  { label: 'Название', type: 'COMPILATION' },
  { label: 'Эксперт', type: 'EXPERT' },
  { label: 'Навык', type: 'SKILL' },
];
