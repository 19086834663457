/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import EventRow from '@/components/EventRow';
import Modal from '@/components/Modal';
import ScrollArrow from '@/components/ScrollArrow';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './EventSelectModal.module.scss';

export class EventSelectModal extends Component {
  static propTypes = {
    events: PropTypes.object,
    onCancel: PropTypes.func,
    onSelect: PropTypes.func,
    onCreateNew: PropTypes.func,
    startDate: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      years: Object.keys(props.events),
      yearIndex: 0,
      showEventNoPlaceModal: false,
    };
  }

  hideEventNoPlaceModal = () => {
    this.setState({ showEventNoPlaceModal: false });
  };

  onSelectClick = () => {
    const { years, yearIndex, selected } = this.state;
    const event = this.props.events[years[yearIndex]].find(data => data.id === selected);
    if (event) {
      if (event.participantsCount === 0 || event.registerCount + event.approvedCount < event.participantsCount) {
        this.props.onSelect(selected);
      } else {
        this.setState({ showEventNoPlaceModal: true });
      }
    }
  };

  render() {
    const { t } = this.props;
    const { years, yearIndex, selected } = this.state;
    return (
      <>
        <Modal open hideFooter onCancel={this.props.onCancel} width={600} headerFixToModal>
          <Form>
            <div className={css['eventSelect-body']}>
              <div className={css['header']}>
                <div>
                  <div className={css['header-text']}>{t('addUser')}</div>
                  <div className={css['header-text']}>
                    {t('inner')} {dayjs(this.props.startDate).format('DD.MM.YYYY')}
                  </div>
                </div>
                <div className={css['header-arrows']}>
                  <ScrollArrow
                    scrollDirection='left'
                    disabled={yearIndex === 0}
                    onClick={() => this.setState({ yearIndex: yearIndex - 1 })}
                    light
                  />
                  <div className={css['header-year']}>{years[yearIndex]}</div>
                  <ScrollArrow
                    scrollDirection='right'
                    disabled={yearIndex + 1 === years.length}
                    onClick={() => this.setState({ yearIndex: yearIndex + 1 })}
                    light
                  />
                </div>
              </div>
              {this.props.events[years[yearIndex]].map(event => (
                <EventRow
                  key={event.id}
                  event={event}
                  setParams={id => this.setState({ selected: id })}
                  selected={event.id === selected}
                  isInModal
                  addUser
                />
              ))}

              <div className={css['buttons']}>
                <Button
                  type='primary'
                  size='large'
                  className={css['btn-event']}
                  onClick={this.onSelectClick}
                  disabled={!selected}
                >
                  {t('addToEvent')}
                </Button>
                <Button
                  // ghost
                  type='primary'
                  size='large'
                  className={css['btn-event']}
                  onClick={this.props.onCreateNew}
                >
                  {t('addToNewEvent')}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
        {this.state.showEventNoPlaceModal && (
          <ConfirmationModal
            visible
            onOk={this.hideEventNoPlaceModal}
            onCancel={this.hideEventNoPlaceModal}
            okText={t('ok')}
            confirmationText={t('description')}
          />
        )}
      </>
    );
  }
}

export default withTranslation('eventSelectModal')(EventSelectModal);
