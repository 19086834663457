import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteBadgeUser, deleteMassBadgeUser, getAppointBadges, saveBadgeInfoSort } from '@/store/badge/actions';

import { selectBadge } from '@/store/badge/selectors';

import { Button, Dropdown, Modal, Table, Tooltip } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import classNames from 'classnames';

import css from '../BadgeDetail.module.scss';

const BadgeTable = () => {
  const { t } = useTranslation('badgeDetail');
  const dispatch = useDispatch();

  const {
    badgeAppointLoading,
    badgeAppointTable,
    badgeAppointTotal,
    badgeAppointPageSize,
    badgeAppointPageNumber,
    badgeAppointCurrentPage,
    badgeInfoSort,
    badgeId,
    badgeInfoFilters,
  } = useSelector(selectBadge);

  const [activeSort, setActiveSort] = useState({ fio: false, date: false });
  const [userIds, setUserIds] = useState([]);
  const [modal, contextHolder] = Modal.useModal();

  const sortFio = () => {
    setActiveSort({ fio: !activeSort.fio, date: false });

    if (!activeSort.fio) {
      dispatch(saveBadgeInfoSort('user.firstName,user.lastName,desc'));
      dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, 0, 'user.firstName,user.lastName,desc'));
    } else {
      dispatch(saveBadgeInfoSort(''));
      dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, 0));
    }
  };

  const sortDate = () => {
    setActiveSort({ fio: false, date: !activeSort.date });

    if (!activeSort.date) {
      dispatch(saveBadgeInfoSort('receiptDate,desc'));
      dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, 0, 'receiptDate,desc'));
    } else {
      dispatch(saveBadgeInfoSort(''));
      dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, 0));
    }
  };

  const templateCallback = () => {
    dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, pagination.current - 1, badgeInfoSort));
  };

  const deleteBadge = item => {
    if (item?.userId && badgeId?.id) {
      const params = { rewardId: badgeId.id, userId: item.userId };
      dispatch(deleteBadgeUser(params, () => templateCallback()));
      // в редюсере нужно будет ответ id найти в badgeAppointTable и отфильтровать оттуда этот id
    }
  };

  const onMassDelete = () => {
    if (userIds.length > 0 && badgeId?.id) {
      const params = { rewardId: badgeId.id, userIds: userIds };
      dispatch(deleteMassBadgeUser(params, () => templateCallback()));
    }
  };

  const showModalDelete = (item, isMass) => {
    modal.warning({
      icon: (
        <HiOutlineQuestionMarkCircle color='var(--personalColor)' className={css['BadgeTable-modal-icon']} size={24} />
      ),
      content: (
        <div className={css['BadgeTable-modal-info']}>
          <div>{isMass ? t('titleMassUsersMassDeleteBadge') : t('titleUserDeleteBadge')}</div>
          <div> {t('txtDeleteBadge')}</div>
        </div>
      ),
      okText: t('delete'),
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      closable: true,
      onOk: isMass ? onMassDelete : () => deleteBadge(item),
      maskClosable: true,
    });
  };

  const items = [
    {
      key: '1',
      label: t('massDelete'),
      onClick: () => showModalDelete(undefined, true),
    },
  ];

  const columns = [
    {
      title: t('badgeInfoFullname'),
      dataIndex: 'badgeInfoFullname',
      key: '1',
    },
    {
      title: t('badgeInfoDep'),
      dataIndex: 'badgeInfoDep',
      key: '2',
    },
    {
      title: t('badgeInfoFun'),
      dataIndex: 'badgeInfoFun',
      key: '3',
    },
    {
      title: t('badgeInfoDate'),
      dataIndex: 'badgeInfoDate',
      key: '4',
      width: 150,
    },
    {
      title: t('badgeInfoIssue'),
      dataIndex: 'badgeInfoIssue',
      key: '5',
      width: 150,
    },
    {
      dataIndex: 'action',
      key: '6',
      align: 'center',
      render: (_, item) => (
        <Tooltip title={t('infoDeleteBadge')}>
          <DeleteOutlined className={css['BadgeTable-table-actions-delete']} onClick={() => showModalDelete(item)} />
        </Tooltip>
      ),
    },
  ];

  const pagination = {
    total: badgeAppointTotal,
    pageSize: badgeAppointPageSize,
    pageNumber: badgeAppointPageNumber,
    current: badgeAppointCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const dataSource = useMemo(
    () =>
      badgeAppointTable?.length > 0 &&
      badgeAppointTable.map(item => {
        return {
          badgeInfoFullname: item.fullName,
          badgeInfoDep: item.department,
          badgeInfoFun: item.function,
          badgeInfoDate: item.receiptDate,
          badgeInfoIssue: item.addType,
          id: item.id,
          userId: item.userId,
        };
      }),
    [badgeAppointTable]
  );

  const onChangeTable = pagination => {
    dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, pagination.current - 1, badgeInfoSort));
  };

  const onSelectChange = (_, items) => {
    const userIds = items.map(i => i?.userId);
    setUserIds(userIds);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  return (
    <div className={css['BadgeTable']}>
      <div className={css['BadgeTable-sort']}>
        <div>{t('sort')}</div>
        <div
          className={classNames(css['BadgeTable-sort-fio'], activeSort.fio && css['BadgeTable-sort-active'])}
          onClick={sortFio}
        >
          {t('sortFio')}
        </div>
        <div
          className={classNames(css['BadgeTable-sort-date'], activeSort.date && css['BadgeTable-sort-active'])}
          onClick={sortDate}
        >
          {t('sortDate')}
        </div>
      </div>

      <div className={css['BadgeTable-table']}>
        <div className={css['BadgeTable-table-actions']}>
          <Dropdown menu={{ items }} disabled={!userIds.length}>
            <Button icon={<DownOutlined />} iconPosition='end'>
              {t('massActions')}
            </Button>
          </Dropdown>
        </div>

        <Table
          pagination={pagination}
          loading={badgeAppointLoading}
          columns={columns}
          dataSource={dataSource}
          rowKey={e => e.userId}
          onChange={onChangeTable}
          rowSelection={rowSelection}
        />
      </div>

      {contextHolder}
    </div>
  );
};

export default BadgeTable;
