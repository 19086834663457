// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo360 {
  transform-style: preserve-3d;
  text-align: center;
  animation-name: spinner;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.projectInfoBlink {
  animation: 1s linear infinite blinker;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/D360/css/logo360.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,uBAAuB;EACvB,uBAAuB;EACvB,iCAAiC;EACjC,mCAAmC;EACnC,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".Logo360 {\n  animation-name: spinner;\n  animation-timing-function: linear;\n  animation-iteration-count: infinite;\n  animation-duration: 20s;\n  transform-style: preserve-3d;\n  text-align: center;\n  display: inline-block;\n}\n\n.projectInfoBlink {\n  animation: blinker 1s linear infinite;\n}\n\n/* .ant-tabs-content { // todo вынести в модуль\n  border: 1px solid #e8e8e8;\n} */\n\n/* @keyframes blinker { */\n\n/*  50% { */\n\n/*    opacity: 0; */\n\n/*  } */\n\n/* } */\n\n/* @keyframes spinner { */\n\n/*  from { */\n\n/*    transform: rotateY(360deg); */\n\n/*  } */\n\n/*  to { */\n\n/*    transform: rotateX(0deg); */\n\n/*  } */\n\n/* } */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
