import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getAllRoles } from '@/store/access/actions';
import { getCompaniesShort } from '@/store/companies/actions';
import { createUser } from '@/store/students/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectLogin } from '@/store/login/selectors';

import { Button, Form, Input, notification, Select } from 'antd';

import css from './AddUser.module.scss';

const MAX_SYMBOL = 50;

const AddUser = ({ roles }) => {
  const { t } = useTranslation('RolesSettings');
  const { companiesShort } = useSelector(selectCompanies);
  const { company } = useSelector(selectLogin);

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCompaniesShort());
  }, []);

  const selectCompany = value => {
    dispatch(getAllRoles([company.id, value]));
    if (form.getFieldValue('roleId')) form.resetFields(['roleId']);
  };

  const onSubmit = data => {
    dispatch(
      createUser(
        { ...data, login: data.email },
        () => {
          api.success({
            message: t('success'),
            description: t('successAdd'),
          });
          form.resetFields(['firstName', 'lastName', 'middleName', 'email', 'password', 'companyId', 'roleId']);
        },
        err => {
          api.error({
            message: t('error'),
            description: err.message,
          });
        }
      )
    );
  };

  return (
    <div className={css['Add-user']}>
      {contextHolder}
      <Form onFinish={onSubmit} form={form} layout='vertical' className={css['Add-user__form']}>
        <Form.Item
          label={t('firstName')}
          name='firstName'
          rules={[
            {
              max: MAX_SYMBOL,
              required: true,
              whitespace: true,
              message: t('errorInput', { inputName: `"${t('firstName')}"` }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('lastName')}
          name='lastName'
          rules={[
            {
              max: MAX_SYMBOL,
              required: true,
              whitespace: true,
              message: t('errorInput', { inputName: `"${t('lastName')}"` }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('middleName')}
          name='middleName'
          rules={[
            {
              max: MAX_SYMBOL,
              required: true,
              whitespace: true,
              message: t('errorInput', { inputName: `"${t('middleName')}"` }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('email')}
          name='email'
          rules={[
            {
              max: MAX_SYMBOL,
              required: true,
              whitespace: true,
              type: 'email',
              message: t('errorInput', { inputName: `"${t('email')}"` }),
            },
          ]}
        >
          <Input type='email' />
        </Form.Item>
        <Form.Item
          label={t('password')}
          name='password'
          rules={[
            {
              required: true,
              type: 'password',
              message: t('errorInput', { inputName: `"${t('password')}"` }),
            },
          ]}
        >
          <Input type='password' />
        </Form.Item>
        <Form.Item
          label={t('company')}
          name='companyId'
          rules={[
            {
              required: true,
              message: t('errorInput', { inputName: `"${t('company')}"` }),
            },
          ]}
        >
          <Select onSelect={selectCompany} options={companiesShort.map(_ => ({ value: _.id, label: _.shortName }))} />
        </Form.Item>
        <Form.Item
          label={t('role')}
          name='roleId'
          rules={[
            {
              required: true,
              message: t('errorInput', { inputName: `"${t('role')}"` }),
            },
          ]}
        >
          <Select
            disabled={!form.getFieldValue('companyId')}
            options={roles.map(_ => ({ value: _.id, label: _.name }))}
          />
        </Form.Item>
        <Button size='large' htmlType='submit' type='primary'>
          {t('createUser')}
        </Button>
      </Form>
    </div>
  );
};

export default AddUser;
