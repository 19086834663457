/* eslint-disable */
import React from 'react';

import { Form, Input } from 'antd';
import Checkbox from '@/components/Checkbox';
import ImageInput from '@/components/ImageInputCrop';
import CustomInput from '@/components/Input';
import Select from '@/components/Select';

import SettingsTalentq from './SettingsTalentq';

import classNames from 'classnames';

import css from './CompanyCreationDialog.module.scss';

const { TextArea } = Input;

export const Settings = ({
  company,
  handleChange,
  handleImageChange,
  fileInfo,
  fileLoadingImage,
  locales,
  timezones,
  t,
  open,
  modalTalentqSubmit,
  modalTalentqCancel,
  optionsDiagnostics,
}) => {
  const defaultLang = locales.find(
    item => item.language === company.defaultLanguage || item.languageDesc === company.defaultLanguage
  );

  return (
    <>
      <div className={css['Add-modal']}>
        <div className={css['Add-modal-title']}>{t('defaultSettings')}</div>
        {/* НАЗВАНИЕ */}
        <Form.Item
          label={t('title')}
          validateStatus={company.errors.shortName ? 'error' : ''}
          help={company.errors.shortName && t('require')}
          colon={false}
          required
          labelAlign='left'
        >
          <CustomInput
            name='shortName'
            placeholder={t('companyTitle')}
            onChange={handleChange}
            value={company.shortName}
            maxLength={50}
          />
        </Form.Item>

        {/* КРАТКОЕ ОПИСАНИЕ */}
        <Form.Item
          label={
            <div>
              <div>{t('short')}</div>
              <span className={css['Add-modal-description']}>{t('max')}</span>
            </div>
          }
          labelAlign='left'
        >
          <TextArea
            name='fullName'
            placeholder={t('detail')}
            // className={css['Add-modal-textarea']}
            onChange={handleChange}
            value={company.fullName}
            maxLength={140}
          />
        </Form.Item>

        {/* ПОЛЬЗОВАТЕЛЬСКИЙ ДОМЕН */}
        <Form.Item
          label={t('usersDomain')}
          validateStatus={company.errors.domain ? 'error' : ''}
          help={company.errors.domain && t('require')}
          colon={false}
          required
          labelAlign='left'
        >
          <CustomInput
            name='domain'
            placeholder={t('domainDescription')}
            onChange={handleChange}
            value={company.domain}
            maxLength={50}
          />
        </Form.Item>

        {/* АДМИНСКИЙ ДОМЕН */}
        <Form.Item
          label={t('adminDomain')}
          validateStatus={company.errors.adminDomain ? 'error' : ''}
          help={company.errors.adminDomain && t('require')}
          colon={false}
          required
          labelAlign='left'
        >
          <CustomInput
            name='adminDomain'
            placeholder={t('domainDescription')}
            onChange={handleChange}
            value={company.adminDomain}
            maxLength={50}
          />
        </Form.Item>

        {/* ЦВЕТ */}
        <Form.Item
          label={t('color')}
          validateStatus={company.errors.colour ? 'error' : ''}
          help={company.errors.colour && t('require')}
          colon={false}
          required
          labelAlign='left'
        >
          <CustomInput
            name='colour'
            placeholder='#000000'
            onChange={handleChange}
            value={company.colour}
            maxLength={50}
          />
        </Form.Item>

        {/* ЛОГО */}
        <Form.Item
          label={
            <div>
              <div>{t('logo2')}</div>
              <span className={css['Add-modal-description']}>SVG 150x45</span>
            </div>
          }
          labelAlign='left'
          validateStatus={company.errors.logo ? 'error' : ''}
          help={company.errors.logo && t('require')}
          colon={false}
          required
        >
          <ImageInput
            reverse
            company
            noCrop
            id='logo'
            onChange={handleImageChange}
            name='logo'
            imageSrc={company.images.logo}
            fileInfo={fileInfo}
            isLoadingFileImage={fileLoadingImage}
          />
        </Form.Item>

        {/* БЭКГРАУНД ПОД ЛОГО */}
        <Form.Item
          label={
            <div>
              <div>{t('logoBackground')}</div>
              <span className={css['Add-modal-description']}>PNG/JPG 600x100</span>
            </div>
          }
          labelAlign='left'
        >
          <ImageInput
            reverse
            company
            noCrop
            id='background'
            onChange={handleImageChange}
            name='background'
            imageSrc={company.images.background}
            fileInfo={fileInfo}
            isLoadingFileImage={fileLoadingImage}
          />
        </Form.Item>

        {/* ЯЗЫК ПО УМОЛЧАНИЮ */}
        <Form.Item label={t('lang')} labelAlign='left'>
          <Select
            className={classNames(css.TopicCreate__select)}
            onChange={handleChange}
            name='defaultLanguage'
            options={locales.map(item => ({
              id: item.language,
              name: item.languageDesc,
            }))}
            value={defaultLang && defaultLang.languageDesc}
            white
          />
        </Form.Item>

        {/* ВРЕМЕННАЯ ЗОНА */}
        <Form.Item label={t('timeZone')} labelAlign='left'>
          <Select
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            notFoundPlaceholder={t('noResults')}
            className={classNames(css.selectTimeZones)}
            name='timezone'
            options={timezones}
            onChange={handleChange}
            value={company.timezone.name}
            white
          />
        </Form.Item>

        {/* ДИАГНОСТИКА */}
        <Form.Item label={t('diagnostics')} labelAlign='left'>
          <Select
            className={classNames(css.selectDiagnostics)}
            onChange={handleChange}
            name='diagnosticType'
            options={optionsDiagnostics}
            value={company.diagnosticType.type}
            white
          />
        </Form.Item>

        {/* КНОПКА "СВЯЗЬ С KAMPUS */}
        <Form.Item
          label={t('massage')}
          validateStatus={company.errors.supportLink ? 'error' : ''}
          help={company.errors.supportLink && t('require')}
          colon={false}
          required
          labelAlign='left'
        >
          <CustomInput
            name='supportLink'
            placeholder={t('link')}
            onChange={handleChange}
            value={company.supportLink}
            maxLength={255}
          />
        </Form.Item>

        {/* КНОПКА "ПОМОЩЬ */}
        <Form.Item label={t('help')} labelAlign='left'>
          <CustomInput
            name='helpLink'
            placeholder={t('link')}
            onChange={handleChange}
            value={company.helpLink}
            maxLength={255}
          />
        </Form.Item>

        {/* КНОПКА TELEGRAM-БОТ */}
        <Form.Item label={t('telegram')} labelAlign='left'>
          <CustomInput
            name='telegramBotLink'
            placeholder={t('link2')}
            onChange={handleChange}
            value={company.telegramBotLink}
            maxLength={255}
          />
        </Form.Item>

        {/* ДОПОЛНИТЕЛЬНАЯ КНОПКА 1 */}
        <Form.Item label={t('additional')} labelAlign='left'>
          <CustomInput
            name='tradeLinks.0.name'
            placeholder={t('title2ru')}
            onChange={handleChange}
            value={company.tradeLinks && company.tradeLinks[0] && company.tradeLinks[0].name}
            maxLength={50}
          />
        </Form.Item>
        <Form.Item label=' '>
          <CustomInput
            name='tradeLinks.0.additionalName'
            placeholder={t('title2en')}
            onChange={handleChange}
            value={company.tradeLinks && company.tradeLinks[0] && company.tradeLinks[0].additionalName}
            maxLength={50}
          />
        </Form.Item>
        <Form.Item label=' '>
          <CustomInput
            name='tradeLinks.0.url'
            placeholder={t('link3')}
            onChange={handleChange}
            value={company.tradeLinks && company.tradeLinks[0] && company.tradeLinks[0].url}
            maxLength={255}
          />
        </Form.Item>

        {/* ДОПОЛНИТЕЛЬНАЯ КНОПКА 2 */}
        <Form.Item label={t('additional2')} labelAlign='left'>
          <CustomInput
            name='tradeLinks.1.name'
            placeholder={t('title2ru')}
            onChange={handleChange}
            value={company.tradeLinks && company.tradeLinks[1] && company.tradeLinks[1].name}
            maxLength={50}
          />
        </Form.Item>

        <Form.Item label=' '>
          <CustomInput
            name='tradeLinks.1.additionalName'
            placeholder={t('title2en')}
            onChange={handleChange}
            value={company.tradeLinks && company.tradeLinks[1] && company.tradeLinks[1].additionalName}
            maxLength={50}
          />
        </Form.Item>
        <Form.Item label=' '>
          <CustomInput
            name='tradeLinks.1.url'
            placeholder={t('link3')}
            onChange={handleChange}
            value={company.tradeLinks && company.tradeLinks[1] && company.tradeLinks[1].url}
            maxLength={255}
          />
        </Form.Item>

        {/* GOOGLE ANALYTICS ID */}
        <Form.Item label='Google Analytics ID' labelAlign='left'>
          <CustomInput
            name='googleAnalyticId'
            placeholder={t('mask')}
            onChange={handleChange}
            value={company.googleAnalyticId}
            maxLength={50}
          />
        </Form.Item>

        {/* GOOGLE CAPTCHA */}
        <Form.Item label='Google Captcha' labelAlign='left'>
          <Checkbox onChange={handleChange} name='hasCaptcha' value={company.hasCaptcha} />
        </Form.Item>
      </div>

      <SettingsTalentq
        open={open}
        modalTalentqSubmit={modalTalentqSubmit}
        modalTalentqCancel={modalTalentqCancel}
        t={t}
        company={company}
      />
    </>
  );
};
