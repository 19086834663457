/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createProfileCustomFieldValue, updateProfileCustomFieldValue } from '@/store/pcfieldvalue/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { Select } from '@/components/elements';
import CustomInput from '@/components/Input';

import CustomFieldList from './CustomFields/List';

import DatePicker from '@shared/components/DatePicker';
import { searchDepartment } from '@shared/utils/department-utils';
import { searchFunction } from '@shared/utils/function-utils';
import { searchUsers } from '@shared/utils/users-utils';

import Utils from '@/Utils.js';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './Users.module.scss';

const { TextArea } = Input;

class EditUser extends Component {
  constructor(props) {
    super(props);
    const {
      departmentInternalDto,
      functionInternalDto,
      manager,
      firstName,
      lastName,
      middleName,
      company,
      location,
      purpose,
      startOfJob,
      email,
      login,
      changeUserError,
    } = props.student;

    this.state = {
      firstName,
      lastName,
      middleName,
      functionDEP: props.student.function,
      company,
      location,
      purpose,
      startOfJob,
      email,
      login,
      pcFields: [],
      departmentInternalId: departmentInternalDto ? departmentInternalDto.id : null,
      functionInternalId: functionInternalDto ? functionInternalDto.id : null,
      managerId: null,
      managerName: manager || null,
      errorInput: {
        firstName: '',
        lastName: '',
        login: '',
        email: email?.length === 0 ? props.t('fieldMustNotBeEmpty') : '',
      },
      changeUserError,
    };

    this.departmentOptions = departmentInternalDto
      ? [
          {
            value: departmentInternalDto.id,
            label: departmentInternalDto.name || null,
          },
        ]
      : [];
    this.careerOptions = functionInternalDto
      ? [
          {
            value: functionInternalDto.id,
            label: functionInternalDto.name || null,
          },
        ]
      : [];
  }

  componentDidMount() {
    if (this.props.student.manager) {
      searchUsers(this.props.student.manager).then(response => {
        if (response.length && response[0].value) {
          this.setState({ managerId: response[0].value });
        }
      });
    }
  }

  handleChange = event => {
    const { name, value } = event.target || event;

    const newState = {};
    newState.errorInput = { ...this.state.errorInput };

    if (name === 'firstName' || name === 'lastName' || name === 'login') {
      newState.errorInput[name] = value.length === 0 ? this.props.t('fieldMustNotBeEmpty') : '';
    }

    if (name === 'email') {
      if (value.length === 0) {
        newState.errorInput[name] = this.props.t('fieldMustNotBeEmpty');
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        newState.errorInput[name] = this.props.t('incorrectEmail');
      } else {
        newState.errorInput[name] = '';
      }
    }

    newState[name] = value;

    this.setState(newState);
  };

  // -- Принимаем изменения кастомных полей
  onFieldsChange = changes => {
    this.setState({ pcFields: changes });
  };

  handleSave = () => {
    const { about, id, localPhotoUuid, notifyEnable, secondName } = this.props.student;
    const { functionDEP, startOfJob, purpose, pcFields, managerId } = this.state;
    const copyState = _.cloneDeep(this.state);
    delete copyState.pcFields;

    Object.keys(copyState).forEach(key => {
      if (copyState[key] && typeof copyState[key] === 'string') {
        copyState[key] = copyState[key].trim();
      }
    });

    // -- Сохраняем кастомные поля
    if (pcFields && Array.isArray(pcFields)) {
      this.props.updateProfileCustomFieldValue(id, pcFields);
    }

    // -- Старый код
    this.props.changeUser(
      {
        ...copyState,
        about,
        id,
        localPhotoUuid,
        notifyEnable,
        secondName,
        purpose,
        startOfJob: startOfJob ? dayjs(startOfJob).format('YYYY-MM-DD') : null,
        function: functionDEP,
        managerIds: managerId ? [managerId] : [],
      },
      () => this.props.changeManagerModalVisibility()
    );
  };

  render() {
    const {
      student: { managers },
      hasAdditionalInfo,
      hasManager,
      t,
      isSuperAdmin,
    } = this.props;

    const { firstName, lastName, middleName, functionDEP, company, location, purpose, startOfJob, email, login } =
      this.state;

    const options = managers[0]
      ? [
          {
            value: managers[0].userId || managers[0].id,
            label: `${managers[0].firstName} ${managers[0].lastName}`,
          },
        ]
      : [];

    const disabledSaving = Boolean(
      this.state.errorInput.firstName ||
        this.state.errorInput.lastName ||
        this.state.errorInput.login ||
        this.state.errorInput.email ||
        Utils.isManager(this.props.currentUser)
    );

    const validateMessages = {
      required: t('fieldMustNotBeEmpty'),
      types: {
        email: t('invalidEmail'),
      },
    };

    const footer = (
      <div>
        <Button style={{ marginRight: '20px' }} onClick={this.props.changeManagerModalVisibility}>
          {t('cancel')}
        </Button>
        <Tooltip title={t('noEditing')}>
          <Button type='primary' onClick={this.handleSave} disabled={disabledSaving}>
            {t('save')}
          </Button>
        </Tooltip>
      </div>
    );

    return (
      <Modal
        open
        onCancel={this.props.changeManagerModalVisibility}
        title={t('edit')}
        width={640}
        className={css['EditModal']}
        footer={footer}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          colon={false}
          validateMessages={validateMessages}
          disabled={Utils.isManager(this.props.currentUser)}
        >
          <Form.Item
            label={t('login')}
            name='login'
            required
            labelAlign='left'
            validateStatus={this.state.errorInput.login ? 'error' : ''}
            help={this.state.errorInput.login}
          >
            {isSuperAdmin ? (
              <CustomInput
                data-qa='adminUsersEditUserModalLoginInput'
                name='login'
                onChange={this.handleChange}
                defaultValue={login}
                value={login}
                maxLength={100}
              />
            ) : (
              <span>{login}</span>
            )}
          </Form.Item>

          <Form.Item
            label={t('email')}
            name='email'
            required
            labelAlign='left'
            validateStatus={this.state.errorInput.email ? 'error' : ''}
            help={this.state.errorInput.email}
          >
            {isSuperAdmin ? (
              <CustomInput
                data-qa='adminUsersEditUserModalEmailInput'
                name='email'
                onChange={this.handleChange}
                defaultValue={email}
                value={email}
                maxLength={100}
              />
            ) : (
              <span>{email}</span>
            )}
          </Form.Item>

          <Form.Item
            label={t('name')}
            hasFeedback
            validateStatus={this.state.errorInput.firstName ? 'error' : ''}
            help={this.state.errorInput.firstName}
            required
            labelAlign='left'
          >
            <CustomInput
              data-qa='adminUsersEditUserModalFirstNameInput'
              name='firstName'
              onChange={this.handleChange}
              value={firstName}
              maxLength={50}
            />
          </Form.Item>

          <Form.Item
            label={t('lastName')}
            hasFeedback
            validateStatus={this.state.errorInput.lastName ? 'error' : ''}
            help={this.state.errorInput.lastName}
            required
            labelAlign='left'
          >
            <CustomInput
              data-qa='adminUsersEditUserModalLastNameInput'
              name='lastName'
              onChange={this.handleChange}
              value={lastName}
              maxLength={50}
            />
          </Form.Item>

          <Form.Item label={t('middleName')} labelAlign='left'>
            <CustomInput
              data-qa='adminUsersEditUserModalMiddleNameInput'
              name='middleName'
              onChange={this.handleChange}
              value={middleName}
              maxLength={50}
            />
          </Form.Item>

          {hasManager && (
            <Form.Item label={t('manager')} labelAlign='left'>
              <Select
                name='managerId'
                dataqa='adminUsersEditUserModalManagerSelect'
                allowClear
                showSearch
                onSearch={searchUsers}
                notFoundPlaceholder={t('placeholder1')}
                onChange={this.handleChange}
                value={this.state.managerName}
                options={options}
              />
            </Form.Item>
          )}
          {hasAdditionalInfo && (
            <>
              <Form.Item label={t('position')} labelAlign='left'>
                <Select
                  name='functionInternalId'
                  dataqa='adminUsersEditUserModalPositionSelect'
                  allowClear
                  showSearch
                  onSearch={searchFunction}
                  placeholder={t('position')}
                  notFoundPlaceholder={t('placeholder2')}
                  onChange={this.handleChange}
                  value={this.state.functionInternalId}
                  options={this.careerOptions}
                />
              </Form.Item>

              <Form.Item label={t('department')} labelAlign='left'>
                <Select
                  dataqa='adminUsersEditUserModalDepartmentSelect'
                  name='departmentInternalId'
                  allowClear
                  showSearch
                  onSearch={searchDepartment}
                  placeholder={t('department')}
                  notFoundPlaceholder={t('placeholder3')}
                  onChange={this.handleChange}
                  value={this.state.departmentInternalId}
                  options={this.departmentOptions}
                />
              </Form.Item>
              <Form.Item label={t('function')} labelAlign='left'>
                <CustomInput
                  data-qa='adminUsersEditUserModalFunctionInput'
                  name='functionDEP'
                  onChange={this.handleChange}
                  value={functionDEP}
                  maxLength={50}
                />
              </Form.Item>

              <Form.Item label={t('company')} labelAlign='left'>
                <CustomInput
                  data-qa='adminUsersEditUserModalCompanyInput'
                  name='company'
                  onChange={this.handleChange}
                  value={company}
                  maxLength={50}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label={t('location')} labelAlign='left'>
            <CustomInput
              data-qa='adminUsersEditUserModalLocationInput'
              name='location'
              onChange={this.handleChange}
              value={location}
              maxLength={255}
            />
          </Form.Item>
          <Form.Item label={t('purpose')} labelAlign='left'>
            <TextArea
              data-qa='adminUsersEditUserModalPurposeTextArea'
              name='purpose'
              onChange={this.handleChange}
              value={purpose}
              maxLength={5000}
            />
          </Form.Item>

          <Form.Item label={t('date')} labelAlign='left'>
            <div className={css.DatePicker}>
              <DatePicker onChange={this.handleChange} name='startOfJob' value={startOfJob} newStyle />
            </div>
          </Form.Item>

          <CustomFieldList student={this.props.student} onChange={this.onFieldsChange} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(usersSelect, users => ({
  currentUser: users.currentUser,
}));

EditUser.propTypes = {
  createProfileCustomFieldValue: PropTypes.func.isRequired,
  updateProfileCustomFieldValue: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  student: PropTypes.object,
  hasAdditionalInfo: PropTypes.bool,
  hasManager: PropTypes.bool,
  changeUser: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  changeUserError: PropTypes.string,
};

const mapActionsToProps = {
  createProfileCustomFieldValue,
  updateProfileCustomFieldValue,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation(['editUserDialog'])(EditUser));
