// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-CurrencyInput-style-module__ql-picker-nKPcwa.admin-src-components-CurrencyInput-style-module__ql-font-Cz2Xfe .admin-src-components-CurrencyInput-style-module__ql-picker-label-mAVyOa[data-value="Montserrat"]:before, .admin-src-components-CurrencyInput-style-module__ql-picker-nKPcwa.admin-src-components-CurrencyInput-style-module__ql-font-Cz2Xfe .admin-src-components-CurrencyInput-style-module__ql-picker-item-xADG29[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-CurrencyInput-style-module__ql-font-Montserrat-olGguS {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-CurrencyInput-style-module__currency-input-ShEm1Z {
  width: 100%;
}

.admin-src-components-CurrencyInput-style-module__currency-input-ShEm1Z div:nth-child(2) > input {
  text-align: right;
  padding-right: 25px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/CurrencyInput/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;AACb;;AAMA;EAHE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.currency-input {\n  width: 100%;\n}\n.currency-input div:nth-child(2) > input {\n  text-align: right;\n  padding-right: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-CurrencyInput-style-module__ql-picker-nKPcwa`,
	"qlPicker": `admin-src-components-CurrencyInput-style-module__ql-picker-nKPcwa`,
	"ql-font": `admin-src-components-CurrencyInput-style-module__ql-font-Cz2Xfe`,
	"qlFont": `admin-src-components-CurrencyInput-style-module__ql-font-Cz2Xfe`,
	"ql-picker-label": `admin-src-components-CurrencyInput-style-module__ql-picker-label-mAVyOa`,
	"qlPickerLabel": `admin-src-components-CurrencyInput-style-module__ql-picker-label-mAVyOa`,
	"ql-picker-item": `admin-src-components-CurrencyInput-style-module__ql-picker-item-xADG29`,
	"qlPickerItem": `admin-src-components-CurrencyInput-style-module__ql-picker-item-xADG29`,
	"ql-font-Montserrat": `admin-src-components-CurrencyInput-style-module__ql-font-Montserrat-olGguS`,
	"qlFontMontserrat": `admin-src-components-CurrencyInput-style-module__ql-font-Montserrat-olGguS`,
	"currency-input": `admin-src-components-CurrencyInput-style-module__currency-input-ShEm1Z`,
	"currencyInput": `admin-src-components-CurrencyInput-style-module__currency-input-ShEm1Z`
};
export default ___CSS_LOADER_EXPORT___;
