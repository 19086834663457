/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360ProjectState } from '@/store/d360/d360_state/actions';

import { Col, Row, Table, Tag } from 'antd';
import { DislikeOutlined, HourglassOutlined, LikeOutlined, SearchOutlined } from '@ant-design/icons';
import { MdAssessment } from 'react-icons/md';

import Search from './Search';

import _ from 'lodash';

import css from '../../css/index.module.scss';

const pageSizeOptions = ['10', '50', '100', '500', '1000'];

export const State = () => {
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation('D360_State');
  const { project } = useSelector(state => state.d360Project);
  const { state, pageTotal, pagePer, loading } = useSelector(state => state.d360State);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [filterColumn, setFilterColumn] = useState(undefined);
  const [filterValue, setFilterValue] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isArray(state)) {
      let data = [];
      state.map((status, index) => {
        if (!_.isNil(status) && _.isObject(status)) {
          if (
            _.has(status, 'id') &&
            _.has(status, 'name') &&
            _.has(status, 'email') &&
            _.has(status, 'position') &&
            _.has(status, 'state')
          ) {
            let employee = '';
            if (!_.isNil(status.position)) {
              employee = (
                <>
                  <div>{status.name}</div>
                  <div style={{ color: 'rgba(70,65,65,0.55)' }}>{status.position}</div>
                </>
              );
            } else {
              employee = <div>{status.name}</div>;
            }

            data.push({
              id: index,
              name: employee,
              email: status.email,
              status: status.state,
            });
          }
        }
      });

      setTableData(data);
    }
  }, [state]);

  const loadState = () => {
    dispatch(D360ProjectState(project.id, pageCurrent, pageSize, sortColumn, sortOrder, filterColumn, filterValue));
  };

  useEffect(() => {
    if (project && project.id) {
      loadState();
    }
  }, [project, pageCurrent, pageSize, sortColumn, sortOrder, filterColumn, filterValue]);

  const onFilterSubmit = e => {
    setFilterColumn('name');
    setFilterValue(e);
  };

  const onFilterReset = () => {
    setFilterValue(null);
    setFilterColumn(null);
  };

  /**
   *
   * @returns {*[]}
   */
  const tableColumns = () => {
    return [
      {
        title: t('name'),
        key: 'name',
        dataIndex: 'name',
        sorter: (a, b) => {
          if (!_.isNil(a) && _.has(a, 'name.props.children') && !_.isNil(b) && _.has(b, 'name.props.children')) {
            return a.name.props.children.toString().localeCompare(b.name.props.children.toString());
          }

          return null;
        },
        sortDirections: ['descend', 'ascend'],
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <Search
              value={filterValue}
              fnClearFilters={clearFilters}
              fnFilterConfirm={confirm}
              onReset={onFilterReset}
              onSubmit={onFilterSubmit}
            />
          );
        },
        render: (name, record) => {
          return <>{record.name}</>;
        },
      },
      {
        title: t('email'),
        key: 'email',
        dataIndex: 'email',
        sorter: (a, b) => a.email.toString().localeCompare(b.email.toString()),
        render: (name, record) => {
          return <a href={`mailto:${record.email}?subject=${project.name}`}>{record.email}</a>;
        },
      },
      {
        title: t('status'),
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.status.toString().localeCompare(b.status.toString()),
        render: (name, record) => {
          switch (name) {
            case 'not_started':
              return (
                <Tag color='#d84352'>
                  <DislikeOutlined />
                  &nbsp;{t(record.status)}
                </Tag>
              );
            case 'has_started':
              return (
                <Tag color='#9479da'>
                  <HourglassOutlined />
                  &nbsp;{t(record.status)}
                </Tag>
              );
            case 'has_finished':
              return (
                <Tag color='#34ba9b'>
                  <LikeOutlined />
                  &nbsp;{t(record.status)}
                </Tag>
              );
            default:
              return <Tag>{record.status}</Tag>;
          }
          // return name === t('finished') ? (
          //   <Tag color={'green'}>{record.status}</Tag>
          // ) : (
          //   <Tag color={'volcano'}>{record.status}</Tag>
          // );
        },
      },
    ];
  };

  const onTableChange = pagination => {
    if (!_.isNil(pagination) && _.isObject(pagination)) {
      if (_.has(pagination, 'pageSize') && _.isNumber(pagination.pageSize)) {
        setPageSize(pagination.pageSize);
      }
      if (_.has(pagination, 'current') && _.isNumber(pagination.current)) {
        setPageCurrent(pagination.current);
      }
    }
  };

  return (
    <div>
      <Row className={css.Header}>
        <Col span={24}>
          <h2 className={css.HeaderTitle}>
            <MdAssessment />
            &nbsp; {t('title')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={tableColumns()}
            dataSource={tableData}
            bordered={true}
            loading={loading}
            rowKey={record => record.id}
            size='middle'
            onChange={onTableChange}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              hideOnSinglePage: false,
              total: pageTotal,
              current: pageCurrent,
              pageSize: pagePer,
              pageSizeOptions: pageSizeOptions,
              responsive: true,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default State;
