/* eslint-disable */
import React, { Component } from 'react';

import { Avatar } from 'antd';
import Rate from '@/components/Rate';

import PortraitPlaceholder from '../Header/PortraitPlaceholder.png';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

export default class Comment extends Component {
  static propTypes = {
    getFile: PropTypes.func,
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: '',
    };
  }

  componentDidMount() {
    this.props.getFile(this.props.item.user.localPhotoUuid, res => {
      this.setState({ imageSrc: URL.createObjectURL(res) });
    });
  }

  render() {
    const { item } = this.props;
    return (
      <div className={css.Comment}>
        <div className={css.Comment__item}>
          <Avatar src={this.state.imageSrc || PortraitPlaceholder} className={css.Comment__item__avatar} />
          <div className={css.Comment__item__text}>
            <div className={css.Comment__title}>
              {item.user.lastName} {item.user.firstName}
            </div>
            <div className={css.Comment__databox}>
              <div className={css.Comment__data}>{dayjs(item.createdDate).format('DD MMMM YYYY')}</div>
              {item.rate !== null ? (
                <div>
                  <Rate disabled count={5} value={item.rate} />
                </div>
              ) : null}
            </div>
            <div className={css.Comment__text}>
              {item.text}
              {item.modified ? <span className={css.Comment__modified}>(ред.)</span> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
