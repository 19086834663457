/* eslint-disable */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Menu } from 'antd';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

import {
  ACCESS_TO_ADMIN_FROM_USERS,
  INCOME_CREATE,
  TRAJECTORY_USERS_MANAGE,
  USER_BLOCK,
  USER_CHANGE_PASSWORD,
  USER_SIGN_IN_ANOTHER_USER,
  USER_UPDATE,
} from '@/constants/permissions';

const Status = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};

export const More = ({
  toggleUserTrajectory,
  blockUser,
  student,
  showITOD,
  domainCompany,
  showCoin,
  changeManagerModalVisibility,
  changePasswordModalVisibility,
  getStudents,
  loginRedirect,
  currentUser,
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('more');

  const trajectoryHandler = () => {
    toggleUserTrajectory(student);
    setVisible(false);
  };

  const userLockedHandler = () => {
    const { id, status } = student;
    if (status === Status.ACTIVE) {
      blockUser(id, status === Status.ACTIVE);
    } else {
      blockUser(id, status !== Status.DELETED);
    }
    getStudents();
    setVisible(false);
  };

  const userManagerHandler = () => {
    changeManagerModalVisibility();
    setVisible(false);
  };

  const showCoinHandler = () => {
    showCoin(student);
    setVisible(false);
  };

  const userChangePasswordHandler = () => {
    changePasswordModalVisibility();
    setVisible(false);
  };

  const logInAsUser = () => {
    window.open(`https://${domainCompany.domain}/collegues/${student.id}/info`);
  };

  const logInAsAdmin = () => {
    loginRedirect(student.login, res => {
      localStorage.setItem('accessToken', res.accessToken);
      localStorage.setItem('authorizationToken', res.accessToken);
      localStorage.setItem('refreshToken', res.refreshToken);
      window.location.reload();
    });
  };

  const onClickHandler = useCallback(() => setVisible(!visible), [visible]);

  const menuItems = [
    ...(currentUser.authorities.includes(TRAJECTORY_USERS_MANAGE) && showITOD
      ? [
          {
            key: '1',
            label: student.trajectoryEnable ? t('disable') : t('enable'),
            onClick: trajectoryHandler,
            dataqa: 'adminUsersDisableEnableIpr',
          },
        ]
      : []),
    ...(currentUser.authorities.includes(USER_BLOCK)
      ? [
          {
            key: '2',
            label: student.status !== Status.ACTIVE ? t('unblock') : t('block'),
            onClick: userLockedHandler,
            dataqa: 'adminUsersBlockUnlock',
          },
        ]
      : []),
    ...(domainCompany && domainCompany.hasCoins && currentUser.authorities.includes(INCOME_CREATE)
      ? [
          {
            key: '3',
            label: t('balance'),
            onClick: showCoinHandler,
            dataqa: 'adminUsersChangeUserBalance',
          },
        ]
      : []),
    ...(currentUser.authorities.includes(USER_UPDATE)
      ? [
          {
            key: '4',
            label: t('edit'),
            onClick: userManagerHandler,
            dataqa: 'adminUsersEditUserInfo',
          },
        ]
      : []),
    ...(currentUser.authorities.includes(USER_CHANGE_PASSWORD)
      ? [
          {
            key: '5',
            label: t('setNewPassword'),
            onClick: userChangePasswordHandler,
            dataqa: 'adminUsersEditUserChangePassword',
          },
        ]
      : []),
    ...(currentUser.authorities.includes(USER_SIGN_IN_ANOTHER_USER)
      ? [
          {
            key: '6',
            label: t('logInAsUser'),
            onClick: logInAsUser,
            dataqa: 'adminUsersLoginToUserPart',
          },
        ]
      : []),
    ...(currentUser.authorities.includes(USER_SIGN_IN_ANOTHER_USER) &&
    student.authorities.includes(ACCESS_TO_ADMIN_FROM_USERS)
      ? [
          {
            key: '7',
            label: t('logInAsAdmin'),
            onClick: logInAsAdmin,
            dataqa: 'adminUsersLoginToAdminPart',
          },
        ]
      : []),
  ];

  return (
    <Dropdown menu={{ items: menuItems }} placement='bottomLeft' trigger={['click']} onOpenChange={onClickHandler}>
      <Button className={css.popoverButton} type='link'>
        {t('respond')}
      </Button>
    </Dropdown>
  );
};

More.propTypes = {
  toggleUserTrajectory: PropTypes.func,
  blockUser: PropTypes.func,
  changeManagerModalVisibility: PropTypes.func,
  showCoin: PropTypes.func,
  changePasswordModalVisibility: PropTypes.func,
  getStudents: PropTypes.func,
  loginRedirect: PropTypes.func,
  showITOD: PropTypes.bool,
  showBlockUser: PropTypes.bool,
};
