import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import _ from 'lodash';

const RU = JSON.parse(JSON.stringify(await import('@/locales/ru.json')));
const EN = JSON.parse(JSON.stringify(await import('@/locales/en.json')));

const sharedRU = JSON.parse(JSON.stringify(await import('@shared/locales/ru.json')));
const sharedEN = JSON.parse(JSON.stringify(await import('@shared/locales/en.json')));

// the translations
let resources = {};

if (import.meta.env.PUBLIC_PARTNER_MODE !== 'cloud') {
  const projectRU = JSON.parse(JSON.stringify(await import('@project/locales/ru.json')));
  const projectEN = JSON.parse(JSON.stringify(await import('@project/locales/en.json')));

  resources = {
    ru_RU: _.merge(RU, sharedRU, projectRU),
    en_US: _.merge(EN, sharedEN, projectEN),
  };
} else {
  resources = {
    ru_RU: _.merge(RU, sharedRU),
    en_US: _.merge(EN, sharedEN),
  };
}

const initObject = {
  resources,
  debug: false,
  fallbackLng: 'ru_RU',
  preload: true,
  interpolation: {
    escapeValue: false,
  },
};

// const browserLanguage = navigator.language;
// const currentLanguage = localStorage.getItem('currentLanguage');

// if (currentLanguage) {
//   initObject.fallbackLng = currentLanguage;
// } else {
//   initObject.fallbackLng = browserLanguage?.includes('en') ? 'en_US' : 'ru_RU';
// }

i18n.use(initReactI18next).init(initObject);

export default i18n;
