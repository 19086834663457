/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getFile } from '@/store/files/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Avatar, Button, Layout } from 'antd';
import { InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';

import PortraitPlaceholder from './PortraitPlaceholder.png';

import useIsMobile from '@shared/hooks/useIsMobile';

import { defaultRoute } from '@/router/routes';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './Header.module.scss';

import { ROOT_CHANGE_PASSWORD } from '@/constants/permissions';

const { Header } = Layout;

export const AppHeader = props => {
  AppHeader.propTypes = {
    currentUser: PropTypes.object,
    onLogout: PropTypes.func,
  };

  const [isMobile] = useIsMobile(768);

  const { t } = props;
  const [avatar, setAvatar] = useState(PortraitPlaceholder);
  const headerLink = get(props, 'currentUser.haveEmployees')
    ? '/profile-setting'
    : get(props, 'currentUser.authorities', []).includes(ROOT_CHANGE_PASSWORD)
      ? '/profile-settings'
      : defaultRoute;

  const domain = get(props, 'currentUser.domainCompany.domain');
  const help = get(props, 'currentUser.domainCompany.helpLink');
  const telegramBotLink = get(props, 'currentUser.domainCompany.telegramBotLink');

  useEffect(() => {
    props.currentUser &&
      props.currentUser?.localPhotoUuid &&
      props.getFile(props.currentUser.localPhotoUuid, res => setAvatar(URL.createObjectURL(res)));
  }, [props.currentUser.localPhotoUuid]);

  const goToUserPage = redirect => {
    let DOMAIN = `https://${domain}`;

    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      DOMAIN = 'http://localhost:3000';
    }
    const URL = `${DOMAIN}/login-through?accessToken=${localStorage.getItem('authorizationToken')}&redirect=${redirect}`;
    window.open(URL, '_blank');
  };

  return (
    <div className={css['App-header-affix']}>
      <Header className={css['App-header']}>
        <div className={css['App-header-content']}>
          {telegramBotLink && (
            <a target='_blank' rel='noopener noreferrer' className={css['App-header-link']} href={telegramBotLink}>
              {t('telegram')}
            </a>
          )}
          {help && (
            <>
              {isMobile ? (
                <Link to={help} target='_blank'>
                  <InfoCircleOutlined className={css['App-header-content-info']} />
                </Link>
              ) : (
                <Button type='primary' target='_blank' href={help}>
                  {t('help')}
                </Button>
              )}
            </>
          )}
          <a
            target='_blank'
            rel='noopener noreferrer'
            className={classNames(css['App-header-link'], css['App-header-link__left'])}
            onClick={() => goToUserPage('/')}
          >
            {isMobile ? t('userMobile') : t('user')}
          </a>
          <Link to={headerLink} className={css['App-header-link']} data-qa='userBlock'>
            <Avatar className={css['App-header-avatar']} src={avatar} />
            {get(props, 'currentUser.login')}
          </Link>
          <Button onClick={props.onLogout} data-qa='logOutBtn' icon={<LogoutOutlined />} shape='circle' />
        </div>
      </Header>
    </div>
  );
};

const mapStateToProps = createSelector([usersSelect], users => ({
  currentUser: users.currentUser || '',
}));

const mapActionsToProps = {
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('header')(AppHeader));
