import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getLimitSettings, postMentorsFilters, updateMentorsData } from '@/store/mentors/actions';

import { selectCompetence } from '@/store/competence/selectors';
import { selectMentors } from '@/store/mentors/selectors';

import { Button, Input, message, Modal, Select } from 'antd';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

import {
  ACCEPT,
  DESC_ACTIVATED,
  DESC_APPLY,
  EDIT_MENTOR,
  INACTIVE,
  REJECT,
  REJECTED_REASON,
  STATUS_ACTIVE,
} from '../constance';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../../Mentors.module.scss';

const { TextArea } = Input;
const { Option } = Select;

const ModalActions = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();

  const { mentorsFilters, mentorsCurrentPage, limit } = useSelector(selectMentors);

  const { competence } = useSelector(selectCompetence); // default size: 1000
  const [comment, setComment] = useState('');
  const [data, setData] = useState({
    mentorId: props.isAction?.obj?.mentorId,
    competences: [],
    personalInfo: props.isAction?.obj?.personalInfo || '',
  });

  useEffect(() => {
    dispatch(getLimitSettings());
  }, []);

  useEffect(() => {
    const competences = props.isAction?.obj?.competence?.map(i => i.id);

    setData(e => ({ ...e, competences }));
  }, [props.isAction.obj.competence]);

  const updateMentor = () => {
    dispatch(
      updateMentorsData(data, () => {
        props.onClose();
        dispatch(postMentorsFilters(mentorsFilters.filters ? mentorsFilters.filters : {}, mentorsCurrentPage));
      })
    );
  };

  const handleSelect = competences => {
    if (competences.length <= limit?.maxMentorCompetences) {
      setData({ ...data, competences });
    } else {
      message.error(t('errorComp'));
      return;
    }
  };

  const footerModal =
    props.isAction.action === EDIT_MENTOR ? (
      <>
        <Button onClick={props.onClose} size='large'>
          {t('close')}
        </Button>
        <Button onClick={updateMentor} size='large' type='primary'>
          {t('update')}
        </Button>
      </>
    ) : (
      <div className={css['ModalActions-setReject-footer']}>
        {props.isError && (
          <div className={css['ModalActions-setReject-footer-error']}>
            <HiOutlineExclamationCircle className={css['ModalActions-setReject-footer-error-icon']} />
            <span className={css['ModalActions-setReject-footer-error-desc']}>{t('errorDesc')}</span>
          </div>
        )}
        <Button onClick={props.onClose} size='large'>
          {t('no')}
        </Button>
        <Button
          onClick={() => props.onChangeStatus(comment)}
          size='large'
          type='primary'
          className={css['ModalActions-setReject-footer-yes']}
        >
          {t('yes')}
        </Button>
      </div>
    );

  const competenceOptions =
    competence &&
    competence.map(c => {
      return (
        <Option key={c.id} value={c.id}>
          {c.name}
        </Option>
      );
    });

  const footerModalRejectReason = (
    <Button onClick={props.onClose} size='large' key='footerModalRejectReason'>
      {t('close')}
    </Button>
  );

  const modalTitle = <div>{t('modalRejectReason')}</div>;

  const contentRejectReason = <div>{props.isAction.obj.rejectReason || t('emptyRejectReason')}</div>;

  const editMentor = (
    <div className={css['ModalActions-edit']}>
      <div className={css['ModalActions-edit-header']}>
        <h2>{t('modalEditMentor')}</h2>
      </div>
      <div className={css['ModalActions-edit-choose']}>
        {t('selectCompetencies', { total: data.competences.length })}
      </div>
      <Select
        className={css['ModalActions-edit-select']}
        mode='multiple'
        name='competences'
        size='large'
        allowClear
        showSearch
        placeholder={t('competencies', { total: limit?.maxMentorCompetences })}
        onChange={handleSelect}
        value={data.competences}
      >
        {competenceOptions}
      </Select>

      <TextArea
        name='personalInfo'
        maxLength={1500}
        rows={10}
        placeholder={t('aboutText')}
        value={data.personalInfo}
        onChange={e => setData({ ...data, personalInfo: e?.target?.value })}
      />
    </div>
  );

  const reject = (
    <div className={css['ModalActions-reject']}>
      <div className={css['ModalActions-reject-desc']}>{t('rejectMentor')}</div>
      <TextArea
        rows={6}
        placeholder={t('placeHolderReject')}
        maxLength={255}
        allowClear
        onChange={e => setComment(e?.target?.value)}
        className={classNames([css['ModalActions-setReject-area']], {
          [css['ModalActions-setReject-area-error']]: props.isError,
        })}
      />
    </div>
  );

  return (
    <Modal
      title={props.isAction.action === REJECTED_REASON ? modalTitle : null}
      open={props.isAction.open}
      onCancel={props.onClose}
      footer={props.isAction.action === REJECTED_REASON ? footerModalRejectReason : footerModal}
      width={props.isAction.action === EDIT_MENTOR ? 600 : 500}
    >
      {props.isAction.action === ACCEPT && props.isAction.descAction === DESC_APPLY && t('acceptMentor')}

      {props.isAction.action === INACTIVE && t('inactivetMentor')}

      {props.isAction.action === STATUS_ACTIVE && props.isAction.descAction === DESC_ACTIVATED && t('activeMentor')}

      {props.isAction.action === REJECT && reject}

      {props.isAction.action === REJECTED_REASON && contentRejectReason}

      {props.isAction.action === EDIT_MENTOR && editMentor}
    </Modal>
  );
};

ModalActions.propTypes = {
  onChangeStatus: PropTypes.func,
  onClose: PropTypes.func,
  isError: PropTypes.bool,
  isAction: PropTypes.object,
};

export default ModalActions;
