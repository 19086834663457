/* eslint-disable */
import {
  ACTIVE_STATUS_FAIL,
  ACTIVE_STATUS_START,
  ACTIVE_STATUS_SUCCESS,
  ADD_USER_EVENT_FAIL,
  ADD_USER_EVENT_START,
  ADD_USER_EVENT_SUCCESS,
  CHANGE_COURSES_FILTER,
  CHECK_DELETE_EVENT_FAIL,
  CHECK_DELETE_EVENT_START,
  CHECK_DELETE_EVENT_SUCCESS,
  CHECK_DELETE_USER_EVENT_FAIL,
  CHECK_DELETE_USER_EVENT_START,
  CHECK_DELETE_USER_EVENT_SUCCESS,
  CLEAR_COURSES_FILTER,
  CLEAR_COURSES_REQUEST,
  COURSE_REQUEST_FINISH_FAIL,
  COURSE_REQUEST_FINISH_START,
  COURSE_REQUEST_FINISH_SUCCESS,
  CREATE_EVENT_FAIL,
  CREATE_EVENT_START,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  DELETE_EVENT_START,
  DELETE_EVENT_SUCCESS,
  DELETE_MASS_FAIL,
  DELETE_MASS_START,
  DELETE_MASS_SUCCESS,
  DELETE_REQUEST_COURSES_FAIL,
  DELETE_REQUEST_COURSES_START,
  DELETE_REQUEST_COURSES_SUCCESS,
  DELETE_USER_EVENT_FAIL,
  DELETE_USER_EVENT_START,
  DELETE_USER_EVENT_SUCCESS,
  GET_COURSES_ADMIN_MANAGER_COST_FAIL,
  GET_COURSES_ADMIN_MANAGER_COST_START,
  GET_COURSES_ADMIN_MANAGER_COST_SUCCESS,
  GET_COURSES_COST_FAIL,
  GET_COURSES_COST_MANAGER_SUCCESS,
  GET_COURSES_COST_START,
  GET_COURSES_COST_SUCCESS,
  GET_COURSES_MANAGER_COST_FAIL,
  GET_COURSES_MANAGER_COST_START,
  GET_COURSES_REQUEST_COMMENT_FAIL,
  GET_COURSES_REQUEST_COMMENT_START,
  GET_COURSES_REQUEST_COMMENT_SUCCESS,
  GET_COURSES_REQUEST_EXPORT_FAIL,
  GET_COURSES_REQUEST_EXPORT_START,
  GET_COURSES_REQUEST_EXPORT_SUCCESS,
  GET_COURSES_REQUEST_FAIL,
  GET_COURSES_REQUEST_START,
  GET_COURSES_REQUEST_SUCCESS,
  GET_COURSES_REQUEST_TOPIC_FAIL,
  GET_COURSES_REQUEST_TOPIC_START,
  GET_COURSES_REQUEST_TOPIC_SUCCESS,
  GET_COURSES_REQUESTS_FAIL,
  GET_COURSES_REQUESTS_START,
  GET_COURSES_REQUESTS_SUCCESS,
  GET_EVENT_TOPIC_FAIL,
  GET_EVENT_TOPIC_START,
  GET_EVENT_TOPIC_SUCCESS,
  GET_EVENT_USERS_FAIL,
  GET_EVENT_USERS_START,
  GET_EVENT_USERS_SUCCESS,
  GET_EVENTS_IN_GROUPS_COURSE_FAIL,
  GET_EVENTS_IN_GROUPS_COURSE_START,
  GET_EVENTS_IN_GROUPS_COURSE_SUCCESS,
  GET_MASS_SELECT_USERS_FAIL,
  GET_MASS_SELECT_USERS_START,
  GET_MASS_SELECT_USERS_SUCCESS,
  GET_MASS_SELECTION_COUNT_FAIL,
  GET_MASS_SELECTION_COUNT_START,
  GET_MASS_SELECTION_COUNT_SUCCESS,
  GET_REGISTRATION_STARTDATE_FAIL,
  GET_REGISTRATION_STARTDATE_START,
  GET_REGISTRATION_STARTDATE_SUCCESS,
  GET_YEAR_COST_FAIL,
  GET_YEAR_COST_START,
  GET_YEAR_COST_SUCCESS,
  GET_YEAR_FAIL,
  GET_YEAR_START,
  GET_YEAR_SUCCESS,
  POST_CHANGE_COMPLITED_FAIL,
  POST_CHANGE_COMPLITED_START,
  POST_CHANGE_COMPLITED_SUCCESS,
  POST_COURSES_REQUEST_COMMENT_FAIL,
  POST_COURSES_REQUEST_COMMENT_START,
  POST_COURSES_REQUEST_COMMENT_SUCCESS,
  PUT_MASS_EVENT_FAIL,
  PUT_MASS_EVENT_START,
  PUT_MASS_EVENT_SUCCESS,
  PUT_REJECT_TEST_FAIL,
  PUT_REJECT_TEST_START,
  PUT_REJECT_TEST_SUCCESS,
  PUT_RESCHEDULE_ALL_FAIL,
  PUT_RESCHEDULE_ALL_START,
  PUT_RESCHEDULE_ALL_SUCCESS,
  PUT_STATUS_ALL_FAIL,
  PUT_STATUS_ALL_START,
  PUT_STATUS_ALL_SUCCESS,
  SEND_MAIL_FAIL,
  SEND_MAIL_START,
  SEND_MAIL_SUCCESS,
  SET_REGISTRATION_STARTDATE_FAIL,
  SET_REGISTRATION_STARTDATE_START,
  SET_REGISTRATION_STARTDATE_SUCCESS,
  UPDATE_COURSES_REQUEST_FAIL,
  UPDATE_COURSES_REQUEST_START,
  UPDATE_COURSES_REQUEST_SUCCESS,
  UPDATE_EVENT_FAIL,
  UPDATE_EVENT_START,
  UPDATE_EVENT_SUCCESS,
  UPDATE_STUDY_PLAN_DATE_FAIL,
  UPDATE_STUDY_PLAN_DATE_START,
  UPDATE_STUDY_PLAN_DATE_SUCCESS,
} from './actions';

const initialState = {
  isLoading: false,
  isLoadingUsers: false,
  coursesIsLoading: false,
  error: false,
  courses: [],
  coursesTotal: null,
  coursesPageSize: null,
  coursesPageNumber: null,
  coursesTopic: null,
  openDate: null,
  coursesRequest: null,
  coursesRequestComment: null,
  coursesRequestCommentTotal: null,
  coursesRequestCommentPageSize: null,
  coursesRequestCommentNumber: null,
  coursesTopicTotal: null,
  coursesTopicPageSize: null,
  coursesTopicPageNumber: null,
  eventsGroups: null,
  event: null,
  eventList: null,
  delete: null,
  coursesStatus: null,
  updateReqUser: false,
  coursesFilter: null,
  eventUsers: null,
  yearRequest: null,
  yearCost: null,
  countMassSelection: null,
  isLoadingDeleteMass: false,
  courseCost: null,
  courseManagerCost: null,
  courseManagerAdminCost: null,
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    case DELETE_REQUEST_COURSES_START:
    case GET_EVENTS_IN_GROUPS_COURSE_START:
    case CHECK_DELETE_EVENT_START:
    case GET_REGISTRATION_STARTDATE_START:
    case SET_REGISTRATION_STARTDATE_START:
    case GET_EVENT_USERS_START:
    case GET_YEAR_START:
    case COURSE_REQUEST_FINISH_START:
    case PUT_REJECT_TEST_START:
    case PUT_RESCHEDULE_ALL_START:
    case PUT_STATUS_ALL_START:
    case PUT_MASS_EVENT_START:
    case GET_MASS_SELECTION_COUNT_START:
    case GET_MASS_SELECT_USERS_START:
    case POST_CHANGE_COMPLITED_START:
      return { ...state, isLoading: true, error: false };

    case GET_COURSES_REQUEST_START:
      return { ...state, isLoadingUsers: true, error: false };

    case GET_COURSES_REQUESTS_START:
      return { ...state, isLoading: true, error: false, coursesIsLoading: true };

    case DELETE_MASS_START:
      return { ...state, isLoadingDeleteMass: true, error: false };

    case GET_YEAR_SUCCESS:
      return {
        ...state,
        yearRequest: response,
        isLoading: false,
        error: false,
      };

    case SET_REGISTRATION_STARTDATE_SUCCESS:
    case GET_REGISTRATION_STARTDATE_SUCCESS:
      return { ...state, openDate: response.openDate, isLoading: false, error: false };

    case GET_EVENT_USERS_SUCCESS:
      return { ...state, eventUsers: response, isLoading: false, error: false };

    case UPDATE_STUDY_PLAN_DATE_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_STUDY_PLAN_DATE_SUCCESS:
      const courseWithNewData = state.courses.map(itemOld => {
        const newData = response.find(res => res.id === itemOld.plan.id);
        if (newData) {
          return {
            ...itemOld,
            plan: {
              ...itemOld.plan,
              withoutSchedule: action.withoutSchedule,
              startDate: newData.startDate,
              commentCount: itemOld.commentCount > 0 ? itemOld.commentCount + 1 : 1,
            },
          };
        }
        return itemOld;
      });
      return { ...state, courses: courseWithNewData, isLoading: false };

    case UPDATE_STUDY_PLAN_DATE_FAIL:
      return {
        isLoading: false,
        error: true,
      };

    case GET_COURSES_REQUESTS_SUCCESS:
      const usersList = !action.party
        ? response
        : response.content.map(user => ({
            id: user.id,
            plan: {
              ...user,
            },
          }));
      return {
        ...state,
        coursesRequest: usersList,
        coursesIsLoading: false,
        isLoading: false,
        coursesTopicTotal: Number(headers.get('X-Total-Elements')) || response.totalElements || 0,
        coursesTopicPageSize: Number(headers.get('X-Page-Size')) || response.size || 0,
        coursesTopicPageNumber: Number(headers.get('X-Page-Number')) || response.number || 0,
        error: false,
      };

    case GET_COURSES_REQUEST_SUCCESS:
      return {
        ...state,
        courses: response,
        coursesTotal: Number(headers.get('X-Total-Elements')) || 0,
        coursesPageSize: Number(headers.get('X-Page-Size')) || 0,
        coursesPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoadingUsers: false,
        error: false,
      };

    case DELETE_REQUEST_COURSES_SUCCESS:
    case COURSE_REQUEST_FINISH_SUCCESS:
    case CHECK_DELETE_EVENT_SUCCESS:
    case PUT_REJECT_TEST_SUCCESS:
    case PUT_RESCHEDULE_ALL_SUCCESS:
    case PUT_STATUS_ALL_SUCCESS:
    case PUT_MASS_EVENT_SUCCESS:
    case GET_MASS_SELECT_USERS_SUCCESS:
    case POST_CHANGE_COMPLITED_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case DELETE_MASS_SUCCESS:
      return { ...state, isLoadingDeleteMass: false, error: false };

    case DELETE_REQUEST_COURSES_FAIL:
    case GET_EVENTS_IN_GROUPS_COURSE_FAIL:
    case CHECK_DELETE_EVENT_FAIL:
    case GET_REGISTRATION_STARTDATE_FAIL:
    case SET_REGISTRATION_STARTDATE_FAIL:
    case GET_EVENT_USERS_FAIL:
    case GET_COURSES_REQUESTS_FAIL:
    case GET_YEAR_FAIL:
    case COURSE_REQUEST_FINISH_FAIL:
    case PUT_RESCHEDULE_ALL_FAIL:
    case PUT_STATUS_ALL_FAIL:
    case PUT_MASS_EVENT_FAIL:
    case GET_MASS_SELECTION_COUNT_FAIL:
    case GET_MASS_SELECT_USERS_FAIL:
    case POST_CHANGE_COMPLITED_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_COURSES_REQUEST_FAIL:
      return { ...state, isLoadingUsers: false, error: true };

    case DELETE_MASS_FAIL:
      return { ...state, isLoadingDeleteMass: false, error: true };

    case GET_COURSES_REQUEST_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case GET_COURSES_REQUEST_TOPIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        coursesTopic: response,
        coursesTopicTotal: Number(headers.get('X-Total-Elements')) || 0,
        coursesTopicPageSize: Number(headers.get('X-Page-Size')) || 0,
        coursesTopicPageNumber: Number(headers.get('X-Page-Number')) || 0,
      };

    case GET_COURSES_REQUEST_TOPIC_FAIL:
      return { ...state, isLoading: false, error: true };

    case CREATE_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case CREATE_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: false, event: response };

    case CREATE_EVENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_EVENT_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case GET_EVENT_TOPIC_SUCCESS:
      return { ...state, isLoading: false, error: false, eventList: response };

    case GET_MASS_SELECTION_COUNT_SUCCESS:
      return { ...state, isLoading: false, countMassSelection: response };

    case GET_EVENT_TOPIC_FAIL:
      return { ...state, isLoading: false, error: true };

    case UPDATE_COURSES_REQUEST_START:
      return { ...state, isLoading: true, updateReqUser: false, error: false };

    case UPDATE_COURSES_REQUEST_SUCCESS:
      return { ...state, isLoading: false, updateReqUser: true, error: false };
    case UPDATE_COURSES_REQUEST_FAIL:
      return { ...state, isLoading: false, updateReqUser: false, error: true };

    case ADD_USER_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case GET_EVENTS_IN_GROUPS_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eventsGroups: response,
        error: false,
      };

    case ADD_USER_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case ADD_USER_EVENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case DELETE_USER_EVENT_START:
    case CHECK_DELETE_USER_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case DELETE_USER_EVENT_SUCCESS:
      return { ...state, isLoading: false, delete: response, error: false };

    case DELETE_USER_EVENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case CHECK_DELETE_USER_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case CHECK_DELETE_USER_EVENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case DELETE_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case DELETE_EVENT_SUCCESS:
      return { ...state, isLoading: false, delete: response, error: false };

    case DELETE_EVENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case UPDATE_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_EVENT_SUCCESS:
      return { ...state, isLoading: false, event: response, error: false };

    case UPDATE_EVENT_FAIL:
    case SEND_MAIL_FAIL:
      return { ...state, isLoading: false, error: true };

    case SEND_MAIL_START:
      return { ...state, isLoading: true, error: false };

    case SEND_MAIL_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case ACTIVE_STATUS_START:
      return { ...state, isLoading: true, error: false };

    case ACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        coursesStatus: response.status,
      };

    case ACTIVE_STATUS_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_COURSES_REQUEST_EXPORT_START:
      return { ...state, isLoading: true, error: false };

    case GET_COURSES_REQUEST_EXPORT_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case GET_COURSES_REQUEST_EXPORT_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_YEAR_COST_START:
      return { ...state, isLoading: true, error: false };

    case GET_YEAR_COST_SUCCESS:
      return { ...state, yearCost: response, isLoading: false, error: false };

    case GET_YEAR_COST_FAIL:
      return { ...state, isLoading: false, error: true };

    case CHANGE_COURSES_FILTER:
      const coursesFilter = { ...state.coursesFilter, [info.name]: info.value };
      return { ...state, coursesFilter };

    case CLEAR_COURSES_FILTER:
      return { ...state, coursesFilter: initialState.coursesFilter };

    case CLEAR_COURSES_REQUEST:
      return {
        ...state,
        coursesRequest: [],
        coursesRequestComment: null,
        coursesRequestCommentTotal: null,
        coursesRequestCommentPageSize: null,
        coursesRequestCommentNumber: null,
        coursesTotal: null,
        coursesPageSize: null,
        coursesPageNumber: null,
        isLoading: false,
        coursesIsLoading: true,
      };

    case POST_COURSES_REQUEST_COMMENT_START:
      return { ...state, isLoading: true, error: false };

    case POST_COURSES_REQUEST_COMMENT_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case POST_COURSES_REQUEST_COMMENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_COURSES_REQUEST_COMMENT_START:
      return { ...state, isLoading: true, error: false };

    case GET_COURSES_REQUEST_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        coursesRequestComment: response,
        coursesRequestCommentTotal: Number(headers.get('X-Total-Elements')) || 0,
        coursesRequestCommentPageSize: Number(headers.get('X-Page-Size')) || 0,
        coursesRequestCommentNumber: Number(headers.get('X-Page-Number')) || 0,
      };
    case GET_COURSES_REQUEST_COMMENT_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_COURSES_COST_START:
    case GET_COURSES_MANAGER_COST_START:
    case GET_COURSES_ADMIN_MANAGER_COST_START:
      return { ...state, isLoading: true };

    case GET_COURSES_COST_SUCCESS:
      return { ...state, isLoading: false, courseCost: response?.cost };

    case GET_COURSES_COST_FAIL:
      return { ...state, isLoading: false, courseCost: 0 };

    case GET_COURSES_COST_MANAGER_SUCCESS:
      return { ...state, isLoading: false, courseManagerCost: response?.approved };

    case GET_COURSES_MANAGER_COST_FAIL:
      return { ...state, isLoading: false, courseManagerCost: 0 };

    case GET_COURSES_ADMIN_MANAGER_COST_SUCCESS:
      return { ...state, isLoading: false, courseManagerAdminCost: response?.cost };

    case GET_COURSES_ADMIN_MANAGER_COST_FAIL:
      return { ...state, isLoading: false, courseManagerAdminCost: 0 };

    default:
      return state;
  }
};
