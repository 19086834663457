import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getBannersTopBody, showBannerTopBody } from '@/store/banner_top_body/actions';

import { selectBannersTopBody } from '@/store/banner_top_body/selectors';

import { Button, Image, Input, Table, Tooltip } from 'antd';
import { Card, Content } from '@/components/Layout';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';

import BannerModal from './BannerModal';
import { CREATE, DELETE, EDIT } from './constance';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import _ from 'lodash';

import css from './Banners.module.scss';

import noCoverImg from '@/assets/images/company_logo_holder.svg';

const { Search } = Input;

const Banners = () => {
  const { t } = useTranslation('bannersTab');
  const dispatch = useDispatch();

  const { bannersTopBody, isLoading } = useSelector(selectBannersTopBody);

  const [isModal, setIsModal] = useState({
    visible: false,
    obj: {},
    action: '',
  });

  useEffect(() => {
    dispatch(getBannersTopBody());
  }, []);

  const showModal = (obj, action) => setIsModal({ visible: true, obj, action });

  const changeVisibleBanner = obj => {
    dispatch(showBannerTopBody(obj.id, !obj?.showBanner, { id: obj.id, showBanner: !obj?.showBanner }));
  };

  const handleGetBanners = event => {
    if (event) {
      let bannerName = event.target.value.trim();
      dispatch(getBannersTopBody(bannerName));
    }
  };

  const handleSearchChangeDebounced = useCallback(_.debounce(handleGetBanners, 1000), []);

  const renderAction = obj => {
    return (
      <div className={css['Banners-main-actions']}>
        <Tooltip title={t('editButton')} placement='left'>
          <FormOutlined
            className={css['Banners-main-actions-edit']}
            size='small'
            type='primary'
            data-qa='updateBannerBtn'
            onClick={() => showModal(obj, EDIT)}
          />
        </Tooltip>

        <Tooltip title={t('visibleButton')} placement='top'>
          <Button
            icon={obj?.showBanner ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            size='small'
            type='primary'
            data-qa='visibleBannerBtn'
            className={css['Banners-main-actions-visible']}
            onClick={() => changeVisibleBanner(obj)}
          />
        </Tooltip>

        <Tooltip title={t('deleteButton')} placement='right'>
          <DeleteOutlined
            size='small'
            data-qa='deleteBannerBtn'
            className={css['Banners-main-actions-delete']}
            onClick={() => showModal(obj, DELETE)}
          />
        </Tooltip>
      </div>
    );
  };

  const RennderUuid = ({ uuid }) => {
    const [imgSrc] = useLocalFileByUUID(uuid, { defaultImg: noCoverImg });

    return (
      <Image
        src={imgSrc}
        width={360}
        height={50}
        // preview={{
        //   mask: 'плейсхолдер',
        // }}
      />
    );
  };

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('imgBanner'),
      dataIndex: 'uuid',
      key: '1',
      width: '30%',
      render: uuid => <RennderUuid uuid={uuid} />,
    });

    columns.push({
      title: t('nameBanner'),
      dataIndex: 'nameBanner',
      key: '2',
      width: '60%',
    });

    columns.push({
      title: t('action'),
      dataIndex: 'action',
      key: '3',
      align: 'center',
      width: '10%',
      render: (text, obj) => renderAction(obj),
    });

    return columns;
  };

  const getData = () => {
    if (bannersTopBody?.length > 0) {
      return bannersTopBody.map(item => {
        return {
          nameBanner: item.name,
          id: item.id,
          uuid: item.uuid,
          link: item.link,
          showBanner: item?.showBanner,
        };
      });
    }
  };

  return (
    <Content className={css['Banners']}>
      <div className={css['Banners-header']}>
        <Search
          id='bannerSearchInput'
          data-qa='searchBannerInput'
          placeholder={t('searchPlaceholder')}
          onChange={handleSearchChangeDebounced}
          enterButton
        />
        <Button
          className={css['Banners-main-actions-add']}
          type='primary'
          data-qa='createBannerBtn'
          onClick={() => showModal({}, CREATE)}
        >
          {t('addBannerButton')}
        </Button>
      </div>

      <div>
        <div className={css['Banners-main']}>
          <Card className={css['Banners-card']}>
            <Table
              ellipsis
              bordered={false}
              columns={getColumns()}
              dataSource={getData()}
              rowKey={e => e?.id}
              loading={isLoading}
              pagination={
                bannersTopBody?.length < 11
                  ? false
                  : {
                      hideOnSinglePage: true,
                    }
              }
            />
          </Card>
        </div>
      </div>

      {isModal.visible && (
        <BannerModal
          open={isModal.visible}
          onClose={() => setIsModal({ visible: false, obj: {}, action: '' })}
          obj={Object.keys(isModal.obj).length === 0 ? false : isModal.obj}
          action={isModal.action}
        />
      )}
    </Content>
  );
};

export default Banners;
