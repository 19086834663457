import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  createTypicalPosition,
  getMismatch,
  getTypicalPositionsList,
  loadTypicalPositions,
} from '@/store/poka_typicalPositions/actions';
import { getPosition } from '@/store/students/actions';

import { selectPokaTypicalPositions } from '@/store/poka_typicalPositions/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Alert, Button, Layout, message, Typography } from 'antd';
import { Content } from '@/components/Layout';

import { DEFAULT_PARAMS } from './constants';
import FilterPositionAssignment from './Content/FilterPositionAssignment';
import ModalPositionAssignment from './Content/ModalPositionAssignment';
import TablePositionAssignment from './Content/TablePositionAssignment';

import css from './PositionAssignmentDirectory.module.scss';

const { Title } = Typography;

const PositionAssignmentDirectory = () => {
  const { t } = useTranslation('PositionAssignmentDirectory');
  const dispatch = useDispatch();

  const { position } = useSelector(selectStudents);
  const { typicalPositions, typicalPositionsList, positionsCurrentPage, mismatch, positionsFilters, positionsSort } =
    useSelector(selectPokaTypicalPositions);
  const [isModal, setIsModal] = useState(false);

  const onCreatePosition = data => {
    if (data?.modelPositionName) {
      const queryParams = { size: DEFAULT_PARAMS.size, page: positionsCurrentPage, sort: positionsSort };
      dispatch(
        createTypicalPosition(data, () => {
          setIsModal(false);
          dispatch(loadTypicalPositions(queryParams, positionsFilters));
        })
      );
    } else {
      message.warning(t('warningCreatePosition'));
    }
  };

  useEffect(() => {
    if (!typicalPositionsList?.length) dispatch(loadTypicalPositions(DEFAULT_PARAMS));
    if (!position?.length) dispatch(getPosition());
    if (!typicalPositions?.length) dispatch(getTypicalPositionsList());
    dispatch(getMismatch());
  }, []);

  return (
    <Layout>
      <Content>
        <div className={css['PositionAssignment']}>
          <div className={css['PositionAssignment-header']}>
            <Title>{t('title')}</Title>
            <div>
              <Button onClick={() => setIsModal(true)} size='large' type='primary'>
                {t('addGroup')}
              </Button>
            </div>
          </div>

          <FilterPositionAssignment />

          {mismatch && (
            <Alert
              className={css['PositionAssignment-alert']}
              message={t('unassignedPositionsAlertMessage')}
              description={
                <>
                  {t('unassignedPositionsAlertDescription')}{' '}
                  <Link to='/function-directory'>{t('functionDirectory')} </Link>
                </>
              }
              type='info'
              showIcon
              closable
            />
          )}

          <TablePositionAssignment />
        </div>
      </Content>

      {isModal && (
        <ModalPositionAssignment
          open={isModal}
          onCancel={() => setIsModal(false)}
          title={t('createTitle')}
          onOk={onCreatePosition}
          onOkText={t('createBtn')}
        />
      )}
    </Layout>
  );
};

export default PositionAssignmentDirectory;
