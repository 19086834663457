import { ReadFilled } from '@ant-design/icons';

const BookSvg = props => (
  <>
    <ReadFilled style={{ fontSize: '12px' }} />
    <span>{props.name}</span>
  </>
);

export default BookSvg;
