import {
  ADD_BANK_CATEGORIES,
  ADD_QUESTION_FAIL,
  ADD_QUESTION_START,
  ADD_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  DELETE_QUESTION_START,
  DELETE_QUESTION_SUCCESS,
  GET_BANK_CATEGORIES_FAIL,
  GET_BANK_CATEGORIES_START,
  GET_BANK_CATEGORIES_SUCCESS,
  GET_COUNT_QUESTIONS_FAIL,
  GET_COUNT_QUESTIONS_START,
  GET_COUNT_QUESTIONS_SUCCESS,
  GET_EXPORT_QUESTION_CSV_FAIL,
  GET_EXPORT_QUESTION_CSV_START,
  GET_EXPORT_QUESTION_CSV_SUCCESS,
  GET_QUESTIONS_LIST_FAIL,
  GET_QUESTIONS_LIST_START,
  GET_QUESTIONS_LIST_SUCCESS,
  GET_RANDOMIZER_INFO_FAIL,
  GET_RANDOMIZER_INFO_START,
  GET_RANDOMIZER_INFO_SUCCESS,
  IMPORT_QUESTION_FAIL,
  IMPORT_QUESTION_START,
  IMPORT_QUESTION_SUCCESS,
  SAVE_RANDOMIZER_INFO,
  SET_ACTIVE_SORT,
  SET_COUNT_QUESTIONS,
  SET_DEFAULT_SORT,
  SET_IS_SORT,
  UPDATE_QUESTION_FAIL,
  UPDATE_QUESTION_START,
  UPDATE_QUESTION_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import { headersForImport } from '@/constants/global';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Импорт вопросов
export const importQuestions = (blob, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/question/bank/import`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: blob,
  requestType: 'formData',
  types: [IMPORT_QUESTION_START, IMPORT_QUESTION_SUCCESS, IMPORT_QUESTION_FAIL],
  onSuccess,
  onError,
});

// Импорт вопросов
export const exportQuestionCSV = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/question/bank/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_EXPORT_QUESTION_CSV_START, GET_EXPORT_QUESTION_CSV_SUCCESS, GET_EXPORT_QUESTION_CSV_FAIL],
  onSuccess,
  onError,
});

// Добавление вопроса
export const addQuestion = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/question/bank`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [ADD_QUESTION_START, ADD_QUESTION_SUCCESS, ADD_QUESTION_FAIL],
  onSuccess,
  onError,
});

// Получение категорий для банка вопросов
export const getBankCategories = (category = '', page = 0, size = 50, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/question/bank/category${getQueryString({ category, page, size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BANK_CATEGORIES_START, GET_BANK_CATEGORIES_SUCCESS, GET_BANK_CATEGORIES_FAIL],
  onSuccess,
  onError,
});

// Получение списка вопросов с фильтрацией по категориям
export const getQuestionsList = (filter = {}, page = 0, sort, size = 20, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/question/bank/filter${getQueryString({ page, size, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    ...filter,
  },
  types: [GET_QUESTIONS_LIST_START, GET_QUESTIONS_LIST_SUCCESS, GET_QUESTIONS_LIST_FAIL],
  onSuccess,
  onError,
});

// Обновление вопроса
export const updateQuestion = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/question/bank`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [UPDATE_QUESTION_START, UPDATE_QUESTION_SUCCESS, UPDATE_QUESTION_FAIL],
  onSuccess,
  onError,
});

// Удаление вопроса
export const deleteQuestion = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/question/bank/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_QUESTION_START, DELETE_QUESTION_SUCCESS, DELETE_QUESTION_FAIL],
  onSuccess,
  onError,
});

// Получение данных из рандомайзера
export const getRandomizerInfo = (testId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/question/bank/test/${testId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_RANDOMIZER_INFO_START, GET_RANDOMIZER_INFO_SUCCESS, GET_RANDOMIZER_INFO_FAIL],
  onSuccess,
  onError,
});

// Получение кол-во вопросов по категориям
export const getCountCatQuestions = (ids, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/question/bank/category/count`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { ids },
  types: [GET_COUNT_QUESTIONS_START, GET_COUNT_QUESTIONS_SUCCESS, GET_COUNT_QUESTIONS_FAIL],
  onSuccess,
  onError,
});

export const setCountQuestions = value => ({
  type: SET_COUNT_QUESTIONS,
  value,
});

export const addBankCategories = value => ({
  type: ADD_BANK_CATEGORIES,
  value,
});

export const setIsSort = value => ({
  type: SET_IS_SORT,
  value,
});

export const setActiveSort = value => ({
  type: SET_ACTIVE_SORT,
  value,
});

export const setDefaultSort = () => ({
  type: SET_DEFAULT_SORT,
});

// сохранить категории для банка вопросов
export const saveRandomizerInfo = value => ({
  type: SAVE_RANDOMIZER_INFO,
  value,
});
