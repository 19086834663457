/* eslint-disable */
import React, { Component } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

export default class MonthCard extends Component {
  static propTypes = {
    disabledSelect: PropTypes.bool,
    displayFormat: PropTypes.string,
    isCurrent: PropTypes.bool,
    isPast: PropTypes.bool,
    onClick: PropTypes.func,
    outputFormat: PropTypes.string,
    selectedMonths: PropTypes.array,
  };

  handleClick = () => {
    const { onClick, isPast, outputFormat } = this.props;
    if (onClick && !isPast) {
      onClick(outputFormat);
    }
  };

  render() {
    const { displayFormat, isPast, outputFormat, selectedMonths, disabledSelect, isCurrent } = this.props;

    const isSame = dayjs(dayjs(selectedMonths.toString()).format('YYYYMM')).isSame(
      dayjs(outputFormat).format('YYYYMM')
    );

    const isSelected = !disabledSelect ? selectedMonths?.length && isSame : isCurrent;

    const monthClassNames = {
      [css['Calendar-modal-month-disabled']]: !disabledSelect ? isPast : !isCurrent,
      [css['Calendar-modal-month-selected']]: isSelected,
    };

    return (
      <div className={classNames(css['Calendar-modal-month'], monthClassNames)} onClick={this.handleClick}>
        <div className={css['Calendar-modal-month-header']}>{displayFormat}</div>
      </div>
    );
  }
}
