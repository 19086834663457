import { useTranslation } from 'react-i18next';

import { Badge, Table } from 'antd';
import FieldItemFileItem from '@/pages/Topics/TopicDetailsDialog/FieldItemFileItem';
import Condition from '@/components/Condition';

import { MATCHING_ID } from '../../test/Test/helpers';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const MATCHING_DELIMITER = '/';

const TestDetailsContent = ({ testItemData, getFile }) => {
  const { t } = useTranslation('result');

  const columns = [
    {
      title: t('status'),
      dataIndex: 'blocksStat.status',
      render: (text, record) => <>{record.blockStatusName}</>,
      sorter: (a, b) => a.blockStatusName.length - b.blockStatusName.length,
    },
    {
      title: t('startDate'),
      dataIndex: 'blocksStat.startTime',
      render: (text, block) => (
        <Condition when={block.blockStartDateTime}>
          {`
            ${dayjs(block.blockStartDateTime).format('DD.MM.YYYY')}
            ${t('in')}
            ${dayjs(block.blockStartDateTime).format('LT')}
          `}
        </Condition>
      ),
    },
    {
      title: t('endDate'),
      dataIndex: 'blocksStat.endDate',
      render: (text, block) => (
        <Condition when={block.blockEndDateTime && block.blockStatus === 'FINISHED'}>
          {`
            ${dayjs(block.blockEndDateTime).format('DD.MM.YYYY')}
            ${t('in')}
            ${dayjs(block.blockEndDateTime).format('LT')}
          `}
        </Condition>
      ),
    },
    {
      title: t('outcome'),
      dataIndex: 'blocksStat.blockId',
      render: () => <>{testItemData.summary}</>,
    },
  ];

  const expandedRowRender = ({ blockName, customQuestions, poll, fullName }) => {
    const data = [];

    const columns = [
      {
        title: t('question'),
        dataIndex: 'question',
        key: 'question',
        width: '25%',
        render: text => <div style={{ textWrap: 'wrap', wordBreak: 'break-word' }}>{text}</div>,
      },
      {
        title: t('answersApprove'),
        dataIndex: 'isCorrectUserAnswer',
        key: 'isCorrectUserAnswer',
        width: '3%',
        render: (text, { type, isCorrectUserAnswer }) => (
          <span>{!poll ? type !== 2 && <Badge status={isCorrectUserAnswer ? 'success' : 'error'} /> : ''}</span>
        ),
      },
      {
        title: t('viewAnswers'),
        dataIndex: 'viewAnswers',
        key: 'viewAnswers',
        width: '35%',
        render: text => <div style={{ textWrap: 'wrap', wordBreak: 'break-word' }}>{text}</div>,
      },
      {
        title: t('attachedFiles'),
        dataIndex: 'attachedFiles',
        key: 'attachedFiles',
        width: '8%',
        render: (text, { attachedFiles, index }) => {
          const name = `${fullName}_${blockName}_${index + 1}`;

          return (
            <>
              {(attachedFiles?.length > 1 && (
                <>
                  <FieldItemFileItem uuid={attachedFiles} getFile={getFile} name={name} />
                </>
              )) ||
                '-'}
            </>
          );
        },
      },
    ];

    Array.isArray(customQuestions) &&
      customQuestions.forEach(
        ({ id, question, customAnswer, type, position, isCorrectUserAnswer, answers, answer, uuid }, index) => {
          let allAnswer = '';

          if (type === 2 && customAnswer) {
            data.push({
              index,
              key: id,
              type,
              question,
              viewAnswers: customAnswer?.userAnswer,
              attachedFiles: customAnswer?.uuid,
              nameTestAndUser: `${fullName}_${blockName}_${+position + 1}`,
            });

            return;
          }

          if (type === MATCHING_ID && answer) {
            const splittedAnswer = answer
              .split(MATCHING_DELIMITER)
              .map(answerItem => answerItem.trim())
              .join('\n');

            allAnswer += splittedAnswer;

            data.push({
              index,
              key: id,
              type,
              question,
              isCorrectUserAnswer,
              viewAnswers: allAnswer,
              attachedFiles: uuid,
            });

            return;
          }

          if (Array.isArray(answers) && answers.length) {
            answers.forEach(answer => {
              if (answer.isUserChoice === true) {
                allAnswer += answer.answer;
                allAnswer += ' ';
              }
            });
          } else if (answer) {
            allAnswer += answer;
          }

          data.push({
            index,
            key: id,
            type,
            question,
            isCorrectUserAnswer,
            viewAnswers: allAnswer,
            attachedFiles: uuid,
          });
        }
      );

    return <Table style={{ whiteSpace: 'pre' }} columns={columns} dataSource={data} pagination={false} bordered />;
  };

  return (
    <>
      <Table
        size='small'
        rowKey={record => `${record?.blockId}-${record?.userId}-${Symbol('uniq').toString()}`}
        locale={{ emptyText: t('first') }}
        dataSource={[testItemData]}
        columns={columns}
        pagination={false}
        scroll={{ x: '100%' }}
        expandedRowRender={expandedRowRender}
        defaultExpandAllRows
      />
    </>
  );
};

TestDetailsContent.propTypes = {
  testItemData: PropTypes.object,
  getFile: PropTypes.func,
};

export default TestDetailsContent;
