/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';

export const GET_CHAPTERS_START = 'GET_CHAPTERS_START';
export const GET_CHAPTERS_SUCCESS = 'GET_CHAPTERS_SUCCESS';
export const GET_CHAPTERS_FAIL = 'GET_CHAPTERS_FAIL';

export const CREATE_CHAPTER_START = 'CREATE_CHAPTER_START';
export const CREATE_CHAPTER_SUCCESS = 'CREATE_CHAPTER_SUCCESS ';
export const CREATE_CHAPTER_FAIL = 'CREATE_CHAPTER_FAIL';

export const DELETE_CHAPTER_START = 'DELETE_CHAPTER_START';
export const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS ';
export const DELETE_CHAPTER_FAIL = 'DELETE_CHAPTER_FAIL';

export const RENAME_CHAPTER_START = 'RENAME_CHAPTER_START';
export const RENAME_CHAPTER_SUCCESS = 'RENAME_CHAPTER_SUCCESS ';
export const RENAME_CHAPTER_FAIL = 'RENAME_CHAPTER_FAIL';

export const MOVE_CHAPTER_START = 'MOVE_CHAPTER_START';
export const MOVE_CHAPTER_SUCCESS = 'MOVE_CHAPTER_SUCCESS ';
export const MOVE_CHAPTER_FAIL = 'MOVE_CHAPTER_FAIL';

export const MOVE_CHAPTER_BLOCK_START = 'MOVE_CHAPTER_BLOCK_START';
export const MOVE_CHAPTER_BLOCK_SUCCESS = 'MOVE_CHAPTER_BLOCK_SUCCESS ';
export const MOVE_CHAPTER_BLOCK_FAIL = 'MOVE_CHAPTER_BLOCK_FAIL';

export const REMOVE_CHAPTER_BLOCK_START = 'REMOVE_CHAPTER_BLOCK_START';
export const REMOVE_CHAPTER_BLOCK_SUCCESS = 'REMOVE_CHAPTER_BLOCK_SUCCESS ';
export const REMOVE_CHAPTER_BLOCK_FAIL = 'REMOVE_CHAPTER_BLOCK_FAIL';

export const CHECK_CHAPTERS_START = 'CHECK_CHAPTERS_START';
export const CHECK_CHAPTERS_SUCCESS = 'CHECK_CHAPTERS_SUCCESS';
export const CHECK_CHAPTERS_FAIL = 'CHECK_CHAPTERS_FAIL';

export const CLEAR_CHAPTERS_TOPIC = 'CLEAR_CHAPTERS_TOPIC';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getChapters = (topicIds, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/chapter?topicId=${topicIds}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    topicIds,
  },
  types: [GET_CHAPTERS_START, GET_CHAPTERS_SUCCESS, GET_CHAPTERS_FAIL],
  onSuccess,
});

export const createChapter = (data, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/chapter`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [CREATE_CHAPTER_START, CREATE_CHAPTER_SUCCESS, CREATE_CHAPTER_FAIL],
});

export const deleteChapter = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/chapter/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [DELETE_CHAPTER_START, DELETE_CHAPTER_SUCCESS, DELETE_CHAPTER_FAIL],
});

export const renameChapter = (id, data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/chapter/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [RENAME_CHAPTER_START, RENAME_CHAPTER_SUCCESS, RENAME_CHAPTER_FAIL],
});

export const moveChapter = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/chapter/position`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [MOVE_CHAPTER_START, MOVE_CHAPTER_SUCCESS, MOVE_CHAPTER_FAIL],
});

export const moveChapterBlock = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/chapter/move`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [MOVE_CHAPTER_BLOCK_START, MOVE_CHAPTER_BLOCK_SUCCESS, MOVE_CHAPTER_BLOCK_FAIL],
});

export const removeChapterBlock = (id, data, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/chapter/${id}/block`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [REMOVE_CHAPTER_BLOCK_START, REMOVE_CHAPTER_BLOCK_SUCCESS, REMOVE_CHAPTER_BLOCK_FAIL],
});

export const checkChapters = (topicIds, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/chapter?topicId=${topicIds}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    topicIds,
  },
  types: [CHECK_CHAPTERS_START, CHECK_CHAPTERS_SUCCESS, CHECK_CHAPTERS_FAIL],
  onSuccess,
  onError,
});

export const clearChapters = () => ({
  type: CLEAR_CHAPTERS_TOPIC,
});
