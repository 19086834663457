import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { exportProducts, postProductsFilters } from '@/store/product/actions';

import { selectShop } from '@/store/product/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Table, Tooltip } from 'antd';
import ImageBlock from '@/components/ImageBlock';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { HiCheck, HiDocumentDownload, HiX } from 'react-icons/hi';

import { CREATE, DELETE, EDIT } from './constants';
import ModalDelete from './Modal/ModalDelete';
import ModalProduct from './Modal/ModalProduct';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../Products.module.scss';

import { PRODUCT_ADMIN_CREATE, PRODUCT_ADMIN_UPDATE, PRODUCT_ADMIN_UPDATE_STATUS } from '@/constants/permissions';

const TableProducts = ({ setIsSort, activeSort, setActiveSort, isSort }) => {
  const { t } = useTranslation('products');
  const dispatch = useDispatch();

  const {
    isLoadingTable,
    productsTable,
    productsTotal,
    productsPageSize,
    productsPageNumber,
    productsCurrentPage,
    shopFilters,
    exportLoading,
  } = useSelector(selectShop);
  const { currentUser } = useSelector(selectUsers);
  const isSuperAdminOrIsAdmin = Utils.isAdminRole(currentUser);
  const [isProduct, setIsProduct] = useState({
    action: '',
    obj: {},
    open: false,
  });

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('productPhoto'),
      dataIndex: 'productPhoto',
      key: 1,
      width: 100,
      align: 'center',
      render: photoUuid => <ImageBlock photoUuid={photoUuid} />,
    });

    columns.push({
      title: t('productName'),
      dataIndex: 'productName',
      key: 2,
      render: (text, value) => (
        <>
          <Tooltip title={text?.length > 25 && text}>
            {isSuperAdminOrIsAdmin && !value.productPublished ? (
              <div className={css['Table-cell-href']}> {text}</div>
            ) : (
              <a
                rel='noreferrer'
                href={`https://${currentUser?.domainCompany?.domain}/shop/${value.productId}`}
                target='_blank'
                className={css['Table-cell-href']}
              >
                {text}
              </a>
            )}
          </Tooltip>
          <div className={css['Table-cell-href-id']}>ID:{value.productId}</div>
        </>
      ),
    });

    columns.push({
      title: t('productCost'),
      dataIndex: 'productCost',
      key: 3,
    });

    columns.push({
      title: t('productAmount'),
      dataIndex: 'productAmount',
      key: 4,
    });

    columns.push({
      title: t('productDelivery'),
      dataIndex: 'productDelivery',
      key: 5,
    });

    columns.push({
      title: t('productChanged'),
      dataIndex: 'productChanged',
      key: 6,
    });

    columns.push({
      title: t('productPublished'),
      dataIndex: 'productPublished',
      key: 7,
      render: (text, value) => (value.productPublished ? <HiCheck size='24' /> : <HiX size='24' />),
      align: 'center',
    });

    columns.push({
      dataIndex: 'action',
      key: 8,
      render: (text, obj) => (
        <div className={css['Table-content-actions']}>
          {currentUser?.authorities.includes(PRODUCT_ADMIN_UPDATE) && (
            <FormOutlined className={css['Table-content-actions-edit']} onClick={() => showModalProduct(EDIT, obj)} />
          )}
          {currentUser?.authorities.includes(PRODUCT_ADMIN_UPDATE_STATUS) && (
            <DeleteOutlined
              className={css['Table-content-actions-delete']}
              onClick={() => showModalProduct(DELETE, obj)}
            />
          )}
        </div>
      ),
    });

    return columns;
  };

  const getData = () => {
    if (productsTable?.length) {
      return productsTable.map(item => {
        return {
          productPhoto: item.photoUuid,
          productName: item.name,
          productCost: item.cost,
          productAmount: item.count,
          productDelivery: item.deliveryDto.name,
          productTypeDelivery: item.deliveryDto,
          productChanged: item.changeDate,
          productPublished: item.published,
          productId: item.id,
          productDesc: item.description,
          productAuthor: item.authorName,
          productChangeAuthor: item.changeAuthorName,
        };
      });
    }
  };

  const showModalProduct = (action, obj) => setIsProduct({ open: true, action, obj });

  const onChangeTable = pagination => {
    dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, pagination.current - 1, isSort));
  };

  const pagination = {
    total: productsTotal,
    pageSize: productsPageSize,
    pageNumber: productsPageNumber,
    current: productsCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const sortCount = () => {
    setActiveSort({ count: !activeSort.count });
    setIsSort(!activeSort.count ? 'count,desc' : '');
    !activeSort.count
      ? dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, 0, 'count,desc'))
      : dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, 0));
  };

  const sortAmount = () => {
    setActiveSort({ cost: !activeSort.cost });
    setIsSort(!activeSort.cost ? 'cost,desc' : '');
    !activeSort.cost
      ? dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, 0, 'cost,desc'))
      : dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, 0));
  };

  const exportTable = () => {
    const date = new Date();
    const fileNameNotSplit = `Prodcuts(${date.toLocaleDateString()}).csv`;

    dispatch(
      exportProducts(shopFilters.filters ? shopFilters.filters : {}, (blob, headers) => {
        const fileName = headers.get('Content-Disposition')?.split?.(['"'])?.[1] || fileNameNotSplit;
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  return (
    <div className={css['Table']}>
      <div className={css['Table-export']}>
        <Button type='link' className={css['Table-export-btn']} onClick={exportTable} loading={exportLoading}>
          <HiDocumentDownload className={css['Table-export-icon']} />
          {t('exportTable')}
        </Button>
      </div>

      {/* Блок сортировки + кнопка */}
      <div className={css['Table-header']}>
        <div className={css['Table-header-button']}>
          {currentUser?.authorities.includes(PRODUCT_ADMIN_CREATE) && (
            <Button
              onClick={() => showModalProduct(CREATE)}
              data-qa='createProduct'
              key='createProduct'
              size='large'
              type='primary'
            >
              {t('createProduct')}
            </Button>
          )}
        </div>
        <div className={css['Table-header-sort']}>
          <span className={css['Table-header-sort-text']}>{t('sort')}</span>
          <span
            className={classNames(
              css['Table-header-sort-action'],
              activeSort.count === true && css['Table-header-sort-active']
            )}
            onClick={sortCount}
          >
            {t('sortCount')}
          </span>
          <span className={css['Table-header-sort-vertical']}></span>
          <span
            className={classNames(
              css['Table-header-sort-action'],
              activeSort.cost === true && css['Table-header-sort-active']
            )}
            onClick={sortAmount}
          >
            {t('sortAmount')}
          </span>
        </div>
      </div>
      {/* конец блока сортировки + кнопка */}

      <div className={css['Table-content']}>
        <Table
          columns={getColumns()}
          dataSource={getData()}
          pagination={pagination}
          onChange={onChangeTable}
          rowKey={e => e.productId}
          loading={isLoadingTable}
        />
      </div>

      {isProduct.open && (isProduct.action === EDIT || isProduct.action === CREATE) && (
        <ModalProduct isProduct={isProduct} onClose={() => setIsProduct({ ...isProduct, open: false })} />
      )}

      {isProduct.open && isProduct.action === DELETE && (
        <ModalDelete isProduct={isProduct} onClose={() => setIsProduct({ ...isProduct, open: false })} />
      )}
    </div>
  );
};

TableProducts.propTypes = {
  setIsSort: PropTypes.func,
  activeSort: PropTypes.object,
  setActiveSort: PropTypes.func,
  isSort: PropTypes.string,
};

export default TableProducts;
