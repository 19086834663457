// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-Yle5tf.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-R6kbLl .admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-label-TqLKry[data-value="Montserrat"]:before, .admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-Yle5tf.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-R6kbLl .admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-item-zpYD__[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-Montserrat-Y1x8ZP {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__headerContentCaption-ytrOad {
  font-weight: bold;
}

.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__headerContentCaptionRow-ls1lXn {
  margin-top: 16px;
  font-weight: bold;
  text-decoration: underline;
}

.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__blockResultBtn-eVOkeW {
  justify-content: space-between;
  display: flex;
}

.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__noResult-uZYkV2 {
  text-align: center;
  font-weight: bold;
}

.admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__loading-Du6aBa {
  text-align: center;
  flex-flow: column;
  place-content: center;
  align-items: center;
  min-height: 15rem;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Users/Profile/modules/DiagnosticsGPM/diagnosticsGPM.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,iBAAiB;AACnB;;AAOA;EAJE,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAOA;EAJE,8BAA8B;EAC9B,aAAa;AACf;;AAOA;EAJE,kBAAkB;EAClB,iBAAiB;AACnB;;AAOA;EAJE,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.headerContentCaption {\n  font-weight: bold;\n}\n\n.headerContentCaptionRow {\n  font-weight: bold;\n  margin-top: 16px;\n  text-decoration: underline;\n}\n\n.blockResultBtn {\n  display: flex;\n  justify-content: space-between;\n}\n\n.noResult {\n  text-align: center;\n  font-weight: bold;\n}\n\n.loading {\n  text-align: center;\n  min-height: 15rem;\n  display: flex;\n  flex-flow: column nowrap;\n  place-content: center center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-Yle5tf`,
	"qlPicker": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-Yle5tf`,
	"ql-font": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-R6kbLl`,
	"qlFont": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-R6kbLl`,
	"ql-picker-label": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-label-TqLKry`,
	"qlPickerLabel": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-label-TqLKry`,
	"ql-picker-item": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-item-zpYD__`,
	"qlPickerItem": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-picker-item-zpYD__`,
	"ql-font-Montserrat": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-Montserrat-Y1x8ZP`,
	"qlFontMontserrat": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__ql-font-Montserrat-Y1x8ZP`,
	"headerContentCaption": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__headerContentCaption-ytrOad`,
	"headerContentCaptionRow": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__headerContentCaptionRow-ls1lXn`,
	"blockResultBtn": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__blockResultBtn-eVOkeW`,
	"noResult": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__noResult-uZYkV2`,
	"loading": `admin-src-pages-Users-Profile-modules-DiagnosticsGPM-diagnosticsGPM-module__loading-Du6aBa`
};
export default ___CSS_LOADER_EXPORT___;
