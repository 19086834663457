/* eslint-disable */
import { CHANGE_NOTIFICATION_USER_SUCCESS } from '@/store/users/actions';

import {
  BLOCK_USER_SUCCESS,
  CHANGE_USERS_FILTER,
  CLEAR_FULLNAME,
  CLEAR_MANAGER_FULLNAME,
  CLEAR_STUDENTS_CREATEUSER_STORE,
  CLEAR_STUDENTS_STORE,
  CREATE_STUDY_PLAN_FAIL,
  CREATE_STUDY_PLAN_MASS_FAIL,
  CREATE_STUDY_PLAN_MASS_START,
  CREATE_STUDY_PLAN_MASS_SUCCESS,
  CREATE_STUDY_PLAN_START,
  CREATE_STUDY_PLAN_SUCCESS,
  EXPORT_SELECTED_USER_IN_MASS_FAIL,
  EXPORT_SELECTED_USER_IN_MASS_START,
  EXPORT_SELECTED_USER_IN_MASS_SUCCESS,
  EXPORT_USER_FAIL,
  EXPORT_USER_START,
  EXPORT_USER_SUCCESS,
  GET_COUNT_SELECTED_USER_FAIL,
  GET_COUNT_SELECTED_USER_START,
  GET_COUNT_SELECTED_USER_SUCCESS,
  GET_DEPARTMENT_FAIL,
  GET_DEPARTMENT_START,
  GET_DEPARTMENT_SUCCESS,
  GET_FULLNAME_FAIL,
  GET_FULLNAME_START,
  GET_FULLNAME_SUCCESS,
  GET_FUNCTION_FAIL,
  GET_FUNCTION_START,
  GET_FUNCTION_SUCCESS,
  GET_LDAP_STATUS_FAIL,
  GET_LDAP_STATUS_START,
  GET_LDAP_STATUS_SUCCESS,
  GET_LOCATION_FAIL,
  GET_LOCATION_START,
  GET_LOCATION_SUCCESS,
  GET_MANAGER_FULLNAME_FAIL,
  GET_MANAGER_FULLNAME_START,
  GET_MANAGER_FULLNAME_SUCCESS,
  GET_POSITION_FAIL,
  GET_POSITION_START,
  GET_POSITION_SUCCESS,
  GET_STRING_VALUE_IN_MASS_FAIL,
  GET_STRING_VALUE_IN_MASS_START,
  GET_STRING_VALUE_IN_MASS_SUCCESS,
  GET_STUDENTS_ALL_STAT_FAIL,
  GET_STUDENTS_ALL_STAT_START,
  GET_STUDENTS_ALL_STAT_SUCCESS,
  GET_STUDENTS_CREATEUSER_FAIL,
  GET_STUDENTS_CREATEUSER_START,
  GET_STUDENTS_CREATEUSER_SUCCESS,
  GET_STUDENTS_DELETED_FAIL,
  GET_STUDENTS_DELETED_START,
  GET_STUDENTS_DELETED_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_START,
  GET_STUDENTS_STAT_FAIL,
  GET_STUDENTS_STAT_START,
  GET_STUDENTS_STAT_SUCCESS,
  GET_STUDENTS_SUCCESS,
  POST_MULTIPLE_USER_TOPICS_FAIL,
  POST_MULTIPLE_USER_TOPICS_START,
  POST_MULTIPLE_USER_TOPICS_SUCCESS,
  PUT_MANAGERS_SUCCESS,
  PUT_PERMISSION_FAIL,
  PUT_PERMISSION_START,
  PUT_PERMISSION_SUCCESS,
  SET_EMPLOYEE_FULLNAME,
  SET_MANAGER_FULLNAME,
  TOGGLE_ALL_USERS_TRAJECTORY_FAIL,
  TOGGLE_ALL_USERS_TRAJECTORY_START,
  TOGGLE_ALL_USERS_TRAJECTORY_SUCCESS,
  TOGGLE_USER_LOCKED_FAIL,
  TOGGLE_USER_LOCKED_START,
  TOGGLE_USER_LOCKED_SUCCESS,
  TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_FAIL,
  TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_START,
  TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_SUCCESS,
  UPDATE_LDAP_STATUS_FAIL,
  UPDATE_LDAP_STATUS_START,
  UPDATE_LDAP_STATUS_SUCCESS,
} from './actions';

const initialState = {
  allStat: null,
  students: [],
  studentsForUser: [],
  studentsTotal: null,
  studentsPageSize: null,
  studentsPageNumber: null,
  userFilter: {},
  fullname: null,
  fullnameManager: null,
  functionStudent: null,
  department: null,
  position: null,
  location: null,
  ldapStatus: {},
  addToPlanMultiple: {},
  userLockedStatus: {},
  authoritiesUser: null,
  isLoading: false,
  error: false,
  deletedStudents: [],
  deletedStudentsTotal: null,
  deletedStudentsPageSize: null,
  deletedStudentsPageNumber: null,
  countSelectedStudents: null,
  selectedUsersBlob: null,
  stringValues: [],
  isLoadingMassTopic: false,
  isLoadingFullnameManager: false,
  isLoadingFullname: false,
  isLoadingCreateUser: false,
  isLoadingUsers: false,
  isLoadingResult: false,
  isLoadingStudents: false,
};

const changeUser = (arr, value) => {
  return arr.map(item => {
    if (item.id === value.id) {
      return value;
    }
    return item;
  });
};

export default (state = initialState, { type, response, info, headers, action, value }) => {
  switch (type) {
    case GET_STUDENTS_START:
      return {
        ...state,
        students: !action.isUserModal ? [] : state.students,
        stat: null,
        studentsTotal: null,
        studentsPageSize: null,
        studentsPageNumber: null,
        isLoadingStudents: true,
        error: false,
      };

    case GET_STUDENTS_CREATEUSER_START:
      return {
        ...state,
        isLoadingCreateUser: true,
        error: false,
      };

    case CREATE_STUDY_PLAN_START:
      return { ...state, isLoading: true };

    case CREATE_STUDY_PLAN_SUCCESS:
      return {
        ...state,
        newStatusPlanId: response,
        isLoading: false,
        error: false,
      };

    case GET_STUDENTS_SUCCESS:
      const userList = !action.isUserModal ? response : [...state.students, ...response];

      return {
        ...state,
        students: userList,
        studentsTotal: Number(headers.get('X-Total-Elements')) || 0,
        studentsPageSize: Number(headers.get('X-Page-Size')) || 0,
        studentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoadingStudents: false,
      };

    case GET_STUDENTS_CREATEUSER_SUCCESS:
      return {
        ...state,
        studentsForUser: response,
        isLoadingCreateUser: false,
      };

    case GET_STUDENTS_DELETED_SUCCESS:
      return {
        ...state,
        deletedStudents: response,
        deletedStudentsTotal: Number(headers.get('X-Total-Elements')) || 0,
        deletedStudentsPageSize: Number(headers.get('X-Page-Size')) || 0,
        deletedStudentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_STUDENTS_STAT_START:
      return { ...state, stat: null, isLoading: true, error: false };

    case GET_STUDENTS_STAT_SUCCESS:
      return { ...state, stat: response, isLoading: false };
    case CHANGE_USERS_FILTER:
      return { ...state, userFilter: info };

    case PUT_PERMISSION_SUCCESS:
      return { ...state, authoritiesUser: response, isLoading: false };

    case GET_STUDENTS_ALL_STAT_START:
      return { ...state, isLoading: true, error: false, allStat: null };

    case GET_STUDENTS_ALL_STAT_SUCCESS:
      return { ...state, isLoading: false, error: false, allStat: response };

    case POST_MULTIPLE_USER_TOPICS_START:
    case TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_START:
    case TOGGLE_USER_LOCKED_START:
    case UPDATE_LDAP_STATUS_START:
    case GET_LDAP_STATUS_START:
    case GET_FUNCTION_START:
    case GET_LOCATION_START:
    case GET_DEPARTMENT_START:
    case GET_POSITION_START:
    case GET_STUDENTS_DELETED_START:
    case TOGGLE_ALL_USERS_TRAJECTORY_START:
    case GET_STRING_VALUE_IN_MASS_START:
    case EXPORT_SELECTED_USER_IN_MASS_START:
    case GET_COUNT_SELECTED_USER_START:
    case TOGGLE_ALL_USERS_TRAJECTORY_SUCCESS:
    case PUT_PERMISSION_START:
      return { ...state, error: false, isLoading: true };

    case EXPORT_USER_START:
      return { ...state, error: false, isLoadingUsers: true };

    case GET_FULLNAME_START:
      return { ...state, error: false, isLoadingFullname: true };

    case GET_MANAGER_FULLNAME_START:
      return { ...state, error: false, isLoadingFullnameManager: true };

    case CREATE_STUDY_PLAN_MASS_START:
      return { ...state, error: false, isLoadingMassTopic: true };

    case GET_LDAP_STATUS_SUCCESS:
      return { ...state, error: false, isLoading: false, ldapStatus: response };

    case TOGGLE_USER_LOCKED_SUCCESS:
    case PUT_MANAGERS_SUCCESS:
    case BLOCK_USER_SUCCESS:
    case CHANGE_NOTIFICATION_USER_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        students: changeUser(state.students, response),
      };

    case TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_SUCCESS:
      const trajectoryList = state.students.map(item => {
        const student = response.find(user => user.id === item.id);
        return student || item;
      });
      return {
        ...state,
        error: false,
        isLoading: false,
        students: trajectoryList,
      };

    case UPDATE_LDAP_STATUS_SUCCESS:
      return { ...state, error: false, isLoading: false, ldapStatus: response };

    case POST_MULTIPLE_USER_TOPICS_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        addToPlanMultiple: response,
      };
    case GET_FUNCTION_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        functionStudent: response,
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        location: response,
      };

    case GET_FULLNAME_SUCCESS:
      return { ...state, error: false, isLoadingFullname: false, fullname: response.content };

    case GET_MANAGER_FULLNAME_SUCCESS:
      return {
        ...state,
        error: false,
        fullnameManager: response.content,
        isLoadingFullnameManager: false,
      };

    case GET_DEPARTMENT_SUCCESS:
      return { ...state, error: false, isLoading: false, department: response };

    case GET_POSITION_SUCCESS:
      return { ...state, error: false, isLoading: false, position: response };

    case EXPORT_USER_SUCCESS:
      return { ...state, error: false, isLoadingUsers: false };

    case TOGGLE_USER_LOCKED_FAIL:
    case TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_FAIL:
    case POST_MULTIPLE_USER_TOPICS_FAIL:
    case UPDATE_LDAP_STATUS_FAIL:
    case GET_LDAP_STATUS_FAIL:
    case PUT_PERMISSION_FAIL:
    case GET_DEPARTMENT_FAIL:
    case GET_LOCATION_FAIL:
    case GET_POSITION_FAIL:
    case GET_STUDENTS_DELETED_FAIL:
    case GET_STRING_VALUE_IN_MASS_FAIL:
    case TOGGLE_ALL_USERS_TRAJECTORY_FAIL:
    case EXPORT_SELECTED_USER_IN_MASS_FAIL:
    case GET_COUNT_SELECTED_USER_FAIL:
    case CREATE_STUDY_PLAN_FAIL:
    case GET_STUDENTS_ALL_STAT_FAIL:
    case GET_STUDENTS_STAT_FAIL:
    case GET_FUNCTION_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_STUDENTS_FAIL:
      return { ...state, error: true, isLoadingStudents: false };

    case EXPORT_USER_FAIL:
      return { ...state, error: true, isLoadingUsers: false };

    case GET_STUDENTS_CREATEUSER_FAIL:
      return { ...state, error: true, isLoadingCreateUser: false };

    case GET_FULLNAME_FAIL:
      return { ...state, error: true, isLoadingFullname: false };

    case GET_MANAGER_FULLNAME_FAIL:
      return { ...state, error: true, isLoadingFullnameManager: false };

    case CREATE_STUDY_PLAN_MASS_FAIL:
      return { ...state, error: true, isLoadingMassTopic: false };

    case GET_COUNT_SELECTED_USER_SUCCESS:
      return { ...state, countSelectedStudents: response, error: false, isLoading: false };

    case EXPORT_SELECTED_USER_IN_MASS_SUCCESS:
      return { ...state, selectedUsersBlob: response, error: false, isLoading: false };

    case GET_STRING_VALUE_IN_MASS_SUCCESS:
      return { ...state, isLoading: false, stringValues: response };

    case CLEAR_STUDENTS_STORE:
      return {
        ...state,
        isLoading: false,
        students: [],
        studentsTotal: null,
        studentsPageSize: null,
        studentsPageNumber: null,
      };

    case CLEAR_STUDENTS_CREATEUSER_STORE:
      return { ...state, isLoading: false, studentsForUser: [] };

    case CREATE_STUDY_PLAN_MASS_SUCCESS:
      return { ...state, isLoadingMassTopic: false };

    case SET_MANAGER_FULLNAME:
      return { ...state, error: false, fullnameManager: value };

    case CLEAR_MANAGER_FULLNAME:
      return { ...state, error: false, fullnameManager: null };

    case CLEAR_FULLNAME:
      return { ...state, error: false, fullname: null };

    case SET_EMPLOYEE_FULLNAME:
      return { ...state, error: false, fullname: value };

    default:
      return state;
  }
};
