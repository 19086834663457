/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_SKILLS_START = 'GET_SKILLS_START';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAIL = 'GET_SKILLS_FAIL';
export const GET_GLOBAL_SKILLS_START = 'GET_GLOBAL_SKILLS_START';
export const GET_GLOBAL_SKILLS_SUCCESS = 'GET_GLOBAL_SKILLS_SUCCESS';
export const GET_GLOBAL_SKILLS_FAIL = 'GET_GLOBAL_SKILLS_FAIL';
export const CREATE_SKILLS_START = 'CREATE_SKILLS_START';
export const CREATE_SKILLS_SUCCESS = 'CREATE_SKILLS_SUCCESS';
export const CREATE_SKILLS_FAIL = 'CREATE_SKILLS_FAIL';
export const DELETE_SKILLS_START = 'DELETE_SKILLS_START';
export const DELETE_SKILLS_SUCCESS = 'DELETE_SKILLS_SUCCESS';
export const DELETE_SKILLS_FAIL = 'DELETE_SKILLS_FAIL';
export const EDIT_SKILLS_START = 'EDIT_SKILLS_START';
export const EDIT_SKILLS_SUCCESS = 'EDIT_SKILLS_SUCCESS';
export const EDIT_SKILLS_FAIL = 'EDIT_SKILLS_FAIL';

export const ADD_TRACK_SKILLS = 'ADD_TRACK_SKILLS';
export const CLEAR_TRACK_SKILLS = 'CLEAR_TRACK_SKILLS';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getSkills = (name = '', page = 0, onSuccess, isGlobal = false) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/skill${getQueryString({
    name: name && name.toLowerCase(),
    isGlobal,
    isSharing: isGlobal ? '' : false,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SKILLS_START, GET_SKILLS_SUCCESS, GET_SKILLS_FAIL],
  onSuccess,
});

export const getSkillsGlobal = (name = '', page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/skill${getQueryString({
    name: name && name.toLowerCase(),
    isGlobal: true,
    isSharing: true,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GLOBAL_SKILLS_START, GET_GLOBAL_SKILLS_SUCCESS, GET_GLOBAL_SKILLS_FAIL],
  onSuccess,
});

export const createSkills = (name = '', onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/skill`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
  },
  onSuccess,
  onError,
  types: [CREATE_SKILLS_START, CREATE_SKILLS_SUCCESS, CREATE_SKILLS_FAIL],
});

export const deleteSkills = (id, { onSuccess, onError }) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/skill/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_SKILLS_START, DELETE_SKILLS_SUCCESS, DELETE_SKILLS_FAIL],
});

export const editSkills = (name, id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/skill/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
  },
  onSuccess,
  types: [EDIT_SKILLS_START, EDIT_SKILLS_SUCCESS, EDIT_SKILLS_FAIL],
});

export const addTrackSkills = value => ({
  type: ADD_TRACK_SKILLS,
  value,
});

export const clearTrackSkills = () => ({
  type: CLEAR_TRACK_SKILLS,
});
