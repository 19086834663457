/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Select } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Select.module.scss';

const { Option } = Select;

class CustomSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    contextSearch: PropTypes.bool,
    contextSearchActive: PropTypes.bool,
    dark: PropTypes.bool,
    darker: PropTypes.bool,
    disabledRequests: PropTypes.bool,
    highLong: PropTypes.bool,
    long: PropTypes.bool,
    longSelect: PropTypes.bool,
    managerName: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    options: PropTypes.any,
    placeholder: PropTypes.string,
    showSearch: PropTypes.bool,
    topicTypeId: PropTypes.bool,
    value: PropTypes.any,
    white: PropTypes.bool,
    whiteSmall: PropTypes.bool,
    withIcon: PropTypes.bool,
    inactive: PropTypes.bool,
    mediumSelect: PropTypes.bool,
    isBlackClear: PropTypes.bool,
    size: PropTypes.string,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
  };

  handleChange = (value, item) => {
    const { onChange, name } = this.props;
    onChange({ name, value, item });
  };

  render() {
    const {
      className,
      options,
      disabledRequests,
      long,
      highLong,
      longSelect,
      topicTypeId,
      dark,
      showSearch,
      white,
      whiteSmall,
      darker,
      withIcon,
      contextSearchActive,
      contextSearch,
      disabled,
      inactive,
      t,
      mediumSelect,
      dataQa,
      topicTypeLabelId,
      isBlackClear,
      size,
      onSearch,
      loading,
      ...rest
    } = this.props;

    const optionElements =
      options &&
      options.length > 0 &&
      options.map((option, key) => {
        const id = option.id || key;
        let val =
          option.value ||
          option.department ||
          option.function ||
          option.name ||
          option.fullName ||
          option.companies ||
          option.id;

        if (topicTypeLabelId || topicTypeId) {
          val = option.id.toString();
        }

        if (!withIcon) {
          return (
            id && (
              <Option key={id} value={String(val || id)}>
                {option.name ||
                  option.fullName ||
                  option.function ||
                  option.department ||
                  option.year ||
                  option.companies}
              </Option>
            )
          );
        }
        return (
          id && (
            <Option key={id} className={css['with-icon']} value={val.toString() || id.toString()}>
              {option.id.indexOf('MAIL_') !== -1 ? (
                <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                  <g fill='none'>
                    <rect width='20' height='20' rx='10' />
                    <g transform='translate(2 2)' />
                    <g stroke='#2D3440'>
                      <path d='M5.5 6.5h9v7h-9z' />
                      <path d='m14 7-4 4-4-4' />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                  <g fill='none'>
                    <rect width='20' height='20' rx='10' />
                    <g transform='translate(2 2)' />
                    <path
                      d='M14.994 11.47 7.011 4l-.005 11.035 2.422-2.359 1.423 3.3 2.22-.99-1.423-3.3zm-2.832 3.155-.956.427-1.542-3.576-1.968 1.916.004-7.794 5.638 5.275-2.717.176z'
                      fill='#2D3440'
                      stroke='#2D3440'
                    />
                  </g>
                </svg>
              )}
              {option.name ||
                option.fullName ||
                option.function ||
                option.department ||
                option.year ||
                option.companies}
            </Option>
          )
        );
      });

    if (rest.value) {
      rest.value = rest.value.toString();
    }

    return (
      <div
        className={classNames(
          css.Select,
          className,
          { [css.dark]: dark },
          { [css.long]: long },
          {
            [css.highLong]: highLong,
            [css.disabledRequests]: disabledRequests,
            [css.darker]: darker || disabled,
            [css.whiteSmall]: whiteSmall,
            [css.contextSearch]: contextSearch,
          },
          { [css.contextSearchActive]: contextSearchActive }
        )}
      >
        <Select
          value={rest.value}
          key={this.props.name}
          data-qa={dataQa}
          disabled={disabled}
          placeholder={this.props.placeholder || t('all')}
          showSearch={showSearch}
          onChange={this.handleChange}
          onSearch={onSearch}
          dropdownRender={optionElements => <span data-qa={`${dataQa}-list`}>{optionElements}</span>}
          notFoundContent={t('search')}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          popupClassName={classNames(css['Select-dropdown'], {
            [css.dark]: dark,
            [css.white]: white,
            [css.darker]: darker,
            [css.whiteSmall]: whiteSmall,
          })}
          className={classNames(css['Select-input'], {
            [css.white]: white,
            [css.whiteSmall]: whiteSmall,
            [css.longSelect]: longSelect,
            [css.mediumSelect]: mediumSelect,
            [css.isBlackClear]: isBlackClear,
          })}
          size={size}
          loading={loading}
          allowClear={this.props.allowClear}
        >
          {optionElements}
        </Select>
      </div>
    );
  }
}

export default withTranslation('select')(CustomSelect);
