// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-_xp4kp.admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-XJSkTE .admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-label-Nv1oD8[data-value="Montserrat"]:before, .admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-_xp4kp.admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-XJSkTE .admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-item-ZQBxi_[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-Montserrat-KEFlsB {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-ScrollArrow-ScrollArrow-module__ScrollArrow-Fe2krZ {
  cursor: pointer;
  background-color: #0000;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  width: 41px;
  min-width: 41px;
  height: 41px;
  display: flex;
}

.admin-src-components-ScrollArrow-ScrollArrow-module__ScrollArrow-Fe2krZ svg path {
  fill: #000;
}

.admin-src-components-ScrollArrow-ScrollArrow-module__ScrollArrow-Fe2krZ.admin-src-components-ScrollArrow-ScrollArrow-module__disabled-tVqvNB {
  cursor: not-allowed;
  background-color: #0000;
}

.admin-src-components-ScrollArrow-ScrollArrow-module__ScrollArrow-Fe2krZ.admin-src-components-ScrollArrow-ScrollArrow-module__disabled-tVqvNB svg g {
  stroke: #ccc;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/ScrollArrow/ScrollArrow.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,eAAe;EACf,uBAAuB;EACvB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAOA;EAJE,UAAU;AACZ;;AAMA;EAHE,mBAAmB;EACnB,uBAAuB;AACzB;;AAKA;EAFE,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.ScrollArrow {\n  height: 41px;\n  width: 41px;\n  min-width: 41px;\n  border-radius: 2px;\n  background-color: transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.ScrollArrow svg path {\n  fill: #000;\n}\n.ScrollArrow.disabled {\n  background-color: transparent;\n  cursor: not-allowed;\n}\n.ScrollArrow.disabled svg g {\n  stroke: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-_xp4kp`,
	"qlPicker": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-_xp4kp`,
	"ql-font": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-XJSkTE`,
	"qlFont": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-XJSkTE`,
	"ql-picker-label": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-label-Nv1oD8`,
	"qlPickerLabel": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-label-Nv1oD8`,
	"ql-picker-item": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-item-ZQBxi_`,
	"qlPickerItem": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-picker-item-ZQBxi_`,
	"ql-font-Montserrat": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-Montserrat-KEFlsB`,
	"qlFontMontserrat": `admin-src-components-ScrollArrow-ScrollArrow-module__ql-font-Montserrat-KEFlsB`,
	"ScrollArrow": `admin-src-components-ScrollArrow-ScrollArrow-module__ScrollArrow-Fe2krZ`,
	"scrollArrow": `admin-src-components-ScrollArrow-ScrollArrow-module__ScrollArrow-Fe2krZ`,
	"disabled": `admin-src-components-ScrollArrow-ScrollArrow-module__disabled-tVqvNB`
};
export default ___CSS_LOADER_EXPORT___;
