/* eslint-disable */
import React, { Component } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export default class ScrollArrow extends Component {
  static propTypes = {
    className: PropTypes.string,
    displayBeforeStart: PropTypes.any,
    displayFormat: PropTypes.string,
    monthClassName: PropTypes.string,
    monthCount: PropTypes.number,
    outputFormat: PropTypes.string,
    renderMonth: PropTypes.func.isRequired,
    startDate: PropTypes.any,
  };

  static defaultProps = {
    monthCount: 12,
    startDate: dayjs(),
  };

  render() {
    const {
      className,
      displayFormat,
      outputFormat,
      startDate,
      monthCount,
      monthClassName,
      renderMonth,
      displayBeforeStart,
    } = this.props;

    const month = dayjs(startDate);
    const beforeStart = dayjs(displayBeforeStart);
    const currentDate = dayjs();
    const content = [];

    for (let i = 0; i < monthCount; i++) {
      const currentMonth = month.add(i, 'month');
      const monthProps = {
        displayFormat: currentMonth.format(displayFormat),
        outputFormat: currentMonth.format(outputFormat),
        isPast: currentMonth.isBefore(currentDate, 'month'),
        isCurrent: currentMonth.isSame(currentDate, 'month'),
      };

      content.push(
        <div className={monthClassName} key={monthProps.outputFormat}>
          {renderMonth(monthProps)}
        </div>
      );
    }

    return <div className={className}>{content}</div>;
  }
}
