/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getFile } from '@/store/files/actions';

import { Button } from 'antd';
import { FiEdit2 } from 'react-icons/fi';

import { Card } from '../Layout';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './CompilationCard.module.scss';

import { COMPILATION_DELETE, COMPILATION_UPDATE } from '@/constants/permissions';
import placeholder from '@/assets/images/no-bg.svg';
import pencil from '@/assets/images/pencil.svg';

const CompilationCard = ({ compilation, getFile, onEdit, onChangeVisibility, currentUser }) => {
  const [coverUrl, setCoverUrl] = useState('');

  const _isMounted = useRef(false);

  useEffect(() => {
    const getCurrentFile = photoUuid => {
      if (!photoUuid) {
        setCoverUrl(null);
        return;
      }

      getFile(photoUuid, blob => {
        if (_isMounted.current) {
          const imageUrl = URL.createObjectURL(blob);
          setCoverUrl(imageUrl);
        }
      });
    };

    if (compilation) {
      getCurrentFile(compilation.cover, compilation.id);
    }

    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, [compilation, getFile]);

  return (
    <CompilationCardComponent
      compilation={compilation}
      coverUrl={coverUrl}
      onEdit={onEdit}
      onChangeVisibility={onChangeVisibility}
      currentUser={currentUser}
    />
  );
};

CompilationCard.propTypes = {
  compilation: PropTypes.object,
  getFile: PropTypes.func,
  history: PropTypes.object,
  onEdit: PropTypes.func,
};

const mapStateToProps = createSelector([], () => ({}));

const mapActionsToProps = {
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(CompilationCard);

const CompilationCardComponent = ({
  currentUser,
  compilation,
  coverUrl,
  onEdit,
  onChangeVisibility,
  compilation: { id, name },
}) => {
  const { t } = useTranslation('compilationCardComponent');

  const editCompilation = (
    <Button className={css.CompilationCard__buttonEdit} size='middle' data-qa='compilationEditCardBtn' onClick={onEdit}>
      <FiEdit2 size={14} />
    </Button>
  );

  const hasAuthorities =
    currentUser.authorities.includes(COMPILATION_UPDATE) || currentUser.authorities.includes(COMPILATION_DELETE);

  return (
    <Card className={css.CompilationCard}>
      <Link className={css.CompilationCard__content} to={`/compilations/${id}`}>
        <div className={css.CompilationCard__cover}>
          {coverUrl ? <img src={coverUrl} alt='' /> : <img className={css.placeholder} src={placeholder} alt='' />}
        </div>
        <div data-qa='compilationCardName' className={css.CompilationCard__name}>
          {name}
        </div>
      </Link>

      {compilation?.global
        ? currentUser?.domainCompany?.global && hasAuthorities && editCompilation
        : hasAuthorities && editCompilation}

      <Button size='middle' className={css.CompilationCard__visible} onClick={() => onChangeVisibility(compilation)}>
        {compilation.visible ? t('hide') : t('show')}
      </Button>
    </Card>
  );
};
