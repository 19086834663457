import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Divider, Layout, Menu, Spin, Tooltip } from 'antd';
import {
  AuditOutlined,
  BulbOutlined,
  DollarOutlined,
  FileSearchOutlined,
  MailFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageFilled,
  ProfileOutlined,
  SketchOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { BiBuilding } from 'react-icons/bi';
import { FiAlertTriangle, FiBookOpen } from 'react-icons/fi';
import {
  ImBell,
  ImBook,
  ImBookmark,
  ImBookmarks,
  ImBooks,
  ImBriefcase,
  ImCalendar,
  ImCart,
  ImClipboard,
  ImCog,
  ImDatabase,
  ImKey2,
  ImMagicWand,
  ImNewspaper,
  ImPen,
  ImPriceTags,
  ImProfile,
  ImQuill,
  ImStatsBars,
  ImStatsDots,
  ImTarget,
  ImTree,
  ImUsers,
} from 'react-icons/im';

import UtilsPoka from '@shared/constants/showPoka';
import useIsMobile from '@shared/hooks/useIsMobile';

import {
  accessSettings,
  career,
  companies,
  compilation,
  d360,
  defaultRoute,
  directory,
  events,
  gamefication,
  inspector,
  logging,
  manageCourse,
  mentor,
  news,
  newsLetters,
  pokaSkills,
  profileSetting,
  profileSettings,
  questionBank,
  shop,
  topics,
  tracks,
  users,
  varDirectory,
} from '@/router/routes';
import PropTypes from 'prop-types';

import css from './Sider.module.scss';

import smallLogo from '@projectStatic/icons/icon-72x72.png';

const { Sider } = Layout;

const routesConfig = [
  topics,
  questionBank,
  compilation,
  pokaSkills,
  tracks,
  news,
  manageCourse,
  career,
  inspector,
  events,
  d360,
  directory,
  users,
  accessSettings,
  varDirectory,
  newsLetters,
  profileSettings,
  profileSetting,
  logging,
  companies,
  mentor,
  shop,
  gamefication,
];

const HIDE_SUBCOMPANY_IDS = [1401, 6952]; // 6952 - Лабмедиа

const AppSider = props => {
  const { t } = useTranslation('appSider');

  const [collapsed, setCollapsed] = useState(false);
  const [isMobile] = useIsMobile(992);

  const getMenuIcon = path => {
    const icons = [
      { path: '/topics', icon: <ImBook /> },
      { path: '/question-bank', icon: <ImDatabase /> },
      { path: '/compilations', icon: <ImBooks /> },
      { path: '/manage-course', icon: <ImClipboard /> },
      { path: '/inspector', icon: <FileSearchOutlined /> },
      { path: '/events', icon: <ImCalendar /> },
      { path: '/users', icon: <ImUsers /> },
      { path: '/access', icon: <ImKey2 /> },
      { path: '/var-directory', icon: <ImCog /> },
      { path: '/notifications', icon: <ImBell /> },
      { path: '/mailing', icon: <MailFilled /> },
      { path: 'newsLetters', icon: <MessageFilled /> },
      { path: '/profile-setting', icon: <ImProfile /> },
      { path: '/tag-directory', icon: <ImPriceTags /> },
      { path: '/skills-directory', icon: <ImStatsDots /> },
      { path: '/competence-directory', icon: <ImBriefcase /> },
      { path: '/authors-directory', icon: <ImQuill /> },
      { path: '/post-directory', icon: <ImTarget /> },
      { path: '/department-directory', icon: <ImTree /> },
      { path: '/function-directory', icon: <ImMagicWand /> },
      { path: 'directories', icon: <FiBookOpen /> },
      { path: 'activity', icon: <FiAlertTriangle /> },
      { path: '/d360', icon: <ImStatsBars /> },
      { path: 'mentor', icon: <ImTree /> },
      { path: 'shop', icon: <ImCart /> },
      { path: '/tracks', icon: <ImBookmarks /> },
      { path: 'poka', icon: <BulbOutlined /> },
      { path: '/skills', icon: <ImBook /> },
      { path: '/news', icon: <ImNewspaper /> },
      { path: '/badge', icon: <SketchOutlined /> },
      { path: '/manage-career', icon: <AuditOutlined /> },
      { path: 'gamefication', icon: <DollarOutlined /> },
      { path: '/coin-settings', icon: <DollarOutlined /> },
      { path: '/approver-directory', icon: <UsergroupAddOutlined /> },
      { path: '/position-assignment', icon: <ImPen /> },
      { path: '/groups-directory', icon: <AuditOutlined /> },
      { path: '/area-directory', icon: <ImBookmark /> },
      { path: '/profile-settings', icon: <ProfileOutlined /> },
      { path: '/companies', icon: <BiBuilding /> },
    ];

    let icon = null;
    icons.map(item => {
      if (item.path === path) {
        icon = item.icon;
      }
    });

    return <span style={{ paddingRight: '0.5rem' }}>{icon}</span>;
  };

  const roles = routesConfig.filter(config => {
    const authorities = props.currentUser?.authorities;

    if (authorities.includes(config.roles)) {
      if ('companyFlag' in config) {
        if (props.currentUser.domainCompany[config.companyFlag]) {
          return config;
        }
        return null;
      }
      return config;
    } else if (config.roles === '') {
      return config;
    } else if (Array.isArray(config.roles)) {
      if (config.roles.filter(role => authorities.includes(role)).length) {
        return config;
      }
    }
  });

  const menu = roles.map(m => {
    const params = m?.params ?? '';

    if (m.children) {
      let filterRouts = m.children.filter(rout => props.currentUser?.authorities.includes(rout.roles));

      // после слития в прод полной поки нужно удалить условие и вернуть старое присвоение чилдренов
      let hidePokaRoutsProd = filterRouts.filter(i => {
        if (UtilsPoka.showPokaElems(i.path, props.currentUser.domainCompany.domain, props.currentUser?.email)) {
          return null;
        }
        return i;
      });

      // m = { ...m, children: filterRouts };  // после слития в прод полной поки нужно удалить условие и вернуть старое присвоение чилдренов
      m = { ...m, children: hidePokaRoutsProd };
    }

    if (UtilsPoka.showPokaElems(m.name, props.currentUser.domainCompany.domain, props.currentUser?.email)) {
      return null;
    }

    if (!m.children) {
      if (m.path === '/d360') {
        if (props.currentUser?.domainCompany?.id && HIDE_SUBCOMPANY_IDS.includes(props.currentUser.domainCompany.id)) {
          return null;
        }
      }

      if (m.path === '/compilations') {
        if (props.currentUser && props.currentUser.domainCompany && !props.currentUser.domainCompany.hasCompilation) {
          return null;
        }
      }

      return {
        key: m.path,
        icon: getMenuIcon(m.path),
        label: <Link to={m.path + params}>{t(m.name)}</Link>,
      };
    }

    return {
      key: `sub-${t(m.name)}`,
      icon: getMenuIcon(m.name),
      label: t(m.name),
      children: m.children
        .filter(route => (route.hasAdditionalInfo ? props.currentUser?.domainCompany?.hasAdditionalInfo : true))
        .map(item => ({
          key: item.path,
          icon: getMenuIcon(item.path),
          label: <Link to={item.path + params}>{t(item.name)}</Link>,
        })),
    };
  });

  const siderTrigger = useMemo(
    () => (
      <>
        <Divider className={css['App-sider-trigger-divider']} />
        <Tooltip
          mouseEnterDelay={1.5}
          mouseLeaveDelay={0.5}
          title={collapsed ? t('unfoldMenu') : t('foldMenu')}
          placement='right'
        >
          <Button type='filled' className={css['App-sider-trigger']}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </Tooltip>
      </>
    ),
    [collapsed]
  );

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isMobile]);

  return (
    <div style={{ width: collapsed ? '4em' : '20em' }} className={css['App-sider-affix']}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        className={css['App-sider']}
        width={'20em'}
        collapsedWidth={'4em'}
        trigger={siderTrigger}
      >
        <Spin spinning={!props.imageLogo}>
          <Link className={css['App-sider-logo']} to='/'>
            <img src={collapsed ? smallLogo : props.imageLogo} />
          </Link>
        </Spin>
        <Menu
          className={css['App-menu']}
          selectedKeys={[props.pathname === '/' ? defaultRoute : props.pathname]}
          mode={collapsed ? 'vertical' : 'inline'}
          items={menu}
        />
      </Sider>
    </div>
  );
};

AppSider.propTypes = {
  currentUser: PropTypes.object,
  pathname: PropTypes.string,
  imageLogo: PropTypes.any,
};

export default AppSider;
