import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeStatusNews, postNewsFilters } from '@/store/news/actions';

import { selectNews } from '@/store/news/selectors';

import { Button, message, Modal } from 'antd';
import { HiOutlineEmojiHappy, HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import { DELETED, IN_ACTIVE, REJECTED_BY_ADMIN, STATUS_ACTIVE, STATUS_DELETED, WAITING_MODERATE } from './constance';

import PropTypes from 'prop-types';

import css from '../News.module.scss';

const ModalAction = ({ onClose, isAction }) => {
  const { t } = useTranslation('news');
  const dispatch = useDispatch();

  const { newsFilters, newsCurrentPage, sortNews } = useSelector(selectNews);

  const [isDelete, setIsDelete] = useState(false);

  const onChangeStatusNews = statusModerate => {
    const params = {
      id: isAction.obj.newsId || null,
      status: statusModerate,
    };

    dispatch(
      changeStatusNews(
        params,
        () => {
          isAction.type === DELETED && setIsDelete(true);
          dispatch(postNewsFilters(newsFilters.filters ? newsFilters.filters : {}, newsCurrentPage, sortNews));
          onClose();
        },
        err => {
          if (err) {
            message.error(err?.message);
          }
        }
      )
    );
  };

  const goToNews = () => {
    dispatch(postNewsFilters(newsFilters.filters ? newsFilters.filters : {}, newsCurrentPage, sortNews));
    onClose();
  };

  const deleteContent = (
    <>
      {isDelete ? (
        <HiOutlineEmojiHappy color='var(--personalColor)' size={168} />
      ) : (
        <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
      )}
      <div className={css['ModalAction-isDelete-title']}>{isDelete ? t('newsDeleted') : t('delNews')}</div>
      <div className={css['ModalAction-isDelete-footer']}>
        {!isDelete ? (
          <>
            <Button onClick={onClose} key='cancel' size='large'>
              {t('cancel')}
            </Button>
            <Button
              className={css['ModalAction-isDelete-footer-delete']}
              onClick={() => onChangeStatusNews(STATUS_DELETED)}
              key='onChangeStatusNews'
              size='large'
              type='primary'
            >
              {t('delete')}
            </Button>
          </>
        ) : (
          <Button onClick={goToNews} key='goToNews' size='large' type='primary'>
            {t('backNews')}
          </Button>
        )}
      </div>
    </>
  );

  const moderateContent = (
    <>
      <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
      <div className={css['ModalAction-isDelete-title']}>{t('moderateNews')} </div>
      <div className={css['ModalAction-isDelete-footer']}>
        <Button onClick={() => onChangeStatusNews(REJECTED_BY_ADMIN)} key='noModerate' size='large'>
          {t('noModerate')}
        </Button>
        <Button
          className={css['ModalAction-isDelete-footer-delete']}
          onClick={() => onChangeStatusNews(STATUS_ACTIVE)}
          key='yesModerate'
          size='large'
          type='primary'
        >
          {t('yesModerate')}
        </Button>
      </div>
    </>
  );

  const inActive = (
    <>
      <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
      <div className={css['ModalAction-isDelete-title']}>{t('inActiveNews')} </div>
      <div className={css['ModalAction-isDelete-footer']}>
        <Button onClick={onClose} key='cancelActive' size='large'>
          {t('cancel')}
        </Button>
        <Button
          className={css['ModalAction-isDelete-footer-delete']}
          onClick={() => onChangeStatusNews(STATUS_ACTIVE)}
          key='yesActive'
          size='large'
          type='primary'
        >
          {t('yesActive')}
        </Button>
      </div>
    </>
  );

  return (
    <Modal className={css['ModalAction']} width={600} open={isAction.open} onCancel={onClose} footer={null}>
      <div className={css['ModalAction-isDelete']}>
        {isAction.type === DELETED && deleteContent}
        {isAction.type === WAITING_MODERATE && moderateContent}
        {isAction.type === IN_ACTIVE && inActive}
      </div>
    </Modal>
  );
};

ModalAction.propTypes = {
  onClose: PropTypes.func,
  isAction: PropTypes.object,
};

export default ModalAction;
