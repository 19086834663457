import { API_D360_URL } from '../service_api';
import {
  D360_GET_PROJECT_FAIL,
  D360_GET_PROJECT_START,
  D360_GET_PROJECT_SUCCESS,
  D360_TEST_IMPORT_FAIL,
  D360_TEST_IMPORT_START,
  D360_TEST_IMPORT_SUCCESS,
} from './constants';

import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360TestImport = (projectId, respondent, measurable, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/import/test`,
    body: {
      d360_project_id: projectId,
      respondent: respondent,
      measurable: measurable,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_TEST_IMPORT_START, D360_TEST_IMPORT_SUCCESS, D360_TEST_IMPORT_FAIL],
    onSuccess,
    onError,
  };
};

export const D360GetProject = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/project${getQueryString({
      id: id,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_PROJECT_START, D360_GET_PROJECT_SUCCESS, D360_GET_PROJECT_FAIL],
    onSuccess,
    onError,
  };
};

/*
   Import statement
   ----------------
   import { D360GetProject } from '@/store/d360_projects/actions';
*/
