/* eslint-disable */
import {
  CREATE_TAG_FAIL,
  CREATE_TAG_START,
  CREATE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  DELETE_TAG_START,
  DELETE_TAG_SUCCESS,
  EDIT_TAG_FAIL,
  EDIT_TAG_START,
  EDIT_TAG_SUCCESS,
  GET_GLOBAL_TAGS_FAIL,
  GET_GLOBAL_TAGS_START,
  GET_GLOBAL_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_TAGS_START,
  GET_TAGS_SUCCESS,
} from './actions';

const initialState = {
  tags: null,
  tagsTotal: null,
  tagsPageSize: null,
  tagsPageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  globalTags: {
    tags: null,
    tagsTotal: null,
    tagsPageSize: null,
    tagsPageNumber: null,
    isLoading: false,
    error: false,
  },
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_TAGS_START:
      return {
        ...state,
        tags: null,
        tagsTotal: null,
        tagsPageSize: null,
        tagsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: response,
        tagsTotal: Number(headers.get('X-Total-Elements')) || 0,
        tagsPageSize: Number(headers.get('X-Page-Size')) || 0,
        tagsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_TAGS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_GLOBAL_TAGS_START:
      return {
        ...state,
        globalTags: {
          tags: null,
          tagsTotal: null,
          tagsPageSize: null,
          tagsPageNumber: null,
          isLoading: true,
          error: false,
        },
      };

    case GET_GLOBAL_TAGS_SUCCESS:
      return {
        ...state,
        globalTags: {
          tags: response,
          tagsTotal: Number(headers.get('X-Total-Elements')) || 0,
          tagsPageSize: Number(headers.get('X-Page-Size')) || 0,
          tagsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoading: false,
        },
      };

    case GET_GLOBAL_TAGS_FAIL:
      return {
        ...state,
        globalTags: { ...state.global, error: true, isLoading: false },
      };

    case CREATE_TAG_START:
      return { ...state, isLoading: true, creationError: false };

    case CREATE_TAG_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_TAG_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_TAG_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_TAG_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_TAG_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_TAG_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_TAG_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_TAG_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    default:
      return state;
  }
};
