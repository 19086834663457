// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-KmQior.admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-f6ho9L .admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-label-tm1xhr[data-value="Montserrat"]:before, .admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-KmQior.admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-f6ho9L .admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-item-DEdNem[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-Montserrat-JwuHjo {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters-_5WrVt {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  display: grid;
}

.admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters__form-item-bzQ0J3 {
  margin: 0;
}

.admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters__element-mEOwfI {
  width: 100%;
  max-width: 375px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MVFilters/components/GlobalFilters/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,qCAAqC;EACrC,SAAS;EACT,aAAa;AACf;;AAMA;EAHE,SAAS;AACX;;AAKA;EAFE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.global-filters {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 20px;\n}\n.global-filters__form-item {\n  margin: 0;\n}\n.global-filters__element {\n  width: 100%;\n  max-width: 375px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-KmQior`,
	"qlPicker": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-KmQior`,
	"ql-font": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-f6ho9L`,
	"qlFont": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-f6ho9L`,
	"ql-picker-label": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-label-tm1xhr`,
	"qlPickerLabel": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-label-tm1xhr`,
	"ql-picker-item": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-item-DEdNem`,
	"qlPickerItem": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-picker-item-DEdNem`,
	"ql-font-Montserrat": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-Montserrat-JwuHjo`,
	"qlFontMontserrat": `admin-src-components-MVFilters-components-GlobalFilters-style-module__ql-font-Montserrat-JwuHjo`,
	"global-filters": `admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters-_5WrVt`,
	"globalFilters": `admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters-_5WrVt`,
	"global-filters__form-item": `admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters__form-item-bzQ0J3`,
	"globalFiltersFormItem": `admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters__form-item-bzQ0J3`,
	"global-filters__element": `admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters__element-mEOwfI`,
	"globalFiltersElement": `admin-src-components-MVFilters-components-GlobalFilters-style-module__global-filters__element-mEOwfI`
};
export default ___CSS_LOADER_EXPORT___;
