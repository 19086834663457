/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getFile } from '@/store/files/actions';

import { Content } from '@/components/Layout';

import { defaultRoute } from '@/router/routes';

const DownloadFile = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const uuidWithFormat = window.location.pathname.split('/download/')[1];
    const uuid = uuidWithFormat.split('/')[0];
    const format = uuidWithFormat.split('/')[1].toLowerCase();
    dispatch(getFile(uuid, file => download(file, format)));
  }, []);

  const download = (file, format) => {
    const date = new Date();
    const objectURL = URL.createObjectURL(file);
    const tempLink = document.createElement('a');
    tempLink.style = 'display: none';
    tempLink.href = objectURL;
    tempLink.download = `${date.toISOString()}.${format}`;
    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(objectURL);
      navigate(defaultRoute, { replace: true });
    }, 1000);
  };

  const render = () => {
    return <Content></Content>;
  };

  return render();
};

export default DownloadFile;
