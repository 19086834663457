import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getGroups, saveGroupFilters } from '@/store/groups/actions';

import { Button, Col, Form, Input, Row } from 'antd';

import { PAGE_SIZE } from '../constants';

import css from '../CompetenceGroupsDirectory.module.scss';

const Filters = () => {
  const { t } = useTranslation('CompetenceGroupsDirectory');
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState('');

  const onSubmitFilters = () => {
    dispatch(saveGroupFilters(groupName));
    dispatch(getGroups(groupName, 0, PAGE_SIZE));
  };

  const onResetFilters = () => {
    setGroupName('');
    dispatch(saveGroupFilters(''));
    dispatch(getGroups('', 0, PAGE_SIZE));
  };

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-txt']}>{t('filters')}</div>
      <Form layout='vertical'>
        <Row type='flex' justify='start' gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={t('searchGroups')} colon={false}>
              <Input
                onChange={e => setGroupName(e?.target?.value)}
                value={groupName}
                placeholder={t('placeholderSearchGroups')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='end' gutter={[16, 0]}>
          <Col>
            <Button onClick={onResetFilters}>{t('reset')}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmitFilters} type='primary'>
              {t('apply')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filters;
