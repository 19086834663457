export const GET_TYPEFORMS_START = 'GET_TYPEFORMS_START';
export const GET_TYPEFORMS_SUCCESS = 'GET_TYPEFORMS_SUCCESS';
export const GET_TYPEFORMS_FAIL = 'GET_TYPEFORMS_FAIL';

export const GET_TYPEFORM_START = 'GET_TYPEFORM_START';
export const GET_TYPEFORM_SUCCESS = 'GET_TYPEFORM_SUCCESS';
export const GET_TYPEFORM_FAIL = 'GET_TYPEFORM_FAIL';

export const POST_TYPEFORM_START = 'POST_TYPEFORM_START';
export const POST_TYPEFORM_SUCCESS = 'POST_TYPEFORM_SUCCESS';
export const POST_TYPEFORM_FAIL = 'POST_TYPEFORM_FAIL';

export const DELETE_TYPEFORM_START = 'DELETE_TYPEFORM_START';
export const DELETE_TYPEFORM_SUCCESS = 'DELETE_TYPEFORM_SUCCESS';
export const DELETE_TYPEFORM_FAIL = 'DELETE_TYPEFORM_FAIL';

export const UPDATE_TYPEFORM_START = 'UPDATE_TYPEFORM_START';
export const UPDATE_TYPEFORM_SUCCESS = 'UPDATE_TYPEFORM_SUCCESS';
export const UPDATE_TYPEFORM_FAIL = 'UPDATE_TYPEFORM_FAIL';

export const GET_TYPEFORM_ANSWERS_START = 'GET_TYPEFORM_ANSWERS_START';
export const GET_TYPEFORM_ANSWERS_SUCCESS = 'GET_TYPEFORM_ANSWERS_SUCCESS';
export const GET_TYPEFORM_ANSWERS_FAIL = 'GET_TYPEFORM_ANSWERS_FAIL';

export const POST_TYPEFORM_ANSWERS_START = 'POST_TYPEFORM_ANSWERS_START';
export const POST_TYPEFORM_ANSWERS_SUCCESS = 'POST_TYPEFORM_ANSWERS_SUCCESS';
export const POST_TYPEFORM_ANSWERS_FAIL = 'POST_TYPEFORM_ANSWERS_FAIL';

export const GET_TYPEFORM_EXPORT_START = 'GET_TYPEFORM_EXPORT_START';
export const GET_TYPEFORM_EXPORT_SUCCESS = 'GET_TYPEFORM_EXPORT_SUCCESS';
export const GET_TYPEFORM_EXPORT_FAIL = 'GET_TYPEFORM_EXPORT_FAIL';
