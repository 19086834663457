/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createFile } from '@/store/files/actions';

import { selectFiles as filesSelect } from '@/store/files/selectors';

import { Button, Col, Row, Spin, Tag, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

export class DiagnosticsPolus extends Component {
  static propTypes = {
    t: PropTypes.func,
    student: PropTypes.object,
    fileInfo: PropTypes.object,
    fileLoading: PropTypes.bool,
    fileLoadingImage: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      competencies: [],
      skills: [],
      competence: {},
      topics: [],
      review: null,
      report: null,
      uploadingReview: false,
      uploadingReport: false,
    };
  }

  componentDidMount() {
    const { student } = this.props;
    const { consult } = this.state;

    findUserReview(
      student.id,
      res => {
        this.setState({ review: res });
      },
      err => {
        this.setState({ review: null });
      }
    );

    findUserReport(
      student.id,
      res => {
        this.setState({ report: res });
      },
      err => {
        this.setState({ report: null });
      }
    );
  }

  uploadReviewFile = f => {
    const { student } = this.props;
    const formData = new FormData();
    formData.append('file', f.file);

    this.setState({ uploadingReview: true });

    this.props.createFile(
      formData,
      uploadFile => {
        this.setState({ uploadingReview: false });
      },
      uploadFileError => {
        console.error('Error', uploadFileError);
        this.setState({ uploadingReview: false });
      }
    );
  };

  uploadReportFile = f => {
    const { student } = this.props;
    const formData = new FormData();
    formData.append('file', f.file);

    this.setState({ uploadingReport: true });

    this.props.createFile(
      formData,
      uploadFile => {
        this.setState({ uploadingReport: false });
      },
      uploadFileError => {
        console.error('Error', uploadFileError);
        this.setState({ uploadingReport: false });
      }
    );
  };

  deleteReview = () => {
    //TODO:deleteReview
  };

  removeReport = () => {
    //TODO: removeReport
  };

  renderUploadReview = () => {
    const { review, uploadingReview } = this.state;
    const { fileLoading, t } = this.props;
    const header = {
      'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
    };

    return (
      <Row style={{ marginBottom: '.5rem' }}>
        <Col span={8}>{t('tabDiagnosticsPolusReview')}</Col>
        <Col
          span={16}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          {!review && (
            <Upload
              accept='.pdf,.doc,.docx,.xls,.xlsx'
              customRequest={this.uploadReviewFile}
              showUploadList={false}
              headers={header}
            >
              <Button disabled={fileLoading} size='small' type='primary'>
                {fileLoading === true && uploadingReview === true ? <Spin size='small' /> : <UploadOutlined />}
                &nbsp;
                {t('tabDiagnosticsPolusUpload')}
              </Button>
            </Upload>
          )}

          {review && review.name && (
            <Tag color='#fbc80d' closable={true} onClose={() => this.setState({ review: null })}>
              {review.name}
            </Tag>
          )}
        </Col>
      </Row>
    );
  };

  renderUploadReport = () => {
    const { report, uploadingReport } = this.state;
    const { fileLoading, t } = this.props;
    const header = {
      'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
    };

    return (
      <Row style={{ marginBottom: '.5rem' }}>
        <Col span={8}>{t('tabDiagnosticsPolusReport')}</Col>
        <Col
          span={16}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          {!report && (
            <Upload
              accept='.pdf,.doc,.docx,.xls,.xlsx'
              customRequest={this.uploadReportFile}
              showUploadList={false}
              headers={header}
            >
              <Button disabled={fileLoading} size='small'>
                {fileLoading === true && uploadingReport === true ? <Spin size='small' /> : <UploadOutlined />}
                &nbsp;
                {t('tabDiagnosticsPolusUpload')}
              </Button>
            </Upload>
          )}

          {report && report.name && (
            <Tag color='#fbc80d' closable={true} onClose={() => this.setState({ report: null })}>
              {report.name}
            </Tag>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { student, t } = this.props;

    return (
      <>
        {this.renderUploadReview()}
        {this.renderUploadReport()}
        <Row>
          <Col span={8}>{t('tabDiagnosticsPolusRecomm')}</Col>
          <Col span={16}>
            <a href={`/polus/${student.id}`}>{t('tabDiagnosticsPolusCheck')}</a>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = createSelector(filesSelect, files => ({
  fileInfo: files.info,
  fileLoading: files.isLoading,
  fileLoadingImage: files.isLoadingImage,
}));

const mapActionsToProps = {
  createFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('studentProfileDialog')(DiagnosticsPolus));
