/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { EllipsisOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import QuillEditor from '@shared/components/QuillEditor';

import Utils from '@/Utils';

import css from '../style/blocksStyle.module.scss';
import article_css from './Article.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';
import article from '@/assets/images/article.svg';

// Максимальный размер = нет ограничений
const MAX_TEXT_SYMBOLS = 99999999999;

export const Article = ({
  data,
  editBlock,
  deleteBlock,
  forceClose,
  createArticle,
  updateArticle,
  canEdit,
  getChapters,
  currentUser,
  topicTypeId,
}) => {
  const { t } = useTranslation('article');
  const [error, setError] = useState(false);
  const [textCharCount, setTextCharCount] = useState(0);
  const [htmlCharCount, setHtmlCharCount] = useState(0);
  const [articleBody, setArticleBody] = useState((data.article && data.article.body) || null);
  const [articleID, setArticleID] = useState((data.article && data.article.id) || null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [alreadySaved, setAlreadySaved] = useState(true);

  const header = useMemo(
    () => (
      <div className={css['Block-header']}>
        <img className={css['Block-header-item']} src={article} alt='' />
        <div className={css['Block-header-name']}>
          <Tooltip title={data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(data.name, 55)}
          </Tooltip>
        </div>
      </div>
    ),
    [data.name]
  );

  const saveButtonHandler = useCallback(() => {
    if (canEdit && !articleID) {
      createArticle(
        data.id,
        articleBody,
        response => {
          setArticleID(response.id);
          setError(false);
          setAlreadySaved(true);
          getChapters(data.topicId);
        },
        response => {
          setError(true);
          setErrorTitle(response.statusText);
          setErrorText(response.message);
        }
      );
    } else {
      updateArticle(
        articleID,
        articleBody,
        () => {
          setError(false);
          setAlreadySaved(true);
          getChapters(data.topicId);
        },
        response => {
          setError(true);
          setErrorTitle(response.statusText);
          setErrorText(response.message);
        }
      );
    }
  }, [articleBody, alreadySaved]);

  const handleEditClick = useCallback(() => {
    editBlock(data.id);
  }, [editBlock]);

  const handleDeleteClick = useCallback(() => {
    deleteBlock(data.id);
  }, [deleteBlock]);

  const dropDownMenu = [
    ...(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={handleEditClick}>
                {t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={handleDeleteClick}>
                {t('delete')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const extra = useMemo(
    () => (
      <div onClick={e => e.stopPropagation()}>
        <Button
          onClick={saveButtonHandler}
          className={css['Block-header-save']}
          disabled={alreadySaved || !canEdit}
          type='primary'
          data-qa='saveBlockBtn'
        >
          {t('save')}
        </Button>
        {(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
          currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
          (topicTypeId === 25 &&
            (currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
              currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
          <Dropdown
            menu={{ items: dropDownMenu }}
            placement='bottomRight'
            overlayClassName={css['Dropdown-menu']}
            trigger={['click']}
          >
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    ),
    [deleteBlock, editBlock, saveButtonHandler, canEdit]
  );

  const handleChangeEditor = (htmlCode, textCode) => {
    if (textCode.length > 0 && undefined !== htmlCode) {
      setAlreadySaved(false);
      setTextCharCount(textCode.length);
      setHtmlCharCount(htmlCode.length);
      setArticleBody(htmlCode);
    } else {
      setAlreadySaved(true);
      setTextCharCount(0);
      setHtmlCharCount(0);
      setArticleBody(null);
    }
  };

  return (
    <div className={css.Block}>
      <CollapseLMS header={header} data={data} extra={extra} forceClose={forceClose}>
        <div className={css['Block-layoutScorm']}>
          <QuillEditor maxChar={MAX_TEXT_SYMBOLS} onChange={handleChangeEditor} Content={articleBody} />
          <div className={article_css.articleCounters}>
            <span>
              {t('text')} <span>{textCharCount}</span>
            </span>
            <span>
              {t('code')} <span>{htmlCharCount}</span>
            </span>
            <span>
              {t('id')} <span>{articleID}</span>
            </span>
          </div>
        </div>
      </CollapseLMS>
      {error && (
        <ConfirmationModal
          open
          onOk={() => {
            setError(false);
          }}
          onCancel={() => {
            setError(false);
          }}
          okText={t('ok')}
          zIndex={1006}
          title={errorTitle}
          confirmationText={errorText}
        />
      )}
    </div>
  );
};
