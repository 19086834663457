import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import Quill from 'quill';

function cmdHTML() {
  const editor = this.quill;
  const root = editor.container.parentNode;
  let htmlViewer = null;

  if (root.querySelector('textarea') === null) {
    htmlViewer = document.createElement('textarea');
    htmlViewer.style.margin = 0;
    htmlViewer.style.height = '25rem';
    htmlViewer.style.width = '100%';
    htmlViewer.style.fontSize = '1rem';
    htmlViewer.style.borderColor = '#ccc';
    htmlViewer.style.borderTop = 0;
    htmlViewer.style.display = 'none';
    editor.container.parentNode.appendChild(htmlViewer);
  }

  htmlViewer = root.querySelector('textarea');

  if (htmlViewer.style.display !== 'block') {
    editor.container.style.display = 'none';
    htmlViewer.style.display = 'block';
    htmlViewer.value = this.state.editorHtml;
  } else {
    editor.container.style.display = 'block';
    htmlViewer.style.display = 'none';
    this.setState({
      editorHtml: htmlViewer.value,
    });
  }
}

function imageHandler() {
  const input = document.createElement('input');
  const editor = this.quill;
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/png, image/jpeg, image/gif, image/jpg');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      var selection = editor.getSelection(true);
      editor.insertEmbed(selection.index, 'image', reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
}

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

export const modules = (container, userName, maxLength = 10000) => ({
  toolbar: {
    container,
    handlers: {
      html: cmdHTML,
      ['username']: function onUserKampus() {
        const editor = this.quill;
        var selection = editor.getSelection(true);
        editor.insertEmbed(selection.index, 'userkampusembed', {
          key: 'id',
          value: userName,
        });

        editor.setSelection(selection.index + 1, 0);
      },
      image: imageHandler,
    },
  },
  history: {
    delay: 500,
    userOnly: false,
  },
  maxlength: { value: maxLength },
  clipboard: {
    matchVisual: true,
  },
  imageResize: {},
});

// Formats objects for setting up the Quill editor
export const formats = [
  'userkampusembed',
  'header',
  'font',
  // 'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'indent',
  'align',
  'link',
  'image',
  'video',
  'color',
  'background',
  'script',
];

const selectToolbarByType = (type, t) => {
  switch (type) {
    case 'short':
      return (
        <>
          <span className='ql-formats'>
            <button className='ql-bold' />
            <button className='ql-italic' />
            <button className='ql-underline' />
            <button className='ql-strike' />
          </span>
          <span className='ql-formats'>
            <button className='ql-link' />
            <button className='ql-clean' />
          </span>
          <span className='ql-formats kampus-tag-username'>
            <Tooltip
              title={t('userNameTooltip')}
              color='var(--personalColorDark)'
              overlayInnerStyle={{ padding: '1em', textAlign: 'center' }}
              destroyTooltipOnHide
            >
              <button className='ql-username'>{'+ {{ userName }}'}</button>
            </Tooltip>
          </span>
        </>
      );
    default:
      return (
        <>
          <span className='ql-formats'>
            {/* <select className='ql-font' defaultValue='arial'>
              <option value='arial'>Arial</option>
              <option value='comic-sans'>Comic Sans</option>
              <option value='courier-new'>Courier New</option>
              <option value='georgia'>Georgia</option>
              <option value='helvetica'>Helvetica</option>
              <option value='lucida'>Lucida</option>
            </select> */}
            {/* <select className='ql-size' defaultValue='medium'>
              <option value='extra-small'>Size 1</option>
              <option value='small'>Size 2</option>
              <option value='medium'>Size 3</option>
              <option value='large'>Size 4</option>
            </select> */}
            <select className='ql-header long' defaultValue='3'>
              <option value='1'>{t('Heading')}</option>
              <option value='2'>{t('Subheading')}</option>
              <option value='3'>{t('Normal')}</option>
            </select>
          </span>
          <span className='ql-formats'>
            <button className='ql-bold' />
            <button className='ql-italic' />
            <button className='ql-underline' />
            <button className='ql-strike' />
          </span>
          <span className='ql-formats'>
            <button className='ql-list' value='ordered' />
            <select className='ql-align' />
            <select className='ql-color' />
            <select className='ql-background' />
            <button className='ql-indent' value='-1' />
            <button className='ql-indent' value='+1' />
          </span>
          <span className='ql-formats'>
            <button className='ql-script' value='super' />
            <button className='ql-script' value='sub' />
            <button className='ql-blockquote' />
          </span>
          <span className='ql-formats'>
            <button className='ql-link' />
            <button className='ql-image' />
            <button className='ql-video' />
          </span>
        </>
      );
  }
};

// Quill Toolbar component
const QuillToolbar = forwardRef(({ typeToolbar }, ref) => {
  const { t } = useTranslation('quillJS');
  return <div ref={ref}>{selectToolbarByType(typeToolbar, t)}</div>;
});

export default QuillToolbar;
