import {
  GET_REPORT_PDF_FAIL,
  GET_REPORT_PDF_START,
  GET_REPORT_PDF_SUCCESS,
  GET_SCORE_FAIL,
  GET_SCORE_START,
  GET_SCORE_SUCCESS,
  GET_SETTINGSTQ_FAIL,
  GET_SETTINGSTQ_START,
  GET_SETTINGSTQ_SUCCESS,
  POST_SETTINGSTQ_FAIL,
  POST_SETTINGSTQ_START,
  POST_SETTINGSTQ_SUCCESS,
  PUT_SETTINGSTQ_FAIL,
  PUT_SETTINGSTQ_START,
  PUT_SETTINGSTQ_SUCCESS,
} from './constants';

const initialState = {
  settingsTQ: [],
  settingsTQget: {},
  loading: false,
  scores: [],
  student: {},
};

/* eslint-disable */
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_SETTINGSTQ_START:
    case POST_SETTINGSTQ_START:
    case PUT_SETTINGSTQ_START:
    case GET_SCORE_START:
      return { ...state, loading: true };

    case POST_SETTINGSTQ_SUCCESS:
    case PUT_SETTINGSTQ_SUCCESS:
      return { ...state, loading: false, settingsTQ: response };

    case GET_SETTINGSTQ_SUCCESS:
      return { ...state, loading: false, settingsTQget: response };

    case GET_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        scores: response['scores:'],
        student: response['student:'],
      };

    case GET_SETTINGSTQ_FAIL:
    case POST_SETTINGSTQ_FAIL:
    case PUT_SETTINGSTQ_FAIL:
      return { ...state, loading: false };

    case GET_SCORE_FAIL:
      return { ...state, loading: false, scores: [], student: {} };

    case GET_REPORT_PDF_START:
    case GET_REPORT_PDF_SUCCESS:
    case GET_REPORT_PDF_FAIL:
    default:
      return state;
  }
};
