/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { addCompilationCareer, deleteCompilationCareer } from '@/store/compilations/actions';

import { Button } from 'antd';
import { Select } from '@/components/elements';
import { Card } from '@/components/Layout';
import Modal from '@/components/Modal';

import { searchCareer } from '@shared/utils/career-utils';

import PropTypes from 'prop-types';

import css from './index.module.scss';

export class AddCompilationCareerModal extends Component {
  constructor(props) {
    super(props);
    const { career } = props.editable;
    this.state = {
      career: career ? career.id : null,
      editBtnClick: false,
    };
  }

  handleEditClick = () => {
    this.setState({ editBtnClick: true });
    const { career } = this.props.editable;
    if (!career || this.state.career !== career.id) {
      this.props
        .addCompilationCareer({
          id: this.props.editable.id,
          careerId: this.state.career,
        })
        .then(() => {
          this.setState({ editBtnClick: false });
          this.props.toggleModal();
        })
        .catch(error => {
          if (error.action === 403) this.setState({ editBtnClick: false });
        });
    }
  };

  handleChange = event => {
    const { name, value } = event.target || event;
    this.setState({ [name]: value });
  };

  render() {
    const { t } = this.props;
    const { career } = this.props.editable;
    const options = career ? [{ value: career.id, label: career.name }] : [];
    const submitButton = (
      <Button onClick={this.handleEditClick} disabled={this.state.editBtnClick || !this.state.career}>
        {t('edit')}
      </Button>
    );

    return (
      <Modal open={true} onCancel={this.props.toggleModal} hideFooter width={800}>
        <Card className={css['Add-modal']}>
          <div className={css['Add-modal-header']}>{t('editSpecialization')}</div>
          <div className={css['Add-modal-body']}>
            <Select
              name='career'
              allowClear
              showSearch
              placeholder={t('save')}
              onSearch={searchCareer}
              notFoundPlaceholder={t('start')}
              onChange={this.handleChange}
              value={this.state.career}
              options={options}
            />
          </div>
        </Card>
        <Card>{submitButton}</Card>
      </Modal>
    );
  }
}

AddCompilationCareerModal.propTypes = {
  compilation: PropTypes.object,
  isGlobal: PropTypes.bool,
};

const mapActionsToProps = {
  addCompilationCareer,
  deleteCompilationCareer,
};

export default connect(
  null,
  mapActionsToProps
)(withTranslation('addCompilationCareerModal')(AddCompilationCareerModal));
