import {
  GET_TOPIC_TYPE_LABEL_FAIL,
  GET_TOPIC_TYPE_LABEL_START,
  GET_TOPIC_TYPE_LABEL_SUCCESS,
  GET_TYPE_LABEL_FAIL,
  GET_TYPE_LABEL_START,
  GET_TYPE_LABEL_SUCCESS,
} from './constants';

import { isSkills } from '@shared/utils/topic-utils';

const initialState = {
  isLoading: false,
  isError: false,
  typeLabel: [],
  typeTopicLabel: [],
  typeLabelApproved: [],
};

export default (state = initialState, { action, type, response }) => {
  switch (type) {
    case GET_TYPE_LABEL_START:
    case GET_TOPIC_TYPE_LABEL_START:
      return { ...state, isLoading: true };

    case GET_TYPE_LABEL_SUCCESS:
      if (action.approved) {
        return {
          ...state,
          isLoading: false,
          typeLabelApproved: action.isSkills ? response : response.filter(label => !isSkills(label?.topicTypeId)),
        };
      }

      return {
        ...state,
        isLoading: false,
        typeLabel: action.isSkills ? response : response.filter(label => !isSkills(label?.topicTypeId)),
      };

    case GET_TOPIC_TYPE_LABEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        typeTopicLabel: action.isSkills ? response : response.filter(label => !isSkills(label?.topicTypeId)),
      };

    case GET_TYPE_LABEL_FAIL:
    case GET_TOPIC_TYPE_LABEL_FAIL:
      return { ...state, isLoading: false, isError: true };

    default:
      return state;
  }
};
