// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-pB3Qbe.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-aTeeGs .admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-label-DZcGgv[data-value="Montserrat"]:before, .admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-pB3Qbe.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-aTeeGs .admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-item-nXaNNi[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-Montserrat-URvXB2 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main-xsqq6p {
  padding: 20px;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__role-sy0jJ4 {
  background: #fff;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__button-Ein2df {
  width: 200px;
  margin-bottom: 2px;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__input-uU07B8 {
  width: 400px;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__filter-ajqCG3 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__table-iF1cYP {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/AccessSettings/parts/RoleSettings/RoleSettings.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,aAAa;AACf;;AAMA;EAHE,gBAAgB;EAChB,SAAS;EACT,aAAa;EACb,aAAa;AACf;;AAKA;EAFE,YAAY;EACZ,kBAAkB;AACpB;;AAIA;EADE,YAAY;AACd;;AAGA;EAAE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.main {\n  padding: 20px;\n}\n.main__role {\n  display: flex;\n  gap: 20px;\n  padding: 20px;\n  background: white;\n}\n.main__button {\n  width: 200px;\n  margin-bottom: 2px;\n}\n.main__input {\n  width: 400px;\n}\n.main__filter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.main__table {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-pB3Qbe`,
	"qlPicker": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-pB3Qbe`,
	"ql-font": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-aTeeGs`,
	"qlFont": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-aTeeGs`,
	"ql-picker-label": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-label-DZcGgv`,
	"qlPickerLabel": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-label-DZcGgv`,
	"ql-picker-item": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-item-nXaNNi`,
	"qlPickerItem": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-picker-item-nXaNNi`,
	"ql-font-Montserrat": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-Montserrat-URvXB2`,
	"qlFontMontserrat": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__ql-font-Montserrat-URvXB2`,
	"main": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main-xsqq6p`,
	"main__role": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__role-sy0jJ4`,
	"mainRole": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__role-sy0jJ4`,
	"main__button": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__button-Ein2df`,
	"mainButton": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__button-Ein2df`,
	"main__input": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__input-uU07B8`,
	"mainInput": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__input-uU07B8`,
	"main__filter": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__filter-ajqCG3`,
	"mainFilter": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__filter-ajqCG3`,
	"main__table": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__table-iF1cYP`,
	"mainTable": `admin-src-pages-AccessSettings-parts-RoleSettings-RoleSettings-module__main__table-iF1cYP`
};
export default ___CSS_LOADER_EXPORT___;
