/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, Tooltip } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './MassSelect.module.scss';

import { LEN_SHOW_TOOLTIP } from '@/constants/tooltip';

const { Option } = Select;

function MassSelect({ name, options, onChange, value, dataQa, disabled, isTrack, className }) {
  const { t } = useTranslation('massSelection');

  const [focus, setFocus] = useState(value ? !!value.toString().length : false);

  const onSelected = value => {
    setFocus(!value);
    if (value[value.length - 1] === 'all') {
      onChange(name, [...options]);
    } else {
      onChange(name, value);
    }
  };

  const generateOption = () => {
    const arr =
      (options?.length && typeof options[0] === 'object') || name === 'condition' || name === 'valueConcrete'
        ? []
        : [
            <Option className={css['MassSelect__all']} value='all' key='all'>
              {t('addAll')}
            </Option>,
          ];
    options &&
      options.length &&
      options.map((value, index) => {
        if (typeof value === 'object') {
          const name = value.name;
          const showTooltip = name.length > LEN_SHOW_TOOLTIP;

          return arr.push(
            <Option value={value.id} key={value.id}>
              {showTooltip ? (
                <Tooltip title={name} placement='topLeft'>
                  {name}
                </Tooltip>
              ) : (
                name
              )}
            </Option>
          );
        }

        const tValue = t(value);
        const showTooltip = tValue.length > LEN_SHOW_TOOLTIP;

        return arr.push(
          <Option value={value} key={index}>
            {showTooltip ? (
              <Tooltip title={tValue} placement='topLeft'>
                {tValue}
              </Tooltip>
            ) : (
              tValue
            )}
          </Option>
        );
      });

    return arr;
  };

  return (
    <Select
      disabled={disabled}
      data-qa={dataQa}
      placeholder={isTrack ? t('modeTrack') : t(name)}
      // placeholder={t(name)}
      className={classNames(
        className,
        { [css['MassSelect']]: focus },
        { [css['MassSelect--focus']]: focus },
        { [css['MassSelect']]: !focus },
        { [css['MassSelect-margin']]: !value }
      )}
      mode={name === 'customField' || name === 'condition' || name === 'mode' ? null : 'multiple'}
      onSearch={value => setFocus(!!value.length)}
      onChange={onSelected}
      value={value}
      dropdownRender={optionElements => <span data-qa={`${dataQa}-list`}>{optionElements}</span>}
      size='large'
      allowClear
    >
      {generateOption()}
    </Select>
  );
}

MassSelect.propTypes = {
  name: PropTypes.string.isRequired,
  dataQa: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  isTrack: PropTypes.bool,
};

export default MassSelect;
