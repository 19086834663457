export const SAVE_TRACKS_FILTERS = 'SAVE_TRACKS_FILTERS';

export const CLEAR_TRACKS_FILTERS = 'CLEAR_TRACKS_FILTERS';

export const SAVE_TRACKS_APPOINT_FILTERS = 'SAVE_TRACKS_APPOINT_FILTERS';

export const CLEAR_TRACKS_APPOINT_FILTERS = 'CLEAR_TRACKS_APPOINT_FILTERS';

export const CLEAR_TRACK_DETAILS = 'CLEAR_TRACK_DETAILS';

export const GET_CONSECUTIVE_START = 'GET_CONSECUTIVE_START';
export const GET_CONSECUTIVE_SUCCESS = 'GET_CONSECUTIVE_SUCCESS';
export const GET_CONSECUTIVE_FAIL = 'GET_CONSECUTIVE_FAIL';

export const POST_TRACKS_FILTERS_START = 'POST_TRACKS_FILTERS_START';
export const POST_TRACKS_FILTERS_SUCCESS = 'POST_TRACKS_FILTERS_SUCCESS';
export const POST_TRACKS_FILTERS_FAIL = 'POST_TRACKS_FILTERS_FAIL';

export const CREATE_TRACK_START = 'CREATE_TRACK_START';
export const CREATE_TRACK_SUCCESS = 'CREATE_TRACK_SUCCESS';
export const CREATE_TRACK_FAIL = 'CREATE_TRACK_FAIL';

export const DELETE_TRACK_START = 'DELETE_TRACK_START';
export const DELETE_TRACK_SUCCESS = 'DELETE_TRACK_SUCCESS';
export const DELETE_TRACK_FAIL = 'DELETE_TRACK_FAIL';

export const GET_TRACK_ID_START = 'GET_TRACK_ID_START';
export const GET_TRACK_ID_SUCCESS = 'GET_TRACK_ID_SUCCESS';
export const GET_TRACK_ID_FAIL = 'GET_TRACK_ID_FAIL';

export const UPDATE_TRACK_START = 'UPDATE_TRACK_START';
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS';
export const UPDATE_TRACK_FAIL = 'UPDATE_TRACK_FAIL';

export const UPDATE_CONSECUTIVE_START = 'UPDATE_CONSECUTIVE_START';
export const UPDATE_CONSECUTIVE_SUCCESS = 'UPDATE_CONSECUTIVE_SUCCESS';
export const UPDATE_CONSECUTIVE_FAIL = 'UPDATE_CONSECUTIVE_FAIL';

export const POST_APPOINT_FILTERS_START = 'POST_APPOINT_FILTERS_START';
export const POST_APPOINT_FILTERS_SUCCESS = 'POST_APPOINT_FILTERS_SUCCESS';
export const POST_APPOINT_FILTERS_FAIL = 'POST_APPOINT_FILTERS_FAIL';

export const ADD_MASS_TRACK_START = 'ADD_MASS_TRACK_START';
export const ADD_MASS_TRACK_SUCCESS = 'ADD_MASS_TRACK_SUCCESS';
export const ADD_MASS_TRACK_FAIL = 'ADD_MASS_TRACK_FAIL';

export const ADD_TRACK_START = 'ADD_TRACK_START';
export const ADD_TRACK_SUCCESS = 'ADD_TRACK_SUCCESS';
export const ADD_TRACK_FAIL = 'ADD_TRACK_FAIL';

export const GET_TRACK_COUNT_START = 'GET_TRACK_COUNT_START';
export const GET_TRACK_COUNT_SUCCESS = 'GET_TRACK_COUNT_SUCCESS';
export const GET_TRACK_COUNT_FAIL = 'GET_TRACK_COUNT_FAIL';

export const EXPORT_TRACK_USERS_START = 'EXPORT_TRACK_USERS_START';
export const EXPORT_TRACK_USERS_SUCCESS = 'EXPORT_TRACK_USERS_SUCCESS';
export const EXPORT_TRACK_USERS_FAIL = 'EXPORT_TRACK_USERS_FAIL';

export const DELETE_USER_TRACK_START = 'DELETE_USER_TRACK_START';
export const DELETE_USER_TRACK_SUCCESS = 'DELETE_USER_TRACK_SUCCESS';
export const DELETE_USER_TRACK_FAIL = 'DELETE_USER_TRACK_FAIL';

export const TRANSFER_USER_TRACK_START = 'TRANSFER_USER_TRACK_START';
export const TRANSFER_USER_TRACK_SUCCESS = 'TRANSFER_USER_TRACK_SUCCESS';
export const TRANSFER_USER_TRACK_FAIL = 'TRANSFER_USER_TRACK_FAIL';

export const EXPORT_USERS_MASS_TRACK_START = 'EXPORT_USERS_MASS_TRACK_START';
export const EXPORT_USERS_MASS_TRACK_SUCCESS = 'EXPORT_USERS_MASS_TRACK_SUCCESS';
export const EXPORT_USERS_MASS_TRACK_FAIL = 'EXPORT_USERS_MASS_TRACK_FAIL';

export const GET_STATUS_TRACK_START = 'GET_STATUS_TRACK_START';
export const GET_STATUS_TRACK_SUCCESS = 'GET_STATUS_TRACK_SUCCESS';
export const GET_STATUS_TRACK_FAIL = 'GET_STATUS_TRACK_FAIL';

export const GET_PROGRESS_TRACK_START = 'GET_PROGRESS_TRACK_START';
export const GET_PROGRESS_TRACK_SUCCESS = 'GET_PROGRESS_TRACK_SUCCESS';
export const GET_PROGRESS_TRACK_FAIL = 'GET_PROGRESS_TRACK_FAIL';

export const CHANGE_STATUS_TRACK_START = 'CHANGE_STATUS_TRACK_START';
export const CHANGE_STATUS_TRACK_SUCCESS = 'CHANGE_STATUS_TRACK_SUCCESS';
export const CHANGE_STATUS_TRACK_FAIL = 'CHANGE_STATUS_TRACK_FAIL';

export const GET_TRACK_STAT_USER_START = 'GET_TRACK_STAT_USER_START';
export const GET_TRACK_STAT_USER_SUCCESS = 'GET_TRACK_STAT_USER_SUCCESS';
export const GET_TRACK_STAT_USER_FAIL = 'GET_TRACK_STAT_USER_FAIL';
