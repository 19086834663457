/* eslint-disable */
import { D360_GET_PROJECT_FAIL, D360_GET_PROJECT_START, D360_GET_PROJECT_SUCCESS } from './constants';

const initialState = {
  loading: false,
  project: undefined,
  status: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // Request start
    case D360_GET_PROJECT_START:
      return { ...state, loading: true };

    // Request fail
    case D360_GET_PROJECT_FAIL:
      return { ...state, loading: false };

    // Get All Projects
    case D360_GET_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: response.data,
      };

    // Default
    default:
      return state;
  }
};
