import {
  CREATE_WEBINAR_CONVERT_FAIL,
  CREATE_WEBINAR_CONVERT_START,
  CREATE_WEBINAR_CONVERT_SUCCESS,
  CREATE_WEBINAR_FAIL,
  CREATE_WEBINAR_REGISTER_FAIL,
  CREATE_WEBINAR_REGISTER_START,
  CREATE_WEBINAR_REGISTER_SUCCESS,
  CREATE_WEBINAR_START,
  CREATE_WEBINAR_SUCCESS,
  GET_WEBINAR_CHECK_FAIL,
  GET_WEBINAR_CHECK_START,
  GET_WEBINAR_CHECK_SUCCESS,
  GET_WEBINAR_RECORD_FAIL,
  GET_WEBINAR_RECORD_START,
  GET_WEBINAR_RECORD_SUCCESS,
  GET_WEBINAR_STATS_FAIL,
  GET_WEBINAR_STATS_START,
  GET_WEBINAR_STATS_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import dayjs from 'dayjs';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getWebinarStats = (data, page = 0) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/integration/platform/webinar/stat${getQueryString({
    eventId: data.eventId,
    from: dayjs(data.from).format('YYYY-MM-DD[T]00:00:00'), // Костыль из за бэка вебинара
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_WEBINAR_STATS_START, GET_WEBINAR_STATS_SUCCESS, GET_WEBINAR_STATS_FAIL],
});

export const webinarRegister = (eventSessionId, userId) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/integration/platform/webinar/register`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    eventSessionId,
    userId,
  },
  types: [CREATE_WEBINAR_REGISTER_START, CREATE_WEBINAR_REGISTER_SUCCESS, CREATE_WEBINAR_REGISTER_FAIL],
});

export const getWebinarRecord = eventSessionId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/integration/platform/webinar/register${getQueryString({ eventSessionId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_WEBINAR_RECORD_START, GET_WEBINAR_RECORD_SUCCESS, GET_WEBINAR_RECORD_FAIL],
});

export const createWebinar = requestDto => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/integration/platform/webinar/register`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    requestDto,
  },
  types: [CREATE_WEBINAR_START, CREATE_WEBINAR_SUCCESS, CREATE_WEBINAR_FAIL],
});

// Поставить вебинар на запись
export const webinarConvert = eventSessionId => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/integration/platform/webinar/register`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    eventSessionId,
    quality: 'FULL_HD',
    view: 'CHAT',
  },
  types: [CREATE_WEBINAR_CONVERT_START, CREATE_WEBINAR_CONVERT_SUCCESS, CREATE_WEBINAR_CONVERT_FAIL],
});

// Статус записи
export const getWebinarCheck = conversionId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/integration/platform/webinar/register${getQueryString({ conversionId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_WEBINAR_CHECK_START, GET_WEBINAR_CHECK_SUCCESS, GET_WEBINAR_CHECK_FAIL],
});
