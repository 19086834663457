import { CalendarOutlined } from '@ant-design/icons';

const EventSvg = props => (
  <div>
    <CalendarOutlined style={{ fontSize: '12px' }} />
    {props.name}
  </div>
);

export default EventSvg;
