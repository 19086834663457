/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';
import { createAsyncAction } from '@shared/utils/redux-helpers';

import { headersForImport } from '@/constants/global';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_ADMIN_START = 'GET_ADMIN_START';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAIL = 'GET_ADMIN_FAIL';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const CHANGE_NOTIFICATION_USER_START = 'CHANGE_NOTIFICATION_USER_START';
export const CHANGE_NOTIFICATION_USER_SUCCESS = 'CHANGE_NOTIFICATION_USER_SUCCESS';
export const CHANGE_NOTIFICATION_USER_FAIL = 'CHANGE_NOTIFICATION_USER_FAIL';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const POST_IMPORT_USER_START = 'POST_IMPORT_USER_START';
export const POST_IMPORT_USER_SUCCESS = 'POST_IMPORT_USER_SUCCESS';
export const POST_IMPORT_USER_FAIL = 'POST_IMPORT_USER_FAIL';

export const GET_SUBORDINATES_START = 'GET_SUBORDINATES_START';
export const GET_SUBORDINATES_SUCCESS = 'GET_SUBORDINATES_SUCCESS';
export const GET_SUBORDINATES_FAIL = 'GET_SUBORDINATES_FAIL';
export const GET_USER_STAT_START = 'GET_USER_STAT_START';
export const GET_USER_STAT_SUCCESS = 'GET_USER_STAT_SUCCESS';
export const GET_USER_STAT_FAIL = 'GET_USER_STAT_FAIL';

export const GET_USERRESULTCSV_START = 'GET_USERRESULTCSV_START';
export const GET_USERRESULTCSV_SUCCESS = 'GET_USERRESULTCSV_SUCCESS';
export const GET_USERRESULTCSV_FAIL = 'GET_USERRESULTCSV_FAIL';

export const GET_DIAGNOSTIC_RESULT_START = 'GET_DIAGNOSTIC_RESULT_START';
export const GET_DIAGNOSTIC_RESULT_SUCCESS = 'GET_DIAGNOSTIC_RESULT_SUCCESS';
export const GET_DIAGNOSTIC_RESULT_FAIL = 'GET_DIAGNOSTIC_RESULT_FAIL';

export const LOGIN_REDIRECT_START = 'LOGIN_REDIRECT_START';
export const LOGIN_REDIRECT_SUCCESS = 'LOGIN_REDIRECT_SUCCESS';
export const LOGIN_REDIRECT_FAIL = 'LOGIN_REDIRECT_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getUserStat = (userId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${userId}/stat`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_STAT_START, GET_USER_STAT_SUCCESS, GET_USER_STAT_FAIL],
  onSuccess,
  onError,
});

export const getUser = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_START, GET_USER_SUCCESS, GET_USER_FAIL],
  onSuccess,
  onError,
});

export const getUsers = (params, onSuccess, onError, searching, isStartLoad) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({ sort: 'lastName,asc', ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USERS_START, GET_USERS_SUCCESS, GET_USERS_FAIL],
  searchingStr: searching,
  isStartLoad,
  onSuccess,
  onError,
});

export const getRoles = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users/roles`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ROLES_START, GET_ROLES_SUCCESS, GET_ROLES_FAIL],
  onSuccess,
  onError,
});

export const getAdmin = (values, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/domain/user`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ADMIN_START, GET_ADMIN_SUCCESS, GET_ADMIN_FAIL],
  onSuccess,
  onError,
  values,
});

export const changeEmailNotification = (notifyEnable, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: notifyEnable,
  types: [CHANGE_NOTIFICATION_USER_START, CHANGE_NOTIFICATION_USER_SUCCESS, CHANGE_NOTIFICATION_USER_FAIL],
  onSuccess,
  onError,
});

export const importUsers = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/register/import`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_IMPORT_USER_START, POST_IMPORT_USER_SUCCESS, POST_IMPORT_USER_FAIL],
});

export const changeUserPassword = (password, username, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/domain/user`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    firstName: username.firstName,
    login: username.login,
    password,
    photoUuid: username.photoUuid,
    secondName: username.secondName,
  },
  responseType: 'text',
  types: [EDIT_USER_START, EDIT_USER_SUCCESS, EDIT_USER_FAIL],
  onSuccess,
});

export const getDiagnosticResultsCSV = (data, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/trajectory/conventus/result${getQueryString(data)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DIAGNOSTIC_RESULT_START, GET_DIAGNOSTIC_RESULT_SUCCESS, GET_DIAGNOSTIC_RESULT_FAIL],
  requestType: 'formData',
  responseType: 'blob',
  onSuccess,
  onError,
});

export const getUserResultCSV = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/block/result/export?userId=${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USERRESULTCSV_START, GET_USERRESULTCSV_SUCCESS, GET_USERRESULTCSV_FAIL],
  onSuccess,
  onError,
});

export const loginRedirect = (username, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/login/redirect`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { username },
  onSuccess,
  types: [LOGIN_REDIRECT_START, LOGIN_REDIRECT_SUCCESS, LOGIN_REDIRECT_FAIL],
});

export const getUserDomainCompanyImages = createAsyncAction('getUserDomainCompanyImages');
