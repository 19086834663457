export const extractUsername = object => {
  let username = '';
  if (object) {
    if (object.last_name && object.last_name !== null) {
      username += object.last_name;
    }

    if (object.first_name && object.first_name !== null) {
      username += ' ' + object.first_name.substring(0, 1).toUpperCase() + '.';
    }

    if (object.middle_name && object.middle_name !== null) {
      username += ' ' + object.middle_name.substring(0, 1).toUpperCase() + '.';
    }
  }

  return username;
};

/* eslint-disable */
export default {
  extractUsername,
};
