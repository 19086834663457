/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getStudents } from '@/store/students/actions';
import { checkWaitingListAction, clearWaitingList, getWaitingListStatuses } from '@/store/waiting-list/actions';

import { selectCourses as coursesSelect } from '@/store/courses/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectWaitingList } from '@/store/waiting-list/selectors';

import { Button, DatePicker, Form, Modal, Select, Table, Typography } from 'antd';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import style from '../TopicDetailsDialog.module.scss';

const { RangePicker } = DatePicker;

const initWaitingData = {
  courseEventId: undefined,
  department: undefined,
  status: undefined,
  position: undefined,
  addedDateStart: undefined,
  addedDateEnd: undefined,
};

const DATE_FORMAT = 'DD.MM.YYYY';
const WaitingListModal = ({ open, onCancel, topicId }) => {
  const { t } = useTranslation('waitingList');
  const dispatch = useDispatch();

  const { position, department, students } = useSelector(selectStudents);
  const { eventList } = useSelector(coursesSelect);
  const { waitingList, isLoading, waitingStatuses, waitingListTotal, waitingListPageSize, waitingListPageNumber } =
    useSelector(selectWaitingList);

  const [sendWaitingData, setSendWaitingData] = useState(initWaitingData);

  const [form] = Form.useForm();

  useEffect(() => {
    if (topicId && open) {
      dispatch(checkWaitingListAction(topicId, sendWaitingData));
      if (!waitingStatuses) {
        dispatch(getWaitingListStatuses());
      }
    }
  }, [sendWaitingData, open]);

  const onSuccess = data => {
    setSendWaitingData({
      courseEventId: data.courseEventId?.length > 0 ? data.courseEventId : undefined,
      department: data.department?.length > 0 ? data.department : undefined,
      status: data.status?.length > 0 ? data.status : undefined,
      position: data.position?.length > 0 ? data.position : undefined,
      addedDateStart: data.addedDate?.[0] && dayjs(data.addedDate?.[0]).format('YYYY-MM-DD'),
      addedDateEnd: data.addedDate?.[1] && dayjs(data.addedDate?.[1]).format('YYYY-MM-DD'),
      userId: data?.userId,
    });
  };

  const onCancelWaitingModal = () => {
    dispatch(clearWaitingList());
    onCancel();
  };

  const waitingStatusOptions = waitingStatuses?.map(item => ({
    value: item,
    label: item === 'ON_WAITING_LIST' ? t('waitingList') : item === 'INTERESTED' ? t('showInterest') : item,
  }));

  const columns = [
    {
      title: t('tableColumns.0'),
      dataIndex: 'courseEventId',
      key: 'courseEventId',
    },
    {
      title: t('tableColumns.1'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('tableColumns.2'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('tableColumns.3'),
      dataIndex: 'addedDate',
      key: 'addedDate',
    },
  ];

  const userStatus = status => {
    switch (status) {
      case 'INTERESTED':
        return t('showInterest');
      case 'ON_WAITING_LIST':
        return t('waitUser');
      case 'REGISTERED':
        return t('registered');
      default:
        return null;
    }
  };

  const tableDataRender = () => {
    return waitingList?.map(item => {
      return {
        courseEventId: item?.courseEventId,
        name: [item.user?.middleName, item.user.firstName, item.user.lastName].join(' ').trim(),
        status: userStatus(item?.status),
        addedDate: item.addedDate && dayjs(item.addedDate).format(DATE_FORMAT),
      };
    });
  };

  const pagination = {
    total: waitingListTotal,
    current: waitingListPageNumber + 1,
    pageSize: waitingListPageSize,
    hideOnSinglePage: true,
  };

  const onChangeTable = pagination => {
    dispatch(checkWaitingListAction(topicId, sendWaitingData, pagination.current - 1));
  };

  const resetFilter = () => {
    form.resetFields();
    setSendWaitingData(initWaitingData);
  };

  const optionUsers = useMemo(
    () =>
      students?.length &&
      students.map(item => {
        const { lastName, firstName, middleName, id } = item;
        let username = `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
        return { label: username, value: id, key: id };
      }),
    [students]
  );

  const handleGetUsers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudents({ fullName: e, size: 50 }));
    }
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetUsers, 1000), []);

  return (
    <Modal open={open} onCancel={onCancelWaitingModal} footer={null} title={t('title')} width={1000}>
      <Typography.Title level={3}>{t('filter')}</Typography.Title>
      <Form form={form} onFinish={onSuccess} layout='vertical' className={style['waiting-list__filter']}>
        <Form.Item className={style['waiting-list__label']} label={t('filterLabels.0')} name='courseEventId'>
          <Select
            options={eventList?.map((item, index) => ({
              value: item.id,
              key: index,
              label: `${item.id} ${dayjs(item.startDate).format(DATE_FORMAT)}`,
            }))}
            showSearch
            placeholder={t('filterPlaceholders.0')}
            mode='multiple'
          />
        </Form.Item>
        <Form.Item className={style['waiting-list__label']} label={t('fullName')} name='userId'>
          <Select
            options={optionUsers}
            placeholder={t('placehodlerFullname')}
            onSearch={getStudentsListDebounced}
            showSearch
            allowClear
            optionFilterProp='label'
          />
        </Form.Item>
        <Form.Item className={style['waiting-list__label']} label={t('filterLabels.3')} name='position'>
          <Select
            options={position?.map((item, index) => ({ value: item, key: index, label: item }))}
            placeholder={t('filterPlaceholders.3')}
            mode='multiple'
          />
        </Form.Item>
        <Form.Item className={style['waiting-list__label']} label={t('filterLabels.1')} name='department'>
          <Select
            options={department?.map((item, index) => ({ value: item, key: index, label: item }))}
            placeholder={t('filterPlaceholders.1')}
            mode='multiple'
          />
        </Form.Item>
        <Form.Item className={style['waiting-list__label']} label={t('filterLabels.2')} name='status'>
          <Select placeholder={t('filterPlaceholders.2')} options={waitingStatusOptions} mode='multiple' />
        </Form.Item>

        <Form.Item className={style['waiting-list__label']} label={t('filterLabels.4')} name='addedDate'>
          <RangePicker allowClear={false} className={style['waiting-list__element']} />
        </Form.Item>
        <div className={style['waiting-list__buttons']}>
          <Button onClick={resetFilter}>{t('buttons.0')}</Button>
          <Button type='primary' htmlType='submit'>
            {t('buttons.1')}
          </Button>
        </div>
      </Form>

      <Table
        columns={columns}
        dataSource={tableDataRender()}
        loading={isLoading}
        rowKey={rec => rec.courseEventId + rec.name}
        onChange={onChangeTable}
        pagination={pagination}
      />
    </Modal>
  );
};

WaitingListModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  topic: PropTypes.object,
};

export default WaitingListModal;
