/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row } from 'antd';
import { HiOutlineCursorClick, HiOutlineUser, HiOutlineUsers } from 'react-icons/hi';
import { RiCalendar2Line, RiCalendarTodoFill, RiUserStarFill, RiUserVoiceFill } from 'react-icons/ri';

import { PROJECT_STATUS_UNKNOWN } from '../../../constants';
import { extractUsername } from '../../../utils';
import ProjectDate from '../../Projects/parts/ProjectDate';
import ProjectStatus from '../../Projects/parts/ProjectStatus';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

/**
 * Компонент выводит детальную информацию по проекту.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProjectDetails = props => {
  /**
   * Имя автора проекта.
   */
  const [author, setAuthor] = useState(null);

  /**
   * Имя со-автора проекта.
   */
  const [coAuthor, setCoAuthor] = useState(null);

  /**
   * Дата создания проекта.
   */
  const [created, setCreated] = useState(null);

  /**
   * Дата обновления проекта.
   */
  const [updated, setUpdated] = useState(null);

  /**
   * Текущий идентификатор статуса проекта.
   */
  const [statusId, setStatusId] = useState(PROJECT_STATUS_UNKNOWN);

  /**
   * Дата начала проекта.
   */
  const [start, setStart] = useState(null);

  /**
   * Дата завершения проекта.
   */
  const [finish, setFinish] = useState(null);

  /**
   * Кол-во респондентов.
   */
  const [respondentCount, setRespondentCount] = useState(null);

  /**
   * Кол-во оцениваемых.
   */
  const [measurableCount, setMeasurableCount] = useState(null);

  const { t } = useTranslation('D360_Info');

  /**
   * Загрузка данных.
   */
  useEffect(() => {
    if (props && props.project) {
      setAuthor(extractUsername(props.project.author));
      setCoAuthor(extractUsername(props.project.coauthor));
      setCreated(props.project.created_at);
      setUpdated(props.project.updated_at);
      setStatusId(props.project.d360_status_id);
      setStart(props.project.start);
      setFinish(props.project.finish);
      setRespondentCount(props.project.respondentCount);
      setMeasurableCount(props.project.measurableCount);
    }
  }, [props.project]);

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.ProjectDetails}>
        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <HiOutlineUser />
            &nbsp;{t('projectAuthor')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            {author}
          </Col>
        </Row>

        {coAuthor && (
          <Row className={css.ProjectDetailsRow}>
            <Col span={12} className={css.ProjectDetailsColKey}>
              <HiOutlineUsers />
              &nbsp;{t('projectCoAuthor')}
            </Col>
            <Col span={12} className={css.ProjectDetailsColValue}>
              {coAuthor}
            </Col>
          </Row>
        )}

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <RiCalendar2Line />
            &nbsp;{t('projectCreated')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            <ProjectDate dateString={created} />
          </Col>
        </Row>

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <RiCalendarTodoFill />
            &nbsp;{t('projectUpdated')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            <ProjectDate dateString={updated} />
          </Col>
        </Row>

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <HiOutlineCursorClick />
            &nbsp;{t('projectStatus')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            <ProjectStatus statusId={statusId} />
          </Col>
        </Row>

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <RiCalendar2Line />
            &nbsp;{t('projectStart')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            <ProjectDate dateString={start} />
          </Col>
        </Row>

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <RiCalendarTodoFill />
            &nbsp;{t('projectEnd')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            <ProjectDate dateString={finish} />
          </Col>
        </Row>

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <RiUserVoiceFill />
            &nbsp;{t('respondentCount')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            {respondentCount}
          </Col>
        </Row>

        <Row className={css.ProjectDetailsRow}>
          <Col span={12} className={css.ProjectDetailsColKey}>
            <RiUserStarFill />
            &nbsp;{t('measurableCount')}
          </Col>
          <Col span={12} className={css.ProjectDetailsColValue}>
            {measurableCount}
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Запуск компонента.
   */
  return render();
};

ProjectDetails.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectDetails;
