/* eslint-disable */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form, Input, Pagination } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { Card, Content } from '@/components/Layout';

import SkillsCreationDialog from './SkillsCreationDialog';
import SkillsItem from './SkillsItem';

import debounce from '@shared/utils/debounce';

import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { SKILL_CREATE } from '@/constants/permissions';

export class SkillsTable extends Component {
  static propTypes = {
    createSkills: PropTypes.func,
    creationError: PropTypes.bool,
    deleteSkills: PropTypes.func,
    editSkills: PropTypes.func,
    editingError: PropTypes.bool,
    getSkills: PropTypes.func,
    isLoading: PropTypes.bool,
    skills: PropTypes.array,
    skillsPageNumber: PropTypes.number,
    skillsPageSize: PropTypes.number,
    skillsTotal: PropTypes.number,
    isGlobal: PropTypes.bool,
    shared: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchName: '',
      page: 1,
    };
    this.getSkillsDebounced = debounce(props.getSkills, 1000);
    this.props.getSkills('', 0, null, props.isGlobal);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchString !== this.state.searchString) {
      this.getSkillsDebounced(
        this.state.searchString,
        0,
        () => {
          this.setState({ searchName: this.state.searchString });
        },
        this.props.isGlobal
      );
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changePage = page => {
    this.setState({ page });
    this.props.getSkills(this.state.searchString, page - 1, null, this.props.isGlobal);
  };

  showDeleteConfirmation = skills => this.setState({ deleteConfirmationVisible: true, activeSkills: skills });

  hideDeleteConfirmation = () => this.setState({ deleteConfirmationVisible: false });

  handleTagDeletion = () =>
    this.props.deleteSkills(this.state.activeSkills && this.state.activeSkills.id, {
      onSuccess: () => {
        this.hideDeleteConfirmation();
        this.props.getSkills(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
      },
      onError: this.hideDeleteConfirmation,
    });

  showTagCreationDialog = () => this.setState({ skillsCreationVisible: true });

  hideTagCreationDialog = () => this.setState({ skillsCreationVisible: false });

  handleTagCreation = skills => {
    this.props.createSkills(
      skills,
      () => {
        this.hideTagCreationDialog();
        this.props.getSkills(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
      },
      error => {
        console.error('Error', error);
      }
    );
  };

  showTagEditingDialog = skills => this.setState({ skillsEditingVisible: true, activeSkills: skills });

  hideTagEditingDialog = () => this.setState({ skillsEditingVisible: false, activeSkills: null });

  handleTagEditing = name => {
    this.props.editSkills(name, this.state.activeSkills && this.state.activeSkills.id, () => {
      this.hideTagEditingDialog();
      this.props.getSkills(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
    });
  };

  render() {
    const { skills, skillsPageSize, skillsTotal, skillsPageNumber, creationError, editingError, shared, t } =
      this.props;
    const label = shared ? t('titleGlobal') : t('title');
    const skillsItems = skills.map(skill => (
      <SkillsItem
        key={skill.id}
        skills={skill}
        onEdit={this.showTagEditingDialog}
        onDelete={this.showDeleteConfirmation}
        shared={shared}
        currentUser={this.props.currentUser}
        t={t}
      />
    ));
    const pagination =
      skillsPageSize && skillsTotal && skillsTotal > skillsPageSize ? (
        <Pagination
          className={css['pagination']}
          current={skillsPageNumber + 1}
          total={skillsTotal}
          pageSize={skillsPageSize}
          onChange={this.changePage}
          showSizeChanger={false}
          responsive={true}
          hideOnSinglePage={true}
        />
      ) : null;

    return (
      <Content className={css.TagDirectory}>
        <h1>{label}</h1>
        <div className={css['TagItem-container']}>
          <Form className={css['TagItem-form']}>
            <Form.Item
              label={t('search')}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              className={css['TagItem-form-search']}
            >
              <Input
                placeholder={t('name')}
                dataqa='searchSkillInput'
                name='searchString'
                value={this.state.searchString}
                onChange={this.handleChange}
              />
            </Form.Item>
          </Form>
          {!shared && this.props.currentUser.authorities.includes(SKILL_CREATE) && (
            <Button
              type='primary'
              className={css['TagItem-button']}
              data-qa='createSkillBtn'
              onClick={this.showTagCreationDialog}
            >
              {t('addName')}
            </Button>
          )}
        </div>
        <Card>
          {skillsItems && skillsItems.length ? (
            skillsItems
          ) : !this.props.isLoading && this.state.searchString ? (
            <div className={css['TagItem-noFound']}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('findError', { searchName: this.state.searchName }),
                }}
              />
            </div>
          ) : null}
          {pagination}
        </Card>
        {this.state.skillsCreationVisible && !shared ? (
          <SkillsCreationDialog
            open
            onOk={this.handleTagCreation}
            onCancel={this.hideTagCreationDialog}
            title={t('addName')}
            descriptionText={t('newOne')}
            okText={t('add')}
            error={creationError}
            errorText={t('exist')}
            require={t('require')}
            minLength={t('minLength')}
            maxLength={t('maxLength')}
          />
        ) : null}
        {this.state.skillsEditingVisible && !shared ? (
          <SkillsCreationDialog
            open
            onOk={this.handleTagEditing}
            onCancel={this.hideTagEditingDialog}
            title={t('editName')}
            descriptionText={t('newOne')}
            okText={t('edit')}
            error={editingError}
            errorText={t('exist')}
            skills={this.state.activeSkills && this.state.activeSkills.name}
            minLength={t('minLength')}
            maxLength={t('maxLength')}
          />
        ) : null}
        <ConfirmationModal
          open={this.state.deleteConfirmationVisible}
          onOk={this.handleTagDeletion}
          onCancel={this.hideDeleteConfirmation}
          title={t('deleteName')}
          okText={t('delete')}
          confirmationText={t('confirm')}
        />
      </Content>
    );
  }
}

export default withTranslation('skillsTable')(SkillsTable);
