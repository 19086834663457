/* eslint-disable */
import {
  ADD_MASS_TRACK_FAIL,
  ADD_MASS_TRACK_START,
  ADD_MASS_TRACK_SUCCESS,
  ADD_TRACK_FAIL,
  ADD_TRACK_START,
  ADD_TRACK_SUCCESS,
  CHANGE_STATUS_TRACK_FAIL,
  CHANGE_STATUS_TRACK_START,
  CHANGE_STATUS_TRACK_SUCCESS,
  CLEAR_TRACK_DETAILS,
  CLEAR_TRACKS_APPOINT_FILTERS,
  CLEAR_TRACKS_FILTERS,
  CREATE_TRACK_FAIL,
  CREATE_TRACK_START,
  CREATE_TRACK_SUCCESS,
  DELETE_TRACK_FAIL,
  DELETE_TRACK_START,
  DELETE_TRACK_SUCCESS,
  DELETE_USER_TRACK_FAIL,
  DELETE_USER_TRACK_START,
  DELETE_USER_TRACK_SUCCESS,
  EXPORT_TRACK_USERS_FAIL,
  EXPORT_TRACK_USERS_START,
  EXPORT_TRACK_USERS_SUCCESS,
  EXPORT_USERS_MASS_TRACK_FAIL,
  EXPORT_USERS_MASS_TRACK_START,
  EXPORT_USERS_MASS_TRACK_SUCCESS,
  GET_CONSECUTIVE_FAIL,
  GET_CONSECUTIVE_START,
  GET_CONSECUTIVE_SUCCESS,
  GET_PROGRESS_TRACK_FAIL,
  GET_PROGRESS_TRACK_START,
  GET_PROGRESS_TRACK_SUCCESS,
  GET_STATUS_TRACK_FAIL,
  GET_STATUS_TRACK_START,
  GET_STATUS_TRACK_SUCCESS,
  GET_TRACK_COUNT_FAIL,
  GET_TRACK_COUNT_START,
  GET_TRACK_COUNT_SUCCESS,
  GET_TRACK_ID_FAIL,
  GET_TRACK_ID_START,
  GET_TRACK_ID_SUCCESS,
  GET_TRACK_STAT_USER_FAIL,
  GET_TRACK_STAT_USER_START,
  GET_TRACK_STAT_USER_SUCCESS,
  POST_APPOINT_FILTERS_FAIL,
  POST_APPOINT_FILTERS_START,
  POST_APPOINT_FILTERS_SUCCESS,
  POST_TRACKS_FILTERS_FAIL,
  POST_TRACKS_FILTERS_START,
  POST_TRACKS_FILTERS_SUCCESS,
  SAVE_TRACKS_APPOINT_FILTERS,
  SAVE_TRACKS_FILTERS,
  TRANSFER_USER_TRACK_FAIL,
  TRANSFER_USER_TRACK_START,
  TRANSFER_USER_TRACK_SUCCESS,
  UPDATE_CONSECUTIVE_FAIL,
  UPDATE_CONSECUTIVE_START,
  UPDATE_CONSECUTIVE_SUCCESS,
  UPDATE_TRACK_FAIL,
  UPDATE_TRACK_START,
  UPDATE_TRACK_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  tracksFilters: [],
  tracksLoading: false,
  tracksTable: null,
  tracksTotal: null,
  tracksPageSize: null,
  tracksPageNumber: null,
  tracksCurrentPage: null,
  consecutive: [],
  trackDetails: {},
  appointLoading: false,
  appointTable: [],
  appointTotal: null,
  appointPageSize: null,
  appointPageNumber: null,
  appointCurrentPage: null,
  trackCount: null,
  isLoadingExportTrack: false,
  appointFilters: {},
  trackDetailLoading: false,
  statuses: [],
  progress: [],
  isProgress: false,
  isChangeStatus: false,
  isUserStat: false,
  userStats: [],
  userStatsTotal: null,
  userStatsPageSize: null,
  userStatsPageNumber: null,
  userStatsCurrentPage: null,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_CONSECUTIVE_START:
    case CREATE_TRACK_START:
    case DELETE_TRACK_START:
    case UPDATE_TRACK_START:
    case UPDATE_CONSECUTIVE_START:
    case ADD_MASS_TRACK_START:
    case ADD_TRACK_START:
    case GET_TRACK_COUNT_START:
    case DELETE_USER_TRACK_START:
    case TRANSFER_USER_TRACK_START:
    case EXPORT_USERS_MASS_TRACK_START:
    case GET_STATUS_TRACK_START:
      return { ...state, isLoading: true };

    case GET_TRACK_STAT_USER_START:
      return { ...state, isUserStat: true };

    case CHANGE_STATUS_TRACK_START:
      return { ...state, isChangeStatus: true };

    case GET_TRACK_ID_START:
      return { ...state, isLoading: true, trackDetailLoading: true };

    case GET_PROGRESS_TRACK_START:
      return { ...state, isProgress: true };

    case EXPORT_TRACK_USERS_START:
      return { ...state, isLoadingExportTrack: true };

    case UPDATE_CONSECUTIVE_SUCCESS:
      return { ...state };

    case POST_TRACKS_FILTERS_START:
      return { ...state, isLoading: true, tracksLoading: true };

    case POST_APPOINT_FILTERS_START:
      return { ...state, isLoading: true, appointLoading: true };

    case GET_CONSECUTIVE_SUCCESS:
      return { ...state, isLoading: false, consecutive: response };

    case CREATE_TRACK_SUCCESS:
    case DELETE_TRACK_SUCCESS:
    case UPDATE_TRACK_SUCCESS:
    case ADD_MASS_TRACK_SUCCESS:
    case ADD_TRACK_SUCCESS:
    case DELETE_USER_TRACK_SUCCESS:
    case EXPORT_USERS_MASS_TRACK_SUCCESS:
      return { ...state, isLoading: false };

    case CHANGE_STATUS_TRACK_SUCCESS:
      return { ...state, isChangeStatus: false };

    case TRANSFER_USER_TRACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        appointTable: state.appointTable.map(i => {
          // подменяю старую запись на новую из бэка
          if (i.user.id === response.user.id && i.trackId === response.trackId) {
            return response;
          } else {
            return i;
          }
        }),
      };

    case GET_TRACK_ID_SUCCESS:
      return { ...state, isLoading: false, trackDetails: response, trackDetailLoading: false };

    case GET_TRACK_COUNT_SUCCESS:
      return { ...state, isLoading: false, trackCount: response };

    case GET_STATUS_TRACK_SUCCESS:
      return { ...state, isLoading: false, statuses: response };

    case GET_PROGRESS_TRACK_SUCCESS:
      return { ...state, isProgress: false, progress: response };

    case GET_TRACK_STAT_USER_SUCCESS:
      return {
        ...state,
        isUserStat: false,
        userStats: response.content,
        userStatsTotal: response.totalElements,
        userStatsPageSize: response.size,
        userStatsPageNumber: response.totalPages,
        userStatsCurrentPage: response.number,
      };

    case POST_TRACKS_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tracksLoading: false,
        tracksTable: response.content,
        tracksTotal: response.totalElements,
        tracksPageSize: response.size,
        tracksPageNumber: response.totalPages,
        tracksCurrentPage: response.number,
      };

    case POST_APPOINT_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        appointLoading: false,
        appointTable: response.content,
        appointTotal: response.totalElements,
        appointPageSize: response.size,
        appointPageNumber: response.totalPages,
        appointCurrentPage: response.number,
      };

    case EXPORT_TRACK_USERS_SUCCESS:
      return { ...state, error: false, isLoadingExportTrack: false };

    case GET_CONSECUTIVE_FAIL:
    case CREATE_TRACK_FAIL:
    case DELETE_TRACK_FAIL:
    case UPDATE_TRACK_FAIL:
    case UPDATE_CONSECUTIVE_FAIL:
    case ADD_MASS_TRACK_FAIL:
    case ADD_TRACK_FAIL:
    case GET_TRACK_COUNT_FAIL:
    case DELETE_USER_TRACK_FAIL:
    case TRANSFER_USER_TRACK_FAIL:
    case EXPORT_USERS_MASS_TRACK_FAIL:
    case GET_STATUS_TRACK_FAIL:
      return { ...state, isLoading: false, isError: true };

    case GET_TRACK_STAT_USER_FAIL:
      return { ...state, isUserStat: false, isError: true };

    case GET_PROGRESS_TRACK_FAIL:
      return { ...state, isProgress: false, isError: true };

    case CHANGE_STATUS_TRACK_FAIL:
      return { ...state, isChangeStatus: false, isError: true };

    case GET_TRACK_ID_FAIL:
      return { ...state, isLoading: false, isError: true, trackDetailLoading: false };

    case EXPORT_TRACK_USERS_FAIL:
      return { ...state, isLoadingExportTrack: false, isError: true };

    case POST_TRACKS_FILTERS_FAIL:
      return { ...state, isLoading: false, isError: true, tracksLoading: false };

    case POST_APPOINT_FILTERS_FAIL:
      return { ...state, isLoading: false, isError: true, appointLoading: false };

    case SAVE_TRACKS_FILTERS:
      const filters = { ...state.tracksFilters, filters: value };
      return { ...state, tracksFilters: filters };

    case CLEAR_TRACKS_FILTERS:
      return { ...state, tracksFilters: [] };

    case SAVE_TRACKS_APPOINT_FILTERS:
      const filtersAppoint = { ...state.appointFilters, filters: value };
      return { ...state, appointFilters: filtersAppoint };

    case CLEAR_TRACKS_APPOINT_FILTERS:
      return { ...state, appointFilters: {} };

    case CLEAR_TRACK_DETAILS:
      return { ...state, isLoading: false, trackDetails: [] };

    default:
      return { ...state };
  }
};
