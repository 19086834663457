import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearNewsVisibility,
  deleteNewsVisibility,
  editNewsVisibility,
  getNewsId,
  getNewsVisibility,
  postNewsFilters,
  updateNewsVisibility,
} from '@/store/news/actions';
import { getCountSettingsVisibility } from '@/store/settings_visibility/actions'; // exportUsersModalVisibility,

import { selectNews } from '@/store/news/selectors';
import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Modal, notification, Table, Tooltip } from 'antd';
import ImageBlock from '@/components/ImageBlock';
import Rate from '@/components/Rate';
import TemplateBlockFilters from '@/components/TemplateBlockFilters';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { HiOutlineExclamation, HiOutlinePlusCircle } from 'react-icons/hi';

import {
  DELETED,
  ERROR,
  IN_ACTIVE,
  REJECTED_BY_ADMIN,
  STATUS_ACTIVE,
  STATUS_WAITING_MODERATE,
  SUCCESS,
  WAITING_MODERATE,
  WARNING,
} from './constance';
import ModalAction from './ModalActions';
import ModalNews from './ModalNews';

import Utils from '@/Utils';
import classNames from 'classnames';

import css from '../News.module.scss';

import { NEWS_ADMIN_UPDATE, NEWS_ADMIN_UPDATE_STATUS, NEWS_VISIBILITY_FILTER_READ } from '@/constants/permissions';

const TableNews = () => {
  const { t } = useTranslation('news');
  const dispatch = useDispatch();

  const {
    newsFilters,
    newsLoading,
    newsTable,
    newsTotal,
    newsPageSize,
    newsPageNumber,
    newsCurrentPage,
    isLoading,
    newsVisibility,
    sortNews,
  } = useSelector(selectNews);
  const { currentUser } = useSelector(selectUsers);
  const { count, loading } = useSelector(selectSettingsVisibility);

  const [visibleModal, setVisibleModal] = useState({
    open: false,
    news: {},
  });
  const [isModalNews, setIsModalNews] = useState({
    open: false,
    newsPhotoUuid: '',
  });
  const [isAction, setIsAction] = useState({
    open: false,
    obj: null,
    type: '',
  });
  // const [successExportUsers, setSuccessExportUsers] = useState(false);

  // const funcCallbackExport = value => setSuccessExportUsers(value);

  // const exportUsersNewsVisibility = dataExport => {
  //   dispatch(
  //     exportUsersModalVisibility(dataExport, blob => {
  //       let url = window.URL.createObjectURL(blob);
  //       let link = document.createElement('a');
  //       link.href = url;
  //       link.click();

  //       funcCallbackExport(true);
  //     })
  //   );
  // };

  const editNews = val => {
    dispatch(getNewsId(val.newsId));
    setIsModalNews({ open: true, newsPhotoUuid: val.newsPhoto });
  };

  const showModalActions = (val, type) => {
    setIsAction({ open: true, obj: val, type });
  };

  const openNotification = value => {
    notification[value]({
      message: t('titleNotific'),
      description: msgNot(value),
    });
  };

  const msgNot = value => {
    switch (value) {
      case SUCCESS:
        return t('descNotSuccess');

      case WARNING:
        return t('descNotWarning');

      case ERROR:
        return t('descNotError');

      default:
        break;
    }
  };

  const onSubmitDataVisibility = (data, isVisible) => {
    if (visibleModal?.news?.newsId) {
      dispatch(editNewsVisibility(visibleModal.news.newsId, isVisible));
      dispatch(
        updateNewsVisibility(visibleModal.news.newsId, data, () => {
          setVisibleModal({ open: false, news: {} });
          openNotification(isVisible ? SUCCESS : WARNING);
        })
      );
    }
  };

  const resetSettingsNews = () => {
    if (visibleModal?.news?.newsId) {
      dispatch(
        deleteNewsVisibility(visibleModal.news.newsId, () => {
          setVisibleModal({ open: false, news: {} });
          openNotification(ERROR);
        })
      );
    }
  };

  const renderActions = item => {
    const deleteItem = (
      // const deleteItem = currentUser?.authorities.includes(TRACK_UPDATE) && (
      <Tooltip placement='top' title={t('deleteNews')}>
        <DeleteOutlined
          className={css['TableContent-actions-delete']}
          onClick={() => showModalActions(item, DELETED)}
        />
      </Tooltip>
    );

    const editItem = currentUser?.authorities.includes(NEWS_ADMIN_UPDATE) && (
      <Tooltip placement='top' title={t('editNews')}>
        <FormOutlined className={css['TableContent-actions-edit']} onClick={() => editNews(item)} />
      </Tooltip>
    );

    // if (item.newsStatusDto.status === REJECTED_BY_ADMIN && currentUser?.authorities.includes(TRACK_UPDATE)) {
    if (item.newsStatusDto.status === REJECTED_BY_ADMIN) {
      return (
        <>
          {editItem}
          {currentUser?.authorities.includes(NEWS_ADMIN_UPDATE_STATUS) && (
            <Tooltip placement='top' title={t('active')}>
              <HiOutlinePlusCircle
                className={css['TableContent-actions-active']}
                onClick={() => showModalActions(item, IN_ACTIVE)}
              />
            </Tooltip>
          )}
        </>
      );
    }

    if (item.newsStatusDto.status === STATUS_ACTIVE) {
      return (
        <>
          {editItem}
          {deleteItem}
          {currentUser?.authorities.includes(NEWS_VISIBILITY_FILTER_READ) && (
            <Tooltip placement='top' title={item.newsVisible ? t('visibleNews') : t('invisibleNews')}>
              {item.newsVisible ? (
                <EyeOutlined
                  className={css['TableContent-actions-visible']}
                  onClick={() => setVisibleModal({ open: true, news: item })}
                />
              ) : (
                <EyeInvisibleOutlined
                  className={css['TableContent-actions-invisible']}
                  onClick={() => setVisibleModal({ open: true, news: item })}
                />
              )}
            </Tooltip>
          )}
        </>
      );
    }

    if (item.newsStatusDto.status === STATUS_WAITING_MODERATE) {
      return (
        <>
          {editItem}
          {deleteItem}
          {currentUser?.authorities.includes(NEWS_ADMIN_UPDATE_STATUS) && (
            <Tooltip placement='top' title={t('moderate')}>
              <HiOutlineExclamation
                className={css['TableContent-actions-moderate']}
                onClick={() => showModalActions(item, WAITING_MODERATE)}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  const columns = [
    {
      title: t('newsPhoto'),
      dataIndex: 'newsPhoto',
      key: '1',
      width: 100,
      align: 'center',
      render: photoUuid => <ImageBlock photoUuid={photoUuid} />,
    },
    {
      title: t('newsName'),
      dataIndex: 'newsName',
      key: '2',
      width: 250,
      render: (text, value) => (
        <Tooltip title={text?.length > 35 && text}>
          <a
            rel='noreferrer'
            href={`https://${currentUser?.domainCompany?.domain}/news/${value.newsId}`}
            target='_blank'
            className={css['TableContent-table-cell-href']}
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: t('newsRate'),
      dataIndex: 'newsRate',
      key: '3',
      render: rate => <Rate disabled value={rate} />,
    },
    {
      title: t('newsCreatedBy'),
      dataIndex: 'newsCreatedBy',
      key: '4',
      render: (text, value) => (
        <Tooltip title={text?.length > 25 && text}>
          <a
            rel='noreferrer'
            href={`https://${currentUser?.domainCompany?.domain}/collegues/${value.newsCreatedById}/info`}
            target='_blank'
            className={classNames(css['TableContent-table-cell-href'], css['TableContent-table-cell-href-fio'])}
          >
            {text}
          </a>
        </Tooltip>
      ),
    },
    {
      title: t('newsDate'),
      dataIndex: 'newsDate',
      key: '5',
    },
    {
      title: t('newsStatus'),
      dataIndex: 'newsStatus',
      key: '6',
    },
    {
      dataIndex: 'newsAction',
      key: '7',
      align: 'center',
      render: (value, item) => <div className={css['TableContent-actions']}>{renderActions(item)}</div>,
      width: 150,
    },
  ];

  const getData = () => {
    if (newsTable?.length) {
      return newsTable.map(item => {
        return {
          newsPhoto: item.photoUuid,
          newsName: item.title,
          newsCreatedBy: item.authorDto.name,
          newsCreatedById: item.authorDto.id,
          newsDate: item.createdDate,
          newsStatus: item.statusDto.text,
          newsStatusDto: item.statusDto,
          newsId: item.id,
          newsVisible: item.visible,
          newsRate: item.rate,
        };
      });
    }
  };

  const onChangeTable = pagination => {
    dispatch(postNewsFilters(newsFilters.filters ? newsFilters.filters : {}, pagination.current - 1, sortNews));
  };

  const pagination = {
    total: newsTotal,
    pageSize: newsPageSize,
    pageNumber: newsPageNumber,
    current: newsCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  return (
    <div className={css['TableContent']}>
      <div className={css['TableContent-table']}>
        <Table
          columns={columns}
          dataSource={getData()}
          rowKey={e => e.newsId}
          pagination={pagination}
          onChange={onChangeTable}
          loading={newsLoading}
        />
      </div>

      {visibleModal.open && (
        <Modal
          title={t('titleModal')}
          open={visibleModal.open}
          width={1000}
          footer={null}
          onCancel={() => setVisibleModal({ open: false, news: {} })}
        >
          <TemplateBlockFilters
            isNews
            showCustomFields
            getSettings={arg => dispatch(getNewsVisibility(arg))}
            getCountSettings={arg => dispatch(getCountSettingsVisibility(arg))}
            count={count}
            loading={loading}
            loaderUpdate={isLoading}
            showUsersMaterial
            showSwitchVisible
            onSubmitData={onSubmitDataVisibility}
            titleUsersMaterial={t('titleUsersModalNews')}
            descriptionVisible={t('descriptionNewsVisible')}
            canEdit={currentUser && Utils.isAdminOrSuperAdmin(currentUser)}
            exportUsersVisibility
            // exportUsersVisibility={exportUsersNewsVisibility}
            // successExportUsers={successExportUsers}
            // funcCallbackExport={funcCallbackExport}
            onClose={() => setVisibleModal({ open: false, news: {} })}
            onResetSettings={resetSettingsNews} // func на сброс настроек видимости
            elemId={visibleModal?.news?.newsId}
            storeData={newsVisibility}
            clearStoreData={() => dispatch(clearNewsVisibility())}
            visibleElement={!visibleModal?.news?.newsVisible}
          />
        </Modal>
      )}

      {isAction.open && (
        <ModalAction isAction={isAction} onClose={() => setIsAction({ open: false, obj: null, type: '' })} />
      )}

      {isModalNews.open && (
        <ModalNews
          open={isModalNews.open}
          onClose={() => setIsModalNews({ open: false, newsPhotoUuid: '' })}
          isEdit
          newsPhotoUuid={isModalNews.newsPhotoUuid}
        />
      )}
    </div>
  );
};

export default TableNews;
