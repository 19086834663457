/* eslint-disable */
import { IAction } from '@shared/interfaces/store';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const REFRESH_LOGIN_START = 'REFRESH_LOGIN_START';
export const REFRESH_LOGIN_SUCCESS = 'REFRESH_LOGIN_SUCCESS';
export const REFRESH_LOGIN_FAIL = 'REFRESH_LOGIN_FAIL';
export const ACTUAL_LOGIN_START = 'ACTUAL_LOGIN_START';
export const ACTUAL_LOGIN_SUCCESS = 'ACTUAL_LOGIN_SUCCESS';
export const ACTUAL_LOGIN_FAIL = 'ACTUAL_LOGIN_FAIL';

export const GET_INFO_COMPANY_START = 'GET_INFO_COMPANY_START';
export const GET_INFO_COMPANY_SUCCESS = 'GET_INFO_COMPANY_SUCCESS';
export const GET_INFO_COMPANY_FAIL = 'GET_INFO_COMPANY_FAIL';

export const UPDATE_LOGIN_EMPTY = 'UPDATE_LOGIN_EMPTY';

export interface ILoginCredentials {
  username: string;
  password: string;
}

interface IError {
  id: number;
  name: string;
  description: string;
  icon: string;
}

interface IProvider {
  id: number;
  name: string;
  type: string;
  icon: string;
  label: string;
  description: string;
  external: boolean;
  domains: string[];
  errors: IError[];
}

interface ICompanyState {
  shortName: string;
  logo: string;
  background: string;
  colour: string;
  providers: IProvider[];
}

export interface ILoginState {
  isLoading: boolean;
  isRefreshing: boolean;
  error: string | null;
  roles: string[];
  authorities: string[];
  company?: ICompanyState;
}

export interface ILoginParams {
  recaptchaToken: string;
}

export interface ILoginPayload extends ILoginParams, ILoginCredentials {
  onSuccess?: (authorities: string[]) => void;
}

export interface ILoginAction extends IAction {
  payload: ILoginPayload;
}
