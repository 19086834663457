/* eslint-disable */
import {
  D360_COPY_SCALE_FAIL,
  D360_COPY_SCALE_START,
  D360_COPY_SCALE_SUCCESS,
  D360_CREATE_SCALE_FAIL,
  D360_CREATE_SCALE_START,
  D360_CREATE_SCALE_SUCCESS,
  D360_DELETE_SCALE_FAIL,
  D360_DELETE_SCALE_START,
  D360_DELETE_SCALE_SUCCESS,
  D360_GET_SCALE_FAIL,
  D360_GET_SCALE_START,
  D360_GET_SCALE_SUCCESS,
  D360_GET_SCALES_FAIL,
  D360_GET_SCALES_START,
  D360_GET_SCALES_SUCCESS,
  D360_SCALE_CAN_SKIP_FAIL,
  D360_SCALE_CAN_SKIP_START,
  D360_SCALE_CAN_SKIP_SUCCESS,
  D360_UPDATE_SCALE_FAIL,
  D360_UPDATE_SCALE_START,
  D360_UPDATE_SCALE_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  scales: [],
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    // Query SUCCESS
    case D360_CREATE_SCALE_START:
    case D360_DELETE_SCALE_START:
    case D360_UPDATE_SCALE_START:
    case D360_COPY_SCALE_START:
    case D360_GET_SCALE_START:
    case D360_GET_SCALES_START:
    case D360_SCALE_CAN_SKIP_START:
      return { ...state, loading: true };

    // Query ERROR
    case D360_CREATE_SCALE_FAIL:
    case D360_DELETE_SCALE_FAIL:
    case D360_UPDATE_SCALE_FAIL:
    case D360_COPY_SCALE_FAIL:
    case D360_GET_SCALE_FAIL:
    case D360_GET_SCALES_FAIL:
    case D360_SCALE_CAN_SKIP_FAIL:
      return { ...state, loading: false };

    // Create
    case D360_CREATE_SCALE_SUCCESS:
      return {
        ...state,
        loading: false,
        scales: state.scales.concat(response.data),
      };

    // Delete
    case D360_DELETE_SCALE_SUCCESS:
      return {
        ...state,
        loading: false,
        scales: state.scales.filter(scale => scale.id !== response.data.id),
      };

    // Update
    case D360_SCALE_CAN_SKIP_SUCCESS:
      return {
        ...state,
        loading: false,
        scales: state.scales.map(scale => {
          return scale.id === response.data[0].id ? response.data[0] : scale;
        }),
      };

    case D360_UPDATE_SCALE_SUCCESS:
      return {
        ...state,
        loading: false,
        scales: state.scales.map(scale => {
          if (scale.id === response.data.id) {
            scale = response.data;
          }

          return scale;
        }),
      };

    // Copy
    case D360_COPY_SCALE_SUCCESS:
      return {
        ...state,
        loading: false,
        scales: response.data,
      };

    // Get Scale
    case D360_GET_SCALE_SUCCESS:
      return state;

    // Get All Scales
    case D360_GET_SCALES_SUCCESS:
      return {
        ...state,
        loading: false,
        scales: response.data,
      };

    default:
      return state;
  }
};
