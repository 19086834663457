/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

export default class Spinner extends Component {
  static propTypes = {
    bigProgress: PropTypes.bool,
    bigSpinner: PropTypes.bool,
    score: PropTypes.number,
    small: PropTypes.bool,
  };

  render() {
    const { bigSpinner, bigProgress, small } = this.props;
    return (
      <div
        data-qa='spinner'
        className={classNames(css.spinner__box, {
          [css.bigSpinner]: bigSpinner,
          [css.small]: small,
        })}
      >
        <span
          className={classNames(css.spinner, {
            [css.bigProgress]: bigProgress,
            [css.small]: small,
          })}
          {...(!bigSpinner
            ? !small
              ? {
                  width: '30px',
                  height: '30px',
                }
              : {
                  width: '20px',
                  height: '20px',
                }
            : {
                width: '64px',
                height: '64px',
              })}
        />
      </div>
    );
  }
}
