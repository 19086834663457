/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const POST_FILE_START = 'POST_FILE_START';
export const POST_FILE_SUCCESS = 'POST_FILE_SUCCESS';
export const POST_FILE_FAIL = 'POST_FILE_FAIL';

export const POST_FILE_IMAGE_START = 'POST_FILE_IMAGE_START';
export const POST_FILE_IMAGE_SUCCESS = 'POST_FILE_IMAGE_SUCCESS';
export const POST_FILE_IMAGE_FAIL = 'POST_FILE_IMAGE_FAIL';

export const POST_STUDY_PLAN_FILE_START = 'POST_STUDY_PLAN_FILE_START';
export const POST_STUDY_PLAN_FILE_SUCCESS = 'POST_STUDY_PLAN_FILE_SUCCESS';
export const POST_STUDY_PLAN_FILE_FAIL = 'POST_STUDY_PLAN_FILE_FAIL';

export const GET_TOPIC_FILE_START = 'GET_TOPIC_FILE_START';
export const GET_TOPIC_PLAN_FILE_SUCCESS = 'GET_TOPIC_PLAN_FILE_SUCCESS';
export const GET_TOPIC_PLAN_FILE_FAIL = 'GET_TOPIC_PLAN_FILE_FAIL';

export const PUT_FILE_START = 'PUT_FILE_START';
export const PUT_FILE_SUCCESS = 'PUT_FILE_SUCCESS';
export const PUT_FILE_FAIL = 'PUT_FILE_FAIL';

export const GET_FILE_START = 'GET_FILE_START';
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_FAIL = 'GET_FILE_FAIL';

export const GET_PUBLIC_FILE_START = 'GET_PUBLIC_FILE_START';
export const GET_PUBLIC_FILE_SUCCESS = 'GET_PUBLIC_FILE_SUCCESS';
export const GET_PUBLIC_FILE_FAIL = 'GET_PUBLIC_FILE_AIL';

export const GET_FILE_SIZES_START = 'GET_FILE_SIZES_START';
export const GET_FILE_SIZES_SUCCESS = 'GET_FILE_SIZES_SUCCESS';
export const GET_FILE_SIZES_FAIL = 'GET_FILE_SIZES_FAIL';

export const GET_FILES_START = 'GET_FILES_START';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAIL = 'GET_FILES_FAIL';

export const DELETE_FILE_START = 'DELETE_FILE_START';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';

export const IN_CACHE_FILE = 'IN_CACHE_FILE';

const headers = {
  Accept: '*/*',
};

const defaultToken = '';

export const createFile = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: API_URL + '/service/file',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_FILE_START, POST_FILE_SUCCESS, POST_FILE_FAIL],
});

export const createFileImage = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: API_URL + '/service/file',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_FILE_IMAGE_START, POST_FILE_IMAGE_SUCCESS, POST_FILE_IMAGE_FAIL],
});

export const createFileBannerImage = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: API_URL + '/service/file?type=BANNER',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_FILE_IMAGE_START, POST_FILE_IMAGE_SUCCESS, POST_FILE_IMAGE_FAIL],
});

export const createFileImagePublic = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: API_URL + '/service/file?isPublic=true',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_FILE_IMAGE_START, POST_FILE_IMAGE_SUCCESS, POST_FILE_IMAGE_FAIL],
});

export const importStudyPlan = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: API_URL + '/user/topic/plan/import',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_STUDY_PLAN_FILE_START, POST_STUDY_PLAN_FILE_SUCCESS, POST_STUDY_PLAN_FILE_FAIL],
});

export const exportTopics = (data, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: API_URL + '/topic/export' + getQueryString({ ...data }),
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'blob',
  onSuccess,
  types: [GET_TOPIC_FILE_START, GET_TOPIC_PLAN_FILE_SUCCESS, GET_TOPIC_PLAN_FILE_FAIL],
});

export const getPublicFile = (uuid, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: API_URL + `/public/file/${uuid}`,
  headers: { ...headers },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_PUBLIC_FILE_START, GET_PUBLIC_FILE_SUCCESS, GET_PUBLIC_FILE_FAIL],
  onSuccess,
  onError,
});

export const getFile = (uuid, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: API_URL + `/service/file/${uuid}` + getQueryString({ mode: 'DOWNLOAD_MODE' }),
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_FILE_START, GET_FILE_SUCCESS, GET_FILE_FAIL],
  onSuccess,
  onError,
});

export const getFileSizes = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/service/file/size`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FILE_SIZES_START, GET_FILE_SIZES_SUCCESS, GET_FILE_SIZES_FAIL],
  onSuccess,
  onError,
});

export const getFiles = uuid => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/service/file/${uuid}${getQueryString({
    mode: 'DOWNLOAD_MODE',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  responseType: 'blob',
  types: [GET_FILES_START, GET_FILES_SUCCESS, GET_FILES_FAIL],
  uuid,
});

export const deleteFile = uuid => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/service/file/${uuid}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_FILE_START, DELETE_FILE_SUCCESS, DELETE_FILE_FAIL],
  uuid,
});

export const inCacheFile = (name, urlImg) => ({
  values: { name, urlImg },
  type: IN_CACHE_FILE,
});
