import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearMentorsFilters,
  getCountStat,
  getMentors,
  postMentorsFilters,
  saveMentorsFilters,
} from '@/store/mentors/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectMentors } from '@/store/mentors/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Form, Row, Select } from 'antd';

import _ from 'lodash';

import css from '../Mentors.module.scss';

const { Option } = Select;

const Filters = () => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();

  const { subCompanies } = useSelector(selectCompanies);
  const { mentors, status } = useSelector(selectMentors);
  const { currentUser } = useSelector(selectUsers);
  const { company } = useSelector(selectLogin);
  const { position, department, functionStudent } = useSelector(selectStudents);
  const isSuperAdmin = currentUser?.parentRoleId === 1 || currentUser?.roleId === 1;
  const hasSubAdmin = company?.hasSubcompanies && !isSuperAdmin;
  const [init, setInit] = useState(false);
  const [data, setData] = useState({
    mentorId: {
      id: null,
      name: '',
    },
    company: '',
    position: '',
    department: '',
    function: '',
    status: {
      id: null,
      name: '',
    },
    // customFields: [{}],
  });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (!init) {
      setInit(true);
      if (company?.hasSubcompanies === true) {
        setData({ ...data, company: currentUser?.company });
        dispatch(postMentorsFilters({ company: currentUser?.company }));
      } else {
        dispatch(postMentorsFilters({}));
      }
    }
  }, [dispatch, company, currentUser, data, init]);

  useEffect(() => {
    dispatch(getCountStat(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    // Обратываю данные для отправки
    let copyData = _.cloneDeep(data);

    for (let key in copyData) {
      if (!copyData[key] || (copyData[key].hasOwnProperty('id') && !copyData[key].id)) {
        delete copyData[key];
      } else if (copyData[key]?.id) {
        copyData[key] = copyData[key].id;
      }
    }

    setFilters(copyData);
  }, [data]);

  useEffect(() => {
    // сохраняю обработанные данные в СТОР
    dispatch(saveMentorsFilters(filters));
  }, [dispatch, filters]);

  const onSendFilters = () => {
    dispatch(postMentorsFilters(filters));
  };

  const clearFilters = () => {
    setData({
      mentorId: {
        id: null,
        name: '',
      },
      company: hasSubAdmin ? currentUser?.company : '',
      position: '',
      department: '',
      function: '',
      status: {
        id: null,
        name: '',
      },
      //   customFields: [{}],
    });
    dispatch(clearMentorsFilters({ customFields: [{}] }));

    setTimeout(() => {
      dispatch(postMentorsFilters({ company: hasSubAdmin ? currentUser?.company : undefined }));
    }, 1);
  };

  const renderOptions = arr => {
    return (
      arr?.length &&
      arr.map((item, index) => (
        <Option value={item} key={index}>
          {item}
        </Option>
      ))
    );
  };

  const optionStatus =
    status?.length &&
    status.map(item => {
      return (
        <Option key={item.value} value={item.value}>
          {item.name}
        </Option>
      );
    });

  const optionMentors =
    mentors?.length &&
    mentors.map(item => {
      return (
        <Option key={item.mentorId} value={item.fullName}>
          {item.fullName}
        </Option>
      );
    });

  const handleGet = (e, func) => {
    e?.length > 3 && dispatch(func(e));
  };

  const getDebounced = useCallback(_.debounce(handleGet, 1000), []);

  const templateFilters = [
    {
      col: [
        {
          label: t('userName'),
          valueLabel: data.mentorId.name,
          onChange: (e, item) => setData({ ...data, mentorId: { id: item?.key, name: e } }),
          option: optionMentors,
          component: Select,
          onSearch: e => getDebounced(e, getMentors),
          dataQa: 'AdminMentorsFilterUserNameInput',
        },
        {
          label: t('position'),
          valueLabel: data.position,
          onChange: e => setData({ ...data, position: e }),
          option: renderOptions(position),
          component: Select,
          dataQa: 'AdminMentorsFilterPositionInput',
        },
        {
          label: t('department'),
          valueLabel: data.department,
          onChange: e => setData({ ...data, department: e }),
          option: renderOptions(department),
          component: Select,
          dataQa: 'AdminMentorsFilterDepartmentInput',
        },
      ],
    },
    {
      type: 'flex',
      justify: 'space-between',
      col: [
        {
          label: t('function'),
          valueLabel: data.function,
          onChange: e => setData({ ...data, function: e }),
          option: renderOptions(functionStudent),
          component: Select,
          dataQa: 'AdminMentorsFilterFunctionInput',
        },
        {
          label: t('company'),
          valueLabel: data.company,
          onChange: e => setData({ ...data, company: e }),
          option: renderOptions(subCompanies),
          component: Select,
          disabled: hasSubAdmin ? true : false,
          dataQa: 'AdminMentorsFilterCompanyInput',
        },
        {
          label: t('status'),
          valueLabel: data.status.name,
          onChange: (e, item) => setData({ ...data, status: { id: item?.key, name: e } }),
          option: optionStatus,
          component: Select,
          dataQa: 'AdminMentorsFilterStatusInput',
        },
      ],
    },
  ];

  const renderSelect = templateFilters.map((item, index) => {
    const customRow = item.col.map((col, index) => {
      const MY_COMP = col.component;
      return (
        <Col span={8} key={index}>
          <Form layout='vertical'>
            <Form.Item label={col.label} colon={false} layout='vertical'>
              <MY_COMP
                // mode='multiple'
                onChange={col.onChange}
                onSearch={col.onSearch}
                allowClear
                showSearch
                className={col.className}
                notFoundContent={t('noData')}
                value={col.valueLabel}
                disabled={col.disabled}
                data-qa={col.dataQa}
                key={col.dataQa}
              >
                {col.option}
              </MY_COMP>
            </Form.Item>
          </Form>
        </Col>
      );
    });
    return (
      <Row type={item.type} justify={item.justify} className={item.classNameRow} key={index} gutter={[16, 0]}>
        {customRow}
      </Row>
    );
  });

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-header']}>
        <div className={css['Filters-header-title']}>{t('filter')}</div>
        <div>
          <Button type='link' onClick={clearFilters} className={css['Filters-header-reset']}>
            {t('reset')}
          </Button>
        </div>
      </div>
      {renderSelect}
      <div className={css['Filters-footer']}>
        <Button type='primary' data-qa='adminMentorsFiltersApply' onClick={onSendFilters}>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
};

export default Filters;
