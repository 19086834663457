/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Input, Pagination, Tag, Tooltip } from 'antd';
import Modal from '@/components/Modal/index';

import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './RejectCommentModal.module.scss';

const { TextArea } = Input;

export class RejectCommentModal extends Component {
  static propTypes = {
    cancelRejectCommentModal: PropTypes.func,
    courses: PropTypes.object,
    coursesId: PropTypes.number, // study plan
    coursesRequestComment: PropTypes.array,
    coursesRequestCommentNumber: PropTypes.number,
    coursesRequestCommentPageSize: PropTypes.number,
    coursesRequestCommentTotal: PropTypes.number,
    getCommentRejected: PropTypes.func,
    handleGetCourseRequest: PropTypes.func,
    handleGetRequest: PropTypes.func,
    manageCourse: PropTypes.bool,
    postCommentRejected: PropTypes.func,
    open: PropTypes.bool,
    hideLogin: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      page: 0,
      newComment: '',
    };
  }

  componentDidMount() {
    if (this.props.open) {
      this.props.getCommentRejected((this.props.courses && this.props.courses.plan.id) || this.props.coursesId, 0);
      this.setState({ page: 0 });
    }
  }

  handleCancel = () => {
    this.props.cancelRejectCommentModal();
  };

  showCommentLabel = action => {
    if (action) {
      return (
        <Tag color='orange' className={css['RejectCommentModal-tag']}>
          {action}
        </Tag>
      );
    }
    return null;
  };

  changeComment = event => {
    this.setState({ newComment: event.target.value });
  };

  submitComment = () => {
    this.setState({ btnClick: true });
    const comment = {
      id: null,
      studyPlanId: this.props.courses?.plan?.id || this.props.coursesId,
      text: this.state.newComment,
    };

    this.props.postCommentRejected(comment, () => {
      this.props.getCommentRejected((this.props.courses && this.props.courses?.plan?.id) || this.props.coursesId, 0);
      if (this.props.manageCourse) {
        this.props.handleGetCourseRequest();
      } else {
        this.props.handleGetRequest();
      }
      this.setState({ page: 0, btnClick: false, newComment: '' });
    });
  };

  changePage = page => {
    this.setState({ page });
    this.props.getCommentRejected(
      (this.props.courses && this.props.courses?.plan?.id) || this.props.coursesId,
      page - 1
    );
  };

  renderFullName = val => {
    const fullName = `${val.lastName} ${val.firstName}`;
    return <Tooltip title={`${val.lastName} ${val.firstName}`}>{Utils.trString(fullName, 20)}</Tooltip>;
  };
  render() {
    const { coursesRequestCommentNumber, coursesRequestCommentTotal, coursesRequestCommentPageSize, t } = this.props;
    const comments =
      this.props.coursesRequestComment &&
      this.props.coursesRequestComment.map(comment => (
        <div
          className={classNames(css['RejectCommentModal-commentBlock'], {
            [css['RejectCommentModal-commentBlock-bot']]: comment.type === 'BOT',
          })}
          key={comment.id}
        >
          <div
            className={classNames(
              css['RejectCommentModal-commentBlock-date'],
              css['RejectCommentModal-commentBlock-textSize']
            )}
          >
            {dayjs(comment.createdDate).format('DD.MM.YYYY')}
          </div>
          <div
            className={classNames(
              css['RejectCommentModal-commentBlock-author'],
              css['RejectCommentModal-commentBlock-textSize']
            )}
          >
            {comment.user
              ? comment.admin && !this.props.hideLogin
                ? t('panel')
                : comment.user && (comment.user.lastName || comment.user.firstName)
                  ? this.renderFullName(comment.user)
                  : null
              : null}
            {!this.props.hideLogin && comment.user && comment.user.login ? <br /> : null}
            {!this.props.hideLogin && (
              <>
                {comment.user && comment.user.login ? (
                  <div>
                    <Tooltip title={`${comment.user.login}`}>
                      {comment.user.login.length > 20
                        ? `${comment.user.login.substring(0, 20)}...`
                        : comment.user.login}
                    </Tooltip>
                  </div>
                ) : (
                  'Kampus'
                )}
              </>
            )}
          </div>
          <div className={css['RejectCommentModal-commentBlock-label']}>{this.showCommentLabel(comment.type)}</div>
          <div
            className={classNames(
              css['RejectCommentModal-commentBlock-comment'],
              css['RejectCommentModal-commentBlock-textSize']
            )}
          >
            {comment.eventDate ? (
              <div className={css['RejectCommentModal-commentBlock-comment-label']}>
                <p className={css['RejectCommentModal-commentBlock-comment-label-error']}>{t('userDeleted')}</p>
                <p>{dayjs(comment.eventDate).format('DD.MM.YYYY')}</p>
                <br />
              </div>
            ) : null}
            {comment.text}
          </div>
        </div>
      ));
    const pagination =
      coursesRequestCommentPageSize &&
      coursesRequestCommentTotal &&
      coursesRequestCommentTotal > coursesRequestCommentPageSize ? (
        <Pagination
          current={coursesRequestCommentNumber + 1}
          total={coursesRequestCommentTotal}
          pageSize={coursesRequestCommentPageSize}
          onChange={this.changePage}
        />
      ) : null;
    return (
      <Modal open={this.props.open} onCancel={this.handleCancel} width={800} hideFooter zIndex={1005} commentModal>
        <div className={classNames(css['App-card-item'], css.RejectCommentModal)}>
          <div className={css['RejectCommentModal-header']}>
            <p>{t('title')}</p>
          </div>
          <TextArea
            className={css['RejectCommentModal-addComment']}
            rows={4}
            placeholder={t('commentText')}
            onChange={this.changeComment}
            value={this.state.newComment}
            maxLength={500}
          />
          <Button
            disabled={!this.state.newComment.trim() || this.state.btnClick}
            className={css['RejectCommentModal-addComment-submit']}
            onClick={this.submitComment}
          >
            {t('leaveComment')}
          </Button>
          {comments && comments.length ? (
            <div className={css.RejectCommentModal}>
              <div className={css['RejectCommentModal-labelBlock']}>
                <div className={css['RejectCommentModal-commentBlock-date']}>{t('date')}</div>
                <div className={css['RejectCommentModal-commentBlock-author']}>{t('author')}</div>
                <div className={css['RejectCommentModal-commentBlock-label']}>{t('action')}</div>
                <div className={css['RejectCommentModal-commentBlock-comment']}>{t('comment')}</div>
              </div>
              <div className={css['RejectCommentModal-comment']}>{comments}</div>
            </div>
          ) : (
            t('noComments')
          )}
          {pagination}
        </div>
      </Modal>
    );
  }
}

export default withTranslation('rejectCommentModal')(RejectCommentModal);
