import {
  DELETE_POKA_APPROVER_FAIL,
  DELETE_POKA_APPROVER_START,
  DELETE_POKA_APPROVER_SUCCESS,
  LOAD_POKA_APPROVERS_FAIL,
  LOAD_POKA_APPROVERS_START,
  LOAD_POKA_APPROVERS_SUCCESS,
  POST_POKA_APPROVER_FAIL,
  POST_POKA_APPROVER_START,
  POST_POKA_APPROVER_SUCCESS,
  PUT_POKA_APPROVER_FAIL,
  PUT_POKA_APPROVER_START,
  PUT_POKA_APPROVER_SUCCESS,
  SAVE_POKA_APPROVER_DATA,
  SAVE_POKA_APPROVERS_FILTERS,
  SAVE_POKA_APPROVERS_SORT,
} from '@/store/poka_approvers/constants';

const initialState = {
  pokaApprovers: [],
  loadingPokaApprovers: false,
  errorPokaApprovers: false,
  pokaApproversTotal: 0,
  pokaApproversPageSize: 0,
  pokaApproversPageNumber: 0,
  pokaApproversCurrentPage: 0,
  pokaApproversFilters: {},
  pokaApproversSort: '',
  loadingAddEditDeleteApprover: false,
  errorAddEditDeleteApprover: false,
  editingPokaApprover: {},
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case LOAD_POKA_APPROVERS_START:
      return {
        ...state,
        loadingPokaApprovers: true,
        errorPokaApprovers: false,
        pokaApprovers: [],
      };

    case LOAD_POKA_APPROVERS_SUCCESS:
      return {
        ...state,
        loadingPokaApprovers: false,
        errorPokaApprovers: false,
        pokaApproversTotal: response.totalElements,
        pokaApproversPageSize: response.pageable.pageSize,
        pokaApproversPageNumber: response.pageable.pageNumber,
        pokaApproversCurrentPage: response.number,
        pokaApprovers: response.content,
      };

    case LOAD_POKA_APPROVERS_FAIL:
      return {
        ...state,
        loadingPokaApprovers: false,
        errorPokaApprovers: true,
        pokaApprovers: [],
      };

    case SAVE_POKA_APPROVERS_FILTERS:
      return { ...state, pokaApproversFilters: value };

    case SAVE_POKA_APPROVERS_SORT:
      return { ...state, pokaApproversSort: value };

    case POST_POKA_APPROVER_START:
    case PUT_POKA_APPROVER_START:
    case DELETE_POKA_APPROVER_START:
      return {
        ...state,
        loadingAddEditDeleteApprover: true,
        errorAddEditDeleteApprover: false,
      };

    case POST_POKA_APPROVER_SUCCESS:
    case PUT_POKA_APPROVER_SUCCESS:
    case DELETE_POKA_APPROVER_SUCCESS:
      return {
        ...state,
        loadingAddEditDeleteApprover: false,
      };

    case POST_POKA_APPROVER_FAIL:
    case PUT_POKA_APPROVER_FAIL:
    case DELETE_POKA_APPROVER_FAIL:
      return {
        ...state,
        loadingAddEditDeleteApprover: false,
        errorAddEditDeleteApprover: true,
      };

    case SAVE_POKA_APPROVER_DATA:
      return { ...state, editingPokaApprover: value };

    default:
      return state;
  }
};
