import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { exportQuestionCSV, getQuestionsList, importQuestions, setDefaultSort } from '@/store/questionBank/actions';

import { selectQuestionBank } from '@/store/questionBank/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, Typography } from 'antd';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import { Content } from '@/components/Layout';
import { PlusOutlined } from '@ant-design/icons';

import BankFilter from './Content/BankFilter';
import { DEFAULT_FILTERS } from './Content/BankFilter/constants';
import BankTable from './Content/BankTable';
import QuestionModal from './Content/QuestionModal';

import css from './QuestionBank.module.scss';

import { QUESTION_BANK_CREATE, QUESTION_BANK_IMPORT } from '@/constants/permissions';

const QuestionBank = () => {
  const { t } = useTranslation('QuestionBank');
  const dispatch = useDispatch();
  const { Title } = Typography;
  const { bankList, isSort } = useSelector(selectQuestionBank);
  const { currentUser } = useSelector(selectUsers);
  const { pagination } = bankList;

  const [importVisible, setImportVisible] = useState(false);
  const [questionModal, setQuestionModal] = useState({
    open: false,
    question: {},
    isEdit: false,
  });
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  // Инициализация страницы
  useEffect(() => {
    dispatch(getQuestionsList({}, '', isSort));

    return () => {
      dispatch(setDefaultSort()); // при размонтирование приводим к начальному состоянию сортировки
    };
  }, []);

  // Изменение видимости модального окна с импортом
  const changeImportVisible = visible => setImportVisible(visible);

  // При успешном импорте
  const onImportOk = (file, onSuccess) => {
    if (file) {
      dispatch(
        importQuestions(file, response => {
          onSuccess(response);
          dispatch(getQuestionsList(filters, bankList.pagination.number, isSort));
        })
      );
    }
  };

  // Смена видимости модального окна и некоторые параметры для него
  const changeQuestionModal = (open, question = questionModal.question, isEdit = questionModal.isEdit) => {
    setQuestionModal({
      open,
      question,
      isEdit,
    });
  };

  return (
    <Layout>
      <Content>
        {/* Модальное окно импорта вопросов */}
        {importVisible && (
          <DictionaryImportDialog
            fileDescription={t('importFileDescription')}
            description1={t('exportDescription')}
            onOk={onImportOk}
            onCancel={() => changeImportVisible(false)}
            open={importVisible}
            exampleFileFunc={blob => dispatch(exportQuestionCSV(blob))}
          />
        )}

        {/* Модальное окно добавления/редактирования вопроса */}
        {questionModal.open && (
          <QuestionModal
            questionModal={questionModal}
            changeQuestionModal={changeQuestionModal}
            getQuestionsWithFilter={() => dispatch(getQuestionsList(filters, pagination.number, isSort))}
          />
        )}

        <div className={css['QuestionBank-header']}>
          <Title>{t('title')}</Title>
          <div className={css['QuestionBank-header-buttons']}>
            {currentUser?.authorities.includes(QUESTION_BANK_CREATE) && (
              <Button type='primary' icon={<PlusOutlined />} onClick={() => changeQuestionModal(true, {}, false)}>
                {t('createQuestion')}
              </Button>
            )}
            {currentUser?.authorities.includes(QUESTION_BANK_IMPORT) && (
              <Button type='primary' onClick={() => changeImportVisible(true)}>
                {t('importQuestions')}
              </Button>
            )}
          </div>
        </div>
        <BankFilter filters={filters} setFilters={setFilters} />
        <BankTable changeEditModal={(open, question) => changeQuestionModal(open, question, true)} filters={filters} />
      </Content>
    </Layout>
  );
};

export default QuestionBank;
