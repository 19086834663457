import React from 'react';
import { useTranslation } from 'react-i18next';

// import css2 from './Ant.module.scss';
import { Tag, Typography } from 'antd';

import PropTypes from 'prop-types';

import css from './TabMentors.module.scss';

// import { useSelector, useDispatch } from 'react-redux'

const { Title } = Typography;

const TabMentors = props => {
  const { t } = useTranslation('mentors');
  // const dispatch = useDispatch();
  // const { topic } = useSelector(selectTopics);

  const tags = props.mentorCompetence.map(item => {
    return (
      <Tag color='#2db7f5' key={item.id} className={css['TabMentors-tags']}>
        {item.name}
      </Tag>
    );
  });

  return (
    <div className={css['TabMentors']}>
      <Title level={4}>{t('titleTabMentors')}</Title>
      <div>{tags}</div>
    </div>
  );
};

TabMentors.propTypes = {
  student: PropTypes.object,
  mentorCompetence: PropTypes.array,
};

export default TabMentors;
