/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Tooltip } from 'antd';
import UserItem from '@/components/UserItem';
import { CloseOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './ModalSchedule.module.scss';

export class ModalSchedule extends Component {
  static propTypes = {
    avatars: PropTypes.object,
    event: PropTypes.object,
    eventUsers: PropTypes.object,
    handleCloseSchedule: PropTypes.func,
    usersList: PropTypes.bool,
    visible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: {},
    };
  }

  render() {
    const { usersList, t } = this.props;
    return (
      <div
        className={classNames(css.ModalSchedule, {
          [css.activeEvent]: this.props.visible,
        })}
      >
        <div className={css['ModalSchedule-label']}>
          {!usersList ? t('schedule') : t('list')}
          <CloseOutlined onClick={this.props.handleCloseSchedule} />
        </div>
        {!usersList ? (
          <div className={css['ModalSchedule-schedule']}>
            <div className={css['ModalSchedule-schedule-label']}>
              <div className={css['ModalSchedule-schedule-date']}>{t('date')}</div>
              <div className={css['ModalSchedule-schedule-time']}>{t('time')}</div>
              <div className={css['ModalSchedule-schedule-place']}>{t('place')}</div>
            </div>
            <div className={css['ModalSchedule-schedule-container']}>
              {this.props.event &&
                this.props.event.periods.map((period, index) => (
                  <div key={`#${index}-${period.id}`} className={css['ModalSchedule-schedule-container-event']}>
                    <div className={css['ModalSchedule-schedule-date']}>{dayjs(period.date).format('DD.MM.YYYY')}</div>
                    <div className={css['ModalSchedule-schedule-time']}>
                      {period.startTime && period.startTime.substring(0, 5)} -{' '}
                      {period.endTime && period.endTime.substring(0, 5)}
                    </div>
                    <div className={css['ModalSchedule-schedule-place']}>
                      {period.place ? (
                        period.place.length > 55 ? (
                          <Tooltip title={period.place}>{period.place.substring(0, 55)}...</Tooltip>
                        ) : (
                          period.place
                        )
                      ) : (
                        t('notSpecified')
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {this.props.event && this.props.event.comment && (
              <div
                className={classNames(
                  css['ModalSchedule-schedule-container'],
                  css['ModalSchedule-schedule-container-comment']
                )}
              >
                {this.props.event.comment}
              </div>
            )}
          </div>
        ) : (
          <div className={css['ModalSchedule-schedule']}>
            {this.props.eventUsers &&
              this.props.eventUsers.map((user, index) => {
                const userParams = user.plan.user;
                return <UserItem index={index} user={userParams} status={user.plan.status} />;
              })}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('modalSchedule')(ModalSchedule);
