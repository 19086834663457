import './constants';

import {
  GET_POKA_DIR_POSITIONS_FAIL,
  GET_POKA_DIR_POSITIONS_START,
  GET_POKA_DIR_POSITIONS_SUCCESS,
  PUT_POKA_DIR_POSITIONS_FAIL,
  PUT_POKA_DIR_POSITIONS_START,
  PUT_POKA_DIR_POSITIONS_SUCCESS,
} from '@/store/poka_directory/constants';

const initialState = {
  pokaDirPositions: [],
  loadingPokaDirPositions: false,
  errorPokaDirPositions: false,
  loadingEditDirPositions: false,
  errorEditDirPositions: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_POKA_DIR_POSITIONS_START:
      return {
        ...state,
        loadingPokaDirPositions: true,
        errorPokaDirPositions: false,
        pokaDirPositions: [],
      };

    case GET_POKA_DIR_POSITIONS_SUCCESS:
      return {
        ...state,
        loadingPokaDirPositions: false,
        errorPokaDirPositions: false,
        pokaDirPositions: response,
      };

    case GET_POKA_DIR_POSITIONS_FAIL:
      return {
        ...state,
        loadingPokaDirPositions: false,
        errorPokaDirPositions: true,
        pokaDirPositions: [],
      };

    case PUT_POKA_DIR_POSITIONS_START:
      return {
        ...state,
        loadingEditDirPositions: true,
        errorEditDirPositions: false,
      };

    case PUT_POKA_DIR_POSITIONS_SUCCESS:
      return {
        ...state,
        loadingEditDirPositions: false,
        errorEditDirPositions: false,
      };

    case PUT_POKA_DIR_POSITIONS_FAIL:
      return {
        ...state,
        loadingEditDirPositions: false,
        errorEditDirPositions: true,
      };

    default:
      return state;
  }
};
