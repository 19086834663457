/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Modal, Tabs } from 'antd';

import Diagnostics from './Diagnostics';
import Metrics from './Metrics';
import DiagnosticsGPM from './modules/DiagnosticsGPM/DiagnosticsGPM';
import DiagnosticsPolus from './modules/DiagnosticsPolus';
import TabMentors from './modules/TabMentors/TabMentors';
import Personal from './Personal';
import Statistics from './Statistics';

import PropTypes from 'prop-types';

import './profile.css'; // TODO: Переписать на module. @VariKX

import { KAMPUS_DEV_DOMAIN } from '@/constants/global';

const { TabPane } = Tabs;

export class Profile extends Component {
  static propTypes = {
    t: PropTypes.func,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    getStudents: PropTypes.func,
    student: PropTypes.object,
    domainCompany: PropTypes.object,
    isMentors: PropTypes.bool,
    mentorCompetence: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      defaultTab: '1',
    };
  }

  componentDidMount() {
    if (this.props.isMentors) {
      this.setState({ defaultTab: '8' });
    }
  }

  isPolyus = () => {
    const { domainCompany } = this.props;

    if (
      typeof domainCompany !== 'undefined' &&
      typeof domainCompany !== 'undefined' &&
      typeof domainCompany.domain !== 'undefined'
    ) {
      if (domainCompany.domain !== null) {
        if (domainCompany.domain === KAMPUS_DEV_DOMAIN || domainCompany.domain === 'polyus.kampus.team') {
          return true;
        }
      }
    }

    return false;
  };

  isGPM = () => {
    const { domainCompany } = this.props;

    if (
      typeof domainCompany !== 'undefined' &&
      typeof domainCompany !== 'undefined' &&
      typeof domainCompany.diagnosticType !== 'undefined'
    ) {
      if (domainCompany.diagnosticType !== null) {
        if (domainCompany.diagnosticType.id === 4 || domainCompany.diagnosticType.type === 'GPM') {
          return true;
        }
      }
    }

    return false;
  };

  render() {
    const { open, student, t, mentorCompetence } = this.props;

    const tabItems = [
      {
        label: t('tabPersonal'),
        key: '1',
        children: <Personal student={student} />,
      },
      {
        label: t('tabStatistics'),
        key: '2',
        children: <Statistics student={student} />,
      },
      {
        label: t('tabMetrics'),
        key: '3',
        children: <Metrics student={student} />,
      },
      {
        label: t('tabDiagnostics'),
        key: '4',
        children: <Diagnostics student={student} />,
      },
      this.isPolyus() && {
        label: t('tabDiagnosticsPolus'),
        key: '5',
        children: <DiagnosticsPolus student={student} />,
      },
      this.isGPM() && {
        label: t('tabDiagnosticsGPM'),
        key: '6',
        children: <DiagnosticsGPM student={student} />,
      },
      this.props.isMentors && {
        label: t('tabMentors'),
        key: '7',
        children: <TabMentors student={student} mentorCompetence={mentorCompetence} />,
      },
    ];

    return (
      <Modal
        onCancel={this.props.onCancel}
        open={open}
        closable={false}
        cancelText={t('cancel')}
        width={640}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Tabs moreIcon={<></>} defaultActiveKey={this.state.defaultTab} className='profile' items={tabItems} />
      </Modal>
    );
  }
}

export default withTranslation('studentProfileDialog')(Profile);
