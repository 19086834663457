/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Checkbox } from 'antd';

import Modal from '../Modal';

import PropTypes from 'prop-types';

import css from './ModalCategory.module.scss';

class ModalCategory extends Component {
  static propTypes = {
    categories: PropTypes.array,
    category: PropTypes.array,
    closeModalCategory: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      categoryIds: this.props.category || [],
    };
  }

  handleChangeCategoryIds = ({ id, name }) => {
    const categoryIds = this.state.categoryIds.slice();
    const catInd = categoryIds.findIndex(cat => cat.id === id);
    ~catInd ? categoryIds.splice(catInd, 1) : categoryIds.push({ id, name });
    this.setState({ categoryIds });
  };

  submitChangeCategory = () => {
    this.props.onChange(this.state.categoryIds);
  };

  showModalCategory = () => {
    const { category = [], categories = [] } = this.props;
    // const getValue = id => !!category.find(cat => cat.id === id);
    const getValue = id => !!this.state.categoryIds.find(cat => cat.id === id);
    const onClick =
      ({ id, name }) =>
      () =>
        this.handleChangeCategoryIds({ id, name });

    return categories?.map(({ id, name }) => (
      <div className={css.Flower} key={id}>
        <Checkbox checked={getValue(id)} data-qa={name} name={String(id)} onClick={onClick({ id, name })}>
          {name}
        </Checkbox>
      </div>
    ));
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onCancel={this.props.closeModalCategory}
        className={css['ModalFlower-categoryModal']}
        onOk={this.submitChangeCategory}
        okText={this.props.t('save')}
        width={600}
        title={this.props.title || this.props.t('title')}
        modalCategory
        manualCancel
        cardOn
      >
        <div className={css['ModalFlower-body']}>
          <div className={css['ModalFlower-body-blockButton']}>{this.showModalCategory()}</div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('modalCategory')(ModalCategory);
