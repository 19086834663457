/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Input, Modal } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './SendReviewModal.module.scss';

const { TextArea } = Input;

export class SendCommentModal extends Component {
  static propTypes = {
    cancelDeleteComment: PropTypes.func,
    isTransfer: PropTypes.bool,
    rejected: PropTypes.bool,
    submitDeleteComment: PropTypes.func,
    visible: PropTypes.bool,
    texts: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible && !this.props.visible) {
      this.setState({ comment: '' });
    }
  }

  changeComment = event => {
    this.setState({ comment: event.target.value });
  };

  clearForm = () => {
    this.setState({
      comment: '',
    });
  };

  handleSendReview = () => {
    const { comment } = this.state;
    this.props.submitDeleteComment(comment);
    this.clearForm();
  };

  texts = {
    reason: this.props.t ? this.props.t('reason') : this.props.texts.reason,
    denying: this.props.t ? this.props.t('denying') : this.props.texts.denying,
    comment: this.props.t ? this.props.t('comment') : this.props.texts.comment,
    deleting: this.props.t ? this.props.t('deleting') : this.props.texts.deleting,
    rescheduling: this.props.t ? this.props.t('rescheduling') : this.props.texts.rescheduling,
    max: this.props.t ? this.props.t('max') : this.props.texts.max,
  };

  render() {
    const { comment } = this.state;
    const { isTransfer, rejected } = this.props;

    return (
      <Modal
        open={this.props.open}
        width={604}
        onCancel={() => this.props.cancelDeleteComment(comment)}
        footer={null}
        zIndex={1005}
      >
        <div className={classNames(css['App-card-item'], css.SendReviewModal)}>
          <div className={css['SendReviewModal-header']}>
            <div>
              {this.texts.reason}{' '}
              {!isTransfer ? (rejected ? this.texts.denying : this.texts.deleting) : this.texts.rescheduling}
            </div>
          </div>
          <div className={css['SendReviewModal-restriction']}>{this.texts.max}</div>
          <TextArea
            className={css['SendReviewModal-textarea']}
            rows={7}
            onChange={this.changeComment}
            value={comment}
            maxLength={500}
          />
          <Button onClick={this.handleSendReview} disabled={!comment}>
            {this.texts.comment}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('sendCommentModal')(SendCommentModal);
