// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actionBar {
  padding-bottom: 1rem;
}

.createFieldButton {
  flex-flow: row;
  place-content: center;
  align-items: center;
  margin: 14px 0;
  display: flex;
}

.ant-tooltip-inner {
  max-width: 300px !important;
}

.actionBtn {
  padding: 0 8px 0 7px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/VarDirectory/Tabs/Users/Panels/css/AdditionalFields.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,mBAAmB;EACnB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAMA;EAHE,oBAAoB;AACtB","sourcesContent":[".actionBar {\n  padding-bottom: 1rem;\n}\n\n.createFieldButton {\n  display: flex;\n  flex-flow: row nowrap;\n  place-content: center center;\n  align-items: center;\n  margin: 14px 0;\n}\n\n.ant-tooltip-inner {\n  max-width: 300px !important;\n}\n\n.createField-table {\n  /* margin-top: 12px; */\n}\n\n.actionBtn {\n  padding: 0 8px 0 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
