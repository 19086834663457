import './constants';

import {
  ADD_POKA_TYPICAL_POSITION_FAIL,
  ADD_POKA_TYPICAL_POSITION_START,
  ADD_POKA_TYPICAL_POSITION_SUCCESS,
  CLEAR_ERROR_TEXT_POSITION,
  DELETE_POKA_TYPICAL_POSITION_FAIL,
  DELETE_POKA_TYPICAL_POSITION_START,
  DELETE_POKA_TYPICAL_POSITION_SUCCESS,
  EDIT_POKA_TYPICAL_POSITION_FAIL,
  EDIT_POKA_TYPICAL_POSITION_START,
  EDIT_POKA_TYPICAL_POSITION_SUCCESS,
  GET_MISMATCH_FAIL,
  GET_MISMATCH_START,
  GET_MISMATCH_SUCCESS,
  GET_POKA_POSITION_TEMPLATE_START,
  GET_POSITION_TEMPLATE_FAIL,
  GET_POSITION_TEMPLATE_SUCCESS,
  GET_TYPICAL_POSITIONS_LIST_FAIL,
  GET_TYPICAL_POSITIONS_LIST_START,
  GET_TYPICAL_POSITIONS_LIST_SUCCESS,
  IMPORT_POKA_POSITION_FILE_FAIL,
  IMPORT_POKA_POSITION_FILE_START,
  IMPORT_POKA_POSITION_FILE_SUCCESS,
  LOAD_POKA_TYPICAL_POSITIONS_FAIL,
  LOAD_POKA_TYPICAL_POSITIONS_START,
  LOAD_POKA_TYPICAL_POSITIONS_SUCCESS,
  SAVE_POKA_TYPICAL_POSITION_FILTERS,
  SAVE_POKA_TYPICAL_POSITION_SORT,
} from '@/store/poka_typicalPositions/constants';

const initialState = {
  typicalPositions: [],
  loadingTypicalPositions: false,
  errorTypicalPositions: false,
  positionsTotal: 0,
  positionsPageSize: 0,
  positionsPageNumber: 0,
  loadingAddPosition: false,
  errorAddPosition: false,
  errorText: null,
  loadingEditPosition: false,
  errorEditPosition: false,
  loadingDeletePosition: false,
  errorDeletePosition: false,
  positionsFilters: {},
  positionsSort: '',
  mismatch: false,
  loadingMismatch: false,
  errorMismatch: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case LOAD_POKA_TYPICAL_POSITIONS_START:
      return {
        ...state,
        loadingTypicalPositions: true,
        errorTypicalPositions: false,
        typicalPositions: [],
      };

    case LOAD_POKA_TYPICAL_POSITIONS_SUCCESS:
      return {
        ...state,
        loadingTypicalPositions: false,
        errorTypicalPositions: false,
        positionsTotal: response.totalElements,
        positionsPageSize: response.pageable.pageSize,
        positionsPageNumber: response.pageable.pageNumber,
        positionsCurrentPage: response.number,
        typicalPositions: response.content,
      };

    case LOAD_POKA_TYPICAL_POSITIONS_FAIL:
      return {
        ...state,
        loadingTypicalPositions: false,
        errorTypicalPositions: true,
        typicalPositions: [],
      };

    case ADD_POKA_TYPICAL_POSITION_START:
      return {
        ...state,
        loadingAddPosition: true,
        errorAddPosition: false,
      };
    case EDIT_POKA_TYPICAL_POSITION_START:
      return {
        ...state,
        loadingEditPosition: true,
        errorEditPosition: false,
      };

    case DELETE_POKA_TYPICAL_POSITION_START:
      return {
        ...state,
        loadingDeletePosition: true,
        errorDeletePosition: false,
      };

    case ADD_POKA_TYPICAL_POSITION_SUCCESS:
      return {
        ...state,
        loadingAddPosition: false,
        errorAddPosition: false,
      };
    case EDIT_POKA_TYPICAL_POSITION_SUCCESS:
      return {
        ...state,
        loadingEditPosition: false,
        errorEditPosition: false,
      };
    case DELETE_POKA_TYPICAL_POSITION_SUCCESS:
      return {
        ...state,
        loadingDeletePosition: false,
        errorDeletePosition: false,
      };

    case ADD_POKA_TYPICAL_POSITION_FAIL:
      return {
        ...state,
        loadingAddPosition: false,
        errorAddPosition: true,
        errorText: response,
      };

    case EDIT_POKA_TYPICAL_POSITION_FAIL:
      return {
        ...state,
        loadingEditPosition: false,
        errorEditPosition: true,
        errorText: response,
      };
    case DELETE_POKA_TYPICAL_POSITION_FAIL:
      return {
        ...state,
        loadingDeletePosition: false,
        errorDeletePosition: true,
      };

    case GET_TYPICAL_POSITIONS_LIST_START:
      return {
        ...state,
        loadingTypicalPositionsList: true,
        errorTypicalPositionsList: false,
        typicalPositionsList: [],
      };

    case GET_TYPICAL_POSITIONS_LIST_SUCCESS:
      return {
        ...state,
        loadingTypicalPositionsList: false,
        typicalPositionsList: response,
      };

    case GET_TYPICAL_POSITIONS_LIST_FAIL:
      return {
        ...state,
        loadingTypicalPositionsList: false,
        errorTypicalPositionsList: true,
      };

    case SAVE_POKA_TYPICAL_POSITION_FILTERS:
      return { ...state, positionsFilters: value };

    case SAVE_POKA_TYPICAL_POSITION_SORT:
      return { ...state, positionsSort: value };

    case GET_MISMATCH_START:
      return {
        ...state,
        loadingMismatch: true,
        errorMismatch: false,
      };

    case GET_MISMATCH_SUCCESS:
      return {
        ...state,
        loadingMismatch: false,
        mismatch: response.mismatch,
      };

    case GET_MISMATCH_FAIL:
      return {
        ...state,
        loadingMismatch: false,
        errorMismatch: true,
      };

    case CLEAR_ERROR_TEXT_POSITION:
      return { ...state, errorText: null };

    case GET_POKA_POSITION_TEMPLATE_START:
    case GET_POSITION_TEMPLATE_SUCCESS:
    case GET_POSITION_TEMPLATE_FAIL:
    case IMPORT_POKA_POSITION_FILE_SUCCESS:
    case IMPORT_POKA_POSITION_FILE_START:
    case IMPORT_POKA_POSITION_FILE_FAIL:
    default:
      return state;
  }
};
