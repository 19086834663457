/* eslint-disable */
import { D360_GET_RESULTS_FAIL, D360_GET_RESULTS_START, D360_GET_RESULTS_SUCCESS } from './constants';

const initialState = {
  loading: false,
  result: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // Request start
    case D360_GET_RESULTS_START:
      return { result: [], loading: true };

    // Request fail
    case D360_GET_RESULTS_FAIL:
      return { result: [], loading: false };

    case D360_GET_RESULTS_SUCCESS:
      return {
        loading: false,
        result: response.data,
      };

    // Default
    default:
      return state;
  }
};
