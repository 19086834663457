import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { exportIprs, getIprDetails, postIprFilters, saveIprSort } from '@/store/ipr/actions';

import { selectIpr } from '@/store/ipr/selectors';

import { Button, Dropdown, Table, Tooltip } from 'antd';
import RenderTableStatus from '@/components/RenderTableStatus';
import { ArrowRightOutlined } from '@ant-design/icons';

import {
  APPROVED_BY_ADMIN,
  APPROVED_BY_MANAGER,
  APPROVED_BY_USER,
  CHECK_APPROVED,
  DATE_FORMAT,
  FINISH_IPR,
  FINISHED,
  REJECTED_BY_ADMIN,
  RTN_WRK,
  VIEW_IPR,
  WAITING_BY_USER,
} from './constants';
import ModalActions from './Modal/ModalActions';
import ModalCheckApprove from './Modal/ModalCheckApprove';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../Career.module.scss';

const TableCareer = ({ refExport, refTable, refActions }) => {
  const { t } = useTranslation('career');
  const dispatch = useDispatch();

  const {
    iprLoading,
    iprTable,
    iprTotal,
    iprPageSize,
    iprPageNumber,
    iprCurrentPage,
    iprSort,
    iprFilters,
    exportLoading,
  } = useSelector(selectIpr);

  const [isIpr, setIsIpr] = useState({ obj: {}, open: false });

  const [isCheckAppr, setIsCheckAppr] = useState({ open: false, type: '', data: {} });

  const [isModalAction, setIsModalAction] = useState({ open: false, action: '', data: {} });

  const [activeSort, setActiveSort] = useState({ fio: false, date: false, dateModif: false });

  const showModalIPR = (obj, action) => {
    switch (action) {
      case RTN_WRK:
        setIsModalAction({ action, open: true, data: obj });
        break;

      case CHECK_APPROVED:
      case FINISH_IPR:
      case VIEW_IPR:
        setIsCheckAppr({ type: action, open: true, data: obj });
        if (obj?.id) {
          dispatch(getIprDetails(obj.id));
        }
        break;
    }

    setIsIpr({ obj, open: true });
  };

  const tableAction = item => {
    if (item.status === APPROVED_BY_USER) {
      return [
        // Проверить и согласовать
        {
          key: '1',
          label: t('checkApproved'),
          onClick: () => showModalIPR(item, CHECK_APPROVED),
        },
        // Отметить выполненное
        {
          key: '2',
          label: t('returnWork'),
          onClick: () => showModalIPR(item, RTN_WRK),
        },
      ];
    }

    if (item.status === APPROVED_BY_ADMIN || item.status === APPROVED_BY_MANAGER) {
      return [
        // Отметить выполненное
        {
          key: '1',
          label: t('markCompleted'),
          onClick: () => showModalIPR(item, FINISH_IPR),
        },
      ];
    }

    if (item.status === FINISHED || item.status === REJECTED_BY_ADMIN) {
      /* Просмотреть ИПР */
      return [
        {
          key: '1',
          label: t('viewIpr'),
          onClick: () => showModalIPR(item, VIEW_IPR),
        },
      ];
    }
  };

  const renderActionTable = item => {
    if (item.status === WAITING_BY_USER) return;

    return (
      <Dropdown menu={{ items: tableAction(item) }} trigger={['click']}>
        <Button className={css['Table-content-btnAction']} type='link'>
          <ArrowRightOutlined />
        </Button>
      </Dropdown>
    );
  };

  const columns = [
    {
      title: t('userTable'),
      dataIndex: 'userTable',
      key: '1',
    },
    {
      title: t('department'),
      dataIndex: 'department',
      key: '2',
    },
    {
      title: t('goalCategory'),
      dataIndex: 'goalCategory',
      key: '3',
      render: text => (
        <div className={css['Table-content-cell']}>
          <div className={css['Table-content-cell-goal']}>{text}</div>
        </div>
      ),
    },
    {
      title: t('manager'),
      dataIndex: 'manager',
      key: '4',
    },
    {
      title: t('deadline'),
      dataIndex: 'deadline',
      key: '5',
      width: 150,
    },
    {
      title: t('сompTable'),
      dataIndex: 'competencies',
      key: '6',
      render: (_, obj) => (
        <div className={css['Table-content-cell']}>
          <div className={css['Table-content-cell-comp']}>
            <Tooltip title={obj?.competencies?.length > 20 && obj.competencies}>{obj.competencies}</Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: t('progressTable'),
      dataIndex: 'progress',
      key: '7',
    },
    {
      title: t('statusTable'),
      dataIndex: 'statusText',
      key: '8',
      width: 100,
      render: (txt, value) => (
        <div className={css['Table-content-cell-status']}>
          <RenderTableStatus status={value?.status} text={txt} />
        </div>
      ),
    },
    {
      title: (
        <span ref={refActions}>
          <Tooltip title={t('action')}>
            <ArrowRightOutlined className={css['Table-content-cell-action']} />
          </Tooltip>
        </span>
      ),
      dataIndex: 'action',
      key: '9',
      render: (_, item) => renderActionTable(item),
      align: 'center',
      width: 80,
    },
  ];

  const dataSource = useMemo(
    () =>
      iprTable?.length > 0 &&
      iprTable.map(item => {
        let competencies = item?.competences?.map(i => i.name)?.join(', ');
        return {
          userTable: item.userDto.fullName,
          department: item.userDto.department,
          goalCategory: item.goalCategory,
          manager: item.userDto.manager,
          deadline: dayjs(item.deadline, DATE_FORMAT).format(DATE_FORMAT),
          competencies: competencies,
          progress: `${item.progress}%`,
          statusText: item.status.statusText,
          status: item.status.status,
          id: item.id,
          fullLastName: `${item.userDto.firstName} ${item.userDto.lastName}`,
        };
      }),
    [iprTable]
  );

  const sortFio = () => {
    setActiveSort({ fio: !activeSort.fio });
    dispatch(saveIprSort(!activeSort.fio ? 'fullName,desc' : ''));
    !activeSort.fio
      ? dispatch(postIprFilters(iprFilters ?? {}, 0, 'fullName,asc'))
      : dispatch(postIprFilters(iprFilters ?? {}, 0));
  };

  const sortDate = () => {
    setActiveSort({ date: !activeSort.date });
    dispatch(saveIprSort(!activeSort.date ? 'createdDate,asc' : ''));
    !activeSort.date
      ? dispatch(postIprFilters(iprFilters ?? {}, 0, 'createdDate,asc'))
      : dispatch(postIprFilters(iprFilters ?? {}, 0));
  };

  const sortDateModif = () => {
    setActiveSort({ dateModif: !activeSort.dateModif });
    dispatch(saveIprSort(!activeSort.dateModif ? 'lastModifiedBy,asc' : ''));
    !activeSort.dateModif
      ? dispatch(postIprFilters(iprFilters ?? {}, 0, 'lastModifiedBy,asc'))
      : dispatch(postIprFilters(iprFilters ?? {}, 0));
  };

  const onChangeTable = pagination => {
    dispatch(postIprFilters(iprFilters ?? {}, pagination.current - 1, iprSort));
  };

  const pagination = {
    total: iprTotal,
    pageSize: iprPageSize,
    pageNumber: iprPageNumber,
    current: iprCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const exportTable = () => {
    const date = new Date();
    const fileNameNotSplit = `pdp_requests(${date.toLocaleDateString()}).csv`;

    dispatch(
      exportIprs(iprFilters ?? {}, (blob, headers) => {
        const fileName = headers.get('Content-Disposition').split(['"'])[1] || fileNameNotSplit;
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  return (
    <div className={css['Table']}>
      <div className={css['Table-actions']}>
        {/* Блок сортировки + кнопка */}
        <div className={css['Table-actions-sort']}>
          <div className={css['Table-actions-sort-text']}>{t('sort')}</div>
          <div
            className={classNames(
              css['Table-actions-sort-fio'],
              activeSort.fio && iprSort && css['Table-actions-sort-active']
            )}
            onClick={sortFio}
          >
            {t('sortFio')}
          </div>
          <div
            className={classNames(
              css['Table-actions-sort-date'],
              activeSort.date && iprSort && css['Table-actions-sort-active']
            )}
            onClick={sortDate}
          >
            {t('sortDate')}
          </div>
          <div
            className={classNames(
              css['Table-actions-sort-dateModif'],
              activeSort.dateModif && iprSort && css['Table-actions-sort-active']
            )}
            onClick={sortDateModif}
          >
            {t('sortDateModif')}
          </div>
        </div>
        {/* конец блока сортировки + кнопка */}
        <div>
          <Button type='primary' size='large' onClick={exportTable} loading={exportLoading} ref={refExport}>
            {t('exportTable')}
          </Button>
        </div>
      </div>

      <div className={css['Table-content']} ref={refTable}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
          onChange={onChangeTable}
          rowKey={e => e.id}
          loading={iprLoading}
          scroll={{ x: 1000 }}
          size='small'
        />
      </div>

      {isModalAction.open && (
        <ModalActions
          open={isModalAction.open}
          onClose={() => setIsModalAction({ type: '', open: false, data: {} })}
          action={isModalAction.action}
          partsTable={isModalAction.data}
          iprId={isIpr.obj.id}
        />
      )}

      {isCheckAppr.open && (
        <ModalCheckApprove
          open={isCheckAppr.open}
          onClose={() => setIsCheckAppr({ ...isCheckAppr, open: false })}
          isIpr={isIpr}
          isModalAction={isModalAction}
          showModalAction={setIsModalAction}
          type={isCheckAppr.type}
        />
      )}
    </div>
  );
};

TableCareer.propTypes = {
  refExport: PropTypes.object,
  refTable: PropTypes.object,
  refActions: PropTypes.object,
};

export default TableCareer;
