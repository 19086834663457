export const CREATE_TILDA_START = 'CREATE_TILDA_START';
export const CREATE_TILDA_SUCCESS = 'CREATE_TILDA_SUCCESS';
export const CREATE_TILDA_FAIL = 'CREATE_TILDA_FAIL';

export const DELETE_TILDA_START = 'DELETE_TILDA_START';
export const DELETE_TILDA_SUCCESS = 'DELETE_TILDA_SUCCESS';
export const DELETE_TILDA_FAIL = 'DELETE_TILDA_FAIL';

export const GET_TILDA_START = 'GET_TILDA_START';
export const GET_TILDA_SUCCESS = 'GET_TILDA_SUCCESS';
export const GET_TILDA_FAIL = 'GET_TILDA_FAIL';
