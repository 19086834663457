/* eslint-disable */
import Quill from 'quill';

const Image = Quill.import('formats/image');

export default class ImageBlot extends Image {
  static get ATTRIBUTES() {
    return ['height', 'width'];
  }

  static create(value) {
    let node = super.create();

    if (typeof value === 'string') {
      node.setAttribute('src', value);
    } else {
      node.setAttribute('src', value.src);
      node.setAttribute('width', value.width);
    }

    return node;
  }

  static value(node) {
    let blot = {};
    blot.src = node.getAttribute('src');
    blot.width = node.getAttribute('width');

    return blot;
  }

  static formats(domNode) {
    return this.ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (this.constructor.ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
