import { GET_TIMEZONES_FAIL, GET_TIMEZONES_START, GET_TIMEZONES_SUCCESS } from './constance';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getTimezones = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/timezones`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TIMEZONES_START, GET_TIMEZONES_SUCCESS, GET_TIMEZONES_FAIL],
  onSuccess,
  onError,
});
