const ImageSvg = props => (
  <div>
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'>
      <g stroke='#2D3540' fill='none'>
        <path d='M1.142.5h10v8h-10z' />
        <path d='m1.642 8 9-7m0 7-9-7z' opacity='.6' />
      </g>
    </svg>
    {props.name}
  </div>
);

export default ImageSvg;
