export const GET_SETTINGS_VISIBILITY_START = 'GET_SETTINGS_VISIBILITY_START';
export const GET_SETTINGS_VISIBILITY_SUCCESS = 'GET_SETTINGS_VISIBILITY_SUCCESS';
export const GET_SETTINGS_VISIBILITY_FAIL = 'GET_SETTINGS_VISIBILITY_FAIL';

export const PUT_SETTINGS_VISIBILITY_START = 'PUT_SETTINGS_VISIBILITY_START';
export const PUT_SETTINGS_VISIBILITY_SUCCESS = 'PUT_SETTINGS_VISIBILITY_SUCCESS';
export const PUT_SETTINGS_VISIBILITY_FAIL = 'PUT_SETTINGS_VISIBILITY_FAIL';

export const DELETE_SETTINGS_VISIBILITY_START = 'DELETE_SETTINGS_VISIBILITY_START';
export const DELETE_SETTINGS_VISIBILITY_SUCCESS = 'DELETE_SETTINGS_VISIBILITY_SUCCESS';
export const DELETE_SETTINGS_VISIBILITY_FAIL = 'DELETE_SETTINGS_VISIBILITY_FAIL';

export const GET_COUNT_SETTINGS_VISIBILITY_START = 'GET_COUNT_SETTINGS_VISIBILITY_START';
export const GET_COUNT_SETTINGS_VISIBILITY_SUCCESS = 'GET_COUNT_SETTINGS_VISIBILITY_SUCCESS';
export const GET_COUNT_SETTINGS_VISIBILITY_FAIL = 'GET_COUNT_SETTINGS_VISIBILITY_FAIL';

export const GET_COUNT_TOPIC_SETTINGS_VISIBILITY_START = 'GET_COUNT_TOPIC_SETTINGS_VISIBILITY_START';
export const GET_COUNT_TOPIC_SETTINGS_VISIBILITY_SUCCESS = 'GET_COUNT_TOPIC_SETTINGS_VISIBILITY_SUCCESS';
export const GET_COUNT_TOPIC_SETTINGS_VISIBILITY_FAIL = 'GET_COUNT_TOPIC_SETTINGS_VISIBILITY_FAIL';

export const GET_USERS_SETTINGS_MODAL_VISIBILITY_START = 'GET_USERS_SETTINGS_MODAL_VISIBILITY_START';
export const GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_SUCCESS = 'GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_SUCCESS';
export const GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_FAIL = 'GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_FAIL';

export const GET_USERS_SETTINGS_TOPIC_VISIBILITY_START = 'GET_USERS_SETTINGS_TOPIC_VISIBILITY_START';
export const GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_SUCCESS = 'GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_SUCCESS';
export const GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_FAIL = 'GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_FAIL';

export const CLEAR_SETTINGS_VISIBILITY_SUCCESS = 'CLEAR_SETTINGS_VISIBILITY_SUCCESS';

export const SAVE_SETTINGS_VISIBILITY_SUCCESS = 'SAVE_SETTINGS_VISIBILITY_SUCCESS';
