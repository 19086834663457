/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUserStat } from '@/store/users/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Steps } from 'antd';
import { Content } from '@/components/Layout';
import { BiFilterAlt } from 'react-icons/bi';
import { FcBullish } from 'react-icons/fc';

import CompetenceList from './CompetenceList';
import RecommendationList from './RecommendationList';
import ResultList from './ResultList';
import SkillList from './SkillList';

import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import PropTypes from 'prop-types';

const { Step } = Steps;

export class Polus extends Component {
  static propTypes = {
    getUserStat: PropTypes.func,
    loading: PropTypes.bool,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentUser: {},
      currentStep: 0,
      selectedCompetence: null,
      selectedSkills: [],
      selectorWindowVisible: true,
      recommendationVisible: false,
      isLoading: false,
      hasErrors: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getUserStat(
      this.props.match.params.id,
      res => {
        if (res && res.user) {
          this.setState({
            currentUser: res.user,
            isLoading: false,
            hasErrors: false,
          });
        }
      },
      error => {
        this.setState({
          isLoading: false,
          hasErrors: true,
        });
        console.error('error:', error);
      }
    );
  }

  isButtonNextDisabled = () => {
    const { currentStep, selectedCompetence, selectedSkills } = this.state;

    if (currentStep === 0 && selectedCompetence !== null) {
      return false;
    }

    if (currentStep === 1 && selectedSkills.length > -1) {
      return false;
    }

    return true;
  };

  stepNext = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };

  stepBack = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep - 1,
      recommendationVisible: false,
    });
  };

  stepDone = () => {
    this.setState({
      recommendationVisible: true,
      selectorWindowVisible: false,
    });
  };

  handleOnSelectCompetence = competence => {
    this.setState({
      selectedCompetence: competence,
    });
  };

  handleOnSelectSkills = skills => {
    this.setState({
      selectedSkills: skills,
    });
  };

  handleToggleSelectorWindow = () => {
    const { selectorWindowVisible } = this.state;
    if (selectorWindowVisible) {
      this.setState({ selectorWindowVisible: false });
    } else {
      this.setState({ selectorWindowVisible: true });
    }
  };

  getSteps = () => {
    const { t, match } = this.props;
    const { currentUser, selectedCompetence, selectedSkills } = this.state;
    return [
      {
        title: t('selectCompetence'),
        content: <CompetenceList onSelectCompetence={this.handleOnSelectCompetence} match={match} t={t} />,
      },
      {
        title: t('selectSkills'),
        content: (
          <SkillList onSelectSkills={this.handleOnSelectSkills} match={match} competence={selectedCompetence} t={t} />
        ),
      },
      {
        title: t('checkResult'),
        content: <ResultList competence={selectedCompetence} skills={selectedSkills} t={t} />,
      },
    ];
  };

  render() {
    const { t } = this.props;
    const {
      currentStep,
      selectedSkills,
      recommendationVisible,
      selectorWindowVisible,
      currentUser,
      isLoading,
      hasErrors,
    } = this.state;

    const userName = currentUser.lastName + ' ' + currentUser.firstName + ' ' + currentUser.middleName;

    const steps = this.getSteps();

    return (
      <div>
        <Content>
          <div>
            <h2
              style={{
                marginBottom: '2rem',
                borderBottom: '1px dotted #ddd',
                display: 'inline-block',
              }}
            >
              <FcBullish />
              &nbsp;{userName || t('loading')}
            </h2>
          </div>

          <div
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type='ghost'
              disabled={!recommendationVisible}
              style={{
                marginLeft: '0.5rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              onClick={() => this.handleToggleSelectorWindow()}
            >
              <BiFilterAlt />
              &nbsp;
              {t('summary')}
            </Button>
          </div>

          {selectorWindowVisible && !isLoading && !hasErrors && (
            <div>
              <Steps current={currentStep}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div
                className='steps-content'
                style={{
                  marginTop: '2rem',
                  height: '100%',
                  minHeight: '100%',
                  backgroundColor: 'white',
                }}
              >
                {steps[currentStep].content}
              </div>
              <div className='steps-actions' style={{ marginTop: '2rem', textAlign: 'center' }}>
                <Button typeof='default' onClick={() => this.stepBack()} disabled={!(currentStep > 0)}>
                  {t('prevButton')}
                </Button>

                <Button
                  type='primary'
                  onClick={() => this.stepDone()}
                  disabled={!(currentStep === steps.length - 1)}
                  style={{ margin: '0 1rem' }}
                >
                  {t('doneButton')}
                </Button>

                <Button type='primary' onClick={() => this.stepNext()} disabled={this.isButtonNextDisabled()}>
                  {t('nextButton')}
                </Button>
              </div>
            </div>
          )}

          {recommendationVisible && <RecommendationList currentUser={currentUser} skills={selectedSkills} t={t} />}
        </Content>
      </div>
    );
  }
}

const mapStateToProps = createSelector([usersSelect], users => ({
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getUserStat,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticPolus')(Polus));
