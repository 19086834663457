import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getUsersForInspection } from '@/store/inspection/actions';

import { selectInspection } from '@/store/inspection/selectors';

import { Col, DatePicker, Form, Row, Select, Switch } from 'antd';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './Inspectors.module.scss';

const DATE_FORMAT = 'DD.MM.YYYY';

const Inspectors = ({ setIsInspectors, isInspectors, isPeriodicAssignment }) => {
  const { t } = useTranslation('inspectorsFilter');
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { usersForInspections, isLoading } = useSelector(selectInspection);

  const disabledDate = current => current && current < dayjs().endOf('day');

  const handleGetUsers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getUsersForInspection(fullName));
    }
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetUsers, 1000), []);

  useEffect(() => {
    return () => getStudentsListDebounced.cancel();
  });

  useEffect(() => {
    if (isPeriodicAssignment) {
      const clearInspecors = { visible: false, deadlineDate: null, inspectors: [] };
      setIsInspectors({ ...clearInspecors });
      form.resetFields();
    }
  }, [isPeriodicAssignment]);

  return (
    <div className={css['Inspectors']}>
      <Form form={form}>
        <Row>
          <Col span={12}>
            <Form.Item label={t('addInspectors')}>
              <Switch
                className={css['Inspectors-switch']}
                value={isInspectors.visible}
                onChange={e => setIsInspectors({ ...isInspectors, visible: e })}
              />
            </Form.Item>
          </Col>
          {isInspectors.visible && (
            <Col span={12}>
              <Form.Item layout='vertical' label={t('inspectionDeadline')} required>
                <DatePicker
                  onChange={e =>
                    setIsInspectors({ ...isInspectors, deadlineDate: e ? dayjs(e).format(DATE_FORMAT) : null })
                  }
                  className={css['Inspectors-datepicker']}
                  disabledDate={disabledDate}
                  format={DATE_FORMAT}
                  placement='topRight'
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {isInspectors.visible && (
          <Row>
            <Col span={24}>
              <Form.Item name='inspectors' layout='vertical' label={t('inspectors')} required>
                <Select
                  allowClear
                  mode='multiple'
                  placeholder={t('inspectorPlaceholder')}
                  onSearch={getStudentsListDebounced}
                  onChange={e => setIsInspectors({ ...isInspectors, inspectors: e })}
                  loading={isLoading.getUsersForInspection}
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  defaultActiveFirstOption={false}
                  placement='topRight'
                >
                  {usersForInspections?.map(user => (
                    <Select.Option value={user.id} key={user.id} label={`${user.firstName} ${user.lastName}`}>
                      {user.firstName} {user.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

Inspectors.propTypes = {
  setIsInspectors: PropTypes.func,
  isInspectors: PropTypes.object,
};

export default Inspectors;
