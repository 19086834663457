/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { TAG_DELETE, TAG_UPDATE } from '@/constants/permissions';

export default class TagItem extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    tag: PropTypes.object.isRequired,
    shared: PropTypes.bool,
  };

  handleEdit = () => this.props.onEdit(this.props.tag);

  handleDelete = () => this.props.onDelete(this.props.tag);

  render() {
    const { tag, shared, currentUser, t } = this.props;
    return (
      <div className={css.TagItem}>
        <div className={css['TagItem-width']} data-qa='tagItem'>
          {Utils.maxTextValue(tag.name) ? (
            <Tooltip title={tag.name} placement='topLeft'>
              {tag.name}
            </Tooltip>
          ) : (
            tag.name
          )}
        </div>
        {!shared && (
          <div className={css['TagItem-container']}>
            {currentUser.authorities.includes(TAG_UPDATE) && (
              <Tooltip title={t('editButton')} placement='left'>
                <FormOutlined
                  size='small'
                  data-qa='editCompetenceBtn'
                  className={css['TagItem-icon-edit']}
                  onClick={this.handleEdit}
                />
              </Tooltip>
            )}
            {currentUser.authorities.includes(TAG_DELETE) && (
              <Tooltip title={t('deleteButton')}>
                <DeleteOutlined
                  size='small'
                  data-qa='deleteCompetenceBtn'
                  className={css['TagItem-icon-delete']}
                  onClick={this.handleDelete}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  }
}
