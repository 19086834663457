import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAccess } from '@/store/access/selectors';

import { Checkbox, Collapse, Typography } from 'antd';

import PermissionRow from './PermissionRow';

import PropTypes from 'prop-types';

import css from '../EditRole.module.scss';

const { Text } = Typography;

const GroupPermission = memo(({ group, selectedPermission, setSelectedPermission }) => {
  const { defaultPermissionsIds } = useSelector(selectAccess);

  const [localState, setLocalState] = useState([]);

  useEffect(() => {
    let arr = [];
    if (selectedPermission.length > 0) {
      selectedPermission.forEach(selPerm => {
        if (group.permissions && group.permissions.length > 0) {
          group.permissions.forEach(perm => {
            if (selPerm === perm.id) {
              arr.push(perm.id);
            }
          });
        }
      });
      setLocalState(arr);
    } else if (defaultPermissionsIds.length > 0) {
      defaultPermissionsIds.forEach(id => {
        if (group.permissions && group.permissions.length > 0) {
          group.permissions.forEach(perm => {
            if (id === perm.id) {
              arr.push(perm.id);
            }
          });
        }
      });
      setLocalState(arr);
    }
  }, [selectedPermission, defaultPermissionsIds]);

  const selectAllPermission = action => {
    let arr = [...selectedPermission];
    if (action) {
      group.permissions.forEach(perm => {
        if (!localState.includes(perm.id)) {
          arr.push(perm.id);
        }
      });
      setSelectedPermission(arr);
    } else {
      localState.forEach(local => {
        if (arr.includes(local) && !defaultPermissionsIds.includes(local)) {
          let index = arr.indexOf(local);
          arr.splice(index, 1);
        }
      });
      setSelectedPermission(arr);
    }
  };

  const selectPermission = (action, permissionId) => {
    let arr = [...selectedPermission];
    if (action) {
      arr.push(permissionId);
      setSelectedPermission(arr);
    } else {
      let index = arr.indexOf(permissionId);
      arr.splice(index, 1);
      setSelectedPermission(arr);
    }
  };

  const groupHeader = () => {
    return (
      <div className={css['main__block-checkbox']}>
        <Checkbox
          indeterminate={group.permissions?.length !== localState.length && localState.length !== 0}
          checked={group.permissions?.length === localState.length && localState.length !== 0}
          onChange={event => selectAllPermission(event.target.checked)}
        />
        <Text>{group.name}</Text>
      </div>
    );
  };

  const render = () => {
    return (
      <Collapse
        items={[
          {
            key: group.name,
            label: groupHeader(),
            children: group.permissions?.map(perm => (
              <PermissionRow
                permission={perm}
                localState={localState}
                onChange={(event, permissionId) => selectPermission(event.target.checked, permissionId)}
                key={perm.id}
              />
            )),
          },
        ]}
      />
    );
  };

  return render();
});

GroupPermission.prototype = {
  group: PropTypes.object.isRequired,
  selectedRole: PropTypes.object,
};

export default GroupPermission;
