import {
  APPROVED,
  APPROVED_BY_MANAGER,
  APPROVED_BY_USER,
  EXPIRED,
  FINISHED,
  IN_PROGRESS,
  ON_REGISTRATION,
  PLANNED,
  REJECTED,
  REJECTED_BY_MANAGER,
  REJECTED_BY_USER,
  SCHEDULED,
  WAITING,
  WAITING_BY_USER,
} from '@/constants/statuses';

export const MAX_TOPIC_NAME = 25;
export const DELETED = 'DELETED';

export const statusCourse = t => [
  { id: 1, value: WAITING, label: t('pendingManager') },
  { id: 2, value: APPROVED, label: t('approvedAdmin') },
  { id: 3, value: REJECTED, label: t('denied') },
  { id: 4, value: WAITING_BY_USER, label: t('pending') },
  { id: 5, value: APPROVED_BY_USER, label: t('confirmed') },
  { id: 6, value: REJECTED_BY_USER, label: t('decline') },
  { id: 7, value: FINISHED, label: t('review') },
  { id: 8, value: APPROVED_BY_MANAGER, label: t('pendingApproval') },
  { id: 9, value: REJECTED_BY_MANAGER, label: t('deniedManager') },
  { id: 10, value: ON_REGISTRATION, label: t('registration') },
  { id: 11, value: EXPIRED, label: t('completed') },
  { id: 12, value: IN_PROGRESS, label: t('inProgress') },
  { id: 13, value: PLANNED, label: t('planned') },
];

export const monthCourse = t => [
  { id: 1, value: '1', label: t('january') },
  { id: 2, value: '2', label: t('february') },
  { id: 3, value: '3', label: t('march') },
  { id: 4, value: '4', label: t('april') },
  { id: 5, value: '5', label: t('may') },
  { id: 6, value: '6', label: t('june') },
  { id: 7, value: '7', label: t('july') },
  { id: 8, value: '8', label: t('august') },
  { id: 9, value: '9', label: t('september') },
  { id: 10, value: '10', label: t('october') },
  { id: 11, value: '11', label: t('november') },
  { id: 12, value: '12', label: t('december') },
];

export const defaultStatuses = isAdmin =>
  isAdmin ? [APPROVED_BY_MANAGER, APPROVED, WAITING, REJECTED_BY_USER, ON_REGISTRATION] : [WAITING];

export const filters = (
  t,
  onSelectAllStatuses,
  yearsOptions,
  typesOptions,
  usersOptions,
  managersOptions,
  departmentsOptions,
  functionsOptions,
  domainCompany,
  changeTypeSearch
) =>
  [
    {
      label: t('labelFilters.0'),
      placeholder: t('placeholderFilters.0'),
      name: 'userId',
      inputs: 'select',
      showSearch: true,
      options: usersOptions,
      filterOption: false,
      onSearch: e => changeTypeSearch(e, 'userId'),
    },
    domainCompany?.hasManager && {
      label: t('labelFilters.1'),
      placeholder: t('placeholderFilters.1'),
      name: 'managerId',
      inputs: 'select',
      showSearch: true,
      options: managersOptions,
      filterOption: false,
      onSearch: e => changeTypeSearch(e, 'managerId'),
    },
    { label: t('labelFilters.2'), placeholder: t('placeholderFilters.2'), name: 'name', inputs: 'input' },
    domainCompany.hasAdditionalInfo && {
      label: t('labelFilters.3'),
      placeholder: t('placeholderFilters.3'),
      name: 'department',
      inputs: 'select',
      options: departmentsOptions,
      showSearch: true,
      optionFilterProp: 'label',
      onSearch: e => changeTypeSearch(e, 'department'),
    },
    domainCompany.hasAdditionalInfo && {
      label: t('labelFilters.4'),
      placeholder: t('placeholderFilters.4'),
      name: 'function',
      inputs: 'select',
      options: functionsOptions,
      showSearch: true,
      optionFilterProp: 'label',
      onSearch: e => changeTypeSearch(e, 'function'),
    },
    {
      label: t('labelFilters.5'),
      placeholder: t('placeholderFilters.5'),
      name: 'month',
      inputs: 'select',
      options: monthCourse(t),
    },
    // { label: t('labelFilters.6'), placeholder: t('placeholderFilters.6'), name: 'company', inputs: 'select' },
    {
      label: t('labelFilters.7'),
      placeholder: t('placeholderFilters.7'),
      name: 'year',
      inputs: 'select',
      options: yearsOptions,
    },
    {
      label: t('labelFilters.8'),
      placeholder: t('placeholderFilters.8'),
      name: 'topicTypeLabelIds',
      inputs: 'select',
      showSearch: true,
      optionFilterProp: 'label',
      mode: 'multiple',
      options: typesOptions,
    },
    {
      label: t('labelFilters.9'),
      placeholder: t('placeholderFilters.9'),
      name: 'status',
      inputs: 'select',
      options: statusCourse(t),
      showSearch: true,
      optionFilterProp: 'label',
      mode: 'multiple',
      labelButton: {
        onClick: onSelectAllStatuses,
        text: t('selectAll'),
      },
    },
  ].filter(Boolean);

export const getStatusTag = status => {
  const statusMap = {
    [WAITING]: { color: 'gold', label: 'pendingManager' },
    [APPROVED]: { color: 'green', label: 'approvedAdmin' },
    [REJECTED]: { color: 'red', label: 'denied' },
    [WAITING_BY_USER]: { color: 'blue', label: 'pending' },
    [APPROVED_BY_USER]: { color: 'green', label: 'confirmed' },
    [REJECTED_BY_USER]: { color: 'red', label: 'decline' },
    [FINISHED]: { color: 'green', label: 'review' },
    [APPROVED_BY_MANAGER]: { color: 'gold', label: 'pendingApproval' },
    [REJECTED_BY_MANAGER]: { color: 'red', label: 'deniedManager' },
    [ON_REGISTRATION]: { color: 'blue', label: 'registration' },
    [EXPIRED]: { color: 'green', label: 'completed' },
    [IN_PROGRESS]: { color: 'blue', label: 'inProgress' },
    [PLANNED]: { color: 'gold', label: 'planned' },
  };

  return statusMap?.[status] ?? { label: '', color: '' };
};

export const DISABLE_COURSE_STATUSES = [FINISHED, IN_PROGRESS, APPROVED_BY_USER, WAITING_BY_USER];

export const NOT_ADMIN_DISABLE_COURSE_STATUSES = [EXPIRED, REJECTED, APPROVED, ON_REGISTRATION, REJECTED_BY_USER];

export const DEFAULT_MODALS_DATA = {
  reasonTransfer: false,
  rejectedCourse: false,
  rejectUsersModal: false,
  deleteCourseModal: false,
  dateModal: false,
  rejectedComment: false,
  commentModal: false,
  isUpdate: false,
};

export const DEFAULT_SELECTED_ROWS = {
  keys: [],
  statuses: [],
  data: [],
};

export const actionStatuses = {
  reschedule: [WAITING, REJECTED_BY_MANAGER, APPROVED_BY_MANAGER, APPROVED, REJECTED, REJECTED_BY_USER],
  approve: [WAITING, REJECTED_BY_MANAGER],
  approveAdmin: [APPROVED_BY_MANAGER, REJECTED],
  deny: [WAITING, APPROVED_BY_MANAGER],
  denyAdmin: [APPROVED, REJECTED_BY_USER],
  confirmRegistration: [ON_REGISTRATION],
  didntGo: [EXPIRED],
  delete: [APPROVED_BY_MANAGER, REJECTED_BY_MANAGER, WAITING, REJECTED],
};

export const scheduledStatuses = [
  WAITING,
  REJECTED_BY_MANAGER,
  APPROVED_BY_MANAGER,
  APPROVED,
  REJECTED,
  REJECTED_BY_USER,
  SCHEDULED,
  FINISHED,
];

// Объект для сопоставления статуса с условиями фильтрации
export const massStatusConditions = {
  [APPROVED]: [WAITING, REJECTED_BY_MANAGER, APPROVED_BY_MANAGER, REJECTED],
  [REJECTED]: [WAITING, APPROVED_BY_MANAGER, APPROVED, REJECTED_BY_USER],
  [APPROVED_BY_MANAGER]: [WAITING, REJECTED_BY_MANAGER],
  [REJECTED_BY_MANAGER]: [WAITING, APPROVED_BY_MANAGER],
  [APPROVED_BY_USER]: [ON_REGISTRATION],
};
