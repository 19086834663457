/* eslint-disable */
import {
  DELETE_GROUP_FAIL,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
  GET_GROUPS_FAIL,
  GET_GROUPS_START,
  GET_GROUPS_SUCCESS,
  POST_GROUPS_FAIL,
  POST_GROUPS_START,
  POST_GROUPS_SUCCESS,
  PUT_GROUPS_FAIL,
  PUT_GROUPS_START,
  PUT_GROUPS_SUCCESS,
  SAVE_GROUPS_FILTERS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  groupsLoading: false,
  groupsTable: [],
  groupsTotal: null,
  groupsPageSize: null,
  groupsPageNumber: null,
  groupsCurrentPage: null,
  groupsFilters: '',
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_GROUPS_START:
    case DELETE_GROUP_START:
    case POST_GROUPS_START:
    case PUT_GROUPS_START:
      return { ...state, isLoading: true };

    case POST_GROUPS_SUCCESS:
    case PUT_GROUPS_SUCCESS:
    case DELETE_GROUP_SUCCESS:
      return { ...state, isLoading: false };

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groupsLoading: false,
        groupsTable: response.content,
        groupsTotal: response.totalElements,
        groupsPageSize: response.size,
        groupsPageNumber: response.totalPages,
        groupsCurrentPage: response.number,
      };

    case GET_GROUPS_FAIL:
    case DELETE_GROUP_FAIL:
    case POST_GROUPS_FAIL:
    case PUT_GROUPS_FAIL:
      return { ...state, groupsLoading: false, isError: true };

    case SAVE_GROUPS_FILTERS:
      return { ...state, groupsFilters: value };

    default:
      return { ...state };
  }
};
