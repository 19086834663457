import {
  GET_BITRIX_WEBHOOK_FAIL,
  GET_BITRIX_WEBHOOK_START,
  GET_BITRIX_WEBHOOK_SUCCESS,
  GET_TYPEFORM_TOKEN_FAIL,
  GET_TYPEFORM_TOKEN_START,
  GET_TYPEFORM_TOKEN_SUCCESS,
  GET_WEBINAR_TOKEN_FAIL,
  GET_WEBINAR_TOKEN_START,
  GET_WEBINAR_TOKEN_SUCCESS,
  UPDATE_BITRIX_WEBHOOK_FAIL,
  UPDATE_BITRIX_WEBHOOK_START,
  UPDATE_BITRIX_WEBHOOK_SUCCESS,
  UPDATE_TYPEFORM_TOKEN_FAIL,
  UPDATE_TYPEFORM_TOKEN_START,
  UPDATE_TYPEFORM_TOKEN_SUCCESS,
  UPDATE_WEBINAR_TOKEN_FAIL,
  UPDATE_WEBINAR_TOKEN_START,
  UPDATE_WEBINAR_TOKEN_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

// Typeform

export const setTypeformToken = token => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/company/typeform/token`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    token,
  },
  types: [UPDATE_TYPEFORM_TOKEN_START, UPDATE_TYPEFORM_TOKEN_SUCCESS, UPDATE_TYPEFORM_TOKEN_FAIL],
});

export const getTypeformToken = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/company/typeform/token`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPEFORM_TOKEN_START, GET_TYPEFORM_TOKEN_SUCCESS, GET_TYPEFORM_TOKEN_FAIL],
});

// WebinarRu
export const setWebinarToken = token => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/company/webinarru/token`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    token,
  },
  types: [UPDATE_WEBINAR_TOKEN_START, UPDATE_WEBINAR_TOKEN_SUCCESS, UPDATE_WEBINAR_TOKEN_FAIL],
});

// Получение токена
export const getWebinarToken = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/company/webinarru/token`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_WEBINAR_TOKEN_START, GET_WEBINAR_TOKEN_SUCCESS, GET_WEBINAR_TOKEN_FAIL],
});

// получение Bitrix Webhook
export const getBitrixWebhook = () => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/company/bitrix/webhook`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_BITRIX_WEBHOOK_START, GET_BITRIX_WEBHOOK_SUCCESS, GET_BITRIX_WEBHOOK_FAIL],
  };
};

// обновление Bitrix Webhook
export const setBitrixWebhook = webhook => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/company/bitrix/webhook`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    webhook,
  },
  types: [UPDATE_BITRIX_WEBHOOK_START, UPDATE_BITRIX_WEBHOOK_SUCCESS, UPDATE_BITRIX_WEBHOOK_FAIL],
});
