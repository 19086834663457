/* eslint-disable */
// @ts-nocheck
import usersService from '../services/users-service';

export const searchUsers = (query: string) =>
  usersService
    .getUsers({ fullName: query })
    .then(response => response.map(item => ({ value: item.id, label: `${item.lastName} ${item.firstName}` })));

export const searchUsersLogin = (query: string) =>
  usersService
    .getUsers({ fullName: query })
    .then(response => response.map(item => ({ value: item.login, label: `${item.lastName} ${item.firstName}` })));

export const searchUsersLoginByCompany = (fullName: string, companyId: string) =>
  usersService
    .getUsers({ fullName, companyId })
    .then(response => response.map(item => ({ value: item.login, label: `${item.lastName} ${item.firstName}` })));
