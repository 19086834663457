/* eslint-disable */
import {
  D360_ACTIVE_PROJECT_FAIL,
  D360_ACTIVE_PROJECT_START,
  D360_ACTIVE_PROJECT_SUCCESS,
  D360_COPY_PROJECT_FAIL,
  D360_COPY_PROJECT_START,
  D360_COPY_PROJECT_SUCCESS,
  D360_CREATE_PROJECT_FAIL,
  D360_CREATE_PROJECT_START,
  D360_CREATE_PROJECT_SUCCESS,
  D360_DEACTIVATE_PROJECT_FAIL,
  D360_DEACTIVATE_PROJECT_START,
  D360_DEACTIVATE_PROJECT_SUCCESS,
  D360_DELETE_PROJECT_FAIL,
  D360_DELETE_PROJECT_START,
  D360_DELETE_PROJECT_SUCCESS,
  D360_GET_PROJECTS_FAIL,
  D360_GET_PROJECTS_START,
  D360_GET_PROJECTS_SUCCESS,
  D360_UPDATE_PROJECT_FAIL,
  D360_UPDATE_PROJECT_START,
  D360_UPDATE_PROJECT_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  projects: [],
  pageTotal: 0,
  pagePer: 0,
  pageCurrent: 1,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // Request start
    case D360_CREATE_PROJECT_START:
    case D360_UPDATE_PROJECT_START:
    case D360_COPY_PROJECT_START:
    case D360_DELETE_PROJECT_START:
    case D360_GET_PROJECTS_START:
    case D360_ACTIVE_PROJECT_START:
    case D360_DEACTIVATE_PROJECT_START:
      return { ...state, loading: true };

    // Request fail
    case D360_CREATE_PROJECT_FAIL:
    case D360_UPDATE_PROJECT_FAIL:
    case D360_COPY_PROJECT_FAIL:
    case D360_DELETE_PROJECT_FAIL:
    case D360_GET_PROJECTS_FAIL:
    case D360_ACTIVE_PROJECT_FAIL:
    case D360_DEACTIVATE_PROJECT_FAIL:
      return { ...state, loading: false };

    // Create
    case D360_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [response.data].concat(state.projects),
      };

    // Update
    case D360_UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: state.projects.map(project => {
          if (project.id === response.data.id) {
            project = response.data;
          }

          return project;
        }),
      };

    // Copy
    case D360_COPY_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: response.data.data,
      };

    // Delete
    case D360_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: state.projects.filter(project => project.id !== response.data.id),
      };

    // Get All Projects
    case D360_GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: response.data.data,
        pageCurrent: response.data.current_page,
        pagePer: response.data.per_page,
        pageTotal: response.data.total,
      };

    case D360_ACTIVE_PROJECT_SUCCESS:
    case D360_DEACTIVATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: state.projects.map(project => {
          if (project.id === response.data.id) {
            project = response.data;
          }

          return project;
        }),
      };

    // Default
    default:
      return state;
  }
};
