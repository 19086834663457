/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getTypeformAnswers } from '@/store/typeform/actions';

import { selectTypeform } from '@/store/typeform/selectors';

import { Button, Table } from 'antd';

import TypeformModal from '../TypeformModal/TypeformModal';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

/**
 * Отображение блока таблицы для тайп-формы
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const TypeformTable = props => {
  const { t } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  // Дата для таблицы
  const [data, setData] = useState([]);
  // Отображение загрузки
  const [currentKey, setCurrentKey] = useState('');

  // Получение ответов
  const [formId, setFormId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (props.typeformId && formId === null) {
      props.getTypeformAnswers(props.typeformId, {
        page: 0,
        size: 10,
      });
    }
  }, [props.typeformId]);

  useEffect(() => {
    if (loaded === false && props.answersSelector && props.answersSelector.answersResponse) {
      setLoaded(true);
      parseData(props.answersSelector);
    }
  }, [props.answersSelector]);

  const btnHeaderTable = record => (
    <Button
      onClick={() => {
        handleMoreClick(record.key);
      }}
    >
      {t('more')}
    </Button>
  );

  // Задаём колонки
  const columns = [
    {
      title: `${t('typeformColumns.0')}`,
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: `${t('typeformColumns.1')}`,
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: `${t('typeformColumns.2')}`,
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: `${t('typeformColumns.3')}`,
      dataIndex: 'more',
      key: 'more',
      render: (text, record) => btnHeaderTable(record),
    },
  ];

  // Сборка данных для таблицы
  const parseData = () => {
    if (props.answersSelector.answersResponse) {
      setData(
        props.answersSelector.answersResponse.content.map(item => {
          const tableData = {};
          tableData.key = item.responseId;
          tableData.user = Utils.extractUsername(item);
          tableData.result = `${item.result.ENDING.title}(${item.result.SCORE})`;
          tableData.date = dayjs(item.submittedAt).format('DD.MM.YYYY');
          return tableData;
        })
      );
    }
  };

  const handleCloseClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleMoreClick = id => {
    setCurrentKey(id);
    setIsModalOpen(!isModalOpen);
  };

  const onTableChange = (pagination, filters) => {
    props.getTypeformAnswers(props.typeformId, { page: pagination.current - 1, size: props.answersSize });
  };

  const pagination = {
    total: props.answersTotal,
    pageSize: props.sizeTotal,
    pageNumber: props.answersPage,
  };

  /**
   * Генерация представления
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div>
        <TypeformModal
          visible={isModalOpen}
          onOk={handleCloseClick}
          onCancel={handleCloseClick}
          answers={props.answersSelector}
          currentKey={currentKey}
        />
        <Table
          columns={columns}
          dataSource={data}
          onChange={onTableChange}
          loading={props.loading}
          pagination={pagination}
        />
      </div>
    );
  };
  return render();
};

TypeformTable.propTypes = {
  getTypeformAnswers: PropTypes.func,
  typeformId: PropTypes.number,
  loading: PropTypes.bool,
  answersSelector: PropTypes.object,
};

const mapStateToProps = createSelector([selectTypeform], answers => ({
  answersSelector: answers.answers,
  loading: answers.loading,
  answersTotal: answers.answersTotal,
  answersSize: answers.answersSize,
  answersPage: answers.answersPage,
}));

const mapActionsToProps = {
  getTypeformAnswers,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('typeform')(TypeformTable));
