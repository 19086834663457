// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-RrXrzZ.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-xfeRiF .admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-label-nhyV3w[data-value="Montserrat"]:before, .admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-RrXrzZ.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-xfeRiF .admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-item-vQLhPl[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-Montserrat-ESMCTO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__TypeBadgeNew-GhFdhN {
  align-items: center;
  margin-right: 4px;
  font-size: 12px;
  display: flex;
}

.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__TypeBadgeNew-GhFdhN svg {
  fill: #00000073;
}

.admin-src-components-TypeBadgeNew-TypeBadgeNew-module__TypeBadgeNew-GhFdhN svg path {
  fill: #00000073;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/TypeBadgeNew/TypeBadgeNew.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,aAAa;AACf;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.TypeBadgeNew {\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n  margin-right: 4px;\n}\n.TypeBadgeNew :global svg {\n  fill: rgba(0, 0, 0, 0.4509803922);\n}\n.TypeBadgeNew :global svg path {\n  fill: rgba(0, 0, 0, 0.4509803922);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-RrXrzZ`,
	"qlPicker": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-RrXrzZ`,
	"ql-font": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-xfeRiF`,
	"qlFont": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-xfeRiF`,
	"ql-picker-label": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-label-nhyV3w`,
	"qlPickerLabel": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-label-nhyV3w`,
	"ql-picker-item": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-item-vQLhPl`,
	"qlPickerItem": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-picker-item-vQLhPl`,
	"ql-font-Montserrat": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-Montserrat-ESMCTO`,
	"qlFontMontserrat": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__ql-font-Montserrat-ESMCTO`,
	"TypeBadgeNew": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__TypeBadgeNew-GhFdhN`,
	"typeBadgeNew": `admin-src-components-TypeBadgeNew-TypeBadgeNew-module__TypeBadgeNew-GhFdhN`
};
export default ___CSS_LOADER_EXPORT___;
