import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectBlocks } from '@/store/lms/selectors';
import { selectPokaValidationUser } from '@/store/poka_validation_user/selectors';

import { Col, Divider, Row, Tag } from 'antd';
import DataTestInfo from '@/pages/Topics/TopicDetailsDialog/DataTestInfo';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import PieMars from '@shared/components/PieMars';
import useIsMobile from '@shared/hooks/useIsMobile';

import css from '../PokaValidationUser.module.scss';

const AboutSkill = () => {
  const { t } = useTranslation('pokaValidation');
  const [isShow, setIsShow] = useState(false);
  const [isMobile] = useIsMobile(752);
  const { shortPoka, statePoka } = useSelector(selectPokaValidationUser);

  const { blockStat } = useSelector(selectBlocks);

  const columns = [
    {
      title: t('blockTable'),
      dataIndex: 'block',
      key: '1',
    },
    {
      title: t('statusTable'),
      dataIndex: 'status',
      key: '2',
    },
    {
      title: t('dateStartTable'),
      dataIndex: 'startDate',
      key: '3',
    },
    {
      title: t('dateEndTable'),
      dataIndex: 'endDate',
      key: '4',
    },
  ];

  const renderTagArr = arr => {
    if (Array.isArray(arr) && arr.length > 0) {
      return arr.map((item, index) => (
        <Tag className={css['AboutSkill-tag']} key={index}>
          {item}
        </Tag>
      ));
    }
  };
  return (
    <div className={css['AboutSkill']}>
      <Row gutter={[0, 16]} align='middle'>
        {/* О навыке */}
        <Col span={24} className={css['AboutSkill-name']}>
          {t('aboutTopic')}
        </Col>

        {/* Название навыка */}
        <Col xs={24} lg={5}>
          <strong>{t('pokaName')}</strong>
        </Col>
        <Col xs={24} lg={19}>
          <Link to={`/topic/${statePoka?.topicId}`} target='_blank' rel='noopener noreferrer'>
            {statePoka?.topicName}
          </Link>
        </Col>

        {/* Целевая оценка с расшифровкой */}
        <Col xs={24} lg={5}>
          <strong>{t('targetAssessment')}</strong>
        </Col>
        <Col xs={24} lg={19}>
          <div className={css['AboutSkill-lvl']}>
            <PieMars value={shortPoka?.endorsementLevel?.value} read isActive={shortPoka?.endorsementLevel?.value} />
            <div>{shortPoka?.endorsementLevel?.label}</div>
          </div>
        </Col>

        {/* Категория навыка  */}
        <Col xs={24} lg={5}>
          <strong>{t('catSkill')}</strong>
        </Col>
        <Col xs={24} lg={19}>
          {renderTagArr(shortPoka?.categories)}
        </Col>

        {/* SITE */}
        <Col xs={24} lg={5}>
          <strong>{t('site')}</strong>
        </Col>
        <Col xs={24} lg={19}>
          {renderTagArr(shortPoka?.sites)}
        </Col>
      </Row>

      <Divider />

      <Row gutter={[0, 16]} className={css['AboutSkill-passing']}>
        <Col span={24} className={css['AboutSkill-passing-info']}>
          {t('passingInformation')}
          <span>
            {isShow ? (
              <UpOutlined className={css['AboutSkill-passing-info-arrow']} onClick={() => setIsShow(false)} />
            ) : (
              <DownOutlined className={css['AboutSkill-passing-info-arrow']} onClick={() => setIsShow(true)} />
            )}
          </span>
        </Col>

        {isShow && (
          <Col span={24}>
            <DataTestInfo
              data={blockStat}
              t={t}
              scroll={isMobile ? { x: 300 } : {}}
              tableSize='small'
              columns={columns}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AboutSkill;
