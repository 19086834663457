import {
  GET_PLAN_LIST_FAIL,
  GET_PLAN_LIST_START,
  GET_PLAN_LIST_SUCCESS,
  POST_PLAN_REFRESH_FAIL,
  POST_PLAN_REFRESH_START,
  POST_PLAN_REFRESH_SUCCESS,
} from './constants';

const initialState = {
  isLoadingButtons: {},
  isError: false,
  planList: [],
};

/* eslint-disable */
export default (state = initialState, { type, response, action }) => {
  switch (type) {
    case POST_PLAN_REFRESH_START:
      state.isLoadingButtons[action.schedulerName] = true;
      return {
        ...state,
      };
    case GET_PLAN_LIST_START:
      return {
        ...state,
      };

    case GET_PLAN_LIST_SUCCESS:
      return {
        ...state,
        isLoadingButtons:
          Array.isArray(response) &&
          response.reduce((acc, curr) => {
            acc[curr] = false;
            return acc;
          }, {}),
        planList: response,
      };

    case POST_PLAN_REFRESH_SUCCESS:
      state.isLoadingButtons[action.schedulerName] = false;
      return {
        ...state,
      };

    case POST_PLAN_REFRESH_FAIL:
    case GET_PLAN_LIST_FAIL:
      return {
        ...state,
        isError: true,
      };

    default:
      return {
        ...state,
      };
  }
};
