/* eslint-disable */
import {
  CLEAR_ERROR_TEXT,
  CREATE_COMPETENCE_FAIL,
  CREATE_COMPETENCE_START,
  CREATE_COMPETENCE_SUCCESS,
  DELETE_COMPETENCE_FAIL,
  DELETE_COMPETENCE_START,
  DELETE_COMPETENCE_SUCCESS,
  EDIT_COMPETENCE_FAIL,
  EDIT_COMPETENCE_START,
  EDIT_COMPETENCE_SUCCESS,
  GET_ALL_SKILLS_FAIL,
  GET_ALL_SKILLS_START,
  GET_ALL_SKILLS_SUCCESS,
  GET_COMPETENCE_FAIL,
  GET_COMPETENCE_START,
  GET_COMPETENCE_SUCCESS,
  GET_COMPETENCE_WITH_SKILL_SUCCESS,
  SAVE_COMPETENCE_FILTERS,
} from './actions';

const initialState = {
  competence: null,
  pageTotal: null,
  pageSize: null,
  pageNumber: null,
  isLoading: false,
  isLoadingAviable: false,
  error: false,
  editingError: false,
  creationError: false,
  errorText: null,
  deletionError: false,
  globalCompetence: {
    competence: null,
    total: null,
    pageSize: null,
    pageNumber: null,
    isLoading: false,
    error: false,
  },
  competenceFilters: {},
};

export default (state = initialState, { type, response, headers, value }) => {
  switch (type) {
    case GET_COMPETENCE_START:
      return {
        ...state,
        competence: null,
        total: null,
        pageSize: null,
        pageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_COMPETENCE_SUCCESS:
      return {
        ...state,
        competence: response.content,
        total: Number(headers.get('X-Total-Elements')) || 0,
        pageSize: Number(headers.get('X-Page-Size')) || 0,
        pageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_COMPETENCE_FAIL:
      return { ...state, error: true, isLoading: false };

    case CREATE_COMPETENCE_START:
      return { ...state, isLoading: true, errorText: null, creationError: false };
    case CREATE_COMPETENCE_SUCCESS:
      return { ...state, isLoading: false };
    case CREATE_COMPETENCE_FAIL:
      return { ...state, creationError: true, errorText: response, isLoading: false };

    case EDIT_COMPETENCE_START:
      return { ...state, isLoading: true, errorText: null, editingError: false };

    case EDIT_COMPETENCE_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_COMPETENCE_FAIL:
      return { ...state, editingError: true, errorText: response, isLoading: false };

    case DELETE_COMPETENCE_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_COMPETENCE_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_COMPETENCE_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    case GET_ALL_SKILLS_SUCCESS:
      return { ...state, isLoadingAviable: false };

    case GET_ALL_SKILLS_FAIL:
      return { ...state, isLoadingAviable: false };

    case GET_ALL_SKILLS_START:
      return { ...state, isLoadingAviable: true };
    case GET_COMPETENCE_WITH_SKILL_SUCCESS:
      return {
        ...state,
        competence: response.content,
        total: response.totalElements,
        pageSize: response.size,
        pageNumber: response.number,
      };
    case CLEAR_ERROR_TEXT:
      return { ...state, errorText: null };

    case SAVE_COMPETENCE_FILTERS:
      return { ...state, competenceFilters: value };

    default:
      return state;
  }
};
