import React from 'react';

import { Spin, Typography } from 'antd';
import { FiDownload } from 'react-icons/fi';

import PropTypes from 'prop-types';

import css from './UsersMaterial.module.scss';

const { Title, Text } = Typography;

function UsersMaterial(props) {
  return (
    <div className={css['UsersMaterial']}>
      <Title level={4}>{props.title}</Title>
      <div className={css['UsersMaterial-count']}>
        <Title level={1}>{props.loading ? <Spin /> : props.count}</Title>
        <div className={css['UsersMaterial-download']} onClick={props.exportUsers}>
          <FiDownload color='var(--personalColor)' size={28} />
          <Text className={css['UsersMaterial-download-text']}>{props.text}</Text>
        </div>
      </div>
    </div>
  );
}

UsersMaterial.propTypes = {
  exportUsers: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  loading: PropTypes.bool,
};

export default UsersMaterial;
