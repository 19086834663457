import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPokaDirCorrectPositionArea,
  getPokaDirSourArea,
  loadPokaAreas,
  savePokaAreasFilters,
} from '@/store/poka_areas/actions';
import { getPokaDirSite } from '@/store/poka_sites/actions';
import { clearStudents } from '@/store/students/actions';

import { selectPokaProperAreas } from '@/store/poka_areas/selectors';
import { selectPokaSites } from '@/store/poka_sites/selectors';

import { Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';

import { DEFAULT_PARAMS } from './constants';
import FilterAreasDirectory from './Content/FilterAreasDirectory';
import TableAreasDirectory from './Content/TableAreasDirectory';

import css from './AreasDirectory.module.scss';

const { Title } = Typography;

const AreasDirectory = () => {
  const { t } = useTranslation('AreasDirectory');
  const dispatch = useDispatch();

  const { pokaProperAreas, pokaCorrectAreas, pokaDirSourAreas } = useSelector(selectPokaProperAreas);
  const { pokaSites } = useSelector(selectPokaSites);

  useEffect(() => {
    if (!pokaProperAreas?.length) dispatch(loadPokaAreas(DEFAULT_PARAMS, { isCorrectPositionAreaEmpty: true }));
    if (!pokaSites?.length) dispatch(getPokaDirSite());
    dispatch(savePokaAreasFilters({ isCorrectPositionAreaEmpty: true }));
    if (!pokaCorrectAreas?.length) dispatch(getPokaDirCorrectPositionArea());
    if (!pokaDirSourAreas?.length) dispatch(getPokaDirSourArea());

    return () => dispatch(clearStudents());
  }, []);

  return (
    <Layout>
      <Content>
        <div className={css['AreasDirectory']}>
          <div className={css['AreasDirectory-header']}>
            <Title>{t('title')}</Title>
          </div>

          <FilterAreasDirectory />

          <TableAreasDirectory />
        </div>
      </Content>
    </Layout>
  );
};

export default AreasDirectory;
