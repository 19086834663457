/* eslint-disable */
import {
  CLEAR_NEWS_FILTERS,
  CLEAR_NEWS_ID,
  CLEAR_NEWS_VISIBILITY,
  CREATE_NEWS_FAIL,
  CREATE_NEWS_START,
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_VISIBILITY_FAIL,
  DELETE_NEWS_VISIBILITY_START,
  DELETE_NEWS_VISIBILITY_SUCCESS,
  GET_NEWS_ID_FAIL,
  GET_NEWS_ID_START,
  GET_NEWS_ID_SUCCESS,
  GET_NEWS_VISIBILITY_FAIL,
  GET_NEWS_VISIBILITY_START,
  GET_NEWS_VISIBILITY_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  POST_NEWS_FILTERS_FAIL,
  POST_NEWS_FILTERS_START,
  POST_NEWS_FILTERS_SUCCESS,
  PUT_NEWS_FAIL,
  PUT_NEWS_START,
  PUT_NEWS_SUCCESS,
  PUT_NEWS_VISIBILITY_FAIL,
  PUT_NEWS_VISIBILITY_START,
  PUT_NEWS_VISIBILITY_SUCCESS,
  PUT_STATUS_NEWS_FAIL,
  PUT_STATUS_NEWS_START,
  PUT_STATUS_NEWS_SUCCESS,
  SAVE_NEWS_FILTERS,
  SAVE_SORT_NEWS,
  UPDATE_NEWS_VISIBILITY_FAIL,
  UPDATE_NEWS_VISIBILITY_START,
  UPDATE_NEWS_VISIBILITY_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  newsFilters: [],
  newsLoading: false,
  newsTable: {},
  newsTotal: null,
  newsPageSize: null,
  newsPageNumber: null,
  newsCurrentPage: null,
  statuses: [],
  newsId: {},
  newsVisibility: [],
  sortNews: 'title,asc',
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_STATUS_START:
    case PUT_NEWS_START:
    case PUT_STATUS_NEWS_START:
    case CREATE_NEWS_START:
    case UPDATE_NEWS_VISIBILITY_START:
    case GET_NEWS_VISIBILITY_START:
    case PUT_NEWS_VISIBILITY_START:
    case DELETE_NEWS_VISIBILITY_START:
      return { ...state, isLoading: true };

    case GET_NEWS_ID_START:
      return { ...state, isLoadingNewsId: true };

    case POST_NEWS_FILTERS_START:
      return { ...state, newsLoading: true };

    case GET_STATUS_SUCCESS:
      return { ...state, isLoading: false, statuses: response };

    case PUT_NEWS_SUCCESS:
    case PUT_STATUS_NEWS_SUCCESS:
    case CREATE_NEWS_SUCCESS:
    case PUT_NEWS_VISIBILITY_SUCCESS:
    case DELETE_NEWS_VISIBILITY_SUCCESS:
      return { ...state, isLoading: false };

    case GET_NEWS_VISIBILITY_SUCCESS:
      return { ...state, isLoading: false, newsVisibility: response };

    case GET_NEWS_ID_SUCCESS:
      return { ...state, isLoadingNewsId: false, newsId: response };

    case POST_NEWS_FILTERS_SUCCESS:
      return {
        ...state,
        newsLoading: false,
        newsTable: response.content,
        newsTotal: response.totalElements,
        newsPageSize: response.size,
        newsPageNumber: response.totalPages,
        newsCurrentPage: response.number,
      };

    case UPDATE_NEWS_VISIBILITY_SUCCESS:
      const filteredNewsTable = state.newsTable.map(i => {
        if (i.id === response.newsId) {
          i.visible = response.visible;
        }
        return i;
      });

      return {
        ...state,
        newsTable: filteredNewsTable,
        newsLoading: false,
      };

    case GET_STATUS_FAIL:
    case PUT_NEWS_FAIL:
    case PUT_STATUS_NEWS_FAIL:
    case CREATE_NEWS_FAIL:
    case UPDATE_NEWS_VISIBILITY_FAIL:
    case GET_NEWS_VISIBILITY_FAIL:
    case PUT_NEWS_VISIBILITY_FAIL:
    case DELETE_NEWS_VISIBILITY_FAIL:
      return { ...state, isLoading: false, isError: true };

    case GET_NEWS_ID_FAIL:
      return { ...state, isLoadingNewsId: false, isError: true };

    case POST_NEWS_FILTERS_FAIL:
      return { ...state, isError: true, newsLoading: false };

    case SAVE_NEWS_FILTERS:
      const filters = { ...state.newsFilters, filters: value };
      return { ...state, newsFilters: filters };

    case SAVE_SORT_NEWS:
      return { ...state, sortNews: value };

    case CLEAR_NEWS_FILTERS:
      const clearFilters = { ...state.newsFilters, filters: value };
      return { ...state, newsFilters: clearFilters };

    case CLEAR_NEWS_ID:
      return { ...state, newsId: {} };

    case CLEAR_NEWS_VISIBILITY:
      return { ...state, newsVisibility: [] };

    default:
      return { ...state };
  }
};
