/* eslint-disable */
import { REST_API_ERROR } from '@shared/constants/rest-api';

const getRestApiError = text => action => ({
  type: REST_API_ERROR,
  text,
  action,
});

export const noMethodApiError = getRestApiError('no proper method in rest_call');

export const noUrlApiError = getRestApiError('no proper url in rest_call');

export const noBodyApiError = getRestApiError('no proper body in rest_call');

export const noActionTypesApiError = getRestApiError('no action types are present in rest_call');
export const noServerApiError = getRestApiError('no server connect');

export const sendStartAction = action => {
  if (!action || !Array.isArray(action.types)) {
    return noActionTypesApiError(action);
  }
  const [type] = action.types;
  return {
    type,
    action,
  };
};

export const sendSuccessAction = (action, response, headers) => {
  if (!action || !Array.isArray(action.types)) {
    return noActionTypesApiError(action);
  }

  const type = action.types[1];
  return {
    type,
    action,
    response,
    headers,
  };
};

export const sendFailAction = (action, response) => {
  if (!action || !Array.isArray(action.types)) {
    return noActionTypesApiError(action);
  }
  const type = action.types[2];
  return {
    type,
    action,
    response,
  };
};
