// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-eTUVlr.admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-pk7ucx .admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-label-U9nRAj[data-value="Montserrat"]:before, .admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-eTUVlr.admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-pk7ucx .admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-item-wQN7zC[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-Montserrat-gXD6Ye {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal-MGLw_9 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 200px;
  display: flex;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal--item-ogTAIc {
  width: 100%;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__input-G0ltEu {
  width: 50%;
  margin: 0 25%;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__input-error-lV97fa {
  border: 1px solid red;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__error-jqwiET {
  text-align: center;
  color: red;
  width: 100%;
  margin-top: .5em;
  font-weight: 500;
  position: absolute;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__btn-Hl8O5e {
  cursor: pointer;
  position: relative;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__btn-Hl8O5e.admin-src-pages-Users-ChangePassword-ChangePassword-module__error-JzKrP0 {
  pointer-events: none;
}

.admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__btn-Hl8O5e.admin-src-pages-Users-ChangePassword-ChangePassword-module__error-JzKrP0:after {
  content: "";
  background-color: #80808099;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Users/ChangePassword/ChangePassword.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,aAAa;AACf;;AAMA;EAHE,WAAW;AACb;;AAKA;EAFE,UAAU;EACV,aAAa;AACf;;AAIA;EADE,qBAAqB;AACvB;;AAGA;EAAE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AACA;EAEE,oBAAoB;AACtB;;AAAA;EAGE,WAAW;EACX,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.change-password-modal {\n  height: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 30px;\n}\n.change-password-modal--item {\n  width: 100%;\n}\n.change-password-modal__input {\n  width: 50%;\n  margin: 0 25%;\n}\n.change-password-modal__input-error {\n  border: 1px solid red;\n}\n.change-password-modal__error {\n  width: 100%;\n  position: absolute;\n  margin-top: 0.5em;\n  text-align: center;\n  color: red;\n  font-weight: 500;\n}\n.change-password-modal__btn {\n  position: relative;\n  cursor: pointer;\n}\n.change-password-modal__btn.error {\n  pointer-events: none;\n}\n.change-password-modal__btn.error::after {\n  content: \"\";\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: rgba(128, 128, 128, 0.6);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-eTUVlr`,
	"qlPicker": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-eTUVlr`,
	"ql-font": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-pk7ucx`,
	"qlFont": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-pk7ucx`,
	"ql-picker-label": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-label-U9nRAj`,
	"qlPickerLabel": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-label-U9nRAj`,
	"ql-picker-item": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-item-wQN7zC`,
	"qlPickerItem": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-picker-item-wQN7zC`,
	"ql-font-Montserrat": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-Montserrat-gXD6Ye`,
	"qlFontMontserrat": `admin-src-pages-Users-ChangePassword-ChangePassword-module__ql-font-Montserrat-gXD6Ye`,
	"change-password-modal": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal-MGLw_9`,
	"changePasswordModal": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal-MGLw_9`,
	"change-password-modal--item": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal--item-ogTAIc`,
	"changePasswordModalItem": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal--item-ogTAIc`,
	"change-password-modal__input": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__input-G0ltEu`,
	"changePasswordModalInput": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__input-G0ltEu`,
	"change-password-modal__input-error": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__input-error-lV97fa`,
	"changePasswordModalInputError": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__input-error-lV97fa`,
	"change-password-modal__error": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__error-jqwiET`,
	"changePasswordModalError": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__error-jqwiET`,
	"change-password-modal__btn": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__btn-Hl8O5e`,
	"changePasswordModalBtn": `admin-src-pages-Users-ChangePassword-ChangePassword-module__change-password-modal__btn-Hl8O5e`,
	"error": `admin-src-pages-Users-ChangePassword-ChangePassword-module__error-JzKrP0`
};
export default ___CSS_LOADER_EXPORT___;
