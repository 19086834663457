/* eslint-disable */
import {
  CHANGE_STATUS_FAIL,
  CHANGE_STATUS_START,
  CHANGE_STATUS_SUCCESS,
  CLEAR_MENTORS_FILTERS,
  CREATE_MENTOR_FAIL,
  CREATE_MENTOR_START,
  CREATE_MENTOR_SUCCESS,
  EXPORT_MENTORS_FAIL,
  EXPORT_MENTORS_SETTINGS_FAIL,
  EXPORT_MENTORS_SETTINGS_START,
  EXPORT_MENTORS_SETTINGS_SUCCESS,
  EXPORT_MENTORS_START,
  EXPORT_MENTORS_SUCCESS,
  GET_COUNT_STAT_FAIL,
  GET_COUNT_STAT_START,
  GET_COUNT_STAT_SUCCESS,
  GET_MENTEE_FAIL,
  GET_MENTEE_START,
  GET_MENTEE_SUCCESS,
  GET_MENTOR_STATUS_FAIL,
  GET_MENTOR_STATUS_SUCCESS,
  GET_MENTORS_FAIL,
  GET_MENTORS_START,
  GET_MENTORS_STATUS_START,
  GET_MENTORS_SUCCESS,
  GET_SETTINGS_COUNT_MENTORS_FAIL,
  GET_SETTINGS_COUNT_MENTORS_START,
  GET_SETTINGS_COUNT_MENTORS_SUCCESS,
  GET_SETTINGS_LIMIT_MENTORS_FAIL,
  GET_SETTINGS_LIMIT_MENTORS_START,
  GET_SETTINGS_LIMIT_MENTORS_SUCCESS,
  GET_SETTINGS_MENTORS_FAIL,
  GET_SETTINGS_MENTORS_START,
  GET_SETTINGS_MENTORS_SUCCESS,
  POST_MENTORS_DATA_FAIL,
  POST_MENTORS_DATA_START,
  POST_MENTORS_DATA_SUCCESS,
  POST_MENTORS_FILTERS_FAIL,
  POST_MENTORS_FILTERS_START,
  POST_MENTORS_FILTERS_SUCCESS,
  PUT_SETTINGS_LIMIT_MENTORS_FAIL,
  PUT_SETTINGS_LIMIT_MENTORS_START,
  PUT_SETTINGS_LIMIT_MENTORS_SUCCESS,
  PUT_SETTINGS_MENTORS_FAIL,
  PUT_SETTINGS_MENTORS_START,
  PUT_SETTINGS_MENTORS_SUCCESS,
  SAVE_MENTORS_FILTERS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  mentorsLoading: false,
  mentorsTable: [],
  mentorsTotal: null,
  mentorsPageSize: null,
  mentorsPageNumber: null,
  mentorsCurrentPage: null,
  mentorsFilters: [],
  mentors: [],
  status: [],
  mentee: [],
  allStat: [],
  settingsMentors: [],
  countSettingsMentors: null,
  exportMentorsLoading: false,
  limit: {},
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_MENTORS_START:
    case GET_MENTORS_STATUS_START:
    case GET_MENTEE_START:
    case CHANGE_STATUS_START:
    case GET_COUNT_STAT_START:
    case PUT_SETTINGS_MENTORS_START:
    case GET_SETTINGS_MENTORS_START:
    case GET_SETTINGS_COUNT_MENTORS_START:
    case CREATE_MENTOR_START:
    case POST_MENTORS_DATA_START:
    case PUT_SETTINGS_LIMIT_MENTORS_START:
    case GET_SETTINGS_LIMIT_MENTORS_START:
      return { ...state, isLoading: true };

    case POST_MENTORS_FILTERS_START:
      return { ...state, isLoading: true, mentorsLoading: true };

    case EXPORT_MENTORS_START:
    case EXPORT_MENTORS_SETTINGS_START:
      return { ...state, exportMentorsLoading: true };

    case CHANGE_STATUS_SUCCESS:
      return { ...state, isLoading: false };

    case EXPORT_MENTORS_SUCCESS:
    case EXPORT_MENTORS_SETTINGS_SUCCESS:
      return { ...state, exportMentorsLoading: false };

    case POST_MENTORS_DATA_SUCCESS:
    case PUT_SETTINGS_LIMIT_MENTORS_SUCCESS:
      return { ...state, isLoading: false };

    case POST_MENTORS_FILTERS_SUCCESS:
      return {
        ...state,
        mentorsLoading: false,
        mentorsTable: response.content,
        mentorsTotal: response.totalElements,
        mentorsPageSize: response.size,
        mentorsPageNumber: response.totalPages,
        mentorsCurrentPage: response.number,
      };

    case GET_MENTORS_SUCCESS:
      return { ...state, isLoading: false, mentors: response };

    case GET_MENTOR_STATUS_SUCCESS:
      return { ...state, isLoading: false, status: response };

    case GET_MENTEE_SUCCESS:
      return { ...state, isLoading: false, mentee: response };

    case GET_COUNT_STAT_SUCCESS:
      return { ...state, isLoading: false, allStat: response };

    case GET_SETTINGS_MENTORS_SUCCESS:
      return { ...state, isLoading: false, settingsMentors: response };

    case GET_SETTINGS_COUNT_MENTORS_SUCCESS:
      return { ...state, isLoading: false, countSettingsMentors: response };

    case EXPORT_MENTORS_FAIL:
    case EXPORT_MENTORS_SETTINGS_FAIL:
      return { ...state, exportMentorsLoading: false, isError: true };

    case GET_MENTORS_FAIL:
    case GET_MENTOR_STATUS_FAIL:
    case GET_MENTEE_FAIL:
    case CHANGE_STATUS_FAIL:
    case GET_COUNT_STAT_FAIL:
    case PUT_SETTINGS_MENTORS_FAIL:
    case GET_SETTINGS_MENTORS_FAIL:
    case GET_SETTINGS_COUNT_MENTORS_FAIL:
    case CREATE_MENTOR_FAIL:
    case POST_MENTORS_DATA_FAIL:
    case PUT_SETTINGS_LIMIT_MENTORS_FAIL:
    case GET_SETTINGS_LIMIT_MENTORS_FAIL:
      return { ...state, isLoading: false, isError: true };

    case POST_MENTORS_FILTERS_FAIL:
      return { ...state, isLoading: false, isError: true, mentorsLoading: false };

    case PUT_SETTINGS_MENTORS_SUCCESS:
    case CREATE_MENTOR_SUCCESS:
      return { ...state, isLoading: false };

    case SAVE_MENTORS_FILTERS:
      const filters = { ...state.mentorsFilters, filters: value };
      return { ...state, mentorsFilters: filters };

    case CLEAR_MENTORS_FILTERS:
      const clearFilters = { ...state.mentorsFilters, filters: value };
      return { ...state, mentorsFilters: clearFilters };

    case GET_SETTINGS_LIMIT_MENTORS_SUCCESS:
      return { ...state, isLoading: false, limit: response };

    default:
      return { ...state };
  }
};
