/* eslint-disable */

import React, { Component } from 'react';

import { Form } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TopicCreationDialog.module.scss';

export default class FormItem extends Component {
  static propTypes = {
    children: PropTypes.any,
    error: PropTypes.any,
    label: PropTypes.string,
    marginBtn: PropTypes.bool,
    required: PropTypes.bool,
    short: PropTypes.bool,
    cols: PropTypes.bool,
  };

  render() {
    const { children, label, error, required, marginBtn } = this.props;
    const requiredLabel = required ? `${label} *` : label;
    return (
      <Form.Item
        label={requiredLabel}
        labelCol={{ span: this.props.cols ? 5 : this.props.short ? 7 : 4 }}
        wrapperCol={{ span: this.props.cols ? 19 : this.props.short ? 17 : 20 }}
        colon={false}
        className={classNames(
          css['Add-modal-form-item'],
          { [css.short]: this.props.short },
          { [css.marginBtn]: marginBtn },
          { [css.error]: error }
        )}
        help={error}
      >
        {children}
      </Form.Item>
    );
  }
}
