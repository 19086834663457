import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getAuthors } from '@/store/authors/actions';
import { exportTopics, importStudyPlan } from '@/store/files/actions';
import { getImportPlan } from '@/store/importPlan/actions';
import { getTypeLabels } from '@/store/label_topic/actions';
import { getSkills } from '@/store/skills/actions';
import { getStudents } from '@/store/students/actions';
import { getTags } from '@/store/tags/actions';
import { deleteTopic, getPinnedTopicsCount, getTopicCategories, getTopics } from '@/store/topics/actions';

import { selectAuthors } from '@/store/authors/selectors';
import { selectFiles } from '@/store/files/selectors';
import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectSkills } from '@/store/skills/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectTags } from '@/store/tags/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, List } from 'antd';
import Typography from 'antd/es/typography/Typography';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import Filter from '@/components/Filter';

import TopicCreationDialog from '../Topics/TopicCreationDialog';
import Topic from './components/Topic';
import { filters } from './constants';

import useDebounce from '@shared/hooks/useDebounce';

import Utils from '@/Utils';

import style from './style.module.scss';

import { STUDY_PLAN_IMPORT, TOPIC_CREATE, TOPIC_EXECUTIVE_TASKS_CREATE, TOPIC_EXPORT } from '@/constants/permissions';

const filename = 'topics.csv';

const Topics = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleImport, setVisibleImport] = useState(false);
  const [visibleCreationTopic, setVisibleCreationTopic] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [searchType, setSearchType] = useState(undefined);

  const debounceSearchValue = useDebounce(searchValue, 800);

  const { t } = useTranslation('newTopics');
  const { topics, isLoadingTopics, topicsTotal, topicsPageSize, topicsPageNumber, categories } =
    useSelector(selectTopics);
  const { typeLabel } = useSelector(selectTypeLabel);
  const { loadingExportMaterials } = useSelector(selectFiles);
  const { currentUser } = useSelector(selectUsers);
  const { tags } = useSelector(selectTags);
  const { skills } = useSelector(selectSkills);
  const { authors } = useSelector(selectAuthors);
  const { students } = useSelector(selectStudents);

  const currentSearchParams = useMemo(() => {
    const initialValues = {};

    for (const [key, value] of searchParams.entries()) {
      initialValues[key] = value;
    }

    return initialValues;
  }, [searchParams]);

  const pageNumber = parseInt(searchParams.get('page') ?? topicsPageNumber);

  const onGetTopics = () => dispatch(getTopics(currentSearchParams));

  // * Получение лейблов топика
  useEffect(() => {
    if (!typeLabel.length) dispatch(getTypeLabels());
    dispatch(getPinnedTopicsCount());
    if (!categories) dispatch(getTopicCategories(currentUser?.domainCompany?.global));
  }, []);

  // * Получение топиков по фильтру
  useEffect(() => {
    onGetTopics();
  }, [searchParams]);

  useEffect(() => {
    if (debounceSearchValue) {
      switch (searchType) {
        case 'tagIds':
          dispatch(getTags(debounceSearchValue, 0, () => {}, currentUser?.domainCompany?.global));
          break;

        case 'skillIds':
          dispatch(getSkills(debounceSearchValue, 0, () => {}, currentUser?.domainCompany?.global));
          break;

        case 'authorIds':
          dispatch(getAuthors(debounceSearchValue, 0, () => {}, currentUser?.domainCompany?.global));
          break;

        case 'creBy':
          dispatch(getStudents({ fullName: debounceSearchValue, size: 50 }));
          break;

        default:
          break;
      }
    }
  }, [debounceSearchValue]);

  const onChangePage = page => {
    const params = new URLSearchParams(searchParams);
    params.set('page', (page - 1).toString());
    setSearchParams(params);
  };

  const checkAuthorities = authorities => currentUser.authorities.includes(authorities);

  const topicTypeLabels = useMemo(
    () => typeLabel?.map(label => ({ value: String(label.id), label: label.name })),
    [typeLabel]
  );
  const topicTags = useMemo(() => tags?.map(label => ({ value: String(label.id), label: label.name })), [tags]);
  const topicSkills = useMemo(() => skills?.map(label => ({ value: String(label.id), label: label.name })), [skills]);
  const topicAuthors = useMemo(
    () => authors?.map(label => ({ value: String(label.id), label: label.name })),
    [authors]
  );
  // const topicCategories = useMemo(
  //   () => categories?.map(label => ({ value: String(label.id), label: label.name })),
  //   [categories]
  // );

  const studentsOptions = useMemo(
    () =>
      students.map(el => ({
        value: String(el?.id),
        label: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''}`,
      })),
    [students]
  );

  const onAddTopic = () => {
    setVisibleCreationTopic(false);
    onGetTopics();
  };

  const onDeleteTopic = id => {
    dispatch(deleteTopic(id, '', () => onGetTopics()));
  };

  const changeTypeSearch = (value, name) => {
    setSearchType(name);
    setSearchValue(value);
  };

  const exportTopic = () => {
    dispatch(
      exportTopics({ filename, status: 'ACTIVE', ...currentSearchParams }, blob => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, 'topics.csv');
        }

        const objectURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.download = 'topics.csv';
        tempLink.style = 'display: none';
        tempLink.href = objectURL;

        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(objectURL);
        }, 1000);
      })
    );
  };

  const pagination = {
    current: pageNumber + 1,
    pageSize: topicsPageSize,
    total: topicsTotal,
    showSizeChanger: false,
    align: 'center',
    responsive: true,
    hideOnSinglePage: true,
    onChange: onChangePage,
  };

  return (
    <Layout>
      <Layout.Content className={style['topics']}>
        <div className={style['topics__header']}>
          <Typography.Title level={1} data-qa='topicsH1'>
            {t('caption')}
          </Typography.Title>
          <div className={style['topics__buttons']}>
            {(checkAuthorities(TOPIC_CREATE) || checkAuthorities(TOPIC_EXECUTIVE_TASKS_CREATE)) && (
              <Button onClick={() => setVisibleCreationTopic(true)} type='primary' data-qa='addTopicBtn'>
                {t('add')}
              </Button>
            )}
            {checkAuthorities(STUDY_PLAN_IMPORT) && (
              <Button onClick={() => setVisibleImport(true)} type='primary'>
                {t('import')}
              </Button>
            )}
            {checkAuthorities(TOPIC_EXPORT) && (
              <Button loading={loadingExportMaterials} onClick={exportTopic} type='primary'>
                {t('export')}
              </Button>
            )}
          </div>
        </div>
        <Filter
          filters={filters(
            t,
            topicTypeLabels,
            topicTags,
            topicSkills,
            topicAuthors,
            // topicCategories,
            studentsOptions,
            changeTypeSearch
          )}
          inputSize={6}
        />
        <List
          grid={{
            gutter: 20,
            column: 4,
          }}
          loading={isLoadingTopics}
          dataSource={topics}
          renderItem={item => (
            <List.Item key={item.id} className={style['topics__list-item']}>
              <Topic onDeleteTopic={onDeleteTopic} {...item} />
            </List.Item>
          )}
          pagination={pagination}
        />
        {visibleImport && (
          <DictionaryImportDialog
            open={visibleImport}
            isTopics
            onCancel={() => setVisibleImport(false)}
            fileDescription={t('downloadExample')}
            description1={t('importText')}
            exampleFileFunc={template => dispatch(getImportPlan(template))}
            onOk={(params, onSuccess, onError) => dispatch(importStudyPlan(params, onSuccess, onError))}
          />
        )}
        {visibleCreationTopic && (
          <TopicCreationDialog
            open={visibleCreationTopic}
            onCancel={() => setVisibleCreationTopic(false)}
            isManager={Utils.isManager(currentUser)}
            onTopicCreationSuccess={onAddTopic}
          />
        )}
      </Layout.Content>
    </Layout>
  );
};

export default Topics;
