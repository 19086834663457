// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-RenderTableStatus-styles-module__ql-picker-t0V5Do.admin-src-components-RenderTableStatus-styles-module__ql-font-SFdoXN .admin-src-components-RenderTableStatus-styles-module__ql-picker-label-tuf7AC[data-value="Montserrat"]:before, .admin-src-components-RenderTableStatus-styles-module__ql-picker-t0V5Do.admin-src-components-RenderTableStatus-styles-module__ql-font-SFdoXN .admin-src-components-RenderTableStatus-styles-module__ql-picker-item-m4BVxb[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-RenderTableStatus-styles-module__ql-font-Montserrat-Pv6lLO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-RenderTableStatus-styles-module__Status-tag-scN0zk {
  white-space: normal;
  margin-right: 0 !important;
  font-weight: 400 !important;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/RenderTableStatus/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;EACnB,0BAA0B;EAC1B,2BAA2B;AAC7B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Status-tag {\n  white-space: normal;\n  margin-right: 0 !important;\n  font-weight: 400 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-RenderTableStatus-styles-module__ql-picker-t0V5Do`,
	"qlPicker": `admin-src-components-RenderTableStatus-styles-module__ql-picker-t0V5Do`,
	"ql-font": `admin-src-components-RenderTableStatus-styles-module__ql-font-SFdoXN`,
	"qlFont": `admin-src-components-RenderTableStatus-styles-module__ql-font-SFdoXN`,
	"ql-picker-label": `admin-src-components-RenderTableStatus-styles-module__ql-picker-label-tuf7AC`,
	"qlPickerLabel": `admin-src-components-RenderTableStatus-styles-module__ql-picker-label-tuf7AC`,
	"ql-picker-item": `admin-src-components-RenderTableStatus-styles-module__ql-picker-item-m4BVxb`,
	"qlPickerItem": `admin-src-components-RenderTableStatus-styles-module__ql-picker-item-m4BVxb`,
	"ql-font-Montserrat": `admin-src-components-RenderTableStatus-styles-module__ql-font-Montserrat-Pv6lLO`,
	"qlFontMontserrat": `admin-src-components-RenderTableStatus-styles-module__ql-font-Montserrat-Pv6lLO`,
	"Status-tag": `admin-src-components-RenderTableStatus-styles-module__Status-tag-scN0zk`,
	"statusTag": `admin-src-components-RenderTableStatus-styles-module__Status-tag-scN0zk`
};
export default ___CSS_LOADER_EXPORT___;
