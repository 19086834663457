import { VideoCameraOutlined } from '@ant-design/icons';

const VideoSvg = props => (
  <div>
    <VideoCameraOutlined style={{ fontSize: '12px' }} />
    {props.name}
  </div>
);

export default VideoSvg;
