import React /*useState, */, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext /*useNavigate */ } from 'react-router-dom';

import { updateLoginEmpty } from '@/store/login/actions';

import { selectLogin } from '@/store/login/selectors';

// import classNames from 'classnames';

import { Button, Form, Input, Spin } from 'antd';

import _ from 'lodash';

import css from './Login.module.scss';

const AuthorizationPage = () => {
  const { imageBackgroundLogin, isLoadingImageLogo, imageLogo, loginUserRecaptcha } = useOutletContext();
  const { t } = useTranslation('authorization');
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { error, company } = useSelector(selectLogin);
  // const [readInfo, changeReadInfo] = useState(false);
  const [form] = Form.useForm();

  const provider = company.providers.find(provider => provider.type === 'INTERNAL' && !provider.external);
  // const ldapLogin = company.providers.find(provider => provider.type === 'LDAP');

  const handleSubmit = formData => {
    loginUserRecaptcha(formData.password, formData.username);
  };

  // const navigation = (
  //   <>
  //     {ldapLogin && (
  //       <div className={css['LoginPage-label']}>
  //         <div className={classNames(css.LoginPage__toInfo)} onClick={() => changeReadInfo(!readInfo)}>
  //           {t('cantSingIn')}
  //         </div>
  //       </div>
  //     )}
  //     {provider && (
  //       <div className={css['LoginPage-label']}>
  //         <div
  //           id='btnRecoverPassword'
  //           className={classNames(css.LoginPage__toInfo)}
  //           onClick={() => navigate('/user-login/recovery')}
  //         >
  //           {t('recoverPassword')}
  //         </div>
  //       </div>
  //     )}
  //     {provider && company.hasRegistration && (
  //       <div className={css['LoginPage-label']}>
  //         <div
  //           id='btnRegistration'
  //           className={classNames(css.LoginPage__toInfo)}
  //           onClick={() => navigate('/user-login/registration')}
  //         >
  //           {t('registration')}
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );

  useEffect(() => {
    dispatch(updateLoginEmpty());
  }, []);

  const resetError = _.debounce(() => {
    if (error) {
      dispatch(updateLoginEmpty());
    }
  }, 0);

  return (
    <div className={css['App-card-item']}>
      {imageBackgroundLogin && <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />}
      <Form className={css['LoginPage-form']} onFinish={handleSubmit} form={form}>
        <div className={css['LoginPage-form__content']}>
          <div className={css['LoginPage-header']}>
            <Spin spinning={isLoadingImageLogo} wrapperClassName={css['LoginPage-header__logo']}>
              {!isLoadingImageLogo && <img src={imageLogo} alt='' />}
            </Spin>
            <div className={css['LoginPage-header__subtitle']}>{t('subtitle')}</div>
          </div>
          <Form.Item
            colon={false}
            className={css['LoginPage-form-item']}
            name='username'
            validateStatus={error || form?.getFieldValue('username')?.length === 0 ? 'error' : 'success'}
            rules={[
              {
                required: true,
                message: t('mailRequired'),
              },
            ]}
          >
            <Input placeholder={provider?.usernameAlias} data-qa='loginUsernameInput' />
          </Form.Item>
          <Form.Item
            colon={false}
            className={css['LoginPage-form-item']}
            name='password'
            validateStatus={error || form?.getFieldValue('password')?.length === 0 ? 'error' : 'success'}
            rules={[
              {
                required: true,
                message: t('passwordRequired'),
              },
              {
                pattern: /^[a-zA-Z0-9!@#$%^&*]/,
                message: t('passwordWrong'),
              },
            ]}
          >
            <Input.Password
              className={css['LoginPage-form-item-password']}
              placeholder={t('password')}
              data-qa='loginPasswordInput'
              onChange={resetError}
            />
          </Form.Item>
          {error && <span className={css['LoginPage-form-item-error']}>{error}</span>}
          <Form.Item className={css['LoginPage-form-google']}>
            <Button size='large' type={'primary'} htmlType='submit' name='ldap' data-qa='loginBtn'>
              {t('enter')}
            </Button>
          </Form.Item>
          {/* {navigation} */}
        </div>
      </Form>
    </div>
  );
};

export default AuthorizationPage;
