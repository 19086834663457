import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getChecklistPoka, putChecklistPoka } from '@/store/poka_validation_user/actions';

import { selectPokaValidationUser } from '@/store/poka_validation_user/selectors';

import { Alert, Button, Col, Divider, Modal, Row, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { checklistOpt } from '../constants';

import classNames from 'classnames';

import css from '../PokaValidationUser.module.scss';

const ChecklistSkill = () => {
  const { t } = useTranslation('pokaValidation');
  const dispatch = useDispatch();
  const { planId } = useParams();

  const { checklistDetail } = useSelector(selectPokaValidationUser);
  const [checkList, setCheckList] = useState([]);
  const [modal, contextHolder] = Modal.useModal();

  const onChange = (e, boxId, blockId) => {
    const data = { blockId, boxId, checked: e };

    setCheckList(prevState => {
      if (!prevState.find(i => i.boxId === boxId && i.blockId === blockId)) {
        return [...prevState, data];
      }

      return prevState.map(state => {
        if (state.boxId === boxId) {
          state.checked = e;
        }
        return state;
      });
    });
  };

  const sendChecklist = value => {
    const currentChecklist = checkList.filter(i => i.blockId === value.blockId);
    const params = currentChecklist.map(i => ({ boxId: i.boxId, checked: i.checked }));
    const currentBlockId = currentChecklist.find(i => i.blockId);

    modal.warning({
      icon: <QuestionCircleOutlined className={css['GradeSkill-modal-icon']} />,
      content: <div>{t('confirmChecklist')}</div>,
      className: css['GradeSkill-modal'],
      okText: t('confirm'),
      closable: true,
      onOk() {
        dispatch(putChecklistPoka(currentBlockId.blockId, planId, params, () => dispatch(getChecklistPoka(planId))));
      },
      maskClosable: true,
    });
  };

  const disableButton = obj => {
    const curCheck = checkList.find(i => i.blockId === obj.blockId && i.checked !== undefined);
    return !Boolean(curCheck);
  };

  const renderChecklists = () => {
    if (Array.isArray(checklistDetail) && checklistDetail.length > 0) {
      return checklistDetail.map((item, index) => (
        <React.Fragment key={index}>
          <div key={`desc-${index}`}>
            <strong>{item.description}</strong>
          </div>
          <Row align='middle' key={`${item.blockId}--${Symbol('uniq').toString()}`}>
            {Array.isArray(item.checklist) &&
              item.checklist.length > 0 &&
              item.checklist.map(c => (
                <React.Fragment key={c?.boxId}>
                  <Col xs={24} lg={16}>
                    {c?.description}
                  </Col>
                  <Col xs={24} lg={8}>
                    <div className={css['ChecklistSkill-rated']}>
                      {c?.result?.checkedDay && (
                        <div className={css['ChecklistSkill-rated-date']}>{c.result.checkedDay}</div>
                      )}
                      <div>
                        <Select
                          className={classNames(css['ChecklistSkill-rated-select'])}
                          placeholder={t('placeholderSelect')}
                          options={checklistOpt(t)}
                          defaultValue={c?.result?.checked}
                          onChange={e => onChange(e, c?.boxId, item?.blockId)}
                        />
                      </div>
                    </div>
                    {c?.result?.checkedBy && (
                      <div className={css['ChecklistSkill-valuer']}>
                        {t('evaluated')} {c.result.checkedBy}
                      </div>
                    )}
                  </Col>
                  <Divider />
                </React.Fragment>
              ))}
          </Row>
          <Row className={css['ChecklistSkill-footer']} justify='end'>
            <Button type='primary' onClick={() => sendChecklist(item)} disabled={disableButton(item)}>
              {t('applyChecklist')}
            </Button>
          </Row>
        </React.Fragment>
      ));
    }
  };

  return (
    <div className={css['ChecklistSkill']}>
      <Row gutter={[0, 16]}>
        <Col span={24} className={css['ChecklistSkill-name']}>
          {t('checklist')}
        </Col>

        <Col span={24}>
          <Alert className={css['ChecklistSkill-alert']} message={t('infoChecklist')} type='info' closable showIcon />
        </Col>
        <Divider />
      </Row>

      {renderChecklists()}

      {contextHolder}
    </div>
  );
};

export default ChecklistSkill;
