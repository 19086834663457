import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearShopFilters, getProductsByName, postProductsFilters, saveShopFilters } from '@/store/product/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectShop } from '@/store/product/selectors';

import { Button, Col, Form, Row, Select } from 'antd';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../Products.module.scss';

const { Option } = Select;

const Filters = ({ data, setData, setIsSort, setActiveSort, isSort }) => {
  const { t } = useTranslation('products');
  const dispatch = useDispatch();

  const optPublish = [
    { name: t('all'), value: undefined, key: '1' },
    { name: t('published'), value: true, key: '2' },
    { name: t('unpublished'), value: false, key: '3' },
  ];
  const { subCompanies } = useSelector(selectCompanies);
  const { rangeCost, products, delivery } = useSelector(selectShop);

  useEffect(() => {
    dispatch(postProductsFilters({}, 0));
  }, [dispatch]);

  const [sendData, setSendData] = useState({});

  useEffect(() => {
    // обрабатываю фильтр
    let copyData = _.cloneDeep(data);

    for (let key in copyData) {
      if (!copyData[key] || (copyData[key].hasOwnProperty('ids') && !copyData[key].ids)) {
        delete copyData[key];
      } else if (copyData[key]?.ids) {
        copyData[key] = copyData[key].ids;
      }

      if (Array.isArray(copyData[key]) && copyData[key].length === 0) {
        delete copyData[key];
      }
    }

    if (copyData?.published?.value !== undefined) {
      copyData.published = copyData.published.value;
    } else {
      delete copyData.published;
    }

    setSendData(copyData);
    dispatch(saveShopFilters(copyData));
  }, [data, dispatch]);

  const resetFilters = () => {
    setData({
      subCompanyName: '',
      deliveryIds: {
        ids: [],
        name: '',
      },
      costRangeIds: {
        ids: [],
        name: '',
      },
      productId: {
        ids: [],
        name: '',
      },
      published: {
        name: t('all'),
        value: undefined,
      },
    });

    setIsSort('');
    setActiveSort({ count: false, cost: false });
    dispatch(clearShopFilters({}));
    dispatch(postProductsFilters({}, 0));
  };

  const onSendFilters = () => {
    dispatch(postProductsFilters(sendData, 0, isSort));
  };

  const optionRangeCost =
    rangeCost?.length &&
    rangeCost.map(item => {
      return (
        <Option value={item.range} key={item.id}>
          {item.range}
        </Option>
      );
    });

  const optionPublished = optPublish.map(item => {
    return (
      <Option value={item.name} key={item.key} bol={item.value}>
        {item.name}
      </Option>
    );
  });

  const optionSubcompnaies =
    subCompanies?.length &&
    subCompanies.map(item => {
      return (
        <Option value={item} key={item}>
          {item}
        </Option>
      );
    });

  const renderOptions = arr => {
    return (
      arr?.length &&
      arr.map(item => {
        return (
          <Option value={item.name} key={item.id}>
            {item.name}
          </Option>
        );
      })
    );
  };

  const filterOptRangeCost = (value, option) => {
    return option?.props?.children.split(' ').join('').indexOf(value) !== -1;
  };

  const handleGet = (e, func) => {
    e?.length > 2 && dispatch(func(e));
  };

  const getDebounced = useCallback(_.debounce(handleGet, 1000), []);

  const templateFilters = [
    {
      type: 'flex',
      justify: 'start',
      classNameRow: css['Filters-block'],
      col: [
        {
          label: t('company'),
          valueLabel: data.subCompanyName,
          className: css['Filters-block-select'],
          onChange: name => setData({ ...data, subCompanyName: name }),
          span: 8,
          option: optionSubcompnaies,
          component: Select,
        },
        {
          label: t('delivery'),
          valueLabel: data.deliveryIds.name || undefined,
          className: css['Filters-block-select-multi'],
          onChange: (name, item) => {
            let ids = item.map(i => +i.key);
            setData({ ...data, deliveryIds: { ids, name } });
          },
          span: 8,
          option: renderOptions(delivery),
          mode: 'multiple',
          component: Select,
        },
        {
          label: t('cost'),
          valueLabel: data.costRangeIds.name || undefined,
          className: css['Filters-block-select-multi'],
          onChange: (name, item) => {
            let ids = item.map(i => +i.key);
            setData({ ...data, costRangeIds: { ids, name } });
          },
          span: 8,
          option: optionRangeCost,
          mode: 'multiple',
          component: Select,
          filterOption: filterOptRangeCost,
        },
      ],
    },
    {
      type: 'flex',
      justify: 'start',
      classNameRow: css['Filters-block'],
      col: [
        {
          label: t('searchName'),
          valueLabel: data.productId.name,
          className: css['Filters-block-select'],
          onChange: (name, item) => setData({ ...data, productId: { ids: +item?.key, name } }),
          span: 8,
          option: renderOptions(products),
          onSearch: e => getDebounced(e, getProductsByName),
          component: Select,
        },
        {
          label: t('publish'),
          valueLabel: data.published.name,
          className: css['Filters-block-select'],
          onChange: (name, item) => setData({ ...data, published: { name, value: item?.bol } }),
          span: 8,
          option: optionPublished,
          component: Select,
        },
        {
          label: t('apply'),
          span: 8,
          component: Button,
          onClick: onSendFilters,
          className: css['Filters-apply-btn'],
        },
      ],
    },
  ];

  const renderFilters = templateFilters.map((item, index) => {
    const customRow = item.col.map((col, index) => {
      const MY_COMP = col.component;
      return (
        <Col span={col.span} key={index} className={Button && css['Filters-block-colBtn']}>
          {MY_COMP !== Button ? (
            <Form layout='vertical'>
              <Form.Item label={col.label} colon={false}>
                <MY_COMP
                  onChange={col.onChange}
                  onSearch={col.onSearch}
                  className={col.className}
                  notFoundContent={t('noData')}
                  value={col.valueLabel}
                  mode={col.mode}
                  allowClear
                  showSearch
                  filterOption={col.filterOption ? col.filterOption : true}
                >
                  {col.option}
                </MY_COMP>
              </Form.Item>
            </Form>
          ) : (
            <div className={css['Filters-apply']}>
              <MY_COMP
                className={col.className}
                onClick={col.onClick}
                data-qa='adminProductsFiltersApply'
                type='primary'
              >
                {MY_COMP !== Button ? col.option : col.label}
              </MY_COMP>
            </div>
          )}
        </Col>
      );
    });

    return (
      <Row type={item.type} justify={item.justify} className={item.classNameRow} key={index}>
        {customRow}
      </Row>
    );
  });

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-buttons']}>
        <span className={css['Filters-buttons-filters']}>{t('filters')}</span>
        <Button type='link' onClick={resetFilters} className={css['Filters-buttons-reset']}>
          {t('reset')}
        </Button>
      </div>
      <div>{renderFilters}</div>
    </div>
  );
};

Filters.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
  setIsSort: PropTypes.func,
  setActiveSort: PropTypes.func,
  isSort: PropTypes.string,
};

export default Filters;
