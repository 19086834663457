import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { getTypeLabels } from '@/store/label_topic/actions';
import { getShortTopics } from '@/store/topics/actions';

import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectTopics } from '@/store/topics/selectors';

import { Alert, Button, Col, Divider, Input, message, Modal, Row, Select, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import TagSelected from '../TagSelected/TagSelected';
import { EXECUTIVE_LABEL_ID, typeOptCost } from './constants';
import MaterialItem from './MaterialItem';

import { ACTIVE } from '@shared/constants/topic-statuses';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './MaterialModal.module.scss';

const { Option } = Select;

const MaterialModal = ({
  open,
  onCancel,
  title,
  limitText,
  onOk,
  selectedTopics,
  hasLimit,
  isIpr,
  devTasks,
  currentCompIpr,
  customPlaceholder,
  isCompilation,
  isTrack,
}) => {
  const { t } = useTranslation('materialModal');
  const dispatch = useDispatch();

  const { typeLabel, isLoading } = useSelector(selectTypeLabel);
  const { topics, topicsTotal, topicsPageNumber, isLoadingTopics } = useSelector(selectTopics);

  const [state, setState] = useState({
    free: undefined,
    status: ACTIVE,
    topicTypeLabelId: (isIpr && devTasks && EXECUTIVE_LABEL_ID) || null,
    name: (isIpr && currentCompIpr?.name && currentCompIpr.name) || '',
    page: topicsPageNumber || 0,
    excludedTopicType: isIpr && !devTasks ? EXECUTIVE_LABEL_ID : null,
    isPersonalized: !isCompilation && !isTrack,
    ipr: isIpr,
  });

  const [selectedMaterial, setSelectedMaterial] = useState([]);

  const headerModal = <div className={css['Modal-header']}>{title}</div>;

  const optTopicLabels = useMemo(() => {
    if (Array.isArray(typeLabel) && typeLabel.length > 0) {
      return typeLabel
        .filter(i => (isIpr && !devTasks ? i.id !== EXECUTIVE_LABEL_ID : true))
        .map(item => {
          return (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          );
        });
    }
  }, [typeLabel, isIpr, devTasks]);

  const optCostMaterials = typeOptCost(t).map(c => {
    return (
      <Option key={c.id} value={c.value}>
        {c.name}
      </Option>
    );
  });

  const handleGet = e => setState({ ...state, name: e?.target?.value });

  const getDebounced = useCallback(_.debounce(handleGet, 1000), [state]);

  const onSelectedItems = item => setSelectedMaterial([...selectedMaterial, item]);

  const onDeletedItems = itemId => {
    const filteredMaterials = selectedMaterial.filter(item => item.id !== itemId);
    setSelectedMaterial(filteredMaterials);
  };

  const onSubmit = () => {
    onOk(selectedMaterial);
    onCancel();
  };

  const footerModal = (
    <div className={css['Modal-footer']}>
      <div className={css['Modal-footer-info']}>
        <div>
          <span>
            {t('chooiseMaterial')} {selectedMaterial.length}
          </span>
          <Button type='link' onClick={() => setSelectedMaterial([])}>
            {t('clearMaterial')}
          </Button>
        </div>
        {hasLimit && <div>{t('from', { selected: selectedMaterial.length, max: hasLimit.maxTopics })}</div>}
      </div>

      <div className={css['Modal-footer-action']}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button type='primary' onClick={onSubmit}>
          {t('add')}
        </Button>
      </div>
    </div>
  );

  const showMaxTopicsTrack = () => message.warning(t('topicsLimitTrack'));

  const renderTags = () => {
    if (Array.isArray(selectedMaterial) && selectedMaterial.length > 0) {
      return (
        <>
          <Divider />
          <Row>
            {selectedMaterial.map(item => (
              <TagSelected name={item.name} id={item.id} key={item.id} onDeleteTag={onDeletedItems} />
            ))}
          </Row>
        </>
      );
    }
  };

  const rednerMaterials = () => {
    if (Array.isArray(topics) && topics.length > 0) {
      return topics.map(i => (
        <MaterialItem
          material={i}
          key={i.id}
          selectedItems={
            hasLimit && selectedMaterial?.length >= hasLimit.maxTopics ? showMaxTopicsTrack : onSelectedItems
          }
          deletedItems={onDeletedItems}
          selectedIds={selectedMaterial.map(i => i.id)}
        />
      ));
    } else {
      return !isLoadingTopics && <div className={css['Modal-materials-empty']}>{t('emptyData')}</div>;
    }
  };

  useEffect(() => {
    if (!typeLabel.length) dispatch(getTypeLabels());
  }, []);

  useEffect(() => {
    if (selectedTopics?.length > 0) {
      setSelectedMaterial(selectedTopics);
    }
  }, [selectedTopics]);

  useEffect(() => {
    dispatch(getShortTopics(state));
  }, [state]);

  useEffect(() => {
    dispatch(getTypeLabels());
  }, []);

  return (
    <Modal
      width={800}
      title={headerModal}
      open={open}
      onCancel={onCancel}
      className={css['Modal']}
      loading={isLoading}
      footer={footerModal}
    >
      <Row className={css['Modal-filters']} align='middle'>
        <Col span={24}>
          <Input
            size='large'
            prefix={<SearchOutlined className={css['Modal-filters-search']} />}
            placeholder={customPlaceholder ?? t('searchPlaceholder')}
            onChange={getDebounced}
            allowClear
            defaultValue={state?.name}
          />
        </Col>
      </Row>

      <Row align='middle' justify='space-between' gutter={[16]}>
        <Col span={14} className={css['Modal-filters-type']}>
          {t('typeMaterial')}
        </Col>

        <Col span={10} className={css['Modal-filters-type']}>
          {t('typeCostMaterial')}
        </Col>
      </Row>

      <Row align='middle' justify='space-between' gutter={[16]}>
        <Col span={14}>
          <Select
            className={css['Modal-filters-select']}
            placeholder={t('selectPlaceholder')}
            allowClear
            optionFilterProp='children'
            loading={isLoading}
            onChange={e => setState({ ...state, topicTypeLabelId: e })}
            value={state?.topicTypeLabelId}
            disabled={devTasks}
            showSearch
          >
            {optTopicLabels}
          </Select>
        </Col>

        {/* Платные/Бесплатные материалы */}
        <Col span={10}>
          <Select
            className={css['Modal-filters-select']}
            placeholder={t('selectPlaceholderCost')}
            allowClear
            optionFilterProp='children'
            loading={isLoading}
            onChange={e => setState({ ...state, free: e })}
            defaultValue={t('allMaterials')}
            showSearch
          >
            {optCostMaterials}
          </Select>
        </Col>
      </Row>

      {hasLimit && (
        <Row className={css['Modal-isLimit']}>
          <Col span={24}>
            <Alert message={limitText} type='info' closable showIcon className={css['Modal-isLimit-alert']} />
          </Col>
        </Row>
      )}

      <Spin spinning={isLoadingTopics}>
        <div className={css['Modal-materials']}>
          <InfiniteScroll
            pageStart={0}
            useWindow={false}
            initialLoad={false}
            hasMore={topicsTotal > topics?.length && !isLoadingTopics}
            threshold={300}
            loadMore={() => !isLoadingTopics && dispatch(getShortTopics({ ...state, page: topicsPageNumber + 1 }))}
          >
            {rednerMaterials()}
          </InfiniteScroll>
        </div>

        {renderTags()}
      </Spin>
    </Modal>
  );
};

MaterialModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  limitText: PropTypes.string, // МСГ лимит топиков в треке
  onOk: PropTypes.func, // Добавить материалы
  selectedTopics: PropTypes.array, // Выбранные материалы
  title: PropTypes.string, // Выбранные материалы
  hasLimit: PropTypes.object, // Лимит на материалы
  isIpr: PropTypes.bool, // из ИПР
  devTasks: PropTypes.bool, // Задачи рук-ля
  currentCompIpr: PropTypes.object, // объект компетенции
  customPlaceholder: PropTypes.string, // кастомный плейсхолдер
  isCompilation: PropTypes.bool, // из Подборок
  isTrack: PropTypes.bool, // из Треков
};

export default MaterialModal;
