const GameSvg = props => (
  <div>
    <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none'>
        <rect fill='#F6F6F6' width='20' height='20' rx='10' />
        <g transform='translate(2 2)' />
        <g transform='translate(6 5)' stroke='#2D3440'>
          <rect x='.5' y='.5' width='3' height='3' rx='1' />
          <rect x='5.5' y='.5' width='3' height='3' rx='1' />
          <rect x='.5' y='5.5' width='3' height='3' rx='1' />
          <rect x='5.5' y='5.5' width='3' height='3' rx='1' />
        </g>
      </g>
    </svg>
    {props.name}
  </div>
);

export default GameSvg;
