import { StorageService } from './storage-service';

import _isUndefined from 'lodash/isUndefined';

export class UserStorage extends StorageService {
  constructor() {
    super();

    this.userKeys = [
      'authorizationToken', // todo удалить после перехода на accessToken
      'accessToken',
      'refreshToken',
      'expiresIn',
      'expiresTime',
      'roles',
      'username',
      'company',
      'uid',
    ];
  }

  get isAuth() {
    return !!this.getItem('authorizationToken');
  }

  get accessToken() {
    return this.getItem('authorizationToken');
  }

  get refreshToken() {
    return this.getItem('refreshToken');
  }

  getData() {
    return {
      roles: this.getItem('roles'),
      username: this.getItem('username'),
      expiresIn: this.getItem('expiresIn'),
      uid: this.getItem('uid'),
    };
  }

  setData(data) {
    this.userKeys.forEach(key => {
      key === 'accessToken' && this.setItem('authorizationToken', data[key]); // todo удалить после переезда на accessToken
      !_isUndefined(data[key]) && this.setItem(key, data[key]);
    });
  }

  removeData() {
    this.userKeys.forEach(key => {
      this.removeItem(key);
    });
  }
}

export default new UserStorage();
