import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getTypeLabels } from '@/store/label_topic/actions';
import { getDepartment, getFunction, getPosition, getStudentsList } from '@/store/students/actions';
import { getActions } from '@/store/topics/actions';

import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectTopics } from '@/store/topics/selectors';

import { Button, Col, DatePicker, Flex, Form, Input, Modal, Row, Select, Typography } from 'antd';
import { ADD_CUSTOM_FIELDS, CUSTOM_FIELDS, DELETE_CUSTOM_FIELDS } from '@/components/TemplateBlockFilters/constants';
import CustomFieldFilter from '@/components/TemplateBlockFilters/CustomFieldFilter/CustomFieldFilter';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../Logging.module.scss';

const Filters = ({ filters, onChangeFilter, onClearFilter, onSubmitFilters }) => {
  const { t } = useTranslation('logging');
  const { Title } = Typography;
  const dispatch = useDispatch();

  const { fullnameManager, isLoadingFullnameManager, department, functionStudent, position } =
    useSelector(selectStudents);
  const { typeLabel } = useSelector(selectTypeLabel);
  const { actionsLog } = useSelector(selectTopics);

  const [showCustomField, setShowCustomField] = useState(false);

  useEffect(() => {
    dispatch(getTypeLabels());
    dispatch(getActions());
    dispatch(getPosition());
    dispatch(getFunction());
    dispatch(getDepartment());

    return () => {
      getStudentsListDebounced.cancel();
    };
  }, []);

  // Даты
  const disabledStartDate = current => {
    return filters.from ? current && current < dayjs(filters.from) : false;
  };

  const disabledEndDate = current => {
    return filters.to ? current && current > dayjs(filters.to) : false;
  };

  // Менеджеры
  const nameManagerSelect = useMemo(
    () =>
      Array.isArray(fullnameManager) &&
      fullnameManager.length > 0 &&
      fullnameManager?.map(manager => ({ id: manager.id, value: manager.id, label: manager.name })),
    [fullnameManager]
  );

  const searchManagers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudentsList({ isManager: true, fullName, size: 100 }));
    }
  };

  const getStudentsListDebounced = useCallback(_.debounce(searchManagers, 1000), []);

  // Отделы, функции, должности
  const departmentSelect = useMemo(
    () =>
      Array.isArray(department) &&
      department.length > 0 &&
      department.map(department => ({ id: department, value: department, label: department })),
    [department]
  );
  const functionSelect = useMemo(
    () =>
      Array.isArray(functionStudent) &&
      functionStudent.length > 0 &&
      functionStudent.map(func => ({ id: func, value: func, label: func })),
    [functionStudent]
  );
  const positionSelect = useMemo(
    () => Array.isArray(position) && position.length > 0 && position.map(pos => ({ id: pos, value: pos, label: pos })),
    [position]
  );

  // Лейблы
  const topicLabelSelect = useMemo(
    () =>
      Array.isArray(typeLabel) &&
      typeLabel.length > 0 &&
      typeLabel.map(type => ({ ...type, id: type.id, value: type.id, label: type.name })),
    [typeLabel]
  );
  // Действия
  const actionsLogSelect = useMemo(
    () =>
      Array.isArray(actionsLog) &&
      actionsLog.length > 0 &&
      actionsLog.map(action => ({ id: action.name, value: action.name, label: action.value })),
    [actionsLog]
  );

  // Кастомные поля
  const onCloseCustomField = () => setShowCustomField(false);
  const onChangeCustomFields = (event, name, value) => {
    let customField;
    switch (name) {
      case CUSTOM_FIELDS:
        customField = [...filters?.customFields];
        customField[event] = value;
        onChangeFilter('customFields', customField);
        break;

      case DELETE_CUSTOM_FIELDS:
        if (filters?.customFields.length === 1) {
          onChangeFilter('customFields', [{}]);
          return;
        }

        customField = [...filters?.customFields];
        customField.splice(event, 1);
        onChangeFilter('customFields', customField);
        break;

      case ADD_CUSTOM_FIELDS:
        customField = [value];
        onChangeFilter('customFields', [...filters.customFields, customField]);
        break;

      default:
        break;
    }
  };

  const footerCustomFieldModal = (
    <>
      <Button onClick={onCloseCustomField} size='large'>
        {t('closeModal')}
      </Button>
      <Button type='primary' size='large' onClick={onCloseCustomField}>
        {t('readyModal')}
      </Button>
    </>
  );

  return (
    <>
      <div className={css['Logging-filters']}>
        <Title className={css['Logging-filters-title']} level={2}>
          {t('filter')}
        </Title>

        <Form layout='vertical'>
          <Row justify='space-between' gutter={16}>
            {/* СОТРУДНИК ФИО/ЛОГИН */}
            <Col span={8}>
              <Form.Item label={t('user')} colon={false}>
                <Input
                  className={css['Logging-filters-input']}
                  placeholder={t('userPlaceholder')}
                  name='recipient'
                  value={filters?.recipient}
                  onChange={e => onChangeFilter('recipient', e.target?.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* ИНИЦИАТОР ДЕЙСТВИЯ ФИО/ЛОГИН */}
            <Col span={8}>
              <Form.Item label={t('initial')} colon={false}>
                <Input
                  className={css['Logging-filters-input']}
                  placeholder={t('userPlaceholder')}
                  name='sender'
                  value={filters?.sender}
                  onChange={e => onChangeFilter(e.target.name, e.target?.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* ДОЛЖНОСТЬ */}
            <Col span={8}>
              <Form.Item label={t('position')} colon={false}>
                <Select
                  className={css['Logging-filters-input']}
                  onChange={e => onChangeFilter('position', e)}
                  value={filters?.position}
                  name='position'
                  options={positionSelect || []}
                  placeholder={t('positionPlaceholder')}
                  mode='multiple'
                />
              </Form.Item>
            </Col>
            {/* ОТДЕЛЫ */}
            <Col span={8}>
              <Form.Item label={t('departments')} colon={false}>
                <Select
                  className={css['Logging-filters-input']}
                  onChange={e => onChangeFilter('department', e)}
                  value={filters?.department}
                  name='department'
                  options={departmentSelect || []}
                  placeholder={t('departmentPlaceholder')}
                  mode='multiple'
                />
              </Form.Item>
            </Col>
            {/* ФИО РУКОВОДИТЕЛЯ */}
            <Col span={8}>
              <Form.Item label={t('nameManager')} colon={false}>
                <Select
                  className={css['Logging-filters-input']}
                  onChange={e => onChangeFilter('managerId', e)}
                  value={filters?.managerId}
                  name='managerId'
                  options={nameManagerSelect || []}
                  onSearch={getStudentsListDebounced}
                  notFoundContent={t('notFound')}
                  loading={isLoadingFullnameManager}
                  allowClear={!isLoadingFullnameManager}
                  placeholder={t('managerPlaceholder')}
                  mode='multiple'
                  filterOption={false}
                />
              </Form.Item>
            </Col>
            {/* ФУНКЦИИ */}
            <Col span={8}>
              <Form.Item label={t('functions')} colon={false}>
                <Select
                  className={css['Logging-filters-input']}
                  onChange={e => onChangeFilter('function', e)}
                  value={filters?.function}
                  name='function'
                  options={functionSelect || []}
                  placeholder={t('functionPlaceholder')}
                  mode='multiple'
                />
              </Form.Item>
            </Col>
            {/* НАЗВАНИЕ МАТЕРИАЛА */}
            <Col span={8}>
              <Form.Item label={t('material')} colon={false}>
                <Input
                  className={css['Logging-filters-input']}
                  placeholder={t('materialPlaceholder')}
                  name='topicName'
                  value={filters?.topicName}
                  onChange={e => onChangeFilter(e.target.name, e.target?.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* НАЗВАНИЕ МЕРОПРИЯТИЯ */}
            <Col span={8}>
              <Form.Item label={t('event2')} colon={false}>
                <Input
                  className={css['Logging-filters-input']}
                  placeholder={t('eventPlaceholder')}
                  name='eventName'
                  value={filters?.eventName}
                  onChange={e => onChangeFilter(e.target.name, e.target?.value)}
                  allowClear
                />
              </Form.Item>
            </Col>
            {/* ТИП МАТЕРИАЛА */}
            <Col span={8}>
              <Form.Item label={t('type')} colon={false}>
                <Select
                  className={css['Logging-filters-input']}
                  onChange={e => onChangeFilter('topicTypeLabelId', e)}
                  value={filters?.topicTypeLabelId}
                  name='topicTypeLabelId'
                  options={topicLabelSelect || []}
                  placeholder={t('materialTypePlaceholder')}
                  mode='multiple'
                  optionFilterProp='label'
                />
              </Form.Item>
            </Col>
            {/* ДЕЙСТВИЕ */}
            <Col span={8}>
              <Form.Item label={t('respond2')} colon={false}>
                <Select
                  className={css['Logging-filters-input']}
                  onChange={e => onChangeFilter('type', e)}
                  value={filters?.type}
                  name='type'
                  options={actionsLogSelect || []}
                  placeholder={t('actionPlaceholder')}
                  mode='multiple'
                  optionFilterProp='label'
                />
              </Form.Item>
            </Col>
            {/* ПЕРИОД ОТ  */}
            <Col span={8}>
              <Form.Item label={t('from')} colon={false}>
                <DatePicker
                  className={css['Logging-filters-input']}
                  name='from'
                  value={filters?.from ? dayjs(filters?.from) : undefined}
                  onChange={date => onChangeFilter('from', date ? dayjs(date).format('YYYY-MM-DD') : undefined)}
                  format='DD.MM.YYYY'
                  allowClear
                  disabledDate={disabledEndDate}
                  placeholder={t('from')}
                />
              </Form.Item>
            </Col>
            {/* ДО */}
            <Col span={8}>
              <Form.Item label={t('to')} colon={false}>
                <DatePicker
                  className={css['Logging-filters-input']}
                  name='to'
                  value={filters?.to ? dayjs(filters?.to) : undefined}
                  onChange={date => onChangeFilter('to', date ? dayjs(date).format('YYYY-MM-DD') : undefined)}
                  format='DD.MM.YYYY'
                  allowClear
                  disabledDate={disabledStartDate}
                  placeholder={t('to')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={css['Logging-customField']}>
          <div className={css['Logging-customField-header']}>
            <div className={css['Logging-customField-header-text']}>{t('customFiltersLogging')}</div>
            {filters?.customFields?.[0].hasOwnProperty('customFieldId') && (
              <div className={css['Logging-customField-header-circle']}></div>
            )}
          </div>

          <div className={css['Logging-customField-settings']}>
            <Button type='link' onClick={() => setShowCustomField(true)}>
              {t('settings')}
            </Button>
          </div>
        </div>
        <Flex justify='flex-end' className={css['Logging-filters-buttons']} gap={24}>
          <Button onClick={onClearFilter}>{t('clear')}</Button>
          <Button onClick={onSubmitFilters} type='primary'>
            {t('apply')}
          </Button>
        </Flex>
      </div>

      {showCustomField && (
        <Modal
          open={showCustomField}
          title={t('customFiltersLogging')}
          onCancel={onCloseCustomField}
          footer={footerCustomFieldModal}
          width={740}
        >
          <CustomFieldFilter
            state={filters?.customFields}
            visibleCustomModal={showCustomField}
            onChange={onChangeCustomFields}
            noPanel
          />
        </Modal>
      )}
    </>
  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  onChangeFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
  onSubmitFilters: PropTypes.func,
  setFilters: PropTypes.func,
};

export default Filters;
