import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { editBonuses, getBonuses } from '@/store/bonuses/actions';

import { selectBonuses } from '@/store/bonuses/selectors';

import { Button, Col, Collapse, Divider, InputNumber, Layout, Row, Typography } from 'antd';
import { Content } from '@/components/Layout';
import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

import css from './Gamification.module.scss';

const { Title } = Typography;

const Gamification = () => {
  const { t } = useTranslation('coinSettings');
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  const { bonuses } = useSelector(selectBonuses);

  useEffect(() => {
    dispatch(getBonuses());
  }, []);

  const onChangeInput = (qty, type, typeLabelId) => {
    if (typeLabelId) {
      setState({ ...state, [`${type}-${typeLabelId}`]: { qty, typeLabelId } });
    } else {
      setState({ ...state, [type]: { qty } });
    }
  };

  const renderInputs = (inputs, type, isTypeName) => {
    return inputs?.map((curInput, index) => {
      return (
        <Col span={6} key={index} className={css['CoinSettings-content-col']}>
          <div className={css['CoinSettings-content-col-label']}>
            {isTypeName ? curInput?.typeLabel?.name : t('coin' + type)}
          </div>
          <InputNumber
            min={0}
            defaultValue={curInput.qty}
            onChange={e => onChangeInput(e, type, curInput?.typeLabel?.id)}
          />
        </Col>
      );
    });
  };

  const renderItem = (obj, isTypeName) => Object.keys(obj).map(key => renderInputs(obj[key], key, isTypeName));

  const onSubmit = () => {
    const currentInputs = Object.keys(state).map(key => ({ name: key.split('-')[0], ...state[key] }));
    dispatch(editBonuses(currentInputs));
  };

  const { LOGIN, RECOMMEND, REVIEW, CONVENTUS, TRACK_COMPLETE, MENTOR_COMPLETE, TOPIC, TOPIC_COMPLETE, COMMENT_TOPIC } =
    bonuses;

  const collapseItems = [
    {
      key: '1',
      label: (
        <>
          <span className={css['CoinSettings-content-collapse-txt']}>{t('coinAddedMaterial')}</span>
          <Divider />
        </>
      ),
      children: <Row gutter={16}>{renderItem({ TOPIC }, true)}</Row>,
    },
    {
      key: '2',
      label: (
        <>
          <span className={css['CoinSettings-content-collapse-txt']}>{t('coinFinishedMaterial')}</span>
          <Divider />
        </>
      ),
      children: <Row gutter={16}>{renderItem({ TOPIC_COMPLETE }, true)}</Row>,
    },
    {
      key: '3',
      label: (
        <>
          <span className={css['CoinSettings-content-collapse-txt']}>{t('coinCommentMaterial')}</span>
          <Divider />
        </>
      ),
      children: <Row gutter={16}>{renderItem({ COMMENT_TOPIC }, true)}</Row>,
    },
  ];

  return (
    <Layout>
      <Content>
        <div className={css['CoinSettings']}>
          <div className={css['CoinSettings-header']}>
            <Title>{t('title')}</Title>
          </div>
          <div className={css['CoinSettings-content']}>
            <Row gutter={16}>
              {renderItem({ LOGIN, RECOMMEND, REVIEW, CONVENTUS, TRACK_COMPLETE, MENTOR_COMPLETE })}
            </Row>

            <Collapse
              className={css['CoinSettings-content-collapse']}
              expandIconPosition='end'
              items={collapseItems}
              bordered={false}
              expandIcon={e =>
                e.isActive ? (
                  <DownCircleOutlined className={css['CoinSettings-content-collapse-icon']} />
                ) : (
                  <RightCircleOutlined className={css['CoinSettings-content-collapse-icon']} />
                )
              }
              ghost
            />
            <div className={css['CoinSettings-content-footer']}>
              <Button type='primary' onClick={onSubmit} disabled={Object.keys(state).length === 0}>
                {t('apply')}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Gamification;
