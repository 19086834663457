import { API_D360_URL } from '../service_api';
import { ReportRequest } from './constants';

import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360StatusReport = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/report${getQueryString({ id: id })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [
      ReportRequest.D360_REPORT_STATUS_START,
      ReportRequest.D360_REPORT_STATUS_SUCCESS,
      ReportRequest.D360_REPORT_STATUS_FAIL,
    ],
    onSuccess,
    onError,
  };
};

export const D360CreateReport = (id, rebuild = false, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/report`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: {
      id: id,
      rebuild: rebuild,
    },
    types: [
      ReportRequest.D360_REPORT_CREATE_START,
      ReportRequest.D360_REPORT_CREATE_SUCCESS,
      ReportRequest.D360_REPORT_CREATE_FAIL,
    ],
    onSuccess,
    onError,
  };
};
