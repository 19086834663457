/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import { TopicTypes } from '@shared/constants/topics';
import getQueryString from '@shared/utils/get-query-string';

export const ACTIVE_STATUS_START = 'ACTIVE_STATUS_START';
export const ACTIVE_STATUS_SUCCESS = 'ACTIVE_STATUS_SUCCESS';
export const ACTIVE_STATUS_FAIL = 'ACTIVE_STATUS_FAIL';
export const GET_TOPIC_TYPES_START = 'GET_TOPIC_TYPES_START';
export const GET_TOPIC_TYPES_SUCCESS = 'GET_TOPIC_TYPES_SUCCESS';
export const GET_TOPIC_TYPES_FAIL = 'GET_TOPIC_TYPES_FAIL';
export const GET_TOPIC_TYPES_AUTO_START = 'GET_TOPIC_TYPES_AUTO_START';
export const GET_TOPIC_TYPES_AUTO_SUCCESS = 'GET_TOPIC_TYPES_AUTO_SUCCESS';
export const GET_TOPIC_TYPES_AUTO_FAIL = 'GET_TOPIC_TYPES_AUTO_FAIL';
export const SET_TOPIC_TYPES_AUTO_START = 'SET_TOPIC_TYPES_AUTO_START';
export const SET_TOPIC_TYPES_AUTO_SUCCESS = 'SET_TOPIC_TYPES_AUTO_SUCCESS';
export const SET_TOPIC_TYPES_AUTO_FAIL = 'SET_TOPIC_TYPES_AUTO_FAIL';
export const GET_TOPIC_SUBTYPES_START = 'GET_TOPIC_SUBTYPES_START';
export const GET_TOPIC_SUBTYPES_SUCCESS = 'GET_TOPIC_SUBTYPES_SUCCESS';
export const GET_TOPIC_SUBTYPES_FAIL = 'GET_TOPIC_SUBTYPES_FAIL';
export const GET_TOPIC_CATEGORY_START = 'GET_TOPIC_CATEGORY_START';
export const GET_TOPIC_CATEGORY_SUCCESS = 'GET_TOPIC_CATEGORY_SUCCESS';
export const GET_TOPIC_CATEGORY_FAIL = 'GET_TOPIC_CATEGORY_FAIL';
export const POST_TOPIC_START = 'POST_TOPIC_START';
export const POST_TOPIC_SUCCESS = 'POST_TOPIC_SUCCESS';
export const POST_TOPIC_FAIL = 'POST_TOPIC_FAIL';
export const GET_TOPIC_VIDEO_TYPES_START = 'GET_TOPIC_VIDEO_TYPES_START';
export const GET_TOPIC_VIDEO_TYPES_SUCCESS = 'GET_TOPIC_VIDEO_TYPES_SUCCESS';
export const GET_TOPIC_VIDEO_TYPES_FAIL = 'GET_TOPIC_VIDEO_TYPES_FAIL';
export const GET_TOPIC_CLASSES_START = 'GET_TOPIC_CLASSES_START';
export const GET_TOPIC_CLASSES_SUCCESS = 'GET_TOPIC_CLASSES_SUCCESS';
export const GET_TOPIC_CLASSES_FAIL = 'GET_TOPIC_CLASSES_FAIL';
export const GET_TOPIC_LEVEL_START = 'GET_TOPIC_LEVEL_START';
export const GET_TOPIC_LEVEL_SUCCESS = 'GET_TOPIC_LEVEL_SUCCESS';
export const GET_TOPIC_LEVEL_FAIL = 'GET_TOPIC_LEVEL_FAIL';
export const GET_TOPICS_START = 'GET_TOPICS_START';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAIL = 'GET_TOPICS_FAIL';
export const GET_TOPICS_COMPILATION_START = 'GET_TOPICS_COMPILATION_START';
export const GET_TOPICS_COMPILATION_SUCCESS = 'GET_TOPICS_COMPILATION_SUCCESS';
export const GET_TOPICS_COMPILATION_FAIL = 'GET_TOPICS_COMPILATION_FAIL';
export const CLEAR_TOPICS_COMPILATION = 'CLEAR_TOPICS_COMPILATION';
export const GET_TOPIC_BY_ID_START = 'GET_TOPIC_BY_ID_START';
export const GET_TOPIC_BY_ID_SUCCESS = 'GET_TOPIC_BY_ID_SUCCESS';
export const GET_TOPIC_BY_ID_FAIL = 'GET_TOPIC_BY_ID_FAIL';
export const UPDATE_TOPIC_START = 'UPDATE_TOPIC_START';
export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS';
export const UPDATE_TOPIC_FAIL = 'UPDATE_TOPIC_FAIL';
export const ADD_CAREER_TOPIC_START = 'ADD_CAREER_TOPIC_START';
export const ADD_CAREER_TOPIC_SUCCESS = 'ADD_CAREER_TOPIC_SUCCESS';
export const ADD_CAREER_TOPIC_FAIL = 'ADD_CAREER_TOPIC_FAIL';
export const DELETE_CAREER_TOPIC_START = 'DELETE_CAREER_TOPIC_START';
export const DELETE_CAREER_TOPIC_SUCCESS = 'DELETE_CAREER_TOPIC_SUCCESS';
export const DELETE_CAREER_TOPIC_FAIL = 'DELETE_CAREER_TOPIC_FAIL';
export const UPDATE_TOPIC_VISIBILITY_START = 'UPDATE_TOPIC_VISIBILITY_START';
export const UPDATE_TOPIC_VISIBILITY_SUCCESS = 'UPDATE_TOPIC_VISIBILITY_SUCCESS';
export const UPDATE_TOPIC_VISIBILITY_FAIL = 'UPDATE_TOPIC_VISIBILITY_FAIL';
export const UPDATE_TOPIC_SEQUENCE_START = 'UPDATE_TOPIC_SEQUENCE_START';
export const UPDATE_TOPIC_SEQUENCE_SUCCESS = 'UPDATE_TOPIC_SEQUENCE_SUCCESS';
export const UPDATE_TOPIC_SEQUENCE_FAIL = 'UPDATE_TOPIC_SEQUENCE_FAIL';
export const DELETE_TOPIC_START = 'DELETE_TOPIC_START';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const DELETE_TOPIC_FAIL = 'DELETE_TOPIC_FAIL';
export const DELETE_AUTHORS_START = 'DELETE_AUTHORS_START';
export const DELETE_AUTHORS_SUCCESS = 'DELETE_AUTHORS_SUCCESS';
export const DELETE_AUTHORS_FAIL = 'DELETE_AUTHORS_FAIL';
export const TOPIC_VALIDATION_FAIL = 'TOPIC_VALIDATION_FAIL';
export const TOPIC_INVALID_CHARACTER_FAIL = 'TOPIC_INVALID_CHARACTER_FAIL';
export const CHANGE_SCOPE_START = 'CHANGE_SCOPE_START';
export const CHANGE_SCOPE_SUCCESS = 'CHANGE_SCOPE_SUCCESS';
export const CHANGE_SCOPE_FAIL = 'CHANGE_SCOPE_FAIL';
export const GET_TOPICS_COUNT_SUCCESS = 'GET_TOPICS_COUNT_SUCCESS';
export const GET_TOPICS_COUNT_START = 'GET_TOPICS_COUNT_START';
export const GET_TOPICS_COUNT_FAIL = 'GET_TOPICS_COUNT_FAIL';
export const CHANGE_TOPIC_FILTER = 'CHANGE_TOPIC_FILTER';
export const CHANGE_LOGGING_FILTER = 'CHANGE_LOGGING_FILTER';
export const CHANGE_LOGGING_ADMIN_FILTER = 'CHANGE_LOGGING_ADMIN_FILTER';
export const CLEAR_TOPIC_FILTER = 'CLEAR_TOPIC_FILTER';
export const CLEAR_LOGGING_FILTER = 'CLEAR_LOGGING_FILTER';
export const CHECK_DELETE_TOPIC_START = 'CHECK_DELETE_TOPIC_START';
export const CHECK_DELETE_TOPIC_SUCCESS = 'CHECK_DELETE_TOPIC_SUCCESS';
export const CHECK_DELETE_TOPIC_FAIL = 'CHECK_DELETE_TOPIC_FAIL';
export const GET_ACTIONS_START = 'GET_ACTIONS_START';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
export const GET_ACTIONS_FAIL = 'GET_ACTIONS_FAIL';
export const GET_LOGGING_START = 'GET_LOGGING_START';
export const GET_LOGGING_SUCCESS = 'GET_LOGGING_SUCCESS';
export const GET_LOGGING_FAIL = 'GET_LOGGING_FAIL';
export const GET_LOGGING_EXPORT_START = 'GET_LOGGING_EXPORT_START';
export const GET_LOGGING_EXPORT_SUCCESS = 'GET_LOGGING_EXPORT_SUCCESS';
export const GET_LOGGING_EXPORT_FAIL = 'GET_LOGGING_EXPORT_FAIL';
export const CHANGE_POSITION_TOPIC_START = 'CHANGE_POSITION_TOPIC_START';
export const CHANGE_POSITION_TOPIC_SUCCESS = 'CHANGE_POSITION_TOPIC_SUCCESS';
export const CHANGE_POSITION_TOPIC_FAIL = 'CHANGE_POSITION_TOPIC_FAIL';

export const GET_ADMIN_LOGGING_START = 'GET_ADMIN_LOGGING_START';
export const GET_ADMIN_LOGGING_SUCCESS = 'GET_ADMIN_LOGGING_SUCCESS';
export const GET_ADMIN_LOGGING_FAIL = 'GET_ADMIN_LOGGING_FAIL';

export const GET_SISTEM_ACTION_START = 'GET_SISTEM_ACTION_START';
export const GET_SISTEM_ACTION_SUCCESS = 'GET_SISTEM_ACTION_SUCCESS';
export const GET_SISTEM_ACTION_FAIL = 'GET_SISTEM_ACTION_FAIL';

export const GET_LOGGING_ADMIN_EXPORT_START = 'GET_LOGGING_ADMIN_EXPORT_START';
export const GET_LOGGING_ADMIN_EXPORT_SUCCESS = 'GET_LOGGING_ADMIN_EXPORT_SUCCESS';
export const GET_LOGGING_ADMIN_EXPORT_FAIL = 'GET_LOGGING_ADMIN_EXPORT_FAIL';

export const CHANGE_DEPEND_START = 'CHANGE_DEPEND_START';
export const CHANGE_DEPEND_SUCCESS = 'CHANGE_DEPEND_SUCCESS';
export const CHANGE_DEPEND_FAIL = 'CHANGE_DEPEND_FAIL';

export const CLEAR_TOPICS = 'CLEAR_TOPICS';

export const GET_TOPIC_SECTION_START = 'GET_TOPIC_SECTION_START';
export const GET_TOPIC_SECTION_SUCCESS = 'GET_TOPIC_SECTION_SUCCESS';
export const GET_TOPIC_SECTION_FAIL = 'GET_TOPIC_SECTION_FAIL';

export const GET_MERITS_START = 'GET_MERITS_START';
export const GET_MERITS_SUCCESS = 'GET_MERITS_SUCCESS';
export const GET_MERITS_FAIL = 'GET_MERITS_FAIL';

export const GET_ALPINA_LINK_START = 'GET_ALPINA_LINK_START';
export const GET_ALPINA_LINK_SUCCESS = 'GET_ALPINA_LINK_SUCCESS';
export const GET_ALPINA_LINK_FAIL = 'GET_ALPINA_LINK_FAIL';

export const GET_TOPIC_REQUEST_EXPORT_START = 'GET_TOPIC_REQUEST_EXPORT_START';
export const GET_TOPIC_REQUEST_EXPORT_SUCCESS = 'GET_TOPIC_REQUEST_EXPORT_SUCCESS';
export const GET_TOPIC_REQUEST_EXPORT_FAIL = 'GET_TOPIC_REQUEST_EXPORT_FAIL';

export const GET_TOPIC_FOR_CHECK_START = 'GET_TOPIC_FOR_CHECK_START';
export const GET_TOPIC_FOR_CHECK_SUCCESS = 'GET_TOPIC_FOR_CHECK_SUCCESS';
export const GET_TOPIC_FOR_CHECK_FAIL = 'GET_TOPIC_FOR_CHECK_FAIL';

export const POST_CREATE_TOPIC_BY_TEMPLATE_START = 'POST_CREATE_TOPIC_BY_TEMPLATE_START';
export const POST_CREATE_TOPIC_BY_TEMPLATE_SUCCESS = 'POST_CREATE_TOPIC_BY_TEMPLATE_SUCCESS';
export const POST_CREATE_TOPIC_BY_TEMPLATE_FAIL = 'POST_CREATE_TOPIC_BY_TEMPLATE_FAIL';

export const GET_TOPIC_PINNED_COUNT_START = 'GET_TOPIC_PINNED_COUNT_START';
export const GET_TOPIC_PINNED_COUNT_SUCCESS = 'GET_TOPIC_PINNED_COUNT_SUCCESS';
export const GET_TOPIC_PINNED_COUNT_FAIL = 'GET_TOPIC_PINNED_COUNT_FAIL';

export const GET_MATERIAL_EXPORT_START = 'GET_MATERIAL_EXPORT_START';
export const GET_MATERIAL_EXPORT_SUCCESS = 'GET_MATERIAL_EXPORT_SUCCESS';
export const GET_MATERIAL_EXPORT_FAIL = 'GET_MATERIAL_EXPORT_FAIL';

export const GET_TOPIC_COMPETENCIES_START = 'GET_TOPIC_COMPETENCIES_START';
export const GET_TOPIC_COMPETENCIES_SUCCESS = 'GET_TOPIC_COMPETENCIES_SUCCESS';
export const GET_TOPIC_COMPETENCIES_FAIL = 'GET_TOPIC_COMPETENCIES_FAIL';

export const GET_TOPIC_MANDATORY_START = 'GET_TOPIC_MANDATORY_START';
export const GET_TOPIC_MANDATORY_SUCCESS = 'GET_TOPIC_MANDATORY_SUCCESS';
export const GET_TOPIC_MANDATORY_FAIL = 'GET_TOPIC_MANDATORY_FAIL';

export const GET_TOPIC_JOB_LEVEL_START = 'GET_TOPIC_JOB_LEVEL_START';
export const GET_TOPIC_JOB_LEVEL_SUCCESS = 'GET_TOPIC_JOB_LEVEL_SUCCESS';
export const GET_TOPIC_JOB_LEVEL_FAIL = 'GET_TOPIC_JOB_LEVEL_FAIL';

export const GET_COURSES_EXPORT_START = 'GET_COURSES_EXPORT_START';
export const GET_COURSES_EXPORT_SUCCESS = 'GET_COURSES_EXPORT_SUCCESS';
export const GET_COURSES_EXPORT_FAIL = 'GET_COURSES_EXPORT_FAIL';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getTopicResultExport = (userId, topicId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/block/result/export/${getQueryString({
      userIds: [userId],
      topicId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    responseType: 'blob',
    onSuccess,
    onError,
    types: [GET_TOPIC_REQUEST_EXPORT_START, GET_TOPIC_REQUEST_EXPORT_SUCCESS, GET_TOPIC_REQUEST_EXPORT_FAIL],
  };
};

const getTopicBodyApiValues = body => {
  const skillNames = body.skillNames && body.skillNames.map(skill => skill.name.toLowerCase());
  const tagNames = body.tagNames && body.tagNames.map(tag => tag.name.toLowerCase());
  const careers = body.careers && body.careers.map(tag => tag.name.toLowerCase());
  const authorNames = body.authors
    ? body.authors.map(author => {
        if (author.name) {
          return author.name;
        }
        return author;
      })
    : [];
  return { ...body, tagNames, skillNames, authorNames, careers };
};

const getTopicFilterApiValues = filter => {
  if (typeof filter.topicTypeId === 'undefined') {
    delete filter.topicTypeId;
  }
  if (typeof filter.type === 'undefined') {
    delete filter.type;
  }
  const { ...rest } = filter;
  return rest;
};

export const getTopicTypes = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `
      ${API_URL}/topic/type`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_TOPIC_TYPES_START, GET_TOPIC_TYPES_SUCCESS, GET_TOPIC_TYPES_FAIL],
});

export const getAlpinaLink = (url, onSuccess) => ({
  type: REST_API,
  method: GET,
  url,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ALPINA_LINK_START, GET_ALPINA_LINK_SUCCESS, GET_ALPINA_LINK_FAIL],
  onSuccess,
});

export const getTopicTypesAuto = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/auto-registration`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_TOPIC_TYPES_AUTO_START, GET_TOPIC_TYPES_AUTO_SUCCESS, GET_TOPIC_TYPES_AUTO_FAIL],
});

export const setTopicTypesAuto = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/type/auto-registration`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [SET_TOPIC_TYPES_AUTO_START, SET_TOPIC_TYPES_AUTO_SUCCESS, SET_TOPIC_TYPES_AUTO_FAIL],
});

export const getMerits = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/trajectory/merit`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MERITS_START, GET_MERITS_SUCCESS, GET_MERITS_FAIL],
});

export const getTopicSubTypes = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_TOPIC_SUBTYPES_START, GET_TOPIC_SUBTYPES_SUCCESS, GET_TOPIC_SUBTYPES_FAIL],
});

export const getTopics = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic${getQueryString({
      size: 20,
      sort: params?.pinned ? 'positions.position,asc' : 'createdDate,desc',
      ...params,
      isUserModal: null,
      forCollection: null,
      status: params.status ?? 'ACTIVE',
      excludedTopicType: TopicTypes.Skills,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    isUserModal: params && params.isUserModal,
    forCollection: params && params.forCollection,
    types: [GET_TOPICS_START, GET_TOPICS_SUCCESS, GET_TOPICS_FAIL],
    onSuccess,
  };
};

export const getTopicsCompilation = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/short/topic${getQueryString({
      size: 20,
      sort: params.pinned ? 'positions.positions,asc' : 'id,desc',
      ...params,
      isUserModal: null,
      forCollection: null,
      excludedTopicType: TopicTypes.Skills,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TOPICS_COMPILATION_START, GET_TOPICS_COMPILATION_SUCCESS, GET_TOPICS_COMPILATION_FAIL],
    onSuccess,
  };
};

export const getShortTopics = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/short/topic${getQueryString({
      size: 20,
      sort: params.pinned ? 'positions.positions,asc' : 'id,desc',
      ...params,
      isUserModal: null,
      forCollection: null,
      excludedTopicType: TopicTypes.Skills,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    isUserModal: params && params.isUserModal,
    forCollection: params && params.forCollection,
    topicTypeId: params && params.topicTypeId,
    topicTypeLabelId: params && params.topicTypeLabelId,
    name: params && params.name,
    page: params && params.page,
    withClear: params && params.withClear,
    types: [GET_TOPICS_START, GET_TOPICS_SUCCESS, GET_TOPICS_FAIL],
    onSuccess,
  };
};

export const getTopicsCount = (scope = '') => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/scope${getQueryString({ scope })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TOPICS_COUNT_START, GET_TOPICS_COUNT_SUCCESS, GET_TOPICS_COUNT_FAIL],
  };
};

export const changeScope = (topicIds, scope) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/topic/scope${getQueryString({ scope, topicIds })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: { scope, topicIds },
    types: [CHANGE_SCOPE_START, CHANGE_SCOPE_SUCCESS, CHANGE_SCOPE_FAIL],
  };
};
export const getTopicById = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/${encodeURI(id)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_BY_ID_START, GET_TOPIC_BY_ID_SUCCESS, GET_TOPIC_BY_ID_FAIL],
  onSuccess,
  onError,
});

export const getTopicCategories = isGlobal => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/category${getQueryString({
    isGlobal,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_CATEGORY_START, GET_TOPIC_CATEGORY_SUCCESS, GET_TOPIC_CATEGORY_FAIL],
});

export const getTopicVideoTypes = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/video`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_VIDEO_TYPES_START, GET_TOPIC_VIDEO_TYPES_SUCCESS, GET_TOPIC_VIDEO_TYPES_FAIL],
});

export const getTopicClasses = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/class`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_CLASSES_START, GET_TOPIC_CLASSES_SUCCESS, GET_TOPIC_CLASSES_FAIL],
});

export const getTopicLevel = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/level`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_LEVEL_START, GET_TOPIC_LEVEL_SUCCESS, GET_TOPIC_LEVEL_FAIL],
  onSuccess,
});

export const createTopic = (topic, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(topic),
  types: [POST_TOPIC_START, POST_TOPIC_SUCCESS, POST_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const editTopic = ({ id, ...topic }, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${encodeURI(id)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(topic),
  types: [UPDATE_TOPIC_START, UPDATE_TOPIC_SUCCESS, UPDATE_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const addTopicCareer = ({ id, careerId }, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${encodeURI(id)}/career`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    careerId,
  },
  types: [ADD_CAREER_TOPIC_START, ADD_CAREER_TOPIC_SUCCESS, ADD_CAREER_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const deleteTopicCareer = ({ id, careerId }, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/topic/${encodeURI(id)}/career`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    careerId,
  },
  types: [DELETE_CAREER_TOPIC_START, DELETE_CAREER_TOPIC_SUCCESS, DELETE_CAREER_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const editTopicVisibility = ({ id }, isVisible, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${id}/visible${getQueryString({ isVisible })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  isVisible,
  types: [UPDATE_TOPIC_VISIBILITY_START, UPDATE_TOPIC_VISIBILITY_SUCCESS, UPDATE_TOPIC_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const editTopicSequence = ({ id, followSequence }, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${encodeURI(id)}/followSequence${getQueryString({
    followSequence: !followSequence,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    followSequence: !followSequence,
  },
  types: [UPDATE_TOPIC_SEQUENCE_START, UPDATE_TOPIC_SEQUENCE_SUCCESS, UPDATE_TOPIC_SEQUENCE_FAIL],
  onSuccess,
  onError,
});

export const deleteTopic = (id, comment, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/topic/${encodeURI(id)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { comment },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_TOPIC_START, DELETE_TOPIC_SUCCESS, DELETE_TOPIC_FAIL],
});

export const changePositionTopic = (topicId, position, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${encodeURI(topicId)}/pin/${position}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [CHANGE_POSITION_TOPIC_START, CHANGE_POSITION_TOPIC_SUCCESS, CHANGE_POSITION_TOPIC_FAIL],
  onSuccess,
  topicId,
  position,
});

export const checkDeleteTopic = (topicId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/request/event/active${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_DELETE_TOPIC_START, CHECK_DELETE_TOPIC_SUCCESS, CHECK_DELETE_TOPIC_FAIL],
  onSuccess,
});

export const getLogging = (loggingFilter, page = 0, onSuccess) => {
  const apiFilter = getTopicFilterApiValues(loggingFilter);
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/log/topic${getQueryString({ page, sort: 'creationTime,desc' })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: apiFilter,
    onSuccess,
    types: [GET_LOGGING_START, GET_LOGGING_SUCCESS, GET_LOGGING_FAIL],
  };
};

export const getAdminLogging = (loggingFilter, page = 0) => {
  const apiFilter = getTopicFilterApiValues(loggingFilter);
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/log/system${getQueryString({
      ...apiFilter,
      page,
      size: 20,
      sort: 'createdTime,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_ADMIN_LOGGING_START, GET_ADMIN_LOGGING_SUCCESS, GET_ADMIN_LOGGING_FAIL],
  };
};

export const getSystemAction = () => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/log/system/action`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_SISTEM_ACTION_START, GET_SISTEM_ACTION_SUCCESS, GET_SISTEM_ACTION_FAIL],
  };
};

export const getLoggingExport = (filename, loggingFilter, onSuccess) => {
  const apiFilter = getTopicFilterApiValues(loggingFilter);
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/log/topic/export${getQueryString({
      filename,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: apiFilter,
    responseType: 'blob',
    onSuccess,
    types: [GET_LOGGING_EXPORT_START, GET_LOGGING_EXPORT_SUCCESS, GET_LOGGING_EXPORT_FAIL],
  };
};

export const getCoursesExport = (filename, loggingFilter, onSuccess) => {
  const apiFilter = getTopicFilterApiValues(loggingFilter);
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/event/export${getQueryString({
      filename,
      from: apiFilter.from,
      to: apiFilter.to,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    responseType: 'blob',
    onSuccess,
    types: [GET_COURSES_EXPORT_START, GET_COURSES_EXPORT_SUCCESS, GET_COURSES_EXPORT_FAIL],
  };
};

export const getLoggingAdminExport = (filename, loggingFilter, onSuccess) => {
  const apiFilter = getTopicFilterApiValues(loggingFilter || {});
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/log/system/export${getQueryString({
      filename,
      ...apiFilter,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    responseType: 'blob',
    onSuccess,
    types: [GET_LOGGING_ADMIN_EXPORT_START, GET_LOGGING_ADMIN_EXPORT_SUCCESS, GET_LOGGING_ADMIN_EXPORT_FAIL],
  };
};

export const getActions = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/log/topic/action`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ACTIONS_START, GET_ACTIONS_SUCCESS, GET_ACTIONS_FAIL],
});

export const deleteAuthors = (name, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/author${getQueryString({ name })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_AUTHORS_START, DELETE_AUTHORS_SUCCESS, DELETE_AUTHORS_FAIL],
  onSuccess,
});

export const changeIndependentStatus = (id, state, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${id}/depend/${state}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [CHANGE_DEPEND_START, CHANGE_DEPEND_SUCCESS, CHANGE_DEPEND_FAIL],
  onSuccess,
});

export const getTopicSection = ({ ids, onSuccess } = {}) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/section${getQueryString({ ids })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_SECTION_START, GET_TOPIC_SECTION_SUCCESS, GET_TOPIC_SECTION_FAIL],
  onSuccess,
});

export const checkTopic = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/${encodeURI(topicId)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_FOR_CHECK_START, GET_TOPIC_FOR_CHECK_SUCCESS, GET_TOPIC_FOR_CHECK_FAIL],
  onSuccess,
  onError,
});

export const createTopicByTemplate = (topicVM, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/create/by-template/${topicVM.id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(topicVM),
  types: [
    POST_CREATE_TOPIC_BY_TEMPLATE_START,
    POST_CREATE_TOPIC_BY_TEMPLATE_SUCCESS,
    POST_CREATE_TOPIC_BY_TEMPLATE_FAIL,
  ],
  onSuccess,
});

export const getPinnedTopicsCount = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/pinnedCount`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_PINNED_COUNT_START, GET_TOPIC_PINNED_COUNT_SUCCESS, GET_TOPIC_PINNED_COUNT_FAIL],
  onSuccess,
});

export const activeStatus = (topicId, status, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/topic/${topicId}/${status}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [ACTIVE_STATUS_START, ACTIVE_STATUS_SUCCESS, ACTIVE_STATUS_FAIL],
  onSuccess,
  topicId,
  status,
});

export const newMaterialExport = (topicId, filters, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/${topicId}/export${getQueryString({ ...filters })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'blob',
  types: [GET_MATERIAL_EXPORT_START, GET_MATERIAL_EXPORT_SUCCESS, GET_MATERIAL_EXPORT_FAIL],
  onSuccess,
  onError,
});

export const getTopicJobLevels = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/jobLevel`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_JOB_LEVEL_START, GET_TOPIC_JOB_LEVEL_SUCCESS, GET_TOPIC_JOB_LEVEL_FAIL],
});

export const getTopicCategoryCompetencies = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/competency`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_COMPETENCIES_START, GET_TOPIC_COMPETENCIES_SUCCESS, GET_TOPIC_COMPETENCIES_FAIL],
});

export const getTopicMandatory = (isAdminPart = true) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/mandatory${getQueryString({ isAdminPart })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_MANDATORY_START, GET_TOPIC_MANDATORY_SUCCESS, GET_TOPIC_MANDATORY_FAIL],
});

export const changeLoggingFilter = info => ({
  type: CHANGE_LOGGING_FILTER,
  info,
});

export const changeLoggingAdminFilter = info => ({
  type: CHANGE_LOGGING_ADMIN_FILTER,
  info,
});

export const clearLoggingFilter = () => ({
  type: CLEAR_LOGGING_FILTER,
});

export const changeTopicFilter = info => ({
  type: CHANGE_TOPIC_FILTER,
  info,
});

export const clearTopicFilter = () => ({
  type: CLEAR_TOPIC_FILTER,
});

export const failTopicValidation = () => ({
  type: TOPIC_VALIDATION_FAIL,
});

export const failTopicValidationCharacter = () => ({
  type: TOPIC_INVALID_CHARACTER_FAIL,
});

export const clearTopics = () => ({
  type: CLEAR_TOPICS,
});

export const clearTopicsCompilation = () => ({
  type: CLEAR_TOPICS_COMPILATION,
});
