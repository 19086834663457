import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFunctions } from '@/store/function/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Table, Tooltip } from 'antd';
import { CloseSquareOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './Dictionary.module.scss';

import { FUNCTION_INTERNAL_DELETE, FUNCTION_INTERNAL_UPDATE } from '@/constants/permissions';

const DictionaryFucTable = ({ onEdit, onDelete, chooseTypicalPosition, changePage }) => {
  const { t } = useTranslation('dictionaryTable');
  const { functions, functionsTotal, functionsPageSize, functionsPageNumber, isLoading } = useSelector(selectFunctions);
  const { currentUser } = useSelector(selectUsers);

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      width: '1047px',
      render: (_, record) =>
        Utils.maxTextValue(record.name) ? (
          <Tooltip title={record.name} placement='topLeft'>
            <div className={css['DictionaryItem-name']}>{record.name}</div>
          </Tooltip>
        ) : (
          <div className={css['DictionaryItem-name']}>{record.name}</div>
        ),
    },
    {
      title: t('actions'),
      key: 'actions',
      width: '80px',
      render: (_, record) => (
        <div className={css['DictionaryItem-actions']}>
          {record.hasNoPokaPositionLink && (
            <Tooltip title={t('positionNotLinkedToTypical')}>
              <CloseSquareOutlined
                onClick={() => chooseTypicalPosition(record)}
                className={css['DictionaryItem-icon-notLinked']}
              />
            </Tooltip>
          )}
          {currentUser?.authorities.includes(FUNCTION_INTERNAL_UPDATE) && (
            <Tooltip title={t('editButton')}>
              <FormOutlined onClick={() => onEdit(record)} className={css['DictionaryItem-icon-edit']} />
            </Tooltip>
          )}
          {currentUser?.authorities.includes(FUNCTION_INTERNAL_DELETE) && (
            <Tooltip title={t('deleteButton')}>
              <DeleteOutlined onClick={() => onDelete(record)} className={css['DictionaryItem-icon-delete']} />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const pagination = {
    current: functionsPageNumber + 1,
    total: functionsTotal,
    pageSize: functionsPageSize,
    pageNumber: functionsPageNumber,
    showSizeChanger: false,
    responsive: true,
    onChange: page => changePage(page),
    hideOnSinglePage: true,
  };

  return (
    <Table
      dataSource={functions}
      columns={columns}
      rowKey={e => e.id}
      pagination={pagination}
      className={css['DictionaryItem-table']}
      bordered
      loading={isLoading}
    />
  );
};

DictionaryFucTable.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  chooseTypicalPosition: PropTypes.func,
};

export default DictionaryFucTable;
