import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd';

import PropTypes from 'prop-types';

import style from '../style.module.scss';

export const Inputs = props => {
  const Label = () => (
    <>
      {props.label}
      {props.labelButton && (
        <Button type='link' size='small' onClick={props.labelButton.onClick} className={style['inputs__label_button']}>
          {props.labelButton.text}
        </Button>
      )}
    </>
  );

  switch (props.inputs) {
    case 'input':
      if (props.type === 'number') {
        return (
          <Form.Item label={<Label />} name={props.name}>
            <InputNumber className={style['inputs__elements']} {...props} />
          </Form.Item>
        );
      } else {
        return (
          <Form.Item label={<Label />} name={props.name}>
            <Input allowClear {...props} />
          </Form.Item>
        );
      }

    case 'select':
      return (
        <Form.Item label={<Label />} name={props.name}>
          <Select allowClear className={style['inputs__elements']} {...props} />
        </Form.Item>
      );

    case 'checkbox':
      return (
        <Form.Item valuePropName='checked' label=' ' name={props.name}>
          <div>
            <Checkbox />
            <span className={style['inputs__label']}>{<Label />}</span>
          </div>
        </Form.Item>
      );

    default:
      return <></>;
  }
};

Inputs.propTypes = {
  labelButton: PropTypes.any, // Если необходимо добавить доп кнопку к label
  inputs: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};
