/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from 'antd';
import TypeBadge from '@/components/TypeBadge';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';
import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

import { TOPIC_TYPES } from '@/constants/topic-types';

class MaterialCard extends Component {
  static propTypes = {
    coverColor: PropTypes.string,
    coverFileUuid: PropTypes.string,
    domainCompany: PropTypes.object,
    getFile: PropTypes.func,
    id: PropTypes.number,
    name: PropTypes.string,
    startDate: PropTypes.string,
    startTime: PropTypes.string,
    thumbFileUuid: PropTypes.string,
    topic: PropTypes.object,
    type: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      cover:
        props.type && isCorpCourseWithOrWithoutApprove(props.type.id)
          ? this.props.corpCourseBackground || TOPIC_IMAGE.find(type => type.id === props.type.id)?.imageCompany
          : TOPIC_IMAGE.find(type => type.id === props.type.id)?.image,
      readyToBackground: false,
    };
  }

  componentDidMount() {
    if (this.props.coverFileUuid || this.props.thumbFileUuid) {
      this.props.getFile(this.props.coverFileUuid || this.props.thumbFileUuid, blob => {
        const cover = URL.createObjectURL(blob);
        this.setState({
          cover,
          readyToBackground: true,
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.corpCourseBackground !== prevProps.corpCourseBackground) {
      if (
        !(this.props.coverFileUuid || this.props.thumbFileUuid) &&
        isCorpCourseWithOrWithoutApprove(this.props.topic.type.id)
      ) {
        const cover =
          this.props.corpCourseBackground ||
          TOPIC_IMAGE.find(type => type.id === this.props.topic.type.id)?.imageCompany;
        this.setState({ cover });
      }
    }
  }

  render() {
    const { name, type, id, coverColor } = this.props;
    const { cover, readyToBackground } = this.state;
    const topicType = type && _.find(TOPIC_TYPES(this.props.t), item => item.id === type.id);
    const typeBadge = topicType ? (
      <TypeBadge small portraitPlaceholder={!cover} type={topicType.code} name={type.name} />
    ) : null;
    return (
      <div className={css.UserCard}>
        <div className={css.UserCard__image} style={{ backgroundColor: readyToBackground && coverColor }}>
          <img src={cover} alt='' />
          {typeBadge}
        </div>
        <div className={css.UserCard__title}>
          <Link data-qa='adminCompilationDetailMaterialName' to={`/topic/${id}`}>
            {name.length < 170 ? name : <Tooltip title={name}>{`${name.substring(0, 170)}...`}</Tooltip>}
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation(['constants', 'constants'])(MaterialCard);
