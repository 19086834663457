import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { exportTopics, importStudyPlan } from '@/store/files/actions';
import { getImportPlan } from '@/store/importPlan/actions';
import { getPokaDirCategory, getPokaDirSite, getPokaDirStatus, getPokaSkills } from '@/store/poka_skills/actions';
import { deleteTopic } from '@/store/topics/actions';

import { selectPokaSkills } from '@/store/poka_skills/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, List } from 'antd';
import Skill from '@/pages/Skills/components/Skill';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import Filter from '@/components/Filter';

import TopicCreationDialog from '../Topics/TopicCreationDialog';

import { TopicTypes } from '@shared/constants/topics';

import Utils from '@/Utils';

import style from './style.module.scss';

const filename = 'skills.csv';

const Skills = () => {
  const { t } = useTranslation('skills');
  const { topics, loading, sites, categories, statuses, ...pokaSkills } = useSelector(selectPokaSkills);
  const { currentUser } = useSelector(selectUsers);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);

  const currentSearchParams = useMemo(() => {
    const initialValues = {};

    for (const [key, value] of searchParams.entries()) {
      initialValues[key] = value;
    }

    return initialValues;
  }, [searchParams]);

  const onGetTopics = () => dispatch(getPokaSkills(currentSearchParams));

  useEffect(() => {
    onGetTopics();
  }, [searchParams]);

  useEffect(() => {
    dispatch(getPokaDirCategory());
    dispatch(getPokaDirSite());
    dispatch(getPokaDirStatus());
  }, []);

  const onAddTopic = () => {
    setVisibleCreateModal(false);
    onGetTopics();
  };

  const onDeleteTopic = id => {
    dispatch(deleteTopic(id, '', () => onGetTopics()));
  };

  const onChangePage = page => {
    const params = new URLSearchParams(searchParams);
    params.set('page', (page - 1).toString());
    setSearchParams(params);
  };

  const pageNumber = parseInt(searchParams.get('page') ?? pokaSkills.pagination.getPokaSkills.pageNumber);

  const pagination = {
    current: pageNumber + 1,
    pageSize: pokaSkills.pagination.getPokaSkills.pageSize,
    total: pokaSkills.pagination.getPokaSkills.total,
    showSizeChanger: false,
    align: 'center',
    responsive: true,
    hideOnSinglePage: true,
    onChange: onChangePage,
  };

  const onExportSkills = () => {
    dispatch(
      exportTopics({ filename, status: 'ACTIVE', ...currentSearchParams, topicTypeId: [TopicTypes.Skills] }, blob => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, 'topics.csv');
        }

        const objectURL = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.download = 'topics.csv';
        tempLink.style = 'display: none';
        tempLink.href = objectURL;

        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(objectURL);
        }, 1000);
      })
    );
  };

  const filters = [
    { label: t('filterLabels.0'), placeholder: t('filterPlaceholders.0'), name: 'name', inputs: 'input' },
    {
      label: t('filterLabels.1'),
      placeholder: t('filterPlaceholders.1'),
      min: 0,
      name: 'ids',
      inputs: 'input',
      type: 'number',
    },
    {
      label: t('filterLabels.2'),
      placeholder: t('filterPlaceholders.2'),
      name: 'generalApprovalStatus',
      inputs: 'select',
      options: statuses,
    },
    {
      label: t('filterLabels.3'),
      placeholder: t('filterPlaceholders.3'),
      name: 'pokaCategories',
      inputs: 'select',
      options: categories,
    },
    {
      label: t('filterLabels.4'),
      placeholder: t('filterPlaceholders.4'),
      name: 'pokaSites',
      inputs: 'select',
      options: sites.map(({ value, label }) => ({ value: String(value), label })),
    },
    // { label: t('filterLabels.5'), name: 'test', inputs: 'checkbox' }, // remove temporarily checkbox with archived skills
  ];

  return (
    <Layout>
      <Layout.Content className={style['skills']}>
        <div className={style['skills__header']}>
          <h1>{t('caption')}</h1>
          <div className={style['skills__buttons']}>
            <Button type='primary' onClick={() => setVisibleCreateModal(true)}>
              {t('add')}
            </Button>
            <Button onClick={() => setVisibleImport(true)} type='primary'>
              {t('import')}
            </Button>
            <Button loading={loading.exportSkills} onClick={onExportSkills} type='primary'>
              {t('export')}
            </Button>
          </div>
        </div>
        <Filter filters={filters} />
        <List
          grid={{
            gutter: 20,
            column: 4,
          }}
          loading={loading.getPokaSkills}
          dataSource={topics}
          renderItem={item => (
            <List.Item key={item.id}>
              <Skill {...item} onDeleteTopic={onDeleteTopic} updateSkills={onGetTopics} />
            </List.Item>
          )}
          pagination={pagination}
        />
        {visibleImport && (
          <DictionaryImportDialog
            open={visibleImport}
            isTopics
            onCancel={() => setVisibleImport(false)}
            fileDescription={t('downloadExample')}
            description1={t('importText')}
            exampleFileFunc={template => dispatch(getImportPlan(template))}
            onOk={(params, onSuccess) => dispatch(importStudyPlan(params, onSuccess))}
          />
        )}
        {visibleCreateModal && (
          <TopicCreationDialog
            open={visibleCreateModal}
            onCancel={() => setVisibleCreateModal(false)}
            isManager={Utils.isManager(currentUser)}
            onTopicCreationSuccess={onAddTopic}
            isSkills
          />
        )}
      </Layout.Content>
    </Layout>
  );
};

export default Skills;
