/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getEventsGrours, getRegistrationOpenDate } from '@/store/courses/actions';

import { selectCourses as courseSelect } from '@/store/courses/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';

import { Button } from 'antd';

import EventRow from '../EventRow';
import ScrollArrow from '../ScrollArrow';
import Spinner from '../Spinner';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

class EventModal extends Component {
  static propTypes = {
    activeEvents: PropTypes.any,
    dateEvent: PropTypes.string,
    disabledSelect: PropTypes.bool,
    eventTypeId: PropTypes.number,
    getEventsGrours: PropTypes.func,
    getRegistrationOpenDate: PropTypes.func,
    handleCalendarModalData: PropTypes.func,
    headerFixToModal: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingGroup: PropTypes.bool,
    isLoadingPlan: PropTypes.bool,
    noComment: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    openDate: PropTypes.string,
    planInfo: PropTypes.object,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    title: PropTypes.any,
    topicInfo: PropTypes.object,
    open: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.currentYear = Number(dayjs().format('YYYY'));

    const selectedMonths = props.activeEvents?.[this.currentYear] && [
      dayjs(props.activeEvents[this.currentYear][0].startTime).format('YYYY-MM-DD'),
    ];

    const selected = {
      [props.activeEvents?.[this.currentYear] && props.activeEvents[this.currentYear][0].id]: true,
    };

    this.state = {
      yearIndex: 0,
      years: [],
      selectedYear: null,
      activeEvents: props.activeEvents,
      selectedMonths,
      selected,
      selectedId: null,
    };
  }

  componentDidMount() {
    this.props.getRegistrationOpenDate();
    this.props.getEventsGrours({ topicId: this.props.topicInfo.id }, res => {
      this.years = Object.keys(res);
      const firstList = res[this.years[0]]?.[0];
      if (firstList) {
        this.setState({
          years: this.years,
          selectedYear: this.years[0],
          activeEvents: res,
          selectedMonths: [dayjs(firstList.startTime).format('YYYY-MM-DD')],
          selected: {
            [firstList.id]: true,
          },
        });
      } else {
        this.props.handleCalendarModalData({ hasEventsFlag: false });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.topicInfo.id !== this.props.topicInfo.id) {
      this.props.getEventsGrours({ topicId: this.props.topicInfo.id });
    }
    if (
      this.props.activeEvents &&
      prevProps.activeEvents !== this.props.activeEvents &&
      Object.keys(this.props.activeEvents).length
    ) {
      this.years = Object.keys(this.props.activeEvents);

      const activeEventByYear = this.props.activeEvents[this.years[this.state.yearIndex]][0];
      const selectedMonths = this.props.activeEvents
        ? [dayjs(activeEventByYear?.startTime).format('YYYY-MM-DD')]
        : dayjs().format('YYYY-MM-DD');

      const selected = {
        [activeEventByYear.id]: true,
      };

      this.setState({
        years: this.years,
        selectedYear: this.years[this.state.yearIndex],
        activeEvents: this.props.activeEvents,
        selectedMonths,
        selected,
      });
    }
    if (
      this.props.activeEvents &&
      this.years &&
      prevState.selectedYear !== this.state.selectedYear &&
      Object.keys(this.props.activeEvents).length
    ) {
      if (
        (this.props.activeEvents[this.years[this.state.yearIndex]] &&
          this.props.activeEvents[this.years[this.state.yearIndex]].length) !== 1
      ) {
        this.setState({
          activeEvents: this.props.activeEvents,
          selectedMonths: [],
          selected: {},
          btnDisabled: true,
        });
      } else {
        const selectedMonths = [
          dayjs(this.props.activeEvents[this.years[this.state.yearIndex]][0].startTime).format('YYYY-MM-DD'),
        ];

        const selected = {
          [this.props.activeEvents && this.props.activeEvents[this.years[this.state.yearIndex]][0].id]: true,
        };

        this.setState({ selectedMonths, selected, btnDisabled: false });
      }
    }
    if (prevProps.open !== this.props.open) {
      this.setState({
        selectedYear: this.years && this.years[this.state.yearIndex],
        comment: '',
        selectedMonths: [],
      });
    }
  }

  increaseYear = () => {
    this.setState(state => ({
      yearIndex: state.yearIndex + 1,
      selectedYear: this.years[state.yearIndex + 1],
    }));
  };

  decreaseYear = () => {
    this.setState(state => ({
      yearIndex: state.yearIndex - 1,
      selectedYear: this.years[state.yearIndex - 1],
    }));
  };

  setParams = (id, date) => {
    this.setState({
      selected: {
        [id]: true,
      },
      selectedId: id,
      selectedMonths: [dayjs(date).format('YYYY-MM-DD')],
      btnDisabled: false,
    });
  };

  handleOkClick = () => {
    const { onOk, planningTopicId, rest, planInfo } = this.props;
    const { selectedId } = this.state;

    if (onOk) {
      onOk(planningTopicId, this.state.selectedMonths, this.state.comment, false, rest, planInfo, selectedId);
    }
  };

  render() {
    const { okText, eventTypeId, isLoading, isLoadingPlan, title, isLoadingGroup } = this.props;
    const { activeEvents, selected, yearIndex, selectedYear } = this.state;
    return (
      <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
        <div
          className={classNames(
            css['Calendar-modal-header'],
            css['Calendar-modal-header--ext'],
            css['Calendar-modal-header--flex']
          )}
        >
          <div>{title}</div>
          <div>
            <div className={classNames(css['Calendar-modal-year'], css['Calendar-modal-year--ext'])}>
              <ScrollArrow
                scrollDirection='left'
                disabled={!Number(this.state.years[yearIndex - 1])}
                onClick={this.decreaseYear}
                light
              />
              <div className={classNames(css['Calendar-modal-year-value'], css['Calendar-modal-year--ext-value'])}>
                {this.state.years[yearIndex]}
              </div>
              <ScrollArrow
                scrollDirection='right'
                disabled={!Number(this.state.years[yearIndex + 1])}
                onClick={this.increaseYear}
                light
              />
            </div>
          </div>
        </div>
        {isLoadingGroup || isLoading ? (
          <Spinner />
        ) : (
          <div className={css['Calendar-modal-eventBox']}>
            {selectedYear &&
              activeEvents[selectedYear].map((event, index) =>
                event.active ? (
                  <EventRow
                    key={index}
                    event={event}
                    setParams={this.setParams}
                    selected={selected && selected[event.id]}
                    topicType={eventTypeId}
                    isInModal
                  />
                ) : null
              )}
          </div>
        )}
        <div className={classNames(css['Calendar-modal__row__btn-box'], css.toCenter)}>
          <Button
            disabled={isLoadingPlan || isLoading || this.state.btnDisabled}
            className={css['Calendar-modal-btn__orange']}
            // orange
            type='primary'
            onClick={this.handleOkClick}
          >
            {okText}
          </Button>
          <Button type='primary' onClick={() => this.props.handleCalendarModalData({ hasEventsFlag: true })}>
            Выбрать другую дату
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector([courseSelect, topicsSelect], (course, plan, topics, userTopics) => ({
  activeEvents: course.eventsGroups,
  openDate: course.openDate,
  isLoading: (plan && plan.isLoading) || (topics && topics.isLoading) || (userTopics && userTopics.isLoading),
  isLoadingGroup: course.isLoading,
}));

const mapActionsToProps = {
  getRegistrationOpenDate,
  getEventsGrours,
};

export default connect(mapStateToProps, mapActionsToProps)(EventModal);
