// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-ModalTrack-ModalTrack-module__ql-picker-M6QjJy.admin-src-components-ModalTrack-ModalTrack-module__ql-font-eEIM6c .admin-src-components-ModalTrack-ModalTrack-module__ql-picker-label-JCW_9n[data-value="Montserrat"]:before, .admin-src-components-ModalTrack-ModalTrack-module__ql-picker-M6QjJy.admin-src-components-ModalTrack-ModalTrack-module__ql-font-eEIM6c .admin-src-components-ModalTrack-ModalTrack-module__ql-picker-item-plOOd7[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-ModalTrack-ModalTrack-module__ql-font-Montserrat-IByfeF {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-spin-wND4I3 {
  justify-content: center;
  display: flex;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-tags-lPwj2P {
  margin-top: 4px;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-upload-k1EWl1 {
  margin-bottom: 2px;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-chooseBtn-AeOqzC {
  padding: 0;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-spin-DtOioG {
  margin-top: 16px;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-number-GavI8S {
  width: 100%;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-header-Fo3doR {
  color: #000;
  font-size: 16px;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-alert-NgbF1X {
  margin: 16px 0;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-noData-vN8Tyh {
  text-align: center;
}

.admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-addMaterial-T0RyHE {
  justify-content: end;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/ModalTrack/ModalTrack.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,uBAAuB;EACvB,aAAa;AACf;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,kBAAkB;AACpB;;AAIA;EADE,UAAU;AACZ;;AAGA;EAAE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AACA;EAEE,WAAW;EACX,eAAe;AACjB;;AAAA;EAGE,cAAc;AAChB;;AADA;EAIE,kBAAkB;AACpB;;AAFA;EAKE,oBAAoB;EACpB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ModalTrack-spin {\n  display: flex;\n  justify-content: center;\n}\n.ModalTrack-form-tags {\n  margin-top: 4px;\n}\n.ModalTrack-form-upload {\n  margin-bottom: 2px;\n}\n.ModalTrack-form-chooseBtn {\n  padding: 0;\n}\n.ModalTrack-form-spin {\n  margin-top: 16px;\n}\n.ModalTrack-form-number {\n  width: 100%;\n}\n.ModalTrack-trackContent-header {\n  color: #000;\n  font-size: 16px;\n}\n.ModalTrack-trackContent-alert {\n  margin: 16px 0;\n}\n.ModalTrack-trackContent-noData {\n  text-align: center;\n}\n.ModalTrack-trackContent-addMaterial {\n  display: flex;\n  justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-ModalTrack-ModalTrack-module__ql-picker-M6QjJy`,
	"qlPicker": `admin-src-components-ModalTrack-ModalTrack-module__ql-picker-M6QjJy`,
	"ql-font": `admin-src-components-ModalTrack-ModalTrack-module__ql-font-eEIM6c`,
	"qlFont": `admin-src-components-ModalTrack-ModalTrack-module__ql-font-eEIM6c`,
	"ql-picker-label": `admin-src-components-ModalTrack-ModalTrack-module__ql-picker-label-JCW_9n`,
	"qlPickerLabel": `admin-src-components-ModalTrack-ModalTrack-module__ql-picker-label-JCW_9n`,
	"ql-picker-item": `admin-src-components-ModalTrack-ModalTrack-module__ql-picker-item-plOOd7`,
	"qlPickerItem": `admin-src-components-ModalTrack-ModalTrack-module__ql-picker-item-plOOd7`,
	"ql-font-Montserrat": `admin-src-components-ModalTrack-ModalTrack-module__ql-font-Montserrat-IByfeF`,
	"qlFontMontserrat": `admin-src-components-ModalTrack-ModalTrack-module__ql-font-Montserrat-IByfeF`,
	"ModalTrack-spin": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-spin-wND4I3`,
	"modalTrackSpin": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-spin-wND4I3`,
	"ModalTrack-form-tags": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-tags-lPwj2P`,
	"modalTrackFormTags": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-tags-lPwj2P`,
	"ModalTrack-form-upload": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-upload-k1EWl1`,
	"modalTrackFormUpload": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-upload-k1EWl1`,
	"ModalTrack-form-chooseBtn": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-chooseBtn-AeOqzC`,
	"modalTrackFormChooseBtn": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-chooseBtn-AeOqzC`,
	"ModalTrack-form-spin": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-spin-DtOioG`,
	"modalTrackFormSpin": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-spin-DtOioG`,
	"ModalTrack-form-number": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-number-GavI8S`,
	"modalTrackFormNumber": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-form-number-GavI8S`,
	"ModalTrack-trackContent-header": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-header-Fo3doR`,
	"modalTrackTrackContentHeader": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-header-Fo3doR`,
	"ModalTrack-trackContent-alert": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-alert-NgbF1X`,
	"modalTrackTrackContentAlert": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-alert-NgbF1X`,
	"ModalTrack-trackContent-noData": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-noData-vN8Tyh`,
	"modalTrackTrackContentNoData": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-noData-vN8Tyh`,
	"ModalTrack-trackContent-addMaterial": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-addMaterial-T0RyHE`,
	"modalTrackTrackContentAddMaterial": `admin-src-components-ModalTrack-ModalTrack-module__ModalTrack-trackContent-addMaterial-T0RyHE`
};
export default ___CSS_LOADER_EXPORT___;
