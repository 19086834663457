/* eslint-disable */
import {
  EDIT_BONUSES_FAIL,
  EDIT_BONUSES_START,
  EDIT_BONUSES_SUCCESS,
  GET_BONUSES_FAIL,
  GET_BONUSES_START,
  GET_BONUSES_SUCCESS,
} from './actions';

const initialState = {
  bonuses: {},
  bonusesAdd: null,
  bonusesComplete: null,
  bonusesLogin: null,
  isLoading: false,
  error: false,
};

const sortBonusesArray = (bonues, type) => {
  return bonues[type];
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_BONUSES_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_BONUSES_SUCCESS:
      return {
        ...state,
        bonuses: response,
        isLoading: false,
      };

    case EDIT_BONUSES_START:
      return { ...state, isLoading: true, error: false };

    case EDIT_BONUSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_BONUSES_FAIL:
    case EDIT_BONUSES_FAIL:
      return { ...state, error: true, isLoading: false };

    default:
      return state;
  }
};
