/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Alert, Button, Modal, Tooltip } from 'antd';
import TopicThumbnail from '@/components/TopicThumbnail/TopicThumbnail';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import FixMaterial from '../FixMaterial/FixMaterial';
import TypeBadge from '../TypeBadge';

import { ADMIN_NAME } from '@shared/constants/admin-name';
import { ACTIVE } from '@shared/constants/topic-statuses';
import { isCorporateCourseWithoutApprove, isCorpOrExtCourse } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import _find from 'lodash/find';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './MaterialCard.module.scss';

import { TOPIC_DELETE, TOPIC_EXECUTIVE_TASKS_DELETE } from '@/constants/permissions';
import { TOPIC_TYPES } from '@/constants/topic-types';
import lock from '@/assets/images/lock.svg';

class MaterialCard extends Component {
  static propTypes = {
    activeStatus: PropTypes.func,
    changePositionTopic: PropTypes.func,
    className: PropTypes.string,
    coursesStatus: PropTypes.string,
    deletable: PropTypes.bool,
    domainCompany: PropTypes.object,
    getTopics: PropTypes.func,
    hideActionButtons: PropTypes.bool,
    inMyCollection: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    onFavoriteClick: PropTypes.func,
    onStudyPlanClick: PropTypes.func,
    pinned: PropTypes.any,
    scope: PropTypes.string,
    scopeTopics: PropTypes.string,
    searchString: PropTypes.string,
    status: PropTypes.string,
    statusTopics: PropTypes.string,
    topic: PropTypes.object,
    topicTypeId: PropTypes.string,
    topicsPageNumber: PropTypes.number,
    type: PropTypes.string,
    userTopicId: PropTypes.number,
    roles: PropTypes.array,
    isGlobal: PropTypes.bool,
    canDelete: PropTypes.bool,
    currentUser: PropTypes.object,
  };

  static defaultProps = {
    topic: {},
    canDelete: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      statusButton: this.props.status,
      handleModal: false,
    };
  }

  handleFavoriteClick = () => {
    const { onFavoriteClick, topic } = this.props;
    if (onFavoriteClick) {
      onFavoriteClick(topic.id);
    }
  };

  handleStudyPlanClick = () => {
    const { onStudyPlanClick, topic } = this.props;
    if (onStudyPlanClick) {
      onStudyPlanClick(topic.id);
    }
  };

  handleDeleteClick = () => {
    const { onDeleteClick, userTopicId, topic } = this.props;
    if (onDeleteClick) {
      onDeleteClick(userTopicId, this.props.topic.type, topic.courseActive);
    }
  };

  handleDeactivateClick = () => {
    this.props.activeStatus(this.props.topic.id, 'DISABLED', () => {
      this.setState({
        statusButton: 'DISABLED',
        handleModal: false,
        showConfirmModal: false,
      });
    });
  };

  handleShowModal = () => {
    this.setState({ handleModal: true });
  };

  handleNotShowModal = () => {
    this.setState({ handleModal: false });
  };

  handleActivateClick = () => {
    this.props.activeStatus(this.props.topic.id, 'ACTIVE', () => {
      this.setState({ statusButton: 'ACTIVE' });
    });
  };

  checkStringLength = string => {
    if (string.length > 17) {
      return;
    }

    return string;
  };

  render() {
    const { className, deletable, inMyCollection, hideActionButtons, topic, isGlobal, t, canDelete, currentUser } =
      this.props;

    const createdBy = topic.creBy || ADMIN_NAME;
    const editable = (isGlobal && topic.global) || !topic.global;
    const deleteButton =
      deletable &&
      (currentUser.authorities.includes(TOPIC_DELETE) ||
        (topic?.type?.id === 25 && currentUser.authorities.includes(TOPIC_EXECUTIVE_TASKS_DELETE))) &&
      editable &&
      canDelete ? (
        <CloseOutlined
          data-qa='deleteCardMaterialBtn'
          className={css['MaterialCard-buttons-delete']}
          onClick={this.handleDeleteClick}
        />
      ) : null;
    const isVisible = topic.visible && <CheckOutlined />;
    const toMyCollectionButton = inMyCollection ? null : (
      <Button onClick={this.handleFavoriteClick} ghost type='primary'>
        {t('favorites')}
      </Button>
    );
    const topicTypeId = _get(this.props, 'topic.type.id');
    const activateButton = () => {
      if (isCorpOrExtCourse(topicTypeId) && deletable) {
        const isActive = this.state.statusButton === ACTIVE;
        const onClickBtn = isActive ? this.handleShowModal : this.handleActivateClick;
        const titleActiveBtn = isActive ? t('deactivate') : t('activate');
        const activeBtn = (
          <Button onClick={onClickBtn} ghost type='primary' className={css['App-btn-titleActiveBtn']}>
            {titleActiveBtn}
          </Button>
        );
        return (
          <Button.Group className={css['App-btn-group']}>
            {activeBtn}
            {((topic.visible && !isActive) || (isActive && this.props?.domainCompany?.hasPinned && topic.visible)) && (
              <Button ghost type='primary' className={css['App-btn-fixMaterial']}>
                <FixMaterial
                  topic={topic}
                  changePositionTopic={this.props.changePositionTopic}
                  getTopics={this.props.getTopics}
                  searchString={this.props.searchString}
                  statusTopics={this.props.statusTopics}
                  scopeTopics={this.props.scopeTopics}
                  topicType={this.props.type}
                  topicTypeId={this.props.topicTypeId}
                  pinned={this.props.pinned}
                  topicPageNumber={this.props.topicsPageNumber}
                />
              </Button>
            )}
          </Button.Group>
        );
      }
    };

    const actionButtons = hideActionButtons ? null : (
      <Button.Group className={css['App-btn-group']}>
        <Button onClick={this.handleStudyPlanClick} ghost type='primary'>
          {t('addToPlan')}
        </Button>
        {toMyCollectionButton}
      </Button.Group>
    );

    const topicType = topic.type && _find(TOPIC_TYPES(t), type => type.id === topic.type.id);

    const typeBadge = topicType ? (
      <TypeBadge type={topicType.code} name={this.checkStringLength(topic.typeLabel?.name || '')} />
    ) : null;

    const truncatedName = topic.name && topic.name.length > 30 ? `${topic.name.substr(0, 30)}...` : topic.name;

    const descriptionVisibleTopic = !topic.visible && (
      <Alert className={css['MaterialCard-description-alert']} message={t('descriptionTopicVisible')} type='warning' />
    );

    return (
      <div
        data-qa='topicCardItem'
        className={classNames(
          className,
          css.MaterialCard,
          css['App-card-item'],
          (isCorpOrExtCourse(topicTypeId) || isCorporateCourseWithoutApprove(topicTypeId)) &&
            this.state.statusButton === 'DISABLED' &&
            css.BlackOut
        )}
      >
        <div className={css['MaterialCard-description']}>{descriptionVisibleTopic}</div>
        <div className={css['MaterialCard-buttons']}>
          {isVisible}
          {deleteButton}
        </div>
        {topic.name.length > 30 ? (
          <Tooltip title={topic.name}>
            <Link data-qa='topicCardName' to={`/topic/${topic.id}`} className={css.header}>
              {this.state.statusButton === 'DISABLED' && (
                <Tooltip title={t('deactivated')}>
                  <img key='deactive' src={lock} alt='' />
                </Tooltip>
              )}
              {truncatedName}
            </Link>
          </Tooltip>
        ) : (
          <Link data-qa='topicCardName' to={`/topic/${topic.id}`} className={css.header}>
            {this.state.statusButton === 'DISABLED' && (
              <Tooltip title={t('deactivated')}>
                <img key='deactive' src={lock} alt='' />
              </Tooltip>
            )}
            {truncatedName}
          </Link>
        )}
        <TopicThumbnail thumbnailFileUuid={topic.thumbFileUuid} />
        <div className={css['MaterialCard-personal']}>
          <div className={css.row}>
            <div className={css.label}>{t('category')}</div>
            <div className={css.value}>{typeBadge}</div>
          </div>
          <div className={css.row}>
            <div className={css.label}>{t('loaded')}</div>
            <div className={classNames(css.value, css.link)}>
              <Tooltip title={`${createdBy.firstName || ''} ${createdBy.lastName || ''}`}>
                {`${createdBy.firstName || ''} ${createdBy.lastName || ''}`}
              </Tooltip>
            </div>
          </div>
        </div>
        {activateButton() ||
          (deletable && topic.visible ? (
            <Button className={css['App-fixMaterial']} ghost type='primary'>
              <FixMaterial
                topic={topic}
                changePositionTopic={this.props.changePositionTopic}
                getTopics={this.props.getTopics}
                searchString={this.props.searchString}
                statusTopics={this.props.statusTopics}
                scopeTopics={this.props.scopeTopics}
                topicType={this.props.type}
                topicTypeId={this.props.topicTypeId}
                pinned={this.props.pinned}
                topicPageNumber={this.props.topicsPageNumber}
              />
            </Button>
          ) : null)}
        {actionButtons}
        <Modal
          open={this.state.handleModal}
          onCancel={this.handleNotShowModal}
          title={t('deactivateMaterial')}
          footer={null}
        >
          <div className={css['Deactive-modal-title']}>{t('confirmation')}</div>
          <div className={css['Deactive-modal-btn']}>
            <Button onClick={this.handleDeactivateClick} type='primary'>
              {t('deactivate')}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation(['materialCard', 'constants'])(MaterialCard);
