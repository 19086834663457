/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCompilations } from '@/store/compilations/actions';
import {
  clearSearchParams,
  clearSearchResult,
  clearSearchText,
  clearSearchUserText,
  getSearch,
  setFindSearchText,
  setSearchParams,
  setSearchText,
  setUserSearchText,
} from '@/store/search/actions';
import { getTags } from '@/store/tags/actions';

import { selectSearch } from '@/store/search/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';

import { Input, Select } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { Resources } from '@shared/resources/index';
import debounce from '@shared/utils/debounce';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ContextSearch.module.scss';

import { COMPILATIONS_TYPE_CONST } from '@/constants/topic-types';
import searchIcon from '@/assets/images/lop.svg';
import searchIconActive from '@/assets/images/searchActive.svg';

const { Search } = Input;

export class ContextSearch extends Component {
  static propTypes = {
    className: PropTypes.string,
    clearSearchParams: PropTypes.func,
    clearSearchResult: PropTypes.func,
    clearSearchText: PropTypes.func,
    clearSearchUserText: PropTypes.func,
    dark: PropTypes.bool,
    getSearch: PropTypes.func,
    getTags: PropTypes.func,
    handleClearSearch: PropTypes.func,
    handleFilterClear: PropTypes.func,
    handleSearchAuthor: PropTypes.func,
    handleSearchAuthors: PropTypes.func,
    handleSearchTag: PropTypes.func,
    handleSearchTags: PropTypes.func,
    handleSearchTopic: PropTypes.func,
    history: PropTypes.object,
    isFavorite: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    searchParams: PropTypes.any,
    searchResult: PropTypes.any,
    searchText: PropTypes.any,
    searchUserText: PropTypes.string,
    setFindSearchText: PropTypes.func,
    setSearchParams: PropTypes.func,
    setSearchText: PropTypes.func,
    setUserSearchText: PropTypes.func,
    topicFilter: PropTypes.object,
    userTopicFilter: PropTypes.object,
    getCompilations: PropTypes.func,
    dataQa: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: (props.searchParams && props.searchParams.resultSearch) || '',
      openSearch: false,
      openSearchBlock: false,
      searchName: '',
      searchType: '',
      resultSearch: this.props.searchResult,
    };
    this.getSearchDebounced = debounce(props.getSearch, 1000);
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const querySearch = queryParams.get('search') || '';
    this.props.setSearchParams({ searchResult: querySearch });
    this.setState({ search: querySearch });
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({
        search: this.props.searchText || '',
      });
    }
  }

  handleClick = event => {
    let txt = event?.target?.value;
    if (txt !== '') {
      this.setState({ openSearch: true });
      txt?.length > 1 && this.getSearchDebounced(txt, this.state.searchType);
    } else {
      setTimeout(() => this.handleCloseResult(), 400);
    }
    this.setState({ search: txt || '' });
  };

  handleCloseResult = () => {
    this.setState({ openSearch: false });
    this.props.clearSearchResult();
  };

  handleClear = () => {
    this.setState({ search: '', openSearch: false });
    this.props.handleClearSearch();
    this.props.setSearchParams({
      ...this.props.searchParams,
      creById: undefined,
      creBy: undefined,
      skillId: undefined,
      skillName: undefined,
      name: undefined,
      searchResult: undefined,
      manager: undefined,
    });
    this.props.clearSearchText();
    this.props.setFindSearchText('');
    this.props.history.push({
      search: '',
    });
  };

  getEnterSearchTopics = event => {
    if (event.key === 'Enter' && this.state.search) {
      this.getSearch();
    }
  };

  getSearch = argValue => {
    const searchParams = {
      ...this.props.searchParams,
      creById: undefined,
      creBy: undefined,
      skillName: undefined,
      skillId: undefined,
      name: undefined,
      searchResult: null,
      manager: undefined,
    };

    const currentValue = argValue;

    this.props.setSearchText();
    if (this.state.searchType === 'EXPERT') {
      searchParams.manager = currentValue;
      this.props.setFindSearchText({ manager: currentValue });
    } else if (this.state.searchType === 'SKILL') {
      searchParams.skillName = currentValue;
      this.props.setFindSearchText({ skillName: currentValue });
    } else if (this.state.searchType === 'COMPILATION') {
      searchParams.name = currentValue;
      this.props.setFindSearchText({ name: currentValue });
    } else if (this.state.searchType === '' || !this.state.searchType) {
      searchParams.searchResult = currentValue;
      this.props.setFindSearchText({ searchResult: currentValue });
    }

    // this.props.history.push({
    //   search: '',
    // });

    this.setState({
      openSearch: false,
      searchResult: currentValue,
      searchType: this.state.searchType,
    });
    this.props.setSearchParams(searchParams);

    const filterParams = {
      search: currentValue,
    };

    const queryParams = new URLSearchParams(filterParams);
    window.history.pushState({}, '', `?${queryParams}`);
  };

  openSearch = () => {
    if (this.state.search && this.state.search.length > 1) {
      this.setState({ openSearch: true });
    }
  };

  closeSearch = () => {
    if (!this.state.openSearchBlock) {
      this.setState({ openSearch: false });
    }
  };

  openSearchBlock = () => {
    this.setState({ openSearchBlock: true });
  };

  closeSearchBlock = () => {
    this.setState({ openSearchBlock: false });
  };

  handleChange = eventType => {
    this.setState({ searchType: eventType });
    if (this.state.search) {
      this.getSearchDebounced(this.state.search, eventType);
    }
  };

  getSearchTopic = item => {
    if (this.state.searchType) {
      this.props.getCompilations(
        {
          creById: item && item.id,
          searchResult: item && item.name,
        },
        () => this.setState({ search: item && item.name, openSearch: false })
      );
    } else {
      this.props.history.push(`compilations/${item && item.id}`);
    }
  };

  showResult = () => {
    return (
      this.props.searchResult &&
      this.props.searchResult.map(item => {
        const [type] = this.props.searchResult.filter(
          itemType => itemType.name.toLowerCase() === item.type.toLowerCase()
        );
        const onClick = () => {
          (type && type.type === 'EXPERT') || (type && type.type === 'SKILL')
            ? this.getSearch(item, type && type.type)
            : this.getSearchTopic(item, type && type.type);
        };
        return (
          <div key={item.id} className={css['ContextSearch-result-item']} onClick={onClick}>
            <div className={css['ContextSearch-result-blockName-name']}>{item.name}</div>
            <div className={css['ContextSearch-result-blockName-label']}>{item.type}</div>
          </div>
        );
      })
    );
  };

  render() {
    const { t, dataQa } = this.props;
    const typesSearch = [
      {
        value: '',
        label: t('all'),
      },
      ...COMPILATIONS_TYPE_CONST.map((item, index) => ({
        value: item.type,
        label: item.label,
      })),
    ];

    return (
      <div className={css.ContextSearch}>
        <Search
          data-qa={`${dataQa}Input`}
          className={css['ContextSearch-block']}
          onSearch={this.getSearch}
          onChange={this.handleClick}
          value={this.state.search}
          placeholder={t('search')}
          enterButton
          allowClear
          addonBefore={
            <Select
              data-qa='compilationSearchType'
              className={css['ContextSearch-select']}
              value={this.state.searchType}
              onChange={this.handleChange}
              options={typesSearch}
            ></Select>
          }
        />
        {/* <CustomSelect
              dataQa='compilationSearchType'
              onChange={this.handleChange}
              name='type'
              options={typesSearch}
              contextSearch
              value={(selectType.length > 0 && selectType[0].name) || undefined}
              contextSearchActive={this.state.openSearch}
              white
              className={css['ContextSearch-select']}
            />
            <div className={css['ContextSearch-inputBlock']}>
              {deleteButton ? (
                <img
                  src={Resources.Icons.ResetSearch}
                  alt='reset'
                  onClick={this.handleClear}
                  className={css['ContextSearch-icon-close']}
                />
              ) : null}
              <input
                data-qa={`${dataQa}Input`}
                value={this.state.search}
                className={classNames(
                  css['ContextSearch-input'],
                  {
                    [css['ContextSearch-input-result']]: this.state.openSearch,
                  },
                  { [css['ContextSearch-input-result-search']]: deleteButton }
                )}
                type='text'
                onChange={this.handleClick}
                placeholder={t('search')}
                onKeyPress={this.getEnterSearchTopics}
              />
            </div>
            <div
              className={classNames(css['ContextSearch-iconBlock'], {
                [css['ContextSearch-iconBlock-active']]: this.state.openSearch,
              })}
              onClick={this.getSearch}
            >
              <img src={this.state.openSearch ? searchIconActive : searchIcon} />
            </div> */}

        {this.state.openSearch && this.props.searchResult && this.props.searchResult.length ? (
          <div
            className={css['ContextSearch-result']}
            onMouseEnter={this.openSearchBlock}
            onMouseLeave={this.closeSearchBlock}
          >
            <div className={css['ContextSearch-result-blockName']}>{this.showResult()}</div>
            <div className={css['ContextSearch-result-blockName-result']} onClick={this.getSearch}>
              {t('result')}
              <ArrowRightOutlined />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createSelector([/* userTopicSelect, */ topicsSelect, selectSearch], (topics, search) => ({
  searchResult: search.search,
  searchParams: search.searchParams,
  searchText: search.searchText,
  searchUserText: search.searchUserText,
  topicFilter: topics.topicFilter,
}));

const mapActionsToProps = {
  clearSearchText,
  getSearch,
  getTags,
  setFindSearchText,
  setSearchParams,
  setSearchText,
  setUserSearchText,
  clearSearchUserText,
  clearSearchParams,
  clearSearchResult,
  getCompilations,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['contextSearch', 'constants'])(ContextSearch));
