const TextSvg = props => (
  <div>
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7'>
      <path d='M.5.5h10m-10 3h10m-10 3h6' stroke='#2D3540' fill='none' />
    </svg>
    {props.name}
  </div>
);

export default TextSvg;
