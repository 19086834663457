/* eslint-disable */
import React, { Component } from 'react';

import { Alert, Col, Divider, Row, Tag, Tooltip } from 'antd';
import {
  ImBook,
  ImBooks,
  ImBriefcase,
  ImCalendar,
  ImClipboard,
  ImCog,
  ImKey2,
  ImMagicWand,
  ImPriceTags,
  ImProfile,
  ImQuill,
  ImStatsDots,
  ImTarget,
  ImTree,
  ImUsers,
} from 'react-icons/im';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

export class ResultList extends Component {
  static propTypes = {
    competence: PropTypes.object,
    skills: PropTypes.array,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { competence, skills, reportLink, t } = this.props;

    return (
      <>
        <div
          style={{
            padding: '2rem',
            fontFamily: 'sans-serif',
            fontSize: '16px',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>Суммарная информация</h2>
          <Row style={{ paddingTop: '2rem' }}>
            <Col>
              <strong>
                <ImBriefcase />
                &nbsp;{t('selectedCompetence')}
              </strong>
              <Divider />
              {competence && competence.name && competence.name}
            </Col>
          </Row>
          <Row style={{ paddingTop: '2rem' }}>
            <Col span={24}>
              <strong>
                <ImStatsDots />
                &nbsp;{t('selectedSkills')}
              </strong>
              <Divider />
              {skills &&
                Array.isArray(skills) &&
                skills.map(skill => {
                  return (
                    <Tooltip title={skill.name}>
                      <Tag>{Utils.trString(skill.name, 30)}</Tag>
                    </Tooltip>
                  );
                })}
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <Col span={24}>
              <Alert type='warning' message={t('pleaseDone')} showIcon />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ResultList;
