import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { postIprFilters, rejectedIpr } from '@/store/ipr/actions';

import { selectIpr } from '@/store/ipr/selectors';

import { Button, Input, Modal } from 'antd';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

import { DATE_FORMAT, RTN_WRK } from '../constants';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../../Career.module.scss';

const { TextArea } = Input;

const ModalActions = props => {
  const { t } = useTranslation('career');
  const dispatch = useDispatch();

  const { iprCurrentPage, iprFilters, iprSort } = useSelector(selectIpr);

  const [isError, setIsError] = useState(false);
  const [descRejected, setDescRejected] = useState('');

  const backToWork = () => {
    if (!descRejected) {
      setIsError(true);
    } else {
      setIsError(false);

      let result =
        Array.isArray(props.partsTable?.competencies) &&
        props.partsTable.competencies?.length > 0 &&
        props.partsTable.competencies.map(item => {
          let topicIds = item.topics.map(i => i.id);
          let tasksIds = item.tasks.map(i => i.id);
          let mentorsIds = item.mentors.map(i => i.id);

          return {
            id: item.id,
            topics: topicIds,
            tasks: tasksIds,
            mentors: mentorsIds,
          };
        });

      let params = {
        comment: descRejected?.trim(),
        deadline:
          typeof props.partsTable?.deadline === 'string'
            ? props.partsTable?.deadline
            : dayjs(props.partsTable?.deadline).format(DATE_FORMAT),
        competences: Array.isArray(result) ? result : undefined,
      };

      dispatch(
        rejectedIpr(props.iprId, params, () => {
          dispatch(postIprFilters(iprFilters ?? {}, iprCurrentPage, iprSort));
          props.onClose();
        })
      );
    }
  };

  const rtnWrk = (
    <div className={css['ModalActions-setReject']}>
      <div className={css['ModalActions-setReject-title']}>{t('titleModalDelete')}</div>
      <div className={css['ModalActions-setReject-desc']}>{t('descModalDelete')}</div>
      <TextArea
        rows={6}
        placeholder={t('placeholderModalDelete')}
        maxLength={1500}
        showCount
        onChange={e => setDescRejected(e?.target?.value)}
        className={classNames([css['ModalActions-setReject-area']], {
          [css['ModalActions-setReject-area-error']]: isError,
        })}
      />

      <div className={css['ModalActions-setReject-footer']}>
        {isError && (
          <div className={css['ModalActions-setReject-footer-error']}>
            <HiOutlineExclamationCircle className={css['ModalActions-setReject-footer-error-icon']} />
            <span className={css['ModalActions-setReject-footer-error-desc']}>{t('errorDesc')}</span>
          </div>
        )}
        <Button onClick={props.onClose} className={css['ModalActions-setReject-footer-cancel']}>
          {t('cancel')}
        </Button>
        <Button type='primary' onClick={backToWork}>
          {t('takeComment')}
        </Button>
      </div>
    </div>
  );
  return (
    <Modal open={props.open} onCancel={props.onClose} footer={null} width={800}>
      {props.action === RTN_WRK && rtnWrk}
    </Modal>
  );
};

ModalActions.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  action: PropTypes.string,
  iprId: PropTypes.number,
  partsTable: PropTypes.object,
};

export default ModalActions;
