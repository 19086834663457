/* eslint-disable */
import {
  CREATE_AUTHORS_FAIL,
  CREATE_AUTHORS_START,
  CREATE_AUTHORS_SUCCESS,
  DELETE_AUTHORS_FAIL,
  DELETE_AUTHORS_START,
  DELETE_AUTHORS_SUCCESS,
  EDIT_AUTHORS_FAIL,
  EDIT_AUTHORS_START,
  EDIT_AUTHORS_SUCCESS,
  GET_AUTHORS_FAIL,
  GET_AUTHORS_START,
  GET_AUTHORS_SUCCESS,
  GET_GLOBAL_AUTHORS_FAIL,
  GET_GLOBAL_AUTHORS_START,
  GET_GLOBAL_AUTHORS_SUCCESS,
} from './actions';

const initialState = {
  authors: null,
  authorsTotal: null,
  authorsPageSize: null,
  authorsPageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  authorsGlobal: {
    authors: null,
    authorsTotal: null,
    authorsPageSize: null,
    authorsPageNumber: null,
    isLoading: false,
    error: false,
  },
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_AUTHORS_START:
      return {
        ...state,
        authors: null,
        authorsTotal: null,
        authorsPageSize: null,
        authorsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_AUTHORS_SUCCESS:
      return {
        ...state,
        authors: response,
        authorsTotal: Number(headers.get('X-Total-Elements')) || 0,
        authorsPageSize: Number(headers.get('X-Page-Size')) || 0,
        authorsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_AUTHORS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_GLOBAL_AUTHORS_START:
      return {
        ...state,
        authorsGlobal: {
          authors: null,
          authorsTotal: null,
          authorsPageSize: null,
          authorsPageNumber: null,
          isLoading: true,
          error: false,
        },
      };

    case GET_GLOBAL_AUTHORS_SUCCESS:
      return {
        ...state,
        authorsGlobal: {
          authors: response,
          authorsTotal: Number(headers.get('X-Total-Elements')) || 0,
          authorsPageSize: Number(headers.get('X-Page-Size')) || 0,
          authorsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoading: false,
        },
      };

    case GET_GLOBAL_AUTHORS_FAIL:
      return { ...state, authorsGlobal: { error: true, isLoading: false } };

    case CREATE_AUTHORS_START:
      return { ...state, isLoading: true, creationError: false };

    case CREATE_AUTHORS_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_AUTHORS_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_AUTHORS_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_AUTHORS_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_AUTHORS_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_AUTHORS_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_AUTHORS_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_AUTHORS_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    default:
      return state;
  }
};
