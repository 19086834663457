import { getUserDomainCompanyImages } from './actions';
import { selectUsers } from './selectors';

import { getFileMemo } from '@shared/utils/file-utils';

import { all, call, put, select, takeLeading } from 'redux-saga/effects';

function* fetchUserDomainCompanyImages() {
  const users = yield select(selectUsers);

  const {
    currentUser: { domainCompany },
  } = users;

  if (!domainCompany) {
    return;
  }

  const calls = [];

  domainCompany.corpCourseBackground && calls.push(call(getFileMemo, domainCompany.corpCourseBackground));
  domainCompany.corpCourseIcon && calls.push(call(getFileMemo, domainCompany.corpCourseIcon));

  const [corpCourseBackground, corpCourseIcon] = yield all(calls);

  if (users.corpCourseBackground === corpCourseBackground && users.corpCourseIcon === corpCourseIcon) {
    return;
  }

  yield put(getUserDomainCompanyImages.success({ corpCourseBackground, corpCourseIcon }));
}

/* eslint-disable */
export default [takeLeading(getUserDomainCompanyImages.type, fetchUserDomainCompanyImages)];
