/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  D360CopyProject,
  D360CreateProject,
  D360DeleteProject,
  D360GetProjects,
  D360UpdateProject,
} from '@/store/d360/d360_projects/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Card, Col, Input, Modal, Progress, Row, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { IoBriefcaseOutline } from 'react-icons/io5';

import { extractUsername } from '../../utils';
import ColumnSearch from '../ColumnSearch';
import ProjectDate from './parts/ProjectDate';
import ProjectEdit from './parts/ProjectEdit';
import ProjectMenu from './parts/ProjectMenu';
import ProjectStatus from './parts/ProjectStatus';

import dayjs from 'dayjs';
import _ from 'lodash';

import css from '../../css/index.module.scss';

import { PROJECT_360_CREATE } from '@/constants/permissions';

const { confirm } = Modal;
const pageSizeOptions = ['10', '50', '100', '500', '1000', '10000'];

export const Projects = () => {
  const dispatch = useDispatch();

  const { projects, loading, pageCurrent, pagePer, pageTotal } = useSelector(state => state.d360Projects);
  const { currentUser } = useSelector(selectUsers);

  const [currentProject, setCurrentProject] = useState({});
  const [showEditProject, setShowEditProject] = useState(false);
  const { t } = useTranslation('D360_Projects');
  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [filterColumn, setFilterColumn] = useState(undefined);
  const [filterValue, setFilterValue] = useState(undefined);
  const [pageSize, setPageSize] = useState(10);

  const [modal, contextHolder] = Modal.useModal();

  // useEffect(() => {
  //   dispatch(D360GetProjects());
  // }, []);

  useEffect(() => {
    dispatch(D360GetProjects(pageCurrent, pageSize, sortColumn, sortOrder, filterColumn, filterValue));
  }, [pageSize, sortOrder, sortColumn, filterColumn, filterValue]);

  useEffect(() => {
    if (Array.isArray(projects)) {
      if (typeof currentProject === 'object' && currentProject.id) {
        for (let project of projects) {
          if (project.id === currentProject.id) {
            setCurrentProject(project);
          }
        }
      }
    }
  }, [projects]);

  const renderStatus = status_id => <ProjectStatus statusId={status_id} />;

  const createProject = () => {
    setCurrentProject({});
    setShowEditProject(true);
  };

  const openProject = project => {
    setCurrentProject(project);
  };

  const copyProject = project => {
    let name = project.name;
    return modal.confirm({
      title: t('copyProjectTitle'),
      content: (
        <>
          <p>{t('copyProjectText')}</p>
          <br />
          <Input
            maxLength={255}
            defaultValue={name}
            onChange={e => {
              name = e.target.value;
            }}
          />
        </>
      ),
      okText: t('copyProjectOkButton'),
      cancelText: t('copyProjectCancelButton'),
      okType: 'primary',
      onOk() {
        project.newname = name;
        dispatch(
          D360CopyProject(
            project.id,
            name,
            () => {
              // no code on success
            },
            () => {
              return modal.confirm({
                title: t('projectAlreadyExistTitle'),
                content: t('projectAlreadyExistText'),
                okText: t('OK'),
                okType: 'primary',
                okButtonProps: { danger: true },
                cancelButtonProps: { style: { display: 'none' } },
              });
            }
          )
        );
      },
    });
  };

  const deleteProject = project => {
    return modal.confirm({
      title: t('deleteProjectTitle'),
      content: t('deleteProjectText'),
      okText: t('deleteProjectOkButton'),
      cancelText: t('deleteProjectCancelButton'),
      okType: 'primary',
      okButtonProps: { danger: true },
      onOk() {
        dispatch(D360DeleteProject(project));
      },
    });
  };

  const editProject = project => {
    setCurrentProject(project);
    setShowEditProject(true);
  };

  const onSearchReset = () => {
    setFilterColumn(undefined);
    setFilterValue(undefined);
  };

  const onSearchSubmit = (column, value) => {
    setFilterColumn(column);
    setFilterValue(value);
  };

  const tableColumns = () => {
    return [
      {
        title: t('columnName'),
        key: 'name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
          return (
            <ColumnSearch
              column='name'
              fnClearFilters={clearFilters}
              fnClose={confirm}
              onReset={onSearchReset}
              onSubmit={onSearchSubmit}
            />
          );
        },
        onFilter: (value, record) => {
          return record.name.toString().toLowerCase().includes(value.toLowerCase());
        },
        render: (name, record) => {
          return (
            <ProjectMenu
              name={name}
              project={record}
              onClick={openProject}
              onCopy={copyProject}
              onDelete={deleteProject}
              onEdit={editProject}
            />
          );
        },
      },
      {
        title: t('columnAuthor'),
        key: 'author',
        align: 'center',
        dataIndex: 'author',
        sorter: (a, b) => a.author.last_name.localeCompare(b.author.last_name),
        render: (name, record) => {
          if (record.author) {
            return extractUsername(record.author);
          } else {
            return '';
          }
        },
      },
      {
        title: t('columnStartDate'),
        key: 'start',
        align: 'center',
        dataIndex: 'start',
        sorter: (a, b) => dayjs(a.start).unix() - dayjs(b.start).unix(),
        render: (name, record) => {
          return <ProjectDate dateString={record.start} />;
        },
      },
      {
        title: t('columnEndDate'),
        key: 'finish',
        align: 'center',
        dataIndex: 'finish',
        sorter: (a, b) => dayjs(a.finish).unix() - dayjs(b.finish).unix(),
        render: (name, record) => {
          return <ProjectDate dateString={record.finish} />;
        },
      },
      {
        title: t('columnProgress'),
        key: 'members',
        align: 'center',
        dataIndex: 'members',
        sorter: (a, b) => a.progress - b.progress,
        render: (name, record) => {
          return (
            <Progress strokeColor={{ from: '#108ee9', to: '#87d068' }} percent={record.progress} status='active' />
          );
        },
      },
      {
        title: t('columnStatus'),
        key: 'status_id',
        dataIndex: 'status_id',
        align: 'center',
        sorter: (a, b) => a.d360_status_id - b.d360_status_id,
        render: (name, record) => {
          return renderStatus(record.d360_status_id);
        },
      },
    ];
  };

  const handleEditProjectSubmit = project => {
    if (project.id) {
      dispatch(D360UpdateProject(project));
    } else {
      dispatch(D360CreateProject(project));
    }

    setShowEditProject(false);
  };

  return (
    <div>
      <Card>
        <Row>
          <Col span={18}>
            <h2>
              <IoBriefcaseOutline />
              &nbsp;{t('projectList')}
            </h2>
          </Col>
          {currentUser?.authorities.includes(PROJECT_360_CREATE) && (
            <Col span={6} align={'right'}>
              <Button type='primary' onClick={createProject}>
                {t('createProject')}
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: '1rem' }}>
            <Table
              className={css.ProjectsTableCustom}
              onChange={(a, b, c) => {
                if (_.isObject(a) && _.has(a, 'pageSize') && _.isNumber(a.pageSize) && a.pageSize > 0) {
                  setPageSize(a.pageSize);
                }
                if (_.has(c, 'order')) {
                  if (c.field === 'status_id') {
                    setSortColumn('d360_status_id');
                  } else if (c.field === 'author') {
                    setSortColumn('created_at');
                  } else {
                    return 'id';
                  }

                  setSortOrder(c.order === 'ascend' ? 'ASC' : 'DESC');
                } else {
                  setSortColumn('id');
                  setSortOrder('DESC');
                }
              }}
              loading={loading}
              columns={tableColumns()}
              dataSource={projects}
              bordered={true}
              rowKey={rec => rec.id}
              pagination={{
                current: pageCurrent,
                defaultCurrent: 1,
                defaultPageSize: 10,
                hideOnSinglePage: false,
                pageSize: pagePer,
                total: pageTotal,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOptions,
                onChange: (page, pageSize) => {
                  dispatch(D360GetProjects(page, pageSize, sortColumn, sortOrder, filterColumn, filterValue));
                },
              }}
            />
          </Col>
        </Row>
      </Card>

      <ProjectEdit
        visible={showEditProject}
        onCancel={() => setShowEditProject(false)}
        onSubmit={handleEditProjectSubmit}
        project={currentProject}
      />
      {contextHolder}
    </div>
  );
};

export default Projects;
