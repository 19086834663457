/* eslint-disable */
import React, { Component } from 'react';

import { Button } from 'antd';

import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

export default class EventRow extends Component {
  static propTypes = {
    addToPlan: PropTypes.func,
    event: PropTypes.object,
    eventDisabled: PropTypes.bool,
    isInModal: PropTypes.bool,
    addUser: PropTypes.bool,
    openDate: PropTypes.number,
    participate: PropTypes.bool,
    planStatus: PropTypes.string,
    selected: PropTypes.bool,
    setParams: PropTypes.func,
    topicStatus: PropTypes.string,
    topicType: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.openDate = dayjs(props.openDate);
    this.workDate =
      !this.openDate || dayjs().add(5, 'day').format('YYYY-MM-DD') > dayjs(this.openDate).format('YYYY-MM-DD')
        ? dayjs().add(5, 'day').format('YYYY-MM-DD')
        : dayjs(this.openDate).format('YYYY-MM-DD');
    this.state = {
      startDate: dayjs(this.workDate).format('YYYY-MM-DD'),
      inProgress:
        dayjs(props.event.endTime).format('YYYY-MM-DD') <= dayjs(this.workDate).format('YYYY-MM-DD') &&
        dayjs(this.workDate).format('YYYY-MM-DD') >= dayjs(props.event.startTime).format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    const eventSelected = this.props?.event;
    if (this.props?.selected && eventSelected?.id && eventSelected?.startTime) {
      this.props.setParams(eventSelected.id, eventSelected.startTime);
    }
  }

  getTimeFromMins = mins => {
    const hours = Math.trunc(mins / 60);
    const minutes = mins % 60;
    if (hours === 0 && hours === 0) {
      return `${minutes} мин.`;
    }
    if (minutes > 0 && hours !== 0) {
      return `${hours} ${noun(hours, 'час', 'часа', 'часов')} ${minutes} мин.`;
    }
    return `${hours} ${noun(hours, 'час', 'часа', 'часов')}`;
  };

  renderInfo = (topicType, duration) => {
    if (!isCorpCourseWithOrWithoutApprove(topicType)) {
      return null;
    }
    return this.getTimeFromMins(duration);
  };

  render() {
    const { event, isInModal, selected, topicType, eventDisabled, addUser } = this.props;
    const hideActionButtons = false;
    const actionButtons = hideActionButtons ? null : !event.active ? null : this.props.planStatus ||
      event.placeCount === 0 ||
      this.state.startDate > dayjs(this.props.event.startTime).format('YYYY-MM-DD') ? null : (
      <Button.Group className={css['App-btn-group']}>
        <Button
          className={css['App-btn']}
          onClick={() => this.props.addToPlan(event.startTime)}
          ghost
          type='primary'
          disabled={this.props.topicStatus === 'DISABLED'}
        >
          Записаться
        </Button>
      </Button.Group>
    );
    return (
      <div
        className={classNames(
          css.eventRow,
          { [css.isEnded]: event.ended },
          {
            [css.isFull]:
              !event.ended &&
              (event.placeCount === 0 ||
                this.state.startDate > dayjs(this.props.event.startTime).format('YYYY-MM-DD') ||
                this.state.inProgress ||
                !event.active),
          },
          { [css.isGrey]: isInModal },
          { [css.isParticipate]: event.participate },
          { [css.selected]: selected }
        )}
        {...(this.props.setParams && {
          onClick: () => this.props.setParams(event.id, event.startTime),
        })}
      >
        <div className={css.eventRow__col}>
          {event.participate && (
            <div className={css['participate-icon']}>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
                <path
                  fill='#FF8C00'
                  d='M4.148 7.883a1.016 1.016 0 0 0-1.452.01 1.055 1.055 0 0 0 .01 1.476l3.127 3.13a1.016 1.016 0 0 0 1.476-.035l6.819-7.513a1.055 1.055 0 0 0-.059-1.475 1.016 1.016 0 0 0-1.452.06l-6.098 6.72-2.37-2.373z'
                />
              </svg>
            </div>
          )}
          {event.startTime !== event.endTime
            ? `${dayjs(event.startTime).format('DD MMMM')}
          - ${dayjs(event.endTime).format('DD MMMM')}`
            : dayjs(event.startTime).format('DD MMMM')}
        </div>
        {addUser && (
          <>
            <span className={css.eventRow__separator} />
            <div className={css.eventRow__col}>
              {event.registerCount}/{event.approvedCount} из {event.participantsCount}
            </div>
          </>
        )}
        {topicType !== 20
          ? !isInModal && (
              <div className={css.eventRow__col}>{event.duration && this.getTimeFromMins(event.duration)}</div>
            )
          : null}
        <div className={css.eventRow__col}>
          {!isInModal
            ? event.active
              ? !event.ended
                ? event.placeCount > 0 && !eventDisabled
                  ? 'Идёт набор группы'
                  : 'Группа набрана'
                : 'Курс закончился'
              : event.ended
                ? 'Курс закончился'
                : 'Группа набрана'
            : this.renderInfo(topicType, this.props.event.duration)}
        </div>
        {(!isInModal || topicType === 20) && <div className={css.eventRow__col} />}
        {!isInModal && (
          <div className={css.eventRow__col}>
            {!event.ended && !eventDisabled ? event.placeCount !== 0 && actionButtons : null}
          </div>
        )}
      </div>
    );
  }
}
