/* eslint-disable */
import React, { useCallback, useState } from 'react';

import { Button, Form, Tag, Tooltip } from 'antd';
import Checkbox from '@/components/Checkbox';
import ImageInput from '@/components/ImageInputCrop';
import CustomInput from '@/components/Input';
import ModalCategory from '@/components/ModalCategory';

import classNames from 'classnames';

import css from './CompanyCreationDialog.module.scss';

export const Sections = ({
  company,
  handleChange,
  handleImageChange,
  fileInfo,
  fileLoadingImage,
  handleTopicClose,
  handleChangeTopics,
  topicTypes,
  handleIntChange,
  t,
}) => {
  const [showModal, setShowModal] = useState(false);

  const topics =
    company.topicTypes &&
    company.topicTypes.map(topic => (
      <Tag key={topic.id} closable onClose={() => handleTopicClose(topic.id)} className={css['Category-tag']}>
        {topic.name}
      </Tag>
    ));

  const changeTopics = useCallback(
    values => {
      handleChangeTopics(values);
      setShowModal(false);
    },
    [handleChangeTopics]
  );

  return (
    <div className={css['Add-modal']}>
      <div className={css['Add-modal-title']}>{t('sections')}</div>
      {/* ТИПЫ МАТЕРИАЛОВ */}
      <Form.Item label={t('typesOfMaterial')} labelAlign='left'>
        <Button
          onClick={() => setShowModal(true)}
          className={classNames(css['Add-modal-topics'])}
          disabled={!topicTypes}
        >
          {t('choose')}
        </Button>
        {topics}
      </Form.Item>

      {/* Пошаренные области развития */}
      <Form.Item label={t('shared')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='globalShare' value={company.globalShare} />
      </Form.Item>

      {/* Диагностика */}
      <Form.Item label={t('PDP')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasTrajectory' value={company.hasTrajectory} />
      </Form.Item>

      {/* Диагностика в польз. части */}
      <Form.Item label={t('usersPDP')} labelAlign='left'>
        <Checkbox
          onChange={handleChange}
          name='hasUserTrajectory'
          value={company.hasUserTrajectory}
          disabled={!company.hasTrajectory}
        />
      </Form.Item>

      {/* ПОВТОРНЫЙ ТЕСТ ЧЕРЕЗ */}
      <Form.Item label={t('assessment')} labelAlign='left'>
        <div className={css['Add-modal-shortInputContainer']}>
          <CustomInput
            className={css['Add-modal-shortInput']}
            name='trajectoryOffset'
            placeholder='000'
            onChange={handleIntChange}
            value={company.trajectoryOffset}
            maxLength={3}
          />
          {t('days')}
        </div>
      </Form.Item>

      {/* КОИНЫ */}
      <Form.Item label={t('coins')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasCoins' value={company.hasCoins} />
      </Form.Item>

      {/* ИКОНКА КОИНОВ */}
      <Form.Item
        label={
          <div>
            <div>{t('coinsLogo')}</div>
            <span className={css['Add-modal-description']}>SVG 37x24</span>
          </div>
        }
        labelAlign='left'
      >
        <ImageInput
          reverse
          company
          noCrop
          id='coinIcon'
          onChange={handleImageChange}
          name='coinIcon'
          imageSrc={company.images.coinIcon}
          fileInfo={fileInfo}
          isLoadingFileImage={fileLoadingImage}
        />
      </Form.Item>

      {/* МЕНТОРЫ */}
      <Form.Item label={t('mentors')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasMentors' value={company.hasMentors} />
      </Form.Item>

      {/* ИПР */}
      <Form.Item label={t('ipr')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasIpr' value={company.hasIpr} />
      </Form.Item>

      {/* МАГАЗИН ПООЩРЕНИЙ */}
      <Form.Item label={t('shop')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasShop' value={company.hasShop} />
      </Form.Item>

      {/* ПОДБОРКИ */}
      <Form.Item label={t('collection')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasCompilation' value={company.hasCompilation} />
      </Form.Item>

      {/* hasGlobalTheme */}
      <Form.Item label={t('hasGlobalTheme')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasGlobalTheme' value={company.hasGlobalTheme} />
      </Form.Item>

      {/* РУКОВОДИТЕЛИ */}
      <Form.Item label={t('manager')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasManager' value={company.hasManager} />
      </Form.Item>

      {/* ТЕЛЕГРАМ */}
      <Form.Item label={t('telegramBot')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasTelegram' value={company.hasTelegram} />
      </Form.Item>

      {/* БИТРИКС */}
      <Form.Item label={t('bitrix')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasBitrix' value={company.hasBitrix} />
      </Form.Item>

      {/* ОТДЕЛЫ / ДОЛЖНОСТИ / ФУНКЦИИ */}
      <Form.Item label={t('departments')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasAdditionalInfo' value={company.hasAdditionalInfo} />
      </Form.Item>

      {/* ДОЛЖНОСТИ В ПОЛЬЗ. ЧАСТИ */}
      <Form.Item label={t('positions')} labelAlign='left'>
        <Checkbox
          onChange={handleChange}
          name='hasUserPosition'
          value={company.hasUserPosition}
          disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* ОТДЕЛЫ В ПОЛЬЗ. ЧАСТИ */}
      <Form.Item label={t('department')} labelAlign='left'>
        <Checkbox
          onChange={handleChange}
          name='hasUserDepartment'
          value={company.hasUserDepartment}
          disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* ФУНКЦИИ В ПОЛЬЗ. ЧАСТИ */}
      <Form.Item label={t('divisions')} labelAlign='left'>
        <Checkbox
          onChange={handleChange}
          name='hasUserFunction'
          value={company.hasUserFunction}
          disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* ОБОЗНАЧ. ОТДЕЛА В ПОЛЬЗ. ЧАСТИ */}
      <Form.Item
        label={t('department2')}
        labelAlign='left'
        validateStatus={company.errors.departmentSingleNameImenit ? 'error' : ''}
      >
        <CustomInput
          name='departmentSingleNameImenit'
          placeholder={t('department3')}
          onChange={handleChange}
          value={company.departmentSingleNameImenit}
          maxLength={30}
          // disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* Обознач. должности в польз. части */}
      <Form.Item
        label={t('position2')}
        labelAlign='left'
        validateStatus={company.errors.positionSingleNameImenit ? 'error' : ''}
      >
        <CustomInput
          name='positionSingleNameImenit'
          placeholder={t('position3')}
          onChange={handleChange}
          value={company.positionSingleNameImenit}
          maxLength={50}
          disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* ОБОЗНАЧ. ФУНКЦИИ В ПОЛЬЗ. ЧАСТИ */}
      <Form.Item
        label={t('divisions2')}
        labelAlign='left'
        validateStatus={company.errors.functionSingleNameImenit ? 'error' : ''}
      >
        <CustomInput
          name='functionSingleNameImenit'
          placeholder={t('divisions3')}
          onChange={handleChange}
          value={company.functionSingleNameImenit}
          maxLength={50}
          disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* КНОПКА МОЕГО ОТДЕЛА */}
      <Form.Item
        label={t('myDepartment')}
        labelAlign='left'
        validateStatus={company.errors.myDepartmentButtonName ? 'error' : ''}
      >
        <CustomInput
          name='myDepartmentButtonName'
          placeholder={t('position3')}
          onChange={handleChange}
          value={company.myDepartmentButtonName}
          maxLength={50}
          disabled={!company.hasAdditionalInfo}
        />
      </Form.Item>

      {/* КНОПКА "ПОДЕЛИТЬСЯ" */}
      <Form.Item label={t('share')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hasShare' value={company.hasShare} />
      </Form.Item>

      {/* Автоскрытие новых материалов в польз. части */}
      <Form.Item
        label={
          <Tooltip title={t('autoHide')}>
            <span className={css['renderText']}>{t('autoHide')}</span>
          </Tooltip>
        }
        labelAlign='left'
      >
        {/* <Form.Item label={t('autoHide')} labelAlign='left'> */}
        <Checkbox onChange={handleChange} name='autoHideNewTopics' value={company.autoHideNewTopics} />
      </Form.Item>

      {/* Альпина - автоскрытие */}
      <Form.Item label={t('autoHidePaidAlpina')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hideAlpina' value={company.hideAlpina} />
      </Form.Item>

      {/* Платные материалы - автоскрытие */}
      <Form.Item label={t('autoHidePaid')} labelAlign='left'>
        <Checkbox onChange={handleChange} name='hideGlobal' value={company.hideGlobal} />
      </Form.Item>

      {showModal && (
        <ModalCategory
          open={showModal}
          title={t('chooseType')}
          category={company.topicTypes}
          categories={topicTypes}
          onChange={changeTopics}
          name='topicTypes'
          closeModalCategory={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
