export const LOAD_POKA_APPROVERS_START = 'LOAD_POKA_APPROVERS_START';
export const LOAD_POKA_APPROVERS_SUCCESS = 'LOAD_POKA_APPROVERS_SUCCESS';
export const LOAD_POKA_APPROVERS_FAIL = 'LOAD_POKA_APPROVERS_FAIL';

export const POST_POKA_APPROVER_START = 'POST_POKA_APPROVER_START';
export const POST_POKA_APPROVER_SUCCESS = 'POST_POKA_APPROVER_SUCCESS';
export const POST_POKA_APPROVER_FAIL = 'POST_POKA_APPROVER_FAIL';

export const PUT_POKA_APPROVER_START = 'PUT_POKA_APPROVER_START';
export const PUT_POKA_APPROVER_SUCCESS = 'PUT_POKA_APPROVER_SUCCESS';
export const PUT_POKA_APPROVER_FAIL = 'PUT_POKA_APPROVER_FAIL';

export const DELETE_POKA_APPROVER_START = 'DELETE_POKA_APPROVER_START';
export const DELETE_POKA_APPROVER_SUCCESS = 'DELETE_POKA_APPROVER_SUCCESS';
export const DELETE_POKA_APPROVER_FAIL = 'DELETE_POKA_APPROVER_FAIL';

export const SAVE_POKA_APPROVERS_SORT = 'SAVE_POKA_APPROVERS_SORT';
export const SAVE_POKA_APPROVERS_FILTERS = 'SAVE_POKA_APPROVERS_FILTERS';

export const SAVE_POKA_APPROVER_DATA = 'SAVE_POKA_APPROVER_DATA';
