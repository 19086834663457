/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  changeCompilationVisibility,
  clearPage,
  createCompilation,
  getCompilation,
  getCompilations,
} from '@/store/compilations/actions';
import { createFileImage, getFile } from '@/store/files/actions';
import { clearSearchParams, setFindSearchText, setSearchParams } from '@/store/search/actions';
import { getTopics } from '@/store/topics/actions';

import { selectCompilations as compilationsSelect } from '@/store/compilations/selectors';
import { selectFiles as filesSelect } from '@/store/files/selectors';
import { selectSearch } from '@/store/search/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as userSelect } from '@/store/users/selectors';

import { Button, Layout, Pagination, Spin } from 'antd';
import AddCompilationCareerModal from '@/components/AddCompilationCareerModal';
import AddCompilationModal from '@/components/AddCompilationModal';
import CompilationCard from '@/components/CompilationCard';
import ContextSearch from '@/components/ContextSearch';
import { Card, Content } from '@/components/Layout';
import ThemesEditor from '@/components/ThemesEditor';
import { PlusCircleOutlined } from '@ant-design/icons';

import debounce from '@shared/utils/debounce';

import { createSelector } from '@reduxjs/toolkit';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './Compilation.module.scss';

import { COMPILATION_CREATE } from '@/constants/permissions';

const { Sider } = Layout;

class Compilation extends Component {
  static propTypes = {
    clearSearchParams: PropTypes.func,
    compilations: PropTypes.array,
    compilationsPage: PropTypes.number,
    compilationsPageNumber: PropTypes.number,
    compilationsPageSize: PropTypes.number,
    compilationsTotal: PropTypes.number,
    domainCompany: PropTypes.object,
    getCompilation: PropTypes.func,
    getCompilations: PropTypes.func,
    clearPage: PropTypes.func,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    isLoadingFile: PropTypes.bool,
    isLoadingFileImage: PropTypes.bool,
    loadMore: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    searchFindSearchText: PropTypes.any,
    searchParams: PropTypes.object,
    setFindSearchText: PropTypes.func,
    setSearchParams: PropTypes.func,
    changeCompilationVisibility: PropTypes.func,
    topics: PropTypes.array,
    topicsTotal: PropTypes.number,
    isGlobal: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isVisibleCareer: false,
      editable: null,
    };
    this.getCompilations = debounce(props.getCompilations, 300, true);
    this.setSearchParams = debounce(props.setSearchParams, 300, true);
  }

  componentDidMount() {
    this.getCompilations({
      page: this.props.compilationsPageNumber,
      ...this.props.searchParams,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchParams !== prevProps.searchParams) {
      this.getCompilations({ page: 0, ...this.props.searchParams });
    }
  }

  componentWillUnmount() {
    this.props.clearPage();
  }

  toggleGlobalModal = params => {
    if (params && params.editable && !this.state.isVisibleCareer) {
      this.props.getCompilation(params.editable, res => {
        const { topics, career } = res;
        this.setState({
          isVisibleCareer: true,
          editable: {
            id: params.editable,
            career,
            topics,
          },
        });
      });
    } else {
      this.setState({
        isVisibleCareer: false,
        editable: this.state.isVisibleCareer && null,
      });
    }
  };

  toggleModal = params => {
    if (params && params.editable && !this.state.isVisible) {
      this.props.getCompilation(params.editable, res => {
        const { topics, themes, shortDescription, name, fullDescription, cover, skills } = res;
        this.setState({
          editable: {
            id: params.editable,
            topics,
            themes,
            shortDescription,
            name,
            fullDescription,
            cover,
            skills,
          },
          isVisible: true,
        });
      });
    } else {
      this.setState({
        isVisible: !this.state.isVisible,
        editable: !this.state.isVisible && !params.editable && null,
      });
    }
  };

  getCompilationsList = page =>
    this.getCompilations({
      ...this.props.searchParams,
      page: page - 1,
      size: this.props.compilationsPageSize,
    });

  showSearchResult = params => {
    this.setSearchParams({
      ...this.props.searchParams,
      searchResult: params.name,
      ...params,
    });
  };

  handleClearSearch = () => {
    this.props.clearSearchParams();
    this.props.setFindSearchText({});
  };

  clearThemes = () => {
    this.setSearchParams({ ...this.props.searchParams, themeId: [] });
  };

  onChangeVisibility = item => {
    this.props.changeCompilationVisibility(item.id, !item.visible);
  };

  render() {
    const {
      compilations,
      history,
      isLoading,
      isLoadingFile,
      isLoadingFileImage,
      compilationsPageNumber,
      compilationsPageSize,
      compilationsTotal,
      t,
    } = this.props;
    const pagination =
      compilationsPageSize && compilationsTotal && compilationsTotal > compilationsPageSize ? (
        <Pagination
          className={css['Pagination']}
          current={compilationsPageNumber + 1}
          total={compilationsTotal}
          pageSize={compilationsPageSize}
          onChange={this.getCompilationsList}
          showSizeChanger={false}
        />
      ) : null;
    const compilationsList =
      compilations &&
      compilations.added === undefined &&
      compilations.map(item => (
        <div data-qa='compilationItem' key={item.id}>
          <CompilationCard
            onEdit={() => this.toggleModal({ editable: item.id })}
            // onEdit={() =>
            //   !this.props.isGlobal && item.global
            //     ? this.toggleGlobalModal({ editable: item.id })
            //     : this.toggleModal({ editable: item.id })
            // }
            onChangeVisibility={this.onChangeVisibility}
            compilation={item}
            history={history}
            currentUser={this.props.currentUser}
          />
        </div>
      ));
    const results = noun(compilations.length, t('result1'), t('result2'), t('result3'));
    return (
      <Layout>
        <Sider className={css.Compilation__slider} width={287}>
          <Layout>
            <Content className={css.Compilation__themes}>
              <h1>{t('collections')}</h1>
              <a href='/themes-editor'>
                <Button data-qa='editThemesBtn' className={css.Compilation__btnmargin_2}>
                  {t('editTopics')}
                </Button>
              </a>
              <ThemesEditor
                dataQa='compilationPageThemeList'
                onlyView
                clearSearchParams={this.props.clearSearchParams}
                setSearchParams={this.setSearchParams}
              />
            </Content>
          </Layout>
        </Sider>
        <Layout>
          <Content className={css.Compilation}>
            <div>
              <ContextSearch
                dataQa='compilationSearch'
                history={this.props.history}
                handleClearSearch={this.handleClearSearch}
                showSearchResult={this.showSearchResult}
              />
            </div>
            <div className={css.Compilation__action}>
              <div className={css.Compilation__search}>
                <span />
                {this.props.searchFindSearchText && this.props.searchFindSearchText.searchResult && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('searchBy', {
                        result: this.props.searchFindSearchText.searchResult,
                        length: compilations.length,
                        results,
                      }),
                    }}
                  />
                )}
                {this.props.searchFindSearchText &&
                  (this.props.searchFindSearchText.creBy || this.props.searchFindSearchText.creById) && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('searchByExpert', {
                          result: this.props.searchFindSearchText.creById || this.props.searchFindSearchText.creBy,
                          length: compilations.length,
                          results,
                        }),
                      }}
                    />
                  )}
                {this.props.searchFindSearchText && this.props.searchFindSearchText.name && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('searchByTitle', {
                        result: this.props.searchFindSearchText.name,
                        length: compilations.length,
                        results,
                      }),
                    }}
                  />
                )}
                {this.props.searchFindSearchText &&
                  (this.props.searchFindSearchText.skillName || this.props.searchFindSearchText.skillId) && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('searchBySkill', {
                          result: this.props.searchFindSearchText.skillId || this.props.searchFindSearchText.skillName,
                          length: compilations.length,
                          results,
                        }),
                      }}
                    />
                  )}
              </div>
              {this.props.currentUser.authorities.includes(COMPILATION_CREATE) && (
                <Button
                  className={css.Compilation__btnmargin}
                  data-qa='addCompilationBtn'
                  icon={<PlusCircleOutlined />}
                  onClick={this.toggleModal}
                  type='primary'
                >
                  {t('createCollection')}
                </Button>
              )}
              {this.state.isVisible && (
                <AddCompilationModal
                  editable={this.state.editable}
                  open={this.state.isVisible}
                  toggleModal={this.toggleModal}
                  domainCompany={this.props.domainCompany}
                  onCreate={this.handleClearSearch}
                />
              )}
              {this.state.isVisibleCareer && (
                <AddCompilationCareerModal
                  editable={this.state.editable}
                  isGlobal={this.props.isGlobal}
                  toggleModal={this.toggleGlobalModal}
                />
              )}
            </div>
            <div>
              <Spin spinning={isLoading}>
                <Card style={{ borderRadius: 10 }} className={css.Compilation__block}>
                  {compilations.length > 0 ? (
                    <div data-qa='compilationList'>
                      {compilationsList}
                      {pagination}
                    </div>
                  ) : !isLoading && !isLoadingFileImage && !isLoadingFile && compilations.length === 0 ? (
                    !this.props.searchFindSearchText && this.props.searchParams === null ? (
                      <div className={css.notFound}>{t('none')}</div>
                    ) : (
                      <div data-qa='compilationsNotFoundBlock' className={css.notFound}>
                        <div dangerouslySetInnerHTML={{ __html: t('such') }} />
                        <a onClick={this.clearThemes}>{t('allThemes')}</a>
                      </div>
                    )
                  ) : null}
                </Card>
              </Spin>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createSelector(
  topicsSelect,
  filesSelect,
  compilationsSelect,
  selectSearch,
  userSelect,
  (topics, files, compilations, search, users) => ({
    searchParams: search.searchParams,
    topics: topics.topics,
    isLoading: compilations.isLoading,
    pageSize: topics.topicsPageSize,
    topicsTotal: topics.topicsTotal,
    isLoadingFileImage: files.isLoadingImage,
    isLoadingFile: files.isLoading,
    compilations: compilations.compilations,
    compilationsTotal: compilations.compilationsTotal || 0,
    compilationsPageSize: compilations.compilationsPageSize || 0,
    compilationsPageNumber: compilations.compilationsPageNumber || 0,
    loadMore: compilations.compilations.hasMore,
    compilationsPage: compilations.compilations.page,
    searchFindSearchText: search.searchFindSearchText,
    pageNumber: topics.topicsPageNumber || 0,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    currentUser: users.currentUser,
    isGlobal: users && users.currentUser.domainCompany.global,
  })
);

const mapActionsToProps = {
  createFileImage,
  createCompilation,
  setSearchParams,
  setFindSearchText,
  clearSearchParams,
  getCompilation,
  getFile,
  getCompilations,
  clearPage,
  getTopics,
  changeCompilationVisibility,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('compilation')(Compilation));
