/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getReportPdf, getScore } from '@/store/talentq/actions';

import { selectSettingsTQ } from '@/store/talentq/selectors';

import { Button, Col, Row, Spin } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './diagnosticsGPM.module.scss';

export const DiagnosticsGPM = props => {
  const { t } = props;
  const [student, setStudent] = useState(null);

  useEffect(() => {
    if (props.student && props.student.id) {
      setStudent(props.student.id);
      props.getScore(props.student.id);
    }
  }, [props.student]);

  const resArray = Array.isArray(props.scores) ? props.scores : Object.keys(props.scores).map(k => props.scores[k]);

  const resultCompetence = resArray.reduce((acc, item) => {
    const competence = { ...item.competence, value: item.value };
    return [...acc, competence];
  }, []);

  const competenceRow = [
    { title: t('strengths'), from: 8, to: 10 },
    { title: t('keyZone'), from: 1, to: 3 },
    { title: t('additionalZone'), from: 4, to: 7 },
  ];

  const FROMTEN = '/10';

  const nameReport = `Report ${student}.pdf`;
  const getReport = () => {
    props.getReportPdf(student, blob => {
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = url;
      link.download = nameReport;
      link.click();
    });
  };

  const render = () => {
    return (
      <div>
        {props.selectTalentQ.loading ? (
          <Col className={css.loading}>
            <Spin size='large' tip={t('loading')} />
          </Col>
        ) : (
          <div>
            {resultCompetence.length ? (
              <div>
                <div className={css.blockResultBtn}>
                  <h2 className={css.headerContentCaption}>{t('results')}</h2>
                  <Button type='primary' onClick={() => getReport()} disabled={!resultCompetence.length}>
                    {t('report')}
                  </Button>
                </div>

                {competenceRow.map((item, index) => (
                  <div key={index}>
                    <h3 className={css.headerContentCaptionRow}>{item.title}</h3>
                    {resultCompetence.length
                      ? resultCompetence
                          .filter(count => count.value >= item.from && count.value <= item.to)
                          .map(item => {
                            const description = item.description ? item.description.split(':')[1] : '-';
                            return (
                              <Row key={item.id} span={8}>
                                <div className={css.text}>
                                  {description} - &nbsp;
                                  <strong>
                                    {item.value}
                                    {FROMTEN}
                                  </strong>
                                </div>
                              </Row>
                            );
                          })
                      : ''}
                  </div>
                ))}
              </div>
            ) : (
              <h3 className={css.noResult}>{t('noResult')}</h3>
            )}
          </div>
        )}
      </div>
    );
  };
  return render();
};

DiagnosticsGPM.propTypes = {
  student: PropTypes.object,
  selectTalentQ: PropTypes.object,
  scores: PropTypes.array,
  getReportPdf: PropTypes.func,
  getScore: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = createSelector([selectSettingsTQ], selectSettingsTQ => ({
  selectTalentQ: selectSettingsTQ,
  scores: selectSettingsTQ.scores,
}));

const mapActionsToProps = {
  getReportPdf,
  getScore,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('studentProfileDialog')(DiagnosticsGPM));
