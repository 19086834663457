import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfileCustomFieldValue } from '@/store/pcfieldvalue/actions';
import {
  exportCorrectArea,
  getPokaDirCorrectPositionArea,
  loadPokaAreas,
  savePokaAreasSort,
} from '@/store/poka_areas/actions';
import { clearStudents, getStudents } from '@/store/students/actions';

import { selectPokaProperAreas } from '@/store/poka_areas/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, message, Select, Table, Tooltip } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { DEFAULT_PARAMS, MAX_TEXT_LENGTH, SORT_VALUES } from '../constants';

import Utils from '@/Utils';
import classNames from 'classnames';
import _ from 'lodash';

import css from '../AreasDirectory.module.scss';

const TableAreasDirectory = () => {
  const { t } = useTranslation('AreasDirectory');
  const dispatch = useDispatch();

  const [isActiveSort, setIsActiveSort] = useState({ aZ: false });
  const [tableState, setTableState] = useState([]);
  const [searchProperArea, setSearchProperArea] = useState('');

  const {
    pokaProperAreas,
    pokaProperAreasTotal,
    pokaProperAreasPageSize,
    pokaProperAreasPageNumber,
    pokaProperAreasCurrentPage,
    pokaProperAreasFilters,
    pokaProperAreasSort,
    loadingPokaProperAreas,
    loadingPokaCorrectAreas,
    pokaCorrectAreas,
    exportLoading,
  } = useSelector(selectPokaProperAreas);

  const { students, isLoadingStudents } = useSelector(selectStudents);

  const renderWithTooltip = text => {
    const content = text || '';
    return (
      <Tooltip title={content.length > MAX_TEXT_LENGTH ? content : ''}>
        {Utils.trString(content, MAX_TEXT_LENGTH)}
      </Tooltip>
    );
  };

  const handleEditClick = item => {
    const matchedItem = tableState.find(stateItem => stateItem.userId === item.userId);
    let value = [];
    if (matchedItem.correctPositionArea?.label) {
      value.push({
        fieldId: item.correctPositionAreaFieldId,
        value: matchedItem.correctPositionArea.label,
      });
    }

    if (matchedItem.collaboratorMentor?.label) {
      value.push({
        fieldId: item.collaboratorMentorFieldId,
        value: matchedItem.collaboratorMentor.label,
      });
    }
    if (value.length === 0) {
      message.warning(t('editErrorTitle'));
    } else {
      dispatch(updateProfileCustomFieldValue(item.userId, value));
    }
  };

  const handleSearchStudents = e => {
    if (e) {
      let fullName = e.trim();
      if (fullName.length > 3) dispatch(getStudents({ fullName: e, size: 100 }));
    }
  };

  const getStudentsDebounced = useCallback(_.debounce(handleSearchStudents, 1000), []);

  const fullNameOptions = useMemo(
    () =>
      students &&
      students?.length &&
      students.map(item => {
        let username = `${item?.lastName || ''} ${item?.firstName || ''} ${item?.middleName || ''}`.trim();
        return (
          <Select.Option value={username} key={item.id}>
            {username}
          </Select.Option>
        );
      }),
    [students]
  );

  // Поиск корректных участков
  const onSearchProperArea = val => {
    const trimVal = val?.trim();
    setSearchProperArea(trimVal);
  };

  // Запись данных из селекта Корректные участки и Менторы, создание нового Кор.участка
  const onChangeCF = (userId, value, name, correctPositionAreaFieldId) => {
    if (value?.value === searchProperArea.trim()) {
      setSearchProperArea('');

      const customFieldValue = [{ fieldId: correctPositionAreaFieldId, value: value.value }];
      dispatch(
        updateProfileCustomFieldValue(userId, customFieldValue, () => dispatch(getPokaDirCorrectPositionArea()))
      );
    }

    setTableState(prevState =>
      prevState.map(item => {
        return item.userId === userId
          ? {
              ...item,
              [name]: value ? { fieldId: Number(value.key), label: value.label } : '',
            }
          : item;
      })
    );
  };

  const pokaCorrectAreastOptions = useMemo(() => {
    const options =
      pokaCorrectAreas && pokaCorrectAreas.length
        ? pokaCorrectAreas.map(item => ({
            value: item.value,
            label: item.label,
          }))
        : [];

    const newOption =
      searchProperArea && !_.some(pokaCorrectAreas, { name: searchProperArea }) && !loadingPokaCorrectAreas
        ? [{ value: searchProperArea.trim(), label: searchProperArea.trim() + t('new') }]
        : [];

    return [...options, ...newOption];
  }, [pokaCorrectAreas, searchProperArea, loadingPokaCorrectAreas]);

  const ProperAreaSelect = record => {
    return (
      <Select
        showSearch
        onSearch={onSearchProperArea}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase().trim())}
        allowClear
        optionLabelProp='label'
        autoClearSearchValue
        labelInValue
        onChange={value => onChangeCF(record.userId, value, 'correctPositionArea', record.correctPositionAreaFieldId)}
        onBlur={() => onSearchProperArea('')}
        placeholder={t('placeholderSelect')}
        loading={loadingPokaCorrectAreas}
        defaultValue={record?.correctPositionArea?.label}
        className={css['Table-select']}
        options={pokaCorrectAreastOptions}
      />
    );
  };

  const MentorSelect = record => {
    return (
      <Select
        showSearch
        allowClear
        labelInValue
        optionFilterProp='children'
        placeholder={t('placeholderSelect')}
        loading={isLoadingStudents}
        onSearch={getStudentsDebounced}
        defaultValue={record?.collaboratorMentor?.label}
        onChange={value => {
          onChangeCF(record.userId, value, 'collaboratorMentor');
          dispatch(clearStudents());
        }}
        className={css['Table-select']}
      >
        {fullNameOptions}
      </Select>
    );
  };

  const columns = [
    {
      title: t('fullnameCol'),
      dataIndex: 'fullName',
      key: '1',
      width: 184,
      fixed: 'left',
      render: (val, record) => renderWithTooltip(record.fullName),
    },
    {
      title: t('position'),
      dataIndex: 'position',
      key: '2',
      width: 112,
      render: (val, record) => renderWithTooltip(record.position),
    },
    {
      title: t('pokaPosition'),
      dataIndex: 'modelPositionName',
      key: '3',
      width: 80,
      render: (val, record) => renderWithTooltip(record.modelPositionName),
    },
    {
      title: t('manager'),
      dataIndex: 'manager',
      key: '4',
      width: 144,
      render: (val, record) => renderWithTooltip(record.manager),
    },
    {
      title: t('site'),
      dataIndex: 'site',
      key: '5',
      width: 80,
      render: (val, record) => renderWithTooltip(record.site),
    },
    {
      title: t('education'),
      dataIndex: 'education',
      key: '6',
      width: 128,
      render: (val, record) => renderWithTooltip(record.education),
    },
    {
      title: t('area'),
      dataIndex: 'area',
      key: '7',
      width: 112,
      render: (val, record) => renderWithTooltip(record.area),
    },
    {
      title: t('properArea'),
      dataIndex: 'correctPositionArea',
      key: '8',
      width: 224,
      render: (val, record) => ProperAreaSelect(record),
    },
    {
      title: t('mentor'),
      dataIndex: 'collaboratorMentor',
      key: '9',
      width: 224,
      render: (val, record) => MentorSelect(record),
    },
    {
      dataIndex: 'action',
      key: '10',
      width: 48,
      align: 'center',
      fixed: 'right',
      render: (val, item) => (
        <div className={css['Table-area-buttons']}>
          <Tooltip title={t('saveInfo')}>
            <SaveOutlined className={css['Table-area-buttons-editBtn']} onClick={() => handleEditClick(item)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  const pagination = {
    total: pokaProperAreasTotal,
    pageSize: pokaProperAreasPageSize,
    pageNumber: pokaProperAreasPageNumber,
    current: pokaProperAreasCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const dataSource = useMemo(
    () =>
      pokaProperAreas?.length > 0 &&
      pokaProperAreas.map(pokaProperArea => ({
        fullName: pokaProperArea.fullName,
        position: pokaProperArea.position,
        modelPositionName: pokaProperArea.modelPositionName || '',
        manager: pokaProperArea.manager && pokaProperArea.manager.fullName ? pokaProperArea.manager.fullName : '',
        site: pokaProperArea.site || '',
        education: pokaProperArea.education || '',
        area: pokaProperArea.area || '',
        correctPositionArea: pokaProperArea.correctPositionArea || '',
        correctPositionAreaFieldId: pokaProperArea.correctPositionAreaFieldId || null,
        collaboratorMentor: pokaProperArea.collaboratorMentor || '',
        collaboratorMentorFieldId: pokaProperArea.collaboratorMentorFieldId || null,
        userId: pokaProperArea.userId,
      })),
    [pokaProperAreas]
  );

  const onChangeTable = ({ current }) => {
    const page = current - 1;
    const queryParams = { size: DEFAULT_PARAMS.size, page, sort: pokaProperAreasSort };
    dispatch(loadPokaAreas(queryParams, pokaProperAreasFilters));
  };

  const sortTable = (sortName, sortValue) => {
    setIsActiveSort({ [sortName]: !isActiveSort[sortName] });
    dispatch(savePokaAreasSort(!isActiveSort[sortName] ? sortValue : ''));
    dispatch(
      loadPokaAreas(
        { page: pokaProperAreasCurrentPage, size: DEFAULT_PARAMS.size, sort: !isActiveSort[sortName] ? sortValue : '' },
        pokaProperAreasFilters
      )
    );
  };

  const exportTable = () => {
    const date = new Date();
    const fileNameNotSplit = `CorrectArea_(${date.toLocaleDateString()}).csv`;

    dispatch(
      exportCorrectArea(pokaProperAreasFilters, (blob, headers) => {
        const fileName = headers.get('Content-Disposition').split('=')[1].split('+')[0] || fileNameNotSplit;
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  useEffect(() => {
    if (pokaProperAreas?.length > 0) {
      let copyData = _.cloneDeep(pokaProperAreas);
      setTableState(copyData);
    }
  }, [pokaProperAreas]);

  return (
    <div className={css['Table']}>
      {/* Блок сортировки + кнопка */}
      <div className={css['Table-actions']}>
        <div className={css['Table-actions-sort']}>
          <div className={css['Table-actions-sort-text']}>{t('sort')}</div>
          <div
            className={classNames(
              pokaProperAreasSort.includes(SORT_VALUES.NAME_ASC) && css['Table-actions-sort-active']
            )}
            onClick={() => sortTable('aZ', SORT_VALUES.NAME_ASC)}
          >
            {t('sortFullName')}
          </div>
        </div>
        <Button size='large' type='primary' loading={exportLoading} onClick={exportTable}>
          {t('exportBtn')}
        </Button>
      </div>

      <div className={css['Table-area']}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey={e => e?.userId}
          pagination={pagination}
          onChange={onChangeTable}
          loading={loadingPokaProperAreas}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  );
};

export default TableAreasDirectory;
