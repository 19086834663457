import { Tag } from 'antd';

import PropTypes from 'prop-types';

import css from './TagSelected.module.scss';

const TagSelected = ({ onDeleteTag, name, id }) => {
  return (
    <span className={css['Tag']}>
      <Tag closeIcon onClose={() => onDeleteTag(id)}>
        {name}
      </Tag>
    </span>
  );
};

TagSelected.propTypes = {
  name: PropTypes.string,
  onDeleteTag: PropTypes.func,
  id: PropTypes.number,
};

export default TagSelected;
