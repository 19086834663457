export const GET_STUDYING_STATISTICS_START = 'GET_STUDYING_STATISTICS_START';
export const GET_STUDYING_STATISTICS_SUCCESS = 'GET_STUDYING_STATISTICS_SUCCESS';
export const GET_STUDYING_STATISTICS_FAIL = 'GET_STUDYING_STATISTICS_FAIL';

export const GET_MATERIAL_STATISTICS_START = 'GET_MATERIAL_STATISTICS_START';
export const GET_MATERIAL_STATISTICS_SUCCESS = 'GET_MATERIAL_STATISTICS_SUCCESS';
export const GET_MATERIAL_STATISTICS_FAIL = 'GET_MATERIAL_STATISTICS_FAIL';

export const PUT_NOTIFICATION_REMINDER_START = 'PUT_NOTIFICATION_REMINDER_START';
export const PUT_NOTIFICATION_REMINDER_SUCCESS = 'PUT_NOTIFICATION_REMINDER_SUCCESS';
export const PUT_NOTIFICATION_REMINDER_FAIL = 'PUT_NOTIFICATION_REMINDER_FAIL';

export const CLEAR_STUDYING_STATISTICS = 'CLEAR_STUDYING_STATISTICS';
