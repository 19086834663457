/* eslint-disable */
import {
  CREATE_TILDA_FAIL,
  CREATE_TILDA_START,
  CREATE_TILDA_SUCCESS,
  DELETE_TILDA_FAIL,
  DELETE_TILDA_START,
  DELETE_TILDA_SUCCESS,
  GET_TILDA_FAIL,
  GET_TILDA_START,
  GET_TILDA_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  info: null,
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    case CREATE_TILDA_START:
    case DELETE_TILDA_START:
    case GET_TILDA_START:
      return { ...state, loading: true };

    case CREATE_TILDA_FAIL:
    case DELETE_TILDA_FAIL:
    case GET_TILDA_FAIL:
      return { ...state, loading: false };

    case CREATE_TILDA_SUCCESS:
      return { ...state, info: response, loading: false };

    case DELETE_TILDA_SUCCESS:
      return { ...state, info: null, loading: false };

    case GET_TILDA_SUCCESS:
      return { ...state, info: response, loading: false };

    default:
      return state;
  }
};
