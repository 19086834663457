/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBookAddresses } from '@/store/books/actions';

import { AutoComplete, Tag } from 'antd';

import Input from '../Input';

import PropTypes from 'prop-types';

import css from './TagsInput.module.scss';

class TagsInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    isAddressBook: PropTypes.bool,
    topicId: PropTypes.number,
    loadOptions: PropTypes.func,
    id: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      tags: props.value || [],
    };
  }

  componentDidMount() {
    if (this.props.isAddressBook && this.props.topicId) {
      this.props.getBookAddresses(
        this.props.topicId,
        data => this.setState({ tags: data }),
        err => console.log('TagsInput componentDidMount err ', err)
      );
    }
  }

  handleClose = removedTagId => {
    const { onChange, name, isAddressBook } = this.props;

    const tags = isAddressBook
      ? this.state.tags.filter(tag => tag.id !== removedTagId)
      : this.state.tags.filter(tag => tag !== removedTagId);

    if (isAddressBook) {
      onChange({ name, value: tags, deleteAddresses: [removedTagId] });
    } else {
      onChange({ name, value: tags });
    }
    this.setState({ tags });
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleInputConfirm = ({ value = '' } = {}) => {
    const { onChange, loadOptions, name, isAddressBook } = this.props;

    this.setState(state => {
      const { inputValue = '', tags = [] } = state;
      const newTag = ((loadOptions ? value : inputValue) || '').trim();

      if (!newTag.length) return { inputValue: '' };

      if (isAddressBook) {
        const addressesStrings = tags.map(tag => tag.address);
        const newTags = addressesStrings.includes(newTag) ? tags : [...tags, { id: 0, address: newTag }];
        onChange({ name, value: newTags, addAddresses: [newTag] });
        return { inputValue: '', tags: newTags };
      }

      const currentTags = tags.map(tag => tag);
      const newTags = currentTags.includes(newTag) ? tags : [...tags, newTag];
      onChange({ name, value: newTags });
      return { inputValue: '', tags: newTags };
    });
  };

  render() {
    const { tags, inputValue } = this.state;
    const { loadOptions, isAddressBook, id } = this.props;

    const tagElements = tags
      .filter(tag => !!tag)
      .map((tag, index) => {
        if (isAddressBook) {
          return (
            <Tag key={tag.id} className={css.tag} closable={tag.accessible} onClose={() => this.handleClose(tag.id)}>
              {tag.address.length > 50 ? `${tag.address.substring(0, 50)}...` : tag.address}
            </Tag>
          );
        }

        return (
          <Tag key={index} className={css.tag} onClose={() => this.handleClose(tag)} closable>
            {tag.length > 50 ? `${tag.substring(0, 50)}...` : tag}
          </Tag>
        );
      });

    return (
      <div className={css.TagInput}>
        {loadOptions ? (
          <AutoComplete
            onChange={this.handleInputConfirm}
            placeholder={this.props.placeholder}
            onSearch={loadOptions}
            id={id}
          />
        ) : (
          <Input
            value={inputValue}
            placeholder={this.props.placeholder}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
            id={id}
          />
        )}
        {tagElements}
      </div>
    );
  }
}

const mapActionsToProps = {
  getBookAddresses,
};

export default connect(null, mapActionsToProps)(TagsInput);
