import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectAccess } from '@/store/access/selectors';

import { Typography } from 'antd';

import PropTypes from 'prop-types';

const { Title } = Typography;

const ExpandedRoleRow = memo(({ role }) => {
  const { groupAll } = useSelector(selectAccess);

  const filteredPermission = useMemo(() => {
    const result = [];
    if (groupAll?.length && role?.permissions?.length) {
      groupAll.forEach((group, index) => {
        result[index] = { groupName: group.name, permissions: [] };
        role.permissions.forEach(rolePermission => {
          group.permissions.forEach(groupPermission => {
            if (groupPermission.id === rolePermission.id) {
              result[index].permissions.push(groupPermission);
            }
          });
        });
        if (!result[index].permissions.length) {
          delete result[index];
        }
      });
    }
    return result;
  }, []);

  return (
    <div>
      {filteredPermission.length
        ? filteredPermission.map((element, index) => (
            <ul key={index}>
              <Title level={3}>{element.groupName}</Title>
              {filteredPermission[index].permissions.map(permission => (
                <li key={permission.id}>{permission.description}</li>
              ))}
            </ul>
          ))
        : null}
    </div>
  );
});

ExpandedRoleRow.propTypes = {
  role: PropTypes.object,
};

export default ExpandedRoleRow;
