import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectLogin } from '@/store/login/selectors';

import { Button, Layout } from 'antd';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';

import css from './NotFound.module.scss';

import logoDefault from '@/assets/images/logo_default.png';
import NotFoundBackGroundPic from '@/assets/images/notFound.png';
import NotFoundBackGroundPicMob from '@/assets/images/notFoundMobile.png';

const { Content } = Layout;

const NotFound = () => {
  const { t } = useTranslation('notFound');
  const navigate = useNavigate();
  const { company } = useSelector(selectLogin);

  const [imageLogo, isLoadingImageLogo] = useLocalFileByUUID(company?.logo, { defaultImg: logoDefault });
  const [isMobile] = useIsMobile(768);

  return (
    <Layout className={css.NotFound}>
      <div className={css.backgroundImage}>{!isMobile && <img src={NotFoundBackGroundPic} alt='Background' />}</div>
      <Content className={css['NotFound__container']}>
        <div className={css['NotFound__info']}>
          <div className={css['NotFound__logo']}>
            {isLoadingImageLogo ? <img src={logoDefault} alt='logo' /> : <img src={imageLogo} alt='logo' />}
          </div>
          <div className={css['NotFound__title']}>{t('find')}</div>
          <div className={css['NotFound__content']}>{t('error')}</div>
          <div className={css['NotFound__btnContainer']}>
            <Button type='primary' size={isMobile ? 'middle' : 'large'} onClick={() => navigate('/')}>
              {t('goTo')}
            </Button>
          </div>
        </div>
        <div className={css.backgroundImageMob}>
          <img src={NotFoundBackGroundPicMob} alt='Background' />
        </div>
      </Content>
    </Layout>
  );
};

export default NotFound;
