import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearIprFilters, postIprFilters, saveIprFilters, saveIprSort } from '@/store/ipr/actions';
import { getStudents } from '@/store/students/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectCompetence } from '@/store/competence/selectors';
import { selectIpr } from '@/store/ipr/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Row, Select } from 'antd';

import _ from 'lodash';

import css from '../Career.module.scss';

const { Option } = Select;

const Filters = () => {
  const { t } = useTranslation('career');
  const dispatch = useDispatch();

  const [data, setData] = useState({
    subCompanies: [],
    positions: [],
    statuses: [],
    departments: [],
    functions: [],
    userId: { id: null, name: '' },
    competencies: [],
    goalCategories: [],
  });

  const { students, position, department, functionStudent } = useSelector(selectStudents);
  const { subCompanies } = useSelector(selectCompanies);
  const { iprStatus, iprSort, goalCategory } = useSelector(selectIpr);
  const { competence } = useSelector(selectCompetence);

  const deleteEmpty = arr => {
    let copyData = _.cloneDeep(arr);

    for (let key in copyData) {
      if (!copyData[key] || (copyData[key].hasOwnProperty('id') && !copyData[key].id)) {
        delete copyData[key];
      } else if (copyData[key]?.id) {
        copyData[key] = copyData[key].id;
      }

      if (Array.isArray(copyData[key]) && copyData[key].length === 0) {
        delete copyData[key];
      }
    }
    return copyData;
  };

  const onSendFilters = () => {
    const sendData = deleteEmpty(data);

    dispatch(saveIprFilters(sendData));
    dispatch(postIprFilters(sendData, 0, iprSort));
  };

  const clearFilters = () => {
    setData({
      subCompanies: [],
      positions: [],
      statuses: [],
      departments: [],
      functions: [],
      userId: { id: null, name: '' },
      competencies: [],
      goalCategories: [],
    });
    dispatch(clearIprFilters());
    dispatch(saveIprSort(''));
    dispatch(postIprFilters({}, 0));
  };

  const renderOptions = arr => {
    let opt =
      arr?.length &&
      arr.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      });

    return opt;
  };

  const optionUsers = useMemo(
    () =>
      students?.length &&
      students.map(item => {
        let username = `${item?.lastName || ''} ${item?.firstName || ''} ${item?.middleName || ''}`;
        return (
          <Option value={username} key={item.id} userId={item.id}>
            {username}
          </Option>
        );
      }),
    [students]
  );

  const optionStatuses = useMemo(
    () =>
      iprStatus?.length > 0 &&
      iprStatus?.map(item => {
        return (
          <Option value={item.status} key={item.status}>
            {item.statusText}
          </Option>
        );
      }),
    [iprStatus]
  );

  const optionCompetencies = useMemo(
    () =>
      competence?.length > 0 &&
      competence?.map(item => {
        return (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        );
      }),
    [competence]
  );

  const optionGoalCategory = useMemo(
    () =>
      goalCategory?.length > 0 &&
      goalCategory?.map(item => {
        return (
          <Option value={item.value} key={item.value}>
            {item.name}
          </Option>
        );
      }),
    [goalCategory]
  );

  const handleGetUsers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudents({ fullName: e, size: 100 }));
    }
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetUsers, 1000), []);

  const tempalateFilters = [
    {
      col: [
        // сотрудник
        {
          label: t('searchUser'),
          value: data.userId.name || undefined,
          onChange: (name, item) => setData({ ...data, userId: { id: item?.userId, name } }),
          span: 8,
          option: optionUsers,
          onSearch: getStudentsListDebounced,
          placeholder: t('placeholderSearchUser'),
        },
        // должность
        {
          label: t('position'),
          value: data.positions,
          onChange: name => setData({ ...data, positions: name }),
          span: 8,
          option: renderOptions(position),
          mode: 'multiple',
          placeholder: t('placeholderPosition'),
        },
        // отдел
        {
          label: t('department'),
          value: data.departments,
          onChange: name => setData({ ...data, departments: name }),
          span: 8,
          option: renderOptions(department),
          mode: 'multiple',
          placeholder: t('placeholderDepartment'),
        },
      ],
    },
    {
      col: [
        // функция
        {
          label: t('function'),
          value: data.functions,
          onChange: name => setData({ ...data, functions: name }),
          span: 8,
          option: renderOptions(functionStudent),
          mode: 'multiple',
          placeholder: t('placeholderFunction'),
        },
        // сабкомпани
        {
          label: t('company'),
          value: data.subCompanies,
          onChange: name => setData({ ...data, subCompanies: name }),
          span: 8,
          option: renderOptions(subCompanies),
          mode: 'multiple',
          placeholder: t('placeholderCompany'),
        },
        // статус
        {
          label: t('status'),
          value: data.statuses,
          onChange: name => setData({ ...data, statuses: name }),
          span: 8,
          option: optionStatuses,
          mode: 'multiple',
          placeholder: t('placeholderStatus'),
          optionFilterProp: 'children',
        },
      ],
    },
    {
      col: [
        // компетенции
        {
          label: t('competencies'),
          value: data.competencies,
          onChange: id => setData({ ...data, competencies: id }),
          span: 8,
          option: optionCompetencies,
          mode: 'multiple',
          placeholder: t('placeholderCompetencies'),
          optionFilterProp: 'children',
        },
        // категория цели
        {
          label: t('goalCategory'),
          value: data.goalCategories,
          onChange: value => setData({ ...data, goalCategories: value }),
          span: 8,
          option: optionGoalCategory,
          mode: 'multiple',
          placeholder: t('placeholderGoalCategory'),
          optionFilterProp: 'children',
        },
      ],
    },
  ];

  const renderFilters = tempalateFilters.map((item, index) => {
    const customRow = item.col.map((col, index) => {
      return (
        <Col span={col.span} key={index}>
          <Form layout='vertical'>
            <Form.Item label={col.label} colon={false}>
              <Select
                onChange={col.onChange}
                onSearch={col.onSearch}
                className={col.className}
                notFoundContent={t('noData')}
                value={col.value}
                mode={col.mode}
                allowClear
                showSearch
                placeholder={col.placeholder}
                optionFilterProp={col.optionFilterProp}
              >
                {col.option}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      );
    });

    return (
      <Row justify={item.justify} key={index} gutter={16}>
        {customRow}
      </Row>
    );
  });

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-title']}>{t('filter')}</div>
      {renderFilters}
      <Row justify='end' gutter={[16, 0]}>
        <Col>
          <Button onClick={clearFilters}>{t('reset')}</Button>
        </Col>
        <Col>
          <Button onClick={onSendFilters} type='primary'>
            {t('apply')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
