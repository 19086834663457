/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';
import { FiUsers } from 'react-icons/fi';

import ConsolidateReportButton from './ConsolidateReportButton';
import List from './List';

import _ from 'lodash';

import css from '../../css/index.module.scss';

export const Reports = () => {
  const { project } = useSelector(state => state.d360Project);
  const { t } = useTranslation('D360_IndividualReports');

  const Header = () => {
    return (
      <Row className={css.Header} gutter={[16, 16]}>
        <Col span={12}>
          <h2 className={css.HeaderTitle}>
            <FiUsers />
            &nbsp;{t('title')}
          </h2>
        </Col>
        <Col span={12} align='right'>
          <ConsolidateReportButton projectId={project.id} />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Header />
      <List />
    </>
  );
};

export default Reports;
