import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getChapters } from '@/store/chapters/actions';
import { createFile, deleteFile } from '@/store/files/actions';
import { addLoadingFiles, removeLoadingFiles, updateBlockFiles } from '@/store/lms/actions';

import { selectBlocks } from '@/store/lms/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Divider, Dropdown, Flex, Form, Input, Modal, Tooltip, Upload } from 'antd';
import { EllipsisOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import Utils from '@/Utils';
import { cloneDeep, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';

const MAX_FILES_LENGTH = 100;
const MAX_FILE_SIZE = 300; // megabytes
const MAX_DESCRIPTION_LENGTH = 1024;
const MAX_TITLE_LENGTH = 65;
const MAX_FILE_NAME_LENGTH = 60;

const { Item: FormItem } = Form;
const { TextArea } = Input;

const PokaFiles = ({ data, canEdit, topicTypeId, editBlock, deleteBlock, forceClose, topicId, deleteEmptyBlock }) => {
  const { currentUser } = useSelector(selectUsers);
  const { loadingFilesBlockId } = useSelector(selectBlocks);
  const { t } = useTranslation(['files', 'blocksCommon']);
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();

  // Состояние для файлов
  const [sendData, setSendData] = useState({
    description: '',
    files: [],
    fileUuids: [],
  });
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [isSaved, setIsSaved] = useState(true);

  // Кастомный isLoading тк нужно отображать Loading только для конкретных блоков
  const isLoading = useMemo(() => loadingFilesBlockId.includes(data?.id), [data?.id, loadingFilesBlockId]);

  const isCanEdit =
    (currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) || topicTypeId === 25) &&
    currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE);
  const isCanDelete =
    (currentUser.authorities.includes(TOPIC_BLOCK_DELETE) || topicTypeId === 25) &&
    currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE);
  const isCanSave = useMemo(() => {
    const dataFilesUuids = Array.isArray(data?.pokaFiles?.files) && data?.pokaFiles?.files.map(file => file?.uuid);

    return !isEqual(sendData?.fileUuids, dataFilesUuids) || data?.pokaFiles?.description !== sendData?.description;
  }, [sendData, data?.pokaFiles?.files]);

  const renderHeader = useMemo(
    () => (
      <div className={css['Block-header']}>
        <FileOutlined className={css['Block-header-item']} />
        <div className={css['Block-header-name']}>
          <Tooltip title={data?.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(data?.name, MAX_TITLE_LENGTH)}
          </Tooltip>
        </div>
      </div>
    ),
    [data?.name]
  );

  const dropDownMenu = useMemo(
    () => [
      ...(isCanEdit
        ? [
            {
              key: '1',
              label: <div data-qa='editBlockBtn'>{t('edit', { ns: 'blocksCommon' })}</div>,
              onClick: () => data.id && editBlock(data.id),
            },
          ]
        : []),
      ...(isCanDelete
        ? [
            {
              key: '2',
              label: <div data-qa='deleteBlockBtn'>{t('remove', { ns: 'blocksCommon' })}</div>,
              onClick: () => data.id && deleteBlock(data.id),
            },
          ]
        : []),
    ],
    [isCanDelete, isCanEdit, editBlock, deleteBlock]
  );

  const handleBeforeUpload = (file, fileList) => {
    const fileSizeMb = file.size / (1024 * 1024);
    const fullState = [...sendData.files, ...fileList];

    // Проверка на то, что загружается допустимое кол-во файлов
    if (fullState.indexOf(file) + 1 > MAX_FILES_LENGTH) {
      modal.error({
        title: t('fileError.title.lenght'),
        content: t('fileError.description.lenght', { maxFiles: MAX_FILES_LENGTH, fileName: file?.name }),
        width: 600,
      });
      return false;
    }

    // Проверяем размер файла
    if (fileSizeMb > MAX_FILE_SIZE) {
      modal.error({
        title: t('fileError.title.size'),
        content: t('fileError.description.size', { maxMb: MAX_FILE_SIZE, fileName: file?.name }),
      });
      return false;
    } else {
      return true;
    }
  };

  // Обработчик файла при успешном запросе
  const onCreateFileSuccess = file => {
    dispatch(removeLoadingFiles(data?.id));

    const correctFile = {
      ...file,
      name: Utils.trStringFile(file?.name, MAX_FILE_NAME_LENGTH),
    };

    setSendData(prevState => {
      return {
        ...prevState,
        fileUuids: [...prevState?.fileUuids, file?.uuid],
        files: [...prevState.files, correctFile],
      };
    });
  };

  // Обработчик файла при ошибочном запросе
  const onCreateFileFail = err => {
    dispatch(removeLoadingFiles(data?.id));

    modal.error({
      title: t('fileError.title.upload'),
      content: err?.message ?? t('fileError.description.upload'),
    });
  };

  // Обработчик для загрузки файлов
  const handleUpload = ({ file }) => {
    setIsSaved(false);

    if (sendData.files.length >= MAX_FILES_LENGTH) {
      return false;
    }

    const formData = new FormData();
    formData.append('file', file);

    dispatch(addLoadingFiles(data?.id)); // Добавляем загрузку именно для этого БЛОКА файла
    dispatch(createFile(formData, onCreateFileSuccess, onCreateFileFail));
  };

  // Удаление неактуальных(удалённых на UI) файлов
  const handleDeleteFiles = () => {
    filesToDelete.forEach(fileUuid => dispatch(deleteFile(fileUuid)));

    setFilesToDelete([]);
  };

  const onUpdateBlockSuccess = () => {
    // Удаляем удалённые файлы
    handleDeleteFiles();
    // получаем главы
    dispatch(getChapters(topicId));
  };

  // Сохранение блока
  const handleSaveFiles = async () => {
    // Проверяем есть ли файлы в блоке файл
    if (sendData?.files?.length > 0) {
      const correctSendData = cloneDeep(sendData);

      delete correctSendData.files;
      // Обновляем блок файлов
      await dispatch(updateBlockFiles(data?.id, correctSendData, onUpdateBlockSuccess));

      setIsSaved(true);
    } else {
      // Если же их нет, то предлагаем пользователю удалить блок
      deleteEmptyBlock({
        delId: data.id,
        funcOk: handleDeleteFiles,
      });
    }
  };

  const renderExtra = useMemo(
    () => (
      <div onClick={e => e.stopPropagation()}>
        <Button
          className={css['Block-header-save']}
          type='primary'
          data-qa='saveBlockBtn'
          disabled={!canEdit || !isCanSave || isSaved || isLoading}
          onClick={handleSaveFiles}
        >
          {t('save', { ns: 'blocksCommon' })}
        </Button>

        {(isCanEdit || isCanDelete) && (
          <Dropdown menu={{ items: dropDownMenu }} placement='bottomRight' trigger={['click']}>
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    ),
    [canEdit, isCanSave, isSaved, isCanEdit, isCanDelete, handleSaveFiles]
  );

  const handleChangeDescription = description => {
    setIsSaved(false);

    setSendData(prevState => ({
      ...prevState,
      description,
    }));
  };

  const showUploadList = useMemo(
    () => ({
      extra: ({ size = 0 }) => <span className={css['Block-files-extra']}>({(size / 1024 / 1024).toFixed(2)}MB)</span>,
      showRemoveIcon: !isLoading,
    }),
    [isLoading]
  );

  const handleRemoveFile = file => {
    setIsSaved(false);
    setFilesToDelete([...filesToDelete, file?.uuid]);
    setSendData(prevState => {
      const files = prevState.files.filter(item => item.uuid !== file?.uuid);
      const fileUuids = files.map(item => item?.uuid);

      return {
        ...prevState,
        files,
        fileUuids,
      };
    });
  };

  // Заполняем данные первичные данные
  useEffect(() => {
    if (data.pokaFiles) {
      const correctFiles = Array.isArray(data?.pokaFiles?.files)
        ? data?.pokaFiles?.files?.map(file => ({
            ...file,
            name: Utils.trStringFile(file?.name, MAX_FILE_NAME_LENGTH),
          }))
        : [];

      setSendData({
        description: data.pokaFiles.description ?? '',
        files: correctFiles,
        fileUuids: data.pokaFiles.files?.map(file => file.uuid) ?? [],
      });
    }
  }, []);

  return (
    <div className={css['Block']}>
      {/* Модальные окна */}
      {contextHolder}

      <CollapseLMS
        className={css['Block-layout']}
        header={renderHeader}
        extra={renderExtra}
        data={data}
        key={data.id}
        forceClose={forceClose}
      >
        <Form>
          <FormItem label={t('description')}>
            <TextArea
              value={sendData.description}
              placeholder={t('descriptionPlaceholder', { max: MAX_DESCRIPTION_LENGTH })}
              onChange={e => handleChangeDescription(e.target.value)}
              autoSize={{
                minRows: 2,
                maxRows: 8,
              }}
              maxLength={MAX_DESCRIPTION_LENGTH}
            />
          </FormItem>
        </Form>

        <Upload
          className={css['Block-files-uploader']}
          customRequest={handleUpload}
          beforeUpload={handleBeforeUpload}
          multiple={true}
          maxCount={MAX_FILES_LENGTH}
          fileList={sendData.files}
          showUploadList={showUploadList}
          onRemove={handleRemoveFile}
          disabled={!isCanEdit || !isCanDelete}
        >
          <Flex gap={20} align='center'>
            <Tooltip title={sendData.files.length >= MAX_FILES_LENGTH && t('maxFiles')}>
              <Button
                type='primary'
                icon={<UploadOutlined />}
                disabled={sendData.files.length >= MAX_FILES_LENGTH}
                loading={isLoading}
              >
                {t('upload', { ns: 'blocksCommon' })}
              </Button>
            </Tooltip>
            <p>{t('uploadInfo', { maxMb: MAX_FILE_SIZE, maxFiles: MAX_FILES_LENGTH })}</p>
          </Flex>
        </Upload>

        <Divider />
      </CollapseLMS>
    </div>
  );
};

PokaFiles.propTypes = {
  canEdit: PropTypes.bool,
  data: PropTypes.object,
  deleteBlock: PropTypes.func,
  editBlock: PropTypes.func,
  forceClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  topicId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topicTypeId: PropTypes.number,
};

export default PokaFiles;
