import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { exportOrders, getOrdersHistory, postOrdersFilters } from '@/store/order/actions';

import { selectOrder } from '@/store/order/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Table, Tooltip } from 'antd';
import { HiDocumentDownload } from 'react-icons/hi';

import ModalOrder from './ModalOrder';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../Orders.module.scss';

import { PRODUCT_ADMIN_GET_ORDER_STATUS_LIST_BY_ID } from '@/constants/permissions';

const TableOrders = ({ setIsSort, activeSort, setActiveSort, isSort }) => {
  const { t } = useTranslation('orders');
  const dispatch = useDispatch();
  const [isOrder, setIsOrder] = useState({
    obj: {},
    open: false,
  });

  const {
    isLoadingTable,
    ordersTable,
    ordersTotal,
    ordersPageSize,
    ordersPageNumber,
    ordersCurrentPage,
    orderFilters,
    exportLoading,
  } = useSelector(selectOrder);
  const { currentUser } = useSelector(selectUsers);

  const onChangeTable = pagination => {
    dispatch(postOrdersFilters(orderFilters.filters ? orderFilters.filters : {}, pagination.current - 1, isSort));
  };

  const pagination = {
    total: ordersTotal,
    pageSize: ordersPageSize,
    pageNumber: ordersPageNumber,
    current: ordersCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('customer'),
      dataIndex: 'customer',
      key: 1,
      render: (val, obj) => (
        <div className={css['Table-content-cell']}>
          <div className={css['Table-content-cell-customer']}>
            <a
              rel='noreferrer'
              href={`https://${currentUser?.domainCompany?.domain}/collegues/${obj.userId}/info`}
              target='_blank'
            >
              {val}
            </a>
          </div>
          <div className={css['Table-content-cell-mail']}>{obj.email}</div>
        </div>
      ),
    });

    columns.push({
      title: t('position'),
      dataIndex: 'position',
      key: 2,
      width: 120,
    });

    columns.push({
      title: t('productName'),
      dataIndex: 'productName',
      key: 3,
      render: (text, obj) => (
        <div className={css['Table-content-cell']}>
          <div className={css['Table-content-cell-product']}>
            <Tooltip title={text?.length > 20 && text}>
              <a
                rel='noreferrer'
                href={`https://${currentUser?.domainCompany?.domain}/shop/${obj.productId}`}
                target='_blank'
                className={css['Table-content-cell-product-href']}
              >
                {text}
              </a>
            </Tooltip>
          </div>
        </div>
      ),
    });

    columns.push({
      title: t('count'),
      dataIndex: 'count',
      key: 4,
    });

    columns.push({
      title: t('amount'),
      dataIndex: 'amount',
      key: 5,
    });

    columns.push({
      title: t('shipping'),
      dataIndex: 'shipping',
      key: 6,
    });

    columns.push({
      title: t('statusLabel'),
      dataIndex: 'statusLabel',
      key: 7,
      render: (text, obj) => (
        <div className={css['Table-content-cell']}>
          <div className={css['Table-content-cell-status']}>
            <span
              className={classNames(
                css['Table-content-cell-status-circle'],
                css[`Table-content-cell-status-circle-${obj.statusDto.value}`]
              )}
            ></span>
            <strong>{text}</strong>
          </div>
        </div>
      ),
    });
    if (currentUser?.authorities.includes(PRODUCT_ADMIN_GET_ORDER_STATUS_LIST_BY_ID)) {
      columns.push({
        dataIndex: 'action',
        key: 8,
        render: (value, obj) => (
          <Button onClick={() => showModalManage(obj)} type='primary'>
            {t('manage')}
          </Button>
        ),
      });
    }

    return columns;
  };

  const showModalManage = obj => {
    setIsOrder({ obj, open: true });
    if (obj.orderId) {
      dispatch(getOrdersHistory(obj.orderId));
    }
  };

  const getData = () => {
    if (ordersTable?.length) {
      return ordersTable.map(item => {
        return {
          customer: item.fullName,
          position: item.position,
          productName: item.productName,
          count: item.amount,
          amount: item.cost,
          shipping: item.delivery,
          statusLabel: item.status.name,
          userId: item.userId,
          email: item.email,
          productId: item.productId,
          th: item.personnelNumber,
          orderId: item.id,
          statusDto: item.status,
        };
      });
    }
  };

  const sortNew = () => {
    setActiveSort({ new: !activeSort.new });
    setIsSort(!activeSort.new ? 'createdDate,desc' : '');
    !activeSort.new
      ? dispatch(postOrdersFilters(orderFilters.filters ? orderFilters.filters : {}, 0, 'createdDate,desc'))
      : dispatch(postOrdersFilters(orderFilters.filters ? orderFilters.filters : {}, 0));
  };

  const sortOld = () => {
    setActiveSort({ old: !activeSort.old });
    setIsSort(!activeSort.old ? 'createdDate,asc' : '');
    !activeSort.old
      ? dispatch(postOrdersFilters(orderFilters.filters ? orderFilters.filters : {}, 0, 'createdDate,asc'))
      : dispatch(postOrdersFilters(orderFilters.filters ? orderFilters.filters : {}, 0));
  };

  const exportTable = () => {
    const date = new Date();
    const fileNameNotSplit = `Orders(${date.toLocaleDateString()}).csv`;

    dispatch(
      exportOrders(orderFilters.filters ? orderFilters.filters : {}, (blob, headers) => {
        const fileName = headers.get('Content-Disposition')?.split(['"'])?.[1] || fileNameNotSplit;
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  return (
    <div className={css['Table']}>
      <div className={css['Table-export']}>
        <Button type='link' className={css['Table-export-btn']} onClick={exportTable} loading={exportLoading}>
          <HiDocumentDownload className={css['Table-export-icon']} />
          {t('exportTable')}
        </Button>
      </div>
      {/* Блок сортировки + кнопка */}
      <div className={css['Table-header']}>
        <div className={css['Table-header-sort']}>
          <span className={css['Table-header-sort-text']}>{t('sort')}</span>
          <span
            className={classNames(
              css['Table-header-sort-action'],
              activeSort.new === true && css['Table-header-sort-active']
            )}
            onClick={sortNew}
          >
            {t('newSort')}
          </span>
          <span className={css['Table-header-sort-vertical']}></span>
          <span
            className={classNames(
              css['Table-header-sort-action'],
              activeSort.old === true && css['Table-header-sort-active']
            )}
            onClick={sortOld}
          >
            {t('oldSort')}
          </span>
        </div>
      </div>
      {/* конец блока сортировки + кнопка */}

      <div className={css['Table-content']}>
        <Table
          columns={getColumns()}
          dataSource={getData()}
          pagination={pagination}
          onChange={onChangeTable}
          rowKey={e => e.orderId}
          loading={isLoadingTable}
        />
      </div>

      {isOrder.open && <ModalOrder isOrder={isOrder} onClose={() => setIsOrder({ ...isOrder, open: false })} />}
    </div>
  );
};

TableOrders.propTypes = {
  setIsSort: PropTypes.func,
  activeSort: PropTypes.object,
  setActiveSort: PropTypes.func,
  isSort: PropTypes.string,
};

export default TableOrders;
