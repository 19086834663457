import React from 'react';

import { Button, Flex, Typography } from 'antd';
import { AuditOutlined, BulbOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import css from '../TopicDetailsDialog.module.scss';

const EventsActions = props => {
  const { t } = props;

  const { Title } = Typography;

  return (
    <>
      <Flex align='middle' gap={20} className={css['DetailsModal-events-actions-container']}>
        <Title level={2} className={css['DetailsModal-events-actions-title']}>
          {t('events')}
        </Title>
        {props.withEvents && (
          <Button onClick={props.showEventCreateDialog} disabled={props.eventBtn} type='primary'>
            {t('createEvent')}
          </Button>
        )}
        <Button onClick={props.showDrawerUsers}>{t('drawerUsers')}</Button>
        <div className={css['DetailsModal-events-actions-interested']}>
          <div className={css['DetailsModal-events-actions-interested-info']}>
            <div className={css['DetailsModal-events-actions-interested-element']}>
              <BulbOutlined className={css['DetailsModal-events-actions-interested-icon']} />
              <Typography.Text>
                {t('interestedUsers', { count: props?.countWaitingUsers?.interestedUsers || 0 })}
              </Typography.Text>
            </div>
            <div className={css['DetailsModal-events-actions-interested-element']}>
              <AuditOutlined className={css['DetailsModal-events-actions-interested-icon']} />
              <Typography.Text>
                {t('waitingUsers', { count: props?.countWaitingUsers?.waitingListUsers || 0 })}
              </Typography.Text>
            </div>
          </div>
          <Button
            type='primary'
            className={css['DetailsModal-events-actions-interested-button']}
            onClick={props.showWaitingList}
          >
            {t('interestedUsersButton')}
          </Button>
        </div>
      </Flex>
    </>
  );
};

EventsActions.propTypes = {
  t: PropTypes.func,
  showEventCreateDialog: PropTypes.func,
  onlyEvent: PropTypes.bool,
  eventBtn: PropTypes.bool,
  showDrawerUsers: PropTypes.func,
  showWaitingList: PropTypes.func,
};

export default EventsActions;
