import { Modal } from 'antd';

import css from './ImageInput.module.scss';

const ImageModal = ({ imageSrc, visible, onCancel }) => (
  <Modal open={visible} footer={null} destroyOnClose maskClosable onCancel={onCancel} width={650}>
    <div className={css['ImageInput-modal-block']}>
      <img className={css['ImageInput-modal-block-image']} src={imageSrc} alt='' />
    </div>
  </Modal>
);

export default ImageModal;
