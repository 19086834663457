import React, { forwardRef, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProfileCustomFieldList } from '@/store/pcfields/actions';

import { selectFields } from '@/store/pcfields/selectors';

import { Button, Form, Select, Typography } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';

import Inputs from './parts/Inputs';
import { toggleCollapsible } from './utils';

import cn from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import style from './style.module.scss';

const CustomFields = forwardRef(({ withLabels = true, ...props }, ref) => {
  const dispatch = useDispatch();
  const contentRef = useRef();
  const arrowRef = useRef();

  const { t } = useTranslation('MVFilters');
  const customFields = useSelector(selectFields);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getProfileCustomFieldList(false, true));
  }, []);

  const resizeBlockWithAction = action => {
    action && action();
    toggleCollapsible(contentRef.current, arrowRef.current, true);
  };

  const handleChangeValues = debounce((currentValues, allValues) => {
    props.onValuesChange(currentValues, allValues);
  }, 500);

  return (
    <Form
      form={form}
      initialValues={{ customFields: [{}] }}
      onValuesChange={handleChangeValues}
      ref={ref}
      className={style['custom-fields']}
      layout='vertical'
    >
      <div
        className={style['custom-fields__header']}
        onClick={() => toggleCollapsible(contentRef.current, arrowRef.current)}
      >
        <Typography.Text className={style['custom-fields__text']}>{t('customFields')}</Typography.Text>
        <DownOutlined ref={arrowRef} className={style['custom-fields__icon']} />
      </div>
      <Form.List name='customFields'>
        {(fields, { add, remove }) => (
          <div className={style['custom-fields__content']} ref={contentRef}>
            {fields.map(({ key, name, ...restField }) => (
              <React.Fragment key={key}>
                <div className={style['custom-fields__box']}>
                  <Form.Item
                    {...restField}
                    name={[name, 'customFieldId']}
                    label={withLabels ? t('customFieldLabel') : ''}
                  >
                    <Select
                      className={style['custom-fields__select']}
                      placeholder={t('customFieldPlaceholder')}
                      options={customFields.fields.map(field => ({ label: field.name, value: field.id }))}
                    />
                  </Form.Item>
                  <DeleteOutlined
                    className={cn(style['custom-fields__icon'], style['custom-fields__icon--danger'])}
                    onClick={() => resizeBlockWithAction(() => remove(name), true)}
                  />
                </div>
                <div className={style['custom-fields__inputs']}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.customFields?.[name]?.customFieldId !==
                      currentValues.customFields?.[name]?.customFieldId
                    }
                  >
                    {() => {
                      resizeBlockWithAction(null);
                      return (
                        <Inputs
                          customFieldId={form.getFieldValue(['customFields', name, 'customFieldId'])}
                          restField={restField}
                          name={name}
                          form={form}
                        />
                      );
                    }}
                  </Form.Item>
                </div>
              </React.Fragment>
            ))}
            <Form.Item>
              <Button type='primary' onClick={() => resizeBlockWithAction(() => add())}>
                {t('addCustomField')}
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </Form>
  );
});

CustomFields.propTypes = {
  onValuesChange: PropTypes.func,
  formValues: PropTypes.object,
};

export default memo(CustomFields);
