export const SAVE_MENTEE_FILTERS = 'SAVE_MENTEE_FILTERS';

export const CLEAR_MENTEE_FILTERS = 'CLEAR_MENTEE_FILTERS';

export const GET_MENTEE_START = 'GET_MENTEE_START';
export const GET_MENTEE_SUCCESS = 'GET_MENTEE_SUCCESS';
export const GET_MENTEE_FAIL = 'GET_MENTEE_FAIL';

export const GET_STATUS_START = 'GET_STATUS_START';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL';

export const POST_MENTEE_FILTERS_START = 'POST_MENTEE_FILTERS_START';
export const POST_MENTEE_FILTERS_SUCCESS = 'POST_MENTEE_FILTERS_SUCCESS';
export const POST_MENTEE_FILTERS_FAIL = 'POST_MENTEE_FILTERS_FAIL';

export const CHANGE_STATUS_START = 'CHANGE_STATUS_START';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAIL = 'CHANGE_STATUS_FAIL';

export const EXPORT_MENTEE_START = 'EXPORT_MENTEE_START';
export const EXPORT_MENTEE_SUCCESS = 'EXPORT_MENTEE_SUCCESS';
export const EXPORT_MENTEE_FAIL = 'EXPORT_MENTEE_FAIL';
