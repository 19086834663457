/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { List } from 'antd';

import FieldItemFileItem from './FieldItemFileItem';

import PropTypes from 'prop-types';

import css from './TopicDetailsDialog.module.scss';

const isFieldEmpty = field => {
  if (typeof field === 'boolean') {
    return false;
  }
  if (Array.isArray(field)) {
    return !field.length;
  }
  return !field;
};
export class FieldItemFile extends Component {
  static propTypes = {
    field: PropTypes.object,
    getFile: PropTypes.func,
    value: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      maxDisplayItems: 3, // Максимально показанное число файлов, можно при желании поменять
      expanded: false,
      files: this.props.value,
    };
  }

  showMore = () => {
    if (this.props.value.length != 0) {
      this.setState({ expanded: !this.state.expanded });
      !this.state.expanded
        ? this.setState({ maxDisplayItems: this.state.files.length })
        : this.setState({ maxDisplayItems: 3 });
    }
  };

  render() {
    const { value, field } = this.props;
    if (isFieldEmpty(value)) {
      return null;
    }
    return (
      <div className={css['DetailsModal-card-item']}>
        <div className={css['DetailsModal-label']}>{field.label}</div>
        <List
          className={css['DetailsModal-list']}
          dataSource={value.slice(0, this.state.maxDisplayItems)}
          loadMore={
            value.length > 3 && (
              <div onClick={this.showMore} className={css['DetailsModal-list-load-more']}>
                {this.state.expanded ? 'Скрыть' : 'Показать все'}
              </div>
            )
          }
          renderItem={file =>
            Array.isArray(this.props.value) && (
              <FieldItemFileItem key={file.uuid} uuid={file.uuid} getFile={this.props.getFile} name={file.name} />
            )
          }
        />
      </div>
    );
  }
}
export default FieldItemFile;
