/* eslint-disable */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';

import { Button, Modal, Tag } from 'antd';

import ThemesEditor from '../ThemesEditor';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ThemesInput.module.scss';

class ThemesInput extends Component {
  static propTypes = {
    isThemes: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.array,
    error: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isSelectThemes: false,
      selectThemes: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selectThemes: this.props.value });
    }
  }

  toggleSelectThemes = () =>
    this.setState({
      isSelectThemes: !this.state.isSelectThemes,
    });

  updateCheckableElems = selectThemes =>
    this.setState({
      selectThemes,
    });

  selectThemes = params => {
    this.toggleSelectThemes();
    return this.props.onChange(params);
  };

  render() {
    const { name, value, t } = this.props;
    const { selectThemes } = this.state;
    const renderThemes = value
      ? value.map(theme => (
          <Tag key={theme.id} tag={theme} className={css['ThemesInput-tag']}>
            {theme.name}
          </Tag>
        ))
      : null;

    return (
      <div>
        <Button
          data-qa='compilationThemesBtn'
          ghost
          onClick={this.toggleSelectThemes}
          className={classNames({ [css.error]: this.props.error })}
        >
          {t('theme')}
        </Button>
        {renderThemes}
        {this.state.isSelectThemes && (
          <Modal open={this.state.isSelectThemes} onCancel={this.toggleSelectThemes} footer={null} width={600}>
            <div className={css['ThemesInput-container-themes']}>
              <h1 className={css['ThemesInput-h1']}>{t('themeChoose')}</h1>
              <Scrollbars
                style={{
                  width: '100%',
                  height: '60vh',
                  marginBottom: -10,
                }}
                renderThumbHorizontal={({ style, props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,

                      height: 6,
                      borderRadius: 10,
                      backgroundColor: '#ccc',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                    }}
                  />
                )}
                hideTracksWhenNotNeeded
              >
                <ThemesEditor
                  dataQa='compilationThemeList'
                  isCheckable
                  themesUsed={value}
                  updateCheckableElems={this.updateCheckableElems}
                  className={css['ThemesInput-theme-edit']}
                />
              </Scrollbars>
            </div>
            <div>
              <Button
                data-qa='compilationSaveThemesBtn'
                onClick={() => this.selectThemes({ name, value: selectThemes })}
                disabled={!this.state.selectThemes.length}
                size='large'
                type='primary'
              >
                {t('choose')}
              </Button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation('themesInput')(ThemesInput);
