/* eslint-disable */
import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';

import { ThemeProvider } from './ThemeContext';

import { ServiceWorkerProvider } from '@shared/context/ServiceWorkerContext';

import store from '@/store';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const Root = () => {
  return (
    <ConfigProvider locale={locale}>
      <ServiceWorkerProvider>
        <Provider store={store}>
          <ThemeProvider />
        </Provider>
      </ServiceWorkerProvider>
    </ConfigProvider>
  );
};

export default Root;
