import {
  CHANGE_STATUS_FAIL,
  CHANGE_STATUS_START,
  CHANGE_STATUS_SUCCESS,
  CLEAR_MENTORS_FILTERS,
  CREATE_MENTOR_FAIL,
  CREATE_MENTOR_START,
  CREATE_MENTOR_SUCCESS,
  EXPORT_MENTORS_FAIL,
  EXPORT_MENTORS_SETTINGS_FAIL,
  EXPORT_MENTORS_SETTINGS_START,
  EXPORT_MENTORS_SETTINGS_SUCCESS,
  EXPORT_MENTORS_START,
  EXPORT_MENTORS_SUCCESS,
  GET_COUNT_STAT_FAIL,
  GET_COUNT_STAT_START,
  GET_COUNT_STAT_SUCCESS,
  GET_MENTEE_FAIL,
  GET_MENTEE_START,
  GET_MENTEE_SUCCESS,
  GET_MENTOR_STATUS_FAIL,
  GET_MENTOR_STATUS_SUCCESS,
  GET_MENTORS_FAIL,
  GET_MENTORS_START,
  GET_MENTORS_STATUS_START,
  GET_MENTORS_SUCCESS,
  GET_SETTINGS_COUNT_MENTORS_FAIL,
  GET_SETTINGS_COUNT_MENTORS_START,
  GET_SETTINGS_COUNT_MENTORS_SUCCESS,
  GET_SETTINGS_LIMIT_MENTORS_FAIL,
  GET_SETTINGS_LIMIT_MENTORS_START,
  GET_SETTINGS_LIMIT_MENTORS_SUCCESS,
  GET_SETTINGS_MENTORS_FAIL,
  GET_SETTINGS_MENTORS_START,
  GET_SETTINGS_MENTORS_SUCCESS,
  POST_MENTORS_DATA_FAIL,
  POST_MENTORS_DATA_START,
  POST_MENTORS_DATA_SUCCESS,
  POST_MENTORS_FILTERS_FAIL,
  POST_MENTORS_FILTERS_START,
  POST_MENTORS_FILTERS_SUCCESS,
  PUT_SETTINGS_LIMIT_MENTORS_FAIL,
  PUT_SETTINGS_LIMIT_MENTORS_START,
  PUT_SETTINGS_LIMIT_MENTORS_SUCCESS,
  PUT_SETTINGS_MENTORS_FAIL,
  PUT_SETTINGS_MENTORS_START,
  PUT_SETTINGS_MENTORS_SUCCESS,
  SAVE_MENTORS_FILTERS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getMentors = (fullName, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/admin/name${getQueryString({ fullName })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTORS_START, GET_MENTORS_SUCCESS, GET_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const createMentor = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin/create`,
  body,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CREATE_MENTOR_START, CREATE_MENTOR_SUCCESS, CREATE_MENTOR_FAIL],
  onSuccess,
  onError,
});

export const postMentorsFilters = (params, page, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin${getQueryString({ size: 20, page })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_MENTORS_FILTERS_START, POST_MENTORS_FILTERS_SUCCESS, POST_MENTORS_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const getCountStat = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin/status/count`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [GET_COUNT_STAT_START, GET_COUNT_STAT_SUCCESS, GET_COUNT_STAT_FAIL],
  onSuccess,
  onError,
});

export const getMenteeForMentor = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/admin/mentee${getQueryString({ id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTEE_START, GET_MENTEE_SUCCESS, GET_MENTEE_FAIL],
  onSuccess,
  onError,
});

export const getMentorsStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/admin/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTORS_STATUS_START, GET_MENTOR_STATUS_SUCCESS, GET_MENTOR_STATUS_FAIL],
  onSuccess,
  onError,
});

export const changeMentorsStatus = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/admin/status/update`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CHANGE_STATUS_START, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAIL],
  onSuccess,
  onError,
});

export const createSettingsMentors = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/admin/visibility`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_SETTINGS_MENTORS_START, PUT_SETTINGS_MENTORS_SUCCESS, PUT_SETTINGS_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const getSettingsMentors = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/admin/visibility`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SETTINGS_MENTORS_START, GET_SETTINGS_MENTORS_SUCCESS, GET_SETTINGS_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const getSettingsCountMentors = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin/visibility/count`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [GET_SETTINGS_COUNT_MENTORS_START, GET_SETTINGS_COUNT_MENTORS_SUCCESS, GET_SETTINGS_COUNT_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const exportMentors = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_MENTORS_START, EXPORT_MENTORS_SUCCESS, EXPORT_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const exportSettingsMentors = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin/export/by-custom-fields`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_MENTORS_SETTINGS_START, EXPORT_MENTORS_SETTINGS_SUCCESS, EXPORT_MENTORS_SETTINGS_FAIL],
  onSuccess,
  onError,
});

export const updateMentorsData = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/admin/update`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_MENTORS_DATA_START, POST_MENTORS_DATA_SUCCESS, POST_MENTORS_DATA_FAIL],
  onSuccess,
  onError,
});

export const setLimitSettings = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/admin/company/settings`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_SETTINGS_LIMIT_MENTORS_START, PUT_SETTINGS_LIMIT_MENTORS_SUCCESS, PUT_SETTINGS_LIMIT_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const getLimitSettings = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/company/settings`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SETTINGS_LIMIT_MENTORS_START, GET_SETTINGS_LIMIT_MENTORS_SUCCESS, GET_SETTINGS_LIMIT_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const saveMentorsFilters = value => ({
  type: SAVE_MENTORS_FILTERS,
  value,
});

export const clearMentorsFilters = value => ({
  type: CLEAR_MENTORS_FILTERS,
  value,
});
