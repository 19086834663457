/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getChannels, getMessageGroup, getTemplateById, getTemplates, getThemes } from '@/store/messages/actions';

import { selectMessages } from '@/store/messages/selectors';

import { Button, Col, Form, message, Modal, Row, Select, Spin, Table, Tag, Tooltip } from 'antd';
import { Content } from '@/components/Layout';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloudSyncOutlined,
  EditOutlined,
  InfoCircleTwoTone,
  MailOutlined,
  NotificationOutlined,
  SendOutlined,
} from '@ant-design/icons';

import MessageDetails from './parts/MessageDetails/MessageDetails';

import Utils from '@/Utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import css from './Messages.module.scss';

const DEFAULT_FILTERS = {
  channels: undefined,
  themes: undefined,
  messageGroup: undefined,
  sort: undefined,
};
const MAX_THEME_LENGTH = 75;

const { Option } = Select;

const Messages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('tabMessages');
  const {
    channels,
    templates,
    themes,
    pagination,
    loadingTemplates,
    loadingThemes,
    loadingChannels,
    loadingMessageGroup,
    messageGroup,
  } = useSelector(selectMessages);

  const [messageApi, contextMessage] = message.useMessage();

  const [modal, contextHolder] = Modal.useModal();

  const [isMessageDetailsLoading, setIsMessageDetailsLoading] = useState(false);

  const [groupFilterValue, setGroupFilterValue] = useState();
  const [groupFilter, setGroupFilter] = useState({
    themes: undefined,
  });

  const [filters, setFilters] = useState({
    channels: undefined,
    themes: undefined,
    sort: undefined,
  });

  const [details, setDetails] = useState({
    open: false,
    template: {},
  });

  useEffect(() => {
    dispatch(getChannels());
    dispatch(getThemes());
    dispatch(getMessageGroup());
  }, []);

  useEffect(() => {
    if (!!groupFilter?.themes && !filters?.themes?.length && !!groupFilterValue) {
      dispatch(getTemplates({ ...filters, ...groupFilter }));
    } else {
      dispatch(getTemplates(filters));
    }
  }, [filters, groupFilter]);

  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS);
    setGroupFilterValue(null);
    setGroupFilter({ themes: undefined });
    dispatch(getThemes());
  };

  const onClickDetails = template => {
    setIsMessageDetailsLoading(true);
    dispatch(
      getTemplateById(
        template?.messageTemplateId,
        () => {
          setDetails({
            open: true,
            template,
          });
          setIsMessageDetailsLoading(false);
        },
        () => {
          messageApi.open({
            type: 'error',
            content: t('errorGetTemplate'),
          });
          setIsMessageDetailsLoading(false);
        }
      )
    );
  };

  const onCloseDetails = (withWarning = true) => {
    if (withWarning) {
      modal.confirm({
        title: t('warning'),
        content: t('closeWarning'),
        onOk: () => {
          setDetails({
            open: false,
            template: {},
          });
        },
      });
    } else {
      setDetails({
        open: false,
        template: {},
      });
    }
  };

  const renderChannels = item => {
    if (Array.isArray(item) && item?.length > 0) {
      return item.map(type => {
        switch (type.channelType) {
          case 'MAIL':
            return (
              <Tooltip key={type?.id} title={type?.localizedName}>
                <MailOutlined className={css['MessagesTable-icons-mail']} />
              </Tooltip>
            );
          case 'INTERNAL':
            return (
              <Tooltip key={type?.id} title={type?.localizedName}>
                <NotificationOutlined className={css['MessagesTable-icons-notification']} />
              </Tooltip>
            );
          case 'TELEGRAM':
            return (
              <Tooltip key={type?.id} title={type?.localizedName}>
                <SendOutlined className={css['MessagesTable-icons-send']} />
              </Tooltip>
            );

          case 'BITRIX':
            return (
              <Tooltip key={type?.id} title={type?.localizedName}>
                <CloudSyncOutlined className={css['MessagesTable-icons-send']} />
              </Tooltip>
            );

          default:
            break;
        }
      });
    } else {
      return <Tag>{t('disabled')}</Tag>;
    }
  };

  const dataSource = useMemo(
    () =>
      !isEmpty(templates) &&
      templates?.map(template => {
        return {
          key: template?.messageTemplateId,
          description: template?.description,
          theme: template?.theme,
          action: template,
          channels: template.channels,
          lastUpdateAt: template?.lastUpdatedAt,
          lastUpdateBy: template.canUpdate ? template?.lastUpdatedBy : '',
        };
      }),
    [templates]
  );

  const columns = [
    {
      dataIndex: 'description',
      key: 'description',
      width: 50,
      align: 'center',
      render: text => {
        if (text) {
          return (
            <Tooltip title={text}>
              <InfoCircleTwoTone />
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: t('themes'),
      dataIndex: 'theme',
      key: 'theme',
      width: 650,
      render: text => (
        <Tooltip title={text.length > MAX_THEME_LENGTH && text}>{Utils.trString(text, MAX_THEME_LENGTH)}</Tooltip>
      ),
    },
    {
      title: t('choosedChannels'),
      dataIndex: 'channels',
      key: 'channels',
      render: (text, item) => renderChannels(text, item),
      align: 'center',
    },
    {
      title: t('lastUpdateAt'),
      dataIndex: 'lastUpdateAt',
      key: 'lastUpdateAt',
      align: 'center',
      width: '10%',
    },
    {
      title: t('lastUpdateBy'),
      dataIndex: 'lastUpdateBy',
      key: 'lastUpdateBy',
      align: 'center',
      width: '15%',
    },
    {
      title: t('action'),
      key: 'action',
      align: 'center',
      render: ({ action }) => (
        <Button type='primary' shape='circle' onClick={() => onClickDetails(action)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const themeOptions = useMemo(() => {
    const uniqueThemes = themes?.filter(
      (obj, idx, arr) => idx === arr.findIndex(t => t.localizationId === obj.localizationId)
    );
    return (
      !isEmpty(uniqueThemes) &&
      uniqueThemes?.map(theme => (
        <Option key={theme?.localizationId} value={theme?.localizationId}>
          {theme?.localizedName}
        </Option>
      ))
    );
  }, [themes]);

  const channelOptions = useMemo(
    () =>
      !isEmpty(channels) &&
      channels?.map(channel => (
        <Option key={channel?.id} value={channel?.id}>
          {channel?.localizedName}
        </Option>
      )),
    [channels]
  );

  const messageGroupOptions = useMemo(
    () =>
      !isEmpty(messageGroup) &&
      messageGroup?.map(group => (
        <Option key={group?.value} value={group?.value}>
          {t('filterBy' + group?.value)}
        </Option>
      )),
    [messageGroup]
  );

  const onChangePagination = pagination => {
    dispatch(getTemplates(filters, { page: pagination.current - 1 }));
  };

  const changeMessageGroup = value => {
    setGroupFilterValue(value);
    dispatch(
      getThemes(value, res => {
        const groupThemes = res.map(item => item.localizationId);
        setGroupFilter({ ...groupFilter, themes: groupThemes });
      })
    );
  };

  const sortBlock = (
    <div className={css['MessagesFilter-sort']}>
      <span className={css['MessagesFilter-sort-text']}>{t('sort')}</span>
      <span>{t('sortDate')}</span>
      <span
        className={classNames(
          css['MessagesFilter-sort-action'],
          filters.sort === 'lastModifiedDate,asc' && css['MessagesFilter-sort-active']
        )}
        onClick={() => setFilters({ ...filters, sort: 'lastModifiedDate,asc' })}
      >
        <ArrowUpOutlined />
      </span>
      <span
        className={classNames(
          css['MessagesFilter-sort-action'],
          filters.sort === 'lastModifiedDate,desc' && css['MessagesFilter-sort-active']
        )}
        onClick={() => setFilters({ ...filters, sort: 'lastModifiedDate,desc' })}
      >
        <ArrowDownOutlined />
      </span>
    </div>
  );

  return (
    <Content className={css['Messages']}>
      <Spin spinning={isMessageDetailsLoading} fullscreen />

      {contextMessage}
      {/* Модальное окно confirm */}
      {contextHolder}

      <h1>{t('title')}</h1>

      {details.open && (
        <MessageDetails open title={details.template?.theme} onClose={onCloseDetails} filters={filters} />
      )}

      <div className={css['MessagesFilter-container']}>
        <div className={css['MessagesFilter-title-container']}>
          <span className={css['MessagesFilter-title']}>{t('filters')}</span>
          <Button type='link' onClick={resetFilters} className={css['MessagesFilter-reset']}>
            {t('reset')}
          </Button>
        </div>

        <Row gutter={20}>
          <Col span={8}>
            <Form layout='vertical'>
              <Form.Item label={t('messageGroup')} colon={false} className={css['MessagesFilter-form']}>
                <Select
                  loading={loadingMessageGroup}
                  allowClear
                  showSearch
                  onChange={changeMessageGroup}
                  placeholder={t('messageGroupPlaceholder')}
                  className={css['MessagesFilter-select']}
                  optionFilterProp='children'
                  value={groupFilterValue}
                >
                  {messageGroupOptions}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <Form layout='vertical'>
              <Form.Item label={t('themes')} colon={false} className={css['MessagesFilter-form']}>
                <Select
                  loading={loadingThemes}
                  allowClear
                  onChange={e => setFilters({ ...filters, themes: e })}
                  placeholder={t('themesPlaceholder')}
                  className={css['MessagesFilter-select']}
                  mode='multiple'
                  optionFilterProp='children'
                  value={filters.themes}
                >
                  {themeOptions}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <Form layout='vertical'>
              <Form.Item label={t('channel')} colon={false} className={css['MessagesFilter-form']}>
                <Select
                  loading={loadingChannels}
                  allowClear
                  onChange={e => setFilters({ ...filters, channels: e })}
                  placeholder={t('channelPlaceholder')}
                  className={css['MessagesFilter-select']}
                  mode='multiple'
                  optionFilterProp='children'
                  value={filters.channels}
                >
                  {channelOptions}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>

      <div className={css['MessagesTable-container']}>
        {sortBlock}
        <Table
          columns={columns}
          dataSource={dataSource || []}
          loading={loadingTemplates}
          pagination={{
            pageSize: pagination.size,
            current: pagination.currentPage + 1,
            total: pagination.totalElements,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          onChange={onChangePagination}
          rowKey={e => e?.key}
          bordered
        />
      </div>
    </Content>
  );
};

export default Messages;
