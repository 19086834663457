/* eslint-disable */
import React from 'react';

import { Form } from 'antd';
import Checkbox from '@/components/Checkbox';
import CustomInput from '@/components/Input';
import Select from '@/components/Select';
import TagsInput from '@/components/TagsInput';

import classNames from 'classnames';

import css from './CompanyCreationDialog.module.scss';

export const Registration = ({ company, handleChange, t }) => {
  return (
    <>
      <div className={css['Add-modal']}>
        <div className={css['Add-modal-title']}>{t('registration')}</div>

        {/* ТИП АВТОРИЗАЦИИ */}
        <Form.Item label={t('type')} labelAlign='left'>
          <Select
            className={classNames(css.TopicCreate)}
            disabled
            name='provider.type'
            options={[{ name: 'Email' }]}
            value={'Email'}
            white
          />
        </Form.Item>

        {/* ПЛЕЙСХОЛДЕР В ЛОГИНЕ */}
        <Form.Item label={t('placeholder')} labelAlign='left'>
          <CustomInput
            name='provider.usernameAlias'
            placeholder={t('description')}
            onChange={handleChange}
            value={company.provider.usernameAlias}
            maxLength={50}
          />
        </Form.Item>

        {/* РАЗРЕШЕННЫЕ ДОМЕНЫ */}
        <Form.Item label={t('domains')} labelAlign='left'>
          <TagsInput
            onChange={handleChange}
            name='provider.domains'
            placeholder={t('descriptionDomains')}
            value={company.provider.domains}
          />
        </Form.Item>
      </div>
      {!company.id && (
        <div className={css['Add-modal']}>
          <div className={css['Add-modal-title']}>{t('once')}</div>
          <Form.Item label={t('copyCompetencies')} labelAlign='left'>
            <Checkbox onChange={handleChange} name='copyGlobalCategories' value={company.copyGlobalCategories} />
          </Form.Item>
          <Form.Item label={t('copySpecializations')} labelAlign='left'>
            <Checkbox onChange={handleChange} name='copyGlobalCareers' value={company.copyGlobalCareers} />
          </Form.Item>
          <Form.Item label={t('copyURL')} labelAlign='left'>
            <Checkbox onChange={handleChange} name='copyGlobalFooter' value={company.copyGlobalFooter} />
          </Form.Item>
          <Form.Item label={t('copyFAQ')} labelAlign='left'>
            <Checkbox onChange={handleChange} name='copyGlobalFaq' value={company.copyGlobalFaq} />
          </Form.Item>
        </div>
      )}
    </>
  );
};
