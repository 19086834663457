/* eslint-disable */
// модальное окно для добавления в план (вручную)
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Spin } from 'antd';

import MonthList from '../MonthList';
import ScrollArrow from '../ScrollArrow';
import DayCard from './DayCard';
import MonthCard from './MonthCard';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

import Arrow from '@/assets/images/blue-arrow-up.svg';

class CalendarTableModal extends Component {
  static propTypes = {
    courses: PropTypes.bool,
    dateEvent: PropTypes.number,
    disabledSelect: PropTypes.bool,
    hasEvents: PropTypes.number,
    hasEventsFlag: PropTypes.bool,
    headerFixToModal: PropTypes.bool,
    isLoadingPlan: PropTypes.bool,
    noComment: PropTypes.bool,
    noEvent: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    openDate: PropTypes.any,
    planInfo: PropTypes.object,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    selectMonthEvents: PropTypes.bool,
    title: PropTypes.any,
    toPrev: PropTypes.func,
    open: PropTypes.bool,
    visibleModalData: PropTypes.bool,
    loadingStatus: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.workDate =
      !props.openDate || dayjs().add(5, 'day').isAfter(dayjs(props.openDate))
        ? dayjs().add(5, 'day').format('YYYY-MM-DD')
        : dayjs(props.openDate).format('YYYY-MM-DD');

    this.currentYear = this.props.noEvent
      ? Number(dayjs().format('YYYY'))
      : Number(dayjs(this.workDate).format('YYYY'));

    this.state = {
      monthListVisible: true,
      dayListVisible: false,
      selectedYear: this.currentYear,
      executiveDate: null,
      selectedMonths: !props.disabledSelect ? [] : [dayjs(props.dateEvent).format('YYYY-MM-DD')],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && prevProps.open !== this.props.open) {
      this.setState({
        selectedYear: this.currentYear,
        comment: '',
        selectedMonths: !this.props.disabledSelect ? [] : [dayjs(props.dateEvent).format('YYYY-MM-DD')],
      });
    }
  }

  handleDayClick = day => {
    const arr = [];
    arr.push(dayjs(this.state.selectedMonths.toString()).set('date', dayjs(day).format('DD')).format('YYYY-MM-DD'));
    this.setState({ selectedMonths: arr });
  };

  handleOkClick = () => {
    const typeId = this.props.topicInfo && this.props.topicInfo.type && this.props.topicInfo.type.id;

    const { onOk, planningTopicId, rest, planInfo } = this.props;
    if (onOk) {
      onOk(planningTopicId, this.state.selectedMonths, this.state.comment, this.props.visibleModalData, rest, planInfo);
    }
  };

  handleMonthClick = month => {
    const arr = [];
    const isSelectedMonthIsCurrent = dayjs(month).format('YYYYMM') === dayjs().format('YYYYMM');
    arr.push(
      isSelectedMonthIsCurrent ? dayjs(dayjs(month).set('date', dayjs().format('DD'))).format('YYYY-MM-DD') : month
    );
    this.setState({ selectedMonths: arr });
  };

  increaseYear = () => {
    let arr = [dayjs(this.state.selectedDate).add(1, 'year').format('YYYY-MM-DD')];
    this.setState(state => ({
      selectedYear: state.selectedYear + 1,
      selectedDate: arr,
    }));
  };

  decreaseYear = () => {
    let arr = [dayjs(this.state.selectedDate).subtract(1, 'year').format('YYYY-MM-DD')];
    this.setState(state => ({
      selectedYear: state.selectedYear - 1,
      selectedDate: arr,
    }));
  };

  renderMonthCard = props => {
    return (
      <MonthCard
        {...props}
        onClick={this.handleMonthClick}
        selectedMonths={!this.props.disabledSelect ? this.state.selectedMonths : this.props.dateEvent}
        disabledSelect={this.props.disabledSelect}
      />
    );
  };

  renderPrev = (hasEventsFlag, hasEvents, toPrev) => {
    if (!toPrev) {
      return null;
    }

    if (!hasEvents || !hasEventsFlag) {
      return null;
    }

    return (
      <div className={css['Calendar-modal-toPrev']} onClick={() => toPrev({ hasEventsFlag: true })}>
        <img src={Arrow} alt='' /> {this.props.t('back')}
      </div>
    );
  };

  render() {
    const {
      okText,
      title,
      disabledSelect,
      dateEvent,
      selectMonthEvents,
      toPrev,
      hasEvents,
      hasEventsFlag,
      t,
      loadingStatus,
    } = this.props;

    const typeId = this.props.topicInfo && this.props.topicInfo.type && this.props.topicInfo.type.id;

    const titleDef =
      typeId !== 20 ? (
        <div dangerouslySetInnerHTML={{ __html: t('description3') }} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: t('description4') }} />
      );

    const year = !disabledSelect ? this.state.selectedYear.toString() : dayjs(dateEvent, 'YYYY');

    let disabledByExecutive = false;
    if (typeId == 25 && (this.state.selectedMonths === null || this.state.selectedMonths.length === 0)) {
      disabledByExecutive = true;
    }

    return (
      <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
        {loadingStatus ? (
          <div className={css['Calendar-modal-spin']}>
            <Spin size='large' spinning={loadingStatus} />
          </div>
        ) : (
          <>
            {this.renderPrev(hasEventsFlag, hasEvents, toPrev)}
            <div
              className={classNames(
                css['Calendar-modal-header'],
                css['Calendar-modal-header--ext'],
                css['Calendar-modal-header--flex']
              )}
            >
              <div>{!selectMonthEvents ? title : titleDef}</div>
              <div>
                <div className={classNames(css['Calendar-modal-year'], css['Calendar-modal-year--ext'])}>
                  <ScrollArrow
                    scrollDirection='left'
                    disabled={disabledSelect /* || this.state.selectedYear <= this.currentYear */}
                    onClick={this.decreaseYear}
                    light
                  />
                  <div className={classNames(css['Calendar-modal-year-value'], css['Calendar-modal-year--ext-value'])}>
                    {this.state.selectedYear}
                  </div>
                  <ScrollArrow scrollDirection='right' onClick={this.increaseYear} light />
                </div>
              </div>
            </div>

            <MonthList
              courses={this.props.courses}
              startDate={year}
              dateEvent={dateEvent}
              disabledSelect={disabledSelect}
              year={year}
              outputFormat='YYYY-MM-DD'
              displayFormat='MMM'
              className={css['Calendar-modal-months']}
              monthClassName={css['Calendar-modal-month-container']}
              renderMonth={this.renderMonthCard}
              open={this.state.monthListVisible}
            />

            <DayCard selectedDate={this.state.selectedMonths} onClick={this.handleDayClick} />
            <div className={css['Calendar-modal-btn']}>
              {
                // TODO: Кнопка ОК в календаре
              }
              <Button
                disabled={
                  this.props.isLoadingPlan ||
                  !this.state.selectedMonths ||
                  this.state.selectedMonths.length === 0 ||
                  disabledByExecutive
                }
                data-qa='confirmAddToPanBtn'
                size='large'
                type='primary'
                onClick={this.handleOkClick}
              >
                {okText}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation('calendarModal')(CalendarTableModal);
