import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createGroups, getGroups } from '@/store/groups/actions';

import { selectGroups } from '@/store/groups/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, message, Typography } from 'antd';
import { Content } from '@/components/Layout';

import { PAGE_SIZE } from './constants';
import FilterGroups from './Content/FilterGroups';
import ModalGroup from './Content/ModalGroup';
import TableGroups from './Content/TableGroups';

import css from './CompetenceGroupsDirectory.module.scss';

import { COMPETENCE_GROUP_CREATE } from '@/constants/permissions';

const { Title } = Typography;

const CompetenceGroupsDirectory = () => {
  const { t } = useTranslation('CompetenceGroupsDirectory');
  const dispatch = useDispatch();

  const { groupsCurrentPage, groupsFilters } = useSelector(selectGroups);
  const { currentUser } = useSelector(selectUsers);

  const [isModal, setIsModal] = useState(false);

  const onCreateGroup = data => {
    if (data?.name) {
      dispatch(
        createGroups(data, () => {
          setIsModal(false);
          dispatch(getGroups(groupsFilters, groupsCurrentPage, PAGE_SIZE));
        })
      );
    } else {
      message.warning(t('warningCreateGroup'));
    }
  };

  useEffect(() => {
    dispatch(getGroups('', 0, PAGE_SIZE));
  }, []);

  return (
    <Layout>
      <Content>
        <div className={css['CompetenceGroups']}>
          <div className={css['CompetenceGroups-header']}>
            <Title>{t('title')}</Title>
            {currentUser?.authorities?.includes(COMPETENCE_GROUP_CREATE) && (
              <div>
                <Button onClick={() => setIsModal(true)} size='large' type='primary'>
                  {t('addGroup')}
                </Button>
              </div>
            )}
          </div>

          <FilterGroups />
          <TableGroups />
        </div>
      </Content>

      {isModal && (
        <ModalGroup
          open={isModal}
          onCancel={() => setIsModal(false)}
          title={t('createTitle')}
          onOk={onCreateGroup}
          onOkText={t('createBtn')}
        />
      )}
    </Layout>
  );
};

export default CompetenceGroupsDirectory;
