import { GET_POSITION_INTERNAL_FAIL, GET_POSITION_INTERNAL_START, GET_POSITION_INTERNAL_SUCCESS } from './constants';

const initialState = {
  positions: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_POSITION_INTERNAL_SUCCESS:
      return { ...state, positions: response.content };

    case GET_POSITION_INTERNAL_FAIL:
    case GET_POSITION_INTERNAL_START:
      return { ...state };

    default:
      return state;
  }
};
