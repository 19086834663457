import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { getOrdersStatus } from '@/store/order/actions';
import { getDelivery } from '@/store/product/actions';
import { getPosition } from '@/store/students/actions';

import { Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import Scores from '@/components/ScoresShop';

import Filters from './Content/Filters';
import TableOrders from './Content/TableOrders';

import css from './Orders.module.scss';

const { Title } = Typography;

const Orders = () => {
  const { t } = useTranslation('orders');
  const dispatch = useDispatch();

  const [isSort, setIsSort] = useState('');

  const [activeSort, setActiveSort] = useState({
    new: false,
    old: false,
  });

  const [data, setData] = useState({
    subCompanyName: '',
    deliveryIds: {
      ids: [],
      name: '',
    },
    positionName: '',
    productId: {
      ids: [],
      name: '',
    },
    userId: {
      ids: [],
      name: '',
    },
    login: '',
    status: {
      value: '',
      name: '',
    },
  });

  useEffect(() => {
    dispatch(getDelivery());
    dispatch(getOrdersStatus());
    dispatch(getPosition());
    dispatch(getSubcompanies());
  }, [dispatch]);

  return (
    <Layout>
      <Content>
        <div className={css['Orders']}>
          <div className={css['Orders-header']}>
            <Title>{t('title')}</Title>
          </div>
          <Scores />
          <div className={css['Orders-main']}>
            <Filters
              setData={setData}
              data={data}
              isSort={isSort}
              setIsSort={setIsSort}
              setActiveSort={setActiveSort}
            />
            <TableOrders setIsSort={setIsSort} activeSort={activeSort} setActiveSort={setActiveSort} isSort={isSort} />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Orders;
