import { GET_IMPORTUSERS_FAIL, GET_IMPORTUSERS_START, GET_IMPORTUSERS_SUCCESS } from './constants';

const initialState = {};

/* eslint-disable */
export default (state = initialState, { type }) => {
  switch (type) {
    case GET_IMPORTUSERS_START:
    case GET_IMPORTUSERS_SUCCESS:
    case GET_IMPORTUSERS_FAIL:
    default:
      return state;
  }
};
