import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { getDepartment, getFunction, getPosition } from '@/store/students/actions';
import { getStatusTracks, postAppointFilters } from '@/store/tracks/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectTracks } from '@/store/tracks/selectors';

import { Divider } from 'antd';

import FiltersAppointments from './FiltersAppointments';
import TableAppointments from './TableAppointments';

import css from '../TrackDetail.module.scss';

const TabAppointments = () => {
  const dispatch = useDispatch();

  const { trackDetails, progress, statuses } = useSelector(selectTracks);
  const { subCompanies } = useSelector(selectCompanies);
  const { functionStudent, department, position } = useSelector(selectStudents);

  const [isSort, setIsSort] = useState('');
  const [activeSort, setActiveSort] = useState({
    date: false,
    lastName: {
      aZ: false,
      zA: false,
    },
  });

  useEffect(() => {
    if (!position) dispatch(getPosition());
    if (!functionStudent) dispatch(getFunction());
    if (!subCompanies.length) dispatch(getSubcompanies());
    if (!department) dispatch(getDepartment());
    if (!statuses.length) dispatch(getStatusTracks());

    if (trackDetails?.id && progress?.length === 0) {
      dispatch(postAppointFilters(trackDetails.id, {}, 0));
    }
  }, [dispatch]);

  return (
    <div className={css['Track-details-tabs-appoint']}>
      <FiltersAppointments isSort={isSort} setIsSort={setIsSort} setActiveSort={setActiveSort} />
      <Divider />
      <TableAppointments setIsSort={setIsSort} activeSort={activeSort} setActiveSort={setActiveSort} isSort={isSort} />
    </div>
  );
};

export default TabAppointments;
