/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import PropTypes from 'prop-types';

const TestDetailsModal = ({ testItemData, closeModal, children }) => {
  const { t } = useTranslation('result');

  return (
    <Modal
      title={`${testItemData.testStatistic.fullName} - ${testItemData.testStatistic.blockName}`}
      open={testItemData.isModalOpen}
      onOk={closeModal}
      onCancel={closeModal}
      width={1400}
      cancelText={t('cancel')}
      okText={t('ok')}
    >
      {children}
    </Modal>
  );
};

TestDetailsModal.propTypes = {
  testItemData: PropTypes.object,
  closeModal: PropTypes.func,
  children: PropTypes.object,
};

export default TestDetailsModal;
