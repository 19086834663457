/* eslint-disable */
import {
  PUT_FINISH_ADMIN_FAIL,
  PUT_FINISH_ADMIN_START,
  PUT_FINISHADMIN_SUCCESS,
  PUT_REJECT_ADMIN_FAIL,
  PUT_REJECT_ADMIN_START,
  PUT_REJECT_ADMIN_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { PUT, REST_API } from '@shared/constants/rest-api';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const rejectBlockByAdmin = (params, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/admin/block/${params.blockId}/user/${params.userId}/reject`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { comment: params.comment },
  onSuccess,
  types: [PUT_REJECT_ADMIN_START, PUT_REJECT_ADMIN_SUCCESS, PUT_REJECT_ADMIN_FAIL],
});

export const finishBlockByAdmin = (params, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/admin/block/${params.blockId}/user/${params.userId}/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [PUT_FINISH_ADMIN_START, PUT_FINISHADMIN_SUCCESS, PUT_FINISH_ADMIN_FAIL],
});
