import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Button, Form, Input, Modal } from 'antd';
import { UsersCount } from '@/components/MVFilters';

import style from '../style.module.scss';

const SaveFilter = ({ withSave = false, ...props }) => {
  const { t } = useTranslation('newMassSelection');

  const [name, setName] = useState(undefined);

  useEffect(() => {
    if (!props.isPeriodicAssignment) {
      onSubmitCancel();
    }
  }, [props.isPeriodicAssignment]);

  const onSubmit = () => {
    props.formValuesRef.current = { ...props.formValuesRef.current, filterName: name, filterSave: true };
    if (props.isPeriodicAssignment) {
      props.setSaveFilter(true);
      props.setLocalSaveFilter(false);
    } else {
      props.setLocalSaveFilter(true);
    }
    props.onClose();
  };

  const onSubmitCancel = () => {
    props.formValuesRef.current = { ...props.formValuesRef.current, filterName: undefined, filterSave: false };
    props.setSaveFilter(false);
    props.setLocalSaveFilter(false);
    props.onClose();
  };

  const footer = (
    <div className={style['modals__buttons']}>
      <Button onClick={onSubmitCancel}>{t('no')}</Button>
      <Button onClick={onSubmit} disabled={!name?.trim()} type='primary'>
        {t('yes')}
      </Button>
    </div>
  );

  return (
    <Modal footer={footer} open={props.open} onCancel={onSubmitCancel} title={t('saveFilterCaption')}>
      <div className={style['modals__content']}>
        {withSave && (
          <Alert
            className={style['modals__alert']}
            message={t('warning')}
            type='warning'
            showIcon
            description={t('warningDescription.1')}
          />
        )}
        <Form.Item layout='vertical' label={t('nameFilterLabel')}>
          <Input onChange={e => setName(e.target.value)} value={name} />
        </Form.Item>
        {!!props.getCountUsers && (
          <UsersCount
            text={t('massText')}
            countSelectedUsers={props.countSelectedUsers}
            exportUsers={props.exportUsers}
          />
        )}
      </div>
    </Modal>
  );
};

export default SaveFilter;
