import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  deletePokaApprover,
  loadPokaApprovers,
  saveEditingPokaApprover,
  savePokaApproversSort,
  updatePokaApprover,
} from '@/store/poka_approvers/actions';
import { clearStudents } from '@/store/students/actions';

import { selectPokaApprovers } from '@/store/poka_approvers/selectors';

import { message, Table, Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import { DEFAULT_PARAMS, DELETE, EDIT, MAX_TEXT_LENGTH, SORT_VALUES } from '../constants';
import ModalApproversDirectory from './ModalApproversDirectory';

import Utils from '@/Utils';
import classNames from 'classnames';

import css from '../ApproversDirectory.module.scss';

const TableApproversDirectory = () => {
  const { t } = useTranslation('ApproversDirectory');
  const dispatch = useDispatch();

  const [isApprover, setIsApprover] = useState({ open: false, name: '', id: null });
  const [isActiveSort, setIsActiveSort] = useState({ aZ: false });

  const {
    pokaApprovers,
    loadingPokaApprovers,
    pokaApproversCurrentPage,
    pokaApproversTotal,
    pokaApproversPageSize,
    pokaApproversPageNumber,
    pokaApproversSort,
    pokaApproversFilters,
  } = useSelector(selectPokaApprovers);

  const renderWithTooltip = text => {
    const content = text || '';
    return (
      <Tooltip title={content.length > MAX_TEXT_LENGTH ? content : ''}>
        {Utils.trString(content, MAX_TEXT_LENGTH)}
      </Tooltip>
    );
  };

  const handleEditClick = item => {
    dispatch(clearStudents());
    dispatch(saveEditingPokaApprover(item));
    setIsApprover({ open: true, mode: EDIT, ...item });
  };

  const handleDeleteClick = item => {
    dispatch(clearStudents());
    setIsApprover({ open: true, mode: DELETE, ...item });
  };

  const columns = [
    {
      title: t('fullname'),
      dataIndex: 'fullName',
      key: '1',
      width: 296,
      render: (_, record) => renderWithTooltip(record.fullName),
    },
    {
      title: t('position'),
      dataIndex: 'position',
      key: '2',
      width: 208,
      render: (_, record) => renderWithTooltip(record.position),
    },
    {
      title: t('category'),
      dataIndex: 'category',
      key: '3',
      width: 288,
      render: (_, record) => {
        const category = record.category?.map(item => item.label).join(', ');
        return renderWithTooltip(category);
      },
    },
    {
      title: t('site'),
      dataIndex: 'site',
      key: '4',
      width: 272,
      render: (_, record) => {
        const site = record.site?.join(', ');
        return renderWithTooltip(site);
      },
    },
    {
      title: t('action'),
      dataIndex: 'action',
      key: '5',
      width: 165,
      align: 'center',
      render: (_, item) => (
        <div className={css['Table-area-buttons']}>
          <Tooltip title={t('editInfo')}>
            <FormOutlined className={css['Table-area-buttons-editBtn']} onClick={() => handleEditClick(item)} />
          </Tooltip>

          <Tooltip title={t('deleteInfo')}>
            <DeleteOutlined className={css['Table-area-buttons-deleteBtn']} onClick={() => handleDeleteClick(item)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  const pagination = {
    total: pokaApproversTotal,
    pageSize: pokaApproversPageSize,
    pageNumber: pokaApproversPageNumber,
    current: pokaApproversCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const dataSource = useMemo(
    () =>
      pokaApprovers?.length > 0 &&
      pokaApprovers.map(pokaApprover => ({
        fullName: pokaApprover.user?.name,
        userId: pokaApprover.user?.id,
        position: pokaApprover.position,
        category: pokaApprover.categories || '',
        site: pokaApprover.sites?.map(site => site.name) || '',
        siteId: pokaApprover.sites?.map(site => site.id) || null,
        id: pokaApprover.approverId,
      })),
    [pokaApprovers]
  );

  const onChangeTable = ({ current }) => {
    const page = current - 1;
    const queryParams = { size: DEFAULT_PARAMS.size, page, sort: pokaApproversSort };
    dispatch(loadPokaApprovers(queryParams, pokaApproversFilters));
  };

  const refreshApproversAndCloseModal = () => {
    const queryParams = { size: DEFAULT_PARAMS.size, page: pokaApproversCurrentPage, sort: pokaApproversSort };
    dispatch(loadPokaApprovers(queryParams, pokaApproversFilters));
    setIsApprover({ open: false, name: '', id: null });
  };

  const onUpdateApprover = data => {
    if (isApprover?.id) {
      dispatch(updatePokaApprover(isApprover.id, data, () => refreshApproversAndCloseModal()));
    } else {
      message.warning(t('warningCreateApprover'));
    }
  };

  const onDeleteApprover = () => dispatch(deletePokaApprover(isApprover?.id, () => refreshApproversAndCloseModal()));

  const sortTable = (sortName, sortValue) => {
    setIsActiveSort({ [sortName]: !isActiveSort[sortName] });
    dispatch(savePokaApproversSort(!isActiveSort[sortName] ? sortValue : ''));
    dispatch(
      loadPokaApprovers(
        { page: pokaApproversCurrentPage, size: DEFAULT_PARAMS.size, sort: !isActiveSort[sortName] ? sortValue : '' },
        pokaApproversFilters
      )
    );
  };

  return (
    <div className={css['Table']}>
      {/* Блок сортировки + кнопка */}
      <div className={css['Table-actions']}>
        <div className={css['Table-actions-sort']}>
          <div className={css['Table-actions-sort-text']}>{t('sort')}</div>
          <div
            className={classNames(pokaApproversSort.includes(SORT_VALUES.NAME_ASC) && css['Table-actions-sort-active'])}
            onClick={() => sortTable('aZ', SORT_VALUES.NAME_ASC)}
          >
            {t('sortFullName')}
          </div>
        </div>
      </div>

      <div className={css['Table-area']}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey={e => e?.id}
          pagination={pagination}
          onChange={onChangeTable}
          loading={loadingPokaApprovers}
        />

        {isApprover.open && (
          <ModalApproversDirectory
            open={isApprover.open}
            onCancel={() => setIsApprover({ open: false, name: '', id: null })}
            title={isApprover.mode === EDIT ? t('editApprover') : t('deleteApprover')}
            approverData={isApprover}
            onOk={isApprover.mode === EDIT ? onUpdateApprover : onDeleteApprover}
            onOkText={isApprover.mode === EDIT ? t('updateOk') : t('deleteBtn')}
          />
        )}
      </div>
    </div>
  );
};

export default TableApproversDirectory;
