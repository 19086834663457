import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeStatusProduct, postProductsFilters } from '@/store/product/actions';

import { selectShop } from '@/store/product/selectors';

import { Button, Modal } from 'antd';
import { HiOutlineEmojiHappy, HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import PropTypes from 'prop-types';

import css from '../../Products.module.scss';

const ModalDelete = props => {
  const { t } = useTranslation('products');
  const dispatch = useDispatch();

  const { productsCurrentPage, shopFilters } = useSelector(selectShop);

  const [isDelete, setIsDelete] = useState(false);

  const deleteProduct = () => {
    const params = {
      productId: props.isProduct?.obj?.productId,
      productStatus: 'DELETED',
    };

    setIsDelete(true);
    dispatch(changeStatusProduct(params));
  };

  const goToShop = () => {
    dispatch(postProductsFilters(shopFilters.filters ? shopFilters.filters : {}, productsCurrentPage));
    props.onClose();
  };

  return (
    <Modal className={css['Modal']} width={600} open={props.isProduct.open} onCancel={props.onClose} footer={null}>
      <div className={css['Modal-isDelete']}>
        {isDelete ? (
          <HiOutlineEmojiHappy color='var(--personalColor)' size={168} />
        ) : (
          <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
        )}
        <div className={css['Modal-isDelete-title']}>{isDelete ? t('modalDeleted') : t('modalIsDelete')}</div>
        <div className={css['Modal-isDelete-desc']}>{!isDelete && t('modalIsDeleteDesc')}</div>
        <div className={css['Modal-isDelete-footer']}>
          {!isDelete ? (
            <>
              <Button onClick={props.onClose} key='onClose' size='large'>
                {t('cancel')}
              </Button>
              <Button
                className={css['Modal-isDelete-footer-delete']}
                onClick={deleteProduct}
                key='onDelete'
                size='large'
                type='primary'
              >
                {t('delete')}
              </Button>
            </>
          ) : (
            <Button onClick={goToShop} companyColor key='backShop' size='large'>
              {t('backShop')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

ModalDelete.propTypes = {
  onClose: PropTypes.func,
  isProduct: PropTypes.object,
};

export default ModalDelete;
