// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-AppLayout-appLayout-module__ql-picker-tiIpLt.admin-src-pages-AppLayout-appLayout-module__ql-font-hlx4ag .admin-src-pages-AppLayout-appLayout-module__ql-picker-label-GXn7I0[data-value="Montserrat"]:before, .admin-src-pages-AppLayout-appLayout-module__ql-picker-tiIpLt.admin-src-pages-AppLayout-appLayout-module__ql-font-hlx4ag .admin-src-pages-AppLayout-appLayout-module__ql-picker-item-phKT50[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-AppLayout-appLayout-module__ql-font-Montserrat-FT1qVd {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-AppLayout-appLayout-module__content-kWdb7g {
  width: 100%;
  max-width: 1380px;
  margin: auto;
  padding: 2em;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/AppLayout/appLayout.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.content {\n  padding: 2em;\n  width: 100%;\n  max-width: 1380px;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-AppLayout-appLayout-module__ql-picker-tiIpLt`,
	"qlPicker": `admin-src-pages-AppLayout-appLayout-module__ql-picker-tiIpLt`,
	"ql-font": `admin-src-pages-AppLayout-appLayout-module__ql-font-hlx4ag`,
	"qlFont": `admin-src-pages-AppLayout-appLayout-module__ql-font-hlx4ag`,
	"ql-picker-label": `admin-src-pages-AppLayout-appLayout-module__ql-picker-label-GXn7I0`,
	"qlPickerLabel": `admin-src-pages-AppLayout-appLayout-module__ql-picker-label-GXn7I0`,
	"ql-picker-item": `admin-src-pages-AppLayout-appLayout-module__ql-picker-item-phKT50`,
	"qlPickerItem": `admin-src-pages-AppLayout-appLayout-module__ql-picker-item-phKT50`,
	"ql-font-Montserrat": `admin-src-pages-AppLayout-appLayout-module__ql-font-Montserrat-FT1qVd`,
	"qlFontMontserrat": `admin-src-pages-AppLayout-appLayout-module__ql-font-Montserrat-FT1qVd`,
	"content": `admin-src-pages-AppLayout-appLayout-module__content-kWdb7g`
};
export default ___CSS_LOADER_EXPORT___;
