export function register(config) {
  if ('serviceWorker' in navigator) {
    const swUrl = '/kampus-sw.js';

    navigator.serviceWorker.register(swUrl).then(
      registration => {
        if (registration.waiting) {
          if (config && config.onUpdate) {
            config.onUpdate(registration);
          }
        }

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  if (config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                } else {
                  if (config && config.onSuccess) {
                    config.onSuccess(registration);
                  }
                }
              }
            };
          }
        };
      },
      error => {
        if (config && config.onError) {
          config.onError(error);
        }
      }
    );
  }
}
