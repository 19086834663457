/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { GrCopy, GrTrash } from 'react-icons/gr';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

export const ScaleMenu = props => {
  const { t } = useTranslation('D360_Scales');

  /**
   * Отобразить баллы.
   */
  const onClick = () => {
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick(props.scale);
    }
  };

  /**
   * Копировать шкалу.
   */
  const onCopy = () => {
    if (props.onCopy && typeof props.onCopy === 'function') {
      props.onCopy(props.scale);
    }
  };

  /**
   * Удалить шкалу.
   */
  const onDelete = () => {
    if (props.onDelete && typeof props.onDelete === 'function') {
      props.onDelete(props.scale);
    }
  };

  /**
   * Редактировать шкалу.
   */
  const onEdit = () => {
    if (props.onEdit && typeof props.onEdit === 'function') {
      props.onEdit(props.scale);
    }
  };

  /**
   * Отображение меню выбранной шкалы.
   *
   * @returns {*}
   */
  const renderMenu = [
    {
      key: '1',
      label: t('menuScaleCopy'),
      onClick: () => onCopy(props.scale),
      icon: <GrCopy />,
    },
    {
      key: '2',
      label: t('menuScaleDelete'),
      onClick: () => onDelete(props.scale),
      icon: <GrTrash />,
    },
    {
      key: '3',
      label: t('menuScaleProperty'),
      onClick: () => onEdit(props.scale),
    },
  ];

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <>
        <Dropdown menu={{ items: renderMenu }} trigger={['click']}>
          <Button size='small' shape='circle' icon={<MoreOutlined />} style={{ marginRight: 10 }} />
        </Dropdown>
        <span className={css.TableLink} type='link' onClick={onClick}>
          {props.name}
        </span>
      </>
    );
  };

  /**
   * Запуск компонента.
   */
  return render();
};

/**
 * Типизация свойств компонента.
 *
 * @type {{name: *, scale: *, onClick: *, onCopy: *, onDelete: *, onEdit: *}}
 */
ScaleMenu.propTypes = {
  name: PropTypes.string.isRequired,
  scale: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

/**
 * Экспорт компонента.
 */
export default ScaleMenu;
