export const GET_POKA_SKILLS_START = 'GET_POKA_SKILLS_START';
export const GET_POKA_SKILLS_SUCCESS = 'GET_POKA_SKILLS_SUCCESS';
export const GET_POKA_SKILLS_FAIL = 'GET_POKA_SKILLS_FAIL';

export const GET_TOPIC_LABELS_START = 'GET_TOPIC_LABELS_START';
export const GET_TOPIC_LABELS_SUCCESS = 'GET_TOPIC_LABELS_SUCCESS';
export const GET_TOPIC_LABELS_FAIL = 'GET_TOPIC_LABELS_FAIL';

export const EXPORT_TOPIC_START = 'EXPORT_TOPIC_START';
export const EXPORT_TOPIC_SUCCESS = 'EXPORT_TOPIC_SUCCESS';
export const EXPORT_TOPIC_FAIL = 'EXPORT_TOPIC_FAIL';

export const GET_POKA_DIR_POSITION_START = 'GET_POKA_DIR_POSITION_START';
export const GET_POKA_DIR_POSITION_SUCCESS = 'GET_POKA_DIR_POSITION_SUCCESS';
export const GET_POKA_DIR_POSITION_FAIL = 'GET_POKA_DIR_POSITION_FAIL';

export const GET_POKA_DIR_SITE_START = 'GET_POKA_DIR_SITE_START';
export const GET_POKA_DIR_SITE_SUCCESS = 'GET_POKA_DIR_SITE_SUCCESS';
export const GET_POKA_DIR_SITE_FAIL = 'GET_POKA_DIR_SITE_FAIL';

export const GET_POKA_DIR_CATEGORY_START = 'GET_POKA_DIR_CATEGORY_START';
export const GET_POKA_DIR_CATEGORY_SUCCESS = 'GET_POKA_DIR_CATEGORY_SUCCESS';
export const GET_POKA_DIR_CATEGORY_FAIL = 'GET_POKA_DIR_CATEGORY_FAIL';

export const GET_POKA_DIR_LEVEL_START = 'GET_POKA_DIR_LEVEL_START';
export const GET_POKA_DIR_LEVEL_SUCCESS = 'GET_POKA_DIR_LEVEL_SUCCESS';
export const GET_POKA_DIR_LEVEL_FAIL = 'GET_POKA_DIR_LEVEL_FAIL';

export const GET_POKA_DIR_STATUS_START = 'GET_POKA_DIR_STATUS_START';
export const GET_POKA_DIR_STATUS_SUCCESS = 'GET_POKA_DIR_STATUS_SUCCESS';
export const GET_POKA_DIR_STATUS_FAIL = 'GET_POKA_DIR_STATUS_FAIL';
