import {
  CLEAR_SETTINGS_VISIBILITY_SUCCESS,
  DELETE_SETTINGS_VISIBILITY_FAIL,
  DELETE_SETTINGS_VISIBILITY_START,
  DELETE_SETTINGS_VISIBILITY_SUCCESS,
  GET_COUNT_SETTINGS_VISIBILITY_FAIL,
  GET_COUNT_SETTINGS_VISIBILITY_START,
  GET_COUNT_SETTINGS_VISIBILITY_SUCCESS,
  GET_COUNT_TOPIC_SETTINGS_VISIBILITY_FAIL,
  GET_COUNT_TOPIC_SETTINGS_VISIBILITY_START,
  GET_COUNT_TOPIC_SETTINGS_VISIBILITY_SUCCESS,
  GET_SETTINGS_VISIBILITY_FAIL,
  GET_SETTINGS_VISIBILITY_START,
  GET_SETTINGS_VISIBILITY_SUCCESS,
  GET_USERS_SETTINGS_MODAL_VISIBILITY_START,
  GET_USERS_SETTINGS_TOPIC_VISIBILITY_START,
  GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_FAIL,
  GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_SUCCESS,
  GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_FAIL,
  GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_SUCCESS,
  PUT_SETTINGS_VISIBILITY_FAIL,
  PUT_SETTINGS_VISIBILITY_START,
  PUT_SETTINGS_VISIBILITY_SUCCESS,
  SAVE_SETTINGS_VISIBILITY_SUCCESS,
} from './constants';

const initialState = {
  settingsVisibility: {},
  loading: false,
  isExportLoading: false,
  count: null,
  countTopic: null,
  stateSettingsVisibility: [],
  loaderUpdate: false,
};

/* eslint-disable */
export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_SETTINGS_VISIBILITY_START:
    case DELETE_SETTINGS_VISIBILITY_START:
    case GET_COUNT_SETTINGS_VISIBILITY_START:
    case GET_COUNT_TOPIC_SETTINGS_VISIBILITY_START:
      return { ...state, loading: true, count: null };

    case PUT_SETTINGS_VISIBILITY_START:
      return { ...state, loaderUpdate: true, count: null };

    case GET_USERS_SETTINGS_MODAL_VISIBILITY_START:
    case GET_USERS_SETTINGS_TOPIC_VISIBILITY_START:
      return { ...state, isExportLoading: true, loading: true };

    case GET_SETTINGS_VISIBILITY_SUCCESS:
      return { ...state, loading: false, settingsVisibility: response };

    case GET_COUNT_SETTINGS_VISIBILITY_SUCCESS:
      return { ...state, loading: false, count: response };

    case GET_COUNT_TOPIC_SETTINGS_VISIBILITY_SUCCESS:
      return { ...state, loading: false, countTopic: response };

    case DELETE_SETTINGS_VISIBILITY_SUCCESS:
    case GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_SUCCESS:
    case GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_SUCCESS:
      return { ...state, loading: false, isExportLoading: false, isExportLoading: false };

    case PUT_SETTINGS_VISIBILITY_SUCCESS:
      return { ...state, loaderUpdate: false };

    case GET_SETTINGS_VISIBILITY_FAIL:
    case DELETE_SETTINGS_VISIBILITY_FAIL:
    case GET_COUNT_SETTINGS_VISIBILITY_FAIL:
    case GET_COUNT_TOPIC_SETTINGS_VISIBILITY_FAIL:
    case GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_FAIL:
    case GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_FAIL:
      return { ...state, loading: false, isExportLoading: false };

    case PUT_SETTINGS_VISIBILITY_FAIL:
      return { ...state, loaderUpdate: false };

    case CLEAR_SETTINGS_VISIBILITY_SUCCESS:
      return { ...state, isLoading: false, settingsVisibility: {}, stateSettingsVisibility: [] };

    case SAVE_SETTINGS_VISIBILITY_SUCCESS: {
      const filters = { ...state.stateSettingsVisibility, filters: value };
      return { ...state, stateSettingsVisibility: filters };
    }
    default:
      return state;
  }
};
