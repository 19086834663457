import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getStatuses, postNewsFilters } from '@/store/news/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import { PlusOutlined } from '@ant-design/icons';

import FiltersNews from './Content/FiltersNews';
import ModalNews from './Content/ModalNews';
import TableNews from './Content/TableNews';

import css from './News.module.scss';

import { NEWS_CREATE } from '@/constants/permissions';

const { Title } = Typography;

const News = () => {
  const { t } = useTranslation('news');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatuses());
    dispatch(postNewsFilters({}));
  }, []);

  const { currentUser } = useSelector(selectUsers);
  const [isModalNews, setIsModalNews] = useState(false);

  const showModalNews = () => {
    setIsModalNews(true);
  };

  return (
    <Layout>
      <Content>
        <div className={css['News']}>
          <div className={css['News-header']}>
            <Title>{t('title')}</Title>
            {currentUser?.authorities.includes(NEWS_CREATE) && (
              <Button icon={<PlusOutlined />} onClick={showModalNews} size='large' type='primary'>
                {t('createNews')}
              </Button>
            )}
          </div>
        </div>
        <div className={css['News-main']}>
          <FiltersNews />
          <TableNews />
        </div>

        {isModalNews && <ModalNews open={isModalNews} onClose={() => setIsModalNews(false)} isCreate />}
      </Content>
    </Layout>
  );
};

export default News;
