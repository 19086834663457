/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_EVENTS_START = 'GET_EVENTS_START';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

const getTopicFilterApiValues = filter => {
  const { ...rest } = filter;
  return rest;
};

export const getEvents = (page = 0, onSuccess, filter) => {
  const apiFilter = getTopicFilterApiValues(filter);
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/event${getQueryString({
      size: 10,
      page,
      ...apiFilter,
      sort: 'startTime,asc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_EVENTS_START, GET_EVENTS_SUCCESS, GET_EVENTS_FAIL],
    onSuccess,
  };
};

export const clearEvents = onSuccess => ({
  type: CLEAR_EVENTS,
  onSuccess,
});
