import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createFunction, deleteFunction, editFunction, getFunctions } from '@/store/function/actions';
import { editPokaDirPosition, getPokaDirPositions } from '@/store/poka_directory/actions';

import { selectFunctions } from '@/store/function/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Input, message, Row, Select } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import DictionaryCreationDialog from '@/components/Dictionary/DictionaryCreationDialog';
import { Content } from '@/components/Layout';

import { hasPokaPositionsLinkOpt, POKA_API_PARAMS } from './constants';
import DictionaryFucTable from './DictionaryFuncTable';
import ModalPositionAssignment from './ModalPositionAssignment';

import css from './Dictionary.module.scss';

import { FUNCTION_INTERNAL_CREATE } from '@/constants/permissions';

const DictionaryFunctions = ({ isGlobal, dictionary, limit }) => {
  const { t } = useTranslation('dictionaryTable');
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUsers);
  const { creationError, editingError, functionsPageNumber } = useSelector(selectFunctions);

  const [modalState, setModalState] = useState({
    dataVisible: false,
    activeData: null,
    typicalPositionVisible: false,
    deleteConfirmationVisible: false,
  });

  const [filterState, setFilterState] = useState({
    searchString: '',
    hasNoPokaPositionLink: true,
  });

  const handleChange = event => {
    const { value } = event.target;
    setFilterState(prev => ({ ...prev, searchString: value }));
  };

  const changePage = page => {
    const requestParams = {
      ...POKA_API_PARAMS,
      ...(filterState.hasNoPokaPositionLink !== '' && { hasNoPokaPositionLink: filterState.hasNoPokaPositionLink }),
    };
    dispatch(getFunctions(filterState.searchString, page - 1, null, isGlobal, requestParams));
  };

  const showDeleteConfirmation = data => {
    setModalState(prevState => ({
      ...prevState,
      deleteConfirmationVisible: true,
      activeData: data,
    }));
  };

  const hideDeleteConfirmation = () => {
    setModalState(prevState => ({
      ...prevState,
      deleteConfirmationVisible: false,
      activeData: null,
    }));
  };

  const handleDataDeletion = () => {
    const requestParams = {
      ...POKA_API_PARAMS,
      ...(filterState.hasNoPokaPositionLink !== '' && { hasNoPokaPositionLink: filterState.hasNoPokaPositionLink }),
    };
    if (modalState.activeData.id) {
      dispatch(
        deleteFunction(modalState.activeData.id, {
          onSuccess: () => {
            hideDeleteConfirmation();
            dispatch(getFunctions(filterState.searchString, functionsPageNumber, null, isGlobal, requestParams));
          },
          onError: hideDeleteConfirmation,
        })
      );
    }
  };

  const showDataDialog = () => setModalState(prev => ({ ...prev, dataVisible: true }));

  const hideDataDialog = () => setModalState(prev => ({ ...prev, dataVisible: false }));

  const hideTypicalPositionModal = () => setModalState(prev => ({ ...prev, typicalPositionVisible: false }));

  const handleOk = data => (modalState.activeData ? handleDataEditing(data) : handleDataCreation(data));

  const handleOkPositionAssignment = (id, data) => {
    const requestParams = {
      ...POKA_API_PARAMS,
      ...(filterState.hasNoPokaPositionLink !== '' && { hasNoPokaPositionLink: filterState.hasNoPokaPositionLink }),
    };
    if (id) {
      dispatch(
        editPokaDirPosition(id, data, () => {
          hideTypicalPositionModal();
          dispatch(getFunctions(filterState.searchString, functionsPageNumber, null, isGlobal, requestParams));
        })
      );
    } else {
      message.warning(t('warningAssignmentPosition'));
    }
  };

  const handleCancel = () => (modalState.activeData ? hideDataEditingDialog() : hideDataDialog());

  const handleDataCreation = data => {
    const requestParams = {
      ...POKA_API_PARAMS,
      ...(filterState.hasNoPokaPositionLink !== '' && { hasNoPokaPositionLink: filterState.hasNoPokaPositionLink }),
    };
    dispatch(
      createFunction(data, () => {
        hideDataDialog();
        dispatch(getFunctions(filterState.searchString, functionsPageNumber, null, isGlobal, requestParams));
      })
    );
  };

  const showDataEditingDialog = data => {
    setModalState(prevState => ({
      ...prevState,
      dataVisible: true,
      activeData: data,
    }));
  };

  const hideDataEditingDialog = () => {
    setModalState(prevState => ({
      ...prevState,
      dataVisible: false,
      activeData: null,
    }));
  };

  const chooseTypicalPosition = data => {
    setModalState(prevState => ({
      ...prevState,
      typicalPositionVisible: true,
      activeData: data,
    }));
  };

  const handleDataEditing = data => {
    const requestParams = {
      ...POKA_API_PARAMS,
      ...(filterState.hasNoPokaPositionLink !== '' && { hasNoPokaPositionLink: filterState.hasNoPokaPositionLink }),
    };
    dispatch(
      editFunction({ ...data, id: modalState.activeData && modalState.activeData.id }, () => {
        hideDataEditingDialog();
        dispatch(getFunctions(filterState.searchString, functionsPageNumber, null, isGlobal, requestParams));
      })
    );
  };

  const resetFilters = () => {
    setFilterState({ searchString: '', hasNoPokaPositionLink: true });
    dispatch(getFunctions('', 0, null, isGlobal, { poka: true, hasNoPokaPositionLink: true }));
  };

  const applyFilters = () => {
    const requestParams = {
      ...POKA_API_PARAMS,
      ...(filterState.hasNoPokaPositionLink !== '' && { hasNoPokaPositionLink: filterState.hasNoPokaPositionLink }),
    };
    dispatch(getFunctions(filterState.searchString, 0, null, isGlobal, requestParams));
  };

  const modalDictionary = modalState.activeData ? dictionary.editDataModal : dictionary.addDataModal;

  useEffect(() => {
    dispatch(getFunctions('', functionsPageNumber, null, isGlobal, { poka: true, hasNoPokaPositionLink: true }));
    dispatch(getPokaDirPositions());
  }, []);

  return (
    <Content className={css.DictionaryDirectory}>
      <div className={css['DictionaryDirectory-header']}>
        <h1>{dictionary.title}</h1>
        {currentUser?.authorities.includes(FUNCTION_INTERNAL_CREATE) && (
          <>
            <Button
              type='primary'
              size='large'
              className={css['DictionaryItem-button']}
              onClick={showDataDialog}
              data-qa='addDictionaryItemBtn'
            >
              {dictionary.addData}
            </Button>
          </>
        )}
      </div>

      <div className={css['DictionaryItem-container']}>
        <div className={css['DictionaryItem-form-title']}>{t('filters')}</div>
        <Row justify='space-between' gutter={16} className={css['DictionaryItem-form-filters']}>
          <Col colon={false} span={12}>
            <Input
              placeholder={dictionary.placeholder}
              dataqa='dictionaryItemSearchInput'
              name='searchString'
              value={filterState.searchString}
              onChange={handleChange}
              allowClear
            />
          </Col>
          <Col colon={false} span={12}>
            <Select
              className={css['DictionaryItem-form-select']}
              onChange={e => setFilterState({ ...filterState, hasNoPokaPositionLink: e })}
              value={filterState.hasNoPokaPositionLink}
              options={hasPokaPositionsLinkOpt(t)}
              allowClear
              placeholder={t('selectFilter')}
            />
          </Col>
        </Row>
        <Row className={css['DictionaryItem-form-buttons']}>
          <Button onClick={resetFilters}>{t('reset')}</Button>
          <Button type='primary' onClick={applyFilters}>
            {t('apply')}
          </Button>
        </Row>
      </div>

      <DictionaryFucTable
        onEdit={showDataEditingDialog}
        onDelete={showDeleteConfirmation}
        chooseTypicalPosition={chooseTypicalPosition}
        changePage={changePage}
      />

      {modalState.typicalPositionVisible && (
        <ModalPositionAssignment
          open
          onCancel={hideTypicalPositionModal}
          onOk={handleOkPositionAssignment}
          positionData={modalState.activeData}
        />
      )}

      {modalState.dataVisible && (
        <DictionaryCreationDialog
          open
          onOk={handleOk}
          onCancel={handleCancel}
          title={modalDictionary.title}
          descriptionText={modalDictionary.label}
          okText={modalState.activeData ? t('edit') : t('add')}
          error={creationError || editingError}
          errorText={dictionary.error}
          data={modalState.activeData}
          limit={limit}
          require={t('require')}
          limitText={t('limit')}
        />
      )}

      <ConfirmationModal
        open={modalState.deleteConfirmationVisible}
        onOk={handleDataDeletion}
        onCancel={hideDeleteConfirmation}
        title={dictionary.deleteData.title}
        okText={t('delete')}
        confirmationText={dictionary.deleteData.confirmation}
      />
    </Content>
  );
};

export default DictionaryFunctions;
