/* eslint-disable */
import {
  CREATE_FUNCTION_FAIL,
  CREATE_FUNCTION_START,
  CREATE_FUNCTION_SUCCESS,
  DELETE_FUNCTION_FAIL,
  DELETE_FUNCTION_START,
  DELETE_FUNCTION_SUCCESS,
  EDIT_FUNCTION_FAIL,
  EDIT_FUNCTION_START,
  EDIT_FUNCTION_SUCCESS,
  GET_FUNCTIONS_FAIL,
  GET_FUNCTIONS_START,
  GET_FUNCTIONS_SUCCESS,
  GET_GLOBAL_FUNCTIONS_FAIL,
  GET_GLOBAL_FUNCTIONS_START,
  GET_GLOBAL_FUNCTIONS_SUCCESS,
} from './actions';

const initialState = {
  functions: null,
  functionsTotal: null,
  functionsPageSize: null,
  functionsPageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  globalFunctions: {
    functions: null,
    functionsTotal: null,
    functionsPageSize: null,
    functionsPageNumber: null,
    isLoading: false,
    error: false,
  },
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_FUNCTIONS_START:
      return {
        ...state,
        functions: null,
        functionsTotal: null,
        functionsPageSize: null,
        functionsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_FUNCTIONS_SUCCESS:
      return {
        ...state,
        functions: response,
        functionsTotal: Number(headers.get('X-Total-Elements')) || 0,
        functionsPageSize: Number(headers.get('X-Page-Size')) || 0,
        functionsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_FUNCTIONS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_GLOBAL_FUNCTIONS_START:
      return {
        ...state,
        globalFunctions: {
          functions: null,
          functionsTotal: null,
          functionsPageSize: null,
          functionsPageNumber: null,
          isLoading: true,
          error: false,
        },
      };

    case GET_GLOBAL_FUNCTIONS_SUCCESS:
      return {
        ...state,
        globalFunctions: {
          functions: response,
          functionsTotal: Number(headers.get('X-Total-Elements')) || 0,
          functionsPageSize: Number(headers.get('X-Page-Size')) || 0,
          functionsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoading: false,
        },
      };

    case GET_GLOBAL_FUNCTIONS_FAIL:
      return {
        ...state,
        globalFunctions: { ...state.global, error: true, isLoading: false },
      };

    case CREATE_FUNCTION_START:
      return { ...state, isLoading: true, creationError: false };

    case CREATE_FUNCTION_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_FUNCTION_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_FUNCTION_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_FUNCTION_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_FUNCTION_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_FUNCTION_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_FUNCTION_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_FUNCTION_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    default:
      return state;
  }
};
