export const COMPETENCIES = 'competenceIds';
export const SKILLS = 'skillIds';

export const IMAGE_TYPE = ['image/jpg', 'image/jpeg', 'image/png'];

export const MAX_COMPETENCIES = 10;
export const MAX_SKILLS = 50;

export const STATUS_IN_PROCESS = 'IN_PROCESS';
export const PROCESS_ON_UPDATE = ' ON_UPDATE';

export const TYPE_UPDATE = 'UPDATED';

export const MAX_TOPICS = 100;
