/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Modal } from 'antd';

import PropTypes from 'prop-types';

import css from './Users.module.scss';

class ImportInfoDialog extends Component {
  static propTypes = {
    importInfo: PropTypes.array,
    errorsInfo: PropTypes.object,
  };

  render() {
    const { importInfo, t, isBadge, isPokaPosition, errorsInfo, ...props } = this.props;

    const errors =
      importInfo?.length &&
      importInfo
        ?.map((line, index) =>
          line.error ? (
            <p key={index}>
              {line.error} {isBadge && line?.email && '- ' + line.email}
            </p>
          ) : null
        )
        ?.filter(error => !!error);

    const errorMessage = errorsInfo?.message;
    const hasFileErrors = errors && errors.length;

    const content = errorMessage ? (
      <p className={css['desc-error']}>{errorMessage}</p>
    ) : hasFileErrors ? (
      <div>
        <p className={css['desc-error']}>{t('description1')}</p>
        {errors}
      </div>
    ) : (
      <p className={css['desc-success']}>{t('description2')}</p>
    );

    return (
      <Modal width={800} title={t('title')} {...props} footer={null} centered={isPokaPosition}>
        <div>{content}</div>
      </Modal>
    );
  }
}

export default withTranslation('importInfoDialog')(ImportInfoDialog);
