import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUsers } from '@/store/users/selectors';

import { Button, Form, Image, Input, message, Modal, Upload } from 'antd';
import { PlusOutlined, ZoomInOutlined } from '@ant-design/icons';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import classNames from 'classnames';
import { isEmpty } from 'lodash';

import css from './TabCertificates.module.scss';

import { CERTIFICATES_UPDATE } from '@/constants/permissions';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const typeMaxLength = 50;
const fioMaxLength = 222;
const materialMaxLength = 210;

const headers = {
  'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const CertificateModal = props => {
  const { certificate, editable, templates } = props;
  const [form] = Form.useForm();

  const { t } = useTranslation('certificateTab');
  const { currentUser } = useSelector(selectUsers);

  const [imageLogo] = useLocalFileByUUID(currentUser.domainCompany.logo);

  const certificateInitial = {
    name: editable ? certificate.name : '',
    templateId: editable ? String(certificate.templateId) : null,
    type: editable ? certificate.type : t('typeDefaultValue'),
    confirmText: editable ? certificate.confirmText : t('confirmTextDefaultValue'),
    completeText: editable ? certificate.completeText : t('completeTextDefaultValue'),
    base64Logo1: editable ? certificate.base64Logo1 : imageLogo,
    base64Logo2: editable ? certificate.base64Logo2 : '',
  };

  const [activeImage, setActiveImage] = useState(certificateInitial.templateId);
  const [isSubmit, setIsSubmit] = useState(false);
  const [logo1FileList, setLogo1FileList] = useState(
    editable && certificateInitial.base64Logo1
      ? [
          {
            key: '1',
            uid: '1',
            name: 'logo1.svg',
            status: 'done',
            url: certificateInitial.base64Logo1,
            thumbUrl: certificateInitial.base64Logo1,
          },
        ]
      : []
  );
  const [logo2FileList, setLogo2FileList] = useState(
    editable && certificateInitial.base64Logo2
      ? [
          {
            key: '2',
            uid: '2',
            name: 'logo2.svg',
            status: 'done',
            url: certificateInitial.base64Logo2,
            thumbUrl: certificateInitial.base64Logo2,
          },
        ]
      : []
  );
  const [logo1Visible, setLogo1Visible] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    templateId: false,
    confirmText: false,
    completeText: false,
  });
  const [previewHtml, setPreviewHtml] = useState({ name: '', content: '' });
  const [showPreview, setShowPreview] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    if (certificateInitial.base64Logo1) {
      const logo1 = {
        key: '1',
        uid: '1',
        name: 'logo1.svg',
        status: 'done',
        url: certificateInitial.base64Logo1,
        thumbUrl: certificateInitial.base64Logo1,
      };
      setLogo1FileList([logo1]);
    }

    if (certificateInitial.base64Logo2) {
      const logo2 = {
        key: '2',
        uid: '2',
        name: 'logo2.svg',
        status: 'done',
        url: certificateInitial.base64Logo2,
        thumbUrl: certificateInitial.base64Logo2,
      };
      setLogo2FileList([logo2]);
    }
  }, [certificateInitial.base64Logo1, certificateInitial.base64Logo2, certificate]);

  const onTemplateClick = e => {
    e.preventDefault();

    const templateId = e.currentTarget.id;
    const templatesById = templates.reduce((acc, tmp) => {
      acc[tmp.id] = tmp;
      return acc;
    }, {});

    if (!templatesById[templateId]) return;

    setActiveImage(templateId);
    setErrors({ ...errors, templateId: isEmpty(templateId) });
    form.setFieldsValue({ templateId });
  };

  const handleLogoUpload = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 400);
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then(async values => {
        setIsSubmit(true);
        const data = { ...values };
        if (editable) data.key = certificate.key;

        try {
          if (logo1FileList.length) {
            // при добавлении логотипа
            if (logo1FileList?.[0].originFileObj) {
              data.base64Logo1 = await getBase64(logo1FileList?.[0].originFileObj);
            }

            // если оставить текущий логотип
            if (logo1FileList?.[0].url) data.base64Logo1 = logo1FileList?.[0].url;
          }

          if (logo2FileList.length) {
            if (logo2FileList?.[0].originFileObj) {
              data.base64Logo2 = await getBase64(logo2FileList?.[0].originFileObj);
            }

            if (logo2FileList?.[0].url) data.base64Logo2 = logo2FileList?.[0].url;
          }

          // удаление логотипа
          if (values.base64Logo1 && !logo1FileList.length) {
            delete data.base64Logo1;
          }

          if (values.base64Logo2 && !logo2FileList.length) {
            delete data.base64Logo2;
          }
        } catch (error) {
          message.error(t('errorBase64'), error);
          throw new Error(error);
        }

        props.onSubmit(data);
      })
      .catch(error => {
        console.log('validate error ', error);
      });
  };

  const validFailed = () => {
    let validError = {};

    if (isEmpty(activeImage)) {
      validError['templateId'] = true;
    }

    form.getFieldsError().forEach(item => {
      if (item.name?.[0] !== 'templateId') {
        validError[item.name?.[0]] = isEmpty(form.getFieldValue(item.name?.[0]));
      }
    });

    const mergedObject = Object.assign(errors, validError);
    setErrors(mergedObject);
    setIsDisabled(true);
  };

  useEffect(() => {
    for (let err in errors) {
      if (errors[err]) {
        setIsDisabled(errors[err]);
        break;
      } else {
        setIsDisabled(false);
      }
    }
  }, [errors]);

  const onChangeForm = e => {
    if (isEmpty(activeImage)) {
      setErrors({ ...errors, templateId: true });
    }
    setErrors({ ...errors, [e.target.id]: isEmpty(form.getFieldValue(e.target.id)) });
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setLogo1Visible({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const onChangeLogo1 = ({ fileList }) => {
    const newList = fileList.filter(el => el.type === 'image/svg+xml');

    if (fileList.length && !newList.length) message.error(t('onlySvg'));

    setLogo1FileList([...newList]);
  };
  const onChangeLogo2 = ({ fileList }) => {
    const newList = fileList.filter(el => el.type === 'image/svg+xml');

    if (fileList.length && !newList.length) message.error(t('onlySvg'));

    setLogo2FileList([...newList]);
  };
  const beforeUpload1 = file => {
    setLogo1FileList([...logo1FileList, file]);
    return false;
  };
  const beforeUpload2 = file => {
    setLogo2FileList([...logo2FileList, file]);
    return false;
  };

  const handleCancel = () => setLogo1Visible({ ...logo1Visible, previewVisible: false });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='ant-upload-text'>{t('uploadButton')}</div>
    </div>
  );

  const closePreviewDialog = () => {
    setShowPreview(false);
    setPreviewHtml({ name: '', content: '' });
  };

  const onPreviewCertificate = record => {
    setPreviewHtml({ name: record.name, content: record.content });
    setShowPreview(true);
    setIsPortrait(String(record.orientation).trim().toLowerCase() === 'portrait');
  };

  const templatesList = templates.map(template => (
    <div className={css['Certificate-modal-imageContainer-div']}>
      <Image
        onClick={onTemplateClick}
        id={template.id}
        className={classNames({
          [css['Certificate-modal-imageContainer-template']]: true,
          [css['Certificate-modal-imageContainer-template-selected']]: template.id === activeImage,
          [css['Certificate-modal-imageContainer-template-port']]: template.orientation === 'portrait',
        })}
        key={template.id}
        src={template.base64Image}
        preview={false}
      />
      <ZoomInOutlined
        className={css['Certificate-modal-imageContainer-mask']}
        onClick={() => onPreviewCertificate(template)}
      />
    </div>
  ));

  const iframeClass = isPortrait ? css['Certificate-preview-portrait'] : css['Certificate-preview-landscape'];

  return (
    <>
      <Modal
        destroyOnClose
        width={1150}
        open={props.visible}
        footer={null}
        onCancel={props.onCancel}
        className={css['Certificate-modal']}
      >
        <h3 className={css['Certificate-title']}>{!editable ? t('addCertificate') : t('editCertificate')}</h3>
        <Form
          form={form}
          {...formItemLayout}
          onFinish={onSubmit}
          onFinishFailed={validFailed}
          labelAlign='left'
          className={css['Certificate-form']}
        >
          <Form.Item
            label={t('templateName')}
            hasFeedback
            initialValue={certificateInitial.name}
            rules={[{ required: true, message: t('nameWarning') }]}
            name='name'
          >
            <Input placeholder={t('namePlaceholder')} onChange={onChangeForm} />
          </Form.Item>
          <Form.Item
            label={t('designLabel')}
            required
            validateStatus={!activeImage && isSubmit ? 'error' : ''}
            help={!activeImage && isSubmit ? t('designWarning') : ''}
            name='templateId'
            initialValue={certificateInitial.templateId}
          >
            <div
              name='templateId'
              className={classNames({
                [css['Certificate-modal-imageContainer']]: true,
                [css['Certificate-modal-imageContainer-error']]: !activeImage && isSubmit,
              })}
            >
              {templatesList}
            </div>
          </Form.Item>
          <Form.Item
            label={t('typeLabel')}
            required
            hasFeedback
            name='type'
            initialValue={certificateInitial.type}
            rules={[{ required: true, message: t('typeWarning') }]}
          >
            <Input name='type' placeholder={t('typePlaceholder')} maxLength={typeMaxLength} onChange={onChangeForm} />
          </Form.Item>

          {/* текст перед ФИО */}
          {/* Настоящий сертификат подтверждает, что */}
          <Form.Item
            label={t('confirmTextLabel')}
            required
            hasFeedback
            name='confirmText'
            initialValue={certificateInitial.confirmText}
            rules={[{ required: true, message: t('confirmTextWarning') }]}
          >
            <TextArea
              name='text'
              placeholder={t('confirmTextPlaceholder')}
              rows={3}
              maxLength={fioMaxLength}
              onChange={onChangeForm}
            />
          </Form.Item>

          {/* текст перед названием материала */}
          {/* успешно прошел программу обучения */}
          <Form.Item
            label={t('completeTextLabel')}
            name='completeText'
            initialValue={certificateInitial.completeText}
            rules={[{ required: true, message: t('completeTextWarning') }]}
            required
            hasFeedback
          >
            <TextArea
              name='text'
              placeholder={t('completeTextPlaceholder')}
              rows={3}
              maxLength={materialMaxLength}
              onChange={onChangeForm}
            />
          </Form.Item>
          <Form.Item label={t('logoLabel') + ' 1'}>
            <Upload
              accept='.svg'
              headers={headers}
              customRequest={handleLogoUpload}
              listType='picture-card'
              fileList={logo1FileList}
              name='base64Logo1'
              onPreview={handlePreview}
              onChange={onChangeLogo1}
              beforeUpload={beforeUpload1}
              className={css['Upload']}
            >
              {logo1FileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label={t('logoLabel') + ' 2'}>
            <Upload
              accept='.svg'
              headers={headers}
              customRequest={handleLogoUpload}
              listType='picture-card'
              fileList={logo2FileList}
              name='base64Logo2'
              onPreview={handlePreview}
              onChange={onChangeLogo2}
              beforeUpload={beforeUpload2}
              className={css['Upload']}
            >
              {logo2FileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          {currentUser.authorities.includes(CERTIFICATES_UPDATE) && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className={css['Certificate-brnCancel']} onClick={props.onCancel}>
                {t('cancelButton')}
              </Button>
              <Button type='primary' htmlType='submit' disabled={isDisabled}>
                {t('saveButton')}
              </Button>
            </div>
          )}
        </Form>
      </Modal>
      <Modal
        centered
        className={css['Certificate-preview']}
        name='previewDialog'
        open={showPreview}
        footer={null}
        onCancel={closePreviewDialog}
        destroyOnClose={true}
        title={<span style={{ fontSize: '24px' }}>{previewHtml.name}</span>}
        width={isPortrait ? 580 : 885}
      >
        <div className={css['Certificate-preview']}>
          <iframe
            className={iframeClass}
            title='varDirectoryPreview'
            srcDoc={previewHtml.content}
            seamless
            frameBorder={0}
          ></iframe>
        </div>
      </Modal>
      <Modal open={logo1Visible.previewVisible} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={logo1Visible.previewImage} />
      </Modal>
    </>
  );
};

const WrappedCertificateModal = CertificateModal;

export default WrappedCertificateModal;
