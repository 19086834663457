// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shared-components-DatePicker-DatePicker-module__ql-picker-DuyiBT.shared-components-DatePicker-DatePicker-module__ql-font-POT350 .shared-components-DatePicker-DatePicker-module__ql-picker-label-MFF0qX[data-value="Montserrat"]:before, .shared-components-DatePicker-DatePicker-module__ql-picker-DuyiBT.shared-components-DatePicker-DatePicker-module__ql-font-POT350 .shared-components-DatePicker-DatePicker-module__ql-picker-item-DMUkCO[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.shared-components-DatePicker-DatePicker-module__ql-font-Montserrat-IjD_NI {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.shared-components-DatePicker-DatePicker-module__DatePicker-AlpdQs {
  width: 100%;
  position: relative;
}

.shared-components-DatePicker-DatePicker-module__DatePicker__input-zQ2Mdt {
  visibility: hidden;
  height: 0;
  min-height: 0;
  position: absolute;
}

.shared-components-DatePicker-DatePicker-module__DatePicker__poppup-u0v3Hq {
  text-align: center;
  position: relative;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.shared-components-DatePicker-DatePicker-module__DatePicker__poppup-u0v3Hq.shared-components-DatePicker-DatePicker-module__error-RhrvMX .ant-picker-panel-container {
  border: 1px solid red;
}
`, "",{"version":3,"sources":["webpack://./shared/components/DatePicker/DatePicker.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;EACX,kBAAkB;AACpB;;AAOA;EAJE,kBAAkB;EAClB,SAAS;EACT,aAAa;EACb,kBAAkB;AACpB;;AAMA;EAHE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.DatePicker {\n  position: relative;\n  width: 100%;\n}\n.DatePicker__input {\n  visibility: hidden;\n  position: absolute;\n  height: 0;\n  min-height: 0;\n}\n.DatePicker__poppup {\n  position: relative;\n  text-align: center;\n  inset: 0 !important;\n}\n.DatePicker__poppup.error :global .ant-picker-panel-container {\n  border: 1px solid #ff0000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `shared-components-DatePicker-DatePicker-module__ql-picker-DuyiBT`,
	"qlPicker": `shared-components-DatePicker-DatePicker-module__ql-picker-DuyiBT`,
	"ql-font": `shared-components-DatePicker-DatePicker-module__ql-font-POT350`,
	"qlFont": `shared-components-DatePicker-DatePicker-module__ql-font-POT350`,
	"ql-picker-label": `shared-components-DatePicker-DatePicker-module__ql-picker-label-MFF0qX`,
	"qlPickerLabel": `shared-components-DatePicker-DatePicker-module__ql-picker-label-MFF0qX`,
	"ql-picker-item": `shared-components-DatePicker-DatePicker-module__ql-picker-item-DMUkCO`,
	"qlPickerItem": `shared-components-DatePicker-DatePicker-module__ql-picker-item-DMUkCO`,
	"ql-font-Montserrat": `shared-components-DatePicker-DatePicker-module__ql-font-Montserrat-IjD_NI`,
	"qlFontMontserrat": `shared-components-DatePicker-DatePicker-module__ql-font-Montserrat-IjD_NI`,
	"DatePicker": `shared-components-DatePicker-DatePicker-module__DatePicker-AlpdQs`,
	"datePicker": `shared-components-DatePicker-DatePicker-module__DatePicker-AlpdQs`,
	"DatePicker__input": `shared-components-DatePicker-DatePicker-module__DatePicker__input-zQ2Mdt`,
	"datePickerInput": `shared-components-DatePicker-DatePicker-module__DatePicker__input-zQ2Mdt`,
	"DatePicker__poppup": `shared-components-DatePicker-DatePicker-module__DatePicker__poppup-u0v3Hq`,
	"datePickerPoppup": `shared-components-DatePicker-DatePicker-module__DatePicker__poppup-u0v3Hq`,
	"error": `shared-components-DatePicker-DatePicker-module__error-RhrvMX`
};
export default ___CSS_LOADER_EXPORT___;
