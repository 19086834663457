export const LOAD_POKA_AREAS_START = 'LOAD_POKA_AREAS_START';
export const LOAD_POKA_AREAS_SUCCESS = 'LOAD_POKA_AREAS_SUCCESS';
export const LOAD_POKA_AREAS_FAIL = 'LOAD_POKA_AREAS_FAIL';

export const GET_POKA_SOUR_AREAS_START = 'GET_POKA_SOUR_AREAS_START';
export const GET_POKA_SOUR_AREAS_SUCCESS = 'GET_POKA_SOUR_AREAS_SUCCESS';
export const GET_POKA_SOUR_AREAS_FAIL = 'GET_POKA_SOUR_AREAS_FAIL';

export const GET_POKA_CORRECT_AREAS_START = 'GET_POKA_CORRECT_AREAS_START';
export const GET_POKA_CORRECT_AREAS_SUCCESS = 'GET_POKA_CORRECT_AREAS_SUCCESS';
export const GET_POKA_CORRECT_AREAS_FAIL = 'GET_POKA_CORRECT_AREAS_FAIL';

export const SAVE_POKA_AREAS_SORT = 'SAVE_POKA_AREAS_SORT';
export const SAVE_POKA_AREAS_FILTERS = 'SAVE_POKA_AREAS_FILTERS';

export const EXPORT_POKA_CORRECT_AREA_START = 'EXPORT_POKA_CORRECT_AREA_START';
export const EXPORT_POKA_CORRECT_AREA_SUCCESS = 'EXPORT_POKA_CORRECT_AREA_SUCCESS';
export const EXPORT_POKA_CORRECT_AREA_FAIL = 'EXPORT_POKA_CORRECT_AREA_FAIL';
