import {
  CREATE_CFIELDVALUE_FAIL,
  CREATE_CFIELDVALUE_START,
  CREATE_CFIELDVALUE_SUCCESS,
  GET_CFIELDVALUE_FAIL,
  GET_CFIELDVALUE_START,
  GET_CFIELDVALUE_SUCCESS,
  UPDATE_CFIELDVALUE_FAIL,
  UPDATE_CFIELDVALUE_START,
  UPDATE_CFIELDVALUE_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, PATCH, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Загружаем значение произвольного поля
export const getProfileCustomFieldValue = (fieldId, userId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/custom-field/${fieldId}/value/${userId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CFIELDVALUE_START, GET_CFIELDVALUE_SUCCESS, GET_CFIELDVALUE_FAIL],
  onSuccess,
  onError,
});

// Создаем значение произвольного поля
export const createProfileCustomFieldValue = (userId, value, onSuccess, onError) => ({
  type: REST_API,
  method: PATCH,
  url: `${API_URL}/profile-fields/user/${userId}/values`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    userId,
    value,
  },
  types: [CREATE_CFIELDVALUE_START, CREATE_CFIELDVALUE_SUCCESS, CREATE_CFIELDVALUE_FAIL],
  onSuccess,
  onError,
});

// Обновляем значение произвольного поля
export const updateProfileCustomFieldValue = (userId, value, onSuccess, onError) => ({
  type: REST_API,
  method: PATCH,
  url: `${API_URL}/profile-fields/user/${userId}/values`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: value,
  types: [UPDATE_CFIELDVALUE_START, UPDATE_CFIELDVALUE_SUCCESS, UPDATE_CFIELDVALUE_FAIL],
  onSuccess,
  onError,
});
