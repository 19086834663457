import {
  GET_INSPECTION_STATUS_FAIL,
  GET_INSPECTION_STATUS_START,
  GET_INSPECTION_STATUS_SUCCESS,
  GET_TOPIC_INSPECTION_FAIL,
  GET_TOPIC_INSPECTION_START,
  GET_TOPIC_INSPECTION_SUCCESS,
  GET_USERS_FOR_INSPECTIONS_FAIL,
  GET_USERS_FOR_INSPECTIONS_START,
  GET_USERS_FOR_INSPECTIONS_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getTopicInspection = (body, query) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/inspection${getQueryString({ ...query, size: 20 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [GET_TOPIC_INSPECTION_START, GET_TOPIC_INSPECTION_SUCCESS, GET_TOPIC_INSPECTION_FAIL],
});

export const getInspectionStatus = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/inspection/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_INSPECTION_STATUS_START, GET_INSPECTION_STATUS_SUCCESS, GET_INSPECTION_STATUS_FAIL],
});

export const getUsersForInspection = fullName => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({ size: 50, fullName })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USERS_FOR_INSPECTIONS_START, GET_USERS_FOR_INSPECTIONS_SUCCESS, GET_USERS_FOR_INSPECTIONS_FAIL],
});
