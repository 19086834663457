/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { commentExecutiveTask } from '@/store/executive/actions';

import RejectCommentModal from '@/pages/RejectCommentModal';
// modals
import { SendCommentModal } from '@/pages/SendReviewModal';
import { useCollectionTopicsContext } from '@/pages/Topics/TopicDetailsDialog/ProviderContextProps';
import { CommentOutlined, MessageOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

import { REJECTED } from '@/constants/statuses';

const CommentsBlock = ({ courses }) => {
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [showModalRejected, setShowModalRejected] = useState(false);
  const { propsValues } = useCollectionTopicsContext();
  const [showModalConfirmRejectedCourse, setShowModalConfirmRejectedCourse] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation(['sendCommentModal', 'coursesTopicRow', 'constants']);

  const texts = {
    reason: t('reason'),
    comment: t('comment'),
    denying: t('denying'),
    deleting: t('deleting'),
    rescheduling: t('rescheduling'),
    max: t('max'),
  };

  const submitRejectedExecutiveTopic = (value, comment) => {
    if (value === REJECTED) {
      dispatch(
        commentExecutiveTask(courses.plan.user.id, courses.id, comment, '', () => {
          courses.plan.status = REJECTED;
          setShowModalRejected(false);
          document.location.reload();
        })
      );
    }
  };

  const submitRejectedCourseRequest = (value, comment) => {
    if (propsValues.typeTopicId && propsValues.typeTopicId === 25) {
      submitRejectedExecutiveTopic(value, comment);
      return;
    }

    propsValues.updateCoursesRequest(value, comment, [courses.id], () => {
      propsValues.getCoursesRequestTopic(
        {
          topicId: propsValues.topicId,
          ...propsValues.filter,
        },
        propsValues.subCompanies,
        propsValues.page - 1,
        !propsValues.withEvents
      );

      propsValues.changeSelect({
        courseId: courses.id,
      });

      setShowModalRejected(false);
    });
  };

  const commentSubmitRejectedCourseRequest = comment => {
    submitRejectedCourseRequest(REJECTED, comment);
  };

  const handleOpenComment = () => {
    setOpenCommentModal(true);
  };

  const handleCloseComment = () => {
    setOpenCommentModal(false);
  };

  const handleGetRequest = () => {
    propsValues.getCoursesRequestTopic(
      {
        topicId: propsValues.topicId,
        ...propsValues.filter,
      },
      propsValues.subCompanies,
      propsValues.page - 1,
      !propsValues.withEvents
    );
  };

  const hideRejectedCourseRequest = comment => {
    if (comment && comment.length > 0) {
      return setShowModalConfirmRejectedCourse(!showModalConfirmRejectedCourse);
    }

    setShowModalRejected(false);
  };

  return (
    <>
      {courses.plan && (
        <div className={classNames(css['columns--message'], css['columns--message-mini'])}>
          <CommentOutlined onClick={handleOpenComment} />
        </div>
      )}
      {showModalRejected && (
        <SendCommentModal
          rejected
          open
          submitDeleteComment={commentSubmitRejectedCourseRequest}
          cancelDeleteComment={hideRejectedCourseRequest}
          texts={texts}
        />
      )}
      {openCommentModal && (
        <RejectCommentModal
          key={courses.id}
          open
          cancelRejectCommentModal={handleCloseComment}
          coursesId={courses.id}
          courses={courses}
          handleGetRequest={handleGetRequest}
          getCommentRejected={propsValues.getCommentRejected}
          postCommentRejected={propsValues.postCommentRejected}
          coursesRequestComment={propsValues.coursesRequestComment}
          coursesRequestCommentNumber={propsValues.coursesRequestCommentNumber}
          coursesRequestCommentPageSize={propsValues.coursesRequestCommentPageSize}
          coursesRequestCommentTotal={propsValues.coursesRequestCommentTotal}
        />
      )}
    </>
  );
};

CommentsBlock.propTypes = {
  courses: PropTypes.object,
};

export default CommentsBlock;
