/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { addInactiveBadge, badgeBindTopic, clearInactiveBadge, getListBadges } from '@/store/badge/actions';
import { changeBookAddress, getBookAddresses, saveBookAddresses } from '@/store/books/actions';
import { clearCertificates, getCertificates } from '@/store/certificate/actions';
import { getEventTopic } from '@/store/courses/actions';
import { getExecutiveTopicInfo, storeExecutiveTopicInfo, updateExecutiveTopicInfo } from '@/store/executive/actions';
import { createFile, createFileImage, getFile } from '@/store/files/actions';
import { getTopicTypeLabel, getTypeLabels } from '@/store/label_topic/actions';
import { getLanguages } from '@/store/locales/actions';
import { getPokaDirCategory, getPokaDirLevel, getPokaDirSite } from '@/store/poka_skills/actions';
import { getPositionInternal } from '@/store/position/actions';
import {
  createTopic,
  createTopicByTemplate,
  editTopic,
  editTopicVisibility,
  failTopicValidation,
  failTopicValidationCharacter,
  getMerits,
  getTopicById,
  getTopicCategories,
  getTopicCategoryCompetencies,
  getTopicClasses,
  getTopicJobLevels,
  getTopicLevel,
  getTopicMandatory,
  getTopicSubTypes,
  getTopicTypes,
  getTopicVideoTypes,
} from '@/store/topics/actions';

import { selectBadge } from '@/store/badge/selectors';
import { selectBooks } from '@/store/books/selectors';
import { selectCertificates } from '@/store/certificate/selectors';
import { selectFiles as filesSelect } from '@/store/files/selectors';
import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectLocales } from '@/store/locales/selectors';
import { selectPokaSkills } from '@/store/poka_skills/selectors';
import { selectPositions } from '@/store/position/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Checkbox, Divider, Form, Input, message, Modal, Select, Tag, Tooltip } from 'antd';
import { STATUS_ACTIVE, STATUS_INACTIVE, TOPIC_FINISH } from '@/pages/Badge/constants';
import ImageInput from '@/components/ImageInput';
import ModalCategory from '@/components/ModalCategory';
import { QuestionCircleOutlined } from '@ant-design/icons';

import AdditionalInputCard from './AdditionalInputCard';

import TagsAutocomplete from '@shared/components/TagsAutocomplete';
import { IMAGE_TYPES } from '@shared/constants/image-types';
import { TopicTypes } from '@shared/constants/topics';
import { isBook, isConference, isExecutiveTask, isSkills, isTestCourses } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isNaN from 'lodash/isNaN';
import _uniq from 'lodash/uniq';
import { PropTypes } from 'prop-types';

import css from './TopicCreationDialog.module.scss';

import { SERIES_MAX } from '@/constants/topic';
import { COMMON_FIELDS, FIELDS, TOPIC_SUBTYPES, TOPIC_TYPES } from '@/constants/topic-types';

dayjs.extend(objectSupport);

const { TextArea } = Input;

export const HARD = 'Hard';
export const SOFT = 'Soft';

const topicsWithSubTypes = [17, 20, 35];

const YYYY_MM_DD = 'YYYY-MM-DD';
const HH_MM_SS = 'HH:mm:ss';

const IsManager = currentUser =>
  (currentUser.roleId === 3 && !currentUser.parentRoleId) ||
  (currentUser.parentRoleId === 3 && currentUser.roleId !== 3);

export class TopicCreationDialog extends Component {
  topicSkillList = [
    { id: 1, name: SOFT },
    { id: 2, name: HARD },
  ];

  defaultTrajectoryValues = {
    awareness: 0,
    involvement: 0,
    mentalFlexibility: 0,
    minAwareness: 0,
    minInvolvement: 0,
    maxInvolvement: 0,
  };

  constructor(props) {
    super(props);

    if (props.topic) {
      const {
        type,
        tags,
        skills,
        categories,
        videoType,
        qiwi,
        topicClass,
        thumbFileUuid,
        level,
        qiwiLibrary,
        dates,
        subType,
        files,
        scope,
        careers,
        awareness,
        mentalFlexibility,
        involvement,
        minAwareness,
        minInvolvement,
        maxInvolvement,
        language,
        languages,
        ...topic
      } = this.props.topic;

      const uniqMerits = _uniq([
        awareness,
        mentalFlexibility,
        involvement,
        minAwareness,
        minInvolvement,
        maxInvolvement,
      ]);

      const isSoft = careers?.length === 0 && (uniqMerits.length > 1 || uniqMerits[0] !== 0);
      const isHard = careers?.length > 0;

      const isCertificateNumbers = topic.certificateDto?.maxNumber && topic.certificateDto?.minNumber;
      const certificateMaxLength = isCertificateNumbers ? String(topic.certificateDto.maxNumber).length : 0;

      const certificateMin = isCertificateNumbers
        ? String(topic.certificateDto.minNumber).padStart(certificateMaxLength, '0')
        : '';
      const certificateMax = isCertificateNumbers ? String(topic.certificateDto.maxNumber) : '';

      const eventDates = !dates || !dates.length ? [{ dates: [], endTime: '', startTime: '', place: '' }] : dates;

      this.state = {
        ...topic,
        files,
        fileUuids: files && files?.map(file => file.uuid),
        qiwi: qiwi || false,
        qiwiLibrary: qiwiLibrary || false,
        addressBookVisible: qiwiLibrary || false,
        addressBook: [],
        addressesDelete: [], // {id: string, address: string }[]
        addressesAdd: [], // number[]
        changeAddressList: false,
        typeId: type && +type.id,
        subTypeId: subType?.id,
        scope,
        statusScope: scope === 'INTERNAL',
        categoryIds: categories,
        levelId: level?.id,
        languageId: language?.id,
        videoTypeId: videoType?.id,
        topicClassId: topicClass?.id,
        tagNames: tags || [],
        careers: careers || [],
        skillNames: skills || [],
        dates: eventDates,
        errors: {},
        confirmVisible: false,
        confirmCloseCreate: false,
        formChange: false,
        imageSrc: '',
        thumbFileUuid,
        typeTrajectory: isSoft ? SOFT : isHard ? HARD : undefined,
        awareness,
        mentalFlexibility,
        involvement,
        minAwareness,
        minInvolvement,
        maxInvolvement,
        executiveId: null,
        executive_duration: 0,
        executive_results: '',
        executive_activity: '',
        executive_indicators: '',
        executive_needfile: false,
        typeLabelId: topic?.typeLabel && topic.typeLabel.id,
        webinarPlatform: '1',
        percentSitIn: 0,
        entryPlatform: null,
        webinarType: '',
        webinarCreateEventRequest: [],
        hasCertificate: Boolean(topic.certificateDto),
        certificateRequest: {
          id: topic.certificateDto?.id,
          minNumber: certificateMin,
          maxNumber: certificateMax,
          series: topic.certificateDto?.series,
        },
        qiwiLibraryError: false,
        topicCompetenceCategory: topic.topicCompetenceCategory,
        topicMandatory: topic.topicMandatory,
        jobFamilies: topic.jobFamilies,
        jobLevels: topic.jobLevels,
        hasBadge: false,
        badgeId: null,
        poka: {
          ...topic?.poka,
          categories: topic?.poka?.categories?.map(({ value }) => value),
          assessors: topic?.poka?.assessors?.map(({ id, name }) => ({ id, name })),
          sites: topic?.poka?.sites?.map(({ id }) => id),
          endorsementLevel: topic?.poka?.endorsementLevel?.value,
        },
      };
    } else {
      this.state = {
        authors: [],
        addressBook: [],
        addressesDelete: [], // {id: string, address: string }[]
        addressesAdd: [], // number[]
        tagNames: [],
        skillNames: [],
        qiwi: false,
        qiwiLibrary: false,
        errors: {},
        dates: [{ dates: [], endTime: '', startTime: '', place: '' }],
        scope: 'EXTERNAL',
        statusScope: false,
        confirmVisible: false,
        confirmCloseCreate: false,
        imageSrc: '',
        eventName: this.props.t('conference'),
        languageId: this.props.languages?.length && this.props.languages[0].id,
        currentLabel: { approvedDisabled: true, withEventsDisabled: true },
        typeLabelId: null,
        hasCertificate: false,
        certificateRequest: {
          id: null,
          minNumber: null,
          maxNumber: null,
          series: null,
        },
        qiwiLibraryError: false,
        topicCompetenceCategory: null,
        topicMandatory: null,
        hasBadge: false,
        badgeId: null,
        poka: {
          withoutValidation: false,
        },
      };
    }

    this.props.getTopicTypes();

    if (!this.props.topicCategories) {
      this.props.getTopicCategories(this.props.isGlobal);
    }

    if (!this.props.topicLevel) {
      this.props.getTopicLevel();
    }

    if (!this.props.topicVideoTypes) {
      this.props.getTopicVideoTypes();
    }

    if (this.props.topic?.type) {
      this.props.getTopicSubTypes(this.props.topic.type.id);
    }

    if (!this.props.topicClasses) {
      this.props.getTopicClasses();
    }

    if (!this.props.merits) {
      this.props.getMerits();
    }
  }

  componentDidMount() {
    this.props.getTypeLabels(undefined, this.props.isSkills);
    this.props.getCertificates();
    this.props.getPositionInternal();
    this.props.getTopicJobLevels();
    if (this.props.isSkills) {
      this.props.getPokaDirCategory();
      this.props.getPokaDirSite();
      this.props.getPokaDirLevel();
    }

    if (!this.props.topicMandatories?.length) {
      this.props.getTopicMandatory();
    }

    if (!this.props.topicCategoryCompetencies?.length) {
      this.props.getTopicCategoryCompetencies();
    }

    this.props.getListBadges({ action: TOPIC_FINISH, status: STATUS_ACTIVE }, 1000);

    if (this.props.topic?.rewardsDto?.length > 0) {
      const reward = this.props.topic.rewardsDto.find(i => i.id && i.name);
      this.setState({ hasBadge: Boolean(reward), badgeId: reward?.id });

      if (reward.status === STATUS_INACTIVE) {
        this.props.addInactiveBadge({ ...reward, name: `${reward.name} (${this.props.t('inactiveBadge')})` });
      }
    }

    if (isBook(this.props.topic?.type?.id) && this.state.qiwiLibrary) {
      this.props.getBookAddresses(this.props.topic.id, res => {
        const addressBook = res.map(el => ({
          id: el.addressDto?.id,
          name: el.addressDto?.address,
          accessible: el.accessible,
        }));

        const isNotAllDeletable = addressBook.some(addr => !addr.accessible);
        if (isNotAllDeletable) {
          this.setState({ qiwiLibraryError: true });
        }

        this.setState({ addressBook });
      });
    }

    if (this.props.topic?.coverFileUuid) {
      this.props.getFile(this.props.topic.coverFileUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    }

    if (!this.props.languages.length) {
      this.props.getLanguages(response => !this.state.languageId && this.setState({ languageId: response[0].id }));
    }

    if (this.props?.topic?.type && isConference(this.props.topic.type.id)) {
      this.props.getEventTopic(this.props.topic.id, {}, ([event]) => {
        this.setState({
          dates: event.periods,
          eventName: event.name,
          managerId: _get(event, 'manager.id'),
          participantsCount: event.participantsCount,
        });
      });
    }

    if (this.props.topic?.id && this.props.topic.type && isExecutiveTask(this.props.topic.type.id)) {
      this.props.getExecutiveTopicInfo(
        this.props.topic.id,
        res => {
          if (res?.id) {
            this.setState({
              executiveId: res.topicId,
              executive_duration: Number(res.duration),
              executive_results: res.resultInfo,
              executive_activity: res.activityInfo,
              executive_indicators: res.indicatorInfo,
              executive_needfile: res.requiredFile,
            });
          }
        },
        err => {
          this.setState({
            executiveId: null,
            executive_duration: 0,
            executive_results: 'error',
            executive_activity: 'error',
            executive_indicators: 'error',
            executive_needfile: false,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.typeLabel !== this.props.typeLabel && this.props.isSkills) {
      this.handleChangeLabel(this.props.typeLabel.find(item => isSkills(item.topicTypeId))?.id);
    }
  }

  componentWillUnmount() {
    this.props.clearCertificates();
    this.props.clearInactiveBadge();
  }

  handleChangePoka = (value, name) => {
    if (name === 'withoutValidation') {
      this.setState({ poka: { ...this.state.poka, [name]: value, assessors: value ? [] : undefined } });
    } else {
      this.setState({ poka: { ...this.state.poka, [name]: value } });
    }
  };

  handleChange = (valueItem, nameItem) => {
    const value = nameItem ? valueItem : valueItem.value || valueItem.target?.value;
    const name = nameItem ? nameItem : valueItem.name || valueItem.target?.name;

    switch (name) {
      case 'executive_duration':
      case 'executive_results':
      case 'executive_activity':
      case 'executive_indicators':
      case 'percentSitIn':
      case 'entryPlatform':
      case 'webinarType':
      case 'badgeId':
        this.setState({ [name]: value });
        break;

      case 'executive_needfile':
        if (value !== true || value !== 'true' || value !== 1 || value !== '1') {
          this.setState({ executive_needfile: false });
        } else {
          this.setState({ executive_needfile: true });
        }

        break;

      case 'webinarPlatform':
        this.setState({ webinarPlatform: value });
        value !== '1' &&
          this.setState({
            dates: this.state.dates.map((item, index) => ({
              ...item,
              place: `${this.props.t('linkWithSchedule', { number: index + 1 })}`,
            })),
          });
        break;

      case 'qiwiLibrary':
        if (!value) {
          const isNotAllDeletable = this.state.addressBook.some(addr => !addr.accessible);
          if (isNotAllDeletable) {
            this.setState(({ errors }) => ({
              errors: {
                ...errors,
                qiwiLibrary: this.props.t('bookAddressNotAccessible'),
              },
            }));

            return;
          }
        }

        this.setState({ addressBookVisible: value, qiwiLibraryError: false });
        break;

      case 'withEvents':
        this.setState({ currentLabel: { ...this.state.currentLabel, withEvents: value } });

        this.props.getTopicTypeLabel(this.state.currentLabel.id, this.state.currentLabel.approved, value, res => {
          this.setState({ typeId: res.topicTypeId });
          this.props.getTopicSubTypes(res.topicTypeId, this.handleSubtypeChange);
        });
        break;

      case 'approved':
        this.setState({ currentLabel: { ...this.state.currentLabel, approved: value } });

        this.props.getTopicTypeLabel(this.state.currentLabel.id, value, this.state.currentLabel.withEvents, res => {
          this.setState({ typeId: res.topicTypeId });
          this.props.getTopicSubTypes(res.topicTypeId, this.handleSubtypeChange);
        });
        break;

      case 'participantsCount':
      case 'topicLimit':
        if (Number(value) < 0) {
          this.setState({ [name]: 0 });
          return;
        }
        break;

      case 'hasCertificate':
        if (value) {
          this.props.getCertificates();
          this.setState({ certificateRequest: {}, formChange: true });
        } else {
          this.props.clearCertificates();
          this.setState({ certificateRequest: null, formChange: true });
        }
        break;

      case 'certificateNumbersMin': {
        let certificateRangeError = '';
        const minNumber = value;
        const maxNumber = this.state.certificateRequest.maxNumber;

        const minToNumber = Number(minNumber);
        const maxToNumber = Number(maxNumber);

        if (minNumber && maxNumber && (minToNumber >= maxToNumber || minToNumber <= 0 || maxToNumber <= 0)) {
          certificateRangeError = this.props.t('certificateRangeError');
        }

        this.setState({
          certificateRequest: {
            ...this.state.certificateRequest,
            minNumber,
          },
          errors: {
            ...this.state.errors,
            certificateRange: certificateRangeError,
          },
          formChange: true,
        });

        return;
      }

      case 'certificateNumbersMax': {
        let certificateRangeError = '';
        const maxNumber = value;
        const minNumber = this.state.certificateRequest.minNumber;

        const minToNumber = Number(minNumber);
        const maxToNumber = Number(maxNumber);

        if (minNumber && maxNumber && (minToNumber >= maxToNumber || minToNumber <= 0 || maxToNumber <= 0)) {
          certificateRangeError = this.props.t('certificateRangeError');
        }

        this.setState({
          certificateRequest: {
            ...this.state.certificateRequest,
            maxNumber,
          },
          errors: {
            ...this.state.errors,
            certificateRange: certificateRangeError,
          },
          formChange: true,
        });
        return;
      }

      case 'certificateId': {
        this.setState({
          certificateRequest: {
            ...this.state.certificateRequest,
            id: value ? Number(value) : 0,
          },
          formChange: true,
        });
        return;
      }

      case 'certificateSeries': {
        this.setState({
          certificateRequest: {
            ...this.state.certificateRequest,
            series: value,
          },
          formChange: true,
        });
        return;
      }

      case 'addressBook': {
        const newState = { [name]: valueItem.value, changeAddressList: true };

        if (valueItem.addressAdd && Object.keys(valueItem.addressAdd).length) {
          const addressAdd = { ...valueItem.addressAdd };

          newState.addressesAdd = [...this.state.addressesAdd, addressAdd];
          if (valueItem.addressAdd.id !== '0') {
            newState.addressesDelete = this.state.addressesDelete.filter(
              id => Number(id) != Number(valueItem.addressAdd.id)
            );
          }
        }

        if (valueItem.removedTagId) {
          newState.addressesDelete = [...this.state.addressesDelete, valueItem.removedTagId];
          newState.addressesAdd = this.state.addressesAdd.filter(
            addr => Number(addr.id) !== Number(valueItem.removedTagId)
          );
        }

        if (valueItem.removedTagName) {
          newState.addressesAdd = this.state.addressesAdd.filter(addr => addr.name !== valueItem.removedTagName);
        }

        this.setState(prevState => ({
          ...newState,
          qiwiLibraryError: false,
          errors: { ...prevState.errors, qiwiLibrary: '' },
        }));

        return;
      }

      case 'name': {
        let nameError = '';

        if (value.includes(';')) {
          nameError = this.props.t('invalidCharacter');
        }

        if (!value?.trim()) {
          nameError = this.props.t('require');
        }

        this.setState(prevState => ({
          name: value,
          errors: {
            ...prevState.errors,
            name: nameError,
          },
        }));
        return;
      }

      case 'topicCompetenceCategory': {
        this.setState({ ...this.state, topicCompetenceCategory: value });
        return;
      }

      case 'topicMandatory': {
        this.setState({ ...this.state, topicMandatory: value });
        return;
      }

      case 'description': {
        let descriptionError = '';

        if (!value?.trim()) {
          descriptionError = this.props.t('require');
        }

        this.setState(prevState => ({
          description: value,
          errors: {
            ...prevState.errors,
            description: descriptionError,
          },
        }));
        return;
      }

      case 'hasBadge':
        if (!value) {
          this.setState({ badgeId: null });
        }
        break;

      case 'tagNames':
      case 'skillNames':
        const newValue = value?.value ? value.value : value;

        this.setState({ [name]: newValue, formChange: true });
        return;
    }

    this.setState({ [name]: value?.value ? value.value : value, formChange: true });

    if (name === 'startDate') {
      this.setState({
        [name]: value ? dayjs(value).format(YYYY_MM_DD) : null,
        formChange: true,
      });
    }

    if (name === 'duration') {
      const { selectedMerit } = this.state;

      if (selectedMerit && value) {
        this.handleMeritChange({ value: selectedMerit, duration: value });
      }
    }
  };

  handleSubtypeChange = subtypes => {
    if (!Array.isArray(subtypes) && Object.keys(subtypes).length > 0) {
      this.setState({ subTypeId: subtypes?.value, formChange: true });
    } else if (subtypes && subtypes[0]) {
      const sub = this.state.typeId === 20 ? subtypes[0] : subtypes[1];
      this.setState({ subTypeId: sub?.id, formChange: true });
    }
  };

  handleTypeChange = event => {
    const { value } = event.target || event;

    this.setState(
      {
        typeId: +value,
        subTypeId: +value === 1 ? 24 : null,
        errors: {},
        comment: null,
        formChange: true,
      },
      () => {
        if (value) {
          this.props.getTopicSubTypes(value, this.handleSubtypeChange);
        }
      }
    );
  };

  handleLinksChange = event => {
    const { name, value } = event.target || event;
    const stateValues = this.state[name];
    const undeliteble = Array.isArray(stateValues) ? stateValues.filter(values => values.undeletable) : [];

    const linksValue =
      value &&
      value
        .filter(link => !!link)
        .map(link => {
          const oldOne = Array.isArray(stateValues) ? stateValues.find(field => field.name === link) : null;

          return oldOne || { name: link, url: link };
        });

    this.setState({
      [name]: [...undeliteble, ...linksValue],
      formChange: true,
    });
  };

  handleAuthorsChange = event => {
    const { name, value } = event.target || event;
    const authorsValue = value && value.map(link => link);
    this.setState({ [name]: authorsValue, formChange: true });
  };

  handleDateTimeChange = event => {
    const { name, hours, minutes } = event.target || event;

    const parsedHours = parseInt(hours, 10) || 0;
    const parsedMinutes = parseInt(minutes, 10) || 0;

    if (parsedHours < 0 || parsedMinutes < 0) {
      return;
    }

    const value = dayjs().set('hour', parsedHours).set('minute', parsedMinutes).set('second', 0).set('millisecond', 0);

    if (!value.isValid()) {
      return;
    }

    let formattedValue = value.format(HH_MM_SS);

    if (formattedValue === '00:00:00') {
      formattedValue = null;
    }

    this.setState({ [name]: formattedValue, formChange: true });
  };

  handleYearChange = (value, name) => {
    const resultValue = dayjs(value).format(YYYY_MM_DD, true) ? dayjs({ year: value }, YYYY_MM_DD, true) : undefined;

    this.setState({ [name]: resultValue, formChange: true });
  };

  isLinkCorrect = fieldName => {
    let flag = false;
    this.state[fieldName] &&
      this.state[fieldName].map(link => {
        if (
          link.url.indexOf('http://') !== 0 &&
          link.url.indexOf('https://') !== 0 &&
          link.url.indexOf('ftp://') !== 0
        ) {
          flag = true;
        }
      });

    return flag;
  };

  isScheduleIncorrect = fieldName => {
    if (!this.state[fieldName] || !this.state[fieldName].length) {
      return true;
    }
    return this.state[fieldName].reduce((res, schedule) => {
      return res || !schedule.dates.length || !schedule.endTime || !schedule.startTime || !schedule.place;
    }, false);
  };

  isFieldEmpty = fieldName => {
    if (fieldName === 'merits') {
      const val = [
        this.state.awareness,
        this.state.mentalFlexibility,
        this.state.involvement,
        this.state.maxInvolvement,
        this.state.minAwareness,
        this.state.minInvolvement,
      ];
      return val.filter(item => !_isNaN(parseInt(item, 10))).length !== val.length;
    }
    if (typeof this.state[fieldName] === 'boolean') {
      return false;
    }

    if (Array.isArray(this.state[fieldName])) {
      return !this.state[fieldName].length;
    }

    if (this.state.poka[fieldName]) {
      return false;
    }

    if (fieldName === 'cost') {
      return this.state[fieldName] < 0 || this.state[fieldName] === null || this.state[fieldName] === undefined;
    }

    return !this.state[fieldName];
  };

  isScheduleCorrect = () => {
    const flag = [];
    this.state.dates &&
      this.state.dates.map((schedule, item) => {
        if (!schedule?.startTime || !schedule?.endTime || !schedule?.place || schedule?.dates?.length === 0) {
          flag.push(item);
        }
      });

    return flag;
  };

  formValidation = () => {
    const errors = {};
    let haveErrors = false;
    const staticFieldValidation = ['name', 'description'];

    for (let i = 0; i < COMMON_FIELDS.length; i++) {
      const field = COMMON_FIELDS[i];
      if (field.required && this.isFieldEmpty(field.apiField)) {
        if ((field.option && this.state.typeTrajectory === field.option) || !field.option) {
          errors[field.apiField] = this.props.t('require');
          haveErrors = true;
        }
      }
    }

    // Проверка полей 'Название' и 'Описание'
    staticFieldValidation.forEach(field => {
      if (!this.state?.[field]?.trim()) {
        errors[field] = this.props.t('require');
        haveErrors = true;
      }
      if (field === 'name' && this.state?.name?.includes(';')) {
        errors[field] = this.props.t('invalidCharacter');
        haveErrors = true;
      }
    });

    const topicType = _find(TOPIC_TYPES(this.props.t), type => type.id === +this.state.typeId) || {};
    const topicTypeTab = _find(
      TOPIC_SUBTYPES(this.props.t),
      subtype => subtype.active && subtype.typeId === +this.state.typeId && subtype.id === this.state.subTypeId
    );

    const topicTypeObject = topicTypeTab || topicType;
    const topicTypeFields = topicTypeObject.fields || [];
    for (let i = 0; i < topicTypeFields.length; i++) {
      const field = topicTypeFields[i];
      const fieldObject = FIELDS(this.props.t)[field.type];

      if (
        field.type === 'poka_assessors' &&
        (!this.state.poka.assessors || !this.state.poka.assessors?.length) &&
        !this.state.poka.withoutValidation
      ) {
        errors[fieldObject.apiField] = this.props.t('require');
        haveErrors = true;
      }

      if (field.required && this.isFieldEmpty(fieldObject.apiField)) {
        errors[fieldObject.apiField] = this.props.t('require');
        haveErrors = true;
      }

      if (fieldObject.apiField === 'links' && this.isLinkCorrect(fieldObject.apiField)) {
        errors[fieldObject.apiField] = this.props.t('link');
        haveErrors = true;
      }

      if (fieldObject.apiField === 'dates' && this.isScheduleIncorrect(fieldObject.apiField)) {
        const scheduleCorrect = this.isScheduleCorrect();
        if (scheduleCorrect.length) {
          errors[fieldObject.apiField] = scheduleCorrect;
          haveErrors = true;
        }
      }

      if (fieldObject.apiField === 'cost' && this.state[fieldObject.apiField] < this.state['costMin']) {
        errors[fieldObject.apiField] = this.props.t('costError');
        haveErrors = true;
      }

      if (
        fieldObject.apiField === 'entryPlatform' ||
        fieldObject.apiField === 'percentSitIn' ||
        fieldObject.apiField === 'webinarType'
      ) {
        if (
          !this.isFieldEmpty('webinarPlatform') &&
          this.state?.webinarPlatform !== '1' &&
          this.isFieldEmpty(fieldObject.apiField)
        ) {
          haveErrors = true;
          errors[fieldObject.apiField] = this.props.t('require');
        } else {
          errors[fieldObject.apiField] = '';
        }
      }

      const requiredFieldObject = FIELDS(this.props.t)[field.requiredIfEmpty] || {};
      if (
        field.requiredIfEmpty &&
        this.isFieldEmpty(fieldObject.apiField) &&
        this.isFieldEmpty(requiredFieldObject.apiField)
      ) {
        errors[fieldObject.apiField] = this.props.t('link2', {
          label: requiredFieldObject.label || '',
        });
        haveErrors = true;
      }
    }

    if (this.state.hasCertificate) {
      const certificateData = this.state.certificateRequest;
      if (!certificateData.id) {
        errors.certificateId = this.props.t('certificateSelectWarning');
        haveErrors = true;
      }

      const minNumber = Number(certificateData.minNumber);
      const maxNumber = Number(certificateData.maxNumber);

      if ((minNumber && !maxNumber) || (!minNumber && maxNumber)) {
        errors.certificateRange = this.props.t('certificateRangeAddWarning');
        haveErrors = true;
      }

      if ((minNumber > 0 && minNumber > maxNumber) || minNumber < 0 || maxNumber < 0) {
        errors.certificateRange = this.props.t('certificateRangeWrongWarning');
        haveErrors = true;
      }
    }

    if (this.state.hasBadge && !this.state.badgeId) {
      errors.badgeId = this.props.t('badgeSelectWarning');
      haveErrors = true;
    }

    this.setState({ errors });
    if (haveErrors) {
      this.props.failTopicValidation();
      if (this.state?.name?.includes(';')) {
        this.props.failTopicValidationCharacter();
      }
    }

    return !haveErrors;
  };

  tagMap = item => ({ id: String(item.id).startsWith('new') ? 0 : item.id, name: item.name });
  tagAdressMap = item => ({ id: String(item?.id).startsWith('new') ? null : item?.id, name: item?.name });

  prepareAddresses = addresses => {
    return addresses.map(addr => {
      if (addr.id == '0' || String(addr.id).startsWith('new-')) {
        addr.id = null;
      }

      addr.address = addr.name;
      delete addr.name;
      delete addr.accessible;

      return addr;
    });
  };

  onSuccessForNewTopic = topicCreated => {
    const { ...topic } = this.state;
    this.setState({ createBtnClick: false });

    // добавление адресов бумажных книг
    if (isBook(topic.typeId)) {
      if (this.state.qiwiLibrary && (this.state.changeAddressList || this.props.template)) {
        const topicId = topicCreated.id;
        const addresses = this.prepareAddresses(this.state.addressesAdd?.map(this.tagAdressMap));

        if (addresses.length > 0) {
          this.props.saveBookAddresses(
            topicId,
            addresses,
            () => this.setState({ changeAddressList: false, addressesAdd: [], addressesDelete: [] }),
            err => message.error(err)
          );
        }
      }

      if (!this.state.qiwiLibrary) {
        this.setState({ addressBook: [], addressesAdd: [], addressesDelete: [] });
      }
    }

    if (isExecutiveTask(topic.typeId)) {
      let nf = false;
      let files = [];
      if (
        this.state.executive_needfile === true ||
        this.state.executive_needfile == 1 ||
        (this.state.fileUuids && this.state.fileUuids.length)
      ) {
        nf = true;
      }

      if (this.state.fileUuids?.length) {
        files = this.state.fileUuids;
      }

      this.props.storeExecutiveTopicInfo(
        files,
        topicCreated.id,
        this.state.executive_results,
        this.state.executive_duration,
        this.state.executive_activity,
        this.state.executive_indicators,
        nf
      );
    }

    this.props.onTopicCreationSuccess();
    this.props.editTopicVisibility(topicCreated, false);
    if (!this.props.template && this.state.badgeId & this.state.hasBadge) {
      this.props.badgeBindTopic(
        { topicId: topicCreated.id, rewardId: this.state.badgeId },
        () => message.success(this.props.t('addedBadge')),
        () => message.error(this.props.t('errorAddedBadge'))
      );
    }
  };

  handleAddTopicClick = () => {
    const { ...topic } = this.state;

    delete topic.currentLabel;
    delete topic.certificateDto;
    delete topic.badgeId;

    topic.errors = '';
    topic.confirmVisible = '';
    topic.categoryIds = topic.categoryIds && topic.categoryIds.map(cat => cat.id);

    topic.jobLevels = this.state.jobLevels?.every(elem => typeof elem === 'object')
      ? this.state.jobLevels.map(elem => elem.id)
      : this.state.jobLevels;
    topic.jobFamilies = this.state.jobFamilies?.every(elem => typeof elem === 'object')
      ? this.state.jobFamilies.map(elem => elem.id)
      : this.state.jobFamilies;

    topic.tags = topic.tagNames.map(this.tagMap);
    topic.skills = topic.skillNames.map(this.tagMap);
    topic.authors = topic.authors.map(this.tagMap);
    topic.addressBook = topic.addressBook.map(this.tagAdressMap);
    topic.addressesAdd = topic.addressesAdd.map(this.tagAdressMap);

    if (topic?.poka?.assessors?.every(item => typeof item === 'object')) {
      topic.poka.assessors = topic.poka.assessors.map(item => item.id);
    }

    const resultDates = [];
    topic.dates.map(shed => {
      shed.dates.map(date => {
        const startTime = typeof shed.startTime === 'string' ? shed.startTime : shed.startTime.format(HH_MM_SS);
        const endTime = typeof shed.endTime === 'string' ? shed.endTime : shed.endTime.format(HH_MM_SS);

        resultDates.push({
          startDate: date,
          startTime,
          endTime,
          place: shed.place,
        });
      });
    });

    const webinarResult = [];

    if (Utils.canUseWebinar(topic.typeId) && topic.webinarPlatform !== '1') {
      topic.dates.map(shed => {
        shed.dates.map(date => {
          const startTime = shed.startTime.format('HH:mm');
          const endTime = shed.endTime.format('HH:mm');

          const startsAt = `${dayjs(date).format(YYYY_MM_DD)}T${startTime}`;
          const endsAt = `${dayjs(date).format(YYYY_MM_DD)}T${endTime}`;

          webinarResult.push({
            startsAt,
            endsAt,
            access: Number(this.state.entryPlatform),
            plannedInvolvement: this.state.percentSitIn && this.state.percentSitIn.toString(),
            name: topic.name,
            webinarType: this.state.webinarType,
          });
        });
      });
    }

    topic.webinarCreateEventRequest = webinarResult;
    topic.dates = resultDates;
    topic.scope = this.state.scope;

    if (isExecutiveTask(topic.typeId) && topic.executive_duration) {
      topic.duration = topic.executive_duration;
    }

    if (!topicsWithSubTypes.includes(topic.typeId)) {
      topic.subTypeId = null;
    }

    if (this.formValidation()) {
      this.setState({ createBtnClick: true });

      if (this.props.template) {
        this.props.createTopicByTemplate(topic, topicCreated => this.onSuccessForNewTopic(topicCreated));
      } else {
        this.props.createTopic(topic, topicCreated => this.onSuccessForNewTopic(topicCreated));
      }
    }
  };

  // Редактирование материала
  handleEditTopicClick = () => {
    const { ...topic } = this.state;

    delete topic.certificateDto;
    delete topic.badgeId;

    topic.tags = topic.tagNames.map(this.tagMap);
    topic.skills = topic.skillNames.map(this.tagMap);
    topic.authors = topic.authors.map(this.tagMap);
    topic.addressBook = topic.addressBook.map(this.tagAdressMap);
    topic.addressesAdd = topic.addressesAdd.map(this.tagAdressMap);

    if (topic?.poka?.assessors?.every(item => typeof item === 'object')) {
      topic.poka.assessors = topic.poka.assessors.map(item => item.id);
    }

    topic.errors = '';
    topic.confirmVisible = '';
    topic.categoryIds = topic.categoryIds && topic.categoryIds.map(cat => cat.id);
    const resultDates = [];
    topic.dates.map(shed => {
      shed.dates.map(date => {
        const startDate = dayjs(date).format(YYYY_MM_DD);
        const startTime = dayjs(shed.startTime, HH_MM_SS).format(HH_MM_SS);
        const endTime = dayjs(shed.endTime, HH_MM_SS).format(HH_MM_SS);
        const place = shed.place;

        resultDates.push({ startDate, startTime, endTime, place });
      });
    });

    topic.jobLevels = this.state.jobLevels?.every(elem => typeof elem === 'object')
      ? this.state.jobLevels.map(elem => elem.id)
      : this.state.jobLevels;
    topic.jobFamilies = this.state.jobFamilies?.every(elem => typeof elem === 'object')
      ? this.state.jobFamilies.map(elem => elem.id)
      : this.state.jobFamilies;

    const webinarResult = [];

    if (Utils.canUseWebinar(topic.typeId) && topic.webinarPlatform !== '1') {
      topic.dates.map(shed => {
        shed.dates.map((date, index) => {
          const startTime = shed.startTime.format('HH:mm');
          const endTime = shed.endTime.format('HH:mm');

          const startsAt = `${dayjs(date).format(YYYY_MM_DD)}T${startTime}`;
          const endsAt = `${dayjs(date).format(YYYY_MM_DD)}T${endTime}`;

          webinarResult.push({
            startsAt,
            endsAt,
            access: Number(this.state.entryPlatform),
            plannedInvolvement: this.state.percentSitIn && this.state.percentSitIn.toString(),
            name: topic.name,
            eventId:
              Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length <= index
                ? _find(topic.webinarCreateEventResponse, { eventLink: shed.eventLinks[index] })?.eventId
                : null,
            webinarType: this.state.webinarType,
          });
        });
      });
    }

    topic.webinarCreateEventRequest = webinarResult;

    topic.dates = resultDates;
    if (this.formValidation()) {
      if (this.state.scope === 'INTERNAL') {
        topic.scope = 'INTERNAL';
      } else if (this.state.scope === 'UNMODERATE') {
        topic.scope = 'UNMODERATE';
      } else {
        topic.scope = 'EXTERNAL';
      }
      this.setState({ createBtnClick: true });

      this.props.editTopic(
        topic,
        () => {
          const needBind = this.props.topic.rewardsDto.find(i => i.id && i.name)?.id != this.state.badgeId;
          this.props.onTopicCreationSuccess(this.props.topic?.id, this.state.badgeId, needBind);

          // обновление адресов книг
          if (isBook(topic.typeId)) {
            if (this.state.qiwiLibrary && this.state.changeAddressList) {
              const addAddresses = this.prepareAddresses(this.state.addressesAdd);
              const deleteAddresses = this.state.addressesDelete.map(addrId => Number(addrId));

              if (addAddresses.length > 0 || deleteAddresses.length > 0) {
                this.props.changeBookAddress(
                  {
                    topicId: topic.id,
                    addAddresses,
                    deleteAddresses,
                  },
                  () => this.setState({ changeAddressList: false, addressesAdd: [], addressesDelete: [] })
                );
              }
            }

            if (!this.state.qiwiLibrary && this.state.addressBook.length) {
              const deleteAddresses = this.state.addressBook.map(addr => Number(addr.id));

              this.props.changeBookAddress(
                {
                  topicId: topic.id,
                  addAddresses: [],
                  deleteAddresses,
                },
                () => this.setState({ addressBook: [], addressesAdd: [], addressesDelete: [] })
              );
            }
          }

          if (isExecutiveTask(topic.typeId)) {
            let files = [];
            if (this.state.fileUuids.length) {
              files = this.state.fileUuids;
            }
            let data = {
              activityInfo: this.state.executive_activity,
              duration: this.state.executive_duration,
              fileUuids: files,
              indicatorInfo: this.state.executive_indicators,
              requiredFile: this.state.executive_needfile,
              resultInfo: this.state.executive_results,
              topicId: topic.id,
            };
            this.props.updateExecutiveTopicInfo(data);
          }

          this.setState({ createBtnClick: false });
        },
        () => this.setState({ createBtnClick: false })
      );
    }
  };

  handleChangeSchedule = (key, name, value) => {
    this.setState(prevState => {
      const dates = prevState.dates.map((item, index) => {
        const newItem = _cloneDeep(item);

        if (prevState.webinarPlatform && prevState.webinarPlatform !== '1') {
          return {
            ...newItem,
            place: `${this.props.t('linkWithSchedule', { number: index + 1 })}`,
          };
        }

        return newItem;
      });

      dates[key][name] = value;

      const isDateExist = Boolean(dates?.[0]?.dates?.[0]);

      const firstDateString =
        typeof dates?.[0]?.dates?.[0] === 'string' && isDateExist
          ? dates?.[0]?.dates?.[0]
          : isDateExist
            ? dayjs(dates[0]?.dates?.[0]).format(YYYY_MM_DD)
            : dayjs().format(YYYY_MM_DD);

      const startTimeString =
        typeof dates?.[0]?.startTime === 'string' && dates?.[0]?.startTime
          ? dates?.[0]?.startTime
          : dates?.[0]?.startTime
            ? dates[0].startTime.format('HH:mm')
            : this.props.domainCompany?.eventStartTime;

      const endTimeString =
        typeof dates?.[0]?.endTime === 'string' && dates?.[0]?.endTime
          ? dates?.[0]?.endTime
          : dates?.[0]?.endTime
            ? dates[0].endTime.format('HH:mm')
            : this.props.domainCompany?.eventEndTime;

      const startDateTime = dayjs(`${firstDateString}T${startTimeString}`);
      const endDateTime = dayjs(`${firstDateString}T${endTimeString}`);

      const duration =
        startDateTime && endDateTime
          ? dayjs.isDayjs(startDateTime) && dayjs.isDayjs(endDateTime)
            ? endDateTime.diff(startDateTime, 'minutes')
            : dayjs(endDateTime).diff(dayjs(startDateTime), 'minutes')
          : 0;

      return { dates, duration };
    });
  };

  handleDeleteSchedule = id => {
    const arr = this.state.dates.map(a => a);
    arr.splice(id, 1);
    this.setState({ dates: arr });
  };

  handleAddSchedule = () => {
    const arr = this.state.dates.map(a => a);
    arr.push({ dates: [], endTime: '', startTime: '', place: '' });
    this.setState({ dates: arr });
  };

  handleChangeModerateStatus = event => {
    this.setState({
      scope: event?.target.checked ? 'INTERNAL' : 'EXTERNAL',
      statusScope: event?.target.checked,
      formChange: true,
    });
  };

  handleChangeCategory = value => {
    this.setState({ categoryIds: value, modalCategory: false });
  };

  handleImageChange = (file, name, info, cropImageSrc, coverColor) => {
    if (file && info) {
      if (IMAGE_TYPES.includes(info.type)) {
        if (this.state.coverFileUuid) {
          this.props.createFileImage(file, fileInfo => {
            this.setState(() => ({
              [name]: fileInfo.uuid,
              thumbFileUuid: fileInfo.thumbUuid,
              errors: {},
              coverColor,
              formChange: true,
              cropImageSrc,
            }));
          });
        } else {
          this.props.createFileImage(file, fileInfo => {
            this.setState(() => ({
              [name]: fileInfo.uuid,
              thumbFileUuid: fileInfo.thumbUuid,
              errors: {},
              coverColor,
              formChange: true,
              cropImageSrc,
            }));
          });
        }
        return true;
      }
      this.setState(state => ({
        [name]: null,
        thumbFileUuid: null,
        coverColor: null,
        errors: {
          ...state.errors,
          [name]: this.props.t('format'),
        },
      }));
    } else {
      this.setState(state => ({
        [name]: file,
        coverColor: null,
        thumbFileUuid: null,
        errors: { ...state.errors, [name]: '' },
      }));
    }
    this.setState({ formChange: true });
  };

  setColor = coverColor => this.setState({ coverColor });

  handleFilesChange = (files, name) => {
    if (Array.isArray(files)) {
      const filesValue = files.map(file => file && file.uuid);
      this.setState({ [name]: filesValue, formChange: true });
    }
  };

  handleCategoryClose = id => {
    const closeCategory = this.state.categoryIds.filter(category => {
      if (category.id !== id) {
        return category;
      }
    });

    this.setState({ categoryIds: closeCategory });
  };

  openModalCategory = () => this.setState({ modalCategory: true });
  closeModalCategory = () => this.setState({ modalCategory: false });

  showConfirmDialog = () => this.setState({ confirmVisible: true });
  hideConfirmDialog = () => this.setState({ confirmVisible: false });

  aceptClose = () => {
    this.setState({ confirmCloseCreate: true });
    this.setState({ confirmVisible: false });
  };

  clearTrajectoryType = () => {
    this.setState({
      ...this.defaultTrajectoryValues,
      careers: [],
      typeTrajectory: undefined,
    });
  };

  handleTrajectoryTypeChange = ({ value }) => {
    this.setState({ typeTrajectory: value });

    if (value === SOFT) {
      this.setState({
        careers: [],
        ...this.defaultTrajectoryValues,
        maxInvolvement: 100,
      });
    } else if (value === HARD) {
      this.setState({ ...this.defaultTrajectoryValues });
    } else {
      this.setState({
        ...this.defaultTrajectoryValues,
        careers: [],
      });
    }
  };

  handleMeritChange = ({ value, duration: dur }) => {
    const meritItem = this.props.merits.find(item => item.name === value);
    const { duration } = this.state;
    const d = dur ? dur : duration;
    if (d) {
      const durationFormat = d / 60;
      const dAwareness = parseFloat(meritItem.awareness * durationFormat);
      const dInvolvement = parseFloat(meritItem.involvement * durationFormat);
      const dMentalFlexibility = parseFloat(meritItem.mentalFlexibility * durationFormat);

      this.setState({
        awareness: dAwareness === 0 ? 0 : dAwareness.toFixed(2),
        involvement: dInvolvement === 0 ? 0 : dInvolvement.toFixed(2),
        mentalFlexibility: dMentalFlexibility === 0 ? 0 : dMentalFlexibility.toFixed(2),
        selectedMerit: value,
      });
    }
  };

  handleChangeLabel = value => {
    const [findCurrentLabel] = this.props.typeLabel.filter(item => item.id === +value);
    if (!findCurrentLabel) return;

    const currentTopicTypeId = findCurrentLabel.topicTypeId;

    if (!currentTopicTypeId) {
      this.props.getTopicTypeLabel(findCurrentLabel.id, findCurrentLabel.approved, findCurrentLabel.withEvents, res => {
        this.setState({ typeId: res.topicTypeId });
        this.props.getTopicSubTypes(res.topicTypeId, this.handleSubtypeChange);
      });
    } else {
      this.props.getTopicSubTypes(currentTopicTypeId, this.handleSubtypeChange);
      this.setState({
        typeId: currentTopicTypeId,
        subTypeId: currentTopicTypeId === 1 ? 24 : null,
        errors: {},
        comment: null,
        formChange: true,
      });
    }

    this.setState({ currentLabel: findCurrentLabel, typeLabelId: +value });
  };

  render() {
    const {
      open,
      onCancel,
      topicTypes,
      topicVideoTypes,
      fileInfo,
      editable,
      topicSubTypes,
      topicClasses,
      topicLevel,
      languages,
      isGlobal,
      t,
      template,
      positions,
      jobLevel,
    } = this.props;

    let topicTypeRadioButtons =
      topicTypes &&
      topicTypes.filter(topicType => {
        const topicTypeFields = _find(TOPIC_TYPES(this.props.t), type => type.id === topicType.id) || {};
        if (topicTypeFields.active) {
          return topicType;
        }
      });

    let tmpTopicTypeRadioButtons = [];
    if (topicTypeRadioButtons && Array.isArray(topicTypeRadioButtons)) {
      topicTypeRadioButtons.forEach(item => {
        if (this.props.isManager === true && item && item?.id === 25) {
          tmpTopicTypeRadioButtons.push(item);
        }
      });
    }

    if (this.props.isManager === true) {
      topicTypeRadioButtons = tmpTopicTypeRadioButtons;
    }

    const submitButton = editable ? (
      <Button
        data-qa='submitButton'
        onClick={this.handleEditTopicClick}
        disabled={this.props.fileLoading || this.props.fileLoadingImage || this.state.createBtnClick}
        type='primary'
      >
        {this.state.scope === 'UNMODERATE'
          ? t('save')
          : t(this.props.topic.type && isSkills(this.props.topic?.type.id) ? 'editSkillButton' : 'edit')}
      </Button>
    ) : (
      <Button
        data-qa='submitButton'
        onClick={this.handleAddTopicClick}
        disabled={this.props.fileLoading || this.props.fileLoadingImage || this.state.createBtnClick}
        type='primary'
      >
        {t(this.props.isSkills ? 'addSkillButton' : 'addMaterial')}
      </Button>
    );

    const categories =
      this.state.categoryIds &&
      this.state.categoryIds.map(cat => (
        <Tag key={cat.id} closable onClose={() => this.handleCategoryClose(cat.id)} className={css['Category-tag']}>
          {cat.name}
        </Tag>
      ));

    const isDisabledType =
      !dayjs(this.state.startDate).isSame(dayjs(), 'day') &&
      !dayjs(this.state.startDate).isAfter() &&
      +this.state.typeId === 8 &&
      this.state.startDate !== null;

    const hasCertificateBlock = this.state.hasCertificate ? (
      <>
        <Form.Item
          label={t('certficateSelectLabel')}
          help={this.state.errors.certificateId}
          labelCol={{ span: 6 }}
          required
          className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.certificateId })}
        >
          <Select
            data-qa='certificateId'
            placeholder={t('notSelected')}
            className={classNames(css.CertificateSelect)}
            onChange={value => this.handleChange(value, 'certificateId')}
            name='certificateId'
            options={this.props.certificates.map(el => ({ value: String(el.id), label: el.name }))}
            value={this.state.certificateRequest.id ? String(this.state.certificateRequest.id) : ''}
          />
        </Form.Item>
        <Form.Item
          label={t('certficateSeriesLabel')}
          help={this.state.errors.certificateSeries}
          className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.certificateSeries })}
          labelCol={{ span: 6 }}
        >
          <Input
            data-qa='certificateSeries'
            name='certificateSeries'
            placeholder={t('certificateSeries')}
            onChange={value => this.handleChange(value.target.value, 'certificateSeries')}
            value={this.state.certificateRequest.series}
            maxLength={SERIES_MAX}
          />
        </Form.Item>
        <Form.Item
          className={css['Add-modal-form-range']}
          label={t('certificateNumbersLabel')}
          help={this.state.errors.certificateRange}
          labelCol={{ span: 6 }}
        >
          <div className={css['Add-modal-form-range-group']}>
            <Input
              type='number'
              className={css['Add-modal-form-range-input']}
              data-qa='certificateNumbersMin'
              name='certificateNumbersMin'
              placeholder={t('certificateNumbersMin')}
              onChange={value => this.handleChange(value.target.value, 'certificateNumbersMin')}
              value={this.state.certificateRequest.minNumber}
            />
            <div className={css['Add-modal-form-range-delimiter']}> ~ </div>
            <Input
              type='number'
              className={css['Add-modal-form-range-input']}
              data-qa='certificateNumbersMax'
              name='certificateNumbersMax'
              placeholder={t('certificateNumbersMax')}
              onChange={value => this.handleChange(value.target.value, 'certificateNumbersMax')}
              value={this.state.certificateRequest.maxNumber}
            />
          </div>
        </Form.Item>
      </>
    ) : null;

    const checkActive = isDisabledType || editable || template;
    // Награда
    const hasBadgeBlock = this.state.hasBadge && (
      <div>
        <Form.Item
          label={t('badgeSelectLabel')}
          help={this.state.errors.badgeId}
          labelCol={{ span: 6 }}
          required
          className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.badgeId })}
        >
          <Select
            data-qa='badgeId'
            placeholder={t('notSelected')}
            className={classNames(css.BadgeSelect)}
            onChange={value => this.handleChange(value, 'badgeId')}
            name='badgeId'
            options={
              this.props.listBadges?.length > 0 && this.props.listBadges.map(el => ({ value: el.id, label: el.name }))
            }
            value={this.state.badgeId}
            showSearch
            filterOption={(value, option) => option.label.toString().toLowerCase().includes(value.toLowerCase())}
          />
        </Form.Item>
      </div>
    );

    const renderTitle = () => {
      if (editable) {
        return this.props.isSkills ? t('editSkill') : t('editPlan');
      }

      return this.props.isSkills ? t('createSkill') : t('addPlan');
    };

    return (
      <Modal
        open={open}
        onCancel={this.state.formChange ? this.showConfirmDialog : onCancel}
        footer={null}
        width={1150}
        destroyOnClose
      >
        <Form className={css['Add-modal-form']} data-qa='addTopicModalForm'>
          <div className={css['Add-modal']}>
            <div className={css['Add-modal-header']}>{renderTitle()}</div>
            <Form.Item
              label={t('title')}
              help={this.state.errors.name}
              labelCol={{ span: 6 }}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.name })}
              required
            >
              <Input
                data-qa='title'
                placeholder={t('title')}
                onChange={value => this.handleChange(value.target.value, 'name')}
                value={this.state.name}
                maxLength={500}
              />
            </Form.Item>
            {/* с бэка будет приходить ответ Лейблов который я буду читать и проставлять в зависимости выбора пользователя состояние чекбоксов */}
            {/* Лейбл Топиков */}
            <Form.Item
              label={t('label')}
              help={this.state.errors.typeLabelId}
              labelCol={{ span: 6 }}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.typeLabelId })}
              required
            >
              <Select
                data-qa='typeLabelIdSelect'
                virtual={false}
                disabled={isDisabledType || editable || template || this.props.isSkills}
                placeholder={t('notSelected')}
                className={classNames(css.TopicCreate, {
                  [css.isDisabledType]: checkActive,
                })}
                onChange={this.handleChangeLabel}
                options={this.props?.typeLabel?.map((option, i) => ({
                  value: option.id,
                  label: option.name,
                  key: option.id,
                  id: i,
                }))}
                value={this.state.typeLabelId}
              />
            </Form.Item>
            {!this.props.topic && (
              <>
                {/* Материал Синхронный или Асинхронный */}
                <Form.Item
                  className={classNames(css['App-modal-label'], {
                    [css['Add-modal-fromItem-error']]: this.state.errors.withEvents,
                  })}
                  label={t('withEvents')}
                  help={this.state.errors.withEvents}
                  labelCol={{ span: 6 }}
                >
                  <Checkbox
                    data-qa='withEvents'
                    onChange={event => this.handleChange(event?.target.checked, 'withEvents')}
                    checked={this.state.currentLabel.withEvents}
                    disabled={this.state.currentLabel.withEventsDisabled}
                  />
                </Form.Item>
                {/* Материал требует Подтверждения или нет */}
                <Form.Item
                  label={t('approved')}
                  help={this.state.errors.approved}
                  className={classNames(css['App-modal-label'], {
                    [css['Add-modal-fromItem-error']]: this.state.errors.description,
                  })}
                  labelCol={{ span: 6 }}
                >
                  <Checkbox
                    data-qa='approved'
                    onChange={event => this.handleChange(event?.target.checked, 'approved')}
                    checked={this.state.currentLabel.approved}
                    disabled={this.state.currentLabel.approvedDisabled}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label={t('description')}
              help={this.state.errors.description}
              labelCol={{ span: 6 }}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.description })}
              required
            >
              <TextArea
                data-qa='description'
                className={css['Add-modal-textarea']}
                rows={8}
                onChange={value => this.handleChange(value.target.value, 'description')}
                value={this.state.description}
              />
            </Form.Item>
            <Form.Item
              label={t('cover')}
              help={this.state.errors.coverFileUuid}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.coverFileUuid })}
              labelCol={{ span: 6 }}
            >
              <ImageInput
                id='imageInput'
                error={this.state.errors.coverFileUuid}
                onChange={this.handleImageChange}
                name='coverFileUuid'
                fileInfo={fileInfo}
                imageSrc={this.props.imageSrc}
                isLoadingFile={this.props.isLoadingFile || this.state.createBtnClick}
                isLoadingFileImage={this.props.fileLoadingImage}
              />
            </Form.Item>
          </div>
          <Divider />
          <div className={css['Add-modal']}>
            <Form.Item
              label={t('tags')}
              help={this.state.errors.tagNames}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.tagNames })}
              labelCol={{ span: 6 }}
            >
              <TagsAutocomplete
                onChange={value => this.handleChange(value, 'tagNames')}
                name='tagNames'
                placeholder={t('key')}
                value={this.state.tagNames}
                // isGlobal={isGlobal}
                id='tagsAutocomplete'
                t={t}
              />
            </Form.Item>
            <Form.Item
              label={t('skills')}
              help={this.state.errors.skillNames}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.skillNames })}
              labelCol={{ span: 6 }}
            >
              <TagsAutocomplete
                onChange={value => this.handleChange(value, 'skillNames')}
                name='skillNames'
                placeholder={t('enterSkill')}
                value={this.state.skillNames}
                // isGlobal={isGlobal}
                isSkills
                id='skillsAutocomplete'
                tagSliceCount={32}
                t={t}
              />
            </Form.Item>
            <Form.Item
              label={t('language')}
              help={this.state.errors.languageId}
              labelCol={{ span: 6 }}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.languageId })}
              required
            >
              <Select
                placeholder={t('notSelected')}
                className={classNames(css.TopicCreate)}
                onChange={value => this.handleChange(value, 'languageId')}
                options={languages?.map(option => ({ label: option.name, value: option.id, key: option.id }))}
                value={this.state.languageId}
                id='languageAutocomplete'
                virtual={false}
              />
            </Form.Item>
            <Form.Item
              label={<Tooltip title={t('topicCategoryCompetencies')}>{t('topicCompetenciesShort')}</Tooltip>}
              labelCol={{ span: 6 }}
              help={this.state.errors.topicCompetenceCategory}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.topicCompetenceCategory })}
              required
            >
              <Select
                arrowclear
                data-qa='topicCompetenceCategory'
                placeholder={t('notSelected')}
                className={css.TopicCreate}
                onChange={value => this.handleChange(value, 'topicCompetenceCategory')}
                options={this.props?.topicCategoryCompetencies?.map(item => ({ label: item.name, value: item.value }))}
                value={this.state?.topicCompetenceCategory}
              />
            </Form.Item>
            <Form.Item
              label={t('function')}
              labelCol={{ span: 6 }}
              help={this.state.errors.jobFamilies}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.jobFamilies })}
            >
              <Select
                mode='multiple'
                showSearch={false}
                data-qa='jobFamiliesSelect'
                placeholder={t('selectFunction')}
                className={classNames(css.TopicCreate)}
                onChange={value => this.handleChange(value, 'jobFamilies')}
                options={positions?.map(option => ({ label: option.name, value: option.id, key: option.id }))}
                value={this.state.jobFamilies?.map(item => item.id ?? item)}
                id='jobFamiliesIdsSelect'
              />
            </Form.Item>
            {isTestCourses(this.state.typeId) && (
              <Form.Item
                label={t('jobLevel')}
                labelCol={{ span: 6 }}
                className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.jobLevels })}
                help={this.state.errors.jobLevels}
              >
                <Select
                  mode='multiple'
                  showSearch={false}
                  data-qa='jobLevelSelect'
                  placeholder={t('selectJobLevel')}
                  className={classNames(css.TopicCreate)}
                  onChange={value => this.handleChange(value, 'jobLevels')}
                  options={jobLevel?.map(option => ({ label: option.name, value: option.id, key: option.id }))}
                  value={this.state.jobLevels?.map(item => item.id ?? item)}
                  id='jobLevelSelect'
                />
              </Form.Item>
            )}
            {/* <Form.Item
              label={t('category')}
              help={this.state.errors.categoryIds}
              labelCol={{ span: 6 }}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.categoryIds })}
              required
            >
              <Button
                onClick={this.openModalCategory}
                data-qa='categoryBtn'
                className={classNames(css['Category-btn'])}
              >
                {t('select')}
              </Button>
              <div className={css['Category-tags']}>{categories}</div>
            </Form.Item> !!!!!! В рамках задачи KAM-4507 закомментировать поля категории !!!!! */}
            <Form.Item
              label={t('topicMandatories')}
              labelCol={{ span: 6 }}
              help={this.state.errors.topicMandatory}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.topicMandatory })}
              required
            >
              <Select
                data-qa='topicMandatory'
                placeholder={t('notSelected')}
                className={css.TopicCreate}
                onChange={value => this.handleChange(value, 'topicMandatory')}
                options={this.props.topicMandatories?.map(item => ({ label: item.name, value: item.value }))}
                value={this.state.topicMandatory}
              />
            </Form.Item>
            <Form.Item
              label={t('levels')}
              help={this.state.errors.levelId}
              labelCol={{ span: 6 }}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.levelId })}
              required
            >
              <Select
                data-qa='levelSelect'
                placeholder={t('notSelected')}
                className={classNames(css.TopicCreate)}
                onChange={value => this.handleChange(value, 'levelId')}
                options={topicLevel?.map(option => ({ label: option.name, value: option.id, key: option.id }))}
                value={this.state.levelId}
                id='levelsSelect'
                virtual={false}
              />
            </Form.Item>
            <Form.Item
              label={t('inner')}
              help={this.state.errors.statusScope}
              className={classNames(css['App-modal-label'], {
                [css['Add-modal-fromItem-error']]: this.state.errors.statusScope,
              })}
              labelCol={{ span: 6 }}
            >
              <Checkbox
                onChange={this.handleChangeModerateStatus}
                name='statusScope'
                data-qa='statusScope'
                checked={this.state.statusScope}
              />
              <Tooltip title={t('tooltip')} placement='top'>
                <QuestionCircleOutlined className={css['Add-modal-label-tooltip-icon']} />
              </Tooltip>
            </Form.Item>
            <Form.Item
              label={t('certificateToggle')}
              help={this.state.errors.hasCertificate}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.hasCertificate })}
              labelCol={{ span: 6 }}
            >
              <Checkbox
                onChange={event => this.handleChange(event?.target.checked, 'hasCertificate')}
                data-qa='hasCertificate'
                checked={this.state.hasCertificate}
              />
            </Form.Item>
            {hasCertificateBlock}
            <Form.Item
              label={t('badgeToggle')}
              help={this.state.errors.hasBadge}
              className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.hasBadge })}
              labelCol={{ span: 6 }}
            >
              <Checkbox
                onChange={event => this.handleChange(event?.target.checked, 'hasBadge')}
                data-qa='hasBadge'
                checked={this.state.hasBadge}
              />
            </Form.Item>
            {hasBadgeBlock}
          </div>
          <AdditionalInputCard
            topic={this.state}
            topicTypeId={this.state.typeId}
            topicSubTypeId={this.state.subTypeId}
            handleAddSchedule={this.handleAddSchedule}
            handleDeleteSchedule={this.handleDeleteSchedule}
            onChange={this.handleChange}
            onChangeSubType={this.handleSubtypeChange}
            onChangeSchedule={this.handleChangeSchedule}
            onChangePoka={this.handleChangePoka}
            onFilesChange={this.handleFilesChange}
            onLinksChange={this.handleLinksChange}
            onAuthorsChange={this.handleAuthorsChange}
            onDateTimeChange={this.handleDateTimeChange}
            topicVideoTypes={topicVideoTypes}
            topicSubTypes={topicSubTypes}
            onYearChange={this.handleYearChange}
            topicClasses={topicClasses}
            files={this.state.fileUuids}
            editable={editable || template}
            domainCompany={this.props.domainCompany}
            isGlobal={isGlobal}
            addressBookVisible={this.state.addressBookVisible}
            executiveDuration={this.state.executive_duration}
            executiveResults={this.state.executive_results}
            executiveActivity={this.state.executive_activity}
            executiveIndicators={this.state.executive_indicators}
            executiveNeedfile={this.state.executive_needfile}
            template={template}
            pokaCategories={this.props.pokaCategories}
            pokaLevels={this.props.pokaLevels}
            pokaSites={this.props.pokaSites}
          />
          <div className={css['Add-modal-createTopicBtn']}>{submitButton}</div>
        </Form>
        <Modal
          open={this.state.confirmVisible}
          onCancel={this.hideConfirmDialog}
          afterClose={() => {
            this.state.confirmCloseCreate && onCancel();
            this.setState({ confirmCloseCreate: false });
          }}
          footer={null}
        >
          <div className={css['Add-modal-closeModal']}>
            <div className={css['Add-modal-closeModal-desc']}>{t('cancelWithoutSave')}</div>
            <div className={css['Add-modal-closeModal-footerBtn']}>
              <Button onClick={this.aceptClose}>{t('cancel')}</Button>
            </div>
          </div>
        </Modal>
        {this.state.modalCategory && (
          <ModalCategory
            open={this.state.modalCategory}
            category={this.state.categoryIds}
            categories={this.props.topicCategories}
            onChange={this.handleChangeCategory}
            name='categoryIds'
            options={this.props.topicCategories}
            closeModalCategory={this.closeModalCategory}
            domainCompany={this.props.domainCompany}
          />
        )}
      </Modal>
    );
  }
}

TopicCreationDialog.propTypes = {
  createFile: PropTypes.func,
  createFileImage: PropTypes.func,
  createTopic: PropTypes.func,
  deleteAuthors: PropTypes.func,
  domainCompany: PropTypes.object,
  editTopic: PropTypes.func,
  editable: PropTypes.bool,
  template: PropTypes.bool,
  failTopicValidation: PropTypes.func,
  failTopicValidationCharacter: PropTypes.func,
  fileInfo: PropTypes.object,
  fileLoading: PropTypes.bool,
  fileLoadingImage: PropTypes.bool,
  getFile: PropTypes.func,
  getTopicById: PropTypes.func,
  getTopicCategories: PropTypes.func,
  getTopicClasses: PropTypes.func,
  getTopicLevel: PropTypes.func,
  getTopicSubTypes: PropTypes.func,
  getTopicTypes: PropTypes.func,
  getMerits: PropTypes.func,
  getTopicVideoTypes: PropTypes.func,
  imageSrc: PropTypes.string,
  isLoadingFile: PropTypes.bool,
  isLoadingFileImage: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onTopicCreationSuccess: PropTypes.func.isRequired,
  topic: PropTypes.object,
  topicCategories: PropTypes.array,
  topicClasses: PropTypes.array,
  topicLevel: PropTypes.array,
  topicSubTypes: PropTypes.array,
  topicTypes: PropTypes.array,
  topicVideoTypes: PropTypes.array,
  open: PropTypes.bool.isRequired,
  isGlobal: PropTypes.bool,
  isManager: PropTypes.bool,
  editTopicVisibility: PropTypes.func,
  getTypeLabels: PropTypes.func,
  getTopicTypeLabel: PropTypes.func,
  getCertificates: PropTypes.func,
  saveBookAddresses: PropTypes.func,
  changeBookAddress: PropTypes.func,
  getBookAddresses: PropTypes.func,
  getTopicCategoryCompetencies: PropTypes.func,
  getTopicMandatory: PropTypes.func,
  topicCategoryCompetencies: PropTypes.array,
  topicMandatories: PropTypes.array,
  getPositionInternal: PropTypes.func,
  getTopicJobLevels: PropTypes.func,
  getListBadges: PropTypes.func,
  listBadges: PropTypes.array,
  badgeBindTopic: PropTypes.func,
  addInactiveBadge: PropTypes.func,
  clearInactiveBadge: PropTypes.func,
  getPokaDirLevel: PropTypes.func,
  getPokaDirSite: PropTypes.func,
  getPokaDirCategory: PropTypes.func,
};

const mapStateToProps = createSelector(
  [
    topicsSelect,
    filesSelect,
    usersSelect,
    selectLocales,
    selectTypeLabel,
    selectCertificates,
    selectBooks,
    selectPositions,
    selectBadge,
    selectPokaSkills,
  ],
  (topics, files, users, { languages }, typeLabel, certificates, books, positions, badges, poka) => ({
    topicVideoTypes: topics.topicVideoTypes,
    topicClasses: topics.topicClasses,
    topicCategories: topics.categories,
    topicTypes: topics.types,
    topicSubTypes: topics.subtypes,
    fileInfo: files.info,
    fileLoading: files.isLoading,
    fileLoadingImage: files.isLoadingImage,
    topicLevel: topics.level,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    merits: topics.merits,
    isGlobal: users && users.currentUser.domainCompany.global,
    languages,
    typeLabel: IsManager(users.currentUser)
      ? typeLabel.typeLabel.filter(value => isExecutiveTask(value.topicTypeId))
      : typeLabel.typeLabel,
    typeTopicLabel: typeLabel.typeTopicLabel,
    certificates: certificates.certificates,
    bookAddresses: books.addresses,
    topicCategoryCompetencies: topics.topicCategoryCompetencies,
    topicMandatories: topics.topicMandatories,
    positions: positions.positions,
    jobLevel: topics.jobLevel,
    listBadges: badges.listBadges,
    pokaCategories: poka.categories,
    pokaLevels: poka.levels,
    pokaSites: poka.sites,
  })
);

const mapActionsToProps = {
  getTopicById,
  createTopic,
  getFile,
  editTopic,
  failTopicValidation,
  failTopicValidationCharacter,
  createFile,
  createFileImage,
  getTopicVideoTypes,
  getTopicClasses,
  getTopicLevel,
  getTopicCategories,
  getTopicTypes,
  getTopicSubTypes,
  getEventTopic,
  getMerits,
  getLanguages,
  storeExecutiveTopicInfo,
  getExecutiveTopicInfo,
  updateExecutiveTopicInfo,
  createTopicByTemplate,
  editTopicVisibility,
  getTypeLabels,
  getTopicTypeLabel,
  getCertificates,
  clearCertificates,
  saveBookAddresses,
  changeBookAddress,
  getBookAddresses,
  getTopicCategoryCompetencies,
  getTopicMandatory,
  getPositionInternal,
  getTopicJobLevels,
  getListBadges,
  badgeBindTopic,
  addInactiveBadge,
  clearInactiveBadge,
  getPokaDirCategory,
  getPokaDirSite,
  getPokaDirLevel,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['topicCreationDialog', 'constants'])(TopicCreationDialog));
