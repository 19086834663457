/* eslint-disable */
import React, { Component } from 'react';

import { Divider, Tooltip } from 'antd';
import { DeleteOutlined, HolderOutlined } from '@ant-design/icons';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';
import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';

import css from './DragItem.module.scss';

export default class DragItem extends Component {
  static propTypes = {
    domainCompany: PropTypes.object,
    getFile: PropTypes.func,
    isMaterials: PropTypes.bool,
    item: PropTypes.object,
    onDelete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      image:
        props.item.type &&
        props.domainCompany.corpCourseBackground === null &&
        isCorpCourseWithOrWithoutApprove(props.item.type.id)
          ? TOPIC_IMAGE.find(type => type.id === props.item.type.id)?.imageCompany
          : TOPIC_IMAGE.find(type => type.id === props.item.type.id)?.image,
      readyToBackground: false,
    };
  }

  componentDidMount() {
    if (this.props.item.coverFileUuid || this.props.item.thumbFileUuid) {
      this.getImage(this.props.item.coverFileUuid || this.props.item.thumbFileUuid);
    }
  }

  getImage = uuid =>
    this.props.getFile(uuid, blob => {
      this.setState({
        image: URL.createObjectURL(blob),
        readyToBackground: true,
      });
    });

  onDelete = () => this.props.onDelete(this.props.item.id);

  renderDelete = () => <DeleteOutlined onClick={this.onDelete} className={css.DragItem__delete} />;

  render() {
    const {
      item,
      domainCompany: { hasTrajectory, hasUserTrajectory },
    } = this.props;
    const uniqMerits = _uniq([
      item.awareness,
      item.mentalFlexibility,
      item.involvement,
      item.minAwareness,
      item.minInvolvement,
      item.maxInvolvement,
    ]);
    const careers = item.careers || [];
    const isSoft = !careers?.length && (uniqMerits.length > 1 || uniqMerits[0] !== 0);
    const isHard = !!careers.length;
    const careersString = item.careers.map((el, index) => `${el.name}${index < item.careers.length - 1 ? ', ' : ''}`);
    const maxStringLength = isSoft || isHard ? 35 : 60;
    return (
      <div className={css.DragItem}>
        <div className={css.DragItem__content}>
          <div className={css.DragItem__dragIcon}>
            <HolderOutlined />
          </div>
          <div
            className={css.DragItem__image}
            style={{
              backgroundColor: this.state.readyToBackground && item.coverColor,
              backgroundImage: `url(${this.state.image})`,
            }}
          />
          <div className={css.DragItem__trajectory}>
            <a href={`/topic/${item.id}`} target='_blank' className={css.DragItem__name}>
              {item.name.length < maxStringLength ? (
                item.name
              ) : (
                <Tooltip title={item.name}>{`${item.name.substring(0, maxStringLength)}...`}</Tooltip>
              )}
            </a>
            {hasTrajectory && hasUserTrajectory && (isSoft || isHard) && (
              <div
                className={classNames(css.DragItem__trajectoryLabel, {
                  [css.DragItem__trajectoryLabel_soft]: isSoft,
                })}
              >
                ИПР : {isSoft ? 'Soft' : 'Hard'} {item.global ? 'Global' : ''}
                {isHard && (
                  <>
                    <Divider type='vertical' />
                    {careersString.length > 2 ? (
                      <Tooltip title={careersString.join('')}>{`${careersString.slice(0, 2).join('')}...`}</Tooltip>
                    ) : (
                      careersString
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {this.renderDelete()}
      </div>
    );
  }
}
