/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteUserTrack,
  exportTrackUsers,
  getStatUser,
  getTrackProgress,
  postAppointFilters,
  transferUserTrack,
} from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Alert, Button, Col, Dropdown, Progress, Row, Table, Tooltip } from 'antd';
import { SendCommentModal } from '@/pages/SendReviewModal';
import CalendarModal from '@/components/CalendarModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import RenderTableStatus from '@/components/RenderTableStatus';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { HiDocumentDownload, HiOutlineBadgeCheck, HiOutlineChartPie, HiOutlineClock } from 'react-icons/hi';

import { ACTIONS_STATUS, IN_PROGRESS, TYPE_ADD_MANUAL, TYPE_ADD_MASS } from '../constants';
import ModalStat from './ModalStat';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../TrackDetail.module.scss';

import { ACCESS_TO_TRACKS, TRACK_PLAN_EDITE } from '@/constants/permissions';

const TabAppointments = ({ setIsSort, activeSort, setActiveSort, isSort }) => {
  const { t } = useTranslation('trackDetail');
  const dispatch = useDispatch();

  const {
    appointLoading,
    appointTable,
    appointTotal,
    appointPageSize,
    appointPageNumber,
    appointCurrentPage,
    appointFilters,
    trackDetails,
    progress,
    isLoadingExportTrack,
  } = useSelector(selectTracks);
  const { currentUser } = useSelector(selectUsers);

  const [isTransferModal, setIsTransferModal] = useState(false);
  const [isCommentModal, setIsCommentModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [paramsUser, setParamsUser] = useState({
    userId: null,
    trackId: null,
    comment: null,
    startDate: null,
    withoutSchedule: true,
  });
  const [addition, setAddition] = useState({ single: [], mass: [] });
  const [showStat, setShowStat] = useState({ open: false, trackId: null, fullName: '', userId: null });

  useEffect(() => {
    if (progress.length > 0) {
      const singleAdd = [];
      const massAdd = [];

      progress.map(j => {
        if (j.type === TYPE_ADD_MASS) {
          massAdd.push(j);
        } else if (j.type === TYPE_ADD_MANUAL) {
          singleAdd.push(j);
        }
        return j;
      });

      setAddition({ single: [...singleAdd], mass: [...massAdd] });
    } else {
      setAddition({ single: [], mass: [] });
    }
  }, [progress]);

  const texts = {
    reason: t('reason'),
    comment: t('comment'),
    rescheduling: t('rescheduling'),
    max: t('max'),
  };

  const showModalTransfer = obj => {
    if (obj?.userId && trackDetails?.id) {
      setParamsUser(prevState => ({ ...prevState, userId: obj.userId, trackId: trackDetails.id }));
      setIsTransferModal(true);
    }
  };

  const toggleModalReasonTransfer = (planningTopicId, selectedMonths) => {
    if (selectedMonths) {
      setIsCommentModal(true);
      setParamsUser(prevState => ({ ...prevState, startDate: selectedMonths.toString() }));
    }
  };

  const transferTrack = comment => {
    if (comment && paramsUser.userId && paramsUser.startDate && paramsUser.trackId) {
      setParamsUser(prevState => {
        let params = { ...prevState, comment: comment.trim() };

        dispatch(
          transferUserTrack(params, () => {
            setIsCommentModal(false);
            setIsTransferModal(false);
          })
        );

        return params;
      });
    }
  };

  const showDeleteModal = obj => {
    setIsDeleteModal(true);

    if (obj?.userId && trackDetails?.id) {
      setParamsUser({
        userId: obj.userId,
        trackId: trackDetails.id,
      });
    }
  };

  const onUserDeleteTrack = () => {
    if (paramsUser.userId && trackDetails?.id) {
      const params = {
        userId: paramsUser.userId,
        trackId: trackDetails.id,
      };

      dispatch(
        deleteUserTrack(params, () => {
          setIsDeleteModal(false);
          dispatch(postAppointFilters(trackDetails.id, appointFilters?.filters ?? {}, appointCurrentPage, isSort));
        })
      );
    }
  };

  const exportAppointTable = () => {
    if (trackDetails?.id) {
      dispatch(
        exportTrackUsers(trackDetails.id, appointFilters?.filters ?? {}, blob => {
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = url;
          link.click();
        })
      );
    }
  };

  const onShowUserStat = track => {
    const { trackId, userId, fullName } = track;
    dispatch(getStatUser(trackId, userId, 0));
    setShowStat({ open: true, trackId, fullName, userId });
  };

  const renderActionTable = obj => {
    if (!currentUser?.authorities.includes(TRACK_PLAN_EDITE)) return;

    return (
      <Dropdown menu={{ items: tableAction(obj) }} trigger={['click']}>
        <Button className={css['Track-details-tabs-appoint-table-content-btn']} type='link'>
          <ArrowRightOutlined />
        </Button>
      </Dropdown>
    );
  };

  const tableAction = obj => {
    const showDetails = {
      key: '100',
      label: t('btnStat'),
      onClick: () => onShowUserStat(obj),
    };

    if (ACTIONS_STATUS.includes(obj.status)) {
      return [showDetails];
    }

    if (obj.status === IN_PROGRESS) {
      return [
        {
          key: '1',
          label: t('btnTransfer'),
          onClick: () => showModalTransfer(obj),
        },
        {
          key: '2',
          label: t('btnDelete'),
          onClick: () => showDeleteModal(obj),
        },
        showDetails,
      ];
    }
  };

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('fullNameTable'),
      dataIndex: 'fullName',
      key: 1,
    });

    columns.push({
      title: t('departmentTable'),
      dataIndex: 'department',
      key: 2,
    });

    columns.push({
      title: t('functionTable'),
      dataIndex: 'function',
      key: 3,
    });

    columns.push({
      title: t('managerTable'),
      dataIndex: 'manager',
      key: 4,
    });

    columns.push({
      title: t('deadlineTable'),
      dataIndex: 'deadline',
      key: 5,
    });

    columns.push({
      title: t('amountTopicTable'),
      dataIndex: 'amountTopic',
      key: 6,
      align: 'center',
    });

    columns.push({
      title: t('statusTable'),
      dataIndex: 'statusText',
      key: 7,
      render: (txt, value) => <RenderTableStatus status={value.statusValue} text={txt} />,
    });

    columns.push({
      dataIndex: 'action',
      title: (
        <Tooltip title={t('action')}>
          <ArrowRightOutlined className={css['Track-details-tabs-appoint-table-content-arrow']} />
        </Tooltip>
      ),

      key: 8,
      width: 40,
      render: (_, obj) => renderActionTable(obj),
    });

    return columns;
  };

  const dataSource = useMemo(
    () =>
      appointTable?.length > 0 &&
      appointTable.map(item => {
        const fullName =
          (item?.user?.lastName || '') + ' ' + (item?.user?.firstName || '') + ' ' + (item?.user?.middleName || '');

        const manager = item?.user.managers?.find(m => m.id);
        const managerName = manager?.fullName;

        const blockAmount = (
          <div className={css['Track-details-tabs-appoint-table-cell']}>
            <Tooltip title={t('finished')}>
              <div className={css['Track-details-tabs-appoint-table-cell-finished']}>
                <HiOutlineBadgeCheck />
                <span className={css['Track-details-tabs-appoint-table-cell-finished-text']}>
                  {item.topicsFinished}
                </span>
              </div>
            </Tooltip>

            <Tooltip title={t('total')}>
              <div className={css['Track-details-tabs-appoint-table-cell-total']}>
                <HiOutlineClock />
                <span className={css['Track-details-tabs-appoint-table-cell-total-text']}>{item.topicsTotal}</span>
              </div>
            </Tooltip>

            <Tooltip title={t('percentage')}>
              <div className={css['Track-details-tabs-appoint-table-cell-percentage']}>
                <HiOutlineChartPie />
                <span className={css['Track-details-tabs-appoint-table-cell-percentage-text']}>
                  {Math.ceil((item.topicsFinished / item.topicsTotal) * 100)}%
                </span>
              </div>
            </Tooltip>
          </div>
        );

        return {
          fullName: fullName,
          department: item.user.department,
          function: item.user.function,
          manager: managerName,
          deadline: item.startDate,
          statusText: item.statusName,
          status: item.status,
          userId: item.user.id,
          amountTopic: blockAmount,
          trackId: item.trackId,
          statusValue: item.status,
        };
      }),
    [appointTable]
  );

  const sortDate = () => {
    setActiveSort({ date: !activeSort.date, lastName: { aZ: false, zA: false } });
    setIsSort(!activeSort.date ? 'selection.startDate,desc' : '');
    !activeSort.date
      ? dispatch(
          postAppointFilters(
            trackDetails?.id,
            appointFilters?.filters ?? {},
            appointCurrentPage,
            'selection.startDate,desc'
          )
        )
      : dispatch(postAppointFilters(trackDetails?.id, appointFilters?.filters ?? {}, appointCurrentPage));
  };

  const sortLastName = (value, sortBy) => {
    setActiveSort({ lastName: { ...activeSort, [sortBy]: !activeSort.lastName[sortBy] } });
    setIsSort(!activeSort.lastName[sortBy] ? `lastName,${value}` : '');
    !activeSort.lastName[sortBy]
      ? dispatch(
          postAppointFilters(trackDetails?.id, appointFilters?.filters ?? {}, appointCurrentPage, `lastName,${value}`)
        )
      : dispatch(postAppointFilters(trackDetails?.id, appointFilters?.filters ?? {}, appointCurrentPage));
  };

  const checkProgress = () => {
    if (progress.length > 0) {
      dispatch(getTrackProgress(trackDetails?.id));
    } else {
      dispatch(postAppointFilters(trackDetails?.id, appointFilters?.filters ?? {}, 0, isSort));
    }
  };

  const onChangeTable = pagination => {
    dispatch(postAppointFilters(trackDetails?.id, appointFilters?.filters ?? {}, pagination.current - 1, isSort));
  };

  const pagination = {
    total: appointTotal,
    pageSize: appointPageSize,
    pageNumber: appointPageNumber,
    current: appointCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const renderProgresSingle = (
    <Row className={css['Track-details-tabs-appoint-progress']}>
      {addition.single.map(i => {
        return (
          <>
            <Col span={1} className={css['Track-details-tabs-appoint-progress-loading']}>
              <LoadingOutlined />
            </Col>
            <Col span={4}>{i.name}</Col>
            <Col span={19}>
              <Progress percent={i.progress ?? 0} />
            </Col>
          </>
        );
      })}
    </Row>
  );

  const renderMassProgres = (
    <Row className={css['Track-details-tabs-appoint-progress']}>
      {addition.mass.map(i => {
        return (
          <>
            <Col span={1} className={css['Track-details-tabs-appoint-progress-loading']}>
              <LoadingOutlined />
            </Col>
            <Col span={23}>{i.name}</Col>
          </>
        );
      })}
    </Row>
  );

  const allProgress = (
    <>
      {addition.single.length > 0 && (
        <>
          <Alert message={t('alertMessageSingle')} type='warning' />
          {renderProgresSingle}
        </>
      )}

      {addition.mass.length > 0 && (
        <>
          <Alert message={t('alertMessageMass')} type='warning' />
          {renderMassProgres}
        </>
      )}
    </>
  );

  return (
    <div className={css['Track-details-tabs-appoint-table']}>
      {progress.length > 0 ? (
        <>
          {allProgress}
          <Button onClick={checkProgress} className={css['Track-details-tabs-appoint-check']} type='primary'>
            {t('btnCheck')}
          </Button>
        </>
      ) : (
        <>
          <div className={css['Track-details-tabs-appoint-table-actions']}>
            {currentUser?.authorities.includes(ACCESS_TO_TRACKS) && (
              <Button
                type='link'
                className={css['Track-details-tabs-appoint-table-actions-btn']}
                onClick={exportAppointTable}
                loading={isLoadingExportTrack}
              >
                <HiDocumentDownload className={css['Track-details-tabs-appoint-table-actions-icon']} />
                {t('exportTable')}
              </Button>
            )}
          </div>

          <div className={css['Track-details-tabs-appoint-table-sort']}>
            <span className={css['Track-details-tabs-appoint-table-sort-text']}>{t('sort')}</span>
            <span>{t('sortLastName')}</span>
            <span
              className={classNames(
                css['Track-details-tabs-appoint-table-sort-action'],
                css['Track-details-tabs-appoint-table-sort-action-az'],
                activeSort.lastName?.aZ && css['Track-details-tabs-appoint-table-sort-active']
              )}
              onClick={() => sortLastName('asc', 'aZ')}
            >
              {t('aZ')}
            </span>
            <span
              className={classNames(
                css['Track-details-tabs-appoint-table-sort-action'],
                activeSort.lastName?.zA && css['Track-details-tabs-appoint-table-sort-active']
              )}
              onClick={() => sortLastName('desc', 'zA')}
            >
              {t('zA')}
            </span>

            <span className={css['Track-details-tabs-appoint-table-sort-vertical']}></span>
            <span
              className={classNames(
                css['Track-details-tabs-appoint-table-sort-action'],
                activeSort.date && css['Track-details-tabs-appoint-table-sort-active']
              )}
              onClick={sortDate}
            >
              {t('sortDate')}
            </span>
          </div>

          {renderMassProgres}

          <div className={css['Track-details-tabs-appoint-table-content']}>
            <Table
              columns={getColumns()}
              dataSource={dataSource}
              pagination={pagination}
              onChange={onChangeTable}
              rowKey={e => `${e.fullName}--${Symbol('uniq').toString()}`}
              scroll={{ x: 700, y: 700 }}
              bordered
              loading={appointLoading}
            />
          </div>
        </>
      )}

      {isTransferModal && (
        <CalendarModal
          open={isTransferModal}
          onOk={toggleModalReasonTransfer}
          onCancel={() => setIsTransferModal(false)}
          okText={t('reschedule')}
          title={t('titleCM')}
        />
      )}

      {isCommentModal && (
        <SendCommentModal
          open={isCommentModal}
          submitDeleteComment={transferTrack}
          cancelDeleteComment={() => setIsCommentModal(false)}
          texts={texts}
          t={t}
          isTransfer
        />
      )}

      {isDeleteModal && (
        <ConfirmationModal
          open={isDeleteModal}
          onOk={onUserDeleteTrack}
          onCancel={() => setIsDeleteModal(false)}
          onCancelButton={() => setIsDeleteModal(false)}
          okText={t('yes')}
          cancelText={t('no')}
          confirmationText={t('descrDelete')}
        />
      )}

      {showStat.open && (
        <ModalStat
          stat={showStat}
          onCancel={() => setShowStat({ open: false, trackId: null, fullName: '', userId: null })}
        />
      )}
    </div>
  );
};

TabAppointments.propTypes = {
  setIsSort: PropTypes.func,
  activeSort: PropTypes.object,
  setActiveSort: PropTypes.func,
  isSort: PropTypes.string,
};

export default TabAppointments;
