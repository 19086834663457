// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MoreButtonComponent-styles-module__ql-picker-R4vYxO.admin-src-components-MoreButtonComponent-styles-module__ql-font-PqHvad .admin-src-components-MoreButtonComponent-styles-module__ql-picker-label-fwvbkO[data-value="Montserrat"]:before, .admin-src-components-MoreButtonComponent-styles-module__ql-picker-R4vYxO.admin-src-components-MoreButtonComponent-styles-module__ql-font-PqHvad .admin-src-components-MoreButtonComponent-styles-module__ql-picker-item-fZku7W[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MoreButtonComponent-styles-module__ql-font-Montserrat-LsV2VT {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MoreButtonComponent-styles-module__more-tDZ2RR {
  color: var(--personalColor);
  cursor: pointer;
  font-weight: 500;
}

.admin-src-components-MoreButtonComponent-styles-module__more__value-truyGW {
  margin-right: 6px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MoreButtonComponent/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;AAClB;;AAMA;EAHE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.more {\n  color: var(--personalColor);\n  cursor: pointer;\n  font-weight: 500;\n}\n.more__value {\n  margin-right: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MoreButtonComponent-styles-module__ql-picker-R4vYxO`,
	"qlPicker": `admin-src-components-MoreButtonComponent-styles-module__ql-picker-R4vYxO`,
	"ql-font": `admin-src-components-MoreButtonComponent-styles-module__ql-font-PqHvad`,
	"qlFont": `admin-src-components-MoreButtonComponent-styles-module__ql-font-PqHvad`,
	"ql-picker-label": `admin-src-components-MoreButtonComponent-styles-module__ql-picker-label-fwvbkO`,
	"qlPickerLabel": `admin-src-components-MoreButtonComponent-styles-module__ql-picker-label-fwvbkO`,
	"ql-picker-item": `admin-src-components-MoreButtonComponent-styles-module__ql-picker-item-fZku7W`,
	"qlPickerItem": `admin-src-components-MoreButtonComponent-styles-module__ql-picker-item-fZku7W`,
	"ql-font-Montserrat": `admin-src-components-MoreButtonComponent-styles-module__ql-font-Montserrat-LsV2VT`,
	"qlFontMontserrat": `admin-src-components-MoreButtonComponent-styles-module__ql-font-Montserrat-LsV2VT`,
	"more": `admin-src-components-MoreButtonComponent-styles-module__more-tDZ2RR`,
	"more__value": `admin-src-components-MoreButtonComponent-styles-module__more__value-truyGW`,
	"moreValue": `admin-src-components-MoreButtonComponent-styles-module__more__value-truyGW`
};
export default ___CSS_LOADER_EXPORT___;
