/* eslint-disable */
import React from 'react';

import { Layout } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Layout.module.scss';

function CustomContent({ className, children, ...props }) {
  return (
    <Layout.Content className={classNames(css.Content, className)} {...props}>
      {children}
    </Layout.Content>
  );
}

CustomContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default CustomContent;
