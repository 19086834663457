// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-mRcv76.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-ACh56l .admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-label-dR5TDO[data-value="Montserrat"]:before, .admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-mRcv76.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-ACh56l .admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-item-Ms8dhZ[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-Montserrat-XPJfuC {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabApiSettings_card-XvrZv1 {
  padding: 20px 32px 20px 20px;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabApiSettings_card-XvrZv1:not(:last-child) {
  margin-bottom: 30px;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-ERUN2s {
  margin-top: 17px;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-card-P_Q5RJ {
  padding: 20px 32px 20px 20px;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-title-yzSsWM {
  font-weight: 400;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__CustomTitle-pRhgzU {
  background-color: rink;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ButtonContainer-NAdSuX {
  justify-content: flex-end;
  gap: 24px;
  margin-top: 45px;
  display: flex;
}

.admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ButtonContainer-NAdSuX button {
  width: 140px;
}

.ant-card-head {
  border-bottom: none;
  min-height: 21px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/VarDirectory/Tabs/Api/TabApiSettings.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,4BAA4B;AAC9B;;AAMA;EAHE,mBAAmB;AACrB;;AAMA;EAHE,gBAAgB;AAClB;;AAKA;EAFE,4BAA4B;AAC9B;;AAIA;EADE,gBAAgB;AAClB;;AAIA;EADE,sBAAsB;AACxB;;AAIA;EADE,yBAAyB;EACzB,SAAS;EACT,gBAAgB;EAChB,aAAa;AACf;;AAGA;EAAE,YAAY;AACd;;AAGA;EAAE,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.TabApiSettings_card {\n  padding: 20px 32px 20px 20px;\n}\n.TabApiSettings_card:not(:last-child) {\n  margin-bottom: 30px;\n}\n\n.TabMargin {\n  margin-top: 17px;\n}\n.TabMargin-card {\n  padding: 20px 32px 20px 20px;\n}\n.TabMargin-title {\n  font-weight: 400;\n}\n\n.CustomTitle {\n  background-color: rink;\n}\n\n.ButtonContainer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 24px;\n  margin-top: 45px;\n}\n.ButtonContainer button {\n  width: 140px;\n}\n\n:global .ant-card-head {\n  font-weight: 400;\n  min-height: 21px;\n  border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-mRcv76`,
	"qlPicker": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-mRcv76`,
	"ql-font": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-ACh56l`,
	"qlFont": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-ACh56l`,
	"ql-picker-label": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-label-dR5TDO`,
	"qlPickerLabel": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-label-dR5TDO`,
	"ql-picker-item": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-item-Ms8dhZ`,
	"qlPickerItem": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-picker-item-Ms8dhZ`,
	"ql-font-Montserrat": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-Montserrat-XPJfuC`,
	"qlFontMontserrat": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ql-font-Montserrat-XPJfuC`,
	"TabApiSettings_card": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabApiSettings_card-XvrZv1`,
	"tabApiSettingsCard": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabApiSettings_card-XvrZv1`,
	"TabMargin": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-ERUN2s`,
	"tabMargin": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-ERUN2s`,
	"TabMargin-card": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-card-P_Q5RJ`,
	"tabMarginCard": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-card-P_Q5RJ`,
	"TabMargin-title": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-title-yzSsWM`,
	"tabMarginTitle": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__TabMargin-title-yzSsWM`,
	"CustomTitle": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__CustomTitle-pRhgzU`,
	"customTitle": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__CustomTitle-pRhgzU`,
	"ButtonContainer": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ButtonContainer-NAdSuX`,
	"buttonContainer": `admin-src-pages-VarDirectory-Tabs-Api-TabApiSettings-module__ButtonContainer-NAdSuX`
};
export default ___CSS_LOADER_EXPORT___;
