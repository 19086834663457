import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { badgeBindTopic } from '@/store/badge/actions';
import { finishBlockByAdmin, rejectBlockByAdmin } from '@/store/block_admin/actions';
import { clearBooksInUse, freeBook, getBookInUse } from '@/store/books/actions';
import {
  addMassEvent,
  addUserEvent,
  changeCoursesFilter,
  changeToComplited,
  checkDeleteEvent,
  checkDeleteUserEvent,
  clearCoursesFilter,
  courseRequestFinish,
  createEvent,
  deleteEvent,
  deleteMass,
  deleteRequestCourses,
  deleteUserEvent,
  failEventValidation,
  getCommentRejected,
  getCoursesRequest,
  getCoursesRequests,
  getEventParticipants,
  getEventParty,
  getEventTopic,
  getMassSelectionCount,
  getYear,
  postCommentRejected,
  putRescheduleAll,
  sendMail,
  updateCoursesRequest,
  updateEvent,
  updateStatusAll,
  updateStudyPlanDate,
} from '@/store/courses/actions';
import { getFile } from '@/store/files/actions';
import { getTopicIncome, getUserIncome2 } from '@/store/income/actions';
import { getBlockStatistic, getBlocksTypeTopic, getResultStatistic, getStudyPlanStatus } from '@/store/lms/actions';
import { getInfoCompany } from '@/store/login/actions';
import { getMassFilters } from '@/store/massfilter/actions';
import {
  clearSettingsVisibility,
  deleteSettingsVisibility,
  exportUsersModalVisibility,
  getCountSettingsVisibility,
  getCountTopicSettingsVisibility,
  getSettingsVisibility,
  updateSettingsVisibility,
} from '@/store/settings_visibility/actions';
import { getMaterialStatistics, putNotificationReminder } from '@/store/statistics/actions';
import {
  clearStudents,
  createStudyPlan,
  exportSelectedUserInMass,
  getCountSelectedUsers,
  getDepartment,
  getFullName,
  getFunction,
  getPosition,
  getStudents,
  massFilterAdd,
} from '@/store/students/actions';
import {
  changeIndependentStatus,
  changePositionTopic,
  changeScope,
  editTopicVisibility,
  getTopicById,
  getTopics,
  getTopicSection,
  newMaterialExport,
} from '@/store/topics/actions';
import { getCountWaitingUsers } from '@/store/waiting-list/actions';

import { selectBooks } from '@/store/books/selectors';
import { selectCourses as coursesSelect } from '@/store/courses/selectors';
import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';
import { selectAllStatistics } from '@/store/statistics/selectors';
import { selectStudents as studentSelect } from '@/store/students/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import {
  selectUsers as usersSelect,
  selectSubordinatesCurrentUser as userSubordinateEmployees,
} from '@/store/users/selectors';
import { selectWaitingList } from '@/store/waiting-list/selectors';

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  List,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Tabs,
  Tooltip,
} from 'antd';
import EventSelectModal from '@/pages/ManageCourse/EventSelectModal';
import WaitingListModal from '@/pages/Topics/TopicDetailsDialog/Materials/WaitingListModal';
import AddressBook from '@/components/AddressBook/AddressBook';
import Assignment from '@/components/Assignment';
import CalendarModal from '@/components/CalendarModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import CoursesTopicMemberstable from '@/components/CoursesTopicMembersTable';
import FixMaterial from '@/components/FixMaterial';
import { Content } from '@/components/Layout';
import CustomModal from '@/components/Modal/Modal';
import { ModerateTopicModal } from '@/components/ModerateTopicModal';
import TemplateBlockFilters from '@/components/TemplateBlockFilters';
import { ERROR, SUCCESS, WARNING } from '@/components/TemplateBlockFilters/constants';
import { CheckCircleOutlined, CloseOutlined, DownloadOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';

import CreateEventModal from '../../ManageCourse/CreateEventModal';
import EventInfoModal from '../../ManageCourse/EventInfoModal';
import SendCommentModal from '../../SendReviewModal';
import TopicCreationDialog from '../TopicCreationDialog';
import ITODDialog from '../TopicCreationDialog/ITODDialog';
import AllStatistics from './AllStatistics/AllStatistics';
import DrawerUsers from './DrawerUsers';
import { LMS } from './LMS/LMS';
import EventsActions from './Materials/EventsActions';
import Filter from './Materials/Filter';
import SortTable from './Materials/SortTable';
import TopicContent from './Materials/TopicContent';
import { CollectionTopicsProvider } from './ProviderContextProps';
import TestsInfoModal from './TestsInfoModal';
import WebinarStatistic from './WebinarStatistic';

import debounce from '@shared/utils/debounce';
import {
  isAnyCourseOrConference,
  isBook,
  isConference,
  isConfOrAllCourse,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourse,
  isCorpOrExtCourseOrConf,
  isEvent,
  isEventOrConference,
  isSkills,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './TopicDetailsDialog.module.scss';

import {
  STUDY_PLAN_UPDATE_BY_ADMIN,
  TOPIC_EXECUTIVE_TASKS_UPDATE,
  TOPIC_PIN_INTERACT,
  TOPIC_UPDATE,
} from '@/constants/permissions';
import { APPROVED, REJECTED } from '@/constants/statuses';
import lock from '@/assets/images/lock.svg';

const TAB_POSITION = {
  TOPIC: 'topic',
  LMS: 'lms',
  WEBINAR: 'webinar',
};

const DEFAULT_SORT = 'addedDate,desc';
const OLD_DEFAULT_SORT = 'plan.addedDate,desc';
// const TYPE_COURSE = [17, 20, 23, 35]; // 17 корп курс, 20 внешний курс, 23 конфер

export class TopicDetailsDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabPosition: TAB_POSITION.TOPIC,
      scopeButton: this.props.topic.scope,
      createEventModal: false,
      selectEventModal: false,
      currentYear: dayjs().format('YYYY'),
      selectRequest: [],
      selectRequestByAction: [],
      allSelect: false,
      editEvent: null,
      eventName: '',
      editable: false,
      showModalModerateTopic: false,
      showModalAddUser: false,
      showModalSendMail: false,
      showEventNoPlaceModal: false,
      addUserNotValidate: false,
      visibleDateModal: false,
      visibleTestModal: false,
      userData: false,
      iTODVisible: false,
      showEventDetail: null,
      currentPlan: null,
      topicIncome: [],
      isEnded: _.get(this.props, 'coursesFilter.isEnded', false),
      month: _.get(this.props, 'coursesFilter.month') || undefined,
      userId: _.get(this.props, 'coursesFilter.userId') || undefined,
      function: _.get(this.props, 'coursesFilter.function') || undefined,
      status: _.get(this.props, 'coursesFilter.status') || undefined,
      department: _.get(this.props, 'coursesFilter.department') || undefined,
      showEventInfoModal: null,
      showModalDeleteUserEvent: null,
      showModalDeleteEvent: null,
      showModalConfirmDeleteEvent: false,
      showModalConfirmDeleteUser: false,
      isUpdate: false,
      statusOptions: this.statusOptions(),
      page: 1,
      year: _.get(this.props, 'coursesFilter.year') || undefined,
      filterYear: _.get(this.props, 'coursesFilter.year') || undefined,
      imageSrc: '',
      activeSort: _.get(this.props, 'coursesFilter.sort'),
      statusActiveMassButton: [],
      autocompleteUserId: null,
      isLoading: true,
      isAddressBookVisible: false,
      qiwiLibrary: false,
      isManager: this.props.isManager || false,
      courses: null,
      drawerUsers: false,
      domainCompanyInfo: null,
      subCompanies: Utils.isGPMValid(props.currentUser),
      topicTypeId: null,
      countView: null,
      applyAll: false,
      isExpiredDate: false,
      topicId: null,
      filterMass: {},
      topicCreatingTemplateVisible: false,
      isOneFilter: false,
      modalSettingsVisible: false,
      showModalReasonTransfer: false,
      showBookFreeModal: false,
      showModalDeleteComment: null,
      showDeletedUsers: false,
      managerId: null,
      startDate: null,
      endDate: null,
      waitingListModal: false,
      inspectionMode: false,
      inspectionStatus: null,
      usersModalVisible: false,
      sortOrder: 'desc',
    };

    this.isAdmin = Utils.isAdminRole(this.props.currentUser);
    this.withEvents = true;
    this.isAdminPlanMan = this.props.currentUser.authorities.includes(STUDY_PLAN_UPDATE_BY_ADMIN);
    this.getCoursesRequestsDebounced = debounce(props.getCoursesRequests);
    this.getEventTopicDebounced = debounce(props.getEventTopic);
    this.isNoCourse = false;
    this.changeComplitedAdminStatus = props.t('notComplete');
  }

  componentDidMount() {
    this.props.clearCoursesFilter();
    const topicId = _.get(this.props, 'params.id');

    if (topicId) {
      this.setState({ topicId });
    }

    this.props.getTopicById(topicId, response => {
      this.setState({
        qiwiLibrary: response.qiwiLibrary,
        countView: response.viewers.count,
      });

      this.props.getUserIncome({
        userId: response.creBy.id,
        topicId,
        bonus: 'TOPIC',
      });

      if (isEventOrConference(response.type.id)) {
        this.props.getTopicSection({ ids: [2] });
      }

      const topicTypeId = _.get(response, 'type.id');
      this.setState({ topicTypeId });

      if (topicId && !isSkills(topicTypeId)) {
        this.props.getCountWaitingUsers(topicId);
      }

      // без согласования и не 35 topic.type.id
      this.isNoCourse = !response.type?.approved && !isCorporateCourseWithoutApprove(topicTypeId);

      if (this.props.coursesFilter && 'sort' in this.props.coursesFilter) {
        this.setState({ activeSort: this.props.coursesFilter.sort });
      } else {
        const activeSort = this.isNoCourse ? DEFAULT_SORT : OLD_DEFAULT_SORT;

        this.props.changeCoursesFilter({ name: 'sort', value: activeSort });
        this.setState({ activeSort });
      }

      this.withEvents = isCorpOrExtCourseOrConf(topicTypeId) || isCorporateCourseWithoutApprove(topicTypeId);

      if (isBook(topicTypeId)) {
        this.props.getBookInUse(topicId);
      }

      if (this.props.coursesFilter && 'year' in this.props.coursesFilter) {
        this.props.getYear(() => {}, { topicId, events: true, requests: true });
        this.setState({ year: this.props.coursesFilter.year });
      } else {
        this.props.getYear(() => this.setState({ year: undefined }), { topicId, events: true, requests: true });
      }

      if (this.props.coursesFilter && 'status' in this.props.coursesFilter) {
        this.setState({ status: this.props.coursesFilter.status });
      } else {
        // const status = ['WAITING', 'APPROVED', 'REJECTED_BY_USER', 'APPROVED_BY_MANAGER', 'ON_REGISTRATION'];
        const status = [];

        this.props.changeCoursesFilter({ name: 'status', value: status });
        this.setState({ status });
      }

      if (!response.type.approved || !response.type.withEvents) {
        this.props.changeCoursesFilter({ name: 'status', value: '' });
      }

      // на getCoursesRequests нужно отправлять true или false, чтобы для запроса принять isNoCourse в зависимости от topic.type.id, выше я создал массив из них TYPE_COURSE
      // где я проверяю содержит ли текущий курс эти значения
      this.getCoursesRequestsDebounced(
        {
          topicId,
          status: this.isNoCourse ? '' : this.state.status,
          sort: this.state.activeSort,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse
      );

      this.setState({ dependent: response.independent });

      if (this.props.coursesFilter?.managerId) {
        delete this.props.coursesFilter.managerId;
      }

      this.getEventTopicDebounced(topicId, {
        year: this.state.filterYear,
        isEnded: this.state.isEnded,
      });

      if (response.coverFileUuid) {
        this.props.getFile(response.coverFileUuid, res => {
          this.setState({ imageSrc: URL.createObjectURL(res) });
        });
      } else {
        this.setState({ imageSrc: '' });
      }

      this.setState({ isLoading: false });

      this.props.getMassSelectionCount(topicId);
      this.props.getStudyPlanStatus(topicId);
      this.props.getFunction();
      this.props.getPosition();
      this.props.getDepartment();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.topic.scope !== this.props.topic.scope) {
      this.setState({ scopeButton: this.props.topic.scope });
    }

    if (
      prevProps.eventList &&
      prevProps.eventList.filter(item => item.id === this.state.showEventDetail).length === 0 &&
      prevProps.eventList !== this.props.eventList
    ) {
      this.setState({ showEventDetail: null, eventName: null });
    }

    if (
      prevProps.delete !== this.props.delete ||
      (prevProps.updateReqUser !== this.props.updateReqUser && this.props.updateReqUser)
    ) {
      this.getEventTopicDebounced(this.props.params.id, {
        sort: this.props.coursesFilter && this.props.coursesFilter.sort,
        year: this.props.coursesFilter && this.props.coursesFilter.year,
        isEnded: prevState.isEnded,
      });

      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...this.props.coursesFilter,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse
      );
    }

    if (prevState.page !== this.state.page) {
      const filters = this.props.coursesFilter ? this.props.coursesFilter : prevProps.coursesFilter;
      // запрос на топики где есть эвент и согласование, тк проставляется фильтр и он изменяется
      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...filters,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse,
        () => this.setState({ isOneFilter: true })
      );
    }

    if (prevProps.coursesPageNumber !== this.props.coursesPageNumber) {
      this.setState({ page: this.props.coursesPageNumber + 1 });
    }
  }

  handleSubmitFilters = sort => {
    const currentSort = sort && typeof sort === 'string' ? sort : this.state.activeSort;

    if (this.props.topic?.type && this.props.topic?.type?.approved && this.props.topic?.type?.withEvents) {
      const filters = this.props.coursesFilter;
      // запрос на топики где есть эвент и согласование, тк проставляется фильтр и он изменяется
      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...filters,
          sort: currentSort,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse,
        () => this.setState({ isOneFilter: true })
      );
    } else if (this.props.topic?.type) {
      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...this.props.coursesFilter,
          sort: currentSort,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse
      );
    }

    if (this.state.isEnded || this.state.year) {
      this.getEventTopicDebounced(this.props.params.id, {
        year: this.state.year,
        isEnded: this.state.isEnded,
      });
      this.setState({
        ...this.state,
        page: 1,
        selectRequest: [],
        currentPlan: null,
        allSelect: false,
      });
    }
  };

  componentWillUnmount() {
    this.setState({ topicTypeId: null, topicId: null });
    this.props.clearBooksInUse();
    this.props.clearCoursesFilter();
  }

  statusOptions = () => [
    { id: 1, value: 'APPROVED_BY_MANAGER', name: this.props.t('pendingApproval') },
    { id: 2, value: 'APPROVED', name: this.props.t('approvedAdmin') },
    { id: 3, value: 'REJECTED', name: this.props.t('denied') },
    { id: 4, value: 'WAITING_BY_USER', name: this.props.t('pending') },
    { id: 5, value: 'APPROVED_BY_USER', name: this.props.t('confirmed') },
    { id: 6, value: 'REJECTED_BY_USER', name: this.props.t('decline') },
    { id: 7, value: 'FINISHED', name: this.props.t('review') },
    { id: 8, value: 'WAITING', name: this.props.t('pendingManager') },
    { id: 9, value: 'REJECTED_BY_MANAGER', name: this.props.t('deniedManager') },
    { id: 10, value: 'ON_REGISTRATION', name: this.props.t('registration') },
    { id: 11, value: 'EXPIRED', name: this.props.t('completed') },
    { id: 12, value: 'IN_PROGRESS', name: this.props.t('inProgress') },
  ];

  changeScope = () => {
    const { domainCompany } = this.props;
    const topicId = this.props.params.id;

    if (!domainCompany.hasCoins) {
      return this.onChangeScope();
    }

    this.props.getTopicIncome({
      topicId,
      onSuccess: topicIncome => {
        const allIncomeAmount = topicIncome.reduce((res, topic) => (res += topic.amount || 0), 0);

        if (allIncomeAmount > 0) {
          this.setState({ topicIncome });
          return this.showModalModerateTopic();
        }

        this.onChangeScope();
      },
    });
  };

  onChangeScope = () => {
    this.props.changeScope(this.props.params.id, 'EXTERNAL');
    this.hideModalModerateTopic();
    this.hideTopicEditingDialog();
  };

  showModalModerateTopic = () => this.setState({ showModalModerateTopic: true });
  hideModalModerateTopic = () => this.setState({ showModalModerateTopic: false });

  showTopicEditingDialog = () => this.setState({ topicEditingVisible: true });
  hideTopicEditingDialog = () => this.setState({ topicEditingVisible: false });

  showTopicCreatingTemplateDialog = () => this.setState({ topicCreatingTemplateVisible: true });
  hideTopicCreatingTemplateDialog = () => this.setState({ topicCreatingTemplateVisible: false });

  showITODDialog = () => this.setState({ iTODVisible: true });
  hideITODDialog = () => this.setState({ iTODVisible: false });

  handleTopicEditingSuccess = (id, rewardId, needBind) => {
    if (needBind) {
      this.props.badgeBindTopic(
        { topicId: id, rewardId },

        () =>
          message[rewardId ? 'success' : 'warning'](
            rewardId ? this.props.t('addedBadge') : this.props.t('deleteBadge')
          ),
        () => message.error(this.props.t('errorAddedBadge'))
      );
    }

    this.props.getTopicById(this.props.params.id, response => {
      if (response.coverFileUuid) {
        this.props.getFile(response.coverFileUuid, res => {
          this.setState({ imageSrc: URL.createObjectURL(res) });
        });
      } else {
        this.setState({ imageSrc: '' });
      }

      if (response.scope === 'UNMODERATE') {
        this.changeScope();
      } else {
        this.hideTopicEditingDialog();
      }
    });
  };

  showEventCreateDialog = () => this.setState({ createEventModal: true });
  showSelectEventDialog = () => this.setState({ selectEventModal: true });
  handleEventCreateSuccess = () => this.hideEventCreateDialog();

  hideEventCreateDialog = () =>
    this.setState({
      editable: false,
      editEvent: null,
      createEventModal: false,
      autocompleteUserId: null,
      selectEventModal: false,
      showEventInfoModal: null,
    });

  changeSelect = event => {
    const allUser = this.selectAllUser();
    if (event.value) {
      let findUser = this.state.selectRequest.filter(selectUser => selectUser.id === event.id);
      if (!findUser.length) {
        this.saveSelectUser(event);
      }
    } else {
      let findUser = this.state.selectRequest.filter(selectUser => selectUser.id === event.id);
      if (findUser.length) {
        this.deleteSelectUser(event);
      }
    }
    this.setState({
      statusActiveMassButton: allUser.map(res => res.status),
      allSelect: false,
    });
  };

  uniqueChangeSelectAll = arr => {
    const obj = {};
    for (let i = 0; i < arr.length; i++) {
      const str = arr[i].id;
      obj[str] = true;
    }
    return Object.keys(obj);
  };

  selectAllUser() {
    const res = this.props.courses.filter(courses => {
      if (
        courses.plan.status !== 'FINISHED' &&
        courses.plan.status !== 'WAITING_BY_USER' &&
        courses.plan.status !== 'APPROVED_BY_USER' &&
        courses.plan.status !== 'IN_PROGRESS'
      ) {
        return courses;
      }
    });
    const resArrRequest = res
      .filter(item => item.plan.user.status !== 'DELETED')
      .map(item => ({
        ...item,
        plan: item.plan,
        status: item.plan.status,
        courseId: item.id,
        planId: item.plan.id,
        id: item.plan.user.id,
        name: `${item.plan.user.lastName} ${item.plan.user.firstName}`,
        value: true,
      }));
    return resArrRequest;
  }

  saveSelectUser = user => {
    this.setState(prevState => ({
      selectRequest: [...prevState.selectRequest, user],
    }));
  };

  deleteSelectUser = user => {
    this.setState(prevState => ({
      selectRequest: prevState.selectRequest.filter(selectUsers => selectUsers.id !== user.id),
    }));
  };

  changeSelectAll = event => {
    if (!this.state.applyAll) {
      let allUser = this.selectAllUser();
      if (event.value) {
        allUser.forEach(user => {
          const findUser = this.state.selectRequest.filter(selectUser => selectUser.id === user.id);
          if (!findUser.length) {
            this.saveSelectUser(user);
          }
        });
        this.setState({
          statusActiveMassButton: allUser.map(user => user.status),
          allSelect: true,
          applyAll: false,
        });
      } else {
        allUser.forEach(user => {
          this.deleteSelectUser(user);
        });
        this.setState({ currentPlan: null, allSelect: false });
      }
    }
  };

  massSelected = () => {
    const filter = {
      year: this.state.year,
      month: this.state.month,
      userId: this.state.userId,
      function: this.state.function,
      department: this.state.department,
      status: this.state.status,
    };
    if (!this.isNoCourse && this.state.applyAll) {
      const result = Object?.keys(filter)?.reduce((acc, key) => {
        const keyData = filter[key];
        if (!keyData || keyData.length === 0) {
          delete filter[key];
          return acc;
        }
        acc[key] = keyData;
        return acc;
      }, {});
      this.setState({ filterMass: result });
    }
  };

  applyAll = e => this.setState({ applyAll: e.value });

  addUserEventHandler = (user, isSoloUser) => {
    if (this.state.applyAll) {
      this.setState({ showModalAddUser: true });
      return;
    }

    if (this.state.selectRequestByAction.length !== 0) {
      const req = user.id ? [...this.state.selectRequestByAction, user] : this.state.selectRequestByAction;
      this.setState({
        addUserNotValidate: !req.length || this.state.eventName === '',
        showModalAddUser: true,
        selectRequest: isSoloUser ? [user] : req,
        user: user && user.id,
      });
    } else {
      const req = user.id ? [...this.state.selectRequest, user] : this.state.selectRequest;
      this.setState({
        addUserNotValidate: !req.length || this.state.eventName === '',
        showModalAddUser: true,
        selectRequest: isSoloUser ? [user] : req,
        user: user && user.id,
      });
    }
  };

  notificationSuccess = res => {
    notification.info({
      message: this.props.t('attention'),
      description: this.props.t('infoMsg', { total: res }),
      placement: 'bottomRight',
    });
  };

  notificationError = res => {
    if (res?.message && res.message.length) {
      notification.warning({
        message: this.props.t('attention'),
        description: res.message,
        placement: 'bottomRight',
      });
    }
  };

  hideConfirmDialog = () => {
    if (this.state.applyAll && this.state.showEventDetail) {
      this.setState({ showModalAddUser: false });

      this.props.addMassEvent(
        this.state.showEventDetail,
        this.state.topicId,
        this.isNoCourse ? '' : this.state.filterMass,
        _.isEmpty(this.state.filterMass) ? false : true,
        this.notificationSuccess,
        this.notificationError
      );
    } else {
      const selectRequest = this.state.selectRequest.slice();
      selectRequest.pop();
      this.setState({
        selectRequest,
        showModalAddUser: false,
        addUserNotValidate: false,
        user: null,
        allSelect: false,
      });
    }
  };

  hideConfirmDialogSuccess = (eventId = null) => {
    const selectRequestId = this.state.autocompleteUserId
      ? [this.state.autocompleteUserId]
      : this.state.selectRequest
          .filter(user => {
            const status = user.status || user.plan.status;
            return (
              status === 'APPROVED' ||
              status === 'REJECTED_BY_USER' ||
              status === 'WAITING' ||
              (status === 'PLANNED' && isCorporateCourseWithoutApprove(this.state.topicTypeId))
            );
          })
          .map(user => `${user.courseId || user.id}`);

    if (this.state.eventHasPlace || eventId) {
      if (selectRequestId.length > 0) {
        this.props.addUserEvent(eventId || this.state.showEventDetail, selectRequestId, () => {
          this.getEventTopicDebounced(this.props.params.id, {
            year: (this.props.coursesFilter && this.props.coursesFilter.year) || this.state.filterYear,
            isEnded: this.state.isEnded,
          });
          if (isCorporateCourseWithoutApprove(this.state.topicTypeId)) {
            this.props.updateCoursesRequest('WAITING_BY_USER', '', selectRequestId);
          }
          this.getCoursesRequestsDebounced(
            {
              topicId: this.props.params.id,
              ...this.props.coursesFilter,
              inspectionMode: this.state.inspectionMode,
            },
            this.state.subCompanies,
            this.state.page - 1,
            this.isNoCourse
          );
          this.setState({
            selectRequest: [],
            currentPlan: null,
            allSelect: false,
          });
        });
      }
      this.hideConfirmDialog();
    } else {
      this.hideConfirmDialog();
      if (!this.state.eventName) {
        return this.setState({
          addUserNotValidate: true,
          showModalAddUser: true,
          user: selectRequestId.length > 0,
        });
      }
      this.setState({ showEventNoPlaceModal: true });
    }
  };

  hideEventNoPlaceModal = () => this.setState({ showEventNoPlaceModal: false });

  deleteUserEventHandler = (id, isApprovedUser) => {
    this.setState({
      showModalDeleteUserEvent: id,
      isApprovedUser:
        isApprovedUser === 'APPROVED_BY_USER' ||
        isApprovedUser === 'IN_PROGRESS' ||
        isApprovedUser === 'ON_REGISTRATION',
    });
  };

  hideDeleteUserEvent = () => {
    this.props.checkDeleteUserEvent(this.state.showModalDeleteUserEvent, response => {
      if (
        response?.plan?.status &&
        (response.plan.status === 'APPROVED_BY_USER' ||
          response.plan.status === 'IN_PROGRESS' ||
          response.plan.status === 'ON_REGISTRATION')
      ) {
        this.setState({ showModalDeleteComment: true });
      } else {
        this.hideDeleteUserEventSuccess('');
      }
    });
  };

  hideDeleteUserEventSuccess = comment => {
    this.props.deleteUserEvent([this.state.showModalDeleteUserEvent], comment, () => {
      this.getEventTopicDebounced(this.props.params.id, {
        year: (this.props.coursesFilter && this.props.coursesFilter.year) || this.state.filterYear,
        isEnded: this.state.isEnded,
      });
      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...this.props.coursesFilter,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse
      );
      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...this.props.coursesFilter,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse
      );
      this.setState({ showModalDeleteUserEvent: null, showModalDeleteComment: null });
    });
  };

  hideHandleDeleteComment = () => {
    this.setState({
      showModalDeleteComment: null,
      showModalConfirmDeleteUser: false,
      showModalDeleteUserEvent: false,
    });
  };

  hideHandleDeleteEventComment = () => {
    this.setState({
      showModalDeleteEventComment: null,
      showModalConfirmDeleteEvent: false,
      showModalDeleteEvent: false,
    });
  };

  hideDeleteUserEventFail = () => this.setState({ showModalDeleteUserEvent: null });
  deleteEventHandler = (id, isApproved) => this.setState({ showModalDeleteEvent: id, isApproved });

  hideDeleteEvent = () => {
    this.props.checkDeleteEvent(this.state.showModalDeleteEvent, response => {
      if (response) {
        this.setState({ showModalDeleteEventComment: true });
      } else {
        this.hideDeleteEventCommentSuccess('');
      }
    });
  };

  hideDeleteEventCommentSuccess = comment => {
    this.props.deleteEvent(this.state.showModalDeleteEvent, comment, () => {
      this.getEventTopicDebounced(this.props.params.id, {
        year: (this.props.coursesFilter && this.props.coursesFilter.year) || this.state.filterYear,
        isEnded: this.state.isEnded,
      });
      this.props.getYear(
        () => {
          this.setState({
            year: (this.props.coursesFilter && this.props.coursesFilter.year) || this.state.filterYear,
          });
        },
        { topicId: this.props.params.id, events: true, requests: true }
      );
      this.getCoursesRequestsDebounced(
        {
          topicId: this.props.params.id,
          ...this.props.coursesFilter,
          inspectionMode: this.state.inspectionMode,
        },
        this.state.subCompanies,
        this.state.page - 1,
        this.isNoCourse
      );
      this.setState({
        showModalDeleteEvent: null,
        showModalDeleteEventComment: null,
      });
    });
    if (this.state.showModalDeleteEvent === this.state.showEventDetail) {
      this.setState({ eventName: '' });
    }
  };

  handleDeleteComment = () => this.setState({ showModalDeleteComment: true });

  hideDeleteEventFail = () => {
    this.setState({ showModalDeleteEvent: null, showModalDeleteEventComment: null });
  };

  showEventInfoHandler = event => {
    this.setState({ showEventInfoModal: event });
  };
  hideEventInfoHandler = () => this.setState({ showEventInfoModal: null });

  editEventHandler = event => {
    this.setState({ editEvent: event, editable: true });
    this.showEventCreateDialog();
  };

  changeEventName = e => this.setState({ eventName: e || '' });

  showEventDetails = (event, name, date, hasPlace, openPanel) => {
    this.setState({
      showEventDetail: event === this.state.showEventDetail && openPanel ? null : event,
      eventName: date === this.state.eventName && openPanel ? '' : date,
      eventHasPlace: hasPlace,
    });
  };

  closePage = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      this.props.navigate('/topics');
    }
  };

  handleScopeChange = event => {
    const { name, value } = event;
    this.setState({ [name]: value });
    this.props.changeCoursesFilter({ name, value });

    if (name === 'userId' && value.length === 0) {
      this.props.clearStudents();
    }
  };

  handleChangeFilterType = event => {
    const { name, value } = event;
    return this.setState({ [name]: name === 'isEnded' ? (value ? true : false) : value ? !value : '' }, () => {
      this.props.changeCoursesFilter({
        name,
        value: value ? !value : '',
      });
    });
  };

  hideSendMail = () => this.setState({ showModalSendMail: false });
  showSendMail = index => this.setState({ showModalSendMail: index });

  sendMail = () => {
    this.props.sendMail(this.state.showModalSendMail);
    this.hideSendMail();
  };

  getEventParticipants = (id, eventName) => {
    this.props.getEventParticipants(id, `${eventName}.csv`, blob => {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${eventName}.csv`);
      }
      const objectURL = URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.download = `${eventName}.csv`;
      tempLink.style = 'display: none';
      tempLink.href = objectURL;
      document.body.appendChild(tempLink);
      tempLink.click();
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(objectURL);
      }, 1000);
    });
  };

  resetDel = comment => {
    if (comment?.length > 0 && this.state.showModalDeleteComment) {
      return this.setState({ showModalConfirmDeleteUser: !this.state.showModalConfirmDeleteUser });
    }
    if (comment?.length > 0) {
      return this.setState({ showModalConfirmDeleteEvent: !this.state.showModalConfirmDeleteEvent });
    }
    this.hideHandleDeleteEventComment();
    this.hideHandleDeleteComment();
  };

  updateTopic = () => {
    this.props.getTopicById(this.props.params.id, response => {
      if (response.coverFileUuid) {
        this.props.getFile(response.coverFileUuid, res => {
          this.setState({ imageSrc: URL.createObjectURL(res) });
        });
      }
    });
  };

  handleChangeSort = sort => {
    this.setState(prevState => {
      const prevSort = (prevState.activeSort || '').split(',')?.[0] || '';
      const newSort = (sort || '').split(',')?.[0] || '';

      let sortOrder = prevState.sortOrder;
      if (prevSort === newSort) {
        sortOrder = prevState.sortOrder === 'asc' ? 'desc' : 'asc';
      }

      return { activeSort: sort, sortOrder };
    });
    this.props.changeCoursesFilter({ name: 'sort', value: sort });
    this.handleSubmitFilters(sort);
  };

  clearOneFilter = type => {
    this.setState({ [type]: undefined });
    this.props.changeCoursesFilter({ name: type, value: undefined });
  };

  showUsersModal = () => {
    this.setState({ usersModalVisible: true });
    this.props.getStudents(
      {
        size: 10,
        topicId: this.props.params.id,
        byManager: Utils.isManager(this.props.currentUser),
        subCompanies: this.state.subCompanies,
      },
      0,
      false
    );
  };

  addPlanGroup = () => {
    this.setState({
      selectRequest: !this.state.visibleDateModal ? this.state.selectRequest : [],
      visibleDateModal: !this.state.visibleDateModal,
    });
  };

  toggleModalCalendars = plan => {
    this.setState({
      currentPlan: plan || null,
      visibleDateModal: !this.state.visibleDateModal,
      isUpdate: !this.state.isUpdate,
    });
  };

  hideUsersModal = () => {
    this.setState({ allSelect: false, usersModalVisible: false });
  };

  handleMassFilter = sendData => {
    if (this.state.topicId && sendData) {
      this.props.massFilterAdd(this.state.topicId, sendData, () => {
        this.refreshCoursesRequests(this.state.topicId);
        this.props.getMassSelectionCount(this.state.topicId); // обновляем кол-во пользователей вверху справа топика
        this.hideUsersModal();
      });
    }
  };

  /**
   *
   */
  handleRecommendPost = (userIds, inspection) => {
    this.setState(
      {
        isUpdate: false,
        showModalReasonTransfer: false,
        currentPlan: null,
        selectRequest: !this.state.visibleDateModal ? (userIds.length > 0 ? userIds : this.state.selectRequest) : [],
        visibleDateModal: !this.state.visibleDateModal,
        inspection: inspection,
      },
      () => this.hideUsersModal()
    );
  };

  getCountUsers = filters => {
    if (this.state.topicId && filters) {
      this.props.getCountSelectedUsers(filters, this.state.topicId);
    }
  };

  exportSelectedUsers = (filters, funcDowload) => {
    if (filters && funcDowload) {
      this.props.exportSelectedUserInMass(filters, blob => funcDowload(blob));
    }
  };

  getSavedFilters = () => {
    if (this.state.topicId) {
      this.props.getMassFilters(this.state.topicId, 'TOPIC');
    }
  };

  onCloseCalendarModal = () => {
    this.setState({
      usersModalVisible: false,
      isUpdate: false,
      visibleDateModal: false,
    });
  };

  onCloseSendCommentModal = () => this.setState({ showModalReasonTransfer: false });

  checkTypeIdTopic = typeId => typeId === 23;

  toggleModalReasonTransfer = (
    planningTopicId,
    selectedMonths,
    comment,
    withoutSchedule,
    rest,
    planInfo,
    selectedEventId
  ) => {
    if (!this.state.isUpdate && !this.state.currentPlan) {
      this.setState(
        {
          visibleDateModal: !this.state.visibleDateModal,
          transferParams: {
            planningTopicId,
            selectedMonths,
            comment,
            withoutSchedule,
            selectedEventId,
          },
        },
        () => this.scheduleStudyPlanClick('')
      );

      return;
    }
    this.setState({
      showModalReasonTransfer: !this.state.showModalReasonTransfer,
      visibleDateModal: !this.state.visibleDateModal,
      transferParams: {
        planningTopicId,
        selectedMonths,
        comment,
        withoutSchedule,
      },
      selectedEventId,
    });
  };

  scheduleStudyPlanClick = comment => {
    let selectedMonths = [];
    let withoutSchedule = true;

    if (this.state.transferParams) {
      if (this.state.transferParams.selectedMonths) {
        selectedMonths = this.state.transferParams.selectedMonths;
      }

      if (this.state.transferParams.withoutSchedule) {
        withoutSchedule = this.state.transferParams.withoutSchedule;
      }
    }

    const topicTypeId = _.get(this.props, 'topic.type.id');
    let ids = this.state.selectRequest.map(item => `${item.id}`);
    const topicId = this.props.params.id;

    if (this.state.applyAll) {
      this.props.putRescheduleAll(this.state.topicId, this.isNoCourse ? '' : this.state.filterMass, {
        comment,
        ids: [0],
        startDate: selectedMonths[0],
        withoutSchedule,
      });
      setTimeout(() => {
        this.refreshCoursesRequests(topicId);
      }, 1000);
      this.setState({ showModalReasonTransfer: !this.state.showModalReasonTransfer });
      return;
    }

    if (!this.state.isUpdate && !this.state.currentPlan) {
      return this.props.createStudyPlan(
        ids,
        topicId,
        selectedMonths[0],
        comment,
        this.state.inspection,
        this.checkTypeIdTopic(topicTypeId),
        this.state.transferParams.selectedEventId,
        () => {
          this.setState(
            {
              visibleDateModal: false,
              currentPlan: null,
              isUpdate: false,
              allSelect: false,
              selectRequest: [],
              showModalReasonTransfer: false,
              transferParams: null,
            },
            () => {
              this.refreshCoursesRequests(topicId);
              this.props.getMassSelectionCount(topicId);
              this.props.getTopicById(topicId);
              this.changeDeletedUsers();
              this.getEventTopicDebounced(this.props.params.id, {
                year: (this.props.coursesFilter && this.props.coursesFilter.year) || this.state.filterYear,
                isEnded: this.state.isEnded,
              });
            }
          );
        }
      );
    }

    if (!this.state.currentPlan) {
      ids = this.state.selectRequest.map(item => `${item.planId}`);
    } else {
      ids = [`${this.state.currentPlan.plan.id}`];
    }

    return this.props.updateStudyPlanDate(
      ids,
      selectedMonths[0],
      comment,
      () => {
        this.setState({
          visibleDateModal: false,
          isUpdate: false,
          selectRequest: [],
          allSelect: false,
          currentPlan: null,
          showModalReasonTransfer: false,
          transferParams: null,
        });
        this.refreshCoursesRequests(topicId);
      },
      this.checkTypeIdTopic(topicTypeId),
      this.state?.selectedEventId
    );
  };

  refreshCoursesRequests = topicId => {
    this.props.getCoursesRequests(
      { topicId, ...this.props.coursesFilter },
      this.state.subCompanies,
      this.state.page - 1,
      this.isNoCourse
    );
  };

  cleanFilter = () => {
    this.props.clearCoursesFilter();
    this.setState({
      userId: undefined,
      month: undefined,
      name: undefined,
      status: [],
      isEnded: false,
      department: undefined,
      function: undefined,
      year: undefined,
      type: undefined,
      managerId: undefined,
      startDate: undefined,
      endDate: undefined,
      fullName: undefined,
      inspectionStatus: undefined,
      sortOrder: 'desc',
      activeSort: this.isNoCourse ? DEFAULT_SORT : OLD_DEFAULT_SORT,
    });
    this.props.changeCoursesFilter({ name: 'status', value: [] });
    this.props.changeCoursesFilter({ name: 'year', value: undefined });
  };

  changeCoursesStatus = status => {
    if (status === REJECTED) {
      return this.setState({ showModalRejected: true });
    }
    if (status === APPROVED) {
      const req = this.state.selectRequest.filter(
        user =>
          user.status === 'WAITING' ||
          user.status === 'REJECTED_BY_MANAGER' ||
          user.status === 'APPROVED_BY_MANAGER' ||
          user.status === 'REJECTED'
      );
      return this.submitRejectedCourseRequest(status, req);
    }
    if (status === 'REJECTED') {
      const req = this.state.selectRequest.filter(
        user =>
          user.status === 'WAITING' ||
          user.status === 'APPROVED_BY_MANAGER' ||
          user.status === 'APPROVED' ||
          user.status === 'REJECTED_BY_USER'
      );
      return this.submitRejectedCourseRequest(status, req);
    }
    if (status === 'APPROVED_BY_MANAGER') {
      const req = this.state.selectRequest.filter(
        user => user.status === 'WAITING' || user.status === 'REJECTED_BY_MANAGER'
      );
      return this.submitRejectedCourseRequest(status, req);
    }
    if (status === 'REJECTED_BY_MANAGER') {
      const req = this.state.selectRequest.filter(
        user => user.status === 'WAITING' || user.status === 'APPROVED_BY_MANAGER'
      );
      return this.submitRejectedCourseRequest(status, req);
    }
    if (status === 'APPROVED_BY_USER') {
      const req = this.state.selectRequest.filter(user => user.status === 'ON_REGISTRATION');
      return this.submitRejectedCourseRequest(status, req);
    }
  };

  submitRejectedCourseRequest = (value, users, comment) => {
    const ids = users.map(item => `${item.courseId}`);
    this.props.updateCoursesRequest(value, comment, ids, () => {
      this.props.getCoursesRequest(0, this.props.coursesFilter);
      this.setState({
        allSelect: false,
        selectRequest: [],
        currentPlan: null,
        showModalRejected: false,
      });
    });
    this.props.getCoursesRequests(
      {
        topicId: this.props.params.id,
        ...this.props.coursesFilter,
      },
      this.state.subCompanies,
      this.state.page - 1,
      this.isNoCourse
    );
  };

  setTestReview = params => {
    const sendParams = {
      topicId: this.props.topic.id,
      subCompanies: this.state.subCompanies,
      status: params.status,
      fullName: params.fullName,
      blockTypeId: params.blockTypeId,
    };

    if (params.isFinish) {
      this.props.rejectBlockByAdmin(params, () => {
        this.props.getResultStatistic(sendParams, params.page);
      });
    } else {
      this.props.finishBlockByAdmin(params, () => {
        this.props.getResultStatistic(sendParams, params.page);
      });
    }
  };

  commentSubmitRejectedCourseRequest = comment => {
    const status = this.isAdmin ? REJECTED : 'REJECTED_BY_MANAGER';

    if (this.state.applyAll) {
      this.props.updateStatusAll(
        this.state.topicId,
        this.isNoCourse ? '' : this.state.filterMass,
        { comment, ids: [0], status },
        () => this.setState({ showModalRejected: false })
      );
      return;
    }

    if (status === REJECTED) {
      const req = this.state.selectRequest.filter(
        user =>
          user.status === 'WAITING' ||
          user.status === 'APPROVED_BY_MANAGER' ||
          user.status === 'APPROVED' ||
          user.status === 'REJECTED_BY_USER'
      );
      return this.submitRejectedCourseRequest(status, req, comment);
    }
    if (status === 'REJECTED_BY_MANAGER') {
      const req = this.state.selectRequest.filter(
        user => user.status === 'WAITING' || user.status === 'APPROVED_BY_MANAGER'
      );
      return this.submitRejectedCourseRequest(status, req, comment);
    }
    this.submitRejectedCourseRequest(status, this.state.selectRequest, comment);
  };

  hideRejectedCourseRequest = comment => {
    if (comment?.length > 0) {
      return this.setState({
        showModalConfirmRejectedCourse: !this.state.showModalConfirmRejectedCourse,
      });
    }
    this.setState({ showModalRejected: false, disableActionBtn: false });
  };

  changeIndependent = event => {
    this.props.changeIndependentStatus(this.props.topic.id, event.target.checked, response => {
      this.setState({ dependent: response.independent });
    });
  };

  funcFilterManagers = (inputValue, option) => {
    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  };

  exportTopic = () => {
    const { topic } = this.props;
    const date = new Date();
    const fileName = `executiveResult-${date.toISOString()}.csv`;

    this.props.newMaterialExport(
      topic.id,
      this.props.coursesFilter,
      blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      },
      () => {
        notification.error({
          message: this.props.t('exportError'),
          duration: 1.5,
          placement: 'topCenter',
        });
      }
    );
  };

  coursesRequestFinish = () => {
    const ids = this.state.selectRequest.map(item => `${item.planId}`);
    this.props.courseRequestFinish(
      ids,
      false,
      () => {
        this.props.getCoursesRequests(
          {
            topicId: this.props.topic.type.id,
            ...this.props.coursesFilter,
          },
          this.state.subCompanies,
          this.state.page - 1,
          this.isNoCourse
        );
        this.setState({
          allSelect: false,
          selectRequest: [],
          currentPlan: null,
          showModalRejected: false,
        });
        this.hideConfirmRejectUser();
      },
      () => {
        this.setState({
          allSelect: false,
          selectRequest: [],
          currentPlan: null,
          showModalRejected: false,
        });
        this.hideConfirmRejectUser();
      }
    );
  };

  coursesDeleteMass = () => {
    if (this.state.applyAll) {
      // массовое удаление через чекбокс ПРИМЕНИТЬ КО ВСЕМ
      this.deleteCoursesMass();
      return;
    }

    const ids = this.state.selectRequest.map(item => ({ topicId: this.state.topicId, userId: item.plan.user.id }));
    this.deleteCoursesForIds(ids);
  };

  deleteCoursesForIds = ids => {
    this.props.deleteRequestCourses(ids, true, () => {
      // массовое удаление через чекбоксы по списку
      this.refreshCoursesRequests(this.props.params.id);
      this.setState({
        allSelect: false,
        selectRequest: [],
        currentPlan: null,
        showModalRejected: false,
        showBookFreeModal: false,
      });
      this.props.getMassSelectionCount(this.state.topicId);
      this.props.getTopicById(this.state.topicId);
      this.hideConfirmDeleteCourse();

      const isFreeBookCase = isBook(this.state.topicTypeId) && !!this.props.booksInUse.length;

      if (isFreeBookCase && this.state.topicId) {
        const userIds = ids.map(item => item.userId);
        const body = { topicId: this.state.topicId, userIds };

        this.props.freeBook(
          body,
          () => this.props.getBookInUse(Number(this.state.topicId)),
          () => message.error(this.props.t('returnBookFail'))
        );
      }
    });
  };

  deleteCoursesMass = () => {
    this.props.deleteMass(this.state.topicId, this.isNoCourse ? '' : this.state.filterMass, () => {
      this.setState({ confirmDeleteCourseModal: false, applyAll: false, showBookFreeModal: false });
      this.props.getMassSelectionCount(this.state.topicId);
      this.refreshCoursesRequests(this.props.params.id);
      this.props.getTopicById(this.state.topicId);

      const isFreeBookCase = isBook(this.state.topicTypeId) && !!this.props.booksInUse.length;
      if (isFreeBookCase && this.state.topicId) {
        const body = { topicId: Number(this.state.topicId), freeAll: true };

        this.props.freeBook(
          body,
          () => this.props.getBookInUse(Number(this.state.topicId)),
          () => message.error(this.props.t('returnBookFail'))
        );
      }
    });
  };

  confirmDeleteCourse = () => this.setState({ confirmDeleteCourseModal: true });
  hideConfirmDeleteCourse = () => this.setState({ confirmDeleteCourseModal: false });
  confirmRejectUser = () => this.setState({ confirmRejectUsersModal: true });
  hideConfirmRejectUser = () => this.setState({ confirmRejectUsersModal: false });

  getAutoEventProp = () => {
    const topicTypeId = _.get(this.props, 'topic.type.id');
    const types = _.get(this.props, 'topicSection[0].types', []);
    const conferenceType = _.find(types, { id: topicTypeId });
    return _.get(conferenceType, 'autoEvent', false);
  };

  handleCreateEvent = (data, callback) => {
    const callbackFunc = res => {
      if (this.state.autocompleteUserId && res?.id) {
        this.hideConfirmDialogSuccess(res.id);
      }
      this.setState({ autocompleteUserId: null });
      callback();
    };
    this.props.createEvent(data, callbackFunc);
  };

  handleSelectEvent = id => {
    if (this.state.autocompleteUserId && id) {
      this.hideConfirmDialogSuccess(id);
    }
    this.hideEventCreateDialog();
  };

  changeTabs = event => {
    this.setState({ tabPosition: event });

    if (event === TAB_POSITION.LMS && this.props.topic?.id) {
      this.props.getBlocksTypeTopic(this.props.topic.id);
    }
  };

  toggleModalTestResults = (userData, courses) => {
    this.setState({
      userData,
      visibleTestModal: !this.state.visibleTestModal,
      currentPlan: courses,
    });
  };

  showDrawerUsers = selectedUser => {
    if (selectedUser?.id) {
      this.setState({ selectRequestByAction: [selectedUser], drawerUsers: true });
    } else {
      this.setState({ ...this.state, drawerUsers: true });
    }
  };

  hideDrawerUsers = () => {
    this.setState({
      ...this.state,
      selectRequestByAction: [],
      drawerUsers: false,
    });

    this.handleChangeFilterType({ name: 'isEnded', value: true });

    if (this.state.isEnded) {
      this.props.getEventTopic(this.state.topicId, {
        year: this.props.coursesFilter.year,
        isEnded: false,
      });
    }
  };

  handleChangeActiveCheckbox = value => {
    this.props.getEventTopic(this.state.topicId, {
      year: this.props.coursesFilter.year,
      isEnded: !value,
    });
    this.handleChangeFilterType({ name: 'isEnded', value: !value });
  };

  changeAllStatus = status => {
    if (status === 'REJECTED') {
      return this.setState({ showModalRejected: true });
    }

    this.props.updateStatusAll(this.state.topicId, this.isNoCourse ? '' : this.state.filterMass, {
      comment: '',
      ids: [0],
      status,
    });
  };

  showModalSettingsVisible = () => this.setState({ modalSettingsVisible: !this.state.modalSettingsVisible });
  hideModalSettingsVisible = () => this.setState({ modalSettingsVisible: !this.state.modalSettingsVisible });

  // funcCallbackExport = value => this.setState({ successExportUsers: value });

  // exportUsersSettingsVisibility = dataExport => {
  //   this.props.exportUsersModalVisibility(dataExport, blob => {
  //     let url = window.URL.createObjectURL(blob);
  //     let link = document.createElement('a');
  //     link.href = url;
  //     link.click();

  //     this.funcCallbackExport(true);
  //   });
  // };

  openNotification = (value, count) => {
    notification[value]({
      message: this.props.t('titleNotification'),
      description: this.msgNot(value, count),
    });
  };

  msgNot = (value, count) => {
    switch (value) {
      case SUCCESS:
        return this.props.t('descriptionNotificationSuccess', { total: count });

      case WARNING:
        return this.props.t('descriptionNotificationWarning');

      case ERROR:
        return this.props.t('descriptionNotificationError');
    }
  };

  onSubmitVisibility = (sendData, isVisible) => {
    this.props.editTopicVisibility(this.props.topic, isVisible);

    this.props.updateSettingsVisibility(this.props.topic.id, sendData, () => {
      this.props.getCountTopicSettingsVisibility(this.props.topic.id, count => {
        this.openNotification(isVisible ? SUCCESS : WARNING, count);
      });
      this.hideModalSettingsVisible();
    });
  };

  resetSettings = () => {
    this.props.deleteSettingsVisibility(this.state.topicId, count => {
      this.hideModalSettingsVisible();
      this.openNotification(ERROR, count);
    });
  };

  confirmDeleteMass = () => {
    if (isBook(this.state.topicTypeId) && !!this.props.booksInUse.length) {
      this.setState({ showBookFreeModal: true, confirmDeleteCourseModal: false });
      return;
    }
    this.coursesDeleteMass();
  };

  changeDeletedUsers = () => {
    if (!this.state.showDeletedUsers) {
      this.props.getMaterialStatistics({ topicId: this.state.topicId, showDeletedUsers: true }, () => {
        this.setState({ showDeletedUsers: !this.state.showDeletedUsers });
      });
    } else {
      this.props.getMaterialStatistics({ topicId: this.state.topicId }, () => {
        this.setState({ showDeletedUsers: !this.state.showDeletedUsers });
      });
    }
  };

  refreshCourseRequest = () => {
    this.getCoursesRequestsDebounced(
      { topicId: this.props.params.id, ...this.props.coursesFilter },
      this.state.subCompanies,
      this.state.page - 1,
      this.isNoCourse
    );
  };

  changeToComplitedSingle = params => {
    this.props.changeToComplited(
      {
        confirmByAdmin: true,
        studyPlanIds: [params.plan.id],
      },
      () => this.refreshCourseRequest()
    );
  };

  changeToComplited = () => {
    const selectedRequestStudyPlan = this.state.selectRequest.map(item => item?.plan?.id);
    this.props.changeToComplited(
      {
        confirmByAdmin: true,
        studyPlanIds: selectedRequestStudyPlan,
      },
      () => this.refreshCourseRequest()
    );
  };

  changeToComplitedMass = () => {
    this.props.changeToComplited(
      {
        confirmByAdmin: true,
        topicId: this.props.topic.id,
      },
      () => this.refreshCourseRequest()
    );
  };

  handleChangeExpiredDate = value => {
    this.setState({ isExpiredDate: value });
    this.props.changeCoursesFilter({ name: 'isExpiredDate', value: value });
  };

  render() {
    const { topic, coursesPageNumber, isGlobal, t, currentUser } = this.props;

    const titleModal = (
      <div className={css['Visibility']}>
        <h2>{t('title')}</h2>
        <div className={css['Visibility-description']}>{t('titleDescription')}</div>
      </div>
    );

    const propsForActionsButtonMenuContext = {
      roles: this.props.roles,
      typeTopicId: this.props.topic.type && this.props.topic.type.id,
      topicId: this.props.topic.id,
      courseRequestFinish: this.props.courseRequestFinish,
      addUserEventHandler: this.showDrawerUsers,
      filter: this.props.coursesFilter,
      withEvents: this.withEvents,
      page: this.state.page,
      toggleModalCalendars: this.toggleModalCalendars,
      getCoursesRequestTopic: this.getCoursesRequestsDebounced,
      updateCoursesRequest: this.props.updateCoursesRequest,
      deleteRequestCourses: this.props.deleteRequestCourses,
      allSelect: this.state.allSelect,
      isAdminPlanMan: this.isAdminPlanMan,
      changeSelect: this.changeSelect,
      selectRequest: this.props.selectRequestId,
      getCommentRejected: this.props.getCommentRejected,
      postCommentRejected: this.props.postCommentRejected,
      coursesRequestComment: this.props.coursesRequestComment,
      coursesRequestCommentNumber: this.props.coursesRequestCommentNumber,
      coursesRequestCommentPageSize: this.props.coursesRequestCommentPageSize,
      coursesRequestCommentTotal: this.props.coursesRequestCommentTotal,
      domainCompany: this.props.domainCompany,
      topic: this.props.topic,
      showEventCreateDialog: this.showEventCreateDialog,
      showSelectEventDialog: this.showSelectEventDialog,
      eventList: this.props.eventList,
      setUserId: id => this.setState({ autocompleteUserId: id }),
      toggleModalTestResults: this.toggleModalTestResults,
      currentUser: this.props.currentUser,
      subCompanies: this.state.subCompanies,
      changeToComplitedSingle: this.changeToComplitedSingle,
    };

    const editable = (isGlobal && topic.global) || !topic.global;
    const onlyEvent = this.getAutoEventProp();
    const topicTypeId = _.get(topic, 'type.id');

    let canEdit = false;
    if (currentUser) {
      if (Utils.isAdminOrSuperAdmin(currentUser) || (topicTypeId === 25 && Utils.isManager(currentUser))) {
        canEdit = true;
      }
    }

    const eventName =
      this.state.eventName && this.state.eventName.length > 30
        ? `${this.state.eventName.substring(0, 30)}...`
        : this.state.eventName;

    const withEvents =
      isCorpOrExtCourse(topicTypeId) ||
      (isConference(topicTypeId) && onlyEvent) ||
      isCorporateCourseWithoutApprove(topicTypeId);

    const reschMassAction =
      this.isAdminPlanMan &&
      (((this.state.statusActiveMassButton.includes('WAITING') ||
        this.state.statusActiveMassButton.includes('REJECTED_BY_MANAGER') ||
        this.state.statusActiveMassButton.includes('SCHEDULED') ||
        this.state.statusActiveMassButton.includes('APPROVED_BY_MANAGER') ||
        this.state.statusActiveMassButton.includes('APPROVED') ||
        this.state.statusActiveMassButton.includes('REJECTED') ||
        this.state.statusActiveMassButton.includes('REJECTED_BY_USER')) &&
        !isConference(topicTypeId)) ||
        (isCorporateCourseWithoutApprove(topicTypeId) && this.state.statusActiveMassButton.includes('PLANNED')));

    const addToEventMassAction =
      topic.type &&
      this.isAdminPlanMan &&
      ((isAnyCourseOrConference(topic.type.id) &&
        (this.state.statusActiveMassButton.includes('APPROVED') ||
          this.state.statusActiveMassButton.includes('REJECTED_BY_USER'))) ||
        (isCorporateCourseWithoutApprove(topicTypeId) &&
          (this.state.statusActiveMassButton.includes('PLANNED') ||
            this.state.statusActiveMassButton.includes('REJECTED_BY_USER'))));

    const menuItems = [
      ...(reschMassAction
        ? [
            {
              key: '1',
              label: <a onClick={() => this.toggleModalCalendars(false)}>{t('reschedule')}</a>,
            },
          ]
        : []),
      ...(topic.type &&
      isAnyCourseOrConference(topic.type.id) &&
      (this.state.statusActiveMassButton.includes('WAITING') ||
        this.state.statusActiveMassButton.includes('REJECTED_BY_MANAGER') ||
        (this.isAdminPlanMan && this.state.statusActiveMassButton.includes('APPROVED_BY_MANAGER')) ||
        (this.isAdminPlanMan && this.state.statusActiveMassButton.includes('REJECTED')))
        ? [
            {
              key: '2',
              label: <a onClick={() => this.changeCoursesStatus('APPROVED')}>{t('approve')}</a>,
            },
          ]
        : []),
      ...(topic.type &&
      isAnyCourseOrConference(topic.type.id) &&
      (this.state.statusActiveMassButton.includes('WAITING') ||
        this.state.statusActiveMassButton.includes('APPROVED_BY_MANAGER') ||
        (this.isAdminPlanMan && this.state.statusActiveMassButton.includes('APPROVED')) ||
        (this.isAdminPlanMan && this.state.statusActiveMassButton.includes('REJECTED_BY_USER')))
        ? [
            {
              key: '3',
              label: <a onClick={() => this.changeCoursesStatus('REJECTED')}>{t('deny')}</a>,
            },
          ]
        : []),
      ...(addToEventMassAction
        ? [
            {
              key: '4',
              label: <a onClick={this.showDrawerUsers}>{t('addToEvent')}</a>,
            },
          ]
        : []),
      ...(topic.type &&
      isAnyCourseOrConference(topic.type.id) &&
      this.isAdminPlanMan &&
      this.state.statusActiveMassButton.includes('ON_REGISTRATION')
        ? [
            {
              key: '5',
              label: <a onClick={() => this.changeCoursesStatus('APPROVED_BY_USER')}>{t('confirmRegistration')}</a>,
            },
          ]
        : []),
      ...(topic.type &&
      isAnyCourseOrConference(topic.type.id) &&
      this.isAdminPlanMan &&
      this.state.statusActiveMassButton.includes('EXPIRED')
        ? [
            {
              key: '6',
              label: <a onClick={this.confirmRejectUser}>{t('didntGo')}</a>,
            },
          ]
        : []),
      ...(topic.type && this.isAdminPlanMan
        ? [
            {
              key: '7',
              label: <span>{t('delete')}</span>,
              onClick: this.confirmDeleteCourse,
            },
          ]
        : []),
      ...(!!this.state.selectRequest.find(item => item?.info?.nameAdmin === this.changeComplitedAdminStatus)
        ? [
            {
              key: '8',
              label: <span>{t('compliteStudy')}</span>,
              onClick: this.changeToComplited,
            },
          ]
        : []),
    ];

    const massMenuItems = [
      {
        key: '1',
        label: <span>{t('reschedule')}</span>,
        onClick: () => this.toggleModalCalendars(false),
      },
      {
        key: '2',
        label: <span>{t('delete')}</span>,
        onClick: this.confirmDeleteCourse,
      },
      {
        key: '3',
        label: (
          <Popconfirm
            className={css.Popconfirm}
            title={t('reminderTitle')}
            onConfirm={() => {
              if (this.state.topicId) {
                this.props.putNotificationReminder(
                  this.state.topicId,
                  () => {
                    notification.success({
                      message: t('reminderSuccessfully'),
                      duration: 1.5,
                      placement: 'bottomRight',
                    });
                  },
                  () => {
                    notification.error({
                      message: t('reminderError'),
                      duration: 1.5,
                      placement: 'bottomRight',
                    });
                  }
                );
              }
            }}
            okText={t('reminderSend')}
            cancelText={t('reminderCancel')}
          >
            {t('sendToNotification')}
          </Popconfirm>
        ),
      },
      ...(!this.isNoCourse && !isCorporateCourseWithoutApprove(topicTypeId)
        ? [
            {
              key: 'APPROVED',
              label: <span onClick={() => this.changeAllStatus('APPROVED')}>{t('approve')}</span>,
            },
            {
              key: 'REJECTED',
              label: <span onClick={() => this.changeAllStatus('REJECTED')}>{t('deny')}</span>,
            },
          ]
        : []),
      ...(!this.isNoCourse && this.isAdmin
        ? [
            {
              key: 'addToEvent',
              label: <span onClick={this.showDrawerUsers}>{t('addToEvent')}</span>,
            },
            {
              key: 'APPROVED_BY_USER',
              label: <span onClick={() => this.changeAllStatus('APPROVED_BY_USER')}>{t('confirmRegistration')}</span>,
            },
          ]
        : []),
      {
        key: '4',
        label: <span>{t('compliteStudy')}</span>,
        onClick: this.changeToComplitedMass,
      },
    ];

    const massDropdownItems = this.state.applyAll ? massMenuItems : menuItems;

    const handleAddressBookSubmit = () => this.setState({ isAddressBookVisible: false });

    // Вывод списка адресов и пользователей
    const addressBookList = () => {
      if (this.state.qiwiLibrary) {
        return (
          <div className={css['DetailsModal-card-item']}>
            <div className={css['DetailsModal-label']}>{t('booksLocation')}</div>
            <div>
              <Button type={'primary'} onClick={() => this.setState({ isAddressBookVisible: true })}>
                {t('showAddresses')}
              </Button>
            </div>
          </div>
        );
      }
    };

    const checkTopicTypeId = () => {
      if (isEvent(topic?.type?.id) || isConfOrAllCourse(topic?.type?.id)) {
        return true;
      }
      return false;
    };

    const addressList = (
      <div className={css.AddressBookList}>
        <List
          bordered={false}
          itemLayout='horizontal'
          dataSource={this.props.booksInUse}
          size='small'
          split={true}
          renderItem={item => (
            <List.Item id={'abli' + item.id} className={css.AddressBookListItem}>
              <List.Item.Meta title={item.address} />
              <div style={{ fontFamily: 'Helvetica, Arial, Verdana, Tahoma, sans-serif, Montserrat500, sans-serif' }}>
                {item.userName}
              </div>
            </List.Item>
          )}
        />
      </div>
    );

    const material = (
      <>
        <AddressBook
          textWindowTitle={t('booksInUse')}
          textSubmitButton='OK'
          open={this.state.isAddressBookVisible}
          onButtonSubmit={handleAddressBookSubmit}
          onButtonClose={handleAddressBookSubmit}
          topicId={Number(this.props?.params.id)}
          data={this.props.booksInUse}
        />
        <TopicContent
          t={t}
          imgState={this.state.imageSrc}
          countView={this.state.countView}
          isAdminPlanMan={this.isAdminPlanMan}
          showUsersModal={this.showUsersModal}
          isAdmin={this.isAdmin}
          editable={editable}
          changeScope={this.changeScope}
          showModalSettingsVisible={this.showModalSettingsVisible}
          showITODDialog={this.showITODDialog}
          showTopicCreatingTemplateDialog={this.showTopicCreatingTemplateDialog}
          changeIndependent={this.changeIndependent}
          addressBookList={addressBookList}
          checkBoxValue={this.state.dependent}
        />
        {this.props?.topic?.type?.withEvents && withEvents && (
          <EventsActions
            t={t}
            showEventCreateDialog={this.showEventCreateDialog}
            withEvents={withEvents}
            eventBtn={topic.status === t('deactivated')}
            showDrawerUsers={this.showDrawerUsers}
            showWaitingList={() => this.setState({ waitingListModal: true })}
            countWaitingUsers={this.props?.countWaitingUsers}
          />
        )}
        <WaitingListModal
          open={this.state.waitingListModal}
          onCancel={() => this.setState({ waitingListModal: false })}
          topicId={this.props?.topic?.id}
        />
        <DrawerUsers
          t={t}
          onClose={this.hideDrawerUsers}
          open={this.state.drawerUsers}
          withEvents={withEvents}
          addUserEventHandler={this.addUserEventHandler}
          eventName={eventName}
          eventList={this.props.eventList}
          showUsers={this.state.showUsers}
          showSendMail={this.showSendMail}
          showEventDetails={this.showEventDetails}
          deleteEventHandler={this.deleteEventHandler}
          showEventInfoHandler={this.showEventInfoHandler}
          editEventHandler={this.editEventHandler}
          getEventParticipants={this.getEventParticipants}
          onlyEvent={onlyEvent}
          getFile={this.props.getFile}
          coursesPageNumber={coursesPageNumber}
          coursesFilter={this.props.coursesFilter}
          isLoading={this.props.isLoading}
          isEnded={this.state.isEnded}
          filterYear={this.state.filterYear}
          courseRequestFinish={this.props.courseRequestFinish}
          deleteUserEventHandler={this.deleteUserEventHandler}
          getEventTopic={this.props.getEventTopic}
          updateCoursesRequest={this.props.updateCoursesRequest}
          getCoursesRequestTopic={this.props.getCoursesRequests}
          showEventDetail={this.state.showEventDetail}
          currentUser={currentUser}
          valueCheckBox={this.state.isEnded === false}
          changeActiveCheckbox={e => this.handleChangeActiveCheckbox(e)}
          isConfOrAllCourse={isConfOrAllCourse(topic?.type?.id)}
        />

        <AllStatistics
          key='AllStatistics--material'
          isStatisticsLoading={this.props.allMaterialStatisticsLoading}
          isStatisticsError={this.props.allStatisticsError}
          data={this.props.allMaterialStatistics}
          isMaterial={true}
          showProgress
          changeDeletedUsers={this.changeDeletedUsers}
        />

        <Filter
          t={t}
          handleScopeChange={this.handleScopeChange}
          cleanFilter={this.cleanFilter}
          yearState={this.state.year}
          clearOneFilter={this.clearOneFilter}
          valueMonths={this.state.month}
          valueFIO={topic?.type?.approved && topic?.type?.withEvents ? this.state.userId : this.state.fullName}
          funcFilterManagers={this.funcFilterManagers}
          domainCompany={this.props.domainCompany}
          valueFunctions={this.state.function}
          valueDepartments={this.state.department}
          valueStatus={this.state.status}
          optionsStatus={this.state.statusOptions}
          isExpiredDate={this.state.isExpiredDate}
          inspectionMode={this.state.inspectionMode}
          inspectionStatus={this.state.inspectionStatus}
          changeInspectionMode={value => this.setState({ inspectionMode: value })}
          withEvents={topic?.type?.approved && topic?.type?.withEvents}
          changeExpiredDate={this.handleChangeExpiredDate}
          valueManagerId={this.state.managerId}
          submitFilters={this.handleSubmitFilters}
          dateFilter={{
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          }}
        />

        <Row justify='space-between' align='middle'>
          <SortTable
            t={t}
            activeSort={this.props.coursesFilter?.sort}
            handleChangeSort={this.handleChangeSort}
            order={this.state.sortOrder}
            isCourse={!this.isNoCourse}
          />

          <Button
            onClick={this.exportTopic}
            loading={this.props.isExportMaterial}
            disabled={this.props.isExportMaterial}
            type='primary'
            size='large'
            iconPosition='start'
            icon={<DownloadOutlined />}
          >
            {t('exportExecutiveTask')}
          </Button>
        </Row>

        <Row gutter={1} className={css.DetailsModal}>
          {this.isAdminPlanMan && (
            <Col span={24} className={css['DetailsModal-col']}>
              <div className={css['DetailsModal-col-tab']}>
                <div className={css['transfer-group-box']}>
                  <Flex gap={24} justify='center' align='center'>
                    <div className={css['DetailsModalPay-col-payCourse-filter-selected']}>
                      {t('employeesSelected')}{' '}
                      <span>
                        {this.state.applyAll
                          ? this.state.isOneFilter
                            ? this.props.coursesTotal
                            : this.props.topic.planStatus && this.props.topic.planStatus.count
                          : this.state.selectRequest.length}
                      </span>
                      /{this.props.coursesTotal || 0}
                    </div>
                    <div className={css['transfer-group-box--left-massAction']}>
                      <span>
                        <Checkbox
                          name='allUsers'
                          checked={this.state.applyAll}
                          onChange={e => this.applyAll({ value: e.target.checked })}
                        />
                      </span>
                      <div className={css['DetailsModalPay-col-payCourse-filter-applyAll']}>{t('applyAll')}</div>
                    </div>
                    {this.isAdminPlanMan && (
                      <Dropdown
                        menu={{ items: massDropdownItems }}
                        placement='bottomLeft'
                        trigger={['click']}
                        disabled={!(this.state.applyAll || this.state.selectRequest.length !== 0)}
                      >
                        <Button type='default' onClick={this.massSelected} icon={<DownOutlined />} iconPosition='end'>
                          {t('respondToSelected')}
                        </Button>
                      </Dropdown>
                    )}
                  </Flex>
                </div>
                <CollectionTopicsProvider propsValues={propsForActionsButtonMenuContext}>
                  <CoursesTopicMemberstable
                    courses={this.props.courses}
                    isLoading={this.props.coursesIsLoading}
                    topicId={this.state.topicId}
                    subordinates={this.props.subordinates}
                    withEvents={withEvents}
                    changeSelect={this.changeSelect}
                    changeSelectAll={this.changeSelectAll}
                    currentStatus={this.state.status}
                    changeCoursesFilter={this.props.changeCoursesFilter}
                    changePage={currentPage => this.setState({ page: currentPage })}
                    checkTopicTypeId={checkTopicTypeId()}
                  />
                </CollectionTopicsProvider>
              </div>
            </Col>
          )}
        </Row>
        {this.state.showModalModerateTopic && (
          <ModerateTopicModal
            open
            onClose={this.hideModalModerateTopic}
            topicId={this.props.params.id}
            topicIncome={this.state.topicIncome}
            onOk={this.onChangeScope}
            coinsIcon={this.props.domainCompany.coinIcon}
          />
        )}
        {this.state.usersModalVisible && (
          <Assignment
            open={this.state.usersModalVisible}
            onCancel={this.hideUsersModal}
            onOk={this.handleRecommendPost}
            textBlock={[t('materialToPlan'), t('addRepeat')]}
            title={t('addMaterial')}
            topicId={+this.props.params.id}
            mass
            getSavedFilters={this.getSavedFilters}
            getCountUsers={this.getCountUsers}
            onMassOk={this.handleMassFilter}
            exportSelectedUsers={this.exportSelectedUsers}
            isLoadingCountSelectedUsers={this.props.isLoadingCountUsersMassTopic}
            isLoadingMass={this.props.isLoadingMassTopic}
            titleModalMass={t('titleModalMassMaterials')}
            countSelectedUsers={this.props.countUsersMassTopic}
            titleSelectedUser={t('titleSelectedUserTopic')}
          />
        )}
        {
          // TODO: Вывод календарного окна
        }

        {this.state.visibleDateModal && (
          <CalendarModal
            hasEvents={topic.futureEvents}
            open={this.state.visibleDateModal}
            comment={topic.comment}
            dateEvent={topic.startDate}
            onOk={this.toggleModalReasonTransfer}
            onCancel={this.onCloseCalendarModal}
            noComment={!(!this.state.isUpdate && !this.state.currentPlan)}
            isAddToPlan={!this.state.isUpdate && !this.state.currentPlan}
            okText={!this.state.isUpdate && !this.state.currentPlan ? t('addToPlan') : t('reschedule')}
            planningTopicId={this.props.currentTopicId}
            topicInfo={topic}
            eventTypeId={topic.type && topic.type.id}
            selectMonthEvents
          />
        )}
        {this.state.createEventModal && (
          <CreateEventModal
            open
            editable={this.state.editable}
            createEvent={this.handleCreateEvent}
            failEventValidation={this.props.failEventValidation}
            topic={topic}
            event={this.state.editEvent}
            updateEvent={this.props.updateEvent}
            year={(this.props.coursesFilter && this.props.coursesFilter.year) || this.state.filterYear}
            getEventTopic={this.props.getEventTopic}
            getYear={this.props.getYear}
            isEnded={this.state.isEnded}
            coursesFilter={this.props.coursesFilter}
            getCoursesRequests={this.props.getCoursesRequests}
            onEventCreateSuccess={this.handleEventCreateSuccess}
            onCancel={this.hideEventCreateDialog}
          />
        )}
        {this.state.showModalRejected && (
          <SendCommentModal
            rejected
            open
            submitDeleteComment={this.commentSubmitRejectedCourseRequest}
            cancelDeleteComment={this.hideRejectedCourseRequest}
          />
        )}

        {this.state.showModalReasonTransfer && (
          <SendCommentModal
            rejected
            open
            isTransfer={!(!this.state.isUpdate && !this.state.currentPlan)}
            submitDeleteComment={this.scheduleStudyPlanClick}
            cancelDeleteComment={this.onCloseSendCommentModal}
          />
        )}
        {this.state.showEventInfoModal && (
          <EventInfoModal
            open
            event={this.state.showEventInfoModal}
            onCancel={this.hideEventInfoHandler}
            onEdit={this.editEventHandler}
            sendMail={this.props.sendMail}
          />
        )}
        {this.state.topicEditingVisible && (
          <TopicCreationDialog
            open
            editable
            topic={topic}
            imageSrc={this.state.imageSrc}
            onTopicCreationSuccess={this.handleTopicEditingSuccess}
            onCancel={this.hideTopicEditingDialog}
            isSkills={isSkills(this.props.topic?.type?.id)}
          />
        )}
        {this.state.topicCreatingTemplateVisible && (
          <TopicCreationDialog
            open
            template
            topic={topic}
            imageSrc={this.state.imageSrc}
            onTopicCreationSuccess={this.hideTopicCreatingTemplateDialog}
            onCancel={this.hideTopicCreatingTemplateDialog}
          />
        )}
        {this.state.selectEventModal && (
          <EventSelectModal
            events={this.props.eventList}
            onCancel={this.hideEventCreateDialog}
            onCreateNew={this.showEventCreateDialog}
            onSelect={this.handleSelectEvent}
          />
        )}
        {this.state.iTODVisible && (
          <ITODDialog
            open
            editable
            topic={topic}
            onCancel={this.hideITODDialog}
            onTopicCreationSuccess={this.handleTopicEditingSuccess}
          />
        )}
        {this.state.showModalDeleteEventComment && (
          <SendCommentModal
            open
            submitDeleteComment={this.hideDeleteEventCommentSuccess}
            cancelDeleteComment={this.resetDel}
          />
        )}
        {this.state.showModalDeleteComment && (
          <SendCommentModal
            open
            submitDeleteComment={this.hideDeleteUserEventSuccess}
            cancelDeleteComment={this.resetDel}
          />
        )}

        {this.state.showModalAddUser &&
          (this.state.addUserNotValidate ? (
            <ConfirmationModal
              open
              onOk={this.hideConfirmDialog}
              onCancel={this.hideConfirmDialog}
              okText={t('close')}
              confirmationText={this.state.user ? t('pleaseSelectEvent') : t('pleaseSelectEventEmployee')}
            />
          ) : (
            <ConfirmationModal
              open
              onOk={() => this.hideConfirmDialogSuccess()}
              onCancel={this.hideConfirmDialog}
              okText={t('add')}
              confirmationText={t('description1')}
            />
          ))}
        {this.state.showModalDeleteUserEvent && (
          <ConfirmationModal
            open
            disabled={this.props.isLoading}
            onOk={this.hideDeleteUserEvent}
            onCancel={this.hideDeleteUserEventFail}
            okText={t('delete')}
            confirmationText={t('description2')}
            deleteBtn
          />
        )}
        {this.state.showEventNoPlaceModal && (
          <ConfirmationModal
            open
            onOk={this.hideEventNoPlaceModal}
            onCancel={this.hideEventNoPlaceModal}
            okText={t('Ok')}
            confirmationText={t('description3')}
          />
        )}
        {this.state.showModalDeleteEvent && (
          <ConfirmationModal
            open
            onOk={this.hideDeleteEvent}
            onCancel={this.hideDeleteEventFail}
            okText={t('delete')}
            confirmationText={t('description4')}
            deleteBtn
          />
        )}
        {this.state.showModalSendMail && (
          <ConfirmationModal
            open
            onOk={this.sendMail}
            onCancel={this.hideSendMail}
            okText={t('send')}
            confirmationText={t('description5')}
          />
        )}
        {this.state.showModalConfirmDeleteEvent && (
          <ConfirmationModal
            open
            onOk={this.hideHandleDeleteEventComment}
            onCancel={() => this.setState({ showModalConfirmDeleteEvent: false })}
            okText={t('close')}
            zIndex={1006}
            confirmationText={t('closeSaving')}
          />
        )}
        {this.state.confirmRejectUsersModal && (
          <ConfirmationModal
            open
            onOk={this.coursesRequestFinish}
            onCancel={this.hideConfirmRejectUser}
            onCancelButton={this.hideConfirmRejectUser}
            okText={t('yes')}
            cancelText={t('no')}
            confirmationText={t('description6')}
          />
        )}
        {/* удаление из учебного плана модалка */}
        {this.state.confirmDeleteCourseModal && (
          <ConfirmationModal
            open
            onOk={this.confirmDeleteMass}
            onCancel={this.hideConfirmDeleteCourse}
            onCancelButton={this.hideConfirmDeleteCourse}
            okText={t('yes')}
            cancelText={t('no')}
            confirmationText={t('description7')}
            isLoadingDeleteMass={this.props.isLoadingDeleteMass}
            applyAll={this.state.applyAll}
            deleteBtn
          />
        )}
        {this.state.showBookFreeModal && (
          <CustomModal
            cancelBtn
            cancelBtnText={t('returnBookNo')}
            title={t('booksInUseTitle')}
            editable={false}
            headerFixToModal={false}
            modalCategory={true}
            okText={t('returnBookYes')}
            hideFooter={false}
            onOk={this.coursesDeleteMass}
            onCancel={() => this.setState({ showBookFreeModal: false })}
            style={{ padding: '15px', backgroundColor: '#fff', width: '700px !important' }}
            open={this.state.showBookFreeModal}
            children={addressList}
            width={650}
          />
        )}

        {this.state.showModalConfirmDeleteUser && (
          <ConfirmationModal
            open
            onOk={this.hideHandleDeleteComment}
            onCancel={() => this.setState({ showModalConfirmDeleteUser: false })}
            okText={t('close')}
            zIndex={1006}
            confirmationText={t('closeSaving')}
          />
        )}
        {this.state.visibleTestModal && (
          <TestsInfoModal
            onCancel={this.toggleModalTestResults}
            user={this.state.userData}
            topicId={topic.id}
            getBlockStatistic={this.props.getBlockStatistic}
            topicTypeId={this.props.topic.type.id}
            companyId={this.props.domainCompany.id}
            planId={this.state.currentPlan?.id}
          />
        )}
        {this.state.modalSettingsVisible && (
          <Modal
            title={titleModal}
            open={this.state.modalSettingsVisible}
            width={1000}
            footer={false}
            onCancel={this.hideModalSettingsVisible}
            className={css['ModalSettingsVisibility']}
          >
            <TemplateBlockFilters
              isSettingsVisibility
              isAdmin={this.isAdmin}
              canEdit={canEdit}
              exportUsersVisibility
              // exportUsersVisibility={this.exportUsersSettingsVisibility}
              // successExportUsers={this.state.successExportUsers}
              funcCallbackExport={this.funcCallbackExport}
              getSettings={this.props.getSettingsVisibility}
              elemId={+this.state.topicId}
              getCountSettings={this.props.getCountSettingsVisibility}
              storeData={this.props.settingsVisibility}
              count={this.props.countSettingsVisibility}
              loading={this.props.loadingSettingsVisibility}
              showUsersMaterial
              showCustomFields
              showSwitchVisible
              onSubmitData={this.onSubmitVisibility}
              onClose={this.hideModalSettingsVisible}
              onResetSettings={this.resetSettings}
              loaderUpdate={this.props.loaderUpdate}
              visibleElement={!this.props.topic.visible}
              titleUsersMaterial={t('usersMaterial')}
              descriptionVisible={t('descriptionTopicVisible')}
            />
          </Modal>
        )}
      </>
    );

    const webinarVisability =
      topic.type &&
      Utils.canUseWebinar(topic.type.id) &&
      Array.isArray(topic.webinarCreateEventResponse) &&
      topic.webinarCreateEventResponse.length;

    const tabsItems = [
      {
        key: TAB_POSITION.TOPIC,
        label: t('material'),
        children: material,
      },
      {
        key: TAB_POSITION.LMS,
        label: t('learning'),
        children: (
          <LMS
            getFile={this.props.getFile}
            changeTestStatus={this.setTestReview}
            canEdit={canEdit}
            subCompanies={this.state.subCompanies}
            topicTypeId={this.props.topic?.type?.id}
          />
        ),
        disabled: this.state.isLoading,
      },
      ...(webinarVisability
        ? [
            {
              key: TAB_POSITION.WEBINAR,
              label: t('webinarStatistic'),
              children: <WebinarStatistic topic={topic} />,
              disabled: this.state.isLoading || !_.some(topic.webinarCreateEventResponse, { access: 4 }),
            },
          ]
        : []),
    ];

    return (
      <Content className={css['App']}>
        <div className={css['DetailsModal-name']}>
          <h1 data-qa='topicTitle'>
            {topic.status === t('deactivated') && (
              <Tooltip title={t('materialDeactivated')}>
                <img key='deactive' src={lock} alt='' />
              </Tooltip>
            )}
            {topic.name}
          </h1>
          {isSkills(topic?.type?.id) && (
            <div className={css['DetailsModal-poka-skills']}>
              <div className={css['DetailsModal-poka-review']}>{t('pokaReview')} 01.01.2025</div>
              <div className={css['DetailsModal-poka-status']}>
                <CheckCircleOutlined className={css['DetailsModal-poka-icon']} />
                {/* Временный перевод по задаче https://kampus.atlassian.net/browse/KAM-7859 */}
                {t('approved')}
              </div>
            </div>
          )}
        </div>
        <div className={css['DetailsModal-btns']}>
          <div className={css['DetailsModal-btn-block']}>
            {this.props.currentUser?.authorities?.includes(TOPIC_PIN_INTERACT) &&
              this.props.domainCompany?.hasPinned && (
                <Tooltip title={t('pinMaterial')}>
                  <Button className={css['DetailsModal-btn-edit']}>
                    <FixMaterial
                      topic={topic}
                      changePositionTopic={this.props.changePositionTopic}
                      updateTopic={this.updateTopic}
                      detailsTopic
                      data-qa='pinMaterialBtn'
                    />
                  </Button>
                </Tooltip>
              )}
            {(this.props.currentUser.authorities.includes(TOPIC_UPDATE) ||
              (this.props.topic?.type?.id === 25 &&
                this.props.currentUser.authorities.includes(TOPIC_EXECUTIVE_TASKS_UPDATE))) &&
              editable && (
                <Tooltip title={t('editMaterial')}>
                  <Button
                    className={css['DetailsModal-btn-edit']}
                    onClick={this.showTopicEditingDialog}
                    data-qa='editMaterialBtn'
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
              )}
            <Tooltip title={t('closeMaterial')}>
              <Button className={css['DetailsModal-btn-close']} onClick={this.closePage}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
        {this.isAdminPlanMan ? (
          <Tabs
            onChange={this.changeTabs}
            className={css['DetailsModal-tabs']}
            activeKey={this.state.tabPosition}
            type='card'
            items={tabsItems}
          />
        ) : (
          material
        )}
      </Content>
    );
  }
}

TopicDetailsDialog.propTypes = {
  addToPlanMultiple: PropTypes.object,
  addUserEvent: PropTypes.func,
  changeCoursesFilter: PropTypes.func,
  changeIndependentStatus: PropTypes.func,
  changePositionTopic: PropTypes.func,
  changeScope: PropTypes.func,
  checkDeleteEvent: PropTypes.func,
  checkDeleteUserEvent: PropTypes.func,
  clearCoursesFilter: PropTypes.func,
  courseRequestFinish: PropTypes.func,
  courses: PropTypes.array,
  coursesFilter: PropTypes.object,
  coursesPageNumber: PropTypes.number,
  coursesPageSize: PropTypes.number,
  coursesRequestComment: PropTypes.array,
  coursesRequestCommentNumber: PropTypes.number,
  coursesRequestCommentPageSize: PropTypes.number,
  coursesRequestCommentTotal: PropTypes.number,
  coursesTotal: PropTypes.number,
  createEvent: PropTypes.func,
  createStudyPlan: PropTypes.func,
  currentTopicId: PropTypes.number,
  delete: PropTypes.number,
  deleteEvent: PropTypes.func,
  deleteRequestCourses: PropTypes.func,
  deleteUserEvent: PropTypes.func,
  disabledSelect: PropTypes.bool,
  domainCompany: PropTypes.object,
  eventList: PropTypes.array,
  failEventValidation: PropTypes.func,
  fullname: PropTypes.array,
  futureEvents: PropTypes.array,
  getCommentRejected: PropTypes.func,
  getCoursesRequest: PropTypes.func,
  getCoursesRequests: PropTypes.func,
  getEventParticipants: PropTypes.func,
  rejectBlockByAdmin: PropTypes.func,
  getEventParty: PropTypes.func,
  getEventTopic: PropTypes.func,
  getFile: PropTypes.func,
  getFullName: PropTypes.func,
  getStudents: PropTypes.func,
  getTopicById: PropTypes.func,
  getTopics: PropTypes.func,
  getYear: PropTypes.func,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  onCancel: PropTypes.func,
  onTopicEditingSuccess: PropTypes.func,
  postCommentRejected: PropTypes.func,
  scopeButton: PropTypes.string,
  sendMail: PropTypes.func,
  startDate: PropTypes.string,
  topic: PropTypes.object,
  topicFilter: PropTypes.object,
  topicId: PropTypes.number,
  updateCoursesRequest: PropTypes.func,
  updateEvent: PropTypes.func,
  updateReqUser: PropTypes.bool,
  updateStudyPlanDate: PropTypes.func,
  visible: PropTypes.bool,
  roles: PropTypes.array,
  getUserIncome: PropTypes.func,
  isGlobal: PropTypes.bool,
  showDrawerUsers: PropTypes.func,
  getInfoCompany: PropTypes.func,
  getMassSelectionCount: PropTypes.func,
  getStudyPlanStatus: PropTypes.func,
  massFilterAdd: PropTypes.func,
  getCountSelectedUsers: PropTypes.func,
  exportSelectedUserInMass: PropTypes.func,
  getMassFilters: PropTypes.func,
  clearStudents: PropTypes.func,
  isLoadingMassTopic: PropTypes.bool,
  isLoadingDeleteMass: PropTypes.bool,
  getBlocksTypeTopic: PropTypes.func,
  putNotificationReminder: PropTypes.func,
  allMaterialStatistics: PropTypes.object,
  allMaterialStatisticsLoading: PropTypes.bool,
  allStatisticsError: PropTypes.bool,
  isExportMaterial: PropTypes.bool,
  exportUsersModalVisibility: PropTypes.func,
  getSettingsVisibility: PropTypes.func,
  getCountSettingsVisibility: PropTypes.func,
  deleteSettingsVisibility: PropTypes.func,
  getCountTopicSettingsVisibility: PropTypes.func,
  updateSettingsVisibility: PropTypes.func,
  editTopicVisibility: PropTypes.func,
  settingsVisibility: PropTypes.object,
  countSettingsVisibility: PropTypes.number,
  loadingSettingsVisibility: PropTypes.bool,
  loaderUpdate: PropTypes.bool,
  clearSettingsVisibility: PropTypes.func,
  booksInUse: PropTypes.array,
  freeBook: PropTypes.func,
  getBookInUse: PropTypes.func,
  clearBooksInUse: PropTypes.func,
  getFunction: PropTypes.func,
  getPosition: PropTypes.func,
  getDepartment: PropTypes.func,
  getMaterialStatistics: PropTypes.func,
  changeToComplited: PropTypes.func,
  badgeBindTopic: PropTypes.func,
  getCountWaitingUsers: PropTypes.func,
  countWaitingUsers: PropTypes.object,
};

const mapStateToProps = createSelector(
  [
    topicsSelect,
    coursesSelect,
    studentSelect,
    usersSelect,
    userSubordinateEmployees,
    selectAllStatistics,
    selectSettingsVisibility,
    selectBooks,
    selectWaitingList,
  ],

  (
    topics,
    course,
    student,
    users,
    subordinateEmployees,
    stateAllStatistics,
    settingsVisibility,
    books,
    waitingList
  ) => ({
    topicSection: topics.topicSection,
    roles: users.currentUser && users.currentUser.authorities,
    topic: topics.topic || {},
    topicFilter: topics.topicFilter,
    coursesFilter: course.coursesFilter,
    courses: course.coursesRequest,
    coursesIsLoading: course.coursesIsLoading,
    coursesTotal: course.coursesTopicTotal,
    coursesPageSize: course.coursesTopicPageSize,
    coursesRequestComment: course.coursesRequestComment,
    coursesRequestCommentTotal: course.coursesRequestCommentTotal,
    coursesRequestCommentPageSize: course.coursesRequestCommentPageSize,
    coursesRequestCommentNumber: course.coursesRequestCommentNumber,
    addToPlanMultiple: student.addToPlanMultiple,
    fullname: student.fullname,
    coursesPageNumber: course.coursesTopicPageNumber,
    eventList: course.eventList,
    updateReqUser: course.updateReqUser,
    isLoading: course.isLoading || course.isLoadingUsers,
    delete: course.delete,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    isGlobal: users && users.currentUser.domainCompany.global,
    currentUser: users.currentUser,
    subordinates: subordinateEmployees,
    countUsersMassTopic: student.countSelectedStudents,
    isLoadingCountUsersMassTopic: student.isLoadingStudents,
    isLoadingMassTopic: student.isLoadingMassTopic,
    isLoadingDeleteMass: course.isLoadingDeleteMass,
    allMaterialStatistics: stateAllStatistics.materialStatistics,
    allMaterialStatisticsLoading: stateAllStatistics.isMaterialLoading,
    allStatisticsError: stateAllStatistics.isMaterialError,
    isExportMaterial: topics.isExportMaterial,
    settingsVisibility: settingsVisibility.settingsVisibility,
    countSettingsVisibility: settingsVisibility.count,
    loadingSettingsVisibility: settingsVisibility.loading,
    loaderUpdate: settingsVisibility.loaderUpdate,
    booksInUse: books.addressesInUse,
    countWaitingUsers: waitingList.countWaitingUsers,
  })
);

const mapActionsToProps = {
  deleteEvent,
  getUserIncome: getUserIncome2,
  getTopicIncome,
  getFile,
  updateEvent,
  sendMail,
  deleteUserEvent,
  failEventValidation,
  addUserEvent,
  getEventTopic,
  updateStudyPlanDate,
  updateCoursesRequest,
  createEvent,
  getCoursesRequests,
  getFullName,
  getTopicById,
  getYear,
  clearCoursesFilter,
  getCoursesRequest,
  createStudyPlan,
  changeCoursesFilter,
  getEventParty,
  getStudents,
  getTopics,
  changeScope,
  getCommentRejected,
  postCommentRejected,
  changePositionTopic,
  getEventParticipants,
  checkDeleteEvent,
  checkDeleteUserEvent,
  courseRequestFinish,
  changeIndependentStatus,
  deleteRequestCourses,
  getTopicSection,
  getBlockStatistic,
  getResultStatistic,
  getInfoCompany,
  newMaterialExport,
  putRescheduleAll,
  updateStatusAll,
  addMassEvent,
  deleteMass,
  getMassSelectionCount,
  rejectBlockByAdmin,
  finishBlockByAdmin,
  getStudyPlanStatus,
  massFilterAdd,
  getCountSelectedUsers,
  exportSelectedUserInMass,
  getMassFilters,
  clearStudents,
  getBlocksTypeTopic,
  putNotificationReminder,
  exportUsersModalVisibility,
  getSettingsVisibility,
  getCountSettingsVisibility,
  deleteSettingsVisibility,
  getCountTopicSettingsVisibility,
  updateSettingsVisibility,
  editTopicVisibility,
  clearSettingsVisibility,
  getBookInUse,
  clearBooksInUse,
  freeBook,
  getFunction,
  getPosition,
  getDepartment,
  getMaterialStatistics,
  changeToComplited,
  badgeBindTopic,
  getCountWaitingUsers,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['topicDetailsDialog', 'constants'])(TopicDetailsDialog));
