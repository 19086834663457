import { memo, useState } from 'react';

import { Select, Tooltip } from 'antd';

import PropTypes from 'prop-types';

const { Option } = Select;

// Кастомный селект в таблице для выбора роли пользователя
const RoleSelect = memo(({ roleId, disabled, userId, roles, changeRole }) => {
  const [value, setValue] = useState(roleId ? roleId : null);

  const onChangeValue = id => {
    changeRole(userId ? [userId] : [], id);
    setValue(id);
  };

  const render = () => {
    return (
      <Select style={{ width: 200 }} onChange={onChangeValue} value={value} disabled={disabled}>
        {roles?.map(role => (
          <Option value={role.id} key={role.id}>
            <Tooltip title={role.name}>{role.name}</Tooltip>
          </Option>
        ))}
      </Select>
    );
  };

  return render();
});

RoleSelect.propTypes = {
  disabled: PropTypes.bool,
  userId: PropTypes.number,
  roleId: PropTypes.number,
  roles: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array]),
  changeRole: PropTypes.func.isRequired,
};

export default RoleSelect;
