export const filterTypes = { department: 'department', function: 'function' };

const getFilterByDepartment = courses => {
  return (
    courses?.department &&
    courses.department.map(item => ({
      text: item,
      value: item,
    }))
  );
};

const getFilterByFunction = courses => {
  return (
    courses?.functionStudent &&
    courses.functionStudent.map(item => ({
      text: item,
      value: item,
    }))
  );
};

export const filtersCoursesList = courses => {
  const filterByDepartment = getFilterByDepartment(courses);
  const filterByFunction = getFilterByFunction(courses);
  return {
    filterByDepartment,
    filterByFunction,
  };
};
