import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getAllRoles, getGroupPermissionAll, getRoleById } from '@/store/access/actions';

import { selectAccess } from '@/store/access/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Spin, Tabs, Typography } from 'antd';
import { Content } from '@/components/Layout';

import AddUser from './parts/AddUser/AddUser';
import EditRole from './parts/EditRole/EditRole';
import RoleSettings from './parts/RoleSettings/RoleSettings';
import UserSettings from './parts/UserSettings/UserSettings';

import Utils from '@/Utils';

import { ROLE_READ, SETTINGS_ROLE_READ } from '@/constants/permissions';

const { Title } = Typography;

const AccessSettings = () => {
  const [visibleTabPane, setVisibleTabPane] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [selectedRole, setSelectedRole] = useState(null);
  const [isCopy, setIsCopy] = useState(false);

  const DEFAULT_ROLE = 4;

  const dispatch = useDispatch();

  const { allRoles, groupAll, isLoading } = useSelector(selectAccess);

  const { currentUser } = useSelector(selectUsers);

  const isDefAdmin = currentUser && Utils.isDefAdmin(currentUser);

  const authorities = currentUser?.authorities;

  const { t } = useTranslation('RolesSettings');

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getGroupPermissionAll());
    authorities.includes(SETTINGS_ROLE_READ) || isDefAdmin
      ? setActiveKey('1')
      : authorities.includes(ROLE_READ)
        ? setActiveKey('2')
        : setActiveKey('1');

    // Получение роли 'Студент' для получение дефолтный пермишинов
    dispatch(getRoleById(DEFAULT_ROLE));
  }, []);

  useEffect(() => {
    visibleTabPane && setActiveKey('3');
  }, [visibleTabPane]);

  useEffect(() => {
    if (visibleTabPane && activeKey !== '3') {
      setVisibleTabPane(false);
      setSelectedRole(null);
      setIsCopy(false);
    }
  }, [activeKey]);

  const closeCreateOrUpdate = () => {
    setVisibleTabPane(false);
    setActiveKey('2');
    setSelectedRole(null);
  };

  const tabItems = [
    {
      label: t('tabPane.0'),
      key: '1',
      children: <UserSettings roles={allRoles} />,
    },
    authorities.includes(ROLE_READ) &&
      !isDefAdmin && {
        label: t('tabPane.1'),
        key: '2',
        children: (
          <Spin spinning={isLoading}>
            <RoleSettings
              setVisibleTabPane={() => setVisibleTabPane(true)}
              setSelectedRole={value => setSelectedRole(value)}
              setIsCopy={() => setIsCopy(true)}
            />
          </Spin>
        ),
      },
    visibleTabPane && {
      label: t('tabPane.2'),
      key: '3',
      children: (
        <Spin spinning={isLoading}>
          <EditRole changeVisible={closeCreateOrUpdate} selectedRole={selectedRole} groups={groupAll} isCopy={isCopy} />
        </Spin>
      ),
    },
    isDefAdmin && {
      label: t('tabPane.3'),
      key: '4',
      children: (
        <Spin spinning={isLoading}>
          <AddUser roles={allRoles} />
        </Spin>
      ),
    },
  ];

  const render = () => {
    return (
      <Content>
        <Title>{t('title')}</Title>
        <Tabs onChange={value => setActiveKey(value)} activeKey={activeKey} items={tabItems} />
      </Content>
    );
  };

  return render();
};

export default AccessSettings;
