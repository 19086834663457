export { default as AppImage } from '../assets/images/app.jpg';
export { default as ArticleImage } from '../assets/images/article.jpg';
export { default as BookImage } from '../assets/images/book.jpg';
export { default as CourseImage } from '../assets/images/corp_v2.jpg';
export { default as CourseImageCompany } from '../assets/images/corp_v2.jpg';
export { default as Course } from '../assets/images/course.jpg';
export { default as External } from '../assets/images/outer.png';
export { default as EventImage } from '../assets/images/event.jpg';
export { default as Conference } from '../assets/images/conference.jpg';
export { default as VideoImage } from '../assets/images/video.jpg';
export { default as Disable } from '../assets/images/disable.jpg';
