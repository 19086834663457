export const POST_MENTORS_FILTERS_START = 'POST_MENTORS_FILTERS_START';
export const POST_MENTORS_FILTERS_SUCCESS = 'POST_MENTORS_FILTERS_SUCCESS';
export const POST_MENTORS_FILTERS_FAIL = 'POST_MENTORS_FILTERS_FAIL';

export const GET_MENTORS_START = 'GET_MENTORS_START';
export const GET_MENTORS_SUCCESS = 'GET_MENTORS_SUCCESS';
export const GET_MENTORS_FAIL = 'GET_MENTORS_FAIL';

export const GET_MENTORS_STATUS_START = 'GET_MENTORS_STATUS_START';
export const GET_MENTOR_STATUS_SUCCESS = 'GET_MENTOR_STATUS_SUCCESS';
export const GET_MENTOR_STATUS_FAIL = 'GET_MENTOR_STATUS_FAIL';

export const GET_MENTEE_START = 'GET_MENTEE_START';
export const GET_MENTEE_SUCCESS = 'GET_MENTEE_SUCCESS';
export const GET_MENTEE_FAIL = 'GET_MENTEE_FAIL';

export const CHANGE_STATUS_START = 'CHANGE_STATUS_START';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAIL = 'CHANGE_STATUS_FAIL';

export const GET_COUNT_STAT_START = 'GET_COUNT_STAT_START';
export const GET_COUNT_STAT_SUCCESS = 'GET_COUNT_STAT_SUCCESS';
export const GET_COUNT_STAT_FAIL = 'GET_COUNT_STAT_FAIL';

export const PUT_SETTINGS_MENTORS_START = 'PUT_SETTINGS_MENTORS_START';
export const PUT_SETTINGS_MENTORS_SUCCESS = 'PUT_SETTINGS_MENTORS_SUCCESS';
export const PUT_SETTINGS_MENTORS_FAIL = 'PUT_SETTINGS_MENTORS_FAIL';

export const GET_SETTINGS_MENTORS_START = 'GET_SETTINGS_MENTORS_START';
export const GET_SETTINGS_MENTORS_SUCCESS = 'GET_SETTINGS_MENTORS_SUCCESS';
export const GET_SETTINGS_MENTORS_FAIL = 'GET_SETTINGS_MENTORS_FAIL';

export const GET_SETTINGS_COUNT_MENTORS_START = 'GET_SETTINGS_COUNT_MENTORS_START';
export const GET_SETTINGS_COUNT_MENTORS_SUCCESS = 'GET_SETTINGS_COUNT_MENTORS_SUCCESS';
export const GET_SETTINGS_COUNT_MENTORS_FAIL = 'GET_SETTINGS_COUNT_MENTORS_FAIL';

export const EXPORT_MENTORS_START = 'EXPORT_MENTORS_START';
export const EXPORT_MENTORS_SUCCESS = 'EXPORT_MENTORS_SUCCESS';
export const EXPORT_MENTORS_FAIL = 'EXPORT_MENTORS_FAIL';

export const EXPORT_MENTORS_SETTINGS_START = 'EXPORT_MENTORS_SETTINGS_START';
export const EXPORT_MENTORS_SETTINGS_SUCCESS = 'EXPORT_MENTORS_SETTINGS_SUCCESS';
export const EXPORT_MENTORS_SETTINGS_FAIL = 'EXPORT_MENTORS_SETTINGS_FAIL';

export const SAVE_MENTORS_FILTERS = 'SAVE_MENTORS_FILTERS';

export const CLEAR_MENTORS_FILTERS = 'CLEAR_MENTORS_FILTERS';

export const CREATE_MENTOR_START = 'CREATE_MENTOR_START';
export const CREATE_MENTOR_SUCCESS = 'CREATE_MENTOR_SUCCESS';
export const CREATE_MENTOR_FAIL = 'CREATE_MENTOR_FAIL';

export const POST_MENTORS_DATA_START = 'POST_MENTORS_DATA_START';
export const POST_MENTORS_DATA_SUCCESS = 'POST_MENTORS_DATA_SUCCESS';
export const POST_MENTORS_DATA_FAIL = 'POST_MENTORS_DATA_FAIL';

export const PUT_SETTINGS_LIMIT_MENTORS_START = 'PUT_SETTINGS_LIMIT_MENTORS_START';
export const PUT_SETTINGS_LIMIT_MENTORS_SUCCESS = 'PUT_SETTINGS_LIMIT_MENTORS_SUCCESS';
export const PUT_SETTINGS_LIMIT_MENTORS_FAIL = 'PUT_SETTINGS_LIMIT_MENTORS_FAIL';

export const GET_SETTINGS_LIMIT_MENTORS_START = 'GET_SETTINGS_LIMIT_MENTORS_START';
export const GET_SETTINGS_LIMIT_MENTORS_SUCCESS = 'GET_SETTINGS_LIMIT_MENTORS_SUCCESS';
export const GET_SETTINGS_LIMIT_MENTORS_FAIL = 'GET_SETTINGS_LIMIT_MENTORS_FAIL';
