import { useLayoutEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

const useIsMobile = value => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < value);
  useLayoutEffect(() => {
    // определяем мобильная версия или нет
    const updateSize = () => {
      setIsMobile(window.innerWidth < value);
      // setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    return () => window.removeEventListener('resize', updateSize);
  }, [value]);

  return [isMobile, setIsMobile];
};

useIsMobile.propTypes = {
  value: PropTypes.number,
};

export default useIsMobile;
