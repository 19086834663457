/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AutosizeInput from 'react-input-autosize';
import { connect } from 'react-redux';

import {
  createTheme,
  deleteTheme,
  getThemes,
  reorderTopThemes,
  reorderTopThemesRequest,
  updateTheme,
} from '@/store/compilations/actions';

import { selectCompilations as compilationsList } from '@/store/compilations/selectors';
import { selectSearch } from '@/store/search/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Alert, Form, message, notification, Spin, Tree } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import TreeNodeTitle from './TreeNodeTitle';

import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';

import css from './ThemesEditor.module.scss';

import { THEME_CREATE } from '@/constants/permissions';

class ThemesEditor extends Component {
  static propTypes = {
    className: PropTypes.string,
    clearSearchParams: PropTypes.func,
    createTheme: PropTypes.func,
    deleteTheme: PropTypes.func,
    getThemes: PropTypes.func,
    isCheckable: PropTypes.bool,
    onlyView: PropTypes.bool,
    isGlobal: PropTypes.bool,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
    themeId: PropTypes.array,
    themes: PropTypes.array,
    themesUsed: PropTypes.array,
    updateCheckableElems: PropTypes.func,
    updateTheme: PropTypes.func,
    dataQa: PropTypes.string,
    isThemeEditor: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const selectedThemeIds =
      (props.themeId.length && props.themeId) || (props.themesUsed && props.themesUsed.map(theme => theme.id)) || [];

    this.state = {
      isVisible: false,
      isAddTheme: false,
      newThemeName: '',
      isEdit: false,
      errors: {},
      selectedThemeIds,
      expandedThemes: this.getExpandedThemes(props.themes, selectedThemeIds),
    };
  }

  componentDidMount() {
    this.props.getThemes();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.themeId.sort()) !== JSON.stringify(this.props.themeId.sort())) {
      this.setState({
        selectedThemeIds: this.props.themeId,
        expandedThemes: _uniq([
          ...this.getExpandedThemes(this.props.themes, this.props.themeId),
          ...this.state.expandedThemes,
        ]),
      });
    }
    if (
      prevProps.themesUsed &&
      JSON.stringify(prevProps.themesUsed.sort()) !== JSON.stringify(this.props.themesUsed.sort())
    ) {
      const selectedIds = this.props.themesUsed.map(theme => theme.id);
      this.setState({
        selectedThemeIds: selectedIds,
        expandedThemes: [...this.getExpandedThemes(this.props.themes, selectedIds), ...this.state.expandedThemes],
      });
    }
  }

  onDelete = () => this.setState({ isEdit: !this.state.isEdit });

  getExpandedThemes = (themes, selectedThemeIds) => {
    return themes.reduce((result, theme) => {
      if (theme.childs?.some(childTheme => selectedThemeIds.includes(String(childTheme.id)))) {
        result.push(`${theme.id}`);
      }
      return result;
    }, []);
  };

  updateTheme = (name, item) => {
    return this.props.updateTheme({
      ...item,
      name,
    });
  };

  setExpandedThemes = expandedThemes => {
    this.setState({ expandedThemes });
  };

  toggleThemeForm = (parentId, childs = '') => {
    if (!parentId) {
      this.setState({
        isAddTheme: !this.state.isAddTheme,
        [`${parentId}-new`]: '',
        ...(!this.state.isAddTheme && {
          newThemeName: '',
        }),
      });
    } else {
      this.setState({
        [`${parentId}-new`]: '',
        [`isForm${childs}-${parentId}`]: !this.state[`isForm${childs}-${parentId}`],
      });
    }
  };

  createTheme = (nameStr, parentId) => {
    const name = nameStr.trim();
    return this.props.createTheme(
      {
        parentId,
        name,
      },
      () => {
        this.props.getThemes();
        if (parentId) {
          this.toggleThemeForm(parentId);
        } else {
          this.toggleThemeForm();
        }
      }
    );
  };

  delTheme = id => this.props.deleteTheme(id, this.props.getThemes);

  onChange = (checkableElems, { checkedNodes }) => {
    const checkbleList = checkedNodes
      ? checkedNodes.map(item => ({
          id: item.id,
          name: item.name,
        }))
      : [];
    return this.props.updateCheckableElems(checkbleList);
  };

  setThemeName = (e, parentId, childs = '') => {
    const { value } = e.target;
    if (value) {
      const newString = !value.trim().match('[!@#$%^&*(),.?":{}|\\/<>]{2,}');
      if (value.trim().length > 50) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('max'),
          },
        });
      }
      if (value.trim().length < 2) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('min'),
          },
        });
      }
      if (!newString) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('error'),
          },
        });
      }
    }
    return this.setState({
      [parentId ? `${parentId}-new` : 'newThemeName']: value,
      errors: {
        ...this.state.errors,
        [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: null,
      },
    });
  };

  renderForm = (parentId, childs, editable) => (
    <div>
      {editable &&
        !this.state[`isForm${childs}-${parentId}`] &&
        this.props.currentUser.authorities.includes(THEME_CREATE) && (
          <a
            href='#'
            className={css.TreeNode__btns}
            onClick={() => this.toggleThemeForm(parentId, childs)}
            style={{ margin: '0' }}
          >
            {this.props.t('addTopicCurrent')}
          </a>
        )}
      {this.state[`isForm${childs}-${parentId}`] && (
        <div className={css.TreeNode__actions__box}>
          <Form.Item error={this.state.errors[`isForm${childs}-${parentId}`]}>
            <AutosizeInput
              data-qa='themeNameInput'
              className={classNames(css.TreeNode__input, this.props.className ? this.props.className : '')}
              onChange={e => this.setThemeName(e, parentId, childs)}
              onBlur={e => this.setThemeName(e, parentId, childs)}
              maxLength={50}
              value={this.state[`${parentId}-new`]}
              placeholder={this.props.t('name')}
            />
          </Form.Item>
          <div className={css.TreeNode__btns__box}>
            <a
              onClick={() => this.createTheme(this.state[`${parentId}-new`], parentId)}
              className={css.TreeNode__btns}
              disabled={this.state.errors[`isForm${childs}-${parentId}`] || !this.state[`${parentId}-new`]}
            >
              <CheckOutlined />
              {this.props.t('create')}
            </a>
            <a
              onClick={() => this.toggleThemeForm(parentId, childs)}
              className={classNames(css.TreeNode__btns, css.TreeNode__btns__cancel)}
            >
              {this.props.t('cancel')}
            </a>
          </div>
        </div>
      )}
    </div>
  );

  renderTreeNodes = (data, withAdd = false, selectedThemeIds) => {
    return data?.map(item => {
      const isEditable = this.props.isGlobal === item.global;
      const isSelected = +selectedThemeIds === item.id;

      const title = (
        <TreeNodeTitle
          dataQa='compilationThemeList'
          onlyView={this.props.onlyView}
          isEditable={isEditable}
          isCheckable={this.props.isCheckable}
          isSelected={isSelected}
          name={item.name}
          id={item.id}
          error={this.state.errors[`isForm-${item.id}`]}
          onChange={e => this.setThemeName(e, item.id)}
          delTheme={() => this.delTheme(item.id)}
          updateTheme={name => this.updateTheme(name, item)}
          currentUser={this.props.currentUser}
        />
      );

      const node = {
        title: title,
        key: item.id,
        className: css.TreeNode,
        ...item,
      };

      if (item.childs && item.childs?.length > 0) {
        node.children = this.renderTreeNodes(item.childs, true, selectedThemeIds);
      }

      if (item.childs?.length > 0 || (withAdd && !this.props.onlyView)) {
        if (!node.children) {
          node.children = [];
        }
        !this.props.onlyView &&
          node.children.push({
            title: this.renderForm(item?.id, 'childs', isEditable),
            key: `${item?.id}-add`,
            className: css.TreeNode,
            disableCheckbox: true,
          });
      }

      return node;
    });
  };

  getThemeForm = () => {
    const { isAddTheme, errors, newThemeName } = this.state;
    const { className } = this.props;

    return isAddTheme ? (
      <div className={css.TreeNode__actions__box}>
        <Form.Item error={errors.newThemeName}>
          <AutosizeInput
            data-qa='themeNameInput'
            className={classNames(css.TreeNode__input, className || '')}
            onChange={this.setThemeName}
            value={newThemeName}
            maxLength={50}
            placeholder={this.props.t('name')}
            style={{ marginLeft: '28px' }}
          />
        </Form.Item>
        <div className={css.TreeNode__btns__container}>
          <a
            data-qa='applyNewThemeBtn'
            onClick={() => this.createTheme(newThemeName)}
            className={css.TreeNode__btns}
            disabled={errors.newThemeName || !newThemeName}
          >
            <CheckOutlined />
            {this.props.t('create')}
          </a>
          <a
            data-qa='cancelThemeBtn'
            onClick={() => this.toggleThemeForm()}
            className={classNames(css.TreeNode__btns, css.TreeNode__btns__cancel)}
          >
            {this.props.t('cancel')}
          </a>
        </div>
      </div>
    ) : null;
  };

  setSelectedThemes = themeIds => {
    const { setSearchParams, searchParams } = this.props;
    this.setState({ selectedThemeIds: themeIds });

    setSearchParams({ ...searchParams, themeId: themeIds, page: 0 });
  };

  onDrop = ({ event, node, dragNode, dragNodesKeys }) => {
    const countOfHypensSource = (dragNode.pos.match(/-/g) || []).length;
    const countOfHypensTarget = (node.pos.match(/-/g) || []).length;

    const isTopLevel = countOfHypensSource === 1 && countOfHypensTarget === 1;

    if (!isTopLevel) {
      notification.error({
        placement: 'bottomRight',
        bottom: 50,
        duration: 5,
        description: this.props.t('cannotDrag'),
        message: this.props.t('cannotDragMessage'),
      });

      return;
    }

    const fromPosition = dragNode.position;
    const toPosition = node.position;

    const fromPositionIndex = this.props.themes?.findIndex(item => item.position === fromPosition);
    const toPositionIndex = this.props.themes?.findIndex(item => item.position === toPosition);

    if (fromPositionIndex === toPositionIndex) return;

    const newThemes = this.props.themes.map(element => ({ ...element }));
    const maxLength = newThemes.length - 1;

    const fromItem = cloneDeep(newThemes[fromPositionIndex]);
    const toItem = cloneDeep(newThemes[toPositionIndex]);

    const fromId = fromItem.id;
    const toId = toItem.id;

    const reorderData = {
      currentPosition: fromPosition,
      newPosition: toPosition,
      themeId: fromId,
    };

    this.props.reorderTopThemesRequest(
      reorderData,
      () => {
        fromItem.position = null;
        newThemes.splice(fromPositionIndex, 1);

        if (toPositionIndex === 0) {
          newThemes.splice(0, 0, fromItem);
        }

        if (toPositionIndex === maxLength) {
          newThemes.push(fromItem);
        }

        if (toPositionIndex !== 0 && toPositionIndex !== maxLength) {
          newThemes.splice(toPositionIndex, 0, fromItem);
        }

        newThemes.forEach((theme, index) => {
          theme.position = index + 1;
        });

        this.props.reorderTopThemes(newThemes);
      },
      error => {
        message.error(this.props.t('cannotDragMessage'));
        console.log(error);
      }
    );
  };

  render() {
    const { selectedThemeIds, expandedThemes, isAddTheme } = this.state;
    const { themesUsed, isCheckable, onlyView, isLoading } = this.props;

    const themes = this.props.themes; // this.props.themes.sort((a, b) => a.position - b.position).map(el => ({ ...el, isTopLevel: true }));

    const isTreeNotEmpty = themes.length;
    const themeForm = this.getThemeForm();

    const treeData = this.renderTreeNodes(themes, true, selectedThemeIds);

    return (
      <div className={classNames({ [css.onlyView]: onlyView })} data-qa={this.props.dataQa}>
        {onlyView && isTreeNotEmpty ? (
          <a
            className={classNames(css.seeAll, {
              [css.active]: !selectedThemeIds.length,
            })}
            onClick={() => this.setSelectedThemes([])}
          >
            {this.props.t('allTopic')}
          </a>
        ) : null}
        {!onlyView && (
          <Alert message={this.props.t('warning')} type='warning' showIcon closable className={css['Alert']} />
        )}
        <Spin spinning={isLoading}>
          <Tree
            key={selectedThemeIds}
            checkStrictly
            checkable={isCheckable}
            defaultCheckedKeys={themesUsed && themesUsed.map(theme => theme.id)}
            expandedKeys={expandedThemes}
            onExpand={this.setExpandedThemes}
            onCheck={this.onChange}
            {...(onlyView && {
              onSelect: this.setSelectedThemes,
            })}
            onDragEnd={this.onDragEnd}
            onDrop={this.onDrop}
            draggable={this.props.isThemeEditor}
            showIcon={this.props.isThemeEditor}
            treeData={treeData}
            height={window.innerHeight < 600 ? 600 : window.innerHeight - 400}
          />
        </Spin>
        {!onlyView && themeForm}
        {!isAddTheme && !onlyView && this.props.currentUser.authorities.includes(THEME_CREATE) && (
          <a
            className={css.TreeNode__btns}
            data-qa='addNewThemeBtn'
            onClick={() => this.toggleThemeForm()}
            style={{ marginLeft: '28px' }}
          >
            {this.props.t('addTopic')}
          </a>
        )}
      </div>
    );
  }
}

ThemesEditor.defaultProps = {
  isCheckable: false,
};

const mapStateToProps = createSelector(compilationsList, selectSearch, usersSelect, (compilations, search, users) => ({
  themes: compilations?.themes || [],
  themeId: (search.searchParams && search.searchParams.themeId) || [],
  searchParams: search.searchParams,
  theme: compilations.theme,
  isGlobal: users && users.currentUser.domainCompany.global,
  currentUser: users.currentUser,
  isLoading: compilations.isLoading,
}));

const mapActionsToProps = {
  getThemes,
  deleteTheme,
  createTheme,
  updateTheme,
  reorderTopThemes,
  reorderTopThemesRequest,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('themesEditor')(ThemesEditor));
