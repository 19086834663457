import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getStatUser } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';

import { Modal, Table, Tooltip } from 'antd';
import ShowCourseStatus from '@/components/CoursesTopicMembersTable/ShowCourseStatus';
import TypeBadgeNew from '@/components/TypeBadgeNew';
import { QuestionCircleOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import css from '../TrackDetail.module.scss';

import { TOPIC_TYPES } from '@/constants/topic-types';

const ModalStat = ({ stat, onCancel }) => {
  const { t } = useTranslation('trackDetail');
  const dispatch = useDispatch();
  const { isUserStat, userStats, userStatsTotal, userStatsPageSize, userStatsPageNumber, userStatsCurrentPage } =
    useSelector(selectTracks);

  const titleModal = <div className={css['Modal-title']}>{stat?.fullName}</div>;

  const columns = [
    {
      title: t('topicName'),
      dataIndex: 'topicName',
      key: 1,
      render: (text, topic) => (
        <Link to={`/topic/${topic?.topicId}`} target='_blank'>
          {text}
        </Link>
      ),
    },
    {
      title: t('typeMaterial'),
      dataIndex: 'typeMaterial',
      key: 2,
      render: topicType => {
        const itemType = TOPIC_TYPES(t).find(type => type.id === topicType?.topicTypeId);
        return (
          <div className={css['Modal-table-type']}>
            <TypeBadgeNew type={itemType.code} /> <span>{topicType?.name}</span>
          </div>
        );
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 3,
      render: (_, val) => <ShowCourseStatus statusValue={val.statusValue} />,
    },
    {
      title: () => (
        <div>
          <span>{t('progress')}</span>
          <Tooltip title={t('infoProgress')}>
            <QuestionCircleOutlined className={css['Modal-table-prog']} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'progress',
      key: 4,
      width: 115,
      render: prog => <strong>{prog}</strong>,
    },
    {
      title: t('deadline'),
      dataIndex: 'deadline',
      key: 5,
    },
    {
      title: t('dateEnd'),
      dataIndex: 'dateEnd',
      key: 6,
    },
    {
      title: t('expired'),
      dataIndex: 'expired',
      key: 7,
      width: 60,
      render: val => (val === true ? t('yes') : t('no')),
    },
  ];

  const dataSource = useMemo(
    () =>
      userStats?.length > 0 &&
      userStats.map(item => {
        return {
          topicName: item.topicName,
          topicId: item.topicId,
          status: item.statusDto.name,
          statusValue: item.statusDto.value,
          progress: `${item.progress}%`,
          deadline: item.startDate,
          dateEnd: item.endDate,
          expired: item.isExpiredDate,
          typeMaterial: item.topicLabel,
        };
      }),
    [userStats]
  );

  const pagination = {
    total: userStatsTotal,
    pageSize: userStatsPageSize,
    pageNumber: userStatsPageNumber,
    current: userStatsCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const onChangeTable = pagination => {
    dispatch(getStatUser(stat.trackId, stat.userId, pagination.current - 1));
  };

  return (
    <Modal
      open={stat.open}
      onCancel={onCancel}
      className={css['Modal']}
      title={titleModal}
      width={1200}
      loading={isUserStat}
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={e => e.topicId}
        pagination={pagination}
        onChange={onChangeTable}
      />
    </Modal>
  );
};

ModalStat.propTypes = {
  stat: PropTypes.object,
  onCancel: PropTypes.func,
};

export default ModalStat;
