import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// import { createBrowserRouter, Route, Routes, RouterProvider, createRoutesFromElements } from 'react-router-dom';

import { getInfoCompany } from '@/store/login/actions';

import { selectLogin } from '@/store/login/selectors';

import { ConfigProvider, Spin } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ruRu from 'antd/lib/locale/ru_RU';
import { CompanyBlocked } from '@/pages/CompanyBlocked';
import { DomainSpinner } from '@/components/DomainSpinner';
import { LoadingOutlined } from '@ant-design/icons';

import { changeLanguage } from '@shared/utils/changeLanguage';

// import ServerError from '@/pages/ServerError';

import { legacyLogicalPropertiesTransformer, px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import dayjs from 'dayjs';
import { darken, setLightness } from 'polished';

import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';

import myRoutes from './myRoutes';

import '@/styles/main.scss';

import config from '@shared/services/merge-antd-style-config';

// import NotFound from '../NotFound';
// import AppLayout, { appLoader } from '../AppLayout';

const px2rem = px2remTransformer({
  rootValue: 14, // 14px = 1rem; @default 14
});

const ThemeContext = createContext();

Spin.setDefaultIndicator(
  <span className='ant-spin-dot-holder'>
    <LoadingOutlined />
  </span>
);

export const ThemeProvider = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  const [theme, setTheme] = useState({}); // Start with a default theme or empty object
  const [isLoadingProject, changeLoadingProject] = useState(true);

  const { company } = useSelector(selectLogin);

  const updateTheme = newTheme => {
    setTheme({ ...theme, ...newTheme });
  };

  const setCompanyInfo = response => {
    const currentColor = response.colour || '#ff8c00';
    const darkenColor = darken(0.1, currentColor);
    const lightenColor = setLightness(0.75, currentColor);
    const selectedMenuItemColor = `${currentColor}10`;
    const body = document.querySelector('body');

    body.style.setProperty('--personalColorSelectedItem', selectedMenuItemColor);
    body.style.setProperty('--personalColor', currentColor);
    body.style.setProperty('--personalColorDark', darkenColor);
    body.style.setProperty('--personalColorLight', lightenColor);

    const replaceConfig = JSON.stringify(config)
      .replaceAll('--personalColorLight', lightenColor)
      .replaceAll('--personalColorDark', darkenColor)
      .replaceAll('--personalColorSelectedItem', selectedMenuItemColor)
      .replaceAll('--personalColor', currentColor);

    const currentConfig = JSON.parse(replaceConfig);

    changeLanguage(i18n, dayjs, response.defaultLanguage);

    setTheme({ ...currentConfig, cssVar: true, hashed: false });

    switch (response?.id) {
      case 5451:
        document.title = response.shortName;
        let link = document.querySelector("link[rel~='icon']");
        link.href = '/assets/favicons/absolute/favicon.ico';
        break;
      default:
        if (import.meta.env.PUBLIC_APP_TITLE_ADMIN) document.title = import.meta.env.PUBLIC_APP_TITLE_ADMIN;
        break;
    }
  };

  useLayoutEffect(() => {
    dispatch(
      getInfoCompany(
        response => {
          setCompanyInfo(response);
          changeLoadingProject(false);
        },
        () => {
          setIsError(true);
        }
      )
    );
  }, []);

  if (company?.status === 'BLOCKED' || company?.status === 'INACTIVE' || isError) return <CompanyBlocked />;

  if (isLoadingProject) return <DomainSpinner />;

  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      <ConfigProvider theme={theme} locale={i18n.language === 'ru_RU' ? ruRu : enUS}>
        <StyleProvider hashPriority='high' transformers={[legacyLogicalPropertiesTransformer, px2rem]} layer>
          <RouterProvider router={createBrowserRouter(myRoutes)} fallbackElement={<DomainSpinner />} />
        </StyleProvider>
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme context
export const useTheme = () => useContext(ThemeContext);
