/* eslint-disable */
import _assign from 'lodash/assign';
import _clone from 'lodash/clone';
import _merge from 'lodash/merge';
import _set from 'lodash/set';

export const createAction = (type, reducer) => {
  const action = payload => ({ type, payload });
  action.type = type;
  action.action = { [type]: reducer || (state => state) };
  action.skip = true; // todo remove after remove api-middleware
  action.toString = () => type;
  return action;
};

export const createAsyncAction = type => {
  const action = createAction(type);
  action.start = createAction(`${type}_start`);
  action.success = createAction(`${type}_success`);
  action.failure = createAction(`${type}_failure`);

  return action;
};

export const combineActions = (handler, actions = []) =>
  actions.reduce(
    // eslint-disable-next-line no-return-assign
    (res, action) => ((res[action] = handler || (state => state)), res),
    {}
  );

export const createReducer =
  (actions = {}, initState) =>
  (state = initState, { type, payload }) =>
    actions[type] ? actions[type](state, payload) : state;

export const reducerAdapter =
  (actions, initState, newReducer, oldReducer) =>
  (state = initState, action) => {
    const { type } = action;
    return actions[type] ? newReducer(state, action) : oldReducer(state, action);
  };

export const updateState = (state, value, force = false) => {
  if (!value) {
    return state;
  }
  const update = force ? _assign : _merge;
  return update(_clone(state), value);
};

export const updateStateFlow =
  (...reducers) =>
  (state, payload) =>
    reducers.reduce((resState, reducer) => reducer(resState, payload), _clone(state));

export const setState = (state, path, value) => _set(_clone(state), path, value);
