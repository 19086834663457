/* eslint-disable */

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import _ from 'lodash';

import gpmAdmins from '@/constants/specialAdmins.json';

const UUID_TEMPLATE = 'xxxxxxxx-xxxx-1979-yxxx-xxxxxxxxxxxx';
const MAX_LENGTH_TEXT = 100;
const MANAGER_ROLE_ID = 3;
const ADMIN_ROLE_ID = 2;
const SUPERADMIN_ROLE_ID = 1;

export default class Utils {
  static isManager(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === MANAGER_ROLE_ID) ||
      currentUser.roleId === MANAGER_ROLE_ID
    );
  }

  static isAdmin(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === ADMIN_ROLE_ID) || currentUser.roleId === ADMIN_ROLE_ID
    );
  }

  static isSuperAdmin(currentUser) {
    return currentUser?.parentRoleId === SUPERADMIN_ROLE_ID || currentUser.roleId === SUPERADMIN_ROLE_ID;
  }

  static isAdminOrSuperAdmin(currentUser) {
    if (Utils.isAdmin(currentUser) || Utils.isSuperAdmin(currentUser)) {
      return true;
    }

    return false;
  }

  static hasOwnPropertyAndNotNull(object, property) {
    if (typeof object !== 'undefined') {
      if (typeof object[property] !== 'undefined') {
        if (object[property] !== null) {
          return true;
        }
      }
    }

    return false;
  }

  static trString(str, len) {
    let sliced = str.slice(0, len);
    if (sliced.length < str.length) {
      sliced += '...';
    }

    return sliced;
  }

  static isGPMValid(user) {
    let data = [];
    if (user && user.email !== null) {
      const admins = gpmAdmins.filter(company => company.email.toLowerCase() === user.email.toLowerCase());
      if (admins.length !== 0) {
        admins.forEach(adminCompany => {
          data.push(adminCompany.company);
        });
        let coincidence = false;
        data.forEach(dataCompany => {
          if (dataCompany === user.company) {
            coincidence = true;
          }
        });
        if (!coincidence) {
          data.push(user.company);
        }
      }
    }
    return data;
  }

  makeUUID() {
    return UUID_TEMPLATE.replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static extractUsername = object => {
    let username = '';
    if (object) {
      if (object.userLastName && object.userLastName !== null) {
        username += object.userLastName;
      }

      if (object.userFirstName && object.userFirstName !== null) {
        username += ' ' + object.userFirstName.substring(0, 1).toUpperCase() + '.';
      }

      if (object.userMiddleName && object.userMiddleName !== null) {
        username += ' ' + object.userMiddleName.substring(0, 1).toUpperCase() + '.';
      }
    }

    return username;
  };

  static maxTextValue = tagName => {
    return tagName.length > MAX_LENGTH_TEXT;
  };

  static canUseWebinar = topicId => {
    return topicId === 8;
  };

  static isAdminRole(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId !== MANAGER_ROLE_ID) ||
      currentUser.roleId !== MANAGER_ROLE_ID
    );
  }

  static isManagerRole(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === MANAGER_ROLE_ID) ||
      currentUser.roleId === MANAGER_ROLE_ID
    );
  }

  static changeActiveStatusCollapse(collProp) {
    if (collProp.isActive) {
      return RightOutlined;
    } else {
      return LeftOutlined;
    }
  }

  static trStringFile(str, len) {
    if (str.length <= len) return str;

    const dotIndex = str.lastIndexOf('.');
    if (dotIndex === -1 || dotIndex === 0) {
      // Если нет расширения файла
      return str.slice(0, len) + '...';
    }

    const extension = str.slice(dotIndex);
    const baseLength = len - extension.length;

    const truncatedBase = str.slice(0, baseLength);
    return `${truncatedBase}...${extension}`;
  }

  static handleCustomFields(customFields) {
    const CF =
      customFields?.length > 0 &&
      customFields
        .filter(el => {
          // убрать пустые объекты
          if (Object.keys(el).length === 0) return false;
          return true;
        })
        .reduce((acc, item) => {
          if (Array.isArray(item.valueConcrete)) {
            const values = item.valueConcrete;

            for (const value of values) {
              acc.push({
                customFieldId: item.customFieldId,
                type: item.type,
                valueConcrete: value,
              });
            }
          } else {
            acc.push(item);
          }

          return acc;
        }, []);
    const result = CF.filter(cf => cf.hasOwnProperty('customFieldId') && cf.hasOwnProperty('valueConcrete'));
    return result;
  }

  static isDefAdmin(currentUser) {
    if (currentUser?.isGlobalAdmin && localStorage.getItem('isDefaultAdmin')) {
      return true;
    }

    return false;
  }
}
