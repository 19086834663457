/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_COMPANIES_SHORT_START = 'GET_COMPANIES_SHORT_START';
export const GET_COMPANIES_SHORT_SUCCESS = 'GET_COMPANIES_SHORT_SUCCESS';
export const GET_COMPANIES_SHORT_FAIL = 'GET_COMPANIES_SHORT_FAIL';

export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';

export const GET_SUBCOMPANIES_START = 'GET_SUBCOMPANIES_START';
export const GET_SUBCOMPANIES_SUCCESS = 'GET_SUBCOMPANIES_SUCCESS';
export const GET_SUBCOMPANIES_FAIL = 'GET_SUBCOMPANIES_FAIL';

export const CREATE_COMPANIES_START = 'CREATE_COMPANIES_START';
export const CREATE_COMPANIES_SUCCESS = 'CREATE_COMPANIES_SUCCESS';
export const CREATE_COMPANIES_FAIL = 'CREATE_COMPANIES_FAIL';

export const EDIT_COMPANIES_START = 'EDIT_COMPANIES_START';
export const EDIT_COMPANIES_SUCCESS = 'EDIT_COMPANIES_SUCCESS';
export const EDIT_COMPANIES_FAIL = 'EDIT_COMPANIES_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getCompaniesShort = onSuccess => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/company/short?status=ACTIVE&size=10000&sort=shortName%2Casc`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPANIES_SHORT_START, GET_COMPANIES_SHORT_SUCCESS, GET_COMPANIES_SHORT_FAIL],
    onSuccess,
  };
};

export const getCompanies = (status = 'ACTIVE', onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/company${getQueryString({
      status,
      size: 10000,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPANIES_START, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAIL],
    onSuccess,
  };
};

export const getSubcompanies = (status = 'ACTIVE', onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/subcompanies`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_SUBCOMPANIES_START, GET_SUBCOMPANIES_SUCCESS, GET_SUBCOMPANIES_FAIL],
    onSuccess,
  };
};

export const createCompanies = (data, onSuccess) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/company`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    types: [CREATE_COMPANIES_START, CREATE_COMPANIES_SUCCESS, CREATE_COMPANIES_FAIL],
    onSuccess,
  };
};

export const editCompanies = ({ id, ...data }, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/company/${encodeURI(id)}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    types: [EDIT_COMPANIES_START, EDIT_COMPANIES_SUCCESS, EDIT_COMPANIES_FAIL],
    onSuccess,
  };
};

export const getCompaniesByUserName = (name = '', topicId, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/search/user/companies${getQueryString({
      name,
      topicId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_SUBCOMPANIES_START, GET_SUBCOMPANIES_SUCCESS, GET_SUBCOMPANIES_FAIL],
    onSuccess,
  };
};
