import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { clearFullname, getStudents, getStudentsList } from '@/store/students/actions';

import { selectStudents as studentSelect } from '@/store/students/selectors';
import { selectUsers as userSelect } from '@/store/users/selectors';

import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import CurrencyInput from '@/components/CurrencyInput';

import EventPeriod from './EventPeriod';

import {
  isConfOrAllCourse,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourseWithOrWithoutApp,
  isEventOrConference,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from '../Topics/TopicCreationDialog/TopicCreationDialog.module.scss';

import { MAX_LENGTH_COST } from '@/constants/global';
import { EVENT_FIELD } from '@/constants/topic-types';

const { TextArea } = Input;
let result = [];
let valPeriod = true;

export class CreateEventModal extends Component {
  static propTypes = {
    coursesFilter: PropTypes.object,
    createEvent: PropTypes.func,
    editable: PropTypes.bool,
    event: PropTypes.object,
    failEventValidation: PropTypes.func,
    fullnameManager: PropTypes.array,
    getCoursesRequests: PropTypes.func,
    getEventTopic: PropTypes.func,
    getStudentsList: PropTypes.func,
    getStudents: PropTypes.func,
    getYear: PropTypes.func,
    isEnded: PropTypes.any,
    manager: PropTypes.object,
    onCancel: PropTypes.func,
    place: PropTypes.func,
    topic: PropTypes.object,
    updateEvent: PropTypes.func,
    open: PropTypes.bool,
    year: PropTypes.number,
    currentUser: PropTypes.object,
    eventStartTime: PropTypes.string,
    eventEndTime: PropTypes.string,
    void: PropTypes.bool,
    sendToOutlook: PropTypes.bool,
    noUserConfirmation: PropTypes.bool,
    fullname: PropTypes.array,
    clearFullname: PropTypes.any,
    isLoadingFullname: PropTypes.bool,
    isLoadingSpeakers: PropTypes.bool,
    speakers: PropTypes.array,
  };

  constructor(props) {
    super(props);
    if (props.event) {
      const { ...event } = props.event;

      this.state = {
        ...event,
        managerId: _get(props, 'event.manager.id'),
        managerName: _get(props, 'event.manager.name'),
        isAdmin: Utils.isAdminRole(this.props.currentUser),
        isSaving: false,
        isUpdating: false,
        isValidationStart: '',
        errors: {},
        name: event.name,
        comment: event.comment,
        count: event.participantsCount,
        minCount: event.participantsMinCount,
        confirmVisible: false,
        formChange: false,
        period: [],
        periodId: 1,
        btnClick: false,
        topicTypeId: _get(event, 'topic.type.id'),
        noUserConfirmation: event.noUserConfirmation,
        cost: event.cost || 0,
        deadlineRegistration: event.deadlineRegistration,
        deadlineCancellation: event.deadlineCancellation,
        cancellationPenalty: event.cancellationPenalty,
      };
    } else {
      this.state = {
        isAdmin: Utils.isAdminRole(this.props.currentUser),
        managerId: _get(props, 'event.manager.id'),
        managerName: _get(props, 'event.manager.name'),
        options: null,
        isSaving: false,
        isUpdating: false,
        isValidationStart: '',
        errors: {},
        name: this.props.topic?.name,
        count: null,
        minCount: null,
        comment: null,
        confirmVisible: false,
        period: [
          {
            id: 0,
            dates: [],
            startTime: props.eventStartTime,
            endTime: props.eventEndTime,
            place: '',
          },
        ],
        periodId: 1,
        btnClick: false,
        sendToOutlook: true,
        noUserConfirmation: false,
        topicTypeId: _get(props, 'topic.type.id'),
        cost: 0,
      };
    }

    this.getStudentsListDebounced = _.debounce(props.getStudentsList, 1000);
    this.getStudentsDebounced = _.debounce(props.getStudents, 1500);
  }

  componentDidMount() {
    this.props.clearFullname();
    // if (this.props.event && this.props.event.periods !== []) { // старая непонятная провека
    if (Array.isArray(this.props.event?.periods) && this.props.event.periods.length > 0) {
      const arr = [];
      let dt = [];
      this.props.event.periods.map((per, item) => {
        per.dates.map(date => {
          dt.push(date);
        });
        arr.push({
          id: item,
          startTime: per.startTime,
          endTime: per.endTime,
          dates: dt,
          place: per.place,
        });
        dt = [];
      });
      this.setState({ period: arr, periodId: arr.length });
    }

    if (isConfOrAllCourse(this.props.topic.type.id) && this.props.event?.speakers?.length > 0) {
      this.setState({
        speakerIds: this.props.event?.speakers?.map(option => ({
          value: option.id,
          label: option.name,
        })),
      });
    }

    if (!this.period) {
      this.createPeriod();
    }
  }

  componentWillUnmount() {
    this.props.clearFullname();
    this.getStudentsListDebounced.cancel();
    this.getStudentsDebounced.cancel();
  }

  handleValidationPeriod = (id, flag) => {
    if (!flag) {
      valPeriod = false;
      this.setState({ isValidationStart: '' });
      this.setState({ btnClick: false });
    }

    if (valPeriod && this.state.period[this.state.period.length - 1].id === id) {
      if (this.state.isValidationStart === 'update') {
        this.setState({ isUpdating: true });
      } else {
        this.setState({ isSaving: true });
      }
    }
  };

  handleEditEventClick = () => {
    if (this.formValidation()) {
      this.setState({ btnClick: true });
      this.setState({ isValidationStart: 'update' });
    }
    valPeriod = true;
  };

  handleAddEventClick = () => {
    if (this.formValidation()) {
      this.setState({ btnClick: true });
      this.setState({ isValidationStart: 'save' });
    }
    valPeriod = true;
  };

  isFieldEmpty = fieldName => {
    if (typeof this.state[fieldName] === 'boolean') {
      return false;
    }
    if (fieldName === 'cost') {
      return this.state[fieldName] < 0;
    }
    if (Array.isArray(this.state[fieldName])) {
      return !this.state[fieldName].length;
    }
    return !this.state[fieldName];
  };

  formValidation = () => {
    let errors = {};
    let haveErrors = false;
    if (this.state.count === null || this.state.count == 0) this.setState({ count: 1 });
    if (this.state.minCount === null || this.state.minCount == 0) this.setState({ minCount: 1 });

    for (let i = 0; i < EVENT_FIELD.length; i++) {
      const field = EVENT_FIELD[i];
      if (field.required && this.isFieldEmpty(field.apiField)) {
        errors[field.apiField] = this.props.t('mandatory');
        haveErrors = true;
      }
      if (field.apiField === 'minCount') {
        errors[field.apiField] = this.state.minCount > this.state.count ? this.props.t('minCountError') : null;
        haveErrors = this.state.minCount > this.state.count;
      }
    }

    if (this.state.deadlineCancellation) {
      if (!this.state.cancellationPenalty) {
        errors['cancellationPenalty'] = this.props.t('cancellationPenaltyError'); //
        haveErrors = true;
      }
    } else {
      if (this.state.cancellationPenalty > 0) {
        errors['deadlineCancellation'] = this.props.t('deadlineCancellationError');
        haveErrors = true;
      }
    }

    this.setState({
      errors,
      btnClick: false,
    });
    return !haveErrors;
  };

  handleChangeNumber = event => {
    const { name, value } = event.target || event;
    if ((value <= 999 && value >= 0) || name === 'cost') {
      this.setState({ [name]: +value, formChange: true });
    } else {
      this.setState({ [name]: null, formChange: true });
    }
  };

  handleChange = event => {
    const { name, value } = event.target || event;
    this.setState({ [name]: value, formChange: true });
  };

  handleChangePeriod = (date, startTime, endTime, id, place) => {
    date.map(d => {
      result.push({
        startTime: `${dayjs(d).format('YYYY-MM-DD')}T${startTime}`,
        endTime: `${dayjs(d).format('YYYY-MM-DD')}T${endTime}`,
        place,
      });
    });
    if (this.state.period[this.state.period.length - 1].id === id) {
      this.handleSaveEventClick(result);
    }
  };

  handleSaveEventClick = res => {
    const { ...event } = this.state;
    const withoutAppRes = res.map(item => ({ ...item, place: item?.place ? item.place : undefined }));
    const courseEventVM = {
      name: this.state.name.trim(),
      participantsCount: this.state.count || 0,
      participantsMinCount: this.state.minCount || 0,
      comment: this.state.comment,
      periods: isCorporateCourseWithoutApprove(this.props.topic?.type.id) ? withoutAppRes : res,
      managerId: this.state.managerId,
      topicId: this.props.topic?.id,
      sendToOutlook: this.state.sendToOutlook,
      noUserConfirmation: this.state.noUserConfirmation,
      speakerIds: this.state.speakerIds?.every(elem => typeof elem === 'object')
        ? this.state.speakerIds.map(elem => elem.value)
        : this.state.speakerIds,
      deadlineRegistration: this.state.deadlineRegistration,
      deadlineCancellation: this.state.deadlineCancellation,
      penalty: this.state.penalty,
      cost: this.state.cost,
      cancellationPenalty: this.state.cancellationPenalty === 0 ? null : this.state.cancellationPenalty,
    };

    if (this.formValidation()) {
      if (this.state.isUpdating) {
        this.props.updateEvent(event.id, courseEventVM, () => {
          this.setState({ btnClick: false });
          this.props.getEventTopic(this.props.topic?.id, {
            year: this.props.year,
            isEnded: this.props.isEnded,
          });
          this.props.getCoursesRequests({
            topicId: this.props.topic?.id,
            ...this.props.coursesFilter,
          });
          this.props.getYear(() => {}, {
            topicId: this.props.topic?.id,
            events: true,
            requests: true,
          });
          this.props.onCancel();
        });
      } else {
        this.props.createEvent(courseEventVM, () => {
          this.setState({ btnClick: false });
          if (!this.props.void) {
            this.props.getEventTopic(this.props.topic?.id, {
              year: this.props.year,
              isEnded: this.props.isEnded,
            });
            this.props.getYear(() => {}, {
              topicId: this.props.topic?.id,
              events: true,
              requests: true,
            });
          }
          this.props.onCancel();
        });
      }
    } else {
      this.setState({ btnClick: false });
    }
    result = [];
  };

  createPeriod = () => {
    const newPeriod = [
      ...this.state.period,
      {
        id: this.state.periodId,
        dates: [],
        startTime: this.props.eventStartTime,
        endTime: this.props.eventEndTime,
      },
    ];
    this.setState({ period: newPeriod, periodId: this.state.periodId + 1 });
  };

  deletePeriod = id => {
    const arr = this.state.period.filter(per => per.id !== id);
    this.setState({ period: arr });
  };

  isChangeCheck = () => {
    this.setState({
      formChange: true,
    });
  };

  handleScopeChange = event => {
    const { name, value } = event;
    if (name === 'managerId') {
      this.setState({ [name]: value });
      this.setState({ managerName: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  showConfirmDialog = () => this.setState({ confirmVisible: true });

  hideConfirmDialog = () => this.setState({ confirmVisible: false });

  handleSendToOutlookChange = n => {
    this.setState({
      sendToOutlook: n.value,
    });
  };

  setNoUserConfirmation = n => {
    this.setState({
      noUserConfirmation: n.value,
    });
  };

  searchManagers = e => {
    if (e) {
      let fullName = e.trim();
      fullName?.length > 3 && this.getStudentsListDebounced({ isManager: false, fullName, size: 100, role: [1, 2, 3] });
    }
  };

  searchSpeakers = e => {
    if (e) {
      let fullName = e.trim();
      fullName?.length > 2 && this.getStudentsDebounced({ fullName, size: 500 });
    }
  };

  changeSpeakers = value => {
    this.setState({ speakerIds: value });
  };

  render() {
    const { t } = this.props;
    const submitButton = this.props.editable ? (
      <Button
        onClick={this.handleEditEventClick}
        className={css['Add-modal-form-event']}
        disabled={this.state.btnClick}
        type='primary'
      >
        {t('save')}
      </Button>
    ) : (
      <Button
        onClick={this.handleAddEventClick}
        className={css['Add-modal-form-event']}
        disabled={this.state.btnClick}
        type='primary'
      >
        {t('save')}
      </Button>
    );

    const period = this.state.period.map(per => (
      <EventPeriod
        key={per.id}
        alone={this.state.period.length === 1}
        errors={this.state.errors}
        place={per.place}
        deletePeriod={this.deletePeriod}
        handleValidationPeriod={this.handleValidationPeriod}
        formValidation={this.formValidation}
        isValidationStart={this.state.isValidationStart}
        isUpdating={this.state.isUpdating}
        isSaving={this.state.isSaving}
        event={this.props.event}
        handleChangePeriod={this.handleChangePeriod}
        isChangeCheck={this.isChangeCheck}
        id={per.id}
        date={per.dates}
        startTime={per.startTime}
        endTime={per.endTime}
        sendToOutlook={this.state.sendToOutlook}
        handleSendToOutlook={this.handleSendToOutlook}
        requiredPlace={isEventOrConference(this.state.topicTypeId)}
      />
    ));

    // const renderSpeakersOption = arr => {
    //   return (
    //     arr?.length &&
    //     arr.map(item => {
    //       const name = `${item.firstName} ${item.middleName ? item.middleName + ' ' : ''}${item.lastName}`;
    //       return (
    //         <Select.Option value={item.id} key={item.id}>
    //           {name}
    //         </Select.Option>
    //       );
    //     })
    //   );
    // };

    const footer = (
      <div className={css['Add-modal-calendar-footerBtn']}>
        <Button onClick={this.createPeriod}>{t('addDates')}</Button>
        {submitButton}
      </div>
    );

    return (
      <Modal
        open={this.props.open}
        footer={footer}
        width={1000}
        onCancel={this.state.formChange ? this.showConfirmDialog : this.props.onCancel}
        title={this.props.void ? t('createEventUsers') : t('createEvent')}
        className={css['Add-modal']}
      >
        <Form className={css['Add-modal-form']}>
          <Form.Item
            label={t('title')}
            help={this.state.errors.name}
            validateStatus={this.state.errors.name ? 'error' : ''}
            required
            labelCol={{ span: 4 }}
          >
            <Input
              name='name'
              placeholder={t('title')}
              onChange={this.handleChange}
              value={this.state.name}
              maxLength={300}
            />
          </Form.Item>

          <Form.Item
            label={t('count')}
            help={this.state.errors.minCount || this.state.errors.count}
            validateStatus={this.state.errors.minCount || this.state.errors.count ? 'error' : ''}
            labelCol={{ span: 4 }}
          >
            {isCorpOrExtCourseWithOrWithoutApp && (
              <>
                <span className={css['Event-create-count-minSpan']}>{t('min')}</span>
                <Input
                  className={css['Event-create-count']}
                  type='number'
                  name='minCount'
                  placeholder='000'
                  onChange={this.handleChangeNumber}
                  value={this.state.minCount}
                  min={1}
                />
                <span className={css['Event-create-count-maxSpan']}>{t('max')}</span>
              </>
            )}
            <Input
              className={css['Event-create-count']}
              type='number'
              name='count'
              placeholder='000'
              onChange={this.handleChangeNumber}
              value={this.state.count}
              min={1}
            />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item
                label={<span style={{ textWrap: 'wrap', lineHeight: '1.2' }}>{t('cost')}</span>}
                colon={false}
                className={css.withoutValidation}
                help={this.state.errors.cost}
                validateStatus={this.state.errors.cost ? 'error' : ''}
                labelCol={{ span: 8 }}
                required
              >
                <CurrencyInput
                  className={css['Event-create-cost']}
                  name='cost'
                  onChange={this.handleChange}
                  value={this.state.cost}
                  maxLength={MAX_LENGTH_COST}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={t('registrationDeadline')} labelCol={{ span: 12 }}>
                <DatePicker
                  className={css['datePicker']}
                  format='YYYY-MM-DD'
                  value={this.state.deadlineRegistration ? dayjs(this.state.deadlineRegistration) : null}
                  onChange={(_, value) =>
                    this.handleChange({
                      value: value ? dayjs(value).format('YYYY-MM-DD') : '',
                      name: 'deadlineRegistration',
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={t('organizer')} colon={false} className={css.withoutValidation} labelCol={{ span: 4 }}>
            <Select
              onSearch={this.searchManagers}
              loading={this.props.isLoadingFullname}
              name='managerId'
              optionFilterProp='children'
              value={this.state.managerName}
              allowClear
              showSearch
              placeholder={t('notAssigned')}
              onChange={value => this.handleScopeChange({ name: 'managerId', value })}
            >
              {this.props.fullname?.map(option => (
                <Select.Option value={option.id} key={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('speaker')} colon={false} className={css.withoutValidation} labelCol={{ span: 4 }}>
            <Select
              mode='multiple'
              onSearch={this.searchManagers}
              loading={this.props.isLoadingFullname}
              name='speakerIds'
              optionFilterProp='children'
              value={this.state.speakerIds}
              allowClear
              showSearch
              placeholder={t('notAssigned')}
              onChange={value => this.handleScopeChange({ name: 'speakerIds', value })}
            >
              {this.props.fullname?.map(option => (
                <Select.Option value={option.id} key={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('comment')} labelCol={{ span: 4 }}>
            <TextArea
              className={css['Event-textarea']}
              rows={4}
              name='comment'
              placeholder={t('comment')}
              onChange={this.handleChange}
              value={this.state.comment}
              maxLength={2000}
            />
          </Form.Item>
          {/* {isConfOrAllCourse(this.props.topic.type.id) && (
              <Form.Item label={t('speakers')} labelCol={{ span: 4 }}>
                <Select
                  showSearch
                  allowClear
                  onSearch={this.searchSpeakers}
                  optionFilterProp='children'
                  onChange={value => this.changeSpeakers(value)}
                  value={this.state.speakerIds}
                  placeholder={t('notAssigned')}
                  loading={this.props.isLoadingSpeakers}
                  labelInValue
                  mode='multiple'
                >
                  {renderSpeakersOption(this.props.speakers)}
                </Select>
              </Form.Item> !!!!! Поле убрано с задачей KAM-4435 !!!!!!!
            )} */}
          <Row>
            <Col span={11}>
              <Form.Item label='' labelCol={{ span: 4 }}>
                <Checkbox
                  className={css['toOutlookCheckbox']}
                  name='sendToOutlook'
                  checked={this.state.sendToOutlook}
                  onChange={event => this.handleSendToOutlookChange({ value: event.target.checked })}
                >
                  {t('send')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item
                label={t('deadLineWithOutPenalty')}
                labelCol={{ span: 14 }}
                className={css['formItem-ml']}
                validateStatus={this.state.errors.deadlineCancellation ? 'error' : ''}
                help={this.state.errors.deadlineCancellation}
                labelAlign='left'
              >
                <DatePicker
                  className={css['datePicker']}
                  format='YYYY-MM-DD'
                  value={this.state.deadlineCancellation ? dayjs(this.state.deadlineCancellation) : null}
                  onChange={(_, value) =>
                    this.handleChange({
                      value: value ? dayjs(value).format('YYYY-MM-DD') : '',
                      name: 'deadlineCancellation',
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item label='' labelCol={{ span: 4 }}>
                <Checkbox
                  className={css['toOutlookCheckbox']}
                  name='noUserConfirmation'
                  checked={this.state.noUserConfirmation}
                  onChange={event => this.setNoUserConfirmation({ value: event.target.checked })}
                >
                  {t('dontAskForConfirmation')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item
                className={css['formItem-ml']}
                label={t('cancellationPenalty')}
                labelCol={{ span: 14 }}
                validateStatus={this.state.errors.cancellationPenalty ? 'error' : ''}
                help={this.state.errors.cancellationPenalty}
                labelAlign='left'
              >
                <CurrencyInput
                  name='cancellationPenalty'
                  value={this.state.cancellationPenalty}
                  onChange={this.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {period}
        </Form>
        <ConfirmationModal
          open={this.state.confirmVisible}
          onOk={this.props.onCancel}
          onCancel={this.hideConfirmDialog}
          okText={t('close')}
          confirmationText={t('closeWithout')}
        />
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([studentSelect, userSelect], (student, users) => ({
  currentUser: users.currentUser,
  eventStartTime:
    (users.currentUser && users.currentUser.domainCompany && users.currentUser.domainCompany.eventStartTime) || '',
  eventEndTime:
    (users.currentUser && users.currentUser.domainCompany && users.currentUser.domainCompany.eventEndTime) || '',
  fullname: student.fullname || [],
  isLoadingFullname: student.isLoadingFullname,
  speakers: student.students,
  isLoadingSpeakers: student.isLoading,
}));

const mapActionsToProps = {
  getStudentsList,
  clearFullname,
  getStudents,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('createEventModal')(CreateEventModal));
