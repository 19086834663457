import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getPokaDirCategory } from '@/store/poka_categories/actions';
import { clearStudents, getStudents } from '@/store/students/actions';

import { selectPokaApprovers } from '@/store/poka_approvers/selectors';
import { selectPokaCategories } from '@/store/poka_categories/selectors';
import { selectPokaSites } from '@/store/poka_sites/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd';

import { DELETE, EDIT } from '../constants';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../ApproversDirectory.module.scss';

const ModalApproversDirectory = ({ open, onCancel, title, onOk, approverData, onOkText }) => {
  const { t } = useTranslation('ApproversDirectory');
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { editingPokaApprover } = useSelector(selectPokaApprovers);
  const { students, isLoadingStudents } = useSelector(selectStudents);
  const { pokaCategories, loadingPokaCategories } = useSelector(selectPokaCategories);
  const { pokaSites, loadingPokaSites } = useSelector(selectPokaSites);

  const handleSubmit = data => {
    const prepareArray = value => (Array.isArray(value) ? value : [value]);
    const preparedData = {
      userId: typeof data.userId.value === 'number' ? data.userId.value : Number(data.userId.key),
      categories:
        typeof data.categories[0] === 'object' ? data.categories.map(category => category.value) : data.categories,
      sites: prepareArray(data.sites),
    };
    onOk(preparedData);
  };

  const handleSearchStudents = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudents({ fullName: e, size: 100 }));
    }
  };

  const getStudentsDebounced = useCallback(_.debounce(handleSearchStudents, 1000), []);

  const fullNameOptions = useMemo(
    () =>
      students &&
      students?.length &&
      students.map(item => {
        let username = `${item?.lastName || ''} ${item?.firstName || ''} ${item?.middleName || ''}`;
        return (
          <Select.Option value={username} key={item.id}>
            {username}
          </Select.Option>
        );
      }),
    [students]
  );

  const renderOptions = arr => {
    let opt =
      Array.isArray(arr) &&
      arr?.length &&
      arr.map(option => {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      });

    return opt;
  };

  const selectsData = [
    {
      label: t('fullname'),
      name: 'userId',
      placeholder: t('selectFullname'),
      mode: undefined,
      options: fullNameOptions,
      loading: isLoadingStudents,
      showSearch: true,
      onSearch: getStudentsDebounced,
      labelInValue: true,
    },
    {
      label: t('selectCategory'),
      name: 'categories',
      placeholder: t('placeholderSelectCategory'),
      mode: 'multiple',
      options: renderOptions(pokaCategories),
      loading: loadingPokaCategories,
    },
    {
      label: t('selectSite'),
      name: 'sites',
      placeholder: t('placeholderSelect'),
      mode: 'multiple',
      options: renderOptions(pokaSites),
      loading: loadingPokaSites,
    },
  ];

  useEffect(() => {
    if (approverData?.mode === EDIT && editingPokaApprover && Object.keys(editingPokaApprover)?.length > 0) {
      if (!pokaCategories?.length) dispatch(getPokaDirCategory());
      form.setFieldsValue({
        userId: {
          label: editingPokaApprover.fullName,
          value: editingPokaApprover.userId,
        },
        categories: editingPokaApprover.category,
        sites: editingPokaApprover.siteId,
      });
    }
  }, [open, editingPokaApprover]);

  useEffect(() => {
    return () => dispatch(clearStudents());
  }, [dispatch]);

  const renderFilters = item => {
    return (
      <Form.Item
        key={item.label}
        label={item.label}
        colon={false}
        name={item.name}
        rules={[{ required: true, message: t('required') }]}
      >
        <Select
          mode={item.mode}
          showSearch
          allowClear
          onSearch={item.onSearch}
          onChange={item.onChange}
          value={item.value}
          placeholder={item.placeholder}
          optionFilterProp='children'
          loading={item.loading}
          notFoundContent={item.loading ? <Spin size='small' /> : t('nothingFound')}
          labelInValue={item.labelInValue || false}
        >
          {item.options && item.options}
        </Select>
      </Form.Item>
    );
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={<div className={css['Modal-title']}>{title}</div>}
      footer={null}
      className={css['Modal']}
      centered
      width='800px'
    >
      {approverData?.mode === DELETE ? (
        // Контент для удаления
        <Col>
          <Row className={css['Modal-deleteText']}>{t('deleteWarning')}</Row>
          <Row justify='end' gutter={22}>
            <Col>
              <Button onClick={onCancel}>{t('cancel')}</Button>{' '}
            </Col>
            <Col>
              <Button type='primary' onClick={onOk}>
                {onOkText}
              </Button>
            </Col>
          </Row>
        </Col>
      ) : (
        // Форма для создания или редактирования
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          <Row gutter={16}>
            {selectsData.map((item, index) => (
              <Col span={24} key={index}>
                {renderFilters(item)}
              </Col>
            ))}
          </Row>

          <Row justify='end' gutter={22}>
            <Col>
              <Button onClick={onCancel}>{t('cancel')}</Button>
            </Col>
            <Col>
              <Button htmlType='submit' type='primary'>
                {onOkText}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

ModalApproversDirectory.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  approverData: PropTypes.object,
  onOkText: PropTypes.string,
};

export default ModalApproversDirectory;
