// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Inspector-style-module__ql-picker-Lqx0Lk.admin-src-pages-Inspector-style-module__ql-font-AoU2EN .admin-src-pages-Inspector-style-module__ql-picker-label-jKEC8S[data-value="Montserrat"]:before, .admin-src-pages-Inspector-style-module__ql-picker-Lqx0Lk.admin-src-pages-Inspector-style-module__ql-font-AoU2EN .admin-src-pages-Inspector-style-module__ql-picker-item-ElghR0[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Inspector-style-module__ql-font-Montserrat-yzWzh9 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Inspector-style-module__inspector__link-GSj3LD {
  color: var(--personalColor);
  cursor: pointer;
}

.admin-src-pages-Inspector-style-module__sort-LSGi_8 {
  align-items: center;
  gap: 4px;
  padding-bottom: 20px;
  display: flex;
}

.admin-src-pages-Inspector-style-module__sort__caption-w27TAF {
  color: #6c737d;
  margin-right: 8px;
}

.admin-src-pages-Inspector-style-module__sort__caption-w27TAF, .admin-src-pages-Inspector-style-module__sort__element-TesDMN {
  cursor: pointer;
  font-weight: 500;
}

.admin-src-pages-Inspector-style-module__sort__caption--active-x6kbzX, .admin-src-pages-Inspector-style-module__sort__element--active-POl7Ps {
  color: var(--personalColor);
}

.admin-src-pages-Inspector-style-module__sort__caption-caption-HANdv9, .admin-src-pages-Inspector-style-module__sort__element-caption-aWHnxD {
  margin-right: 4px;
  font-weight: 500;
}

.admin-src-pages-Inspector-style-module__sort__vertical-line-dINOU3 {
  background-color: #000;
  width: 1px;
  height: 10px;
  margin: 0 8px;
}

.admin-src-pages-Inspector-style-module__icon-FI0hCx {
  font-size: 24px;
}

.admin-src-pages-Inspector-style-module__icon--WAITING-dLuvLS {
  color: orange !important;
}

.admin-src-pages-Inspector-style-module__icon--CHECKED-o_erBx {
  color: green !important;
}

.admin-src-pages-Inspector-style-module__icon--NOT_WAITING-VGhwEJ {
  color: #00f !important;
}

.admin-src-pages-Inspector-style-module__icon--small-f3ecm9 {
  margin-right: 4px;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Inspector/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,2BAA2B;EAC3B,eAAe;AACjB;;AAOA;EAJE,mBAAmB;EACnB,QAAQ;EACR,oBAAoB;EACpB,aAAa;AACf;;AAMA;EAHE,cAAc;EACd,iBAAiB;AACnB;;AAKA;EAFE,eAAe;EACf,gBAAgB;AAClB;;AAIA;EADE,2BAA2B;AAC7B;;AAGA;EAAE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AACA;EAEE,wBAAwB;AAC1B;;AAAA;EAGE,uBAAuB;AACzB;;AADA;EAIE,sBAAsB;AACxB;;AAFA;EAKE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.inspector__link {\n  color: var(--personalColor);\n  cursor: pointer;\n}\n\n.sort {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  padding-bottom: 20px;\n}\n.sort__caption {\n  margin-right: 8px;\n  color: #6c737d;\n}\n.sort__caption, .sort__element {\n  font-weight: 500;\n  cursor: pointer;\n}\n.sort__caption--active, .sort__element--active {\n  color: var(--personalColor);\n}\n.sort__caption-caption, .sort__element-caption {\n  margin-right: 4px;\n  font-weight: 500;\n}\n.sort__vertical-line {\n  width: 1px;\n  height: 10px;\n  margin: 0 8px;\n  background-color: #000;\n}\n\n.icon {\n  font-size: 24px;\n}\n.icon--WAITING {\n  color: orange !important;\n}\n.icon--CHECKED {\n  color: green !important;\n}\n.icon--NOT_WAITING {\n  color: blue !important;\n}\n.icon--small {\n  font-size: 12px;\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Inspector-style-module__ql-picker-Lqx0Lk`,
	"qlPicker": `admin-src-pages-Inspector-style-module__ql-picker-Lqx0Lk`,
	"ql-font": `admin-src-pages-Inspector-style-module__ql-font-AoU2EN`,
	"qlFont": `admin-src-pages-Inspector-style-module__ql-font-AoU2EN`,
	"ql-picker-label": `admin-src-pages-Inspector-style-module__ql-picker-label-jKEC8S`,
	"qlPickerLabel": `admin-src-pages-Inspector-style-module__ql-picker-label-jKEC8S`,
	"ql-picker-item": `admin-src-pages-Inspector-style-module__ql-picker-item-ElghR0`,
	"qlPickerItem": `admin-src-pages-Inspector-style-module__ql-picker-item-ElghR0`,
	"ql-font-Montserrat": `admin-src-pages-Inspector-style-module__ql-font-Montserrat-yzWzh9`,
	"qlFontMontserrat": `admin-src-pages-Inspector-style-module__ql-font-Montserrat-yzWzh9`,
	"inspector__link": `admin-src-pages-Inspector-style-module__inspector__link-GSj3LD`,
	"inspectorLink": `admin-src-pages-Inspector-style-module__inspector__link-GSj3LD`,
	"sort": `admin-src-pages-Inspector-style-module__sort-LSGi_8`,
	"sort__caption": `admin-src-pages-Inspector-style-module__sort__caption-w27TAF`,
	"sortCaption": `admin-src-pages-Inspector-style-module__sort__caption-w27TAF`,
	"sort__element": `admin-src-pages-Inspector-style-module__sort__element-TesDMN`,
	"sortElement": `admin-src-pages-Inspector-style-module__sort__element-TesDMN`,
	"sort__caption--active": `admin-src-pages-Inspector-style-module__sort__caption--active-x6kbzX`,
	"sortCaptionActive": `admin-src-pages-Inspector-style-module__sort__caption--active-x6kbzX`,
	"sort__element--active": `admin-src-pages-Inspector-style-module__sort__element--active-POl7Ps`,
	"sortElementActive": `admin-src-pages-Inspector-style-module__sort__element--active-POl7Ps`,
	"sort__caption-caption": `admin-src-pages-Inspector-style-module__sort__caption-caption-HANdv9`,
	"sortCaptionCaption": `admin-src-pages-Inspector-style-module__sort__caption-caption-HANdv9`,
	"sort__element-caption": `admin-src-pages-Inspector-style-module__sort__element-caption-aWHnxD`,
	"sortElementCaption": `admin-src-pages-Inspector-style-module__sort__element-caption-aWHnxD`,
	"sort__vertical-line": `admin-src-pages-Inspector-style-module__sort__vertical-line-dINOU3`,
	"sortVerticalLine": `admin-src-pages-Inspector-style-module__sort__vertical-line-dINOU3`,
	"icon": `admin-src-pages-Inspector-style-module__icon-FI0hCx`,
	"icon--WAITING": `admin-src-pages-Inspector-style-module__icon--WAITING-dLuvLS`,
	"iconWaiting": `admin-src-pages-Inspector-style-module__icon--WAITING-dLuvLS`,
	"icon--CHECKED": `admin-src-pages-Inspector-style-module__icon--CHECKED-o_erBx`,
	"iconChecked": `admin-src-pages-Inspector-style-module__icon--CHECKED-o_erBx`,
	"icon--NOT_WAITING": `admin-src-pages-Inspector-style-module__icon--NOT_WAITING-VGhwEJ`,
	"iconNotWaiting": `admin-src-pages-Inspector-style-module__icon--NOT_WAITING-VGhwEJ`,
	"icon--small": `admin-src-pages-Inspector-style-module__icon--small-f3ecm9`,
	"iconSmall": `admin-src-pages-Inspector-style-module__icon--small-f3ecm9`
};
export default ___CSS_LOADER_EXPORT___;
