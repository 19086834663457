import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { getCompetence } from '@/store/competence/actions';
import { getIprGoalCategory, getIprSettings, getIprStatus, postIprFilters } from '@/store/ipr/actions';
import { getDepartment, getFunction, getPosition } from '@/store/students/actions';

import { Button, Layout, Tooltip, Tour, Typography } from 'antd';
import { Content } from '@/components/Layout';
import { InfoCircleTwoTone } from '@ant-design/icons';

import Filters from './Content/Filters';
import ModalSettingsIpr from './Content/Modal/ModalSettingsIpr';
import TableCareer from './Content/TableCareer';

import css from './Career.module.scss';

const { Title } = Typography;

const Career = () => {
  const { t } = useTranslation('career');
  const dispatch = useDispatch();

  const [isSettingsIpr, setIsSettingsIpr] = useState(false);
  const [openTour, setOpenTour] = useState(false);

  const refSettings = useRef(null);
  const refFilters = useRef(null);
  const refExport = useRef(null);
  const refTable = useRef(null);
  const refActions = useRef(null);

  const steps = [
    {
      title: t('infoTourTitle'),
      description: t('infoTourText'),
    },
    {
      title: t('settingsTitle'),
      description: t('settingsText'),
      target: () => refSettings.current,
      placement: 'left',
    },
    {
      title: t('filtersTitle'),
      description: t('filtersText'),
      target: () => refFilters.current,
    },
    {
      title: t('exportTitle'),
      description: t('exportText'),
      target: () => refExport.current,
      placement: 'left',
    },
    {
      title: t('tableTitle'),
      description: t('tableText'),
      target: () => refTable.current,
    },
    {
      title: t('actionsTitle'),
      description: t('actionsText'),
      target: () => refActions.current,
      placement: 'left',
    },
  ];

  const setIpr = () => {
    dispatch(getIprSettings());
    setIsSettingsIpr(true);
  };

  useEffect(() => {
    dispatch(getIprGoalCategory());
    dispatch(getPosition());
    dispatch(getSubcompanies());
    dispatch(getFunction());
    dispatch(getDepartment());
    dispatch(getIprStatus());
    dispatch(getCompetence('', 0, true));
    dispatch(postIprFilters({}));
  }, [dispatch]);

  return (
    <Layout>
      <Content>
        <div className={css['Career']}>
          <div className={css['Career-header']}>
            <div className={css['Career-header-txt']}>
              <Title>{t('title')}</Title>
              <Tooltip title={t('infoTourCareer')}>
                <InfoCircleTwoTone className={css['Career-header-txt-info']} onClick={() => setOpenTour(true)} />
              </Tooltip>
            </div>
            <Button type='primary' onClick={setIpr} size='large' ref={refSettings}>
              {t('settingsIpr')}
            </Button>
          </div>

          <div ref={refFilters}>
            <Filters />
          </div>

          <TableCareer refExport={refExport} refTable={refTable} refActions={refActions} />
        </div>

        <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />

        {isSettingsIpr && <ModalSettingsIpr open={isSettingsIpr} onCancel={() => setIsSettingsIpr(false)} />}
      </Content>
    </Layout>
  );
};

export default Career;
