/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createAuthor, deleteAuthor, editAuthor, getAuthors, getAuthorsGlobal } from '@/store/authors/actions';

import { selectAuthors } from '@/store/authors/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import AuthorsTable from './AuthorsTable';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

export class AuthorsDirectory extends Component {
  static propTypes = {
    authors: PropTypes.array,
    authorsGlobal: PropTypes.object,
    authorsPageNumber: PropTypes.number,
    authorsPageSize: PropTypes.number,
    authorsTotal: PropTypes.number,
    createAuthor: PropTypes.func,
    creationError: PropTypes.bool,
    deleteAuthor: PropTypes.func,
    editAuthor: PropTypes.func,
    editingError: PropTypes.bool,
    getAuthors: PropTypes.func,
    getAuthorsGlobal: PropTypes.func,
    isLoading: PropTypes.bool,
    isGlobal: PropTypes.bool,
    currentUser: PropTypes.object,
  };

  render() {
    return (
      <>
        <AuthorsTable {...this.props} />
        {!this.props.isGlobal && (
          <AuthorsTable
            {...this.props}
            {...this.props.authorsGlobal}
            authors={this.props.authorsGlobal.authors || []}
            getAuthors={this.props.getAuthorsGlobal}
            shared={true}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createSelector([selectAuthors, usersSelect], (authors, users) => ({
  authors: authors.authors || [],
  authorsGlobal: authors.authorsGlobal,
  authorsTotal: authors.authorsTotal,
  authorsPageSize: authors.authorsPageSize,
  authorsPageNumber: authors.authorsPageNumber || 0,
  creationError: authors.creationError || false,
  editingError: authors.editingError || false,
  isLoading: authors.isLoading,
  isGlobal: users?.currentUser.domainCompany.global,
  currentUser: users?.currentUser,
}));

const mapActionsToProps = {
  getAuthors,
  getAuthorsGlobal,
  createAuthor,
  editAuthor,
  deleteAuthor,
};

export default connect(mapStateToProps, mapActionsToProps)(AuthorsDirectory);
