import { PixelCrop } from 'react-image-crop';

export async function canvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) {
  if (!canvas) return;

  const ctx = canvas?.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  if (!image) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = 0;
    canvas.height = 0;
    return;
  }

  canvas.width = crop.squeezeWidth || crop.width;
  canvas.height = crop.squeezeHeigth || crop.height;

  ctx.imageSmoothingQuality = 'high';

  ctx.save();

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.squeezeWidth || crop.width,
    crop.squeezeHeigth || crop.height
  );
  ctx.restore();
}
