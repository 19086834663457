import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

import { GET_POKA_SITES_FAIL, GET_POKA_SITES_START, GET_POKA_SITES_SUCCESS } from '@/store/poka_sites/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getPokaDirSite = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/site`,
  headers,
  types: [GET_POKA_SITES_START, GET_POKA_SITES_SUCCESS, GET_POKA_SITES_FAIL],
});
