/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Card } from '../Layout';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import css from './CompanyCard.module.scss';

import noCoverImg from '@/assets/images/company_logo_holder.svg';
import integrations from '@/assets/images/integration.svg';
import pencil from '@/assets/images/pencil.svg';

const CompanyCard = ({ company, onEdit, onEditIntegrations }) => {
  const [coverUrl] = useLocalFileByUUID(company.logo, { defaultImg: noCoverImg });

  return (
    <Card className={css.CompanyCard}>
      <div className={css.CompanyCard__content}>
        <div className={css.CompanyCard__cover}>
          <img src={coverUrl} alt='' />
        </div>
        <div className={css.CompanyCard__container}>
          <div className={css.CompanyCard__name}>{company.shortName}</div>
          <div className={css.CompanyCard__description}>{company.fullName}</div>
        </div>
      </div>
      <div className={css.CompanyCard__edit} onClick={onEditIntegrations}>
        <img src={integrations} alt='' />
      </div>
      <div className={css.CompanyCard__edit} onClick={onEdit}>
        <img src={pencil} alt='' />
      </div>
    </Card>
  );
};

const mapActionsToProps = {
  getFile,
};

export default connect(null, mapActionsToProps)(CompanyCard);
