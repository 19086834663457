/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form, Input, Modal } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const MIN_NUMBER = 2;
const MAX_NUMBER = 500;

class SkillsCreationDialog extends Component {
  static propTypes = {
    descriptionText: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    okText: PropTypes.string,
    onOk: PropTypes.func,
    skills: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      skills: props.skills || '',
      dirty: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    let skillsError = '';

    let newValue = '';

    if (value !== ' ') {
      newValue = value;
    }
    if (value.includes(';')) {
      skillsError = this.props.t('invalidCharacter');
    }
    if (newValue.length < MIN_NUMBER) {
      skillsError = this.props.minLength;
    }
    if (newValue.length > MAX_NUMBER) {
      skillsError = this.props.maxLength;
    }
    newValue = newValue.replace(/\s+/g, ' ');
    this.setState({ [name]: newValue, skillsError, dirty: false });
  };

  handleSubmit = () => {
    const { skills, skillsError } = this.state;
    if (skillsError) {
      return;
    }
    if (skills.trim().length >= MIN_NUMBER) {
      this.setState({
        skillsError: '',
        dirty: true,
      });
      this.props.onOk(skills.trim());
    } else {
      this.setState({ skillsError: this.props.minLength, skills: skills.trim() });
    }
  };

  render() {
    const { descriptionText, okText, error, errorText, ...props } = this.props;
    const disabled =
      this.state.skillsError ||
      (error && this.state.dirty) ||
      (this.state.skills && this.state.skills.length < MIN_NUMBER) ||
      (this.state.skills && this.state.skills.length > MAX_NUMBER)
        ? 'error'
        : '';

    return (
      <Modal {...props} footer={null}>
        <p>{descriptionText}</p>
        <Form>
          <Form.Item
            className={classNames({ error: this.state.skillsError || error })}
            help={
              this.state.skillsError ||
              (this.state.skills && this.state.skills.length < MIN_NUMBER ? this.props.minLength : '') ||
              (this.state.skills && this.state.skills.length > MAX_NUMBER ? this.props.maxLength : '') ||
              (error && this.state.dirty ? errorText : '')
            }
            validateStatus={disabled}
          >
            <Input
              name='skills'
              dataqa='skillInput'
              value={this.state.skills}
              onChange={this.handleChange}
              maxLength={MAX_NUMBER + 1}
            />
          </Form.Item>
          <Button type='primary' onClick={this.handleSubmit} data-qa='submitBtn' htmlType='submit' disabled={disabled}>
            {okText}
          </Button>
        </Form>
      </Modal>
    );
  }
}

export default withTranslation(['tagsTable'])(SkillsCreationDialog);
