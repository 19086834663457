export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_INACTIVE = 'INACTIVE';

export const IPR = {
  APPROVED_BY_USER: 'APPROVED_BY_USER', // Требует согласования
  APPROVED_BY_MANAGER: 'APPROVED_BY_MANAGER', // Согласован руководителем
  APPROVED_BY_ADMIN: 'APPROVED_BY_ADMIN', // Согласован администратором
  REJECTED: 'REJECTED', // Отправлен на доработку
  FINISHED: 'FINISHED', // Завершен
};

export const TRACK = {
  IN_PROGRESS: 'IN_PROGRESS', // В процессе
  EXPIRED: 'EXPIRED', // Просрочен
  FINISHED: 'FINISHED', // Завершён
};
