import React /*useMemo,*/, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearBadgeInfo, createBadge, getBadgeId, getBadges, updateBadgeId } from '@/store/badge/actions';
import { createFileImage, getFile } from '@/store/files/actions';

import { selectBadge } from '@/store/badge/selectors';
import { selectFiles } from '@/store/files/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Alert /*Select,*/, Button, Form, Input, Modal, Spin } from 'antd';
import { TOPIC_FINISH } from '@/pages/Badge/constants';
import CoverImage from '@/components/CoverImage/CoverImage';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ModalBadge.module.scss';

const { TextArea } = Input;
// const { Option } = Select;

const ModalBadge = ({ open, isCreate, isEdit, onCancel, badgePhotoUuid, clearBadgeId, isBadgeDetail }) => {
  const { t } = useTranslation('badgeModal');
  const dispatch = useDispatch();

  const { badgeId, isLoadingBadgeId, badgeFilters, badgeCurrentPage /*, actionsBadge*/, badgeSort } =
    useSelector(selectBadge);
  const { isLoadingImage } = useSelector(selectFiles);
  const { currentUser } = useSelector(selectUsers);

  const [data, setData] = useState({
    id: null,
    name: '',
    description: '',
    imageUuid: '',
    authorId: null,
    author: '',
    action: TOPIC_FINISH,
  });

  const [fileBlob, setFileBlob] = useState(undefined);
  const [isLoadingPreviwImage, setLoadingPreviwImage] = useState(false);
  const [previewImg, setPreviewImg] = useState('');

  const [errors, setErrors] = useState({
    name: false,
    image: false,
    description: false,
    // action: false,
  });

  useEffect(() => {
    return () => {
      if (clearBadgeId) {
        dispatch(clearBadgeInfo()); // чистим в сторе badgeId
      }
    };
  }, []);

  useEffect(() => {
    if (isEdit && badgeId && Object.keys(badgeId).length > 0) {
      setData({
        ...data,
        id: badgeId.id,
        name: badgeId.name,
        description: badgeId.description,
        imageUuid: badgeId.imageUuid, // badgePhotoUuid
        authorId: badgeId.authorDto?.userId,
        author: badgeId.authorDto?.fullName,
        action: TOPIC_FINISH,
      });
    }
  }, [badgeId]);

  useEffect(() => {
    if (isEdit && badgePhotoUuid && !previewImg) {
      setLoadingPreviwImage(true);
      dispatch(
        getFile(badgePhotoUuid, blob => {
          const imageUrl = URL.createObjectURL(blob);
          setPreviewImg(imageUrl);
          setLoadingPreviwImage(false);
        })
      );
    }
  }, []);

  // const optActionsBadge = useMemo(() => {
  //   return (
  //     actionsBadge?.length &&
  //     actionsBadge.map(item => (
  //       <Option value={item.value} key={item.id}>
  //         {item.name}
  //       </Option>
  //     ))
  //   );
  // }, [actionsBadge]);

  const updateImage = blob => {
    setFileBlob(blob);
    setPreviewImg(window.URL.createObjectURL(blob));
  };

  const pushError = (name, status) => {
    setErrors({ ...errors, [name]: status });
  };

  const onDeleteBlob = () => {
    setFileBlob(undefined);
    setPreviewImg(undefined);
    setData({ ...data, imageUuid: '' });
  };

  const checkErrors = () => {
    if (!data.name || !data.description || !data.action || !previewImg) {
      setErrors({
        name: !data.name,
        image: !previewImg,
        description: !data.description,
        action: !data.action,
      });
      return false;
    }

    return true;
  };

  const templateCallback = () => {
    if (isBadgeDetail && badgeId?.id) {
      dispatch(getBadgeId(badgeId.id));
    } else {
      dispatch(getBadges(badgeFilters.filters ? badgeFilters.filters : {}, badgeCurrentPage, badgeSort));
    }
    onCancel();
  };

  const onCreateBadge = () => {
    if (!checkErrors()) return;

    const formData = new FormData();
    formData.append('file', fileBlob);

    const sendData = {
      name: data.name.trim(),
      description: data.description.trim(),
      imageUuid: data.imageUuid,
      action: data.action,
    };

    if (fileBlob) {
      dispatch(
        createFileImage(formData, res => {
          dispatch(createBadge({ ...sendData, imageUuid: res.uuid }, () => templateCallback()));
        })
      );
    }
  };

  const onUpdateBadge = () => {
    if (!checkErrors()) return;

    const formData = new FormData();
    formData.append('file', fileBlob);

    const sendData = {
      id: data.id,
      name: data.name.trim(),
      description: data.description.trim(),
      imageUuid: data.imageUuid,
      action: data.action,
    };

    if (fileBlob) {
      dispatch(
        createFileImage(formData, res => {
          dispatch(updateBadgeId(data.id, { ...sendData, imageUuid: res.uuid }, () => templateCallback()));
        })
      );
    } else {
      dispatch(updateBadgeId(data.id, sendData, () => templateCallback()));
    }
  };

  const footerModal = (
    <>
      <Button onClick={onCancel}>{t('cancel')}</Button>

      <Button type='primary' onClick={isCreate ? onCreateBadge : onUpdateBadge}>
        {isCreate ? t('create') : t('update')}
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      title={isCreate ? t('createTitle') : t('editTitle')}
      onCancel={onCancel}
      className={css['ModalBadge']}
      width={600}
      footer={!isLoadingImage && !isLoadingBadgeId && footerModal}
    >
      <Spin size='large' className={css['ModalBadge-spin']} spinning={(isLoadingBadgeId && isEdit) || isLoadingImage}>
        <Form className={css['ModalBadge-form']} layout='vertical'>
          {/* Название */}
          <Form.Item
            label={t('nameBadge')}
            colon={false}
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name && t('notNameBadge')}
            required
          >
            <Input
              size='large'
              onChange={e => setData({ ...data, name: e.target.value })}
              value={data.name}
              placeholder={t('placeholderBadge')}
              maxLength={50}
            />
          </Form.Item>

          {/* Автор */}
          {isEdit && (
            <Form.Item label={t('authorBadgeText')} colon={false}>
              <a
                rel='noreferrer'
                href={`https://${currentUser?.domainCompany?.domain}/collegues/${data.authorId}/info`}
                target='_blank'
              >
                {data.author}
              </a>
            </Form.Item>
          )}

          {/* Описание */}
          <Form.Item
            label={t('descBadge')}
            colon={false}
            validateStatus={errors.description ? 'error' : ''}
            help={errors.description && t('notDescBadge')}
            required
          >
            <TextArea
              rows={5}
              onChange={e => setData({ ...data, description: e.target.value })}
              value={data.description}
              placeholder={t('placeholderDescBadge')}
              maxLength={300}
            />
          </Form.Item>

          {/* Изображение */}
          <Form.Item
            label={t('imageBadge')}
            colon={false}
            validateStatus={errors.image ? 'error' : ''}
            help={errors.image && t('notPhotoBadge')}
            required
          >
            <div className={classNames(errors.image && css['ModalBadge-form-photoError'])}>
              <Alert
                message={t('infoUploadImageBadge')}
                className={css['ModalBadge-form-alert']}
                type='info'
                closable
              />
              <Spin spinning={!isLoadingBadgeId && isLoadingPreviwImage}>
                <CoverImage
                  updateImage={updateImage}
                  pushError={pushError}
                  typeUpload='image/png, image/jpeg, image/jpg'
                  errorSizeMessage={t('errorSizeMessageBadge')}
                  changeImageText={t('changeImageBadge')}
                  clearFileBlob={onDeleteBlob}
                  fileBlob={fileBlob || previewImg}
                  canDelete
                  squeeze={{ squeezeWidth: 100, squeezeHeigth: 100 }}
                  errorMaxParamsMessage={t('errorUploadBadge')}
                  aspect={1}
                  maxSizeInfo={t('maxSizeBadgeImage')}
                />
                <div>
                  {!isLoadingPreviwImage && (
                    <img
                      className={`${css['ModalBadge-form-upload-img']} ${previewImg && css['show']}`}
                      src={previewImg}
                      alt={t('infoPreviewImage')}
                    />
                  )}
                </div>
              </Spin>
            </div>
          </Form.Item>

          {/* ACTIONS */}
          {/* на mvp версию этого не будет, всегда будет один экшен TOPIC_FINISH */}
          {/* {isCreate && (
            <Form.Item
              label={t('actionBadge')}
              colon={false}
              validateStatus={errors.action ? 'error' : ''}
              help={errors.action && t('notActionBadge')}
              required={isCreate}
            >
              <Select
                size='large'
                allowClear
                showSearch
                optionFilterProp='children'
                onChange={e => setData({ ...data, action: e })}
                value={data.action}
                placeholder={t('placeholderActions')}
              >
                {optActionsBadge}
              </Select>
            </Form.Item>
          )} */}
        </Form>
      </Spin>
    </Modal>
  );
};

ModalBadge.propTypes = {
  open: PropTypes.bool,
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  badgePhotoUuid: PropTypes.string,
  clearBadgeId: PropTypes.bool,
  isBadgeDetail: PropTypes.bool,
};

export default ModalBadge;
