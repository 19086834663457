/* eslint-disable */
import React, { Component } from 'react';

import EventUser from './EventUser';

import PropTypes from 'prop-types';

import css from './EventUserList.module.scss';

export default class EventUserList extends Component {
  static propTypes = {
    courseRequestFinish: PropTypes.func,
    coursesFilter: PropTypes.object,
    coursesPageNumber: PropTypes.number,
    deleteUserEventHandler: PropTypes.func,
    filterYear: PropTypes.string,
    getCoursesRequestTopic: PropTypes.func,
    getEventTopic: PropTypes.func,
    getFile: PropTypes.func,
    index: PropTypes.number,
    isEnded: PropTypes.bool,
    isLoading: PropTypes.bool,
    requests: PropTypes.array,
    updateCoursesRequest: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
    };
  }

  render() {
    const userRow =
      this.props.requests &&
      this.props.requests.map((user, item) => {
        return (
          <EventUser
            key={user && user.id}
            getFile={this.props.getFile}
            isLoading={this.props.isLoading}
            item={item}
            user={user}
            courseRequestFinish={this.props.courseRequestFinish}
            filterYear={this.props.filterYear}
            deleteUserEventHandler={this.props.deleteUserEventHandler}
            isEnded={this.props.isEnded}
            getEventTopic={this.props.getEventTopic}
            coursesFilter={this.props.coursesFilter}
            coursesPageNumber={this.props.coursesPageNumber}
            getCoursesRequestTopic={this.props.getCoursesRequestTopic}
            updateCoursesRequest={this.props.updateCoursesRequest}
          />
        );
      });
    return (
      <table className={css['Course-table']}>
        <tbody className={css['Course-table-body']}>{userRow}</tbody>
      </table>
    );
  }
}
