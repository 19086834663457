/* eslint-disable */
import { API_URL, API_V2 } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_STUDENTS_START = 'GET_STUDENTS_START';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAIL = 'GET_STUDENTS_FAIL';

export const GET_STUDENTS_CREATEUSER_START = 'GET_STUDENTS_CREATEUSER_START';
export const GET_STUDENTS_CREATEUSER_SUCCESS = 'GET_STUDENTS_CREATEUSER_SUCCESS';
export const GET_STUDENTS_CREATEUSER_FAIL = 'GET_STUDENTS_CREATEUSER_FAIL';

export const GET_STUDENTS_STAT_START = 'GET_STUDENTS_STAT_START';
export const GET_STUDENTS_STAT_SUCCESS = 'GET_STUDENTS_STAT_SUCCESS';
export const GET_STUDENTS_STAT_FAIL = 'GET_STUDENTS_STAT_FAIL';

export const GET_FULLNAME_START = 'GET_FULLNAME_START';
export const GET_FULLNAME_SUCCESS = 'GET_FULLNAME_SUCCESS';
export const GET_FULLNAME_FAIL = 'GET_FULLNAME_FAIL';

export const GET_FUNCTION_START = 'GET_FUNCTION_START';
export const GET_FUNCTION_SUCCESS = 'GET_FUNCTION_SUCCESS';
export const GET_FUNCTION_FAIL = 'GET_FUNCTION_FAIL';

export const GET_LOCATION_START = 'GET_LOCATION_START';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';

export const GET_DEPARTMENT_START = 'GET_DEPARTMENT_START';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAIL = 'GET_DEPARTMENT_FAIL';

export const GET_POSITION_START = 'GET_POSITION_START';
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS';
export const GET_POSITION_FAIL = 'GET_POSITION_FAIL';

export const GET_STUDENTS_ALL_STAT_START = 'GET_STUDENTS_ALL_STAT_START';
export const GET_STUDENTS_ALL_STAT_SUCCESS = 'GET_STUDENTS_ALL_STAT_SUCCESS';
export const GET_STUDENTS_ALL_STAT_FAIL = 'GET_STUDENTS_ALL_STAT_FAIL';

export const PUT_MANAGERS_START = 'PUT_MANAGERS_START';
export const PUT_MANAGERS_SUCCESS = 'PUT_MANAGERS_SUCCESS';
export const PUT_MANAGERS_FAIL = 'PUT_MANAGERS_FAIL';

export const CHANGE_USERS_FILTER = 'CHANGE_USERS_FILTER';
export const PUT_PERMISSION_START = 'PUT_PERMISSION_START';
export const PUT_PERMISSION_SUCCESS = 'PUT_PERMISSION_SUCCESS';
export const PUT_PERMISSION_FAIL = 'PUT_PERMISSION_FAIL';

export const GET_MANAGER_FULLNAME_START = 'GET_MANAGER_FULLNAME_START';
export const GET_MANAGER_FULLNAME_SUCCESS = 'GET_MANAGER_FULLNAME_SUCCESS';
export const GET_MANAGER_FULLNAME_FAIL = 'GET_MANAGER_FULLNAME_FAIL';

export const GET_LDAP_STATUS_START = 'GET_LDAP_STATUS_START';
export const GET_LDAP_STATUS_SUCCESS = 'GET_LDAP_STATUS_SUCCESS';
export const GET_LDAP_STATUS_FAIL = 'GET_LDAP_STATUS_FAIL';

export const UPDATE_LDAP_STATUS_START = 'UPDATE_LDAP_STATUS_START';
export const UPDATE_LDAP_STATUS_SUCCESS = 'UPDATE_LDAP_STATUS_SUCCESS';
export const UPDATE_LDAP_STATUS_FAIL = 'UPDATE_LDAP_STATUS_FAIL';

export const TOGGLE_USER_LOCKED_START = 'TOGGLE_USER_LOCKED_START';
export const TOGGLE_USER_LOCKED_SUCCESS = 'TOGGLE_USER_LOCKED_SUCCESS';
export const TOGGLE_USER_LOCKED_FAIL = 'TOGGLE_USER_LOCKED_FAIL';

export const BLOCK_USER_START = 'BLOCK_USER_START';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';

export const TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_START = 'TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_START';
export const TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_SUCCESS = 'TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_SUCCESS';
export const TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_FAIL = 'TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_FAIL';

export const POST_MULTIPLE_USER_TOPICS_START = 'POST_MULTIPLE_USER_TOPICS_START';
export const POST_MULTIPLE_USER_TOPICS_SUCCESS = 'POST_MULTIPLE_USER_TOPICS_SUCCESS';
export const POST_MULTIPLE_USER_TOPICS_FAIL = 'POST_MULTIPLE_USER_TOPICS_FAIL';

export const CREATE_STUDY_PLAN_START = 'CREATE_STUDY_PLAN_START';
export const CREATE_STUDY_PLAN_SUCCESS = 'CREATE_STUDY_PLAN_SUCCESS';
export const CREATE_STUDY_PLAN_FAIL = 'CREATE_STUDY_PLAN_FAIL';

export const CREATE_STUDY_PLAN_MASS_START = 'CREATE_STUDY_PLAN_MASS_START';
export const CREATE_STUDY_PLAN_MASS_SUCCESS = 'CREATE_STUDY_PLAN_MASS_SUCCESS';
export const CREATE_STUDY_PLAN_MASS_FAIL = 'CREATE_STUDY_PLAN_MASS_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const CHANGE_USER_PASSWORD_START = 'CHANGE_USER_PASSWORD_START';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD_FAIL';

export const GET_STUDENTS_DELETED_START = 'GET_STUDENTS_DELETED_START';
export const GET_STUDENTS_DELETED_SUCCESS = 'GET_STUDENTS_DELETED_SUCCESS';
export const GET_STUDENTS_DELETED_FAIL = 'GET_STUDENTS_DELETED_FAIL';

export const TOGGLE_ALL_USERS_TRAJECTORY_START = 'TOGGLE_ALL_USERS_TRAJECTORY_START';
export const TOGGLE_ALL_USERS_TRAJECTORY_SUCCESS = 'TOGGLE_ALL_USERS_TRAJECTORY_SUCCESS';
export const TOGGLE_ALL_USERS_TRAJECTORY_FAIL = 'TOGGLE_ALL_USERS_TRAJECTORY_FAIL';

export const EXPORT_USER_START = 'EXPORT_USER_START';
export const EXPORT_USER_SUCCESS = 'EXPORT_USER_SUCCESS';
export const EXPORT_USER_FAIL = 'EXPORT_USER_FAIL';

export const GET_COUNT_SELECTED_USER_START = 'GET_COUNT_SELECTED_USER_START';
export const GET_COUNT_SELECTED_USER_SUCCESS = 'GET_COUNT_SELECTED_USER_SUCCESS';
export const GET_COUNT_SELECTED_USER_FAIL = 'GET_COUNT_SELECTED_USER_FAIL';

export const EXPORT_SELECTED_USER_IN_MASS_START = 'EXPORT_SELECTED_USER_IN_MASS_START';
export const EXPORT_SELECTED_USER_IN_MASS_SUCCESS = 'EXPORT_SELECTED_USER_IN_MASS_SUCCESS';
export const EXPORT_SELECTED_USER_IN_MASS_FAIL = 'EXPORT_SELECTED_USER_IN_MASS_FAIL';

export const GET_STRING_VALUE_IN_MASS_START = 'GET_STRING_VALUE_IN_MASS_START';
export const GET_STRING_VALUE_IN_MASS_SUCCESS = 'GET_STRING_VALUE_IN_MASS_SUCCESS';
export const GET_STRING_VALUE_IN_MASS_FAIL = 'GET_STRING_VALUE_IN_MASS_FAIL';

export const CLEAR_STUDENTS_STORE = 'CLEAR_STUDENTS_STORE';

export const CLEAR_STUDENTS_CREATEUSER_STORE = 'CLEAR_STUDENTS_CREATEUSER_STORE';

export const SET_MANAGER_FULLNAME = 'SET_MANAGER_FULLNAME';

export const CLEAR_MANAGER_FULLNAME = 'CLEAR_MANAGER_FULLNAME';

export const CLEAR_FULLNAME = 'CLEAR_FULLNAME';

export const SET_EMPLOYEE_FULLNAME = 'SET_EMPLOYEE_FULLNAME';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const createUser = (data, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/register/any`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  types: [CREATE_USER_START, CREATE_USER_SUCCESS, CREATE_USER_FAIL],
  onSuccess,
  onError,
});

export const getStudents = (params, page = 0, createUser, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({
    ...params,
    page,
    isUserModal: null,
    sort: 'lastName,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  isUserModal: params && params.isUserModal !== undefined && params.isUserModal,
  types: !createUser
    ? [GET_STUDENTS_START, GET_STUDENTS_SUCCESS, GET_STUDENTS_FAIL]
    : [GET_STUDENTS_CREATEUSER_START, GET_STUDENTS_CREATEUSER_SUCCESS, GET_STUDENTS_CREATEUSER_FAIL],
  onSuccess,
});

export const getStudentsDeleted = (status, page, filter, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({
    status,
    page,
    sort: 'lastName,asc',
    withDeleted: true,
    ...filter,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STUDENTS_DELETED_START, GET_STUDENTS_DELETED_SUCCESS, GET_STUDENTS_DELETED_FAIL],
  onSuccess,
});

export const createUserTopicToMultipleUsers = (userIds, id = 0, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/topic/${encodeURI(id)}${getQueryString({ userIds })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_MULTIPLE_USER_TOPICS_START, POST_MULTIPLE_USER_TOPICS_SUCCESS, POST_MULTIPLE_USER_TOPICS_FAIL],
  onSuccess,
});

export const createStudyPlan = (
  userIds,
  topicId,
  planDate,
  comment,
  inspection,
  withoutSchedule,
  eventId,
  onSuccess
) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/admin/plan`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    comment,
    date: planDate,
    topicId,
    userIds,
    withoutSchedule,
    eventId,
    inspection,
  },
  onSuccess,
  month: planDate,
  types: [CREATE_STUDY_PLAN_START, CREATE_STUDY_PLAN_SUCCESS, CREATE_STUDY_PLAN_FAIL],
});

export const massFilterAdd = (topicId, filter, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/filter/add${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: filter,
  onSuccess,
  onError,
  types: [CREATE_STUDY_PLAN_MASS_START, CREATE_STUDY_PLAN_MASS_SUCCESS, CREATE_STUDY_PLAN_MASS_FAIL],
});

export const getCountSelectedUsers = (data, topicId) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/count/users${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  types: [GET_COUNT_SELECTED_USER_START, GET_COUNT_SELECTED_USER_SUCCESS, GET_COUNT_SELECTED_USER_FAIL],
});

export const exportSelectedUserInMass = (data, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/export/users`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  responseType: 'blob',
  types: [EXPORT_SELECTED_USER_IN_MASS_START, EXPORT_SELECTED_USER_IN_MASS_SUCCESS, EXPORT_SELECTED_USER_IN_MASS_FAIL],
  onSuccess,
});

export const updateManagers = (params, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/manager`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    ...params,
  },
  types: [PUT_MANAGERS_START, PUT_MANAGERS_SUCCESS, PUT_MANAGERS_FAIL],
  onSuccess,
});

export const updateUserPermission = (params, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/permission`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    ...params,
  },
  types: [PUT_PERMISSION_START, PUT_PERMISSION_SUCCESS, PUT_PERMISSION_FAIL],
  onSuccess,
});

export const changeUsersFilter = info => ({
  type: CHANGE_USERS_FILTER,
  info,
});

export const getStudentStat = id => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${encodeURI(id)}/plan/stat`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STUDENTS_STAT_START, GET_STUDENTS_STAT_SUCCESS, GET_STUDENTS_STAT_FAIL],
});

export const getStudentAllStat = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${encodeURI(id)}/stat`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STUDENTS_ALL_STAT_START, GET_STUDENTS_ALL_STAT_SUCCESS, GET_STUDENTS_ALL_STAT_FAIL],
  onSuccess,
});

export const getDepartment = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/department`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DEPARTMENT_START, GET_DEPARTMENT_SUCCESS, GET_DEPARTMENT_FAIL],
});

export const getPosition = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/position`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSITION_START, GET_POSITION_SUCCESS, GET_POSITION_FAIL],
});

export const getFullName = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/fullname/employee`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FULLNAME_START, GET_FULLNAME_SUCCESS, GET_FULLNAME_FAIL],
});

export const getStudentsList = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url:
    params && params.isManager
      ? `${API_URL}/user/fullname/manager${getQueryString({
          size: params.size || 20,
          fullName: params.fullName,
        })}`
      : `${API_URL}/user/fullname/employee${getQueryString({
          role: params && params.role,
          size: params.size || 20,
          fullName: params.fullName,
        })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types:
    params && params.isManager
      ? [GET_MANAGER_FULLNAME_START, GET_MANAGER_FULLNAME_SUCCESS, GET_MANAGER_FULLNAME_FAIL]
      : [GET_FULLNAME_START, GET_FULLNAME_SUCCESS, GET_FULLNAME_FAIL],
  onSuccess,
});

export const getManagersList = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  //  url: `${API_URL}/courses/event/manager${getQueryString({ sort: 'lastName,asc' })}`,
  //    url: `${API_URL}/company/users/admins`,
  url: `${API_V2}/api/company/users/admins`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MANAGER_FULLNAME_START, GET_MANAGER_FULLNAME_SUCCESS, GET_MANAGER_FULLNAME_FAIL],
  onSuccess,
});

export const getFunction = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/function`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FUNCTION_START, GET_FUNCTION_SUCCESS, GET_FUNCTION_FAIL],
});

export const getLocation = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/location`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_LOCATION_START, GET_LOCATION_SUCCESS, GET_LOCATION_FAIL],
});

export const getLdapStatus = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users/ldap/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_LDAP_STATUS_START, GET_LDAP_STATUS_SUCCESS, GET_LDAP_STATUS_FAIL],
});

export const updateLdapStatus = () => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/users/ldap`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [UPDATE_LDAP_STATUS_START, UPDATE_LDAP_STATUS_SUCCESS, UPDATE_LDAP_STATUS_FAIL],
});

export const toggleUserIndividualTrajectory = (params, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/trajectory/user/statusEnable`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    ...params,
  },
  types: [
    TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_START,
    TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_SUCCESS,
    TOGGLE_USERS_INDIVIDUAL_TRAJECTORY_FAIL,
  ],
  onSuccess,
});

export const toggleUserLocked = (id, locked, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/${id}/${locked}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [TOGGLE_USER_LOCKED_START, TOGGLE_USER_LOCKED_SUCCESS, TOGGLE_USER_LOCKED_FAIL],
  onSuccess,
});

export const blockUser = (id, lock, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/lockedForLogin`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id,
    lock,
  },
  types: [BLOCK_USER_START, BLOCK_USER_SUCCESS, BLOCK_USER_FAIL],
  onSuccess,
});

export const changePassword = (userId, password, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/${userId}/update/password`,
  headers: {
    ...headers,
    'Content-Type': 'text/plain',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: password,
  types: [CHANGE_USER_PASSWORD_START, CHANGE_USER_PASSWORD_SUCCESS, CHANGE_USER_PASSWORD_FAIL],
  onSuccess,
  onError,
});

export const toggleAllUsersTrajectory = (trajectoryStatus, userIds, filter, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/trajectory/users/statusEnable${getQueryString({ ...filter })}`,
  body: {
    trajectoryStatus,
    userIds,
  },
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [TOGGLE_ALL_USERS_TRAJECTORY_START, TOGGLE_ALL_USERS_TRAJECTORY_SUCCESS, TOGGLE_ALL_USERS_TRAJECTORY_FAIL],
  onSuccess,
});

export const exportUser = (subCompanies, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/users/user-export/csv`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    subCompanies,
  },
  responseType: 'blob',
  types: [EXPORT_USER_START, EXPORT_USER_SUCCESS, EXPORT_USER_FAIL],
  onSuccess,
  onError,
});

export const getPositionsByUserName = (name = '', topicId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/search/user/positions${getQueryString({
    name,
    topicId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSITION_START, GET_POSITION_SUCCESS, GET_POSITION_FAIL],
  onSuccess,
});

export const getStringValue = fieldId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/profile-fields/${fieldId}/values`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STRING_VALUE_IN_MASS_START, GET_STRING_VALUE_IN_MASS_SUCCESS, GET_STRING_VALUE_IN_MASS_FAIL],
});

export const clearStudents = () => ({
  type: CLEAR_STUDENTS_STORE,
});

export const clearStudentsCreatUser = () => ({
  type: CLEAR_STUDENTS_CREATEUSER_STORE,
});

export const setManagerFullname = value => ({
  type: SET_MANAGER_FULLNAME,
  value,
});

export const clearManagerFullname = () => ({
  type: CLEAR_MANAGER_FULLNAME,
});

export const clearFullname = () => ({
  type: CLEAR_FULLNAME,
});

export const setEmployeeFullname = value => ({
  type: SET_EMPLOYEE_FULLNAME,
  value,
});
