/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360GetScales } from '@/store/d360/d360_scales/actions';

import { selectD360Scales } from '@/store/d360/d360_scales/selectors';

import { Button, Col, Input, Row, Select } from 'antd';
import { FiHexagon } from 'react-icons/fi';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

export const StepScales = props => {
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation('D360_StepScales');

  useEffect(() => {
    if (props.project && props.project.id) {
      props.D360GetScales(props.project.id);
    }
  }, [props.project]);

  useEffect(() => {
    if (props.onSelect && typeof props.onSelect === 'function') {
      props.onSelect(selected);
    }
  }, [selected]);

  const selectScale = id => {
    if (parseInt(id, 10) <= 0) {
      return setSelected(null);
    }

    if (props && props.scales && Array.isArray(props.scales)) {
      let scale = props.scales.filter(item => {
        return item.id === parseInt(id, 10);
      });

      if (scale && Array.isArray(scale) && scale.length) {
        setSelected(scale[0]);
      }
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.StepScreenWindow}>
        <Row className={css.Notes}>
          <Col span={24} className={css.NotesCol}>
            <FiHexagon size='3rem' className={css.NotesIcon} />
            <p className={css.NotesText}>{t('title')}</p>
          </Col>
        </Row>

        <Row>
          <Col span={24}>{t('scale')}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select defaultValue={0} style={{ width: '100%' }} onSelect={selectScale}>
              <Option key={0} value={0}>
                {t('defaultSelected')}
              </Option>
              {props &&
                props.scales &&
                Array.isArray(props.scales) &&
                props.scales.map(scale => {
                  return (
                    <Option key={scale.id} value={scale.id}>
                      {scale.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
      </div>
    );
  };

  return render();
};

StepScales.propTypes = {
  project: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = createSelector([selectD360Scales], D360Scales => ({
  scales: D360Scales.scales,
  loading: D360Scales.loading,
}));

const mapDispatchToProps = {
  D360GetScales,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepScales);
