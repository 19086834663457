export const ACCEPT = 'ACTIVE';
export const REJECT = 'REJECTED';
export const REJECTED_REASON = 'REJECTED_REASON';
export const INACTIVE = 'INACTIVE';
export const INWAITING = 'PENDING';
export const EDIT_MENTOR = 'EDIT_MENTOR';

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_PENDING = 'PENDING';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_INACTIVE = 'INACTIVE';

export const DESC_APPLY = 'descApply';
export const DESC_ACTIVATED = 'descActivated';
