import React from 'react';

import { Col, Row } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../TopicDetailsDialog.module.scss';

const SortTable = props => {
  const { t, isCourse, order = 'asc' } = props;

  const newOrder = order === 'asc' ? 'desc' : 'asc';

  const addedDate = `addedDate,${order}`;
  const fio = `user.lastName,${order}`;
  const isFioSort = props.activeSort === fio;
  const isDateSort = props.activeSort === addedDate;
  const sortByAddedDate = `addedDate,${isDateSort ? newOrder : order}`;
  const sortByFio = `user.lastName,${isFioSort ? newOrder : order}`;

  const courseFio = `plan.user.lastName,${order}`;
  const courseDate = `plan.addedDate,${order}`;
  const isCourseFioSort = props.activeSort === courseFio;
  const isCourseDateSort = props.activeSort === courseDate;
  const courseSortByFio = `plan.user.lastName,${isCourseFioSort ? newOrder : order}`;
  const courseSortByDate = `plan.addedDate,${isCourseDateSort ? newOrder : order}`;

  const arrows = order === 'asc' ? <ArrowUpOutlined /> : <ArrowDownOutlined />;

  const forCourse = (
    <div className={css['DetailsModal-sort-block']}>
      {t('sortBy')}
      <div
        className={classNames(css.Sort, { [css['Sort-active']]: isCourseFioSort })}
        onClick={() => props.handleChangeSort(courseSortByFio)}
      >
        {t('name')} {isCourseFioSort && arrows}
      </div>

      <div
        className={classNames(css.Sort, { [css['Sort-active']]: isCourseDateSort })}
        onClick={() => props.handleChangeSort(courseSortByDate)}
      >
        {t('dateAdded')} {isCourseDateSort && arrows}
      </div>
    </div>
  );

  const forNoCourse = (
    <div className={css['DetailsModal-sort-block']}>
      {t('sortBy')}
      <div
        className={classNames(css.Sort, { [css['Sort-active']]: isFioSort })}
        onClick={() => props.handleChangeSort(sortByFio)}
      >
        {t('name')} {isFioSort && arrows}
      </div>

      <div
        className={classNames(css.Sort, { [css['Sort-active']]: isDateSort })}
        onClick={() => props.handleChangeSort(sortByAddedDate)}
      >
        {t('dateAdded')} {isDateSort && arrows}
      </div>
    </div>
  );

  return (
    <Row>
      <Col>{isCourse ? forCourse : forNoCourse}</Col>
    </Row>
  );
};

SortTable.propTypes = {
  activeSort: PropTypes.string,
  handleChangeSort: PropTypes.func,
  t: PropTypes.func,
};

export default SortTable;
