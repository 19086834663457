import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUsers } from '@/store/users/selectors';

import { Spin, Typography } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.scss';

import { EXPORT_MASS_FILTER_USERS } from '@/constants/permissions';

const UsersCount = ({ exportUsers, isLoadingCountSelectedUsers, countSelectedUsers, titleSelectedUser }) => {
  const { currentUser } = useSelector(selectUsers);
  const { t } = useTranslation('MVFilters');

  return (
    <div className={style['users-count']}>
      <Typography.Text className={style['users-count__text']}>{titleSelectedUser}</Typography.Text>
      <div className={style['users-count__content']}>
        {isLoadingCountSelectedUsers ? (
          <Spin className={style['users-count__text']} indicator={<LoadingOutlined spin />} />
        ) : (
          <Typography.Text className={cn(style['users-count__text'], style['users-count__text--big'])}>
            {countSelectedUsers}
          </Typography.Text>
        )}
        {currentUser.authorities.includes(EXPORT_MASS_FILTER_USERS) && (
          <div className={style['users-count__export']} onClick={exportUsers}>
            <UploadOutlined />
            <Typography.Text>{t('downloadUsersFile')}</Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

UsersCount.propTypes = {
  text: PropTypes.string,
};

export default UsersCount;
