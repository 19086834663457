// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-DAMYYg.admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-TNhxDA .admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-label-hkdS6E[data-value="Montserrat"]:before, .admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-DAMYYg.admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-TNhxDA .admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-item-lfWTU1[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-Montserrat-ri4GEt {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count-jtTPp_ {
  background-color: #efefef;
  border-radius: 12px;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count__text-liN9o0 {
  font-size: 18px;
  font-weight: 600;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count__text--big-m6UFKR {
  font-size: 32px;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count__content-qN8aHz {
  align-items: center;
  gap: 40px;
  display: flex;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count__export-kpcCEC {
  cursor: pointer;
  align-items: center;
  gap: 6px;
  display: flex;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count__export-kpcCEC > span {
  color: var(--personalColor);
  font-size: 16px;
}

.admin-src-components-MVFilters-components-UsersCount-style-module__users-count__export-kpcCEC > span:first-child {
  font-size: 22px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MVFilters/components/UsersCount/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,aAAa;AACf;;AAMA;EAHE,eAAe;EACf,gBAAgB;AAClB;;AAKA;EAFE,eAAe;AACjB;;AAIA;EADE,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAGA;EAAE,eAAe;EACf,mBAAmB;EACnB,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AACA;EAEE,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.users-count {\n  background-color: #efefef;\n  padding: 20px;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: column;\n}\n.users-count__text {\n  font-size: 18px;\n  font-weight: 600;\n}\n.users-count__text--big {\n  font-size: 32px;\n}\n.users-count__content {\n  display: flex;\n  gap: 40px;\n  align-items: center;\n}\n.users-count__export {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}\n.users-count__export > span {\n  color: var(--personalColor);\n  font-size: 16px;\n}\n.users-count__export > span:first-child {\n  font-size: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-DAMYYg`,
	"qlPicker": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-DAMYYg`,
	"ql-font": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-TNhxDA`,
	"qlFont": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-TNhxDA`,
	"ql-picker-label": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-label-hkdS6E`,
	"qlPickerLabel": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-label-hkdS6E`,
	"ql-picker-item": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-item-lfWTU1`,
	"qlPickerItem": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-picker-item-lfWTU1`,
	"ql-font-Montserrat": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-Montserrat-ri4GEt`,
	"qlFontMontserrat": `admin-src-components-MVFilters-components-UsersCount-style-module__ql-font-Montserrat-ri4GEt`,
	"users-count": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count-jtTPp_`,
	"usersCount": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count-jtTPp_`,
	"users-count__text": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__text-liN9o0`,
	"usersCountText": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__text-liN9o0`,
	"users-count__text--big": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__text--big-m6UFKR`,
	"usersCountTextBig": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__text--big-m6UFKR`,
	"users-count__content": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__content-qN8aHz`,
	"usersCountContent": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__content-qN8aHz`,
	"users-count__export": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__export-kpcCEC`,
	"usersCountExport": `admin-src-components-MVFilters-components-UsersCount-style-module__users-count__export-kpcCEC`
};
export default ___CSS_LOADER_EXPORT___;
