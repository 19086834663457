export const MODS = [
  {
    value: 3,
    labelPosition: 2,
  },
  {
    value: 1,
    labelPosition: 0,
  },
  {
    value: 2,
    labelPosition: 1,
  },
  {
    value: 4,
    labelPosition: 3,
  },
];
