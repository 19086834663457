/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createFile } from '@/store/files/actions';

import { selectFiles as filesSelect } from '@/store/files/selectors';

import { Button, Form, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { DOC_TYPES } from '@shared/constants/image-types';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './FileInput.module.scss';

export class FileInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    createFile: PropTypes.func,
    error: PropTypes.string,
    fileInfo: PropTypes.object,
    fileLoading: PropTypes.bool,
    fileLoadingImage: PropTypes.bool,
    files: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    topicType: PropTypes.object,
    value: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      files: props.value || [],
      error: props.error || '',
    };
  }

  setInputRef = input => {
    const a = DOC_TYPES.join(', ');
    input && input.setAttribute('accept', a);
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  handleFileUpload = (file, fileName, fileSize) => {
    const { onChange, name } = this.props;
    if (file) {
      this.props.createFile(
        file,
        fileInfo => {
          const files = [...this.state.files, { uuid: fileInfo.uuid, name: fileName, size: fileSize }];
          this.setState({ files });
          onChange(files, name);
          this.setState({ error: '' });
        },
        () => {
          this.setState({
            error: this.props.t('error'),
          });
        }
      );
    }
  };

  handleFileChange = event => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    reader.onloadend = () => {
      if (file && file) {
        if (
          !this.state.files.some(f => {
            if (f.size === file.size && f.name === file.name) {
              return true;
            }
          })
        ) {
          if (!DOC_TYPES.includes(file.type) || file.size > 314572800) {
            this.setState({
              error: this.props.t('error'),
            });
          } else {
            this.setState({ error: '' });
            this.handleFileUpload(formData, file.name, file.size);
          }
        }
      }
    };
    file && reader.readAsDataURL(file);
    event.target.value = null;
  };

  handleFileDelete = fileUuid => {
    const { onChange, name } = this.props;
    const files = this.state.files.filter(file => file.uuid !== fileUuid);
    this.setState({ files });
    onChange(files, name);
  };

  render() {
    const { className, t } = this.props;
    const { files } = this.state;

    const fileTags = files.map(file => (
      <Tag key={file.uuid} className={css['FileInput-item']} closable onClose={() => this.handleFileDelete(file.uuid)}>
        {file.name}
      </Tag>
    ));
    const content = fileTags.length ? <div className={css['FileInput-content']}>{fileTags}</div> : null;

    return (
      <Form.Item
        label={this.props.label}
        required={this.props.required}
        error={this.state.error}
        labelCol={{ span: 6 }}
      >
        <div className={classNames(css.FileInput, className)}>
          {content}
          <Button
            type='link'
            onClick={this.openFileDialog}
            disabled={
              this.props.fileLoading ||
              this.props.fileLoadingImage ||
              (this.props.files && this.props.files.length >= 100)
            }
            className={css['FileInput-content-btn']}
          >
            {this.props.fileLoading ? (
              <div>
                {t('loading')}
                <LoadingOutlined />
              </div>
            ) : (
              t('add')
            )}
          </Button>
          {this.state.error.length ? null : <p>{t('error2')}</p>}
          <input data-qa='fileInput' type='file' ref={this.setInputRef} onChange={this.handleFileChange} />
        </div>
      </Form.Item>
    );
  }
}

const mapStateToProps = createSelector(filesSelect, files => ({
  fileInfo: files.info,
  fileLoading: files.isLoading,
  fileLoadingImage: files.isLoadingImage,
}));

const mapActionsToProps = {
  createFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('fileInput')(FileInput));
