import {
  GET_PLAN_LIST_FAIL,
  GET_PLAN_LIST_START,
  GET_PLAN_LIST_SUCCESS,
  POST_PLAN_REFRESH_FAIL,
  POST_PLAN_REFRESH_START,
  POST_PLAN_REFRESH_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPlanList = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/quartz/get`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_PLAN_LIST_START, GET_PLAN_LIST_SUCCESS, GET_PLAN_LIST_FAIL],
});

export const refreshPlan = (schedulerName, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/quartz/test${getQueryString({
    schedulerName,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_PLAN_REFRESH_START, POST_PLAN_REFRESH_SUCCESS, POST_PLAN_REFRESH_FAIL],
  onSuccess,
  onError,
  schedulerName,
});
