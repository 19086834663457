/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectFields } from '@/store/pcfields/selectors';

import MassSelect from '@/components/UsersModal/components/MassSelect/MassSelect';
import { FiTrash2 } from 'react-icons/fi';

import Inputs from './Inputs';

import PropTypes from 'prop-types';

import css from '../CustomFieldFilter.module.scss';

function Filter({ id, state, onChange, customFieldLength }) {
  const { fields } = useSelector(selectFields);

  const [customFieldType, setCustomFieldType] = useState(null);

  useEffect(() => {
    let newValue = null;
    fields.forEach(field => {
      if (field.id === state.customFieldId) {
        newValue = field.fieldType.type;
      }
    });
    newValue ? setCustomFieldType(newValue) : setCustomFieldType(null);
  }, [customFieldType, customFieldLength]);

  const actionChange = (type, value, name) => {
    switch (type) {
      case 'customFieldId':
        fields.forEach(field => {
          if (field.id === value) {
            setCustomFieldType(field.fieldType.type);
            onChange(id, 'customField', {
              customFieldId: value,
              type:
                field.fieldType.type === 'TYPE_STRING' ||
                field.fieldType.type === 'TYPE_DATE' ||
                field.fieldType.type === 'TYPE_DATETIME'
                  ? 'EQUALS'
                  : field.fieldType.type === 'TYPE_TEXT'
                    ? 'LIKE'
                    : 'BOOLEAN',
              valueConcrete: field.fieldType.type === 'TYPE_BOOLEAN' ? false : undefined,
            });
          }
        });
        return;
      case 'type':
        return onChange(id, 'customField', { customFieldId: state.customFieldId, type: value });

      case 'value':
        return onChange(id, 'customField', { ...state, [name]: value });

      case 'delete':
        return onChange(id, 'deleteCustomField', {});

      default:
        return;
    }
  };

  return (
    <div className={css['Filter']}>
      <div className={css['Filter__main']}>
        <MassSelect
          name='customField'
          options={fields}
          onChange={(name, value) => actionChange('customFieldId', value)}
          value={state.customFieldId}
        />
        <div className={css['Filter__main-additional']}>
          <FiTrash2
            color='var(--personalColor)'
            size={24}
            className={css['Filter__main-icon']}
            onClick={() => actionChange('delete')}
          />
        </div>
      </div>
      {!!Object.keys(state).length && customFieldType && (
        <div className={css['Filter__additional']}>
          <Inputs onChange={actionChange} typeCustomField={customFieldType} state={state} />
        </div>
      )}
    </div>
  );
}

Filter.propTypes = {
  id: PropTypes.number.isRequired,
  state: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  customFieldLength: PropTypes.number.isRequired,
};

export default Filter;
