import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCompaniesShort } from '@/store/companies/actions';
import { changeUsersFilter } from '@/store/students/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Form, Input, Select, Typography } from 'antd';

import useDebounce from '@shared/hooks/useDebounce';

import Utils from '@/Utils';
import classNames from 'classnames';

import css from '../UserSettings.module.scss';

const { Option } = Select;
const { Text } = Typography;

// Блок фильтрации в настройках ролей пользователей
const HeaderFilter = memo(({ roles, setSelectedUser, setSelectedRowKeys }) => {
  const [selectedRoleId, setSelectedRoleId] = useState(undefined);
  const [searchData, setSearchData] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const debounceSearchData = useDebounce(searchData, 800);

  const { currentUser } = useSelector(selectUsers);

  const dispatch = useDispatch();

  const isDefAdmin = currentUser && Utils.isDefAdmin(currentUser);

  useEffect(() => {
    if (isDefAdmin) {
      dispatch(getCompaniesShort());
    }
  }, []);

  useEffect(() => {
    dispatch(
      changeUsersFilter({
        roleId: selectedRoleId,
        fullName: searchData,
        companyId: selectedCompanyId,
      })
    );
  }, [debounceSearchData, selectedRoleId, selectedCompanyId]);

  const { companiesShort } = useSelector(selectCompanies);

  const companyOptions = useMemo(
    () =>
      companiesShort?.map(company => (
        <Option key={company.id} value={company.id}>
          {company.shortName}
        </Option>
      )),
    [companiesShort]
  );

  const { t } = useTranslation('RolesSettings');

  const clearValue = () => {
    setSearchData('');
    setSelectedRoleId(undefined);
    setSelectedUser([]);
    setSelectedRowKeys([]);
  };

  const render = () => {
    return (
      <Form className={css['main__filter']} layout='vertical'>
        <Form.Item label={t('searchByFIO')} className={css['main__filter-FIO']}>
          <Input
            className={css['main__search']}
            onChange={event => setSearchData(event.target.value)}
            placeholder={t('placeholderFilter')}
            value={searchData}
          />
        </Form.Item>
        <Form.Item label={t('roleValue')}>
          <Select
            className={css['main__select']}
            placeholder={t('placeholderFilter')}
            onChange={setSelectedRoleId}
            value={selectedRoleId}
          >
            {roles?.map(role => (
              <Option value={role.id} key={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isDefAdmin && (
          <Form.Item label={t('company')}>
            <Select
              className={css['main__select']}
              placeholder={t('placeholderFilter')}
              onChange={setSelectedCompanyId}
              value={selectedCompanyId}
            >
              {companyOptions}
            </Select>
          </Form.Item>
        )}
        <Text
          className={classNames(css['main__text'], !isDefAdmin && css['main__text__notAdmin'])}
          onClick={clearValue}
        >
          {t('clear')}
        </Text>
      </Form>
    );
  };

  return render();
});

export default HeaderFilter;
