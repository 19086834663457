import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { exportMentee, getStatus } from '@/store/mentee/actions';
import { getDepartment, getFunction, getLocation, getPosition } from '@/store/students/actions';

import { selectMentee } from '@/store/mentee/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import { FileExcelOutlined } from '@ant-design/icons';

import Filters from './Content/Filters';
import TableMentee from './Content/TableMentee';

import css from './Mentee.module.scss';

import { EXPORT_MENTEE } from '@/constants/permissions';

const { Title } = Typography;

const Mentors = () => {
  const { t } = useTranslation('mentee');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFunction());
    dispatch(getSubcompanies());
    dispatch(getPosition());
    dispatch(getDepartment());
    dispatch(getLocation());
    dispatch(getStatus());
  }, [dispatch]);

  const { menteeFilters, exportMenteeLoading } = useSelector(selectMentee);
  const { currentUser } = useSelector(selectUsers);

  const exportTable = () => {
    const date = new Date();
    const fileNameNotSplit = `mentees(${date.toLocaleDateString()}).csv`;

    dispatch(
      exportMentee(menteeFilters.filters ? menteeFilters.filters : {}, (blob, headers) => {
        const fileName = headers.get('Content-Disposition').split(['"'])[1] || fileNameNotSplit;
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  return (
    <Layout>
      <Content>
        <div className={css['Content-header']}>
          <Title>{t('title')}</Title>
          <div className={css['Content-header-actions']}>
            {currentUser.authorities.includes(EXPORT_MENTEE) && (
              <Button
                className={css['Content-header-actions-export']}
                type='primary'
                onClick={exportTable}
                icon={<FileExcelOutlined />}
                loading={exportMenteeLoading}
                size='large'
              >
                {t('exportBtn')}
              </Button>
            )}
          </div>
        </div>

        <Filters />
        <TableMentee />
      </Content>
    </Layout>
  );
};

export default Mentors;
