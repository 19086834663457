import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getRoles } from '@/store/access/actions';
import { getSubcompanies } from '@/store/companies/actions';
import { getDepartment, getFunction, getLocation, getPosition } from '@/store/students/actions';

import { selectAccess } from '@/store/access/selectors';
import { selectCompanies } from '@/store/companies/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Form, Select } from 'antd';

import PropTypes from 'prop-types';

import style from './style.module.scss';

const SELECT_ALL = 'select-all';

const GlobalFilters = forwardRef(({ withLabels = true, ...props }, ref) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('MVFilters');
  const { functionStudent, department, position, location } = useSelector(selectStudents);
  const { subCompanies } = useSelector(selectCompanies);
  const { roles } = useSelector(selectAccess);

  const [form] = Form.useForm();

  // При инициализации получение всех данных для фильтрации
  useEffect(() => {
    dispatch(getFunction());
    dispatch(getDepartment());
    dispatch(getPosition());
    dispatch(getLocation());
    dispatch(getSubcompanies());
    dispatch(getRoles({ size: 500 }));
  }, []);

  const handleSelectChange = (value, name, options) => {
    if (value[value.length - 1] === SELECT_ALL) {
      form.setFieldsValue({ [name]: options });
      return options;
    } else {
      form.setFieldsValue({ [name]: value });
      return value;
    }
  };

  const renderOption = array => {
    let options = [{ value: SELECT_ALL, label: t('selectAll') }];
    if (array?.length && typeof array[0] === 'string') {
      options = [...options, ...array.map(elem => ({ value: elem, label: elem }))];
    } else if (array?.length) {
      options = [...options, ...array.map(elem => ({ value: elem.name, label: elem.name }))];
    }
    return options;
  };

  const handleChangeValues = (currentValues, allValues) => {
    Object.keys(allValues).forEach(key => {
      if (Array.isArray(allValues[key]) && allValues[key].length === 0) {
        allValues[key] = undefined;
      }
    });
    props.onValuesChange(currentValues, allValues);
  };

  return (
    <Form
      onValuesChange={handleChangeValues}
      form={form}
      ref={ref}
      layout='vertical'
      className={style['global-filters']}
    >
      <Form.Item
        name='companies'
        className={style['global-filters__form-item']}
        label={withLabels ? t('company') : ''}
        getValueFromEvent={value => handleSelectChange(value, 'companies', subCompanies)}
      >
        <Select
          allowClear
          mode='multiple'
          options={renderOption(subCompanies)}
          placeholder={t('selectCompanyPlaceholder')}
          className={style['global-filters__element']}
        />
      </Form.Item>
      <Form.Item
        name='departments'
        className={style['global-filters__form-item']}
        label={withLabels ? t('department') : ''}
        getValueFromEvent={value => handleSelectChange(value, 'departments', department)}
      >
        <Select
          allowClear
          mode='multiple'
          options={renderOption(department)}
          placeholder={t('selectDepartmentPlaceholder')}
          className={style['global-filters__element']}
        />
      </Form.Item>
      <Form.Item
        name='positions'
        className={style['global-filters__form-item']}
        label={withLabels ? t('position') : ''}
        getValueFromEvent={value => handleSelectChange(value, 'positions', position)}
      >
        <Select
          allowClear
          mode='multiple'
          options={renderOption(position)}
          placeholder={t('selectPositionPlaceholder')}
          className={style['global-filters__element']}
        />
      </Form.Item>
      <Form.Item
        name='functions'
        className={style['global-filters__form-item']}
        label={withLabels ? t('function') : ''}
        getValueFromEvent={value => handleSelectChange(value, 'functions', functionStudent)}
      >
        <Select
          allowClear
          mode='multiple'
          options={renderOption(functionStudent)}
          placeholder={t('selectFunctionPlaceholder')}
          className={style['global-filters__element']}
        />
      </Form.Item>
      <Form.Item
        name='roles'
        className={style['global-filters__form-item']}
        label={withLabels ? t('roles') : ''}
        getValueFromEvent={value =>
          handleSelectChange(
            value,
            'roles',
            roles.map(role => role.name)
          )
        }
      >
        <Select
          allowClear
          mode='multiple'
          options={renderOption(roles)}
          placeholder={t('selectRolesPlaceholder')}
          className={style['global-filters__element']}
        />
      </Form.Item>
      <Form.Item
        name='locations'
        className={style['global-filters__form-item']}
        label={withLabels ? t('location') : ''}
        getValueFromEvent={value => handleSelectChange(value, 'locations', location)}
      >
        <Select
          allowClear
          mode='multiple'
          options={renderOption(location)}
          placeholder={t('selectLocationPlaceholder')}
          className={style['global-filters__element']}
        />
      </Form.Item>
    </Form>
  );
});

GlobalFilters.propTypes = {
  onValuesChange: PropTypes.func,
};

export default GlobalFilters;
