/* eslint-disable */
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  changeCoursesFilter,
  clearCoursesFilter,
  getCoursesRequest,
  getEventUsers,
  getYear,
} from '@/store/courses/actions';
import { clearEvents, getEvents } from '@/store/event/actions';
import { getTypeLabels } from '@/store/label_topic/actions';
import { clearFullname, getStudentsList } from '@/store/students/actions';

import { selectCourses as coursesSelect } from '@/store/courses/selectors';
import { selectEvent } from '@/store/event/selectors';
import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectStudents as studentsSelect } from '@/store/students/selectors';

import { Button, Form, Tooltip } from 'antd';
import Checkbox from '@/components/Checkbox';
import { Content } from '@/components/Layout';
import MultiplySelect from '@/components/MultiplySelect';
import Select from '@/components/Select';
import { InfoCircleTwoTone } from '@ant-design/icons';

import { Event } from './Event';

import CloseIcon from '@/assets/images/close-icon.svg?react';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import css from './Events.module.scss';

const getDefaultTopicTypeIds = typeLabels => typeLabels.map(i => i.value);

export class Events extends Component {
  static propTypes = {
    changeCoursesFilter: PropTypes.func,
    clearCoursesFilter: PropTypes.func,
    clearEvents: PropTypes.func,
    coursesFilter: PropTypes.any,
    eventUsers: PropTypes.array,
    events: PropTypes.array,
    eventsPageNumber: PropTypes.number,
    getCoursesRequest: PropTypes.func,
    getEventUsers: PropTypes.func,
    getEvents: PropTypes.func,
    getStudentsList: PropTypes.func,
    clearFullname: PropTypes.func,
    getYear: PropTypes.func,
    isLoading: PropTypes.bool,
    managerList: PropTypes.array,
    yearsRequest: PropTypes.any,
    getTypeLabels: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeEvent: '',
      hasMore: true,
      haveRequests: true,
      noEvents: false,
      status: _get(this.props, 'coursesFilter.status') || 'after',
      topicTypeLabelIds: [],
      years: null,
      managerId: undefined,
      firstLoad: false,
      months: [
        { id: 1, value: '1', name: props.t('january') },
        { id: 2, value: '2', name: props.t('february') },
        { id: 3, value: '3', name: props.t('march') },
        { id: 4, value: '4', name: props.t('april') },
        { id: 5, value: '5', name: props.t('may') },
        { id: 6, value: '6', name: props.t('june') },
        { id: 7, value: '7', name: props.t('july') },
        { id: 8, value: '8', name: props.t('august') },
        { id: 9, value: '9', name: props.t('september') },
        { id: 10, value: '10', name: props.t('october') },
        { id: 11, value: '11', name: props.t('november') },
        { id: 12, value: '12', name: props.t('december') },
      ],
      statusRequestList: [{ id: 3, value: 'registers', name: props.t('registration') }],
      statusList: [
        { id: 1, value: 'after', name: props.t('upcoming') },
        { id: 2, value: 'isEnded', name: props.t('incomplete') },
      ],
      coursesTypeList: [
        { id: 1, value: '20', name: props.t('external') },
        { id: 2, value: '17', name: props.t('corporate') },
      ],
    };
    this.getEventsThrottle = _throttle(props.getEvents, 1500);
    this.getStudentsListDebounced = _.debounce(props.getStudentsList, 1000);
  }

  componentDidMount() {
    this.props.getTypeLabels(true, false, labels => {
      this.setState({ topicTypeLabelIds: getDefaultTopicTypeIds(labels) });
    });
    this.props.clearEvents();
    this.clearCoursesFilter();
    this.props.getYear(
      res => {
        const years = res
          .sort((a, b) => b - a)
          .map((item, index) => ({ id: index + 1, value: item, name: item }))
          .sort((a, b) => b - a);
        this.setState({ years });
      },
      { events: true, requests: false }
    );
    this.props.changeCoursesFilter({
      name: 'after',
      value: dayjs().format('YYYY-MM-DD'),
    });
    this.handleScopeChange({ name: 'statusRequest', value: 'requests' });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coursesFilter !== this.props.coursesFilter) {
      this.getFilterElems(this.props.coursesFilter);
    }
  }

  componentWillUnmount() {
    this.props.clearFullname();
  }

  getFilterElems = coursesFilter => {
    this.setState({ isLoading: true });
    this.props.clearEvents();
    this.getEventsThrottle(
      0,
      response => {
        this.setState({
          hasMore: !!response.length,
          isLoading: false,
          noEvents: !response.length,
          firstLoad: true,
        });
      },
      coursesFilter
    );
  };

  getEvents = () => {
    if (this.state.firstLoad && !this.props.isLoading) {
      this.getEventsThrottle(
        this.props.eventsPageNumber + 1,
        response => {
          this.setState({ hasMore: !!response.length });
        },
        this.props.coursesFilter
      );
    }
  };

  handleChoice = id => {
    this.setState({ activeEvent: id });
  };

  showEvents = () => {
    const events =
      this.props.events &&
      this.props.events.map((event, index) => (
        <Event
          key={`#${index}-${event.name}`}
          eventUsers={this.props.eventUsers}
          getEventUsers={this.props.getEventUsers}
          event={event}
          handleChoice={this.handleChoice}
          activeEvent={this.state.activeEvent === event.id}
        />
      ));
    const notEvents = (
      <div className={css['Event-noEvent']}>
        <div dangerouslySetInnerHTML={{ __html: this.props.t('noResult') }} />
        <Link to='/topics'>
          <Button>{this.props.t('goToMaterials')}</Button>
        </Link>
      </div>
    );
    if (this.state.noEvents || this.props.events.length === 0) {
      if (!this.props.isLoading && !this.state.isLoading) {
        return notEvents;
      }
    } else {
      return events;
    }
  };

  searchManagers = event => {
    if (event) {
      let fullName = event.trim();
      fullName.length > 3 && this.getStudentsListDebounced({ isManager: false, fullName, size: 100, role: [1, 2, 3] });
    }
  };

  handleScopeChange = event => {
    const { name, value } = event;
    if (value !== 'requests') {
      this.setState({ [name]: value });
    }
    this.props.clearEvents();

    switch (value) {
      case 'approves':
        this.props.changeCoursesFilter({ name: 'requests', value: '' });
        this.props.changeCoursesFilter({ name: 'registers', value: '' });
        return this.props.changeCoursesFilter({
          name: 'approves',
          value: true,
        });
      case 'requests':
        this.props.changeCoursesFilter({ name: 'approves', value: '' });
        this.props.changeCoursesFilter({ name: 'registers', value: '' });
        return this.props.changeCoursesFilter({
          name: 'requests',
          value: true,
        });
      case 'registers':
        this.props.changeCoursesFilter({ name: 'approves', value: '' });
        this.props.changeCoursesFilter({ name: 'requests', value: '' });
        return this.props.changeCoursesFilter({
          name: 'registers',
          value: true,
        });
      case 'after':
        this.props.changeCoursesFilter({ name: 'isEnded', value: '' });
        return this.props.changeCoursesFilter({
          name: 'after',
          value: dayjs().format('YYYY-MM-DD'),
        });
      case 'isEnded':
        this.props.changeCoursesFilter({ name: 'after', value: '' });
        return this.props.changeCoursesFilter({
          name: 'isEnded',
          value: 'false',
        });
      case 'all':
        this.props.changeCoursesFilter({ name: 'after', value: undefined });
        return this.props.changeCoursesFilter({
          name: 'isEnded',
          value: 'false',
        });
      default:
        this.props.changeCoursesFilter({ name, value });
    }
  };

  changeHaveRequests = event => {
    this.setState({ [event.name]: event.value });
    if (!event.value) {
      this.props.changeCoursesFilter({ name: 'approves', value: '' });
      this.props.changeCoursesFilter({ name: 'requests', value: '' });
      this.props.changeCoursesFilter({ name: 'registers', value: '' });
      return this.setState({ statusRequest: undefined });
    }
    this.handleScopeChange({ name: 'statusRequest', value: 'requests' });
  };

  clearCoursesFilter = () => {
    this.props.clearCoursesFilter();
    this.setState({
      statusRequest: undefined,
      haveRequests: true,
      status: 'after',
      year: undefined,
      month: undefined,
      after: dayjs().format('YYYY-MM-DD'),
      topicTypeLabelIds: getDefaultTopicTypeIds(this.props.typeLabel),
    });
    this.props.changeCoursesFilter({
      name: 'after',
      value: dayjs().format('YYYY-MM-DD'),
    });
    this.props.changeCoursesFilter({
      name: 'requests',
      value: true,
    });
    this.props.changeCoursesFilter({
      name: 'topicTypeLabelIds',
      value: getDefaultTopicTypeIds(this.props.typeLabel),
    });
  };

  clearOneFilter = type => {
    if (type === 'statusRequest') {
      this.props.changeCoursesFilter({ name: 'approves', value: '' });
      this.props.changeCoursesFilter({ name: 'requests', value: true });
      this.props.changeCoursesFilter({ name: 'registers', value: '' });
      return this.setState({ [type]: undefined });
    }
    if (type === 'month' || type === 'year' || type === 'managerId' || type === 'topicTypeId') {
      this.setState({ [type]: undefined });
      this.props.changeCoursesFilter({ name: type, value: undefined });
      if (type === 'managerId') {
        this.props.clearFullname();
      }
    } else {
      this.setState({ [type]: undefined }, () => {
        this.props.changeCoursesFilter({ name: 'after', value: undefined });
        this.props.changeCoursesFilter({ name: 'isEnded', value: undefined });
      });
    }
  };

  render() {
    return (
      <EventComponent
        month={this.state.month}
        months={this.state.months}
        year={this.state.year}
        years={this.state.years}
        status={this.state.status}
        topicTypeLabelIds={this.state.topicTypeLabelIds}
        statusList={this.state.statusList}
        managerId={this.state.managerId}
        hasMore={this.state.hasMore}
        managerList={this.props.managerList}
        haveRequests={this.state.haveRequests}
        statusRequest={this.state.statusRequest}
        statusRequestList={this.state.statusRequestList}
        handleScopeChange={this.handleScopeChange}
        clearOneFilter={this.clearOneFilter}
        searchManagers={this.searchManagers}
        clearCoursesFilter={this.clearCoursesFilter}
        changeHaveRequests={this.changeHaveRequests}
        pageStart={this.props.eventsPageNumber}
        getEvents={this.getEvents}
        showEvents={this.showEvents}
        typeLabel={this.props.typeLabel}
      />
    );
  }
}

const mapStateToProps = createSelector(
  [selectEvent, coursesSelect, studentsSelect, selectTypeLabel],
  (event, courses, students, typeLabel) => ({
    events: event.events,
    managerList: students.fullname,
    eventUsers: courses.eventUsers,
    yearsRequest: courses.yearRequest || [],
    eventsPageNumber: event.eventsPageNumber,
    coursesFilter: courses.coursesFilter || {},
    isLoading: event.isLoading || courses.isLoading,
    typeLabel: typeLabel.typeLabelApproved?.map(el => ({ ...el, value: el.id })),
  })
);

const mapActionsToProps = {
  getEvents,
  clearEvents,
  getCoursesRequest,
  clearCoursesFilter,
  getStudentsList,
  clearFullname,
  getYear,
  getEventUsers,
  changeCoursesFilter,
  getTypeLabels,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation(['events', 'constants'])(Events));

const EventComponent = ({
  month,
  months,
  year,
  years,
  status,
  topicTypeLabelIds,
  statusList,
  managerId,
  hasMore,
  managerList,
  haveRequests,
  statusRequest,
  statusRequestList,
  handleScopeChange,
  clearOneFilter,
  searchManagers,
  clearCoursesFilter,
  changeHaveRequests,
  pageStart,
  getEvents,
  showEvents,
  typeLabel,
}) => {
  const resultManagerList = managerList?.map((item, index) => ({
    id: index + 1,
    value: item.id,
    name: item.name,
  }));
  const { t } = useTranslation(['events', 'constants']);

  const typeLabelRender = () => {
    return (
      <>
        {t('type')}
        <Tooltip title={t('typeInfo')} className={css['Events-form-tooltip']}>
          <InfoCircleTwoTone />
        </Tooltip>
      </>
    );
  };

  return (
    <Content className={css.Events}>
      <h1>{t('calendar')}</h1>
      <Form layout='vertical' className={css['Events-form']}>
        <div className={css['Events-form-item-title']}>
          <h2>{t('filter')}</h2>
          <Button type={'link'} onClick={clearCoursesFilter}>
            {t('clear')}
          </Button>
        </div>
        <div className={css['Events-form-section']}>
          <Form.Item label={t('month')} className={css['Events-form-item']}>
            <Select white onChange={handleScopeChange} value={month} name='month' options={months} long />
            {month && (
              <div className={css['Events-delete-button']} onClick={() => clearOneFilter('month')}>
                <CloseIcon />
              </div>
            )}
          </Form.Item>
          <Form.Item label={t('year')} className={css['Events-form-item']}>
            <Select white onChange={handleScopeChange} value={year || undefined} name='year' options={years} long />
            {year && (
              <div className={css['Events-delete-button']} onClick={() => clearOneFilter('year')}>
                <CloseIcon />
              </div>
            )}
          </Form.Item>
          <Form.Item label={t('status')} className={css['Events-form-item']}>
            <Select
              onChange={handleScopeChange}
              defaultActiveFirstOption
              value={status}
              name='status'
              options={statusList}
              white
              long
            />
            {status && (
              <div className={css['Events-delete-button']} onClick={() => clearOneFilter('status')}>
                <CloseIcon />
              </div>
            )}
          </Form.Item>
          <Form.Item label={t('organizer')} className={css['Events-form-item-long']}>
            <Select
              onChange={handleScopeChange}
              onSearch={searchManagers}
              value={managerId}
              name='managerId'
              options={resultManagerList}
              white
              long
              showSearch
            />
            {managerId && (
              <div className={css['Events-delete-button']} onClick={() => clearOneFilter('managerId')}>
                <CloseIcon />
              </div>
            )}
          </Form.Item>
          <Form.Item label={t('request')} className={css['Events-form-item']}>
            <Checkbox
              value={haveRequests}
              onChange={changeHaveRequests}
              name='haveRequests'
              label={t('eventRequest')}
              white
            />
          </Form.Item>
          <Form.Item label={t('statusRequest')} className={css['Events-form-item']}>
            <Select
              disabled={!haveRequests}
              disabledRequests={!haveRequests}
              onChange={handleScopeChange}
              defaultActiveFirstOption
              value={statusRequest}
              placeholder={t('all')}
              name='statusRequest'
              options={statusRequestList}
              white
              long
            />
            {haveRequests && statusRequest && (
              <div className={css['Events-delete-button']} onClick={() => clearOneFilter('statusRequest')}>
                <CloseIcon />
              </div>
            )}
          </Form.Item>
          <Form.Item label={typeLabelRender()} className={css['Events-form-itemhigh']}>
            <MultiplySelect
              dataQa='topicTypeLabelIds'
              onChange={handleScopeChange}
              value={topicTypeLabelIds}
              name='topicTypeLabelIds'
              options={typeLabel}
              style={{ height: 24 }}
              white
              long
            />
          </Form.Item>
        </div>
      </Form>
      <InfiniteScroll
        initialLoad={false}
        hasMore={hasMore}
        loadMore={getEvents}
        pageStart={pageStart}
        useWindow
        threshold={300}
      >
        <div className={css['Events-body']}>{showEvents()}</div>
      </InfiniteScroll>
    </Content>
  );
};
