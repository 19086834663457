/* eslint-disable */
import { CLEAR_CUSTOM_TAGS, GET_CUSTOM_TAGS_FAIL, GET_CUSTOM_TAGS_START, GET_CUSTOM_TAGS_SUCCESS } from './actions';

const initialState = {
  customTags: [],
  isCustomTagsLoading: false,
  customTagsError: false,
};

const mapResponseCustomTag = e => ({
  label: e.name,
  value: e.id,
  id: e.id,
  name: e.name,
  global: e?.global,
  mode: e?.mode,
});

export default (state = initialState, { type, response, action }) => {
  switch (type) {
    case GET_CUSTOM_TAGS_START:
      return {
        ...state,
        // customTags: [],
        isCustomTagsLoading: true,
        customTagsError: false,
      };

    case GET_CUSTOM_TAGS_SUCCESS:
      const customTags =
        action.values.path === '/book/address'
          ? response.content.map(e => ({ label: e.address, value: 'ad-' + e.address, id: e.id }))
          : action.values.path === '/skill'
            ? response.content.map(mapResponseCustomTag)
            : response.map(mapResponseCustomTag);
      return {
        ...state,
        customTags,
        isCustomTagsLoading: false,
      };

    case GET_CUSTOM_TAGS_FAIL:
      return { ...state, customTagsError: true, isCustomTagsLoading: false };

    case CLEAR_CUSTOM_TAGS:
      return {
        ...state,
        customTags: [],
      };

    default:
      return state;
  }
};
