/* eslint-disable */
import { ReportRequest } from './constants';

const initialState = {
  loading: false,
  report: {
    id: null,
    company_id: null,
    project_id: null,
    filename: null,
    status: 'empty',
    created_at: null,
    updated_at: null,
  },
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case ReportRequest.D360_REPORT_STATUS_START:
    case ReportRequest.D360_REPORT_CREATE_START:
      return { ...state, loading: true };

    case ReportRequest.D360_REPORT_STATUS_FAIL:
    case ReportRequest.D360_REPORT_CREATE_FAIL:
      return { ...state, report: null, loading: false };

    case ReportRequest.D360_REPORT_STATUS_SUCCESS:
    case ReportRequest.D360_REPORT_CREATE_SUCCESS:
      return { ...state, report: response.data, loading: false };

    default:
      return state;
  }
};
