import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getRegistrationOpenDate } from '@/store/courses/actions';

import { selectCourses as courseSelect } from '@/store/courses/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';

import Modal from '../Modal';
import CalendarTableModal from './CalendarModal';
import CalendarModalData from './CalendarModalData';
import ConfirmModal from './ConfirmModal';
import EventModal from './EventModal';

import {
  isCorpCourseWithOrWithoutApprove,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourse,
  isEventOrConference,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

class CalendarModal extends Component {
  static propTypes = {
    comment: PropTypes.string,
    dateEvent: PropTypes.string,
    disabledSelect: PropTypes.bool,
    eventTypeId: PropTypes.number,
    getRegistrationOpenDate: PropTypes.func,
    hasEvents: PropTypes.number,
    headerFixToModal: PropTypes.bool,
    isAddToPlan: PropTypes.bool,
    isLoading: PropTypes.bool,
    noComment: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    openDate: PropTypes.any,
    planInfo: PropTypes.object,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    selectMonthEvents: PropTypes.bool,
    title: PropTypes.any,
    toCalendar: PropTypes.bool,
    topicInfo: PropTypes.object,
    open: PropTypes.bool,
    loadingStatus: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.currentYear = Number(dayjs().format('YYYY'));

    this.state = {
      selectedYear: this.currentYear,
      visibleModalData: !props.hasEvents,
      selectedMonths: !props.disabledSelect ? [] : [dayjs(props.dateEvent).format('YYYY-MM-DD')],

      hasEventsFlag: !!props.hasEvents,
    };
  }

  componentDidMount() {
    this.props.getRegistrationOpenDate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({
        selectedYear: this.currentYear,
        visibleModalData: !this.props.hasEvents,
        comment: '',
        selectedMonths: !this.props.disabledSelect ? [] : [dayjs(this.props.dateEvent).format('YYYY-MM-DD')],
      });
    }
  }

  handleCalendarModalData = flag => {
    this.setState({
      hasEventsFlag: flag.hasEventsFlag,
      visibleModalData: !this.state.visibleModalData,
    });
  };

  render() {
    const {
      open,
      onCancel /* , okText, */,
      disabledSelect,
      dateEvent,
      eventTypeId,
      selectMonthEvents,
      isAddToPlan,
      hasEvents,
      t,
    } = this.props;

    const year = !disabledSelect ? this.state.selectedYear : dayjs(dateEvent).format('YYYY');
    const eventTypeIdList = [8, 20, 17, 35, 25, 23];

    const typeId = this.props.topicInfo?.type?.id;
    const titleUpdate = typeId !== 20 ? t('description1') : t('description2');
    const titleAdd = <div>{typeId !== 20 ? t('description3') : t('description4')}</div>;

    const event = this.props.eventList.find(el => dateEvent && el.startTime?.includes(dateEvent));

    !event && console.debug(`Не найден event со startTime = ${dateEvent}}`);

    return (
      <Modal
        open={open}
        loading={this.props.isLoading}
        onCancel={onCancel}
        width={eventTypeId === 8 || eventTypeId === 23 ? 470 : 600}
        hideFooter
        headerFixToModal
      >
        {!eventTypeIdList.includes(eventTypeId) && (
          <CalendarTableModal
            {...this.props}
            noEvent
            openDate={this.props.openDate}
            isLoadingPlan={this.props.isLoading}
            selectMonthEvents={selectMonthEvents}
          />
        )}
        {isEventOrConference(eventTypeId) && (
          <ConfirmModal
            {...this.props}
            comment={this.props.comment}
            isLoadingPlan={this.props.isLoading}
            eventId={event?.id}
          />
        )}
        {eventTypeId === 25 && (
          <CalendarTableModal
            {...this.props}
            noEvent
            openDate={this.props.openDate}
            isLoadingPlan={this.props.isLoading}
            selectMonthEvents={selectMonthEvents}
          />
        )}
        {eventTypeId === 20 && (this.state.visibleModalData || this.props.toCalendar) && (
          <CalendarModalData
            {...this.props}
            title={isAddToPlan ? titleAdd : titleUpdate}
            openDate={this.props.openDate}
            isLoadingPlan={this.props.isLoading}
            visibleModalData={this.state.visibleModalData}
            planInfo={this.props.planInfo}
            dateEvent={year}
            toPrev={this.handleCalendarModalData}
            hasEventsFlag={this.state.hasEventsFlag}
            hasEvents={hasEvents}
          />
        )}
        {isCorpCourseWithOrWithoutApprove(eventTypeId) && (this.state.visibleModalData || this.props.toCalendar) && (
          <CalendarTableModal
            {...this.props}
            title={isAddToPlan ? titleAdd : titleUpdate}
            openDate={this.props.openDate}
            courses
            planInfo={this.props.planInfo}
            isLoadingPlan={this.props.isLoading}
            visibleModalData={this.state.visibleModalData}
            dateEvent={year}
            toPrev={this.handleCalendarModalData}
            hasEventsFlag={this.state.hasEventsFlag}
            hasEvents={hasEvents}
            selectMonthEvents={selectMonthEvents}
          />
        )}
        {!this.props.toCalendar &&
          (isCorpOrExtCourse(eventTypeId) || isCorporateCourseWithoutApprove(eventTypeId)) &&
          !this.state.visibleModalData && (
            <EventModal
              title={isAddToPlan ? <div>{t('description5')}</div> : <div>{t('description6')}</div>}
              isLoadingPlan={this.props.isLoading}
              planInfo={this.props.planInfo}
              handleCalendarModalData={this.handleCalendarModalData}
              {...this.props}
            />
          )}
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([topicsSelect, courseSelect], (topics, course) => ({
  isLoading: topics.isLoading,
  openDate: (course.openDate && course.openDate) || null,
  eventList: course.eventList,
}));

const mapActionsToProps = {
  getRegistrationOpenDate,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('calendarModal')(CalendarModal));
