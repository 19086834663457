/* eslint-disable */

import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearFullname, getStudents, getStudentsList } from '@/store/students/actions';

import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Checkbox, Form, Input, InputNumber, Select } from 'antd';
import FileInput from '@/components/FileInput';
import HoursMinutesInput from '@/components/HoursMinutesInput';
import ScheduleCustom from '@/components/ScheduleCustom';
import TagsInput from '@/components/TagsInput';
import TimeInput from '@/components/TimeInput';

import DatePicker from '@shared/components/DatePicker';
import TagsAutocomplete from '@shared/components/TagsAutocomplete';
import { isConference, isEvent } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import _find from 'lodash/find';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './TopicCreationDialog.module.scss';

import { MAX_LENGTH_COST, MAX_VALUE_COST } from '@/constants/global';
import { FIELDS } from '@/constants/topic-types';

const { TextArea } = Input;

const InputItem = ({
  field,
  topicType,
  files,
  onAuthorsChange,
  onChange,
  topicVideoTypes,
  curTime,
  handleDeleteSchedule,
  handleAddSchedule,
  onChangeSchedule,
  onLinksChange,
  onFilesChange,
  onDateTimeChange,
  topic,
  required,
  topicClasses,
  onYearChange,
  domainCompany,
  hideOnEdit,
  isGlobal,
  addressBookVisible,
  executiveDuration,
  executiveResults,
  execitiveActivity,
  executiveIndicators,
  executiveNeedfile,
  template,
  pokaCategories,
  pokaLevels,
  pokaSites,
  onChangePoka,
}) => {
  const isHideOnEdit = () => topic.id && hideOnEdit;
  const { t } = useTranslation('inputItem');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearFullname());
    };
  }, []);

  const { fullname, students, isLoadingStudents } = useSelector(selectStudents);

  const EVENT = 8; // Тип материала "Событие"

  const handleGetManagers = (e, isSkills = false) => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 &&
        dispatch(
          isSkills
            ? getStudents({ fullName })
            : getStudentsList({
                isManager: false,
                fullName,
                size: 100,
                role: [1, 2],
              })
        );
    }
  };

  const isArrayObjects = arr => {
    return Array.isArray(arr) && arr.every(item => typeof item === 'object' && item !== null);
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetManagers, 1000), []);

  if (field.type === 'files') {
    return (
      <FileInput
        label={field.label}
        required={required}
        topicType={topicType}
        files={files}
        error={topic.errors[field.apiField]}
        onChange={onFilesChange}
        name={field.apiField}
        value={topic[field.responseField]}
      />
    );
  }
  if (field.type === 'tagsInput') {
    const value = Array.isArray(topic[field.apiField])
      ? topic[field.apiField].map(link => link && !link.undeletable && link.name)
      : [];
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TagsInput
          onChange={onLinksChange}
          name={field.apiField}
          placeholder={t('placeholder1')}
          value={value}
          id='linkInput'
        />
      </Form.Item>
    );
  }

  // доработать теги
  if (field.type === 'addressBookInput') {
    const isVisible = addressBookVisible ? 'block' : 'none';
    const value = Array.isArray(topic[field.apiField]) ? topic[field.apiField] : [];

    return (
      <div style={{ display: isVisible }}>
        <Form.Item
          label={field.label}
          required={required}
          help={topic.errors[field.apiField]}
          className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
          labelCol={{ span: 6 }}
        >
          <TagsAutocomplete
            name={field.apiField}
            placeholder={t('placeholder9')}
            onChange={onChange}
            value={value}
            isAddress={true}
            id='addressBookInput'
            t={t}
          />
        </Form.Item>
      </div>
    );
  }
  if (field.type === 'authorsInput') {
    const value = Array.isArray(topic[field.apiField]) ? topic[field.apiField] : [];
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TagsAutocomplete
          id='authorsAutocomplete'
          onChange={onAuthorsChange}
          name={field.apiField}
          value={value}
          // isGlobal={isGlobal}
          isAuthor
          placeholder={t('placeholderOnSearch')}
          t={t}
        />
      </Form.Item>
    );
  }
  if (field.type === 'timeInput') {
    const disabled = isEvent(topic.typeId) || isConference(topic.typeId);

    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TimeInput
          onChange={onChange}
          name={field.apiField}
          value={topic[field.apiField]}
          startWith={field.startWith}
          disabled={disabled}
        />
      </Form.Item>
    );
  }
  if (field.type === 'minNumberInput') {
    return (
      <Form.Item
        label={t('minCost')}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <InputNumber
          min={0}
          data-qa={`minNumberInput${topic.typeId}`}
          className={[css['Add-modal-inputCost']]}
          onChange={value => onChange(value, field.apiField)}
          name={field.apiField}
          value={topic[field.apiField]}
          maxLength={MAX_LENGTH_COST}
          max={MAX_VALUE_COST}
        />
      </Form.Item>
    );
  }
  if (field.type === 'numberInput') {
    const label = field.apiField === 'cost' && topic.typeId === 20 ? t('placeholder2') : t('placeholder3');
    return (
      <Form.Item
        label={label}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
        required={required}
      >
        <InputNumber
          min={0}
          data-qa={`numberInput${topic.typeId}`}
          className={[css['Add-modal-inputCost']]}
          onChange={value => onChange(value, field.apiField)}
          name={field.apiField}
          value={topic[field.apiField]}
          maxLength={field.apiField === 'cost' && MAX_LENGTH_COST}
          max={field.apiField === 'cost' && MAX_VALUE_COST}
          id='costInput'
        />
      </Form.Item>
    );
  }
  if (field.type === 'checkbox') {
    const errorsQiwiLibrary = Boolean(field.apiField === 'qiwiLibrary' && topic.qiwiLibraryError);

    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <Checkbox
          onChange={event => onChange(event?.target.checked, field.apiField)}
          name={field.apiField}
          checked={topic[field.apiField]}
          // errorsQiwiLibrary={errorsQiwiLibrary}
        />
      </Form.Item>
    );
  }
  if (field.type === 'calendar') {
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <DatePicker onChange={onChange} name={field.apiField} value={topic[field.apiField]} initEmpty />
      </Form.Item>
    );
  }
  if (field.type === 'year') {
    const dayjsDate = topic[field.apiField] ? dayjs(topic[field.apiField]) : null;
    const isValid = dayjsDate ? dayjsDate.isValid() : false;
    const yearValue = isValid ? dayjsDate.year() : null;

    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <InputNumber
          onChange={value => onYearChange(value, field.apiField)}
          name={field.apiField}
          value={yearValue}
          min={0}
          max={270000}
          id='yearInput'
        />
      </Form.Item>
    );
  }
  if (field.type === 'topicClassSelector') {
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <Select
          className={css.TopicCreate}
          placeholder={t('placeholder4')}
          onChange={value => onChange(value, field.apiField)}
          name={field.apiField}
          options={topicClasses && topicClasses.map(e => ({ label: e.name, value: e.id }))}
          value={topic[field.apiField]}
          id='periodInput'
        />
      </Form.Item>
    );
  }
  if (field.type === 'videoStyleselector') {
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <Select
          onChange={value => onChange(value, field.apiField)}
          name={field.apiField}
          options={topicVideoTypes}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }
  if (field.type === 'dateTimeInput') {
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <HoursMinutesInput
          onChange={onDateTimeChange}
          name={field.apiField}
          hours={(topic[field.apiField] && topic[field.apiField].split(':')[0]) || ''}
          minutes={(topic[field.apiField] && topic[field.apiField].split(':')[1]) || ''}
        />
      </Form.Item>
    );
  }

  if (field.type === 'schedule') {
    if (isHideOnEdit() && !template) {
      return null;
    }

    let value = Array.isArray(topic[field.apiField]) ? topic[field.apiField].map(schedule => schedule) : [''];
    let disabledDays = null;

    if (value && !value.length) {
      value = [{ dates: [], endTime: '', startTime: '', place: '' }];
    }

    const endDateTime = topic?.endDate && topic?.endTime ? dayjs(`${topic.endDate}T${topic.endTime}+03:00`) : null;
    const isFinished = endDateTime ? endDateTime.isBefore(dayjs()) : false;
    const isFinishedEvent = Boolean(isEvent(topic.typeId) && isFinished);

    if (isConference(topic.typeId)) {
      disabledDays = {
        before: dayjs().add(0, 'days').toDate(),
      };
    }

    return (
      <Form.Item label={t('placeholder5')} required={required} labelCol={{ span: 6 }}>
        <ScheduleCustom
          error={topic.errors[field.apiField]}
          value={value}
          handleDeleteSchedule={handleDeleteSchedule}
          handleAddSchedule={handleAddSchedule}
          curTime={curTime}
          topic={topic}
          onChange={onChangeSchedule}
          onDateChange={onDateTimeChange}
          name={field.apiField}
          domainCompany={domainCompany}
          disabledDays={disabledDays}
          isFinishedEvent={isFinishedEvent}
          isTemplate={template}
        />
      </Form.Item>
    );
  }
  if (field.type === 'comment') {
    if (isHideOnEdit() && !template) {
      return null;
    }
    const value =
      topicType.id === 23
        ? topic[field.apiField] !== null
          ? topic[field.apiField]
          : domainCompany && domainCompany.hasManager
            ? ''
            : ''
        : topic[field.apiField];
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TextArea
          onChange={onChange}
          placeholder={t('placeholder6')}
          maxLength={2000}
          className={css['Add-modal-textarea']}
          rows={8}
          name={field.apiField}
          value={value}
          id='commentInput'
        />
      </Form.Item>
    );
  }

  if (field.type === 'executive_duration') {
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TimeInput onChange={onChange} name={field.type} value={executiveDuration} startWith={field.startWith} />
      </Form.Item>
    );
  }

  if (field.type === 'executive_results') {
    if (isHideOnEdit()) return null;
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TextArea
          onChange={onChange}
          placeholder={t('executive_results_placeholder')}
          maxLength={2000}
          className={css['Add-modal-textarea']}
          rows={8}
          name={field.type}
          value={executiveResults}
          id='executiveResultsInput'
        />
      </Form.Item>
    );
  }

  if (field.type === 'executive_activity') {
    if (isHideOnEdit()) return null;
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TextArea
          onChange={onChange}
          placeholder={t('executive_activity_placeholder')}
          maxLength={2000}
          className={css['Add-modal-textarea']}
          rows={field.rows}
          name={field.type}
          value={execitiveActivity}
          id='executiveActivityInput'
        />
      </Form.Item>
    );
  }

  if (field.type === 'executive_indicators') {
    if (isHideOnEdit()) return null;
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <TextArea
          onChange={onChange}
          placeholder={t('executive_indicators_placeholder')}
          maxLength={2000}
          className={css['Add-modal-textarea']}
          rows={field.rows}
          name={field.type}
          value={executiveIndicators}
          id='executiveIndicatorsInput'
        />
      </Form.Item>
    );
  }

  if (field.type === 'executive_needfile' || field.type === 'poka_needfile') {
    if (isHideOnEdit()) return null;
    return (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <Checkbox
          onChange={event => onChange(event?.target.checked, field.type)}
          name={field.type}
          checked={executiveNeedfile}
        />
      </Form.Item>
    );
  }

  if (field.type === 'webinarPlatform') {
    const isEdit =
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
        ? '2'
        : null;

    !topic[field.apiField] && (topic.webinarPlatform = '1');
    isEdit && (topic.webinarPlatform = isEdit); // Костыль до webinar v2

    return (
      <Form.Item label={field.label} required={required} labelCol={{ span: 6 }}>
        <Select
          key={field.type}
          onChange={value => onChange(value, field.type)}
          disabled={isEdit}
          // inactive={isEdit}
          className={classNames({ [css.isDisabledType]: isEdit })}
          options={[
            { value: '1', label: t('none') },
            { value: '2', label: t('mtcLink') },
          ]}
          id='platformSelect'
          name={field.type}
          value={isEdit ? isEdit : topic[field.apiField]}
        />
      </Form.Item>
    );
  }

  if (field.type === 'entryPlatform') {
    const value =
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
        ? topic.webinarCreateEventResponse[0].access
        : '1';

    const isEdit = Boolean(
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
    );

    !topic[field.apiField] && value && (topic.entryPlatform = value);

    return (
      topic?.webinarPlatform !== '1' && (
        <Form.Item
          label={field.label}
          required={topic.webinarPlatform !== '1'}
          validateStatus={topic.webinarPlatform !== '1' && topic.errors[field.apiField] ? 'error' : ''}
          labelCol={{ span: 6 }}
          help={topic.errors[field.apiField]}
        >
          <Select
            key={field.type}
            onChange={value => onChange(value, field.type)}
            value={topic[field.apiField].toString() || value.toString()}
            disabled={isEdit}
            className={classNames({ [css.isDisabledType]: isEdit })}
            options={[
              { value: '1', label: t('freeAccess') },
              { value: '4', label: t('accessWithRegister') },
            ]}
            name={field.type}
          />
        </Form.Item>
      )
    );
  }

  if (field.type === 'percentSitIn') {
    const value =
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
        ? topic.webinarCreateEventResponse[0].plannedInvolvement
        : 0;

    const isEdit = Boolean(
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
    );

    if (value) topic.percentSitIn = value;

    return (
      topic?.webinarPlatform !== '1' && (
        <Form.Item
          label={field.label}
          required={topic.webinarPlatform !== '1'}
          labelCol={{ span: 6 }}
          validateStatus={topic.webinarPlatform !== '1' && topic.errors[field.apiField] ? 'error' : ''}
          help={topic.errors[field.apiField]}
        >
          <InputNumber
            key={field.type}
            onChange={e => onChange(e, field.type)}
            min={0}
            max={100}
            placeholder={0}
            disabled={isEdit}
            className={classNames({ [css.isDisabledType]: isEdit })}
            name={field.type}
            value={topic?.webinarCreateEventResponse?.[0]?.plannedInvolvement}
          />
        </Form.Item>
      )
    );
  }

  if (field.type === 'webinarType') {
    const value =
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
        ? topic.webinarCreateEventResponse[0].webinarType
        : 'WEBINAR';

    const isEdit = Boolean(
      topic && Array.isArray(topic.webinarCreateEventResponse) && topic.webinarCreateEventResponse.length > 0
    );

    !topic[field.apiField] && value && (topic.webinarType = value);

    return (
      topic?.webinarPlatform !== '1' && (
        <Form.Item
          label={field.label}
          required={topic.webinarPlatform !== '1'}
          validateStatus={topic.webinarPlatform !== '1' && topic.errors[field.apiField] ? 'error' : ''}
          labelCol={{ span: 6 }}
          help={topic.errors[field.apiField]}
        >
          <Select
            key={field.type}
            onChange={value => onChange(value, field.type)}
            value={topic[field.apiField] || value}
            disabled={isEdit}
            // inactive={isEdit}
            className={classNames({ [css.isDisabledType]: isEdit })}
            options={[
              { value: 'WEBINAR', label: t('webinar') },
              { value: 'MEETING', label: t('meeting') },
              { value: 'TRAINING', label: t('training') },
            ]}
            name={field.type}
          />
        </Form.Item>
      )
    );
  }

  if (field.type === 'participantsCount') {
    if (isHideOnEdit() && !template) {
      return null;
    }

    const name = topic.typeId === EVENT ? field.responseField : field.apiField;
    const value = topic[name];

    return (
      <Form.Item
        label={t('placeholder7')}
        help={topic.errors[name]}
        required={required}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[name] })}
        labelCol={{ span: 6 }}
      >
        <Input
          className={css['Event-create-count']}
          type='number'
          name={name}
          placeholder='000'
          onChange={onChange}
          value={value}
          id='usersCountInput'
        />
      </Form.Item>
    );
  }

  if (field.type === 'manager') {
    if (isHideOnEdit()) return null;

    const manager = _find(fullname, manager => manager.id === topic[field.apiField]);
    const managerId = _get(manager, 'id');

    return (
      <Form.Item label={t('placeholder8')} colon={false} labelCol={{ span: 6 }}>
        <Select
          onChange={value => onChange(+value, field.apiField)}
          value={managerId}
          name={field.apiField}
          options={fullname}
          allowClear
          onSearch={getStudentsListDebounced}
          showSearch
          // showArrow={!Boolean(managerId)}
          placeholder={t('placeholderOnSearch')}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_category') {
    return (
      <Form.Item required={required} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <Select
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? topic?.poka[field.apiField] : undefined}
          placeholder={t('notSelected')}
          name={field.apiField}
          options={pokaCategories}
          mode='multiple'
          allowClear
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_site') {
    const selectValue = isArrayObjects(topic?.poka[field.apiField])
      ? topic?.poka[field.apiField]?.map(({ id }) => id)
      : topic?.poka[field.apiField];

    return (
      <Form.Item required={required} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <Select
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? selectValue : undefined}
          placeholder={t('notSelected')}
          name={field.apiField}
          options={pokaSites}
          mode='multiple'
          allowClear
          filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_assessment') {
    return (
      <Form.Item required={required} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <Select
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? topic?.poka[field.apiField] : undefined}
          placeholder={t('notSelected')}
          name={field.apiField}
          options={pokaLevels}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_revision') {
    return (
      <Form.Item required={required} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <InputNumber
          className={css['InputNumber']}
          placeholder={t('selectCountDays')}
          name={field.apiField}
          min={1}
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? topic?.poka[field.apiField] : undefined}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_priority') {
    return (
      <Form.Item required={required} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <InputNumber
          className={css['InputNumber']}
          placeholder={t('selectCountDays')}
          name={field.apiField}
          min={1}
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? topic?.poka[field.apiField] : undefined}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_cyclicality') {
    return (
      <Form.Item required={required} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <InputNumber
          className={css['InputNumber']}
          placeholder={t('selectCountDays')}
          name={field.apiField}
          min={1}
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? topic?.poka[field.apiField] : undefined}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_approval') {
    return (
      <Form.Item label={field.label} colon={false} labelCol={{ span: 6 }}>
        <Checkbox
          onChange={e => onChangePoka(e.target.checked, field.apiField)}
          checked={topic?.poka ? topic?.poka[field.apiField] : undefined}
          name={field.apiField}
        />
      </Form.Item>
    );
  }

  if (field.type === 'poka_assessors') {
    const selectValue = isArrayObjects(topic?.poka[field.apiField])
      ? topic?.poka[field.apiField]?.map(({ id }) => id)
      : topic?.poka[field.apiField];

    return (
      <Form.Item required={!topic?.poka?.withoutValidation} label={field.label} colon={false} labelCol={{ span: 6 }}>
        <Select
          dropdownAlign={{ overflow: 'visible', points: ['bc', 'tc'] }}
          placeholder={t('placeholderOnSearch')}
          showSearch
          options={
            students?.length
              ? students?.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }))
              : topic?.poka[field.apiField]?.map(({ id, name }) => ({ value: id, label: name }))
          }
          onSearch={e => getStudentsListDebounced(e, true)}
          name={field.apiField}
          loading={isLoadingStudents}
          onChange={value => onChangePoka(value, field.apiField)}
          value={topic?.poka ? selectValue : undefined}
          mode='multiple'
          disabled={topic?.poka?.withoutValidation}
          filterOption={(input, option) =>
            option?.value ? option.label.toLowerCase().includes(input.toLowerCase()) : null
          }
        />
      </Form.Item>
    );
  }

  return (
    !isHideOnEdit() && (
      <Form.Item
        label={field.label}
        required={required}
        help={topic.errors[field.apiField]}
        className={classNames({ [css['Add-modal-fromItem-error']]: topic.errors[field.apiField] })}
        labelCol={{ span: 6 }}
      >
        <Input
          maxLength={field.apiField === 'meetPlace' ? 100 : 255}
          onChange={onChange}
          name={field.apiField}
          value={topic[field.apiField]}
          id={field.apiField === 'meetPlace' ? 'meetPlaceInput' : 'companyInput'}
        />
      </Form.Item>
    )
  );
};

InputItem.propTypes = {
  curTime: PropTypes.object,
  domainCompany: PropTypes.object,
  field: PropTypes.object,
  files: PropTypes.array,
  handleAddSchedule: PropTypes.func,
  handleDeleteSchedule: PropTypes.func,
  onAuthorsChange: PropTypes.func,
  onChange: PropTypes.func,
  onChangeSchedule: PropTypes.func,
  onChangePoka: PropTypes.func,
  onDateTimeChange: PropTypes.func,
  onFilesChange: PropTypes.func,
  onLinksChange: PropTypes.func,
  onYearChange: PropTypes.func,
  required: PropTypes.bool,
  topic: PropTypes.object,
  topicClasses: PropTypes.array,
  topicType: PropTypes.object,
  topicVideoTypes: PropTypes.array,
  isGlobal: PropTypes.bool,
  addressBookVisible: PropTypes.bool,
  template: PropTypes.bool,
};

export const InputList = props => {
  const { t } = useTranslation(['constants']);
  const { fields, topicType, template, ...rest } = props;

  const inputs =
    fields &&
    fields.map((field, key) => {
      if (field.type === 'header' && !(props.topic.id && field.hideOnEdit)) {
        return (
          <div key={key} className={classNames(css['Add-modal-header'], css['Add-modal-header_in-block'])}>
            {field.text}
          </div>
        );
      }

      const fieldObject = FIELDS(t)[field.type];
      return (
        !!fieldObject && (
          <InputItem
            key={fieldObject.apiField}
            field={fieldObject}
            topicType={topicType}
            files={props.files}
            required={field.required}
            hideOnEdit={field.hideOnEdit}
            addressBookVisible={props.addressBookVisible}
            executiveDuration={props.executiveDuration}
            executiveResults={props.executiveResults}
            execitiveActivity={props.executiveActivity}
            executiveIndicators={props.executiveIndicators}
            executiveNeedfile={props.executiveNeedfile}
            template={props.template}
            {...rest}
          />
        )
      );
    });

  return <div>{inputs}</div>;
};

InputList.propTypes = {
  curTime: PropTypes.object,
  domainCompany: PropTypes.object,
  fields: PropTypes.array,
  files: PropTypes.array,
  handleAddSchedule: PropTypes.func,
  handleDeleteSchedule: PropTypes.func,
  onAuthorsChange: PropTypes.func,
  onChange: PropTypes.func,
  onChangeSchedule: PropTypes.func,
  onFilesChange: PropTypes.func,
  onChangePoka: PropTypes.func,
  onLinksChange: PropTypes.func,
  topicClasses: PropTypes.array,
  topicType: PropTypes.object,
  topicVideoTypes: PropTypes.array,
  onChangeBookAddresses: PropTypes.func,
  executiveDuration: PropTypes.number,
  executiveResults: PropTypes.string,
  execitiveActivity: PropTypes.string,
  executiveIndicators: PropTypes.string,
  executiveNeedfile: PropTypes.bool,
  template: PropTypes.bool,
};

export default InputList;
