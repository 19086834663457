export const PlanStatus = {
  SCHEDULED: 'SCHEDULED',
  PLANNED: 'PLANNED',
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  APPROVED_BY_MANAGER: 'APPROVED_BY_MANAGER',
  REJECTED: 'REJECTED',
  REJECTED_BY_MANAGER: 'REJECTED_BY_MANAGER',
  WAITING_BY_USER: 'WAITING_BY_USER',
  APPROVED_BY_USER: 'APPROVED_BY_USER',
  ON_REGISTRATION: 'ON_REGISTRATION',
  REJECTED_BY_USER: 'REJECTED_BY_USER',
  IN_PROGRESS: 'IN_PROGRESS',
  SKIPPED_BY_USER: 'SKIPPED_BY_USER',
  EXPIRED: 'EXPIRED',
  FINISHED: 'FINISHED',
};
