/* eslint-disable */
import { Component } from 'react';
import { connect } from 'react-redux';

import { getUserStat } from '@/store/users/actions';

import { Table } from 'antd';

import PropTypes from 'prop-types';

const { Column } = Table;

export class SkillList extends Component {
  static propTypes = {
    onSelectSkills: PropTypes.func,
    competence: PropTypes.object,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hasErrors: false,
      skills: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true, hasError: false });
    this.props.getUserStat(
      this.props.match.params.id,
      res => {
        if (res && res.user) {
          this.loadSKills(res.user.id);
        }
      },
      err => {
        this.setState({ isLoading: false, hasErrors: true });
        console.error('error:', err);
      }
    );
  }

  loadSKills = uid => {
    const { competence } = this.props;

    this.setState({ isLoading: true });
  };

  handleChange = (selectedRowKeys, selectedRows) => {
    if (typeof selectedRows === 'object' && typeof this.props.onSelectSkills === 'function') {
      this.props.onSelectSkills(selectedRows);
    }
  };

  render() {
    const { isLoading, skills } = this.state;
    const { t } = this.props;
    return (
      <>
        <Table
          ellipsis={true}
          bordered={false}
          style={{ width: '100%' }}
          loading={isLoading}
          dataSource={skills}
          pagination={{
            pageSize: 6,
            style: { padding: '1rem' },
          }}
          onSelect={this.handleChange}
          rowSelection={{
            type: 'check',
            onChange: this.handleChange,
          }}
        >
          <Column title={t('skills')} dataIndex='name' key='skills' filterSearch={false} />
        </Table>
      </>
    );
  }
}

const mapActionsToProps = { getUserStat };

export default connect(null, mapActionsToProps)(SkillList);
