export const GET_WEBINAR_TOKEN_START = 'GET_WEBINAR_TOKEN_START';
export const GET_WEBINAR_TOKEN_SUCCESS = 'GET_WEBINAR_TOKEN_SUCCESS';
export const GET_WEBINAR_TOKEN_FAIL = 'GET_WEBINAR_TOKEN_FAIL';

export const UPDATE_WEBINAR_TOKEN_START = 'UPDATE_WEBINAR_TOKEN_START';
export const UPDATE_WEBINAR_TOKEN_SUCCESS = 'UPDATE_WEBINAR_TOKEN_SUCCESS';
export const UPDATE_WEBINAR_TOKEN_FAIL = 'UPDATE_WEBINAR_TOKEN_FAIL';

export const UPDATE_TYPEFORM_TOKEN_START = 'UPDATE_TYPEFORM_TOKEN_START';
export const UPDATE_TYPEFORM_TOKEN_SUCCESS = 'UPDATE_TYPEFORM_TOKEN_SUCCESS';
export const UPDATE_TYPEFORM_TOKEN_FAIL = 'UPDATE_TYPEFORM_TOKEN_FAIL';

export const GET_TYPEFORM_TOKEN_START = 'GET_TYPEFORM_TOKEN_START';
export const GET_TYPEFORM_TOKEN_SUCCESS = 'GET_TYPEFORM_TOKEN_SUCCESS';
export const GET_TYPEFORM_TOKEN_FAIL = 'GET_TYPEFORM_TOKEN_FAIL';

export const GET_BITRIX_WEBHOOK_START = 'GET_BITRIX_WEBHOOK_START';
export const GET_BITRIX_WEBHOOK_SUCCESS = 'GET_BITRIX_WEBHOOK_SUCCESS';
export const GET_BITRIX_WEBHOOK_FAIL = 'GET_BITRIX_WEBHOOK_FAIL';

export const UPDATE_BITRIX_WEBHOOK_START = 'UPDATE_BITRIX_WEBHOOK_START';
export const UPDATE_BITRIX_WEBHOOK_SUCCESS = 'UPDATE_BITRIX_WEBHOOK_SUCCESS';
export const UPDATE_BITRIX_WEBHOOK_FAIL = 'UPDATE_BITRIX_WEBHOOK_FAIL';
