import { API_D360_URL } from '../service_api';
import { D360_STATE_FAIL, D360_STATE_START, D360_STATE_SUCCESS } from './constants';

import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360ProjectState = (
  projectId,
  page = 1,
  pageSize = 10,
  sortColumn = 'id',
  sortOrder = 'DESC',
  filterColumn = 'id',
  filterValue = '',
  onSuccess,
  onError
) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/project/respondent/status${getQueryString({
      id: projectId,
      page: page,
      pageSize: pageSize,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      filterColumn: filterColumn,
      filterValue: filterValue,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_STATE_START, D360_STATE_SUCCESS, D360_STATE_FAIL],
    onSuccess,
    onError,
  };
};
