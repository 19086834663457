import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteGroup, getGroups, updateGroup } from '@/store/groups/actions';

import { selectGroups } from '@/store/groups/selectors';
import { selectUsers } from '@/store/users/selectors';

import { message, Modal, Table, Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import { PAGE_SIZE } from '../constants';
import ModalGroup from './ModalGroup';

import css from '../CompetenceGroupsDirectory.module.scss';

import { COMPETENCE_GROUP_DELETE, COMPETENCE_GROUP_UPDATE } from '@/constants/permissions';

const TableGroups = () => {
  const { t } = useTranslation('CompetenceGroupsDirectory');
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUsers);
  const [modal, contextHolder] = Modal.useModal();

  const [isGroup, setIsGroup] = useState({ open: false, name: '', id: null });

  const {
    groupsLoading,
    groupsTable,
    groupsTotal,
    groupsPageSize,
    groupsPageNumber,
    groupsCurrentPage,
    groupsFilters,
  } = useSelector(selectGroups);

  const columns = [
    {
      title: t('groupName'),
      dataIndex: 'name',
      key: '1',
    },
    {
      title: t('action'),
      dataIndex: 'action',
      key: '2',
      width: 100,
      align: 'center',
      render: (_, item) => (
        <div className={css['Table-actions']}>
          {currentUser?.authorities?.includes(COMPETENCE_GROUP_UPDATE) && (
            <Tooltip title={t('editInfo')}>
              <FormOutlined className={css['Table-actions-edit']} onClick={() => setIsGroup({ open: true, ...item })} />
            </Tooltip>
          )}
          {currentUser?.authorities?.includes(COMPETENCE_GROUP_DELETE) && (
            <Tooltip title={t('deleteInfo')}>
              <DeleteOutlined className={css['Table-actions-delete']} onClick={() => onDeleteGroup(item)} />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const pagination = {
    total: groupsTotal,
    pageSize: groupsPageSize,
    pageNumber: groupsPageNumber,
    current: groupsCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const dataSource = useMemo(
    () => groupsTable?.length > 0 && groupsTable.map(item => ({ name: item.name, id: item.id })),
    [groupsTable]
  );

  const onChangeTable = pagination => dispatch(getGroups(groupsFilters ?? {}, pagination.current - 1, PAGE_SIZE));

  const onUpdateGroup = data => {
    if (data?.name && isGroup?.id) {
      dispatch(
        updateGroup(isGroup.id, data, () => {
          dispatch(getGroups(groupsFilters, groupsCurrentPage, PAGE_SIZE));
          setIsGroup({ open: false, name: '', id: null });
        })
      );
    } else {
      message.warning(t('warningCreateGroup'));
    }
  };

  const onDeleteGroup = item => {
    modal.error({
      title: t('deleteGroup'),
      content: t('descDeleteGroup'),
      closable: true,
      onOk() {
        dispatch(
          deleteGroup(item?.id, () => {
            dispatch(getGroups(groupsFilters, groupsCurrentPage, PAGE_SIZE));
          })
        );
      },
      okText: t('delete'),
      okButtonProps: { danger: true },
    });
  };

  return (
    <div className={css['Table']}>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={e => e?.id}
        pagination={pagination}
        onChange={onChangeTable}
        loading={groupsLoading}
      />

      {isGroup.open && (
        <ModalGroup
          open={isGroup.open}
          group={isGroup}
          onCancel={() => setIsGroup({ open: false, name: '', id: null })}
          onOkText={t('updateOk')}
          title={t('updateTitle')}
          onOk={onUpdateGroup}
        />
      )}

      {contextHolder}
    </div>
  );
};

export default TableGroups;
