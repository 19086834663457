// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Gamification-Gamification-module__ql-picker-HkLNah.admin-src-pages-Gamification-Gamification-module__ql-font-gyXQrV .admin-src-pages-Gamification-Gamification-module__ql-picker-label-RkRS65[data-value="Montserrat"]:before, .admin-src-pages-Gamification-Gamification-module__ql-picker-HkLNah.admin-src-pages-Gamification-Gamification-module__ql-font-gyXQrV .admin-src-pages-Gamification-Gamification-module__ql-picker-item-R_LjXP[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Gamification-Gamification-module__ql-font-Montserrat-fRk5zl {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-header-B8tNca h1 {
  margin-bottom: 40px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-jbnQCF {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-esZuPK {
  margin-top: 24px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-icon-qm96Tl {
  font-size: 24px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-txt-xRnWI8 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-col-wLFD0h {
  margin-bottom: 16px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-col-label-eRGi16 {
  margin-bottom: 8px;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-footer-T7r2x1 {
  justify-content: flex-end;
  margin-top: 12px;
  display: flex;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-jbnQCF .ant-collapse-header {
  padding: 10px 0;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-jbnQCF .ant-collapse-content-box {
  padding: 0;
}

.admin-src-pages-Gamification-Gamification-module__CoinSettings-content-jbnQCF .ant-input-number {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Gamification/Gamification.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;AACrB;;AAMA;EAHE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;;AAKA;EAFE,gBAAgB;AAClB;;AAIA;EADE,eAAe;AACjB;;AAGA;EAAE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AACA;EAEE,kBAAkB;AACpB;;AAAA;EAGE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;AACf;;AADA;EAIE,eAAe;AACjB;;AAFA;EAKE,UAAU;AACZ;;AAHA;EAME,WAAW;AACb","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.CoinSettings-header h1 {\n  margin-bottom: 40px;\n}\n.CoinSettings-content {\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 20px;\n}\n.CoinSettings-content-collapse {\n  margin-top: 24px;\n}\n.CoinSettings-content-collapse-icon {\n  font-size: 24px;\n}\n.CoinSettings-content-collapse-txt {\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n}\n.CoinSettings-content-col {\n  margin-bottom: 16px;\n}\n.CoinSettings-content-col-label {\n  margin-bottom: 8px;\n}\n.CoinSettings-content-footer {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 12px;\n}\n.CoinSettings-content :global .ant-collapse-header {\n  padding: 10px 0;\n}\n.CoinSettings-content :global .ant-collapse-content-box {\n  padding: 0;\n}\n.CoinSettings-content :global .ant-input-number {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Gamification-Gamification-module__ql-picker-HkLNah`,
	"qlPicker": `admin-src-pages-Gamification-Gamification-module__ql-picker-HkLNah`,
	"ql-font": `admin-src-pages-Gamification-Gamification-module__ql-font-gyXQrV`,
	"qlFont": `admin-src-pages-Gamification-Gamification-module__ql-font-gyXQrV`,
	"ql-picker-label": `admin-src-pages-Gamification-Gamification-module__ql-picker-label-RkRS65`,
	"qlPickerLabel": `admin-src-pages-Gamification-Gamification-module__ql-picker-label-RkRS65`,
	"ql-picker-item": `admin-src-pages-Gamification-Gamification-module__ql-picker-item-R_LjXP`,
	"qlPickerItem": `admin-src-pages-Gamification-Gamification-module__ql-picker-item-R_LjXP`,
	"ql-font-Montserrat": `admin-src-pages-Gamification-Gamification-module__ql-font-Montserrat-fRk5zl`,
	"qlFontMontserrat": `admin-src-pages-Gamification-Gamification-module__ql-font-Montserrat-fRk5zl`,
	"CoinSettings-header": `admin-src-pages-Gamification-Gamification-module__CoinSettings-header-B8tNca`,
	"coinSettingsHeader": `admin-src-pages-Gamification-Gamification-module__CoinSettings-header-B8tNca`,
	"CoinSettings-content": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-jbnQCF`,
	"coinSettingsContent": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-jbnQCF`,
	"CoinSettings-content-collapse": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-esZuPK`,
	"coinSettingsContentCollapse": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-esZuPK`,
	"CoinSettings-content-collapse-icon": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-icon-qm96Tl`,
	"coinSettingsContentCollapseIcon": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-icon-qm96Tl`,
	"CoinSettings-content-collapse-txt": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-txt-xRnWI8`,
	"coinSettingsContentCollapseTxt": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-collapse-txt-xRnWI8`,
	"CoinSettings-content-col": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-col-wLFD0h`,
	"coinSettingsContentCol": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-col-wLFD0h`,
	"CoinSettings-content-col-label": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-col-label-eRGi16`,
	"coinSettingsContentColLabel": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-col-label-eRGi16`,
	"CoinSettings-content-footer": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-footer-T7r2x1`,
	"coinSettingsContentFooter": `admin-src-pages-Gamification-Gamification-module__CoinSettings-content-footer-T7r2x1`
};
export default ___CSS_LOADER_EXPORT___;
