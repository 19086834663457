import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getPlanList, refreshPlan } from '@/store/quartz/actions';

import { selectQuartz } from '@/store/quartz/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Card, Col, Divider, Empty, notification, Row, Tooltip, Typography } from 'antd';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';

import { QUARTZ_TEST } from '../../../../constants/permissions';

import css from '../TabsVarDirectory.module.scss';

const TabPlanSettings = () => {
  const dispatch = useDispatch();
  const { isLoadingButtons, planList } = useSelector(selectQuartz);
  const { currentUser } = useSelector(selectUsers);
  const { Text } = Typography;
  const { t } = useTranslation('varDirectory');

  useEffect(() => {
    dispatch(getPlanList());
  }, [dispatch]);

  const onRefreshPlan = name => {
    dispatch(
      refreshPlan(name, () => {
        notification.success({
          message: t('planRefreshSuccess', { name }),
        });
      }),
      () => {
        notification.error({
          message: t('planRefreshFail', { name }),
        });
      }
    );
  };

  return (
    <Row className={css['TabMargin']}>
      <Col span={24}>
        <Card bordered={false} className={css['TabMargin-card']}>
          {planList ? (
            planList.map((item, index) => (
              <div key={index}>
                <div className={css['TabMargin-content']}>
                  <Text>{item}</Text>
                  {currentUser.authorities.includes(QUARTZ_TEST) && (
                    <Tooltip title={t('refreshPlan')}>
                      <Button
                        type='primary'
                        loading={isLoadingButtons[item]}
                        onClick={() => onRefreshPlan(item)}
                        icon={isLoadingButtons[item] ? <LoadingOutlined /> : <SyncOutlined />}
                      />
                    </Tooltip>
                  )}
                </div>
                {index !== planList.length - 1 && <Divider />}
              </div>
            ))
          ) : (
            <Empty description={<Text>{t('plansEmpty')}</Text>} />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TabPlanSettings;
