/* eslint-disable */
import {
  CHANGE_ORDER_FAIL,
  CHANGE_ORDER_START,
  CHANGE_ORDER_SUCCESS,
  CLEAR_ORDER_FILTERS,
  EXPORT_ORDERS_FAIL,
  EXPORT_ORDERS_START,
  EXPORT_ORDERS_SUCCESS,
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_START,
  GET_ORDER_HISTORY_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  POST_ORDERS_FILTERS_FAIL,
  POST_ORDERS_FILTERS_START,
  POST_ORDERS_FILTERS_SUCCESS,
  SAVE_ORDER_FILTERS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  isLoadingTable: false,
  ordersTable: null,
  ordersTotal: null,
  ordersPageSize: null,
  ordersPageNumber: null,
  ordersCurrentPage: null,
  orderFilters: [],
  status: [],
  orderHistory: [],
  exportLoading: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_STATUS_START:
    case GET_ORDER_HISTORY_START:
    case CHANGE_ORDER_START:
      return { ...state, isLoading: true };

    case POST_ORDERS_FILTERS_START:
      return { ...state, isLoading: true, isLoadingTable: true };

    case EXPORT_ORDERS_START:
      return { ...state, exportLoading: true };

    case POST_ORDERS_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingTable: false,
        ordersTable: response.content,
        ordersTotal: response.totalElements,
        ordersPageSize: response.size,
        ordersPageNumber: response.totalPages,
        ordersCurrentPage: response.number,
      };

    case GET_STATUS_SUCCESS:
      return { ...state, isLoading: false, status: response };

    case GET_ORDER_HISTORY_SUCCESS:
      return { ...state, isLoading: false, orderHistory: response };

    case CHANGE_ORDER_SUCCESS:
      return { ...state, isLoading: false };

    case EXPORT_ORDERS_SUCCESS:
      return { ...state, exportLoading: false };

    case GET_STATUS_FAIL:
      return { ...state, isLoading: false, isError: true };

    case POST_ORDERS_FILTERS_FAIL:
    case GET_ORDER_HISTORY_FAIL:
    case CHANGE_ORDER_FAIL:
      return { ...state, isLoading: false, isError: true, isLoadingTable: false };

    case EXPORT_ORDERS_FAIL:
      return { ...state, exportLoading: false, isError: true };

    case SAVE_ORDER_FILTERS:
      const filters = { ...state.orderFilters, filters: value };
      return { ...state, orderFilters: filters };

    case CLEAR_ORDER_FILTERS:
      const clearFilters = { ...state.orderFilters, filters: value };
      return { ...state, orderFilters: clearFilters };

    default:
      return { ...state };
  }
};
