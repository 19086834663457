/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Divider, Tabs } from 'antd';

import InputList from './InputList';

import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './TopicCreationDialog.module.scss';

import { TOPIC_SUBTYPES, TOPIC_TYPES } from '@/constants/topic-types';

const { TabPane } = Tabs;

export class AdditionalInputCard extends Component {
  constructor(props) {
    super(props);
    const topicType = _.find(TOPIC_TYPES(this.props.t), type => type.id === +props.topicTypeId);
    this.state = {
      topicType: topicType || {},
      curTime: dayjs(),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.topicTypeId !== prevProps.topicTypeId) {
      const topicType = _.find(TOPIC_TYPES(this.props.t), type => type.id === +this.props.topicTypeId);
      this.setState({ topicType });
    }
  }

  handleTabChange = tab => {
    this.props.onChangeSubType({ name: 'subTypeId', value: Number(tab) });
  };

  render() {
    const { topicType } = this.state;
    const { topicSubTypes, topicSubTypeId, editable, topicTypeId, template } = this.props;

    if (!topicTypeId || !topicSubTypes) {
      return null;
    }

    const tabs = topicSubTypes
      .map(tab => {
        const topicSubType = _.find(TOPIC_SUBTYPES(this.props.t), subtype => subtype.id === tab.id) || {};
        return topicSubType.active
          ? {
              key: tab.id.toString(),
              label: tab.name,
              children: <InputList fields={topicSubType.fields} {...this.props} />,
            }
          : null;
      })
      .filter(tab => !!tab);

    if (tabs.length) {
      return (
        <>
          <Divider />
          <div className={css['Add-modal']}>
            <Tabs
              animated={false}
              defaultActiveKey={
                (!editable && topicTypeId === 20 && '22') ||
                (!editable && isCorpCourseWithOrWithoutApprove(topicTypeId) && '19') ||
                (topicSubTypeId && topicSubTypeId.toString())
              }
              {...(editable && {
                activeKey: topicSubTypeId && topicSubTypeId.toString(),
              })}
              className={css['Add-modal-tabs']}
              onChange={this.handleTabChange}
              items={tabs}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <Divider />
        <div>
          <InputList
            fields={topicType.fields}
            topicType={topicType}
            files={this.props.files}
            curTime={this.state.curTime}
            addressBookVisible={this.props.addressBookVisible}
            executiveDuration={this.props.executiveDuration}
            executiveResults={this.props.executiveResults}
            executiveActivity={this.props.execitiveActivity}
            executiveIndicators={this.props.executiveIndicators}
            template={!!template}
            {...this.props}
          />
        </div>
      </>
    );
  }
}

AdditionalInputCard.propTypes = {
  domainCompany: PropTypes.object,
  editable: PropTypes.bool,
  files: PropTypes.array,
  handleAddSchedule: PropTypes.func,
  handleDeleteSchedule: PropTypes.func,
  onAuthors: PropTypes.func,
  onChange: PropTypes.func,
  onChangeSubType: PropTypes.func,
  onChangeSchedule: PropTypes.func,
  onChangePoka: PropTypes.func,
  onFilesChange: PropTypes.func,
  onLinksChange: PropTypes.func,
  topicSubTypeId: PropTypes.number,
  topicSubTypes: PropTypes.array,
  topicTypeId: PropTypes.number,
  topicVideoTypes: PropTypes.array,
  isGlobal: PropTypes.bool,
  addressBookVisible: PropTypes.bool,
  onChangeBookAddresses: PropTypes.func,
  executiveDuration: PropTypes.number,
  executiveResults: PropTypes.string,
  executiveActivity: PropTypes.string,
  executiveIndicators: PropTypes.string,
};

export default withTranslation(['constants', 'constants'])(AdditionalInputCard);
