/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_BOOK_ADDRESSES_START = 'GET_BOOK_ADDRESSES_START';
export const GET_BOOK_ADDRESSES_SUCCESS = 'GET_BOOK_ADDRESSES_SUCCESS';
export const GET_BOOK_ADDRESSES_FAIL = 'GET_BOOK_ADDRESSES_FAIL';

export const SAVE_BOOK_ADDRESSES_START = 'SAVE_BOOK_ADDRESSES_START';
export const SAVE_BOOK_ADDRESSES_SUCCESS = 'SAVE_BOOK_ADDRESSES_SUCCESS';
export const SAVE_BOOK_ADDRESSES_FAIL = 'SAVE_BOOK_ADDRESSES_FAIL';

export const GET_BOOKS_INUSE_START = 'GET_BOOKS_INUSE_START';
export const GET_BOOKS_INUSE_SUCCESS = 'GET_BOOKS_INUSE_SUCCESS';
export const GET_BOOKS_INUSE_FAIL = 'GET_BOOKS_INUSE_FAIL';
export const CLEAR_BOOKS_INUSE = 'CLEAR_BOOKS_INUSE';

export const GET_ALL_BOOK_ADDRESSES_START = 'GET_ALL_BOOK_ADDRESSES_START';
export const GET_ALL_BOOK_ADDRESSES_SUCCESS = 'GET_ALL_BOOK_ADDRESSES_SUCCESS';
export const GET_ALL_BOOK_ADDRESSES_FAIL = 'GET_ALL_BOOK_ADDRESSES_FAIL';

export const CHANGE_BOOK_ADDRESSES_START = 'CHANGE_BOOK_ADDRESSES_START';
export const CHANGE_BOOK_ADDRESSES_SUCCESS = 'CHANGE_BOOK_ADDRESSES_SUCCESS';
export const CHANGE_BOOK_ADDRESSES_FAIL = 'CHANGE_BOOK_ADDRESSES_FAIL';

export const FREE_BOOK_START = 'FREE_BOOK_START';
export const FREE_BOOK_SUCCESS = 'FREE_BOOK_SUCCESS';
export const FREE_BOOK_FAIL = 'FREE_BOOK_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getAllBookAddresses = (address = '', page = 0, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/book/address${getQueryString({ address, page, size: 20 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ALL_BOOK_ADDRESSES_START, GET_ALL_BOOK_ADDRESSES_SUCCESS, GET_ALL_BOOK_ADDRESSES_FAIL],
  onSuccess,
  onError,
});

/**
 * изменить адреса книг в топике
 *
 *
 */
export const changeBookAddress = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/book/admin/change`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  onSuccess,
  onError,
  types: [CHANGE_BOOK_ADDRESSES_START, CHANGE_BOOK_ADDRESSES_SUCCESS, CHANGE_BOOK_ADDRESSES_FAIL],
});

export const getBookAddresses = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/book/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BOOK_ADDRESSES_START, GET_BOOK_ADDRESSES_SUCCESS, GET_BOOK_ADDRESSES_FAIL],
  onSuccess,
  onError,
});

export const saveBookAddresses = (topicId, addresses, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/book/admin/create`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    topicId,
    addressDtos: addresses,
  },
  onSuccess,
  onError,
  types: [SAVE_BOOK_ADDRESSES_START, SAVE_BOOK_ADDRESSES_SUCCESS, SAVE_BOOK_ADDRESSES_FAIL],
});

export const getBookInUse = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/book/admin/inuse/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  types: [GET_BOOKS_INUSE_START, GET_BOOKS_INUSE_SUCCESS, GET_BOOKS_INUSE_FAIL],
});

/**
 * body:
 * { topicId: number, userIds: number[] } для определенных пользователей
 * { topicId: number, forAll: boolean } для всех пользователей
 * @param {object} body
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
export const freeBook = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/book/free`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  onSuccess,
  onError,
  types: [FREE_BOOK_START, FREE_BOOK_SUCCESS, FREE_BOOK_FAIL],
});

export const clearBooksInUse = () => ({
  type: CLEAR_BOOKS_INUSE,
});
