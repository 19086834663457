import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectPokaDirPositions } from '@/store/poka_directory/selectors';

import { Button, Col, Form, Modal, Row, Select } from 'antd';

import PropTypes from 'prop-types';

import css from './Dictionary.module.scss';

const ModalPositionAssignment = ({ open, onCancel, onOk, positionData }) => {
  const { t } = useTranslation('dictionaryTable');
  const [form] = Form.useForm();

  const { pokaDirPositions } = useSelector(selectPokaDirPositions);

  const handleSubmit = data => onOk(positionData.id, data);

  const positionOptions =
    Array.isArray(pokaDirPositions) &&
    pokaDirPositions.length > 0 &&
    pokaDirPositions.map(item => (
      <Select.Option key={item.value} value={item.value}>
        {item.label}
      </Select.Option>
    ));

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={<div className={css['Modal-title']}>{t('assignTypicalPosition')}</div>}
      footer={null}
      className={css['Modal']}
      centered
      width='800px'
    >
      <Form layout='vertical' form={form} onFinish={handleSubmit}>
        <Row gutter={16} className={css['Modal-row']}>
          <Col span={24}>
            <Form.Item
              label={t('selectNamePosition')}
              colon={false}
              name='pokaPositionId'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select
                placeholder={t('placeholderSelectNamePosition')}
                allowClear
                showSearch
                optionFilterProp='children'
              >
                {positionOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='end' gutter={22}>
          <Col>
            <Button onClick={onCancel}>{t('cancel')}</Button>
          </Col>
          <Col>
            <Button htmlType='submit' type='primary'>
              {t('onOkText')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalPositionAssignment.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  positionData: PropTypes.object,
};

export default ModalPositionAssignment;
