/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360GetFilters, D360GetFilterTips } from '@/store/d360/d360_filters/actions';

import { AutoComplete, Button, Checkbox, Col, Row, Select } from 'antd';
import { HiOutlineSearch, HiOutlineX } from 'react-icons/hi';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const ButtonGroup = Button.Group;
const { Option } = Select;

const EmptyFilter = {
  first_name: null,
  last_name: null,
  middle_name: null,
  email: null,
  department: null,
  position: null,
  function: null,
};

const EmptyTips = {
  firstNames: [],
  lastNames: [],
  middleNames: [],
  emails: [],
};

export const ManualFilter = props => {
  const [disableFindButton, setDisableFindButton] = useState(true);
  const [tips, setTips] = useState(EmptyTips);
  const [filter, setFilter] = useState(EmptyFilter);
  const { t } = useTranslation('D360_Finder');
  const [needReset, setNeedReset] = useState(false);

  useEffect(() => {
    props.D360GetFilters();
  }, []);

  useEffect(() => {
    if (!props.open) {
      setFilter(EmptyFilter);
      setTips(EmptyTips);
      setDisableFindButton(true);
    }
  }, [props.open]);

  useEffect(() => {
    if (filter && typeof filter === 'object') {
      let keys = Object.keys(filter);
      for (let key of keys) {
        if (filter[key] !== null && filter[key].length > 0) {
          return setDisableFindButton(false);
        }
      }
    }

    setDisableFindButton(true);
  }, [filter]);

  const onFind = () => {
    if (props && props.onFilter && typeof props.onFilter === 'function') {
      props.onFilter(filter);
      setNeedReset(true);
    }
  };

  const onReset = () => {
    setFilter(EmptyFilter);
    if (props && typeof props.onReset === 'function') {
      props.onReset();
      setNeedReset(false);
    }
  };

  const searchInSelect = (input, option) => {
    if (option && option.props && option.props.children && input) {
      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };

  const onChangeField = (key, value) => {
    switch (key) {
      case 'last_name':
        if (value && value.length > 2) {
          props.D360GetFilterTips('last_name', value, success => {
            if (success && success.data && Array.isArray(success.data)) {
              setTips({ ...tips, lastNames: success.data });
            }
          });
        }

        setFilter({ ...filter, last_name: value });
        break;

      case 'first_name':
        if (value && value.length > 2) {
          props.D360GetFilterTips('first_name', value, success => {
            if (success && success.data && Array.isArray(success.data)) {
              setTips({ ...tips, firstNames: success.data });
            }
          });
        }

        setFilter({ ...filter, first_name: value });
        break;

      case 'middle_name':
        if (value && value.length > 2) {
          props.D360GetFilterTips('middle_name', value, success => {
            if (success && success.data && Array.isArray(success.data)) {
              setTips({ ...tips, middleNames: success.data });
            }
          });
        }

        setFilter({ ...filter, middle_name: value });
        break;

      case 'email':
        if (value && value.length > 2) {
          props.D360GetFilterTips('email', value, success => {
            if (success && success.data && Array.isArray(success.data)) {
              setTips({ ...tips, emails: success.data });
            }
          });
        }

        setFilter({ ...filter, email: value });
        break;

      case 'department':
        setFilter({ ...filter, department: value });
        break;

      case 'position':
        setFilter({ ...filter, position: value });
        break;

      case 'function':
        setFilter({ ...filter, function: value });
        break;
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.Filters}>
        <Row>
          <Col span={8} className={css.FiltersCol}>
            <span>{t('lastname')}</span>
            <AutoComplete
              style={{ width: '100%' }}
              onChange={value => onChangeField('last_name', value)}
              dataSource={tips.lastNames}
              value={filter.last_name}
              disabled={needReset}
            />
          </Col>
          <Col span={8} className={css.FiltersCol}>
            <span>{t('firstname')}</span>
            <AutoComplete
              style={{ width: '100%' }}
              onChange={value => onChangeField('first_name', value)}
              dataSource={tips.firstNames}
              value={filter.first_name}
              disabled={needReset}
            />
          </Col>
          <Col span={8} className={css.FiltersCol}>
            <span>{t('middlename')}</span>
            <AutoComplete
              style={{ width: '100%' }}
              onChange={value => onChangeField('middle_name', value)}
              dataSource={tips.middleNames}
              value={filter.middle_name}
              disabled={needReset}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12} className={css.FiltersCol}>
            <span>{t('columnEmail')}</span>
            <AutoComplete
              style={{ width: '100%' }}
              onChange={value => onChangeField('email', value)}
              dataSource={tips.emails}
              value={filter.email}
              disabled={needReset}
            />
          </Col>
          <Col span={12} className={css.FiltersCol}>
            <span>{t('columnDepartment')}</span>
            <Select
              showSearch={true}
              optionFilterProp='children'
              filterOption={searchInSelect}
              style={{ width: '100%' }}
              loading={props.loading}
              onChange={value => onChangeField('department', value)}
              value={filter.department}
              disabled={needReset}
            >
              {props.filters &&
                props.filters.departments &&
                Array.isArray(props.filters.departments) &&
                props.filters.departments.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={12} className={css.FiltersCol}>
            <span>{t('columnPosition')}</span>
            <Select
              showSearch={true}
              optionFilterProp='children'
              filterOption={searchInSelect}
              style={{ width: '100%' }}
              loading={props.loading}
              onChange={value => onChangeField('position', value)}
              value={filter.position}
              disabled={needReset}
            >
              {props.filters &&
                props.filters.positions &&
                Array.isArray(props.filters.positions) &&
                props.filters.positions.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col span={12} className={css.FiltersCol}>
            <span>{t('columnFunction')}</span>
            <Select
              showSearch={true}
              optionFilterProp='children'
              filterOption={searchInSelect}
              style={{ width: '100%' }}
              loading={props.loading}
              onChange={value => onChangeField('function', value)}
              value={filter.function}
              disabled={needReset}
            >
              {props.filters &&
                props.filters.functions &&
                Array.isArray(props.filters.functions) &&
                props.filters.functions.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24} align='right' className={css.FiltersCol}>
            <ButtonGroup className={css.FiltersButtonGroup}>
              <Button className={css.FiltersButton} type='primary' onClick={onFind} disabled={disableFindButton}>
                <HiOutlineSearch />
                &nbsp;{t('find')}
              </Button>
              <Button className={css.FiltersButton} onClick={onReset} disabled={disableFindButton}>
                <HiOutlineX />
                &nbsp;{t('reset')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    );
  };

  return render();
};

ManualFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  filters: state.d360Filters.filters,
  loading: state.d360Filters.loading,
});

const mapDispatchToProps = {
  D360GetFilters,
  D360GetFilterTips,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualFilter);
