// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-LFuiTf.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-AWRT1w .admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-label-JNPXLc[data-value="Montserrat"]:before, .admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-LFuiTf.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-AWRT1w .admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-item-RUgAvI[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-Montserrat-JQZRaQ {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main-vd2BGH {
  margin-top: 40px;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__main-XtmWON {
  background: #fff;
  padding: 0 40px;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__mass-vVzQnl {
  justify-content: space-between;
  align-items: center;
  height: 60px;
  display: flex;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__filter-O0yH3A {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__filter-FIO-tIfM6j {
  margin-right: 16px;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__select-t5L_6Y {
  width: 300px !important;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__search-qNuTRb {
  width: 400px;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__button-ok8YXs {
  margin-top: 33px;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__text-u3gyMP {
  color: var(--personalColor);
  text-align: end;
  cursor: pointer;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__text__notAdmin-iXZcYp {
  width: 100%;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__form-MW_XW3 {
  gap: 50px;
  display: flex;
}

.admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__create-user-nugVWI {
  padding: 0 60px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/AccessSettings/parts/UserSettings/UserSettings.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AAMA;EAHE,gBAAgB;EAChB,eAAe;AACjB;;AAKA;EAFE,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAIA;EADE,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAGA;EAAE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AACA;EAEE,YAAY;AACd;;AAAA;EAGE,gBAAgB;AAClB;;AADA;EAIE,2BAA2B;EAC3B,eAAe;EACf,eAAe;AACjB;;AAFA;EAKE,WAAW;AACb;;AAHA;EAME,SAAS;EACT,aAAa;AACf;;AAJA;EAOE,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.main {\n  margin-top: 40px;\n}\n.main__main {\n  background: white;\n  padding: 0 40px;\n}\n.main__mass {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 60px;\n}\n.main__filter {\n  margin-bottom: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.main__filter-FIO {\n  margin-right: 16px;\n}\n.main__select {\n  width: 300px !important;\n}\n.main__search {\n  width: 400px;\n}\n.main__button {\n  margin-top: 33px;\n}\n.main__text {\n  color: var(--personalColor);\n  text-align: end;\n  cursor: pointer;\n}\n.main__text__notAdmin {\n  width: 100%;\n}\n.main__form {\n  display: flex;\n  gap: 50px;\n}\n.main__create-user {\n  padding: 0 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-LFuiTf`,
	"qlPicker": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-LFuiTf`,
	"ql-font": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-AWRT1w`,
	"qlFont": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-AWRT1w`,
	"ql-picker-label": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-label-JNPXLc`,
	"qlPickerLabel": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-label-JNPXLc`,
	"ql-picker-item": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-item-RUgAvI`,
	"qlPickerItem": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-picker-item-RUgAvI`,
	"ql-font-Montserrat": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-Montserrat-JQZRaQ`,
	"qlFontMontserrat": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__ql-font-Montserrat-JQZRaQ`,
	"main": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main-vd2BGH`,
	"main__main": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__main-XtmWON`,
	"mainMain": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__main-XtmWON`,
	"main__mass": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__mass-vVzQnl`,
	"mainMass": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__mass-vVzQnl`,
	"main__filter": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__filter-O0yH3A`,
	"mainFilter": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__filter-O0yH3A`,
	"main__filter-FIO": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__filter-FIO-tIfM6j`,
	"mainFilterFio": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__filter-FIO-tIfM6j`,
	"main__select": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__select-t5L_6Y`,
	"mainSelect": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__select-t5L_6Y`,
	"main__search": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__search-qNuTRb`,
	"mainSearch": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__search-qNuTRb`,
	"main__button": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__button-ok8YXs`,
	"mainButton": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__button-ok8YXs`,
	"main__text": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__text-u3gyMP`,
	"mainText": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__text-u3gyMP`,
	"main__text__notAdmin": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__text__notAdmin-iXZcYp`,
	"mainTextNotAdmin": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__text__notAdmin-iXZcYp`,
	"main__form": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__form-MW_XW3`,
	"mainForm": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__form-MW_XW3`,
	"main__create-user": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__create-user-nugVWI`,
	"mainCreateUser": `admin-src-pages-AccessSettings-parts-UserSettings-UserSettings-module__main__create-user-nugVWI`
};
export default ___CSS_LOADER_EXPORT___;
