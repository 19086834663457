import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getFile, getPublicFile } from '@/store/files/actions';

import PropTypes from 'prop-types';

const useLocalFileByUUID = (uuid, config = {}) => {
  const { isDefaultNull, defaultImg, publish } = config;
  const dispatch = useDispatch();

  const setDefaultImg = isDefaultNull ? null : defaultImg;

  const [localFile, setLocalFile] = useState(setDefaultImg);
  const [isLoading, setLoading] = useState(true);

  const onSuccessResponse = success => {
    const imgObjectURL = URL.createObjectURL(success);
    setLocalFile(imgObjectURL);
    setLoading(false);
  };

  const onErrorResponse = () => {
    setLocalFile(setDefaultImg);
    setLoading(false);
  };

  const loadCurrentFile = () => {
    if (publish) {
      dispatch(getPublicFile(uuid, onSuccessResponse, onErrorResponse));
    } else {
      dispatch(getFile(uuid, onSuccessResponse, onErrorResponse));
    }
  };

  useLayoutEffect(() => {
    if (uuid) {
      setLoading(true);
      loadCurrentFile();
    } else {
      setLoading(false);
    }
  }, [uuid]);

  return [localFile, isLoading];
};

useLocalFileByUUID.propTypes = {
  uuid: PropTypes.string,
  options: PropTypes.shape({
    isDefaultNull: PropTypes.bool, // Если 1 то картинка по умолчанию может быть null
    defaultImg: PropTypes.any, // Ссылка  на картинку по умолчанию
    publish: PropTypes.bool, // Если 1 то поиск картинки на сервере через публичный api getPublicFile
  }),
};

export { useLocalFileByUUID };
