/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUserIncome, writeOffCoin } from '@/store/income/actions';

import { selectIncome } from '@/store/income/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Modal, Pagination, Row, Tabs } from 'antd';
import Input from '@/components/Input';
import cssInput from '@/components/Input/Input.module.scss';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import CoinRow from './CoinRow';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './Users.module.scss';

export class CoinProfile extends Component {
  static propTypes = {
    getUserIncome: PropTypes.func,
    onCancel: PropTypes.func,
    student: PropTypes.object,
    userIncome: PropTypes.array.isRequired,
    userIncomePageNumber: PropTypes.number,
    userIncomePageSize: PropTypes.number,
    userIncomeTotal: PropTypes.number,
    writeOffCoin: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      coins: 0,
      comment: '',
      btnClick: false,
    };
  }

  componentDidMount() {
    this.props.getUserIncome(this.props.student.id, 0);
  }

  changePageCoin = event => {
    this.props.getUserIncome(this.props.student.id, event - 1);
  };

  changeCoin = event => {
    this.setState({ coins: event.target.value });
  };

  changeComment = event => {
    this.setState({ comment: event.target.value });
  };

  writeOffCoin = type => {
    this.setState({ btnClick: true });
    this.props.writeOffCoin(
      this.props.student.id,
      type === 'off' ? -this.state.coins : this.state.coins,
      this.state.comment,
      () => {
        this.setState({ btnClick: false });
        this.props.onCancel();
      },
      () => {
        this.setState({ btnClick: false });
      }
    );
  };

  render() {
    const { student, userIncome, currentUser, t, ...props } = this.props;
    const coinRows = userIncome.map(ui => <CoinRow key={ui.id} userIncome={ui} currentUser={currentUser} t={t} />);
    const paginationCoin = (
      <Pagination
        current={this.props.userIncomePageNumber + 1}
        total={this.props.userIncomeTotal}
        pageSize={this.props.userIncomePageSize}
        onChange={this.changePageCoin}
        showSizeChanger={false}
        responsive
        hideOnSinglePage
        className={css['HistoryCoin-pagination']}
      />
    );

    const tabsItems = [
      {
        key: '1',
        label: t('spending'),
        children: (
          <div className={css.Coin}>
            <div>{t('total', { account: student.account })}</div>
            <div>{t('count')}</div>
            <Input type='number' className={cssInput['Input-coin']} onChange={this.changeCoin} />
            <div className={cssInput['Input-coin-label']}>{t('coins')}</div>
            <div>
              {t('comment')}
              <Input maxLength='50' onChange={this.changeComment} />
            </div>
            <Button onClick={() => this.writeOffCoin('off')} disabled={this.state.btnClick} type='primary'>
              {t('spend')}
            </Button>
          </div>
        ),
      },
      {
        key: '2',
        label: t('add'),
        children: (
          <div className={css.Coin}>
            <div>{t('total', { account: student.account })}</div>
            <div>{t('adding')}</div>
            <Input type='number' className={cssInput['Input-coin']} onChange={this.changeCoin} />
            <div className={cssInput['Input-coin-label']}>{t('coins')}</div>
            <div>
              {t('comment')}
              <Input maxLength='50' onChange={this.changeComment} />
            </div>
            <Button onClick={() => this.writeOffCoin('on')} disabled={this.state.btnClick} type='primary'>
              {t('add2')}
            </Button>
          </div>
        ),
      },
      {
        key: '3',
        label: t('history'),
        children: (
          <>
            <table className={css['HistoryCoin-table']}>
              <thead className={css['HistoryCoin-table-head']}>
                <tr>
                  <th>{t('date')}</th>
                  <th>
                    <ArrowLeftOutlined />
                  </th>
                  <th>
                    <ArrowRightOutlined />
                  </th>
                  <th>{t('comment')}</th>
                </tr>
              </thead>
              <tbody className={css['HistoryCoin-table-body']}>{coinRows}</tbody>
            </table>
            {this.props.userIncomeTotal === 0 ? '' : paginationCoin}
          </>
        ),
      },
    ];

    return (
      <Modal width={800} footer={null} title={t('titleCoinProfile')} {...props}>
        <Row gutter={1} className={css['Students-profile']}>
          <Col span={24} className={css['Students-profile-col']}>
            <Tabs items={tabsItems} />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([selectIncome, selectUsers], (income, users) => ({
  userIncome: income.userIncome,
  userIncomePageNumber: income.userIncomePageNumber,
  userIncomePageSize: income.userIncomePageSize,
  userIncomeTotal: income.userIncomeTotal,
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getUserIncome,
  writeOffCoin,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('coinProfile')(CoinProfile));
