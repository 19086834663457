import { AudioFilled } from '@ant-design/icons';

const ConferenceSvg = props => (
  <>
    <AudioFilled style={{ fontSize: '12px' }} />
    <span>{props.name}</span>
  </>
);

export default ConferenceSvg;
