/* eslint-disable */
import React, { useCallback, useMemo } from 'react';

import { Form } from 'antd';
import { Select as UserSearch } from '@/components/elements';
import CustomInput from '@/components/Input';
import Select from '@/components/Select';

import DatePicker from '@shared/components/DatePicker';
import { searchUsersLoginByCompany } from '@shared/utils/users-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';

import css from './CompanyCreationDialog.module.scss';

export const roles = {
  empty: 'empty',
  Пользователь: 'EMPLOYEE',
  Руководитель: 'MANAGER',
  Администратор: 'ADMIN',
  Суперадминистратор: 'SUPER_ADMIN',
};

export const Users = ({ company, handleChange, handleIntChange, manager, t }) => {
  const options = useMemo(
    () => (company.adminLogin ? [{ value: company.adminLogin, label: manager || company.adminLogin }] : []),
    [manager]
  );
  const searchUsers = useCallback(name => {
    return searchUsersLoginByCompany(name, company.id);
  }, []);

  return (
    <div className={css['Add-modal']}>
      <div className={css['Add-modal-title']}>{t('restriction')}</div>

      {/* ГЛАВНЫЙ В КОМПАНИИ */}
      <Form.Item label={t('lead')} labelAlign='left'>
        <UserSearch
          name='adminLogin'
          allowClear
          showSearch
          onSearch={searchUsers}
          placeholder={t('example')}
          notFoundPlaceholder={t('start')}
          onChange={handleChange}
          value={company.adminLogin}
          options={options}
          disabled={!company.id}
          forceUpdate
        />
      </Form.Item>

      {/* РОЛЬ ПО УМОЛЧАНИЮ */}
      <Form.Item label={t('role')} labelAlign='left'>
        <Select
          className={classNames(css.TopicCreate)}
          onChange={handleChange}
          name='defaultRoleRegistration'
          options={Object.keys(roles).map(item => ({ name: item }))}
          value={company.defaultRoleRegistration}
          white
        />
      </Form.Item>

      {/* БЛОКИРОВКА ЧЕРЕЗ */}
      {/* ПОСЛЕ РЕГИСТРАЦИИ */}
      <Form.Item
        label={
          <div>
            <div>{t('block')}</div>
            <span className={css['Add-modal-description']}>{t('block2')}</span>
          </div>
        }
        labelAlign='left'
      >
        <div className={css['Add-modal-shortInputContainer']}>
          <CustomInput
            className={css['Add-modal-shortInput']}
            name='lifeTimeUser'
            placeholder='000'
            onChange={handleIntChange}
            value={company.lifeTimeUser}
            maxLength={3}
          />
          {t('days')}
        </div>
      </Form.Item>

      {/* КОЛИЧЕСТВО ПОЛЬЗОВАТЕЛЕЙ */}
      <Form.Item label={t('count')} labelAlign='left'>
        <CustomInput
          name='usersLimit'
          placeholder='0000000000'
          onChange={handleIntChange}
          value={company.usersLimit}
          maxLength={10}
        />
      </Form.Item>

      {/* ИСТЕЧЕНИЕ ДОСТУПА */}
      <Form.Item label={t('expired')} labelAlign='left'>
        <DatePicker
          onChange={handleChange}
          name='expirationDate'
          value={company.expirationDate}
          placeholder={dayjs().add(7, 'days').format('DD.MM.YYYY')}
          modificate
          readOnly
        />
      </Form.Item>
    </div>
  );
};
