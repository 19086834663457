/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  createCompilation,
  deleteCompilation,
  getCompilation,
  getCompilations,
  updateCompilation,
} from '@/store/compilations/actions';
import { createFileImage, getFile } from '@/store/files/actions';
import { clearTopics, getTopicsCompilation } from '@/store/topics/actions';

import { selectCompilations as compilationsSelect } from '@/store/compilations/selectors';
import { selectFiles as filesSelect } from '@/store/files/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Form, Input, Modal } from 'antd';
import { Select } from '@/components/elements';
import ImageInput from '@/components/ImageInputCrop';
import { CloseOutlined } from '@ant-design/icons';

import ConfirmationModal from '../ConfirmationModal';
import DragDropList from '../DragDropList';
import MaterialModal from '../MaterialModal';
import Tag from '../Tag';
import ThemesInput from '../ThemesInput';

import { IMAGE_TYPES } from '@shared/constants/image-types';
import debounce from '@shared/utils/debounce';
import { searchUsers } from '@shared/utils/users-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';

import css from './Compilation.module.scss';

import { COMPILATION_DELETE, COMPILATION_UPDATE } from '@/constants/permissions';
import { COMPILATION_FIELD } from '@/constants/topic-types';

const { TextArea } = Input;

class AddCompilationModal extends Component {
  static propTypes = {
    clearTopics: PropTypes.func,
    compilation: PropTypes.object,
    compilationsPageNumber: PropTypes.number,
    createCompilation: PropTypes.func,
    createFileImage: PropTypes.func,
    deleteCompilation: PropTypes.func,
    domainCompany: PropTypes.object,
    editable: PropTypes.object,
    errors: PropTypes.any,
    fileLoadingImage: PropTypes.bool,
    getCompilation: PropTypes.func,
    getCompilations: PropTypes.func,
    getFile: PropTypes.func,
    getTopicsCompilation: PropTypes.func,
    navigate: PropTypes.any,
    isDetile: PropTypes.bool,
    isLoadingCompilations: PropTypes.bool,
    isLoadingFile: PropTypes.bool,
    isLoadingFileImage: PropTypes.bool,
    isLoadingTopics: PropTypes.bool,
    open: PropTypes.bool,
    onCreate: PropTypes.func,
    toggleModal: PropTypes.func,
    topicsCompilation: PropTypes.array,
    topicsCompilationPageNumber: PropTypes.number,
    topicsCompilationTotal: PropTypes.number,
    updateCompilation: PropTypes.func,
    career: PropTypes.array,
    isGlobal: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    if (!props.editable) {
      this.state = {
        open: false,
        isSelectMaterialsModal: false,
        onDelete: false,
        errors: {},
        topics: [],
        themes: [],
        shortDescription: '',
        cropImageSrc: '',
        coverFileUuid: '',
        name: '',
        fullDescription: '',
        cover: '',
        skillsList: [],
        confirmationVisible: false,
        trajectoryVisible: false,
        managerId: undefined,
        careerId: [],
      };
    } else {
      this.state = {
        open: false,
        isSelectMaterialsModal: false,
        trajectoryGlobalVisible: false,
        onDelete: false,
        errors: {},
        topics: props.topicsCompilation,
        themes: props.editable.themes || [],
        shortDescription: props.editable.shortDescription || '',
        cropImageSrc: '',
        coverFileUuid: '',
        name: props.editable.name || '',
        fullDescription: props.editable.fullDescription || '',
        cover: props.editable.cover || '',
        skillsList: props.editable.skills || [],
        confirmationVisible: false,
        trajectoryVisible: false,
        managerId: _get(props, 'compilation.manager.id'),
        manager: _get(props, 'compilation.manager'),
        careerId: [],
        career: [],
        // careerId: _get(props, 'compilation.careers').map(({ id }) => id),
        // career: _get(props, 'compilation.careers'),
      };
    }

    this.getTopicsCompilation = debounce(props.getTopicsCompilation, 400);

    this.InputList = [
      {
        label: this.props.t('title'),
        type: 'input',
        name: 'name',
        required: true,
        maxLength: '255',
        placeholder: this.props.t('compilationTitle'),
        dataqa: 'compilationNameInput',
      },
      {
        label: this.props.t('short'),
        type: 'textarea',
        name: 'shortDescription',
        required: true,
        style: { resize: 'none' },
        placeholder: this.props.t('description'),
        maxLength: '140',
        characterendcheck: 'true',
        dataqa: 'compilationShortDescriptionInput',
      },
      {
        label: this.props.t('learning'),
        type: 'textarea',
        name: 'fullDescription',
        required: true,
        style: { resize: 'none' },
        maxLength: '4096',
        rows: 5,
        placeholder: this.props.t('skillsDescriptions'),
        dataqa: 'compilationFullDescriptionInput',
      },
      {
        label: this.props.t('theme'),
        type: 'themes',
        name: 'themes',
        required: true,
        placeholder: this.props.t('themeTitle'),
        dataqa: 'compilationThemesBtn',
      },
      {
        label: this.props.t('cover'),
        type: 'image',
        name: 'cover',
        required: true,
        dataqa: 'compilationImageInput',
      },
      {
        label: this.props.t('expert'),
        type: 'manager',
        name: 'managerId',
        placeholder: this.props.t('expertName'),
        required: false,
        dataqa: 'compilationCareerBtn',
      },
    ];
    // this.InputList = props.domainCompany.hasTrajectory
    //   ? [
    //       ...this.InputList,
    //       {
    //         label: this.props.t('specialization'),
    //         type: 'career',
    //         name: 'careerId',
    //         placeholder: this.props.t('hard'),
    //         required: false,
    //       },
    //     ]
    //   : this.InputList;
  }

  componentDidMount() {
    if (this.props.editable) {
      this.props.getTopicsCompilation({
        compilationId: this.props.editable.id,
        sort: 'compilations.position,asc',
        page: 0,
      });
      this.getFile(this.props.editable.cover);
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.topicsCompilation) !== JSON.stringify(prevProps.topicsCompilation)) {
      this.setState({
        topics: this.props.topicsCompilation,
      });
    }
    if (
      this.props.topicsCompilation.length &&
      this.props.topicsCompilation.length < this.props.topicsCompilationTotal &&
      !this.props.isLoadingTopics
    ) {
      this.loadMoreTopics();
    }
  }

  loadMoreTopics = () => {
    this.getTopicsCompilation({
      compilationId: this.props.editable.id,
      sort: 'compilations.position,asc',
      page: this.props.topicsCompilationPageNumber + 1,
    });
  };

  getFile = photoUuid => {
    this.props.getFile(photoUuid, blob => {
      const imageUrl = URL.createObjectURL(blob);
      this.setState({
        cover: photoUuid,
        cropImageSrc: imageUrl,
        coverFileUuid: imageUrl,
      });
    });
  };

  handleChange = event => {
    const { name, value } = event.target || event;
    this.setState({ [name]: value, formChange: true });
  };

  isFieldEmpty = fieldName => {
    if (typeof this.state[fieldName] === 'boolean') {
      return false;
    }
    if (Array.isArray(this.state[fieldName])) {
      return !this.state[fieldName].length;
    }
    return !this.state[fieldName];
  };

  formValidation = () => {
    const errors = {};
    let haveErrors = false;
    for (let i = 0; i < COMPILATION_FIELD.length; i++) {
      const field = COMPILATION_FIELD[i];
      if (field.required && this.isFieldEmpty(field.apiField)) {
        errors[field.apiField] = this.props.t('require');
        haveErrors = true;
      }
      if (this.state.topics.length === 0) {
        errors.topics = this.props.t('require');
        haveErrors = true;
      }
    }
    this.setState({ errors });
    return !haveErrors;
  };

  deleteCompilation = (id, isDetile) => {
    this.props.deleteCompilation(id, () => {
      this.props.toggleModal();
      if (isDetile) {
        this.props.navigate('/compilations');
      }
      this.props.getCompilations();
    });
  };

  handleSend = (isUpdate = false) => {
    const { careerId } = this.state;
    const { topics } = this.state;
    const soft = topics.filter(item => {
      const careers = item.careers || [];
      const uniqMerits = _uniq([
        item.awareness,
        item.mentalFlexibility,
        item.involvement,
        item.minAwareness,
        item.minInvolvement,
        item.maxInvolvement,
      ]);
      return !careers.length && (uniqMerits.length > 1 || uniqMerits[0] !== 0);
    });
    if (!this.props.isGlobal && !!careerId.length && soft.some(item => item.global)) {
      this.setState({ trajectoryGlobalVisible: true });
    } else if (!!careerId.length && !!soft.length) {
      this.setState({ trajectoryVisible: true });
    } else {
      this.sendCompilationForm(isUpdate);
    }
  };

  toggleTrajectoryModal = () => {
    const { editable } = this.props;
    this.sendCompilationForm(editable);
    this.setState({ trajectoryVisible: false });
  };

  sendCompilationForm = (isUpdate = false) => {
    const { topics, themes, shortDescription, name, fullDescription, cover, managerId, careerId } = this.state;
    if (this.formValidation()) {
      this.setState({ createBtnClick: true });
      const filteredTopics = topics
        .filter((item, index) => {
          const itemSerialized = JSON.stringify(item);
          return (
            index ===
            topics.findIndex(obj => {
              return JSON.stringify(obj) === itemSerialized;
            })
          );
        })
        .map((topic, index) => ({ ...topic, position: index }));
      if (!isUpdate) {
        this.props.createCompilation(
          {
            topics: filteredTopics,
            themes: themes.map(theme => theme.id),
            shortDescription,
            name,
            fullDescription,
            cover,
            managerId,
            careerIds: !!careerId.length ? careerId : null,
          },
          () => {
            this.setState({ createBtnClick: false }, this.props.toggleModal);
            this.props.onCreate && this.props.onCreate();
            this.props.getCompilations();
          },
          () => {
            this.setState({ createBtnClick: false }, this.props.toggleModal);
          }
        );
      } else {
        this.props.updateCompilation(
          this.props.editable.id,
          {
            topics: filteredTopics,
            themes: themes.map(theme => theme.id),
            shortDescription,
            name,
            fullDescription,
            cover,
            managerId,
            careerIds: !!careerId.length ? careerId : null,
          },
          () => {
            const { compilationsPageNumber } = this.props;
            this.setState({ createBtnClick: false }, this.props.toggleModal);
            this.props.onCreate && this.props.onCreate();
            this.props.getCompilations({ page: compilationsPageNumber });
          },
          () => {
            this.setState({ createBtnClick: false }, this.props.toggleModal);
          }
        );
      }
    }
  };

  setColor = coverColor => this.setState({ coverColor });

  addMaterialsToList = topics => {
    const { isSelectMaterialsModal } = this.state;
    this.setState(
      {
        topics,
        formChange: true,
      },
      () => {
        this.reloadSkills();
        if (isSelectMaterialsModal) {
          return this.onCloseMaterialModal();
        }
      }
    );
  };

  deleteMaterialFromList = itemId => {
    const topics = this.state.topics.filter(el => el.id !== itemId);
    this.setState(
      {
        topics,
        formChange: true,
      },
      () => {
        this.reloadSkills();
      }
    );
  };

  handleImageChange = (file, name, info, cropImageSrc, coverColor) => {
    if (file && info) {
      if (IMAGE_TYPES.includes(info.type)) {
        if (this.state.coverFileUuid) {
          this.props.createFileImage(file, fileInfo => {
            this.setState(() => ({
              [name]: fileInfo.uuid,
              thumbFileUuid: fileInfo.thumbUuid,
              errors: {},
              coverColor,
              formChange: true,
              cropImageSrc,
            }));
          });
        } else {
          this.props.createFileImage(file, fileInfo => {
            this.setState(() => ({
              [name]: fileInfo.uuid,
              thumbFileUuid: fileInfo.thumbUuid,
              errors: {},
              coverColor,
              formChange: true,
              cropImageSrc,
            }));
          });
        }
        return true;
      }
      this.setState(state => ({
        [name]: null,
        thumbFileUuid: null,
        coverColor: null,
        errors: {
          ...state.errors,
          [name]: this.props.t('format'),
        },
      }));
    } else {
      this.setState(state => ({
        [name]: file,
        coverColor: null,
        thumbFileUuid: null,
        errors: { ...state.errors, [name]: '' },
      }));
    }
    this.setState({ formChange: true });
  };

  renderFormItem = () =>
    this.InputList.map(currentItem => {
      const { dataQa, ...item } = currentItem;
      if (item.type === 'textarea') {
        return (
          <Form.Item
            key={item.label}
            label={
              <div>
                <div>{item.label}</div>
                {item.characterendcheck && <span>{this.props.t('max', { maxLength: item.maxLength })}</span>}
              </div>
            }
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            validateStatus={this.state.errors[item.name] ? 'error' : ''}
            help={this.state.errors[item.name]}
          >
            <TextArea
              data-qa={dataQa}
              className={css['Add-modal-textarea']}
              {...item}
              onChange={this.handleChange}
              value={this.state[item.name]}
            />
          </Form.Item>
        );
      }
      if (item.type === 'image') {
        return (
          <Form.Item
            key={item.label}
            label={item.label}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            validateStatus={this.state.errors[item.name] ? 'error' : ''}
            help={this.state.errors[item.name]}
          >
            <ImageInput
              reverse
              dataqa='compilationImageInput'
              onChange={this.handleImageChange}
              name='cover'
              {...item}
              fileInfo={this.state.fileInfo}
              error={!!this.state.errors.coverFileUuid}
              imageSrc={this.state.coverFileUuid || this.state.cropImageSrc}
              setColor={this.setColor}
              defaultAspectWidth='950'
              defaultAspectHeight='594'
              fileLoading={this.props.fileLoadingImage}
              isLoadingFile={this.props.isLoadingFile || this.state.createBtnClick}
              isLoadingFileImage={this.props.isLoadingFileImage}
              errorStatus={!!this.state.errors[item.name]}
            />
          </Form.Item>
        );
      }
      if (item.type === 'themes') {
        return (
          <Form.Item
            key={item.label}
            label={item.label}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            validateStatus={this.state.errors[item.name] ? 'error' : ''}
            help={this.state.errors[item.name]}
          >
            <ThemesInput
              onChange={this.handleChange}
              name='themes'
              value={this.state[item.name]}
              {...item}
              error={!!this.state.errors[item.name]}
            />
          </Form.Item>
        );
      }
      if (item.type === 'manager') {
        const { manager } = this.state;
        const options = manager ? [{ value: manager.id, label: manager.name }] : [];

        return (
          <Form.Item
            key={item.label}
            label={item.label}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 13 }}
            validateStatus={this.state.errors[item.name] ? 'error' : ''}
            help={this.state.errors[item.name]}
          >
            <Select
              dataqa='compilationsExpertSelect'
              name={item.name}
              allowClear
              showSearch
              onSearch={searchUsers}
              placeholder={item.placeholder}
              notFoundPlaceholder={this.props.t('placeholderExpert')}
              onChange={this.handleChange}
              value={this.state[item.name]}
              options={options}
            />
          </Form.Item>
        );
      }
      // if (item.type === 'career') {
      //   const { career } = this.state;
      //   return (
      //     <Form.Item
      //       key={item.label}
      //       normal
      //       label={item.label}
      //       labelCol={{ span: 7 }}
      //       wrapperCol={{ span: 13 }}
      //       error={this.state.errors[item.name]}
      //     >
      //       <Select
      //         dataQa='compilationsSpecializationSelect'
      //         name={item.name}
      //         showSearch
      //         multiple
      //         onSearch={searchCareer}
      //         placeholder={item.placeholder}
      //         notFoundPlaceholder={this.props.t('placeholderSpecialization')}
      //         onChange={this.handleChange}
      //         data={this.state[item.type]}
      //         options={career}
      //       />
      //     </Form.Item>
      //   );
      // }
      return (
        <Form.Item
          key={item.label}
          label={item.label}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          validateStatus={this.state.errors[item.name] ? 'error' : ''}
          help={this.state.errors[item.name]}
        >
          <Input
            dataqa={dataQa}
            {...item}
            maxLength={Number(item?.maxLength)}
            onChange={this.handleChange}
            value={this.state[item.name]}
          />
        </Form.Item>
      );
    });

  toggleMaterialsModal = () =>
    this.setState({
      isSelectMaterialsModal: true,
    });

  onDelete = () =>
    this.setState({
      onDelete: !this.state.onDelete,
    });

  reloadSkills = () => {
    const skillsList = [];
    this.state.topics &&
      this.state.topics.length &&
      this.state.topics.map(item => {
        item.skills &&
          item.skills.map(skill => {
            let flag = false;
            skillsList.map(s => {
              if (s.id === skill.id) {
                flag = true;
              }
            });
            if (!flag) {
              skillsList.push(skill);
            }
          });
      });
    this.setState({ skillsList });
  };

  toggleModal = () => {
    if (this.state.formChange) {
      this.setState({
        confirmationVisible: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onCloseMaterialModal = () => {
    this.setState({ isSelectMaterialsModal: false });
    this.props.clearTopics();
  };

  render() {
    const { isDetile, isLoadingCompilations, editable, open, t, currentUser } = this.props;
    const submitButton = editable ? (
      <div className={css['Add-modal-form-actions']}>
        {currentUser.authorities.includes(COMPILATION_UPDATE) && (
          <Button
            data-qa='compilationConfirmEditBtn'
            onClick={() => this.handleSend(true)}
            className={css['Add-modal-form-event']}
            disabled={isLoadingCompilations}
            type='primary'
            size='large'
          >
            {t('edit')}
          </Button>
        )}
        {editable && currentUser.authorities.includes(COMPILATION_DELETE) && (
          <Button
            type='primary'
            danger
            size='large'
            data-qa='compilationDeleteBtn'
            onClick={this.onDelete}
            className={css['Add-modal-form-event']}
            disabled={isLoadingCompilations}
          >
            <CloseOutlined />

            <span className={css.removeCompilation}>{t('deleteCompilation')}</span>
          </Button>
        )}
        <ConfirmationModal
          open={this.state.onDelete}
          onOk={() => this.deleteCompilation(editable.id, isDetile)}
          onCancel={this.onDelete}
          title={t('deleting')}
          okText={t('delete')}
          confirmationText={t('confirmDelete')}
        />
      </div>
    ) : (
      <Button
        onClick={() => this.handleSend()}
        className={css['Add-modal-form-event']}
        disabled={isLoadingCompilations}
        data-qa='compilationPublishBtn'
      >
        {t('publish')}
      </Button>
    );
    return (
      <Modal
        footer={null}
        open={open}
        onCancel={this.toggleModal}
        width={800}
        title={!editable ? t('createCompilation') : t('editCompilation')}
      >
        <Form className={css['Add-modal-form']}>
          {this.renderFormItem()}
          <div className={css.FormItem__materials}>
            <Form.Item
              label={t('materials')}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
              validateStatus={this.state.errors.topics ? 'error' : ''}
              help={this.state.errors.topics}
            >
              <div className={css['Add-modal-form-event__box']}>
                <Button
                  type='primary'
                  onClick={this.toggleMaterialsModal}
                  data-qa='compilationAddMaterial'
                  className={classNames(css['Add-modal-form-event'], {
                    [css['Add-modal-form-event-error']]: !!this.state.errors.topics,
                  })}
                >
                  {t('addMaterial')}
                </Button>

                {!this.state.topics.length && (
                  <div className={css['Add-modal-form-comment']}>{t('materialChoose')}</div>
                )}
              </div>
              <DragDropList
                domainCompany={this.props.domainCompany}
                addMaterialsToList={this.addMaterialsToList}
                getFile={this.props.getFile}
                onDelete={this.deleteMaterialFromList}
                list={this.state.topics}
              />
            </Form.Item>
          </div>
          <div className={css.FormItem__skills}>
            <Form.Item label={t('skills')} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
              {this.state.skillsList && this.state.skillsList.length ? (
                <div>
                  {this.state.skillsList.map(skill => (
                    <Tag orange noDel noUpper key={skill.id} tag={skill} className={css['Add-modal-form-skill']} />
                  ))}
                  <p className={css['Add-modal-form-skill-label']}>{t('descriptions')}</p>
                </div>
              ) : (
                <div>
                  <p className={css['Add-modal-form-skill-label']}>{t('descriptions')}</p>
                </div>
              )}
            </Form.Item>
          </div>
          {submitButton}
          {this.state.isSelectMaterialsModal && (
            <MaterialModal
              open={this.state.isSelectMaterialsModal}
              title={t('addMaterialToCompilation')}
              onCancel={this.onCloseMaterialModal}
              onOk={this.addMaterialsToList}
              selectedTopics={this.state.topics}
              isCompilation
            />
          )}
        </Form>
        <ConfirmationModal
          open={this.state.confirmationVisible}
          onOk={this.props.toggleModal}
          onCancel={() => this.setState({ confirmationVisible: false })}
          okText={t('close')}
          zIndex={1006}
          confirmationText={t('closeWithoutSaving')}
        />
        <ConfirmationModal
          open={this.state.trajectoryVisible}
          onOk={this.toggleTrajectoryModal}
          onCancel={() => this.setState({ trajectoryVisible: false })}
          okText={t('confirm')}
          zIndex={1006}
          title={t('confirmRefresh')}
          confirmationText={t('soft')}
        />
        <ConfirmationModal
          open={this.state.trajectoryGlobalVisible}
          onOk={() => this.setState({ trajectoryGlobalVisible: false })}
          onCancel={() => this.setState({ trajectoryGlobalVisible: false })}
          okText={t('ok')}
          zIndex={1006}
          title={t('error')}
          confirmationText={t('addSpecialisation')}
        />
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(
  topicsSelect,
  filesSelect,
  compilationsSelect,
  usersSelect,
  (topics, files, compilations, users) => ({
    topicsCompilation: topics.topicsCompilation,
    topicsCompilationTotal: topics.topicsCompilationTotal,
    topicsCompilationPageNumber: topics.topicsCompilationPageNumber,
    compilation: compilations.compilation,
    compilationsPageNumber: compilations.compilationsPageNumber,
    isLoadingFileImage: files.isLoadingImage,
    isLoadingFile: files.isLoading,
    isLoadingTopics: topics.isLoading,
    isLoadingCompilations: compilations.isLoading,
    isGlobal: users && users.currentUser.domainCompany.global,
    currentUser: users.currentUser,
  })
);

const mapActionsToProps = {
  createFileImage,
  createCompilation,
  clearTopics,
  getCompilations,
  deleteCompilation,
  updateCompilation,
  getCompilation,
  getFile,
  getTopicsCompilation,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('addCompilationModal')(AddCompilationModal));
