import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFiles } from '@/store/files/actions';

import { selectFiles } from '@/store/files/selectors';

import { Resources } from '@shared/resources';

import PropTypes from 'prop-types';

import AvatarPlaceholder from '@/assets/images/PortraitPlaceholder.png';

export default function useImage(uuid, type) {
  const { files, isLoadingUuid } = useSelector(selectFiles);

  const dispatch = useDispatch();

  useEffect(() => {
    if (uuid && !files[uuid] && !isLoadingUuid.includes(uuid)) {
      dispatch(getFiles(uuid));
    }
  }, [uuid]);

  const typeImage = () => {
    switch (type) {
      case 'avatar':
        return AvatarPlaceholder;

      case 'topic':
        return Resources.Images.Course;

      default:
        return null;
    }
  };

  return useMemo(() => {
    if (!uuid || !files[uuid]) {
      return typeImage();
    } else {
      return files[uuid];
    }
  }, [files[uuid], uuid]);
}

useImage.propTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
