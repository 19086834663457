import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCompetence } from '@/store/competence/actions';
import { createFileImage } from '@/store/files/actions';
import { addTrackSkills, clearTrackSkills, getSkills } from '@/store/skills/actions';
import { getTopicCategories } from '@/store/topics/actions';
import {
  changeStatusTrack,
  clearTrackDetails,
  createTrack,
  getTrackDetails,
  postTracksFilters,
  updateTrack,
} from '@/store/tracks/actions';

import { selectCompetence } from '@/store/competence/selectors';
import { selectFiles } from '@/store/files/selectors';
import { selectSkills } from '@/store/skills/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectTracks } from '@/store/tracks/selectors';
import { selectUsers } from '@/store/users/selectors';

import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Upload,
} from 'antd';
import ImageBlock from '@/components/ImageBlock';
import ModalCategory from '@/components/ModalCategory';
import TrackTopics from '@/components/TrackTopics';

import MaterialModal from '../MaterialModal';
import {
  COMPETENCIES,
  IMAGE_TYPE,
  MAX_COMPETENCIES,
  MAX_SKILLS,
  MAX_TOPICS,
  PROCESS_ON_UPDATE,
  SKILLS,
  STATUS_IN_PROCESS,
  TYPE_UPDATE,
} from './constance';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './ModalTrack.module.scss';

import { TRACK_CREATE, TRACK_UPDATE } from '@/constants/permissions';

const { TextArea } = Input;
const { Option } = Select;

const ModalTrack = ({ open, onClose, form, isCreate, isEdit, isTrackDetail }) => {
  const { t } = useTranslation('tracks');
  const dispatch = useDispatch();

  const { isLoadingImage } = useSelector(selectFiles);
  const { tracksFilters, tracksCurrentPage, trackDetails, isLoading, isChangeStatus } = useSelector(selectTracks);
  const { categories } = useSelector(selectTopics);
  const { competence } = useSelector(selectCompetence); // default size: 1000
  const { skills } = useSelector(selectSkills);
  const { currentUser } = useSelector(selectUsers);

  useEffect(() => {
    dispatch(getCompetence());

    return () => {
      if (!isTrackDetail) {
        dispatch(clearTrackDetails()); // чистим стор при размонтировании
      }
      dispatch(clearTrackSkills());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTopicCategories(currentUser?.domainCompany?.global));
  }, []);

  const [errors, setErrors] = useState({
    name: false,
    shortDescription: false,
    categoryIds: false,
    duration: false,
    topics: false,
  });

  const [data, setData] = useState({
    id: null,
    name: '',
    shortDescription: '',
    fullDescription: '',
    coverImage: '',
    skillIds: [],
    competenceIds: [],
    categoryIds: [],
    fullCategory: [],
    duration: '',
    topics: [],
  });

  const [isModalCategory, setIsModalCategory] = useState(false);
  const [isChangedStatusTopic, changedStatusTopic] = useState(false);
  const [isSelectPhoto, setIsSelectPhoto] = useState(false);
  const [isAddMaterials, setIsAddMaterials] = useState(false);

  const [sortedTopicsCache, setSortedTopicsCache] = useState([]);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (isEdit && trackDetails && Object.keys(trackDetails).length > 0) {
      const sortedTopics = trackDetails.topics.sort((a, b) => a.position - b.position);
      setSortedTopicsCache(
        sortedTopics.map((j, index) => {
          return {
            id: j.id,
            position: index,
          };
        })
      );

      setData({
        ...data,
        id: trackDetails.id,
        name: trackDetails.name,
        shortDescription: trackDetails.shortDescription,
        fullDescription: trackDetails.fullDescription,
        coverImage: trackDetails.coverImage,
        skillIds: trackDetails.skills.map(i => i.id),
        competenceIds: trackDetails.competences.map(i => i.id),
        categoryIds: trackDetails.categories.map(i => i.id),
        fullCategory: trackDetails.categories,
        duration: trackDetails.duration,
        topics: sortedTopics,
        isProcessTrack: trackDetails.status === STATUS_IN_PROCESS,
      });

      dispatch(addTrackSkills(trackDetails.skills)); // добавляю скиллы для опшинов селекта
    }
  }, [trackDetails]);

  const templateCallback = () => {
    if (isTrackDetail && trackDetails?.id) {
      dispatch(getTrackDetails(trackDetails.id));
    } else {
      dispatch(postTracksFilters(tracksFilters.filters ? tracksFilters.filters : {}, tracksCurrentPage));
    }

    onClose();
  };

  const addCurrentTrack = copyData => {
    if (isCreate) delete copyData.id;
    dispatch(createTrack(copyData, () => templateCallback()));
  };

  const updateCurrentTrack = copyData => {
    //Сообщаем на сервер что есть изменения или же нет
    copyData.isChangedTopics = isChangedStatusTopic;

    if (isChangedStatusTopic) {
      let sortedTopicsCacheBuffer = [...sortedTopicsCache];

      copyData.topics = copyData.topics.map(elementTopic => {
        const changeTopicIndex = sortedTopicsCacheBuffer.findIndex(cacheTopic => cacheTopic.id === elementTopic.id);
        if (changeTopicIndex >= 0) {
          let bufferObject = {};
          if (elementTopic.position === sortedTopicsCacheBuffer[changeTopicIndex].position) {
            bufferObject = { ...elementTopic, actionType: 'NOT_CHANGE_POSITION' };
          } else {
            bufferObject = { ...elementTopic, actionType: 'CHANGE_POSITION' };
          }
          sortedTopicsCacheBuffer.splice(changeTopicIndex, 1);
          return bufferObject;
        } else {
          return { ...elementTopic, actionType: 'ADD' };
        }
      });

      if (sortedTopicsCacheBuffer.length > 0) {
        sortedTopicsCacheBuffer.forEach(elemTop => {
          copyData.topics.push({ ...elemTop, position: -1, actionType: 'DELETE' });
        });
      }
    }

    const topicsIdsForAdd = copyData.topics.filter(j => j.actionType === 'ADD').map(i => i.id);
    const paramsStatus = { status: PROCESS_ON_UPDATE, type: TYPE_UPDATE, topics: topicsIdsForAdd };

    dispatch(
      changeStatusTrack(trackDetails?.id, paramsStatus, res => {
        if (res?.processId) {
          const newFilters = { ...copyData, trackProcessId: res.processId };
          dispatch(updateTrack(copyData.id, newFilters, () => templateCallback()));
          // templateCallback();
        }
      })
    );
  };

  const onSubmit = () => {
    if (!data.name || !data.shortDescription || !data.categoryIds.length || !data.duration || !data.topics.length > 0) {
      setErrors({
        name: !data.name,
        shortDescription: !data.shortDescription,
        categoryIds: !data.categoryIds.length > 0,
        duration: !data.duration,
        topics: !data.topics.length > 0,
      });
      return;
    }

    let copyData = _.cloneDeep(data);

    for (let key in copyData) {
      if (_.isString(copyData[key])) {
        copyData[key] = copyData[key].trim();
      }
    }

    copyData.topics = copyData.topics.map((j, index) => {
      return {
        id: j.id,
        position: index,
      };
    });

    delete copyData.fullCategory;

    if (form) {
      form.validateFields(err => {
        if (!err) {
          if (isCreate) {
            addCurrentTrack(copyData);
          } else {
            updateCurrentTrack(copyData);
          }
        }
      });
    } else {
      if (isCreate) {
        addCurrentTrack(copyData);
      } else {
        updateCurrentTrack(copyData);
      }
    }
  };

  const handleChangeCategory = value => {
    let catIds = value.map(i => i.id);
    setData({ ...data, categoryIds: catIds, fullCategory: value });
    setIsModalCategory(false);
  };

  const deleteCategory = catId => {
    let filteredCategory = data.categoryIds.filter(i => i !== catId);
    let filteredFullCategory = data.fullCategory.filter(i => i.id !== catId);
    setData({ ...data, categoryIds: filteredCategory, fullCategory: filteredFullCategory });
  };

  const handleSelect = (ids, name) => {
    const maxValue = name === COMPETENCIES ? MAX_COMPETENCIES : MAX_SKILLS;
    const compOrSkills = name === COMPETENCIES ? COMPETENCIES : SKILLS;
    const errorText = name === COMPETENCIES ? t('errorComp') : t('errorSkills');

    if (ids.length <= maxValue) {
      setData({ ...data, [compOrSkills]: ids });
    } else {
      message.error(errorText);
      return;
    }
  };

  const onChangeUpload = e => {
    if (e && IMAGE_TYPE.includes(e.type)) {
      e.fileList?.length > 0 && setIsSelectPhoto(true);
    }
  };

  const removePhotoUuid = () => {
    setIsSelectPhoto(false);
    setData({ ...data, coverImage: '' });
    setFileList(null);
  };

  const uploadProductImage = info => {
    if (IMAGE_TYPE.includes(info.file.type)) {
      const formData = new FormData();

      setFileList([
        {
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
        },
      ]);
      setIsSelectPhoto(true);

      formData.append('file', info.file);
      if (formData) {
        dispatch(
          createFileImage(
            formData,
            res => {
              if (res.uuid) setData({ ...data, coverImage: res.uuid });
            },
            err => {
              setIsSelectPhoto(false);
              setFileList(null);
              message.error(err?.message || t('imageUploadError'));
            }
          )
        );
      }
    } else {
      setIsSelectPhoto(false);
      message.warning(t('infoUploadImage'));
    }
  };

  const addMaterialParts = newTopics => {
    let materials = newTopics.map((k, index) => {
      return { position: index, ...k };
    });
    setData({ ...data, topics: materials });

    if (!isChangedStatusTopic) {
      changedStatusTopic(true);
    }

    setIsAddMaterials(false);
  };

  const deleteTopic = val => {
    if (!isChangedStatusTopic) {
      changedStatusTopic(true);
    }

    const filteredTopics = data.topics.filter(j => j.id !== val);
    setData({ ...data, topics: filteredTopics });
  };

  const havePermissions = isCreate
    ? currentUser?.authorities.includes(TRACK_CREATE)
    : currentUser?.authorities.includes(TRACK_UPDATE);

  const footerModal = havePermissions ? (
    <>
      <Button onClick={onClose} key='close' size='large'>
        {t('close')}
      </Button>
      <Button
        onClick={onSubmit}
        key='onSubmit'
        // disabled={!data.name || !data.shortDescription || !data.categoryIds.length || !data.duration || !data.topics}
        size='large'
        type='primary'
      >
        {isCreate ? t('create') : t('update')}
      </Button>
    </>
  ) : (
    <Button onClick={onClose} key='close2' size='large'>
      {t('close')}
    </Button>
  );

  const tagsCategory =
    data.fullCategory?.length > 0 &&
    data.fullCategory.map(j => {
      return (
        <Tag closable onClose={() => deleteCategory(j.id)} color='var(--personalColor)' key={j.id}>
          {j.name}
        </Tag>
      );
    });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    changedStatusTopic(true);
    if (!result.destination) return; // dropped outside the list
    const items = reorder(data.topics, result.source.index, result.destination.index);
    setData({ ...data, topics: items });
  };

  const topicsTrackFunc = () => {
    if (data.topics?.length > 0) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='Tracks'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.topics.map((k, index) => {
                  return (
                    <Draggable
                      key={k.id}
                      index={index}
                      draggableId={k.id.toString()}
                      isDragDisabled={data.isProcessTrack}
                    >
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <TrackTopics
                            topicData={k}
                            key={k.id}
                            deleteTopic={deleteTopic}
                            trackIndex={index}
                            isModalTrack
                            isProcess={data.isProcessTrack}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  };

  const renderOptions = arr => {
    return (
      arr?.length &&
      arr.map(item => (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      ))
    );
  };

  return (
    <Modal
      open={open}
      title={isCreate ? t('titleCreate') : t('titleEdit')}
      className={css['ModalTrack']}
      onCancel={onClose}
      footer={!isLoading && !isChangeStatus && footerModal}
      width={800}
    >
      <Spin size='large' className={css['ModalTrack-spin']} spinning={isLoading || isChangeStatus}>
        <Form className={css['ModalTrack-form']} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label={t('name')}
            colon={false}
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name && t('notName')}
            required
          >
            <Input
              size='large'
              onChange={e => setData({ ...data, name: e.target.value })}
              value={data.name}
              placeholder={t('placeholderName')}
              maxLength={255}
            />
          </Form.Item>

          <Form.Item
            label={t('shortDesc')}
            colon={false}
            validateStatus={errors.shortDescription ? 'error' : ''}
            help={errors.shortDescription && t('notShortDescription')}
            required
          >
            <Input
              maxLength={500}
              size='large'
              onChange={e => setData({ ...data, shortDescription: e.target.value })}
              value={data.shortDescription}
              placeholder={t('placeholderShortDescription')}
            />
          </Form.Item>

          <Form.Item label={t('description')} colon={false}>
            <TextArea
              rows={5}
              maxLength={4096}
              onChange={e => setData({ ...data, fullDescription: e.target.value })}
              value={data.fullDescription}
              placeholder={t('placeholderDescription')}
              size='large'
            />
          </Form.Item>

          <Form.Item label={t('image')} colon={false}>
            <div>
              <div className={css['ModalTrack-form-upload']}>
                <Alert message={t('infoUploadImage')} type='info' closable />
              </div>
              {isLoadingImage ? (
                <div className={css['ModalTrack-form-spin']}>
                  <Spin spinning={isLoadingImage} size='large' />
                </div>
              ) : (
                <>
                  <Upload
                    fileList={fileList}
                    accept='image/jpg, image/jpeg, image/png'
                    listType='picture'
                    onRemove={removePhotoUuid}
                    onChange={onChangeUpload}
                    customRequest={uploadProductImage}
                  >
                    <Button disabled={isSelectPhoto} type='link' className={css['ModalTrack-form-chooseBtn']}>
                      {t('choose')}
                    </Button>
                  </Upload>

                  {isEdit && !isSelectPhoto && (
                    <div>
                      <ImageBlock photoUuid={data.coverImage} okText={t('close')} />
                    </div>
                  )}
                </>
              )}
            </div>
          </Form.Item>
          <Form.Item label={t('skills')} colon={false}>
            {data.skillIds?.length > 0 && (
              <div className={css['ModalTrack-form-choose']}>
                {t('selectSkills', { total: data.skillIds.length })}/{MAX_SKILLS}
              </div>
            )}
            <Select
              mode='multiple'
              size='large'
              allowClear
              showSearch
              optionFilterProp='children'
              onChange={e => handleSelect(e, SKILLS)}
              onSearch={e =>
                e?.length > 1 && dispatch(getSkills(e, null, () => {}, currentUser?.domainCompany?.global))
              }
              value={data.skillIds}
              placeholder={t('placeholderSkills')}
            >
              {renderOptions(skills)}
            </Select>
          </Form.Item>
          <Form.Item label={t('competencies')} colon={false}>
            {data.competenceIds?.length > 0 && (
              <div className={css['ModalTrack-form-choose']}>
                {t('selectCompetencies', { total: data.competenceIds.length })}/{MAX_COMPETENCIES}
              </div>
            )}

            <Select
              mode='multiple'
              size='large'
              allowClear
              showSearch
              optionFilterProp='children'
              onChange={e => handleSelect(e, COMPETENCIES)}
              value={data.competenceIds}
              placeholder={t('placeholderCompetencies')}
            >
              {renderOptions(competence)}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('category')}
            colon={false}
            validateStatus={errors.categoryIds ? 'error' : ''}
            help={errors.categoryIds && t('notCategoryIds')}
            required
          >
            <Button onClick={() => setIsModalCategory(true)} key='choose' size='large'>
              {t('choose')}
            </Button>
            <div className={css['ModalTrack-form-tags']}>{tagsCategory}</div>
          </Form.Item>
          <Form.Item
            label={t('days')}
            colon={false}
            validateStatus={errors.duration ? 'error' : ''}
            help={errors.duration && t('notDuration')}
            required
          >
            <InputNumber
              value={data.duration}
              size='large'
              min={1}
              onChange={e => setData({ ...data, duration: e })}
              placeholder={t('placeholderDuration')}
              className={css['ModalTrack-form-number']}
            />
          </Form.Item>
        </Form>

        <Divider />

        <Row justify='space-between'>
          <Col span={17} className={css['ModalTrack-trackContent-header']}>
            {t('trackContent')}
          </Col>
          <Col span={7} className={css['ModalTrack-trackContent-addMaterial']}>
            <Button
              onClick={() => setIsAddMaterials(true)}
              disabled={data.topics.length > 99 || data.isProcessTrack}
              key='addMaterials'
              size='large'
              type='primary'
            >
              {t('addMaterials')}
            </Button>
          </Col>
        </Row>

        <Form.Item validateStatus={errors.topics ? 'error' : ''} help={errors.topics && t('notTopics')} required>
          {data.topics.length > 0 ? (
            <>
              {data.isProcessTrack && (
                <Alert
                  className={css['ModalTrack-trackContent-alert']}
                  message={t('inProccesAlert')}
                  type='warning'
                  closable
                />
              )}

              {topicsTrackFunc()}
            </>
          ) : (
            <div className={css['ModalTrack-trackContent-noData']}>{t('noTopics')}</div>
          )}
        </Form.Item>
      </Spin>

      {isModalCategory && (
        <ModalCategory
          open={isModalCategory}
          closeModalCategory={() => setIsModalCategory(false)}
          categories={categories}
          name='categoryIds'
          domainCompany={currentUser?.domainCompany}
          onChange={handleChangeCategory}
          category={data.fullCategory}
        />
      )}

      {isAddMaterials && (
        <MaterialModal
          open={isAddMaterials}
          title={t('chooseMaterials')}
          limitText={t('trackLimitTopic', { limit: MAX_TOPICS })}
          onCancel={() => setIsAddMaterials(false)}
          onOk={addMaterialParts}
          selectedTopics={data.topics}
          hasLimit={{ maxTopics: MAX_TOPICS }}
          isTrack
        />
      )}
    </Modal>
  );
};

ModalTrack.propTypes = {
  open: PropTypes.bool,
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  isTrackDetail: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.object,
};

export default ModalTrack;
