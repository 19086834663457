/* eslint-disable */
import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Tooltip,
} from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import './css/CreateField.css';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { InfoCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

export const FIELD_STRING = 1;
export const FIELD_TEXT = 2;
export const FIELD_NUMERIC = 3;
export const FIELD_DATE = 4;
export const FIELD_TIME = 5;
export const FIELD_DATETIME = 6;
export const FIELD_BOOLEAN = 7;

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const SHORT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATETIME_FORMAT = DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT;

export const MODE_CREATE = 'create';
export const MODE_EDIT = 'edit';

/**
 * Окно создания кастомных полей в профиле пользователя.
 *
 * @param props параметры функции (см. CreateField.propTypes )
 * @returns {*}
 * @constructor
 */
export const CreateField = props => {
  const { t } = useTranslation('varDirectory');

  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [type, setType] = useState(null);
  const [value, setValue] = useState(null);
  const [sorted, setSorted] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [showInProfile, setShowInProfile] = useState(false);
  const [enabledAsUserFilter, setEnabledAsUserFilter] = useState(false);
  const [tab, setTab] = useState(1);
  const [position, setPosition] = useState(0);
  const [showAlert, setShowAlert] = useState(true);

  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledFieldType, setDisabledFieldType] = useState(true);

  useEffect(() => {
    if (props.mode === MODE_CREATE) {
      setId(null);
      setName(null);
      setDescription(null);
      setType(FIELD_STRING);
      setValue(null);
      setSorted(false);
      setFiltered(false);
      setDisabledFieldType(false);
      setEnabledAsUserFilter(false);
    }

    setShowAlert(true);

    if (props.mode === MODE_EDIT) {
      setDisabledFieldType(true);

      if (props.field.hasOwnProperty('id')) {
        setId(props.field.id);
      }

      if (props.field.hasOwnProperty('name')) {
        setName(props.field.name);
      }

      if (props.field.hasOwnProperty('description')) {
        setDescription(props.field.description);
      }

      if (props.field.hasOwnProperty('defaultValue')) {
        setValue(props.field.defaultValue);
      }

      if (props.field.hasOwnProperty('canSorted')) {
        setSorted(props.field.canSorted === true);
      }

      if (props.field.hasOwnProperty('showInProfile')) {
        setShowInProfile(props.field.showInProfile === true);
      }

      if (props.field.hasOwnProperty('canFiltered')) {
        setFiltered(props.field.canFiltered === true);
      }

      if (props.field.hasOwnProperty('enabledAsUserFilter')) {
        setEnabledAsUserFilter(props.field.enabledAsUserFilter === true);
      }

      if (props.field.hasOwnProperty('tabId')) {
        setId(props.field.id);
      }

      if (props.field.hasOwnProperty('position')) {
        setPosition(props.field.position);
      }

      if (props.field.hasOwnProperty('fieldType')) {
        if (props.field.fieldType.hasOwnProperty('id') && props.field.fieldType.hasOwnProperty('type')) {
          onChangeType(props.field.fieldType.id);
        }
      }
    }
  }, [props.field, props.mode]);

  /**
   * Валидация данных
   */
  useEffect(() => {
    if (
      name !== null &&
      name.length > 0 &&
      description !== null &&
      description.length > 0 &&
      type >= FIELD_STRING &&
      type <= FIELD_BOOLEAN
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [name, description, type]);

  /**
   * Установка значения поля.
   *
   * @param event Значение поля
   * @param eType Тип поля
   */
  const onChangeValue = (event, eType) => {
    switch (eType) {
      case FIELD_STRING:
        setValue(event.target.value);
        break;

      case FIELD_TEXT:
        setValue(event.target.value);
        break;

      case FIELD_NUMERIC:
        setValue(event);
        break;

      case FIELD_DATE:
        event !== null ? setValue(event.format(DEFAULT_DATE_FORMAT)) : setValue(null);

        break;

      case FIELD_TIME:
        event !== null ? setValue(event.format(DEFAULT_TIME_FORMAT)) : setValue(null);

        break;

      case FIELD_DATETIME:
        event !== null ? setValue(event.format(DEFAULT_DATETIME_FORMAT)) : setValue(null);

        break;

      case FIELD_BOOLEAN:
        setValue(event);
        break;
    }
  };

  /**
   * Установка типа поля.
   *
   * @param eType int
   */
  const onChangeType = eType => {
    if (eType >= FIELD_STRING && eType <= FIELD_BOOLEAN) {
      setType(eType);
    }
  };

  /**
   * Рендеринг полей.
   *
   * @returns {*}
   */
  const renderElement = () => {
    let validValue = null;
    const { placeholderValue } = props.field;
    switch (type) {
      // Строковое поле
      case FIELD_STRING:
        value !== null ? (validValue = value) : (validValue = '');

        return (
          <Input
            placeholder={placeholderValue || t('enterValuePlaceholder')}
            maxLength={255}
            allowClear={true}
            onChange={event => onChangeValue(event, type)}
            value={value}
          />
        );

      // Текстовое поле
      case FIELD_TEXT:
        value !== null ? (validValue = value) : (validValue = '');

        return (
          <TextArea
            placeholder={placeholderValue || t('enterValuePlaceholder')}
            rows={4}
            maxLength={1024}
            onChange={event => onChangeValue(event, type)}
            value={validValue}
          />
        );

      // Цифровое поле
      case FIELD_NUMERIC:
        !Number.isNaN(Number.parseInt(value)) || value !== null || typeof value !== 'number'
          ? (validValue = value || null)
          : (validValue = 0);

        return (
          <InputNumber
            defaultValue={0}
            placeholder={placeholderValue || t('chooseNumberPlaceholder')}
            min={0}
            style={{ width: '100%' }}
            onChange={event => onChangeValue(event, type)}
            value={validValue}
          />
        );

      // Дата
      case FIELD_DATE:
        dayjs(value, DEFAULT_DATE_FORMAT).isValid() ? dayjs(value, DEFAULT_DATE_FORMAT) : dayjs();

        return (
          <DatePicker
            placeholder={placeholderValue || t('chooseDatePlaceholder')}
            style={{ width: '100%' }}
            showNow={true}
            showToday={true}
            showTime={false}
            onChange={event => onChangeValue(event, type)}
            defaultValue={dayjs(value).isValid() ? dayjs(value) : null}
            displayValue={dayjs(value)}
          />
        );

      // Время
      case FIELD_TIME:
        dayjs(value, DEFAULT_TIME_FORMAT, true) ? dayjs(value, DEFAULT_TIME_FORMAT) : dayjs();

        return (
          <TimePicker
            placeholder={placeholderValue || t('chooseTimePlaceholder')}
            style={{ width: '100%' }}
            onChange={event => onChangeValue(event, type)}
            defaultValue={dayjs(value).isValid() ? dayjs(value) : null}
            displayValue={dayjs(value)}
          />
        );

      // Дата и время
      case FIELD_DATETIME:
        dayjs(value, DEFAULT_DATETIME_FORMAT, true) ? dayjs(value, DEFAULT_DATETIME_FORMAT) : dayjs();

        return (
          <Row>
            <Col span={24}>
              <DatePicker
                placeholder={placeholderValue || t('chooseDateAndTimePlaceholder')}
                showTime={true}
                showNow={true}
                showToday={true}
                style={{ width: '100%' }}
                onChange={event => onChangeValue(event, type)}
                defaultValue={dayjs(value).isValid() ? dayjs(value) : null}
                displayValue={dayjs(value)}
              />
            </Col>
          </Row>
        );

      // Чекбокс
      case FIELD_BOOLEAN:
        value === 'true' || value === true ? (validValue = true) : (validValue = false);

        return <Switch onChange={event => onChangeValue(event, type)} checked={validValue} />;
    }
  };

  /**
   * Рендеринг подвала окна.
   *
   * @returns {*}
   */
  const renderFooter = () => {
    return (
      <>
        <Button onClick={() => props.onCancel()} type={'default'} style={{ width: '180px', marginRight: '22px' }}>
          {t('cancel')}
        </Button>

        <Button
          onClick={() => {
            props.onSubmit(
              id,
              name,
              description,
              type,
              value,
              position,
              tab,
              showInProfile,
              sorted,
              filtered,
              enabledAsUserFilter,
              props.mode
            );
          }}
          type={'primary'}
          disabled={disabledSave}
          style={{ width: '180px' }}
        >
          {t('save')}
        </Button>
      </>
    );
  };

  /**
   * Заголовок окна.
   *
   * @type {string}
   */
  const windowTitlte = props.mode === MODE_CREATE ? `${t('additionalTitles.0')}` : `${t('additionalTitles.1')}`;

  const changeAlertVisible = () => setShowAlert(!showAlert);

  const alertText = (
    <div className={'CreateField-alert-text'}>
      <div>{t('createMessage1')}</div>
      <div>{t('createMessage2')}</div>
      <div>
        {t('createMessage3')}{' '}
        <Link to='https://kampus.atlassian.net/wiki/spaces/BTPQ/pages/132317247'>{t('instruction')}</Link>
      </div>
    </div>
  );

  return (
    <Modal
      title={<span style={{ fontSize: '24px' }}>{windowTitlte}</span>}
      open={props.visible}
      centered={true}
      footer={renderFooter()}
      forceRender={true}
      onCancel={props.onCancel}
      width={815}
    >
      {showAlert && (
        <Alert
          afterClose={changeAlertVisible}
          className={'CreateField-alert'}
          closable
          type='info'
          message={alertText}
          showIcon
          icon={<InfoCircleFilled className={'CreateField-alert-icon'} />}
        />
      )}
      <Form layout='vertical'>
        <Form.Item label={t('nameLabel')}>
          <Input
            maxLength={50}
            placeholder={t('enterName')}
            value={name}
            onChange={event => {
              if (!event.target.value.replace(/([A-Za-zА-Яа-я0-9 ]+)/, '')) {
                setName(event.target.value);
              }
            }}
          />
        </Form.Item>
        <Form.Item label={t('nameDescription')}>
          <TextArea
            maxLength={255}
            placeholder={t('enterDescription')}
            value={description}
            onChange={event => {
              setDescription(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label={
            <div>
              {t('typeLabel')}
              <Tooltip
                className={'CreateField-tooltip'}
                title={<span style={{ fontSize: '14px' }}>{t('typeHelp')}</span>}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          }
        >
          <Select value={type} style={{ width: '100%' }} onChange={onChangeType} disabled={disabledFieldType}>
            <Option value={FIELD_STRING}>{t('fieldCreateType.0')}</Option>
            <Option value={FIELD_TEXT}>{t('fieldCreateType.1')}</Option>
            <Option value={FIELD_NUMERIC}>{t('fieldCreateType.2')}</Option>
            <Option value={FIELD_DATE}>{t('fieldCreateType.3')}</Option>
            <Option value={FIELD_TIME}>{t('fieldCreateType.4')}</Option>
            <Option value={FIELD_DATETIME}>{t('fieldCreateType.5')}</Option>
            <Option value={FIELD_BOOLEAN}>{t('fieldCreateType.6')}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <div>
              {t('placeHoldereLabel')}
              <Tooltip
                className={'CreateField-tooltip'}
                title={<span style={{ fontSize: '14px' }}>{t('placeHolderHelp')}</span>}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          }
        >
          {renderElement()}
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={event => setShowInProfile(event.target.checked)} checked={showInProfile}>
            {t('sorting.2')}
          </Checkbox>
          <Checkbox onChange={event => setEnabledAsUserFilter(event.target.checked)} checked={enabledAsUserFilter}>
            {t('sorting.3')}
          </Checkbox>
        </Form.Item>
      </Form>
      {/* Оставил закомментированный код
        <Col span={12}>    Включить сортировку
          <Checkbox onChange={event => setSorted(event.target.checked)} checked={sorted}>
            {t('sorting.0')}
          </Checkbox>
        </Col>
        <Col span={12}>        Включить фильтрацию
          <Checkbox onChange={event => setFiltered(event.target.checked)} checked={filtered}>
            {t('sorting.1')}
          </Checkbox>
        </Col> */}
    </Modal>
  );
};

CreateField.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]).isRequired,
  field: PropTypes.object.isRequired,
};

export default CreateField;
