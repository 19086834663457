/* eslint-disable */
import { D360_STATE_FAIL, D360_STATE_START, D360_STATE_SUCCESS } from './constants';

const initialState = {
  loading: false,
  state: [],
  pageCurrent: 1,
  pagePer: 10,
  pageTotal: 0,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // Request start
    case D360_STATE_START:
      return { ...state, loading: true };

    // Request fail
    case D360_STATE_FAIL:
      return { ...state, loading: false };

    case D360_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        state: response.data.data,
        pageCurrent: response.data.current_page,
        pagePer: response.data.per_page,
        pageTotal: response.data.total,
      };

    // Default
    default:
      return state;
  }
};
