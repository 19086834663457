/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getDepartments } from '@/store/department/actions';
import { getFile } from '@/store/files/actions';
import { getStudentStat, toggleUserLocked } from '@/store/students/actions';
import { changeEmailNotification } from '@/store/users/actions';

import { selectStudents as studentsSelect } from '@/store/students/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Avatar, Button, Col, Modal, Row, Tooltip } from 'antd';
import EditUser from '@/pages/Users/EditUserDialog';
import { Card } from '@/components/Layout';

import PortraitPlaceholder from './PortraitPlaceholder.png';
import { isLDAPLogin } from './utils';

//import Modal from '@/components/Modal';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './Users.module.scss';

import { LDAP_CONFIGURE } from '@/constants/permissions';
import LDAP_OFF from '@/assets/images/LDAP_off.svg';
import LDAP_ON from '@/assets/images/LDAP_on.svg';
import lock from '@/assets/images/lock.svg';

const ACTIVE_USER = 'ACTIVE';
export class StudentProfileDialog extends Component {
  static propTypes = {
    domainCompany: PropTypes.object,
    authorities: PropTypes.array,
    getFile: PropTypes.func,
    getDepartments: PropTypes.func,
    changeUser: PropTypes.func,
    getStudents: PropTypes.func,
    getStudentStat: PropTypes.func,
    stat: PropTypes.object.isRequired,
    student: PropTypes.object.isRequired,
    toggleUserLocked: PropTypes.func,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: '',
      locked: props.student.locked,
      showModal: false,
    };

    if (this.props.student && this.props.student.status === ACTIVE_USER) {
      this.props.getStudentStat(this.props.student.id);
    }
  }

  componentDidMount() {
    if (this.props.student.localPhotoUuid) {
      this.props.getFile(this.props.student.localPhotoUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    } else if (this.props.student.photoUuid) {
      this.props.getFile(this.props.student.photoUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    } else {
      this.setState({ imageSrc: PortraitPlaceholder });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.student.locked !== this.props.student.locked) {
      this.setState({ locked: this.props.student.locked });
    }
  }

  toggleLocked = () => {
    const { student } = this.props;
    this.props.toggleUserLocked(student.id, !this.state.locked, res => {
      this.setState({ locked: res.locked });
    });
  };

  changeStudent = data => {
    this.props.changeUser(data, () => {
      this.props.onCancel();
      this.props.getStudents();
    });
  };

  render() {
    const { student, stat, authorities, t, ...props } = this.props;
    const createdDate = dayjs(student.createdDate);
    const daysInSystem = createdDate && dayjs().diff(createdDate, 'days');
    const DELETED_USER = student?.status && student.status === 'DELETED';
    const showDepartment = authorities.includes(LDAP_CONFIGURE);
    const hasAdditionalInfo = _get(this.props, 'domainCompany.hasAdditionalInfo');
    const hasManager = _get(this.props, 'domainCompany.hasManager');
    const isLDAP = isLDAPLogin(this.props);

    return (
      <Modal
        width={800}
        {...props}
        footer={[
          <Button type='primary' onClick={this.props.onCancel}>
            {t('close')}
          </Button>,
        ]}
      >
        <Row gutter={1} className={css['Students-profile']}>
          <Col span={12} className={css['Students-profile-col']}>
            <Card className={css['Students-profile-card']}>
              <div className={css['Students-profile-card-label']}>
                <h3>{t('personalData')}</h3>
                {showDepartment && (
                  <Button ghost onClick={() => this.setState({ showModal: true })}>
                    {t('edit')}
                  </Button>
                )}
              </div>
              <div className={css['Students-profile-data']}>
                <div className={css['Students-profile-data-row']}>
                  <Avatar
                    className={classNames(css['Students-profile-avatar'], {
                      [css.DeleteUser]: DELETED_USER,
                    })}
                    src={this.state.imageSrc}
                  />
                  {DELETED_USER && (
                    <Tooltip title={t('userDeleted')}>
                      <div className={classNames(css['DeleteUser-key'], css['DeleteUser-key-fix3'])}>
                        <img key='deleteUser' src={lock} alt='' />
                      </div>
                    </Tooltip>
                  )}
                  {isLDAP && (
                    <div
                      className={classNames(css['Lock-svg'], css.userDialog, {
                        [css['Lock-active']]: !this.state.locked,
                      })}
                      onClick={this.toggleLocked}
                    >
                      <img src={this.state.locked ? LDAP_OFF : LDAP_ON} alt='' />
                    </div>
                  )}
                </div>
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('email')}</div>
                  <div className={css['Students-profile-data-value']}>{student.email || t('notSpecified')}</div>
                </div>
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('name')}</div>
                  <div className={css['Students-profile-data-value']}>{student.firstName || t('notSpecified')}</div>
                </div>
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('lastName')}</div>
                  <div className={css['Students-profile-data-value']}>{student.lastName || t('notSpecified')}</div>
                </div>
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('middleName')}</div>
                  <div className={css['Students-profile-data-value']}>{student.middleName || t('notSpecified')}</div>
                </div>
                {hasManager && (
                  <div className={css['Students-profile-data-row']}>
                    <div className={css['Students-profile-data-label']}>{t('manager')}</div>
                    <div className={css['Students-profile-data-value']}>{student.manager || t('notSpecified')}</div>
                  </div>
                )}
                {hasAdditionalInfo && (
                  <>
                    <div className={css['Students-profile-data-row']}>
                      <div className={css['Students-profile-data-label']}>{t('position')}</div>
                      <div className={css['Students-profile-data-value']}>{student.position || t('notSpecified')}</div>
                    </div>
                    <div className={css['Students-profile-data-row']}>
                      <div className={css['Students-profile-data-label']}>{t('department')}</div>
                      <div className={css['Students-profile-data-value']}>
                        {student.department || t('notSpecified')}
                      </div>
                    </div>
                    <div className={css['Students-profile-data-row']}>
                      <div className={css['Students-profile-data-label']}>{t('function')}</div>
                      <div className={css['Students-profile-data-value']}>{student.function || t('notSpecified')}</div>
                    </div>
                    <div className={css['Students-profile-data-row']}>
                      <div className={css['Students-profile-data-label']}>{t('company')}</div>
                      <div className={css['Students-profile-data-value']}>{student.company || t('notSpecified')}</div>
                    </div>
                  </>
                )}
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('objective')}</div>
                  <div className={css['Students-profile-data-value']}>{student.purpose || t('notSpecified')}</div>
                </div>

                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('location')}</div>
                  <div className={css['Students-profile-data-value']}>{student.location || t('notSpecified')}</div>
                </div>
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('date')}</div>
                  <div className={css['Students-profile-data-value']}>{student.startOfJob || t('notSpecified')}</div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={12} className={css['Students-profile-col']}>
            <Card className={css['Students-profile-card']}>
              <h3>{t('statistic')}</h3>
              <h4>{t('recommended')}</h4>
              <div className={css['Students-profile-stat']}>
                <div className={css['Students-profile-stat-col']}>
                  <div className={css['Students-profile-stat-value']}>{stat.totalRecommend || 0}</div>
                  <div className={css['Students-profile-stat-label']}>{t('all')}</div>
                </div>
                <div className={css['Students-profile-stat-col']}>
                  <div className={css['Students-profile-stat-value']}>{stat.recommendFinished || 0}</div>
                  <div className={css['Students-profile-stat-label']}>{t('completed')}</div>
                </div>
                <div className={css['Students-profile-stat-col']}>
                  <div className={css['Students-profile-stat-value']}>
                    {(stat.totalRecommend || 0) - (stat.recommendFinished || 0)}
                  </div>
                  <div className={css['Students-profile-stat-label']}>{t('left')}</div>
                </div>
              </div>
              <h4>{t('other')}</h4>
              <div className={css['Students-profile-stat']}>
                <div className={css['Students-profile-stat-col']}>
                  <div className={css['Students-profile-stat-value']}>{stat.total || 0}</div>
                  <div className={css['Students-profile-stat-label']}>{t('all')}</div>
                </div>
                <div className={css['Students-profile-stat-col']}>
                  <div className={css['Students-profile-stat-value']}>{stat.finished || 0}</div>
                  <div className={css['Students-profile-stat-label']}>{t('completed')}</div>
                </div>
                <div className={css['Students-profile-stat-col']}>
                  <div className={css['Students-profile-stat-value']}>{(stat.total || 0) - (stat.finished || 0)}</div>
                  <div className={css['Students-profile-stat-label']}>{t('left')}</div>
                </div>
              </div>
            </Card>
            <Card className={css['Students-profile-card']}>
              <h3>{t('metrics')}</h3>
              <div className={css['Students-profile-data']}>
                {this.props.domainCompany && this.props.domainCompany.hasCoins && (
                  <div className={css['Students-profile-data-row']}>
                    <div className={css['Students-profile-data-label']}>{t('allCoins')}</div>
                    <div className={css['Students-profile-data-value']}>{student.account || 0}</div>
                  </div>
                )}
                <div className={css['Students-profile-data-row']}>
                  <div className={css['Students-profile-data-label']}>{t('inSystem')}</div>
                  <div className={css['Students-profile-data-value']}>{daysInSystem || 0}</div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {this.state.showModal && (
          <EditUser
            student={this.props.student}
            hasAdditionalInfo={hasAdditionalInfo}
            hasManager={hasManager}
            changeManagerModalVisibility={() => this.setState({ showModal: false })}
            changeUser={this.changeStudent}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([studentsSelect, usersSelect], (students, users) => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  authorities: users.currentUser.authorities,
  stat: students.stat || {},
}));

const mapActionsToProps = {
  getStudentStat,
  toggleUserLocked,
  getFile,
  getDepartments,
  changeUser: changeEmailNotification,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation('studentProfileDialog')(StudentProfileDialog));
