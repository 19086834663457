import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Input, Modal, Rate } from 'antd';

import PropTypes from 'prop-types';

import css from './ApproveModal.module.scss';

const { TextArea } = Input;

export const ApproveModal = props => {
  const [comment, setComment] = useState('');
  const [rate, setRate] = useState(0);

  const render = () => {
    return (
      <Modal open={props.open} width={604} onCancel={() => props.onCancel()} footer={false} zIndex={1005}>
        <div className={css['ApproveModal']}>
          <div className={css['ApproveModal__header']}>
            <p>{props.t('caption')}</p>
          </div>
          <div className={css['ApproveModal__restriction']}>
            <p>{props.t('max')}</p>
          </div>
          <TextArea
            className={css['ApproveModal__textarea']}
            rows={7}
            onChange={event => setComment(event.target.value)}
            value={comment}
            maxLength={500}
          />
          <Rate className={css['ApproveModal__rate']} onChange={value => setRate(value)} />
          <Button onClick={() => props.onSubmit(comment, rate)}>{props.t('button')}</Button>
        </div>
      </Modal>
    );
  };

  return render();
};

ApproveModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default withTranslation('approveModal')(ApproveModal);
