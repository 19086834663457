import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

import {
  GET_POKA_CATEGORIES_FAIL,
  GET_POKA_CATEGORIES_START,
  GET_POKA_CATEGORIES_SUCCESS,
} from '@/store/poka_categories/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getPokaDirCategory = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/category`,
  headers,
  types: [GET_POKA_CATEGORIES_START, GET_POKA_CATEGORIES_SUCCESS, GET_POKA_CATEGORIES_FAIL],
});
