// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: auto;
}

.ant-modal-confirm-body .anticon {
  color: revert-layer;
}
`, "",{"version":3,"sources":["webpack://./shared/styles/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAGA;EAAE,mBAAmB;AACrB","sourcesContent":["html {\n  height: auto;\n}\n\n/* Костыль на версии анта 5.20.3 как поправят, нужно обновить и удалить костыль https://github.com/ant-design/ant-design/issues/49377 */\n.ant-modal-confirm-body .anticon {\n  color: revert-layer;\n}\n\n/* Конец костыля на версии анта 5.20.3 */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
