/* eslint-disable */
import React, { Component } from 'react';

import { Layout } from 'antd';

import css from './ServerError.module.scss';

import locked from '@/assets/images/login-lock.png';

const { Content } = Layout;

export class CompanyBlocked extends Component {
  render() {
    return (
      <Layout className={css.ServerError}>
        <Content className={css.LoginContent}>
          <div className={css['App-card-item']}>
            <div className={css['App-card-item-imageBlock']}>
              <img src={locked} alt='' />
            </div>
            <div className={css['App-card-item-text']}>
              Доступ к платформе недоступен.
              <br />
              Пожалуйста, свяжись с командой Kampus
            </div>
            {this.props?.company?.supportLink && (
              <div
                className={css['App-card-item-link']}
                onClick={() => window.location.replace(this.props.company.supportLink)}
              >
                Связаться
              </div>
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}
