// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MassSelection-components-Footer-style-module__ql-picker-shOVzR.admin-src-components-MassSelection-components-Footer-style-module__ql-font-Ws8Rx5 .admin-src-components-MassSelection-components-Footer-style-module__ql-picker-label-doJan0[data-value="Montserrat"]:before, .admin-src-components-MassSelection-components-Footer-style-module__ql-picker-shOVzR.admin-src-components-MassSelection-components-Footer-style-module__ql-font-Ws8Rx5 .admin-src-components-MassSelection-components-Footer-style-module__ql-picker-item-p5erDT[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MassSelection-components-Footer-style-module__ql-font-Montserrat-OIMvpd {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MassSelection-components-Footer-style-module__footer-wyMifd {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.admin-src-components-MassSelection-components-Footer-style-module__footer__switch-CeT1KU {
  align-items: center;
  gap: 6px;
  display: flex;
}

.admin-src-components-MassSelection-components-Footer-style-module__footer__buttons-ecZ04u {
  gap: 20px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MassSelection/components/Footer/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAMA;EAHE,mBAAmB;EACnB,QAAQ;EACR,aAAa;AACf;;AAKA;EAFE,SAAS;EACT,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.footer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.footer__switch {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}\n.footer__buttons {\n  display: flex;\n  gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MassSelection-components-Footer-style-module__ql-picker-shOVzR`,
	"qlPicker": `admin-src-components-MassSelection-components-Footer-style-module__ql-picker-shOVzR`,
	"ql-font": `admin-src-components-MassSelection-components-Footer-style-module__ql-font-Ws8Rx5`,
	"qlFont": `admin-src-components-MassSelection-components-Footer-style-module__ql-font-Ws8Rx5`,
	"ql-picker-label": `admin-src-components-MassSelection-components-Footer-style-module__ql-picker-label-doJan0`,
	"qlPickerLabel": `admin-src-components-MassSelection-components-Footer-style-module__ql-picker-label-doJan0`,
	"ql-picker-item": `admin-src-components-MassSelection-components-Footer-style-module__ql-picker-item-p5erDT`,
	"qlPickerItem": `admin-src-components-MassSelection-components-Footer-style-module__ql-picker-item-p5erDT`,
	"ql-font-Montserrat": `admin-src-components-MassSelection-components-Footer-style-module__ql-font-Montserrat-OIMvpd`,
	"qlFontMontserrat": `admin-src-components-MassSelection-components-Footer-style-module__ql-font-Montserrat-OIMvpd`,
	"footer": `admin-src-components-MassSelection-components-Footer-style-module__footer-wyMifd`,
	"footer__switch": `admin-src-components-MassSelection-components-Footer-style-module__footer__switch-CeT1KU`,
	"footerSwitch": `admin-src-components-MassSelection-components-Footer-style-module__footer__switch-CeT1KU`,
	"footer__buttons": `admin-src-components-MassSelection-components-Footer-style-module__footer__buttons-ecZ04u`,
	"footerButtons": `admin-src-components-MassSelection-components-Footer-style-module__footer__buttons-ecZ04u`
};
export default ___CSS_LOADER_EXPORT___;
