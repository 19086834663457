/* eslint-disable */
import { ApiService } from '@shared/services/api-service';

export class LoginService extends ApiService {
  login = (credentials, params) => this.post('/login', credentials, { params });

  logout = () => this.delete('/logout');
}

export default new LoginService();
