import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getConsecutive, postTracksFilters } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import ModalTrack from '@/components/ModalTrack';
import { PlusOutlined } from '@ant-design/icons';

import Filters from './Content/Filters';
import TableTracks from './Content/TableTracks';

import css from './Tracks.module.scss';

import { TRACK_CREATE } from '@/constants/permissions';

const { Title } = Typography;

const Tracks = () => {
  const { t } = useTranslation('tracks');
  const dispatch = useDispatch();

  const { tracksFilters } = useSelector(selectTracks);
  const { currentUser } = useSelector(selectUsers);
  const [isModalTrack, setIsModalTrack] = useState(false);

  useEffect(() => {
    dispatch(getConsecutive());
    dispatch(postTracksFilters(tracksFilters.filters ? tracksFilters.filters : {}));
  }, [dispatch]);

  const showModalTrack = () => {
    setIsModalTrack(true);
  };

  return (
    <Layout>
      <Content>
        <div className={css['Tracks']}>
          <div className={css['Tracks-header']}>
            <Title>{t('title')}</Title>
            {currentUser?.authorities.includes(TRACK_CREATE) && (
              <Button icon={<PlusOutlined />} onClick={showModalTrack} key='createTrack' size='large' type='primary'>
                {t('createTrack')}
              </Button>
            )}
          </div>
        </div>
        <div className={css['Tracks-main']}>
          <Filters />
          <TableTracks />
        </div>
      </Content>

      <ModalTrack open={isModalTrack} onClose={() => setIsModalTrack(false)} isCreate />
    </Layout>
  );
};

export default Tracks;
