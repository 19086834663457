import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getRegistrationOpenDate, setRegistrationStartDate } from '@/store/courses/actions';
import { getTopicTypesAuto, setTopicTypesAuto } from '@/store/topics/actions';

import { selectCourses } from '@/store/courses/selectors';
import { selectTopicsTypesAuto } from '@/store/topics/selectors';

import { Button, Checkbox, DatePicker } from 'antd';

import dayjs from 'dayjs';
import deepEqual from 'deep-equal';

import css from './SettingRequests.module.scss';

const FORMATE_DATE = 'YYYY-MM-DD';

const SettingRequests = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingRequests');

  const typesAuto = useSelector(selectTopicsTypesAuto);
  const { openDate } = useSelector(selectCourses);

  const [dateValue, setDateValue] = useState(openDate);
  const [topicTypeIds, setTopicTypeIds] = useState([]);

  const disabledDate = current => dayjs().add(-1, 'days').isAfter(dayjs(current));

  const onChangeCheck = type => {
    const findIndex = topicTypeIds.findIndex(i => i === type.id);
    if (findIndex === -1) {
      setTopicTypeIds([...topicTypeIds, type.id]);
    } else {
      const filtered = topicTypeIds.filter(i => i !== type.id);
      setTopicTypeIds(filtered);
    }
  };

  const checkItems =
    typesAuto?.length > 0 &&
    typesAuto.map(type => (
      <Checkbox key={type.id} defaultChecked={type.autoRegistration} onChange={() => onChangeCheck(type)}>
        {type.name}
      </Checkbox>
    ));

  const disableSubmit = () => {
    const newDate = dateValue && dayjs(dateValue).format(FORMATE_DATE);
    const currTypes = typesAuto.filter(i => i.autoRegistration).map(i => i.id);
    const isEqualDate = deepEqual(openDate, newDate);
    const isEqualArr = JSON.stringify(topicTypeIds) === JSON.stringify(currTypes);
    return isEqualDate && isEqualArr;
  };

  const onSubmit = () => {
    const newDate = dateValue && dayjs(dateValue).format(FORMATE_DATE);
    const isEqualDate = openDate === newDate;

    if (!isEqualDate) {
      dispatch(setRegistrationStartDate(dayjs(dateValue).format(FORMATE_DATE)));
    }
    dispatch(setTopicTypesAuto({ topicTypeIds: topicTypeIds }));
  };

  useEffect(() => {
    if (!typesAuto?.length) dispatch(getTopicTypesAuto());
    if (!openDate) dispatch(getRegistrationOpenDate());
  }, []);

  useEffect(() => {
    if (openDate) {
      setDateValue(openDate);
    }
  }, [openDate]);

  useEffect(() => {
    const filteredTypeId = typesAuto.filter(i => i.autoRegistration).map(i => i.id);
    setTopicTypeIds(filteredTypeId);
  }, [typesAuto]);

  return (
    <div className={css['SettingRequests']}>
      <div className={css['SettingRequests-content']}>
        <div>
          {t('chooseMonthPicker')}
          <DatePicker
            picker='month'
            className={css['SettingRequests-content-datePicker']}
            value={dateValue && dayjs(dateValue)}
            format='MM.YYYY'
            onChange={e => setDateValue(e)}
            disabledDate={disabledDate}
          />
        </div>
        <div className={css['SettingRequests-content-check']}>
          {t('autoregisterCheck')} {checkItems}
        </div>
        <div className={css['SettingRequests-content-footer']}>
          <Button type='primary' onClick={onSubmit} disabled={disableSubmit()}>
            {t('apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingRequests;
