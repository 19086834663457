import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { loadPokaAreas, savePokaAreasFilters, savePokaAreasSort } from '@/store/poka_areas/actions';
import { clearStudents, getStudents } from '@/store/students/actions';

import { selectPokaProperAreas } from '@/store/poka_areas/selectors';
import { selectPokaSites } from '@/store/poka_sites/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Checkbox, Col, Form, Row, Select, Spin } from 'antd';

import { DEFAULT_PARAMS } from '../constants';

import { deleteEmpty } from '@shared/utils/deleteEmpty';

import _ from 'lodash';

import css from '../AreasDirectory.module.scss';

const FilterAreasDirectory = () => {
  const { t } = useTranslation('AreasDirectory');
  const dispatch = useDispatch();

  const { pokaSites, loadingPokaSites } = useSelector(selectPokaSites);
  const { students, isLoadingStudents } = useSelector(selectStudents);
  const { pokaProperAreasSort, pokaDirSourAreas, loadingPokaDirSourAreas } = useSelector(selectPokaProperAreas);

  const [data, setData] = useState({
    userId: null,
    siteIds: [],
    areaIds: [],
    isCorrectPositionAreaEmpty: true,
    isCollaboratorMentorEmpty: null,
  });
  const [fullNameState, setFullNameState] = useState({ value: null, label: '' });

  const onSubmitFilters = () => {
    const cleanedData = deleteEmpty(data);
    dispatch(savePokaAreasFilters(cleanedData));
    dispatch(loadPokaAreas({ ...DEFAULT_PARAMS, sort: pokaProperAreasSort }, cleanedData));
  };

  const onResetFilters = () => {
    setData({
      userId: null,
      siteIds: [],
      areaIds: [],
      isCorrectPositionAreaEmpty: true,
      isCollaboratorMentorEmpty: null,
    });
    setFullNameState({ value: null, label: '' });
    dispatch(savePokaAreasFilters({}));
    dispatch(savePokaAreasSort(''));
    dispatch(loadPokaAreas(DEFAULT_PARAMS, { isCorrectPositionAreaEmpty: true }));
    dispatch(clearStudents());
  };

  const handleSearchStudents = e => {
    if (e) {
      let fullName = e.trim();
      if (fullName.length > 3) dispatch(getStudents({ fullName: e, size: 100 }));
    }
  };

  const getStudentsDebounced = useCallback(_.debounce(handleSearchStudents, 1000), []);

  const handleCheckboxChange = e => setData(prev => ({ ...prev, [e.name]: e.checked }));

  const studentsOptions = useMemo(
    () =>
      students.map(el => ({
        value: el?.id,
        label: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''}`,
      })),
    [students]
  );

  const onChangeFullName = user => {
    setData({ ...data, userId: user?.value ?? null });
    setFullNameState(user ?? { value: null, label: '' });
    dispatch(clearStudents());
  };

  const selectsData = [
    {
      label: t('fullname'),
      placeholder: t('fullnamePlaceholder'),
      onChange: user => onChangeFullName(user),
      value: fullNameState,
      loading: isLoadingStudents,
      onSearch: getStudentsDebounced,
      options: studentsOptions,
      optionLabelProp: 'label',
      labelInValue: true,
    },
    {
      label: t('site'),
      placeholder: t('placeholderSelect'),
      options: pokaSites,
      onChange: siteIds => setData(prev => ({ ...prev, siteIds })),
      value: data.siteIds,
      loading: loadingPokaSites,
      mode: 'multiple',
    },
    {
      label: t('area'),
      placeholder: t('placeholderSelect'),
      options: pokaDirSourAreas,
      onChange: areaIds => setData(prev => ({ ...prev, areaIds })),
      value: data.areaIds,
      loading: loadingPokaDirSourAreas,
      mode: 'multiple',
    },
  ];

  const renderFilters = item => {
    return (
      <Form.Item key={item.label} label={item.label} colon={false}>
        <Select
          mode={item.mode}
          showSearch
          allowClear
          onSearch={item.onSearch}
          onChange={item.onChange}
          value={item.value}
          placeholder={item.placeholder}
          optionFilterProp='label'
          loading={item.loading}
          notFoundContent={item.loading ? <Spin size='small' /> : t('nothingFound')}
          options={item.options}
          optionLabelProp={item.optionLabelProp}
          labelInValue={item.labelInValue}
        />
      </Form.Item>
    );
  };

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-txt']}>{t('filters')}</div>
      <Form layout='vertical'>
        <Row type='flex' justify='start' gutter={16}>
          {selectsData.map((item, index) => (
            <Col span={12} key={index}>
              {renderFilters(item)}
            </Col>
          ))}
          <Col span={12} className={css['Filters-checkbox']}>
            <Checkbox
              onChange={e => handleCheckboxChange(e.target)}
              checked={data.isCorrectPositionAreaEmpty}
              name='isCorrectPositionAreaEmpty'
            >
              {t('missingProperArea')}
            </Checkbox>
            <Checkbox
              onChange={e => handleCheckboxChange(e.target)}
              checked={data.isCollaboratorMentorEmpty}
              name='isCollaboratorMentorEmpty'
            >
              {t('missingMentor')}
            </Checkbox>
          </Col>
        </Row>

        <Row justify='end' gutter={16}>
          <Col>
            <Button onClick={onResetFilters}>{t('reset')}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmitFilters} type='primary'>
              {t('apply')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterAreasDirectory;
