import { Resources } from '../resources';

// TOPIC_IMAGE deprecated
export const TOPIC_IMAGE = [
  {
    id: 1,
    name: 'Уч. курс',
    image: Resources.Images.Course,
  },
  {
    id: 17,
    name: 'Плат. курс',
    image: Resources.Images.CourseImage,
    imageCompany: Resources.Images.CourseImageCompany,
  },
  {
    id: 35,
    name: 'Плат. курс без согласования',
    image: Resources.Images.CourseImage,
    imageCompany: Resources.Images.CourseImageCompany,
  },
  {
    id: 38,
    name: 'Навык',
    image: Resources.Images.CourseImage,
    imageCompany: Resources.Images.CourseImageCompany,
  },
  {
    id: 4,
    name: 'Видео',
    image: Resources.Images.VideoImage,
  },
  {
    id: 5,
    name: 'Приложение/Сервис',
    image: Resources.Images.AppImage,
  },
  {
    id: 8,
    name: 'Событие',
    image: Resources.Images.EventImage,
  },
  {
    id: 23,
    name: 'Конференция',
    image: Resources.Images.Conference,
  },
  {
    id: 15,
    name: 'Статья',
    image: Resources.Images.ArticleImage,
  },
  {
    id: 16,
    name: 'Книга',
    image: Resources.Images.BookImage,
  },
  {
    id: 20,
    name: 'Внешний курс',
    image: Resources.Images.External,
  },
  {
    id: 25,
    name: 'Обучение на рабочем месте',
    image: Resources.Images.Disable,
  },
  {
    id: 404,
    name: 'disable',
    image: Resources.Images.Disable,
  },
];

export const defaultMaterialImage = {
  1: Resources.Images.Course,
  17: Resources.Images.CourseImageCompany,
  4: Resources.Images.VideoImage,
  5: Resources.Images.AppImage,
  8: Resources.Images.EventImage,
  23: Resources.Images.Conference,
  15: Resources.Images.ArticleImage,
  16: Resources.Images.BookImage,
  20: Resources.Images.External,
  25: Resources.Images.Disable,
  404: Resources.Images.Disable,
};

export const materialIcon = {
  1: Resources.Icons.Course,
  17: Resources.Icons.DefaultCorp,
  4: Resources.Icons.Video,
  5: Resources.Icons.Application,
  8: Resources.Icons.Event,
  23: Resources.Icons.Conference,
  15: Resources.Icons.Article,
  16: Resources.Icons.Book,
  20: Resources.Icons.External,
  25: Resources.Icons.Bars,
};
