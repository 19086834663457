import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getChapters } from '@/store/chapters/actions';
import { deleteBlock, getBlocks } from '@/store/lms/actions';
import { createTilda, deleteTilda, getTilda } from '@/store/tilda/actions';

import { selectTilda } from '@/store/tilda/selectors';

import { Button, Col, Dropdown, Input, Modal, Row, Spin, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { MdOutlinePublic } from 'react-icons/md';

import { CollapseLMS } from '../CollapseLMS';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';

const TILDAURL = 'https://export.tildacdn.com';

export const Tilda = props => {
  const { t } = useTranslation('tilda');
  const [topicId, setTopicId] = useState(null);
  const [tildaId, setTildaId] = useState(null);
  const [blockId, setBlockId] = useState(null);
  const [disableSave, setDisableSave] = useState(true);
  const [url, setUrl] = useState(null);
  const [disableUrl, setDisableUrl] = useState(false);
  const [tilda, setTilda] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props && props.block && props.block.id && props.block.id !== blockId) {
      setBlockId(props.block.id);
      setTildaId(props.block.tildaId);
      setTopicId(props.topicId);
      if (props.block.tildaId) {
        setLoading(true);
        props.getTilda(
          props.block.tildaId,
          success => {
            setTilda(success);
            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        );
      }
    }
  }, [blockId, props]);

  useEffect(() => {
    if (props && props.removed && props.removed === true && tildaId !== null) {
      props.deleteTilda(tildaId);
    }
  }, [props, props.removed, tildaId]);

  const onEdit = () => {
    props.editBlock(blockId);
  };

  const onDelete = () => {
    Modal.confirm({
      title: t('deleteTitle'),
      content: t('deleteContent'),
      okText: 'OK',
      onOk: () => {
        if (tildaId) {
          props.deleteTilda(tildaId, () => {
            props.deleteBlock(blockId, () => {
              props.getBlocks([topicId]);
              props.getChapters(topicId);
            });
          });
        } else {
          props.deleteBlock(blockId, () => {
            props.getBlocks([topicId]);
            props.getChapters(topicId);
          });
        }
      },
    });
  };

  // light: https://export.tildacdn.com/export/zips/project3417759_1649975769.zip
  // https://export.tildacdn.com/export/zips/project2930547_1649940380.zip
  const onSave = () => {
    setDisableSave(true);
    setDisableUrl(true);
    setLoading(true);
    props.createTilda(
      blockId,
      url,
      success => {
        setLoading(false);
        setTilda(success);
        setTildaId(success.id);
        props.getChapters(topicId);
      },
      () => {
        setDisableSave(false);
        setDisableUrl(false);
        setLoading(false);
      }
    );
  };

  const dropDownMenu = [
    ...(props.currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (props.topicTypeId === 25 && props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={onEdit}>
                {t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(props.currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (props.topicTypeId === 25 && props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={onDelete}>
                {t('delete')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const extra = () => {
    return (
      <div onClick={e => e.stopPropagation()}>
        <Button
          data-qa='saveBlockBtn'
          onClick={onSave}
          className={css['Block-header-save']}
          type='primary'
          disabled={disableSave}
        >
          {t('save')}
        </Button>
        {(props.currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
          props.currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
          (props.topicTypeId === 25 &&
            (props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
              props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
          <Dropdown
            menu={{ items: dropDownMenu }}
            placement='bottomRight'
            overlayClassName={css['Dropdown-menu']}
            trigger={['click']}
          >
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    );
  };

  const header = () => {
    return (
      <div className={css['Block-header']}>
        <MdOutlinePublic className={css['Block-header-svg']} />
        <div className={css['Block-header-name']}>
          <Tooltip title={props.block.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(props.block.name, 55)}
          </Tooltip>
        </div>
      </div>
    );
  };

  const onInput = e => {
    if (e.length >= TILDAURL.length) {
      if (e.substring(0, TILDAURL.length) === TILDAURL) {
        setUrl(e);
        setDisableSave(false);
        return;
      }
    }

    setDisableSave(true);
  };

  const getPages = () => {
    return Object.keys(tilda.pages).length;
  };

  const showHelp = () => {
    Modal.confirm({
      title: t('helpTitle'),
      content: <span dangerouslySetInnerHTML={{ __html: t('helpContent') }} />,
      okText: 'OK',
    });
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.Block}>
        <CollapseLMS header={header()} data={props.block} extra={extra()} forceClose={props.forceClose}>
          <div style={{ padding: ' 1rem' }}>
            {tilda === null && (
              <Row style={{ paddingBottom: '1rem' }}>
                <Col span={24}>
                  {t('url')} (
                  <span style={{ color: '#2c70bc', cursor: 'pointer' }} onClick={showHelp}>
                    {t('more')}
                  </span>
                  )
                </Col>
                <Col span={24}>
                  <Input onChange={e => onInput(e.target.value)} disabled={disableUrl} />
                </Col>
              </Row>
            )}

            {loading && loading === true && (
              <Row>
                <Col span={24} align='center'>
                  <Spin />
                </Col>
              </Row>
            )}

            {tilda && tilda.id && (
              <Row>
                <Col span={24}>{t('loaded', { total: getPages() })}</Col>
              </Row>
            )}
          </div>
        </CollapseLMS>
      </div>
    );
  };

  /**
   * Запуск компонента.
   */
  return render();
};

/**
 * Типизация свойств компонента.
 *
 * @type {{project: *}}
 */
Tilda.propTypes = {
  block: PropTypes.object.isRequired,
  editBlock: PropTypes.func.isRequired,
  forceClose: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  topicId: PropTypes.any.isRequired,
};

/**
 * Состояния в редаксе.
 */
const mapStateToProps = createSelector([selectTilda], tilda => ({
  loading: tilda.loading,
  tilda: tilda.info,
}));

/**
 * Экшены редакса.
 */
const mapActionsToProps = {
  createTilda,
  deleteTilda,
  getTilda,
  getBlocks,
  deleteBlock,
  getChapters,
};

/**
 * Экспорт компонента.
 */
export default connect(mapStateToProps, mapActionsToProps)(Tilda);
