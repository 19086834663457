/* eslint-disable */
import { CLEAR_EVENTS, GET_EVENTS_FAIL, GET_EVENTS_START, GET_EVENTS_SUCCESS } from './actions';

const initialState = {
  isLoading: false,
  error: false,
  events: [],
  eventsPageNumber: null,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case CLEAR_EVENTS:
      return { ...state, events: [], eventsPageNumber: null };
    case GET_EVENTS_START:
      return { ...state, isLoading: true, error: false };
    case GET_EVENTS_SUCCESS:
      if (state.events.length) {
        return {
          ...state,
          events: state.events.concat(response),
          eventsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoading: false,
          error: false,
        };
      }
      return {
        ...state,
        events: response,
        eventsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
        error: false,
      };

    case GET_EVENTS_FAIL:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};
