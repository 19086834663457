/* eslint-disable */
import { useTranslation } from 'react-i18next';

import { Card, Col, Row } from 'antd';

import AdditionalFields from './Panels/AdditionalFields';

import css from '../TabsVarDirectory.module.scss';

export const TabUsersSettings = () => {
  const { t } = useTranslation('varDirectory');

  return (
    <Row className={css['TabMargin-customFields']}>
      <Col span={24}>
        <Card bordered={false} className={css['TabMargin-card']}>
          <AdditionalFields />
        </Card>
      </Col>
    </Row>
  );
};

export default TabUsersSettings;
