/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_DEPARTMENTS_START = 'GET_DEPARTMENTS_START';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';
export const GET_GLOBAL_DEPARTMENTS_START = 'GET_GLOBAL_DEPARTMENTS_START';
export const GET_GLOBAL_DEPARTMENTS_SUCCESS = 'GET_GLOBAL_DEPARTMENTS_SUCCESS';
export const GET_GLOBAL_DEPARTMENTS_FAIL = 'GET_GLOBAL_DEPARTMENTS_FAIL';
export const CREATE_DEPARTMENT_START = 'CREATE_DEPARTMENT_START';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_FAIL = 'CREATE_DEPARTMENT_FAIL';
export const IMPORT_DEPARTMENT_START = 'IMPORT_DEPARTMENT_START';
export const IMPORT_DEPARTMENT_SUCCESS = 'IMPORT_DEPARTMENT_SUCCESS';
export const IMPORT_DEPARTMENT_FAIL = 'IMPORT_DEPARTMENT_FAIL';
export const DELETE_DEPARTMENT_START = 'DELETE_DEPARTMENT_START';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAIL = 'DELETE_DEPARTMENT_FAIL';
export const EDIT_DEPARTMENT_START = 'EDIT_DEPARTMENT_START';
export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const EDIT_DEPARTMENT_FAIL = 'EDIT_DEPARTMENT_FAIL';

export const GET_DEPARTMENTS_LIMIT_COST_START = 'GET_DEPARTMENTS_LIMIT_COST_START';
export const GET_DEPARTMENTS_LIMIT_COST_SUCCESS = 'GET_DEPARTMENTS_LIMIT_COST_SUCCESS';
export const GET_DEPARTMENTS_LIMIT_COST_FAIL = 'GET_DEPARTMENTS_LIMIT_COST_FAIL';

export const GET_TOTAL_DEPARTMENTS_LIMIT_COST_START = 'GET_TOTAL_DEPARTMENTS_LIMIT_COST_START';
export const GET_TOTAL_DEPARTMENTS_LIMIT_COST_SUCCESS = 'GET_TOTAL_DEPARTMENTS_LIMIT_COST_SUCCESS';
export const GET_TOTAL_DEPARTMENTS_LIMIT_COST_FAIL = 'GET_TOTAL_DEPARTMENTS_LIMIT_COST_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getDepartments = (name = '', page = 0, onSuccess, isGlobal = false) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/departmentInternal${getQueryString({
    name: name,
    isGlobal,
    isSharing: isGlobal ? '' : false,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DEPARTMENTS_START, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAIL],
  onSuccess,
});

export const getDepartmentsLimitCost = (department, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/departmentInternal/limit${getQueryString({
    departmentIds: [department],
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DEPARTMENTS_LIMIT_COST_START, GET_DEPARTMENTS_LIMIT_COST_SUCCESS, GET_DEPARTMENTS_LIMIT_COST_FAIL],
  onSuccess,
});

export const getTotalDepartmentsLimitCost = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/departmentInternal/limit`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [
    GET_TOTAL_DEPARTMENTS_LIMIT_COST_START,
    GET_TOTAL_DEPARTMENTS_LIMIT_COST_SUCCESS,
    GET_TOTAL_DEPARTMENTS_LIMIT_COST_FAIL,
  ],
  onSuccess,
});

export const getDepartmentsGlobal = (name = '', page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/departmentInternal${getQueryString({
    name: name,
    isGlobal: true,
    isSharing: true,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GLOBAL_DEPARTMENTS_START, GET_GLOBAL_DEPARTMENTS_SUCCESS, GET_GLOBAL_DEPARTMENTS_FAIL],
  onSuccess,
});

export const createDepartment = (data, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/departmentInternal`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [CREATE_DEPARTMENT_START, CREATE_DEPARTMENT_SUCCESS, CREATE_DEPARTMENT_FAIL],
});

export const importCSV = (file, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/departmentInternal/import`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  types: [IMPORT_DEPARTMENT_START, IMPORT_DEPARTMENT_SUCCESS, IMPORT_DEPARTMENT_FAIL],
});

export const deleteDepartment = (id, { onSuccess, onError }) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/departmentInternal${getQueryString({ id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_DEPARTMENT_START, DELETE_DEPARTMENT_SUCCESS, DELETE_DEPARTMENT_FAIL],
});

export const editDepartment = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/departmentInternal${getQueryString({ id: data.id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [EDIT_DEPARTMENT_START, EDIT_DEPARTMENT_SUCCESS, EDIT_DEPARTMENT_FAIL],
});
