/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { Button, Col, Input, message, Modal, Row } from 'antd';

import PropTypes from 'prop-types';

import 'react-image-crop/dist/ReactCrop.css';

import { COVER_IMAGE_TYPES } from '@shared/constants/image-types';

export const ProjectCover = props => {
  const { t } = useTranslation('D360_ProjectEdit');
  const [srcImg, setSrcImg] = useState(null);
  const [image, setImage] = useState(null);
  const fileInput = useRef(null);
  const [showCropWindow, setShowCropWindow] = useState(false);
  const [result, setResult] = useState(null);
  //  const [filename, setFilename] = useState('');

  const [crop, setCrop] = useState({
    aspect: 16 / 9,
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    unit: '%',
  });

  useEffect(() => {
    if (image !== null) {
      getCroppedImage(crop);
    }
  }, [image]);

  /**
   * Пользователь нажал на кнопку загрузки изображения.
   */
  const onButtonUpload = () => {
    fileInput.current.click();
    getCroppedImage(crop);
  };

  /**
   * Изображение загружено.
   *
   * @param event
   */
  const onUploaded = event => {
    if (
      event.target &&
      event.target.files &&
      event.target.files.length > 0 &&
      COVER_IMAGE_TYPES.includes(event.target.files[0].type)
    ) {
      setSrcImg(URL.createObjectURL(event.target.files[0]));
      setShowCropWindow(true);
    } else {
      message.error(t('errorAlert'));
    }
  };

  /**
   * Получение изображения с измененными размерами.
   *
   * @returns {Promise<void>}
   */
  const getCroppedImage = async crop => {
    try {
      if (image) {
        const pixelRatio = window.devicePixelRatio;
        const canvas = await document.createElement('canvas');

        canvas.width = Math.round((image.naturalWidth * crop.width) / 100);
        canvas.height = Math.round((image.naturalHeight * crop.height) / 100);

        const ctx = canvas.getContext('2d');

        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
          image, // CanvasImageSource
          (image.naturalWidth * crop.x) / 100,
          (image.naturalHeight * crop.y) / 100,
          (image.naturalWidth * crop.width) / 100,
          (image.naturalHeight * crop.height) / 100,
          0,
          0,
          (image.naturalWidth * crop.width) / 100,
          (image.naturalHeight * crop.height) / 100
        );

        setResult(canvas.toDataURL('image/jpeg'));
      }
    } catch (e) {
      console.log('Error:', e);
    }
  };

  /**
   * Пользователь нажал кнопку "ОК"
   */
  const onSubmit = () => {
    if (props.onSubmit && typeof props.onSubmit === 'function' && result) {
      const blob = atob(result.split(',')[1]);
      const data = [];
      for (let index = 0; index < blob.length; index++) {
        data.push(blob.charCodeAt(index));
      }

      const file = new Blob([new Uint8Array(data)], { type: 'image/jpeg' });
      const form = new FormData();
      form.append('file', file);
      props.onSubmit(form);
      setShowCropWindow(false);
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <>
        <Row>
          <Col span={20} style={{ paddingRight: '.2rem' }}>
            <Input placeholder={t('projectCoverPH')} disabled={true} />
          </Col>
          <Col span={4}>
            <Button block={true} type={'primary'} onClick={onButtonUpload}>
              ...
            </Button>
            <input
              type='file'
              accept='image/jpeg, image/jpg, image/png, image/svg+xml'
              ref={fileInput}
              onChange={onUploaded}
              style={{ display: 'none' }}
            />
          </Col>
        </Row>

        {showCropWindow && srcImg && (
          <Modal
            open={showCropWindow}
            title={'Загрузка изображения'}
            centered={true}
            cancelText={'Отмена'}
            okText={'OK'}
            okType={'primary'}
            onCancel={() => setShowCropWindow(false)}
            onOk={onSubmit}
          >
            <ReactCrop
              aspect={16 / 9}
              src={srcImg}
              crop={crop}
              onImageLoaded={setImage}
              onChange={setCrop}
              keepSelection={true}
              ruleOfThirds={true}
              onComplete={getCroppedImage}
            />

            {/*<img src={result} alt={""} style={{ width: '400px', height: '250px' }}/>*/}
            {/*<Button onClick={()=>getCroppedImage(crop)} >O</Button>*/}
            {/*<Row>*/}
            {/*  <Col span={24}>*/}
            {/*    <span>*/}
            {/*      x: {crop.x}, y: {crop.y}, w: {crop.width}, h: {crop.height}*/}
            {/*    </span>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Modal>
        )}
      </>
    );
  };

  return render();
};

ProjectCover.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProjectCover;
