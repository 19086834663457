// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-NewTopics-style-module__ql-picker-q7OiSP.admin-src-pages-NewTopics-style-module__ql-font-QvktQ4 .admin-src-pages-NewTopics-style-module__ql-picker-label-IaCrWo[data-value="Montserrat"]:before, .admin-src-pages-NewTopics-style-module__ql-picker-q7OiSP.admin-src-pages-NewTopics-style-module__ql-font-QvktQ4 .admin-src-pages-NewTopics-style-module__ql-picker-item-wlM11g[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-NewTopics-style-module__ql-font-Montserrat-AIayyi {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-NewTopics-style-module__topics-gtSX_d {
  width: 100%;
  margin: auto;
}

.admin-src-pages-NewTopics-style-module__topics__header-GqzTve {
  justify-content: space-between;
  margin-bottom: 40px;
  display: flex;
}

.admin-src-pages-NewTopics-style-module__topics__buttons-_E_JwX {
  align-items: center;
  gap: 10px;
  display: flex;
}

.admin-src-pages-NewTopics-style-module__topics__list-item-o8SH4a {
  height: calc(100% - 20px);
}

.admin-src-pages-NewTopics-style-module__topics-gtSX_d .ant-col {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/NewTopics/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;EACX,YAAY;AACd;;AAMA;EAHE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAKA;EAFE,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAIA;EADE,yBAAyB;AAC3B;;AAGA;EAAE,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.topics {\n  width: 100%;\n  margin: auto;\n}\n.topics__header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 40px;\n}\n.topics__buttons {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n.topics__list-item {\n  height: calc(100% - 20px);\n}\n.topics :global .ant-col {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-NewTopics-style-module__ql-picker-q7OiSP`,
	"qlPicker": `admin-src-pages-NewTopics-style-module__ql-picker-q7OiSP`,
	"ql-font": `admin-src-pages-NewTopics-style-module__ql-font-QvktQ4`,
	"qlFont": `admin-src-pages-NewTopics-style-module__ql-font-QvktQ4`,
	"ql-picker-label": `admin-src-pages-NewTopics-style-module__ql-picker-label-IaCrWo`,
	"qlPickerLabel": `admin-src-pages-NewTopics-style-module__ql-picker-label-IaCrWo`,
	"ql-picker-item": `admin-src-pages-NewTopics-style-module__ql-picker-item-wlM11g`,
	"qlPickerItem": `admin-src-pages-NewTopics-style-module__ql-picker-item-wlM11g`,
	"ql-font-Montserrat": `admin-src-pages-NewTopics-style-module__ql-font-Montserrat-AIayyi`,
	"qlFontMontserrat": `admin-src-pages-NewTopics-style-module__ql-font-Montserrat-AIayyi`,
	"topics": `admin-src-pages-NewTopics-style-module__topics-gtSX_d`,
	"topics__header": `admin-src-pages-NewTopics-style-module__topics__header-GqzTve`,
	"topicsHeader": `admin-src-pages-NewTopics-style-module__topics__header-GqzTve`,
	"topics__buttons": `admin-src-pages-NewTopics-style-module__topics__buttons-_E_JwX`,
	"topicsButtons": `admin-src-pages-NewTopics-style-module__topics__buttons-_E_JwX`,
	"topics__list-item": `admin-src-pages-NewTopics-style-module__topics__list-item-o8SH4a`,
	"topicsListItem": `admin-src-pages-NewTopics-style-module__topics__list-item-o8SH4a`
};
export default ___CSS_LOADER_EXPORT___;
