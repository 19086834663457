/* esling-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360GetFilterUsers, D360GetFilterUsersAuto } from '@/store/d360/d360_filters/actions';

import { Button, Col, Modal, Row, Switch } from 'antd';

import FinderTable from './parts/FinderTable';
import ManualFilter from './parts/ManualFilter';
import { RoleWindow } from './parts/RoleWindow';

import PropTypes from 'prop-types';

import css from '../../css/index.module.scss';

const defaultSelected = {
  keys: [],
  rows: [],
};

export const Finder = props => {
  // Данные таблицы
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState(defaultSelected);
  const [totalSelected, setTotalSelected] = useState(0);
  const [disabledAddButton, setDisabledAddButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('D360_Finder');
  const [selfAssessment, setSelfAssessment] = useState(true);
  const [showRoleWindow, setShowRoleWindow] = useState(false);

  /**
   * Отслеживаем изменение выбранных сотрудников.
   */
  useEffect(() => {
    if (selected.keys.length) {
      setTotalSelected(selected.keys.length);
      return setDisabledAddButton(false);
    }

    setTotalSelected(0);
    setDisabledAddButton(true);
  }, [selected]);

  /**
   *
   */
  useEffect(() => {
    if (!props.open) {
      handleReset();
      setDisabledAddButton(true);
    }
  }, [props.open]);

  /**
   *
   */
  const onCancel = () => {
    if (props && props.onCancel && typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  /**
   *
   */
  const onSubmit = () => {
    if (props && props.onSubmit && typeof props.onSubmit === 'function') {
      props.onSubmit(selected, selfAssessment);
    }
  };

  /**
   *
   */
  const handleReset = () => {
    setTableData([]);
    setSelected(defaultSelected);
    setTotalSelected(0);
  };

  /**
   *
   * @param filter
   */
  const handleFilter = filter => {
    setLoading(true);
    props.D360GetFilterUsers(
      props.projectId,
      props.users,
      filter,
      success => {
        setLoading(false);
        if (success && success.data && Array.isArray(success.data)) {
          setTableData(success.data);
        }
      },
      error => {
        console.error('Error: ', error);
        setLoading(false);
      }
    );
  };

  const onSubmitWithRole = role => {
    setShowRoleWindow(false);
    if (props && props.onSubmit && typeof props.onSubmit === 'function') {
      props.onSubmit(selected, selfAssessment, role);
    }
  };

  const buttonBar = () => {
    return (
      <Row>
        {props && props.findMeasurable === true && (
          <Col span={12} align='left' justify='middle'>
            <Switch
              defaultChecked={selfAssessment}
              onChange={v => setSelfAssessment(v)}
              className={css['Finder-switch']}
            />
            <b>{t('selfAssessment')}</b>
          </Col>
        )}

        {props && props.findMeasurable === false && (
          <Col span={12} align='left' justify='middle'>
            <Button disabled={selected.keys.length <= 0} type='primary' danger onClick={() => setShowRoleWindow(true)}>
              {t('manualAssignRole')}
            </Button>
          </Col>
        )}

        <Col span={12} align='right'>
          <Button type='default' onClick={() => onCancel()} className={css['Finder-cancel']}>
            {t('cancel')}
          </Button>
          <Button type='primary' disabled={disabledAddButton} onClick={() => onSubmit()}>
            {t('add') + ` (${totalSelected})`}
          </Button>
        </Col>
      </Row>
    );
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <Modal
        destroyOnClose={true}
        width={800}
        title={props.title}
        open={props.open}
        footer={buttonBar()}
        onCancel={() => onCancel()}
      >
        <ManualFilter onFilter={handleFilter} onReset={handleReset} open={props.open} />

        <FinderTable onSelect={e => setSelected(e)} data={tableData} loading={loading} open={props.open} />

        <RoleWindow open={showRoleWindow} onOk={onSubmitWithRole} onCancel={() => setShowRoleWindow(false)} />
      </Modal>
    );
  };

  /**
   *
   */
  return render();
};

/**
 *
 * @type {{title: *, open: *, onCancel: *, onSubmit: *, projectId: *, users: *}}
 */
Finder.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  users: PropTypes.any.isRequired,
  findMeasurable: PropTypes.bool.isRequired,
};

/**
 *
 * @param state
 * @returns {{}}
 */
// eslint-disable-next-line
const mapStateToProps = state => ({});

/**
 *
 * @type {{D360GetFilterUsers: *}}
 */
const mapDispatchToProps = {
  D360GetFilterUsers,
  D360GetFilterUsersAuto,
};

export default connect(mapStateToProps, mapDispatchToProps)(Finder);
