// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-RY8jId.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-w_Vtt4 .admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-label-tJnl9S[data-value="Montserrat"]:before, .admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-RY8jId.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-w_Vtt4 .admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-item-UeJTEo[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-Montserrat-eKc7f7 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__CompetenceGroups-header-P3hyA9 {
  justify-content: space-between;
  display: flex;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Filters-IOgL2T {
  background-color: #fff;
  border-radius: 16px;
  padding: 32px 16px;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Filters-txt-nxVO2w {
  margin-bottom: 16px;
  font-size: 30px;
  font-weight: 500;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-ih6xDh {
  background-color: #fff;
  border-radius: 16px;
  margin-top: 24px;
  padding: 24px;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-BMBwUB {
  cursor: pointer;
  justify-content: space-evenly;
  font-size: 18px;
  display: flex;
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-edit-f2pt5n {
  color: var(--personalColor);
}

.admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-delete-DEyXIq {
  color: #d0021b;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/CompetenceGroupsDirectory/CompetenceGroupsDirectory.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,8BAA8B;EAC9B,aAAa;AACf;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAMA;EAHE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAMA;EAHE,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;;AAKA;EAFE,eAAe;EACf,6BAA6B;EAC7B,eAAe;EACf,aAAa;AACf;;AAIA;EADE,2BAA2B;AAC7B;;AAGA;EAAE,cAAc;AAChB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.CompetenceGroups-header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Filters {\n  background-color: #fff;\n  border-radius: 16px;\n  padding: 32px 16px;\n}\n.Filters-txt {\n  font-size: 30px;\n  font-weight: 500;\n  margin-bottom: 16px;\n}\n\n.Table {\n  margin-top: 24px;\n  padding: 24px;\n  background-color: #fff;\n  border-radius: 16px;\n}\n.Table-actions {\n  display: flex;\n  justify-content: space-evenly;\n  cursor: pointer;\n  font-size: 18px;\n}\n.Table-actions-edit {\n  color: var(--personalColor);\n}\n.Table-actions-delete {\n  color: #d0021b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-RY8jId`,
	"qlPicker": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-RY8jId`,
	"ql-font": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-w_Vtt4`,
	"qlFont": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-w_Vtt4`,
	"ql-picker-label": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-label-tJnl9S`,
	"qlPickerLabel": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-label-tJnl9S`,
	"ql-picker-item": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-item-UeJTEo`,
	"qlPickerItem": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-picker-item-UeJTEo`,
	"ql-font-Montserrat": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-Montserrat-eKc7f7`,
	"qlFontMontserrat": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__ql-font-Montserrat-eKc7f7`,
	"CompetenceGroups-header": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__CompetenceGroups-header-P3hyA9`,
	"competenceGroupsHeader": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__CompetenceGroups-header-P3hyA9`,
	"Filters": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Filters-IOgL2T`,
	"filters": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Filters-IOgL2T`,
	"Filters-txt": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Filters-txt-nxVO2w`,
	"filtersTxt": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Filters-txt-nxVO2w`,
	"Table": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-ih6xDh`,
	"table": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-ih6xDh`,
	"Table-actions": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-BMBwUB`,
	"tableActions": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-BMBwUB`,
	"Table-actions-edit": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-edit-f2pt5n`,
	"tableActionsEdit": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-edit-f2pt5n`,
	"Table-actions-delete": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-delete-DEyXIq`,
	"tableActionsDelete": `admin-src-pages-CompetenceGroupsDirectory-CompetenceGroupsDirectory-module__Table-actions-delete-DEyXIq`
};
export default ___CSS_LOADER_EXPORT___;
