export const REJECTED = 'REJECTED';

export const REJECTED_BY_MANAGER = 'REJECTED_BY_MANAGER';
export const REJECTED_BY_USER = 'REJECTED_BY_USER';

export const APPROVED_BY_USER = 'APPROVED_BY_USER';
export const APPROVED_BY_MANAGER = 'APPROVED_BY_MANAGER';

export const FINISHED = 'FINISHED';
export const APPROVED = 'APPROVED';
export const WAITING = 'WAITING';
export const SCHEDULED = 'SCHEDULED';
export const EXPIRED = 'EXPIRED';
export const IN_PROGRESS = 'IN_PROGRESS';

export const ON_REGISTRATION = 'ON_REGISTRATION';

export const WAITING_BY_USER = 'WAITING_BY_USER';
export const PLANNED = 'PLANNED';
