import {
  DELETE_MASSFILTER_FAIL,
  DELETE_MASSFILTER_START,
  DELETE_MASSFILTER_SUCCESS,
  DISABLE_MASSFILTER_FAIL,
  DISABLE_MASSFILTER_START,
  DISABLE_MASSFILTER_SUCCESS,
  ENABLE_MASSFILTER_FAIL,
  ENABLE_MASSFILTER_START,
  ENABLE_MASSFILTER_SUCCESS,
  GET_MASSFILTERS_FAIL,
  GET_MASSFILTERS_START,
  GET_MASSFILTERS_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

// Загружаем список массовых фильтров
export const getMassFilters = (identifier, type, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mass/filter/list/${type}${getQueryString({
    page: 0,
    size: 20,
    identifier,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MASSFILTERS_START, GET_MASSFILTERS_SUCCESS, GET_MASSFILTERS_FAIL],
  onSuccess,
  onError,
});

// Удаляем массовый фильтр по идентификатору
export const deleteMassFilter = (filterId, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/mass/filter/delete${getQueryString({
    filterId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_MASSFILTER_START, DELETE_MASSFILTER_SUCCESS, DELETE_MASSFILTER_FAIL],
  onSuccess,
  onError,
});

// Активируем массовый фильтр
export const enableMassFilter = (filterId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/filter/enable${getQueryString({
    filterId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [ENABLE_MASSFILTER_START, ENABLE_MASSFILTER_SUCCESS, ENABLE_MASSFILTER_FAIL],
  onSuccess,
  onError,
});

// Выключаем массовый фильтр
export const disableMassFilter = (filterId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mass/filter/disable${getQueryString({
    filterId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [DISABLE_MASSFILTER_START, DISABLE_MASSFILTER_SUCCESS, DISABLE_MASSFILTER_FAIL],
  onSuccess,
  onError,
});
