/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Input, InputNumber, Modal, Row } from 'antd';
import { FiHexagon } from 'react-icons/fi';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

export const ScaleEditScore = props => {
  const [id, setId] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [value, setValue] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const { t } = useTranslation('D360_Scales');

  // Initialize Window
  useEffect(() => {
    setIndicator(null);
    setValue(null);

    if (props.score) {
      if (props.score.id && !isNaN(props.score.id) && parseInt(props.score.id, 10) > 0) {
        setId(props.score.id);
      }

      if (props.score.indicator && props.score.indicator !== null && props.score.indicator.length > 0) {
        setIndicator(props.score.indicator);
      }

      if (props.score.value && props.score.value !== null && parseInt(props.score.value, 10) > -1) {
        setValue(props.score.value);
      }
    }
  }, [props.score, props.scores]);

  // Enable/Disable Save Button
  useEffect(() => {
    if (indicator === null || indicator.length <= 0) {
      return setDisableSubmitButton(true);
    }

    if (value === null || parseFloat(value.toString()) < 0.1) {
      return setDisableSubmitButton(true);
    }

    if (props.scores && Array.isArray(props.scores)) {
      for (let score of props.scores) {
        if (score.indicator && score.value) {
          if (!id) {
            if (score.indicator === indicator || parseInt(score.value, 10) === value) {
              return setDisableSubmitButton(true);
            }
          }
        }
      }
    }

    setDisableSubmitButton(false);
  }, [indicator, value]);

  const onCancel = () => {
    if (props.onCancel && typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const onSubmit = () => {
    if (props.onSubmit && typeof props.onSubmit === 'function') {
      props.onSubmit(id, indicator, value);
    }
  };

  const render = () => {
    return (
      <Modal
        open
        title={t('addScoreTitle')}
        onOk={onSubmit}
        onCancel={onCancel}
        okButtonProps={{ disabled: disableSubmitButton }}
        destroyOnClose
      >
        <Row className={css.Notes}>
          <Col span={24}>
            <FiHexagon size='3rem' className={css.NotesIcon} />
            <p className={css.NotesText}>{t('addScoreDescription')}</p>
          </Col>
        </Row>

        <Row className={css.WindowRow}>
          <Col span={24}>{t('scoreName')}</Col>
          <Col span={24}>
            <Input
              maxLength={600}
              placeholder=''
              style={{ width: '100%' }}
              value={indicator}
              onChange={e => setIndicator(e.target.value)}
              disabled={!props.editable}
            />
          </Col>
        </Row>

        <Row className={css.WindowRow}>
          <Col span={24}>{t('scoreValue')}</Col>
          <Col span={24}>
            <InputNumber
              min={0.1}
              max={1000000}
              value={value}
              disabled={!props.editable}
              style={{ width: '100%' }}
              onChange={e => setValue(e)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  return render();
};

ScaleEditScore.propTypes = {
  scores: PropTypes.any,
  score: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default ScaleEditScore;
