// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-aWzZoi.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-lHAI59 .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-label-j8_Fz7[data-value="Montserrat"]:before, .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-aWzZoi.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-lHAI59 .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-item-_KEGwN[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-Montserrat-BDYylg {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-QvYC1m {
  background: #fff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px 24px;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__icon-hMQ7oE {
  margin-left: 6px;
  font-size: 20px;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-input-t6ax1a {
  width: 100%;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-input-container-xE0lN0 {
  margin: 20px 0;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__header-iJjIcR {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__checkbox-label-XRVycM {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Topics/TopicDetailsDialog/LMS/Blocks/Results/ResultsTable/ResultsFilter.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB;;AAOA;EAJE,gBAAgB;EAChB,eAAe;AACjB;;AAMA;EAHE,WAAW;AACb;;AAKA;EAFE,cAAc;AAChB;;AAIA;EADE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAGA;EAAE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.ResultsFilter {\n  background: #fff;\n  border-radius: 8px;\n  padding: 20px 24px;\n  margin: 20px 0;\n}\n.ResultsFilter__icon {\n  margin-left: 6px;\n  font-size: 20px;\n}\n.ResultsFilter-input {\n  width: 100%;\n}\n.ResultsFilter-input-container {\n  margin: 20px 0;\n}\n.ResultsFilter__header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.ResultsFilter__checkbox-label {\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-aWzZoi`,
	"qlPicker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-aWzZoi`,
	"ql-font": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-lHAI59`,
	"qlFont": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-lHAI59`,
	"ql-picker-label": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-label-j8_Fz7`,
	"qlPickerLabel": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-label-j8_Fz7`,
	"ql-picker-item": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-item-_KEGwN`,
	"qlPickerItem": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-picker-item-_KEGwN`,
	"ql-font-Montserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-Montserrat-BDYylg`,
	"qlFontMontserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ql-font-Montserrat-BDYylg`,
	"ResultsFilter": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-QvYC1m`,
	"resultsFilter": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-QvYC1m`,
	"ResultsFilter__icon": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__icon-hMQ7oE`,
	"resultsFilterIcon": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__icon-hMQ7oE`,
	"ResultsFilter-input": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-input-t6ax1a`,
	"resultsFilterInput": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-input-t6ax1a`,
	"ResultsFilter-input-container": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-input-container-xE0lN0`,
	"resultsFilterInputContainer": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter-input-container-xE0lN0`,
	"ResultsFilter__header": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__header-iJjIcR`,
	"resultsFilterHeader": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__header-iJjIcR`,
	"ResultsFilter__checkbox-label": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__checkbox-label-XRVycM`,
	"resultsFilterCheckboxLabel": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Results-ResultsTable-ResultsFilter-module__ResultsFilter__checkbox-label-XRVycM`
};
export default ___CSS_LOADER_EXPORT___;
