// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-ThemesInput-ThemesInput-module__ql-picker-oNHxER.admin-src-components-ThemesInput-ThemesInput-module__ql-font-BSzQVR .admin-src-components-ThemesInput-ThemesInput-module__ql-picker-label-YZu1Hw[data-value="Montserrat"]:before, .admin-src-components-ThemesInput-ThemesInput-module__ql-picker-oNHxER.admin-src-components-ThemesInput-ThemesInput-module__ql-font-BSzQVR .admin-src-components-ThemesInput-ThemesInput-module__ql-picker-item-uExiau[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-ThemesInput-ThemesInput-module__ql-font-Montserrat-qgI8mu {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-h1-u0tqR3 {
  color: #000;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}

.admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-shadow-box-IiCU8f {
  background-color: #fff;
  margin: -60px -40px 0;
  padding: 48px 48px 25px;
  box-shadow: 0 0 50px #0000001a;
}

.admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-container-themes-OmcjX0 {
  padding-bottom: 20px;
}

.admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-theme-edit-B2Jgoi input {
  max-width: 300px;
  width: 300px !important;
}

.admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-tag-cbX60q {
  margin-left: 16px;
}

.admin-src-components-ThemesInput-ThemesInput-module__card-pb-Z2scdT {
  padding-bottom: 0;
}

.admin-src-components-ThemesInput-ThemesInput-module__error-BIroOT {
  border: 1px solid #f5222d !important;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/ThemesInput/ThemesInput.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAOA;EAJE,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,8BAA8B;AAChC;;AAOA;EAJE,oBAAoB;AACtB;;AAMA;EAHE,gBAAgB;EAChB,uBAAuB;AACzB;;AAKA;EAFE,iBAAiB;AACnB;;AAKA;EAFE,iBAAiB;AACnB;;AAKA;EAFE,oCAAoC;AACtC","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.ThemesInput-h1 {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 1.2;\n  color: #000;\n  padding-bottom: 20px;\n}\n.ThemesInput-shadow-box {\n  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);\n  margin: -60px -40px 0 -40px;\n  padding: 48px;\n  padding-bottom: 25px;\n  background-color: #fff;\n}\n.ThemesInput-container-themes {\n  padding-bottom: 20px;\n}\n.ThemesInput-theme-edit input {\n  width: 300px !important;\n  max-width: 300px;\n}\n.ThemesInput-tag {\n  margin-left: 16px;\n}\n\n.card-pb {\n  padding-bottom: 0;\n}\n\n.error {\n  border: 1px solid #f5222d !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-ThemesInput-ThemesInput-module__ql-picker-oNHxER`,
	"qlPicker": `admin-src-components-ThemesInput-ThemesInput-module__ql-picker-oNHxER`,
	"ql-font": `admin-src-components-ThemesInput-ThemesInput-module__ql-font-BSzQVR`,
	"qlFont": `admin-src-components-ThemesInput-ThemesInput-module__ql-font-BSzQVR`,
	"ql-picker-label": `admin-src-components-ThemesInput-ThemesInput-module__ql-picker-label-YZu1Hw`,
	"qlPickerLabel": `admin-src-components-ThemesInput-ThemesInput-module__ql-picker-label-YZu1Hw`,
	"ql-picker-item": `admin-src-components-ThemesInput-ThemesInput-module__ql-picker-item-uExiau`,
	"qlPickerItem": `admin-src-components-ThemesInput-ThemesInput-module__ql-picker-item-uExiau`,
	"ql-font-Montserrat": `admin-src-components-ThemesInput-ThemesInput-module__ql-font-Montserrat-qgI8mu`,
	"qlFontMontserrat": `admin-src-components-ThemesInput-ThemesInput-module__ql-font-Montserrat-qgI8mu`,
	"ThemesInput-h1": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-h1-u0tqR3`,
	"themesInputH1": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-h1-u0tqR3`,
	"ThemesInput-shadow-box": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-shadow-box-IiCU8f`,
	"themesInputShadowBox": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-shadow-box-IiCU8f`,
	"ThemesInput-container-themes": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-container-themes-OmcjX0`,
	"themesInputContainerThemes": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-container-themes-OmcjX0`,
	"ThemesInput-theme-edit": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-theme-edit-B2Jgoi`,
	"themesInputThemeEdit": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-theme-edit-B2Jgoi`,
	"ThemesInput-tag": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-tag-cbX60q`,
	"themesInputTag": `admin-src-components-ThemesInput-ThemesInput-module__ThemesInput-tag-cbX60q`,
	"card-pb": `admin-src-components-ThemesInput-ThemesInput-module__card-pb-Z2scdT`,
	"cardPb": `admin-src-components-ThemesInput-ThemesInput-module__card-pb-Z2scdT`,
	"error": `admin-src-components-ThemesInput-ThemesInput-module__error-BIroOT`
};
export default ___CSS_LOADER_EXPORT___;
