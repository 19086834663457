// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-Inspectors-Inspectors-module__ql-picker-WbRMCH.admin-src-components-Inspectors-Inspectors-module__ql-font-efbxZC .admin-src-components-Inspectors-Inspectors-module__ql-picker-label-_aAGYN[data-value="Montserrat"]:before, .admin-src-components-Inspectors-Inspectors-module__ql-picker-WbRMCH.admin-src-components-Inspectors-Inspectors-module__ql-font-efbxZC .admin-src-components-Inspectors-Inspectors-module__ql-picker-item-L35ZCn[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-Inspectors-Inspectors-module__ql-font-Montserrat-Kyfi44 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-Inspectors-Inspectors-module__Inspectors-x3jFIg {
  margin-top: 16px;
}

.admin-src-components-Inspectors-Inspectors-module__Inspectors-datepicker-Oikgq4 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/Inspectors/Inspectors.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AAMA;EAHE,WAAW;AACb","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Inspectors {\n  margin-top: 16px;\n}\n.Inspectors-datepicker {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-Inspectors-Inspectors-module__ql-picker-WbRMCH`,
	"qlPicker": `admin-src-components-Inspectors-Inspectors-module__ql-picker-WbRMCH`,
	"ql-font": `admin-src-components-Inspectors-Inspectors-module__ql-font-efbxZC`,
	"qlFont": `admin-src-components-Inspectors-Inspectors-module__ql-font-efbxZC`,
	"ql-picker-label": `admin-src-components-Inspectors-Inspectors-module__ql-picker-label-_aAGYN`,
	"qlPickerLabel": `admin-src-components-Inspectors-Inspectors-module__ql-picker-label-_aAGYN`,
	"ql-picker-item": `admin-src-components-Inspectors-Inspectors-module__ql-picker-item-L35ZCn`,
	"qlPickerItem": `admin-src-components-Inspectors-Inspectors-module__ql-picker-item-L35ZCn`,
	"ql-font-Montserrat": `admin-src-components-Inspectors-Inspectors-module__ql-font-Montserrat-Kyfi44`,
	"qlFontMontserrat": `admin-src-components-Inspectors-Inspectors-module__ql-font-Montserrat-Kyfi44`,
	"Inspectors": `admin-src-components-Inspectors-Inspectors-module__Inspectors-x3jFIg`,
	"inspectors": `admin-src-components-Inspectors-Inspectors-module__Inspectors-x3jFIg`,
	"Inspectors-datepicker": `admin-src-components-Inspectors-Inspectors-module__Inspectors-datepicker-Oikgq4`,
	"inspectorsDatepicker": `admin-src-components-Inspectors-Inspectors-module__Inspectors-datepicker-Oikgq4`
};
export default ___CSS_LOADER_EXPORT___;
