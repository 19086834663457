import {
  CHANGE_STATUS_PRODUCT_FAIL,
  CHANGE_STATUS_PRODUCT_START,
  CHANGE_STATUS_PRODUCT_SUCCESS,
  CLEAR_PRODUCTS_VISIBILITY,
  CLEAR_SHOP_FILTERS,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_START,
  CREATE_PRODUCT_SUCCESS,
  EXPORT_PRODUCTS_FAIL,
  EXPORT_PRODUCTS_START,
  EXPORT_PRODUCTS_SUCCESS,
  GET_DELIVERY_FAIL,
  GET_DELIVERY_START,
  GET_DELIVERY_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_VISIBILITY_FAIL,
  GET_PRODUCTS_VISIBILITY_START,
  GET_PRODUCTS_VISIBILITY_SUCCESS,
  GET_RANGE_COST_FAIL,
  GET_RANGE_COST_START,
  GET_RANGE_COST_SUCCESS,
  POST_PRODUCTS_FILTERS_FAIL,
  POST_PRODUCTS_FILTERS_START,
  POST_PRODUCTS_FILTERS_SUCCESS,
  POST_PRODUCTS_VISIBILITY_FAIL,
  POST_PRODUCTS_VISIBILITY_START,
  POST_PRODUCTS_VISIBILITY_SUCCESS,
  SAVE_SHOP_FILTERS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_START,
  UPDATE_PRODUCT_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getRangeCost = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product/admin/cost/range`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_RANGE_COST_START, GET_RANGE_COST_SUCCESS, GET_RANGE_COST_FAIL],
  onSuccess,
  onError,
});

export const getDelivery = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/delivery/admin`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DELIVERY_START, GET_DELIVERY_SUCCESS, GET_DELIVERY_FAIL],
  onSuccess,
  onError,
});

export const getProductsByName = (prefix, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product/admin/id${getQueryString({ prefix, limit: 1000 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_PRODUCTS_START, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL],
  onSuccess,
  onError,
});

export const postProductsFilters = (params, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/admin${getQueryString({ size: 20, page, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_PRODUCTS_FILTERS_START, POST_PRODUCTS_FILTERS_SUCCESS, POST_PRODUCTS_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const createProduct = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/product/admin/create`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CREATE_PRODUCT_START, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAIL],
  onSuccess,
  onError,
});

export const updateProduct = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/product/admin/update`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [UPDATE_PRODUCT_START, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL],
  onSuccess,
  onError,
});

export const changeStatusProduct = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/admin/update/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CHANGE_STATUS_PRODUCT_START, CHANGE_STATUS_PRODUCT_SUCCESS, CHANGE_STATUS_PRODUCT_FAIL],
  onSuccess,
  onError,
});

export const postProductsVisibility = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/product/admin/visibility`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_PRODUCTS_VISIBILITY_START, POST_PRODUCTS_VISIBILITY_SUCCESS, POST_PRODUCTS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const getProductsVisibility = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product/admin/visibility/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_PRODUCTS_VISIBILITY_START, GET_PRODUCTS_VISIBILITY_SUCCESS, GET_PRODUCTS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const exportProducts = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/admin/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_PRODUCTS_START, EXPORT_PRODUCTS_SUCCESS, EXPORT_PRODUCTS_FAIL],
  onSuccess,
  onError,
});

export const saveShopFilters = value => ({
  type: SAVE_SHOP_FILTERS,
  value,
});

export const clearShopFilters = value => ({
  type: CLEAR_SHOP_FILTERS,
  value,
});

export const clearShopVisibility = () => ({
  type: CLEAR_PRODUCTS_VISIBILITY,
});
