import api from './api/reducer';
import companyUsersLimit from './company_users_limit/reducer';
import executive from './executive/reducer';
import existingMaterial from './existing-materials/reducer';
import importUser from './importUsers/reducer';
import inspection from './inspection/reducer';
import filters from './massfilter/reducer';
// Profile Custom Fields
import pcfields from './pcfields/reducer';
import pcfieldvalue from './pcfieldvalue/reducer';
import quartz from './quartz/reducer';
import settingsVisibility from './settings_visibility/reducer';
import talentQ from './talentq/reducer';
import tilda from './tilda/reducer';
import typeform from './typeform/reducer';
import webinar from './webinar/reducer';

import customTags from '@shared/store/customTags/reducer';

import access from '@/store/access/reducer';
import authors from '@/store/authors/reducer';
import badge from '@/store/badge/reducer';
import bannerTopBody from '@/store/banner_top_body/reducer';
import blockAdmin from '@/store/block_admin/reducer';
import bonuses from '@/store/bonuses/reducer';
import books from '@/store/books/reducer';
import certificates from '@/store/certificate/reducer';
import chapters from '@/store/chapters/reducer';
import chat from '@/store/chat/reducer';
import companies from '@/store/companies/reducer';
import competence from '@/store/competence/reducer';
import compilations from '@/store/compilations/reducer';
import courses from '@/store/courses/reducer';
import d360Criterias from '@/store/d360/d360_criterias/reducer';
import d360Filters from '@/store/d360/d360_filters/reducer';
import d360Import from '@/store/d360/d360_import/reducer';
import d360Measurables from '@/store/d360/d360_measurables/reducer';
import d360Project from '@/store/d360/d360_project/reducer';
// Diagnostic 360
import d360Projects from '@/store/d360/d360_projects/reducer';
import d360Reports from '@/store/d360/d360_reports/reducer';
import d360Respondents from '@/store/d360/d360_respondents/reducer';
import d360Results from '@/store/d360/d360_results/reducer';
import d360Scales from '@/store/d360/d360_scales/reducer';
import d360Scores from '@/store/d360/d360_scores/reducer';
import d360State from '@/store/d360/d360_state/reducer';
import departments from '@/store/department/reducer';
import error from '@/store/error/reducer';
import event from '@/store/event/reducer';
import files from '@/store/files/reducer';
import functions from '@/store/function/reducer';
import groups from '@/store/groups/reducer';
import importQuestion from '@/store/import_question/reducer';
import income from '@/store/income/reducer';
import ipr from '@/store/ipr/reducer';
import typeLabel from '@/store/label_topic/reducer';
import lms from '@/store/lms/reducer';
import locales from '@/store/locales/reducer';
import login from '@/store/login/reducer';
import mentee from '@/store/mentee/reducer';
import mentors from '@/store/mentors/reducer';
import messages from '@/store/messages/reducer';
import newNotifications from '@/store/newNotifications/reducer';
import news from '@/store/news/reducer';
import notifications from '@/store/notifications/reducer';
import order from '@/store/order/reducer';
import pokaApprovers from '@/store/poka_approvers/reduser';
import pokaProperAreas from '@/store/poka_areas/reduser';
import pokaCategories from '@/store/poka_categories/reduser';
import pokaDirPositions from '@/store/poka_directory/reducer';
import pokaSites from '@/store/poka_sites/reduser';
import pokaSkills from '@/store/poka_skills/reducer';
import typicalPositions from '@/store/poka_typicalPositions/reducer';
import pokaValidationUser from '@/store/poka_validation_user/reducer';
import positions from '@/store/position/reducer';
import posts from '@/store/posts/reducer';
import shop from '@/store/product/reducer';
import questionBank from '@/store/questionBank/reducer';
import search from '@/store/search/reducer';
import skills from '@/store/skills/reducer';
import statistics from '@/store/statistics/reducer';
import students from '@/store/students/reducer';
import tags from '@/store/tags/reducer';
import timezones from '@/store/timezones/reducer';
import topics from '@/store/topics/reducer';
import tracks from '@/store/tracks/reducer';
import users from '@/store/users/reducer';
import version from '@/store/version/reducer';
import waitingList from '@/store/waiting-list/reducer';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  // Implements Diagnostic 360
  d360Projects,
  d360Project,
  d360Scales,
  d360Scores,
  d360Filters,
  d360Measurables,
  d360Respondents,
  d360Criterias,
  d360Results,
  d360Import,
  d360State,
  d360Reports,

  // Others
  access,
  pcfields,
  pcfieldvalue,
  executive,
  authors,
  bonuses,
  compilations,
  courses,
  error,
  event,
  files,
  income,
  login,
  notifications,
  search,
  skills,
  competence,
  students,
  tags,
  posts,
  topics,
  users,
  departments,
  functions,
  companies,
  chapters,
  lms,
  locales,
  filters,
  timezones,
  importUser,
  typeform,
  talentQ,
  tilda,
  existingMaterial,
  companyUsersLimit,
  settingsVisibility,
  quartz,
  blockAdmin,
  webinar,
  typeLabel,
  certificates,
  bannerTopBody,
  api,
  mentors,
  mentee,
  shop,
  order,
  importQuestion,
  tracks,
  news,
  books,
  statistics,
  newNotifications,
  questionBank,
  positions,
  pokaDirPositions,
  messages,
  badge,
  chat,
  waitingList,
  ipr,
  version,
  customTags,
  inspection,
  typicalPositions,
  pokaCategories,
  pokaApprovers,
  pokaSites,
  pokaSkills,
  groups,
  pokaValidationUser,
  pokaProperAreas,
});
