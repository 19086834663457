import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AutoComplete, Skeleton, Tag, Tooltip } from 'antd';

import { clearCustomTags, getCustomTags } from '@shared/store/customTags/actions';
import { selectCustomTags } from '@shared/store/customTags/selectors';

import Utils from '@/Utils';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import css from './TagsAutocomplete.module.scss';

import { ADD_MODE } from '@/constants/bookAddress';

const TagsAutocomplete = ({
  isSkills,
  isThemes,
  isAuthor,
  isCareers,
  isAddress,
  name,
  onChange,
  placeholder,
  value,
  returnAll,
  tagSliceCount,
  className,
  t,
  id,
}) => {
  const dispatch = useDispatch();
  // const { t } = useTranslation('topicCreationDialog');

  const { customTags, isCustomTagsLoading } = useSelector(selectCustomTags);

  const [tags, setTags] = useState(Array.isArray(value) ? value : []);

  const [currentValue, setCurrentValue] = useState('');
  const [selectedTagIds, setSelectedTagIds] = useState(new Set());

  useEffect(() => {
    setTags(value);

    return () => getCustomTagsDebounce.cancel();
  }, [value]);

  const getTagName = item => {
    const name = typeof item === 'string' ? item : item?.props?.children?.[0];
    if (!tagSliceCount) return name;

    return Utils.trString(name, tagSliceCount);
  };

  const renderTags = tags?.map(tag => {
    const { id, name, global } = tag;

    // const showClose = !isAddress ? true : isAddress && tag.accessible;
    const ifGlobal = global && <span className={css.options__global}>{t('global')}</span>;

    return (
      <Tag key={id} className={css.tag} closable onClose={() => handleClose(id, name)}>
        {tagSliceCount > 0 ? (
          <Tooltip title={name} overlayStyle={{ display: 'inline-block' }} trigger={'hover'}>
            <span>{getTagName(name)}</span>
            {ifGlobal}
          </Tooltip>
        ) : (
          <div className={css.tag__row}>
            {name}
            {ifGlobal}
          </div>
        )}
      </Tag>
    );
  });

  const handleClose = (removedTagId, removedTagName) => {
    let updatedTags;
    if (!removedTagId) {
      updatedTags = tags.filter(({ name }) => name !== removedTagName);
      onChange({ name, value: updatedTags, removedTagName });
    } else {
      updatedTags = tags.filter(({ id }) => String(id) !== String(removedTagId));
      onChange({ name, value: updatedTags, removedTagId });
    }

    setSelectedTagIds(prev => {
      const newIds = new Set(prev);
      newIds.delete(removedTagId);
      return newIds;
    });
  };

  const handleInputConfirm = (_, event) => {
    const inputValue = event?.name || event.value;

    if (inputValue?.includes('@skeleton-')) return;
    if (!inputValue?.trim().length) return;
    if (isSkills && inputValue.length < 2) return;
    if (isAuthor && inputValue.length < 1) return;

    const tagsList = tags.map(a => a.name);

    setCurrentValue('');
    dispatch(clearCustomTags());

    if (inputValue && !tagsList.includes(inputValue)) {
      if ((name === 'skillNames' || name === 'tagNames') && inputValue.includes(';')) {
        // processedInputValue = inputValue.replace(/;/g, '');
      }

      const newTagId = `new-${tags.length}`;

      if (isAddress) {
        const { label, mode, value } = event;
        const id = mode === ADD_MODE ? event.id : newTagId;
        const newItem = {
          id,
          name: typeof event.label === 'string' ? label : value.replace('ad-', ''),
          accessible: true,
        };
        const updatedTags = [...tags, newItem];
        onChange({ name, value: updatedTags, addressAdd: newItem });
      } else {
        const id = event.id ? event.id : newTagId; // для новых тегов

        const newItem = {
          id,
          name: event.label?.props?.children[0] || inputValue,
          global: Boolean(event.label?.props?.children[2]?.props),
        };
        const updatedTags = [...tags, returnAll ? event.data : newItem];
        onChange({ name, value: updatedTags });

        setSelectedTagIds(prev => new Set(prev).add(newItem.id));
      }
    }
  };

  const getCustomTagsDebounce = useCallback(
    debounce((...args) => {
      dispatch(getCustomTags(...args));
    }, 400),
    [dispatch]
  );

  const loadOptions = input => {
    const lowerInput = input.toLowerCase();
    setCurrentValue(input);

    if (!input || input.length < 2) {
      return;
    }

    if (isAddress) {
      getCustomTagsDebounce('/book/address', { address: input }, true);
      setCurrentValue(input);
    } else if (isCareers) {
      setCurrentValue(lowerInput);
      getCustomTagsDebounce('/trajectory/career', { name: lowerInput });
    } else if (!isThemes) {
      if (isSkills) {
        getCustomTagsDebounce('/skill', { name: input });
        setCurrentValue(input);
      } else if (isAuthor) {
        setCurrentValue(input);
        getCustomTagsDebounce('/author', { name: input });
      } else {
        setCurrentValue(input);
        getCustomTagsDebounce('/tag', { name: input });
      }
    } else {
      setCurrentValue(lowerInput);
      getCustomTagsDebounce('/topic/compilation', { name: lowerInput });
    }
  };

  const currentCustomTags = useMemo(() => {
    if (isCustomTagsLoading) {
      return customTags.length > 0
        ? customTags.map(e => ({
            label: <Skeleton.Input size='small' active block className={css['skeleton']} />,
            name: '@skeleton-' + e.value,
          }))
        : [{ label: <Skeleton.Input size='small' active block className={css['skeleton']} />, name: '@skeleton-' }];
    } else {
      return customTags.map(({ label, global, value, ...other }) => ({
        label: (
          <div>
            {label} {global ? <span className={css.options__global}>{t('global')}</span> : ''}
          </div>
        ),
        value: String(value),
        ...other,
      }));
    }
  }, [customTags, isCustomTagsLoading]);

  const onBlur = () => {
    setCurrentValue('');
    dispatch(clearCustomTags());
  };

  const options = useMemo(() => {
    const filteredCustomTags = currentCustomTags.filter(tag => !selectedTagIds.has(tag.id));

    return currentValue ? [{ label: currentValue, value: String(currentValue) }, ...filteredCustomTags] : [];
  }, [currentValue, currentCustomTags, selectedTagIds]);

  return (
    <div className={css.TagInput}>
      <AutoComplete
        allowClear
        placeholder={placeholder}
        options={options}
        onSearch={loadOptions}
        className={className}
        onSelect={handleInputConfirm}
        value={String(currentValue)}
        defaultActiveFirstOption={false}
        popupClassName={css['autocomplete-popup']}
        onBlur={onBlur}
        id={id}
      />

      {tags?.length > 0 && <div className={css['tags-container']}>{renderTags}</div>}
    </div>
  );
};

TagsAutocomplete.propTypes = {
  isSkills: PropTypes.bool,
  isThemes: PropTypes.bool,
  isAuthor: PropTypes.bool,
  isCareers: PropTypes.bool,
  isAddress: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  returnAll: PropTypes.bool,
  tagSliceCount: PropTypes.number,
  id: PropTypes.string,
};

export default TagsAutocomplete;
