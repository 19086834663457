/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from 'react';

const ProviderPropsContext = createContext();

export const CollectionTopicsProvider = ({ children, propsValues }) => {
  const [data, setData] = useState({ propsValues });

  const { coursesRequestComment } = propsValues;

  useEffect(() => {
    setData(() => ({ propsValues }));
  }, [coursesRequestComment, propsValues]);

  return <ProviderPropsContext.Provider value={data}>{children}</ProviderPropsContext.Provider>;
};

export const useCollectionTopicsContext = () => useContext(ProviderPropsContext);
