import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import {
  EXPORT_POKA_CORRECT_AREA_FAIL,
  EXPORT_POKA_CORRECT_AREA_START,
  EXPORT_POKA_CORRECT_AREA_SUCCESS,
  GET_POKA_CORRECT_AREAS_FAIL,
  GET_POKA_CORRECT_AREAS_START,
  GET_POKA_CORRECT_AREAS_SUCCESS,
  GET_POKA_SOUR_AREAS_FAIL,
  GET_POKA_SOUR_AREAS_START,
  GET_POKA_SOUR_AREAS_SUCCESS,
  LOAD_POKA_AREAS_FAIL,
  LOAD_POKA_AREAS_START,
  LOAD_POKA_AREAS_SUCCESS,
  SAVE_POKA_AREAS_FILTERS,
  SAVE_POKA_AREAS_SORT,
} from '@/store/poka_areas/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const headersExport = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Получение таблицы корректных участков
export const loadPokaAreas = (params, body = {}) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/area${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [LOAD_POKA_AREAS_START, LOAD_POKA_AREAS_SUCCESS, LOAD_POKA_AREAS_FAIL],
});

// Список всех участков
export const getPokaDirSourArea = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/area${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_SOUR_AREAS_START, GET_POKA_SOUR_AREAS_SUCCESS, GET_POKA_SOUR_AREAS_FAIL],
});

// Список всех корректных участков
export const getPokaDirCorrectPositionArea = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/area/correct${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_CORRECT_AREAS_START, GET_POKA_CORRECT_AREAS_SUCCESS, GET_POKA_CORRECT_AREAS_FAIL],
});

// Сохранение фильтров корректных участков
export const savePokaAreasFilters = value => ({
  type: SAVE_POKA_AREAS_FILTERS,
  value,
});

// Сохранение сортировки корректных участков
export const savePokaAreasSort = value => ({
  type: SAVE_POKA_AREAS_SORT,
  value,
});
// экспортировать данные из таблицы Корректные участки
export const exportCorrectArea = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/area/export`,
  headers: {
    ...headersExport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_POKA_CORRECT_AREA_START, EXPORT_POKA_CORRECT_AREA_SUCCESS, EXPORT_POKA_CORRECT_AREA_FAIL],
  onSuccess,
  onError,
});
