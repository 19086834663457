import {
  GET_COMPANY_USERS_LIMIT_FAIL,
  GET_COMPANY_USERS_LIMIT_START,
  GET_COMPANY_USERS_LIMIT_SUCCESS,
} from './constants';

const initialState = {
  total: null,
  available: null,
  active: null,
  deleted: null,
  loading: false,
};

/* eslint-disable */
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_COMPANY_USERS_LIMIT_START:
      return { ...state, loading: true };

    case GET_COMPANY_USERS_LIMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        total: response.limit,
        available: response.available,
        active: response.active,
        deleted: response.deleted,
      };

    case GET_COMPANY_USERS_LIMIT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
