import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getLimitSettings, setLimitSettings } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import { Button, Form, InputNumber, Modal } from 'antd';

import classNames from 'classnames';
import { inRange, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import css from '../../Mentors.module.scss';

const ModalSettings = props => {
  const { t } = useTranslation('mentors');
  const { limit, isLoading } = useSelector(selectMentors);
  const dispatch = useDispatch();
  const maxNumbSlots = 100;
  const maxNumbCompetences = 100;

  const [data, setData] = useState({
    maxMentorSlots: limit.maxMentorSlots || null,
    maxMentorCompetences: limit.maxMentorCompetences || null,
  });

  const [errors, setErrors] = useState({
    errorMaxSlot: !(inRange(data?.maxMentorSlots, 1, maxNumbSlots + 1) && data.maxMentorSlots !== undefined),
    errorMaxCompetences: !(
      inRange(data?.maxMentorCompetences, 1, maxNumbCompetences + 1) && data.maxMentorCompetences !== undefined
    ),
  });

  useEffect(() => {
    dispatch(getLimitSettings());
  }, []);

  useEffect(() => {
    if (!isEmpty(limit)) {
      setData({ ...data, maxMentorSlots: limit.maxMentorSlots, maxMentorCompetences: limit.maxMentorCompetences });
    }
  }, [limit]);

  useEffect(() => {
    setErrors({
      ...errors,
      errorMaxSlot: !(inRange(data?.maxMentorSlots, 1, maxNumbSlots + 1) && data.maxMentorSlots !== undefined),
      errorMaxCompetences: !(
        inRange(data?.maxMentorCompetences, 1, maxNumbCompetences + 1) && data.maxMentorCompetences !== undefined
      ),
    });
  }, [data]);

  const checkValid = () => {
    return setErrors({
      ...errors,
      errorMaxSlot: !inRange(data.maxMentorSlots, 1, maxNumbSlots + 1),
      errorMaxCompetences: !inRange(data?.maxMentorCompetences, 1, maxNumbCompetences + 1),
    });
  };

  const handleSubmit = () => {
    checkValid();
    if (!errors.errorMaxSlot && !errors.errorMaxCompetences) {
      dispatch(
        setLimitSettings(data, () => {
          props.onCloseModal();
        })
      );
    }
  };

  const isNum = value => {
    const re = /^[0-9\b]+$/;
    return value === undefined || re.test(value);
  };

  const handleOnChange = (value, name) => {
    if (isNum(value)) {
      setData({ ...data, [name]: +value || null });
    }
  };

  const titleModal = <div className={css['Modal-title']}>{t('titleModal')}</div>;

  const footerModal = (
    <>
      <Button onClick={props.onCloseModal} key='cancel' size='large'>
        {t('cancel')}
      </Button>
      <Button onClick={handleSubmit} key='ready' type='primary' size='large'>
        {t('ready')}
      </Button>
    </>
  );

  return (
    <Modal
      className={classNames(css['ModalSettings'])}
      open={props.open}
      title={titleModal}
      onCancel={props.onCloseModal}
      width={450}
      footer={footerModal}
      loading={isLoading}
    >
      <Form>
        <Form.Item
          required
          label={t('maxMentorsSlots')}
          validateStatus={errors.errorMaxSlot ? 'error' : 'success'}
          help={data.maxMentorSlots > maxNumbSlots ? t('errorMax') : errors.errorMaxSlot && t('error')}
        >
          <InputNumber
            min={1}
            name={'maxMentorSlots'}
            placeholder={t('maxSlotPlaceholder')}
            onChange={e => handleOnChange(e, 'maxMentorSlots')}
            value={data.maxMentorSlots}
            className={css['ModalSettings-inputNumber']}
            size='large'
          />
        </Form.Item>
        <Form.Item
          required
          label={t('maxCompetencesMentors')}
          validateStatus={errors.errorMaxCompetences ? 'error' : 'success'}
          help={
            data.maxMentorCompetences > maxNumbCompetences ? t('errorMax') : errors.errorMaxCompetences && t('error')
          }
        >
          <InputNumber
            min={1}
            name={'maxMentorCompetences'}
            placeholder={t('maxCompetencesPlaceholder')}
            onChange={e => handleOnChange(e, 'maxMentorCompetences')}
            value={data.maxMentorCompetences}
            className={css['ModalSettings-inputNumber']}
            size='large'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalSettings.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

export default ModalSettings;
