import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectMentors } from '@/store/mentors/selectors';

import { Button, Modal, Table, Tooltip } from 'antd';

import PropTypes from 'prop-types';

import css from '../../Mentors.module.scss';

const ModalMenti = props => {
  const { t } = useTranslation('mentors');
  const { mentee } = useSelector(selectMentors);

  const footerModal = (
    <Button onClick={props.onClose} size='large'>
      {t('close')}
    </Button>
  );

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('userName'),
      dataIndex: 'userName',
      key: '1',
      align: 'left',
    });

    columns.push({
      title: t('position'),
      dataIndex: 'position',
      key: '2',
      align: 'left',
      render: text => (
        <div className={css['ModalMenti-table-position']}>
          <Tooltip title={text?.length > 30 && text}>{text}</Tooltip>
        </div>
      ),
    });

    columns.push({
      title: t('company'),
      dataIndex: 'company',
      key: '3',
      align: 'left',
    });

    columns.push({
      title: t('department'),
      dataIndex: 'department',
      key: '4',
      align: 'left',
    });

    columns.push({
      title: t('function'),
      dataIndex: 'function',
      key: '5',
      align: 'left',
    });

    columns.push({
      title: t('status'),
      dataIndex: 'statusText',
      key: '6',
      align: 'left',
    });

    return columns;
  };

  const getData = () => {
    if (mentee?.length) {
      return mentee.map(item => {
        return {
          userName: item.userName,
          position: item.position,
          company: item.company,
          department: item.department,
          function: item.function,
          status: item.status,
          statusText: item.statusText,
        };
      });
    }
  };

  const pagination = {
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  return (
    <Modal
      className={css['ModalMenti']}
      title={t('modalTitleStat')}
      open={props.open}
      onCancel={props.onClose}
      footer={footerModal}
      width={1100}
      bordered
    >
      <div>
        <div>
          <Table
            dataSource={getData()}
            columns={getColumns()}
            rowKey={e => `${e.userName}--${Symbol('uniq').toString()}`}
            pagination={pagination}
          />
        </div>
      </div>
    </Modal>
  );
};

ModalMenti.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalMenti;
