/* eslint-disable */
import { GET_VERSION_FAIL, GET_VERSION_START, GET_VERSION_SUCCESS } from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  version: {},
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_VERSION_START:
      return { ...state, isLoading: true };

    case GET_VERSION_SUCCESS:
      return { ...state, isLoading: false, version: response };

    case GET_VERSION_FAIL:
      return { ...state, isLoading: false, isError: true };

    default:
      return { ...state };
  }
};
