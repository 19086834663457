/* eslint-disable */
import {
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_START,
  USER_LOGOUT_SUCCESS,
} from './types';

import getQueryString from '@/utils/get-query-string';
import { API_BASE, API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import { IAction } from '@shared/interfaces/store';

export const EXTERNAL_LOGIN = 'EXTERNAL_LOGIN';
export const EXTERNAL_LOGOUT = 'EXTERNAL_LOGOUT';
export const UPDATE_LOGIN_EMPTY = 'UPDATE_LOGIN_EMPTY';

export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const REFRESH_LOGIN_START = 'REFRESH_LOGIN_START';
export const REFRESH_LOGIN_SUCCESS = 'REFRESH_LOGIN_SUCCESS';
export const REFRESH_LOGIN_FAIL = 'REFRESH_LOGIN_FAIL';

export const ACTUAL_LOGIN_START = 'ACTUAL_LOGIN_START';
export const ACTUAL_LOGIN_SUCCESS = 'ACTUAL_LOGIN_SUCCESS';
export const ACTUAL_LOGIN_FAIL = 'ACTUAL_LOGIN_FAIL';

export const GET_INFO_COMPANY_START = 'GET_INFO_COMPANY_START';
export const GET_INFO_COMPANY_SUCCESS = 'GET_INFO_COMPANY_SUCCESS';
export const GET_INFO_COMPANY_FAIL = 'GET_INFO_COMPANY_FAIL';

export const MASK_IP_CHECK_START = 'MASK_IP_CHECK_START';
export const MASK_IP_CHECK_SUCCESS = 'MASK_IP_CHECK_SUCCESS';
export const MASK_IP_CHECK_FAIL = 'MASK_IP_CHECK_FAIL';

const defaultToken = '';
const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

type TActionCallback = (response: any) => {};

export const getInfoCompany = (onSuccess: TActionCallback, onError: TActionCallback) => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/company/domain`,
  headers: { ...headers },
  types: [GET_INFO_COMPANY_START, GET_INFO_COMPANY_SUCCESS, GET_INFO_COMPANY_FAIL],
  onSuccess,
  onError,
});

export const maskIpCheck = (onSuccess: TActionCallback, onError: TActionCallback) => ({
  type: REST_API,
  method: GET,
  url: API_BASE + '/api/v1/mask/check',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [MASK_IP_CHECK_START, MASK_IP_CHECK_SUCCESS, MASK_IP_CHECK_FAIL],
  onSuccess,
  onError,
});

export const loginGoogle = (provider: string, onSuccess: TActionCallback) => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/oauth2/authorization/${provider}`,
  headers: {
    ...headers,
    credentials: 'same-origin',
  },
  responseType: 'text',
  onSuccess,
  types: [LOGIN_GOOGLE_START, LOGIN_GOOGLE_SUCCESS, LOGIN_GOOGLE_FAIL],
});

export const refreshLogin = (onSuccess: TActionCallback, noHeader: boolean, onError: TActionCallback) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/login/refresh`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('refreshToken'),
  },
  types: [REFRESH_LOGIN_START, REFRESH_LOGIN_SUCCESS, REFRESH_LOGIN_FAIL],
  onSuccess,
  onError,
});

export const actualLogin = (onSuccess: TActionCallback) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/login/check`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [ACTUAL_LOGIN_START, ACTUAL_LOGIN_SUCCESS, ACTUAL_LOGIN_FAIL],
  onSuccess,
});

export const externalLogin = () => ({
  type: EXTERNAL_LOGIN,
});

export const externalLogout = () => ({
  type: EXTERNAL_LOGOUT,
});

export const updateLoginEmpty = () => ({
  type: UPDATE_LOGIN_EMPTY,
});

export const login = (password: any, username: any, verifyCaptcha: any, onSuccess: any, onError: any) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/login${getQueryString({
    'g-recaptcha-response': verifyCaptcha,
  })}`,
  headers,
  body: { password, username },
  onSuccess,
  onError,
  types: [LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL],
});

const loginSuccess = (payload: any): IAction => ({
  type: LOGIN_SUCCESS,
  payload,
  skip: true,
});

const loginFail = (error: string): IAction => ({
  type: LOGIN_FAIL,
  skip: true,
  payload: error,
});

const logoutStart = (payload: any) => ({
  type: USER_LOGOUT_START,
  payload,
  skip: true,
});

const logoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS,
});

const logoutFail = () => ({
  type: USER_LOGOUT_FAIL,
});

export { loginFail, loginSuccess, logoutStart, logoutFail, logoutSuccess };
