import { API_URL } from '../constants/config';
import { goToLoginPage } from '../utils/location';
import { UserStorage } from './user-storage';

import axios from 'axios';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import Qs from 'qs';

let isRefreshing = false;
const refreshSubscribers = new Set(); // todo оформить в singleton

export class ApiService {
  userStorage = new UserStorage();

  instance;

  constructor(config = {}) {
    this.instance = axios.create({
      baseURL: API_URL,
      headers: {
        Accept: 'application/json',
      },
      paramsSerializer: (params = {}) => Qs.stringify(params, { arrayFormat: 'repeat' }),
      ...config,
    });
    this.instance.interceptors.request.use(this.requestHandler);
    this.instance.interceptors.response.use(undefined, this.errorHandler.bind(this));
  }

  customInstance(config = this.instance.defaults) {
    return axios.create(config);
  }

  request({ fullResponse = false, ...config } = {}) {
    return this.instance.request(config).then(this.responseHandler(fullResponse));
  }

  get(url, params = {}, { fullResponse = false, ...config } = {}) {
    return this.instance.get(url, { ...config, params }).then(this.responseHandler(fullResponse));
  }

  post(url, data = {}, { fullResponse = false, ...config } = {}) {
    return this.instance.post(url, data, config).then(this.responseHandler(fullResponse));
  }

  put(url, data = {}, { fullResponse = false, ...config } = {}) {
    return this.instance.put(url, data, config).then(this.responseHandler(fullResponse));
  }

  delete(url, params = {}, { fullResponse = false, ...config } = {}) {
    return this.instance.delete(url, { ...config, params }).then(this.responseHandler(fullResponse));
  }

  responseHandler = isFullResponse => response => (isFullResponse ? response : _get(response, 'data'));

  requestHandler = config => {
    const token = this.userStorage.accessToken;

    if (token && (_isUndefined(config.headers.Authorization) || config.headers.Authorization)) {
      config.headers.Authorization = token;
    } else if (token && !config.headers.Authorization) {
      delete config.headers.Authorization;
    }

    return config;
  };

  getRefreshAuth() {
    if (!this.userStorage.refreshToken) {
      return Promise.reject(Error('Refresh token not founded'));
    }
    return this.customInstance()
      .get('/login/refresh', {
        headers: { Authorization: this.userStorage.refreshToken },
      })
      .then(response => _get(response, 'data'));
  }

  errorHandler(error) {
    const { response } = error;
    const config = error.config;

    const originalRequest = config;

    if (response) {
      switch (response.status) {
        case 401:
          if (config.withoutRefresh) {
            break;
          }
          if (!isRefreshing) {
            isRefreshing = true;
            this.getRefreshAuth()
              .then(response => {
                this.userStorage.setData(response);
                refreshSubscribers.forEach(cb => cb(response.accessToken));
              })
              .catch(() => {
                this.userStorage.removeData();
                goToLoginPage();
              })
              .finally(() => {
                isRefreshing = false;
                refreshSubscribers.clear();
              });
          }

          return new Promise(resolve => {
            refreshSubscribers.add(token => {
              originalRequest.headers.Authorization = token;
              resolve(this.instance(originalRequest));
            });
          });
        default:
          return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
}

export default new ApiService();
