/* eslint-disable */
import {
  CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_FAIL,
  CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_START,
  CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS,
  DELETE_CUSTOM_BY_ONE_NOTIFICATION_START,
  DELETE_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
  DELETE_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  GET_CUSTOM_BY_ALL_NOTIFICATIONS_FAIL,
  GET_CUSTOM_BY_ALL_NOTIFICATIONS_START,
  GET_CUSTOM_BY_ALL_NOTIFICATIONS_SUCCESS,
  GET_CUSTOM_BY_ONE_NOTIFICATION_START,
  GET_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
  GET_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_START,
  PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
  PUBLISH_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const createOrUpdateCustomNotifications = (isCreate, body, onSuccess, onError) => ({
  type: REST_API,
  method: isCreate ? POST : PUT,
  url: `${API_URL}/notifications/admin/${isCreate ? 'custom' : 'edit'}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  onSuccess,
  onError,
  isCreate,
  types: [
    CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_START,
    CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS,
    CREATE_OR_UPDATE_CUSTOM_NOTIFICATIONS_FAIL,
  ],
});

export const getCustomNotifications = (params, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/notifications/admin/custom${getQueryString(params)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  types: [
    GET_CUSTOM_BY_ALL_NOTIFICATIONS_START,
    GET_CUSTOM_BY_ALL_NOTIFICATIONS_SUCCESS,
    GET_CUSTOM_BY_ALL_NOTIFICATIONS_FAIL,
  ],
});

export const getCustomByOneNotification = (paramID, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/notifications/admin/custom/${paramID}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  types: [
    GET_CUSTOM_BY_ONE_NOTIFICATION_START,
    GET_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
    GET_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  ],
});

export const deleteCustomByOneNotification = (paramId, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/notifications/admin/custom/${paramId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  paramId: paramId,
  onSuccess,
  onError,
  types: [
    DELETE_CUSTOM_BY_ONE_NOTIFICATION_START,
    DELETE_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
    DELETE_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  ],
});

export const publishCustomByOneNotification = (paramId, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/notifications/admin/publish/${paramId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  paramId: paramId,
  body,
  onSuccess,
  onError,
  types: [
    PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_START,
    PUBLISH_CUSTOM_BY_ONE_NOTIFICATION_SUCCESS,
    PUBLISH_CUSTOM_BY_ONE_NOTIFICATIONS_FAIL,
  ],
});
