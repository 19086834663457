import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { changeCoursesFilter } from '@/store/courses/actions';
import {
  addCurrentMentors,
  clearIprDetails,
  confirmIpr,
  finishedIpr,
  getIprMentors,
  postIprFilters,
} from '@/store/ipr/actions';
import { setEmployeeFullname } from '@/store/students/actions';

import { selectIpr } from '@/store/ipr/selectors';
import { selectUsers } from '@/store/users/selectors';

import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Modal,
  Progress,
  Row,
  Select,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';
import { STATUS_WAITING_MENTOR } from '@/pages/Mentee/Content/constance';
import MaterialModal from '@/components/MaterialModal';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';

import {
  CHECK_APPROVED,
  DATE_FORMAT,
  FINISH_IPR,
  FINISHED,
  FINISHED_IPR_STATUS,
  MAX_EXECUTIVE_IPR,
  MAX_TOPICS_IPR,
  RTN_WRK,
  TASKS,
  TOPICS,
  VIEW_IPR,
} from '../constants';
import CareerTopic from './CareerTopic';

import { isExecutiveTask } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import deepEqual from 'deep-equal';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../Career.module.scss';

const { Option } = Select;

const ModalCheckApprove = props => {
  const { t } = useTranslation('career');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const finishOrView = props.type === FINISH_IPR || props.type === VIEW_IPR;

  const { iprMentors, iprDetails, isLoading, iprSort, iprFilters } = useSelector(selectIpr);
  const { currentUser } = useSelector(selectUsers);

  const [cloneParts, setCloneParts] = useState([]);
  const [isModalMaterial, setIsModalMaterial] = useState({ open: false, devTasks: false });
  const [compTopics, setCompTopics] = useState([]);
  const [compDevTasks, setCompDevTasks] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [clickedTab, setClickedTab] = useState({});
  const [currentComp, setCurrentComp] = useState({ id: null, name: '' });
  const [step, setIsStep] = useState({ checked: true, course: false, finish: false });
  const [dateIpr, setDateIpr] = useState();

  const onChangeTabs = index => {
    setCurrentTab(index);
    setClickedTab(prevState => ({ ...prevState, [index]: true }));

    let arr = cloneParts[index].mentors;
    dispatch(addCurrentMentors(arr));
  };

  const disabledDate = current => dayjs().add(-1, 'days').isAfter(dayjs(current));

  const deleteTopic = (val, typeId) => {
    if (isModalMaterial.devTasks || isExecutiveTask(typeId)) {
      cloneParts[currentTab].tasks = cloneParts[currentTab].tasks.filter(j => j.id !== val);
    } else {
      cloneParts[currentTab].topics = cloneParts[currentTab].topics.filter(j => j.id !== val);
    }

    setCloneParts([...cloneParts]);
  };

  const showAddmaterials = type => {
    if (type === TOPICS) {
      setCompTopics(cloneParts[currentTab].topics);
    } else {
      setCompDevTasks(cloneParts[currentTab].tasks);
    }

    setIsModalMaterial({ open: true, devTasks: type !== TOPICS });

    setCurrentComp({
      id: cloneParts[currentTab].id,
      name: cloneParts[currentTab].name,
    });
  };

  const addMaterialParts = newTopics => {
    if (isModalMaterial.devTasks) {
      cloneParts[currentTab].tasks = newTopics;
    } else {
      cloneParts[currentTab].topics = newTopics;
    }

    setCloneParts([...cloneParts]);
    setIsModalMaterial({ ...isModalMaterial, open: false });
  };

  const optMentors = useMemo(
    () =>
      iprMentors?.length &&
      iprMentors.map(item => {
        let mentorName = (item.lastName || '') + ' ' + (item.firstName || '');
        return (
          <Option
            key={item.id}
            value={item.id}
            firstName={item.firstName}
            lastName={item.lastName}
            disabled={item?.mentoringStatusDto?.value ? item.mentoringStatusDto.value !== STATUS_WAITING_MENTOR : false}
            status={item?.mentoringStatusDto?.value}
            statusText={item?.mentoringStatusDto?.name}
          >
            {mentorName}
          </Option>
        );
      }),
    [iprMentors]
  );

  const disableApproved = () => {
    const isEqual = deepEqual(iprDetails?.competencies, cloneParts);
    const isEqualDate = deepEqual(iprDetails?.deadline, dateIpr && dayjs(dateIpr).format(DATE_FORMAT));

    return Object.values(clickedTab).includes(false) || !isEqual || !isEqualDate;
  };

  const onSearchMentors = e => {
    let currentMentorsIds = cloneParts[currentTab]?.mentors?.length && cloneParts[currentTab].mentors.map(a => a.id);
    let params = { fullName: e, excluded: currentMentorsIds };
    e?.length > 3 && dispatch(getIprMentors(params));
  };

  const getStudentsListDebounced = useCallback(_.debounce(onSearchMentors, 1000), [currentTab, cloneParts]);

  const onChangeMentors = (_, item) => {
    const newMentors = item.map(i => ({
      id: i.value,
      firstName: i.firstName,
      lastName: i.lastName,
      mentoringStatusDto: { value: i.status, name: i.statusText },
    }));
    cloneParts[currentTab].mentors = newMentors;
    setCloneParts([...cloneParts]);
  };

  const showBlockFinished = () => {
    setIsStep({ checked: false, course: false, finish: true });
  };

  const renderMentorsName = (j, index) => {
    let nameMentor = `${j.lastName || ''} ${j.firstName || ''}`;
    return (
      <div className={css['ModalCheckApprove-main-tabs-mentors-check']} key={index}>
        <div key={j.id}>{nameMentor}</div>
        <Checkbox key={index} checked={j.mentoringStatusDto?.value === FINISHED} />
      </div>
    );
  };

  const renderMaterials = k => {
    let data = {
      id: k.id,
      thumbFileUuid: k.thumbFileUuid,
      name: k.name,
      type: k.type,
      status: k.planStatus,
    };

    return <CareerTopic data={data} key={k.id} deleteTopic={deleteTopic} type={props.type} />;
  };

  const tagRender = obj => {
    const { label, value, onClose } = obj;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    const currStatusMentor = cloneParts?.[currentTab]?.mentors.find(i => i.id === value);

    return (
      <Tooltip title={currStatusMentor?.mentoringStatusDto?.name}>
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={
            currStatusMentor?.mentoringStatusDto?.value
              ? currStatusMentor.mentoringStatusDto.value === STATUS_WAITING_MENTOR
              : true
          }
          onClose={onClose}
          style={{ marginInlineEnd: 4 }}
        >
          {label}
        </Tag>
      </Tooltip>
    );
  };

  const tabsItem =
    cloneParts?.length &&
    cloneParts.map((i, index) => {
      let topicsPart = i?.topics?.map(renderMaterials);

      let mentorsName = i?.mentors?.map(renderMentorsName);

      let devTasks = i?.tasks?.map(renderMaterials);

      let nameTab = (
        <div className={css['ModalCheckApprove-main-tabs-name']}>
          {props.type === CHECK_APPROVED && (
            <div className={css['ModalCheckApprove-main-tabs-icons']}>
              {clickedTab[index] ? <CheckCircleFilled /> : <CheckCircleOutlined />}
            </div>
          )}
          <div>{i.name}</div>
        </div>
      );

      return {
        key: index,
        label: nameTab,
        children: (
          <>
            <Row justify='space-between' align='middle' className={css['ModalCheckApprove-main-tabs-dev']}>
              <Col className={css['ModalCheckApprove-main-tabs-dev-title']}>{t('devTasks')}</Col>
              <Col>
                {props.type === FINISH_IPR && <div>{t('descDevTasks')}</div>}
                {props.type === CHECK_APPROVED && (
                  <Button onClick={() => showAddmaterials(TASKS)} type='primary'>
                    {t('add')}
                  </Button>
                )}
              </Col>

              <Col span={24}>{devTasks}</Col>
            </Row>

            <Divider />

            {currentUser?.domainCompany?.hasMentors && (
              <>
                <Row className={css['ModalCheckApprove-main-tabs-mentors']}>
                  <Col span={24} className={css['ModalCheckApprove-main-tabs-mentors-title']}>
                    {t('mentors')}
                  </Col>
                  <Col span={24} className={css['ModalCheckApprove-main-tabs-mentors-desc']}>
                    <Alert type='info' closable message={t('mentorsDesc')} />
                  </Col>

                  <Col span={24}>
                    {props.type === CHECK_APPROVED && (
                      <Select
                        showSearch
                        filterOption={false}
                        className={css['ModalCheckApprove-main-tabs-mentors-select']}
                        onChange={onChangeMentors}
                        onSearch={getStudentsListDebounced}
                        maxCount={3}
                        tagRender={tagRender}
                        value={cloneParts?.[currentTab]?.mentors.map(i => i.id)}
                        placeholder={t('placeholderMentorSearch')}
                        mode='multiple'
                      >
                        {optMentors}
                      </Select>
                    )}
                    {finishOrView && <div>{mentorsName}</div>}
                  </Col>
                </Row>

                <Divider />
              </>
            )}

            <Row className={css['ModalCheckApprove-main-tabs-material']} justify='space-between' align='middle'>
              <Col className={css['ModalCheckApprove-main-tabs-material-title']}>{t('materials')}</Col>
              <Col>
                {props.type === FINISH_IPR && t('descMaterials')}
                {props.type === CHECK_APPROVED && (
                  <Button
                    onClick={() => showAddmaterials(TOPICS)}
                    disabled={cloneParts[currentTab].topics.length > 50}
                    type='primary'
                  >
                    {t('add')}
                  </Button>
                )}
              </Col>
              <Col span={24}>{topicsPart}</Col>
            </Row>
          </>
        ),
      };
    });

  const renderTabs = (
    <div className={css['ModalCheckApprove-main-tabs']}>
      <Tabs onChange={onChangeTabs} items={tabsItem} />
    </div>
  );

  const reWorkIpr = () => {
    props.onClose();
    props.showModalAction({
      action: RTN_WRK,
      open: true,
      data: { competencies: cloneParts, deadline: dayjs(dateIpr, DATE_FORMAT) },
    });
  };

  const approvedCareer = () => {
    if (iprDetails?.id) {
      dispatch(
        confirmIpr(iprDetails.id, () => {
          setIsStep({ checked: false, course: true, finish: false });
          dispatch(postIprFilters(iprFilters ?? {}, 0, iprSort));
        })
      );
    }
  };

  const goToManageCourse = () => {
    const userForApprove = {
      id: iprDetails?.userId,
      name: props.isIpr?.obj?.fullLastName,
    };
    dispatch(changeCoursesFilter({ name: 'userId', value: userForApprove?.id }));
    dispatch(setEmployeeFullname([{ id: userForApprove?.id, name: userForApprove?.name }]));
    navigate('/manage-course');
  };

  const onFinish = () => {
    if (iprDetails?.id && iprDetails?.status?.status && FINISHED_IPR_STATUS.includes(iprDetails.status.status)) {
      dispatch(
        finishedIpr(iprDetails.id, () => {
          props.onClose();
          dispatch(postIprFilters(iprFilters ?? {}, 0, iprSort));
        })
      );
    }
  };

  const titleModal = <div className={css['ModalCheckApprove-title']}>{props.isIpr.obj.userTable}</div>;

  useEffect(() => {
    return () => {
      dispatch(clearIprDetails()); // чистим стор при размонтировании
    };
  }, [dispatch]);

  useEffect(() => {
    if (iprDetails?.competencies?.length) {
      setCloneParts(_.cloneDeep(iprDetails.competencies));

      if (currentUser?.domainCompany?.hasMentors) {
        let arr = iprDetails.competencies[currentTab].mentors;
        dispatch(addCurrentMentors(arr));
      }
    }
  }, [iprDetails, props.open]);

  useEffect(() => {
    if (iprDetails?.deadline) {
      setDateIpr(dayjs(iprDetails.deadline, DATE_FORMAT));
    }
  }, [iprDetails, props.open]);

  useEffect(() => {
    if (props.type === CHECK_APPROVED && iprDetails?.competencies?.length) {
      const newClickedTab = iprDetails.competencies.reduce((acc, item, index) => {
        if (index === 0) {
          acc[index] = true;
        } else {
          acc[index] = false;
        }

        return acc;
      }, {});
      setClickedTab(newClickedTab);
    }
  }, [iprDetails.competencies, props.open, props.type]);

  return (
    <Modal
      open={props.open}
      onCancel={props.onClose}
      footer={null}
      title={step.checked && titleModal}
      width={800}
      loading={isLoading}
    >
      <div className={css['ModalCheckApprove']}>
        {step.checked && (
          <>
            <div className={css['ModalCheckApprove-header']}>
              <Row align='middle'>
                <Col span={5}>{t('catGoal')}</Col>
                <Col span={19}>{iprDetails?.goalCategory}</Col>
              </Row>
              <Row className={css['ModalCheckApprove-header-goal']} align='middle'>
                <Col span={5}>{t('goal')}</Col>
                <Col span={19}>{iprDetails?.goal}</Col>
              </Row>
              <Row align='middle'>
                <Col span={5}>{t('deadlineIpr')}</Col>
                <Col span={19}>
                  <DatePicker
                    value={dayjs(dateIpr)}
                    format={DATE_FORMAT}
                    disabledDate={disabledDate}
                    disabled={finishOrView}
                    onChange={e => setDateIpr(e ? dayjs(e) : dayjs())}
                  />
                </Col>
              </Row>
            </div>

            {props.type === CHECK_APPROVED && (
              <Alert className={css['ModalCheckApprove-alert']} message={t('iprInfoLimit')} type='info' closable />
            )}

            <div className={css['ModalCheckApprove-main']}>{renderTabs}</div>

            <Divider />

            {iprDetails?.rejectComment && (
              <Row className={css['ModalCheckApprove-comment']}>
                <Col span={4}>{t('comments')}</Col>
                <Col span={20}>{iprDetails?.rejectComment}</Col>
              </Row>
            )}

            {props.type === CHECK_APPROVED && (
              <>
                <Alert className={css['ModalCheckApprove-alert']} message={t('descCheckAppr')} type='info' closable />
                <Row align='middle' justify='end' gutter={[24]}>
                  <Col>
                    <Button onClick={reWorkIpr} size='large'>
                      {t('rework')}
                    </Button>
                  </Col>
                  <Col>
                    <Button type='primary' disabled={disableApproved()} onClick={approvedCareer} size='large'>
                      {t('approvedIpr')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            {finishOrView && (
              <>
                <Row className={css['ModalCheckApprove-progress']}>
                  <Col span={4}>{t('progress')}</Col>
                  <Col span={20}>
                    <Progress percent={iprDetails?.progress} />
                  </Col>
                </Row>
                {props.type === FINISH_IPR && (
                  <Row justify='end'>
                    <Button type='primary' onClick={showBlockFinished}>
                      {t('accept')}
                    </Button>
                  </Row>
                )}
              </>
            )}
          </>
        )}

        {step.course && (
          <div className={css['ModalCheckApprove-approved']}>
            <div className={css['ModalCheckApprove-approved-desc']}>{t('manageCourse')}</div>
            <div className={css['ModalCheckApprove-approved-desc-btn']}>
              <Button onClick={props.onClose}>{t('cancelCourse')}</Button>
              <Button type='primary' onClick={goToManageCourse}>
                {t('checkCourse')}
              </Button>
            </div>
          </div>
        )}

        {step.finish && props.type === FINISH_IPR && (
          <div className={css['ModalCheckApprove-finish']}>
            <div className={css['ModalCheckApprove-finish-desc']}>{t('finishIpr')}</div>
            <div className={css['ModalCheckApprove-finish-desc-btn']}>
              <Button onClick={props.onClose}>{t('btnCancelIpr')}</Button>
              <Button type='primary' onClick={onFinish}>
                {t('btnFinishIpr')}
              </Button>
            </div>
          </div>
        )}
      </div>

      {isModalMaterial.open && (
        <MaterialModal
          open={isModalMaterial.open}
          title={isModalMaterial.devTasks ? t('choiseDevTasks') : t('choiseMaterials')}
          isIpr
          onCancel={() => setIsModalMaterial({ ...isModalMaterial, open: false })}
          onOk={addMaterialParts}
          selectedTopics={isModalMaterial.devTasks ? compDevTasks : compTopics}
          customPlaceholder={t('placeholderSearch')}
          currentCompIpr={currentComp}
          devTasks={isModalMaterial.devTasks}
          limitText={
            isModalMaterial.devTasks
              ? t('iprLimitDevTasks', { limit: MAX_EXECUTIVE_IPR })
              : t('iprLimitTopic', { limit: MAX_TOPICS_IPR })
          }
          hasLimit={{ maxTopics: MAX_TOPICS_IPR, maxExecutive: MAX_EXECUTIVE_IPR }}
        />
      )}
    </Modal>
  );
};

ModalCheckApprove.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  isIpr: PropTypes.object,
  showModalAction: PropTypes.func,
  type: PropTypes.string,
  isModalAction: PropTypes.object,
};

export default ModalCheckApprove;
