/* eslint-disable */
import { API_D360_URL } from '../service_api';
import {
  D360_CREATE_RESPONDENT_FAIL,
  D360_CREATE_RESPONDENT_START,
  D360_CREATE_RESPONDENT_SUCCESS,
  D360_DELETE_RESPONDENT_FAIL,
  D360_DELETE_RESPONDENT_START,
  D360_DELETE_RESPONDENT_SUCCESS,
  D360_GET_RESPONDENTS_FAIL,
  D360_GET_RESPONDENTS_START,
  D360_GET_RESPONDENTS_SUCCESS,
} from './constants';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

// POST
export const D360CreateRespondent = (projectId, measurableIds, userId, role = null, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/respondent`,
    body: {
      d360_project_id: projectId,
      measurables: measurableIds,
      user_id: userId,
      role: role,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_RESPONDENT_START, D360_CREATE_RESPONDENT_SUCCESS, D360_CREATE_RESPONDENT_FAIL],
    onSuccess,
    onError,
  };
};

// DELETE
export const D360DeleteRespondent = (projectId, measurableId, respondentId, removeScore, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/respondent${getQueryString({
      d360_project_id: projectId,
      d360_measurable_id: measurableId,
      respondent_id: respondentId,
      remove_score: removeScore,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DELETE_RESPONDENT_START, D360_DELETE_RESPONDENT_SUCCESS, D360_DELETE_RESPONDENT_FAIL],
    onSuccess,
    onError,
  };
};

// GET
export const D360GetRespondents = (projectId, userId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/respondents${getQueryString({
      d360_project_id: projectId,
      user_id: userId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_RESPONDENTS_START, D360_GET_RESPONDENTS_SUCCESS, D360_GET_RESPONDENTS_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

 import {
    D360CreateRespondent,
    D360DeleteRespondent,
    D360GetRespondents,
   } from '@/store/d360_respondents/actions';

 **************************************************************/
