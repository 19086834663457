const ENV_API_BASE = location.origin;

export const API_URL = `${ENV_API_BASE}/api/v1`;
export const API_BASE = ENV_API_BASE;

const ENV_API_V2 = process.env.VITE_APP_API_V2 || '';
export const API_V2 = ENV_API_V2;

let ENV_API_TQ = process.env.VITE_APP_API_TQ || '';

if (location.hostname.includes('stage.kampus.local')) ENV_API_TQ = 'https://51.250.110.153';

export const API_TQ = ENV_API_TQ;

const ENV_API_V3 = process.env.REACT_APP_API_V3 || '';
export const API_URL_V3 = `${ENV_API_V3}/api/v3`;
