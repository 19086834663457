/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { D360GetResults } from '@/store/d360/d360_results/actions';

import { Button, Card, Col, Modal, Progress, Row, Spin, Tabs } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { ImFileExcel } from 'react-icons/im';

import { extractUsername } from '../../utils';
import ResultRadar from './ResultRadar';
import ResultTable from './ResultTable';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MODAL_WIDTH = 1000;

export const ViewResult = props => {
  const { t } = useTranslation('D360_Result');
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [loaded, setLoaded] = useState(0);

  useEffect(() => {
    if (!_.isNil(props) && _.isObject(props)) {
      if (_.has(props, 'projectId') && _.isNumber(props.projectId) && props.projectId > 0) {
        if (_.has(props, 'measurables') && _.isArray(props.measurables) && props.measurables.length > 0) {
          props.measurables.forEach((measurable, index) => {
            if (reports.filter(e => e.id === measurable.id).length === 0) {
              dispatch(
                D360GetResults(
                  props.projectId,
                  measurable.id,
                  response => {
                    const report = {
                      id: measurable.id,
                      name: extractUsername(measurable.student),
                      data: response.data,
                      elements: (
                        <Tabs.TabPane key={`measurable_tab_${measurable.id}`} tab={extractUsername(measurable.student)}>
                          <Card size='small'>
                            <Tabs size='small' style={{ padding: 10 }}>
                              <Tabs.TabPane key='table' tab={t('table')}>
                                <ResultTable data={response.data} />
                              </Tabs.TabPane>
                              <Tabs.TabPane key='radar' tab={t('radar')}>
                                <ResultRadar data={response.data} />
                              </Tabs.TabPane>
                            </Tabs>
                          </Card>
                        </Tabs.TabPane>
                      ),
                    };

                    setReports(oldData => [...oldData, report]);
                    setLoaded(index);
                  },
                  error => {
                    setLoaded(index);
                  }
                )
              );
            }
          });
        }
      }
    }

    return () => setReports([]);
  }, [props]);

  const excelColumns = [
    {
      title: t('columnCriteria'),
      key: 'criteria',
      dataIndex: 'criteria',
      render: (name, record) => {
        return <>{record.name}!</>;
      },
    },
    {
      title: t('columnManagers'),
      key: 'manager',
      dataIndex: 'manager',
      align: 'center',
    },
    {
      title: t('columnSubordinates'),
      key: 'subordinate',
      dataIndex: 'subordinate',
      align: 'center',
    },
    {
      title: t('columnColleagues'),
      key: 'colleague',
      dataIndex: 'colleague',
      align: 'center',
    },
    {
      title: t('columnOthers'),
      key: 'cross',
      dataIndex: 'cross',
      align: 'center',
    },
    {
      title: t('columnSelf'),
      key: 'self',
      dataIndex: 'self',
      align: 'center',
    },
    {
      title: t('columnMedian'),
      key: 'median',
      dataIndex: 'median',
      align: 'center',
    },
  ];

  const onCancel = () => (typeof props.onCancel === 'function' ? props.onCancel() : null);

  const onExportToExcel = () => {
    const excel = new Excel();
    const filename = 'Report_' + dayjs().format('YYYY-MM-DD').toString() + '.xlsx';
    const filtered = ['self', 'manager', 'colleague', 'subordinate', 'cross'];

    const columns = [
      { key: 'name', dataIndex: 'name', title: t('columnCriteria') },
      { key: 'manager', dataIndex: 'manager', title: t('columnManagers') },
      { key: 'subordinate', dataIndex: 'subordinate', title: t('columnSubordinates') },
      { key: 'colleague', dataIndex: 'colleague', title: t('columnColleagues') },
      { key: 'cross', dataIndex: 'cross', title: t('columnOthers') },
      { key: 'self', dataIndex: 'self', title: t('columnSelf') },
      { key: 'median', dataIndex: 'median', title: t('columnMedian') },
    ];

    reports.forEach((report, index) => {
      // console.log( report.data );

      //excel.addSheet(`report_${index}`);
      //excel.addColumns(columns);
      // excel.addDataSource([
      //   { manager: '1', subordinate: '2', colleague: '3', cross: '4', self: '5', median: '6'},
      // ]);

      // console.log(report.data);
      //
      let currentReport = report.data;
      currentReport.forEach(object => {
        Object.keys(object).forEach(key => {
          if (filtered.includes(key)) {
            object[key] < 0 ? (object[key] = '') : (object[key] = object[key]);
          }
        });
      });

      excel.addSheet(report.name);
      excel.addColumns(columns);
      excel.addDataSource(currentReport);
    });

    excel.saveAs(filename);
  };

  const Footer = () => {
    return (
      <Row>
        <Col span={12} align='left'>
          <Button disabled={loaded !== props.measurables.length - 1} onClick={onExportToExcel}>
            <ImFileExcel /> &nbsp; {t('exportToExcel')}
          </Button>
        </Col>
        <Col span={12} align='right'>
          <Button type={'primary'} onClick={onCancel}>
            {t('close')}
          </Button>
        </Col>
      </Row>
    );
  };

  const Loading = () => {
    let percent = 0;

    if (loaded && props.measurables.length - 1 > 0) {
      percent = (loaded * 100) / props.measurables.length - 1;
    }

    return (
      <Row>
        <Col span={24} align='center'>
          <Card size='small' style={{ textAlign: 'center', width: '90%', padding: 40 }}>
            <Spin size='default' />
            <p>{t('loading')}</p>
            <Progress strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} percent={Math.floor(percent)} />
          </Card>
        </Col>
      </Row>
    );
  };

  const List = () => {
    if (reports.length > 0) {
      return (
        <Tabs size='small'>
          {reports.map(report => {
            return report.elements;
          })}
        </Tabs>
      );
    }
    return null;
  };

  return (
    <Modal
      title={t('windowReportTitle')}
      open={props.open || false}
      footer={<Footer />}
      width={MODAL_WIDTH}
      onCancel={onCancel}
    >
      {loaded !== props.measurables.length - 1 ? <Loading /> : <List />}
    </Modal>
  );
};

ViewResult.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  measurables: PropTypes.array.isRequired,
};

export default ViewResult;
