/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { Button, message, Modal } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import 'react-image-crop/dist/ReactCrop.css';

import { canvasPreview } from './canvasPreview';

import classNames from 'classnames';

import css from './CoverImage.module.scss';

const CoverImage = ({
  t,
  updateImage,
  errorImage,
  typeUpload,
  pushError,
  aspect = 0,
  changeImageText,
  errorSizeMessage,
  errorMaxParamsMessage,
  maxSizeInfo,
  maxParams,
  clearFileBlob,
  canDelete,
  fileBlob,
  squeeze,
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isOpen, setIsOpen] = useState(false);
  const [completedCrop, setCompletedCrop] = useState();
  const [imageObject, setImageObject] = useState({});
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 100, // % от картинки
    aspect: aspect, // 15.555 (для баннеров), соотношение высоты к ширине
  });

  const [loadImg, setLoadImg] = useState(true);

  const previewCanvasRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imageObject && previewCanvasRef.current) {
      canvasPreview(imageObject, previewCanvasRef.current, { ...completedCrop, ...squeeze });
    }
  }, [completedCrop]);

  const openFileDialog = () => {
    if (inputRef.current) {
      inputRef.current.click();
      pushError('image', false);
    }
  };

  const onChange = evt => {
    evt.preventDefault();
    const img = new Image();
    img.src = window.URL.createObjectURL(evt.target.files[0]);

    if (typeUpload.includes(evt.target.files[0].type)) {
      img.onload = function () {
        if (maxParams) {
          if (img.width > maxParams?.maxWidth && img.height > maxParams?.maxHeigth) {
            setImageObject(img);
            setIsOpen(true);
          } else {
            messageApi.error(errorMaxParamsMessage);
            pushError('image', true);
          }
        } else {
          setImageObject(img);
          setIsOpen(true);
        }
      };
    } else {
      messageApi.error(errorSizeMessage);
      pushError('image', true);
    }

    evt.target.value = null;
  };

  const onChangeCrop = crop => {
    setCrop(crop);
    setLoadImg(true);
  };

  const onComplete = (_, completeCrop) => {
    setCompletedCrop(completeCrop);
    setLoadImg(false);
  };

  const getCroppedImg = () => {
    previewCanvasRef.current.toBlob(blob => {
      if (blob) {
        updateImage(blob);
      } else {
        console.error('Ошибка создания blob');
      }
      setIsOpen(false);
    });
  };

  const resetModal = () => {
    setIsOpen(false);
  };

  const onDeleteImage = () => {
    clearFileBlob();
    setCrop({
      x: 0,
      y: 0,
      width: 100,
      aspect: aspect,
    });

    setImageObject({});
    canvasPreview(false, previewCanvasRef.current, completedCrop);
  };

  return (
    <div className={css['ImageInput']}>
      <div className={css['ImageInput-content']}>
        <div className={css['ImageBlock']}>
          <Button
            htmlFor='cover-img-file-input'
            className={`${css['ImageInput-content--button']} ${errorImage && css['error']}`}
            onClick={openFileDialog}
            type='link'
          >
            <DownloadOutlined />
            {t('loadImage')}
          </Button>

          {fileBlob && canDelete && (
            <Button
              htmlFor='cover-img-file-input'
              className={`${css['ImageInput-content--button-delete']} ${errorImage && css['error']}`}
              onClick={onDeleteImage}
              type='link'
            >
              <DeleteOutlined />
              {t('deleteImage')}
            </Button>
          )}
        </div>
      </div>
      <input type='file' ref={inputRef} id='cover-img-file-input' onChange={onChange} accept={typeUpload} />
      <Modal className={css['modal-content--closeEdit']} open={isOpen} onCancel={resetModal} footer={null}>
        <div className={css['modal-content--box']}>
          <div className={css['modal-content__title']}>{changeImageText}</div>
          {isOpen && <ReactCrop src={imageObject.src} crop={crop} onChange={onChangeCrop} onComplete={onComplete} />}

          <p className={css['modal-content__note']}>{maxSizeInfo}</p>
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              className={classNames(css['modal-content__preview'], squeeze && css['modal-content__preview-squeeze'])}
            />
          )}
          <div className={css['modal-content__footer']}>
            <Button disabled={loadImg} onClick={getCroppedImg} type='primary'>
              {t('save')}
            </Button>
          </div>
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
};

CoverImage.propTypes = {
  updateImage: PropTypes.func,
  errorImage: PropTypes.bool,
  typeUpload: PropTypes.string,
  pushError: PropTypes.func,
  aspect: PropTypes.number,
  changeImageText: PropTypes.string,
  errorSizeMessage: PropTypes.string,
  errorMaxParamsMessage: PropTypes.string,
  maxSizeInfo: PropTypes.string,
  maxParams: PropTypes.object,
  clearFileBlob: PropTypes.func,
  canDelete: PropTypes.bool,
  fileBlob: PropTypes.any,
  squeeze: PropTypes.object,
};

export default withTranslation('coverImage')(CoverImage);
