/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getStringValue } from '@/store/students/actions';

import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';
import { selectStudents } from '@/store/students/selectors';

import { DatePicker, Form, Input, Switch, TimePicker } from 'antd';
import MassSelect from '@/components/UsersModal/components/MassSelect/MassSelect';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../CustomFieldFilter.module.scss';

const { TextArea } = Input;

function Inputs({ typeCustomField, onChange, state }) {
  const { t } = useTranslation('massSelection');

  const [isFocus, setIsFocus] = useState(false);
  const [isFocusRange, setIsFocusRange] = useState(false);

  const dispatch = useDispatch();
  const students = useSelector(selectStudents);
  const { stateSettingsVisibility } = useSelector(selectSettingsVisibility);

  useEffect(() => {
    stateSettingsVisibility?.customFields?.length === 0 &&
      typeCustomField === 'TYPE_BOOLEAN' &&
      onChange('value', false, 'valueConcrete');
    typeCustomField === 'TYPE_STRING' && dispatch(getStringValue(state.customFieldId));
  }, [typeCustomField, state.customFieldId]);

  useEffect(() => {
    setIsFocus(!!state.valueConcrete || !!state.valueFrom);
    setIsFocusRange(!!state.valueTo);
  }, [state.valueConcrete, state.valueFrom, state.valueTo]);

  const render = () => {
    switch (typeCustomField) {
      case 'TYPE_BOOLEAN':
        return (
          <Form.Item>
            <Switch
              onChange={event => onChange('value', event, 'valueConcrete')}
              checked={Boolean(state.valueConcrete)}
              className={css['Inputs__checkbox']}
            />
          </Form.Item>
        );

      case 'TYPE_DATE':
        if (state.type === 'RANGE') {
          return (
            <>
              <div className={css['Inputs__range']}>
                <Form.Item className={css['Inputs__item']}>
                  <span className={css[isFocus ? 'Inputs__label-range-focus' : 'Inputs__label-range']}>
                    {t('selectDateStart')}
                  </span>
                  <DatePicker
                    placeholder=''
                    format='YYYY-MM-DD'
                    onChange={(event, value) => onChange('value', value, 'valueFrom')}
                    className={css['Inputs__date-only-date']}
                    value={state.valueFrom && dayjs(state.valueFrom, 'YYYY-MM-DD')}
                  />
                </Form.Item>
                -
                <Form.Item className={css['Inputs__item']}>
                  <span className={css[isFocusRange ? 'Inputs__label-range-focus' : 'Inputs__label-range']}>
                    {t('selectDateEnd')}
                  </span>
                  <DatePicker
                    placeholder=''
                    format='YYYY-MM-DD'
                    onChange={(event, value) => onChange('value', value, 'valueTo')}
                    className={css['Inputs__date-only-date']}
                    value={state.valueTo && dayjs(state.valueTo, 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </div>
              <Form.Item className={css['Inputs__item']}>
                <Switch
                  onChange={e => (e ? onChange('type', 'RANGE') : onChange('type', 'EQUALS'))}
                  className={css['Inputs__switch']}
                  value={state.type === 'RANGE'}
                />
                {t('range')}
              </Form.Item>
            </>
          );
        } else {
          return (
            <>
              <Form.Item className={css['Inputs__item']}>
                <span className={css[isFocus ? 'Inputs__label-focus' : 'Inputs__label']}>{t('selectDate')}</span>
                <DatePicker
                  placeholder=''
                  format='YYYY-MM-DD'
                  onChange={(event, value) => onChange('value', value, 'valueConcrete')}
                  className={css['Inputs__date']}
                  value={state.valueConcrete && dayjs(state.valueConcrete, 'YYYY-MM-DD')}
                />
              </Form.Item>
              <Form.Item className={css['Inputs__item']}>
                <Switch
                  onChange={e => (e ? onChange('type', 'RANGE') : onChange('type', 'EQUALS'))}
                  className={css['Inputs__switch']}
                  value={state.type === 'RANGE'}
                />
                {t('range')}
              </Form.Item>
            </>
          );
        }

      case 'TYPE_DATETIME':
        if (state.type === 'RANGE') {
          return (
            <>
              <div className={css['Inputs__range']}>
                <Form.Item className={css['Inputs__item']}>
                  <DatePicker
                    placeholder={t('selectTimeStart')}
                    showTime
                    format='YYYY-MM-DD HH:mm:ss'
                    onChange={(event, value) => onChange('value', value, 'valueFrom')}
                    className={classNames(state.valueFrom && css['Inputs__date-isFill'], css['Inputs__date'])}
                    value={state.valueFrom && dayjs(state.valueFrom, 'YYYY-MM-DD HH:mm:ss')}
                  />
                </Form.Item>
                -
                <Form.Item className={css['Inputs__item']}>
                  <DatePicker
                    placeholder={t('selectTimeEnd')}
                    showTime
                    format='YYYY-MM-DD HH:mm:ss'
                    onChange={(event, value) => onChange('value', value, 'valueTo')}
                    className={classNames(state.valueTo && css['Inputs__date-isFill'], css['Inputs__date'])}
                    value={state.valueTo && dayjs(state.valueTo, 'YYYY-MM-DD HH:mm:ss')}
                  />
                </Form.Item>
              </div>
              <Form.Item className={css['Inputs__item']}>
                <Switch
                  onChange={e => (e ? onChange('type', 'RANGE') : onChange('type', 'EQUALS'))}
                  className={css['Inputs__switch']}
                  value={state.type === 'RANGE'}
                />
                {t('range')}
              </Form.Item>
            </>
          );
        } else {
          return (
            <>
              <Form.Item className={css['Inputs__item']}>
                <DatePicker
                  placeholder={t('selectDate')}
                  showTime
                  format='YYYY-MM-DD HH:mm:ss'
                  onChange={(event, value) => onChange('value', value, 'valueConcrete')}
                  className={classNames(state.valueConcrete && css['Inputs__date-isFill'], css['Inputs__date'])}
                  value={state.valueConcrete && dayjs(state.valueConcrete, 'YYYY-MM-DD HH:mm:ss')}
                />
              </Form.Item>
              <Form.Item className={css['Inputs__item']}>
                <Switch
                  onChange={e => (e ? onChange('type', 'RANGE') : onChange('type', 'EQUALS'))}
                  className={css['Inputs__switch']}
                  value={state.type === 'RANGE'}
                />
                {t('range')}
              </Form.Item>
            </>
          );
        }

      case 'TYPE_TIME':
        return (
          <Form.Item className={css['Inputs__item']}>
            <TimePicker
              placeholder={t('selectTime')}
              format='HH:mm:ss'
              onChange={(event, value) => onChange('value', value, 'valueConcrete')}
              className={classNames(state.valueConcrete?.length && css['Inputs__date-isFill'], css['Inputs__date'])}
              value={state.valueConcrete && dayjs(state.valueConcrete, 'HH:mm:ss')}
            />
          </Form.Item>
        );

      case 'TYPE_STRING':
        return (
          <Form.Item className={css['Inputs__item']}>
            <MassSelect
              name='valueConcrete'
              options={students.stringValues}
              onChange={(name, value) => onChange('value', value, 'valueConcrete')}
              value={state.valueConcrete}
            />
          </Form.Item>
        );
      case 'TYPE_TEXT':
        return (
          <Form.Item>
            <span
              className={classNames(
                isFocus && css['Inputs__label-focus'],
                css['Inputs__text-area__label'],
                state.valueConcrete?.length && css['Inputs__text-area__label-show'],
                css['Inputs__label']
              )}
            >
              {t('selectText')}
            </span>
            <TextArea
              onChange={event => onChange('value', event.target.value, 'valueConcrete')}
              placeholder={t('selectText')}
              value={state.valueConcrete}
              allowClear={false}
              className={classNames(
                state.valueConcrete?.length && css['Inputs__text-area-isFill'],
                css['Inputs__text-area']
              )}
            />
          </Form.Item>
        );

      case 'TYPE_NUMERIC':
        return (
          <Form.Item className={css['Inputs__item']}>
            <span className={classNames(isFocus && css['Inputs__label-focus'], css['Inputs__label'])}>
              {t('selectNumber')}
            </span>
            <Input
              onChange={event => onChange('value', event.target.value, 'valueConcrete')}
              value={state.valueConcrete}
              className={classNames(
                isFocus && css['Inputs__input-focus'],
                state.valueConcrete?.length && css['Inputs__input-isFill'],
                css['Inputs__input']
              )}
              type='number'
            />
          </Form.Item>
        );

      default:
        return <></>;
    }
  };

  return render();
}

Inputs.propTypes = {
  typeCustomField: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Inputs;
