import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, DatePicker, Form, InputNumber, Select, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { MODS } from './constants';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import style from './style.module.scss';

const StartOfJob = ({ withLabels = true, ...props }) => {
  const { t } = useTranslation('newMassSelection');

  const [form] = Form.useForm();
  useEffect(() => {
    ['postpone', 'launchDate', 'startOfJobTo', 'plusDaysDeadline', 'plusDaysStudyPlan'].forEach(item =>
      form.setFieldValue(item, undefined)
    );
  }, [props.formValuesRef.current.mode]);

  useEffect(() => {
    if (!props.openSave) {
      form.setFieldValue('isPeriodicAssignment', props.saveFilter);
      props.formValuesRef.current = { ...props.formValuesRef.current, isPeriodicAssignment: props.saveFilter };
    }
  }, [props.openSave]);

  useEffect(() => {
    if (props.isInspectors.visible) {
      form.setFieldValue('isPeriodicAssignment', false);
      form.setFieldValue('endDate', undefined);
      form.setFieldValue('periodInMonths', undefined);
      props.formValuesRef.current = {
        ...props.formValuesRef.current,
        isPeriodicAssignment: undefined,
        endDate: undefined,
        periodInMonths: undefined,
      };
    }
  }, [props.isInspectors.visible]);

  const commonDatePickerProps = {
    getValueProps: value => ({ value: value ? dayjs(value, 'YYYY-MM-DD') : dayjs() }),
    getValueFromEvent: value => {
      if (!value) return dayjs().format('YYYY-MM-DD');
      return value.format('YYYY-MM-DD');
    },
  };

  const commonDateEndProps = {
    getValueProps: value => ({ value: value ? dayjs(value, 'YYYY-MM-DD') : undefined }),
    getValueFromEvent: value => (value ? value.format('YYYY-MM-DD') : undefined),
  };

  const commonInputNumberProps = {
    className: style['start-of-job__element'],
    min: 0,
  };

  const renderMode = mode => {
    switch (mode) {
      case 3:
        return (
          <>
            <div className={style['start-of-job__box']}>
              <Form.Item
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectDeadlineLabel') : ''}
                name='concreteDate'
                {...commonDatePickerProps}
              >
                <DatePicker placeholder={t('selectDeadlinePlaceholder')} className={style['start-of-job__element']} />
              </Form.Item>
              <Form.Item className={style['start-of-job__form-item']} name='postpone' valuePropName='checked' label=' '>
                <div className={style['start-of-job__checkbox']}>
                  <Checkbox />
                  <span className={style['start-of-job__label']}>{t('postponement')}</span>
                  <Tooltip title={t('postponementTooltip')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
              </Form.Item>
            </div>
            <div className={style['start-of-job__box']}>
              <Form.Item
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectCountDayLabel.0') : ''}
                name='plusDaysDeadline'
              >
                <InputNumber placeholder={t('selectCountDayPlaceholder.0')} {...commonInputNumberProps} />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.postpone !== currentValues.postpone}
              >
                {() => {
                  return form.getFieldValue('postpone') ? (
                    <div className={style['start-of-job__box']}>
                      <Form.Item
                        className={style['start-of-job__form-item']}
                        label={withLabels ? t('selectPlanDateLabel') : ''}
                        name='launchDate'
                        {...commonDatePickerProps}
                      >
                        <DatePicker
                          placeholder={t('selectPlanDatePlaceholder')}
                          className={style['start-of-job__element']}
                        />
                      </Form.Item>
                    </div>
                  ) : null;
                }}
              </Form.Item>
            </div>
          </>
        );

      case 1:
        return (
          <>
            <div className={style['start-of-job__box']}>
              <Form.Item
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectOutDateLabel') : ''}
                name='startOfJobFrom'
                {...commonDatePickerProps}
              >
                <DatePicker placeholder={t('selectOutDatePlaceholder')} className={style['start-of-job__element']} />
              </Form.Item>
            </div>
            <div className={style['start-of-job__box']}>
              <Form.Item
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectCountDayLabel.0') : ''}
                name='plusDaysDeadline'
              >
                <InputNumber placeholder={t('selectCountDayPlaceholder.0')} {...commonInputNumberProps} />
              </Form.Item>
              <Form.Item
                tooltip={t('countDayTooltip')}
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectCountDayLabel.1') : ''}
                name='plusDaysStudyPlan'
              >
                <InputNumber placeholder={t('selectCountDayPlaceholder.1')} {...commonInputNumberProps} />
              </Form.Item>
            </div>
          </>
        );

      case 2:
      case 4:
        return (
          <>
            <div className={style['start-of-job__box']}>
              <Form.Item
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectStartLabel') : ''}
                name='startOfJobFrom'
                {...commonDatePickerProps}
              >
                <DatePicker placeholder={t('selectStartPlaceholder')} className={style['start-of-job__element']} />
              </Form.Item>
              <Form.Item
                className={style['start-of-job__form-item']}
                label={withLabels ? t('selectEndLabel') : ''}
                name='startOfJobTo'
                {...commonDateEndProps}
              >
                <DatePicker placeholder={t('selectEndPlaceholder')} className={style['start-of-job__element']} />
              </Form.Item>
            </div>
            <div className={style['start-of-job__box']}>
              <Form.Item
                className={style['start-of-job__form-item']}
                name='plusDaysDeadline'
                label={withLabels ? t('selectCountDayLabel.0') : ''}
              >
                <InputNumber placeholder={t('selectCountDayPlaceholder.0')} {...commonInputNumberProps} />
              </Form.Item>
              <Form.Item
                tooltip={t('countDayTooltip')}
                className={style['start-of-job__form-item']}
                name='plusDaysStudyPlan'
                label={withLabels ? t('selectCountDayLabel.1') : ''}
              >
                <InputNumber placeholder={t('selectCountDayPlaceholder.1')} {...commonInputNumberProps} />
              </Form.Item>
            </div>
          </>
        );
    }
  };

  const periodicAssignmentRender = (
    <>
      <div className={style['start-of-job__box']}>
        <div className={style['start-of-job__switch']}>
          <Form.Item
            className={style['start-of-job__form-item']}
            name='isPeriodicAssignment'
            valuePropName='checked'
            label={withLabels ? t('internal') : ' '}
          >
            <Switch />
          </Form.Item>
        </div>
      </div>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.isPeriodicAssignment !== currentValues.isPeriodicAssignment
        }
      >
        {() => {
          return form.getFieldValue('isPeriodicAssignment') ? (
            <>
              <div className={style['start-of-job__box']}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.plusDaysDeadline !== currentValues.plusDaysDeadline
                  }
                >
                  {() => {
                    //new Периодические
                    const minPeriodicMounth = form.getFieldValue('plusDaysStudyPlan')
                      ? parseInt(
                          (form.getFieldValue('plusDaysDeadline') + form.getFieldValue('plusDaysStudyPlan')) / 30
                        )
                      : parseInt(form.getFieldValue('plusDaysDeadline') / 30);

                    return (
                      <div className={style['start-of-job__form-item-period']}>
                        <Form.Item
                          className={style['start-of-job__form-item']}
                          label={withLabels ? t('selectMonthsInterval') : ''}
                          name='periodInMonths'
                        >
                          <InputNumber
                            className={style['start-of-job__element']}
                            min={minPeriodicMounth ? minPeriodicMounth + 1 : 0} // new добавил мин значение
                            max={300}
                          />
                        </Form.Item>
                        <span>{t('periodInMonthsValidation', { date: minPeriodicMounth || 0 })}</span>
                      </div>
                    );
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.periodInMonths !== currentValues.periodInMonths
                  }
                >
                  {() => {
                    const date = dayjs().add((form.getFieldValue('periodInMonths') || 0) * 30, 'day');

                    return (
                      <div className={style['start-of-job__form-item-period']}>
                        <Form.Item
                          className={style['start-of-job__form-item']}
                          label={withLabels ? t('selectDateInterval') : ''}
                          name='endDate'
                          {...commonDateEndProps}
                        >
                          <DatePicker
                            className={style['start-of-job__element']}
                            format={'YYYY-MM-DD'}
                            disabledDate={current => dayjs().subtract(1, 'day') >= current}
                            minDate={date} // new добавил мин значение
                          />
                        </Form.Item>
                        <span>{t('periodDateValidation', { date: date.format('DD.MM.YYYY') })}</span>
                      </div>
                    );
                  }}
                </Form.Item>
              </div>
              {props.isError && <div className={style['start-of-job__form-item-error']}>{t('errorInterval')}</div>}
            </>
          ) : null;
        }}
      </Form.Item>
    </>
  );

  return (
    <Form
      layout='vertical'
      onValuesChange={props.onValuesChange}
      initialValues={{ mode: 3, concreteDate: dayjs().format('YYYY-MM-DD') }}
      form={form}
      className={style['start-of-job']}
    >
      <Form.Item name='mode' className={style['start-of-job__form-item']}>
        <Select options={MODS.map(mode => ({ value: mode.value, label: t(`mode.${mode.labelPosition}`) }))} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.mode !== currentValues.mode}>
        {() => renderMode(form.getFieldValue('mode'))}
      </Form.Item>
      {props.isTopic && periodicAssignmentRender}
    </Form>
  );
};

StartOfJob.propTypes = {
  onValuesChange: PropTypes.func,
};

export default StartOfJob;
