/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getChapters } from '@/store/chapters/actions';
import {
  createTypeform,
  deleteTypeform,
  exportTypeform,
  getTypeform,
  getTypeforms,
  grabTypeformAnswers,
  updateTypeform,
} from '@/store/typeform/actions';

import { selectChapters } from '@/store/chapters/selectors';
import { selectTypeform } from '@/store/typeform/selectors';

import { Button, Dropdown, Form, Input, Tooltip } from 'antd';
import { EllipsisOutlined, FileDoneOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';
import TypeformTable from './TypeformTable/TypeformTable';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../style/blocksStyle.module.scss';

import {
  EXPORT_TYPEFORM_BLOCK_RESULTS,
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';

const ButtonGroup = Button.Group;

/**
 * Отображение блока тайп-формы.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const Typeform = props => {
  const typeFormID = props.blocks.filter(item => item.type.id === 6).map(item => item.id);
  const { t } = useTranslation('typeform');
  /**
   * Идентификатор тайп-формы.
   */
  const [id, setId] = useState(null);

  /**
   * Ссылка на тайп-форму.
   */
  const [link, setLink] = useState('');

  //  Старая ссылка на тайп-форму
  const [oldLink, setOldLink] = useState('');

  const [loading, setLoading] = useState(false);
  /**
   * Отслеживаем состояние props.data.
   */
  useEffect(() => {
    if (props.data && _.has(props.data, 'id')) {
      props.getTypeforms({
        blockIds: typeFormID,
        size: typeFormID.length,
        page: 0,
      });
    }
  }, [props.data, props.getTypeforms]);

  /**
   * Отслеживаем состояние props.typeform.
   */
  useEffect(() => {
    if (Array.isArray(props.typeform)) {
      for (let current of props.typeform) {
        if (props.data && _.has(props.data, 'id') && _.has(current, 'blockId')) {
          if (props.data.id === current.blockId) {
            setId(current.id);
            setLink(current.link);
            setOldLink(current.link);
            break;
          }
        }
      }
    }
  }, [props.data, props.typeform, props.getChapters]);

  /**
   * Создаем тайп-форму.
   */
  const create = () => {
    props.createTypeform(props.data.id, props.data.companyId, link, props.data.name, res => {
      props.getChapters(props.data.topicId);
    });
  };

  /**
   * Обновляем тайп-форму.
   */
  const update = () => {
    props.updateTypeform(props.data.id, props.data.companyId, id, link, props.data.name, res => {
      props.getChapters(props.data.topicId);
    });
  };

  // Сохранение изменений
  const saveButtonHandler = () => {
    if (props.canEdit) {
      !id ? create() : update();
    }
  };

  const handleEditClick = () => {
    props.editBlock(props.data.id);
  };

  const handleDeleteClick = () => {
    if (props.data.id) {
      props.deleteBlock(props.data.id);
    }
  };

  // Забрать данные с typeform в нашу db
  const onGrabClick = () => {
    if (id) {
      setLoading(true);
      props.grabTypeformAnswers(
        id,
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  // Сохранение CSV файла
  const onDownloadClick = () => {
    props.exportTypeform(id, props.data.name, blob => {
      let url = window.URL.createObjectURL(blob);
      let anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = props.data.name;
      anchor.click();
    });
  };

  // Проверка ссылки
  const correctLink = () => {
    if (link[link.length - 1] === '/' || !link || oldLink === link) {
      return true;
    } else {
      return false;
    }
  };

  const dropDownMenu = [
    ...(props.currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (props.topicTypeId === 25 && props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: <div onClick={handleEditClick}>{t('edit')}</div>,
          },
        ]
      : []),
    ...(props.currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (props.topicTypeId === 25 && props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: <div onClick={handleDeleteClick}>{t('remove')}</div>,
          },
        ]
      : []),
  ];

  const header = useMemo(
    () => (
      <div className={css['Block-header']}>
        <FileDoneOutlined className={css['Block-header-item']} />
        <div className={css['Block-header-name']}>
          <Tooltip title={props.data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(props.data.name, 55)}
          </Tooltip>
        </div>
      </div>
    ),
    [props.data.name]
  );

  const extra = useMemo(() => (
    <div onClick={e => e.stopPropagation()}>
      <Button
        className={css['Block-header-save']}
        type='primary'
        disabled={!props.canEdit || correctLink()}
        onClick={saveButtonHandler}
      >
        {t('save')}
      </Button>
      {(props.currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
        props.currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
        (props.topicTypeId === 25 &&
          (props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
            props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
        <Dropdown menu={{ items: dropDownMenu }} placement='bottomRight' trigger={['click']}>
          <EllipsisOutlined className={css.More} />
        </Dropdown>
      )}
    </div>
  ));

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.Block}>
        <CollapseLMS
          className={css['Block-layout']}
          header={header}
          data={props.data}
          extra={extra}
          key={props.data.id}
          forceClose={props.forceClose}
        >
          <Form className={css['Block-typeform']}>
            <Form.Item label={t('typeformLink')} className={css['Block-typeform']}>
              <Input value={link} placeholder={t('link')} onChange={event => setLink(event.target.value)} />
            </Form.Item>
            <div className={css['Block-buttonGroup']}>
              <ButtonGroup>
                <Tooltip title={t('warning')}>
                  <Button loading={loading} type='primary' onClick={onGrabClick}>
                    {t('grabData')}
                  </Button>
                </Tooltip>
                {props.currentUser.authorities.includes(EXPORT_TYPEFORM_BLOCK_RESULTS) && (
                  <Button onClick={onDownloadClick}>{t('download')}</Button>
                )}
              </ButtonGroup>
            </div>
          </Form>
          <TypeformTable typeformId={id} loadingTable={loading} />
        </CollapseLMS>
      </div>
    );
  };

  return render();
};

/**
 * Типизация свойств компонента.
 */
Typeform.propTypes = {
  getTypeforms: PropTypes.func.isRequired,
  getTypeform: PropTypes.func.isRequired,
  updateTypeform: PropTypes.func.isRequired,
  grabTypeformAnswers: PropTypes.func.isRequired,
  exportTypeform: PropTypes.func.isRequired,
  createTypeform: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  getChapters: PropTypes.func.isRequired,
  deleteBlock: PropTypes.func.isRequired,
  editBlock: PropTypes.func.isRequired,
  deleteTypeform: PropTypes.func.isRequired,
  forceClose: PropTypes.bool.isRequired,
};

/**
 * Состояния в редаксе.
 */
const mapStateToProps = createSelector([selectTypeform, selectChapters], (typeforms, chapters) => ({
  loading: typeforms.loading,
  typeform: typeforms.typeforms,
  chapters: chapters.chapters,
}));

/**
 * Экшены редакса.
 */
const mapActionsToProps = {
  getTypeforms,
  getTypeform,
  updateTypeform,
  grabTypeformAnswers,
  exportTypeform,
  createTypeform,
  getChapters,
  deleteTypeform,
};

/**
 * Экспорт компонента.
 */
export default connect(mapStateToProps, mapActionsToProps)(Typeform);
