/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectBlocks } from '@/store/lms/selectors';

import { Button, Form, Input, Modal, Select } from 'antd';

import { CHECKLIST } from '@shared/constants/block-type';
import { isSkills } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

const { Option } = Select;

class AddBlockDialog extends Component {
  static propTypes = {
    onCreate: PropTypes.func,
    onClose: PropTypes.func,
    block: PropTypes.object,
    topicId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    blockTypes: PropTypes.array,
    topicTypeId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: _get(props, 'block.name', ''),
      typeId: _get(props, 'block.type.id', props.blockTypes[0]?.id),
      dirty: false,
    };
  }

  handleChange = (action, value) => {
    switch (action) {
      case 'name':
        this.setState({ name: value });
        break;
      case 'typeId':
        this.setState({ typeId: value });
        break;
      default:
        return;
    }
  };

  handleSubmit = () => {
    const { name, typeId } = this.state;
    const { block, topicId, t } = this.props;
    this.setState({
      nameError: '',
      typeIdError: '',
    });
    if (name && typeId) {
      this.setState({ dirty: true });
      this.props.onCreate({ ...block, name: name.trim(), typeId, topicId });
    } else {
      !name && this.setState({ nameError: t('required') });
      !typeId && this.setState({ typeIdError: t('required') });
    }
  };

  renderOpt = arr => {
    if (Array.isArray(arr) && arr.length > 0) {
      return arr.map(item => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ));
    }
  };

  render() {
    const { blockTypes, onClose, t, topicTypeId } = this.props;

    const filteredBlockTypes = blockTypes.filter(i => {
      if (!isSkills(topicTypeId) && i.id === CHECKLIST) {
        return false;
      }
      return true;
    });

    return (
      <Modal open title={!this.props.block ? t('addBlock') : t('editBlock')} onCancel={onClose} footer={null}>
        <div style={{ padding: '0', boxShadow: 'none' }}>
          <div style={{ marginBottom: '16px' }}>{t('title')}</div>
          <Form fields={[{ name: ['blockName'], value: this.state.name }]}>
            <Form.Item
              name='blockName'
              className={classNames({ error: this.state.nameError })}
              rules={[{ required: true, message: t('required') }]}
            >
              <Input
                data-qa='addBlockInput'
                maxLength={255}
                value={this.state.name}
                onChange={event => this.handleChange('name', event.target.value)}
              />
            </Form.Item>
            <Form.Item className={classNames({ error: this.state.typeIdError })} help={this.state.typeIdError}>
              <Select
                data-qa='addBlockSelect'
                dropdownRender={optionElements => <span data-qa='addBlockSelect-list'>{optionElements}</span>}
                placeholder={t('type')}
                onChange={value => this.handleChange('typeId', value)}
                disabled={this.props.block}
                value={this.state.typeId}
              >
                {this.renderOpt(filteredBlockTypes)}
              </Select>
            </Form.Item>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                data-qa='addBlockSubmitBtn'
                onClick={this.handleSubmit}
                htmlType='submit'
                loading={this.props.isLoading}
              >
                {!this.props.block ? t('add') : t('edit')}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([selectBlocks], blocks => ({
  isLoading: blocks.isLoading,
}));

export default connect(mapStateToProps)(withTranslation('addBlockDialog')(AddBlockDialog));
