/* eslint-disable */
import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Layout.module.scss';

function CustomCard({ className, children, ...props }) {
  return (
    <div className={classNames(css.Card, className)} {...props}>
      {children}
    </div>
  );
}

CustomCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default CustomCard;
