/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_AUTHORS_START = 'GET_AUTHORS_START';
export const GET_AUTHORS_SUCCESS = 'GET_AUTHORS_SUCCESS';
export const GET_AUTHORS_FAIL = 'GET_AUTHORS_FAIL';
export const GET_GLOBAL_AUTHORS_START = 'GET_GLOBAL_AUTHORS_START';
export const GET_GLOBAL_AUTHORS_SUCCESS = 'GET_GLOBAL_AUTHORS_SUCCESS';
export const GET_GLOBAL_AUTHORS_FAIL = 'GET_GLOBAL_AUTHORS_FAIL';
export const CREATE_AUTHORS_START = 'CREATE_AUTHORS_START';
export const CREATE_AUTHORS_SUCCESS = 'CREATE_AUTHORS_SUCCESS';
export const CREATE_AUTHORS_FAIL = 'CREATE_AUTHORS_FAIL';
export const DELETE_AUTHORS_START = 'DELETE_AUTHORS_START';
export const DELETE_AUTHORS_SUCCESS = 'DELETE_AUTHORS_SUCCESS';
export const DELETE_AUTHORS_FAIL = 'DELETE_AUTHORS_FAIL';
export const EDIT_AUTHORS_START = 'EDIT_AUTHORS_START';
export const EDIT_AUTHORS_SUCCESS = 'EDIT_AUTHORS_SUCCESS';
export const EDIT_AUTHORS_FAIL = 'EDIT_AUTHORS_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getAuthors = (name = '', page = 0, onSuccess, isGlobal = false) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/author${getQueryString({
    name: name && name.toLowerCase(),
    isGlobal,
    isSharing: isGlobal ? '' : false,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_AUTHORS_START, GET_AUTHORS_SUCCESS, GET_AUTHORS_FAIL],
  onSuccess,
});

export const getAuthorsGlobal = (name = '', page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/author${getQueryString({
    name: name && name.toLowerCase(),
    isGlobal: true,
    isSharing: true,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GLOBAL_AUTHORS_START, GET_GLOBAL_AUTHORS_SUCCESS, GET_GLOBAL_AUTHORS_FAIL],
  onSuccess,
});

export const createAuthor = (name = '', onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/author`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
  },
  onSuccess,
  onError,
  types: [CREATE_AUTHORS_START, CREATE_AUTHORS_SUCCESS, CREATE_AUTHORS_FAIL],
});

export const deleteAuthor = (id, { onSuccess, onError }) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/author${getQueryString({ id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_AUTHORS_START, DELETE_AUTHORS_SUCCESS, DELETE_AUTHORS_FAIL],
});

export const editAuthor = (name, id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/author`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id,
    name,
  },
  onSuccess,
  types: [EDIT_AUTHORS_START, EDIT_AUTHORS_SUCCESS, EDIT_AUTHORS_FAIL],
});
