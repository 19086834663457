/* eslint-disable */
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Button, Checkbox, Collapse, Form, Input, Tooltip } from 'antd';
import ImageInput from '@/components/ImageInputCrop';
import Select from '@/components/Select';
import { CloseOutlined, DeleteOutlined, HolderOutlined } from '@ant-design/icons';
import { AiOutlineCopy } from 'react-icons/ai';

import {
  MATCHING,
  MATCHING_ID,
  MAX_MATCH_TEXT_LEN,
  MAX_MATCHING_ANSWERS,
  MIN_MATCHING_ANSWERS,
  MULTIPLE,
  MULTIPLE_ID,
  OPEN,
  OPEN_ID,
  SINGLE,
} from '../Test/helpers';

import Utils from '@/Utils';
import classNames from 'classnames';

import css from './Question.module.scss';

const { TextArea } = Input;

export const Question = ({
  data,
  index,
  changeQuestionValue,
  changeAnswerValue,
  addAnswer,
  deleteQuestion,
  files,
  handleImageChange,
  handleDeleteAnswer,
  forceClose,
  keepOpen,
  canEdit,
  changeOpenQuestion,
  withoutCollapse,
  handleAddToBank,
  isCanAddToBank,
  questionBank, // Если вызов из банка вопросов на редактировании, то true
}) => {
  const { t } = useTranslation('question');
  const { t: tTest } = useTranslation('test');
  const [open, setOpen] = useState(keepOpen ? [data.id] : []);

  const header = useMemo(
    () => (
      <div className={css['Question-header']}>
        <div className={css['Question-dragIcon']}>
          <HolderOutlined />
        </div>
        <div>{`${t('question')} ${index + 1}`}</div>
      </div>
    ),
    [index]
  );

  const optionsTypesQuestion = [
    { name: t('single'), value: SINGLE, id: SINGLE },
    { name: t('multiple'), value: MULTIPLE, id: MULTIPLE },
    { name: t('open'), value: OPEN, id: OPEN },
    { name: t('matching'), value: MATCHING, id: MATCHING },
  ];

  const valueSelectTypeQuestion = ({ type, multipleAnswers }) => {
    switch (type) {
      case MULTIPLE_ID:
        if (multipleAnswers) return MULTIPLE;
        return SINGLE;

      case OPEN_ID:
        return OPEN;

      case MATCHING_ID:
        return MATCHING;
    }
  };

  const isOpenAnswer = data.type === OPEN_ID;
  const isMatchingQuestion = data.type === MATCHING_ID;

  const handleDelete = e => {
    e.stopPropagation();
    deleteQuestion(index);
  };

  const handleOpenAddToBank = e => {
    e.stopPropagation();
    handleAddToBank(data.id);
  };

  const handleCollapseChange = key => {
    Array.isArray(key) && setOpen(open.length ? [] : key);
  };

  const extra = useMemo(() => {
    const isNewAnswer = typeof data.id === 'string';

    return (
      <>
        {/* Кнопка добавления в банк вопросов */}
        {!data?.inBank && (
          <Tooltip title={isCanAddToBank && !isNewAnswer ? t('addToQuestionBank') : t('saveToAdd')}>
            <AiOutlineCopy
              className={classNames(
                css['Question-addToBankIcon'],
                (!isCanAddToBank || isNewAnswer) && css['Question-addToBankIcon-disabled']
              )}
              onClick={isCanAddToBank && !isNewAnswer ? handleOpenAddToBank : () => {}}
            />
          </Tooltip>
        )}
        {/* Удаление вопроса */}
        {canEdit && <DeleteOutlined className={css['Question-deleteIcon']} id={index} onClick={handleDelete} />}
      </>
    );
  }, [index, canEdit, deleteQuestion, data?.inBank, isCanAddToBank]);

  const questionHandler = useCallback(
    event => {
      if (event.target && !event.target.value.includes(';')) {
        changeQuestionValue(event, index);
      } else if (!event.target) {
        changeQuestionValue(event, index);
      }
    },
    [changeQuestionValue, index]
  );

  const addAnswerHandler = useCallback((isMatching = false) => addAnswer(index, isMatching), [addAnswer, index]);

  const matchingAnswersList = data.matchingAnswers?.sort((a, b) => a.position - b.position);

  const answerBlock = (value, answerIndex) => (
    <div className={css['Question-layoutQuestion-questionLayout']} key={answerIndex}>
      <div className={css['Question-layoutQuestion-questionBlock']}>
        <Form.Item label={`${t('answer')} ${answerIndex + 1}`}>
          <div className={css['Question-layoutQuestion-topicBlock']}>
            <TextArea
              autoSize={{ minRows: 2 }}
              name='answer'
              data-qa={`answerInput-${answerIndex}`}
              placeholder={t('text')}
              className={css['Question-textarea']}
              value={value.answer}
              maxLength={10000}
              onChange={event => changeAnswerValue({ event, index, indexAnswer: answerIndex })}
              disabled={!canEdit || data?.inBank}
            />
            {data.answers.length > 1 && canEdit && !data?.inBank && (
              <Button
                className={classNames(
                  css['Question-layoutQuestion-deleteButton'],
                  withoutCollapse && css['Question-layoutQuestion-deleteButton_alt']
                )}
                onClick={() => handleDeleteAnswer(index, answerIndex)}
                type='link'
              >
                <CloseOutlined className={css['Question-layoutQuestion-xMark']} type='close' id={index} />
                <span className={css['Question-span']}>&nbsp;{t('delete')}</span>
              </Button>
            )}
          </div>
          <div className={css['Question-imageBlock']}>
            <ImageInput
              isAspectOption
              short
              reverse
              company
              id='imgUuid'
              name='imgUuid'
              label={t('addImage')}
              onChange={(formData, name, defFile, image, color) =>
                handleImageChange(formData, name, defFile, image, color, index, answerIndex)
              }
              imageSrc={value.image || ''}
              fileInfo={files.info}
              isLoadingFileImage={files.isLoadingImage}
              disabled={!canEdit || data?.inBank}
              isQuestion={true}
            />
            <div className={css['Question-checkBox']}>
              <Checkbox
                data-qa='correctAnswerCheckbox'
                name='correct'
                checked={value.correct}
                onChange={event => changeAnswerValue({ event, index, indexAnswer: answerIndex })}
                disabled={!canEdit || data?.inBank}
              />
              {t('rightAnswer')}
            </div>
          </div>
        </Form.Item>
      </div>
    </div>
  );

  const mathchingAnswers = (value, answerIndex) => {
    return (
      <div className={css['Question-layoutQuestion-questionLayout-matching']} key={answerIndex}>
        <p>{t('matchingPair', { number: answerIndex + 1 })}</p>
        <div className={css['Question-layoutQuestion-questionBlock']}>
          <Form.Item label={t('item', { number: answerIndex + 1 })}>
            <div className={css['Question-layoutQuestion-topicBlock']}>
              <TextArea
                autoSize={{ minRows: 2 }}
                name='answer-left'
                data-qa={`answerInput-left-${answerIndex}`}
                placeholder={t('text')}
                className={css['Question-textarea']}
                value={value.left?.isText ? value.left?.content : ''}
                maxLength={MAX_MATCH_TEXT_LEN}
                onChange={event => changeAnswerValue({ event, index, indexAnswer: answerIndex })}
                disabled={!canEdit || !value.left?.isText || data?.inBank}
              />
              {data.matchingAnswers?.length > MIN_MATCHING_ANSWERS && canEdit && !data?.inBank && (
                <Button
                  type='link'
                  className={css['Question-layoutQuestion-deleteButton']}
                  onClick={() => handleDeleteAnswer(index, answerIndex)}
                >
                  <CloseOutlined className={css['Question-layoutQuestion-xMark']} type='close' id={index} />
                  <span className={css['Question-span']}>&nbsp;{t('delete')}</span>
                </Button>
              )}
            </div>
            <div className={css['Question-imageBlock']}>
              <ImageInput
                isAspectOption
                short
                reverse
                company
                id={`imgUuid-left-${index}`}
                name='imgUuid-left'
                label={t('addImage')}
                onChange={(formData, name, defFile, image, color) =>
                  handleImageChange(formData, name, defFile, image, color, index, answerIndex)
                }
                imageSrc={value.left && !value.left.isText ? value.left.image || '' : ''}
                fileInfo={files.info}
                isLoadingFileImage={files.isLoadingImage}
                disabled={!canEdit || data?.inBank}
                isQuestion={true}
              />
            </div>
          </Form.Item>
          <Form.Item label={t('compliance', { number: answerIndex + 1 })}>
            <div className={css['Question-layoutQuestion-topicBlock']}>
              <TextArea
                autoSize={{ minRows: 2 }}
                name='answer-right'
                data-qa={`answerInput-right-${answerIndex}`}
                placeholder={t('text')}
                className={css['Question-textarea']}
                value={value.right?.isText ? value.right?.content : ''}
                maxLength={MAX_MATCH_TEXT_LEN}
                onChange={event => changeAnswerValue({ event, index, indexAnswer: answerIndex })}
                disabled={!canEdit || !value.right?.isText || data?.inBank}
              />
            </div>
            <div className={css['Question-imageBlock']}>
              <ImageInput
                isAspectOption
                short
                reverse
                company
                id={`imgUuid-right-${index}`}
                name='imgUuid-right'
                label={t('addImage')}
                onChange={(formData, name, defFile, image, color) =>
                  handleImageChange(formData, name, defFile, image, color, index, answerIndex)
                }
                imageSrc={value.right && !value.right.isText ? value.right.image || '' : ''}
                fileInfo={files.info}
                isLoadingFileImage={files.isLoadingImage}
                disabled={!canEdit || data?.inBank}
                isQuestion={true}
              />
            </div>
          </Form.Item>
        </div>
      </div>
    );
  };

  const answerSortList = questionBank ? data.answers?.sort((a, b) => a.position - b.position) : data.answers;

  const questionMaxLength = questionBank ? 2500 : 10000;

  const content = (
    <>
      <div className={css['Question-layoutQuestion']}>
        {data?.inBank && (
          <Alert className={css['Question-layoutQuestion-alert']} showIcon description={t('alert')} type='warning' />
        )}
        <div className={css['Question-layoutQuestion-innerBlock']}>
          <Form.Item label={t('responseType')}>
            <Select
              dataQa='responseTypeDropDown'
              name='answerType'
              options={[...optionsTypesQuestion]}
              value={valueSelectTypeQuestion(data)}
              onChange={questionHandler}
              disabled={!canEdit || data?.inBank}
              white
            />
          </Form.Item>
        </div>
        <div className={css['Question-layoutQuestion-questionBlock']}>
          <Form.Item label={t('question')}>
            <TextArea
              data-qa='questionTextInput'
              autoSize={{ minRows: 2 }}
              name='question'
              placeholder={t('questionText')}
              className={css['Question-textarea']}
              value={data.question}
              onChange={questionHandler}
              maxLength={questionMaxLength}
              disabled={!canEdit || data?.inBank}
            />
            <div className={css['Question-imageBlock']}>
              <ImageInput
                isAspectOption
                reverse
                company
                id='imgUuid'
                name='imgUuid'
                label={t('addImage')}
                onChange={(formData, name, defFile, image, color) =>
                  handleImageChange(formData, name, defFile, image, color, index)
                }
                imageSrc={data.image || ''}
                fileInfo={files.info}
                isLoadingFileImage={files.isLoadingImage}
                disabled={!canEdit || data?.inBank}
                isQuestion={true}
              />
            </div>
          </Form.Item>
        </div>
        {isOpenAnswer && (
          <div className={css['Question-layoutQuestion-questionBlock']}>
            <Form.Item>
              <div className={css['Question-checkBox']}>
                <Checkbox
                  checked={data.hasAttachment || false}
                  onChange={event => changeOpenQuestion({ value: event.target.checked, name: 'hasAttachment' }, index)}
                  disabled={!canEdit || data?.inBank}
                >
                  {tTest('hasAttachment')}
                </Checkbox>
              </div>
            </Form.Item>
          </div>
        )}
        {!isOpenAnswer &&
          !isMatchingQuestion &&
          answerSortList?.map((value, answerIndex) => answerBlock(value, answerIndex))}

        {isMatchingQuestion && matchingAnswersList?.map((value, answerIndex) => mathchingAnswers(value, answerIndex))}
      </div>

      {!isOpenAnswer && !isMatchingQuestion && canEdit && data.answers.length < 1024 && !data?.inBank && (
        <div className={css['Question-addQuestion']}>
          <Button type='link' onClick={() => addAnswerHandler()}>
            {t('addAnswer')}
          </Button>
        </div>
      )}

      {isMatchingQuestion && data.matchingAnswers?.length < MAX_MATCHING_ANSWERS && !data?.inBank && canEdit && (
        <div className={css['Question-addQuestion']}>
          <Button type='link' onClick={() => addAnswerHandler(isMatchingQuestion)}>
            {t('addPair')}
          </Button>
        </div>
      )}
    </>
  );

  const collapseItems = [
    {
      key: String(data.id),
      label: header,
      extra: extra,
      children: content,
      onChange: handleCollapseChange,
    },
  ];

  return (
    <div className={css.Question}>
      {!withoutCollapse ? (
        <Collapse
          expandIconPosition='end'
          activeKey={open}
          onChange={handleCollapseChange}
          items={collapseItems}
          expandIcon={panelProps => {
            const ExpandIcon = Utils.changeActiveStatusCollapse(panelProps);
            return <ExpandIcon />;
          }}
        />
      ) : (
        content
      )}
    </div>
  );
};
