/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Form, Tooltip } from 'antd';
import { Card } from '@/components/Layout';
import Modal from '@/components/Modal';

import { isConfOrAllCourse } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../Topics/TopicCreationDialog/TopicCreationDialog.module.scss';

export class EventInfoModal extends Component {
  static propTypes = {
    event: PropTypes.object,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    open: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { ...event } = props.event;
    this.state = {
      ...event,
      periods: event.periods,
      name: event.name,
      count: event.participantsCount,
      comment: event.comment,
      speakers: event.speakers || [],
    };
  }

  render() {
    const correctPeriods = this.state.periods.reduce((acc, period) => {
      if (Array.isArray(period.dates)) {
        period.dates.forEach(date => acc.push({ ...period, date }));
      }
      return acc;
    }, []);

    const eventRow = correctPeriods.map((period, index) => (
      <tr key={index}>
        <td>{dayjs(period.date).format('DD.MM.YYYY')}</td>
        <td>{dayjs(period.startTime, 'hh:mm:ss').format('HH:mm')}</td>
        <td>{dayjs(period.endTime, 'hh:mm:ss').format('HH:mm')}</td>
        <td>{period.duration}</td>
        <td>
          {period.place ? (
            <span>
              {period.place.length > 90 ? (
                <Tooltip title={period.place}>{`${period.place.substring(0, 90)}...`}</Tooltip>
              ) : (
                period.place
              )}
            </span>
          ) : (
            this.props.t('notSpecified')
          )}
        </td>
      </tr>
    ));

    const speakersList =
      this.state?.speakers.length > 0
        ? this.state?.speakers?.map(option => option.name).join(', ')
        : this.props.t('absent');

    return (
      <Modal
        open={this.props.open}
        hideFooter
        editable
        onEdit={() => this.props.onEdit(this.props.event)}
        onCancel={this.props.onCancel}
        width={1000}
      >
        <Form className={css['Add-modal-form']}>
          <Card className={css['Add-modal']}>
            <div className={css['Add-modal-header']}>{this.state.name}</div>
            <Form.Item label={this.props.t('count')}>{this.state.count}</Form.Item>
            <Form.Item label={this.props.t('organizer')}>
              {this.state.manager ? (
                this.state.manager.name
              ) : (
                <span className={css.noManager}>{this.props.t('notSpecified2')}</span>
              )}
            </Form.Item>
            <Form.Item label={this.props.t('comment')}>
              {this.state.comment ? this.state.comment : this.props.t('absent')}
            </Form.Item>
            {isConfOrAllCourse(this.props?.event?.topic.type.id) && (
              <Form.Item label={this.props.t('speakers')}>{speakersList}</Form.Item>
            )}
            <Form.Item label={this.props.t('schedule')} />
            <table className={css['EventInfo-table']}>
              <thead className={css['EventInfo-table-head']}>
                <tr>
                  <th>{this.props.t('date')}</th>
                  <th>{this.props.t('start')}</th>
                  <th>{this.props.t('end')}</th>
                  <th>{this.props.t('duration')}</th>
                  <th>{this.props.t('location')}</th>
                </tr>
              </thead>
              <tbody className={css['EventInfo-table-body']}>{eventRow}</tbody>
            </table>
          </Card>
        </Form>
      </Modal>
    );
  }
}

export default connect()(withTranslation('eventInfoModal')(EventInfoModal));
