/* eslint-disable */
import {
  ADD_TRACK_SKILLS,
  CLEAR_TRACK_SKILLS,
  CREATE_SKILLS_FAIL,
  CREATE_SKILLS_START,
  CREATE_SKILLS_SUCCESS,
  DELETE_SKILLS_FAIL,
  DELETE_SKILLS_START,
  DELETE_SKILLS_SUCCESS,
  EDIT_SKILLS_FAIL,
  EDIT_SKILLS_START,
  EDIT_SKILLS_SUCCESS,
  GET_GLOBAL_SKILLS_FAIL,
  GET_GLOBAL_SKILLS_START,
  GET_GLOBAL_SKILLS_SUCCESS,
  GET_SKILLS_FAIL,
  GET_SKILLS_START,
  GET_SKILLS_SUCCESS,
} from './actions';

const initialState = {
  skills: null,
  skillsTotal: null,
  skillsPageSize: null,
  skillsPageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  globalSkills: {
    skills: null,
    skillsTotal: null,
    skillsPageSize: null,
    skillsPageNumber: null,
    isLoading: false,
    error: false,
  },
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_SKILLS_START:
      return {
        ...state,
        skills: null,
        skillsTotal: null,
        skillsPageSize: null,
        skillsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        skills: response.content,
        skillsTotal: response.totalElements || 0,
        skillsPageSize: response.size || 0,
        skillsPageNumber: response.number || 0,
        isLoading: false,
      };

    case GET_SKILLS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_GLOBAL_SKILLS_START:
      return {
        ...state,
        globalSkills: {
          skills: null,
          skillsTotal: null,
          skillsPageSize: null,
          skillsPageNumber: null,
          isLoading: true,
          error: false,
        },
      };

    case GET_GLOBAL_SKILLS_SUCCESS:
      return {
        ...state,
        globalSkills: {
          skills: response.content,
          skillsTotal: response.totalElements || 0,
          skillsPageSize: response.size || 0,
          skillsPageNumber: response.number || 0,
          isLoading: false,
        },
      };

    case GET_GLOBAL_SKILLS_FAIL:
      return { ...state, globalSkills: { error: true, isLoading: false } };

    case CREATE_SKILLS_START:
      return { ...state, isLoading: true, creationError: false };

    case CREATE_SKILLS_SUCCESS:
      return { ...state, isLoading: false };

    case CREATE_SKILLS_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_SKILLS_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_SKILLS_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_SKILLS_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_SKILLS_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_SKILLS_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_SKILLS_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    case ADD_TRACK_SKILLS:
      return { ...state, skills: value };

    case CLEAR_TRACK_SKILLS:
      return { ...state, skills: null };

    default:
      return state;
  }
};
