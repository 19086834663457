/* eslint-disable */
import { API_D360_URL } from '../service_api';
import {
  D360_CREATE_MEASURABLE_FAIL,
  D360_CREATE_MEASURABLE_START,
  D360_CREATE_MEASURABLE_SUCCESS,
  D360_DELETE_MEASURABLE_FAIL,
  D360_DELETE_MEASURABLE_START,
  D360_DELETE_MEASURABLE_SUCCESS,
  D360_GET_MEASURABLES_FAIL,
  D360_GET_MEASURABLES_START,
  D360_GET_MEASURABLES_SUCCESS,
  D360_PUBLISH_REPORT_FAIL,
  D360_PUBLISH_REPORT_START,
  D360_PUBLISH_REPORT_SUCCESS,
} from './constants';

import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360PublishReport = (projectId, measurables, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/measurable/publish/report`,
    body: {
      id: projectId,
      measurables: measurables,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_PUBLISH_REPORT_START, D360_PUBLISH_REPORT_SUCCESS, D360_PUBLISH_REPORT_FAIL],
    onSuccess,
    onError,
  };
};

// POST
export const D360CreateMeasurable = (projectId, ids, selfAssessment, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/measurable`,
    body: {
      d360_project_id: projectId,
      ids: ids,
      selfAssessment: selfAssessment,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_MEASURABLE_START, D360_CREATE_MEASURABLE_SUCCESS, D360_CREATE_MEASURABLE_FAIL],
    onSuccess,
    onError,
  };
};

// DELETE
export const D360DeleteMeasurable = (projectId, ids, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/measurable${getQueryString({
      d360_project_id: projectId,
      ids: ids,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DELETE_MEASURABLE_START, D360_DELETE_MEASURABLE_SUCCESS, D360_DELETE_MEASURABLE_FAIL],
    onSuccess,
    onError,
  };
};

// GET
export const D360GetMeasurables = (
  projectId,
  page = 1,
  pageSize = 10,
  sortColumn = 'id',
  sortOrder = 'DESC',
  filterColumn = 'id',
  filterValue = '',
  onSuccess,
  onError
) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/measurables${getQueryString({
      d360_project_id: projectId,
      page: page,
      pageSize: pageSize,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      filterColumn: filterColumn,
      filterValue: filterValue,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_MEASURABLES_START, D360_GET_MEASURABLES_SUCCESS, D360_GET_MEASURABLES_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

 import {
    D360CreateMeasurable,
    D360DeleteMeasurable,
    D360GetMeasurables,
   } from '@/store/d360_measurables/actions';

 **************************************************************/
