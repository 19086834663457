/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Input, TimePicker } from 'antd';
import FormItem from '@/pages/Topics/TopicCreationDialog/FormItem';

import CustomDateSomePicker from '@shared/components/DatePicker/DateSomePicker';

import classNames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';

import css from './ScheduleCustom.module.scss';

const { TextArea } = Input;
dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

const isString = value => typeof value === 'string';

class ScheduleCustom extends Component {
  static propTypes = {
    curTime: PropTypes.object,
    dates: PropTypes.array,
    domainCompany: PropTypes.object,
    error: PropTypes.bool,
    ids: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    topic: PropTypes.object,
    value: PropTypes.object,
    isTemplate: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { domainCompany } = this.props;

    const startTimeValue = this.props.value?.startTime
      ? this.props.value.startTime
      : domainCompany?.eventStartTime
        ? domainCompany.eventStartTime
        : undefined;

    const endTimeValue = this.props.value?.endTime
      ? this.props.value.endTime
      : domainCompany?.eventEndTime
        ? domainCompany.eventEndTime
        : undefined;

    const startTimeForFormat = startTimeValue ? dayjs(startTimeValue, timeFormat) : startTimeValue;

    const endTimeForFormat = endTimeValue ? dayjs(endTimeValue, timeFormat) : endTimeValue;

    this.props.onChange(this.props.ids, 'startTime', startTimeForFormat);
    this.props.onChange(this.props.ids, 'endTime', endTimeForFormat);
  }

  changeStartTime = (time, timeString) => {
    const isDateExist = Boolean(this.props.value?.dates?.[0]);

    const endTime = this.props.value?.endTime;

    const firstDateString =
      isString(this.props.value?.dates?.[0]) && isDateExist
        ? this.props.value?.dates?.[0]
        : dayjs(this.props.value?.dates?.[0]).format(dateFormat);

    const fullEndDateTime =
      firstDateString && isString(endTime) && endTime ? dayjs(`${firstDateString}T${endTime}`) : endTime;

    let startTimeValue = timeString ? dayjs(timeString, timeFormat) : null;

    if (isDateExist && firstDateString) {
      startTimeValue = dayjs(`${firstDateString}T${timeString}`);
      const endDate = startTimeValue.add(5, 'minute');

      if (endDate.isAfter(fullEndDateTime)) {
        this.props.onChange(this.props.ids, 'endTime', endDate);
      }
    }
    this.props.onChange(this.props.ids, 'startTime', startTimeValue);
  };

  changeEndTime = (time, timeString) => {
    this.props.onChange(this.props.ids, 'endTime', time);
  };

  changePlace = event => {
    this.props.onChange(this.props.ids, 'place', event.target.value);
  };

  changeDates = event => {
    const dayjsDates = event.map(day => {
      if (isString(day)) {
        return dayjs(day, dateFormat);
      }

      if (dayjs.isDayjs(day)) {
        return day;
      }

      return dayjs(day);
    });

    const includesToday = dayjsDates.find(day => dayjs().format(dateFormat) === day.format(dateFormat));

    if (includesToday) {
      const endDateTime = dayjs.isDayjs(this.props.value?.endTime)
        ? this.props.value.endTime
        : this.props.value?.endTime
          ? dayjs(this.props.value.endTime, timeFormat)
          : dayjs().add(5, 'minutes');

      if (endDateTime.isBefore(dayjs())) {
        this.props.onChange(this.props.ids, 'startTime', dayjs());
        this.props.onChange(this.props.ids, 'endTime', dayjs().add(5, 'minutes'));
        return;
      }

      this.props.onChange(this.props.ids, 'startTime', dayjs());
    }

    this.props.onChange(this.props.ids, 'dates', event);
  };

  shouldDisableHours = () => {
    const { dates } = this.props.value;
    if (!dates) {
      return false;
    }
    const isCurDate = dates.filter(date => dayjs(date).isSame(dayjs(), 'day'));
    return !!isCurDate.length;
  };

  checkDisabledHours = timeType => {
    let currHH = 0;

    if (timeType === 'endTime') {
      const isDateExist = Boolean(this.props.value?.dates?.[0]);
      const firstDateString =
        isString(this.props.value?.dates?.[0]) && isDateExist
          ? this.props.value?.dates?.[0]
          : isDateExist
            ? dayjs(this.props.value.dates[0]).format(dateFormat)
            : dayjs().format(dateFormat);

      const onlyToday =
        this.props.value?.dates?.length === 1 &&
        this.props.value?.dates?.[0] &&
        firstDateString === dayjs().format(dateFormat);

      const startTimeString =
        this.props.value?.startTime && isString(this.props.value?.startTime)
          ? this.props.value.startTime
          : this.props.value?.startTime
            ? this.props.value.startTime.format(timeFormat)
            : dayjs().format(timeFormat);

      if (onlyToday) {
        currHH = Number(this.props.curTime.format('H'));
      } else if (this.props.value?.startTime) {
        currHH = Number(dayjs(`${firstDateString}T${startTimeString}`).format('H'));
      } else {
        currHH = 0;
      }
    } else {
      currHH = this.shouldDisableHours() ? Number(this.props.curTime.format('H')) : 0;
    }

    const disabledHours = [];
    let i = 0;

    while (i < currHH && i <= 23) {
      disabledHours.push(i);
      i++;
    }

    return disabledHours;
  };

  checkDisabledMinutes = (timeType, selectedHour) => {
    let curHH = this.shouldDisableHours() ? Number(this.props.curTime.format('HH')) : 0;
    let curMM;
    let i = 0;

    const disabledMinutes = [];
    const startHH = (this.props.value?.startTime && Number(dayjs(this.props.value?.startTime).format('HH'))) || 0;

    curMM = this.shouldDisableHours() ? Number(this.props.curTime.format('m')) : 0;

    if (timeType === 'endTime') {
      if (selectedHour === startHH) {
        const isDateExist = Boolean(this.props.value?.dates?.[0]);
        const firstDateString =
          isString(this.props.value?.dates?.[0]) && isDateExist
            ? this.props.value?.dates?.[0]
            : isDateExist
              ? dayjs(this.props.value.dates[0]).format(dateFormat)
              : dayjs().format(dateFormat);

        const startTimeString =
          this.props.value?.startTime && isString(this.props.value?.startTime)
            ? this.props.value.startTime
            : this.props.value?.startTime
              ? this.props.value.startTime.format(timeFormat)
              : dayjs().format(timeFormat);

        curMM = this.props.value?.startTime && Number(dayjs(`${firstDateString}T${startTimeString}`).format('m')) + 5;
      }
    }

    if (selectedHour === curHH || selectedHour === startHH) {
      while (i < curMM && i <= 59) {
        disabledMinutes.push(i);
        i++;
      }
    }

    return disabledMinutes;
  };

  render() {
    const { domainCompany, topic, isTemplate } = this.props;

    const isTopicType8 = topic.typeId === 8;

    const startDateTime = topic?.startDate && topic?.startTime ? dayjs(`${topic.startDate}T${topic.startTime}`) : null;
    const endDateTime = topic?.endDate && topic?.endTime ? dayjs(`${topic.endDate}T${topic.endTime}`) : null;
    const isFinished = endDateTime ? endDateTime.isBefore(dayjs()) : false;
    const isFinishedEvent = isTopicType8 && isFinished && !isTemplate;

    const dateIsBeforeStart =
      (!isTemplate && isTopicType8 && this.shouldDisableHours() && startDateTime?.isBefore(dayjs())) ||
      (isFinishedEvent && !isTemplate);

    const dateIsBeforeEnd =
      (!isTemplate && isTopicType8 && !this.props.value?.startTime) || (isFinishedEvent && !isTemplate);

    const disabledStartTime = isTopicType8;
    const disabledEndTime = isTopicType8;

    const startTimeForFormat = dayjs(
      this.props.value?.startTime
        ? this.props.value.startTime
        : domainCompany?.eventStartTime
          ? domainCompany.eventStartTime
          : '10:00',
      timeFormat
    );

    const endTimeForFormat = dayjs(
      this.props.value?.endTime
        ? this.props.value.endTime
        : domainCompany?.eventEndTime
          ? domainCompany.eventEndTime
          : '12:00',
      timeFormat
    );

    return (
      <div className={css.ScheduleCustomItem}>
        <div className={css['ScheduleCustomItem-dates']}>
          <FormItem label={this.props.t('time')} error={false} schedule required>
            <div className={css['ScheduleCustomItem-dates-date']}>
              {/* Начало мероприятия / события */}
              <div
                className={classNames(
                  { [css['ScheduleCustomItem-error']]: this.props.error && this.props.value?.endTime === '' },
                  css.HoursMinutesInput
                )}
              >
                <TimePicker
                  className={classNames(css['HoursMinutesTimePicker-modificate'])}
                  placeholder='10:00'
                  format={timeFormat}
                  onChange={this.changeStartTime}
                  name='startTime'
                  value={startTimeForFormat}
                  disabled={dateIsBeforeStart}
                  disabledTime={now => ({
                    disabledHours: disabledStartTime ? () => this.checkDisabledHours('startTime') : () => [],
                    disabledMinutes: disabledStartTime
                      ? selectedHour => this.checkDisabledMinutes('startTime', selectedHour)
                      : () => [],
                  })}
                />
              </div>

              {/* Конец мероприятия / события */}
              <div
                className={classNames(
                  { [css['ScheduleCustomItem-error']]: this.props.error && this.props.value?.endTime === '' },
                  css.HoursMinutesInput
                )}
              >
                <TimePicker
                  className={classNames(css['HoursMinutesTimePicker-modificate'])}
                  placeholder='10:00'
                  format={timeFormat}
                  onChange={this.changeEndTime}
                  name='endTime'
                  value={endTimeForFormat}
                  disabled={dateIsBeforeEnd}
                  disabledTime={now => ({
                    disabledHours: disabledEndTime ? () => this.checkDisabledHours('endTime') : () => [],
                    disabledMinutes: disabledEndTime
                      ? selectedHour => this.checkDisabledMinutes('endTime', selectedHour)
                      : () => [],
                  })}
                />
              </div>
            </div>
          </FormItem>
          <FormItem label={this.props.t('place')} error={false} required>
            <TextArea
              className={classNames(css['ScheduleCustomItem-textarea'], {
                [css['ScheduleCustomItem-error']]: this.props.error && this.props.value?.place === '',
              })}
              rows={5}
              name='description'
              placeholder={this.props.t('address')}
              onChange={this.changePlace}
              value={this.props.value?.place}
              maxLength={500}
              disabled={topic?.webinarPlatform && topic?.webinarPlatform !== '1'}
            />
          </FormItem>
        </div>
        <div className={css['ScheduleCustomItem-calendar']}>
          <CustomDateSomePicker
            value={this.props.value?.dates}
            error={!this.props.value?.dates.length && this.props.error}
            onChange={this.changeDates}
            disabledDays={this.props.disabledDays}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('scheduleCustom')(ScheduleCustom);
