import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUsers } from '@/store/users/selectors';

import { Button } from 'antd';

import PropTypes from 'prop-types';

import css from './ScoresShop.module.scss';

import { ACCESS_TO_ADMIN_SYSTEM_SETTINGS } from '@/constants/permissions';

const Scores = props => {
  const { t } = useTranslation('products');
  const navigate = useNavigate();

  const { currentUser } = useSelector(selectUsers);

  const goToVarDirectory = () => navigate('/coin-settings');

  return (
    <div className={css['Scores']}>
      <div className={css['Scores-header']}>
        <div className={css['Scores-header-title']}>{t('titleScores')}</div>
        <div className={css['Scores-header-history']}>
          <div className={css['Scores-header-history-text']}>{t('history')}</div>
          <div className={css['Scores-header-history-date']}>{props.date || '13.02.2023'}</div>
        </div>
      </div>

      <div className={css['Scores-desc']}>
        <div className={css['Scores-desc-text']}>{t('historyDesc')}</div>
        <div className={css['Scores-desc-btn']}>
          {currentUser?.authorities.includes(ACCESS_TO_ADMIN_SYSTEM_SETTINGS) && (
            <Button onClick={goToVarDirectory} size='large' type='primary' data-qa='adminsChangeCoins'>
              {t('change')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Scores.propTypes = {
  date: PropTypes.string,
};

export default Scores;
