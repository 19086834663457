/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { editCompetence, getAllAvailableSkills } from '@/store/competence/actions';
import { getGroups } from '@/store/groups/actions';

import { selectGroups } from '@/store/groups/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { AutoComplete, Button, Col, Input, message, Modal, Radio, Row, Select, Tag, Tooltip } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './index.module.scss';

export class AddCompetenceModal extends Component {
  static propTypes = {
    skills: PropTypes.array,
    skillsGlobal: PropTypes.object,
    skillsTotal: PropTypes.number,
    skillsPageSize: PropTypes.number,
    skillsPageNumber: PropTypes.number,
    isLoading: PropTypes.bool,
    isGlobal: PropTypes.bool,
    getSkills: PropTypes.func,
    getSkillsGlobal: PropTypes.func,
    clearErrorText: PropTypes.func,
    getAllAvailableSkills: PropTypes.func,
    errorAlertText: PropTypes.string,
    getGroups: PropTypes.func,

    domainCompany: PropTypes.object,

    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    locale: PropTypes.func,
    record: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      record: {},
      skills: [],
      currentSearch: '',
      updateSkills: [],
      errorAlertText: null,
      groupsValue: [],
    };

    this.getAllAvailableSkillsDebounced = _.debounce(props.getAllAvailableSkills, 300);
    this.getGroupsDebounced = _.debounce(props.getGroups, 1000);
  }

  componentDidMount() {
    if (this.props.record) {
      this.setState({
        record: this.props.record,
        updateSkills: this.props.record.skills,
        groupsValue: this.props.record.groups,
      });
    }

    if (this.props.record?.groups?.length) {
      this.props.getGroups('', 0, 500);
    }
  }

  componentDidUpdate(_, prevState) {
    const { errorAlertText, clearErrorText } = this.props;

    if (errorAlertText !== prevState.errorAlertText && errorAlertText !== null) {
      this.setState({ errorAlertText });
    }

    setTimeout(() => {
      if (this.state.errorAlertText) {
        message.error(this.state.errorAlertText);
        this.setState({ errorAlertText: null });
        clearErrorText();
      }
    }, 500);
  }

  componentWillUnmount() {
    this.props.clearErrorText();
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleSubmit() {
    const { record, updateSkills, groupsValue } = this.state;
    const resultUpdatedSkills = updateSkills.map(skill => skill.id);
    this.props.onSubmit(record, resultUpdatedSkills, groupsValue);
  }

  footer() {
    const { locale } = this.props;
    return [
      <Button
        key='cancel'
        onClick={() => {
          this.handleCancel();
        }}
      >
        {locale('addCompetenceModalButtonCancel')}
      </Button>,

      <Button
        data-qa='saveCompetenceBtn'
        key='submit'
        onClick={() => {
          this.handleSubmit();
        }}
        type='primary'
        disabled={
          this.state.updateSkills.length &&
          this.state.record &&
          this.state.record.competence &&
          this.state.record.competence.length
            ? false
            : true
        }
      >
        {locale('addCompetenceModalButtonSubmit')}
      </Button>,
    ];
  }

  handleCompetenceChange(e) {
    if (e.length) {
      this.setState({
        record: {
          ...this.state.record,
          competence: e,
        },
      });
    } else {
      this.setState({
        record: {
          ...this.state.record,
          competence: '',
        },
      });
    }
  }

  handleSelect(_, options) {
    const { record, updateSkills } = this.state;

    const skills = [...record.skills];
    let found = false;
    skills.map(skill => {
      if (skill.id === options.id) {
        found = true;
      }
    });

    if (!found) {
      skills.push({ id: Number(options.id), name: options.value });
      updateSkills.push({ id: Number(options.id), name: options.value });
    }

    record.skills = skills;
    this.setState({ currentSearch: '', skills: [] });
  }

  handleRadioChange(event) {
    const { record } = this.state;
    record.isHardSkill = event.target.value;
    this.setState({ record: record });
  }

  handleSearch(event) {
    this.setState({ currentSearch: event });
    let skills = [];
    if (event && event.length > 1) {
      this.getAllAvailableSkillsDebounced(this.props.record.key, event, true, res => {
        if (Array.isArray(res)) {
          res.map(skill => {
            skills.push({
              value: skill.name,
              id: skill.id,
            });
          });
        }
        this.setState({ skills: skills });
      });
    } else {
      this.setState({
        skills: [],
      });
    }
  }

  handleSearchGroup(value) {
    this.getGroupsDebounced(value, 0, 500);
  }

  static skillsFilter(value, option) {
    return option.value.toUpperCase().indexOf(value.toUpperCase()) !== -1;
  }

  handleRemoveSkill(e) {
    const skillsFiltered = this.state.updateSkills.filter(({ id }) => id !== e);
    this.setState({ updateSkills: skillsFiltered });
  }

  getSkillName(name) {
    let sliced = name.slice(0, 40);
    if (sliced.length < name.length) {
      sliced += '...';
    }

    return sliced;
  }

  renderOptions = arr => {
    return (
      arr?.length &&
      arr.map(item => {
        return (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        );
      })
    );
  };

  render() {
    const { locale, open, onCancel } = this.props;
    const { record, skills, currentSearch } = this.state;

    return (
      <Modal
        title={locale('addCompetenceModalTitle')}
        open={open}
        centered={true}
        closable={true}
        footer={this.footer()}
        onCancel={onCancel}
        destroyOnClose={true}
      >
        <Row data-qa='competenceModal'>
          <Col span={6}>{locale('addCompetenceModalInputCompetence')}</Col>
          <Col span={18}>
            <Input
              data-qa='competenceNameInput'
              placeholder={locale('addCompetenceModalInputCompetencePlaceholder')}
              allowClear={true}
              maxLength={50}
              size='default'
              id='competence'
              onChange={e => this.handleCompetenceChange(e.target.value)}
              value={record.competence}
            />
          </Col>
        </Row>
        <Row className={css['AddCompetence-row-autoComplete']}>
          <Col span={6}>{locale('addCompetenceModalInputSkills')}</Col>
          <Col span={18}>
            <AutoComplete
              className={css['AddCompetence-select']}
              placeholder={locale('addCompetenceModalInputSkillsPlaceholder')}
              options={skills}
              onSearch={v => {
                this.handleSearch(v);
              }}
              onSelect={(v, o) => {
                this.handleSelect(v, o);
              }}
              dropdownRender={optionElements => <span data-qa='competenceSkillSelect-list'>{optionElements}</span>}
              id='skillSearch'
              data-qa='competenceSkillSelect'
              value={currentSearch}
              filterOption={AddCompetenceModal.skillsFilter}
            />
          </Col>
        </Row>
        <Row className={css['AddCompetence-row']}>
          <Col offset={6} span={18}>
            {!!record?.skills?.length &&
              record.skills.map(skill => (
                <Tag closable={true} key={skill.id} onClose={() => this.handleRemoveSkill(skill.id)}>
                  <Tooltip title={skill.name}>{this.getSkillName(skill.name)}</Tooltip>
                </Tag>
              ))}
          </Col>
        </Row>
        <Row className={css['AddCompetence-row']}>
          <Col span={6}>{locale('addCompetenceModalInputGroup')}</Col>
          <Col span={18}>
            <Select
              placeholder={locale('placeholderSearchGroups')}
              className={css['AddCompetence-select']}
              onSearch={e => this.handleSearchGroup(e)}
              onChange={e => this.setState({ groupsValue: e })}
              loading={this.props.groupsLoading}
              value={this.state.groupsValue}
              optionFilterProp='children'
              mode='multiple'
              showSearch
              allowClear
            >
              {this.renderOptions(this.props.groupsTable)}
            </Select>
          </Col>
        </Row>
        <Row className={css['AddCompetence-radio']}>
          <Col span={8}>&nbsp;</Col>
          <Col span={16}>
            <Radio.Group onChange={e => this.handleRadioChange(e)} value={record.isHardSkill}>
              <Radio value={false}>Soft Skills</Radio>
              <Radio value={true}>Hard Skills</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(usersSelect, selectGroups, (users, groups) => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  groupsTable: groups.groupsTable,
  groupsLoading: groups.groupsLoading,
}));

const mapActionsToProps = {
  editCompetence,
  getAllAvailableSkills,
  getGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(AddCompetenceModal);
