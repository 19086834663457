/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360ImportStatus } from '@/store/d360/d360_import/actions';
import { D360CreateMeasurable, D360DeleteMeasurable, D360GetMeasurables } from '@/store/d360/d360_measurables/actions';
import { D360GetProject } from '@/store/d360/d360_project/actions';
import { D360CreateRespondent } from '@/store/d360/d360_respondents/actions';

import { Badge, Button, Col, Divider, Dropdown, Modal, Row, Table } from 'antd';
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { FiUsers } from 'react-icons/fi';

import { PROJECT_STATUS_DRAFT } from '../../constants';
import { extractUsername } from '../../utils';
import AutoAssigner from '../AutoAssigner/AutoAssigner';
import ColumnSearch from '../ColumnSearch';
import Finder from '../Finder/Finder';
import ImportModal from '../Import/ImportModal';
import { ImportAlert } from './ImportAlert';
import Respondents from './parts/Respondents';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../css/index.module.scss';

const { confirm } = Modal;

const ButtonGroup = Button.Group;
const selectedTemplate = { keys: [], rows: [] };

const pageSizeOptions = ['10', '50', '100', '500', '1000'];

export const Assignment = props => {
  const { t } = useTranslation('D360_Assignment');
  const dispatch = useDispatch();

  const { gettingStatus } = useSelector(state => state.d360Import);
  const { measurables, loading, pagePer, pageTotal } = useSelector(state => state.d360Measurables);

  const [showMeasurableFinder, setShowMeasurableFinder] = useState(false);
  const [showRespondentFinder, setShowRespondentFinder] = useState(false);
  const [showAutoAssigner, setShowAutoAssigner] = useState(false);
  const [showRespondents, setShowRespondents] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState(selectedTemplate);
  const [disableSelAction, setDisableSelAction] = useState(false);
  const [currentMeasurableId, setCurrentMeasurableId] = useState(0);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('DESC');

  const [filterColumn, setFilterColumn] = useState(undefined);
  const [filterValue, setFilterValue] = useState(undefined);
  const [importing, setImporting] = useState(true);

  const makeFilter = uniq => {
    let data = [];
    if (Array.isArray(uniq)) {
      uniq.forEach(item => {
        if (typeof item !== 'undefined' && item !== null) {
          data.push({ text: item, value: item });
        }
      });
    }

    return data;
  };

  const getFilterPosition = () => {
    return makeFilter([...new Set(tableData.map(item => item.student.position))]);
  };

  const getFilterDepartment = () => {
    const uniq = [...new Set(tableData.map(item => item.student.department))];
    return makeFilter(uniq);
  };

  const getFilterFunction = () => {
    return makeFilter([...new Set(tableData.map(item => item.student.function))]);
  };

  const onSearchReset = () => {
    setFilterColumn(undefined);
    setFilterValue(undefined);
  };

  const onSearchSubmit = (column, value) => {
    setFilterColumn(column);
    setFilterValue(value);
  };

  const columns = [
    {
      sorter: (a, b) => a.student.last_name.localeCompare(b.student.last_name),
      sortDirections: ['descend', 'ascend'],
      title: t('fullname'),
      key: 'fullname',
      dataIndex: 'fullname',
      width: '15%',
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => {
        return record.name.toString().toLowerCase().includes(value.toLowerCase());
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <ColumnSearch
            column='fullname'
            fnClearFilters={clearFilters}
            fnClose={confirm}
            onReset={onSearchReset}
            onSubmit={onSearchSubmit}
            text={filterValue}
          />
        );
      },
      render: (name, record) => {
        let badgeStyle = 'danger';
        if (record.respondentCount && record.respondentCount > 0) {
          badgeStyle = 'success';
        }

        return (
          <>
            <Badge status={badgeStyle} dot>
              <span className={css.TableLink} onClick={() => openRespondents(record.id)}>
                {extractUsername(record.student)}
              </span>
            </Badge>
          </>
        );
      },
    },
    {
      title: t('email'),
      key: 'email',
      dataIndex: 'email',
      render: (name, record) => record.student.email,
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => {
        return record.email.toString().toLowerCase().includes(value.toLowerCase());
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <ColumnSearch
            column='email'
            fnClearFilters={clearFilters}
            fnClose={confirm}
            onReset={onSearchReset}
            onSubmit={onSearchSubmit}
            text={filterValue}
          />
        );
      },
    },
    {
      filters: getFilterDepartment(),
      onFilter: (value, record) => record.student.department === value,
      title: t('department'),
      key: 'department',
      dataIndex: 'department',
      render: (name, record) => record.student.department,
    },
    {
      filters: getFilterPosition(),
      onFilter: (value, record) => record.student.position === value,
      title: t('position'),
      key: 'position',
      dataIndex: 'position',
      render: (name, record) => record.student.position,
    },
    {
      filters: getFilterFunction(),
      onFilter: (value, record) => record.student.function === value,
      title: t('function'),
      key: 'function',
      dataIndex: 'function',
      render: (name, record) => record.student.function,
    },
  ];

  const loadMeasurables = () => {
    dispatch(
      D360ImportStatus(
        props.project.id,
        response => {
          if (!_.isNil(response) && _.isObject(response) && _.has(response, 'data')) {
            if (!_.isNil(response.data) && _.isObject(response.data) && _.has(response.data, 'status')) {
              if (_.isString(response.data.status) && response.data.status.toString().length > 0) {
                setImporting(true);
              } else {
                setImporting(false);
              }
            } else {
              setImporting(false);
              dispatch(
                D360GetMeasurables(
                  props.project.id,
                  pageCurrent,
                  pageSize,
                  sortColumn,
                  sortOrder,
                  filterColumn,
                  filterValue
                )
              );
            }
          }
        },
        error => {
          console.log('Error: ' + error);
        }
      )
    );
  };

  useEffect(() => {
    if (props.project && props.project.id) {
      loadMeasurables();
    }
  }, [props.project, pageCurrent, pageSize, sortColumn, sortOrder, filterColumn, filterValue]);

  useEffect(() => {
    let data = [];
    if (measurables && Array.isArray(measurables)) {
      measurables.forEach(user => {
        data.push({ ...user, key: user.user_id });
      });
    }

    setTableData(data);
  }, [measurables]);

  useEffect(() => {
    if (selected.keys?.length === 0) {
      setDisableSelAction(true);
    } else {
      setDisableSelAction(!props.editable);
    }
  }, [selected]);

  const openRespondents = measurableId => {
    setCurrentMeasurableId(measurableId);
    setShowRespondents(true);
  };

  const handleMeasurableFinderResult = (users, selfAssessment) => {
    if (users && users.rows && Array.isArray(users.rows)) {
      const users2create = [];

      for (let user of users.rows) {
        if (user && user.id) {
          // dispatch(D360CreateMeasurable(props.project.id, user.id, selfAssessment));
          // dispatch(D360GetProject(props.project.id));
          users2create.push(user.id);
        }
      }

      if (users2create.length > 0) {
        dispatch(D360CreateMeasurable(props.project.id, users2create, selfAssessment));
        dispatch(D360GetProject(props.project.id));
      }
    }

    setShowMeasurableFinder(false);
  };

  const handleRespondentFinderResult = (users, selfAssessment, role) => {
    if (users && users.rows && Array.isArray(users.rows)) {
      for (let respondent of users.rows) {
        dispatch(D360CreateRespondent(props.project.id, selected.keys, respondent.id, role));
      }
    }

    setShowRespondentFinder(false);
  };

  const handleAutoAssignerComplete = () => {
    setShowAutoAssigner(false);
    if (props.project && props.project.id) {
      loadMeasurables();
    }
  };

  const renderMenu = [
    {
      key: '1',
      label: t('import'),
      onClick: () => setImportModalVisible(true),
      icon: <FileExcelOutlined />,
      disabled: importing,
    },
    {
      key: '2',
      label: t('addMeasurables'),
      onClick: () => setShowMeasurableFinder(true),
    },
    {
      key: '3',
      label: t('addRespondents'),
      onClick: () => setShowRespondentFinder(true),
      disabled: disableSelAction,
    },
    {
      key: '4',
      label: t('autoAsignRespondents'),
      onClick: () => setShowAutoAssigner(true),
      disabled: disableSelAction,
    },
  ];

  const onDeleteUsers = () => {
    confirm({
      content: <span>{t('confirmDelete')}</span>,
      okText: <span>{t('yesDelete')}</span>,
      okType: 'danger',
      onOk() {
        deleteUsers();
      },
      onCancel() {},
    });
  };

  const deleteUsers = () => {
    if (selected && selected.rows && Array.isArray(selected.rows)) {
      const users2delete = [];

      for (let user of selected.rows) {
        if (user && user.user_id) {
          users2delete.push(user.user_id);
          //          dispatch(D360DeleteMeasurable(props.project.id, user.user_id));
        }
      }

      if (users2delete.length > 0) {
        dispatch(D360DeleteMeasurable(props.project.id, users2delete));
      }
    }

    setSelected(selectedTemplate);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selected.keys,
      onChange: (selectedKeys, selectedRows) => {
        setSelected({
          ...selected,
          keys: selectedKeys,
          rows: selectedRows,
        });
      },
    };
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (!_.isNil(pagination) && _.isObject(pagination)) {
      if (_.has(pagination, 'pageSize') && _.isNumber(pagination.pageSize)) {
        setPageSize(pagination.pageSize);
      }
      if (_.has(pagination, 'current') && _.isNumber(pagination.current)) {
        setPageCurrent(pagination.current);
      }
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} className={css.Header}>
        <Col span={12}>
          <h2 className={css.HeaderTitle}>
            <FiUsers />
            &nbsp;{t('assigns')}
          </h2>
        </Col>

        <Col span={12} align='right'>
          <ButtonGroup style={{ marginRight: '.5rem' }}>
            <Dropdown menu={{ items: renderMenu }} trigger={['click']}>
              <Button type='primary'>{t('add')}</Button>
            </Dropdown>
            <Button
              type='primary'
              danger
              disabled={disableSelAction}
              onClick={props.project?.d360_status_id === PROJECT_STATUS_DRAFT ? deleteUsers : onDeleteUsers}
            >
              {t('delete')}
            </Button>
          </ButtonGroup>
        </Col>

        <Col span={24}>
          {t('windowDescription')}
          <Divider orientation={'horizontal'} />
        </Col>

        {importing && (
          <ImportAlert
            projectId={
              _.isObject(props) && _.has(props, 'project') && _.isObject(props.project) && _.has(props.project, 'id')
                ? props.project.id
                : null
            }
            onClose={() => {
              loadMeasurables();
            }}
          />
        )}

        <Col span={24}>
          <Table
            columns={columns}
            dataSource={tableData}
            bordered={true}
            rowSelection={rowSelection()}
            loading={loading || importing || gettingStatus}
            size='middle'
            scroll={{ x: 1000 }}
            onChange={onTableChange}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              hideOnSinglePage: false,
              total: pageTotal,
              current: pageCurrent,
              pageSize: pagePer,
              pageSizeOptions: pageSizeOptions,
              responsive: true,
            }}
          />
        </Col>
      </Row>

      <Respondents
        open={showRespondents}
        onCancel={() => setShowRespondents(false)}
        onSubmit={() => setShowRespondents(false)}
        measurableId={currentMeasurableId}
        projectId={props.project.id}
        editable={props.editable}
      />

      <Finder
        key={1}
        title={t('selectMeasurables')}
        open={showMeasurableFinder}
        onCancel={() => setShowMeasurableFinder(false)}
        onSubmit={handleMeasurableFinderResult}
        projectId={props.project.id}
        users={selected.keys}
        findMeasurable={true}
      />

      <Finder
        key={2}
        title={t('selectRespondents')}
        open={showRespondentFinder}
        onCancel={() => setShowRespondentFinder(false)}
        onSubmit={handleRespondentFinderResult}
        projectId={props.project.id}
        users={selected.keys}
        findMeasurable={false}
      />

      <AutoAssigner
        onCancel={() => setShowAutoAssigner(false)}
        onComplete={handleAutoAssignerComplete}
        open={showAutoAssigner}
        measurables={selected.keys}
        project={props.project}
      />

      {importModalVisible && (
        <ImportModal
          onCancel={() => {
            setImportModalVisible(false);
            loadMeasurables();
          }}
          projectId={props.project.id}
        />
      )}
    </>
  );
};

Assignment.propTypes = {
  project: PropTypes.any,
  editable: PropTypes.bool.isRequired,
};

export default Assignment;
