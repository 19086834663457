/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectUsers } from '@/store/users/selectors';

import { Button, Dropdown, Tooltip } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { GrCopy, GrTrash } from 'react-icons/gr';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

import { PROJECT_360_DELETE, PROJECT_360_UPDATE } from '@/constants/permissions';

export const ProjectMenu = props => {
  const { t } = useTranslation('D360_ProjectEdit');

  const { currentUser } = useSelector(selectUsers);

  const onCopy = () => {
    if (props.onCopy && typeof props.onCopy === 'function') {
      props.onCopy(props.project);
    }
  };

  const onDelete = () => {
    if (props.onDelete && typeof props.onDelete === 'function') {
      props.onDelete(props.project);
    }
  };

  const onEdit = () => {
    if (props.onEdit && typeof props.onEdit === 'function') {
      props.onEdit(props.project);
    }
  };

  const renderMenu = [
    {
      key: '1',
      label: t('menuCopy'),
      onClick: () => onCopy(props.project),
      icon: <GrCopy />,
    },
    currentUser?.authorities.includes(PROJECT_360_DELETE) && {
      key: '2',
      label: t('menuDelete'),
      onClick: () => onDelete(props.project),
      icon: <GrTrash />,
    },
    currentUser?.authorities.includes(PROJECT_360_UPDATE) && {
      key: '3',
      label: t('menuProperty'),
      onClick: () => onEdit(props.project),
    },
  ];

  const projectName = text => {
    if (text?.length > 99) {
      return (
        <Tooltip title={text}>
          <span className={css.ProjectName}>{text}</span>
        </Tooltip>
      );
    } else {
      return <span className={css.ProjectName}>{text}</span>;
    }
  };

  return (
    <span className={css.TableLink} type='link'>
      <Dropdown menu={{ items: renderMenu }} trigger={['click']}>
        <Button size='small' shape='circle' icon={<MoreOutlined />} style={{ marginRight: 10 }} />
      </Dropdown>
      <Link to={`/d360/${props.project.id}`}>{projectName(props.name)}</Link>
    </span>
  );
};

ProjectMenu.propTypes = {
  name: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ProjectMenu;
