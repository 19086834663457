import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getAllRoles } from '@/store/access/actions';
import { getDepartments } from '@/store/department/actions';
import { getFunctions } from '@/store/function/actions';
import { createUser, getStudents } from '@/store/students/actions';

import { selectAccess } from '@/store/access/selectors';
import { selectDepartments } from '@/store/department/selectors';
import { selectFunctions } from '@/store/function/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Form, Input, Modal, Select } from 'antd';

import _ from 'lodash';
import PropTypes from 'prop-types';

const { Option } = Select;

const CreateUser = ({ onClose, clearStudent, open, filter }) => {
  const { t } = useTranslation('createUser');
  const dispatch = useDispatch();

  const { departments } = useSelector(selectDepartments);
  const { allRoles } = useSelector(selectAccess);
  const { functions } = useSelector(selectFunctions);
  const { studentsForUser } = useSelector(selectStudents);
  const { currentUser } = useSelector(selectUsers);

  useEffect(() => {
    dispatch(getAllRoles());

    return clearStudent;
  }, []);

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    login: '',
    password: '',
    roleId: undefined,
    department: undefined,
    function: '',
    position: undefined,
    location: '',
    subCompany: '',
    managerId: undefined,
    createSk: true,
    companyId: currentUser?.domainCompany?.id,
  });

  const [confirmPassword, setConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    roleId: false,
    passwordConfirm: false,
    emailVerify: false,
    passwordVerify: false,
    individEmail: false,
    lengthPassVer: false,
    firstNamedVerify: false,
    lastNamedVerify: false,
    middleNamedVerify: false,
  });

  const handleGetCallbackActions = (e, getCallbackAction) => {
    if (e) {
      let text = e.trim();
      text.length > 3 && dispatch(getCallbackAction(text, null, () => {}, currentUser?.domainCompany?.global));
    }
  };

  const handleGetStudents = e => {
    if (e) {
      let text = e.trim();
      text.length > 3 && dispatch(getStudents({ fullName: text }, 0, true));
    }
  };

  const getDepartmentsDebounced = useCallback(
    _.debounce(e => handleGetCallbackActions(e, getDepartments), 1000),
    []
  );
  const getPositionsDebounced = useCallback(
    _.debounce(e => handleGetCallbackActions(e, getFunctions), 1000),
    []
  );
  const getStudentsDebounced = useCallback(_.debounce(handleGetStudents, 1000), []);

  const renderOptions = arr => {
    return (
      arr?.length &&
      arr.map(item => (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      ))
    );
  };

  const renderStudentOptions = arr => {
    return (
      arr?.length &&
      arr.map(item => (
        <Option value={item.id} key={item.id}>
          {item.lastName + ' ' + item.firstName}
        </Option>
      ))
    );
  };

  const handleChangePassword = e => {
    setData({ ...data, password: e.target.value });
    setErrors({
      ...errors,
      password: !e.target.value,
      passwordVerify: !isValidPassword(e.target.value),
      lengthPassVer: e.target.value.length < 6,
    });
  };

  const handleChangeConfPassword = e => {
    let valueConf = e?.target.value === data.password;
    setConfirmPassword(valueConf);
  };

  const handleChangeFirstName = e => {
    setData({ ...data, firstName: e.target.value.trim() });
    setErrors({ ...errors, firstNamedVerify: isValidFio(e.target.value) });
  };

  const handleChangeLastName = e => {
    setData({ ...data, lastName: e.target.value.trim() });
    setErrors({ ...errors, lastNamedVerify: isValidFio(e.target.value) });
  };

  const handleChangeMiddleName = e => {
    setData({ ...data, middleName: e.target.value.trim() });
    setErrors({ ...errors, middleNamedVerify: isValidFio(e.target.value) });
  };

  const searchInSelect = (input, option) => {
    if (option?.props?.children && input) {
      return option.props.children.toUpperCase().indexOf(input.toUpperCase()) !== -1;
    }
    return false;
  };

  function isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]$/i.test(email);
  }

  function isValidPassword(password) {
    return /^[a-zA-Z0-9!@#$%^&*]{6,}$/.test(password);
  }

  function isValidFio(fio) {
    return /[^а-яА-ЯёЁa-zA-Z- ]/g.test(fio);
  }

  const checkErrors = () => {
    if (
      !data.firstName ||
      !data.lastName ||
      !data.email ||
      !data.password ||
      !data.roleId ||
      !confirmPassword ||
      !isValidEmail(data.email) ||
      !isValidPassword(data.password) ||
      data.password.length < 6 ||
      errors.middleNamedVerify
    ) {
      setErrors({
        ...errors,
        firstName: !data.firstName,
        lastName: !data.lastName,
        email: !data.email,
        password: !data.password,
        roleId: !data.roleId,
        passwordConfirm: !confirmPassword,
        emailVerify: !isValidEmail(data.email),
        passwordVerify: !isValidPassword(data.password),
        lengthPassVer: data.password.length < 6,
      });
      return false;
    } else {
      setErrors({
        ...errors,
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        roleId: false,
        passwordConfirm: false,
        emailVerify: false,
        passwordVerify: false,
        individEmail: false,
        lengthPassVer: false,
      });
      return true;
    }
  };

  const onSubmit = () => {
    if (!checkErrors()) return;

    let copyData = _.cloneDeep(data);

    for (let key in copyData) {
      if (!copyData[key]) {
        delete copyData[key];
      } else if (_.isString(copyData[key])) {
        copyData[key].trim();
      }
    }

    dispatch(
      createUser(
        copyData,
        () => {
          dispatch(getStudents({ ...filter }, 0, false));
          onClose();
        },
        err => {
          err.message === t('noIndivEmail') &&
            setErrors({
              firstName: false,
              lastName: false,
              email: false,
              password: false,
              roleId: false,
              passwordConfirm: false,
              emailVerify: false,
              passwordVerify: false,
              individEmail: true,
              lengthPassVer: false,
              firstNamedVerify: false,
              lastNamedVerify: false,
            });
        }
      )
    );
  };

  const footerModal = (
    <>
      <Button onClick={onClose} key='close' data-qa='createUserCancelBtn'>
        {t('close')}
      </Button>

      <Button type={'primary'} onClick={onSubmit} key='onSubmit' data-qa='createUserSubmitBtn'>
        {t('create')}
      </Button>
    </>
  );

  const helpEmailErr = errors.email
    ? t('noEmail')
    : errors.emailVerify
      ? t('incorrectEmail')
      : errors.individEmail && t('noIndivEmail');

  return (
    <Modal
      open={open}
      title={t('titleCreateUser')}
      data-qa='createUserModal'
      onCancel={onClose}
      width={810}
      footer={footerModal}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <Form.Item
          label={t('firstName')}
          colon={false}
          validateStatus={errors.firstName ? 'error' : errors.firstNamedVerify ? 'error' : ''}
          help={errors.firstName ? t('noFirstName') : errors.firstNamedVerify && t('errFio')}
          required
          labelAlign='left'
        >
          <Input
            //
            placeholder={t('firstNameHolder')}
            maxLength={50}
            onChange={e => handleChangeFirstName(e)}
            data-qa='createUserModalFirstNameInput'
          />
        </Form.Item>
        <Form.Item
          label={t('lastName')}
          colon={false}
          validateStatus={errors.lastName ? 'error' : errors.lastNamedVerify ? 'error' : ''}
          help={errors.lastName ? t('noLastName') : errors.lastNamedVerify && t('errFio')}
          required
          labelAlign='left'
        >
          <Input
            placeholder={t('lastNameHolder')}
            data-qa='createUserModalLastNameInput'
            maxLength={50}
            onChange={e => handleChangeLastName(e)}
          />
        </Form.Item>
        <Form.Item
          label={t('middleName')}
          validateStatus={errors.middleNamedVerify ? 'error' : ''}
          help={errors.middleNamedVerify && t('errFio')}
          colon={false}
          labelAlign='left'
        >
          <Input
            placeholder={t('middleNameHolder')}
            data-qa='createUserModalMiddleNameInput'
            maxLength={50}
            onChange={e => handleChangeMiddleName(e)}
          />
        </Form.Item>
        <Form.Item
          label={t('email')}
          colon={false}
          validateStatus={errors.email ? 'error' : errors.emailVerify ? 'error' : errors.individEmail ? 'error' : ''}
          help={helpEmailErr}
          required
          labelAlign='left'
        >
          <Input
            placeholder={t('emailHolder')}
            maxLength={50}
            autoComplete='new-email'
            data-qa='createUserModalEmailInput'
            onChange={e => setData({ ...data, email: e.target.value.trim(), login: e.target.value.trim() })}
          />
        </Form.Item>
        <Form.Item
          label={t('password')}
          colon={false}
          validateStatus={errors.passwordVerify ? 'error' : errors.password ? 'error' : ''}
          help={
            errors.password && !data.password
              ? t('noPassword')
              : errors.lengthPassVer
                ? t('errLenghtPassword')
                : errors.passwordVerify && t('incorrectPassword')
          }
          required
          labelAlign='left'
        >
          <Input.Password
            placeholder={t('passwordHolder')}
            maxLength={25}
            autoComplete='new-password'
            data-qa='createUserModalPasswordInput'
            onChange={e => handleChangePassword(e)}
          />
        </Form.Item>
        {!errors.passwordVerify && data.password.length > 0 && (
          <Form.Item
            label={t('passwordConfirm')}
            colon={false}
            validateStatus={errors.passwordConfirm ? 'error' : ''}
            help={errors.passwordConfirm && t('unconfirmPassword')}
            required
            labelAlign='left'
          >
            <Input.Password
              placeholder={t('passwordConfHolder')}
              maxLength={25}
              autoComplete='new-password'
              data-qa='createUserModalConfirmPasswordInput'
              onChange={e => handleChangeConfPassword(e)}
            />
          </Form.Item>
        )}
        <Form.Item
          label={t('roleId')}
          colon={false}
          validateStatus={errors.roleId ? 'error' : ''}
          help={errors.roleId && t('noRoleId')}
          required
          labelAlign='left'
        >
          <Select
            allowClear
            showSearch
            filterOption={searchInSelect}
            placeholder={t('roleHolder')}
            id='createUserModalRoleSelect'
            onChange={e => setData({ ...data, roleId: e })}
          >
            {renderOptions(allRoles)}
          </Select>
        </Form.Item>
        <Form.Item label={t('department')} colon={false} labelAlign='left'>
          <Select
            allowClear
            showSearch
            optionFilterProp='children'
            placeholder={t('searchPlaceHolder')}
            onChange={e => setData({ ...data, department: e })}
            onSearch={getDepartmentsDebounced}
            id='createUserModalDepartmentSelect'
          >
            {renderOptions(departments)}
          </Select>
        </Form.Item>
        <Form.Item label={t('function')} colon={false} labelAlign='left'>
          <Input
            placeholder={t('funcHolder')}
            maxLength={50}
            onChange={e => setData({ ...data, function: e.target.value })}
            data-qa='createUserModalFunctionInput'
          />
        </Form.Item>
        <Form.Item label={t('position')} colon={false} labelAlign='left'>
          <Select
            allowClear
            showSearch
            optionFilterProp='children'
            placeholder={t('searchPlaceHolder')}
            onChange={e => setData({ ...data, position: e })}
            onSearch={getPositionsDebounced}
            id='createUserModalPositionSelect'
          >
            {renderOptions(functions)}
          </Select>
        </Form.Item>
        <Form.Item label={t('location')} colon={false} labelAlign='left'>
          <Input
            maxLength={255}
            placeholder={t('locationHolder')}
            data-qa='createUserModalLocationInput'
            onChange={e => setData({ ...data, location: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('subCompanyName')} colon={false} labelAlign='left'>
          <Input
            placeholder={t('subCompHolder')}
            maxLength={50}
            data-qa='createUserModalSubCompanyInput'
            onChange={e => setData({ ...data, subCompany: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('manager')} colon={false} labelAlign='left'>
          <Select
            allowClear
            showSearch
            optionFilterProp='children'
            placeholder={t('searchPlaceHolder')}
            id='createUserModalManagerSelect'
            onChange={e => setData({ ...data, managerId: e })}
            onSearch={getStudentsDebounced}
          >
            {renderStudentOptions(studentsForUser)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  clearStudent: PropTypes.func,
  filter: PropTypes.any,
};

export default CreateUser;
