import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  createSettingsMentors,
  exportSettingsMentors,
  getSettingsCountMentors,
  getSettingsMentors,
} from '@/store/mentors/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectMentors } from '@/store/mentors/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Form, Modal, Row, Select } from 'antd';
import {
  ADD_CUSTOM_FIELDS,
  ALL,
  CUSTOM_FIELDS,
  DELETE_CUSTOM_FIELDS,
} from '@/components/TemplateBlockFilters/constants';
import CustomFieldFilter from '@/components/TemplateBlockFilters/CustomFieldFilter/CustomFieldFilter';
import UsersMaterial from '@/components/TemplateBlockFilters/UsersMaterial/UsersMaterial';

import Utils from '@/Utils';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../Mentors.module.scss';

const { Option } = Select;

const ModalVisibilitySettings = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();
  const { subCompanies } = useSelector(selectCompanies);
  const { currentUser } = useSelector(selectUsers);
  const { company } = useSelector(selectLogin);
  const { settingsMentors, countSettingsMentors, exportMentorsLoading } = useSelector(selectMentors);
  const { position, department, functionStudent, location } = useSelector(selectStudents);

  const [data, setData] = useState({
    userId: {
      id: null,
      name: '',
    },
    companies: '',
    positions: '',
    departments: '',
    functions: '',
    locations: '',
    status: {
      id: null,
      name: '',
    },
    customFields: [{}],
  });

  const [sendData, setSendData] = useState({});
  const isSuperAdmin = Utils.isSuperAdmin(currentUser);
  const hasSubAdmin = company?.hasSubcompanies && !isSuperAdmin;
  const currentCompany = isSuperAdmin ? data.companies : currentUser?.company;
  const valueCompany = company?.hasSubcompanies ? currentCompany : data.companies;

  useEffect(() => {
    // получение настроек менторов
    dispatch(getSettingsMentors());
  }, [dispatch]);

  useEffect(() => {
    // получение счетчика менторов
    dispatch(getSettingsCountMentors(sendData));
  }, [dispatch, sendData]);

  useEffect(() => {
    if (settingsMentors) {
      const data = Object.keys(settingsMentors).reduce((acc, key) => {
        const keyData = settingsMentors[key];
        if (Array.isArray(keyData) && keyData.length) {
          acc[key] = [...keyData];
        } else if (key === 'customFields') {
          acc[key] = [{}];
        }
        return acc;
      }, {});

      if (company.hasSubcompanies && !isSuperAdmin && data.companies) {
        data.companies = Array.from(new Set([...data.companies, currentUser?.company]));
      }

      if (!company?.hasSubcompanies && !isSuperAdmin) {
        data.companies = [];
      }

      setData(data);
    }
  }, [settingsMentors, company.hasSubcompanies, currentUser.company, isSuperAdmin]);

  useEffect(() => {
    // Обратываю данные для отправки
    let copyData = _.cloneDeep(data);
    let filterCustomFileds = copyData?.customFields?.filter(item => Object.keys(item)?.length);
    if (filterCustomFileds?.length === 0) {
      copyData.customFields = filterCustomFileds;
    } else {
      const newCustomFields = filterCustomFileds?.reduce((acc, item) => {
        if (Array.isArray(item.valueConcrete)) {
          const values = item.valueConcrete;
          for (const value of values) {
            acc.push({
              customFieldId: item.customFieldId,
              type: item.type,
              valueConcrete: value,
            });
          }
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
      copyData.customFields = newCustomFields;
    }

    copyData.customFields = copyData?.customFields?.filter(
      item => item.valueConcrete || item.valueFrom || item.valueTo
    );

    const result = Object.keys(copyData).reduce((acc, key) => {
      const keyData = copyData[key];
      if (Array.isArray(keyData) && keyData.length) {
        acc[key] = [...keyData];
      }
      return acc;
    }, {});

    setSendData(result);
  }, [data]);

  const sendSettings = () => {
    dispatch(createSettingsMentors(sendData, () => props.onCloseModal()));
  };

  const exportMentors = () => {
    dispatch(
      exportSettingsMentors(sendData, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.click();
      })
    );
  };

  const changeData = (event, name, value) => {
    let customField;
    switch (name) {
      case CUSTOM_FIELDS:
        customField = [...data.customFields];
        customField[event] = value;
        setData({ ...data, customFields: customField });
        break;

      case DELETE_CUSTOM_FIELDS:
        if (data.customFields.length === 1) {
          setData({ ...data, customFields: [{}] });
          return;
        }

        customField = [...data.customFields];
        customField.splice(event, 1);
        setData({ ...data, customFields: customField });
        break;

      case ADD_CUSTOM_FIELDS:
        customField = sendData.customFields ? [...sendData.customFields, value] : [value];
        setData({ ...data, customFields: customField });
        break;
      default:
        break;
    }
  };

  const renderOptions = arr => {
    let opt =
      arr?.length &&
      arr.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      });

    return opt;
  };

  const templateFilters = [
    {
      label: t('company'),
      valueLabel: valueCompany || data.companies,
      onChange: e => setData({ ...data, companies: e.includes(ALL) ? subCompanies : e }),
      option: renderOptions(subCompanies),
      disabled: hasSubAdmin ? true : false,
    },
    {
      label: t('department'),
      valueLabel: data.departments,
      onChange: e => setData({ ...data, departments: e.includes(ALL) ? department : e }),
      option: renderOptions(department),
    },
    {
      label: t('position'),
      valueLabel: data.positions,
      onChange: e => setData({ ...data, positions: e.includes(ALL) ? position : e }),
      option: renderOptions(position),
    },
    {
      label: t('function'),
      valueLabel: data.functions,
      onChange: e => setData({ ...data, functions: e.includes(ALL) ? functionStudent : e }),
      option: renderOptions(functionStudent),
    },
    {
      label: t('location'),
      valueLabel: data.locations,
      onChange: e => setData({ ...data, locations: e.includes(ALL) ? location : e }),
      option: renderOptions(location),
    },
  ];

  const renderSelect = templateFilters.map((item, index) => {
    return (
      <Row key={index}>
        <Col key={index} span={24}>
          <Form.Item label={item.label} colon={false} className={css['ModalSettings-form']}>
            <Select
              mode='multiple'
              onChange={item.onChange}
              notFoundContent={t('noData')}
              value={item.valueLabel || undefined}
              disabled={item.disabled}
              placeholder={t('placeholderSelect')}
              allowClear
              showSearch
              className={css['ModalSettings-select']}
              size='large'
            >
              <Option value={ALL} className={css['ModalSettings-select-option']}>
                {t('addAll')}
              </Option>
              {item.option}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    );
  });

  const titleModal = <div className={css['Modal-title']}>{t('settingsVisibleMentors')}</div>;

  const footerModal = (
    <>
      <Button onClick={props.onCloseModal} key='cancel' size='large'>
        {t('cancel')}
      </Button>
      <Button onClick={sendSettings} key='ready' size='large' type='primary'>
        {t('ready')}
      </Button>
    </>
  );

  return (
    <Modal
      className={classNames(css['ModalSettings'])}
      open={props.open}
      title={titleModal}
      onCancel={props.onCloseModal}
      footer={footerModal}
      width={1000}
    >
      <Form layout='vertical'>
        {renderSelect}
        <div className={css['ModalSettings-customFields']}>
          <CustomFieldFilter state={data.customFields} onChange={changeData} visibleCustomModal={props.open} />
        </div>
      </Form>
      <UsersMaterial
        exportUsers={exportMentors}
        text={t('downloadUsersMaterial')}
        title={t('usersMaterialTitle')}
        count={countSettingsMentors || 0}
        loading={exportMentorsLoading}
      />
    </Modal>
  );
};

ModalVisibilitySettings.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

export default ModalVisibilitySettings;
