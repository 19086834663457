export const GET_BADGES_START = 'GET_BADGES_START';
export const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS';
export const GET_BADGES_FAIL = 'GET_BADGES_FAIL';

export const CREATE_BADGE_START = 'CREATE_BADGE_START';
export const CREATE_BADGE_SUCCESS = 'CREATE_BADGE_SUCCESS';
export const CREATE_BADGE_FAIL = 'CREATE_BADGE_FAIL';

export const UPDATE_BADGE_START = 'UPDATE_BADGE_START';
export const UPDATE_BADGE_SUCCESS = 'UPDATE_BADGE_SUCCESS';
export const UPDATE_BADGE_FAIL = 'UPDATE_BADGE_FAIL';

export const GET_STATUS_START = 'GET_STATUS_START';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL';

export const GET_BADGE_ID_START = 'GET_BADGE_ID_START';
export const GET_BADGE_ID_SUCCESS = 'GET_BADGE_ID_SUCCESS';
export const GET_BADGE_ID_FAIL = 'GET_BADGE_ID_FAIL';

export const CHANGE_STATUS_BADGE_START = 'CHANGE_STATUS_BADGE_START';
export const CHANGE_STATUS_BADGE_SUCCESS = 'CHANGE_STATUS_BADGE_SUCCESS';
export const CHANGE_STATUS_BADGE_FAIL = 'CHANGE_STATUS_BADGE_FAIL';

export const GET_APPOINT_BADGE_START = 'GET_APPOINT_BADGE_START';
export const GET_APPOINT_BADGE_SUCCESS = 'GET_APPOINT_BADGE_SUCCESS';
export const GET_APPOINT_BADGE_FAIL = 'GET_APPOINT_BADGE_FAIL';

export const DELETE_BADGE_START = 'DELETE_BADGE_START';
export const DELETE_BADGE_SUCCESS = 'DELETE_BADGE_SUCCESS';
export const DELETE_BADGE_FAIL = 'DELETE_BADGE_FAIL';

export const APPOINTS_BADGE_START = 'APPOINTS_BADGE_START';
export const APPOINTS_BADGE_SUCCESS = 'APPOINTS_BADGE_SUCCESS';
export const APPOINTS_BADGE_FAIL = 'APPOINTS_BADGE_FAIL';

export const GET_EXAMPLE_BADGE_CSV_START = 'GET_EXAMPLE_BADGE_CSV_START';
export const GET_EXAMPLE_BADGE_CSV_SUCCESS = 'GET_EXAMPLE_BADGE_CSV_SUCCESS';
export const GET_EXAMPLE_BADGE_CSV_FAIL = 'GET_EXAMPLE_BADGE_CSV_FAIL';

export const IMPORT_BADGE_USERS_START = 'IMPORT_BADGE_USERS_START';
export const IMPORT_BADGE_USERS_SUCCESS = 'IMPORT_BADGE_USERS_SUCCESS';
export const IMPORT_BADGE_USERS_FAIL = 'IMPORT_BADGE_USERS_FAIL';

export const GET_ISSUE_START = 'GET_ISSUE_START';
export const GET_ISSUE_SUCCESS = 'GET_ISSUE_SUCCESS';
export const GET_ISSUE_FAIL = 'GET_ISSUE_FAIL';

export const GET_LIST_BADGE_START = 'GET_LIST_BADGE_START';
export const GET_LIST_BADGE_SUCCESS = 'GET_LIST_BADGE_SUCCESS';
export const GET_LIST_BADGE_FAIL = 'GET_LIST_BADGE_FAIL';

export const GET_ACTIONS_BADGE_START = 'GET_ACTIONS_BADGE_START';
export const GET_ACTIONS_BADGE_SUCCESS = 'GET_ACTIONS_BADGE_SUCCESS';
export const GET_ACTIONS_BADGE_FAIL = 'GET_ACTIONS_BADGE_FAIL';

export const PUT_BADGE_TOPIC_START = 'PUT_BADGE_TOPIC_START';
export const PUT_BADGE_TOPIC_SUCCESS = 'PUT_BADGE_TOPIC_SUCCESS';
export const PUT_BADGE_TOPIC_FAIL = 'PUT_BADGE_TOPIC_FAIL';

export const DELETE_USER_BADGE_START = 'PUT_BADGE_TOPIC_START';
export const DELETE_USER_BADGE_SUCCESS = 'PUT_BADGE_TOPIC_SUCCESS';
export const DELETE_USER_BADGE_FAIL = 'PUT_BADGE_TOPIC_FAIL';

export const DELETE_MASS_USER_BADGE_START = 'DELETE_MASS_USER_BADGE_START';
export const DELETE_MASS_USER_BADGE_SUCCESS = 'DELETE_MASS_USER_BADGE_SUCCESS';
export const DELETE_MASS_USER_BADGE_FAIL = 'DELETE_MASS_USER_BADGE_FAIL';

export const CLEAR_BADGE_INFO = 'CLEAR_BADGE_INFO';

export const SAVE_BADGE_FILTERS = 'SAVE_BADGE_FILTERS';

export const CLEAR_BADGE_FILTERS = 'CLEAR_BADGE_FILTERS';

export const SAVE_BADGE_INFO_FILTERS = 'SAVE_BADGE_INFO_FILTERS';

export const CLEAR_BADGE_INFO_FILTERS = 'CLEAR_BADGE_INFO_FILTERS';

export const SAVE_BADGE_INFO_SORT = 'SAVE_BADGE_INFO_SORT';

export const SAVE_BADGE_SORT = 'SAVE_BADGE_SORT';

export const ADD_INACTIVE_BADGE = 'ADD_INACTIVE_BADGE';

export const CLEAR_INACTIVE_BADGE = 'CLEAR_INACTIVE_BADGE';
