export const defaultToken = '';
export const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const GET_BANNERS_TOP_BODY_START = 'GET_BANNER_TOP_BODY_START';
export const GET_BANNERS_TOP_BODY_SUCCESS = 'GET_BANNER_TOP_BODY_SUCCESS';
export const GET_BANNERS_TOP_BODY_FAIL = 'GET_BANNER_TOP_BODY_FAIL';

export const GET_BANNER_TOP_BODY_START = 'GET_BANNER_TOP_BODY_START';
export const GET_BANNER_TOP_BODY_SUCCESS = 'GET_BANNER_TOP_BODY_SUCCESS';
export const GET_BANNER_TOP_BODY_FAIL = 'GET_BANNER_TOP_BODY_FAIL';

export const CREATE_BANNER_TOP_BODY_START = 'CREATE_BANNER_TOP_BODY_START';
export const CREATE_BANNER_TOP_BODY_SUCCESS = 'CREATE_BANNER_TOP_BODY_SUCCESS';
export const CREATE_BANNER_TOP_BODY_FAIL = 'CREATE_BANNER_TOP_BODY_FAIL';

export const EDIT_BANNER_TOP_BODY_START = 'EDIT_BANNER_TOP_BODY_START';
export const EDIT_BANNER_TOP_BODY_SUCCESS = 'EDIT_BANNER_TOP_BODY_SUCCESS';
export const EDIT_BANNER_TOP_BODY_FAIL = 'EDIT_BANNER_TOP_BODY_FAIL';

export const DELETE_BANNER_TOP_BODY_START = 'DELETE_BANNER_TOP_BODY_START';
export const DELETE_BANNER_TOP_BODY_SUCCESS = 'DELETE_BANNER_TOP_BODY_SUCCESS';
export const DELETE_BANNER_TOP_BODY_FAIL = 'DELETE_BANNER_TOP_BODY_FAIL';

export const SHOW_BANNER_TOP_BODY_START = 'SHOW_BANNER_TOP_BODY_START';
export const SHOW_BANNER_TOP_BODY_SUCCESS = 'SHOW_BANNER_TOP_BODY_SUCCESS';
export const SHOW_BANNER_TOP_BODY_FAIL = 'SHOW_BANNER_TOP_BODY_FAIL';

export const CLEAR_BANNERS_TOP_BODY = 'CLEAR_BANNERS_TOP_BODY';
