import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getResultStatistic } from '@/store/lms/actions';
import { clearStudents, getStudents } from '@/store/students/actions';

import { selectBlocks } from '@/store/lms/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Checkbox, Col, Flex, Row, Select, Tooltip, Typography } from 'antd';
import DeadlineFilter from '@/components/Topic/DeadlineFilter';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import dayjs from 'dayjs';
import _ from 'lodash';

import css from './ResultsFilter.module.scss';

const { Title, Text } = Typography;

const ResultsFilter = ({ filterTable, setFilterTable, resetFilter }) => {
  const { t } = useTranslation('ResultsFilter');
  const dispatch = useDispatch();
  const { statisticsStatus, blocksTopic } = useSelector(selectBlocks);
  const { students, isLoadingStudents } = useSelector(selectStudents); // suda

  const handleSubmitFilter = () => {
    dispatch(getResultStatistic({ ...filterTable }, 0));
  };

  const correctStatusOptions = useMemo(
    () =>
      statisticsStatus.map(status => ({
        value: status?.value,
        label: status?.text,
      })),
    [statisticsStatus]
  );

  const studentsOptions = useMemo(
    () =>
      students.map(el => ({
        key: el?.id,
        value: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''}`,
        label: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''}`,
      })),
    [students]
  );

  const handleGetStudents = value => {
    dispatch(getStudents(value));
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetStudents, 1000), []);

  const onSearchStudents = value => {
    const filteredValue = value.trim();

    if (!filteredValue.length) {
      setFilterTable({ ...filterTable, fullName: '' });
      dispatch(clearStudents());
      return;
    }

    if (filteredValue.length < 3) return;

    getStudentsListDebounced({ fullName: filteredValue });
  };

  const disabledStartDate = current => {
    return filterTable.endDate ? current && current >= dayjs(filterTable.endDate) : false;
  };

  const disabledEndDate = current => {
    return filterTable.startDate ? current && current <= dayjs(filterTable.startDate) : false;
  };

  const onChangeStartDate = date =>
    setFilterTable(prevFilter => ({
      ...prevFilter,
      startDate: date ? dayjs(date).format('YYYY-MM-DD') : undefined,
    }));

  const onChangeEndDate = date =>
    setFilterTable(prevFilter => ({
      ...prevFilter,
      endDate: date ? dayjs(date).format('YYYY-MM-DD') : undefined,
    }));

  const correctBlocksType = blocksTopic?.map(i => ({ value: String(i.id), label: i.name }));

  return (
    <div className={css['ResultsFilter']}>
      <div className={css['ResultsFilter__header']}>
        <Title level={2}>{t('title')}</Title>
        <div>
          <Checkbox
            checked={filterTable.inspectionMode}
            onChange={e => setFilterTable({ ...filterTable, inspectionMode: e.target.checked })}
          />
          <span className={css['ResultsFilter__checkbox-label']}>{t('inspectionMode')}</span>
          <Tooltip title={t('inspectionModeTooltip')}>
            <ExclamationCircleTwoTone className={css['ResultsFilter__icon']} />
          </Tooltip>
        </div>
      </div>
      {/* Fields */}

      <Row className={css['ResultsFilter-input-container']} gutter={12}>
        {/* fullname */}
        <Col span={6}>
          <Text>{t('fullname')}</Text>
          <Select
            className={css['ResultsFilter-input']}
            value={filterTable.valueFIO}
            placeholder={t('fullnamePlaceholder')}
            options={studentsOptions}
            onSearch={onSearchStudents}
            onChange={e => setFilterTable({ ...filterTable, fullName: e })}
            showSearch
            allowClear
            loading={isLoadingStudents}
          />
        </Col>
        {/* status */}
        <Col span={6}>
          <Text>{t('status')}</Text>
          <Select
            className={css['ResultsFilter-input']}
            value={filterTable.status}
            placeholder={t('statusPlaceholder')}
            options={correctStatusOptions}
            onChange={e => setFilterTable({ ...filterTable, status: e })}
          />
        </Col>
        {/* blocks */}
        <Col span={6}>
          <Text>{t('blockType')}</Text>
          <Select
            className={css['ResultsFilter-input']}
            value={filterTable.blockTypeId}
            placeholder={t('blockTypePlaceholder')}
            options={correctBlocksType}
            onChange={e => setFilterTable({ ...filterTable, blockTypeId: e })}
          />
        </Col>
        <Col span={6}>
          <DeadlineFilter
            startDateValue={filterTable.startDate ? dayjs(filterTable.startDate) : undefined}
            endDateValue={filterTable.endDate ? dayjs(filterTable.endDate) : undefined}
            disabledStartDate={disabledStartDate}
            disabledEndDate={disabledEndDate}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            t={t}
          />
        </Col>
      </Row>

      {/* Buttons */}
      <Flex justify='flex-end' gap={24}>
        <Button type='default' onClick={resetFilter}>
          {t('clear')}
        </Button>
        <Button type='primary' onClick={handleSubmitFilter}>
          {t('submit')}
        </Button>
      </Flex>
    </div>
  );
};

export default ResultsFilter;
