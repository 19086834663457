import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { clearBadgeFilters, getBadges, getStatusBadge } from '@/store/badge/actions';

import { Button, Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import ModalBadge from '@/components/ModalBadge';
import { PlusOutlined } from '@ant-design/icons';

import FiltersBadge from './Content/FiltersBadge';
import TableBadge from './Content/TableBadge';

import css from './Badge.module.scss';

const { Title } = Typography;

const Badge = () => {
  const { t } = useTranslation('badge');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatusBadge());
    dispatch(getBadges({}, 0));

    return () => {
      dispatch(clearBadgeFilters()); // чистим стор с фильтрами
    };
  }, [dispatch]);

  const [isModalBadge, setIsModalBadge] = useState(false);

  const showModalBadge = () => setIsModalBadge(true);

  return (
    <Layout>
      <Content>
        <div className={css['Badge']}>
          <div className={css['Badge-header']}>
            <Title>{t('title')}</Title>
            <div>
              <Button icon={<PlusOutlined />} onClick={showModalBadge} size='large' type='primary'>
                {t('createBadge')}
              </Button>
            </div>
          </div>
          <FiltersBadge />
          <TableBadge />
        </div>

        {isModalBadge && <ModalBadge open={isModalBadge} onCancel={() => setIsModalBadge(false)} isCreate />}
      </Content>
    </Layout>
  );
};

export default Badge;
