/* eslint-disable */
import React, { Component } from 'react';

import { Button, Form, Input, InputNumber, Modal } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class DictionaryCreationDialog extends Component {
  static propTypes = {
    descriptionText: PropTypes.string,
    error: PropTypes.bool,
    limit: PropTypes.bool,
    errorText: PropTypes.string,
    okText: PropTypes.string,
    onOk: PropTypes.func,
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: (props.data && props.data.name) || '',
      limitCost: (props.data && props.data.limitCost) || '',
      dirty: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeNumber = value => {
    this.setState({ limitCost: value });
  };

  handleSubmit = () => {
    const { name, limitCost } = this.state;
    if (name) {
      this.setState({
        nameError: '',
        dirty: true,
      });
      if (limitCost) {
        this.props.onOk({ name: name.trim(), limitCost: limitCost });
      } else {
        this.props.onOk({ name: name.trim() });
      }
    } else {
      this.setState({ nameError: this.props.require });
    }
  };

  render() {
    const { descriptionText, okText, error, errorText, limit, ...props } = this.props;

    return (
      <Modal {...props} footer={null}>
        <Form>
          <p>{descriptionText}</p>
          <Form.Item
            className={classNames({ error: this.state.nameError || error })}
            help={this.state.nameError || (error && this.state.dirty ? errorText : '')}
            validateStatus={this.state.nameError || (error && this.state.dirty) ? 'error' : ''}
          >
            <Input
              maxLength={200}
              name='name'
              value={this.state.name}
              onChange={this.handleChange}
              dataqa='dictionaryItemNameInput'
            />
          </Form.Item>
          {limit && (
            <>
              <p>{this.props.limitText}</p>
              <Form.Item>
                <InputNumber
                  dataqa='dictionaryItemLimitInput'
                  maxLength={200}
                  name='limitCost'
                  value={this.state.limitCost}
                  onChange={this.handleChangeNumber}
                />
              </Form.Item>
            </>
          )}
          <Button type='primary' onClick={this.handleSubmit} htmlType='submit' data-qa='confirmDictionaryItemBtn'>
            {okText}
          </Button>
        </Form>
      </Modal>
    );
  }
}
