// Создаем значение произвольного поля [POST]
export const CREATE_CFIELDVALUE_START = 'CREATE_CFIELDVALUE_START';
export const CREATE_CFIELDVALUE_SUCCESS = 'CREATE_CFIELDVALUE_SUCCESS';
export const CREATE_CFIELDVALUE_FAIL = 'CREATE_CFIELDVALUE_FAIL';

// Обновяем значение произвольного поля [PUT]
export const UPDATE_CFIELDVALUE_START = 'UPDATE_CFIELDVALUE_START';
export const UPDATE_CFIELDVALUE_SUCCESS = 'UPDATE_CFIELDVALUE_SUCCESS';
export const UPDATE_CFIELDVALUE_FAIL = 'UPDATE_CFIELDVALUE_FAIL';

// Возвращаем значение произвольного поля по идентификатору [GET]
export const GET_CFIELDVALUE_START = 'GET_CFIELDVALUE_START';
export const GET_CFIELDVALUE_SUCCESS = 'GET_CFIELDVALUE_SUCCESS';
export const GET_CFIELDVALUE_FAIL = 'GET_CFIELDVALUE_FAIL';
