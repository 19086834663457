import {
  CHANGE_TEMPLATE_BY_ID_FAIL,
  CHANGE_TEMPLATE_BY_ID_START,
  CHANGE_TEMPLATE_BY_ID_SUCCESS,
  CREATE_TEMPLATE_BY_ID_FAIL,
  CREATE_TEMPLATE_BY_ID_START,
  CREATE_TEMPLATE_BY_ID_SUCCESS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_START,
  GET_CHANNELS_SUCCESS,
  GET_MESSAGE_GROUP_FAIL,
  GET_MESSAGE_GROUP_START,
  GET_MESSAGE_GROUP_SUCCESS,
  GET_TEMPLATE_BY_ID_FAIL,
  GET_TEMPLATE_BY_ID_START,
  GET_TEMPLATE_BY_ID_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
  GET_THEMES_FAIL,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getChannels = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/messages/admin/channels`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CHANNELS_START, GET_CHANNELS_SUCCESS, GET_CHANNELS_FAIL],
});

export const getTemplates = (filters, pagination = { page: 0 }) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/messages/admin/templates${getQueryString({ ...filters, ...pagination })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TEMPLATES_START, GET_TEMPLATES_SUCCESS, GET_TEMPLATES_FAIL],
});

export const getThemes = (group, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/messages/admin/themes${getQueryString({ group })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_THEMES_START, GET_THEMES_SUCCESS, GET_THEMES_FAIL],
});

export const getTemplateById = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/messages/admin/templates/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  types: [GET_TEMPLATE_BY_ID_START, GET_TEMPLATE_BY_ID_SUCCESS, GET_TEMPLATE_BY_ID_FAIL],
});

export const changeTemplateById = (id, data, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/messages/admin/templates/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    ...data,
  },
  types: [CHANGE_TEMPLATE_BY_ID_START, CHANGE_TEMPLATE_BY_ID_SUCCESS, CHANGE_TEMPLATE_BY_ID_FAIL],
  onSuccess,
  onError,
});

export const createTemplateById = (id, data, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/messages/admin/templates`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    messageTemplateId: id,
    ...data,
  },
  types: [CREATE_TEMPLATE_BY_ID_START, CREATE_TEMPLATE_BY_ID_SUCCESS, CREATE_TEMPLATE_BY_ID_FAIL],
  onSuccess,
  onError,
  id,
});

export const getMessageGroup = onError => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/messages/admin/groups`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onError,
  types: [GET_MESSAGE_GROUP_START, GET_MESSAGE_GROUP_SUCCESS, GET_MESSAGE_GROUP_FAIL],
});
