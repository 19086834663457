import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createRole, getRoles, updateRole } from '@/store/access/actions';

import { selectAccess } from '@/store/access/selectors';

import { Button, Form, Input, message, Select } from 'antd';

import GroupPermission from './components/GroupPermission';

import classNames from 'classnames';

import css from './EditRole.module.scss';

const { Option } = Select;

const DEFAULT_ROLE_ID = 1;

const EditRole = memo(({ selectedRole, changeVisible, groups, isCopy }) => {
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [roleName, setRoleName] = useState(undefined);
  const [roleKey, setRoleKey] = useState(undefined);
  const [parentRoleId, setParentRoleId] = useState(DEFAULT_ROLE_ID);

  const { rolesDefault } = useSelector(selectAccess);

  const { t } = useTranslation('RolesSettings');

  const dispatch = useDispatch();

  // roleId = number (id роли которую мы выбрали в select) после чего ищем ее в списке дефолтных ролей и выбираем пермишинны которые относятся к этой дефолтной роли
  const funcPermissionRole = roleId => {
    const result = rolesDefault.filter(role => role.id === roleId)[0]?.permissions.map(perm => perm.id) || [];
    setSelectedPermission(result);
  };

  useEffect(() => {
    if (selectedRole) {
      setRoleName(selectedRole.name);
      setRoleKey(selectedRole.keyRole);
      setSelectedPermission(selectedRole.permissions.map(perm => perm.id));
      setParentRoleId(selectedRole.parentId ? selectedRole.parentId : selectedRole.id);
    } else {
      setRoleName(undefined);
      setRoleKey(undefined);
      funcPermissionRole(DEFAULT_ROLE_ID);
    }
  }, [rolesDefault]);

  useEffect(() => {
    dispatch(getRoles({ defaultOnly: true }));
  }, []);

  const onChangeSelect = e => {
    setParentRoleId(e);
    funcPermissionRole(e);
  };

  const parentRoles = useMemo(() => {
    return rolesDefault.map(role => {
      return (
        <Option key={role.id} value={role.id}>
          {role.name}
        </Option>
      );
    });
  }, [rolesDefault]);

  const errorName = useMemo(() => {
    const result = rolesDefault.findIndex(role => role.name === roleName);
    return result === -1 || (selectedRole?.name === roleName && !isCopy);
  }, [roleName]);

  const errorKey = useMemo(() => {
    const result = rolesDefault.findIndex(role => role.keyRole === roleKey);
    return result === -1 || (selectedRole?.keyRole === roleKey && !isCopy);
  }, [roleKey]);

  const onChangeKeyRole = e => {
    if (e) {
      const val = e.target.value;
      const valReplace = val
        .replace(/[^A-Za-z_\s]/gi, '')
        .toUpperCase()
        .replace(/ /g, '_');
      setRoleKey(valReplace);
    }
  };

  const createOrUpdateRole = () => {
    const obj = {
      name: roleName,
      permissionIds: selectedPermission,
      parentId: parentRoleId,
      keyRole: roleKey,
    };

    if (selectedRole && !isCopy) {
      obj['id'] = selectedRole.id;
      dispatch(
        updateRole(
          obj,
          () => dispatch(getRoles({ size: 20 })),
          err => message.error(err?.message)
        )
      );
      changeVisible();
    } else {
      dispatch(
        createRole(
          obj,
          () => dispatch(getRoles({ size: 20 })),
          err => message.error(err?.message)
        )
      );
      changeVisible();
    }
  };

  const render = () => {
    return (
      <div className={css['main']}>
        <Form layout='vertical' className={css['main__form']}>
          <Form.Item
            label={t('roleName')}
            required
            help={!errorName && t('errorName')}
            validateStatus={!errorName ? 'error' : ''}
          >
            <Input
              className={css['main__input']}
              value={roleName}
              onChange={event => setRoleName(event.target.value)}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('roleKey')}
            required
            help={!errorKey && t('errorKey')}
            validateStatus={!errorKey ? 'error' : ''}
          >
            <Input
              className={css['main__input']}
              value={roleKey}
              onChange={onChangeKeyRole}
              maxLength={255}
              placeholder={t('placeholderRoleKey')}
              allowClear
            />
          </Form.Item>
          <Form.Item label={t('parentRole')}>
            <Select
              onChange={onChangeSelect}
              value={rolesDefault?.length > 0 && parentRoleId}
              className={css['main__input']}
              allowClear
            >
              {parentRoles}
            </Select>
          </Form.Item>
          <Form.Item label=' '>
            <Button
              type='primary'
              size='middle'
              disabled={!roleName?.length || !roleKey?.length || !selectedPermission.length || !errorName || !errorKey}
              onClick={createOrUpdateRole}
            >
              {t(selectedRole && !isCopy ? 'editRole' : 'createRole')}
            </Button>
          </Form.Item>
        </Form>
        <div className={classNames(css['main__groups'], (!errorName || !errorKey) && css['main__groups__error'])}>
          {groups.map(group => (
            <GroupPermission
              group={group}
              selectedRole={selectedRole}
              selectedPermission={selectedPermission}
              setSelectedPermission={arr => setSelectedPermission(arr)}
              key={group.id}
            />
          ))}
        </div>
      </div>
    );
  };

  return render();
});

export default EditRole;
