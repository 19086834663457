import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { POKA_PIE_SIZES, POKA_STATUS } from './constants';

import { PlanStatus } from '@shared/constants/plan';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './PieMars.module.scss';

const PieMars = ({
  value,
  isActive,
  onClick,
  text,
  read,
  disabled,
  pokaStatus,
  size = POKA_PIE_SIZES.small,
  studyPlanStatus,
}) => {
  const { t } = useTranslation('iconPokaStatus');
  const currentPie = value === isActive;

  const renderIcons = useMemo(() => {
    switch (studyPlanStatus) {
      case PlanStatus.WAITING:
        return (
          <Tooltip title={t('statusWaiting')}>
            <ExclamationCircleFilled />
          </Tooltip>
        );

      case PlanStatus.REJECTED_BY_MANAGER:
        return (
          <Tooltip title={t('statusRejectedByManager')}>
            <CheckCircleFilled />
          </Tooltip>
        );

      default:
        return <></>;
    }
  }, [studyPlanStatus]);

  const CustomTrigger = React.forwardRef((props, ref) => (
    <div
      {...props}
      ref={ref}
      className={classNames(
        css['Pie'],
        currentPie && css[`Pie-${[isActive]}`],
        currentPie && css[`Pie-isActive`],
        read && css[`Pie-read`],
        disabled && css[`Pie-disable`],
        pokaStatus === POKA_STATUS.FIRST_PASS && css[`Pie-firstPass`],
        pokaStatus === POKA_STATUS.REPASS && css[`Pie-repass`],
        pokaStatus === POKA_STATUS.EXPIRED && css[`Pie-expired`],
        pokaStatus === POKA_STATUS.FINISHED && css[`Pie-finished`],
        css[`Pie-size-${POKA_PIE_SIZES[size]}`]
      )}
      onClick={onClick}
    >
      <span className={css['Pie-icon']}>{renderIcons}</span>
    </div>
  ));

  return (
    <Tooltip title={text}>
      <CustomTrigger />
    </Tooltip>
  );
};

PieMars.propTypes = {
  value: PropTypes.number,
  isActive: PropTypes.number,
  onClick: PropTypes.func,
  text: PropTypes.string,
  read: PropTypes.bool,
  pokaStatus: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string, // 'small' || 'middle' || 'large',
  studyPlanStatus: PropTypes.string,
};

export default PieMars;
