// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-N47lWd.shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-IiRrWR .shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-label-Hfwd9Y[data-value="Montserrat"]:before, .shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-N47lWd.shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-IiRrWR .shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-item-Mun9hC[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-Montserrat-vX0HZB {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__tag-dIEsCL {
  padding: 4px 8px;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__tag__row-qLDwgz {
  display: inline-block;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__options__global-oH3PWo {
  background-color: var(--personalColor);
  color: #fff;
  border-radius: 8px;
  margin-left: 10px;
  padding: 4px 8px;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__tags-container-l1S0Vr {
  width: 100%;
  margin-top: 5px;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__tags-container-l1S0Vr .ant-tag {
  margin-right: 4px;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__autocomplete-popup-CMxLR6 .ant-select-item-option-content {
  overflow: visible;
}

.shared-components-TagsAutocomplete-TagsAutocomplete-module__skeleton-oGEDEj {
  pointer-events: none;
  width: calc(100% + 12px);
  height: 25px;
  margin: 0 -6px;
}
`, "",{"version":3,"sources":["webpack://./shared/components/TagsAutocomplete/TagsAutocomplete.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,gBAAgB;AAClB;;AAOA;EAJE,qBAAqB;AACvB;;AAOA;EAJE,sCAAsC;EACtC,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAOA;EAJE,WAAW;EACX,eAAe;AACjB;;AAMA;EAHE,iBAAiB;AACnB;;AAMA;EAHE,iBAAiB;AACnB;;AAMA;EAHE,oBAAoB;EACpB,wBAAwB;EACxB,YAAY;EACZ,cAAc;AAChB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.tag {\n  padding: 4px 8px;\n}\n.tag__row {\n  display: inline-block;\n}\n\n.options__global {\n  padding: 4px 8px;\n  background-color: var(--personalColor);\n  border-radius: 8px;\n  color: #fff;\n  margin-left: 10px;\n}\n\n.tags-container {\n  width: 100%;\n  margin-top: 5px;\n}\n.tags-container :global .ant-tag {\n  margin-right: 4px;\n}\n\n.autocomplete-popup :global .ant-select-item-option-content {\n  overflow: visible;\n}\n\n.skeleton {\n  width: calc(100% + 12px);\n  height: 25px;\n  margin: 0 -6px;\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-N47lWd`,
	"qlPicker": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-N47lWd`,
	"ql-font": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-IiRrWR`,
	"qlFont": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-IiRrWR`,
	"ql-picker-label": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-label-Hfwd9Y`,
	"qlPickerLabel": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-label-Hfwd9Y`,
	"ql-picker-item": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-item-Mun9hC`,
	"qlPickerItem": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-picker-item-Mun9hC`,
	"ql-font-Montserrat": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-Montserrat-vX0HZB`,
	"qlFontMontserrat": `shared-components-TagsAutocomplete-TagsAutocomplete-module__ql-font-Montserrat-vX0HZB`,
	"tag": `shared-components-TagsAutocomplete-TagsAutocomplete-module__tag-dIEsCL`,
	"tag__row": `shared-components-TagsAutocomplete-TagsAutocomplete-module__tag__row-qLDwgz`,
	"tagRow": `shared-components-TagsAutocomplete-TagsAutocomplete-module__tag__row-qLDwgz`,
	"options__global": `shared-components-TagsAutocomplete-TagsAutocomplete-module__options__global-oH3PWo`,
	"optionsGlobal": `shared-components-TagsAutocomplete-TagsAutocomplete-module__options__global-oH3PWo`,
	"tags-container": `shared-components-TagsAutocomplete-TagsAutocomplete-module__tags-container-l1S0Vr`,
	"tagsContainer": `shared-components-TagsAutocomplete-TagsAutocomplete-module__tags-container-l1S0Vr`,
	"autocomplete-popup": `shared-components-TagsAutocomplete-TagsAutocomplete-module__autocomplete-popup-CMxLR6`,
	"autocompletePopup": `shared-components-TagsAutocomplete-TagsAutocomplete-module__autocomplete-popup-CMxLR6`,
	"skeleton": `shared-components-TagsAutocomplete-TagsAutocomplete-module__skeleton-oGEDEj`
};
export default ___CSS_LOADER_EXPORT___;
