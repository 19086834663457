/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearChapters,
  createChapter,
  deleteChapter,
  getChapters,
  moveChapter,
  moveChapterBlock,
  removeChapterBlock,
  renameChapter,
} from '@/store/chapters/actions';

import { selectChapters } from '@/store/chapters/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Dropdown, Form, Input, message, Modal, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { EllipsisOutlined, EyeInvisibleOutlined, HolderOutlined } from '@ant-design/icons';

import { isExecutiveTask } from '@shared/utils/topic-utils';

import CloseIcon from '@/assets/images/close-icon.svg?react';
import classNames from 'classnames';

import css from './index.module.scss';

import {
  TOPIC_BLOCK_EXECUTIVE_TASK_CREATE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_CHAPTER_CREATE,
  TOPIC_CHAPTER_DELETE,
  TOPIC_CHAPTER_UPDATE,
} from '@/constants/permissions';

// dnd styles
const getItemStyle = (isDragging, isChapter, draggableStyle) => ({
  border: isDragging ? '1px dashed #ccc' : 'none',
  width: '100%',
  paddingBottom: isChapter ? 9 : 5,
  ...draggableStyle,
});
const getListStyle = isDraggingOver => ({});

const DragItem = () => (
  <div className={css.DragIcon}>
    <HolderOutlined />
  </div>
);

const Block = ({ name, id, disconnectBlock, parentChapterID, currentUser, topicTypeId }) => {
  return (
    <div className={css['Chapter-block']}>
      <DragItem />
      <div className={css['Chapter-fullWidth']}>{name}</div>
      {parentChapterID &&
        (currentUser.authorities.includes(TOPIC_CHAPTER_UPDATE) ||
          (topicTypeId === 25 && currentUser.authorities.includes('TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE') && (
            <div onClick={() => disconnectBlock(parentChapterID, id)} className={css.CloseButton}>
              <CloseIcon />
            </div>
          )))}
    </div>
  );
};

const Chapter = ({
  id,
  index,
  name,
  blocks,
  active,
  RemoveChapter,
  dispatch,
  updateChaptersState,
  canEdit,
  topicTypeId,
}) => {
  const { t } = useTranslation('chapterPanel');
  const [confirmRemoveChapter, setConfirmRemoveChapter] = useState(false);
  const [confirmEditChapter, setConfirmEditChapter] = useState(false);
  const [chapterName, setChapterName] = useState(name);
  const { currentUser } = useSelector(selectUsers);

  const onSubmitChapterNameChange = () => {
    dispatch(
      renameChapter(
        id,
        {
          name: chapterName,
        },
        () => {
          updateChaptersState();
          setConfirmEditChapter(false);
        }
      )
    );
  };

  const [errorText, setErrorText] = useState('');

  const handleChapterNameChange = e => {
    setChapterName(e.target.value);
    /^\s/.test(e.target.value) || e.target.value.length === 0 ? setErrorText(t('required')) : setErrorText('');
  };

  const disconnectBlock = (chapterId, blockId) => {
    dispatch(removeChapterBlock(chapterId, { blockId }, () => updateChaptersState()));
  };

  const checkDropMenuUpdate =
    currentUser.authorities.includes(TOPIC_CHAPTER_UPDATE) ||
    (isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE));

  const checkDropMenuDelete =
    currentUser.authorities.includes(TOPIC_CHAPTER_DELETE) ||
    (isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE));

  const dropDownMenu = [
    ...(checkDropMenuUpdate
      ? [
          {
            key: '1',
            label: (
              <div data-qa='renameBtn' onClick={() => setConfirmEditChapter(true)}>
                {t('rename')}
              </div>
            ),
          },
        ]
      : []),
    ...(checkDropMenuDelete
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBtn' onClick={() => setConfirmRemoveChapter(true)}>
                {t('delete')}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <ConfirmationModal
        open={confirmRemoveChapter}
        onOk={() => {
          RemoveChapter(id);
          setConfirmRemoveChapter(false);
        }}
        onCancel={() => setConfirmRemoveChapter(false)}
        title={t('deleteChapter')}
        okText={t('delete')}
        confirmationText={t('description')}
        deleteBtn
      />
      <Modal
        open={confirmEditChapter}
        title={t('editChapter')}
        onCancel={() => setConfirmEditChapter(false)}
        footer={null}
      >
        <div>
          <div className={css['Chapter-title-edit']}>{t('title')}</div>
          <Form>
            <Form.Item className={classNames({ error: 'Chapter-error' })} help={errorText}>
              <Input
                data-qa='chapterNameChangeInput'
                maxLength={255}
                value={chapterName}
                autoFocus
                onChange={handleChapterNameChange}
              />
            </Form.Item>
            <div className={css['Chapter-edit-btn']}>
              <Button
                data-qa='submitChapterNameChangeBtn'
                onClick={onSubmitChapterNameChange}
                type='primary'
                htmlType='submit'
                disabled={errorText !== ''}
              >
                {t('save')}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Droppable droppableId={id ? id.toString() : `chapter-${index}`} type='droppableBlock'>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className={css.Chapter}>
            <div className={css['Chapter-title']}>
              <DragItem />
              <div className={active ? css['Chapter-fullWidth'] : classNames(css.inactive, css['Chapter-fullWidth'])}>
                {name}
                {!active && (
                  <Tooltip title={t('hide')}>
                    <EyeInvisibleOutlined style={{ marginLeft: 7 }} />
                  </Tooltip>
                )}
              </div>

              {((isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE)) ||
                currentUser.authorities.includes(TOPIC_CHAPTER_UPDATE) ||
                currentUser.authorities.includes(TOPIC_CHAPTER_DELETE)) &&
                id &&
                canEdit && (
                  <Dropdown
                    menu={{ items: dropDownMenu }}
                    overlayClassName={css['Dropdown-menu']}
                    placement='bottomRight'
                    trigger={['click']}
                  >
                    <EllipsisOutlined data-qa='optionsList' className={css.More} />
                  </Dropdown>
                )}
            </div>

            {active &&
              blocks.map((block, index) => (
                <Draggable
                  key={block.id}
                  draggableId={block.id.toString()}
                  index={index}
                  isDragDisabled={
                    !currentUser.authorities.includes(TOPIC_CHAPTER_UPDATE) &&
                    topicTypeId === 25 &&
                    !currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE)
                  }
                >
                  {(provided, snapshot) => (
                    <div style={{ display: 'flex' }}>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, false, provided.draggableProps.style)}
                      >
                        <Block
                          parentChapterID={id}
                          name={block.name}
                          index={index}
                          key={block.id}
                          id={block.id}
                          disconnectBlock={disconnectBlock}
                          canEdit={canEdit}
                          currentUser={currentUser}
                          topicTypeId={topicTypeId}
                        />
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

const ChapterPanel = ({ topicId, canModify, topicTypeId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('chapterPanel');
  const { chapters } = useSelector(selectChapters);
  const { currentUser } = useSelector(selectUsers);
  const updateChaptersState = () => dispatch(getChapters(topicId));
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    updateChaptersState();
    setCanEdit(canModify);

    return () => dispatch(clearChapters());
  }, []);

  const AddChapter = () => {
    dispatch(createChapter({ name: t('newChapter'), topicId }, () => updateChaptersState()));
  };

  const RemoveChapter = id => {
    dispatch(deleteChapter(id, () => updateChaptersState()));
  };

  const moveChapters = mappedChapters => {
    dispatch(moveChapter({ chapters: mappedChapters, topicId }, () => updateChaptersState()));
  };

  const MoveBlock = (blockId, destChapterId, destPosition) => {
    if (isNaN(destChapterId)) {
      message.error(t('dragBlockError'));
      return;
    }

    dispatch(moveChapterBlock({ blockId, destChapterId, destPosition }, () => updateChaptersState()));
  };

  const onDragEnd = result => {
    if (result.type === 'droppableBlock' && result.destination) {
      MoveBlock(+result.draggableId, +result.destination.droppableId, result.destination.index);
    }
    if (result.type === 'droppableChapter') {
      chapters.splice(result.destination.index, 0, chapters.splice(result.source.index, 1)[0]);
      const mappedChapters = chapters.map((chapter, index) => {
        chapter.position = index;
        return {
          blockId: chapter.id ? null : chapter.blocks[0].id,
          chapterId: chapter.id ? chapter.id : null,
          position: chapter.position,
        };
      });
      moveChapters(mappedChapters);
    }
  };

  return (
    <div className={css.Wrapper}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='chapters' type='droppableChapter'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {chapters.length > 0 &&
                chapters.map((chapter, index) => (
                  <Draggable
                    isDragDisabled={
                      !currentUser.authorities.includes(TOPIC_CHAPTER_UPDATE) &&
                      topicTypeId === 25 &&
                      !currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE)
                    }
                    key={`chapter-${index}`}
                    draggableId={`chapter-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <div
                          data-qa='asd'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, true, provided.draggableProps.style)}
                        >
                          {chapter && (
                            <Chapter
                              index={index}
                              key={`${chapter.name}-${index}`}
                              position={chapter.position}
                              id={chapter.id}
                              name={chapter.name}
                              blocks={chapter.blocks}
                              active={chapter.active}
                              RemoveChapter={RemoveChapter}
                              dispatch={dispatch}
                              updateChaptersState={updateChaptersState}
                              canEdit={canEdit}
                              topicTypeId={topicTypeId}
                            />
                          )}
                        </div>
                        {provided.placeholder}
                      </>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={css.AddChapterBtn}>
        {((!isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_CHAPTER_CREATE)) ||
          (isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_CREATE))) && (
          <Button type='link' onClick={AddChapter}>
            {t('addChapter')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChapterPanel;
