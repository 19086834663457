const External = props => (
  <div>
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10'>
      <g stroke='#2D3540' fill='none'>
        <path d='M8.812 4.185v3.953c0 .7-1.412 1.268-3.153 1.268s-3.153-.567-3.153-1.268V4.185' />
        <path d='M5.659 6.108.672 3.34 5.659.572l4.987 2.768z' />
      </g>
    </svg>

    {props.name}
  </div>
);

export default External;
