/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Card, Tooltip } from 'antd';
import Rate from '@/components/Rate';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './CompilationHeader.module.scss';

import { MASS_FILTER_INTERACT, STUDY_PLAN_CREATE_BY_ADMIN, TRACK_ADD } from '@/constants/permissions';

class CompilationHeader extends Component {
  static propTypes = {
    cover: PropTypes.string,
    dataQa: PropTypes.string,
    manager: PropTypes.object,
    getFile: PropTypes.func,
    match: PropTypes.any,
    name: PropTypes.string,
    rate: PropTypes.number,
    shortDescription: PropTypes.string,
    addToPlan: PropTypes.func,
    author: PropTypes.string,
    isTrack: PropTypes.bool,
    authorLabel: PropTypes.string,
    currentUser: PropTypes.object,
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      cover: null,
    };
  }

  componentDidMount() {
    if (this.props.cover) {
      this.props.getFile(this.props.cover, blob => {
        const cover = URL.createObjectURL(blob);
        this.setState({ cover });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cover && prevProps.cover !== this.props.cover) {
      this.props.getFile(this.props.cover, blob => {
        const cover = URL.createObjectURL(blob);
        this.setState({ cover });
      });
    }
  }

  render() {
    const { name, shortDescription, rate, manager = {}, t, dataQa, author, authorLabel } = this.props;

    const renderImage = (
      <div className={css.Header__image}>
        <img src={this.state.cover} alt='' />
      </div>
    );

    const studyPlanAccess =
      this.props.currentUser.authorities.includes(MASS_FILTER_INTERACT) ||
      this.props.currentUser.authorities.includes(STUDY_PLAN_CREATE_BY_ADMIN);

    const showAddToPlan = this.props.isTrack
      ? this.props.currentUser.authorities.includes(TRACK_ADD) && studyPlanAccess
      : studyPlanAccess;

    return (
      <div className={css.Header}>
        <div className={css.Header__content}>
          <h3 data-qa={`${dataQa}H3`}>{name}</h3>
          <p data-qa={`${dataQa}ShortDescription`}>{shortDescription}</p>
        </div>
        <div className={this.props.isTrack ? css.Header__contentTrack : css.Header__content}>
          <Card style={{ borderRadius: 8 }}>
            <div className={css.Header__card}>
              {!this.props.isTrack && (
                <div className={css.Header__card__col}>
                  <div>{t('rate')}</div>
                  <Rate count={5} value={rate} disabled noChange />
                </div>
              )}
              {manager && !this.props.isTrack && (
                <div className={css.Header__card__col}>
                  <div>{t('expert')}</div>
                  <div data-qa={`${dataQa}ExpertName`} className={css.Header__expert}>
                    {manager.name}
                  </div>
                </div>
              )}
              {this.props.isTrack && (
                <div className={css.Header__card__col}>
                  <div>{authorLabel}</div>
                  <div data-qa={`${dataQa}ExpertName`} className={css.Header__expert}>
                    {author}
                  </div>
                </div>
              )}
            </div>
          </Card>
          {typeof this.props.addToPlan === 'function' && (
            <Tooltip
              title={this.props.tooltipText && this.props.disabled ? this.props.tooltipText : false}
              placement='top'
            >
              <div style={{ marginTop: '1rem', textAlign: 'right' }}>
                {showAddToPlan && (
                  <Button
                    data-qa='addToPlanBtn'
                    onClick={this.props.addToPlan}
                    type='primary'
                    disabled={this.props.disabled}
                  >
                    {t('addToPlan')}
                  </Button>
                )}
              </div>
            </Tooltip>
          )}
        </div>
        {renderImage}
      </div>
    );
  }
}

const mapStateToProps = createSelector([selectUsers], users => ({
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('compilationHeader')(CompilationHeader));
