// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-ModalBadge-ModalBadge-module__ql-picker-oDSzYt.admin-src-components-ModalBadge-ModalBadge-module__ql-font-fZaZEs .admin-src-components-ModalBadge-ModalBadge-module__ql-picker-label-LQgnzd[data-value="Montserrat"]:before, .admin-src-components-ModalBadge-ModalBadge-module__ql-picker-oDSzYt.admin-src-components-ModalBadge-ModalBadge-module__ql-font-fZaZEs .admin-src-components-ModalBadge-ModalBadge-module__ql-picker-item-smIDFR[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-ModalBadge-ModalBadge-module__ql-font-Montserrat-jbyGnw {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-alert-tsp08q {
  padding: 16px;
}

.admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-photoError-PGa3_7 {
  border: 2px solid #f5222d;
  border-radius: 12px;
}

.admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-upload-img-Iv4cfh {
  object-fit: contain;
  width: 35%;
  height: auto;
  display: none;
}

.admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-upload-img-Iv4cfh.admin-src-components-ModalBadge-ModalBadge-module__show-Qy7ytT {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/ModalBadge/ModalBadge.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,aAAa;AACf;;AAMA;EAHE,yBAAyB;EACzB,mBAAmB;AACrB;;AAKA;EAFE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAIA;EADE,cAAc;AAChB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ModalBadge-form-alert {\n  padding: 16px;\n}\n.ModalBadge-form-photoError {\n  border: 2px solid #f5222d;\n  border-radius: 12px;\n}\n.ModalBadge-form-upload-img {\n  width: 35%;\n  height: auto;\n  object-fit: contain;\n  display: none;\n}\n.ModalBadge-form-upload-img.show {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-ModalBadge-ModalBadge-module__ql-picker-oDSzYt`,
	"qlPicker": `admin-src-components-ModalBadge-ModalBadge-module__ql-picker-oDSzYt`,
	"ql-font": `admin-src-components-ModalBadge-ModalBadge-module__ql-font-fZaZEs`,
	"qlFont": `admin-src-components-ModalBadge-ModalBadge-module__ql-font-fZaZEs`,
	"ql-picker-label": `admin-src-components-ModalBadge-ModalBadge-module__ql-picker-label-LQgnzd`,
	"qlPickerLabel": `admin-src-components-ModalBadge-ModalBadge-module__ql-picker-label-LQgnzd`,
	"ql-picker-item": `admin-src-components-ModalBadge-ModalBadge-module__ql-picker-item-smIDFR`,
	"qlPickerItem": `admin-src-components-ModalBadge-ModalBadge-module__ql-picker-item-smIDFR`,
	"ql-font-Montserrat": `admin-src-components-ModalBadge-ModalBadge-module__ql-font-Montserrat-jbyGnw`,
	"qlFontMontserrat": `admin-src-components-ModalBadge-ModalBadge-module__ql-font-Montserrat-jbyGnw`,
	"ModalBadge-form-alert": `admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-alert-tsp08q`,
	"modalBadgeFormAlert": `admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-alert-tsp08q`,
	"ModalBadge-form-photoError": `admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-photoError-PGa3_7`,
	"modalBadgeFormPhotoError": `admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-photoError-PGa3_7`,
	"ModalBadge-form-upload-img": `admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-upload-img-Iv4cfh`,
	"modalBadgeFormUploadImg": `admin-src-components-ModalBadge-ModalBadge-module__ModalBadge-form-upload-img-Iv4cfh`,
	"show": `admin-src-components-ModalBadge-ModalBadge-module__show-Qy7ytT`
};
export default ___CSS_LOADER_EXPORT___;
