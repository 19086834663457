/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectBlocks } from '@/store/lms/selectors';

import { Button, Dropdown } from 'antd';
import { SendCommentModal } from '@/pages/SendReviewModal';
import { ArrowRightOutlined } from '@ant-design/icons';

import { BlockStatus } from '../../Results/ResultsTable/types';

import PropTypes from 'prop-types';

const TestActionDropdown = ({ changeTestStatus, showTestResultModal, block, filterTable }) => {
  const [showModalRejected, setShowModalRejected] = useState(false);

  const { statisticCurrentPage } = useSelector(selectBlocks);

  const isCustomTestReadyToApprove = block.blockStatus === BlockStatus.WAITING;

  const { t } = useTranslation(['coursesTopicRow']);
  const { t: tResult } = useTranslation('result');

  const approveTest = async () => {
    const queryParams = {
      userId: block.userId,
      blockId: block.blockId,
      isFinish: false,
      page: statisticCurrentPage,
      ...filterTable,
    };

    try {
      await changeTestStatus(queryParams);
    } catch (error) {
      console.error(error);
    }
    setShowModalRejected(false);
  };

  const rejectTest = () => {
    setShowModalRejected(true);
  };

  const actionTestsIndex = {
    rejectTest: () => rejectTest(),
    approveTest: () => approveTest(),
    viewTestResults: () => showTestResultModal(block),
  };

  const commentSubmitRejectedCourseRequest = async comment => {
    const queryParams = {
      userId: block.userId,
      blockId: block.blockId,
      comment,
      isFinish: true,
      page: statisticCurrentPage,
      ...filterTable,
    };

    try {
      await changeTestStatus(queryParams);
    } catch (error) {
      console.error(error);
    }
    setShowModalRejected(false);
  };

  const hideRejectedCourseRequest = comment => {
    if (comment && comment.length > 0) {
      return setShowModalRejected(!showModalRejected);
    }
    setShowModalRejected(false);
  };

  const texts = {
    reason: t('reason'),
    comment: t('comment'),
    denying: t('denying'),
    deleting: t('deleting'),
    rescheduling: t('rescheduling'),
    max: t('max'),
  };

  const menu = [
    {
      key: 'viewTestResults',
      label: tResult('viewTestResults'),
      onClick: actionTestsIndex['viewTestResults'],
    },
  ];

  if (isCustomTestReadyToApprove) {
    menu.push({
      key: 'rejectTest',
      label: tResult('toReject'),
      onClick: actionTestsIndex['rejectTest'],
    });
    menu.push({
      key: 'approveTest',
      label: tResult('toAccept'),
      onClick: actionTestsIndex['approveTest'],
    });
  }

  return (
    <>
      <Dropdown menu={{ items: menu }} trigger={['click']}>
        <ArrowRightOutlined />
      </Dropdown>
      {showModalRejected && (
        <SendCommentModal
          rejected
          open
          submitDeleteComment={commentSubmitRejectedCourseRequest}
          cancelDeleteComment={hideRejectedCourseRequest}
          texts={texts}
        />
      )}
    </>
  );
};

TestActionDropdown.propTypes = {
  changeTestStatus: PropTypes.func,
  showTestResultModal: PropTypes.func,
  block: PropTypes.object,
  filterTable: PropTypes.object,
};

export default TestActionDropdown;
