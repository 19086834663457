/* eslint-disable */
import { D360_CREATE_FILTERS_FAIL, D360_CREATE_FILTERS_START, D360_CREATE_FILTERS_SUCCESS } from './constants';

const initialState = {
  loading: false,
  filters: [],
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    case D360_CREATE_FILTERS_START:
      return { ...state, loading: true };

    case D360_CREATE_FILTERS_SUCCESS:
      return { ...state, filters: response.data, loading: false };

    case D360_CREATE_FILTERS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
