import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectTopics } from '@/store/topics/selectors';

import { Button, Col, DatePicker, Flex, Form, Input, Row, Select, Typography } from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../../LoggingAdmin.module.scss';

const { Title } = Typography;

const Filters = ({ filters, onChangeFilter, onClearFilter, onSubmitFilters }) => {
  const { t } = useTranslation('loggingAdmin');
  const { sistemActionsList } = useSelector(selectTopics);

  // Даты
  const disabledStartDate = current => {
    return filters.from ? current && current < dayjs(filters.from) : false;
  };

  const disabledEndDate = current => {
    return filters.to ? current && current > dayjs(filters.to) : false;
  };

  // Действия
  const actionsLogSelect = useMemo(
    () =>
      Array.isArray(sistemActionsList) &&
      sistemActionsList.length > 0 &&
      sistemActionsList.map(action => ({ id: action.name, value: action.name, label: action.value })),
    [sistemActionsList]
  );

  return (
    <div className={css['LoggingAdmin-filters']}>
      <Title className={css['LoggingAdmin-filters-title']} level={2}>
        {t('filter')}
      </Title>

      <Form layout='vertical'>
        <Row justify='space-between' gutter={16}>
          {/* ИНИЦИАТОР ДЕЙСТВИЯ ФИО/ЛОГИН */}
          <Col span={8}>
            <Form.Item label={t('initial')} colon={false}>
              <Input
                className={css['Logging-filters-input']}
                placeholder={t('userPlaceholder')}
                name='loginOrFullName'
                value={filters?.loginOrFullName}
                onChange={e => onChangeFilter(e.target.name, e.target?.value)}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* ДЕЙСТВИЕ */}
          <Col span={8}>
            <Form.Item label={t('respond')} colon={false}>
              <Select
                onChange={e => onChangeFilter('type', e)}
                value={filters?.type}
                name='type'
                options={actionsLogSelect || []}
                placeholder={t('actionPlaceholder')}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* ПЕРИОД ОТ  */}
          <Col span={4}>
            <Form.Item label={t('from')} colon={false}>
              <DatePicker
                className={css['LoggingAdmin-filters-input']}
                name='from'
                value={filters?.from ? dayjs(filters?.from) : undefined}
                onChange={date => onChangeFilter('from', date ? dayjs(date).format('YYYY-MM-DD') : undefined)}
                format='DD.MM.YYYY'
                allowClear
                disabledDate={disabledEndDate}
                placeholder={t('from')}
              />
            </Form.Item>
          </Col>
          {/* ДО */}
          <Col span={4}>
            <Form.Item label={t('to')} colon={false}>
              <DatePicker
                className={css['LoggingAdmin-filters-input']}
                name='to'
                value={filters?.to ? dayjs(filters?.to) : undefined}
                onChange={date => onChangeFilter('to', date ? dayjs(date).format('YYYY-MM-DD') : undefined)}
                format='DD.MM.YYYY'
                allowClear
                disabledDate={disabledStartDate}
                placeholder={t('to')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify='flex-end' className={css['LoggingAdmin-filters-buttons']} gap={24}>
          <Button onClick={onClearFilter}>{t('clear')}</Button>
          <Button onClick={onSubmitFilters} type='primary'>
            {t('apply')}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    from: PropTypes.string,
    loginOrFullName: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string,
  }),
  onChangeFilter: PropTypes.func,
  onClearFilter: PropTypes.func,
  onSubmitFilters: PropTypes.func,
};

export default Filters;
