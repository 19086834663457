/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBookInUse } from '@/store/books/actions';

import { List } from 'antd';
import CustomModal from '@/components/Modal/Modal';

import PropTypes from 'prop-types';

import css from './AddressBook.module.scss';

class AddressBook extends Component {
  static propTypes = {
    textWindowTitle: PropTypes.string,
    textSubmitButton: PropTypes.string,
    onButtonSubmit: PropTypes.func,
    onButtonClose: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    topicId: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open || false,
      okButtonDisabled: true,
      addressId: null,
      data: [],
    };
  }

  componentDidMount() {
    this.props.getBookInUse(
      this.props.topicId,
      data => this.setState({ data }),
      err => console.log(`Get book in use error ${err}`)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  getMapLink(addr) {
    return 'https://yandex.ru/maps/?text=' + addr;
  }

  onClickItem(item) {
    // Выключаем кнопку "выбрать адрес"
    this.setState({ okButtonDisabled: true });

    // Сбросим ранее выделенный элемент
    let elems = document.getElementsByClassName(css.AddressBookListItem);
    if (elems !== null) {
      for (let eindex = 0; eindex < elems.length; eindex++) {
        elems[eindex].style.backgroundColor = '#fff';
      }
    }

    // Получаем выбранный элемент
    let el = document.getElementById('abli' + item.id);
    if (el !== null) {
      el.style.backgroundColor = '#dadada';

      if (item.accessible) {
        this.setState({
          okButtonDisabled: false,
          addressId: item.id,
        });
      }
    }
  }

  addressList() {
    const dataSource = this.state.data;

    return (
      <div className={css.AddressBookList}>
        <List
          bordered={false}
          itemLayout='horizontal'
          dataSource={dataSource}
          size='small'
          split={true}
          renderItem={item => (
            <List.Item id={'abli' + item.id} className={css.AddressBookListItem} onClick={() => this.onClickItem(item)}>
              <List.Item.Meta title={item.address} />
              <div style={{ fontFamily: 'Helvetica, Arial, Verdana, Tahoma, Montserrat500, sans-serif' }}>
                {item.userName}
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  }

  render() {
    return (
      <CustomModal
        cancelBtn={false}
        fileModal={true}
        title={this.props.textWindowTitle}
        cancelBtnText='CANCEL'
        editable={false}
        headerFixToModal={false}
        modalCategory={true}
        okText={this.props.textSubmitButton}
        hideFooter={false}
        onOk={() => {
          this.props.onButtonSubmit(this.state.addressId);
        }}
        onCancel={this.props.onButtonClose}
        style={{
          padding: '15px',
          backgroundColor: '#fff',
          width: '700px !important',
        }}
        open={this.props.open}
        children={this.addressList()}
        width={650}
      />
    );
  }
}

const mapActionsToProps = {
  getBookInUse,
};

export default connect(null, mapActionsToProps)(AddressBook);
