/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBitrixWebhook,
  getTypeformToken,
  getWebinarToken,
  setBitrixWebhook,
  setTypeformToken,
  setWebinarToken,
} from '@/store/api/actions';

import { selectApi } from '@/store/api/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Card, Col, Input, Row } from 'antd';

import {
  BITRIX_WEBHOOK_READ,
  BITRIX_WEBHOOK_UPDATE,
  TYPEFORM_TOKEN_READ,
  TYPEFORM_TOKEN_UPDATE,
  WEBINAR_TOKEN_READ,
  WEBINAR_TOKEN_UPDATE,
} from '../../../../constants/permissions';

import css from './TabApiSettings.module.scss';

const TabApiSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('varDirectory');
  const { tokens, isLoadingButtons } = useSelector(selectApi);
  const { currentUser } = useSelector(selectUsers);

  // Иницилизация всех сторонних апишек
  const [apiItems, setApiItems] = useState([
    {
      name: 'Typeform',
      getToken: () => dispatch(getTypeformToken()),
      setToken: token => dispatch(setTypeformToken(window.btoa(token))),
      value: '',
      permissionRead: TYPEFORM_TOKEN_READ,
      permissionUpdate: TYPEFORM_TOKEN_UPDATE,
    },
    {
      name: 'MTCLink',
      getToken: () => dispatch(getWebinarToken()),
      setToken: token => dispatch(setWebinarToken(token)),
      value: '',
      permissionRead: WEBINAR_TOKEN_READ,
      permissionUpdate: WEBINAR_TOKEN_UPDATE,
    },
    {
      name: 'Bitrix',
      getToken: () => dispatch(getBitrixWebhook()),
      setToken: token => dispatch(setBitrixWebhook(token)),
      value: '',
      permissionRead: BITRIX_WEBHOOK_READ,
      permissionUpdate: BITRIX_WEBHOOK_UPDATE,
    },
  ]);

  // Вызов получение токенов
  useEffect(() => {
    apiItems.forEach(item => {
      if (currentUser.authorities.includes(item.permissionRead)) {
        item.getToken();
      }
    });
  }, []);

  const onChangeApi = useCallback((name, value) => {
    setApiItems(prevState => prevState.map(obj => (obj.name === name ? { ...obj, value, isChanged: true } : obj)));
  }, []);

  useEffect(() => {
    setApiItems(prevState =>
      prevState.map(obj => {
        switch (obj.name) {
          case 'Typeform':
            return { ...obj, value: tokens.Typeform };
          case 'MTCLink':
            return { ...obj, value: tokens.WebinarRu };
          case 'Bitrix':
            return { ...obj, value: tokens.Bitrix };
          default:
            return obj;
        }
      })
    );
  }, [tokens]);

  const handleSave = item => {
    item.setToken(item.value);

    const newState = apiItems.map(obj => {
      if (obj.name === item.name) {
        return { ...obj, isChanged: false };
      }
      return obj;
    });

    setApiItems(newState);
  };

  return (
    <Row className={css['TabMargin']}>
      <Col span={24}>
        {apiItems.map(
          (item, index) =>
            currentUser.authorities.includes(item.permissionRead) && (
              <Card
                key={index}
                title={
                  <span className={css['TabMargin-title']}>
                    {item.name === 'Bitrix' ? t('bitrixChannelTitle') : t('apiLabel', { name: item.name })}
                  </span>
                }
                size='small'
                className={css['TabApiSettings_card']}
              >
                <Input.Password
                  placeholder={t('inputToken')}
                  value={item.value}
                  disabled={isLoadingButtons[item.name] || !currentUser.authorities.includes(item.permissionUpdate)}
                  onChange={e => onChangeApi(item.name, e.target.value)}
                  size='large'
                />

                <div className={css['ButtonContainer']}>
                  <Button
                    disabled={!currentUser.authorities.includes(item.permissionUpdate)}
                    onClick={() => onChangeApi(item.name, '')}
                    type='default'
                    size='large'
                  >
                    {t('reset')}
                  </Button>
                  <Button
                    disabled={!currentUser.authorities.includes(item.permissionUpdate) || !item.isChanged}
                    onClick={() => handleSave(item)}
                    type='primary'
                    size='large'
                  >
                    {t('save')}
                  </Button>
                </div>
              </Card>
            )
        )}
      </Col>
    </Row>
  );
};

export default TabApiSettings;
