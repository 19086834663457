import {
  GET_INSPECTION_STATUS_START,
  GET_INSPECTION_STATUS_SUCCESS,
  GET_TOPIC_INSPECTION_START,
  GET_TOPIC_INSPECTION_SUCCESS,
  GET_USERS_FOR_INSPECTIONS_START,
  GET_USERS_FOR_INSPECTIONS_SUCCESS,
} from './constants';

const initialState = {
  isLoading: {
    getTopicInspection: false,
    getInspectionStatus: false,
    getUsersForInspections: false,
  },
  pagination: {
    getTopicInspection: {
      total: 0,
      pageSize: 0,
      pageNumber: 0,
    },
  },
  topicInspection: [],
  inspectionStatus: [],
  usersForInspections: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_TOPIC_INSPECTION_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTopicInspection: true,
        },
        topicInspection: [],
      };

    case GET_TOPIC_INSPECTION_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getTopicInspection: false,
        },
        pagination: {
          getTopicInspection: {
            total: response.totalElements,
            pageNumber: response.number,
            pageSize: response.size,
          },
        },
        topicInspection: response.content,
      };

    case GET_INSPECTION_STATUS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInspectionStatus: true,
        },
        inspectionStatus: [],
      };

    case GET_INSPECTION_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getInspectionStatus: false,
        },
        inspectionStatus: response,
      };

    case GET_USERS_FOR_INSPECTIONS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getUsersForInspections: true,
        },
        usersForInspections: [],
      };

    case GET_USERS_FOR_INSPECTIONS_SUCCESS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getUsersForInspections: false,
        },
        usersForInspections: response,
      };

    default:
      return state;
  }
};
