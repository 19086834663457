import {
  ADD_BANK_CATEGORIES,
  ADD_QUESTION_FAIL,
  ADD_QUESTION_START,
  ADD_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  DELETE_QUESTION_START,
  DELETE_QUESTION_SUCCESS,
  GET_BANK_CATEGORIES_FAIL,
  GET_BANK_CATEGORIES_START,
  GET_BANK_CATEGORIES_SUCCESS,
  GET_COUNT_QUESTIONS_FAIL,
  GET_COUNT_QUESTIONS_START,
  GET_COUNT_QUESTIONS_SUCCESS,
  GET_EXPORT_QUESTION_CSV_FAIL,
  GET_EXPORT_QUESTION_CSV_START,
  GET_EXPORT_QUESTION_CSV_SUCCESS,
  GET_QUESTIONS_LIST_FAIL,
  GET_QUESTIONS_LIST_START,
  GET_QUESTIONS_LIST_SUCCESS,
  GET_RANDOMIZER_INFO_FAIL,
  GET_RANDOMIZER_INFO_START,
  GET_RANDOMIZER_INFO_SUCCESS,
  IMPORT_QUESTION_FAIL,
  IMPORT_QUESTION_START,
  IMPORT_QUESTION_SUCCESS,
  SAVE_RANDOMIZER_INFO,
  SET_ACTIVE_SORT,
  SET_COUNT_QUESTIONS,
  SET_DEFAULT_SORT,
  SET_IS_SORT,
  UPDATE_QUESTION_FAIL,
  UPDATE_QUESTION_START,
  UPDATE_QUESTION_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isLoadingCategories: false,
  isLoadingRandomizer: false,
  bankList: {
    list: [],
    pagination: {
      totalElements: null,
      totalPages: 0,
      size: 20,
      number: 0,
    },
  },
  categories: [],
  randomizerInfo: {
    questionCategories: [],
    totalQuestionsCount: 0,
    randomizerCount: 0,
  },
  isLoadingCount: false,
  countQuestions: null,
  isSort: 'id,desc',
  activeSort: {
    id: true,
    aZ: false,
    zA: false,
  },
  randomizerInfoSave: {},
};

/* eslint-disable */
export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_EXPORT_QUESTION_CSV_START:
    case IMPORT_QUESTION_START:
    case ADD_QUESTION_START:
    case UPDATE_QUESTION_START:
    case GET_QUESTIONS_LIST_START:
    case DELETE_QUESTION_START:
      return { ...state, isLoading: true };

    case GET_COUNT_QUESTIONS_START:
      return { ...state, isLoadingCount: true };

    case GET_RANDOMIZER_INFO_START:
      return { ...state, isLoadingRandomizer: true };

    case GET_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        bankList: {
          list: response.content,
          pagination: {
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            size: response.size,
            number: response.number,
          },
        },
        isLoading: false,
      };

    case GET_BANK_CATEGORIES_START:
      return { ...state, isLoadingCategories: true };

    case GET_BANK_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categories: response.content,
      };

    case ADD_BANK_CATEGORIES:
      let oldIdsCat = state.categories.map(i => i.id);
      let newCat = value.filter(i => !oldIdsCat.includes(i.id));
      return { ...state, categories: [...state.categories, ...newCat] };

    case GET_BANK_CATEGORIES_FAIL:
      return { ...state, isLoadingCategories: false };

    case GET_RANDOMIZER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingRandomizer: false,
        randomizerInfo: response,
      };

    case GET_COUNT_QUESTIONS_SUCCESS:
      return { ...state, countQuestions: response, isLoadingCount: false };

    case GET_RANDOMIZER_INFO_FAIL:
      return { ...state, isLoadingRandomizer: false };

    case GET_EXPORT_QUESTION_CSV_SUCCESS:
    case IMPORT_QUESTION_SUCCESS:
    case UPDATE_QUESTION_SUCCESS:
    case ADD_QUESTION_SUCCESS:
    case DELETE_QUESTION_SUCCESS:
    case GET_EXPORT_QUESTION_CSV_FAIL:
    case IMPORT_QUESTION_FAIL:
    case ADD_QUESTION_FAIL:
    case UPDATE_QUESTION_FAIL:
    case GET_QUESTIONS_LIST_FAIL:
    case DELETE_QUESTION_FAIL:
      return { ...state, isLoading: false };

    case GET_COUNT_QUESTIONS_FAIL:
      return { ...state, isLoadingCount: false };

    case SET_COUNT_QUESTIONS:
      return { ...state, countQuestions: value };

    case SET_IS_SORT:
      return { ...state, isSort: value };

    case SET_ACTIVE_SORT:
      return { ...state, activeSort: value };

    case SET_DEFAULT_SORT:
      return {
        ...state,
        isSort: 'id,desc',
        activeSort: { id: true, aZ: false, zA: false },
      };

    case SAVE_RANDOMIZER_INFO:
      return { ...state, randomizerInfoSave: value };

    default:
      return { ...state };
  }
};
