/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Comment from './Comment';

import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

class Comments extends Component {
  static propTypes = {
    comments: PropTypes.array,
    compId: PropTypes.number,
    getComments: PropTypes.func,
    getFile: PropTypes.func,
  };

  componentDidMount() {
    const { compId, getComments } = this.props;
    if (compId) {
      getComments({ compilationId: compId });
    }
  }

  render() {
    const { comments, getFile, t } = this.props;
    return (
      <div className={css.Info}>
        {comments.length ? comments.map(item => <Comment key={item.id} item={item} getFile={getFile} />) : t('comment')}
      </div>
    );
  }
}

export default withTranslation('translation')(Comments);
