import { API_D360_URL } from '../service_api';
import {
  D360_IMPORT_DELETE_FAIL,
  D360_IMPORT_DELETE_START,
  D360_IMPORT_DELETE_SUCCESS,
  D360_IMPORT_STATUS_FAIL,
  D360_IMPORT_STATUS_START,
  D360_IMPORT_STATUS_SUCCESS,
  D360_UPLOAD_FAIL,
  D360_UPLOAD_START,
  D360_UPLOAD_SUCCESS,
} from './constants';

import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360ImportDelete = (projectId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/import${getQueryString({
      project_id: projectId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_IMPORT_DELETE_START, D360_IMPORT_DELETE_SUCCESS, D360_IMPORT_DELETE_FAIL],
    onSuccess,
    onError,
  };
};

export const D360Upload = (file, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/import`,
    body: file,
    requestType: 'formData',
    headers: {
      ...headers,
      'Content-Type': 'text/csv',
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_UPLOAD_START, D360_UPLOAD_SUCCESS, D360_UPLOAD_FAIL],
    onSuccess,
    onError,
  };
};

export const D360ImportStatus = (projectId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/import/status${getQueryString({
      project_id: projectId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_IMPORT_STATUS_START, D360_IMPORT_STATUS_SUCCESS, D360_IMPORT_STATUS_FAIL],
    onSuccess,
    onError,
  };
};
