/* eslint-disable */
import { useTranslation } from 'react-i18next';

import CalendarModal from '@/components/CalendarModal';
import ConfirmationModal from '@/components/ConfirmationModal';

import SendCommentModal from '../SendReviewModal';

import dayjs from 'dayjs';

export const Modals = ({
  commentSubmitRejectedCourseRequest,
  hideRejectedCourseRequest,
  scheduleStudyPlanClick,
  isUpdate,
  currentPlan,
  coursesRequestFinish,
  hideConfirmRejectUser,
  coursesDelete,
  hideConfirmDeleteCourse,
  toggleModalReasonTransfer,
  topic,
  confirmCloseCourse,
  confirmCourse,
  onCloseCalendarModal,
  onCloseSendCommentModal,
  openedModals,
}) => {
  const { t } = useTranslation('modals');
  return (
    <>
      <SendCommentModal
        rejected
        open={openedModals.rejectedComment}
        submitDeleteComment={commentSubmitRejectedCourseRequest}
        cancelDeleteComment={hideRejectedCourseRequest}
      />
      {openedModals.reasonTransfer && (
        <SendCommentModal
          rejected
          isTransfer={!(!isUpdate && !currentPlan)}
          open
          submitDeleteComment={scheduleStudyPlanClick}
          cancelDeleteComment={onCloseSendCommentModal}
        />
      )}
      {openedModals.rejectedCourse && (
        <ConfirmationModal
          open
          onOk={confirmCourse}
          onCancel={confirmCloseCourse}
          okText={t('close')}
          zIndex={1006}
          confirmationText={t('close2')}
        />
      )}
      {openedModals.rejectUsersModal && (
        <ConfirmationModal
          open
          onOk={coursesRequestFinish}
          onCancel={hideConfirmRejectUser}
          onCancelButton={hideConfirmRejectUser}
          okText={t('yes')}
          cancelText={t('no')}
          confirmationText={t('description1')}
        />
      )}
      {openedModals.deleteCourseModal && (
        <ConfirmationModal
          open
          onOk={coursesDelete}
          onCancel={hideConfirmDeleteCourse}
          onCancelButton={hideConfirmDeleteCourse}
          okText={t('yes')}
          cancelText={t('no')}
          confirmationText={t('description2')}
        />
      )}
      {openedModals.dateModal && (
        <CalendarModal
          hasEvents={topic && topic.futureEvents}
          open={openedModals.dateModal}
          dateEvent={topic && dayjs(topic.startDate, 'DD MMMM YYYY')}
          onOk={toggleModalReasonTransfer}
          onCancel={onCloseCalendarModal}
          noComment={!(!isUpdate && !currentPlan)}
          isAddToPlan={!isUpdate}
          okText={!isUpdate && !currentPlan ? t('addToPlan') : t('reschedule')}
          topicInfo={topic && topic}
          eventTypeId={topic && topic.type && topic.type.id}
          selectMonthEvents
        />
      )}
    </>
  );
};
