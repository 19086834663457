import { useTranslation } from 'react-i18next';

import { Alert, Button, Modal } from 'antd';
import { UsersCount } from '@/components/MVFilters';

import style from '../style.module.scss';

const CompleteMass = props => {
  const { t } = useTranslation('newMassSelection');

  const onSubmit = () => {
    props.onMassOk({
      ...props.formValuesRef.current,
      inspection: props.isInspectors.visible
        ? {
            deadlineDate: props.isInspectors.deadlineDate,
            inspectors: props.isInspectors.inspectors,
          }
        : undefined,
    });
  };

  const footer = (
    <div className={style['modals__buttons']}>
      <Button onClick={props.onClose}>{t('no')}</Button>
      <Button loading={props.isLoadingMass} type='primary' onClick={onSubmit}>
        {t('yes')}
      </Button>
    </div>
  );

  return (
    <Modal footer={footer} open={props.open} onCancel={props.onClose} title={t('completeMassCaption')}>
      <div className={style['modals__content']}>
        <Alert
          className={style['modals__alert']}
          message={t('warning')}
          type='warning'
          showIcon
          description={t('warningDescription.0')}
        />
        {!!props.getCountUsers && (
          <UsersCount
            text={t('massText')}
            countSelectedUsers={props.countSelectedUsers}
            exportUsers={props.exportUsers}
          />
        )}
      </div>
    </Modal>
  );
};

export default CompleteMass;
