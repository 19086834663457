/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Dropdown } from 'antd';
import { AuditOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './Panel.module.scss';

import { EXPORT_USERS_FROM_EVENT, FEE_BASED_COURSE_EVENT_RESEND_MAIL } from '@/constants/permissions';

class PanelCustom extends Component {
  static propTypes = {
    approvedCount: PropTypes.number,
    children: PropTypes.object,
    count: PropTypes.number,
    startDate: PropTypes.string,
    deleteEventHandler: PropTypes.func,
    editEventHandler: PropTypes.func,
    event: PropTypes.object,
    flagShowDetail: PropTypes.bool,
    getEventParticipants: PropTypes.func,
    hasUsers: PropTypes.bool,
    index: PropTypes.number,
    name: PropTypes.string,
    registerCount: PropTypes.number,
    requests: PropTypes.array,
    sendMail: PropTypes.func,
    showEventDetails: PropTypes.func,
    showEventInfoHandler: PropTypes.func,
    onlyEvent: PropTypes.bool,
    isConfOrAllCourse: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.approvedCount !== this.props.approvedCount ||
      prevProps.count !== this.props.count ||
      prevProps.registerCount !== this.props.registerCount
    ) {
      this.props.showEventDetails(
        prevProps.index,
        prevProps.name,
        dayjs(prevProps.startDate).format('DD.MM.YYYY'),
        this.props.count === 0 || this.props.registerCount + this.props.approvedCount < this.props.count
      );
    }
  }

  showEvtDetails = value => {
    this.props.showEventDetails(
      this.props.event.id,
      this.props.name,
      dayjs(this.props.startDate).format('DD.MM.YYYY'),
      this.props.count === 0 || this.props.registerCount + this.props.approvedCount < this.props.count,
      value
    );
  };

  render() {
    const { onlyEvent, t, isConfOrAllCourse } = this.props;

    const startDate = this.props.event?.startDate ? dayjs(this.props.event.startDate).format('DD.MM.YYYY') : null;
    const endDate = this.props.event?.endDate ? dayjs(this.props.event.endDate).format('DD.MM.YYYY') : null;

    const isOneDate =
      this.props.event?.startDate &&
      this.props.event?.endDate &&
      this.props.event?.startDate === this.props.event?.endDate;

    const datesString = isOneDate ? startDate : `${startDate}-${endDate}`;

    const isApprovedArray =
      this.props.event.requests &&
      this.props.event.requests.length &&
      this.props.event.requests.filter(requests => {
        if (
          requests.plan.status === 'APPROVED_BY_USER' ||
          requests.plan.status === 'IN_PROGRESS' ||
          requests.plan.status === 'ON_REGISTRATION'
        ) {
          return requests;
        }
        return null;
      });
    const isApproved = isApprovedArray.length;

    const menuItems = [
      {
        key: 'show',
        label: <a onClick={() => this.props.showEventInfoHandler(this.props.event)}>{t('view')}</a>,
      },
      {
        key: 'edit',
        label: <a onClick={() => this.props.editEventHandler(this.props.event)}>{t('edit')}</a>,
      },
      ...(this.props.event &&
      this.props.currentUser.authorities.includes(FEE_BASED_COURSE_EVENT_RESEND_MAIL) &&
      this.props.hasUsers
        ? [
            {
              key: 'sendMail',
              label: <a onClick={() => this.props.sendMail(this.props.event.id)}>{t('repeat')}</a>,
            },
          ]
        : []),
      ...(this.props.hasUsers && this.props.currentUser.authorities.includes(EXPORT_USERS_FROM_EVENT)
        ? [
            {
              key: 'upload',
              label: (
                <a onClick={() => this.props.getEventParticipants(this.props.event.id, `event_${startDate}`)}>
                  {t('download')}
                </a>
              ),
            },
          ]
        : []),
      ...(!onlyEvent
        ? [
            {
              key: 'delete',
              label: (
                <a onClick={() => this.props.deleteEventHandler(this.props.event.id, isApproved)}>{t('delete')}</a>
              ),
            },
          ]
        : []),
    ];

    return (
      <div>
        <div className={this.props.flagShowDetail ? classNames(css.Panel, css.activePanel) : css.Panel}>
          <div className={css['Panel-block']}>
            {this.props.flagShowDetail ? (
              <UpOutlined className={css['Panel-block-icons']} onClick={this.showEvtDetails} />
            ) : (
              <DownOutlined className={css['Panel-block-icons']} onClick={this.showEvtDetails} />
            )}
            <div className={css['Panel-name']} onClick={() => this.showEvtDetails(true)}>
              {isConfOrAllCourse ? (
                <>
                  {datesString} ({this.props.requests?.length} {t('from')} {this.props.count}) ID {this.props.event.id}
                </>
              ) : (
                <>
                  {datesString} ({this.props.registerCount}/{this.props.requests?.length} {t('from')} {this.props.count}
                  ) ID {this.props.event.id}
                </>
              )}
            </div>
            <div className={css['Panel-interested-info']}>
              <div className={css['Panel-interested-element']}>
                <AuditOutlined className={css['Panel-interested-icon']} />
                <span>{this.props.event?.waitingListUsers}</span>
              </div>
            </div>
          </div>
          <div className={css['Panel-button']}>
            <div className={css['Panel-button-block']}>
              <Dropdown menu={{ items: menuItems }} placement='bottomRight' trigger={['click']}>
                <a>
                  <div className={css['Panel-button-block-oval']} />
                  <div className={css['Panel-button-block-oval']} />
                  <div className={css['Panel-button-block-oval']} />
                </a>
              </Dropdown>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default withTranslation('panelCustom')(PanelCustom);
