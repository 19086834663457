/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import { extractUsername } from '../../../utils';

import PropTypes from 'prop-types';

const EmptySelected = { keys: [], rows: [] };
const pageSizeOptions = ['10', '50', '100', '500', '1000', '10000'];

export const FinderTable = props => {
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState(EmptySelected);
  const { t } = useTranslation('D360_Finder');
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  useEffect(() => {
    let data = [];
    if (props && props.data && Array.isArray(props.data)) {
      props.data.forEach(user => {
        data.push({ ...user, key: user.id });
      });
    }

    setTableData(data);
  }, [props.data]);

  useEffect(() => {
    if (!props.open) {
      setSelected(EmptySelected);
      setTableData([]);
    }
  }, [props.open]);

  useEffect(() => {
    if (props && props.onSelect && typeof props.onSelect === 'function') {
      props.onSelect(selected);
    }
  }, [selected]);

  const tableColumns = () => {
    return [
      {
        title: t('columnFullname'),
        key: 'fullname',
        dataIndex: 'fullname',
        render: (name, record) => extractUsername(record),
      },
      {
        title: t('columnEmail'),
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: t('columnDepartment'),
        key: 'department',
        dataIndex: 'department',
      },
      {
        title: t('columnPosition'),
        key: 'position',
        dataIndex: 'position',
      },
      {
        title: t('columnFunction'),
        key: 'function',
        dataIndex: 'function',
      },
    ];
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selected.keys,
      onChange: (selectedKeys, selectedRows) => {
        setSelected({
          ...selected,
          keys: selectedKeys,
          rows: selectedRows,
        });
      },
    };
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <Table
        size='small'
        rowSelection={rowSelection()}
        columns={tableColumns()}
        dataSource={tableData}
        bordered={true}
        loading={props.loading}
        scroll={{ x: 1000 }}
        pagination={{
          defaultPageSize: 10,
          pageSize: paginationPageSize,
          pageSizeOptions: pageSizeOptions,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onShowSizeChange: (c, s) => {
            setPaginationPageSize(s);
          },
          responsive: true,
        }}
      />
    );
  };

  return render();
};

FinderTable.propTypes = {
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FinderTable;
