import {
  GET_POKA_CATEGORIES_FAIL,
  GET_POKA_CATEGORIES_START,
  GET_POKA_CATEGORIES_SUCCESS,
} from '@/store/poka_categories/constants';

const initialState = {
  pokaCategories: [],
  loadingPokaCategories: false,
  errorPokaCategories: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_POKA_CATEGORIES_START:
      return {
        ...state,
        loadingPokaCategories: true,
        errorPokaCategories: false,
        pokaCategories: [],
      };

    case GET_POKA_CATEGORIES_SUCCESS:
      return {
        ...state,
        loadingPokaCategories: false,
        errorPokaCategories: false,
        pokaCategories: response,
      };

    case GET_POKA_CATEGORIES_FAIL:
      return {
        ...state,
        loadingPokaCategories: false,
        errorPokaCategories: true,
        pokaCategories: [],
      };

    default:
      return state;
  }
};
