export const POST_IPR_FILTERS_START = 'POST_IPR_FILTERS_START';
export const POST_IPR_FILTERS_SUCCESS = 'POST_IPR_FILTERS_SUCCESS';
export const POST_IPR_FILTERS_FAIL = 'POST_IPR_FILTERS_FAIL';

export const GET_MENTORS_IPR_START = 'GET_MENTORS_IPR_START';
export const GET_MENTORS_IPR_SUCCESS = 'GET_MENTORS_IPR_SUCCESS';
export const GET_MENTORS_IPR_FAIL = 'GET_MENTORS_IPR_FAIL';

export const GET_IPR_STATUS_START = 'GET_IPR_STATUS_START';
export const GET_IPR_STATUS_SUCCESS = 'GET_IPR_STATUS_SUCCESS';
export const GET_IPR_STATUS_FAIL = 'GET_IPR_STATUS_FAIL';

export const GET_IPR_DETAILS_START = 'GET_IPR_DETAILS_START';
export const GET_IPR_DETAILS_SUCCESS = 'GET_IPR_DETAILS_SUCCESS';
export const GET_IPR_DETAILS_FAIL = 'GET_IPR_DETAILS_FAIL';

export const REJECTED_IPR_START = 'REJECTED_IPR_START';
export const REJECTED_IPR_SUCCESS = 'REJECTED_IPR_SUCCESS';
export const REJECTED_IPR_FAIL = 'REJECTED_IPR_FAIL';

export const CONFIRM_IPR_START = 'CONFIRM_IPR_START';
export const CONFIRM_IPR_SUCCESS = 'CONFIRM_IPR_SUCCESS';
export const CONFIRM_IPR_FAIL = 'CONFIRM_IPR_FAIL';

export const FINISHED_IPR_START = 'FINISHED_IPR_START';
export const FINISHED_IPR_SUCCESS = 'FINISHED_IPR_SUCCESS';
export const FINISHED_IPR_FAIL = 'FINISHED_IPR_FAIL';

export const EXPORT_IPRS_START = 'EXPORT_IPRS_START';
export const EXPORT_IPRS_SUCCESS = 'EXPORT_IPRS_SUCCESS';
export const EXPORT_IPRS_FAIL = 'EXPORT_IPRS_FAIL';

export const GET_IPR_GOAL_CATEGORY_START = 'GET_IPR_GOAL_CATEGORY_START';
export const GET_IPR_GOAL_CATEGORY_SUCCESS = 'GET_IPR_GOAL_CATEGORY_SUCCESS';
export const GET_IPR_GOAL_CATEGORY_FAIL = 'GET_IPR_GOAL_CATEGORY_FAIL';

export const GET_IPR_SETTINGS_START = 'GET_IPR_SETTINGS_START';
export const GET_IPR_SETTINGS_SUCCESS = 'GET_IPR_SETTINGS_SUCCESS';
export const GET_IPR_SETTINGS_FAIL = 'GET_IPR_SETTINGS_FAIL';

export const UPDATE_SETTINGS_IPR_START = 'UPDATE_SETTINGS_IPR_START';
export const UPDATE_SETTINGS_IPR_SUCCESS = 'UPDATE_SETTINGS_IPR_SUCCESS';
export const UPDATE_SETTINGS_IPR_FAIL = 'UPDATE_SETTINGS_IPR_FAIL';

export const CLEAR_IPR_DETAILS_SUCCESS = 'CLEAR_IPR_DETAILS_SUCCESS';

export const SAVE_IPR_SORT = 'SAVE_IPR_SORT';

export const SAVE_IPR_FILTERS = 'SAVE_IPR_FILTERS';

export const CLEAR_IPR_FILTERS = 'CLEAR_IPR_FILTERS';

export const ADD_CURRENT_MENTORS = 'ADD_CURRENT_MENTORS';
