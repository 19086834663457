/* eslint-disable */
import { EDIT_BONUSES_FAIL, EDIT_BONUSES_SUCCESS, SAVE_BONUSES } from '@/store/bonuses/actions';
import {
  ADD_USER_EVENT_FAIL,
  ADD_USER_EVENT_SUCCESS,
  CHECK_DELETE_USER_EVENT_FAIL,
  CREATE_EVENT_FAIL,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_USER_EVENT_FAIL,
  DELETE_USER_EVENT_SUCCESS,
  EVENT_VALIDATION_FAIL,
  GET_COURSES_REQUEST_EXPORT_FAIL,
  GET_COURSES_REQUEST_EXPORT_SUCCESS,
  GET_EVENT_PARTICIPANTS_FAIL,
  GET_EVENT_PARTICIPANTS_SUCCESS,
  SEND_MAIL_FAIL,
  SEND_MAIL_SUCCESS,
  SET_REGISTRATION_STARTDATE_SUCCESS,
  UPDATE_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
} from '@/store/courses/actions';
import { IMPORT_DEPARTMENT_FAIL } from '@/store/department/actions';
import { GET_TOPIC_PLAN_FILE_FAIL, GET_TOPIC_PLAN_FILE_SUCCESS } from '@/store/files/actions';
import { IMPORT_FUNCTION_FAIL } from '@/store/function/actions';
import { PUT_USER_WRITEOFFCOIN_FAIL, PUT_USER_WRITEOFFCOIN_SUCCESS } from '@/store/income/actions';
import { CREATE_STUDY_PLAN_FAIL, CREATE_STUDY_PLAN_SUCCESS, CREATE_USER_FAIL } from '@/store/students/actions';
import { DELETE_TAG_FAIL } from '@/store/tags/actions';
import {
  CHANGE_SCOPE_FAIL,
  CHANGE_SCOPE_SUCCESS,
  DELETE_TOPIC_FAIL,
  DELETE_TOPIC_SUCCESS,
  GET_LOGGING_ADMIN_EXPORT_FAIL,
  GET_LOGGING_ADMIN_EXPORT_SUCCESS,
  GET_LOGGING_EXPORT_FAIL,
  GET_LOGGING_EXPORT_SUCCESS,
  POST_TOPIC_FAIL,
  POST_TOPIC_SUCCESS,
  SET_TOPIC_TYPES_AUTO_SUCCESS,
  TOPIC_INVALID_CHARACTER_FAIL,
  TOPIC_VALIDATION_FAIL,
  UPDATE_TOPIC_FAIL,
  UPDATE_TOPIC_SUCCESS,
} from '@/store/topics/actions';
import { EDIT_USER_SUCCESS } from '@/store/users/actions';

import {
  CHANGE_VISIBILITY_COMPILATION_FAIL,
  DELETE_COMPILATION_FAIL,
  DELETE_THEME_FAIL,
  POST_COMPILATION_FAIL,
  POST_COMPILATION_SUCCESS,
  POST_THEME_FAIL,
  UPDATE_CAREER_FAIL,
  UPDATE_COMPILATION_FAIL,
} from '@/store/compilations/constants';

const initialState = {
  message: '',
  type: '',
};

export default (state = initialState, { type, action, response }) => {
  switch (type) {
    case CREATE_STUDY_PLAN_SUCCESS:
      return {
        message: 'addToPlan',
        type: 'success',
      };
    case POST_THEME_FAIL:
      return {
        message: 'themeError',
        type: 'error',
      };
    case CHANGE_VISIBILITY_COMPILATION_FAIL:
    case IMPORT_DEPARTMENT_FAIL:
    case IMPORT_FUNCTION_FAIL:
    case CREATE_USER_FAIL:
      return {
        message: response,
        type: 'error',
      };
    case DELETE_THEME_FAIL:
      return {
        message: 'themeDelete',
        type: 'error',
      };
    case UPDATE_CAREER_FAIL:
      return {
        message: 'itodError',
        type: 'error',
      };

    case CREATE_STUDY_PLAN_FAIL:
      return {
        message: 'addToPlanError',
        data: response,
        type: 'error',
      };
    case EDIT_USER_SUCCESS:
      return {
        message: 'passwordChange',
        type: 'success',
      };

    case SET_REGISTRATION_STARTDATE_SUCCESS:
      return {
        message: 'dataChange',
        type: 'success',
      };
    case SAVE_BONUSES:
      return {
        message: 'settingsSaved',
        type: 'success',
      };
    case SET_TOPIC_TYPES_AUTO_SUCCESS:
    case EDIT_BONUSES_SUCCESS:
      return {
        message: 'settingsChanged',
        type: 'success',
      };

    case EDIT_BONUSES_FAIL:
      return {
        message: 'setCorrect',
        type: 'error',
      };

    case POST_COMPILATION_FAIL:
    case DELETE_COMPILATION_FAIL:
    case UPDATE_COMPILATION_FAIL:
    case POST_TOPIC_FAIL:
    case UPDATE_TOPIC_FAIL:
    case CHANGE_SCOPE_FAIL:
    case CREATE_EVENT_FAIL:
    case UPDATE_EVENT_FAIL:
    case DELETE_USER_EVENT_FAIL:
    case PUT_USER_WRITEOFFCOIN_FAIL:
    case GET_COURSES_REQUEST_EXPORT_FAIL:
    case GET_TOPIC_PLAN_FILE_FAIL:
    case GET_EVENT_PARTICIPANTS_FAIL:
    case GET_LOGGING_EXPORT_FAIL:
    case GET_LOGGING_ADMIN_EXPORT_FAIL:
    case CHECK_DELETE_USER_EVENT_FAIL:
      return {
        message: response || 'unknownError',
        type: 'error',
      };

    case PUT_USER_WRITEOFFCOIN_SUCCESS:
      const msg = action.body.qty > 0 ? 'addCoins' : 'shareCoins';
      return {
        message: msg,
        type: 'success',
      };

    case POST_TOPIC_SUCCESS:
      return {
        message: 'newMaterial',
        type: 'success',
      };

    case UPDATE_TOPIC_SUCCESS:
      return {
        message: 'materialEdited',
        type: 'success',
      };

    case POST_COMPILATION_SUCCESS:
      return {
        message: 'newCompilation',
        type: 'success',
      };

    case DELETE_TOPIC_SUCCESS:
      return {
        message: 'deleteMaterial',
        type: 'success',
      };

    case DELETE_TOPIC_FAIL:
      return {
        message: 'deleteError',
        type: 'error',
      };

    case SEND_MAIL_SUCCESS:
      return {
        message: 'mails',
        type: 'success',
      };

    case SEND_MAIL_FAIL:
      return {
        message: 'mailsError',
        type: 'error',
      };

    case DELETE_TAG_FAIL:
      return {
        message: 'deleteTagError',
        type: 'error',
      };

    case CREATE_EVENT_SUCCESS:
      return {
        message: 'addEvent',
        type: 'success',
      };

    case CHANGE_SCOPE_SUCCESS:
      return {
        message: 'statusChanged',
        type: 'success',
      };
    case TOPIC_INVALID_CHARACTER_FAIL:
      return {
        message: 'invalidCharacter',
        type: 'error',
      };
    case TOPIC_VALIDATION_FAIL:
    case EVENT_VALIDATION_FAIL:
      return {
        message: 'required',
        type: 'error',
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        message: 'eventChanged',
        type: 'success',
      };

    case GET_COURSES_REQUEST_EXPORT_SUCCESS:
    case GET_TOPIC_PLAN_FILE_SUCCESS:
    case GET_EVENT_PARTICIPANTS_SUCCESS:
    case GET_LOGGING_EXPORT_SUCCESS:
    case GET_LOGGING_ADMIN_EXPORT_SUCCESS:
      return {
        message: 'dataExtracted',
        type: 'success',
      };

    case DELETE_USER_EVENT_SUCCESS:
      return {
        message: 'userDeletedFromEvent',
        type: 'success',
      };

    case DELETE_EVENT_FAIL:
      return {
        message: 'eventDeleteError',
        type: 'error',
      };
    case DELETE_EVENT_SUCCESS:
      return {
        message: 'eventDeleted',
        type: 'success',
      };

    case ADD_USER_EVENT_SUCCESS:
      return {
        message: 'eventAdd',
        type: 'success',
      };

    case ADD_USER_EVENT_FAIL:
      return {
        message: 'eventError',
        data: response,
        type: 'error',
      };

    default:
      return state;
  }
};
