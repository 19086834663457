/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_TAGS_START = 'GET_TAGS_START';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';
export const GET_GLOBAL_TAGS_START = 'GET_GLOBAL_TAGS_START';
export const GET_GLOBAL_TAGS_SUCCESS = 'GET_GLOBAL_TAGS_SUCCESS';
export const GET_GLOBAL_TAGS_FAIL = 'GET_GLOBAL_TAGS_FAIL';
export const CREATE_TAG_START = 'CREATE_TAG_START';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL';
export const DELETE_TAG_START = 'DELETE_TAG_START';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';
export const EDIT_TAG_START = 'EDIT_TAG_START';
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';
export const EDIT_TAG_FAIL = 'EDIT_TAG_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getTags = (name = '', page = 0, onSuccess, isGlobal = false) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/tag${getQueryString({
    name: name && name.toLowerCase(),
    isGlobal,
    isSharing: isGlobal ? '' : false,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TAGS_START, GET_TAGS_SUCCESS, GET_TAGS_FAIL],
  onSuccess,
});

export const getTagsGlobal = (name = '', page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/tag${getQueryString({
    name: name && name.toLowerCase(),
    isGlobal: true,
    isSharing: true,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GLOBAL_TAGS_START, GET_GLOBAL_TAGS_SUCCESS, GET_GLOBAL_TAGS_FAIL],
  onSuccess,
});

export const createTag = (name = '', onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/tag`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name: name && name.toLowerCase(),
  },
  onSuccess,
  types: [CREATE_TAG_START, CREATE_TAG_SUCCESS, CREATE_TAG_FAIL],
});

export const deleteTag = (id, { onSuccess, onError }) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/tag${getQueryString({ id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_TAG_START, DELETE_TAG_SUCCESS, DELETE_TAG_FAIL],
});

export const editTag = (name, id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/tag${getQueryString({ id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name: name && name.toLowerCase(),
  },
  onSuccess,
  types: [EDIT_TAG_START, EDIT_TAG_SUCCESS, EDIT_TAG_FAIL],
});
