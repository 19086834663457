/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from 'antd';

import ModalSchedule from './ModalSchedule';

import classNames from 'classnames';
import dayjs from 'dayjs';
import throttle from 'lodash/throttle';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './Events.module.scss';

export const Event = ({ activeEvent, getEventUsers, eventUsers, event, handleChoice }) => {
  const [activeSchedule, setActiveSchedule] = useState(false);
  const [activeUserSchedule, setActiveUserSchedule] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const { t } = useTranslation('events');

  const getEventUsersThrottled = throttle(getEventUsers, 1000);

  useEffect(() => {
    if (!activeEvent) {
      setActiveSchedule(false);
      setActiveUserSchedule(false);
    }
  }, [activeEvent]);

  const onSubmitChoice = () => {
    handleChoice(event.id);
  };

  const handleOpenSchedule = () => {
    setActiveSchedule(!activeSchedule);
    setActiveUserSchedule(false);
  };

  const handleOpenUserSchedule = () => {
    setActiveUserSchedule(!activeUserSchedule);
    setActiveSchedule(false);

    getEventUsersThrottled(event.id);
  };

  const startDateDay = event && dayjs(event.startTime).format('ddd');

  const startDateNumber = event && dayjs(event.startTime).format('D MMM');

  const endDateDay = event && dayjs(event.endTime).format('ddd');

  const endDateNumber = event && dayjs(event.endTime).format('D MMM');

  const nameCourse =
    event && event.topic.name.length > 47 ? (
      <Tooltip title={event.topic.name}>{`${event.topic.name.substring(0, 47)}...`}</Tooltip>
    ) : (
      event.topic.name
    );

  const nameEvent =
    event && event.name.length > 47 ? (
      <Tooltip title={event.name}>{`${event.name.substring(0, 47)}...`}</Tooltip>
    ) : (
      event.name
    );

  return (
    <div className={css.EventBlock}>
      <div
        className={classNames(css.Event, {
          [css.activeEvent]: activeEvent,
        })}
        onClick={onSubmitChoice}
      >
        <div className={css['Event-marker']} />
        <div className={css['Event-date']}>
          <div className={css['Event-date-day']}>{startDateDay}</div>
          <div className={css['Event-date-number']}>{startDateNumber}</div>
        </div>
        <div className={css['Event-dash']}>
          <div className={css['Event-date-number']}>-</div>
        </div>
        <div className={css['Event-date']}>
          <div className={css['Event-date-day']}>{endDateDay}</div>
          <div className={css['Event-date-number']}>{endDateNumber}</div>
        </div>
        <div className={css['Event-name']}>
          <div className={css['Event-name-name']}>{nameCourse}</div>
          <div className={css['Event-name-event']}>
            <div
              className={classNames(css['Event-name-event-name'], {
                [css['Event-name-event-name-disabled']]: event.requestCount === 0,
              })}
              onClick={handleOpenUserSchedule}
            >
              {t('text', { event })}
            </div>{' '}
            <span className={css['Event-name-separator']} />
            <div className={css['Event-name-event-name']} onClick={handleOpenSchedule}>
              {t('text2', {
                event,
                day: noun(event.dayCount, t('day1'), t('day2'), t('day3')),
              })}
              {nameEvent}
            </div>
            <span className={css['Event-name-separator']} />
            <div
              className={classNames(css['Event-manager'], {
                [css.noManager]: !event.manager,
              })}
            >
              {!event.manager ? t('notAssigned1') : event.manager.name}
            </div>
          </div>
        </div>
        <div className={css['Event-link']}>
          <Link to={`/topic/${event.topic.id}`} target='_blank'>
            {t('go')}
          </Link>
        </div>
      </div>
      {activeSchedule && (
        <ModalSchedule
          key={`modal-key-${event.name}`}
          visible={activeEvent && activeSchedule}
          handleCloseSchedule={handleOpenSchedule}
          event={event}
        />
      )}
      {activeUserSchedule && (
        <ModalSchedule
          key={`modal-key2-${event.name}`}
          visible={activeEvent && activeUserSchedule}
          handleCloseSchedule={handleOpenUserSchedule}
          eventUsers={eventUsers}
          usersList
        />
      )}
    </div>
  );
};

Event.propTypes = {
  activeEvent: PropTypes.bool,
  event: PropTypes.object,
  eventUsers: PropTypes.array,
  getEventUsers: PropTypes.func,
  handleChoice: PropTypes.func,
  isLoading: PropTypes.bool,
};
