import {
  PUT_FINISH_ADMIN_FAIL,
  PUT_FINISH_ADMIN_START,
  PUT_FINISHADMIN_SUCCESS,
  PUT_REJECT_ADMIN_FAIL,
  PUT_REJECT_ADMIN_START,
  PUT_REJECT_ADMIN_SUCCESS,
} from './constants';

const initialState = {
  blockAdmin: [],
  loading: false,
};

/* eslint-disable */
export default (state = initialState, { type }) => {
  switch (type) {
    case PUT_REJECT_ADMIN_START:
    case PUT_FINISH_ADMIN_START:
      return { ...state, loading: true };

    case PUT_REJECT_ADMIN_SUCCESS:
    case PUT_REJECT_ADMIN_FAIL:
    case PUT_FINISHADMIN_SUCCESS:
    case PUT_FINISH_ADMIN_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
