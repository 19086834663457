import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button } from 'antd';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

class CalendarModal extends Component {
  static propTypes = {
    comment: PropTypes.string,
    dateEvent: PropTypes.string,
    disabledSelect: PropTypes.bool,
    headerFixToModal: PropTypes.bool,
    isLoadingPlan: PropTypes.bool,
    noComment: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    title: PropTypes.string,
    open: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.currentYear = Number(dayjs().format('YYYY'));
    this.state = {
      selectedYear: this.currentYear,
      selectedMonths: !props.disabledSelect ? [] : [dayjs(props.dateEvent).format('YYYY-MM-DD')],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({
        selectedYear: this.currentYear,
        comment: '',
        selectedMonths: !this.props.disabledSelect ? [] : [dayjs(this.props.dateEvent).format('YYYY-MM-DD')],
      });
    }
  }

  handleOkClick = () => {
    const { onOk, planningTopicId, rest } = this.props;

    if (onOk) {
      onOk(planningTopicId, this.state.selectedMonths, this.state.comment, false, rest, '', this.props.eventId);
    }
  };

  render() {
    const { okText, dateEvent } = this.props;

    return (
      <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
        <div className={classNames(css['Calendar-modal-header'], css['Calendar-modal-header--ext'])}>
          {this.props.t('addOnDate', { dateEvent })}
        </div>
        {this.props.comment && <div className={css['Calendar-modal-comment']}>{this.props.comment}</div>}
        <div className={css['Calendar-modal__row__btn-box']}>
          <Button
            disabled={this.props.isLoadingPlan}
            className={css['Calendar-modal-btn__orange']}
            type='primary'
            onClick={this.handleOkClick}
          >
            {okText}
          </Button>
          <span className={css['Calendar-modal__row__btn-box-cancel']} onClick={this.props.onCancel}>
            {this.props.t('cancel')}
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation('calendarModal')(CalendarModal);
