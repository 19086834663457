// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-S42T3d.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-ppYQr3 .admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-label-dckIOu[data-value="Montserrat"]:before, .admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-S42T3d.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-ppYQr3 .admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-item-JXebyx[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-Montserrat-V88s5z {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__MessageDetails-hK5ARG .ql-toolbar {
  width: 100%;
}

.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__MessageDetails-message-rBVF7r {
  width: 410px !important;
  height: 64px !important;
}

.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__InfoCircleIcon-c3Ffqb {
  cursor: pointer;
  color: var(--personalColor);
  margin-right: 1em;
  font-size: 1.5em;
}

.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__tooltipIcon-koRfxN .ant-tooltip-inner {
  color: #000;
  padding: 1em;
}

.admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__iframe-QqwrG3 {
  border: 1px solid var(--personalColor);
  width: 100%;
  height: 35rem;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Messages/parts/MessageDetails/MessageDetails.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;AACb;;AAOA;EAJE,uBAAuB;EACvB,uBAAuB;AACzB;;AAOA;EAJE,eAAe;EACf,2BAA2B;EAC3B,iBAAiB;EACjB,gBAAgB;AAClB;;AAOA;EAJE,WAAW;EACX,YAAY;AACd;;AAOA;EAJE,sCAAsC;EACtC,WAAW;EACX,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.MessageDetails :global .ql-toolbar {\n  width: 100%;\n}\n.MessageDetails-message {\n  width: 410px !important;\n  height: 64px !important;\n}\n\n.InfoCircleIcon {\n  cursor: pointer;\n  margin-right: 1em;\n  font-size: 1.5em;\n  color: var(--personalColor);\n}\n\n.tooltipIcon :global .ant-tooltip-inner {\n  color: #000;\n  padding: 1em;\n}\n\n.iframe {\n  width: 100%;\n  border: 1px solid var(--personalColor);\n  height: 35rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-S42T3d`,
	"qlPicker": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-S42T3d`,
	"ql-font": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-ppYQr3`,
	"qlFont": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-ppYQr3`,
	"ql-picker-label": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-label-dckIOu`,
	"qlPickerLabel": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-label-dckIOu`,
	"ql-picker-item": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-item-JXebyx`,
	"qlPickerItem": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-picker-item-JXebyx`,
	"ql-font-Montserrat": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-Montserrat-V88s5z`,
	"qlFontMontserrat": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__ql-font-Montserrat-V88s5z`,
	"MessageDetails": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__MessageDetails-hK5ARG`,
	"messageDetails": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__MessageDetails-hK5ARG`,
	"MessageDetails-message": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__MessageDetails-message-rBVF7r`,
	"messageDetailsMessage": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__MessageDetails-message-rBVF7r`,
	"InfoCircleIcon": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__InfoCircleIcon-c3Ffqb`,
	"infoCircleIcon": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__InfoCircleIcon-c3Ffqb`,
	"tooltipIcon": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__tooltipIcon-koRfxN`,
	"iframe": `admin-src-pages-Messages-parts-MessageDetails-MessageDetails-module__iframe-QqwrG3`
};
export default ___CSS_LOADER_EXPORT___;
