/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { SKILL_DELETE, SKILL_UPDATE } from '@/constants/permissions';

export default class SkillsItem extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    skills: PropTypes.object.isRequired,
    shared: PropTypes.bool,
    currentUser: PropTypes.object,
  };

  handleEdit = () => this.props.onEdit(this.props.skills);

  handleDelete = () => this.props.onDelete(this.props.skills);

  render() {
    const { skills, shared, currentUser, t } = this.props;
    return (
      <div className={css.TagItem} data-qa='tagItem'>
        <div className={css['TagItem-width']}>
          {Utils.maxTextValue(skills.name) ? (
            <Tooltip title={skills.name} placement='topLeft'>
              {skills.name}
            </Tooltip>
          ) : (
            skills.name
          )}
        </div>
        {!shared && (
          <div className={css['TagItem-container']}>
            {currentUser.authorities.includes(SKILL_UPDATE) && (
              <Tooltip title={t('editButton')} placement='left'>
                <FormOutlined
                  size='small'
                  data-qa='editCompetenceBtn'
                  type='primary'
                  className={css['TagItem-icon-edit']}
                  onClick={this.handleEdit}
                />
              </Tooltip>
            )}
            {currentUser.authorities.includes(SKILL_DELETE) && (
              <Tooltip title={t('deleteButton')}>
                <DeleteOutlined
                  size='small'
                  data-qa='deleteCompetenceBtn'
                  className={css['TagItem-icon-delete']}
                  onClick={this.handleDelete}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  }
}
