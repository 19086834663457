export const GET_TOPIC_INSPECTION_START = 'GET_TOPIC_INSPECTION_START';
export const GET_TOPIC_INSPECTION_SUCCESS = 'GET_TOPIC_INSPECTION_SUCCESS';
export const GET_TOPIC_INSPECTION_FAIL = 'GET_TOPIC_INSPECTION_FAIL';

export const GET_INSPECTION_STATUS_START = 'GET_INSPECTION_STATUS_START';
export const GET_INSPECTION_STATUS_SUCCESS = 'GET_INSPECTION_STATUS_SUCCESS';
export const GET_INSPECTION_STATUS_FAIL = 'GET_INSPECTION_STATUS_FAIL';

export const GET_USERS_FOR_INSPECTIONS_START = 'GET_USERS_FOR_INSPECTIONS_START';
export const GET_USERS_FOR_INSPECTIONS_SUCCESS = 'GET_USERS_FOR_INSPECTIONS_SUCCESS';
export const GET_USERS_FOR_INSPECTIONS_FAIL = 'GET_USERS_FOR_INSPECTIONS_FAIL';
