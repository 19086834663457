/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360GetFilterSkills } from '@/store/d360/d360_filters/actions';

import { Button, Col, Input, Row, Select } from 'antd';
import { FiHexagon } from 'react-icons/fi';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { Option } = Select;
const { TextArea } = Input;

export const StepSkills = props => {
  const [enableDescription, setEnableDescription] = useState(false);
  const [description, setDescription] = useState(null);
  const [skills, setSkills] = useState(null);
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation('D360_StepSkills');

  useEffect(() => {
    if (props.competence && typeof props.competence === 'object') {
      props.D360GetFilterSkills(props.projectId, props.competence.id, success => {
        if (success && success.data && Array.isArray(success.data)) {
          setSkills(success.data);
        }
      });
    }
  }, [props, props.competence]);

  useEffect(() => {
    if (props.onSelect && typeof props.onSelect === 'function') {
      props.onSelect(selected, description);
    }
  }, [selected, description]);

  const selectSkill = id => {
    if (parseInt(id, 10) <= 0) {
      return setSelected(null);
    }

    let skill = skills.filter(item => {
      return item.id === parseInt(id, 10);
    });

    if (skill && Array.isArray(skill) && skill.length) {
      setSelected(skill[0]);
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.StepScreenWindow}>
        <Row className={css.Notes}>
          <Col span={24} className={css.NotesCol}>
            <FiHexagon size='3rem' className={css.NotesIcon} />
            <p className={css.NotesText}>{t('title')}</p>
          </Col>
        </Row>

        <Row>
          <Col span={12}>* {t('skills')}</Col>
          {selected && (
            <Col span={12} align='right'>
              <Button type='link' size='small' onClick={() => setEnableDescription(!enableDescription)}>
                {!enableDescription ? t('addInterpretation') : t('delInterpretation')}
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Select defaultValue={0} style={{ width: '100%' }} onSelect={selectSkill}>
              <Option key={0} value={0}>
                {t('defaultSelected')}
              </Option>
              {skills &&
                Array.isArray(skills) &&
                skills.map(skill => {
                  return (
                    <Option key={skill.id} value={skill.id}>
                      {skill.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>

        {enableDescription && (
          <Row style={{ marginTop: '1rem' }}>
            <Col span={24}>{t('textAreaLabel')}</Col>
            <Col span={24}>
              <TextArea rows={4} onChange={e => setDescription(e.target.value)} maxLength={500} />
            </Col>
          </Row>
        )}
      </div>
    );
  };

  return render();
};

StepSkills.propTypes = {
  projectId: PropTypes.number.isRequired,
  competence: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  D360GetFilterSkills,
};

export default connect(null, mapDispatchToProps)(StepSkills);
