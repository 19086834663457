// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-XwtPwZ.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-gy4RQE .admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-label-e9znp3[data-value="Montserrat"]:before, .admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-XwtPwZ.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-gy4RQE .admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-item-inTKv4[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-Montserrat-O9NDpE {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_table-OhTKIO {
  background-color: #fff;
  width: 100%;
  padding: 40px;
}

.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_select-p3P7G7 {
  width: 100%;
  margin-bottom: 30px;
}

.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_select_container-nU0lDB {
  min-width: 270px;
}

.admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_header-eBHVhP {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Topics/TopicDetailsDialog/WebinarStatistic/WebinarStatistic.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;;AAMA;EAHE,WAAW;EACX,mBAAmB;AACrB;;AAKA;EAFE,gBAAgB;AAClB;;AAIA;EADE,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.WebinarStatistic_table {\n  width: 100%;\n  background-color: #fff;\n  padding: 40px;\n}\n.WebinarStatistic_select {\n  width: 100%;\n  margin-bottom: 30px;\n}\n.WebinarStatistic_select_container {\n  min-width: 270px;\n}\n.WebinarStatistic_header {\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-XwtPwZ`,
	"qlPicker": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-XwtPwZ`,
	"ql-font": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-gy4RQE`,
	"qlFont": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-gy4RQE`,
	"ql-picker-label": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-label-e9znp3`,
	"qlPickerLabel": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-label-e9znp3`,
	"ql-picker-item": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-item-inTKv4`,
	"qlPickerItem": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-picker-item-inTKv4`,
	"ql-font-Montserrat": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-Montserrat-O9NDpE`,
	"qlFontMontserrat": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__ql-font-Montserrat-O9NDpE`,
	"WebinarStatistic_table": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_table-OhTKIO`,
	"webinarStatisticTable": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_table-OhTKIO`,
	"WebinarStatistic_select": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_select-p3P7G7`,
	"webinarStatisticSelect": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_select-p3P7G7`,
	"WebinarStatistic_select_container": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_select_container-nU0lDB`,
	"webinarStatisticSelectContainer": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_select_container-nU0lDB`,
	"WebinarStatistic_header": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_header-eBHVhP`,
	"webinarStatisticHeader": `admin-src-pages-Topics-TopicDetailsDialog-WebinarStatistic-WebinarStatistic-module__WebinarStatistic_header-eBHVhP`
};
export default ___CSS_LOADER_EXPORT___;
