import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import { TopicTypes } from '@shared/constants/topics';
import getQueryString from '@shared/utils/get-query-string';

import {
  EXPORT_TOPIC_FAIL,
  EXPORT_TOPIC_START,
  EXPORT_TOPIC_SUCCESS,
  GET_POKA_DIR_CATEGORY_FAIL,
  GET_POKA_DIR_CATEGORY_START,
  GET_POKA_DIR_CATEGORY_SUCCESS,
  GET_POKA_DIR_LEVEL_FAIL,
  GET_POKA_DIR_LEVEL_START,
  GET_POKA_DIR_LEVEL_SUCCESS,
  GET_POKA_DIR_POSITION_FAIL,
  GET_POKA_DIR_POSITION_START,
  GET_POKA_DIR_POSITION_SUCCESS,
  GET_POKA_DIR_SITE_FAIL,
  GET_POKA_DIR_SITE_START,
  GET_POKA_DIR_SITE_SUCCESS,
  GET_POKA_DIR_STATUS_FAIL,
  GET_POKA_DIR_STATUS_START,
  GET_POKA_DIR_STATUS_SUCCESS,
  GET_POKA_SKILLS_FAIL,
  GET_POKA_SKILLS_START,
  GET_POKA_SKILLS_SUCCESS,
  GET_TOPIC_LABELS_FAIL,
  GET_TOPIC_LABELS_START,
  GET_TOPIC_LABELS_SUCCESS,
} from '@/store/poka_skills/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPokaSkills = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic${getQueryString({
    ...params,
    size: 20,
    sort: 'createdDate,desc',
    status: params.status ?? 'ACTIVE',
    topicTypeId: [TopicTypes.Skills],
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_SKILLS_START, GET_POKA_SKILLS_SUCCESS, GET_POKA_SKILLS_FAIL],
});

export const exportSkills = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/export${getQueryString({
    ...params,
    topicTypeId: [TopicTypes.Skills],
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [EXPORT_TOPIC_START, EXPORT_TOPIC_SUCCESS, EXPORT_TOPIC_FAIL],
  onSuccess,
});

export const getTypePokaLabels = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_LABELS_START, GET_TOPIC_LABELS_SUCCESS, GET_TOPIC_LABELS_FAIL],
});

export const getPokaDirPosition = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/position`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_DIR_POSITION_START, GET_POKA_DIR_POSITION_SUCCESS, GET_POKA_DIR_POSITION_FAIL],
});

export const getPokaDirSite = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/site`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_DIR_SITE_START, GET_POKA_DIR_SITE_SUCCESS, GET_POKA_DIR_SITE_FAIL],
});

export const getPokaDirCategory = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/category`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_DIR_CATEGORY_START, GET_POKA_DIR_CATEGORY_SUCCESS, GET_POKA_DIR_CATEGORY_FAIL],
});

export const getPokaDirLevel = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/level`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_DIR_LEVEL_START, GET_POKA_DIR_LEVEL_SUCCESS, GET_POKA_DIR_LEVEL_FAIL],
});

export const getPokaDirStatus = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_DIR_STATUS_START, GET_POKA_DIR_STATUS_SUCCESS, GET_POKA_DIR_STATUS_FAIL],
});
