/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Col, Divider, Empty, Progress, Row, Typography } from 'antd';
import {
  HiOutlineChartPie,
  HiOutlineCheck,
  HiOutlineTrendingDown,
  HiOutlineTrendingUp,
  HiOutlineX,
} from 'react-icons/hi';

import PDF from './Conventus.pdf';

import _ from 'lodash';
import PropTypes from 'prop-types';

const { Text } = Typography;
const { Title } = Typography;

export class Diagnostics extends Component {
  static propTypes = {
    t: PropTypes.func,
    student: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      careerName: '',
      awareness: { min: 0, max: 0, cur: 0 },
      involvement: { min: 0, max: 0, cur: 0 },
      flexibility: { min: 0, max: 0, cur: 0 },
      hasNoData: true,
    };
  }

  parseAwareness() {
    const { student } = this.props;

    if (
      _.has(student, 'awareness') &&
      _.has(student, 'career.conventusRole.minAwareness') &&
      _.has(student, 'career.conventusRole.maxAwareness')
    ) {
      if (
        !Number.isNaN(student.awareness) &&
        !Number.isNaN(student.career.conventusRole.minAwareness) &&
        !Number.isNaN(student.career.conventusRole.maxAwareness)
      ) {
        let awareness = {
          min: Number.parseInt(student.career.conventusRole.minAwareness),
          max: Number.parseInt(student.career.conventusRole.maxAwareness),
          cur: Number.parseInt(student.awareness),
        };

        if (awareness.cur > awareness.max) awareness.cur = awareness.max;

        this.setState({ awareness: awareness });

        return true;
      }
    }

    return false;
  }

  parseInvolvement() {
    const { student } = this.props;
    if (
      _.has(student, 'involvement') &&
      _.has(student, 'career.conventusRole.minInvolvement') &&
      _.has(student, 'career.conventusRole.maxInvolvement')
    ) {
      if (
        !Number.isNaN(student.involvement) &&
        !Number.isNaN(student.career.conventusRole.minInvolvement) &&
        !Number.isNaN(student.career.conventusRole.maxInvolvement)
      ) {
        let involvement = {
          min: Number.parseInt(student.career.conventusRole.minInvolvement),
          max: Number.parseInt(student.career.conventusRole.maxInvolvement),
          cur: Number.parseInt(student.involvement),
        };

        if (involvement.cur > involvement.max) involvement.cur = involvement.max;

        this.setState({ involvement: involvement });

        return true;
      }
    }

    return false;
  }

  parseFlexibility() {
    const { student } = this.props;
    if (
      _.has(student, 'mentalFlexibility') &&
      _.has(student, 'career.conventusRole.minMentalFlexibility') &&
      _.has(student, 'career.conventusRole.maxMentalFlexibility')
    ) {
      if (
        !Number.isNaN(student.mentalFlexibility) &&
        !Number.isNaN(student.career.conventusRole.minMentalFlexibility) &&
        !Number.isNaN(student.career.conventusRole.maxMentalFlexibility)
      ) {
        let flexibility = {
          min: Number.parseInt(student.career.conventusRole.minMentalFlexibility),
          max: Number.parseInt(student.career.conventusRole.maxMentalFlexibility),
          cur: Number.parseInt(student.mentalFlexibility),
        };

        this.setState({ flexibility: flexibility });

        return true;
      }
    }

    return false;
  }

  parseCareerName() {
    const { student } = this.props;
    if (_.has(student, 'career.conventusRole.name')) {
      if (student.career.conventusRole.name !== null) {
        this.setState({ careerName: student.career.conventusRole.name });
        return true;
      }
    }

    return false;
  }

  componentDidMount() {
    if (this.parseCareerName() && this.parseAwareness() && this.parseInvolvement() && this.parseFlexibility()) {
      this.setState({ hasNoData: false });
    }
  }

  addIndicatorItem = (name, indicator) => {
    return (
      <Row>
        <Col span={6}>
          <Text className='fontBold'>{name}</Text>
        </Col>
        <Col span={6} className='alignCenter'>
          <Text>{indicator.min}</Text>
        </Col>
        <Col span={6} className='alignCenter'>
          <Text>{indicator.max}</Text>
        </Col>
        <Col span={6} className='alignCenter'>
          <Text>{indicator.cur}</Text>
        </Col>
      </Row>
    );
  };

  addIndicatorValue = (name, indicator) => {
    let success = indicator.cur > indicator.min ? 100 : indicator.min ? (indicator.cur * 100) / indicator.min : 100;
    let percent = (indicator.min * 100) / indicator.max;

    success = parseInt(success);
    percent = parseInt(percent);

    return (
      <Row>
        <Col span={24} className='alignCenter'>
          <Progress percent={percent} success={{ percent: success }} type='dashboard' format={() => <>{success}%</>} />
        </Col>
        <Col span={24} className='alignCenter'>
          {name}
        </Col>
      </Row>
    );
  };

  renderDiagnostics = () => {
    const { t } = this.props;
    const { awareness, involvement, flexibility, careerName } = this.state;

    return (
      <div className='diagnostic'>
        <Row>
          <Col span={24}>
            <strong className='careerName'>
              {t('tabDiagnosticsRole')} {careerName}
            </strong>
            <Title level={4} className='title'>
              <HiOutlineTrendingUp />
              &nbsp;
              {t('tabDiagnosticsCurrent')}&nbsp;
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Text className='fontBold' strong>
              {t('tabDiagnosticsIndicator')}
            </Text>
          </Col>
          <Col span={6} className='alignCenter'>
            <Text className='fontBold' strong>
              {t('tabDiagnosticsMin')}
            </Text>
          </Col>
          <Col span={6} className='alignCenter'>
            <Text className='fontBold' strong>
              {t('tabDiagnosticsMax')}
            </Text>
          </Col>
          <Col span={6} className='alignCenter'>
            <Text className='fontBold' strong>
              {t('tabDiagnosticsCur')}
            </Text>
          </Col>
        </Row>
        {this.addIndicatorItem(t('tabDiagnosticsAwareness'), awareness)}
        {this.addIndicatorItem(t('tabDiagnosticsInvolvement'), involvement)}
        {this.addIndicatorItem(t('tabDiagnosticsFlexibility'), flexibility)}

        <Divider />

        <Row className='legend'>
          <div className='legendBox currentPoints'></div>
          <p className='legendText'>{t('tabDiagnosticsCurrentPoints')}</p>
          <div className='legendBox maxPoints'></div>
          <p className='legendText'>{t('tabDiagnosticsMinimum')}</p>
        </Row>
        <Row>
          <Col span={8}>{this.addIndicatorValue(t('tabDiagnosticsAwareness'), awareness)}</Col>
          <Col span={8}>{this.addIndicatorValue(t('tabDiagnosticsInvolvement'), involvement)}</Col>
          <Col span={8}>{this.addIndicatorValue(t('tabDiagnosticsFlexibility'), flexibility)}</Col>
        </Row>
        <a className='diagnosticLink' href={PDF} target='_blank'>
          {t('tabDiagnosticsLink')}
        </a>
      </div>
    );
  };

  renderNoData = () => {
    const { t } = this.props;
    return (
      <Row>
        <Col span={24}>
          <Empty description={<span>{t('noData')}</span>} />
        </Col>
      </Row>
    );
  };

  render() {
    const { hasNoData } = this.state;

    if (hasNoData === true) {
      return this.renderNoData();
    } else {
      return this.renderDiagnostics();
    }
  }
}

export default withTranslation('studentProfileDialog')(Diagnostics);
