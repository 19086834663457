/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const POST_CHECK_WAITING_LIST_START = 'POST_CHECK_WAITING_LIST_START';
export const POST_CHECK_WAITING_LIST_SUCCESS = 'POST_CHECK_WAITING_LIST_SUCCESS';
export const POST_CHECK_WAITING_LIST_FAIL = 'POST_CHECK_WAITING_LIST_FAIL';

export const GET_COUNT_WAITING_USERS_START = 'GET_COUNT_WAITING_USERS_START';
export const GET_COUNT_WAITING_USERS_SUCCESS = 'GET_COUNT_WAITING_USERS_SUCCESS';
export const GET_COUNT_WAITING_USERS_FAIL = 'GET_COUNT_WAITING_USERS_FAIL';

export const GET_WAITING_STATUSES_START = 'GET_WAITING_STATUSES_START';
export const GET_WAITING_STATUSES_SUCCESS = 'GET_WAITING_STATUSES_SUCCESS';
export const GET_WAITING_STATUSES_FAIL = 'GET_WAITING_STATUSES_FAIL';

export const CLEAR_WAITING_LIST = 'CLEAR_WAITING_LIST';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const checkWaitingListAction = (topicId, params, page = 0, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/admin/waiting/users/${topicId}${getQueryString({ page, size: 10 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_CHECK_WAITING_LIST_START, POST_CHECK_WAITING_LIST_SUCCESS, POST_CHECK_WAITING_LIST_FAIL],
  onSuccess,
});

export const getCountWaitingUsers = (topicId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/admin/waiting/users/count/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COUNT_WAITING_USERS_START, GET_COUNT_WAITING_USERS_SUCCESS, GET_COUNT_WAITING_USERS_FAIL],
  onSuccess,
});

export const getWaitingListStatuses = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/admin/waiting/statuses/notRegistered`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_WAITING_STATUSES_START, GET_WAITING_STATUSES_SUCCESS, GET_WAITING_STATUSES_FAIL],
});

export const clearWaitingList = () => ({
  type: CLEAR_WAITING_LIST,
});
