/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AutosizeInput from 'react-input-autosize';

import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

import ConfirmationModal from '../ConfirmationModal';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ThemesEditor.module.scss';

import { THEME_DELETE, THEME_UPDATE } from '@/constants/permissions';

class TreeNodeTitle extends Component {
  static propTypes = {
    delTheme: PropTypes.func,
    error: PropTypes.string,
    getThemes: PropTypes.func,
    id: PropTypes.number,
    isCheckable: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isValid: PropTypes.func,
    key: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onlyView: PropTypes.bool,
    themes: PropTypes.array,
    updateTheme: PropTypes.func,
    dataQa: PropTypes.string,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isEdit: false,
      name: props.name,
      error: {},
    };
  }

  onEdit = () =>
    this.setState(
      {
        isEdit: !this.state.isEdit,
      },
      () => {
        if (this.state.isEdit) {
          this.nameInput.focus();
        }
      }
    );

  handleChange = event => {
    const { value } = event.target;
    this.props.onChange(event);
    this.setState({ name: value });
  };

  updateTheme = () => {
    this.props.updateTheme(this.state.name);
    this.onEdit();
  };

  cancelTheme = () => {
    const { name } = this.props;
    this.setState({ name });
    this.onEdit();
  };

  changeVisibleModal = () => {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible,
    }));
  };

  render() {
    const { id, error, isCheckable, isEditable, onlyView, isSelected, delTheme, dataQa, t } = this.props;
    const { name, isVisible, isEdit } = this.state;
    const title = (
      <div
        data-qa={`${dataQa}-item`}
        key={id}
        className={classNames(
          css.TreeNode__title,
          { [css.onlyView]: onlyView },
          { [css.active]: onlyView && isSelected }
        )}
      >
        {isEdit ? (
          <AutosizeInput
            data-qa='themeNameInput'
            className={css.TreeNode__input}
            placeholder={t('title')}
            name='theme'
            value={name}
            maxLength={50}
            onChange={this.handleChange}
            ref={input => {
              this.nameInput = input;
            }}
          />
        ) : (
          name
        )}
        {!onlyView && (
          <div className={classNames(css.TreeNode__actions, isEdit ? css.TreeNode__actions__noMargin : '')}>
            {!isCheckable &&
              isEditable &&
              (!isEdit ? (
                this.props.currentUser.authorities.includes(THEME_UPDATE) && (
                  <EditOutlined data-qa='editMaterialBtn' className={css.TreeNode__btns} onClick={this.onEdit} />
                )
              ) : (
                <div>
                  <a
                    data-qa='applyNewThemeBtn'
                    onClick={this.updateTheme}
                    className={css.TreeNode__btns}
                    disabled={error || !name}
                  >
                    <CheckOutlined
                      className={classNames(css.TreeNode__btns, {
                        [css.TreeNode__btns__error]: !!error,
                      })}
                    />
                    {t('apply')}
                  </a>
                  <a
                    data-qa='cancelThemeBtn'
                    onClick={this.cancelTheme}
                    className={classNames(css.TreeNode__btns, css.TreeNode__btns__cancel)}
                  >
                    {t('cancel')}
                  </a>
                </div>
              ))}
            {!isCheckable && isEditable && !isEdit && this.props.currentUser.authorities.includes(THEME_DELETE) && (
              <CloseOutlined
                data-qa='deleteThemeBtn'
                onClick={this.changeVisibleModal}
                className={css.TreeNode__btns}
              />
            )}
          </div>
        )}
        <ConfirmationModal
          open={isVisible}
          onOk={delTheme}
          onCancel={this.changeVisibleModal}
          title={t('deleteTheme')}
          okText={t('delete')}
          confirmationText={t('description')}
        />
      </div>
    );

    return title;
  }
}

export default withTranslation('treeNodeTitle')(TreeNodeTitle);
