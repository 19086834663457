import { API_D360_URL } from '../service_api';
import {
  D360_COPY_SCALE_FAIL,
  D360_COPY_SCALE_START,
  D360_COPY_SCALE_SUCCESS,
  D360_CREATE_SCALE_FAIL,
  D360_CREATE_SCALE_START,
  D360_CREATE_SCALE_SUCCESS,
  D360_DELETE_SCALE_FAIL,
  D360_DELETE_SCALE_START,
  D360_DELETE_SCALE_SUCCESS,
  D360_GET_SCALES_FAIL,
  D360_GET_SCALES_START,
  D360_GET_SCALES_SUCCESS,
  D360_SCALE_CAN_SKIP_FAIL,
  D360_SCALE_CAN_SKIP_START,
  D360_SCALE_CAN_SKIP_SUCCESS,
  D360_UPDATE_SCALE_FAIL,
  D360_UPDATE_SCALE_START,
  D360_UPDATE_SCALE_SUCCESS,
} from './constants';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

// POST
// eslint-disable-next-line
export const D360CreateScale = (projectId, { name, description, _scores }, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/scale`,
    body: {
      d360_project_id: projectId,
      name: name,
      description: description,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_SCALE_START, D360_CREATE_SCALE_SUCCESS, D360_CREATE_SCALE_FAIL],
    onSuccess,
    onError,
  };
};

export const D360ScaleCanSkip = (projectId, scaleId, value, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/scale/skip`,
    body: {
      d360_project_id: projectId,
      id: scaleId,
      value: value,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_SCALE_CAN_SKIP_START, D360_SCALE_CAN_SKIP_SUCCESS, D360_SCALE_CAN_SKIP_FAIL],
    onSuccess,
    onError,
  };
};

// UPDATE
export const D360UpdateScale = (projectId, { id, name, description }, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/scale`,
    body: {
      d360_project_id: projectId,
      id: id,
      name: name,
      description: description,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_UPDATE_SCALE_START, D360_UPDATE_SCALE_SUCCESS, D360_UPDATE_SCALE_FAIL],
    onSuccess,
    onError,
  };
};

// DELETE
export const D360DeleteScale = (projectId, scaleId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/scale${getQueryString({
      id: scaleId,
      d360_project_id: projectId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DELETE_SCALE_START, D360_DELETE_SCALE_SUCCESS, D360_DELETE_SCALE_FAIL],
    onSuccess,
    onError,
  };
};

export const D360GetScales = (projectId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/scales${getQueryString({
      d360_project_id: projectId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_SCALES_START, D360_GET_SCALES_SUCCESS, D360_GET_SCALES_FAIL],
    onSuccess,
    onError,
  };
};

export const D360CopyScale = (projectId, scaleId, name, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/scale/copy`,
    body: {
      d360_project_id: projectId,
      id: scaleId,
      name: name,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_COPY_SCALE_START, D360_COPY_SCALE_SUCCESS, D360_COPY_SCALE_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

   import {
    D360CreateScale,
    D360UpdateScale,
    D360DeleteScale,
    D360GetScales,
   } from '@/store/d360_scales/actions';

**************************************************************/
