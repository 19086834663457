import {
  CLEAR_NEWS_FILTERS,
  CLEAR_NEWS_ID,
  CLEAR_NEWS_VISIBILITY,
  CREATE_NEWS_FAIL,
  CREATE_NEWS_START,
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_VISIBILITY_FAIL,
  DELETE_NEWS_VISIBILITY_START,
  DELETE_NEWS_VISIBILITY_SUCCESS,
  GET_NEWS_ID_FAIL,
  GET_NEWS_ID_START,
  GET_NEWS_ID_SUCCESS,
  GET_NEWS_VISIBILITY_FAIL,
  GET_NEWS_VISIBILITY_START,
  GET_NEWS_VISIBILITY_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  POST_NEWS_FILTERS_FAIL,
  POST_NEWS_FILTERS_START,
  POST_NEWS_FILTERS_SUCCESS,
  PUT_NEWS_FAIL,
  PUT_NEWS_START,
  PUT_NEWS_SUCCESS,
  PUT_NEWS_VISIBILITY_FAIL,
  PUT_NEWS_VISIBILITY_START,
  PUT_NEWS_VISIBILITY_SUCCESS,
  PUT_STATUS_NEWS_FAIL,
  PUT_STATUS_NEWS_START,
  PUT_STATUS_NEWS_SUCCESS,
  SAVE_NEWS_FILTERS,
  SAVE_SORT_NEWS,
  UPDATE_NEWS_VISIBILITY_FAIL,
  UPDATE_NEWS_VISIBILITY_START,
  UPDATE_NEWS_VISIBILITY_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const saveNewsFilters = value => ({
  type: SAVE_NEWS_FILTERS,
  value,
});

export const saveSortNews = value => ({
  type: SAVE_SORT_NEWS,
  value,
});

export const clearNewsFilters = value => ({
  type: CLEAR_NEWS_FILTERS,
  value,
});

export const clearNewsId = () => ({
  type: CLEAR_NEWS_ID,
});

export const clearNewsVisibility = () => ({
  type: CLEAR_NEWS_VISIBILITY,
});

export const getStatuses = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/admin/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATUS_START, GET_STATUS_SUCCESS, GET_STATUS_FAIL],
  onSuccess,
  onError,
});

export const postNewsFilters = (params, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news/admin${getQueryString({ size: 20, page, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_NEWS_FILTERS_START, POST_NEWS_FILTERS_SUCCESS, POST_NEWS_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const updateNews = (id, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/admin/update/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_NEWS_START, PUT_NEWS_SUCCESS, PUT_NEWS_FAIL],
  onSuccess,
  onError,
});

export const changeStatusNews = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/admin/update/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_STATUS_NEWS_START, PUT_STATUS_NEWS_SUCCESS, PUT_STATUS_NEWS_FAIL],
  onSuccess,
  onError,
});

export const getNewsId = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/admin/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_NEWS_ID_START, GET_NEWS_ID_SUCCESS, GET_NEWS_ID_FAIL],
  onSuccess,
  onError,
});

export const createNews = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news/admin/create`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CREATE_NEWS_START, CREATE_NEWS_SUCCESS, CREATE_NEWS_FAIL],
  onSuccess,
  onError,
});

export const editNewsVisibility = (id, isVisible, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/admin/visible/${id}${getQueryString({ isVisible })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  // isVisible, ???
  types: [UPDATE_NEWS_VISIBILITY_START, UPDATE_NEWS_VISIBILITY_SUCCESS, UPDATE_NEWS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const getNewsVisibility = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/admin/visibility/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_NEWS_VISIBILITY_START, GET_NEWS_VISIBILITY_SUCCESS, GET_NEWS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const updateNewsVisibility = (id, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/admin/visibility/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_NEWS_VISIBILITY_START, PUT_NEWS_VISIBILITY_SUCCESS, PUT_NEWS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const deleteNewsVisibility = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/news/admin/visibility/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_NEWS_VISIBILITY_START, DELETE_NEWS_VISIBILITY_SUCCESS, DELETE_NEWS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});
