/* eslint-disable */
import { loginFail, loginSuccess, logoutFail, logoutSuccess } from './actions';
import { LOGIN_START, USER_LOGOUT_START } from './types';

import { clearStorage } from '@/utils/clearStorage';
import adminStorage from '@shared/services/admin-storage';
import loginService from '@shared/services/login-service';
import { goToLoginPage } from '@shared/utils/location';

import _get from 'lodash/get';
import { call, put, takeLatest } from 'redux-saga/effects';

function* login({ payload }) {
  try {
    const credentials = {
      password: payload.password,
      username: payload.username,
    };

    const response = yield call(loginService.login, credentials, { 'g-recaptcha-response': payload.recaptchaToken });

    const authorities = _get(response, 'authorities', []);

    adminStorage.setData({ ...response, username: payload.username, roles: authorities });

    yield put(loginSuccess(authorities));

    payload.onSuccess && payload.onSuccess(authorities); // todo hotfix
  } catch (error) {
    yield put(loginFail(_get(error, 'response.data.message')));
  }
}

function* logout() {
  try {
    goToLoginPage();
    clearStorage();
    yield call(loginService.logout);
    adminStorage.removeData();
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFail());
  }
}

export default [takeLatest(USER_LOGOUT_START, logout), takeLatest(LOGIN_START, login)];
