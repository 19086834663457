import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getChapters } from '@/store/chapters/actions';
import { updateChecklist } from '@/store/lms/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Divider, Dropdown, Form, Input, Row, Tooltip } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FileDoneOutlined, PlusOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import Utils from '@/Utils';
import { cloneDeep, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';

const { TextArea } = Input;

const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_ITEM_DESCRIPTION_LENGTH = 1000;
const MAX_TITLE_LENGTH = 65;

const ChecklistAssesor = ({ data, canEdit, topicTypeId, editBlock, deleteBlock, forceClose, topicId }) => {
  const { currentUser } = useSelector(selectUsers);
  const { t } = useTranslation(['checklistAssesor', 'blocksCommon']);
  const dispatch = useDispatch();

  const [sendData, setSendData] = useState({
    description: '',
    name: '',
    checklist: [
      {
        description: '',
      },
    ],
  });

  const isCanEdit =
    (currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) || topicTypeId === 25) &&
    currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE);
  const isCanDelete =
    (currentUser.authorities.includes(TOPIC_BLOCK_DELETE) || topicTypeId === 25) &&
    currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE);

  const isCanSave = useMemo(() => {
    const isDescriptionNotEmpty = sendData?.description?.trim()?.length !== 0;
    const isChecklistDescriptionNotEmpty = sendData?.checklist.every(item => item?.description?.trim()?.length !== 0);
    const isDataChanged = !isEqual(sendData, {
      name: data?.name,
      description: data?.description,
      checklist: data?.checklist,
    });

    return isChecklistDescriptionNotEmpty && isDescriptionNotEmpty && isDataChanged;
  }, [sendData, data?.description, data?.checklist]);

  const renderHeader = useMemo(
    () => (
      <div className={css['Block-header']}>
        <FileDoneOutlined className={css['Block-header-item']} />
        <div className={css['Block-header-name']}>
          <Tooltip title={sendData?.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(sendData?.name, MAX_TITLE_LENGTH)}
          </Tooltip>
        </div>
      </div>
    ),
    [sendData?.name]
  );

  const dropDownMenu = useMemo(
    () => [
      ...(isCanEdit
        ? [
            {
              key: '1',
              label: <div data-qa='editBlockBtn'>{t('edit', { ns: 'blocksCommon' })}</div>,
              onClick: () => data.id && editBlock(data.id),
            },
          ]
        : []),
      ...(isCanDelete
        ? [
            {
              key: '2',
              label: <div data-qa='deleteBlockBtn'>{t('remove', { ns: 'blocksCommon' })}</div>,
              onClick: () => data.id && deleteBlock(data.id),
            },
          ]
        : []),
    ],
    [isCanDelete, isCanEdit, editBlock, deleteBlock]
  );

  // Сохранение блока
  const handleSaveChecklist = async () => {
    await dispatch(updateChecklist(data?.id, sendData));
    dispatch(getChapters(topicId));
  };

  const renderExtra = useMemo(() => (
    <div onClick={e => e.stopPropagation()}>
      <Button
        className={css['Block-header-save']}
        type='primary'
        data-qa='saveBlockBtn'
        disabled={!isCanSave || !canEdit}
        onClick={() => handleSaveChecklist()}
      >
        {t('save', { ns: 'blocksCommon' })}
      </Button>

      {(isCanEdit || isCanDelete) && (
        <Dropdown menu={{ items: dropDownMenu }} placement='bottomRight' trigger={['click']}>
          <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
        </Dropdown>
      )}
    </div>
  ));

  // удаление пункта
  const handleDeleteCheckList = index => {
    const updatedChecklist = sendData.checklist.filter((_, itemIndex) => itemIndex !== index);

    setSendData({
      ...sendData,
      checklist: updatedChecklist,
    });
  };

  // добавить новый пункт
  const handleAddChecklist = () => {
    setSendData(prevState => ({
      ...prevState,
      checklist: [
        ...prevState?.checklist,
        {
          description: '',
        },
      ],
    }));
  };

  // изменение описание пункта
  const handleChangeChecklist = (value, index) => {
    const newChecklist = [...sendData.checklist];
    newChecklist[index].description = value;
    setSendData(prevState => ({ ...prevState, checklist: newChecklist }));
  };

  // изменение описания
  const handleChangeDescription = description => {
    setSendData(prevState => ({
      ...prevState,
      description,
    }));
  };

  // рендер пунктов
  const checklistItems = useMemo(() => {
    return sendData?.checklist.map((checklist, index) => (
      <Form.Item key={index} label={t('itemDescription')}>
        <Row align='middle'>
          <Col span={21}>
            <TextArea
              placeholder={t('itemDescriptionPlaceholder', { max: MAX_ITEM_DESCRIPTION_LENGTH })}
              onChange={e => handleChangeChecklist(e.target?.value, index)}
              value={checklist?.description}
              maxLength={MAX_ITEM_DESCRIPTION_LENGTH}
              autoSize={{
                minRows: 2,
                maxRows: 6,
              }}
            />
          </Col>
          <Col span={3}>
            {index !== 0 && (
              <Tooltip title={t('deleteChecklist')}>
                <Button type='link' danger onClick={() => handleDeleteCheckList(index)}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
          </Col>
        </Row>
      </Form.Item>
    ));
  }, [sendData?.checklist]);

  // Обновление checklist'a
  useEffect(() => {
    setSendData(prevProps => {
      if (data?.name !== prevProps?.name && prevProps?.name !== '') {
        return {
          ...prevProps,
          name: data?.name,
        };
      }

      const correctChecklist = data?.checklist?.length > 0 ? cloneDeep(data?.checklist) : [{ description: '' }];

      return {
        name: data?.name,
        description: data?.description ?? '',
        checklist: correctChecklist,
      };
    });
  }, [data?.checklist, data?.description, data?.name]);

  return (
    <div className={css['Block']}>
      <CollapseLMS
        className={css['Block-layout']}
        header={renderHeader}
        extra={renderExtra}
        data={data}
        key={data.id}
        forceClose={forceClose}
      >
        <Form labelCol={{ span: 6 }}>
          <Form.Item label={t('assesorDescription')}>
            <TextArea
              value={sendData?.description}
              onChange={e => handleChangeDescription(e.target.value)}
              placeholder={t('assesorDescriptionPlaceholder', { max: MAX_DESCRIPTION_LENGTH })}
              maxLength={MAX_DESCRIPTION_LENGTH}
              autoSize={{
                minRows: 4,
                maxRows: 8,
              }}
            />
          </Form.Item>

          {checklistItems}
        </Form>

        <Button type='link' icon={<PlusOutlined />} onClick={handleAddChecklist}>
          {t('addNewChecklist')}
        </Button>

        <Divider />
      </CollapseLMS>
    </div>
  );
};

ChecklistAssesor.propTypes = {
  canEdit: PropTypes.bool,
  data: PropTypes.shape({
    checklist: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  deleteBlock: PropTypes.func,
  editBlock: PropTypes.func,
  forceClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  topicId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topicTypeId: PropTypes.number,
};

export default ChecklistAssesor;
