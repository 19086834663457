// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-Topic-DeadlineFilter-module__ql-picker-kuDh09.admin-src-components-Topic-DeadlineFilter-module__ql-font-f5uXMf .admin-src-components-Topic-DeadlineFilter-module__ql-picker-label-YFL0tH[data-value="Montserrat"]:before, .admin-src-components-Topic-DeadlineFilter-module__ql-picker-kuDh09.admin-src-components-Topic-DeadlineFilter-module__ql-font-f5uXMf .admin-src-components-Topic-DeadlineFilter-module__ql-picker-item-m_xbJb[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-Topic-DeadlineFilter-module__ql-font-Montserrat-uOtCvU {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-Topic-DeadlineFilter-module__Filter-block-LPoF1f {
  flex-direction: column;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/Topic/DeadlineFilter.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Filter-block {\n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-Topic-DeadlineFilter-module__ql-picker-kuDh09`,
	"qlPicker": `admin-src-components-Topic-DeadlineFilter-module__ql-picker-kuDh09`,
	"ql-font": `admin-src-components-Topic-DeadlineFilter-module__ql-font-f5uXMf`,
	"qlFont": `admin-src-components-Topic-DeadlineFilter-module__ql-font-f5uXMf`,
	"ql-picker-label": `admin-src-components-Topic-DeadlineFilter-module__ql-picker-label-YFL0tH`,
	"qlPickerLabel": `admin-src-components-Topic-DeadlineFilter-module__ql-picker-label-YFL0tH`,
	"ql-picker-item": `admin-src-components-Topic-DeadlineFilter-module__ql-picker-item-m_xbJb`,
	"qlPickerItem": `admin-src-components-Topic-DeadlineFilter-module__ql-picker-item-m_xbJb`,
	"ql-font-Montserrat": `admin-src-components-Topic-DeadlineFilter-module__ql-font-Montserrat-uOtCvU`,
	"qlFontMontserrat": `admin-src-components-Topic-DeadlineFilter-module__ql-font-Montserrat-uOtCvU`,
	"Filter-block": `admin-src-components-Topic-DeadlineFilter-module__Filter-block-LPoF1f`,
	"filterBlock": `admin-src-components-Topic-DeadlineFilter-module__Filter-block-LPoF1f`
};
export default ___CSS_LOADER_EXPORT___;
