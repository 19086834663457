/* eslint-disable */
import {
  GET_USER_INCOME_FAIL,
  GET_USER_INCOME_START,
  GET_USER_INCOME_SUCCESS,
  PUT_USER_WRITEOFFCOIN_FAIL,
  PUT_USER_WRITEOFFCOIN_START,
  PUT_USER_WRITEOFFCOIN_SUCCESS,
} from './actions';

const initialState = {
  userIncome: [],
  userIncomeTotal: 0,
  userIncomePageSize: 0,
  userIncomePageNumber: 0,
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_USER_INCOME_START:
      return {
        ...state,
        userIncome: [],
        isLoading: true,
        error: false,
      };

    case GET_USER_INCOME_SUCCESS:
      return {
        ...state,
        userIncome: response,
        userIncomeTotal: Number(headers.get('X-Total-Elements')) || 0,
        userIncomePageSize: Number(headers.get('X-Page-Size')) || 0,
        userIncomePageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_USER_INCOME_FAIL:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case PUT_USER_WRITEOFFCOIN_START:
      return {
        ...state,
        error: false,
        isLoading: true,
      };
    case PUT_USER_WRITEOFFCOIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PUT_USER_WRITEOFFCOIN_FAIL:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
