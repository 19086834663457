export const D360_CREATE_RESPONDENT_START = 'D360_CREATE_RESPONDENT_START';
export const D360_CREATE_RESPONDENT_SUCCESS = 'D360_CREATE_RESPONDENT_SUCCESS';
export const D360_CREATE_RESPONDENT_FAIL = 'D360_CREATE_RESPONDENT_FAIL';

export const D360_DELETE_RESPONDENT_START = 'D360_DELETE_RESPONDENT_START';
export const D360_DELETE_RESPONDENT_SUCCESS = 'D360_DELETE_RESPONDENT_SUCCESS';
export const D360_DELETE_RESPONDENT_FAIL = 'D360_DELETE_RESPONDENT_FAIL';

export const D360_GET_RESPONDENTS_START = 'D360_GET_RESPONDENTS_START';
export const D360_GET_RESPONDENTS_SUCCESS = 'D360_GET_RESPONDENTS_SUCCESS';
export const D360_GET_RESPONDENTS_FAIL = 'D360_GET_RESPONDENTS_FAIL';
