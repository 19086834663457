/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getDepartments } from '@/store/department/actions';
import { getFile } from '@/store/files/actions';
import { getStudentStat, toggleUserLocked } from '@/store/students/actions';
import { changeEmailNotification } from '@/store/users/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Avatar, Badge, Breadcrumb, Col, Collapse, Divider, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IoLocationOutline, IoMailOutline } from 'react-icons/io5';

import PortraitPlaceholder from '../PortraitPlaceholder.png';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

const { Title } = Typography;
const { Text } = Typography;
const { Item: BreadcrumbItem } = Breadcrumb;
const { Panel } = Collapse;

export class Personal extends Component {
  static propTypes = {
    t: PropTypes.func,
    student: PropTypes.object,
    getFile: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      photo: '',
    };
  }

  componentDidMount() {
    if (this.props.student.localPhotoUuid) {
      this.props.getFile(this.props.student.localPhotoUuid, res => {
        this.setState({ photo: URL.createObjectURL(res) });
      });
    } else if (this.props.student.photoUuid) {
      this.props.getFile(this.props.student.photoUuid, res => {
        this.setState({ photo: URL.createObjectURL(res) });
      });
    } else {
      this.setState({ photo: PortraitPlaceholder });
    }
  }

  isShowAdditionInfo = () => {
    const { student } = this.props;
    if (typeof student !== 'undefined') {
      if (
        typeof student.function !== 'undefined' &&
        typeof student.about !== 'undefined' &&
        typeof student.purpose !== 'undefined'
      ) {
        if (
          (student.function !== null && student.function.length > 0) ||
          (student.about !== null && student.about.length > 0) ||
          (student.purpose !== null && student.purpose.length > 0)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  collapseItems = () => {
    const { student, t } = this.props;
    const returnArr = [];

    if (student.function !== null && student.function.length > 0)
      returnArr.push({
        key: 1,
        label: t('tabPersonalFunction'),
        children: student?.function ?? t('noData'),
      });

    if (student.about !== null && student.about.length > 0)
      returnArr.push({
        key: 2,
        label: t('tabPersonalAbout'),
        children: student?.about ?? t('noData'),
      });

    if (student.purpose !== null && student.purpose.length > 0)
      returnArr.push({
        key: 3,
        label: t('tabPersonalObjective'),
        children: student?.purpose ?? t('noData'),
      });

    return returnArr;
  };

  render() {
    const { student, t } = this.props;
    const { photo } = this.state;

    const showAdditionInfo = this.isShowAdditionInfo();

    const breadcrumbItems = [
      {
        title: (
          <>
            <IoMailOutline className='svgButton' />
            &nbsp;
            <Text>
              <a href={`mailto:${student.email}`} target='_blank'>
                {student.email}
              </a>
            </Text>
          </>
        ),
      },
      {
        title: (
          <>
            <IoLocationOutline />
            &nbsp;<Text>location</Text>
          </>
        ),
      },
    ];

    return (
      <>
        <Row className='personalInfo'>
          <Col span={6}>
            <Badge status='success' dot={true}>
              <Avatar icon={<UserOutlined />} size='large' src={photo} style={{ width: '5rem', height: '5rem' }} />
            </Badge>
          </Col>
          <Col span={18}>
            <div style={{ fontSize: '18px', fontFamily: 'sans-serif' }}>
              <Title level={4} className='username'>
                {student.lastName} {student.firstName} {student.middleName}
              </Title>
              <p className='userDepartment'>{student.department || t('tabPersonalNoDepartment')}</p>
              <p className='userPosition'>{student.position || t('tabPersonalNoPosition')}</p>
            </div>
            <Breadcrumb separator='|' items={breadcrumbItems} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={8}>
            <Text>{t('tabPersonalMainCompany')}</Text>
          </Col>
          <Col span={16}>{student.domainCompany.shortName || t('noData')}</Col>
        </Row>
        {student.company && (
          <Row>
            <Col span={8}>
              <Text>{t('tabPersonalSubCompany')}</Text>
            </Col>
            <Col span={16}>{student.company || t('noData')}</Col>
          </Row>
        )}
        <Row>
          <Col span={8}>
            <Text>{t('tabPersonalManager')}</Text>
          </Col>
          <Col span={16}>{student.manager || t('noData')}</Col>
        </Row>

        <Row>
          <Col span={8}>
            <Text>{t('tabPersonalStartOfJob')}</Text>
          </Col>
          <Col span={16}>{student.startOfJob || t('noData')}</Col>
        </Row>

        {showAdditionInfo && (
          <Collapse accordion={true} defaultActiveKey='1' className='additionInfo' items={this.collapseItems()} />
        )}
      </>
    );
  }
}

const mapStateToProps = createSelector([usersSelect], users => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  authorities: users.currentUser.authorities,
}));

const mapActionsToProps = {
  toggleUserLocked,
  getFile,
  getDepartments,
  changeUser: changeEmailNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('studentProfileDialog')(Personal));
