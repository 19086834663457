export const DATE_FORMAT = 'DD.MM.YYYY';

export const APPROVED_BY_USER = 'APPROVED_BY_USER';
export const APPROVED_BY_MANAGER = 'APPROVED_BY_MANAGER';
export const REJECTED_BY_ADMIN = 'REJECTED';
export const APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN';
export const WAITING_BY_USER = 'WAITING_BY_USER';
export const FINISHED = 'FINISHED';

export const RTN_WRK = 'rtn_wrk';
export const CHECK_APPROVED = 'checkApproved';
export const FINISH_IPR = 'finishIpr';
export const VIEW_IPR = 'viewIpr';

export const TASKS = 'tasks';
export const TOPICS = 'topics';

export const FINISHED_TOPICS_TASKS = ['FINISHED', 'EXPIRED'];
export const FINISHED_IPR_STATUS = ['APPROVED_BY_ADMIN', 'APPROVED_BY_MANAGER'];

export const MAX_TOPICS_IPR = 50;
export const MAX_EXECUTIVE_IPR = 50;
