/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import { PlanStatus } from '@shared/constants/plan';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

const ShowCourseStatus = ({ statusValue, mentionValue, info }) => {
  const { t } = useTranslation(['coursesTopicRow']);

  const statusList = () => [
    {
      id: 1,
      status: 'WAITING',
      label: t('pendingManager'),
      colorText: 'gold',
    },
    {
      id: 1,
      status: 'SCHEDULED',
      label: t('notCompleted'),
      colorText: 'orange',
    },
    {
      id: 2,
      status: 'APPROVED',
      label: t('approvedAdmin'),
      colorText: 'green',
    },
    {
      id: 3,
      status: 'REJECTED',
      label: t('denied'),
      colorText: 'red',
    },
    {
      id: 4,
      status: 'WAITING_BY_USER',
      label: t('pending'),
      colorText: 'blue',
    },
    {
      id: 5,
      status: 'APPROVED_BY_USER',
      label: t('confirmed'),
      colorText: 'green',
    },
    {
      id: 6,
      status: 'IN_PROGRESS',
      label: t('inProgress'),
      colorText: 'blue',
    },
    {
      id: 7,
      status: 'EXPIRED',
      label: info?.nameAdmin === t('requiresCompletion') ? t('requiresCompletion') : t('completed'),
      colorText: 'green',
    },
    {
      id: 8,
      status: 'FINISHED',
      label:
        mentionValue == false && info?.nameAdmin === t('requiresCompletion') ? t('requiresCompletion') : t('finished'),
      colorText: 'green',
    },
    {
      id: 9,
      status: 'REJECTED_BY_USER',
      label: t('decline'),
      colorText: 'red',
    },
    {
      id: 10,
      status: 'APPROVED_BY_MANAGER',
      label: t('pendingApproval'),
      colorText: 'gold',
    },
    {
      id: 11,
      status: 'REJECTED_BY_MANAGER',
      label: t('deniedManager'),
      colorText: 'red',
    },
    {
      id: 12,
      status: 'ON_REGISTRATION',
      label: t('registration'),
      colorText: 'gold',
    },
    {
      id: 13,
      status: PlanStatus.PLANNED,
      label: t('planned'),
      colorText: 'orange',
    },
  ];

  const status = statusList().filter(item => {
    if (item.status === statusValue) {
      return item;
    }
    if (statusValue === 'FINISHED' && mentionValue == false && item.id === 7) {
      return item;
    }
  });

  return (
    <div className={css['Status-tag-container']}>
      <Tag className={css['Status-tag']} color={status[0] && status[0].colorText}>
        {info ? info?.nameAdmin : status[0] && status[0].label}
      </Tag>
    </div>
  );
};

ShowCourseStatus.propTypes = {
  statusValue: PropTypes.string,
  mentionValue: PropTypes.bool,
};

export default ShowCourseStatus;
