/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { connect, useSelector } from 'react-redux';

import { D360ScaleCanSkip } from '@/store/d360/d360_scales/actions';
import { D360CreateScore, D360DeleteScore, D360GetScores, D360UpdateScore } from '@/store/d360/d360_scores/actions';

import { selectD360Scales } from '@/store/d360/d360_scales/selectors';

import { Button, Col, Modal, Row, Switch, Table } from 'antd';
import { CgTrending } from 'react-icons/cg';
import { FiHexagon } from 'react-icons/fi';

import ScaleEditScore from './ScaleEditScore';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const ButtonGroup = Button.Group;

const pageSizeOptions = ['10', '50', '100', '500', '1000', '10000'];

const ScaleScores = props => {
  const { scales } = useSelector(selectD360Scales);

  const [selectedScoresKeys, setSelectedScoresKeys] = useState([]);
  const [selectedScores, setSelectedScores] = useState([]);
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const [showEditScore, setShowEditScore] = useState(false);
  const { t } = useTranslation('D360_Scales');
  const [canSkipBusy, setCanSkipBusy] = useState(false);

  /**
   * Текущая выбранная оценка.
   */
  const [currentScore, setCurrentScore] = useState({});

  /**
   * Идентификатор проекта.
   */
  const [projectId, setProjectId] = useState(null);

  /**
   * Идентификатор шкалы.
   */
  const [scaleId, setScaleId] = useState(null);

  const [canSkip, setCanSkip] = useState(false);

  /**
   * Данные таблицы.
   */
  const [tableData, setTableData] = useState([]);

  const [paginationPageSize, setPaginationPageSize] = useState(10);

  /**
   * Инициализация компонента.
   */
  useEffect(() => {
    if (props) {
      if (props.project && props.project.id && props.scale && props.scale.id) {
        setProjectId(props.project.id);
        setScaleId(props.scale.id);
        props.D360GetScores(props.project.id, props.scale.id);
      }

      if (props.scale) {
        setCanSkip(props.scale.can_skip === true);
      }
    }
  }, [props.project, props.scale]);

  /**
   * Загрузка данных.
   */
  useEffect(() => {
    let data = [];
    if (props.scores && Array.isArray(props.scores)) {
      props.scores.forEach(score => {
        if (typeof score.value !== 'undefined' && score.value > 0) {
          data.push({ ...score, key: score.id });
        }
      });
    }

    setTableData(data);
  }, [props.scores]);

  useEffect(() => {
    if (scales) {
      const currentScale = scales.find(e => e.id === scaleId);
      if (currentScale?.hasOwnProperty('can_skip')) {
        // Изменил изменения switch на противоположный, но не понял почему только по [0] элементу
        const currentCanSkip = currentScale['can_skip'];
        setCanSkip(currentCanSkip);
      }
    }
  }, [scales]);

  /**
   * Столбцы таблицы.
   *
   * @returns {*[]}
   */
  const tableColumns = () => {
    return [
      {
        title: t('columnScore'),
        width: 70,
        key: 'value',
        dataIndex: 'value',
        align: 'center',
      },
      {
        title: t('columnIndicator'),
        key: 'indicator',
        dataIndex: 'indicator',
        render: (name, record) => {
          return (
            <span className={css.TableLink} type='link' onClick={() => onEditScore(record)}>
              {record.indicator}
            </span>
          );
        },
      },
    ];
  };

  const onCreateScore = () => {
    setCurrentScore({});
    setShowEditScore(true);
  };

  const onEditScore = score => {
    setCurrentScore(score);
    setShowEditScore(true);
  };

  const onSubmit = () => {
    if (props.onSubmit && typeof props.onSubmit === 'function') {
      props.onSubmit();
    }
  };

  const onDelete = () => {
    if (selectedScores && Array.isArray(selectedScores)) {
      for (let score of selectedScores) {
        if (score && score.id) {
          props.D360DeleteScore(projectId, scaleId, score.id);
        }
      }
    }
    setSelectedScores([]);
    setSelectedScoresKeys([]);
    setDisableDeleteButton(true);
  };

  const rowSelection = () => {
    if (props.editable) {
      return {
        selectedRowKeys: selectedScoresKeys,
        onChange: (selectedKeys, selectedRows) => {
          setSelectedScoresKeys(selectedKeys);
          setSelectedScores(selectedRows);
          if (selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0) {
            setDisableDeleteButton(false);
          }
        },
      };
    }
  };

  const windowTitle = () => {
    return (
      <>
        <CgTrending />
        &nbsp;{t('scaleScoresWindowTitle')}
      </>
    );
  };

  const handleEditScoreCancel = () => {
    setShowEditScore(false);
  };

  /**
   *
   * @param scoreId
   * @param indicator
   * @param value
   */
  const handleEditScoreSubmit = (scoreId, indicator, value) => {
    // Update score
    if (scoreId) {
      props.D360UpdateScore(projectId, scaleId, scoreId, indicator, value, () => {
        D360GetScores(projectId, scaleId);
      });

      // Create score
    } else {
      props.D360CreateScore(projectId, scaleId, indicator, value);
    }

    setShowEditScore(false);
  };

  const canSkipSubmit = value => {
    setCanSkipBusy(true);
    props.D360ScaleCanSkip(
      projectId,
      scaleId,
      value,
      response => {
        setCanSkipBusy(false);
      },
      error => {
        setCanSkipBusy(false);
      }
    );
  };

  const windowFooter = () => {
    return (
      <Button type='primary' onClick={onSubmit}>
        {t('scaleScoresWindowClose')}
      </Button>
    );
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <>
        <Modal title={windowTitle()} open={props.open} footer={windowFooter()} onCancel={onSubmit}>
          <Row className={css.Notes}>
            <Col span={24}>
              <FiHexagon size='3rem' className={css.NotesIcon} />
              <p className={css.NotesText}>{t('scaleScoresWindowDescription')}</p>
            </Col>
          </Row>

          {props.editable && (
            <Row className={css.WindowRow}>
              <Col span={24} align='right'>
                <ButtonGroup>
                  <Button size='small' type='primary' onClick={onCreateScore}>
                    {t('addScore')}
                  </Button>
                  <Button size='small' type='primary' danger disabled={disableDeleteButton} onClick={onDelete}>
                    {t('deleteScore')}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          )}

          <Row className={css.WindowRow}>
            <Col span={24}>
              <Table
                rowSelection={rowSelection()}
                columns={tableColumns()}
                dataSource={tableData}
                loading={props.loading}
                size='small'
                bordered={true}
                pagination={{
                  defaultPageSize: 10,
                  pageSize: paginationPageSize,
                  pageSizeOptions: pageSizeOptions,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onShowSizeChange: (c, s) => {
                    setPaginationPageSize(s);
                  },
                  responsive: true,
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Switch className={css['Diff-switch']} checked={canSkip} onClick={canSkipSubmit} loading={canSkipBusy} />
              {t('labelDiffToAnswer')}
            </Col>
          </Row>
        </Modal>

        {showEditScore && (
          <ScaleEditScore
            score={currentScore}
            scores={tableData}
            onCancel={handleEditScoreCancel}
            onSubmit={handleEditScoreSubmit}
            editable={props.editable}
          />
        )}
      </>
    );
  };

  /**
   * Запуск компонента.
   */
  return render();
};

/**
 * Типизация свойств компонента.
 *
 * @type {{open: *, onCancel: *, onSubmit: *, scale: shim}}
 */
ScaleScores.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  project: PropTypes.any,
  scale: PropTypes.any,
  editable: PropTypes.bool.isRequired,
};

/**
 * Состояния в редаксе.
 *
 * @param state
 * @returns {{scores: Array, loading: *}}
 */
const mapStateToProps = state => ({
  scores: state.d360Scores.scores,
  loading: state.d360Scores.loading,
});

/**
 * Экшены редакса.
 *
 * @type {{D360CreateScore: *, D360UpdateScore: *, D360DeleteScore: *,  D360GetScores: *}}
 */
const mapDispatchToProps = {
  D360CreateScore,
  D360UpdateScore,
  D360DeleteScore,
  D360GetScores,
  D360ScaleCanSkip,
};

/**
 * Экспорт компонента.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ScaleScores);
