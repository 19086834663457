/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { exportExecutiveTopic } from '@/store/executive/actions';
import { getTopicResultExport } from '@/store/topics/actions';

import { Button, Col, Collapse, Modal, Rate, Row, Table } from 'antd';

import { getExecutiveTopicTask } from '../../../store/executive/actions';
import { getFile } from '../../../store/files/actions';
import { checkStudyPlan } from '../../../store/study-plan/actions';
import DataTestInfo from './DataTestInfo';

import { isExecutiveTask } from '@shared/utils/topic-utils';

import PropTypes from 'prop-types';

import css from '../TopicCreationDialog/TopicCreationDialog.module.scss';

const { Panel } = Collapse;

const equalsElement = <span>&nbsp;=&nbsp;</span>;

export class TestsInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: null,
      review: '',
      files: [],
      filesReview: null,
      reviewFilesName: null,
      filesName: [],
      rating: 0,
      date: new Date(),
      exportFileName: '',
    };
  }

  componentDidMount() {
    if (isExecutiveTask(this.props.topicTypeId)) {
      this.props.checkStudyPlan(this.props.user.id, this.props.topicId, '', '', res => {
        this.props.getExecutiveTopicTask(res && res[0].id, res => {
          this.setState({
            review: res.answer,
            files: res.fileUuids,
            filesReview: res.reviewFileUuid,
            reviewFilesName: res.reviewFileName,
            filesName: res.fileNames,
            rating: res.rating,
          });
        });
      });
    }

    this.setState({ exportFileName: `topicResult-${this.state.date.toISOString()}.csv` });

    this.props.getBlockStatistic({
      topicId: this.props.topicId,
      userId: this.props.user.id,
      planId: this.props.planId,
      onSuccess: response => this.setState({ blocks: response }),
    });
  }

  downloadFile = (uuid, name) => {
    this.props.getFile(uuid, res => {
      const objectURL = URL.createObjectURL(res);
      const tempLink = document.createElement('a');
      tempLink.style = 'display: none';
      tempLink.href = objectURL;
      tempLink.download = name;
      document.body.appendChild(tempLink);
      tempLink.click();
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(objectURL);
      }, 1000);
    });
  };

  types = () => ({
    NOT_STARTED: this.props.t('notComplete'),
    FINISHED: this.props.t('complete'),
  });

  templateBlob = blob => {
    const objectURL = URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style = 'display: none';
    tempLink.href = objectURL;
    tempLink.download = this.state.exportFileName;
    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(objectURL);
    }, 1000);
  };

  handleExportResult = () => {
    this.props.getTopicResultExport(this.props.user.id, this.props.topicId, this.templateBlob);
  };

  handleExportResultExecutive = () => {
    this.props.exportExecutiveTopic(this.state.exportFileName, this.props.topicId, this.templateBlob);
  };

  // для рендера вложенных объектов
  printInnerKeys = (targetObject, currentKey = '') => {
    const t = this.props.t;
    const keys = Object.keys(targetObject);

    if (keys.length === 0) {
      return (
        <div key={currentKey} className={css['scorm-data-component__item']}>
          <b>{t(currentKey)}</b>
          {equalsElement}
          <span className={css['scorm-data-component__res']}>{''}</span>
        </div>
      );
    }

    return keys.map(e => {
      const resultByKey = targetObject[e];
      const isResultObject = typeof resultByKey === 'object';

      if (isResultObject) {
        return this.printInnerKeys(resultByKey, e);
      }

      return (
        <div key={currentKey + e} className={css['scorm-data-component__item']}>
          <b>{t(`${currentKey ? currentKey + '.' : ''}${e}`)}</b>
          {equalsElement}
          <span className={css['scorm-data-component__res']}>{resultByKey}</span>
        </div>
      );
    });
  };

  render() {
    const { t, topicTypeId, onCancel, user } = this.props;

    if (!this.state.blocks) {
      return null;
    }

    const { countFinishedBlocks, countBlocks } = this.state.blocks;

    const windowTitle = isExecutiveTask(topicTypeId) ? `${t('resultsExecutive')}` : `${t('results')}`;

    const footerButtons = (
      <>
        <Button onClick={isExecutiveTask(topicTypeId) ? this.handleExportResultExecutive : this.handleExportResult}>
          {t('download')}
        </Button>
        <Button type='primary' onClick={onCancel}>
          OK
        </Button>
      </>
    );

    const columns = [
      {
        title: t('block'),
        dataIndex: 'block',
        key: 'block',
      },
      {
        title: isExecutiveTask(topicTypeId) ? t('statusExecutive') : t('status'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: t('startDate'),
        dataIndex: 'startDate',
        key: 'startDate',
      },
      {
        title: t('endDate'),
        dataIndex: 'endDate',
        key: 'endDate',
      },
      {
        title: t('result'),
        dataIndex: 'result',
        key: 'result',
      },
      {
        title: t('outcome'),
        dataIndex: 'outcome',
        key: 'outcome',
      },
    ];

    return (
      <Modal title={windowTitle} open onCancel={onCancel} width={1000} footer={footerButtons}>
        <Row>
          <Col span={14}>
            <div className={css['Add-modal-headerName']}>{`${user.lastName} ${user.firstName}`}</div>
          </Col>
        </Row>
        <Row>
          <Col className={css['Add-modal-margin']} span={18}>
            <span className={css['Add-modal-headerTest']}>
              {t('blocks')} {countFinishedBlocks}/{countBlocks}
            </span>
          </Col>
        </Row>
        <DataTestInfo
          data={this.state.blocks}
          t={t}
          topicTypeId={topicTypeId}
          scroll={{ x: 1000, y: 800 }}
          columns={columns}
          printInnerKeys={this.printInnerKeys}
        />

        {isExecutiveTask(topicTypeId) && (
          <div style={{ marginTop: '40px' }}>
            <div className={css['Add-modal-headerTest']}>{t('attachments')}</div>
            <Collapse accordion>
              <Panel header={t('executiveComments')} key='1'>
                {this.state.review && this.state.review.length > 0 && this.state.review}
              </Panel>
              <Panel header={t('executiveAttachedEmployee')} key='2'>
                <ul>
                  {this.state.files &&
                    Array.isArray(this.state.files) &&
                    this.state.filesName.map((name, index) => {
                      if (name) {
                        return (
                          <li key={index}>
                            <a onClick={() => this.downloadFile(this.state.files[index], name)}>{name}</a>
                          </li>
                        );
                      }
                    })}
                </ul>
              </Panel>

              {this.state.filesReview && this.state.reviewFilesName && (
                <Panel header={t('executiveAttachedChecking')} key='3'>
                  <ul>
                    <li>
                      <a onClick={() => this.downloadFile(this.state.filesReview, this.state.reviewFilesName)}>
                        {this.state.reviewFilesName}
                      </a>
                    </li>
                  </ul>
                </Panel>
              )}
            </Collapse>
          </div>
        )}
        {isExecutiveTask(topicTypeId) && (
          <div className={css['Rate']}>
            <span>{t('rate')}</span>
            <Rate disabled value={this.state.rating} />
          </div>
        )}
      </Modal>
    );
  }
}

TestsInfoModal.propTypes = {
  onCancel: PropTypes.func,
  getBlockStatistic: PropTypes.func,
  user: PropTypes.object,
  topicId: PropTypes.number,
  topicTypeId: PropTypes.number,
  companyId: PropTypes.number,
  getTopicResultExport: PropTypes.func,
  exportExecutiveTopic: PropTypes.func,
};

const mapActionsToProps = {
  getTopicResultExport,
  getExecutiveTopicTask,
  checkStudyPlan,
  getFile,
  exportExecutiveTopic,
};

export default connect(null, mapActionsToProps)(withTranslation('testsInfoModal')(TestsInfoModal));
