export const GET_MASSFILTERS_START = 'GET_MASSFILTERS_START';
export const GET_MASSFILTERS_SUCCESS = 'GET_MASSFILTERS_SUCCESS';
export const GET_MASSFILTERS_FAIL = 'GET_MASSFILTERS_FAIL';

export const DELETE_MASSFILTER_START = 'DELETE_MASSFILTER_START';
export const DELETE_MASSFILTER_SUCCESS = 'DELETE_MASSFILTER_SUCCESS';
export const DELETE_MASSFILTER_FAIL = 'DELETE_MASSFILTER_FAIL';

export const ENABLE_MASSFILTER_START = 'ENABLE_MASSFILTER_START';
export const ENABLE_MASSFILTER_SUCCESS = 'ENABLE_MASSFILTER_SUCCESS';
export const ENABLE_MASSFILTER_FAIL = 'ENABLE_MASSFILTER_FAIL';

export const DISABLE_MASSFILTER_START = 'DISABLE_MASSFILTER_START';
export const DISABLE_MASSFILTER_SUCCESS = 'DISABLE_MASSFILTER_SUCCESS';
export const DISABLE_MASSFILTER_FAIL = 'DISABLE_MASSFILTER_FAIL';
