export const TopicTypes = {
  Course: 1,
  Text: 2,
  Audio: 3,
  Video: 4,
  Application: 5,
  Image: 6,
  Presentation: 7,
  Event: 8,
  Article: 15,
  Book: 16,
  CorporateCourse: 17,
  ExternalCourse: 20,
  Conference: 23,
  ExecutiveTask: 25,
  CorporateCourseWithoutApprove: 35,
  Skills: 38,
};
