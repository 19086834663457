/* eslint-disable */
import {
  ADD_COMPILATIONTOPLAN_FAIL,
  ADD_COMPILATIONTOPLAN_START,
  ADD_COMPILATIONTOPLAN_SUCCESS,
  ADD_MASS_COMPILATIONTOPLAN_FAIL,
  ADD_MASS_COMPILATIONTOPLAN_START,
  ADD_MASS_COMPILATIONTOPLAN_SUCCESS,
  CHANGE_VISIBILITY_COMPILATION_FAIL,
  CHANGE_VISIBILITY_COMPILATION_START,
  CHANGE_VISIBILITY_COMPILATION_SUCCESS,
  CLEAR_COMPILATION,
  CLEAR_PAGE,
  DELETE_CAREER_FAIL,
  DELETE_CAREER_START,
  DELETE_CAREER_SUCCESS,
  DELETE_COMPILATION_FAIL,
  DELETE_COMPILATION_START,
  DELETE_COMPILATION_SUCCESS,
  DELETE_THEME_FAIL,
  DELETE_THEME_START,
  DELETE_THEME_SUCCESS,
  GET_COMPILATION_FAIL,
  GET_COMPILATION_START,
  GET_COMPILATION_SUCCESS,
  GET_COMPILATIONS_COMMENTS_FAIL,
  GET_COMPILATIONS_COMMENTS_START,
  GET_COMPILATIONS_COMMENTS_SUCCESS,
  GET_COMPILATIONS_FAIL,
  GET_COMPILATIONS_START,
  GET_COMPILATIONS_SUCCESS,
  GET_THEMES_FAIL,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
  POST_COMPILATION_FAIL,
  POST_COMPILATION_START,
  POST_COMPILATION_SUCCESS,
  POST_THEME_FAIL,
  POST_THEME_START,
  POST_THEME_SUCCESS,
  REORDER_THEMES,
  REORDER_THEMES_REQUEST_FAIL,
  REORDER_THEMES_REQUEST_START,
  REORDER_THEMES_REQUEST_SUCCESS,
  UPDATE_CAREER_FAIL,
  UPDATE_CAREER_START,
  UPDATE_CAREER_SUCCESS,
  UPDATE_COMPILATION_FAIL,
  UPDATE_COMPILATION_START,
  UPDATE_COMPILATION_SUCCESS,
  UPDATE_THEME_FAIL,
  UPDATE_THEME_START,
  UPDATE_THEME_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const createCompilation = (compilationVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/topic/compilation`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: compilationVM,
    types: [POST_COMPILATION_START, POST_COMPILATION_SUCCESS, POST_COMPILATION_FAIL],
    onSuccess,
  };
};

export const updateCompilation = (id, compilationVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/topic/compilation/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: compilationVM,
    types: [UPDATE_COMPILATION_START, UPDATE_COMPILATION_SUCCESS, UPDATE_COMPILATION_FAIL],
    onSuccess,
  };
};

export const changeCompilationVisibility = (id, isVisible, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/topic/compilation/${id}/visible${getQueryString({
      isVisible,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: {
      id,
      isVisible: isVisible,
    },
    types: [
      CHANGE_VISIBILITY_COMPILATION_START,
      CHANGE_VISIBILITY_COMPILATION_SUCCESS,
      CHANGE_VISIBILITY_COMPILATION_FAIL,
    ],
    onSuccess,
  };
};

export const deleteCompilation = (id, onSuccess) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_URL}/topic/compilation/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [DELETE_COMPILATION_START, DELETE_COMPILATION_SUCCESS, DELETE_COMPILATION_FAIL],
    onSuccess,
  };
};

export const getCompilations = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/compilation${getQueryString({
      page: 0,
      size: 10,
      ...params,
      sort: 'createdDate,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPILATIONS_START, GET_COMPILATIONS_SUCCESS, GET_COMPILATIONS_FAIL],
    onSuccess,
  };
};

export const getCompilationComments = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/compilation/comment${getQueryString({
      page: 0,
      size: 10,
      ...params,
      sort: 'createdDate,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPILATIONS_COMMENTS_START, GET_COMPILATIONS_COMMENTS_SUCCESS, GET_COMPILATIONS_COMMENTS_FAIL],
    onSuccess,
  };
};

export const clearCompilation = () => ({ type: CLEAR_COMPILATION });

export const clearPage = () => ({ type: CLEAR_PAGE });

export const getCompilation = (id, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/compilation/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPILATION_START, GET_COMPILATION_SUCCESS, GET_COMPILATION_FAIL],
    onSuccess,
  };
};

export const createTheme = (themeVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/theme`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: themeVM,
    types: [POST_THEME_START, POST_THEME_SUCCESS, POST_THEME_FAIL],
    onSuccess,
  };
};

export const addCompilationCareer = ({ id, careerId }, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/compilation/${encodeURI(id)}/career`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    careerId,
  },
  types: [UPDATE_CAREER_START, UPDATE_CAREER_SUCCESS, UPDATE_CAREER_FAIL],
  onSuccess,
  onError,
});

export const deleteCompilationCareer = ({ id, careerId }, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/topic/compilation/${encodeURI(id)}/career`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    careerId,
  },
  types: [DELETE_CAREER_START, DELETE_CAREER_SUCCESS, DELETE_CAREER_FAIL],
  onSuccess,
  onError,
});

export const updateTheme = (compilationVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/theme/${compilationVM.id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: compilationVM,
    types: [UPDATE_THEME_START, UPDATE_THEME_SUCCESS, UPDATE_THEME_FAIL],
    onSuccess,
  };
};
export const deleteTheme = (id, onSuccess) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_URL}/theme/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    responseType: 'text',
    types: [DELETE_THEME_START, DELETE_THEME_SUCCESS, DELETE_THEME_FAIL],
    onSuccess,
  };
};

export const getThemes = onSuccess => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/theme/edit${getQueryString({})}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_THEMES_START, GET_THEMES_SUCCESS, GET_THEMES_FAIL],
    onSuccess,
  };
};

export const compilationAddToPlanRedux = (compilationId, startDate, endDate, users, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/compilation/addtoplan`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    compilationId,
    startDate,
    endDate,
    users,
  },
  types: [ADD_COMPILATIONTOPLAN_START, ADD_COMPILATIONTOPLAN_SUCCESS, ADD_COMPILATIONTOPLAN_FAIL],
  onSuccess,
  onError,
});

export const compilationAddToPlanMass = (compilationId, filter, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/compilation/addtoplan/mass${getQueryString({
    compilationId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: filter,
  types: [ADD_MASS_COMPILATIONTOPLAN_START, ADD_MASS_COMPILATIONTOPLAN_SUCCESS, ADD_MASS_COMPILATIONTOPLAN_FAIL],
  onSuccess,
  onError,
});

export const reorderTopThemesRequest = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/theme/position`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [REORDER_THEMES_REQUEST_START, REORDER_THEMES_REQUEST_SUCCESS, REORDER_THEMES_REQUEST_FAIL],
  onSuccess,
  onError,
});

export const reorderTopThemes = info => ({ info, type: REORDER_THEMES });
