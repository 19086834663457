/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { LeftOutlined } from '@ant-design/icons';

import LogoCompany from '../../components/LogoCompany';

import classNames from 'classnames';

import css from './Login.module.scss';

const ErrorItem = ({ getPublicFile, length, idx, error: { icon, description, name } }) => {
  const [iconSrc, setIconSrc] = useState('');

  useEffect(() => {
    getPublicFile(icon, blob => {
      const imageUrl = URL.createObjectURL(blob);
      setIconSrc(imageUrl);
    });
  }, [getPublicFile, icon, length, idx]);

  return (
    <div className={css['LoginPage-bodyRow']}>
      <div className={css['LoginPage-bodyRow__icon']}>
        <img src={iconSrc} alt='' />
      </div>
      <div>
        <div className={css['LoginPage-bodyRow__bodyTitle']}>{name}</div>
        <div className={css['LoginPage-bodyRow__bodyText']} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

const ErrorList = ({ errors, handleRedirect, imageBackgroundLogin, imageLogo, hasErrors, ...props }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('login');
  return (
    <>
      <div>
        {/*TODO: css.hide для того, чтобы иконки грузились, если не будет компонента, то и грузиться не будут (кол-во иконок динамическое)*/}
        {/* <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' /> */}
        <div>
          <div className={css['LoginPage-header__failure']}>
            <div className={css['LoginPage-header__logo']}>
              <LogoCompany imageLogo={imageLogo} />
            </div>
          </div>
          <div className={css['LoginPage-titleHead']}>{hasErrors ? t('accFail') : t('fail')}</div>
          <div className={css['LoginPage-titleBody']}>{hasErrors ? t('tooltip') : t('callback')}</div>
          <div className={css['LoginPage-body']}>
            {errors &&
              errors.length > 0 &&
              errors.map((error, idx) => (
                <ErrorItem key={error.id} {...props} error={error} idx={idx} length={errors.length - 1} />
              ))}
          </div>
          <div className={css['LoginPage-label']}>
            <div className={classNames(css.LoginPage__toInfo)} onClick={() => navigate('/user-login')}>
              <LeftOutlined /> {t('goBack')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LoginFailure = () => {
  const props = useOutletContext();

  const [hasErrors, setHasErrors] = useState(true);

  useEffect(() => {
    localStorage.removeItem('externalLogin');
    localStorage.removeItem('isAuthStarted');
  }, []);

  useEffect(() => {
    if (props.errors && props.errors.length === 0) {
      setHasErrors(false);
    }
  }, [props.errors]);

  return (
    <div className={css['App-card-item']}>
      <ErrorList {...props} hasErrors={hasErrors} />
    </div>
  );
};

export default LoginFailure;
