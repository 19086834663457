export const POST_EXECUTIVE_TOPIC_START = 'POST_EXECUTIVE_TOPIC_START';
export const POST_EXECUTIVE_TOPIC_SUCCESS = 'POST_EXECUTIVE_TOPIC_SUCCESS';
export const POST_EXECUTIVE_TOPIC_FAIL = 'POST_EXECUTIVE_TOPIC_FAIL';

export const POST_EXECUTIVE_TOPIC_APPROVE_START = 'POST_EXECUTIVE_TOPIC_APPROVE_START';
export const POST_EXECUTIVE_TOPIC_APPROVE_SUCCESS = 'POST_EXECUTIVE_TOPIC_APPROVE_SUCCESS';
export const POST_EXECUTIVE_TOPIC_APPROVE_FAIL = 'POST_EXECUTIVE_TOPIC_APPROVE_FAIL';

export const POST_EXECUTIVE_TOPIC_COMMENT_START = 'POST_EXECUTIVE_TOPIC_COMMENT_START';
export const POST_EXECUTIVE_TOPIC_COMMENT_SUCCESS = 'POST_EXECUTIVE_TOPIC_COMMENT_SUCCESS';
export const POST_EXECUTIVE_TOPIC_COMMENT_FAIL = 'POST_EXECUTIVE_TOPIC_COMMENT_FAIL';

export const GET_TOPIC_EXECUTIVE_INFO_START = 'GET_TOPIC_EXECUTIVE_INFO_START';
export const GET_TOPIC_EXECUTIVE_INFO_SUCCESS = 'GET_TOPIC_EXECUTIVE_INFO_SUCCESS';
export const GET_TOPIC_EXECUTIVE_INFO_FAIL = 'GET_TOPIC_EXECUTIVE_INFO_FAIL';

export const GET_EXECUTIVE_TOPIC_TASK_START = 'GET_EXECUTIVE_TOPIC_TASK_START';
export const GET_EXECUTIVE_TOPIC_TASK_SUCCESS = 'GET_EXECUTIVE_TOPIC_TASK_SUCCESS';
export const GET_EXECUTIVE_TOPIC_TASK_FAIL = 'GET_EXECUTIVE_TOPIC_TASK_FAIL';

export const PUT_EXECUTIVE_TOPIC_INFO_START = 'PUT_EXECUTIVE_TOPIC_INFO_START';
export const PUT_EXECUTIVE_TOPIC_INFO_SUCCESS = 'PUT_EXECUTIVE_TOPIC_INFO_SUCCESS';
export const PUT_EXECUTIVE_TOPIC_INFO_FAIL = 'PUT_EXECUTIVE_TOPIC_INFO_FAIL';

export const GET_EXPORT_EXECUTIVE_TOPIC_START = 'GET_EXPORT_EXECUTIVE_TOPIC_START';
export const GET_EXPORT_EXECUTIVE_TOPIC_SUCCESS = 'GET_EXPORT_EXECUTIVE_TOPIC_SUCCESS';
export const GET_EXPORT_EXECUTIVE_TOPIC_FAIL = 'GET_EXPORT_EXECUTIVE_TOPIC_FAIL';

export const POST_UPLOAD_EXECUTIVE_REVIEW_FILE_START = 'POST_UPLOAD_EXECUTIVE_REVIEW_FILE_START';
export const POST_UPLOAD_EXECUTIVE_REVIEW_FILE_SUCCESS = 'POST_UPLOAD_EXECUTIVE_REVIEW_FILE_SUCCESS';
export const POST_UPLOAD_EXECUTIVE_REVIEW_FILE_FAIL = 'POST_UPLOAD_EXECUTIVE_REVIEW_FILE_FAIL';
