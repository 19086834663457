// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MassSelection-style-module__ql-picker-v2zrsq.admin-src-components-MassSelection-style-module__ql-font-XfX7K5 .admin-src-components-MassSelection-style-module__ql-picker-label-cG_8_z[data-value="Montserrat"]:before, .admin-src-components-MassSelection-style-module__ql-picker-v2zrsq.admin-src-components-MassSelection-style-module__ql-font-XfX7K5 .admin-src-components-MassSelection-style-module__ql-picker-item-PgieFW[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MassSelection-style-module__ql-font-Montserrat-bZTChR {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MassSelection-style-module__mass-selection-B4Csp9 {
  box-sizing: border-box;
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.admin-src-components-MassSelection-style-module__mass-selection__title-fTTYrA {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

.admin-src-components-MassSelection-style-module__mass-selection__settings-ZU6aEn {
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  display: flex;
}

.admin-src-components-MassSelection-style-module__mass-selection__icon-nKRwU0 {
  font-size: 22px;
}

.admin-src-components-MassSelection-style-module__mass-selection__block-WV3W9Q {
  cursor: pointer;
  gap: 6px;
  display: flex;
}

.admin-src-components-MassSelection-style-module__mass-selection__block-WV3W9Q > span {
  color: var(--personalColor);
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MassSelection/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAMA;EAHE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAKA;EAFE,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,aAAa;AACf;;AAIA;EADE,eAAe;AACjB;;AAGA;EAAE,eAAe;EACf,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.mass-selection {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.mass-selection__title {\n  font-weight: 700;\n  font-size: 28px;\n  line-height: 40px;\n}\n.mass-selection__settings {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 6px;\n}\n.mass-selection__icon {\n  font-size: 22px;\n}\n.mass-selection__block {\n  display: flex;\n  gap: 6px;\n  cursor: pointer;\n}\n.mass-selection__block > span {\n  color: var(--personalColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MassSelection-style-module__ql-picker-v2zrsq`,
	"qlPicker": `admin-src-components-MassSelection-style-module__ql-picker-v2zrsq`,
	"ql-font": `admin-src-components-MassSelection-style-module__ql-font-XfX7K5`,
	"qlFont": `admin-src-components-MassSelection-style-module__ql-font-XfX7K5`,
	"ql-picker-label": `admin-src-components-MassSelection-style-module__ql-picker-label-cG_8_z`,
	"qlPickerLabel": `admin-src-components-MassSelection-style-module__ql-picker-label-cG_8_z`,
	"ql-picker-item": `admin-src-components-MassSelection-style-module__ql-picker-item-PgieFW`,
	"qlPickerItem": `admin-src-components-MassSelection-style-module__ql-picker-item-PgieFW`,
	"ql-font-Montserrat": `admin-src-components-MassSelection-style-module__ql-font-Montserrat-bZTChR`,
	"qlFontMontserrat": `admin-src-components-MassSelection-style-module__ql-font-Montserrat-bZTChR`,
	"mass-selection": `admin-src-components-MassSelection-style-module__mass-selection-B4Csp9`,
	"massSelection": `admin-src-components-MassSelection-style-module__mass-selection-B4Csp9`,
	"mass-selection__title": `admin-src-components-MassSelection-style-module__mass-selection__title-fTTYrA`,
	"massSelectionTitle": `admin-src-components-MassSelection-style-module__mass-selection__title-fTTYrA`,
	"mass-selection__settings": `admin-src-components-MassSelection-style-module__mass-selection__settings-ZU6aEn`,
	"massSelectionSettings": `admin-src-components-MassSelection-style-module__mass-selection__settings-ZU6aEn`,
	"mass-selection__icon": `admin-src-components-MassSelection-style-module__mass-selection__icon-nKRwU0`,
	"massSelectionIcon": `admin-src-components-MassSelection-style-module__mass-selection__icon-nKRwU0`,
	"mass-selection__block": `admin-src-components-MassSelection-style-module__mass-selection__block-WV3W9Q`,
	"massSelectionBlock": `admin-src-components-MassSelection-style-module__mass-selection__block-WV3W9Q`
};
export default ___CSS_LOADER_EXPORT___;
