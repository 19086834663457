// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-Layout-Layout-module__ql-picker-NJSJ76.admin-src-components-Layout-Layout-module__ql-font-frBMRc .admin-src-components-Layout-Layout-module__ql-picker-label-eLCbkL[data-value="Montserrat"]:before, .admin-src-components-Layout-Layout-module__ql-picker-NJSJ76.admin-src-components-Layout-Layout-module__ql-font-frBMRc .admin-src-components-Layout-Layout-module__ql-picker-item-jolh2C[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-Layout-Layout-module__ql-font-Montserrat-zHHBka {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-Layout-Layout-module__Card-sBxsR1 {
  background-color: #fff;
  border-radius: 2px;
  padding: 40px;
  box-shadow: 0 1px 3px #0000000f;
}

.admin-src-components-Layout-Layout-module__Content-dqUPij {
  width: 100%;
}

.admin-src-components-Layout-Layout-module__Content-dqUPij h1, .admin-src-components-Layout-Layout-module__Content-dqUPij h2, .admin-src-components-Layout-Layout-module__Content-dqUPij h3, .admin-src-components-Layout-Layout-module__Content-dqUPij h4 {
  text-align: left;
}

.admin-src-components-Layout-Layout-module__Content-dqUPij h1 {
  margin: 0 0 32px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.admin-src-components-Layout-Layout-module__Content-dqUPij h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.admin-src-components-Layout-Layout-module__Content-dqUPij h3 {
  font-size: 17px;
  font-weight: 800;
  line-height: 22px;
}

.admin-src-components-Layout-Layout-module__Content-dqUPij h4 {
  font-size: 11px;
  font-weight: 800;
  line-height: 13px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,+BAA+B;AACjC;;AAQA;EALE,WAAW;AACb;;AAOA;EAJE,gBAAgB;AAClB;;AASA;EANE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAQA;EALE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAOA;EAJE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAMA;EAHE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.Card {\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);\n  padding: 40px;\n  background-color: #fff;\n  border-radius: 2px;\n}\n\n.Content {\n  width: 100%;\n}\n.Content h1,\n.Content h2,\n.Content h3,\n.Content h4 {\n  text-align: left;\n}\n.Content h1 {\n  margin: 0 0 32px 0;\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n}\n.Content h2 {\n  font-size: 24px;\n  line-height: 29px;\n  font-weight: 500;\n}\n.Content h3 {\n  font-size: 17px;\n  line-height: 22px;\n  font-weight: 800;\n}\n.Content h4 {\n  font-size: 11px;\n  line-height: 13px;\n  font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-Layout-Layout-module__ql-picker-NJSJ76`,
	"qlPicker": `admin-src-components-Layout-Layout-module__ql-picker-NJSJ76`,
	"ql-font": `admin-src-components-Layout-Layout-module__ql-font-frBMRc`,
	"qlFont": `admin-src-components-Layout-Layout-module__ql-font-frBMRc`,
	"ql-picker-label": `admin-src-components-Layout-Layout-module__ql-picker-label-eLCbkL`,
	"qlPickerLabel": `admin-src-components-Layout-Layout-module__ql-picker-label-eLCbkL`,
	"ql-picker-item": `admin-src-components-Layout-Layout-module__ql-picker-item-jolh2C`,
	"qlPickerItem": `admin-src-components-Layout-Layout-module__ql-picker-item-jolh2C`,
	"ql-font-Montserrat": `admin-src-components-Layout-Layout-module__ql-font-Montserrat-zHHBka`,
	"qlFontMontserrat": `admin-src-components-Layout-Layout-module__ql-font-Montserrat-zHHBka`,
	"Card": `admin-src-components-Layout-Layout-module__Card-sBxsR1`,
	"card": `admin-src-components-Layout-Layout-module__Card-sBxsR1`,
	"Content": `admin-src-components-Layout-Layout-module__Content-dqUPij`,
	"content": `admin-src-components-Layout-Layout-module__Content-dqUPij`
};
export default ___CSS_LOADER_EXPORT___;
