/* eslint-disable */

import React, { Component } from 'react';

import PropTypes from 'prop-types';

export class MaterialCost extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    topic: PropTypes.object,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      cost: 0,
      symbol: '',
      costMin: 0,
    };
  }

  componentDidMount() {
    this.getCost();
  }

  getCost() {
    const { topic, currentUser } = this.props;
    let defaultCurrency = {
      id: '',
      code: '',
      name: '',
      nominal: 1,
      value: 1,
    };

    let convertedCost = 0;
    let convertedMinCost = 0;

    // Получаем текущую валюты компании
    if (
      currentUser &&
      currentUser.currencies &&
      Array.isArray(currentUser.currencies) &&
      currentUser.domainCompany &&
      currentUser.domainCompany.currencyId
    ) {
      const { currencies } = currentUser;
      const { currencyId } = currentUser.domainCompany;

      if (typeof currencyId === 'number') {
        currencies.map(currency => {
          if (currency.id === currencyId) {
            defaultCurrency = currency;
          }
        });
      }
    }

    if (topic && topic.cost) {
      if (defaultCurrency.nominal > 0) {
        convertedCost = topic.cost / defaultCurrency.nominal;
      }
    }

    if (topic?.costMin) {
      if (defaultCurrency.nominal > 0) {
        convertedMinCost = topic.costMin / defaultCurrency.nominal;
      }
    }

    // Это глобальный материал, требуется ли конвертация валюты ?
    if (topic && topic.global && topic.global === true) {
      if (defaultCurrency.value > 0) {
        convertedCost = (topic.cost / defaultCurrency.value).toFixed(2);
        convertedMinCost = (topic.costMin / defaultCurrency.value).toFixed(2);
      }
    }

    this.setState({ cost: convertedCost, costMin: convertedMinCost });

    switch (defaultCurrency.code) {
      case 'AUD':
        this.setState({ symbol: '&#36;' });
        break;
      case 'AZN':
        break;
      case 'GBP':
        break;
      case 'AMD':
        break;
      case 'BYN':
        break;
      case 'BGN':
        break;
      case 'BRL':
        break;
      case 'HUF':
        break;
      case 'HKD':
        break;
      case 'DKK':
        break;
      case 'USD':
        this.setState({ symbol: '&#65284;' });
        break;
      case 'EUR':
        this.setState({ symbol: '&#8364;' });
        break;
      case 'INR':
        break;
      case 'KZT':
        break;
      case 'CAD':
        break;
      case 'KGS':
        break;
      case 'CNY':
        break;
      case 'MDL':
        break;
      case 'NOK':
        break;
      case 'PLN':
        break;
      case 'RON':
        break;
      case 'XDR':
        break;
      case 'SGD':
        break;
      case 'TJS':
        break;
      case 'TRY':
        break;
      case 'TMT':
        break;
      case 'UZS':
        break;
      case 'UAH':
        break;
      case 'CZK':
        break;
      case 'SEK':
        break;
      case 'CHF':
        break;
      case 'ZAR':
        break;
      case 'KRW':
        break;
      case 'JPY':
        this.setState({ symbol: '&#65509;' });
        break;
      case 'RUB':
        this.setState({ symbol: '&#8381;' });
        break;
    }
  }

  render() {
    const { cost, symbol, costMin } = this.state;

    return (
      <>
        {this.props.value === 'cost' ? cost.toLocaleString() : costMin.toLocaleString()}
        &nbsp;
        <span dangerouslySetInnerHTML={{ __html: symbol }}></span>
      </>
    );
  }
}
