import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { refreshLogin } from '@/store/login/actions';

const OAUTHSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    const refreshParam = searchParams.get('refresh');
    const registerParam = searchParams.get('register');

    if (refreshParam) {
      localStorage.setItem('refreshToken', `Bearer ${refreshParam}`);
      dispatch(
        refreshLogin(response => {
          localStorage.setItem('authorizationToken', response.accessToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('expiresIn', response.expiresIn);
          localStorage.setItem('expiresTime', response.expiresIn * 1000 + Date.now());

          if (registerParam) {
            navigate('/user-login/registration-page-data');
          } else {
            const currentRedirect = localStorage.getItem('currentRedirect');
            navigate(currentRedirect || '/');
          }
        })
      );
    } else {
      navigate('/user-login/oauth');
    }
  }, []);

  return <></>;
};

export default OAUTHSuccess;
