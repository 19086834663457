import React from 'react';

import { Button, Checkbox, Col, Drawer } from 'antd';
import PanelCustom from '@/components/Panel';

import EventUserList from '../../ManageCourseOld/EventUserList';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TopicDetailsDialog.module.scss';

const DrawerUsers = props => {
  const {
    t,
    withEvents,
    eventName,
    addUserEventHandler,
    showSendMail,
    showEventDetails,
    deleteEventHandler,
    showEventInfoHandler,
    editEventHandler,
    getEventParticipants,
    onlyEvent,
    eventList,
    showEventDetail,
    getFile,
    coursesPageNumber,
    coursesFilter,
    isLoading,
    isEnded,
    filterYear,
    courseRequestFinish,
    deleteUserEventHandler,
    getEventTopic,
    updateCoursesRequest,
    getCoursesRequestTopic,
    open,
    onClose,
    currentUser,
    valueCheckBox,
    changeActiveCheckbox,
    isConfOrAllCourse,
  } = props;

  const correctEventList = Array.isArray(eventList)
    ? eventList.map(event => {
        const datesLength = event?.periods?.[0]?.dates?.length || 0;

        return {
          ...event,
          startDate: event?.periods?.[0]?.dates?.[0] || '',
          endDate: event?.periods?.[0]?.dates?.[datesLength - 1] || '',
        };
      })
    : [];

  const eventRow =
    correctEventList &&
    correctEventList.map((event, index) => {
      const showUsers = showEventDetail === event.id;
      return (
        <PanelCustom
          key={event.id}
          index={event.id + index}
          name={event.name}
          startDate={event.startDate}
          event={event}
          count={event.participantsCount}
          hasUsers={event.requests && event.requests.length !== 0}
          approvedCount={event.approvedCount}
          registerCount={event.registerCount}
          sendMail={showSendMail}
          showEventDetails={showEventDetails}
          deleteEventHandler={deleteEventHandler}
          showEventInfoHandler={showEventInfoHandler}
          editEventHandler={editEventHandler}
          requests={event.requests}
          getEventParticipants={getEventParticipants}
          flagShowDetail={showUsers}
          onlyEvent={onlyEvent}
          currentUser={currentUser}
          isConfOrAllCourse={isConfOrAllCourse}
        >
          {showUsers ? (
            <EventUserList
              key={`userlist-${event.id}`}
              getFile={getFile}
              coursesPageNumber={coursesPageNumber}
              coursesFilter={coursesFilter}
              index={event.id}
              isLoading={isLoading}
              isEnded={isEnded}
              filterYear={filterYear}
              courseRequestFinish={courseRequestFinish}
              requests={event.requests}
              deleteUserEventHandler={deleteUserEventHandler}
              getEventTopic={getEventTopic}
              updateCoursesRequest={updateCoursesRequest}
              getCoursesRequestTopic={getCoursesRequestTopic}
            />
          ) : null}
        </PanelCustom>
      );
    });

  return (
    <Drawer
      className={css['DetailsModalPay-drawer']}
      title={t('drawerUsers')}
      placement='left'
      open={open}
      onClose={onClose}
      width={450}
    >
      <div className={css['DetailsModalPay-col-payCourse-filter']}>
        <Checkbox
          className={css['DetailsModalPay-col-payCourse-filter']}
          name='isEnded'
          checked={valueCheckBox}
          onChange={event => changeActiveCheckbox(event.target.checked)}
        >
          {t('onlyActive')}
        </Checkbox>
      </div>
      {withEvents && (
        <Col span={8} className={css['DetailsModal-col']}>
          <div className={css['DetailsModalPay-col-card']}>
            <div className={css['DetailsModalPay-col-payCourse']}>
              <div
                className={classNames(css['DetailsModalPay-col-payCourse'], css['DetailsModalPay-col-payCourseEvent'])}
              >
                {t('eventSelected')}
                <span
                  className={classNames(css['DetailsModalPay-col-currencyEvent'], css['DetailsModalPay-col-currency'])}
                >
                  <span>{eventName}</span>
                </span>
              </div>
              <Button onClick={addUserEventHandler}>{t('addToEvent')}</Button>
            </div>
          </div>
          {eventRow && eventRow.length === 0 && (
            <div className={css['DetailsModalPay-col-payCourse-noEvents']}>{t('noNewEvents')}</div>
          )}
          <div className={css['DetailsModalPay-col-panelCustom']}>{eventRow}</div>
        </Col>
      )}
    </Drawer>
  );
};

DrawerUsers.propTypes = {
  getCoursesRequestTopic: PropTypes.func,
  sendMail: PropTypes.func,
  showEventDetails: PropTypes.func,
  deleteEventHandler: PropTypes.func,
  showEventInfoHandler: PropTypes.func,
  editEventHandler: PropTypes.func,
  getEventParticipants: PropTypes.func,
  getFile: PropTypes.func,
  coursesPageNumber: PropTypes.number,
  coursesFilter: PropTypes.object,
  isLoading: PropTypes.bool,
  isEnded: PropTypes.any,
  courseRequestFinish: PropTypes.func,
  deleteUserEventHandler: PropTypes.func,
  getEventTopic: PropTypes.func,
  updateCoursesRequest: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  showEventDetail: PropTypes.number,
  valueCheckBox: PropTypes.bool,
};

export default DrawerUsers;
