/* eslint-disable */
import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { getCompanyUsersLimit } from '@/store/company_users_limit/actions';
import { getFile } from '@/store/files/actions';
import { getImportUsers } from '@/store/importUsers/actions';
import { actualLogin } from '@/store/login/actions';
import {
  blockUser,
  clearManagerFullname,
  clearStudentsCreatUser,
  exportUser,
  getDepartment,
  getFunction,
  getLdapStatus,
  getPosition,
  getStudents,
  getStudentsDeleted,
  getStudentsList,
  setManagerFullname,
  toggleAllUsersTrajectory,
  toggleUserIndividualTrajectory,
  toggleUserLocked,
} from '@/store/students/actions';
import { changeEmailNotification, getDiagnosticResultsCSV, importUsers, loginRedirect } from '@/store/users/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectCompanyUsersLimit } from '@/store/company_users_limit/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectStudents as studentsSelect } from '@/store/students/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Col, Form, Input, Modal, Pagination, Row, Spin } from 'antd';
import Checkbox from '@/components/Checkbox';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import { Card, Content } from '@/components/Layout';
import SelectCustom from '@/components/Select';

import CoinProfile from './CoinProfile';
import CreateUser from './CreateUserDialog';
import { IPDDropdown } from './IPDDropdown';
import Profile from './Profile';
import StudentProfileDialog from './StudentProfileDialog';
import StudentRow from './StudentRow';
import { isLDAPLogin } from './utils';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './Users.module.scss';

import {
  COMPANY_USERS_COUNT_READ,
  CONVENTUS_RESULTS_EXPORT,
  LDAP_CONFIGURE,
  USER_CREATE_USING_FORM,
  USER_EXPORT,
  USER_SIGN_UP_LOAD,
} from '@/constants/permissions';

const DELETED_USERS = 'DELETED';
const ACTIVE_USER = 'ACTIVE';
const COUNT_ZERO = 0;

export class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualLoginStatus: false,
      filter: {
        managerId: (props.currentUser?.haveEmployees && props.currentUser?.id) || undefined,
      },
      coinProfileVisible: false,
      selectedUsers: [],
      allSelectUsers: false,
      importVisible: false,
      subCompanies: Utils.isGPMValid(this.props.currentUser),
      newCompanyOption: {},
      deletedUsers: false,
      allUsers: false,
      isLoading: true,
      dynamicHeightTable: 'auto',
      tableDivRef: React.createRef(),
      resizeObserver: null,
      createModalVisible: false,
    };

    this.getStudentsWithLoader = (params, options) => {
      this.setState({ isLoading: true });
      const changeLoadingState = () => {
        this.setState({ isLoading: false });
      };
      if (options.isGetDeleteStudents) {
        props.getStudentsDeleted(...params).finally(changeLoadingState);
      } else {
        props.getStudents(...params).finally(changeLoadingState);
      }
    };

    this.debounceGetStudentsWithLoader = _.debounce(this.getStudentsWithLoader, 500);
    this.getStudentsListDebounced = _.debounce(props.getStudentsList, 1000);
  }

  setManagerData = () => {
    if (this.props.currentUser?.haveEmployees && this.props.currentUser?.id) {
      const nameManager = this.props.currentUser.lastName + ' ' + this.props.currentUser.firstName;
      this.props.setManagerFullname([{ id: this.props.currentUser.id, name: nameManager }]);
      this.handleChange({ name: 'managerId', value: this.props.currentUser.id });
    }
  };

  componentDidMount() {
    const {
      actualLogin,
      getLdapStatus,
      getFunction,
      getDepartment,
      getPosition,
      getSubcompanies,
      adminCompanyInfo,
      domainCompany,
      currentUser,
      getCompanyUsersLimit,
      students,
    } = this.props;

    this.setManagerData();
    getCompanyUsersLimit(domainCompany.id);

    const resizeObserver = new ResizeObserver(() => {
      if (students.length > 0) {
        this.setState({ dynamicHeightTable: this.state.tableDivRef.current.clientHeight });
      } else {
        this.setState({ dynamicHeightTable: 'auto' });
      }
    });

    resizeObserver.observe(this.state.tableDivRef.current);

    this.setState({ resizeObserver: resizeObserver });

    const createNewCopmanyOption = () => {
      let array = [];
      this.state.subCompanies.map((company, index) => {
        array.push({ id: index + 1, companies: company });
      });
      this.setState({ newCompanyOption: array });
    };

    createNewCopmanyOption();

    actualLogin(() => {
      this.setState({ actualLoginStatus: true });
      getLdapStatus();
      getFunction();
      getDepartment();
      getPosition();
      getSubcompanies({}, response => {
        const comp = response.some(item => item === currentUser.company);
        if (this.isCompanyDefaultValueForAdmin(comp, adminCompanyInfo, currentUser)) {
          this.handleChange({ name: 'company', value: currentUser.company });
        }
        this.setState({
          subCompanies: Utils.isGPMValid(this.props.currentUser, response, this.props.adminCompanyInfo.hasSubcompanies),
        });
      });

      // if (!this.props.currentUser?.haveEmployees) { ??? нужно переписать вызов первоначальный
      this.getStudentsWithLoader(
        [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
        { isGetDeleteStudents: false }
      );
      // }
    });
  }

  componentWillUnmount() {
    this.state.resizeObserver.disconnect();
    this.debounceGetStudentsWithLoader.cancel();
    this.getStudentsListDebounced.cancel();
  }

  isCompanyDefaultValueForAdmin = (company, { hasSubcompanies }, currentUser) =>
    company && hasSubcompanies && Utils.isAdmin(currentUser);

  isCanSelectOtherCompany = (isSuperAdmin, hasSubcompanies) => isSuperAdmin || !hasSubcompanies;

  updateData = () => {
    this.props.getFunction();
    this.props.getDepartment();
    this.props.getPosition();
    this.props.getSubcompanies();
    if (this.state.deletedUsers) {
      this.getStudentsWithLoader([DELETED_USERS, 0, this.state.filter], { isGetDeleteStudents: true });
    } else {
      this.getStudentsWithLoader(
        [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, this.state.page, false],
        { isGetDeleteStudents: false }
      );
    }

    setTimeout(() => this.props.getCompanyUsersLimit(this.props.domainCompany.id), 1200);
  };

  handleChange = event => {
    const data = event.hasOwnProperty('target') ? event.target : event;
    const { name, value } = data;
    if (name === 'company') {
      this.setState({ filter: { ...this.state.filter, [name]: value } }, () => {
        this.getStudentsWithLoader([{ ...this.state.filter }, this.state.page, false], {
          isGetDeleteStudents: false,
        });
      });
    } else {
      if (this.state.deletedUsers) {
        this.setState({ filter: { ...this.state.filter, [name]: value } }, () => {
          this.getStudentsWithLoader([DELETED_USERS, 0, this.state.filter], { isGetDeleteStudents: true });
        });
      } else {
        this.setState(
          prevState => ({ filter: { ...prevState.filter, [name]: value } }),
          () => {
            if (data.type == 'text') {
              this.debounceGetStudentsWithLoader(
                [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
                { isGetDeleteStudents: false }
              );
            } else {
              this.getStudentsWithLoader(
                [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
                { isGetDeleteStudents: false }
              );
            }
          }
        );
      }
    }
  };

  changePage = page => {
    this.setState({ page: page - 1, selectedUsers: [], allSelectUsers: false });
    this.getStudentsWithLoader(
      [
        {
          login: this.state.filter?.login,
          fullName: this.state.filter?.fullName,
          ...this.state.filter,
          subCompanies: this.state.subCompanies,
          status: ACTIVE_USER,
        },
        page - 1,
        false,
      ],
      { isGetDeleteStudents: false }
    );
  };

  changePageDeleted = page => {
    this.setState({ page: page - 1, selectedUsers: [], allSelectUsers: false });
    this.getStudentsWithLoader([DELETED_USERS, page - 1, this.state.filter], { isGetDeleteStudents: true });
  };

  showStudentProfile = student => this.setState({ studentProfileVisible: true, activeStudent: student });

  hideStudentProfile = () => this.setState({ studentProfileVisible: false, activeStudent: {} });

  showCoinProfile = student => this.setState({ coinProfileVisible: true, activeStudent: student });

  hideCoinProfile = () => {
    this.setState({ coinProfileVisible: false, activeStudent: {} });
    this.getStudentsWithLoader([{ ...this.state.filter, subCompanies: this.state.subCompanies }, 0, false], {
      isGetDeleteStudents: false,
    });
  };

  handleChangeFilterType = event => {
    const { name, value } = event.target;
    if (name === 'locked') {
      return this.setState({ filter: { ...this.state.filter, locked: !this.state.filter.locked } }, () => {
        this.getStudentsWithLoader([{ ...this.state.filter, subCompanies: this.state.subCompanies }, 0, false], {
          isGetDeleteStudents: false,
        });
      });
    }
    return this.setState({ filter: { ...this.state.filter, [name]: value ? !value : '' } }, () => {
      this.getStudentsWithLoader([{ ...this.state.filter, subCompanies: this.state.subCompanies }, 0, false], {
        isGetDeleteStudents: false,
      });
    });
  };

  clearCoursesFilter = name => {
    this.setState(
      () => {
        if (typeof name === 'string') return { filter: { ...this.state.filter, [name]: undefined } };
        return { filter: {} };
      },
      () => {
        if (this.state.deletedUsers) {
          this.getStudentsWithLoader([DELETED_USERS, 0, this.state.filter], { isGetDeleteStudents: true });
        } else {
          this.getStudentsWithLoader(
            [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
            { isGetDeleteStudents: false }
          );
        }
      }
    );
  };

  selectUser = (e, newId) => {
    if (!e.value) {
      return this.setState({
        allSelectUsers: false,
        selectedUsers: this.state.selectedUsers.filter(idUser => idUser !== newId),
      });
    }
    return this.setState({
      allSelectUsers: this.state.selectedUsers.length + 1 === this.props.students.length,
      selectedUsers: [...this.state.selectedUsers, newId],
      allUsers: false,
    });
  };

  selectAllUser = e => {
    if (!e.value) {
      return this.setState({
        selectedUsers: [],
        allSelectUsers: false,
      });
    }

    this.setState({
      selectedUsers: this.state.deletedUsers
        ? this.props.deletedStudents.map(student => student.id)
        : this.props.students.map(student => student.id),
      allSelectUsers: true,
      allUsers: false,
    });
  };

  applyAll = e => {
    if (e.value) {
      this.setState({
        allUsers: e.value,
        allSelectUsers: e.value,
        selectedUsers: this.props.students.map(student => student.id),
      });
    } else {
      this.setState({ allUsers: e.value, selectedUsers: [], allSelectUsers: e.value });
    }
  };

  funcFilterOption = (inputValue, option) => {
    return option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  };

  toggleUserIndividualTrajectory = user => {
    this.props.toggleUserIndividualTrajectory({
      trajectoryStatus: !user.trajectoryEnable,
      userIds: [user.id],
    });
  };

  toggleUsersIndividualTrajectory = trajectoryStatus => {
    const userIds = [0];
    if (this.state.allUsers) {
      const filter = Object.keys(this.state.filter).reduce((acc, key) => {
        const keyData = this.state.filter[key];
        if (!keyData) {
          delete this.state.filter[key];
          return acc;
        }
        acc[key] = keyData;
        return acc;
      }, {});
      this.props.toggleAllUsersTrajectory(trajectoryStatus, userIds, filter, () => {
        this.setState({ allUsers: false, selectedUsers: [], allSelectUsers: false });
        this.getStudentsWithLoader(
          [{ ...filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
          { isGetDeleteStudents: false }
        );
      });
    } else {
      this.props.toggleUserIndividualTrajectory(
        {
          trajectoryStatus,
          userIds: this.state.selectedUsers,
        },
        () => {
          this.setState({ selectedUsers: [], allSelectUsers: false });
        }
      );
    }
  };

  onShowUsers = status => {
    if (status === DELETED_USERS) {
      this.setState({ deletedUsers: DELETED_USERS === status });
      this.getStudentsWithLoader([DELETED_USERS, 0, this.state.filter], { isGetDeleteStudents: true });
    } else {
      this.setState({ deletedUsers: ACTIVE_USER === status });
      this.getStudentsWithLoader(
        [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
        { isGetDeleteStudents: false }
      );
    }
    this.props.getCompanyUsersLimit(this.props.domainCompany.id);
  };

  getUsersCompanyInfo() {
    const { t } = this.props;
    return (
      <div
        style={{
          fontSize: '12px',
          textAlign: 'right',
          backgroundColor: '#2d3540',
          padding: '4px 8px',
          fontFamily: 'Helvetica, Arial, Verdana, Tahoma, sans-serif',
        }}
      >
        <strong style={{ color: '#F2F56C' }}>{t('total')}</strong>&nbsp;
        <span style={{ color: 'white' }}>{this.props.usersTotal || 0},</span>&nbsp;
        <strong style={{ color: '#a9d9a9' }}>{t('available')}</strong>&nbsp;
        <span style={{ color: 'white' }}>{this.props.usersAvailable || 0},</span>
        &nbsp;
        <span onClick={this.onShowUsers}>
          <strong style={{ color: '#7fc9f5' }} className={css.spanLink}>
            {t('active')}
          </strong>
          &nbsp;
          <span style={{ color: 'white' }}>{this.props.usersActive || 0},</span>
        </span>
        &nbsp;
        <span onClick={() => this.onShowUsers(DELETED_USERS)}>
          <strong style={{ color: '#ef98bb' }} className={css.spanLink}>
            {t('blocked')}
          </strong>
        </span>
        &nbsp;
        <span style={{ color: 'white' }}>{this.props.usersDelete || 0}</span>
      </div>
    );
  }

  handleExportUser = () => {
    const date = new Date();
    const fileName = `exportUser-${date.toISOString()}.csv`;
    this.props.exportUser(this.state.subCompanies, blob => {
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.download = fileName;
      link.href = url;
      link.click();
    });
  };

  searchManagers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && this.getStudentsListDebounced({ isManager: true, fullName, size: 100 });
    }
  };

  render() {
    const {
      adminCompanyInfo,
      students,
      companies,
      studentsPageSize,
      studentsTotal,
      studentsPageNumber,
      roles,
      fullNameManager,
      functionCourse,
      domainCompany,
      directory,
      position,
      currentUser,
      t,
      deletedStudentsPageSize,
      deletedStudentsTotal,
      deletedStudentsPageNumber,
    } = this.props;

    const rolesLDAP = roles.includes(LDAP_CONFIGURE);
    const showITOD = domainCompany?.hasTrajectory;
    const studentRowRender = student => (
      <StudentRow
        key={`student-${student.id}`}
        dataqa='adminUsersUserItem'
        student={student}
        selectedUsers={this.state.selectedUsers}
        toggleUserTrajectory={this.toggleUserIndividualTrajectory}
        selectUser={this.selectUser}
        rolesLDAP={rolesLDAP}
        showITOD={showITOD}
        showProfile={this.showStudentProfile}
        showCoin={this.showCoinProfile}
        getFile={this.props.getFile}
        domainCompany={this.props.domainCompany}
        toggleUserLocked={this.props.toggleUserLocked}
        changeUser={this.props.changeUser}
        blockUser={this.props.blockUser}
        getStudents={this.updateData}
        currentUser={this.props.currentUser}
        loginRedirect={this.props.loginRedirect}
      />
    );
    const studentRows = this.state.actualLoginStatus && students.map(studentRowRender);
    const studentRowsDeleted = this.props.deletedStudents.map(studentRowRender);
    const pagination = (
      <Pagination
        current={studentsPageNumber + 1}
        total={studentsTotal}
        pageSize={studentsPageSize}
        onChange={this.changePage}
        showSizeChanger={false}
        responsive
        hideOnSinglePage
        className={css['Pagination']}
      />
    );

    const paginationDeleted = (
      <Pagination
        current={deletedStudentsPageNumber + 1}
        total={deletedStudentsTotal}
        pageSize={deletedStudentsPageSize}
        onChange={this.changePageDeleted}
        responsive
        hideOnSinglePage
        className={css['Pagination']}
      />
    );

    const ldapLogin = isLDAPLogin(this.props);

    const exportDiagnostics = () => {
      this.props.getDiagnosticResultsCSV(
        {
          filename: 'DiagnosticResult',
        },
        blob => {
          let url = window.URL.createObjectURL(blob);
          let anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'DiagnosticResult';
          anchor.click();
        }
      );
    };

    const desrtoyModal = () => {
      Modal.destroyAll();
    };

    const warningModal = action => {
      const onOk = () => {
        action();
        desrtoyModal();
      };
      Modal.confirm({
        title: t('warning'),
        content: t('warningContent'),
        footer: () => {
          return (
            <>
              <Button className={css['Students-modal-btn']} onClick={desrtoyModal}>
                {t('cancel')}
              </Button>
              <Button className={css['Students-modal-btn']} type='primary' onClick={onOk}>
                {t('ok')}
              </Button>
            </>
          );
        },
      });
    };

    return (
      <Content>
        <div>
          <h1 data-qa='usersH1'>{t('users')}</h1>
        </div>
        <div className={css['Students-header']}>
          {currentUser.authorities.includes(USER_CREATE_USING_FORM) && (
            <Button
              className={css['Students-header-button']}
              onClick={() => this.setState({ createModalVisible: true })}
              type='primary'
              data-qa='createUserBtn'
            >
              {t('createUser')}
            </Button>
          )}
          {showITOD &&
            domainCompany.diagnosticType.type === 'CONVENTUS' &&
            this.props.currentUser.authorities.includes(CONVENTUS_RESULTS_EXPORT) && (
              <Button
                disabled={!Boolean(this.props.usersTotal)}
                className={css['Students-header-button']}
                onClick={() => warningModal(exportDiagnostics)}
                type='primary'
                loading={this.props.isLoadingResult}
              >
                {t('export')}
              </Button>
            )}
          {this.props.currentUser.authorities.includes(USER_SIGN_UP_LOAD) && (
            <Button
              className={css['Students-header-button']}
              onClick={() => this.setState({ importVisible: true })}
              type='primary'
            >
              {t('import')}
            </Button>
          )}
          {this.props.currentUser.authorities.includes(USER_EXPORT) && (
            <Button
              disabled={!Boolean(this.props.usersTotal)}
              className={css['Students-header-button']}
              onClick={() => warningModal(this.handleExportUser)}
              type='primary'
              loading={this.props.isLoadingUsers}
            >
              {t('exportUser')}
            </Button>
          )}
        </div>
        <div className={css['Students-form-container']}>
          <div className={classNames(css['Students-form-container'], css.row)}>
            <Form
              className={classNames(css['Students-form'], css['Students-form-padding'])}
              layout='vertical'
              style={{ padding: '16px' }}
            >
              <div className={css['Students-header-title']}>
                <div className={css['Students-header-title-text']}>{t('filter')}</div>
                <div>
                  <Button type='link' onClick={this.clearCoursesFilter} className={css['Students-header-title-reset']}>
                    {t('clear')}
                  </Button>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <Row gutter={24} type='flex' align='bottom'>
                  <Col span={6}>
                    <Form.Item label={t('fullName')} colon={false} className={css['Students-form-search']}>
                      <Input
                        dataqa='adminUsersFullNameSearchInput'
                        placeholder={t('fullName')}
                        name='fullName'
                        allowClear
                        value={this.state.filter.fullName}
                        onChange={this.handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={t('login')} colon={false} className={css['Students-form-search']}>
                      <Input
                        placeholder={t('login')}
                        dataqa='adminUsersLoginSearchInput'
                        name='login'
                        allowClear
                        value={this.state.filter.login}
                        onChange={this.handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={t('email')} colon={false} className={css['Students-form-search']}>
                      <Input
                        placeholder={t('email')}
                        dataqa='adminUsersEmailSearchInput'
                        name='email'
                        allowClear
                        value={this.state.filter.email}
                        onChange={this.handleChange}
                      />
                    </Form.Item>
                  </Col>
                  {domainCompany?.hasManager && (
                    <Col span={6}>
                      <Form.Item label={t('managerName')} colon={false} className={css['Students-form-search']}>
                        <SelectCustom
                          showSearch
                          long
                          dataqa='adminUsersManagerSearchInput'
                          name='managerId'
                          topicTypeId
                          allowClear
                          filterOption={this.funcFilterOption}
                          onChange={this.handleChange}
                          value={this.state.filter.managerId}
                          options={fullNameManager}
                          onSearch={this.searchManagers}
                          loading={this.props.isLoadingFullnameManager}
                          white
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={24} type='flex' align='top'>
                  {domainCompany?.hasAdditionalInfo && (
                    <>
                      <Col span={6}>
                        <Form.Item label={t('company')} colon={false}>
                          <SelectCustom
                            showSearch
                            long
                            dataqa='adminUsersCompanySearchInput'
                            name='company'
                            allowClear
                            filterOption={this.funcFilterOption}
                            onChange={this.handleChange}
                            value={this.state.filter.company}
                            options={this.state.newCompanyOption.length !== 0 ? this.state.newCompanyOption : companies}
                            disabled={
                              !this.isCanSelectOtherCompany(
                                Utils.isSuperAdmin(currentUser),
                                adminCompanyInfo?.hasSubcompanies
                              )
                            }
                            white
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label={t('position')} colon={false}>
                          <SelectCustom
                            showSearch
                            filterOption={this.funcFilterOption}
                            onChange={this.handleChange}
                            value={this.state.filter.position}
                            dataqa='adminUsersPositionSearchInput'
                            name='position'
                            allowClear
                            options={position}
                            long
                            white
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label={t('department')} colon={false}>
                          <SelectCustom
                            showSearch
                            filterOption={this.funcFilterOption}
                            onChange={this.handleChange}
                            value={this.state.filter.department}
                            dataqa='adminUsersDepartmentSearchInput'
                            name='department'
                            allowClear
                            options={directory}
                            long
                            white
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label={t('function')} colon={false}>
                          <SelectCustom
                            showSearch
                            filterOption={this.funcFilterOption}
                            onChange={this.handleChange}
                            value={this.state.filter.function}
                            name='function'
                            allowClear
                            dataqa='adminUsersFunctionSearchInput'
                            options={functionCourse}
                            long
                            white
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  {domainCompany?.hasAdditionalInfo && ldapLogin && rolesLDAP && (
                    <Col span={8}>
                      <Form.Item colon={false}>
                        <Checkbox
                          style={{ width: '100%' }}
                          className={css['Topics-form-filter-courseType-item']}
                          name='locked'
                          value={this.state.filter.locked}
                          onClick={e => this.handleChangeFilterType(e)}
                          label='Только запрещенные для обновления'
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>
            </Form>
          </div>
        </div>
        {this.props.currentUser.authorities.includes(COMPANY_USERS_COUNT_READ) && this.getUsersCompanyInfo()}
        <Card>
          {!this.state.isLoading && showITOD && (
            <div className={classNames(css['Students-form'], css['Students-form-flex'])}>
              <span className={css['Students-form-text']}>
                {t('selected')}
                {!this.state.allUsers ? <span>{this.state.selectedUsers.length}</span> : <span>{COUNT_ZERO}</span>}
              </span>
              {!this.state.deletedUsers && (
                <>
                  <span className={css['Students-form-apply']}>
                    {t('applyAll')}
                    {this.state.allUsers ? (
                      <span className={css['Students-form-students']}>{studentsTotal}</span>
                    ) : (
                      <span className={css['Students-form-students']}>{COUNT_ZERO}</span>
                    )}
                  </span>
                  <span className={css['Students-form-check']}>
                    <Checkbox name='allUsers' value={this.state.allUsers} onChange={e => this.applyAll(e)} />
                  </span>
                </>
              )}
              <IPDDropdown
                toggleUsersIndividualTrajectory={this.toggleUsersIndividualTrajectory}
                disabled={this.state.selectedUsers.length === 0 && !this.state.allUsers}
              />
            </div>
          )}
          <AnimateHeight
            duration={500}
            contentClassName={css['Students-transition']}
            contentRef={this.state.tableDivRef}
            height={this.state.dynamicHeightTable}
          >
            {this.state.isLoading ? (
              <div className={css['Students-spin']}>
                <Spin />
              </div>
            ) : (this.state.deletedUsers ? studentRowsDeleted.length > 0 : studentRows.length > 0) ? (
              <>
                <div className={css['Students-table']}>
                  <div className={css['Students-table-head']}>
                    {showITOD && (
                      <div className={css['Students-table-head-check']}>
                        <Checkbox
                          name='selectedUsers'
                          value={this.state.allSelectUsers}
                          onChange={e => this.selectAllUser(e)}
                        />
                      </div>
                    )}
                    {ldapLogin && <div className={css['Students-table-head-ldap']} />}
                    <div className={css['Students-table-head-avatar']} />
                    <div className={css['Students-table-head-fields']}>{t('fullName')}</div>
                    <div className={css['Students-table-head-fields']}>{t('login')}</div>
                    {this.props.domainCompany?.hasManager && (
                      <div className={css['Students-table-head-fields']}>{t('manager')}</div>
                    )}
                    {this.props.domainCompany?.hasAdditionalInfo && (
                      <>
                        <div className={css['Students-table-head-fields']}>{t('position')}</div>
                        <div className={css['Students-table-head-fields']}>{t('department')}</div>
                        <div className={css['Students-table-head-fields']}>{t('function')}</div>
                      </>
                    )}
                    <div className={css['Students-table-head-action']} />
                  </div>

                  <div data-qa='adminUsersTableBody' className={css['Students-table-body']}>
                    {this.state.deletedUsers ? studentRowsDeleted : studentRows}
                  </div>
                </div>
                {this.state.deletedUsers ? paginationDeleted : pagination}
              </>
            ) : (
              <div className={css['Students-noFound']}>
                <p dangerouslySetInnerHTML={{ __html: t('description') }} />
              </div>
            )}
          </AnimateHeight>
        </Card>
        {this.state.studentProfileVisible && (
          <>
            <StudentProfileDialog
              open={false}
              onCancel={this.hideStudentProfile}
              getStudents={this.updateData}
              student={
                this.state.deletedUsers
                  ? this.props.deletedStudents.find(student => student.id === this.state.activeStudent.id)
                  : this.props.students.find(student => student.id === this.state.activeStudent.id)
              }
            />

            <Profile
              t={this.props.t}
              open={true}
              onCancel={this.hideStudentProfile}
              getStudents={this.updateData}
              student={
                this.state.deletedUsers
                  ? this.props.deletedStudents.find(student => student.id === this.state.activeStudent.id)
                  : this.props.students.find(student => student.id === this.state.activeStudent.id)
              }
              domainCompany={domainCompany}
            />
          </>
        )}
        {this.state.coinProfileVisible && (
          <CoinProfile open onCancel={this.hideCoinProfile} student={this.state.activeStudent} />
        )}
        {this.state.importVisible && (
          <DictionaryImportDialog
            onOk={this.props.importUsers}
            onCancel={() => this.setState({ importVisible: false })}
            fileDescription={t('download')}
            getData={() =>
              this.getStudentsWithLoader(
                [{ ...this.state.filter, subCompanies: this.state.subCompanies, status: ACTIVE_USER }, 0, false],
                { isGetDeleteStudents: false }
              )
            }
            users={true}
            description1={t('description1')}
            exampleFileFunc={this.props.getImportUsers}
            open={this.state.importVisible}
          />
        )}
        {this.state.createModalVisible && (
          <CreateUser
            open={this.state.createModalVisible}
            clearStudent={this.props.clearStudentsCreatUser}
            filter={this.state.filter}
            onClose={() => this.setState({ createModalVisible: false })}
          />
        )}
      </Content>
    );
  }
}

Users.propTypes = {
  actualLogin: PropTypes.func,
  importUsers: PropTypes.func,
  getFunctions: PropTypes.func,
  domainCompany: PropTypes.object,
  getFile: PropTypes.func,
  getLdapStatus: PropTypes.func,
  getStudents: PropTypes.func,
  clearStudentsCreatUser: PropTypes.func,
  students: PropTypes.array,
  studentsPageNumber: PropTypes.number,
  studentsPageSize: PropTypes.number,
  studentsTotal: PropTypes.number,
  toggleUserLocked: PropTypes.func,
  blockUser: PropTypes.func,
  changeUser: PropTypes.func,
  userLockedStatus: PropTypes.object,
  roles: PropTypes.array,
  department: PropTypes.array,
  directory: PropTypes.array,
  fullNameManager: PropTypes.array,
  functionCourse: PropTypes.array,
  currentUser: PropTypes.object,
  getDiagnosticResultsCSV: PropTypes.func,
  exportUser: PropTypes.func,
  usersLimit: PropTypes.array,
  getCompanyUsersLimit: PropTypes.func,
  loginRedirect: PropTypes.func,
  getImportUsers: PropTypes.func,
  setManagerFullname: PropTypes.func,
  clearManagerFullname: PropTypes.func,
  isLoadingFullnameManager: PropTypes.bool,
  isLoadingUsers: PropTypes.bool,
  isLoadingResult: PropTypes.bool,
};

const mapStateToProps = createSelector(
  [studentsSelect, usersSelect, selectCompanies, loginSelect, selectCompanyUsersLimit],
  (students, users, companies, loginState, usersLimit) => ({
    currentUser: users.currentUser,
    roles: users.currentUser && users.currentUser.authorities,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    students: students.students || [],
    studentsTotal: students.studentsTotal,
    userLockedStatus: students.userLockedStatus,
    studentsPageSize: students.studentsPageSize,
    studentsPageNumber: students.studentsPageNumber || 0,
    fullNameManager: students.fullnameManager,
    department: students.department,
    functionCourse:
      students.functionStudent &&
      students.functionStudent.map((func, item) => {
        return { id: item + 1, function: func };
      }),
    directory:
      students.department &&
      students.department.map((depart, item) => {
        return { id: item + 1, department: depart };
      }),
    position:
      students.position &&
      students.position.map((position, item) => {
        return { id: item + 1, name: position };
      }),
    companies:
      companies.subCompanies &&
      companies.subCompanies.map((subCompany, item) => {
        return { id: item + 1, companies: subCompany.trim() };
      }),
    companiesIsLoading: companies.isLoading,
    adminCompanyInfo: loginState.company,
    deletedStudents: students.deletedStudents,
    deletedStudentsTotal: students.deletedStudentsTotal,
    deletedStudentsPageSize: students.deletedStudentsPageSize,
    deletedStudentsPageNumber: students.deletedStudentsPageNumber,
    usersTotal: usersLimit.total,
    usersAvailable: usersLimit.available,
    usersActive: usersLimit.active,
    usersDelete: usersLimit.deleted,
    isLoadingFullnameManager: students.isLoadingFullnameManager,
    isLoadingUsers: students.isLoadingUsers,
    isLoadingResult: users.isLoadingResult,
  })
);

const mapActionsToProps = {
  getStudents,
  clearStudentsCreatUser,
  getFile,
  toggleUserLocked,
  toggleUserIndividualTrajectory,
  getLdapStatus,
  actualLogin,
  getDepartment,
  getStudentsList,
  getFunction,
  changeUser: changeEmailNotification,
  getDiagnosticResultsCSV,
  blockUser,
  getPosition,
  importUsers,
  getSubcompanies,
  getStudentsDeleted,
  toggleAllUsersTrajectory,
  exportUser,
  getCompanyUsersLimit,
  loginRedirect,
  getImportUsers,
  setManagerFullname,
  clearManagerFullname,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('users')(Users));
