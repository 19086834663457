export const IMPORT_QUESTION_START = 'IMPORT_QUESTION_START';
export const IMPORT_QUESTION_SUCCESS = 'IMPORT_QUESTION_SUCCESS';
export const IMPORT_QUESTION_FAIL = 'IMPORT_QUESTION_FAIL';

export const ADD_QUESTION_START = 'ADD_QUESTION_START';
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS';
export const ADD_QUESTION_FAIL = 'ADD_QUESTION_FAIL';

export const GET_QUESTIONS_LIST_START = 'GET_QUESTIONS_LIST_START';
export const GET_QUESTIONS_LIST_SUCCESS = 'GET_QUESTIONS_LIST_SUCCESS';
export const GET_QUESTIONS_LIST_FAIL = 'GET_QUESTIONS_LIST_FAIL';

export const UPDATE_QUESTION_START = 'UPDATE_QUESTION_START';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL';

export const DELETE_QUESTION_START = 'DELETE_QUESTION_START';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

export const GET_BANK_CATEGORIES_START = 'GET_BANK_CATEGORIES_START';
export const GET_BANK_CATEGORIES_SUCCESS = 'GET_BANK_CATEGORIES_SUCCESS';
export const GET_BANK_CATEGORIES_FAIL = 'GET_BANK_CATEGORIES_FAIL';

export const GET_EXPORT_QUESTION_CSV_START = 'GET_EXPORT_QUESTION_CSV_START';
export const GET_EXPORT_QUESTION_CSV_SUCCESS = 'GET_EXPORT_QUESTION_CSV_SUCCESS';
export const GET_EXPORT_QUESTION_CSV_FAIL = 'GET_EXPORT_QUESTION_CSV_FAIL';

export const GET_RANDOMIZER_INFO_START = 'GET_RANDOMIZER_INFO_START';
export const GET_RANDOMIZER_INFO_SUCCESS = 'GET_RANDOMIZER_INFO_SUCCESS';
export const GET_RANDOMIZER_INFO_FAIL = 'GET_RANDOMIZER_INFO_FAIL';

export const GET_COUNT_QUESTIONS_START = 'GET_COUNT_QUESTIONS_START';
export const GET_COUNT_QUESTIONS_SUCCESS = 'GET_COUNT_QUESTIONS_SUCCESS';
export const GET_COUNT_QUESTIONS_FAIL = 'GET_COUNT_QUESTIONS_FAIL';

export const SET_COUNT_QUESTIONS = 'SET_COUNT_QUESTIONS';

export const ADD_BANK_CATEGORIES = 'ADD_BANK_CATEGORIES';

export const SET_IS_SORT = 'SET_IS_SORT';

export const SET_ACTIVE_SORT = 'SET_ACTIVE_SORT';

export const SET_DEFAULT_SORT = 'SET_DEFAULT_SORT';

export const SAVE_RANDOMIZER_INFO = 'SAVE_RANDOMIZER_INFO';
