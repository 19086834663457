import React, { useEffect, useState } from 'react';

import { getFileMemo } from '@shared/utils/file-utils';

import Resources from '@/resources';
import PropTypes from 'prop-types';

/* eslint-disable */
export const CoinsIcon = ({ coinsIcon, className } = {}) => {
  const [file, setFile] = useState();

  useEffect(() => {
    coinsIcon && getFileMemo(coinsIcon).then(setFile);
  }, [coinsIcon]);

  return file ? (
    <img src={file} alt='' className={className} />
  ) : (
    <Resources.icons.DefaultCoins className={className} />
  );
};

CoinsIcon.propTypes = {
  coinsIcon: PropTypes.string,
  className: PropTypes.string,
};
