/* eslint-disable */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form, Input, Pagination } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { Card, Content } from '@/components/Layout';

import TagCreationDialog from './TagCreationDialog';
import TagItem from './TagItem';

import debounce from '@shared/utils/debounce';

import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { TAG_CREATE } from '@/constants/permissions';

export class TagsTable extends Component {
  static propTypes = {
    createTag: PropTypes.func,
    creationError: PropTypes.bool,
    deleteTag: PropTypes.func,
    editTag: PropTypes.func,
    editingError: PropTypes.bool,
    getTags: PropTypes.func,
    isLoading: PropTypes.bool,
    tags: PropTypes.array,
    tagsPageNumber: PropTypes.number,
    tagsPageSize: PropTypes.number,
    tagsTotal: PropTypes.number,
    isGlobal: PropTypes.bool,
    shared: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchName: '',
      page: 1,
    };
    this.getTagsDebounced = debounce(props.getTags, 1000);
    props.getTags('', 0, null, props.isGlobal);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchString !== this.state.searchString) {
      this.getTagsDebounced(
        this.state.searchString,
        0,
        () => {
          this.setState({ searchName: this.state.searchString });
        },
        this.props.isGlobal
      );
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changePage = page => {
    this.setState({ page });
    this.props.getTags(this.state.searchString, page - 1, null, this.props.isGlobal);
  };

  showDeleteConfirmation = tag => this.setState({ deleteConfirmationVisible: true, activeTag: tag });

  hideDeleteConfirmation = () => this.setState({ deleteConfirmationVisible: false });

  handleTagDeletion = () =>
    this.props.deleteTag(this.state.activeTag && this.state.activeTag.id, {
      onSuccess: () => {
        this.hideDeleteConfirmation();
        this.props.getTags(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
      },
      onError: this.hideDeleteConfirmation,
    });

  showTagCreationDialog = () => this.setState({ tagCreationVisible: true });

  hideTagCreationDialog = () => this.setState({ tagCreationVisible: false });

  handleTagCreation = tag => {
    this.props.createTag(tag, () => {
      this.hideTagCreationDialog();
      this.props.getTags(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
    });
  };

  showTagEditingDialog = tag => this.setState({ tagEditingVisible: true, activeTag: tag });

  hideTagEditingDialog = () => this.setState({ tagEditingVisible: false, activeTag: null });

  handleTagEditing = name => {
    this.props.editTag(name, this.state.activeTag && this.state.activeTag.id, () => {
      this.hideTagEditingDialog();
      this.props.getTags(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
    });
  };

  render() {
    const { tags, tagsPageSize, tagsTotal, tagsPageNumber, creationError, editingError, shared, t, currentUser } =
      this.props;
    const label = shared ? t('titleGlobal') : t('title');
    const tagItems = tags.map(tag => (
      <TagItem
        key={tag.id}
        tag={tag}
        onEdit={this.showTagEditingDialog}
        onDelete={this.showDeleteConfirmation}
        shared={shared}
        currentUser={currentUser}
        t={t}
      />
    ));
    const pagination = (
      <Pagination
        current={tagsPageNumber + 1}
        total={tagsTotal}
        pageSize={tagsPageSize}
        onChange={this.changePage}
        className={css['pagination']}
        showSizeChanger={false}
        responsive={true}
        hideOnSinglePage={true}
      />
    );

    return (
      <Content className={css.TagDirectory}>
        <h1>{label}</h1>
        <div className={css['TagItem-container']}>
          <Form className={css['TagItem-form']}>
            <Form.Item
              label={t('search')}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              className={css['TagItem-form-search']}
            >
              <Input
                placeholder={t('name')}
                dataqa='searchTagInput'
                name='searchString'
                value={this.state.searchString}
                onChange={this.handleChange}
              />
            </Form.Item>
          </Form>
          {!shared && currentUser.authorities.includes(TAG_CREATE) && (
            <Button
              type='primary'
              className={css['TagItem-button']}
              onClick={this.showTagCreationDialog}
              id='AddNewTagName'
            >
              {t('addName')}
            </Button>
          )}
        </div>
        <Card data-qa='tagDirectory'>
          {tagItems && tagItems.length ? (
            tagItems
          ) : !this.props.isLoading && this.state.searchString ? (
            <div className={css['TagItem-noFound']}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('findError', { searchName: this.state.searchName }),
                }}
              />
            </div>
          ) : null}
          {pagination}
        </Card>
        {this.state.tagCreationVisible && !shared ? (
          <TagCreationDialog
            visible
            onOk={this.handleTagCreation}
            onCancel={this.hideTagCreationDialog}
            title={t('addName')}
            descriptionText={t('newOne')}
            okText={t('add')}
            error={creationError}
            errorText={t('exist')}
          />
        ) : null}
        {this.state.tagEditingVisible && !shared ? (
          <TagCreationDialog
            open
            onOk={this.handleTagEditing}
            onCancel={this.hideTagEditingDialog}
            title={t('editName')}
            descriptionText={t('newOne')}
            okText={t('edit')}
            error={editingError}
            errorText={t('exist')}
            tag={this.state.activeTag && this.state.activeTag.name}
            require={t('require')}
          />
        ) : null}
        <ConfirmationModal
          open={this.state.deleteConfirmationVisible}
          onOk={this.handleTagDeletion}
          onCancel={this.hideDeleteConfirmation}
          title={t('deleteName')}
          okText={t('delete')}
          confirmationText={t('confirm')}
        />
      </Content>
    );
  }
}

export default withTranslation('tagsTable')(TagsTable);
