/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import { getChapters } from '@/store/chapters/actions';
import { createFileImage, getFile, getFileSizes } from '@/store/files/actions';
import {
  createArticle,
  createBlock,
  createPresentation,
  createScorm,
  createVideo,
  deleteBlock,
  deletePresentation,
  deleteScorm,
  editBlock,
  editBlockContent,
  getBlocks,
  getBlockTypes,
  getResultStatistic,
  updateArticle,
  uploadPresentation,
  uploadScorm,
  uploadVideo,
} from '@/store/lms/actions';
import { editTopicSequence } from '@/store/topics/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectBlocks } from '@/store/lms/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Checkbox, Modal, Row } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';

import AddBlockDialog from './AddBlockDialog';
import AddFromBankModal from './AddFromBankModal';
import AddToBankModal from './AddToBankModal';
import { Article } from './article/Article';
import ChecklistAssesor from './ChecklistAssesor';
import ExistingMaterial from './ExistingMaterial/ExistingMaterial';
import PokaFiles from './PokaFiles';
import { Presentation } from './presentation/Presentation';
import { Scorm } from './scorm/Scorm';
import { Test } from './test/Test/Test';
import Tilda from './Tilda/Tilda';
import Typeform from './typeform/Typeform';
import { Video } from './video/Video';

import { isExecutiveTask } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _has from 'lodash/has';

import css from './Blocks.module.scss';

import {
  TOPIC_BLOCK_CREATE,
  TOPIC_BLOCK_EXECUTIVE_TASK_CREATE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_CHAPTER_UPDATE,
} from '@/constants/permissions';

const BlocksComp = ({
  blockTypes,
  getBlockTypes,
  getBlocks,
  createBlock,
  editBlock,
  editBlockContent,
  deleteBlock,
  getFile,
  createFileImage,
  files,
  topicId,
  blocks,
  currentUser,
  getChapters,
  createScorm,
  uploadScorm,
  deleteScorm,
  createVideo,
  uploadVideo,
  topic,
  editTopicSequence,
  createArticle,
  updateArticle,
  createPresentation,
  uploadPresentation,
  deletePresentation,
  getFileSizes,
}) => {
  const { t } = useTranslation('lms');
  const dispatch = useDispatch();
  const [addBlockDialog, setAddBlockDialog] = useState(false);
  const [deleteBlockDialog, setDeleteBlockDialog] = useState(false);
  const [editedBlock, setEditedBlock] = useState(null);
  const [forceCloseAll, setForceCloseAll] = useState(false);
  const [removed, setRemove] = useState(false);
  const [bankModal, setBankModal] = useState({
    visible: false,
    questionId: null,
    blockId: null,
  });
  const [fromBankModal, setFromBankModal] = useState({
    visible: false,
    questions: [],
    testId: null,
  });
  const changeBlockVisibility = useCallback(() => {
    if (editedBlock) {
      dispatch(getResultStatistic({ topicId: topicId }));
    }
    setEditedBlock(null);
    setAddBlockDialog(!addBlockDialog);
  }, [addBlockDialog, editedBlock]);

  const changeDeleteDialogVisibility = useCallback(() => {
    setEditedBlock(null);
    setDeleteBlockDialog(!deleteBlockDialog);
  }, [deleteBlockDialog]);

  useEffect(() => {
    getBlockTypes();
    getBlocks([topicId]);
  }, [getBlockTypes, getBlocks]);

  useEffect(() => {
    getFileSizes();
  }, []);

  const editBlockCallback = useCallback(() => {
    changeBlockVisibility();
    getChapters(topicId);
  }, [topicId, getChapters, changeBlockVisibility]);

  const onBlockChange = useCallback(
    data => {
      data.id
        ? editBlock(data, editBlockCallback)
        : createBlock({ ...data, position: blocks.length }, editBlockCallback);
    },
    [createBlock, editBlock, addBlockDialog]
  );

  const editBlockHandler = useCallback(
    id => {
      const block = blocks.find(data => data.id === id);
      setEditedBlock(block);
      setAddBlockDialog(true);
    },
    [setEditedBlock, setAddBlockDialog, blocks, addBlockDialog, editedBlock]
  );

  const deleteBlockHandler = useCallback(
    id => {
      setEditedBlock(id);
      setDeleteBlockDialog(true);
    },
    [setEditedBlock, setDeleteBlockDialog]
  );

  const delBlock = useCallback(
    id =>
      deleteBlock(id ? id : editedBlock, () => {
        getBlocks([topicId]);
        if (!id) {
          changeDeleteDialogVisibility();
        }
        getChapters(topicId);
      }),
    [deleteBlock, editedBlock, getChapters, topicId]
  );

  const showSaveModal = useCallback(
    res => {
      if (res.id) {
        res?.funcSave();
      } else {
        showDeleteEmptyBlock(res);
      }
    },
    [delBlock]
  );

  const showDeleteEmptyBlock = res => {
    Modal.confirm({
      title: t('titlePrevention'),
      content: t('prevention'),
      async onOk() {
        await res?.funcOk?.();
        delBlock(res.delId);
      },
      onCancel() {
        res?.funcCancel?.();
      },
      okText: t('yes'),
      cancelText: t('no'),
    });
  };

  const domainId = useMemo(() => _get(currentUser, 'domainCompany.id'), [currentUser]);

  blocks.sort((firstItem, secondItem) => firstItem.position - secondItem.position);

  const handleAddToBank = (questionId, blockId) => {
    setBankModal({
      visible: true,
      questionId,
      blockId,
    });
  };

  const handleAddFromBank = (questions, testId) => {
    const questionIds = questions.map(i => i.id);
    setFromBankModal({
      visible: true,
      questions: questionIds,
      testId,
    });
  };

  const checkRoleForEdit = () => {
    return (
      (isExecutiveTask(topic?.type?.id) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE)) ||
      currentUser.authorities.includes(TOPIC_CHAPTER_UPDATE)
    );
  };

  const blocksPanels = useMemo(
    () =>
      blocks.map((data, index) => {
        const otherCompany = domainId !== data.companyId;
        switch (data.type.id) {
          case 1:
            return (
              <Test
                data={data}
                key={data.id}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                saveBlock={editBlockContent}
                hideBorder={index === blocks.length - 1}
                getFile={getFile}
                createFileImage={createFileImage}
                files={files}
                forceClose={forceCloseAll}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
                handleAddToBank={questionId => handleAddToBank(questionId, data.id)}
                handleAddFromBank={handleAddFromBank}
              />
            );
          case 2:
            return (
              <Scorm
                key={data.id}
                data={data}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                showSaveModal={showSaveModal}
                forceClose={forceCloseAll}
                createScorm={createScorm}
                uploadScorm={uploadScorm}
                deleteScorm={deleteScorm}
                getBlocks={getBlocks}
                topicId={topicId}
                getChapters={getChapters}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
              />
            );
          case 3:
            return (
              <Video
                key={data.id}
                data={data}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                showSaveModal={showSaveModal}
                forceClose={forceCloseAll}
                createVideo={createVideo}
                uploadVideo={uploadVideo}
                getFile={getFile}
                createFileImage={createFileImage}
                files={files}
                getChapters={getChapters}
                topicId={topicId}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
              />
            );
          case 4:
            return (
              <Presentation
                key={data.id}
                data={data}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                showSaveModal={showSaveModal}
                forceClose={forceCloseAll}
                createPresentation={createPresentation}
                uploadPresentation={uploadPresentation}
                deletePresentation={deletePresentation}
                getBlocks={getBlocks}
                topicId={topicId}
                getChapters={getChapters}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
              />
            );
          case 5:
            return (
              <Article
                key={data.id}
                data={data}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                forceClose={forceCloseAll}
                createArticle={createArticle}
                updateArticle={updateArticle}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
                getChapters={getChapters}
              />
            );
          case 6:
            return (
              <Typeform
                key={data.id}
                data={data}
                deleteBlock={deleteBlockHandler}
                editBlock={editBlockHandler}
                forceClose={forceCloseAll}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                // canEdit={!(otherCompany || false)}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
                blocks={blocks}
              />
            );
          case 7:
            return (
              <Tilda
                key={data.id}
                block={data}
                editBlock={editBlockHandler}
                forceClose={forceCloseAll}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
                removed={removed}
                topicId={topicId}
              />
            );
          case 8:
            return (
              <ExistingMaterial
                key={data.id}
                data={data}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                forceClose={forceCloseAll}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                currentUser={currentUser}
                topicTypeId={topic?.type?.id}
              />
            );
          case 9:
            return (
              <ChecklistAssesor
                key={data.id}
                data={data}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                forceClose={forceCloseAll}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                topicId={topic?.id}
              />
            );
          case 10:
            return (
              <PokaFiles
                key={data.id}
                data={data}
                canEdit={!(otherCompany || !checkRoleForEdit())}
                forceClose={forceCloseAll}
                editBlock={editBlockHandler}
                deleteBlock={deleteBlockHandler}
                topicId={topic?.id}
                deleteEmptyBlock={showDeleteEmptyBlock}
              />
            );
          default:
            return <></>;
        }
      }),
    [blocks, forceCloseAll, domainId]
  );

  const changeConsistent = useCallback(() => {
    editTopicSequence(topic);
  }, [topic]);

  return (
    <>
      <Row gutter={1} className={css.Blocks}>
        {/* Модальное окно добавления вопроса в банк */}
        <AddToBankModal data={bankModal} setData={setBankModal} />
        {/* Модальное окно добавления вопросов из банка */}
        <AddFromBankModal data={fromBankModal} setData={setFromBankModal} />
        {(currentUser.authorities.includes(TOPIC_BLOCK_CREATE) ||
          (isExecutiveTask(topic.type.id) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_CREATE))) && (
          <>
            <div className={css['Blocks-consistent']}>
              <Checkbox
                data-qa='followSequenceCheckBox'
                checked={_has(topic, 'followSequence') && topic.followSequence}
                onChange={changeConsistent}
              >
                {t('consistent')}
              </Checkbox>
            </div>
            <Button type='link' onClick={changeBlockVisibility} data-qa='blockPlanCreateBtn'>
              {t('addBlock')}
            </Button>
          </>
        )}
        {!!blocks.length && (
          <Button
            data-qa='linkActionBtn'
            type='link'
            className={css['Blocks-closeAll']}
            onClick={() => {
              setForceCloseAll(!forceCloseAll);
            }}
          >
            {t('hide')}
          </Button>
        )}
        {addBlockDialog && (
          <AddBlockDialog
            blockTypes={blockTypes}
            topicId={topicId}
            block={editedBlock}
            onClose={changeBlockVisibility}
            onCreate={onBlockChange}
            topicTypeId={topic.type.id}
          />
        )}
        <ConfirmationModal
          open={deleteBlockDialog}
          onOk={() => delBlock()}
          onCancel={changeDeleteDialogVisibility}
          title={t('deleteBlock')}
          okText={t('delete')}
          confirmationText={t('description')}
          deleteBtn
        />
      </Row>
      <Row className={css['Blocks-collapsibleContainer']}>{blocksPanels}</Row>
    </>
  );
};

const mapStateToProps = createSelector(
  [selectBlocks, selectFiles, selectUsers, selectTopics],
  ({ types: { blockTypes }, blocks }, files, users, { topic }) => ({
    blocks,
    blockTypes,
    files,
    currentUser: users.currentUser || '',
    topic,
  })
);

const mapActionsToProps = {
  getBlockTypes,
  createBlock,
  editBlock,
  editBlockContent,
  deleteBlock,
  getBlocks,
  createFileImage,
  getFile,
  getChapters,
  createScorm,
  uploadScorm,
  deleteScorm,
  createVideo,
  uploadVideo,
  createArticle,
  updateArticle,
  editTopicSequence,
  createPresentation,
  uploadPresentation,
  deletePresentation,
  getFileSizes,
};

export const Blocks = connect(mapStateToProps, mapActionsToProps)(BlocksComp);
