/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createFileImage } from '@/store/files/actions';

import { Button, Col, DatePicker, Input, Modal, Row } from 'antd';
import { GrVmware } from 'react-icons/gr';

import { DATE_FORMAT } from '../../../constants';
import ProjectCover from './ProjectCover';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const ProjectEdit = props => {
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [uuid, setUUID] = useState(null);
  const [start, setStart] = useState(null);
  const [finish, setFinish] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { t } = useTranslation('D360_ProjectEdit');
  const dispatch = useDispatch();
  const { isLoadingImage } = useSelector(state => state.files);

  useEffect(() => {
    setId(null);
    setName(null);
    setDescription(null);
    setUUID(null);
    setStart(dayjs());
    setFinish(dayjs());
    setDisableSubmit(true);

    if (props.project && typeof props.project === 'object') {
      setId(props.project.id);
      setName(props.project.name);
      setDescription(props.project.description);
      setUUID(props.project.file_uuid);
      setStart(dayjs(props.project.start));
      setFinish(dayjs(props.project.finish));
    }
  }, [props.project]);

  useEffect(() => {
    if (
      name != null &&
      name.length > 0 &&
      description != null &&
      description.length > 0 &&
      dayjs(start).format(DATE_FORMAT) &&
      dayjs(finish).format(DATE_FORMAT)
    ) {
      return setDisableSubmit(false);
    }

    setDisableSubmit(true);
  }, [id, name, description, start, finish]);

  const cancel = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const submit = () => {
    if (typeof props.onSubmit === 'function') {
      props.onSubmit({
        id: id,
        name: name,
        description: description,
        file_uuid: uuid,
        start: start.format(DATE_FORMAT).toString(),
        finish: finish.format(DATE_FORMAT).toString(),
      });
    }
  };

  const Title = () => {
    if (props.project && typeof props.project === 'object' && !_.isEmpty(props.project)) {
      return (
        <>
          <GrVmware />
          &nbsp;{t('windowTitle')}
        </>
      );
    } else {
      return (
        <>
          <GrVmware />
          &nbsp;{t('newProject')}
        </>
      );
    }
  };

  const Footer = () => {
    return (
      <>
        <Button key='cancel' onClick={cancel}>
          {t('cancelButton')}
        </Button>
        <Button key='submit' type='primary' onClick={submit} disabled={disableSubmit} loading={isLoadingImage}>
          {props.project && typeof props.project === 'object' && !_.isEmpty(props.project)
            ? t('applyButton')
            : t('saveButton')}
        </Button>
      </>
    );
  };

  const openFile = file => {
    dispatch(
      createFileImage(file, success => {
        if (success.uuid) {
          setUUID(success.uuid);
        }
      })
    );
  };

  return (
    <Modal width={600} open={props.visible} title={<Title />} footer={<Footer />} centered={true} onCancel={cancel}>
      <Row className={css.Notes}>
        <Col span={24}>
          <GrVmware size='3rem' className={css.NotesIcon} />
          <p className={css.NotesText}>{t('windowDescription')}</p>
        </Col>
      </Row>

      <Row className={css.WindowRow}>
        <Col span={6}>*&nbsp;{t('projectName')}</Col>
        <Col span={18}>
          <Input
            value={name}
            placeholder={t('projectNamePH')}
            onChange={e => setName(e.target.value)}
            minLength={3}
            maxLength={255}
          />
        </Col>
      </Row>
      <Row className={css.WindowRow}>
        <Col span={6}>*&nbsp;{t('projectDescription')}</Col>
        <Col span={18}>
          <TextArea
            value={description}
            rows={5}
            placeholder={t('projectDescriptionPH')}
            onChange={e => setDescription(e.target.value)}
          />
        </Col>
      </Row>

      <Row className={css.WindowRow}>
        <Col span={6}>{t('projectCover')}</Col>
        <Col span={18}>
          <ProjectCover onSubmit={openFile} />
        </Col>
      </Row>

      <Row className={css.WindowRow}>
        <Col span={6}>*&nbsp;{t('projectPeriod')}</Col>
        <Col span={18}>
          <RangePicker
            style={{ width: '100%' }}
            value={[start, finish]}
            onChange={range => {
              setStart(range[0]);
              setFinish(range[1]);
            }}
            className={css['RangePicker']}
          />
        </Col>
      </Row>
    </Modal>
  );
};

ProjectEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  project: PropTypes.any.isRequired,
};

export default ProjectEdit;
