import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFields } from '@/store/pcfields/selectors';

import { Modal, Typography } from 'antd';

import PropTypes from 'prop-types';

import css from './SaveFilterModal.module.scss';

const { Text } = Typography;

function SaveFilterModal({ field, onCancel }) {
  const parseField = useMemo(() => JSON.parse(field.filter), [field]);

  const { t } = useTranslation('saveFilterModal');

  const { fields } = useSelector(selectFields);

  const findCustomField = id => {
    let customFieldName = null;
    if (fields.length) {
      fields.forEach(item => {
        if (item.id === id) {
          customFieldName = item.name;
        }
      });
    }
    return customFieldName;
  };

  return (
    <Modal open footer={null} onCancel={onCancel} width={700} className={css['SaveFilterModal']} title={field.name}>
      {Object.keys(parseField).map(key => {
        if (
          (key === 'locations' ||
            key === 'positions' ||
            key === 'functions' ||
            key === 'departments' ||
            key === 'companies' ||
            key === 'roles') &&
          parseField[key]
        ) {
          return (
            <>
              <Text>{t(key)}:</Text>
              <ul>
                {parseField[key].map(item => {
                  return <li>{item}</li>;
                })}
              </ul>
            </>
          );
        } else if (key === 'mode') {
          return (
            <>
              <div className={css['SaveFilterModal__block']}>
                <Text>
                  {t('modeCaption')}: {t(`mode.${parseField.mode - 1}`)}
                </Text>
                {parseField.mode !== 3 ? (
                  <>
                    <Text>
                      {t('startOfJobFrom')}: {parseField.startOfJobFrom}
                    </Text>
                    {parseField.startOfJobTo && (
                      <Text>
                        {t('startOfJobTo')}: {parseField.startOfJobTo}
                      </Text>
                    )}
                    {parseField.plusDaysDeadline && (
                      <Text>
                        {t('plusDaysDeadline')}: {parseField.plusDaysDeadline}
                      </Text>
                    )}
                    {parseField.plusDaysStudyPlan && (
                      <Text>
                        {t('plusDaysStudyPlan')}: {parseField.plusDaysStudyPlan}
                      </Text>
                    )}
                  </>
                ) : !parseField.launchDate ? (
                  <>
                    <Text>
                      {t('concreteDate')}: {parseField.concreteDate}
                    </Text>
                    {parseField.plusDaysDeadline && (
                      <Text>
                        {t('plusDaysDeadline')}: {parseField.plusDaysDeadline}
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    <Text>
                      {t('launchDateFilter')}: {parseField.launchDate}
                    </Text>
                    <Text>
                      {t('concreteDateFilter')}: {parseField.concreteDate}
                    </Text>
                    {parseField.plusDaysDeadline && (
                      <Text>
                        {t('plusDaysDeadline')}: {parseField.plusDaysDeadline}
                      </Text>
                    )}
                  </>
                )}
              </div>
              <div className={css['SaveFilterModal__block']}>
                {parseField.periodInMonths && (
                  <Text>
                    {t('periodInMonths')}: {parseField.periodInMonths}
                  </Text>
                )}
                {parseField.plusDaysDeadline && (
                  <Text>
                    {t('endDate')}: {parseField.endDate}
                  </Text>
                )}
              </div>
            </>
          );
        } else if (key === 'customFields' && parseField[key].length) {
          return (
            <>
              <Text>{t('recordCustomField')}:</Text>
              {parseField[key].map(item => {
                return (
                  <div className={css['SaveFilterModal__block-customField']}>
                    <Text>
                      {t('customField')}: {findCustomField(item.customFieldId)}
                    </Text>
                    <Text>
                      {t('condition')}: {t(item.type)}
                    </Text>
                    <Text>
                      {t('value')}: {item.valueConcrete ? item.valueConcrete : item.valueFrom}{' '}
                      {!item.valueConcrete && '-'} {item.valueConcrete ? null : item.valueTo}
                    </Text>
                  </div>
                );
              })}
            </>
          );
        } else {
          return null;
        }
      })}
    </Modal>
  );
}

SaveFilterModal.propTypes = {
  field: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SaveFilterModal;
