/* eslint-disable */
import { API_D360_URL } from '../service_api';
import {
  D360_CREATE_FILTERS_FAIL,
  D360_CREATE_FILTERS_START,
  D360_CREATE_FILTERS_SUCCESS,
  D360_GET_FILTERCOMPETENCIES_FAIL,
  D360_GET_FILTERCOMPETENCIES_START,
  D360_GET_FILTERCOMPETENCIES_SUCCESS,
  D360_GET_FILTERSKILLS_FAIL,
  D360_GET_FILTERSKILLS_START,
  D360_GET_FILTERSKILLS_SUCCESS,
  D360_GET_FILTERTIPS_FAIL,
  D360_GET_FILTERTIPS_START,
  D360_GET_FILTERTIPS_SUCCESS,
  D360_GET_FILTERUSERS_FAIL,
  D360_GET_FILTERUSERS_START,
  D360_GET_FILTERUSERS_SUCCESS,
} from './constants';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360GetFilters = () => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/filters`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_FILTERS_START, D360_CREATE_FILTERS_SUCCESS, D360_CREATE_FILTERS_FAIL],
  };
};

export const D360GetFilterTips = (field, value, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/find/user${getQueryString({
      field: field,
      value: value,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_FILTERTIPS_START, D360_GET_FILTERTIPS_SUCCESS, D360_GET_FILTERTIPS_FAIL],
    onSuccess,
    onError,
  };
};

export const D360GetFilterUsers = (projectId, usersIds, filters, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/users${getQueryString({
      d360_project_id: projectId,
      users: usersIds,
      ...filters,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_FILTERUSERS_START, D360_GET_FILTERUSERS_SUCCESS, D360_GET_FILTERUSERS_FAIL],
    onSuccess,
    onError,
  };
};

export const D360GetFilterUsersAuto = (projectId, userId, filters, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/auto/assign`,
    body: {
      d360_project_id: projectId,
      user: userId,
      ...filters,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_FILTERUSERS_START, D360_GET_FILTERUSERS_SUCCESS, D360_GET_FILTERUSERS_FAIL],
    onSuccess,
    onError,
  };
};

export const D360GetFilterCompetencies = (onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/competencies`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_FILTERCOMPETENCIES_START, D360_GET_FILTERCOMPETENCIES_SUCCESS, D360_GET_FILTERCOMPETENCIES_FAIL],
    onSuccess,
    onError,
  };
};

export const D360GetFilterSkills = (projectId, competenceId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/skills${getQueryString({
      d360_project_id: projectId,
      competence_id: competenceId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_FILTERSKILLS_START, D360_GET_FILTERSKILLS_SUCCESS, D360_GET_FILTERSKILLS_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

 import {
    D360GetFilters,
    D360GetFilterTips,
    D360GetFilterUsers,
    D360GetFilterUsersAuto,
    D360GetFilterCompetencies,
    D360GetFilterSkills,
   } from '@/store/d360_filters/actions';

 **************************************************************/
