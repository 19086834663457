/* eslint-disable */
import {
  CLEAR_WAITING_LIST,
  GET_COUNT_WAITING_USERS_FAIL,
  GET_COUNT_WAITING_USERS_START,
  GET_COUNT_WAITING_USERS_SUCCESS,
  GET_WAITING_STATUSES_FAIL,
  GET_WAITING_STATUSES_START,
  GET_WAITING_STATUSES_SUCCESS,
  POST_CHECK_WAITING_LIST_FAIL,
  POST_CHECK_WAITING_LIST_START,
  POST_CHECK_WAITING_LIST_SUCCESS,
} from './actions';

const initialState = {
  error: false,
  isLoading: false,
  waitingList: [],
  countWaitingUsers: {},
  waitingStatuses: null,
  waitingListTotal: null,
  waitingListPageSize: null,
  waitingListPageNumber: null,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case POST_CHECK_WAITING_LIST_START:
    case GET_COUNT_WAITING_USERS_START:
    case GET_WAITING_STATUSES_START:
      return { ...state, error: false, isLoading: true };

    case POST_CHECK_WAITING_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        waitingList: response,
        waitingListTotal: Number(headers.get('X-Total-Elements')) || 0,
        waitingListPageSize: Number(headers.get('X-Page-Size')) || 0,
        waitingListPageNumber: Number(headers.get('X-Page-Number')) || 0,
      };

    case GET_COUNT_WAITING_USERS_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        countWaitingUsers: response,
      };

    case GET_WAITING_STATUSES_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        waitingStatuses: response,
      };

    case POST_CHECK_WAITING_LIST_FAIL:
    case GET_COUNT_WAITING_USERS_FAIL:
    case GET_WAITING_STATUSES_FAIL:
      return { ...state, error: true, isLoading: false };

    case CLEAR_WAITING_LIST:
      return { ...state, error: false, waitingList: [], isLoading: false };

    default:
      return state;
  }
};
