/* eslint-disable */

import React, { Component } from 'react';

import PropTypes from 'prop-types';

export class CurrencySymbol extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { symbol: 'RUB' };
  }

  componentDidMount() {
    this.getCost();
  }

  getCost() {
    const { currentUser } = this.props;
    let symbol = 'RUB';

    // Получаем текущую валюты компании
    if (
      currentUser &&
      currentUser.currencies &&
      Array.isArray(currentUser.currencies) &&
      currentUser.domainCompany &&
      currentUser.domainCompany.currencyId
    ) {
      const { currencies } = currentUser;
      const { currencyId } = currentUser.domainCompany;

      if (typeof currencyId === 'number') {
        currencies.map(currency => {
          if (currency.id === currencyId) {
            symbol = currency.code;
          }
        });
      }
    }

    switch (symbol) {
      case 'AUD':
        this.setState({ symbol: '&#36;' });
        break;
      case 'AZN':
        break;
      case 'GBP':
        break;
      case 'AMD':
        break;
      case 'BYN':
        break;
      case 'BGN':
        break;
      case 'BRL':
        break;
      case 'HUF':
        break;
      case 'HKD':
        break;
      case 'DKK':
        break;
      case 'USD':
        this.setState({ symbol: '&#65284;' });
        break;
      case 'EUR':
        this.setState({ symbol: '&#8364;' });
        break;
      case 'INR':
        break;
      case 'KZT':
        break;
      case 'CAD':
        break;
      case 'KGS':
        break;
      case 'CNY':
        break;
      case 'MDL':
        break;
      case 'NOK':
        break;
      case 'PLN':
        break;
      case 'RON':
        break;
      case 'XDR':
        break;
      case 'SGD':
        break;
      case 'TJS':
        break;
      case 'TRY':
        break;
      case 'TMT':
        break;
      case 'UZS':
        break;
      case 'UAH':
        break;
      case 'CZK':
        break;
      case 'SEK':
        break;
      case 'CHF':
        break;
      case 'ZAR':
        break;
      case 'KRW':
        break;
      case 'JPY':
        this.setState({ symbol: '&#65509;' });
        break;
      case 'RUB':
        this.setState({ symbol: '&#8381;' });
        break;
      default:
        this.setState({ symbol: '&#8381;' });
        break;
    }
  }

  render() {
    const { symbol } = this.state;

    return (
      <>
        &nbsp;<span dangerouslySetInnerHTML={{ __html: symbol }}></span>
      </>
    );
  }
}
