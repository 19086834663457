/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360GetProject } from '@/store/d360/d360_project/actions';
import { D360ActiveProject, D360DeactivateProject, D360HealthProject } from '@/store/d360/d360_projects/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Modal } from 'antd';

import {
  D360_HEALTH_ERROR_NOCRITERIAS,
  D360_HEALTH_ERROR_NOMEASURABLES,
  D360_HEALTH_ERROR_NORESPONDENTS,
  D360_HEALTH_ERROR_NOSCALES,
  D360_HEALTH_ERROR_NOSCORES,
  PROJECT_STATUS_ACTIVE,
  PROJECT_STATUS_DRAFT,
} from '../../../constants';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { PROJECT_360_ACTIVATE, PROJECT_360_DEACTIVATE } from '@/constants/permissions';

/**
 * Компонент выводит детальную информацию по проекту.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProjectHealth = props => {
  const [disableStartProject, setDisableStartProject] = useState(false);
  const dispatch = useDispatch();

  const { currentUser } = useSelector(selectUsers);

  const { t } = useTranslation('D360_ProjectHealth');

  const [modal, contextHolder] = Modal.useModal();

  /**
   * Отображение сообщения об ошибке.
   *
   * @param code Код ошибки
   * @param name Имя объекта ошибки
   *
   * @returns {*}
   */
  const errorMessage = (code, name) => {
    let content = '';
    let title = t('errorMessageTitle') + ' 0x' + code.toString(16);
    switch (code) {
      case D360_HEALTH_ERROR_NOSCALES:
        content = 'В проекте отсутствуют шкалы!';
        break;
      case D360_HEALTH_ERROR_NOSCORES:
        content = t('D360_HEALTH_ERROR_NOSCORES', { name: name });
        break;
      case D360_HEALTH_ERROR_NOCRITERIAS:
        content = t('D360_HEALTH_ERROR_NOCRITERIAS');
        break;
      case D360_HEALTH_ERROR_NOMEASURABLES:
        content = t('D360_HEALTH_ERROR_NOMEASURABLES');
        break;
      case D360_HEALTH_ERROR_NORESPONDENTS:
        content = t('D360_HEALTH_ERROR_NORESPONDENTS', { name: name });
        break;
      default:
        content = t('D360_HEALTH_ERROR_UNKNOWN');
        break;
    }

    return modal.confirm({
      title: title,
      content: content,
      okText: '',
      okButtonProps: { style: { display: 'none' } },
      cancelText: t('errorMessageCancel'),
      okType: 'danger',
    });
  };

  const startProject = () => {
    return modal.confirm({
      title: t('confirmRunTitle'),
      content: t('confirmRunText'),
      onOk() {
        dispatch(
          D360ActiveProject(props.project.id, () => {
            dispatch(D360GetProject(props.project.id));
          })
        );
      },
      onCancel() {
        setDisableStartProject(false);
      },
    });
  };

  const stopProject = () => {
    return modal.confirm({
      title: t('confirmStopTitle'),
      content: t('confirmStopText'),
      onOk() {
        dispatch(
          D360DeactivateProject(props.project.id, () => {
            dispatch(D360GetProject(props.project.id));
          })
        );
      },
      onCancel() {
        setDisableStartProject(false);
      },
    });
  };

  const healthCheck = () => {
    setDisableStartProject(true);
    dispatch(
      D360HealthProject(
        props.project.id,
        response => {
          if (response && response.data) {
            if (_.has(response.data, 'code') && _.has(response.data, 'error') && _.has(response.data, 'name')) {
              if (response.data.error === false) {
                return startProject();
              } else {
                setDisableStartProject(false);
                return errorMessage(response.data.code, response.data.name);
              }
            }
          }
        },
        error => {
          console.log('Error:', error);
          setDisableStartProject(false);
        }
      )
    );
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    switch (props.project.d360_status_id) {
      case PROJECT_STATUS_DRAFT:
        return (
          <Button
            size='large'
            type='primary'
            onClick={healthCheck}
            disabled={disableStartProject && !currentUser?.authorities.includes(PROJECT_360_ACTIVATE)}
          >
            {/* Запустить проект */}
            {t('run')}
          </Button>
        );
      case PROJECT_STATUS_ACTIVE:
        return (
          <Button
            size='large'
            type='primary'
            disabled={!currentUser?.authorities.includes(PROJECT_360_DEACTIVATE)}
            danger
            onClick={stopProject}
          >
            {t('stop')}
          </Button>
        );
      default:
        return (
          <Button size='large' type='ghost' disabled={true}>
            {t('inactive')}
          </Button>
        );
    }
  };

  /**
   * Запуск компонента.
   */
  return (
    <>
      {render()}
      {contextHolder}
    </>
  );
};

ProjectHealth.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectHealth;
