/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Input } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './HoursMinutesInput.module.scss';

const maxValues = {
  hours: 23,
  minutes: 59,
};

class HoursMinutesInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hours: props.hours,
      minutes: props.minutes,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { name: inputName, onChange } = this.props;
    const numberValue = parseInt(value);
    if (numberValue < 0 || numberValue > maxValues[name]) {
      return;
    }
    const resultValue = isNaN(numberValue) ? undefined : value.substring(0, 2);
    onChange({
      name: inputName,
      minutes: parseInt(this.state.minutes),
      hours: parseInt(this.state.hours),
      [name]: resultValue,
    });
    this.setState({ [name]: resultValue });
  };

  render() {
    const { className, t } = this.props;
    const { hours, minutes } = this.state;

    return (
      <div className={classNames(className, css.HoursMinutesInput)}>
        <Input
          placeholder={t('hours')}
          name='hours'
          value={hours}
          onChange={this.handleChange}
          type='text'
          className={css['HoursMinutesInput-input']}
        />
        <Input
          placeholder={t('minutes')}
          name='minutes'
          value={minutes}
          onChange={this.handleChange}
          type='text'
          className={css['HoursMinutesInput-input']}
        />
      </div>
    );
  }
}

export default withTranslation('timeInput')(HoursMinutesInput);
