/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Button, Col, Drawer, Progress, Row, Spin, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import ProjectDetails from './parts/ProjectDetails';
import ProjectHealth from './parts/ProjectHealth';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import css from '../../css/index.module.scss';

import defaultImg from '@/assets/images/360/default.png';

export const Info = props => {
  const { t } = useTranslation('D360_Info');

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [infoVisible, setInfoVisible] = useState(false);

  const [image, isImageLoading] = useLocalFileByUUID(props?.project?.file_uuid, { defaultImg });

  useEffect(() => {
    if (props && props.project) {
      const { project } = props;

      if (project.name && project.name.length > 0) {
        setName(project.name);
      }

      if (project.description && project.description.length > 0) {
        setDescription(project.description);
      }
    }
  }, [props.project]);

  const render = () => {
    return (
      <div>
        <Row className={css.Header}>
          <Col span={24} className={css.HeaderPaneLeft}>
            <div style={{ position: 'relative' }}>
              <Spin spinning={isImageLoading}>
                <img src={image} alt={name} className={css.HeaderCover} />
              </Spin>
              <div className={css.HeaderInfoButton}>
                <InfoCircleOutlined
                  className={'projectInfoBlink'}
                  style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', fontSize: 35, color: 'white' }}
                  onClick={() => setInfoVisible(!infoVisible)}
                />
              </div>
              <Drawer
                title={t('summaryInfo')}
                placement='right'
                open={infoVisible}
                getContainer={false}
                width={400}
                onClose={() => setInfoVisible(false)}
                rootStyle={{ position: 'absolute', display: infoVisible ? 'block' : 'none' }}
              >
                <ProjectDetails project={props.project} />
              </Drawer>
              <div className={css.HeaderProjectProgress}>
                <Progress type='line' trailColor='white' percent={props.project.progress} />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <p style={{ whiteSpace: 'break-spaces', padding: '20px 0' }}>{description}</p>
          </Col>
          <Col span={24} align={'right'}>
            <ProjectHealth project={props.project} />
          </Col>
        </Row>
      </div>
    );
  };

  return render();
};

Info.propTypes = {
  project: PropTypes.object.isRequired,
};

const mapDispatchToProps = { getFile };

export default connect(null, mapDispatchToProps)(Info);
