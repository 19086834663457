export const COMPIL_SUBTYPES = (t: (data: string) => string) => [
  {
    name: t('constants:material'),
    panname: 'materials',
    active: true,
    dataQa: 'adminCompilationsMaterialTab',
  },
  {
    name: t('constants:info'),
    panname: 'info',
    active: false,
    dataQa: 'adminCompilationsInfoTab',
  },
  {
    name: t('constants:commentFeedback'),
    panname: 'feedback',
    active: false,
    dataQa: 'adminCompilationsCommentFeedbackTab',
  },
];
