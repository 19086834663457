/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFileSizes } from '@/store/files/selectors';

import { Button, Dropdown, Form, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import SingleFileInput from '@/components/FileInput/SingleFileInput';
import ImageInput from '@/components/ImageInputCrop';
import { EllipsisOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import { IMAGE_TYPES } from '@shared/constants/image-types';

import Utils from '@/Utils';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';
import video from '@/assets/images/video.svg';

export const Video = ({
  data,
  editBlock,
  deleteBlock,
  forceClose,
  createVideo,
  uploadVideo,
  getFile,
  createFileImage,
  files,
  getChapters,
  topicId,
  canEdit,
  currentUser,
  topicTypeId,
  showSaveModal,
}) => {
  const { t } = useTranslation('video');
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(true);
  const [videoID, setVideoID] = useState((data.video && data.video.id) || null);
  const [imageID, setImageID] = useState(data.coverImage || null);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileSizes = useSelector(selectFileSizes);
  const videoFileSize = fileSizes.filter(item => item.uploadSizeType === 'VIDEO')[0];

  const header = useMemo(
    () => (
      <div className={css['Block-header']}>
        <img className={css['Block-header-item']} src={video} alt='' />
        <div className={css['Block-header-name']}>
          <Tooltip title={data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(data.name, 55)}
          </Tooltip>
        </div>
      </div>
    ),
    [data.name]
  );

  useEffect(() => {
    if (data.coverImage) {
      getFile(data.coverImage, res => {
        setImage(URL.createObjectURL(res));
      });
    }
  }, [data.coverImage]);

  const saveButtonHandler = useCallback(() => {
    createVideo(data.id, { id: videoID, coverImage: imageID }, () => {
      setDirty(true);
      getChapters(topicId);
    });
  }, [videoID, imageID, setDirty]);

  const handleEditClick = useCallback(() => {
    editBlock(data.id);
  }, [editBlock]);

  const handleDeleteClick = useCallback(() => {
    deleteBlock(data.id);
  }, [deleteBlock]);

  const saveModal = useCallback(() => {
    showSaveModal({
      id: videoID,
      delId: data.id,
      funcSave: () => saveButtonHandler(),
      funcCancel: () => {
        setVideoID(data.id);
        setDirty(true);
      },
    });
  }, [showSaveModal, videoID, saveButtonHandler]);

  const onChange = useCallback(
    file => {
      if (!file) {
        setDirty(false);
        setVideoID(null);
      } else {
        uploadVideo(
          file,
          res => {
            setVideoID(res.id);
            setIsLoading(false);
            setDirty(false);
            data.video = data.video || {};
            data.video.fileName = res.fileName;
          },
          e => {
            setError(true);
            setIsLoading(false);
          }
        );
      }
    },
    [dirty, error, videoID]
  );

  const handleImageChange = useCallback(
    (file, name, info, cropImageSrc) => {
      if (file && info) {
        if (IMAGE_TYPES.includes(info.type)) {
          createFileImage(file, fileInfo => {
            setImage(URL.createObjectURL(cropImageSrc));
            setImageID(fileInfo.uuid);
            setDirty(false);
          });
          return true;
        }
      } else {
        setImage(null);
        setImageID(null);
        setDirty(false);
      }
    },
    [setImage, setImageID]
  );

  const dropDownMenu = [
    ...(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={handleEditClick}>
                {t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={handleDeleteClick}>
                {t('delete')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const extra = useMemo(
    () => (
      <div onClick={e => e.stopPropagation()}>
        <Button
          onClick={saveModal}
          className={css['Block-header-save']}
          disabled={dirty || !canEdit || isLoading}
          type='primary'
          data-qa='saveBlockBtn'
        >
          {t('save')}
        </Button>
        {(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
          currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
          (topicTypeId === 25 &&
            (currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
              currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
          <Dropdown
            menu={{ items: dropDownMenu }}
            placement='bottomRight'
            overlayClassName={css['Dropdown-menu']}
            trigger={['click']}
          >
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    ),
    [deleteBlock, editBlock, saveButtonHandler, dirty, canEdit, isLoading]
  );

  return (
    <div className={css.Block}>
      <CollapseLMS header={header} data={data} extra={extra} forceClose={forceClose}>
        <div className={css['Block-layoutScorm']}>
          <Form.Item data-qa='inputsBlock' label={t('file')} wrapperCol={{ span: 20 }}>
            <SingleFileInput
              type='video'
              loading={isLoading}
              setLoading={setIsLoading}
              onChange={onChange}
              name={videoID && data.video ? data.video.fileName : null}
              disable={!canEdit}
              error={error}
              scormFileSize={videoFileSize}
            />
          </Form.Item>
          <Form.Item label={t('cover')} wrapperCol={{ span: 20 }}>
            <ImageInput
              dataQa='blockImage'
              short
              reverse
              company
              aspectWidth={16}
              aspectHeight={9}
              id='imgUuid'
              name='imgUuid'
              label={t('add')}
              onChange={handleImageChange}
              imageSrc={image || ''}
              fileInfo={files.info}
              isLoadingFileImage={files.isLoadingImage}
              disabled={!canEdit}
            />
          </Form.Item>
        </div>
      </CollapseLMS>
      {error && (
        <ConfirmationModal
          open
          onOk={() => {
            setError(false);
            setIsLoading(false);
          }}
          onCancel={() => {
            setError(false);
            setIsLoading(false);
          }}
          okText={t('ok')}
          zIndex={1006}
          title={t('error')}
          confirmationText={t('description')}
        />
      )}
    </div>
  );
};
