/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

import { headersForImport } from '@/constants/global';

export const GET_BLOCK_TYPES_START = 'GET_BLOCK_TYPES_START';
export const GET_BLOCK_TYPES_SUCCESS = 'GET_BLOCK_TYPES_SUCCESS';
export const GET_BLOCK_TYPES_FAIL = 'GET_BLOCK_TYPES_FAIL';

export const CREATE_BLOCK_START = 'CREATE_BLOCK_START';
export const CREATE_BLOCK_SUCCESS = 'CREATE_BLOCK_SUCCESS';
export const CREATE_BLOCK_FAIL = 'CREATE_BLOCK_FAIL';

export const EDIT_BLOCK_START = 'EDIT_BLOCK_START';
export const EDIT_BLOCK_SUCCESS = 'EDIT_BLOCK_SUCCESS';
export const EDIT_BLOCK_FAIL = 'EDIT_BLOCK_FAIL';

export const EDIT_BLOCK_CONTENT_START = 'EDIT_BLOCK_CONTENT_START';
export const EDIT_BLOCK_CONTENT_SUCCESS = 'EDIT_BLOCK_CONTENT_SUCCESS';
export const EDIT_BLOCK_CONTENT_FAIL = 'EDIT_BLOCK_CONTENT_FAIL';

export const GET_BLOCKS_START = 'GET_BLOCKS_START';
export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS';
export const GET_BLOCKS_FAIL = 'GET_BLOCKS_FAIL';

export const DELETE_BLOCK_START = 'DELETE_BLOCK_START';
export const DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS';
export const DELETE_BLOCK_FAIL = 'DELETE_BLOCK_FAIL';

export const GET_BLOCK_STATISTIC_START = 'GET_BLOCK_STATISTIC_START';
export const GET_BLOCK_STATISTIC_SUCCESS = 'GET_BLOCK_STATISTIC_SUCCESS';
export const GET_BLOCK_STATISTIC_FAIL = 'GET_BLOCK_STATISTIC_FAIL';

export const GET_RESULT_STATISTIC_START = 'GET_RESULT_STATISTIC_START';
export const GET_RESULT_STATISTIC_SUCCESS = 'GET_RESULT_STATISTIC_SUCCESS';
export const GET_RESULT_STATISTIC_FAIL = 'GET_RESULT_STATISTIC_FAIL';

export const GET_BLOCK_STATISTICS_EXPORT_START = 'GET_BLOCK_STATISTICS_EXPORT_START';
export const GET_BLOCK_STATISTICS_EXPORT_SUCCESS = 'GET_BLOCK_STATISTICS_EXPORT_SUCCESS';
export const GET_BLOCK_STATISTICS_EXPORT_FAIL = 'GET_BLOCK_STATISTICS_EXPORT_FAIL';

export const CREATE_SCORM_START = 'CREATE_SCORM_START';
export const CREATE_SCORM_SUCCESS = 'CREATE_SCORM_SUCCESS';
export const CREATE_SCORM_FAIL = 'CREATE_SCORM_FAIL';

export const DELETE_SCORM_START = 'DELETE_SCORM_START';
export const DELETE_SCORM_SUCCESS = 'DELETE_SCORM_SUCCESS';
export const DELETE_SCORM_FAIL = 'DELETE_SCORM_FAIL';

export const UPLOAD_SCORM_START = 'UPLOAD_SCORM_START';
export const UPLOAD_SCORM_SUCCESS = 'UPLOAD_SCORM_SUCCESS';
export const UPLOAD_SCORM_FAIL = 'UPLOAD_SCORM_FAIL';

export const CREATE_VIDEO_START = 'CREATE_VIDEO_START';
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';
export const CREATE_VIDEO_FAIL = 'CREATE_VIDEO_FAIL';

export const DELETE_VIDEO_START = 'DELETE_VIDEO_START';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAIL = 'DELETE_VIDEO_FAIL';

export const UPLOAD_VIDEO_START = 'UPLOAD_VIDEO_START';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAIL = 'UPLOAD_VIDEO_FAIL';

export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL';

export const CREATE_PRESENTATION_START = 'CREATE_PRESENTATION_START';
export const CREATE_PRESENTATION_SUCCESS = 'CREATE_PRESENTATION_SUCCESS';
export const CREATE_PRESENTATION_FAIL = 'CREATE_PRESENTATION_FAIL';

export const DELETE_PRESENTATION_START = 'DELETE_PRESENTATION_START';
export const DELETE_PRESENTATION_SUCCESS = 'DELETE_PRESENTATION_SUCCESS';
export const DELETE_PRESENTATION_FAIL = 'DELETE_PRESENTATION_FAIL';

export const UPLOAD_PRESENTATION_START = 'UPLOAD_PRESENTATION_START';
export const UPLOAD_PRESENTATION_SUCCESS = 'UPLOAD_PRESENTATION_SUCCESS';
export const UPLOAD_PRESENTATION_FAIL = 'UPLOAD_PRESENTATION_FAIL';

export const GET_STATISTICS_STATUS_START = 'GET_STATISTICS_STATUS_START';
export const GET_STATISTICS_STATUS_SUCCESS = 'GET_STATISTICS_STATUS_SUCCESS';
export const GET_STATISTICS_STATUS_FAIL = 'GET_STATISTICS_STATUS_FAIL';

export const GET_STUDY_PLAN_STATUS_START = 'GET_STUDY_PLAN_STATUS_START';
export const GET_STUDY_PLAN_STATUS_SUCCESS = 'GET_STUDY_PLAN_STATUS_SUCCESS';
export const GET_STUDY_PLAN_STATUS_FAIL = 'GET_STUDY_PLAN_STATUS_FAIL';

export const GET_BLOCKS_TOPIC_START = 'GET_BLOCKS_TOPIC_START';
export const GET_BLOCKS_TOPIC_SUCCESS = 'GET_BLOCKS_TOPIC_SUCCESS';
export const GET_BLOCKS_TOPIC_FAIL = 'GET_BLOCKS_TOPIC_FAIL';

export const ADD_CUSTOM_QUESTION_START = 'ADD_CUSTOM_QUESTION_START';
export const ADD_CUSTOM_QUESTION_SUCCESS = 'ADD_CUSTOM_QUESTION_SUCCESS';
export const ADD_CUSTOM_QUESTION_FAIL = 'ADD_CUSTOM_QUESTION_FAIL';

export const ADD_QUESTIONS_TO_TEST_START = 'ADD_QUESTIONS_TO_TEST_START';
export const ADD_QUESTIONS_TO_TEST_SUCCESS = 'ADD_QUESTIONS_TO_TEST_SUCCESS';
export const ADD_QUESTIONS_TO_TEST_FAIL = 'ADD_QUESTIONS_TO_TEST_FAIL';

export const UPDATE_CHECKLIST_START = 'UPDATE_CHECKLIST_START';
export const UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS';
export const UPDATE_CHECKLIST_FAIL = 'UPDATE_CHECKLIST_FAIL';

export const UPDATE_FILE_START = 'UPDATE_FILE_START';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAIL = 'UPDATE_FILE_FAIL';

export const ADD_LOADING_FILE = 'ADD_LOADING_FILE';
export const REMOVE_LOADING_FILE = 'REMOVE_LOADING_FILE';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getBlockStatistic = ({ topicId, userId, planId, onSuccess }) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/result/statistic${getQueryString({
    topicId,
    userId,
    planId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_BLOCK_STATISTIC_START, GET_BLOCK_STATISTIC_SUCCESS, GET_BLOCK_STATISTIC_FAIL],
});

export const getResultStatistic = (filter, page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/result/statistic-users/repeat${getQueryString({ ...filter, page, size: 20 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_RESULT_STATISTIC_START, GET_RESULT_STATISTIC_SUCCESS, GET_RESULT_STATISTIC_FAIL],
});

export const getBlockStatisticsExport = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/block/result/export${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'blob',
  onSuccess,
  types: [GET_BLOCK_STATISTICS_EXPORT_START, GET_BLOCK_STATISTICS_EXPORT_SUCCESS, GET_BLOCK_STATISTICS_EXPORT_FAIL],
});

export const getBlockTypes = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/blockType`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BLOCK_TYPES_START, GET_BLOCK_TYPES_SUCCESS, GET_BLOCK_TYPES_FAIL],
});

export const createBlock = (data, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [CREATE_BLOCK_START, CREATE_BLOCK_SUCCESS, CREATE_BLOCK_FAIL],
});

export const editBlock = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${data.id}/short`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [EDIT_BLOCK_START, EDIT_BLOCK_SUCCESS, EDIT_BLOCK_FAIL],
});

export const editBlockContent = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${data.id}/detail`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [EDIT_BLOCK_CONTENT_START, EDIT_BLOCK_CONTENT_SUCCESS, EDIT_BLOCK_CONTENT_FAIL],
});

export const deleteBlock = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/block/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [DELETE_BLOCK_START, DELETE_BLOCK_SUCCESS, DELETE_BLOCK_FAIL],
});

export const getBlocks = topicIds => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block?topicId=${topicIds}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    topicIds,
  },
  types: [GET_BLOCKS_START, GET_BLOCKS_SUCCESS, GET_BLOCKS_FAIL],
});

export const createScorm = (id, body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${id}/scorm/detail`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  onSuccess,
  onError,
  types: [CREATE_SCORM_START, CREATE_SCORM_SUCCESS, CREATE_SCORM_FAIL],
});

export const deleteScorm = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/scorm/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [DELETE_SCORM_START, DELETE_SCORM_SUCCESS, DELETE_SCORM_FAIL],
});

export const uploadScorm = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/scorm/upload`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  onSuccess,
  onError,
  types: [UPLOAD_SCORM_START, UPLOAD_SCORM_SUCCESS, UPLOAD_SCORM_FAIL],
});

export const createVideo = (id, video, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${id}/video/detail`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: video,
  onSuccess,
  onError,
  types: [CREATE_VIDEO_START, CREATE_VIDEO_SUCCESS, CREATE_VIDEO_FAIL],
});

export const deleteVideo = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/video/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [DELETE_VIDEO_START, DELETE_VIDEO_SUCCESS, DELETE_VIDEO_FAIL],
});

export const uploadVideo = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/video/upload`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  onSuccess,
  onError,
  types: [UPLOAD_VIDEO_START, UPLOAD_VIDEO_SUCCESS, UPLOAD_VIDEO_FAIL],
});

export const createArticle = (id, content, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${id}/article`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    body: content,
  },
  onSuccess,
  onError,
  types: [CREATE_ARTICLE_START, CREATE_ARTICLE_SUCCESS, CREATE_ARTICLE_FAIL],
});

export const updateArticle = (id, content, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/article/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    body: content,
  },
  onSuccess,
  onError,
  types: [CREATE_ARTICLE_START, CREATE_ARTICLE_SUCCESS, CREATE_ARTICLE_FAIL],
});

export const createPresentation = (id, pid, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${id}/presentation/detail`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id: pid,
  },
  onSuccess,
  onError,
  types: [CREATE_PRESENTATION_START, CREATE_PRESENTATION_SUCCESS, CREATE_PRESENTATION_FAIL],
});

export const deletePresentation = (pid, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/presentation/${pid}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [DELETE_PRESENTATION_START, DELETE_PRESENTATION_SUCCESS, DELETE_PRESENTATION_FAIL],
});

export const uploadPresentation = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/presentation/upload`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  onSuccess,
  onError,
  types: [UPLOAD_PRESENTATION_START, UPLOAD_PRESENTATION_SUCCESS, UPLOAD_PRESENTATION_FAIL],
});

export const getStatisticsStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATISTICS_STATUS_START, GET_STATISTICS_STATUS_SUCCESS, GET_STATISTICS_STATUS_FAIL],
  onSuccess,
  onError,
});

export const getStudyPlanStatus = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/status/topic${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STUDY_PLAN_STATUS_START, GET_STUDY_PLAN_STATUS_SUCCESS, GET_STUDY_PLAN_STATUS_FAIL],
  onSuccess,
  onError,
});

export const getBlocksTypeTopic = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/${id}/blocks`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_BLOCKS_TOPIC_START, GET_BLOCKS_TOPIC_SUCCESS, GET_BLOCKS_TOPIC_FAIL],
});

// Добавление вопроса в банк из кастомных вопросов
export const addCustomQuestion = (id, categories, blockId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/question/bank/custom`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id,
    questionCategoryDtos: categories,
  },
  types: [ADD_CUSTOM_QUESTION_START, ADD_CUSTOM_QUESTION_SUCCESS, ADD_CUSTOM_QUESTION_FAIL],
  blockId,
  onSuccess,
  onError,
});

// Добавление вопроса в банк из кастомных вопросов
export const addQuestionsToTest = (testId, questionIds, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/question/bank/test/${testId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    questionIds,
  },
  types: [ADD_QUESTIONS_TO_TEST_START, ADD_QUESTIONS_TO_TEST_SUCCESS, ADD_QUESTIONS_TO_TEST_FAIL],
  testId,
  onSuccess,
  onError,
});

// Обновление checklist assesor'a
export const updateChecklist = (blockId, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/checklist/${blockId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [UPDATE_CHECKLIST_START, UPDATE_CHECKLIST_SUCCESS, UPDATE_CHECKLIST_FAIL],
  blockId,
  onSuccess,
  onError,
});

// Обновление блока files
export const updateBlockFiles = (blockId, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/file/${blockId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [UPDATE_FILE_START, UPDATE_FILE_SUCCESS, UPDATE_FILE_FAIL],
  blockId,
  onSuccess,
  onError,
});

export const addLoadingFiles = blockId => ({
  type: ADD_LOADING_FILE,
  blockId,
});

export const removeLoadingFiles = blockId => ({
  type: REMOVE_LOADING_FILE,
  blockId,
});
