import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createFunction, deleteFunction, editFunction, getFunctions } from '@/store/function/actions';

import { selectFunctions } from '@/store/function/selectors';
import { selectUsers as userSelect } from '@/store/users/selectors';

import DictionaryFunctions from './MARScontent/DictionaryFunctions';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

export class FunctionDirectory extends Component {
  static propTypes = {
    createData: PropTypes.func,
    creationError: PropTypes.bool,
    deleteData: PropTypes.func,
    editData: PropTypes.func,
    editingError: PropTypes.bool,
    getData: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    dataPageNumber: PropTypes.number,
    dataPageSize: PropTypes.number,
    dataTotal: PropTypes.number,
  };

  dictionary = () => ({
    title: this.props.t('title'),
    importText: this.props.t('importText'),
    sharedTitle: this.props.t('titleGlobal'),
    addData: this.props.t('addName'),
    placeholder: this.props.t('name'),
    addDataModal: {
      title: this.props.t('addName'),
      label: this.props.t('newOne'),
    },
    editDataModal: {
      title: this.props.t('editName'),
      label: this.props.t('newOne'),
    },
    error: this.props.t('exist'),
    deleteData: {
      title: this.props.t('deleteName'),
      confirmation: this.props.t('confirm'),
    },
  });

  render() {
    return <DictionaryFunctions {...this.props} dictionary={this.dictionary()} />;
  }
}

const mapStateToProps = createSelector([selectFunctions, userSelect], (functionSelect, users) => ({
  currentUser: users.currentUser,
  data: functionSelect.functions || [],
  dataTotal: functionSelect.functionsTotal,
  dataPageSize: functionSelect.functionsPageSize,
  dataPageNumber: functionSelect.functionsPageNumber || 0,
  creationError: functionSelect.creationError || false,
  editingError: functionSelect.editingError || false,
  isLoading: functionSelect.isLoading,
}));

const mapActionsToProps = {
  getData: getFunctions,
  createData: createFunction,
  editData: editFunction,
  deleteData: deleteFunction,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('functionDirectory')(FunctionDirectory));
