import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Modal } from 'antd';
import { FileJpgOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ImageTrack.module.scss';

const ImageBlock = props => {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(null);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    props.photoUuid
      ? dispatch(
          getFile(props.photoUuid, res => {
            setAvatar(URL.createObjectURL(res));
          })
        )
      : setAvatar(null);
  }, [props.photoUuid, dispatch]);

  const showPhotoProduct = photo => {
    modal.info({
      content: <img src={photo} alt='imageBlock' className={css['ImageBlock-modal-image']} />,
      icon: <></>,
      okText: props.okText,
      okType: 'default',
      maskClosable: true,
      okButtonProps: {
        type: 'primary',
        className: css['ImageBlock-modal-onOk'],
      },
    });
  };

  return (
    <>
      <div>
        {avatar ? (
          <img
            src={avatar}
            alt='altPhoto'
            className={classNames(css['ImageBlock-photo'], props.isBadge && css['ImageBlock-photo-badge'])}
            onClick={() => showPhotoProduct(avatar)}
          />
        ) : (
          <FileJpgOutlined className={css['ImageBlock-photo-noPhoto']} />
        )}
      </div>
      {contextHolder}
    </>
  );
};

ImageBlock.propTypes = {
  photoUuid: PropTypes.string,
  okText: PropTypes.string,
  isBadge: PropTypes.bool,
};

export default ImageBlock;
