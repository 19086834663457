// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-Z3JiiE.admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-UjGaCw .admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-label-XlbIZa[data-value="Montserrat"]:before, .admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-Z3JiiE.admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-UjGaCw .admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-item-blFZv6[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-Montserrat-LueljD {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal-P1LWz9 {
  flex-direction: column;
  align-items: center;
  margin-bottom: 1px;
  padding-top: 27px;
  display: flex;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__header-mUlrSr {
  color: #000;
  text-align: center;
  font-size: 21px;
  font-weight: 800;
  line-height: 25px;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__restriction-moqViX {
  margin: 16px 0 24px;
  font-size: 14px;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__rate-ZcJI3f {
  margin-bottom: 20px;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__textarea-v8r4IB {
  color: #000;
  resize: none;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 17px;
  font-size: 14px;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__textarea-v8r4IB:hover, .admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__textarea-v8r4IB:focus {
  box-shadow: none;
  border-color: #dadada;
  outline: 0;
}

.admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__textarea-v8r4IB::placeholder {
  color: #0006;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/ManageCourse/components/ApproveModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;AACf;;AAMA;EAHE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAKA;EAFE,mBAAmB;EACnB,eAAe;AACjB;;AAIA;EADE,mBAAmB;AACrB;;AAGA;EAAE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,UAAU;AACZ;;AACA;EAEE,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ApproveModal {\n  padding-top: 27px;\n  margin-bottom: 1px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.ApproveModal__header {\n  font-weight: 800;\n  line-height: 25px;\n  font-size: 21px;\n  color: black;\n  text-align: center;\n}\n.ApproveModal__restriction {\n  font-size: 14px;\n  margin: 16px 0 24px;\n}\n.ApproveModal__rate {\n  margin-bottom: 20px;\n}\n.ApproveModal__textarea {\n  padding: 10px 17px;\n  font-size: 14px;\n  color: #000;\n  background-color: #f6f6f6;\n  border: 1px solid #f6f6f6;\n  border-radius: 10px;\n  margin-bottom: 20px;\n  resize: none;\n}\n.ApproveModal__textarea:hover, .ApproveModal__textarea:focus {\n  border-color: #dadada;\n  outline: 0;\n  box-shadow: none;\n}\n.ApproveModal__textarea::placeholder {\n  color: rgba(0, 0, 0, 0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-Z3JiiE`,
	"qlPicker": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-Z3JiiE`,
	"ql-font": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-UjGaCw`,
	"qlFont": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-UjGaCw`,
	"ql-picker-label": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-label-XlbIZa`,
	"qlPickerLabel": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-label-XlbIZa`,
	"ql-picker-item": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-item-blFZv6`,
	"qlPickerItem": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-picker-item-blFZv6`,
	"ql-font-Montserrat": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-Montserrat-LueljD`,
	"qlFontMontserrat": `admin-src-pages-ManageCourse-components-ApproveModal-module__ql-font-Montserrat-LueljD`,
	"ApproveModal": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal-P1LWz9`,
	"approveModal": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal-P1LWz9`,
	"ApproveModal__header": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__header-mUlrSr`,
	"approveModalHeader": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__header-mUlrSr`,
	"ApproveModal__restriction": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__restriction-moqViX`,
	"approveModalRestriction": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__restriction-moqViX`,
	"ApproveModal__rate": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__rate-ZcJI3f`,
	"approveModalRate": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__rate-ZcJI3f`,
	"ApproveModal__textarea": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__textarea-v8r4IB`,
	"approveModalTextarea": `admin-src-pages-ManageCourse-components-ApproveModal-module__ApproveModal__textarea-v8r4IB`
};
export default ___CSS_LOADER_EXPORT___;
