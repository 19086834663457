import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Col, Form, Row, Typography } from 'antd';

import { Inputs } from './parts/Inputs';

import PropTypes from 'prop-types';

import style from './style.module.scss';

const Filter = ({ filters, inputSize = 8 }) => {
  const { t } = useTranslation('filter');

  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  // * Заполняем form values через searchParams
  useEffect(() => {
    const initialValues = {};

    for (const [key, value] of searchParams.entries()) {
      if (value !== undefined && value?.length) {
        if (value.includes(',')) {
          initialValues[key] = value.split(',');
        } else {
          initialValues[key] = value;
        }
      }
    }

    form.setFieldsValue(initialValues);
  }, [form, searchParams]);

  const onSubmitForm = data => {
    const params = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && String(value)?.length && value !== false && value !== null) {
        params.set(key, value);
      }
    });

    setSearchParams(params);
  };

  const resetFields = () => {
    form.resetFields();

    const sortValue = searchParams.get('sort');
    const formData = sortValue ? { sort: sortValue } : {};

    onSubmitForm(formData);
  };

  return (
    <div className={style['filter']}>
      <div className={style['filter__header']}>
        <Typography.Title className={style['filter__caption']} level={3}>
          {t('filter')}
        </Typography.Title>
      </div>
      <Form form={form} onFinish={onSubmitForm} layout='vertical' className={style['filter__form']}>
        <Row gutter={20}>
          {filters?.map((filter, index) => (
            <Col key={`filter-${index}`} span={inputSize}>
              <Inputs {...filter} />
            </Col>
          ))}
        </Row>
        <div className={style['filter__footer']}>
          <Button onClick={resetFields} data-qa='filterClearBtn'>
            {t('reset')}
          </Button>
          <Button type='primary' htmlType='submit' data-qa='filterSubmitBtn'>
            {t('submit')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

Filter.propTypes = {
  filters: PropTypes.any,
  inputSize: PropTypes.number, // размер col'а
};

export default Filter;
