import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCommentRejected, postCommentRejected } from '@/store/courses/actions';
import { approveExecutiveTask, commentExecutiveTask } from '@/store/executive/actions';
import { getStatePoka } from '@/store/poka_validation_user/actions';

import { selectCourses } from '@/store/courses/selectors';
import { selectPokaValidationUser } from '@/store/poka_validation_user/selectors';

import { Alert, Button, Col, Input, Modal, Row } from 'antd';
import RejectCommentModal from '@/pages/RejectCommentModal';
import { QuestionCircleOutlined } from '@ant-design/icons';

import PieMars from '@shared/components/PieMars';
import { PlanStatus } from '@shared/constants/plan';

import css from '../PokaValidationUser.module.scss';

const { TextArea } = Input;

const GradeSkill = () => {
  const { t } = useTranslation('pokaValidation');
  const dispatch = useDispatch();
  const { userId, planId } = useParams();

  const [isActive, setIsActive] = useState(0);
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState('');
  const [modal, contextHolder] = Modal.useModal();

  const {
    coursesRequestComment,
    coursesRequestCommentTotal,
    coursesRequestCommentPageSize,
    coursesRequestCommentNumber,
  } = useSelector(selectCourses);
  const { statePoka, shortPoka } = useSelector(selectPokaValidationUser);

  const disableSkill = statePoka?.studyPlanStatus === PlanStatus.WAITING;

  const templatePie = [
    {
      isActive: isActive,
      value: 1,
      onClick: () => disableSkill && setIsActive(1),
      text: t('pie1'),
    },
    {
      isActive: isActive,
      value: 2,
      onClick: () => disableSkill && setIsActive(2),
      text: t('pie2'),
    },
    {
      isActive: isActive,
      value: 3,
      onClick: () => disableSkill && setIsActive(3),
      text: t('pie3'),
    },
    {
      isActive: isActive,
      value: 4,
      onClick: () => disableSkill && setIsActive(4),
      text: t('pie4'),
    },
  ];

  const renderPies = () => templatePie.map((pie, index) => <PieMars key={index} disabled={!disableSkill} {...pie} />);

  const showHistoryComment = () => {
    setShowComment(true);
  };

  const templateCallback = () => {
    setComment('');
    dispatch(getStatePoka({ planId: planId }));
  };

  const showModalApplyGrade = (txt, isApply) => {
    modal.warning({
      icon: <QuestionCircleOutlined className={css['GradeSkill-modal-icon']} />,
      content: <div>{txt}</div>,
      className: css['GradeSkill-modal'],
      okText: t('confirm'),
      closable: true,
      onOk() {
        isApply
          ? dispatch(approveExecutiveTask(+planId, +userId, comment.trim(), '', isActive, () => templateCallback()))
          : dispatch(commentExecutiveTask(+userId, +planId, comment.trim(), isActive, () => templateCallback()));
      },
      maskClosable: true,
    });
  };

  const applyGrade = () => {
    if (isActive <= shortPoka?.endorsementLevel?.value && comment) {
      // отклоняем валидацию
      showModalApplyGrade(t('isDeclineSkill'));
    } else if (isActive > shortPoka?.endorsementLevel?.value) {
      // принимает валидацию
      showModalApplyGrade(t('isApplySkill'), true);
    }
  };

  return (
    <div className={css['GradeSkill']}>
      <Row gutter={[0, 16]}>
        <Col span={24} className={css['GradeSkill-name']}>
          {t('gradeSkill')}
        </Col>

        <Col span={24}>
          <Alert className={css['GradeSkill-alert']} message={t('infoGradeSkill')} type='info' closable showIcon />
        </Col>
      </Row>

      <Row gutter={[0, 16]}>
        {/* Оценка сотрудника */}
        <Col xs={24} lg={5}>
          <strong>{t('employeeEvaluation')}</strong>
        </Col>
        <Col xs={24} lg={19}>
          <div className={css['GradeSkill-pies']}>{renderPies()}</div>
        </Col>

        <Col span={24} className={css['GradeSkill-comment']}>
          <div className={css['GradeSkill-comment-txt']}>{t('comment')}</div>
          <div>
            <TextArea
              rows={4}
              placeholder={t('commentPlaceholder')}
              maxLength={1000}
              showCount
              onChange={e => setComment(e?.target?.value)}
              value={comment}
              disabled={!disableSkill}
            />
          </div>

          <div className={css['GradeSkill-comment-footer']}>
            <Button type='link' onClick={showHistoryComment} className={css['GradeSkill-comment-footer-link']}>
              {t('historyComment')}
            </Button>
            <Button type='primary' onClick={applyGrade} disabled={!disableSkill}>
              {t('applyGrade')}
            </Button>
          </div>
        </Col>
      </Row>

      {showComment && (
        <RejectCommentModal
          open={showComment}
          getCommentRejected={(a, b, c) => dispatch(getCommentRejected(a, b, c))}
          coursesId={+planId} // studyplan
          cancelRejectCommentModal={() => setShowComment(false)}
          postCommentRejected={(a, b) => dispatch(postCommentRejected(a, b))}
          coursesRequestComment={coursesRequestComment}
          coursesRequestCommentNumber={coursesRequestCommentNumber}
          coursesRequestCommentPageSize={coursesRequestCommentPageSize}
          coursesRequestCommentTotal={coursesRequestCommentTotal}
          hideLogin
        />
      )}

      {contextHolder}
    </div>
  );
};

export default GradeSkill;
