import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getAdminLogging, getLoggingAdminExport, getSystemAction } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Flex, Table, Tooltip } from 'antd';
import { Content } from '@/components/Layout';
import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons';

import Filters from './parts/Filters/Filters';

import Utils from '@/Utils';
import dayjs from 'dayjs';

import css from './LoggingAdmin.module.scss';

import { EXPORT_LOG_SYSTEM } from '@/constants/permissions';
import lock from '@/assets/images/lock.svg';

const DEFAULT_FILTERS = {
  from: dayjs().subtract(2, 'months').format('YYYY-MM-DD'),
  to: dayjs().format('YYYY-MM-DD'),
};

const MAX_ACTION_LENGTH = 24;
const MAX_LOGIN_LENGTH = 20;

const LoggingAdmin = () => {
  const { t } = useTranslation('loggingAdmin');
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUsers);
  const {
    loggingAdmin,
    sistemActionsList,
    loggingAdminPageSize,
    loggingAdminTotal,
    loggingAdminPageNumber,
    isLogSystem,
    isLoading,
  } = useSelector(selectTopics);

  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });

  const [submitedFilters, setSubmitedFilters] = useState({ ...DEFAULT_FILTERS });

  const [filterIsEdited, setFilterIsEdited] = useState(false);

  const onChangeFilter = (name, value) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value,
    }));

    setFilterIsEdited(true);
  };

  const onSubmitFilters = () => {
    dispatch(getAdminLogging(filters, 0));
    setSubmitedFilters(filters);
    setFilterIsEdited(false);
  };

  const onClearFilter = async () => {
    setFilters({ ...DEFAULT_FILTERS });
    dispatch(getAdminLogging({ ...DEFAULT_FILTERS }, 0));
    setSubmitedFilters(filters);
    setFilterIsEdited(false);
  };

  useEffect(() => {
    dispatch(getAdminLogging(filters, 0));
    dispatch(getSystemAction());
  }, []);

  const exportLogging = () => {
    const date = new Date();
    const fileName = `loggingAdmin-${date.toISOString().replace(/\./g, '')}.csv`;

    dispatch(
      getLoggingAdminExport(fileName, submitedFilters, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  const getTooltip = (person, item) =>
    item?.[person] && item?.[person]?.status === 'DELETED' ? (
      <Tooltip title={t('deleted')} placement='topLeft'>
        <img key='deleteUser' src={lock} alt='' />
      </Tooltip>
    ) : (
      item[person] &&
      item[person].moved && (
        <Tooltip title={t('newCompany')} placement='topLeft'>
          <ArrowRightOutlined />
        </Tooltip>
      )
    );

  const columns = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      width: '100px',
      render: val => dayjs(val).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: t('respond2'),
      dataIndex: 'action',
      key: 'action',
      width: '200px',
      render: item => {
        const action = sistemActionsList && sistemActionsList.find(actionItm => item === actionItm.name);

        return (
          <Tooltip title={action?.value && action?.value.length >= MAX_ACTION_LENGTH && action?.value}>
            {action?.value && Utils.trString(action?.value, MAX_ACTION_LENGTH)}
          </Tooltip>
        );
      },
    },
    {
      title: t('previous'),
      dataIndex: 'previous',
      key: 'previous',
    },
    {
      title: t('new'),
      dataIndex: 'new',
      key: 'new',
      width: '240px',
    },
    {
      title: t('goal'),
      dataIndex: 'target',
      key: 'target',
      width: '200px',
      render: item => {
        if (item.target && item.type !== 'EDIT_CUSTOM_FIELD_VALUE') {
          return (
            <div>
              <Tooltip title={item.target} placement='topLeft'>
                {item.target}
              </Tooltip>
            </div>
          );
        } else if (item.target && item.type === 'EDIT_CUSTOM_FIELD_VALUE') {
          return (
            <div>
              <Tooltip title={item?.recipient?.login} placement='topLeft'>
                {(item?.recipient?.lastName || '') + ' ' + (item?.recipient?.firstName || '').trim()}
                <br />
                {item?.recipient?.login}
              </Tooltip>
            </div>
          );
        } else if (!item.target && item.recipient) {
          return (
            <div>
              <Tooltip title={`${item.recipient.login}`} placement='topLeft'>
                {`${item.recipient.lastName} ${item.recipient.firstName} `} <br />
                {getTooltip('recipient', item)}{' '}
                {item.recipient.login &&
                  (item.recipient.login.length > 20
                    ? `${item.recipient.login.substring(0, 20)}...`
                    : item.recipient.login)}
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      title: t('respondent'),
      dataIndex: 'sender',
      key: 'sender',
      width: '200px',
      render: item => {
        return (
          item.sender && (
            <Tooltip title={`${item.sender.login}`} placement='topLeft'>
              {`${item.sender.lastName} ${item.sender.firstName} `} <br />
              {getTooltip('sender', item)}{' '}
              {item.sender.login.length > MAX_LOGIN_LENGTH
                ? Utils.trString(item.sender.login, MAX_LOGIN_LENGTH)
                : item.sender.login}
            </Tooltip>
          )
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    return loggingAdmin.map(log => ({
      key: log?.id,
      date: log?.createdTime,
      action: log?.type,
      previous: log?.oldValue,
      new: log?.curValue,
      target: log,
      sender: log,
    }));
  }, [loggingAdmin]);

  const pagination = {
    total: loggingAdminTotal,
    pageSize: loggingAdminPageSize,
    current: loggingAdminPageNumber + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const onChangePagination = ({ current }) => dispatch(getAdminLogging(submitedFilters, current - 1));

  return (
    <Content>
      <h1>{t('login')}</h1>
      <Filters
        filters={filters}
        onChangeFilter={onChangeFilter}
        onClearFilter={onClearFilter}
        onSubmitFilters={onSubmitFilters}
      />
      <Flex align='center' justify='flex-end'>
        {currentUser.authorities.includes(EXPORT_LOG_SYSTEM) && (
          <Tooltip title={!filters?.from ? t('fromTooltip') : filterIsEdited ? t('submitTooltip') : false}>
            <Button
              className={css['LoggingAdmin-export']}
              onClick={exportLogging}
              type='primary'
              disabled={filterIsEdited || !filters?.from}
              loading={isLogSystem}
              size='large'
              icon={<DownloadOutlined />}
            >
              {t('download')}
            </Button>
          </Tooltip>
        )}
      </Flex>
      <div className={css['LoggingAdmin-table']}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
          onChange={onChangePagination}
          loading={isLoading}
        />
      </div>
    </Content>
  );
};

export default LoggingAdmin;
