import {
  ADD_CURRENT_MENTORS,
  CLEAR_IPR_DETAILS_SUCCESS,
  CLEAR_IPR_FILTERS,
  CONFIRM_IPR_FAIL,
  CONFIRM_IPR_START,
  CONFIRM_IPR_SUCCESS,
  EXPORT_IPRS_FAIL,
  EXPORT_IPRS_START,
  EXPORT_IPRS_SUCCESS,
  FINISHED_IPR_FAIL,
  FINISHED_IPR_START,
  FINISHED_IPR_SUCCESS,
  GET_IPR_DETAILS_FAIL,
  GET_IPR_DETAILS_START,
  GET_IPR_DETAILS_SUCCESS,
  GET_IPR_GOAL_CATEGORY_FAIL,
  GET_IPR_GOAL_CATEGORY_START,
  GET_IPR_GOAL_CATEGORY_SUCCESS,
  GET_IPR_SETTINGS_FAIL,
  GET_IPR_SETTINGS_START,
  GET_IPR_SETTINGS_SUCCESS,
  GET_IPR_STATUS_FAIL,
  GET_IPR_STATUS_START,
  GET_IPR_STATUS_SUCCESS,
  GET_MENTORS_IPR_FAIL,
  GET_MENTORS_IPR_START,
  GET_MENTORS_IPR_SUCCESS,
  POST_IPR_FILTERS_FAIL,
  POST_IPR_FILTERS_START,
  POST_IPR_FILTERS_SUCCESS,
  REJECTED_IPR_FAIL,
  REJECTED_IPR_START,
  REJECTED_IPR_SUCCESS,
  SAVE_IPR_FILTERS,
  SAVE_IPR_SORT,
  UPDATE_SETTINGS_IPR_FAIL,
  UPDATE_SETTINGS_IPR_START,
  UPDATE_SETTINGS_IPR_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// получение менторов для ИПР
export const getIprMentors = (params, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/admin/available${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTORS_IPR_START, GET_MENTORS_IPR_SUCCESS, GET_MENTORS_IPR_FAIL],
  onSuccess,
  onError,
});

// получение данных в таблицу ИПР
export const postIprFilters = (params, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan/admin${getQueryString({ size: 20, page, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_IPR_FILTERS_START, POST_IPR_FILTERS_SUCCESS, POST_IPR_FILTERS_FAIL],
  onSuccess,
  onError,
});

// получение статусов ИПР
export const getIprStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/career-plan/admin/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_IPR_STATUS_START, GET_IPR_STATUS_SUCCESS, GET_IPR_STATUS_FAIL],
  onSuccess,
  onError,
});

// получение ИПР единичного
export const getIprDetails = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/career-plan/admin/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_IPR_DETAILS_START, GET_IPR_DETAILS_SUCCESS, GET_IPR_DETAILS_FAIL],
  onSuccess,
  onError,
});

// вернуть на доработку ИПР
export const rejectedIpr = (id, params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan/admin/${id}/reject`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [REJECTED_IPR_START, REJECTED_IPR_SUCCESS, REJECTED_IPR_FAIL],
  onSuccess,
  onError,
});

// согласовать ИПР
export const confirmIpr = (id, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan/admin/${id}/approve`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [CONFIRM_IPR_START, CONFIRM_IPR_SUCCESS, CONFIRM_IPR_FAIL],
  onSuccess,
  onError,
});

// завершить ИПР
export const finishedIpr = (id, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan/admin/${id}/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [FINISHED_IPR_START, FINISHED_IPR_SUCCESS, FINISHED_IPR_FAIL],
  onSuccess,
  onError,
});

// экспортировать данные из таблицы ИПР
export const exportIprs = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan/admin/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_IPRS_START, EXPORT_IPRS_SUCCESS, EXPORT_IPRS_FAIL],
  onSuccess,
  onError,
});

// получение Категория цели
export const getIprGoalCategory = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/career-plan/goalcategory`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_IPR_GOAL_CATEGORY_START, GET_IPR_GOAL_CATEGORY_SUCCESS, GET_IPR_GOAL_CATEGORY_FAIL],
  onSuccess,
  onError,
});

export const getIprSettings = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/company/settings/ipr`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_IPR_SETTINGS_START, GET_IPR_SETTINGS_SUCCESS, GET_IPR_SETTINGS_FAIL],
  onSuccess,
  onError,
});

export const updateSettingsIpr = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/company/settings/ipr`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [UPDATE_SETTINGS_IPR_START, UPDATE_SETTINGS_IPR_SUCCESS, UPDATE_SETTINGS_IPR_FAIL],
  onSuccess,
  onError,
});

export const clearIprDetails = () => ({
  type: CLEAR_IPR_DETAILS_SUCCESS,
});

// сохранить сортировку наград
export const saveIprSort = value => ({
  type: SAVE_IPR_SORT,
  value,
});

// сохранение фильтров ипр
export const saveIprFilters = value => ({
  type: SAVE_IPR_FILTERS,
  value,
});

// удалить фильтры ипр
export const clearIprFilters = () => ({
  type: CLEAR_IPR_FILTERS,
});

// добавить менторов при первом загрузе
export const addCurrentMentors = value => ({
  type: ADD_CURRENT_MENTORS,
  value,
});
