/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { changeUserPassword } from '@/store/users/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Form } from 'antd';
import Input from '@/components/Input';
import { Card, Content } from '@/components/Layout';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ProfileSettings.module.scss';

export class ProfileSettings extends Component {
  static propTypes = {
    changeUserPassword: PropTypes.func,
    currentUser: PropTypes.object,
    userError: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { password } = this.state;
    if (password) {
      this.setState({ passwordError: '' });
      this.props.changeUserPassword(password, this.props.currentUser, () => {
        this.setState({ password: '' });
      });
    } else {
      this.setState({ passwordError: this.props.t('require') });
    }
  };

  render() {
    const apiError = this.props.userError;
    const apiErrorText = apiError ? this.props.t('fail') : '';

    return (
      <Content>
        <h1>{this.props.t('change')}</h1>
        <Card>
          <Form>
            <Form.Item
              label={this.props.t('newOne')}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 10 }}
              colon={false}
              className={classNames({
                error: this.state.passwordError || apiError,
              })}
              help={this.state.passwordError || apiErrorText}
            >
              <Input
                type='password'
                placeholder={this.props.t('password')}
                name='password'
                value={this.state.password}
                onChange={this.handleChange}
                className={css.input}
              />
            </Form.Item>
            <Button onClick={this.handleSubmit} htmlType='submit'>
              {this.props.t('edit')}
            </Button>
          </Form>
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = createSelector(usersSelect, adminuUsers => ({
  currentUser: adminuUsers.currentUser || '',
  userError: adminuUsers.error,
}));

const mapActionsToProps = {
  changeUserPassword,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('profileSettings')(ProfileSettings));
