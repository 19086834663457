export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_WAITING_MODERATE = 'WAITING_MODERATE';
export const STATUS_DELETED = 'DELETED';

export const IN_ACTIVE = 'IN_ACTIVE';

export const WAITING_MODERATE = 'WAITING_MODERATE';
export const REJECTED_BY_ADMIN = 'REJECTED_BY_ADMIN';
export const DELETED = 'DELETED';

export const EDIT = 'EDIT';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const MODERATE = 'MODERATE';

export const typeOpt = t => [
  { id: 1, type: 'TITLE', name: t('typeName') },
  { id: 2, type: 'TAG', name: t('typeTag') },
];

export const ERROR = 'error';
export const SUCCESS = 'success';
export const WARNING = 'warning';

export const MAX_CHAR_NEWS = 5000;

export const ON_CREATE = 'ON_CREATE';
