/* eslint-disable */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown } from 'antd';

import PropTypes from 'prop-types';

export const IPDDropdown = ({ toggleUsersIndividualTrajectory, disabled }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('IPDDropdown');

  const trajectoryHandler = status => {
    toggleUsersIndividualTrajectory(status);
    setVisible(false);
  };

  const onClickHandler = useCallback(() => setVisible(!visible), [visible]);

  const itemsMenu = [
    {
      key: '1',
      label: t('enable'),
      onClick: () => trajectoryHandler(true),
    },
    {
      key: '2',
      label: t('disable'),
      onClick: () => trajectoryHandler(false),
    },
  ];

  return (
    <Dropdown
      menu={{ items: itemsMenu }}
      placement='bottomLeft'
      trigger={['click']}
      onOpenChange={onClickHandler}
      open={visible}
      disabled={disabled}
    >
      <Button>{t('all')}</Button>
    </Dropdown>
  );
};

IPDDropdown.propTypes = {
  toggleUsersIndividualTrajectory: PropTypes.func,
  disabled: PropTypes.bool,
};
