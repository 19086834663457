/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { D360GetFilterUsersAuto } from '@/store/d360/d360_filters/actions';

import { Col, InputNumber, Modal, Progress, Row } from 'antd';

import PropTypes from 'prop-types';

import css from '../../css/index.module.scss';

const EmptyFilter = {
  managers: 0,
  colleagues: 0,
  subordinates: 0,
  cross: 0,
};

const EmptyProgress = {
  total: 0,
  finished: 0,
  percent: 0,
};

export const AutoAssigner = props => {
  const [disabledFindButton, setDisabledFindButton] = useState(true);
  const [filter, setFilter] = useState(EmptyFilter);
  const [projectId, setProjectId] = useState(null);
  const [progress, setProgress] = useState(EmptyProgress);
  const { t } = useTranslation('D360_AutoAssigner');

  /**
   * Отслеживаем изменения filter
   */
  useEffect(() => {
    if (filter.managers > 0 || filter.colleagues > 0 || filter.subordinates > 0 || filter.cross > 0) {
      return setDisabledFindButton(false);
    }

    setDisabledFindButton(true);
  }, [filter]);

  /**
   * Отслеживаем прогресс.
   */
  useEffect(() => {
    if (progress.total > 0 && progress.total === progress.finished) {
      if (props && props.onComplete && typeof props.onComplete === 'function') {
        props.onComplete();
      }
    }
  }, [progress]);

  /**
   * Отслеживаем изменения props.project
   */
  useEffect(() => {
    if (props && props.project.id && props.project.id > 0) {
      setProjectId(props.project.id);
    }
  }, [props.project]);

  /**
   *
   */
  useEffect(() => {
    if (!props.open) {
      setFilter(EmptyFilter);
      setDisabledFindButton(true);
      setProgress(0);
    }
  }, [props.open]);

  /**
   * Пользователь нажал на кнопку "Отмена"
   */
  const onCancel = () => {
    if (props && props.onCancel && typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const onSubmit = () => {
    setProgress(EmptyProgress);
    let total = 0;
    let finished = 0;
    let percent = 0;

    if (props.measurables && Array.isArray(props.measurables)) {
      total = props.measurables.length;
      for (let userId of props.measurables) {
        props.D360GetFilterUsersAuto(projectId, userId, filter, () => {
          finished++;
          percent = (finished * 100) / total;

          setProgress(prevState => ({
            ...prevState,
            total: total,
            finished: finished,
            percent: Number.parseInt(percent, 10),
          }));
        });
      }
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <Modal
        width={600}
        title={t('title')}
        open={props.open}
        onCancel={onCancel}
        onOk={onSubmit}
        okButtonProps={{ disabled: disabledFindButton }}
        okText={t('findAndAssign')}
      >
        <div className={css.Filters}>
          <Row className={css.FiltersRow}>
            <Col span={8} className={css.FiltersCol}>
              {t('colManagers')}
            </Col>
            <Col span={4} className={css.FiltersCol}>
              <InputNumber
                min={0}
                max={20}
                value={filter.managers}
                style={{ width: '100%' }}
                onChange={e => setFilter({ ...filter, managers: e })}
              />
            </Col>

            <Col span={8} className={css.FiltersCol}>
              {t('colColleagues')}
            </Col>
            <Col span={4} className={css.FiltersCol}>
              <InputNumber
                min={0}
                max={20}
                value={filter.colleagues}
                style={{ width: '100%' }}
                onChange={e => setFilter({ ...filter, colleagues: e })}
              />
            </Col>
          </Row>
          <Row className={css.FiltersRow}>
            <Col span={8} className={css.FiltersCol}>
              {t('colSubordinates')}
            </Col>
            <Col span={4} className={css.FiltersCol}>
              <InputNumber
                min={0}
                max={20}
                value={filter.subordinates}
                style={{ width: '100%' }}
                onChange={e => setFilter({ ...filter, subordinates: e })}
              />
            </Col>
            <Col span={8} className={css.FiltersCol}>
              {t('colOther')}
            </Col>
            <Col span={4} className={css.FiltersCol}>
              <InputNumber
                min={0}
                max={20}
                value={filter.cross}
                style={{ width: '100%' }}
                onChange={e => setFilter({ ...filter, cross: e })}
              />
            </Col>
          </Row>
          <Row className={css.FiltersRow}>
            <Col span={24} align='left'>
              <Progress
                min={0}
                max={100}
                percent={progress.percent}
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  return render();
};

AutoAssigner.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  measurables: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  D360GetFilterUsersAuto,
};

/**
 * Экспорт компонента.
 */
export default connect(mapStateToProps, mapDispatchToProps)(AutoAssigner);
