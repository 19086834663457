/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Button, Modal } from 'antd';
import ImportInfoDialog from '@/pages/Users/ImportInfoDialog';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './Dictionary.module.scss';

class DictionaryImportDialog extends Component {
  static propTypes = {
    fileDescription: PropTypes.string,
    onOk: PropTypes.func,
    getData: PropTypes.func,
    onCancel: PropTypes.func,
    description1: PropTypes.string,
    exampleFileFunc: PropTypes.func, // функция пропс для скачивания примера
    open: PropTypes.bool,
    isTopics: PropTypes.bool, // вызвали из Топиков
    isBadge: PropTypes.bool,
    isPokaPosition: PropTypes.bool, // вызвали из Poka Справочник Типовых должностей
  };

  constructor(props) {
    super(props);
    this.state = {
      showResult: false,
      importModal: false,
      errorsFile: [],
      errors: {},
      file: null,
      fileName: '',
      lock: false,
    };
    this.input = null;
  }

  blobTemplate = (blob, headers) => {
    const fileName = this.props.isPokaPosition
      ? headers.get('Content-Disposition')?.split('=')?.[1]?.replace(/"/g, '') || 'file'
      : headers.get('Content-Disposition')?.split('=')?.[1] || 'file';

    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  };

  onSuccess = data => {
    this.setState({ showResult: true, errorsFile: data });
    if (this.props.getData) {
      this.props.getData('', 0, null);
    }
  };

  onError = error => {
    this.setState({ showResult: true, errors: error });
  };

  setInputRef = input => {
    input && input.setAttribute('accept', '.csv');
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  handleFileUpload = () => {
    if (this.state.file) {
      this.setState({ lock: true });
      this.props.onOk(
        this.state.file,
        data => this.onSuccess(data),
        error => this.onError(error)
      );
    }
  };

  handleFileChange = event => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    reader.onloadend = () => {
      this.setState({
        file: formData,
        fileName: file.name,
        importModal: true,
      });
    };
    reader.readAsDataURL(file);
    event.target.value = null;
  };

  render() {
    const { fileDescription, onCancel, t, description1, open, isTopics, isPokaPosition } = this.props;

    return !this.state.showResult ? (
      !this.state.importModal ? (
        <Modal
          title={t('importFile')}
          open={open}
          onCancel={onCancel}
          className={css['DictionaryItem-modal']}
          width={isPokaPosition ? 800 : 500}
          centered={isPokaPosition}
          footer={null}
        >
          <span className={css['ImportFile-body']}>{description1}</span>
          {isTopics && (
            <>
              <br /> {t('infoStudyPlan')}
            </>
          )}

          <div className={css['ImportFile-footer']}>
            <Button onClick={this.openFileDialog} type='primary'>
              {t('select')}
            </Button>
            <input
              type='file'
              id='file-upload'
              data-qa='importFileInput'
              ref={this.setInputRef}
              onChange={this.handleFileChange}
            />
            <div className={isPokaPosition ? css['ImportFile-footer-link-poka'] : css['ImportFile-footer-link']}>
              <a
                className={css['ImportFile-footer-downloadButton']}
                onClick={() => this.props.exampleFileFunc(this.blobTemplate)}
              >
                {fileDescription}
              </a>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          title={t('importSelected')}
          open={open}
          onCancel={onCancel}
          onOk={this.handleFileUpload}
          className={css['DictionaryItem-modal']}
          width={isPokaPosition ? 800 : 584}
          centered={isPokaPosition}
          dataQa='importDictionaryItemConfirmBtn'
          footer={null}
        >
          <div className={css['ModalFlower-body']}>
            <div className={css['ModalFlower-body-blockButton']}>{this.state.fileName}</div>
          </div>
          <div className={css['ModalFlower-body-actions']}>
            <Button onClick={this.handleFileUpload} disabled={this.state.lock} data-qa='saveCategoryBtn' type='primary'>
              {t('import')}
            </Button>
            <div className={css['ModalFlower-body-actions-cancel']}>
              <Button onClick={onCancel}>{t('cancel')}</Button>
            </div>
          </div>
        </Modal>
      )
    ) : (
      <ImportInfoDialog
        open={open}
        onCancel={onCancel}
        importInfo={this.state.errorsFile}
        isBadge={this.props.isBadge}
        isPokaPosition={isPokaPosition}
        errorsInfo={this.state.errors}
      />
    );
  }
}

const mapActionToProps = {};

const mapStateToProps = createSelector([], () => ({}));

export default connect(
  mapStateToProps,
  mapActionToProps
)(withTranslation('dictionaryImportDialog')(DictionaryImportDialog));
