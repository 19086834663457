import {
  CLEAR_CERTIFICATES,
  CREATE_CERTIFICATE_FAIL,
  CREATE_CERTIFICATE_START,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_START,
  CREATE_TEMPLATE_SUCCESS,
  defaultToken,
  DELETE_CERTIFICATE_FAIL,
  DELETE_CERTIFICATE_START,
  DELETE_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_FAIL,
  EDIT_CERTIFICATE_START,
  EDIT_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_FAIL,
  GET_CERTIFICATE_START,
  GET_CERTIFICATE_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
  headers,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

// eslint-disable-next-line
export const getTemplates = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/certificate/template`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TEMPLATES_START, GET_TEMPLATES_SUCCESS, GET_TEMPLATES_FAIL],
  onSuccess,
});

export const createTemplate = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/certificate/template`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_TEMPLATE_START, CREATE_TEMPLATE_SUCCESS, CREATE_TEMPLATE_FAIL],
  onSuccess,
  onError,
});

export const getCertificates = (name, page = 0, size = 100, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/certificate${getQueryString({
    name,
    size,
    sort: 'name,asc',
    page: Number(page),
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CERTIFICATE_START, GET_CERTIFICATE_SUCCESS, GET_CERTIFICATE_FAIL],
  onSuccess,
});

export const createCertificate = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/certificate`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_CERTIFICATE_START, CREATE_CERTIFICATE_SUCCESS, CREATE_CERTIFICATE_FAIL],
  onSuccess,
  onError,
});

export const updateCertificate = (id, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/certificate/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [EDIT_CERTIFICATE_START, EDIT_CERTIFICATE_SUCCESS, EDIT_CERTIFICATE_FAIL],
  onSuccess,
  onError,
});

export const previewCertificate = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/certificate`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [EDIT_CERTIFICATE_START, EDIT_CERTIFICATE_SUCCESS, EDIT_CERTIFICATE_FAIL],
  onSuccess,
  onError,
});

export const deleteCertificate = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/certificate/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_CERTIFICATE_START, DELETE_CERTIFICATE_SUCCESS, DELETE_CERTIFICATE_FAIL],
  onSuccess,
  onError,
});

export const clearCertificates = () => ({
  type: CLEAR_CERTIFICATES,
});
