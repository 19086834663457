/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Avatar, Button, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { CloseOutlined } from '@ant-design/icons';

import PortraitPlaceholder from '../Header/PortraitPlaceholder.png';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css2 from '../Users/Users.module.scss';
import css from './EventUserList.module.scss';

import lock from '@/assets/images/lock.svg';

class EventUser extends Component {
  static propTypes = {
    courseRequestFinish: PropTypes.func,
    coursesFilter: PropTypes.object,
    coursesPageNumber: PropTypes.number,
    deleteUserEventHandler: PropTypes.func,
    filterYear: PropTypes.string,
    getCoursesRequestTopic: PropTypes.func,
    getEventTopic: PropTypes.func,
    getFile: PropTypes.func,
    isEnded: PropTypes.bool,
    isLoading: PropTypes.bool,
    item: PropTypes.number,
    updateCoursesRequest: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: '',
      confirmRejectUserModal: false,
    };
  }

  componentDidMount() {
    if (this.props.user.plan.user.localPhotoUuid || this.props.user.plan.user.photoUuid) {
      this.props.getFile(this.props.user.plan.user.localPhotoUuid || this.props.user.plan.user.photoUuid, res => {
        this.setState({ imageSrc: URL.createObjectURL(res) });
      });
    } else {
      this.setState({ imageSrc: PortraitPlaceholder });
    }
  }

  changeCoursesStatus = (event, id) => {
    this.props.updateCoursesRequest(event, '', [id], () => {
      this.props.getCoursesRequestTopic({
        topicId: this.props.user.plan.topic.id,
        ...this.props.coursesFilter,
        page: this.props.coursesPageNumber,
      });
      this.props.getEventTopic(this.props.user.plan.topic.id, {
        year: this.props.filterYear,
        isEnded: this.props.isEnded,
      });
    });
  };

  courseRequestFinish = course => {
    const ids = course.plan.id;
    this.props.courseRequestFinish(
      ids,
      false,
      () => {
        this.props.getCoursesRequestTopic({
          topicId: this.props.user.plan.topic.id,
          ...this.props.coursesFilter,
          page: this.props.coursesPageNumber,
        });
        this.props.getEventTopic(this.props.user.plan.topic.id, {
          year: this.props.filterYear,
          isEnded: this.props.isEnded,
        });
        this.hideConfirmRejectUser();
      },
      () => {
        this.hideConfirmRejectUser();
      }
    );
  };

  confirmRejectUser = () => {
    this.setState({ confirmRejectUserModal: true });
  };

  hideConfirmRejectUser = () => {
    this.setState({ confirmRejectUserModal: false });
  };

  render() {
    const { user, t } = this.props;
    let status = '';
    switch (user.plan.status) {
      case 'FINISHED':
        status = t('review');
        break;
      case 'EXPIRED':
        status = t('completed');
        break;
      case 'IN_PROGRESS':
        status = t('inProgress');
        break;
      case 'WAS_HANDLE':
      case 'APPROVED_BY_USER':
        status = t('confirmed');
        break;
      case 'ON_REGISTRATION':
        status = t('inRegistration');
        break;
      case 'WAITING_BY_USER':
      default:
        status = t('pending');
        break;
    }
    const DELETED_USER = user && user.status === 'DELETED';

    return (
      <tr key={user.id}>
        <Tooltip title={`${user.plan.user.lastName} ${user.plan.user.firstName}`}>
          <td>
            <div className={css.Avatar}>
              <Avatar className={classNames({ [css2.DeleteUser]: DELETED_USER })} src={this.state.imageSrc} />
              {DELETED_USER && (
                <Tooltip title={t('accountDeleted')}>
                  <div className={classNames(css2['DeleteUser-key'], css2['DeleteUser-key-fix6'])}>
                    <img key='deleteUser' src={lock} alt='' />
                  </div>
                </Tooltip>
              )}
              <div className={css['Avatar-status']}>
                <div className={css['Avatar-status-name']}>{`${user.plan.user.lastName || ''} ${
                  user.plan.user.firstName || ''
                }`}</div>
                <div className={css['Avatar-status-label']}>{status}</div>
              </div>
            </div>
          </td>
        </Tooltip>
        <td>
          {status === t('inRegistration') ? (
            <Button
              disabled={this.props.isLoading}
              onClick={() => this.changeCoursesStatus('APPROVED_BY_USER', user.id)}
              className={css.RegisterButton}
              type='primary'
              ghost
            >
              {t('confirmRegistration')}
            </Button>
          ) : null}
          {status === t('completed') ? (
            <Button
              disabled={this.props.isLoading}
              onClick={this.confirmRejectUser}
              className={css.RegisterButton}
              type='primary'
              ghost
            >
              {t('didntGo')}
            </Button>
          ) : null}
        </td>
        <td>
          <CloseOutlined
            onClick={() =>
              this.props.deleteUserEventHandler(user.id, user.plan && user.plan.status, user.plan && user.plan.topic.id)
            }
          />
        </td>
        {this.state.confirmRejectUserModal ? (
          <ConfirmationModal
            open
            onOk={() => this.courseRequestFinish(user, false)}
            onCancel={this.hideConfirmRejectUser}
            onCancelButton={this.hideConfirmRejectUser}
            okText={t('yes')}
            cancelText={t('no')}
            confirmationText={t('description')}
          />
        ) : null}
      </tr>
    );
  }
}

export default withTranslation('eventUser')(EventUser);
