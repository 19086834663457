import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeTemplateById, createTemplateById, getTemplates } from '@/store/messages/actions';

import { selectMessages } from '@/store/messages/selectors';
import { selectUsers } from '@/store/users/selectors';

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Flex,
  Form,
  Modal,
  notification,
  Row,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import QuillEditor from '@shared/components/QuillEditor';

import deepEqual from 'deep-equal';
import { cloneDeep, isEmpty, merge, some } from 'lodash';
import PropTypes from 'prop-types';

import css from './MessageDetails.module.scss';

const { Group: CheckboxGroup } = Checkbox;

const parser = new DOMParser();

const MAIL_VARIABLES = {
  indexUrl: '{{ index_url }}',
  pictureUrl: '{{ picture_url }}',
  headerImg: '{{ header_img }}',
};

const MAIL_ELEMENTS = {
  body: '#### BODY ####',
  info: '#### INFO ####',
  signature: '#### SIGNATURE ####',
};

const MAX_CHAR_BODY = 1000;
const MAX_CHAR_SIGNATURE = 1000;

const MessageDetails = ({ open, title, onClose, filters }) => {
  const { t } = useTranslation('messageDetails');
  const dispatch = useDispatch();

  const { channels, template, loadingTemplate } = useSelector(selectMessages);
  const { currentUser } = useSelector(selectUsers);

  // Табы с текстом
  const [modal, contextHolder] = Modal.useModal();
  const [notificationApi, notificationHolder] = notification.useNotification();

  const [isChangedHtml, setIsChangedHtml] = useState(false);
  const [previewData, setPreviewData] = useState(cloneDeep(template.content));
  const initContent = useRef(cloneDeep(template.content));

  // Чекбоксы для выбора каналов
  const [isChangedChannels, setIsChangedChannels] = useState(false);
  const [activeChannels, setActiveChannels] = useState([]);
  const [activeTab, setActiveTab] = useState('ru');
  const initChannels = useRef([]);

  const onChangeChannels = list => {
    setActiveChannels(list);
    setIsChangedChannels(list.join() !== initChannels.current.join());
  };

  const onChangeText = (language, type, html, text) => {
    const newPreviewData = merge(previewData, { [language]: { [`${type}Html`]: html, [`${type}Text`]: text } });
    setPreviewData({ ...newPreviewData });

    setIsChangedHtml(!deepEqual(initContent.current, newPreviewData));
  };

  const onEdit = () => {
    if (template?.global && currentUser?.domainCompany?.global) {
      modal.confirm({
        title: t('globalTitle'),
        content: t('content', { theme: template?.theme }),
        onOk: editTemplate,
        okText: t('ok'),
      });
    } else {
      editTemplate();
    }
  };

  const onTemplateSuccess = () => {
    notificationApi.success({
      message: t('editSuccess'),
      placement: 'bottomRight',
      className: css['MessageDetails-message'],
    });
    onClose(false);

    // Если есть значение в фильтрах, то нужно обновлять таблицу
    if (some(filters, value => !isEmpty(value))) {
      dispatch(getTemplates(filters));
    }
  };

  const editTemplate = () => {
    const sendData = {
      activeChannels,
      localizations: previewData,
    };

    if (template?.canUpdate) {
      dispatch(changeTemplateById(template?.messageTemplateId, sendData, () => onTemplateSuccess()));
    } else {
      dispatch(createTemplateById(template?.messageTemplateId, sendData, () => onTemplateSuccess()));
    }
  };

  const onChangeTab = e => setActiveTab(e);

  const channelsOptions = channels.map(channel => ({
    label: channel?.localizedName,
    value: channel?.channelType,
  }));

  const checkAll = channels.map(e => e.channelType);

  const indeterminate = activeChannels.length > 0 && activeChannels.length < channelsOptions.length;

  const languages = Object.keys(template.content);

  const messageTextItems = languages.map(item => ({
    key: item,
    label: item.toUpperCase(),
    children: (
      <QuillEditor
        Content={template?.content[item].bodyHtml}
        onChange={(html, text) => onChangeText(item, 'body', html, text)}
        placeholder={t('bodyPlaceholder')}
        maxChar={MAX_CHAR_BODY}
        textMaxChar={t('maxChar')}
        typeToolbar='short'
        showFooter
      />
    ),
  }));

  const messageSignatureItems = languages.map(item => ({
    key: item,
    label: item.toUpperCase(),
    children: (
      <QuillEditor
        Content={template?.content[item].signatureHtml}
        onChange={(html, text) => onChangeText(item, 'signature', html, text)}
        maxChar={MAX_CHAR_SIGNATURE}
        textMaxChar={t('maxChar')}
        placeholder={t('signaturePlaceholder')}
        typeToolbar='short'
        showFooter
      />
    ),
  }));

  const previewPanes = languages.map(language => {
    // Объект с новыми значениями для замены
    const replacements = {
      [MAIL_VARIABLES.indexUrl]: template?.variables?.indexUrl,
      [MAIL_VARIABLES.pictureUrl]: template?.imgUuid,
      [MAIL_VARIABLES.headerImg]: template?.variables?.headerImg,
    };

    const regex = new RegExp(Object.keys(replacements).join('|'), 'g');

    const htmlTemplate = document.createElement('html');
    htmlTemplate.innerHTML = template?.html[language].replace(regex, match => replacements[match]);

    const treeWalker = document.createTreeWalker(htmlTemplate, NodeFilter.SHOW_COMMENT);

    while (treeWalker.nextNode()) {
      const node = treeWalker.currentNode;

      switch (node.nodeValue.trim()) {
        case MAIL_ELEMENTS.body:
          const bodyElement = parser.parseFromString(previewData[language].bodyHtml, 'text/html').body;
          node.parentNode.appendChild(bodyElement);
          break;

        case MAIL_ELEMENTS.info:
          const infoElement = parser.parseFromString(template?.content?.[language]?.infoHtml, 'text/html').body;
          const infoContainer = document.createElement('div');

          infoContainer.innerHTML = infoElement.outerHTML;
          node.parentNode.insertBefore(infoContainer, node.nextSibling);
          break;

        case MAIL_ELEMENTS.signature:
          const signatureElement = parser.parseFromString(previewData[language].signatureHtml, 'text/html').body;
          node.parentNode.appendChild(signatureElement);
          break;

        default:
          break;
      }
    }

    return {
      key: language,
      label: language.toUpperCase(),
      children: <iframe className={css.iframe} sandbox='allow-same-origin' srcDoc={htmlTemplate.outerHTML} />,
    };
  });

  useEffect(() => {
    if (template?.channels) {
      const correctChannels = template.channels.filter(item => item.active).map(item => item.channelType);
      initChannels.current = correctChannels;
      setActiveChannels(correctChannels);
    }
  }, [template]);

  return (
    <Drawer
      open={open}
      onClose={() => onClose(isChangedChannels || isChangedHtml)}
      width={1200}
      title={t('title', { name: title })}
      className={css['MessageDetails']}
    >
      {/* Модальное окно confirm */}
      {contextHolder}

      {/* Уведомления */}
      {notificationHolder}

      <Spin spinning={loadingTemplate}>
        <Form layout='vertical'>
          {/*  Выбор каналов */}
          <Form.Item colon={false} label={t('channelsForMessages')}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={() => onChangeChannels(activeChannels.length === 0 ? checkAll : [])}
              checked={channelsOptions.length === activeChannels.length}
            >
              {t('checkAll')}
            </Checkbox>
            <Divider />
            <Flex justify='space-between'>
              <CheckboxGroup options={channelsOptions} value={activeChannels} onChange={onChangeChannels} />
              {template?.content?.[activeTab]?.description && (
                <Tooltip
                  placement='leftBottom'
                  overlayClassName={css.tooltipIcon}
                  color='#fff'
                  title={template.content[activeTab].description}
                >
                  <InfoCircleOutlined className={css.InfoCircleIcon} />
                </Tooltip>
              )}
            </Flex>
          </Form.Item>

          {/* Текст  */}
          <Form.Item colon={false} label={t('messageText')}>
            <Tabs activeKey={activeTab} onChange={onChangeTab} tabPosition='left' items={messageTextItems} />
          </Form.Item>
          <Form.Item colon={false} label={t('messageSignature')}>
            <Tabs activeKey={activeTab} onChange={onChangeTab} tabPosition='left' items={messageSignatureItems} />
          </Form.Item>

          {/* Превью  */}
          <Form.Item colon={false} label={t('messagePreview')}>
            <Tabs activeKey={activeTab} onChange={onChangeTab} tabPosition='left' items={previewPanes} />
          </Form.Item>

          {/* Кнопки сохранения */}
          <Divider />
          <Row gutter={20} type='flex' justify='end'>
            <Col>
              <Button onClick={() => onClose(isChangedChannels || isChangedHtml)}>{t('cancel')}</Button>
            </Col>
            <Col>
              <Tooltip title={t('fillAllFields')}>
                <Button
                  disabled={!isChangedChannels && !isChangedHtml}
                  onClick={onEdit}
                  type='primary'
                  loading={loadingTemplate}
                >
                  {t('edit')}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

MessageDetails.propTypes = {
  open: PropTypes.bool,
};

export default MessageDetails;
