/* eslint-disable */
import { GET_WEBINAR_STATS_FAIL, GET_WEBINAR_STATS_START, GET_WEBINAR_STATS_SUCCESS } from './constants';

const initialState = {
  stats: [],
  isLoading: false,
  pagination: {
    totalElements: 0,
    totalPages: 0,
    number: 0,
    size: 0,
  },
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // START
    case GET_WEBINAR_STATS_START:
      return {
        ...state,
        isLoading: true,
      };

    // SUCCESS
    case GET_WEBINAR_STATS_SUCCESS:
      return {
        ...state,
        stats: response.content,
        isLoading: false,
        pagination: {
          totalElements: response.totalElements,
          totalPages: response.totalPages,
          number: response.number,
          size: response.size,
        },
      };

    // FAIL
    case GET_WEBINAR_STATS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
};
