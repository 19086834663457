import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  createCertificate,
  deleteCertificate,
  getCertificates,
  getTemplates,
  previewCertificate,
  updateCertificate,
} from '@/store/certificate/actions';

import { selectCertificates } from '@/store/certificate/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Form, Input, message, Modal, Table, Tooltip } from 'antd';
// UI
import { Card, Content } from '@/components/Layout';
import { DeleteOutlined, FormOutlined, ZoomInOutlined } from '@ant-design/icons';

import CertificateModal from './CertificateModal';

import css from './TabCertificates.module.scss';

import { CERTIFICATES_READ, CERTIFICATES_UPDATE } from '@/constants/permissions';

const { Search } = Input;
const { Column } = Table;

const Certificates = () => {
  const dispatch = useDispatch();
  const { isLoading, total, pageSize, pageNumber, certificates, templates, isLoadingTemplate } =
    useSelector(selectCertificates);
  const { currentUser } = useSelector(selectUsers);
  const { t } = useTranslation('certificateTab');

  useEffect(() => {
    dispatch(getCertificates());
    dispatch(getTemplates());
  }, [dispatch]);

  const [certificate, setCertificate] = useState({
    id: null,
    name: '',
    templateId: null,
    type: '',
    description: '',
    base64Logo1: null,
    base64Logo2: null,
  });
  const [searchValue, setSearchValue] = useState('');
  const [editable, setEditable] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [certificateModalVisible, setCertificateModalVisible] = useState(false);
  const [previewHtml, setPreviewHtml] = useState({ name: '', content: '' });
  const [isPortrait, setIsPortrait] = useState(false);

  const onSubmit = certificateData => {
    if (certificateData?.key && Number(certificateData.key) > 0) {
      dispatch(
        updateCertificate(
          certificateData.key,
          certificateData,
          () => {
            message.success(t('successEdition'));
            setCertificateModalVisible(false);
            dispatch(getCertificates());
          },
          () => {
            message.error(t('errorEdition'));
          }
        )
      );
    } else {
      dispatch(
        createCertificate(
          certificateData,
          () => {
            setCertificateModalVisible(false);
            dispatch(getCertificates());
            message.success(t('successCreation'));
          },
          () => {
            message.error(t('errorCreation'));
          }
        )
      );
    }
  };

  const handleSearch = () => dispatch(getCertificates(searchValue, 0));
  const handleModalClose = () => setCertificateModalVisible(false);
  const handleChangePage = page => dispatch(getCertificates(searchValue, Number(page) - 1));
  const handleSearchChange = event => setSearchValue(event.target.value);

  const openCreateModal = () => {
    setCertificate({
      id: null,
      name: '',
      templateId: null,
      type: '',
      description: '',
      base64Logo1: null,
      base64Logo2: null,
    });
    setCertificateModalVisible(true);
    setEditable(false);
  };

  const editTemplate = record => {
    setCertificate(record); // для редактирования
    setCertificateModalVisible(true);
    setEditable(true);
  };

  const openDeleteTemplateModal = record => {
    setDeleteDialogVisible(true);
    setCertificate({ ...record, id: record.key });
  };

  const deleteTemplate = () => {
    dispatch(
      deleteCertificate(
        certificate.id,
        () => dispatch(getCertificates()),
        err => message.error(err.message)
      )
    );
    closeDeleteDialog();
  };

  const closeDeleteDialog = () => setDeleteDialogVisible(false);
  const closePreviewDialog = () => {
    setShowPreview(false);
    setPreviewHtml({ name: '', content: '' });
  };

  const onPreviewCertificate = record => {
    dispatch(
      previewCertificate(
        record,
        result => {
          setPreviewHtml({ name: record.name, content: result.content });
          setShowPreview(true);
          setIsPortrait(String(result.orientation).trim().toLowerCase() === 'portrait');
        },
        () => {
          message.error(t('previewError'));
        }
      )
    );
  };

  const deleteFooter = [<Button onClick={deleteTemplate}>{t('deleteButton')}</Button>];

  const pagination =
    total > 100
      ? {
          total,
          pageSize,
          pageNumber,
          current: pageNumber + 1,
          defaultCurrent: 0,
          onChange: (p, s) => handleChangePage(p, s),
          style: { padding: '10px' },
        }
      : false;

  const dataSource = certificates.map(el => ({ ...el, key: el.id }));

  const iframeClass = isPortrait ? css['Certificate-preview-portrait'] : css['Certificate-preview-landscape'];

  return (
    <Content className={css.DictionaryDirectory}>
      <div className={css['DictionaryItem-container']}>
        <Form className={css['DictionaryItem-form']}>
          <Search
            id='certificateSearchInput'
            data-qa='searchCertificateInput'
            placeholder={t('searchPlaceholder')}
            onSearch={handleSearch}
            value={searchValue}
            onChange={event => handleSearchChange(event)}
            enterButton={true}
          />
        </Form>
        <Button
          className={css['DictionaryItem-button']}
          onClick={openCreateModal}
          type='primary'
          data-qa='createCertificateBtn'
        >
          {t('addCertificateButton')}
        </Button>
      </div>
      <Card className={css['DictionaryDirectory-card']}>
        <Table
          className={css['DictionaryItem-noFound']}
          ellipsis={true}
          bordered={false}
          dataSource={dataSource}
          loading={isLoading || isLoadingTemplate}
          pagination={pagination}
        >
          <Column
            title={t('certPreviewColumn')}
            dataIndex='img'
            key='img'
            filterSearch={true}
            width={100}
            render={(text, record) => {
              const [test] = templates.filter(item => +item.id === record.templateId);
              const iframePreview =
                String(test?.orientation).trim().toLowerCase() === 'portrait'
                  ? css['Certificate-preview-portraitSmall']
                  : css['Certificate-preview-landscapeSmall'];

              const iconPreview =
                String(test?.orientation).trim().toLowerCase() === 'portrait'
                  ? css['DictionaryDirectory-container-previewIconPort']
                  : css['DictionaryDirectory-container-previewIcon'];

              const finalContent = test?.content.replace('<html', '<html style="zoom: 0.05"');

              return (
                <>
                  <div className={css['DictionaryDirectory-container']}>
                    <iframe
                      className={iframePreview}
                      title='varDirectoryPreview'
                      srcDoc={finalContent}
                      width={100}
                      height={64}
                      scrolling='no'
                      seamless
                    ></iframe>
                    {currentUser.authorities.includes(CERTIFICATES_READ) && (
                      <div className={iconPreview} onClick={() => onPreviewCertificate(record)}>
                        <ZoomInOutlined />
                      </div>
                    )}
                  </div>
                </>
              );
            }}
          />
          <Column title={t('certificateColumn')} dataIndex='name' key='name' filterSearch={true} />
          <Column
            title={t('actionsColumn')}
            key='actions'
            align='center'
            width={150}
            render={(text, record) => {
              return (
                <>
                  {currentUser.authorities.includes(CERTIFICATES_UPDATE) && (
                    <Tooltip title={t('editButton')} placement='left'>
                      <FormOutlined
                        size='small'
                        type='primary'
                        data-qa='updateCertificateBtn'
                        className={css['DictionaryItem-actionsButton']}
                        onClick={() => editTemplate(record)}
                      />
                    </Tooltip>
                  )}
                  {currentUser.authorities.includes(CERTIFICATES_UPDATE) && (
                    <Tooltip title={t('deleteButton')}>
                      <DeleteOutlined
                        size='small'
                        data-qa='deleteCertificateBtn'
                        className={css['DictionaryItem-deleteButton']}
                        onClick={() => openDeleteTemplateModal(record)}
                      />
                    </Tooltip>
                  )}
                </>
              );
            }}
          />
        </Table>
      </Card>

      {certificateModalVisible && (
        <CertificateModal
          onCancel={handleModalClose}
          onSubmit={onSubmit}
          visible={certificateModalVisible}
          certificate={certificate}
          setCertificate={setCertificate}
          editable={editable}
          templates={templates}
        />
      )}

      <Modal name='deleteDialog' open={deleteDialogVisible} footer={deleteFooter} onCancel={closeDeleteDialog}>
        {`${t('deleteTemplate')} ${certificate.name}?`}
      </Modal>

      <Modal
        centered
        className={css['Certificate-preview']}
        name='previewDialog'
        open={showPreview}
        footer={null}
        onCancel={closePreviewDialog}
        destroyOnClose={true}
        title={<span style={{ fontSize: '24px' }}>{previewHtml.name}</span>}
        width={isPortrait ? 580 : 885}
      >
        <div className={css['Certificate-preview']}>
          <iframe
            className={iframeClass}
            title='varDirectoryPreview'
            srcDoc={previewHtml.content}
            seamless
            frameBorder={0}
          ></iframe>
        </div>
      </Modal>
    </Content>
  );
};

export default Certificates;
