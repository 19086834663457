import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getClearStatistics } from '@/store/statistics/actions';

import { Checkbox, Flex, Progress, Row, Tooltip } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './all-statistics-style.module.scss';

const AllStatistics = props => {
  const { isStatisticsLoading, isStatisticsError, data, isMaterial, showProgress, changeDeletedUsers } = props;

  const { t } = useTranslation('topicDetailsDialog');
  const dispatch = useDispatch();

  const [currentData, setCurrentData] = useState({});
  const [dynamicHeight, setDynamicHeight] = useState(0);

  useEffect(() => {
    return () => {
      setCurrentData({});
      dispatch(getClearStatistics());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (isMaterial) {
        const sortKeys = { allCount: null };
        let assign = Object.assign(sortKeys, data);
        delete assign.learningCount;

        setCurrentData(assign);
      } else {
        setCurrentData(data);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isStatisticsLoading && !_.isEmpty(currentData) && !_.isEmpty(data) && !isStatisticsError) {
      setDynamicHeight('auto');
    } else {
      setDynamicHeight(0);
    }
  }, [isStatisticsLoading, currentData]);

  return (
    <AnimateHeight duration={500} contentClassName={css['allstatistic']} height={dynamicHeight}>
      <div className={css['allstatistic--transition']}>
        <div className={classNames(css['allstatistic-items'], { [css['allstatistic-items-center']]: !isMaterial })}>
          {showProgress && (
            <div className={css['allstatistic--progress']}>
              <Progress
                className={css['allstatistic--progress__line']}
                format={null}
                type='circle'
                strokeColor={{
                  '0%': 'var(--personalColor)',
                  '100%': 'var(--personalColor)',
                }}
                percent={Math.trunc(data?.learningCount || 0)}
              />
              <div className={css['allstatistic-items__item--title__tooltip_center']}>
                <Tooltip
                  className={css['allstatistic-items__item--title__tooltip']}
                  placement='right'
                  title={t('questionAllTooltip')}
                >
                  {t('learningCount')} <QuestionCircleTwoTone />
                </Tooltip>
              </div>
            </div>
          )}
          <Row gutter={20} wrap='wrap' justify='start'>
            {Object.keys(currentData).map(key => {
              return (
                <div key={'allstatistic-' + key} className={css['allstatistic-items__item']}>
                  <div
                    className={classNames(css['allstatistic-items__item--title'], {
                      [css['allstatistic-items__item--title__center']]: !isMaterial,
                    })}
                  >
                    {t(key)}
                  </div>
                  <div
                    className={classNames(css['allstatistic-items__item--value'], {
                      [css['allstatistic-items__item--value__center']]: !isMaterial,
                    })}
                  >
                    {currentData[key]}
                  </div>
                </div>
              );
            })}
          </Row>
        </div>
        {isMaterial && (
          <Flex className={css['allstatistic-container']} justify='flex-end' align='center'>
            <Checkbox className={css['allstatistic-deleted__users']} onChange={changeDeletedUsers}>
              {t('withDeletedUsers')}
            </Checkbox>
          </Flex>
        )}
      </div>
    </AnimateHeight>
  );
};

AllStatistics.propTypes = {
  t: PropTypes.func,
  isStatisticsLoading: PropTypes.bool,
  isStatisticsError: PropTypes.bool,
  data: PropTypes.object,
};

export default AllStatistics;
