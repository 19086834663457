import { GET_POKA_SITES_FAIL, GET_POKA_SITES_START, GET_POKA_SITES_SUCCESS } from '@/store/poka_sites/constants';

const initialState = {
  pokaSites: [],
  loadingPokaSites: false,
  errorPokaSites: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_POKA_SITES_START:
      return {
        ...state,
        loadingPokaSites: true,
        errorPokaSites: false,
        pokaSites: [],
      };

    case GET_POKA_SITES_SUCCESS:
      return {
        ...state,
        loadingPokaSites: false,
        errorPokaSites: false,
        pokaSites: response,
      };

    case GET_POKA_SITES_FAIL:
      return {
        ...state,
        loadingPokaSites: false,
        errorPokaSites: true,
        pokaSites: [],
      };

    default:
      return state;
  }
};
