import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { getSubcompanies } from '@/store/companies/actions';
import { clearStudents, getDepartment, getFunction, getPosition, getStudents } from '@/store/students/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Divider, Input, Row, Select, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Inspectors from '../Inspectors';
import TagSelected from '../TagSelected/TagSelected';
import UserNew from './UserNew';

import Utils from '@/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './UsersAssign.module.scss';

import { ACCESS_TO_ADMIN_TOPIC_INSPECTION } from '@/constants/permissions';

const UsersAssign = ({
  onCancel,
  onOk,
  textBlock,
  title,
  topicId,
  rewardId,
  trackId,
  setFooter,
  isTab,
  canMentor,
  setData,
}) => {
  const { t } = useTranslation('modalAddUser');
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUsers);
  const { subCompanies } = useSelector(selectCompanies);
  const { functionStudent, department, position } = useSelector(selectStudents);
  const { isLoadingStudents, students, studentsTotal, studentsPageNumber } = useSelector(selectStudents);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [state, setState] = useState({
    fullName: undefined,
    position: undefined,
    company: undefined,
    function: undefined,
    department: undefined,
    page: 0,
    size: 10,
    rewardId: rewardId,
    topicId: topicId,
    trackId: trackId,
    byManager: currentUser && Utils.isManagerRole(currentUser),
    canMentor: canMentor,
  });
  const [isInspectors, setIsInspectors] = useState({ visible: false, deadlineDate: null, inspectors: [] });

  const titleModal = <div className={css['Modal-title']}>{title}</div>;

  const handleGet = e => setState({ ...state, fullName: e?.target?.value });

  const getDebounced = useCallback(_.debounce(handleGet, 1000), [state]);

  const optionsFilters = arr => {
    if (Array.isArray(arr) && arr.length > 0) return arr.map(item => ({ value: item, label: item }));
  };

  const templateFilters = [
    {
      col: [
        {
          // Должность
          placeholder: t('placeholderPosition'),
          onChange: e => setState({ ...state, position: e }),
          dataqa: 'selectPosition',
          value: state.position,
          options: optionsFilters(position),
        },
        {
          // Отдел
          placeholder: t('placeholderDepartment'),
          onChange: e => setState({ ...state, department: e }),
          dataqa: 'selectDepartment',
          value: state.department,
          options: optionsFilters(department),
        },
      ],
    },
    {
      col: [
        {
          // Функция
          placeholder: t('placeholderFunction'),
          onChange: e => setState({ ...state, function: e }),
          dataqa: 'selectFunction',
          value: state.function,
          options: optionsFilters(functionStudent),
        },
        {
          // Компания
          placeholder: t('placeholderCompany'),
          onChange: e => setState({ ...state, company: e }),
          dataqa: 'selectCompany',
          value: state.company,
          options: optionsFilters(subCompanies),
        },
      ],
    },
  ];

  const renderFiltersSelect = templateFilters.map((item, indexRow) => {
    const customCol = item.col.map((col, index) => {
      return (
        <Col xs={24} md={12} key={index}>
          <Select {...col} allowClear showSearch className={css['Modal-filters-row-select']} />
        </Col>
      );
    });

    return (
      <Row justify='space-between' gutter={[16]} key={indexRow} className={css['Modal-filters-row']}>
        {customCol}
      </Row>
    );
  });

  const onSelectUsers = item => {
    if (canMentor) {
      setSelectedUsers([item]);
    } else {
      setSelectedUsers([...selectedUsers, item]);
    }
  };

  const onDeletedItems = itemId => {
    const filteredUsers = selectedUsers.filter(item => item.id !== itemId);
    setSelectedUsers(filteredUsers);
  };

  const renderTags = () => {
    if (Array.isArray(selectedUsers) && selectedUsers.length > 0) {
      return (
        <>
          <Divider />
          <Row>
            {selectedUsers.map(item => {
              const { lastName, firstName, middleName } = item;
              const name = `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
              return <TagSelected name={name} id={item.id} key={item.id} onDeleteTag={onDeletedItems} />;
            })}
          </Row>
        </>
      );
    }
  };

  const renderUsers = () => {
    if (Array.isArray(students) && students.length > 0) {
      return students.map(i => (
        <UserNew
          user={i}
          key={i.id}
          selectUser={onSelectUsers}
          textBlock={textBlock}
          deletedItems={onDeletedItems}
          selectedIds={selectedUsers.map(i => i.id)}
          isTopic={!!topicId}
          canMentor={canMentor}
        />
      ));
    } else {
      return !isLoadingStudents && <div className={css['Modal-users-empty']}>{t('emptyData')}</div>;
    }
  };

  const onSubmit = () => {
    const inspect = isInspectors.visible
      ? {
          deadlineDate: isInspectors.deadlineDate,
          inspectors: isInspectors.inspectors,
        }
      : null;

    onOk(selectedUsers, inspect);
  };

  const footerModal = (
    <div className={css['Modal-footer']}>
      <div className={css['Modal-footer-info']}>
        <div>
          {t('chooiseuser')} {selectedUsers.length}
        </div>
        <Button type='link' onClick={() => setSelectedUsers([])}>
          {t('clearUsers')}
        </Button>
      </div>

      <div className={css['Modal-footer-action']}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button
          type='primary'
          onClick={onSubmit}
          disabled={
            !selectedUsers.length ||
            (isInspectors.visible && (!isInspectors.inspectors.length || !isInspectors.deadlineDate))
          }
        >
          {t('add')}
        </Button>
      </div>
    </div>
  );

  const renderInspectors = () => {
    return (
      <div>
        <Divider />
        <Inspectors setIsInspectors={setIsInspectors} isInspectors={isInspectors} />
      </div>
    );
  };

  useEffect(() => {
    if (!subCompanies.length) dispatch(getSubcompanies());
    if (!position) dispatch(getPosition());
    if (!functionStudent) dispatch(getFunction());
    if (!department) dispatch(getDepartment());
  }, []);

  useEffect(() => {
    dispatch(getStudents(state));
  }, [state]);

  useEffect(() => {
    return () => {
      dispatch(clearStudents());
    };
  }, []);

  useEffect(() => {
    if (canMentor) {
      setData(selectedUsers);
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (setFooter && typeof setFooter === 'function') setFooter(footerModal);
  }, [isTab, selectedUsers, isInspectors]);

  return (
    <div>
      <div>{titleModal}</div>
      <Row>
        <Col span={24}>
          <Input
            placeholder={t('placeholderSearch')}
            onChange={getDebounced}
            dataqa='adminCompilationMaterialSearchInput'
            defaultValue={state.fullName}
            size='large'
            prefix={<SearchOutlined className={css['Modal-filters-search']} />}
            className={css['Modal-filters-input']}
            allowClear
          />
        </Col>
      </Row>
      {renderFiltersSelect}
      <Spin spinning={isLoadingStudents}>
        <div className={css['Modal-users']}>
          <InfiniteScroll
            pageStart={0}
            useWindow={false}
            initialLoad={false}
            hasMore={studentsTotal > students?.length && !isLoadingStudents}
            threshold={300}
            loadMore={() =>
              !isLoadingStudents && dispatch(getStudents({ ...state, isUserModal: true }, studentsPageNumber + 1))
            }
          >
            {renderUsers()}
          </InfiniteScroll>
        </div>

        {renderTags()}
        {topicId && currentUser?.authorities?.includes(ACCESS_TO_ADMIN_TOPIC_INSPECTION) && renderInspectors()}
      </Spin>
    </div>
  );
};

UsersAssign.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  textBlock: PropTypes.array,
  topicId: PropTypes.number,
  rewardId: PropTypes.number,
  trackId: PropTypes.number,
  isTab: PropTypes.string,
  canMentor: PropTypes.bool,
  setData: PropTypes.func,
};

export default UsersAssign;
