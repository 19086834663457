import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearTrackFilters, postTracksFilters, saveTrackFilters } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import css from '../Tracks.module.scss';

const { Option } = Select;

const Filters = () => {
  const { t } = useTranslation('tracks');
  const dispatch = useDispatch();

  const { consecutive, tracksFilters } = useSelector(selectTracks);

  const [filters, setFilters] = useState({
    name: undefined || tracksFilters?.filters?.name,
    consecutive: undefined || tracksFilters?.filters?.consecutive,
  });

  const resetFilters = () => {
    setFilters({
      name: undefined,
      consecutive: undefined,
    });
    dispatch(clearTrackFilters());
    dispatch(postTracksFilters({}, 0));
  };

  const onSubmitFilters = () => {
    dispatch(saveTrackFilters(filters));
    dispatch(postTracksFilters(filters, 0));
  };

  const optionsConsecutive =
    consecutive?.length &&
    consecutive.map((item, index) => {
      return (
        <Option value={item.value} key={index}>
          {item.name}
        </Option>
      );
    });

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-buttons']}>
        <span className={css['Filters-buttons-filters']}>{t('filters')}</span>
        <Button type='link' onClick={resetFilters} className={css['Filters-buttons-reset']}>
          {t('reset')}
        </Button>
      </div>
      <Row type='flex' justify='start' gutter={[16, 0]}>
        <Col span={8}>
          <Form layout='vertical'>
            <Form.Item label={t('passing')} colon={false}>
              <Select
                className={css['Filters-block-select']}
                value={filters.consecutive}
                allowClear
                showSearch
                onChange={e => setFilters({ ...filters, consecutive: e })}
                optionFilterProp='children'
                placeholder={t('placeholderSelect')}
              >
                {optionsConsecutive}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Form layout='vertical'>
            <Form.Item label={t('searchTrack')} colon={false}>
              <Input
                onChange={e => setFilters({ ...filters, name: e.target.value || undefined })}
                value={filters.name}
                placeholder={t('placeholderSearch')}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <div className={css['Filters-apply']}>
        <Button onClick={onSubmitFilters} key='apply' type='primary'>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
};

export default Filters;
