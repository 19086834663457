/* eslint-disable */
import {
  ADD_CURRENT_MENTORS,
  CLEAR_IPR_DETAILS_SUCCESS,
  CLEAR_IPR_FILTERS,
  CONFIRM_IPR_FAIL,
  CONFIRM_IPR_START,
  CONFIRM_IPR_SUCCESS,
  EXPORT_IPRS_FAIL,
  EXPORT_IPRS_START,
  EXPORT_IPRS_SUCCESS,
  FINISHED_IPR_FAIL,
  FINISHED_IPR_START,
  FINISHED_IPR_SUCCESS,
  GET_IPR_DETAILS_FAIL,
  GET_IPR_DETAILS_START,
  GET_IPR_DETAILS_SUCCESS,
  GET_IPR_GOAL_CATEGORY_FAIL,
  GET_IPR_GOAL_CATEGORY_START,
  GET_IPR_GOAL_CATEGORY_SUCCESS,
  GET_IPR_SETTINGS_FAIL,
  GET_IPR_SETTINGS_START,
  GET_IPR_SETTINGS_SUCCESS,
  GET_IPR_STATUS_FAIL,
  GET_IPR_STATUS_START,
  GET_IPR_STATUS_SUCCESS,
  GET_MENTORS_IPR_FAIL,
  GET_MENTORS_IPR_START,
  GET_MENTORS_IPR_SUCCESS,
  POST_IPR_FILTERS_FAIL,
  POST_IPR_FILTERS_START,
  POST_IPR_FILTERS_SUCCESS,
  REJECTED_IPR_FAIL,
  REJECTED_IPR_START,
  REJECTED_IPR_SUCCESS,
  SAVE_IPR_FILTERS,
  SAVE_IPR_SORT,
  UPDATE_SETTINGS_IPR_FAIL,
  UPDATE_SETTINGS_IPR_START,
  UPDATE_SETTINGS_IPR_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  iprLoading: false,
  iprTable: [],
  iprTotal: null,
  iprPageSize: null,
  iprPageNumber: null,
  iprCurrentPage: null,
  iprMentors: [],
  iprStatus: [],
  iprDetails: {},
  exportLoading: false,
  isLoadingMentors: false,
  goalCategory: [],
  settingsIpr: {},
  isLoadingSettingsIpr: false,

  iprSort: '',
  iprFilters: {},
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case POST_IPR_FILTERS_START:
    case GET_IPR_STATUS_START:
    case GET_IPR_DETAILS_START:
    case REJECTED_IPR_START:
    case CONFIRM_IPR_START:
    case FINISHED_IPR_START:
    case GET_IPR_GOAL_CATEGORY_START:
    case UPDATE_SETTINGS_IPR_START:
      return { ...state, isLoading: true };

    case GET_MENTORS_IPR_START:
      return { ...state, isLoadingMentors: true };

    case EXPORT_IPRS_START:
      return { ...state, exportLoading: true };

    case EXPORT_IPRS_SUCCESS:
      return { ...state, exportLoading: false };

    case GET_IPR_SETTINGS_START:
      return { ...state, isLoadingSettingsIpr: true };

    case POST_IPR_FILTERS_SUCCESS:
      return {
        ...state,
        iprLoading: false,
        iprTable: response.content,
        iprTotal: response.totalElements,
        iprPageSize: response.size,
        iprPageNumber: response.totalPages,
        iprCurrentPage: response.number,
        isLoading: false,
      };

    case GET_MENTORS_IPR_SUCCESS:
      return { ...state, isLoadingMentors: false, iprMentors: response };

    case GET_IPR_GOAL_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, goalCategory: response };

    case GET_IPR_SETTINGS_SUCCESS:
      return { ...state, isLoadingSettingsIpr: false, settingsIpr: response };

    case REJECTED_IPR_SUCCESS:
    case CONFIRM_IPR_SUCCESS:
    case FINISHED_IPR_SUCCESS:
      return { ...state, isLoading: false, isError: false };

    case UPDATE_SETTINGS_IPR_SUCCESS:
      return { ...state, isLoading: false, isError: false, settingsIpr: response };

    case GET_IPR_STATUS_SUCCESS:
      return { ...state, isLoading: false, iprStatus: response };

    case GET_IPR_DETAILS_SUCCESS:
      return { ...state, isLoading: false, iprDetails: response };

    case CLEAR_IPR_DETAILS_SUCCESS:
      return { ...state, isLoading: false, iprDetails: {} };

    case POST_IPR_FILTERS_FAIL:
    case GET_IPR_STATUS_FAIL:
    case GET_IPR_DETAILS_FAIL:
    case REJECTED_IPR_FAIL:
    case CONFIRM_IPR_FAIL:
    case FINISHED_IPR_FAIL:
    case GET_IPR_GOAL_CATEGORY_FAIL:
    case UPDATE_SETTINGS_IPR_FAIL:
      return { ...state, isLoading: false, isError: true };

    case GET_MENTORS_IPR_FAIL:
      return { ...state, isLoadingMentors: false, isError: true };

    case EXPORT_IPRS_FAIL:
      return { ...state, exportLoading: false, isError: true };

    case GET_IPR_SETTINGS_FAIL:
      return { ...state, isLoadingSettingsIpr: false, isError: true };

    case SAVE_IPR_SORT:
      return { ...state, iprSort: value };

    case SAVE_IPR_FILTERS:
      return { ...state, iprFilters: value };

    case CLEAR_IPR_FILTERS:
      return { ...state, iprFilters: {} };

    case ADD_CURRENT_MENTORS:
      return { ...state, iprMentors: value };

    default:
      return { ...state };
  }
};
