// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./MaterialIcons.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Montserrat.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]:before, .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.ql-font-Montserrat {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
}

@font-face {
  font-family: Montserrat;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

html, body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./sharedPartners/mars/fonts/index.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,4DAAiD;AACnD;;AAOA;EAJE,uBAAuB;EACvB,+DAA+C;AACjD;;AAMA;EAHE,qDAAqD;AACvD","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n@font-face {\n  font-family: \"Material Icons\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"./MaterialIcons.woff2\") format(\"woff2\");\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(\"./Montserrat.ttf\") format(\"truetype\");\n}\nhtml,\nbody {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
