import { GET_VERSION_FAIL, GET_VERSION_START, GET_VERSION_SUCCESS } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getVersion = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/version`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_VERSION_START, GET_VERSION_SUCCESS, GET_VERSION_FAIL],
  onSuccess,
  onError,
});
