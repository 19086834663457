import React from 'react';

import { Col, Input, Row } from 'antd';

import PropTypes from 'prop-types';

import css from './MeritsInput.module.scss';

const MeritsInput = ({ merits, onChange, disabled = false }) => {
  const handleChange = event => {
    const { name, value: val } = event.target;
    const numberRegExp = /^-?\d*\.?\d*$/;
    if (!numberRegExp.test(val)) {
      return;
    }
    const output = {};

    merits.map(item => {
      output[item.id] = item.value;
      return true;
    });
    onChange({ ...output, [name]: val });
  };

  const meritItems = merits.map(item => (
    <Col span={6} key={item.id}>
      <Row className={css['MeritsInput-row']}>
        <Col span={15}>
          <div className={css['MeritsInput-label']}>{item.name}</div>
        </Col>
        <Col span={10}>
          <Input
            className={css['MeritsInput-input']}
            name={item.id}
            onChange={handleChange}
            value={item.value}
            maxLength={6}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Col>
  ));
  return <Row>{meritItems}</Row>;
};

MeritsInput.propTypes = {
  merits: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MeritsInput;
