import {
  GET_COMPANY_TIMEZONE_FAIL,
  GET_COMPANY_TIMEZONE_START,
  GET_COMPANY_TIMEZONE_SUCCESS,
  PUT_COMPANY_TIMEZONE_FAIL,
  PUT_COMPANY_TIMEZONE_START,
  PUT_COMPANY_TIMEZONE_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getCompanyTimezone = ({ id }, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/company/${encodeURI(id)}/timezone`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPANY_TIMEZONE_START, GET_COMPANY_TIMEZONE_SUCCESS, GET_COMPANY_TIMEZONE_FAIL],
  onSuccess,
  onError,
});

export const editCompanyTimezone = ({ id }, timezoneId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/company/${encodeURI(id)}/timezone/${getQueryString({
    timezoneId: timezoneId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [PUT_COMPANY_TIMEZONE_START, PUT_COMPANY_TIMEZONE_SUCCESS, PUT_COMPANY_TIMEZONE_FAIL],
  onSuccess,
});
