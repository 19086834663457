// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MeritsInput-MeritsInput-module__ql-picker-TELJpv.admin-src-components-MeritsInput-MeritsInput-module__ql-font-OzfPuu .admin-src-components-MeritsInput-MeritsInput-module__ql-picker-label-VRQ6hV[data-value="Montserrat"]:before, .admin-src-components-MeritsInput-MeritsInput-module__ql-picker-TELJpv.admin-src-components-MeritsInput-MeritsInput-module__ql-font-OzfPuu .admin-src-components-MeritsInput-MeritsInput-module__ql-picker-item-uesfye[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MeritsInput-MeritsInput-module__ql-font-Montserrat-aUr4fl {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-label-otEfYy {
  color: #b6b6b6;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 800;
}

.admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-row-W8UmSD {
  text-align: center;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-input-dkETYB {
  color: #000;
  text-align: center;
  background-image: none;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90px;
  height: 33px;
  margin-right: 12px;
  padding: 0 17px;
  font-size: 13px;
  display: inline-block;
  position: relative;
}

.admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-input-dkETYB:hover, .admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-input-dkETYB:focus {
  border-color: var(--personalColor);
  box-shadow: none;
  outline: 0;
}

.admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-input-dkETYB::placeholder {
  color: #0006;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MeritsInput/MeritsInput.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAOA;EAJE,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAOA;EAJE,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;;AAKA;EAFE,kCAAkC;EAClC,gBAAgB;EAChB,UAAU;AACZ;;AAIA;EADE,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.MeritsInput-label {\n  color: #b6b6b6;\n  font-weight: 800;\n  font-size: 11px;\n  text-transform: uppercase;\n  white-space: nowrap;\n}\n.MeritsInput-row {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  text-align: center;\n  align-items: center;\n  flex-wrap: wrap;\n}\n.MeritsInput-input {\n  position: relative;\n  display: inline-block;\n  padding: 0 17px;\n  margin-right: 12px;\n  width: fit-content;\n  height: 33px;\n  font-size: 13px;\n  color: #000;\n  background-image: none;\n  border: 1px solid #dadada;\n  border-radius: 8px;\n  text-align: center;\n  max-width: 90px;\n}\n.MeritsInput-input:hover, .MeritsInput-input:focus {\n  border-color: var(--personalColor);\n  box-shadow: none;\n  outline: 0;\n}\n.MeritsInput-input::placeholder {\n  color: rgba(0, 0, 0, 0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MeritsInput-MeritsInput-module__ql-picker-TELJpv`,
	"qlPicker": `admin-src-components-MeritsInput-MeritsInput-module__ql-picker-TELJpv`,
	"ql-font": `admin-src-components-MeritsInput-MeritsInput-module__ql-font-OzfPuu`,
	"qlFont": `admin-src-components-MeritsInput-MeritsInput-module__ql-font-OzfPuu`,
	"ql-picker-label": `admin-src-components-MeritsInput-MeritsInput-module__ql-picker-label-VRQ6hV`,
	"qlPickerLabel": `admin-src-components-MeritsInput-MeritsInput-module__ql-picker-label-VRQ6hV`,
	"ql-picker-item": `admin-src-components-MeritsInput-MeritsInput-module__ql-picker-item-uesfye`,
	"qlPickerItem": `admin-src-components-MeritsInput-MeritsInput-module__ql-picker-item-uesfye`,
	"ql-font-Montserrat": `admin-src-components-MeritsInput-MeritsInput-module__ql-font-Montserrat-aUr4fl`,
	"qlFontMontserrat": `admin-src-components-MeritsInput-MeritsInput-module__ql-font-Montserrat-aUr4fl`,
	"MeritsInput-label": `admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-label-otEfYy`,
	"meritsInputLabel": `admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-label-otEfYy`,
	"MeritsInput-row": `admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-row-W8UmSD`,
	"meritsInputRow": `admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-row-W8UmSD`,
	"MeritsInput-input": `admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-input-dkETYB`,
	"meritsInputInput": `admin-src-components-MeritsInput-MeritsInput-module__MeritsInput-input-dkETYB`
};
export default ___CSS_LOADER_EXPORT___;
