/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Tooltip } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';
import css2 from '@/pages/Users/Users.module.scss';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import css from './User.module.scss';

import lock from '@/assets/images/lock.svg';

class User extends Component {
  static propTypes = {
    active: PropTypes.bool,
    getFile: PropTypes.func,
    getPhoto: PropTypes.func,
    index: PropTypes.number,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    selected: PropTypes.bool,
    setAvaToCache: PropTypes.func,
    status: PropTypes.string,
    user: PropTypes.object,
  };

  static defaultProps = {
    onClick: () => {},
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      isLoading: true,
      userUuid: props.user.localPhotoUuid || props.user.photoUuid,
    };
    this.getFiledebounce = _throttle(props.getFile, 1000);
  }

  componentDidMount() {
    this.getFile(this.state.userUuid, this.props.user.id);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.user.id !== this.props.user.id && prevProps.user.localPhotoUuid !== this.props.user.localPhotoUuid) ||
      prevProps.user.photoUuid !== this.props.user.photoUuid
    ) {
      this.getFile(this.props.user.localPhotoUuid || this.props.user.photoUuid, this.props.user.id);
    }
  }

  getFile = (photoUuid, id) => {
    if (!photoUuid) {
      return this.setState({
        avatar: null,
        userUuid: null,
      });
    }
    this.getFiledebounce(photoUuid, blob => {
      const imageUrl = URL.createObjectURL(blob);
      this.setState({
        avatar: {
          [id]: imageUrl,
        },
      });
    });
  };

  getStatus = () => {
    let status = '';
    switch (this.props.status && this.props.status) {
      case 'IN_PROGRESS':
        status = this.props.t('process');
        break;
      case 'WAS_HANDLE':
      case 'APPROVED_BY_USER':
        status = this.props.t('confirmed');
        break;
      case 'ON_REGISTRATION':
        status = this.props.t('registration');
        break;
      case 'EXPIRED':
        status = this.props.t('completed');
        break;
      case 'FINISHED':
        status = this.props.t('completed');
        break;
      case 'WAITING_BY_USER':
      default:
        status = this.props.t('waiting');
        break;
    }
    return status;
  };

  render() {
    const DELETED_USER = this.props.user && this.props.user.status === 'DELETED';

    const lastName = this.props.user?.lastName ? this.props.user.lastName : '';
    const firstName = this.props.user?.firstName ? this.props.user.firstName : '';
    const userName = `${lastName.substr(0, 1)}${firstName.substr(0, 1)}`;

    return (
      <div className={css.User}>
        <span className={css['User-col']}>{this.props.index + 1}.</span>
        <span className={classNames(css['User-avatar'], css['User-col'])}>
          {this.state.avatar && this.state.avatar[this.props.user.id] ? (
            <img src={this.state.avatar[this.props.user.id] || PortraitPlaceholder} alt='' />
          ) : (
            <div className={css['User-abc-ava']}>{userName}</div>
          )}
        </span>
        {DELETED_USER && (
          <Tooltip title={this.props.t('userDeleted')}>
            <div className={classNames(css2['DeleteUser-key'], css2['DeleteUser-key-fix7'])}>
              <img key='deleteUser' src={lock} alt='' />
            </div>
          </Tooltip>
        )}
        <span className={css['User-col']}>{`${lastName} ${firstName}`}</span>
        <span className={css['User-col']}>{this.getStatus()}</span>
      </div>
    );
  }
}

const mapStateToProps = createSelector([], () => ({}));

const mapActionsToProps = {
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('user')(User));
