/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

export const GET_BONUSES_START = 'GET_BONUSES_START';
export const GET_BONUSES_SUCCESS = 'GET_BONUSES_SUCCESS';
export const GET_BONUSES_FAIL = 'GET_BONUSES_FAIL';
export const EDIT_BONUSES_START = 'EDIT_BONUSES_START';
export const EDIT_BONUSES_SUCCESS = 'EDIT_BONUSES_SUCCESS';
export const EDIT_BONUSES_FAIL = 'EDIT_BONUSES_FAIL';
export const SAVE_BONUSES = 'SAVE_BONUSES';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getBonuses = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/bonus`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BONUSES_START, GET_BONUSES_SUCCESS, GET_BONUSES_FAIL],
});

export const editBonuses = (bonus = [], onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/bonus`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: bonus,
  types: [EDIT_BONUSES_START, EDIT_BONUSES_SUCCESS, EDIT_BONUSES_FAIL],
  onSuccess,
  onError,
});

export const saveBonuses = () => ({
  type: SAVE_BONUSES,
});
