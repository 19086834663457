import { useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LoginThrough = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  console.log(searchParams);

  useLayoutEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const redirect = searchParams.get('redirect');

    if (accessToken) {
      localStorage.setItem('authorizationToken', accessToken);
      navigate(redirect || '/');
    } else {
      navigate('/user-login');
    }
  }, []);

  return <></>;
};

export default LoginThrough;
