import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360GetMeasurables, D360PublishReport } from '@/store/d360/d360_measurables/actions';

import { Button, Col, Modal, Row, Table, Tooltip } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';

import { extractUsername } from '../../utils';
import Search from './Search';
import ViewResult from './ViewResult';

import _ from 'lodash';

const selectedTemplate = { keys: [], rows: [] };
const pageSizeOptions = ['10', '50', '100', '500', '1000'];

const List = () => {
  const { measurables, pagePer, pageTotal, loading } = useSelector(state => state.d360Measurables);
  const { t } = useTranslation('D360_IndividualReports');
  const [pageCurrent, setPageCurrent] = useState(1);
  const [selected, setSelected] = useState(selectedTemplate);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const { project } = useSelector(state => state.d360Project);
  const [data, setData] = useState([]);
  const [viewResult, setViewResult] = useState(false);
  // const [sortColumn, setSortColumn] = useState('id');
  // const [sortOrder, setSortOrder] = useState('descend');
  const [filterColumn, setFilterColumn] = useState(undefined);
  const [filterValue, setFilterValue] = useState(undefined);
  const [modal, contextHolder] = Modal.useModal();

  const loadMeasurables = () => {
    dispatch(D360GetMeasurables(project.id, pageCurrent, pageSize, null, null, filterColumn, filterValue));
  };

  useEffect(() => {
    if (typeof project === 'object' && project.id) {
      loadMeasurables();
    }
  }, [project, pageCurrent, pageSize, filterColumn, filterValue]);

  useEffect(() => {
    let data = [];
    if (!_.isNil(measurables) && _.isArray(measurables)) {
      measurables.forEach(user => {
        data.push({ ...user, key: user.user_id });
      });
    }

    setData(data);
  }, [measurables]);

  const filtersValue = value => {
    const uniq = [...new Set(data.map(item => item.student?.[value]).filter(Boolean))];
    return uniq?.length > 0 && uniq.map(i => ({ text: i, value: i }));
  };
  // eslint-disable-next-line
  const onFilterReset = column => {
    setFilterColumn(null);
    setFilterValue(null);
  };

  const onFilterSubmit = (column, value) => {
    setFilterColumn(column);
    setFilterValue(value);
  };

  const columns = [
    {
      title: t('columnMeasurable'),
      key: 'fullname',
      dataIndex: 'fullname',
      sorter: (a, b) => a.student.last_name.localeCompare(b.student.last_name),
      sortDirections: ['descend', 'ascend'],
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      // eslint-disable-next-line
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Search
            fnClearFilters={clearFilters}
            fnConfirm={confirm}
            onReset={onFilterReset}
            onSubmit={onFilterSubmit}
            column={'fullname'}
            defaultValue={filterValue}
          />
        );
      },
      onFilter: (value, record) => {
        if (!_.isNil(record) && _.isObject(record) && _.has(record, 'student')) {
          const fullName = extractUsername(record.student);
          if (_.isString(fullName)) {
            return fullName.toLowerCase().includes(value.toLowerCase());
          }
        }

        return null;
      },
      render: (name, record) => {
        return <>{extractUsername(record.student)}</>;
      },
    },
    {
      title: t('columnEmail'),
      key: 'email',
      dataIndex: 'email',
      ellipsis: true,
      render: (name, record) => {
        return <Tooltip title={record.student.email}>{record.student.email}</Tooltip>;
      },
    },
    {
      title: t('columnDepartment'),
      key: 'department',
      dataIndex: 'department',
      ellipsis: true,
      filters: filtersValue('department') && filtersValue('department'),
      onFilter: (value, record) => record.student.department === value,
      render: (name, record) => {
        return <Tooltip title={record.student.department}>{record.student.department}</Tooltip>;
      },
    },

    {
      title: t('columnPosition'),
      key: 'position',
      dataIndex: 'position',
      ellipsis: true,
      onFilter: (value, record) => record.student.position === value,
      filters: filtersValue('position') && filtersValue('position'),
      render: (name, record) => {
        return <Tooltip title={record.student.position}>{record.student.position}</Tooltip>;
      },
    },
    {
      title: t('progress'),
      key: 'progress',
      dataIndex: 'progress',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.progress?.toString().localeCompare(b.progress.toString()),
      render: name => `${name}%`,
    },
    {
      title: t('status'),
      width: '20%',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.status.toString().localeCompare(b.status.toString()),
      render: name => t(name),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selected.keys,
    onChange: (selectedKeys, selectedRows) => {
      setSelected({ ...selected, keys: selectedKeys, rows: selectedRows });
    },
  };

  const onPagination = pagination => {
    if (!_.isNil(pagination) && _.isObject(pagination)) {
      if (_.has(pagination, 'pageSize') && _.isNumber(pagination.pageSize)) {
        setPageSize(pagination.pageSize);
      }
      if (_.has(pagination, 'current') && _.isNumber(pagination.current)) {
        setPageCurrent(pagination.current);
      }
    }
  };

  // const onSort = (sorter) => {
  //   if( !_.isNil(sorter) && _.isObject(sorter) ) {
  //     if( _.has(sorter, 'field') && _.has(sorter, 'order') ) {
  //       if( _.isString(sorter.field) && _.isString(sorter.order) ) {
  //         const field = sorter.field.toString().toLowerCase();
  //         const order = sorter.order.toString().toLowerCase();
  //         if( field.length > 0 && (order === 'descend' || order === 'ascend') ) {
  //           setSortColumn(field);
  //           setSortOrder(order);
  //           return;
  //         }
  //       }
  //     }
  //   }
  //
  //   setSortColumn(null);
  //   setSortOrder(null);
  // };
  // eslint-disable-next-line
  const onTableChange = (pagination, filters, sorter) => {
    onPagination(pagination);
    //onSort(sorter);
  };

  const Title = () => {
    return (
      <Row>
        <Col span={24} style={{ margin: '10px auto' }} align='right'>
          <Button.Group size='small'>
            <Button
              type='primary'
              disabled={selected.rows.length <= 0}
              onClick={() => setViewResult(true)}
              icon={<EyeOutlined />}
            >
              {t('showReport')} &nbsp; {`( ${selected.keys.length} )`}
            </Button>
            <Button
              disabled={selected.rows.length <= 0}
              onClick={() => {
                return modal.confirm({
                  title: t('publishReportTitle'),
                  content: t('publishReportContent'),
                  okType: 'primary',
                  onOk() {
                    const measurableIds = [];
                    if (!_.isNil(selected) && _.isObject(selected) && _.has(selected, 'rows')) {
                      if (_.isArray(selected.rows) && selected.rows.length > 0) {
                        selected.rows.forEach(measurable => {
                          if (
                            !_.isNil(measurable) &&
                            _.isObject(measurable) &&
                            _.has(measurable, 'id') &&
                            _.isNumber(measurable.id)
                          ) {
                            measurableIds.push(measurable.id);
                          }
                        });

                        if (measurableIds.length > 0) {
                          dispatch(D360PublishReport(project.id, measurableIds));
                        }
                      }
                    }
                  },
                });
              }}
            >
              {t('publishResults')} &nbsp; {`( ${selected.keys.length} )`}
            </Button>
          </Button.Group>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row style={{ marginTop: 32 }}>
        <Col span={24}>
          {data && (
            <Table
              columns={columns}
              dataSource={data}
              bordered={true}
              rowSelection={rowSelection}
              loading={loading}
              size='middle'
              scroll={{ x: 1000 }}
              onChange={onTableChange}
              showHeader={true}
              title={() => <Title />}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                hideOnSinglePage: false,
                total: pageTotal,
                current: pageCurrent,
                pageSize: pagePer,
                pageSizeOptions: pageSizeOptions,
                responsive: true,
              }}
            />
          )}
        </Col>
      </Row>

      {viewResult === true && (
        <ViewResult
          open={viewResult}
          onCancel={() => setViewResult(false)}
          projectId={project.id}
          measurables={selected.rows}
        />
      )}
      {contextHolder}
    </>
  );
};

export default List;
