import { useTranslation } from 'react-i18next';

import { Row } from 'antd';

import classNames from 'classnames';

import css from '../ManageCourse.module.scss';

export const SortBar = ({ activeSort, handleChangeSort }) => {
  const { t } = useTranslation('sortBar');

  return (
    <Row span={20} className={css['ManageCourse-SortBar-container']} align='middle'>
      {t('sort')}
      <div
        className={classNames(css['ManageCourse-SortBar-item'], {
          [css['ManageCourse-SortBar-item-active']]: activeSort === 'plan.user.lastName,asc',
        })}
        onClick={() => handleChangeSort('plan.user.lastName,asc')}
      >
        {t('name')}
      </div>
      |
      <div
        className={classNames(css['ManageCourse-SortBar-item'], {
          [css['ManageCourse-SortBar-item-active']]: activeSort === 'id,desc',
        })}
        onClick={() => handleChangeSort('id,desc')}
      >
        {t('date')}
      </div>
      |
      <div
        className={classNames(css['ManageCourse-SortBar-item'], {
          [css['ManageCourse-SortBar-item-active']]: activeSort === 'plan.startDate,asc',
        })}
        onClick={() => handleChangeSort('plan.startDate,asc')}
      >
        {t('month')}
      </div>
      |
      <div
        className={classNames(css['ManageCourse-SortBar-item'], {
          [css['ManageCourse-SortBar-item-active']]: activeSort === 'plan.topic.name,asc',
        })}
        onClick={() => handleChangeSort('plan.topic.name,asc')}
      >
        {t('title')}
      </div>
    </Row>
  );
};
