export const FIELD_TYPES = {
  TYPE_DATE: 'TYPE_DATE',
  TYPE_STRING: 'TYPE_STRING',
  TYPE_DATETIME: 'TYPE_DATETIME',
  TYPE_NUMERIC: 'TYPE_NUMERIC',
  TYPE_TIME: 'TYPE_TIME',
  TYPE_TEXT: 'TYPE_TEXT',
  TYPE_BOOLEAN: 'TYPE_BOOLEAN',
};

export const TYPES = {
  TYPE_DATE: 'EQUALS',
  TYPE_STRING: 'EQUALS',
  TYPE_DATETIME: 'EQUALS',
  TYPE_NUMERIC: 'BOOLEAN',
  TYPE_TIME: 'BOOLEAN',
  TYPE_TEXT: 'LIKE',
  TYPE_BOOLEAN: 'BOOLEAN',
  RANGE: 'RANGE',
};
