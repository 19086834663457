import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

import {
  GET_POKA_DIR_POSITIONS_FAIL,
  GET_POKA_DIR_POSITIONS_START,
  GET_POKA_DIR_POSITIONS_SUCCESS,
  PUT_POKA_DIR_POSITIONS_FAIL,
  PUT_POKA_DIR_POSITIONS_START,
  PUT_POKA_DIR_POSITIONS_SUCCESS,
} from '@/store/poka_directory/constants';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPokaDirPositions = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/position`,
  headers,
  types: [GET_POKA_DIR_POSITIONS_START, GET_POKA_DIR_POSITIONS_SUCCESS, GET_POKA_DIR_POSITIONS_FAIL],
});

export const editPokaDirPosition = (id, body, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/position/link/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_POKA_DIR_POSITIONS_START, PUT_POKA_DIR_POSITIONS_SUCCESS, PUT_POKA_DIR_POSITIONS_FAIL],
  onSuccess,
});
