/* eslint-disable */
import {
  ADD_INACTIVE_BADGE,
  APPOINTS_BADGE_FAIL,
  APPOINTS_BADGE_START,
  APPOINTS_BADGE_SUCCESS,
  CHANGE_STATUS_BADGE_FAIL,
  CHANGE_STATUS_BADGE_START,
  CHANGE_STATUS_BADGE_SUCCESS,
  CLEAR_BADGE_FILTERS,
  CLEAR_BADGE_INFO,
  CLEAR_BADGE_INFO_FILTERS,
  CLEAR_INACTIVE_BADGE,
  CREATE_BADGE_FAIL,
  CREATE_BADGE_START,
  CREATE_BADGE_SUCCESS,
  DELETE_BADGE_FAIL,
  DELETE_BADGE_START,
  DELETE_BADGE_SUCCESS,
  DELETE_MASS_USER_BADGE_FAIL,
  DELETE_MASS_USER_BADGE_START,
  DELETE_MASS_USER_BADGE_SUCCESS,
  DELETE_USER_BADGE_FAIL,
  DELETE_USER_BADGE_START,
  DELETE_USER_BADGE_SUCCESS,
  GET_ACTIONS_BADGE_FAIL,
  GET_ACTIONS_BADGE_START,
  GET_ACTIONS_BADGE_SUCCESS,
  GET_APPOINT_BADGE_FAIL,
  GET_APPOINT_BADGE_START,
  GET_APPOINT_BADGE_SUCCESS,
  GET_BADGE_ID_FAIL,
  GET_BADGE_ID_START,
  GET_BADGE_ID_SUCCESS,
  GET_BADGES_FAIL,
  GET_BADGES_START,
  GET_BADGES_SUCCESS,
  GET_EXAMPLE_BADGE_CSV_FAIL,
  GET_EXAMPLE_BADGE_CSV_START,
  GET_EXAMPLE_BADGE_CSV_SUCCESS,
  GET_ISSUE_FAIL,
  GET_ISSUE_START,
  GET_ISSUE_SUCCESS,
  GET_LIST_BADGE_FAIL,
  GET_LIST_BADGE_START,
  GET_LIST_BADGE_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  IMPORT_BADGE_USERS_FAIL,
  IMPORT_BADGE_USERS_START,
  IMPORT_BADGE_USERS_SUCCESS,
  PUT_BADGE_TOPIC_FAIL,
  PUT_BADGE_TOPIC_START,
  PUT_BADGE_TOPIC_SUCCESS,
  SAVE_BADGE_FILTERS,
  SAVE_BADGE_INFO_FILTERS,
  SAVE_BADGE_INFO_SORT,
  SAVE_BADGE_SORT,
  UPDATE_BADGE_FAIL,
  UPDATE_BADGE_START,
  UPDATE_BADGE_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,

  badgeLoading: false,
  badgeTable: [],
  badgeTotal: null,
  badgePageSize: null,
  badgePageNumber: null,
  badgeCurrentPage: null,

  statusBadge: [],
  issueBadge: [],
  badgeId: {},
  isLoadingBadgeId: false,
  badgeFilters: {},
  badgeInfoFilters: {},
  badgeInfoSort: '',
  actionsBadge: [],

  badgeAppointLoading: false,
  badgeAppointTable: [],
  badgeAppointTotal: null,
  badgeAppointPageSize: null,
  badgeAppointPageNumber: null,
  badgeAppointCurrentPage: null,

  listBadges: [],

  badgeSort: '',
  inactiveBadge: {},
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_STATUS_START:
    case CREATE_BADGE_START:
    case UPDATE_BADGE_START:
    case CHANGE_STATUS_BADGE_START:
    case DELETE_BADGE_START:
    case APPOINTS_BADGE_START:
    case GET_EXAMPLE_BADGE_CSV_START:
    case IMPORT_BADGE_USERS_START:
    case GET_ISSUE_START:
    case GET_LIST_BADGE_START:
    case GET_ACTIONS_BADGE_START:
    case PUT_BADGE_TOPIC_START:
    case DELETE_USER_BADGE_START:
    case DELETE_MASS_USER_BADGE_START:
      return { ...state, isLoading: true };

    case GET_BADGE_ID_START:
      return { ...state, isLoadingBadgeId: true };

    case GET_BADGES_START:
      return { ...state, badgeLoading: true };

    case GET_APPOINT_BADGE_START:
      return { ...state, badgeAppointLoading: true };

    case GET_STATUS_SUCCESS:
      return { ...state, isLoading: false, statusBadge: response };

    case GET_ISSUE_SUCCESS:
      return { ...state, isLoading: false, issueBadge: response };

    case GET_BADGE_ID_SUCCESS:
      return { ...state, isLoadingBadgeId: false, badgeId: response };

    case GET_LIST_BADGE_SUCCESS:
      if (Object.keys(state.inactiveBadge)?.length > 0) {
        return { ...state, isLoading: false, listBadges: [...response.content, state.inactiveBadge] };
      } else {
        return { ...state, isLoading: false, listBadges: response.content };
      }

    case GET_ACTIONS_BADGE_SUCCESS:
      return { ...state, isLoading: false, actionsBadge: response };

    case CREATE_BADGE_SUCCESS:
    case UPDATE_BADGE_SUCCESS:
    case DELETE_BADGE_SUCCESS:
    case APPOINTS_BADGE_SUCCESS:
    case GET_EXAMPLE_BADGE_CSV_SUCCESS:
    case IMPORT_BADGE_USERS_SUCCESS:
    case PUT_BADGE_TOPIC_SUCCESS:
    case DELETE_MASS_USER_BADGE_SUCCESS:
    case DELETE_USER_BADGE_SUCCESS:
      return { ...state, isLoading: false };

    case GET_BADGES_SUCCESS:
      return {
        ...state,
        badgeLoading: false,
        badgeTable: response.content,
        badgeTotal: response.totalElements,
        badgePageSize: response.size,
        badgePageNumber: response.totalPages,
        badgeCurrentPage: response.number,
      };

    case GET_APPOINT_BADGE_SUCCESS:
      return {
        ...state,
        badgeAppointLoading: false,
        badgeAppointTable: response.content,
        badgeAppointTotal: response.totalElements,
        badgeAppointPageSize: response.size,
        badgeAppointPageNumber: response.totalPages,
        badgeAppointCurrentPage: response.number,
      };

    case CHANGE_STATUS_BADGE_SUCCESS:
      const filteredBadgeTable = state.badgeTable.map(i => {
        if (i.id === response.id) {
          i.statusDto.value = response.statusDto.value;
          i.statusDto.name = response.statusDto.name;
        }
        return i;
      });

      return { ...state, badgeTable: filteredBadgeTable, badgeLoading: false };

    case GET_BADGES_FAIL:
      return { ...state, badgeLoading: false, isError: true };

    case GET_APPOINT_BADGE_FAIL:
      return { ...state, badgeAppointLoading: false, isError: true };

    case GET_STATUS_FAIL:
    case CREATE_BADGE_FAIL:
    case UPDATE_BADGE_FAIL:
    case CHANGE_STATUS_BADGE_FAIL:
    case DELETE_BADGE_FAIL:
    case APPOINTS_BADGE_FAIL:
    case GET_EXAMPLE_BADGE_CSV_FAIL:
    case IMPORT_BADGE_USERS_FAIL:
    case GET_ISSUE_FAIL:
    case GET_LIST_BADGE_FAIL:
    case GET_ACTIONS_BADGE_FAIL:
    case PUT_BADGE_TOPIC_FAIL:
    case DELETE_USER_BADGE_FAIL:
    case DELETE_MASS_USER_BADGE_FAIL:
      return { ...state, isLoading: false, isError: true };

    case GET_BADGE_ID_FAIL:
      return { ...state, isLoadingBadgeId: false, isError: true };

    case CLEAR_BADGE_INFO:
      return { ...state, badgeId: {} };

    case SAVE_BADGE_FILTERS:
      return { ...state, badgeFilters: value };

    case SAVE_BADGE_INFO_FILTERS:
      return { ...state, badgeInfoFilters: value };

    case CLEAR_BADGE_FILTERS:
      return { ...state, badgeFilters: {} };

    case CLEAR_BADGE_INFO_FILTERS:
      return { ...state, badgeInfoFilters: {} };

    case SAVE_BADGE_INFO_SORT:
      return { ...state, badgeInfoSort: value };

    case SAVE_BADGE_SORT:
      return { ...state, badgeSort: value };

    case ADD_INACTIVE_BADGE:
      return { ...state, inactiveBadge: value };

    case CLEAR_INACTIVE_BADGE:
      return { ...state, inactiveBadge: {} };

    default:
      return { ...state };
  }
};
