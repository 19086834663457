import {
  CLEAR_SETTINGS_VISIBILITY_SUCCESS,
  DELETE_SETTINGS_VISIBILITY_FAIL,
  DELETE_SETTINGS_VISIBILITY_START,
  DELETE_SETTINGS_VISIBILITY_SUCCESS,
  GET_COUNT_SETTINGS_VISIBILITY_FAIL,
  GET_COUNT_SETTINGS_VISIBILITY_START,
  GET_COUNT_SETTINGS_VISIBILITY_SUCCESS,
  GET_COUNT_TOPIC_SETTINGS_VISIBILITY_FAIL,
  GET_COUNT_TOPIC_SETTINGS_VISIBILITY_START,
  GET_COUNT_TOPIC_SETTINGS_VISIBILITY_SUCCESS,
  GET_SETTINGS_VISIBILITY_FAIL,
  GET_SETTINGS_VISIBILITY_START,
  GET_SETTINGS_VISIBILITY_SUCCESS,
  GET_USERS_SETTINGS_MODAL_VISIBILITY_START,
  GET_USERS_SETTINGS_TOPIC_VISIBILITY_START,
  GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_FAIL,
  GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_SUCCESS,
  GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_FAIL,
  GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_SUCCESS,
  PUT_SETTINGS_VISIBILITY_FAIL,
  PUT_SETTINGS_VISIBILITY_START,
  PUT_SETTINGS_VISIBILITY_SUCCESS,
  SAVE_SETTINGS_VISIBILITY_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getSettingsVisibility = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/visibility/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SETTINGS_VISIBILITY_START, GET_SETTINGS_VISIBILITY_SUCCESS, GET_SETTINGS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const updateSettingsVisibility = (topicId, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/visibility/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_SETTINGS_VISIBILITY_START, PUT_SETTINGS_VISIBILITY_SUCCESS, PUT_SETTINGS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const deleteSettingsVisibility = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/topic/visibility/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_SETTINGS_VISIBILITY_START, DELETE_SETTINGS_VISIBILITY_SUCCESS, DELETE_SETTINGS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const getCountSettingsVisibility = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/visibility/user-count/filter`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [
    GET_COUNT_SETTINGS_VISIBILITY_START,
    GET_COUNT_SETTINGS_VISIBILITY_SUCCESS,
    GET_COUNT_SETTINGS_VISIBILITY_FAIL,
  ],
  onSuccess,
  onError,
});

// не использовать
export const getCountTopicSettingsVisibility = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/visibility/user-count/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [
    GET_COUNT_TOPIC_SETTINGS_VISIBILITY_START,
    GET_COUNT_TOPIC_SETTINGS_VISIBILITY_SUCCESS,
    GET_COUNT_TOPIC_SETTINGS_VISIBILITY_FAIL,
  ],
  onSuccess,
  onError,
});

export const exportUsersModalVisibility = (params, currentSignal, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/mass/export/topic-visibility`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: params,
    types: [
      GET_USERS_SETTINGS_MODAL_VISIBILITY_START,
      GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_SUCCESS,
      GET_USERS_TOPIC_SETTINGS_MODAL_VISIBILITY_FAIL,
    ],
    signal: currentSignal,
    responseType: 'blob',
    onSuccess,
    onError,
  };
};

export const exportUsersTopicVisibility = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mass/export/topic-visibility${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'blob',
  types: [
    GET_USERS_SETTINGS_TOPIC_VISIBILITY_START,
    GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_SUCCESS,
    GET_USERS_TOPIC_SETTINGS_TOPIC_VISIBILITY_FAIL,
  ],
  onSuccess,
  onError,
});

export const clearSettingsVisibility = () => ({
  type: CLEAR_SETTINGS_VISIBILITY_SUCCESS,
});

export const saveSettingsVisibility = value => ({
  type: SAVE_SETTINGS_VISIBILITY_SUCCESS,
  value,
});
