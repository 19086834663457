/* eslint-disable */
import React from 'react';

import { Form } from 'antd';
import ImageInput from '@/components/ImageInputCrop';
import CustomInput from '@/components/Input';

import css from './CompanyCreationDialog.module.scss';

export const Mails = ({ company, handleChange, handleImageChange, fileInfo, fileLoadingImage, t }) => {
  return (
    <div className={css['Add-modal']}>
      <div className={css['Add-modal-title']}>{t('letter')}</div>

      {/* ЗАЯВКИ И ОБРАТНАЯ СВЯЗЬ */}
      <Form.Item
        label={t('request')}
        validateStatus={company.errors.mailFeedback ? 'error' : ''}
        help={company.errors.mailFeedback && t('require')}
        required
        labelAlign='left'
      >
        <CustomInput
          name='mailFeedback'
          placeholder=' example@mail.com'
          onChange={handleChange}
          value={company.mailFeedback}
          maxLength={50}
        />
      </Form.Item>

      {/* КОММЕНТАРИИ И ОТЗЫВЫ */}
      <Form.Item
        label={t('comet')}
        validateStatus={company.errors.mailAdmin ? 'error' : ''}
        help={company.errors.mailAdmin && t('require')}
        required
        labelAlign='left'
      >
        <CustomInput
          name='mailAdmin'
          placeholder=' example@mail.com'
          onChange={handleChange}
          value={company.mailAdmin}
          maxLength={50}
        />
      </Form.Item>

      {/* ЛОГО */}
      <Form.Item
        label={
          <div>
            <div>{t('logo')}</div>
            <span className={css['Add-modal-description']}>PNG 600x115</span>
          </div>
        }
        labelAlign='left'
      >
        <ImageInput
          reverse
          company
          noCrop
          id='mailLogo'
          onChange={handleImageChange}
          name='mailLogo'
          imageSrc={company.images.mailLogo}
          fileInfo={fileInfo}
          isLoadingFileImage={fileLoadingImage}
        />
      </Form.Item>
    </div>
  );
};
