/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteCustomByOneNotification,
  getCustomNotifications,
  publishCustomByOneNotification,
} from '@/store/newNotifications/actions';
import { getProfileCustomFieldList } from '@/store/pcfields/actions';

import { selectNewNotifications } from '@/store/newNotifications/selectors';
import { selectFields } from '@/store/pcfields/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Card, Dropdown, Layout, Menu, message, Modal, Spin, Table, Tooltip } from 'antd';
import { Content } from '@/components/Layout';

import CreateOrUpdateModal from './Elements/CreateOrUpdateModal.jsx';

import dayjs from 'dayjs';

import css from './Notifications.module.scss';

import {
  // NOTIFICATION_READ,
  NOTIFICATION_BODY_READ,
  NOTIFICATION_CREATE,
  NOTIFICATION_DELETE,
  NOTIFICATION_UPDATE,
  NOTIFICATION_UPDATE_TO_READ,
} from '@/constants/permissions';

const PAGE_SIZE = 20;

const Notifications = () => {
  const dispatch = useDispatch();
  const { customByAllNotifications, isByAllNotificationsLoading, totalCountNotifications, isPublishLoading } =
    useSelector(selectNewNotifications);
  const { currentUser } = useSelector(selectUsers);
  const { fields } = useSelector(selectFields);

  const { t } = useTranslation('notifications');

  const [isVisibleModal, changeVisibleModal] = useState(false);
  const [confirmSendNotification, setConfirmSendNotification] = useState({ isVisible: false, id: 0 });
  const [visibleModal, setVisibleModal] = useState({
    type: 'create',
    item: {},
  });

  const checkPermission = permission => {
    return !currentUser.authorities.includes(permission);
  };

  const removeFileItem = index => {
    Modal.confirm({
      centered: true,
      maskClosable: true,
      content: <h2>{t('confirmRemove')}</h2>,
      className: css['Notifications--remove-modal'],
      onOk() {
        dispatch(
          deleteCustomByOneNotification(
            index,
            success => {},
            error => {
              console.log(error);
            }
          )
        );
      },
    });
  };

  const showAllFilters = item => {
    let { createdDate, ...allFilters } = item;
    delete allFilters.url;
    delete allFilters.activeTill;
    delete allFilters.subject;
    delete allFilters.message;
    delete allFilters.status;
    delete allFilters.content;
    delete allFilters.newTab;
    delete allFilters.id;

    const isNotEmptyFilters = Object.values(allFilters).some(e => e !== null && e !== '' && e?.length > 0);

    Modal.info({
      title: t('titleFilter'),
      centered: true,
      autoFocusButton: null,
      maskClosable: true,
      footer: null,
      content: (
        <div>
          <h2>
            {t('createdDate')} {dayjs(createdDate).format('DD-MM-YYYY')}
          </h2>
          {isNotEmptyFilters ? (
            Object.keys(allFilters).map(allFiltersKey => {
              return (
                allFilters[allFiltersKey]?.length > 0 && (
                  <div key={allFiltersKey}>
                    <h2>{t(allFiltersKey)}</h2>
                    {allFilters[allFiltersKey] !== null &&
                    allFilters[allFiltersKey]?.length > 0 &&
                    allFiltersKey === 'customFields' ? (
                      <ul>
                        {allFilters[allFiltersKey].map((customFields, customFieldsIndex) => (
                          <li key={'li-' + customFieldsIndex}>
                            {fields.find(el => el.id === customFields.customFieldId).name} :{' '}
                            <b>{customFields.valueConcrete}</b>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        {allFilters[allFiltersKey].map((li, indexLi) => (
                          <li key={'li-' + indexLi}>{li}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              );
            })
          ) : (
            <div>{t('filtersEmpty')}</div>
          )}
        </div>
      ),
    });
  };

  const toPublishedNotification = notificationId => {
    setConfirmSendNotification({
      isVisible: true,
      id: notificationId,
    });
  };

  const dropdownMenu = item => {
    const menuItems = [
      {
        key: 'menu-item-0',
        disabled: checkPermission(NOTIFICATION_UPDATE_TO_READ),
        label: t('editDropdownItem'),
        onClick: () => {
          setVisibleModal({ type: 'update', item: item });
          changeVisibleModal(true);
        },
      },
      {
        key: 'menu-item-1',
        disabled: checkPermission(NOTIFICATION_DELETE),
        label: t('removeDropdownItem'),
        onClick: () => {
          removeFileItem(item.id);
        },
      },
      {
        key: 'menu-item-2',
        disabled: checkPermission(NOTIFICATION_BODY_READ),
        label: t('viewDropdownItem'),
        onClick: () => {
          showAllFilters(item);
        },
      },
    ];

    if (item.status === 'DRAFT' && !checkPermission(NOTIFICATION_CREATE)) {
      menuItems.push(
        {
          type: 'divider',
        },
        {
          key: 'menu-item-3',
          label: t('sendDropdownItem'),
          onClick: () => toPublishedNotification(item.id),
        }
      );
    }

    return menuItems;
  };

  const notificationTableColumns = [
    { title: t('tableLink'), ellipsis: true, width: '15%', dataIndex: 'url', key: 'url' },
    { title: t('tableDescription'), width: '35%', dataIndex: 'subject', key: 'subject' },
    {
      title: t('tableStatus'),
      width: '15%',
      dataIndex: 'status',
      key: 'status',
      render: (el, item) => (
        <Tooltip placement='top' title={t('tableTooltip' + item.status)}>
          <span>{t('table' + item.status)}</span>{' '}
        </Tooltip>
      ),
    },
    {
      title: t('tableDate'),
      width: '10%',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (el, item) => {
        return <span>{dayjs(item.createdDate).format('DD-MM-YYYY')}</span>;
      },
    },
    {
      title: t('tableDateTill'),
      width: '10%',
      dataIndex: 'activeTill',
      key: 'activeTill',
      render: (el, item) => <span>{dayjs(item.activeTill).format('DD-MM-YYYY')}</span>,
    },
    {
      title: t('tableAction'),
      key: 'operation',
      fixed: 'right',
      width: '15%',
      render: (el, item) => (
        <Dropdown menu={{ items: dropdownMenu(item) }} trigger={['click']} placement='bottom'>
          <Button
            onClick={e => {
              e.preventDefault();
            }}
            type={'primary'}
          >
            {t('tableAction')}
          </Button>
        </Dropdown>
      ),
    },
  ];

  const changePage = currentPage => {
    dispatch(getCustomNotifications({ page: currentPage - 1, size: PAGE_SIZE }));
  };

  useEffect(() => {
    dispatch(getProfileCustomFieldList(false, true));
    changePage(1);
  }, []);

  const createNew = () => {
    setVisibleModal({ type: 'create', item: {} });
    changeVisibleModal(true);
  };

  return (
    <Layout>
      <Content>
        <h1>{t('title')}</h1>
        <Card className={css['Notifications-card']}>
          <h3>{t('createNewLabel')}</h3>
          <Button
            disabled={checkPermission(NOTIFICATION_CREATE)}
            className={css['Notifications-card__btn']}
            onClick={createNew}
            type={'primary'}
          >
            {t('createNew')}
          </Button>
        </Card>
        <Card className={css['Notifications-card']}>
          <Table
            loading={isByAllNotificationsLoading}
            dataSource={customByAllNotifications}
            rowKey={record => `row-key-${record.id}`}
            columns={notificationTableColumns}
            pagination={{
              total: totalCountNotifications,
              pageSize: PAGE_SIZE,
              onChange: changePage,
            }}
          />
        </Card>

        <Modal
          open={isVisibleModal}
          title={t('createNewTitle')}
          className={css['Notifications--create-modal']}
          onCancel={() => changeVisibleModal(false)}
          centered={true}
          footer={null}
          destroyOnClose={true}
          width={visibleModal.type === 'create' ? '80%' : '30%'}
          style={{ maxWidth: '1200px' }}
        >
          <CreateOrUpdateModal
            closeModal={() => {
              changeVisibleModal(false);
            }}
            disabled={checkPermission(NOTIFICATION_UPDATE)}
            itemNotification={visibleModal.item}
            isCreate={visibleModal.type === 'create'}
          />
        </Modal>

        <Modal
          open={confirmSendNotification.isVisible}
          centered={true}
          maskClosable={true}
          footer={null}
          onCancel={() => {
            setConfirmSendNotification({ ...confirmSendNotification, isVisible: false });
          }}
        >
          <Spin spinning={isPublishLoading}>
            <h2>{t('confirmPublished')}</h2>
            <div style={{ textAlign: 'center' }}>
              <Button
                className={css['Notifications__modal-btn']}
                key={`back-button-${confirmSendNotification.id}`}
                onClick={() => {
                  setConfirmSendNotification({ ...confirmSendNotification, isVisible: false });
                }}
              >
                {t('back')}
              </Button>
              <Button
                className={css['Notifications__modal-btn']}
                key={`published-button-${confirmSendNotification.id}`}
                type={'primary'}
                onClick={() => {
                  dispatch(
                    publishCustomByOneNotification(
                      confirmSendNotification.id,
                      {},
                      success => {
                        setConfirmSendNotification({ isVisible: false, id: 0 });
                      },
                      error => {
                        message.error(t('errorPublished'));
                      }
                    )
                  );
                }}
              >
                {t('send')}
              </Button>
            </div>
          </Spin>
        </Modal>
      </Content>
    </Layout>
  );
};

export default Notifications;
