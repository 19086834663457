/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Progress } from 'antd';
import Condition from '@/components/Condition';
import Modal from '@/components/Modal';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';

import { API_URL } from '@shared/constants/config';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './FileInput.module.scss';

import archiveImage from '@/assets/images/archive2.png';
import mediaImage from '@/assets/images/media2.png';

export class SingleFileInput extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['video', 'pdf', 'file']),
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disable: PropTypes.bool,
    error: PropTypes.bool,
    scormFileSize: PropTypes.object,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      error: null,
      showModal: false,
      percent: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.error !== this.props.error) {
      this.setState({ file: null });
    }
  }

  setInputRef = input => {
    input && input.setAttribute('accept', this.getFormatName());
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  handleFileChange = event => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('file', file);

    let types = null;
    if (this.props.type === 'file') {
      types = ['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'];
    } else if (this.props.type === 'pdf') {
      types = ['application/pdf'];
    } else {
      types = ['video/mp4'];
    }

    reader.onloadstart = () => this.setState({ percent: 0 });

    reader.onloadend = () => {
      if (file) {
        const { onChange } = this.props;
        if (!types.includes(file.type)) {
          this.setState({ showModal: true });
        } else if (file.size > this.props.scormFileSize?.maxUploadSizeLong) {
          this.setState({
            error: this.props.t('error', {
              maxUploadSize: this.props.scormFileSize?.maxUploadSize,
            }),
            showModal: true,
          });
        } else {
          this.setState({ file: file, error: null, showModal: false });
          this.props.setLoading(true);
          onChange(formData);
          this.setState({ percent: 100 });
        }
      }
    };
    file && reader.readAsDataURL(file);
  };

  handleFileDelete = () => {
    this.props.onChange(null);
    this.setState({ file: null });
  };

  getModalContent = () => {
    const { t, type } = this.props;
    switch (type) {
      case 'file':
        return (
          <div className={css['App-card-info']}>
            <img src={archiveImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>{t('archive')}</h2>
                <div className={css['App-card-info-little']}>
                  {t('max', {
                    maxUploadSize: this.props.scormFileSize?.maxUploadSize,
                  })}
                </div>
              </div>
              <h3>zip</h3>
            </div>
          </div>
        );

      case 'pdf':
        return (
          <div className={css['App-card-info']}>
            <img src={archiveImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>PDF</h2>
                <div className={css['App-card-info-little']}>
                  {t('max', {
                    maxUploadSize: this.props.scormFileSize?.maxUploadSize,
                  })}
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className={css['App-card-info']}>
            <img src={mediaImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>{t('media')}</h2>
                <div className={css['App-card-info-little']}>
                  {t('max', {
                    maxUploadSize: this.props.scormFileSize?.maxUploadSize,
                  })}
                </div>
              </div>
              <h3>mp4</h3>
            </div>
          </div>
        );
    }
  };

  getFormatName = () => {
    switch (this.props.type) {
      case 'file':
        return 'zip';
      case 'pdf':
        return 'pdf';
      default:
        return 'mp4';
    }
  };

  render() {
    const { className, t } = this.props;
    return (
      <div className={classNames(css.SingleFileInput, className)}>
        <Button onClick={this.openFileDialog} disabled={this.props.disable || this.props.loading}>
          {!this.props.loading ? (
            this.props.name || (this.state.file && this.state.file.name) ? (
              t('edit')
            ) : (
              t('add')
            )
          ) : (
            <>
              {t('loading')} <LoadingOutlined />
            </>
          )}
        </Button>
        <Condition when={!this.props.loading}>
          {this.state.file || this.props.name ? (
            <div className={css['SingleFileInput-nameLayout']}>
              <div className={css['SingleFileInput-name']}>
                {this.props.name ? this.props.name : this.state.file.name}
              </div>
              {!this.props.disable && (
                <CloseOutlined className={css['SingleFileInput-xMark']} onClick={this.handleFileDelete} />
              )}
            </div>
          ) : (
            <div className={css['SingleFileInput-description']}>
              {t('format', {
                type: this.getFormatName(),
                maxUploadSize: this.props.scormFileSize?.maxUploadSize,
              })}
            </div>
          )}
        </Condition>
        <Condition when={this.props.loading}>
          <div className={css['SingleFileInput-progress']}>
            <Progress status='active' percent={this.state.percent} size='small' showInfo={false} />
          </div>
        </Condition>
        <input type='file' ref={this.setInputRef} onChange={this.handleFileChange} />
        {this.state.showModal && (
          <Modal
            modalCategory
            fileModal
            open
            onOk={this.openFileDialog}
            onCancel={() => this.setState({ showModal: false })}
            okText={t('add')}
          >
            <div className={css['App-card-label']}>{t('types')}</div>
            {this.getModalContent()}
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation('singleFileInput')(SingleFileInput);
