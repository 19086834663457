/* eslint-disable */
import { ApiService } from './api-service';

export class CareerService extends ApiService {
  getUsers(params: TGetCareerParams): Promise<any[]> {
    return this.get('/trajectory/career', params);
  }
}

type TGetCareerParams = {
  name?: string;
};

export default new CareerService();
