/* eslint-disable */
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getFunctions } from '@/store/function/actions';
import { getInspectionStatus } from '@/store/inspection/actions';
import { clearStudents, getDepartment, getFunction, getStudents, getStudentsList } from '@/store/students/actions';

import { selectCourses } from '@/store/courses/selectors';
import { selectInspection } from '@/store/inspection/selectors';
import { selectBlocks } from '@/store/lms/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Checkbox, Col, DatePicker, Flex, Row, Tooltip, Typography } from 'antd';
import MultiplySelect from '@/components/MultiplySelect';
import Select from '@/components/Select';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../TopicDetailsDialog.module.scss';

const { Title } = Typography;

// TODO: треубется рефакторинг на form из antd, возможно стоит переписать на компонент фильтрации

const Filter = props => {
  const { t } = props;
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const { yearRequest } = useSelector(selectCourses);
  const student = useSelector(selectStudents);
  const { studyPlanStatus } = useSelector(selectBlocks);
  const { inspectionStatus } = useSelector(selectInspection);

  const monthCourse = [
    { id: 1, value: '1', name: t('january') },
    { id: 2, value: '2', name: t('february') },
    { id: 3, value: '3', name: t('march') },
    { id: 4, value: '4', name: t('april') },
    { id: 5, value: '5', name: t('may') },
    { id: 6, value: '6', name: t('june') },
    { id: 7, value: '7', name: t('july') },
    { id: 8, value: '8', name: t('august') },
    { id: 9, value: '9', name: t('september') },
    { id: 10, value: '10', name: t('october') },
    { id: 11, value: '11', name: t('november') },
    { id: 12, value: '12', name: t('december') },
  ];

  const functionCourse =
    student.functionStudent && student.functionStudent.map((func, item) => ({ id: item + 1, function: func }));

  const year = yearRequest && yearRequest.map(item => ({ id: item, year: item }));

  const directory =
    student.department && student.department.map((depart, item) => ({ id: item + 1, department: depart }));

  const students = student.students.map(el => ({
    id: el.id,
    value: el.id,
    name: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''}`,
  }));

  const correctOptions =
    !props.withEvents &&
    studyPlanStatus &&
    studyPlanStatus.map((status, index) => ({
      id: index,
      name: status.text,
      value: status.value,
    }));

  const optionsManagers = useMemo(
    () =>
      student?.fullnameManager?.length > 0 &&
      student?.fullnameManager.map(item => ({
        id: item?.id,
        value: item?.id,
        name: item?.name,
      })),
    [student]
  );

  // Размонтирование компонента
  useEffect(() => {
    dispatch(getInspectionStatus());
    dispatch(getDepartment());
    dispatch(getFunction());

    return () => {
      getStudentsListDebounced.cancel();
    };
  }, []);

  const onSearch = value => {
    const filteredValue = value.trim();

    if (!filteredValue.length) {
      props.clearOneFilter('userId');
      dispatch(clearStudents());
      return;
    }

    if (filteredValue.length < 3) return;

    dispatch(getStudents({ fullName: filteredValue }));
  };

  const handleChangeUsername = e => {
    const correctData = !props.withEvents ? { ...e, name: 'userId' } : e;
    props.handleScopeChange(correctData);
  };

  const handleGetManagers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudentsList({ isManager: true, fullName, size: 100 }));
    }
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetManagers, 1000), []);

  const disabledStartDate = current => {
    return props.dateFilter.endDate ? current && current >= dayjs(props.dateFilter.endDate) : false;
  };

  const disabledEndDate = current => {
    return props.dateFilter.startDate ? current && current <= dayjs(props.dateFilter.startDate) : false;
  };

  const clearFilters = async () => {
    await props.cleanFilter();
    props.submitFilters();
  };

  useEffect(() => {
    props.changeInspectionMode(searchParams.get('inspectionMode'));
    props.submitFilters();
  }, [searchParams]);

  return (
    <Flex className={classNames(css['RowAllFilter'])} vertical>
      <div className={css['RowAllFilter__header']}>
        <Title level={2} className={css['Filter-block-labelBlock-bigLabel']}>
          {t('filter')}
        </Title>
        <div className={css['RowAllFilter__box']}>
          <div className={css['RowAllFilter__checkbox']}>
            <Checkbox
              name='inspectionMode'
              checked={props.inspectionMode}
              onChange={e => {
                props.changeInspectionMode(e.target.checked);
              }}
            />
            <span>{t('inspectionMode')}</span>
            <Tooltip title={t('inspectionModeTooltip')}>
              <ExclamationCircleTwoTone className={css['RowAllFilter__icon']} />
            </Tooltip>
          </div>
          <div className={css['RowAllFilter__checkbox']}>
            <Checkbox
              name='isExpiredDate'
              checked={props.isExpiredDate}
              onChange={e => {
                props.changeExpiredDate(e.target.checked);
              }}
            />
            <span>{t('isExpiredDate')}</span>
          </div>
        </div>
      </div>
      <Row className={css['RowAllFilter-items']} gutter={12}>
        {/* год */}
        {props.withEvents && (
          <Col span={6}>
            <div className={classNames(css['DetailsModalPay-col-applications'])}>
              <div className={classNames(css['DetailsModalPay-col-applications-filter'])}>
                <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('year')}</span>
                <Select
                  className={css['DetailsModalPay-select']}
                  onChange={props.handleScopeChange}
                  value={yearRequest?.length ? props.yearState : undefined}
                  name='year'
                  options={year}
                  white
                  allowClear
                />
              </div>
            </div>
          </Col>
        )}
        {/* месяц */}
        {props.withEvents && (
          <Col span={6}>
            <div className={classNames(css['DetailsModalPay-col-applications-filter'])}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('requestMonth')}</span>
              <Select
                className={css['DetailsModalPay-select']}
                onChange={props.handleScopeChange}
                value={props.valueMonths}
                name='month'
                options={monthCourse}
                white
                allowClear
              />
            </div>
          </Col>
        )}
        {/* фио */}
        <Col span={6}>
          <div className={classNames(css['DetailsModalPay-col-applications-filter'], css.distanse)}>
            <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('fullName')}</span>
            <Select
              className={css['DetailsModalPay-select']}
              onChange={handleChangeUsername}
              value={props.valueFIO}
              options={students}
              name='userId'
              white
              long
              showSearch
              topicTypeId={props.withEvents}
              onSearch={onSearch}
              allowClear
            />
          </div>
        </Col>
        {/* функция */}
        <Col span={6}>
          {props.domainCompany?.hasAdditionalInfo && (
            <div className={classNames(css['DetailsModalPay-col-applications-filter'], css.distanse)}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('division')}</span>
              <Select
                className={css['DetailsModalPay-select']}
                onChange={props.handleScopeChange}
                value={props.valueFunctions}
                options={functionCourse}
                name='function'
                white
                long
                allowClear
              />
            </div>
          )}
        </Col>
        {/* отдел */}
        <Col span={6}>
          {props.domainCompany?.hasAdditionalInfo && (
            <div className={css['DetailsModalPay-col-applications-filter']}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('department')}</span>
              <Select
                // className={css['DetailsModalPay-select']}
                showSearch
                className={css['DetailsModalPay-col-applications-filter-label-input']}
                onChange={props.handleScopeChange}
                value={props.valueDepartments}
                name='department'
                options={directory}
                white
                long
                allowClear
              />
            </div>
          )}
        </Col>
        {/* статус */}
        <Col span={6}>
          <div className={css['Filter-block']}>
            <div className={css['DetailsModalPay-col-applications-filter']}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('status')}</span>
              <MultiplySelect
                onChange={props.handleScopeChange}
                className={css['DetailsModalPay-col-applications-filter-label-multi']}
                value={props.valueStatus}
                name='status'
                options={props.withEvents ? props.optionsStatus : correctOptions}
                white
              />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className={css['Filter-block']}>
            <div className={css['DetailsModalPay-col-applications-filter']}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('manager')}</span>
              <Select
                showSearch
                className={css['DetailsModalPay-col-applications-filter-label-input']}
                onChange={props.handleScopeChange}
                value={props.valueManagerId}
                onSearch={getStudentsListDebounced}
                name='managerId'
                options={optionsManagers}
                white
                long
                allowClear
              />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className={css['Filter-block']}>
            <div className={css['DetailsModalPay-col-applications-filter']}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('deadlinePeriod')}</span>
              <Row gutter={10}>
                <Col span={12}>
                  <DatePicker
                    className={css['DetailsModalPay-col-applications-filter-label-input']}
                    placeholder={t('fromDate')}
                    name='startDate'
                    value={props.dateFilter.startDate ? dayjs(props.dateFilter.startDate) : undefined}
                    format='DD.MM.YYYY'
                    onChange={date =>
                      props.handleScopeChange({
                        name: 'startDate',
                        value: date ? dayjs(date).format('YYYY-MM-DD') : undefined,
                      })
                    }
                    disabledDate={disabledStartDate}
                  />
                </Col>
                <Col span={12}>
                  <DatePicker
                    className={css['DetailsModalPay-col-applications-filter-label-input']}
                    placeholder={t('toDate')}
                    name='endDate'
                    value={props.dateFilter.endDate ? dayjs(props.dateFilter.endDate) : undefined}
                    format='DD.MM.YYYY'
                    onChange={date =>
                      props.handleScopeChange({
                        name: 'endDate',
                        value: date ? dayjs(date).format('YYYY-MM-DD') : undefined,
                      })
                    }
                    disabledDate={disabledEndDate}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className={css['Filter-block']}>
            <div className={css['DetailsModalPay-col-applications-filter']}>
              <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('inspectionStatus')}</span>
              <Select
                className={css['DetailsModalPay-col-applications-filter-label-input']}
                onChange={props.handleScopeChange}
                value={props.inspectionStatus}
                name='inspectionStatus'
                allowClear
                white
                options={inspectionStatus}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Flex justify='flex-end' gap={24}>
        <Button type='default' onClick={clearFilters}>
          {t('clear')}
        </Button>
        <Button type='primary' onClick={props.submitFilters}>
          {t('submit')}
        </Button>
      </Flex>
    </Flex>
  );
};

Filter.propTypes = {
  t: PropTypes.func,
  handleScopeChange: PropTypes.func,
  cleanFilter: PropTypes.func,
  yearState: PropTypes.string,
  clearOneFilter: PropTypes.func,
  valueMonths: PropTypes.string,
  valueFIO: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  funcFilterManagers: PropTypes.func,
  domainCompany: PropTypes.object,
  valueFunctions: PropTypes.string,
  valueDepartments: PropTypes.string,
  valueStatus: PropTypes.array,
  optionsStatus: PropTypes.array,
  changeInspectionMode: PropTypes.func,
  inspectionMode: PropTypes.bool,
};
export default Filter;
