export const PROJECT_STATUS_ACTIVE = 1;
export const PROJECT_STATUS_INACTIVE = 2;
export const PROJECT_STATUS_DRAFT = 3;
export const PROJECT_STATUS_UNKNOWN = 666;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;

export const D360_MODE_EXPERIMENTAL = 'Experimental';
export const D360_MODE_ALPHA = 'Alpha Version';
export const D360_MODE_BETA = '';
export const D360_MODE_RELEASE = 'Version 1.0';
export const D360_MODE_DEFAULT = D360_MODE_EXPERIMENTAL;
export const D360_MODE = D360_MODE_BETA;

export const D360_HEALTH_ERROR_NOSCALES = 2000;
export const D360_HEALTH_ERROR_NOSCORES = 4000;
export const D360_HEALTH_ERROR_NOCRITERIAS = 8000;
export const D360_HEALTH_ERROR_NOMEASURABLES = 16000;
export const D360_HEALTH_ERROR_NORESPONDENTS = 32000;

export const RESPONDENT_TYPE_SELF = 1;
export const RESPONDENT_TYPE_MANAGER = 2;
export const RESPONDENT_TYPE_COLLEAGUE = 3;
export const RESPONDENT_TYPE_SUBORDINATE = 4;
export const RESPONDENT_TYPE_CROSS = 5;

export const RESPONDENT_TYPES = [
  RESPONDENT_TYPE_SELF,
  RESPONDENT_TYPE_MANAGER,
  RESPONDENT_TYPE_COLLEAGUE,
  RESPONDENT_TYPE_SUBORDINATE,
  RESPONDENT_TYPE_CROSS,
];
