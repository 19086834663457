import { Col, DatePicker, Row } from 'antd';

import css from './DeadlineFilter.module.scss';

const DeadlineFilter = props => {
  const { disabledStartDate, disabledEndDate, startDateValue, endDateValue, onChangeStartDate, onChangeEndDate, t } =
    props;

  return (
    <div className={css['Filter-block']}>
      <span className={css['DetailsModalPay-col-applications-filter-label']}>{t('deadlinePeriod')}</span>
      <Row gutter={10}>
        <Col span={12}>
          <DatePicker
            className={css['DetailsModalPay-col-applications-filter-label-input']}
            placeholder={t('fromDate')}
            name='startDate'
            value={startDateValue}
            format='DD.MM.YYYY'
            onChange={onChangeStartDate}
            disabledDate={disabledStartDate}
          />
        </Col>
        <Col span={12}>
          <DatePicker
            className={css['DetailsModalPay-col-applications-filter-label-input']}
            placeholder={t('toDate')}
            name='endDate'
            value={endDateValue}
            format='DD.MM.YYYY'
            onChange={onChangeEndDate}
            disabledDate={disabledEndDate}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DeadlineFilter;
