/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Dropdown, Tooltip } from 'antd';

import { isAnyCourseOrConference, isCorporateCourseWithoutApprove } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './TopicDetailsDialog.module.scss';

const isFieldEmpty = field => {
  if (typeof field === 'boolean') {
    return false;
  }
  if (Array.isArray(field)) {
    return !field.length;
  }
  return !field;
};

export class FieldItem extends Component {
  static propTypes = {
    field: PropTypes.object,
    getFile: PropTypes.func,
    getAlpinaLink: PropTypes.func,
    topic: PropTypes.object,
    typeId: PropTypes.number,
    value: PropTypes.any,
  };

  alpinaRedirect = link => {
    if (this.props.getAlpinaLink) {
      this.props.getAlpinaLink(link.url, res => res.url && window.open(res.url, '_blank'));
    }
  };

  render() {
    const { value, field, typeId, t } = this.props;

    if (isFieldEmpty(value)) {
      return null;
    }

    if (field.type === 'files') {
      const files =
        Array.isArray(value) &&
        value.map(file => (
          <div key={file.uuid} className={css['DetailsModal-topic-file']} target='_blank'>
            {file.name}
            <svg xmlns='http://www.w3.org/2000/svg' width='8' height='9' viewBox='0 0 8 9'>
              <path d='m1 4.5 2.86 2.86L6.72 4.5m-2.97 2V1.444' stroke='currentColor' fill='none' />
            </svg>
          </div>
        ));
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{files}</div>
        </div>
      );
    }
    if (field.type === 'tagsInput') {
      const tags =
        Array.isArray(value) &&
        value.map((tag, index) =>
          !tag.undeletable ? (
            <a key={index} href={tag.url} className={css['DetailsModal-topic-link']} target='_blank'>
              {tag.name}
            </a>
          ) : (
            <a className={css['DetailsModal-topic-link']} onClick={() => this.alpinaRedirect(tag)}>
              {tag.name}
            </a>
          )
        );
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div className={css['DetailsModal-value']}>{tags}</div>
        </div>
      );
    }
    if (field.type === 'authorsInput') {
      const author =
        Array.isArray(value) &&
        value.map(auth => (
          <div key={auth.id} className={css['DetailsModal-topic-link']}>
            {auth.name}
          </div>
        ));
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div className={css['DetailsModal-value']}>{author}</div>
        </div>
      );
    }
    if (field.type === 'timeInput' || field.type === 'executive_duration') {
      if (isAnyCourseOrConference(typeId) || isCorporateCourseWithoutApprove(typeId)) {
        const mins = Number(value) || 0;
        const hours = Math.trunc(mins / 60);
        const minutes = mins % 60;
        if (hours === 0 && hours === 0) {
          return (
            <div className={css['DetailsModal-card-item']}>
              <div className={css['DetailsModal-label']}>{field.label}</div>
              <div>{`${minutes} ${t('minutes')}`}</div>
            </div>
          );
        }
        if (minutes > 0 && hours !== 0) {
          return (
            <div className={css['DetailsModal-card-item']}>
              <div className={css['DetailsModal-label']}>{field.label}</div>
              <div>{`${hours} ${noun(hours, t('hour1'), t('hour2'), t('hour3'))} ${minutes} ${t('minutes')}`}</div>
            </div>
          );
        }
        return (
          <div className={css['DetailsModal-card-item']}>
            <div className={css['DetailsModal-label']}>{field.label}</div>
            <div>{`${hours} ${noun(hours, t('hour1'), t('hour2'), t('hour3'))}`}</div>
          </div>
        );
      }
      let minutes = Number(value) || 0;
      const weeks = Math.floor(minutes / (24 * 60 * 7));
      const weeksString = weeks ? `${weeks} ${noun(weeks, t('week1'), t('week2'))}` : '';
      minutes -= weeks * 24 * 60 * 7;
      const days = Math.floor(minutes / (24 * 60));
      const daysString = days ? `${days} ${noun(days, t('day1'), t('day2'))}` : '';
      minutes -= days * 24 * 60;
      const hours = Math.floor(minutes / 60);
      const hoursString = hours ? `${hours} ${noun(hours, t('hour1'), t('hour2'), t('hour3'))}` : '';
      minutes -= hours * 60;
      const minutesString = minutes ? `${minutes} ${t('minutes')}` : '';
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{[weeksString, daysString, hoursString, minutesString].join(' ').replace(/\s+/g, ' ')}</div>
        </div>
      );
    }
    if (field.type === 'numberInput') {
      const placeholder = field.apiField === 'cost' ? t('free') : '';
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{value || placeholder}</div>
        </div>
      );
    }
    if (field.type === 'checkbox') {
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{value ? 'Есть' : 'Нет'}</div>
        </div>
      );
    }
    if (field.type === 'calendar') {
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{dayjs(value).format('DD.MM.YYYY').toString()}</div>
        </div>
      );
    }
    if (field.type === 'schedule') {
      const scheduleItem = value.map((item, key) => {
        const fromToString = (
          <span>
            {dayjs(item.startTime, 'HH:mm:ss').format('HH:mm')} - {dayjs(item.endTime, 'HH:mm:ss').format('HH:mm')}
          </span>
        );

        return item.dates.map((d, index) => {
          return (
            <div key={`schedule_${key}`} className={css['Details-schedule__schedule__row']}>
              <span>{dayjs(d).format('DD.MM.YYYY')}</span>
              {fromToString}
              <span>
                {item.place ? (
                  Array.isArray(item.eventLinks) && item.eventLinks.length > 0 && item.eventLinks[index] ? (
                    <a className={css['Details-schedule__schedule_link']} target='_blank' href={item.eventLinks[index]}>
                      {t('eventLink')}
                    </a>
                  ) : item.place.length > 90 ? (
                    <Tooltip title={item.place}>{`${item.place.substring(0, 90)}...`}</Tooltip>
                  ) : (
                    item.place
                  )
                ) : (
                  t('notSpecified')
                )}
              </span>
            </div>
          );
        });
      });
      const scheduleData = (
        <div className={css['Details-schedule__schedule']}>
          <div className={css['Details-schedule__schedule__title']}>
            {t('schedule')} &nbsp;
            {this.props.topic && this.props.topic.type && this.props.topic.type.id === 8
              ? t('events')
              : t('conferences')}
          </div>
          <div
            className={classNames(css['Details-schedule__schedule__row'], css['Details-schedule__schedule__header'])}
          >
            <span>{t('date')}</span>
            <span>{t('time')}</span>
            <span>{t('location')}</span>
          </div>
          {scheduleItem}
          {this.props.topic.comment && (
            <div className={classNames(css['Details-schedule__schedule__row'], css['Details-schedule-comment'])}>
              {this.props.topic.comment}
            </div>
          )}
        </div>
      );

      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{t('schedule')}</div>
          <div>
            <Dropdown overlay={scheduleData} trigger={['click']}>
              <div className={css['Details-label-schedule']}>
                {dayjs(this.props.topic.startDate).format('DD.MM.YYYY')}
              </div>
            </Dropdown>
          </div>
        </div>
      );
    }
    if (field.type === 'year') {
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{dayjs(value).format('YYYY')}</div>
        </div>
      );
    }
    if (field.type === 'videoStyleselector' || field.type === 'topicClassSelector') {
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{value && value.name}</div>
        </div>
      );
    }
    if (field.type === 'dateTimeInput') {
      return (
        <div className={css['DetailsModal-card-item']}>
          <div className={css['DetailsModal-label']}>{field.label}</div>
          <div>{dayjs(value, 'HH:mm').format('HH:mm')}</div>
        </div>
      );
    }
    return (
      <div className={css['DetailsModal-card-item']}>
        <div className={css['DetailsModal-label']}>{field.label}</div>
        <div>{value}</div>
      </div>
    );
  }
}

export default withTranslation('fieldItem')(FieldItem);
