/* eslint-disable */
import {
  D360_IMPORT_DELETE_FAIL,
  D360_IMPORT_DELETE_START,
  D360_IMPORT_DELETE_SUCCESS,
  D360_IMPORT_STATUS_FAIL,
  D360_IMPORT_STATUS_START,
  D360_IMPORT_STATUS_SUCCESS,
  D360_UPLOAD_FAIL,
  D360_UPLOAD_START,
  D360_UPLOAD_SUCCESS,
} from './constants';

const initialState = {
  status: '',
  errorLine: 0,
  errorMessage: '',
  loading: false,
  gettingStatus: false,
  uploading: false,
  deleting: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case D360_UPLOAD_START:
      return { ...state, uploading: true };

    case D360_UPLOAD_FAIL:
      return { ...state, uploading: false };

    case D360_UPLOAD_SUCCESS:
      return { ...state, uploading: false };

    case D360_IMPORT_STATUS_START:
      return {
        ...state,
        gettingStatus: true,
        status: '',
        errorLine: 0,
        errorMessage: null,
      };

    case D360_IMPORT_STATUS_FAIL:
      return {
        ...state,
        gettingStatus: false,
        status: '',
        errorLine: 0,
        errorMessage: null,
      };

    case D360_IMPORT_STATUS_SUCCESS:
      if (response.data === null) {
        return { ...state, gettingStatus: false, status: '', errorLine: 0, errorMessage: '' };
      } else {
        return {
          ...state,
          gettingStatus: false,
          status: response.data.status,
          errorLine: response.data.error_line,
          errorMessage: response.data.error_message,
        };
      }

    case D360_IMPORT_DELETE_START:
      return {
        ...state,
        deleting: true,
      };

    case D360_IMPORT_DELETE_FAIL:
      return {
        ...state,
        deleting: false,
      };

    case D360_IMPORT_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        status: '',
        errorLine: 0,
        errorMessage: null,
      };

    // Default
    default:
      return state;
  }
};
