/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getStudentStat } from '@/store/students/actions';

import { selectStudents as studentsSelect } from '@/store/students/selectors';

import { Col, Divider, Progress, Row, Typography } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import './profile.css';

const { Title } = Typography;
const { Text } = Typography;
const ACTIVE_USER = 'ACTIVE';
export class Statistics extends Component {
  static propTypes = {
    t: PropTypes.func,
    student: PropTypes.object,
    stat: PropTypes.object.isRequired,
    getStudentStat: PropTypes.func,
  };

  constructor(props) {
    super(props);
    if (this.props.student && this.props.student.status === ACTIVE_USER) {
      this.props.getStudentStat(this.props.student.id);
    }
  }

  renderRecommended = () => {
    const { stat, t } = this.props;

    let total = stat.totalRecommend || 0; // Всего уч. материалов
    let finished = stat.recommendFinished || 0; // Уч. материалов завершено
    let difference = total - finished; // Производная разница
    let percent = 0; // Процент выполнения

    if (total > 0) percent = ((finished / total) * 100).toFixed(2);

    return (
      <div className='recommended'>
        <Row>
          <Col span={24}>
            <Title level={4}>{t('tabStatisticsRecommended')}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <Text>{t('tabStatisticsAll')}</Text>
              </Col>
              <Col span={24}>
                <Text>{t('tabStatisticsCompleted')}</Text>
              </Col>
              <Col span={24}>
                <Text>{t('tabStatisticsLeft')}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24}>{total}</Col>
              <Col span={24}>{finished}</Col>
              <Col span={24}>{difference}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <Progress
                  type='circle'
                  size={80}
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  }}
                  percent={percent}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  renderOthers = () => {
    const { stat, t } = this.props;

    let total = stat.total || 0;
    let finished = stat.finished || 0;
    let difference = total - finished;
    let percent = 0;

    if (total > 0) percent = ((finished / total) * 100).toFixed(2);

    return (
      <div className=''>
        <Row>
          <Col span={24}>
            <Title level={4}>{t('tabStatisticsOther')}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>{t('tabStatisticsAll')}</Text>
          </Col>
          <Col span={16}>{total}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>{t('tabStatisticsCompleted')}</Text>
          </Col>
          <Col span={16}>{finished}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>{t('tabStatisticsLeft')}</Text>
          </Col>
          <Col span={16}>{difference}</Col>
        </Row>
        <Col span={24}>
          <Progress percent={percent} status='active' strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
        </Col>
      </div>
    );
  };
  renderDeletedUser = () => {
    const { t } = this.props;

    return <div className='infoDeletedUser'>{t('infoDeletedUser')}</div>;
  };
  render() {
    return (
      <>
        {this.props.student && this.props.student.status === ACTIVE_USER ? (
          <>
            {this.renderRecommended()}
            <Divider />
            {this.renderOthers()}
          </>
        ) : (
          this.renderDeletedUser()
        )}
      </>
    );
  }
}

const mapStateToProps = createSelector([studentsSelect], students => ({
  stat: students.stat || {},
}));

const mapActionsToProps = {
  getStudentStat,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('studentProfileDialog')(Statistics));
