import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeCoursesFilter, clearCoursesFilter } from '@/store/courses/actions';
import { loadPokaApprovers, savePokaApproversFilters, savePokaApproversSort } from '@/store/poka_approvers/actions';
import { clearStudents, getStudents } from '@/store/students/actions';

import { selectPokaApprovers } from '@/store/poka_approvers/selectors';
import { selectPokaCategories } from '@/store/poka_categories/selectors';
import { selectPokaSites } from '@/store/poka_sites/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Row, Select, Spin } from 'antd';

import { DEFAULT_PARAMS } from '../constants';

import { deleteEmpty } from '@shared/utils/deleteEmpty';

import _ from 'lodash';

import css from '../ApproversDirectory.module.scss';

const FilterApproversDirectory = () => {
  const { t } = useTranslation('ApproversDirectory');
  const dispatch = useDispatch();

  const { position, isLoading } = useSelector(selectStudents);
  const { pokaCategories, loadingPokaCategories } = useSelector(selectPokaCategories);
  const { pokaSites, loadingPokaSites } = useSelector(selectPokaSites);
  const { students, isLoadingStudents } = useSelector(selectStudents);

  const { pokaApproversSort } = useSelector(selectPokaApprovers);

  const [data, setData] = useState({
    userId: null,
    categories: [],
    positions: [],
    siteIds: [],
  });

  const onSubmitFilters = () => {
    const cleanedData = deleteEmpty(data);
    dispatch(savePokaApproversFilters(cleanedData));
    dispatch(loadPokaApprovers({ ...DEFAULT_PARAMS, sort: pokaApproversSort }, cleanedData));
  };

  const onResetFilters = () => {
    clearCoursesFilter();
    setData({ userId: null, categories: [], positions: [], siteIds: [] });
    changeCoursesFilter({ name: 'userId', value: undefined });
    dispatch(savePokaApproversFilters({}));
    dispatch(savePokaApproversSort(''));
    dispatch(loadPokaApprovers(DEFAULT_PARAMS));
    dispatch(clearStudents());
  };

  const renderOptions = arr => {
    let opt =
      Array.isArray(arr) &&
      arr?.length &&
      arr.map(option => {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      });

    return opt;
  };

  const positionOptions = useMemo(() => {
    return Array.isArray(position) && position.length > 0
      ? position.map((option, index) => (
          <Select.Option key={index} value={option}>
            {option}
          </Select.Option>
        ))
      : [];
  }, [position]);

  const usersOptions = useMemo(
    () =>
      Array.isArray(students) &&
      students?.length &&
      students.map(item => {
        let username = `${item?.lastName || ''} ${item?.firstName || ''} ${item?.middleName || ''}`.trim();
        return (
          <Select.Option value={item.id} key={item.id}>
            {username}
          </Select.Option>
        );
      }),
    [students]
  );

  const handleSearchStudents = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudents({ fullName: e, size: 100 }));
    }
  };

  const getStudentsDebounced = useCallback(_.debounce(handleSearchStudents, 1000), []);

  const selectsData = [
    {
      label: t('fullname'),
      placeholder: t('all'),
      options: usersOptions,
      onChange: item => setData({ ...data, userId: item ? item.value : null }),
      value: data.userId ? data.userId : null,
      loading: isLoadingStudents,
      onSearch: getStudentsDebounced,
      labelInValue: true,
    },
    {
      label: t('position'),
      placeholder: t('placeholderSelect'),
      options: positionOptions,
      onChange: positions => setData(prev => ({ ...prev, positions })),
      value: data.positions,
      loading: isLoading,
      mode: 'multiple',
    },
    {
      label: t('category'),
      placeholder: t('placeholderSelect'),
      options: renderOptions(pokaCategories),
      onChange: categories => setData(prev => ({ ...prev, categories })),
      value: data.categories,
      loading: loadingPokaCategories,
      mode: 'multiple',
    },
    {
      label: t('site'),
      placeholder: t('placeholderSelect'),
      options: renderOptions(pokaSites),
      onChange: siteIds => setData(prev => ({ ...prev, siteIds })),
      value: data.siteIds,
      loading: loadingPokaSites,
      mode: 'multiple',
    },
  ];

  const renderFilters = item => {
    return (
      <Form.Item key={item.label} label={item.label} colon={false}>
        <Select
          mode={item.mode}
          showSearch
          allowClear
          onSearch={item.onSearch}
          onChange={item.onChange}
          value={item.value}
          placeholder={item.placeholder}
          optionFilterProp='children'
          loading={item.loading}
          notFoundContent={item.loading ? <Spin size='small' /> : t('nothingFound')}
          labelInValue={item.labelInValue || false}
        >
          {item.options && item.options}
        </Select>
      </Form.Item>
    );
  };

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-txt']}>{t('filters')}</div>
      <Form layout='vertical'>
        <Row type='flex' justify='start' gutter={[16, 0]}>
          {selectsData.map((item, index) => (
            <Col span={12} key={index}>
              {renderFilters(item)}
            </Col>
          ))}
        </Row>

        <Row justify='end' gutter={[16, 0]}>
          <Col>
            <Button onClick={onResetFilters}>{t('reset')}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmitFilters} type='primary'>
              {t('apply')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterApproversDirectory;
