/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { freeBook, getBookInUse } from '@/store/books/actions';
import { getMassSelectionCount } from '@/store/courses/actions';
import { commentExecutiveTask } from '@/store/executive/actions';
import { getTopicById } from '@/store/topics/actions';

import { selectBooks } from '@/store/books/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Dropdown, message } from 'antd';
import ActionButton from '@/pages/ManageCourse/ActionButton';
import { SendCommentModal } from '@/pages/SendReviewModal';
import { useCollectionTopicsContext } from '@/pages/Topics/TopicDetailsDialog/ProviderContextProps';
import ConfirmationModal from '@/components/ConfirmationModal';
import { ArrowRightOutlined } from '@ant-design/icons';

import { DELETED } from '@shared/constants/topic-statuses';
import { isExecutiveTask, isSkills } from '@shared/utils/topic-utils';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

import {
  APPROVED,
  APPROVED_BY_USER,
  FINISHED,
  REJECTED,
  REJECTED_BY_MANAGER,
  WAITING_BY_USER,
} from '@/constants/statuses';

const ActionButtonsMenu = ({ courses }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['coursesTopicRow']);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [confirmRejectUsersModal, setConfirmRejectUsersModal] = useState(false);
  const [confirmDeleteCourseModal, setConfirmDeleteCourseModal] = useState(false);
  const [finishedCourse, setFinishedCourse] = useState(null);
  const [deleteCourseData, setDeleteCourseData] = useState(null);
  const [showModalRejected, setShowModalRejected] = useState(false);
  const [showModalConfirmRejectedCourse, setShowModalConfirmRejectedCourse] = useState(false);
  const { propsValues } = useCollectionTopicsContext();
  const [isVisible, setIsVisible] = useState(false);
  const [showBookModal, setShowBookModal] = useState(false);
  const [returnBookText, setReturnBookText] = useState('');

  const { currentUser } = useSelector(selectUsers);
  const { bookInUseByUserId } = useSelector(selectBooks);

  const texts = {
    reason: t('reason'),
    comment: t('comment'),
    denying: t('denying'),
    deleting: t('deleting'),
    rescheduling: t('rescheduling'),
    max: t('max'),
  };

  const courseRequestFinish = course => {
    const ids = course.plan.id;
    propsValues.courseRequestFinish(
      ids,
      false,
      () => {
        propsValues.getCoursesRequestTopic(
          {
            topicId: propsValues.topicId,
            ...propsValues.filter,
          },
          propsValues.subCompanies,
          propsValues.page - 1,
          !propsValues.withEvents
        );

        hideConfirmRejectUser();
      },
      () => hideConfirmRejectUser()
    );
  };

  const finishCourses = course => {
    confirmRejectUser();
    setFinishedCourse(course);
  };

  const confirmRejectUser = () => {
    setConfirmRejectUsersModal(true);
    setDropdownVisible(false);
  };

  const hideConfirmRejectUser = () => {
    setConfirmRejectUsersModal(false);
    setFinishedCourse(null);
  };

  const submitAcceptExecutiveTopic = value => {
    propsValues.updateCoursesRequest(value, '', [courses.id], () => {
      propsValues.getCoursesRequestTopic(
        {
          topicId: propsValues.topicId,
          ...propsValues.filter,
        },
        propsValues.subCompanies,
        propsValues.page - 1,
        !propsValues.withEvents
      );

      propsValues.changeSelect({ courseId: courses.id });

      setShowModalRejected(false);
    });
  };

  const coursesRequestDelete = course => {
    //удаление одиночное через Действие

    const userId = course.plan.user.id;
    const bookData = bookInUseByUserId[userId];
    const userName = bookData?.userName;
    const address = bookData?.address;

    if (bookData) {
      setReturnBookText(`${t('returnBookText', { userName, address })}\n${t('returnBookUserDelete')}`);
      setShowBookModal(true);
      hideConfirmDeleteCourse();
      return;
    }

    deleteCourse(course);
  };

  const deleteCourse = course => {
    const userId = course.plan.user.id;
    const topicId = propsValues.topicId;

    const ids = [{ topicId, userId }];
    propsValues.deleteRequestCourses(
      ids,
      true,
      () => {
        propsValues.getCoursesRequestTopic(
          {
            topicId,
            ...propsValues.filter,
          },
          propsValues.subCompanies,
          propsValues.page - 1,
          !propsValues.withEvents
        );
        hideConfirmDeleteCourse();
        dispatch(getMassSelectionCount(topicId));
        dispatch(getBookInUse(topicId));
        dispatch(getTopicById(topicId));
      },
      () => hideConfirmDeleteCourse()
    );
  };

  const onFreeBookAndDeleteCourse = () => {
    if (deleteCourseData) {
      const userId = deleteCourseData.plan.user.id;
      const topicId = propsValues.topicId;
      const body = { topicId, userIds: [userId] };

      dispatch(
        freeBook(
          body,
          () => deleteCourse(deleteCourseData),
          err => message.error(t('returnBookFail'))
        )
      );
    }

    setShowBookModal(false);
  };

  const deleteCourses = course => {
    confirmDeleteCourse();
    setDeleteCourseData(course);
    setDropdownVisible(false);
  };

  const confirmDeleteCourse = () => {
    setConfirmDeleteCourseModal(true);
  };

  const hideConfirmDeleteCourse = () => {
    setConfirmDeleteCourseModal(false);
  };

  const changeCoursesStatus = (event, courses, autocomplete = false) => {
    setDropdownVisible(false);

    if (!!event) {
      if (event === FINISHED && propsValues.typeTopicId) {
        propsValues.changeSelect({
          name: courses.id.toString(),
          value: true,
        });

        submitAcceptExecutiveTopic(event);
      }

      if (event === APPROVED_BY_USER) {
        propsValues.changeSelect({
          name: courses.id.toString(),
          value: true,
        });
      }

      if (event === REJECTED || propsValues.allSelect) {
        propsValues.changeSelect({
          name: courses.id.toString(),
          value: event === APPROVED,
        });
      }

      if (event === REJECTED) {
        setShowModalRejected(true);
      } else {
        submitRejectedCourseRequest(event, '');
      }
    }

    if (autocomplete) {
      propsValues.setUserId(courses.id);
      if (propsValues.eventList.length === 0) {
        propsValues.showEventCreateDialog();
      } else {
        propsValues.showSelectEventDialog();
      }
    }
  };

  const submitRejectedExecutiveTopic = (value, comment) => {
    if (value === REJECTED || value === REJECTED_BY_MANAGER) {
      dispatch(
        commentExecutiveTask(courses.plan.user.id, courses.id, comment, () => {
          courses.plan.status = REJECTED;
          setShowModalRejected(false);
          updateTable();
        })
      );
    }
  };

  const submitRejectedCourseRequest = (value, comment) => {
    if (isExecutiveTask(propsValues?.typeTopicId) || isSkills(propsValues?.typeTopicId)) {
      submitRejectedExecutiveTopic(value, comment);
      return;
    }

    propsValues.updateCoursesRequest(value, comment, [courses.id], () => {
      propsValues.getCoursesRequestTopic(
        {
          topicId: propsValues.topicId,
          ...propsValues.filter,
        },
        propsValues.subCompanies,
        propsValues.page - 1,
        !propsValues.withEvents
      );

      propsValues.changeSelect({ courseId: courses.id });

      setShowModalRejected(false);
    });
  };

  const commentSubmitRejectedCourseRequest = comment => {
    submitRejectedCourseRequest(REJECTED, comment);
  };

  const hideRejectedCourseRequest = comment => {
    if (comment?.length > 0) {
      return setShowModalConfirmRejectedCourse(!showModalConfirmRejectedCourse);
    }

    setShowModalRejected(false);
  };

  const updateTable = () => {
    propsValues.getCoursesRequestTopic(
      {
        topicId: propsValues.topicId,
        ...propsValues.filter,
      },
      propsValues.subCompanies,
      propsValues.page - 1,
      !propsValues.withEvents
    );
  };

  const menu = (
    <ActionButton
      courses={courses}
      typeTopicId={propsValues.typeTopicId}
      status={courses.plan.status}
      finishCourses={finishCourses}
      deleteCourses={deleteCourses}
      addUserEventHandler={propsValues.addUserEventHandler}
      changeToComplitedSingle={propsValues.changeToComplitedSingle}
      toggleModalCalendars={propsValues.toggleModalCalendars}
      currentUser={currentUser}
      changeCoursesStatus={changeCoursesStatus}
      withTransfer={propsValues.isAdminPlanMan}
      showOrganized={false}
      toggleModalTestResults={propsValues.toggleModalTestResults}
      onClose={() => setIsVisible(false)}
      updateTable={updateTable}
      topicId={propsValues.topicId}
      topic={propsValues.topic}
    />
  );

  const disabledActionButtons =
    courses.plan &&
    (courses.plan.user.status === DELETED ||
      courses.plan.status === WAITING_BY_USER ||
      courses.plan.status === APPROVED_BY_USER);

  return (
    <>
      <Dropdown
        overlay={menu}
        placement='topLeft'
        trigger={['click']}
        onOpenChange={e => setIsVisible(e)}
        open={isVisible}
        disabled={disabledActionButtons}
      >
        <Button className={css['Table-actionBtn']} disabled={disabledActionButtons} type='link'>
          <ArrowRightOutlined />
        </Button>
      </Dropdown>

      {confirmRejectUsersModal ? (
        <ConfirmationModal
          open
          disabled={false}
          onOk={() => finishedCourse && courseRequestFinish(finishedCourse)}
          onCancel={hideConfirmRejectUser}
          onCancelButton={hideConfirmRejectUser}
          okText={t('yes')}
          cancelText={t('no')}
          confirmationText={t('description')}
        />
      ) : null}
      {confirmDeleteCourseModal ? (
        <ConfirmationModal
          open
          disabled={false}
          onOk={() => deleteCourseData && coursesRequestDelete(deleteCourseData)}
          onCancel={hideConfirmDeleteCourse}
          onCancelButton={hideConfirmDeleteCourse}
          okText={t('yes')}
          cancelText={t('no')}
          confirmationText={t('description2')}
          deleteBtn
        />
      ) : null}

      <SendCommentModal
        rejected
        open={showModalRejected}
        submitDeleteComment={commentSubmitRejectedCourseRequest}
        cancelDeleteComment={hideRejectedCourseRequest}
        texts={texts}
      />

      {showModalConfirmRejectedCourse ? (
        <ConfirmationModal
          open
          onOk={() => {
            setShowModalConfirmRejectedCourse(false);
            setShowModalRejected(false);
          }}
          onCancel={() => setShowModalConfirmRejectedCourse(false)}
          onCancelButton={() => setShowModalConfirmRejectedCourse(false)}
          okText={t('close')}
          disabled={false}
          cancelText={t('no')}
          zIndex={1006}
          confirmationText={t('closeWithout')}
        />
      ) : null}

      <ConfirmationModal
        open={showBookModal}
        onOk={onFreeBookAndDeleteCourse}
        onCancel={() => setShowBookModal(false)}
        onCancelButton={() => setShowBookModal(false)}
        okText={t('returnBookYes')}
        disabled={false}
        cancelText={t('returnBookNo')}
        confirmationText={returnBookText}
        zIndex={1001}
      />
    </>
  );
};

ActionButtonsMenu.propTypes = {
  isAdminPlanMan: PropTypes.bool,
  typeTopicId: PropTypes.any,
  courses: PropTypes.object,
};

export default ActionButtonsMenu;
