import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteTypicalPosition,
  editTypicalPosition,
  getPokaPositionTemplate,
  importPokaPosition,
  loadTypicalPositions,
  saveTypicalPositionSort,
} from '@/store/poka_typicalPositions/actions';

import { selectPokaTypicalPositions } from '@/store/poka_typicalPositions/selectors';

import { Button, message, Row, Table, Tooltip } from 'antd';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import { DEFAULT_PARAMS, DELETE, EDIT, MAX_TEXT_LENGTH, SORT_VALUES } from '../constants';
import ModalPositionAssignment from './ModalPositionAssignment';

import Utils from '@/Utils';
import classNames from 'classnames';

import css from '../PositionAssignmentDirectory.module.scss';

const TablePositions = () => {
  const { t } = useTranslation('PositionAssignmentDirectory');
  const dispatch = useDispatch();

  const [isPosition, setIslPosition] = useState({ open: false, name: '', id: null });
  const [isActiveSort, setIsActiveSort] = useState({ aZ: false, zA: false });
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const {
    loadingTypicalPositions,
    typicalPositions,
    positionsTotal,
    positionsPageSize,
    positionsPageNumber,
    positionsCurrentPage,
    positionsFilters,
    positionsSort,
  } = useSelector(selectPokaTypicalPositions);

  const columns = [
    {
      title: t('typicalPosition'),
      dataIndex: 'typicalPosition',
      key: '1',
      width: 600,
      render: (_, record) => {
        const typicalPosition = record.typicalPosition || '';
        return (
          <Tooltip title={typicalPosition.length > MAX_TEXT_LENGTH ? typicalPosition : ''}>
            {Utils.trString(typicalPosition, MAX_TEXT_LENGTH)}
          </Tooltip>
        );
      },
    },
    {
      title: t('position'),
      dataIndex: 'positions',
      key: '2',
      width: 600,
      render: positions => {
        const positionsString = Array.isArray(positions) && positions.join(', ');
        return (
          <Tooltip title={positionsString.length > MAX_TEXT_LENGTH ? positionsString : ''}>
            {Utils.trString(positionsString, MAX_TEXT_LENGTH)}
          </Tooltip>
        );
      },
    },
    {
      title: t('action'),
      dataIndex: 'action',
      key: '3',
      width: 165,
      align: 'center',
      render: (_, item) => (
        <div className={css['Table-area-buttons']}>
          <Tooltip title={t('editInfo')}>
            <FormOutlined
              className={css['Table-area-buttons-editBtn']}
              onClick={() => setIslPosition({ open: true, mode: EDIT, ...item })}
            />
          </Tooltip>

          <Tooltip title={t('deleteInfo')}>
            <DeleteOutlined
              className={css['Table-area-buttons-deleteBtn']}
              onClick={() => setIslPosition({ open: true, mode: DELETE, ...item })}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const pagination = {
    total: positionsTotal,
    pageSize: positionsPageSize,
    pageNumber: positionsPageNumber,
    current: positionsCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const dataSource = useMemo(
    () =>
      typicalPositions?.length > 0 &&
      typicalPositions.map(item => ({
        typicalPosition: item.modelPositionName,
        id: item.id,
        positions: item.positions,
      })),
    [typicalPositions]
  );

  const onChangeTable = ({ current }) => {
    const page = current - 1;
    const queryParams = { size: DEFAULT_PARAMS.size, page, sort: positionsSort };
    dispatch(loadTypicalPositions(queryParams, positionsFilters));
  };

  const refreshPositionsAndCloseModal = () => {
    const queryParams = { size: DEFAULT_PARAMS.size, page: positionsCurrentPage, sort: positionsSort };
    dispatch(loadTypicalPositions(queryParams, positionsFilters));
    setIslPosition({ open: false, name: '', id: null });
  };

  const onUpdatePosition = data => {
    if (isPosition?.id) {
      dispatch(editTypicalPosition(isPosition.id, data, () => refreshPositionsAndCloseModal()));
    } else {
      message.warning(t('warningCreatePosition'));
    }
  };

  const onDeletePosition = () => dispatch(deleteTypicalPosition(isPosition?.id, () => refreshPositionsAndCloseModal()));

  const sortTable = (sortName, sortValue) => {
    setIsActiveSort({ [sortName]: !isActiveSort[sortName] });

    dispatch(saveTypicalPositionSort(!isActiveSort[sortName] ? sortValue : ''));

    dispatch(
      loadTypicalPositions(
        { page: positionsCurrentPage, size: DEFAULT_PARAMS.size, sort: !isActiveSort[sortName] ? sortValue : '' },
        positionsFilters
      )
    );
  };

  const onOpenImportModal = () => setIsImportModalOpen(true);
  const onCloseImportModal = () => setIsImportModalOpen(false);

  return (
    <div className={css['Table']}>
      {/* Блок сортировки + кнопка */}
      <div className={css['Table-actions']}>
        <div className={css['Table-actions-sort']}>
          <div className={css['Table-actions-sort-text']}>{t('sort')}</div>
          <Row className={css['Table-actions-az']}>
            <div
              className={classNames(positionsSort.includes(SORT_VALUES.NAME_ASC) && css['Table-actions-sort-active'])}
              onClick={() => sortTable('aZ', SORT_VALUES.NAME_ASC)}
            >
              {t('aZ')}
            </div>
            <div
              className={classNames(positionsSort.includes(SORT_VALUES.NAME_DESC) && css['Table-actions-sort-active'])}
              onClick={() => sortTable('zA', SORT_VALUES.NAME_DESC)}
            >
              {t('zA')}
            </div>
          </Row>
        </div>
        <Button size='large' type='primary' onClick={onOpenImportModal}>
          {t('importBtn')}
        </Button>
      </div>

      <div className={css['Table-area']}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey={e => e?.id}
          pagination={pagination}
          onChange={onChangeTable}
          loading={loadingTypicalPositions}
        />

        {/* модалка для редактирования и удаления типовой должности */}
        {isPosition.open && (
          <ModalPositionAssignment
            open={isPosition.open}
            onCancel={() => setIslPosition({ open: false, name: '', id: null })}
            title={isPosition.mode === EDIT ? t('updatePosition') : t('deletePosition')}
            positionData={isPosition}
            onOk={isPosition.mode === EDIT ? onUpdatePosition : onDeletePosition}
            onOkText={isPosition.mode === EDIT ? t('updateOk') : t('deleteBtn')}
          />
        )}

        {/* модалка для импорта типовых должностей */}
        {isImportModalOpen && (
          <DictionaryImportDialog
            open={isImportModalOpen}
            onCancel={onCloseImportModal}
            onOk={(params, onSuccess) => dispatch(importPokaPosition(params, onSuccess))}
            fileDescription={t('downloadExampleButton')}
            description1={t('importCsvInstruction')}
            exampleFileFunc={template => dispatch(getPokaPositionTemplate(template))}
            isPokaPosition
          />
        )}
      </div>
    </div>
  );
};

export default TablePositions;
