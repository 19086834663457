/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getResultStatistic } from '@/store/lms/actions';

import { selectBlocks } from '@/store/lms/selectors';

import { Button, Input, Table, Tooltip } from 'antd';
import TestActionDropdown from '@/pages/Topics/TopicDetailsDialog/LMS/Blocks/test/TestActionDropdown';
import Condition from '@/components/Condition';
import BlockStatusTag from '@/components/CoursesTopicMembersTable/BlockStatusTag';
import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';

import TestDetailsModal from './TestDeatilsModal';
import TestDetailsContent from './TestDetailsContent';
import { BlockStatus } from './types';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../index.module.scss';

import articleIcon from '@/assets/images/article.svg';
import scormIcon from '@/assets/images/scorm.svg';
import testIcon from '@/assets/images/test.svg';
import videoIcon from '@/assets/images/video.svg';

const initialTestStatistic = {
  isModalOpen: false,
};

const SCORM = 2;
const VIDEO = 3;
const TEST = 1;
const ARTICLE = 5;

const ResultTable = ({ getFile, changeTestStatus, filterTable, setFilterTable }) => {
  const { t } = useTranslation('result');
  const dispatch = useDispatch();

  const [isModalResultsOpen, setModalResultsOpen] = useState(initialTestStatistic);
  const { statisticTotal, statisticPageSize, statisticPageNumber, statisticCurrentPage, statistic, isLoadingTable } =
    useSelector(selectBlocks);

  const isAccepted = status => status === BlockStatus.FINISHED;
  const isWaitingToApprove = status => status === BlockStatus.WAITING;

  const isTestStarted = ({ test, blockStatus }) => {
    if (!!test && (isWaitingToApprove(blockStatus) || isAccepted(blockStatus))) {
      return 'testId' in test;
    }
    return false;
  };

  const isTestOrScrom = ({ blockTypeId }) => blockTypeId === TEST || blockTypeId === SCORM;

  const showResultsModal = block => {
    const targetBlock = statistic?.find(item => item.key === block.key);

    const newState = {
      isModalOpen: true,
      testStatistic: targetBlock,
    };
    setModalResultsOpen(newState);
  };

  const closeModal = () => setModalResultsOpen(initialTestStatistic);

  const columns = [
    {
      title: t('name'),
      dataIndex: `fullName`,
      render: (text, block) => <>{block.fullName} </>,
      width: '15%',
      fixed: 'left',
    },
    {
      title: t('title'),
      dataIndex: `blockName`,
      width: '355px',
      render: (text, block) => (
        <div className={css['Result-table-body-block']}>
          <Condition when={block.blockTypeId === TEST}>
            <img src={testIcon} className={css['Result-table-body-block-icon']} alt='' />
          </Condition>
          <Condition when={block.blockTypeId === SCORM}>
            <img src={scormIcon} className={css['Result-table-body-block-icon']} alt='' />
          </Condition>
          <Condition when={block.blockTypeId === VIDEO}>
            <img src={videoIcon} className={css['Result-table-body-block-icon']} alt='' />
          </Condition>
          <Condition when={block.blockTypeId === ARTICLE}>
            <img src={articleIcon} className={css['Result-table-body-block-icon']} alt='' />
          </Condition>
          <Tooltip className={css['Result-table-body-block-text']} title={block.blockName}>
            {block.blockName}
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('deadlinePeriod'),
      dataIndex: 'studyPlanStartDate',
      width: '150px',
      render: (text, record) => (
        <>{record.studyPlanStartDate ? dayjs(record.studyPlanStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''}</>
      ),
    },
    {
      title: t('status'),
      dataIndex: `blockStatusName`,
      width: '150px',
      render: (text, record) => (
        <div className={css['Result-table-body-name']}>
          <BlockStatusTag record={record} />
        </div>
      ),
    },
    {
      title: t('startDate'),
      dataIndex: `blockStartDateTime`,
      width: '13%',
      render: (text, block) => (
        <Condition when={block.blockStartDateTime}>
          {`
            ${dayjs(block.blockStartDateTime).format('DD.MM.YYYY')}
            ${t('in')}
            ${dayjs(block.blockStartDateTime).format('LT')}
          `}
        </Condition>
      ),
    },
    {
      title: t('endDate'),
      dataIndex: `blockEndDateTime`,
      width: '13%',
      render: (text, block) => (
        <Condition when={block.blockEndDateTime && block.blockStatus === 'FINISHED'}>
          {`
            ${dayjs(block.blockEndDateTime).format('DD.MM.YYYY')}
            ${t('in')}
            ${dayjs(block.blockEndDateTime).format('LT')}
          `}
        </Condition>
      ),
    },
    {
      title: t('result'),
      dataIndex: `percentageResult`,
      width: '10%',
      render: (text, block) => <>{block.percentageResult}</>,
    },
    {
      title: t('outcome'),
      dataIndex: `summary`,
      width: '13%',
      render: (text, block) => <div className={css['Result-table-body-name']}>{block.summary}</div>,
    },
    {
      title: <ArrowRightOutlined />,
      dataIndex: `blockTypeId`,
      width: '50px',
      align: 'center',
      fixed: 'right',
      render: (text, block) => (
        <>
          {isTestStarted(block) && isTestOrScrom(block) && (
            <div className={css['Result-table-body-name']}>
              <TestActionDropdown
                showTestResultModal={showResultsModal}
                changeTestStatus={changeTestStatus}
                block={block}
                filterTable={filterTable}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  const resultStatiscticDataFilter = statistic
    ?.filter(item => item.blockStatus === 'FINISHED' || item.userStatus !== 'DELETED')
    .map((item, idx) => {
      item.key = `${item.blockId}-${item.userId}-${idx}`;
      return item;
    });

  const onChangeTable = (pagination, filters) => {
    setFilterTable(prevFilterTable => {
      dispatch(
        getResultStatistic(
          {
            ...prevFilterTable,
            status: prevFilterTable.status || '',
            fullName: prevFilterTable.fullName,
            blockTypeId: prevFilterTable.blockTypeId || undefined,
          },
          pagination?.current ? pagination.current - 1 : 0
        )
      );

      return {
        ...prevFilterTable,
        status: prevFilterTable.status || undefined,
        blockTypeId: prevFilterTable.blockTypeId || undefined,
      };
    });
  };

  const pagination =
    statisticPageNumber > 1
      ? {
          total: statisticTotal,
          pageSize: statisticPageSize,
          pageNumber: statisticPageNumber,
          current: statisticCurrentPage + 1,
          showSizeChanger: false,
          responsive: true,
        }
      : false;

  return (
    <>
      <Table
        className={css['ant-table']}
        rowKey={record => record.key}
        locale={{ emptyText: t('first') }}
        dataSource={resultStatiscticDataFilter}
        columns={columns}
        pagination={pagination}
        onChange={onChangeTable}
        loading={isLoadingTable}
        scroll={{ x: true }}
      />

      {isModalResultsOpen.testStatistic && (
        <TestDetailsModal testItemData={isModalResultsOpen} closeModal={closeModal}>
          <TestDetailsContent testItemData={isModalResultsOpen.testStatistic} getFile={getFile} />
        </TestDetailsModal>
      )}
    </>
  );
};

ResultTable.propTypes = {
  getFile: PropTypes.func,
  changeTestStatus: PropTypes.func,
  filterTable: PropTypes.object,
  setFilterTable: PropTypes.func,
};

export default ResultTable;
