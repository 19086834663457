import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360CreateReport, D360StatusReport } from '@/store/d360/d360_reports/actions';

import { Button, Modal, Tooltip } from 'antd';
import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';

import { API_D360_URL } from '@/store/d360/service_api';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const ConsolidateReportButton = props => {
  const dispatch = useDispatch();
  const { report, loading } = useSelector(state => state.d360Reports);
  const { t } = useTranslation('D360_IndividualReports');

  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(true);
  const [modal, contextHolder] = Modal.useModal();

  const getReportStatus = () => {
    setDownloadButtonDisabled(true);
    setDeleteButtonDisabled(true);
    setSyncButtonDisabled(true);
    dispatch(D360StatusReport(props.projectId));
  };

  useEffect(() => {
    getReportStatus();
  }, []);

  useEffect(() => {
    if (!_.isNil(report) && _.isObject(report) && _.has(report, 'status') && _.isString(report.status)) {
      switch (report.status.toString().toLowerCase()) {
        case 'new':
        case 'started':
          setDownloadButtonDisabled(true);
          setDeleteButtonDisabled(true);
          setSyncButtonDisabled(false);
          break;

        case 'finished':
          setDownloadButtonDisabled(false);
          setDeleteButtonDisabled(false);
          setSyncButtonDisabled(true);
          break;

        default:
          setDownloadButtonDisabled(true);
          setDeleteButtonDisabled(true);
          setSyncButtonDisabled(false);
      }
    }
  }, [report]);

  const handleClick = filename => {
    const url = `${API_D360_URL}/report/download?id=${props.projectId}`;
    const headers = {
      Authorization: localStorage.getItem('authorizationToken') || '',
    };

    fetch(url, { headers })
      .then(response => {
        if (response.ok) {
          response.blob().then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          });
        }
      })
      .catch(err => {
        console.log('ERROR: ' + err);
      });
  };

  const onCreateReport = (renew = false) => {
    setDeleteButtonDisabled(true);
    setDownloadButtonDisabled(true);

    dispatch(
      D360CreateReport(
        props.projectId,
        renew,
        () => {
          dispatch(D360StatusReport(props.projectId));
        },
        () => {
          dispatch(D360StatusReport(props.projectId));
        }
      )
    );
  };

  const renderComponent = () => {
    if (!_.isNil(report) && _.isObject(report)) {
      if (_.has(report, 'status') && _.isString(report.status)) {
        switch (report.status.toString().toLowerCase()) {
          case 'new':
          case 'started':
            return (
              <Button.Group>
                <Button loading={true}>{t(`report_status_${report.status}`)}...</Button>
                <Tooltip title={t('report_check')}>
                  <Button
                    type='primary'
                    icon={<SyncOutlined />}
                    onClick={() => getReportStatus()}
                    disabled={syncButtonDisabled}
                  />
                </Tooltip>
                {/* <Button type='primary' danger icon={<SyncOutlined />} onClick={() => onCreateReport(true)} /> */}
              </Button.Group>
            );
          case 'finished':
            return (
              <Button.Group
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button
                  type='primary'
                  icon={<DownloadOutlined />}
                  onClick={() => handleClick(report.filename)}
                  disabled={downloadButtonDisabled}
                >
                  {t(`report_status_${report.status}`)}
                </Button>

                <Tooltip title={t(`report_renew`)}>
                  <Button
                    type='primary'
                    danger
                    icon={<SyncOutlined />}
                    onClick={() => onCreateReport(true)}
                    disabled={deleteButtonDisabled}
                  />
                </Tooltip>
              </Button.Group>
            );

          default:
            return (
              <Button
                type='primary'
                loading={loading}
                onClick={() => {
                  return modal.confirm({
                    title: t('consolidateReportConfirmTitle'),
                    content: t('consolidateReportConfirmText'),
                    okText: t('consolidateReportConfirmOkButton'),
                    cancelText: t('consolidateReportConfirmCancelButton'),
                    okType: 'primary',
                    okButtonProps: {
                      danger: true,
                    },
                    onOk() {
                      onCreateReport();
                    },
                  });
                }}
              >
                {t(`report_status_${report.status}`)}
              </Button>
            );
        }
      }
    }

    return null;
  };

  return (
    <>
      {contextHolder}
      {renderComponent()}
    </>
  );
};

ConsolidateReportButton.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default ConsolidateReportButton;
