import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getMismatch,
  loadTypicalPositions,
  saveTypicalPositionFilters,
  saveTypicalPositionSort,
} from '@/store/poka_typicalPositions/actions';

import { selectPokaTypicalPositions } from '@/store/poka_typicalPositions/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Row, Select } from 'antd';

import { DEFAULT_PARAMS } from '../constants';

import { deleteEmpty } from '@shared/utils/deleteEmpty';

import css from '../PositionAssignmentDirectory.module.scss';

const DirectorySelect = ({ label, placeholder, onSearch, options, onChange, value, loading }) => (
  <Form.Item label={label} colon={false}>
    <Select
      mode='multiple'
      showSearch
      allowClear
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      optionFilterProp='children'
      loading={loading}
    >
      {options &&
        options?.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
    </Select>
  </Form.Item>
);

const Filters = () => {
  const { t } = useTranslation('PositionAssignmentDirectory');
  const dispatch = useDispatch();

  const { position, isLoading } = useSelector(selectStudents);
  const { typicalPositionsList, loadingTypicalPositionsList } = useSelector(selectPokaTypicalPositions);

  const [data, setData] = useState({ functionNames: [], modelPositionIds: [] });

  const onSubmitFilters = () => {
    const cleanedData = deleteEmpty(data);
    dispatch(saveTypicalPositionFilters(cleanedData));
    dispatch(loadTypicalPositions(DEFAULT_PARAMS, cleanedData));
    dispatch(getMismatch());
  };

  const onResetFilters = () => {
    setData({ functionNames: [], modelPositionIds: [] });
    dispatch(saveTypicalPositionFilters({}));
    dispatch(saveTypicalPositionSort(''));
    dispatch(loadTypicalPositions(DEFAULT_PARAMS));
  };

  const positionOptions =
    Array.isArray(position) && position.length > 0 && position.map(item => ({ value: item, label: item }));

  const selectsData = [
    {
      label: t('typicalPosition'),
      placeholder: t('placeholderSelect'),
      options: typicalPositionsList,
      onChange: modelPositionIds => setData(prev => ({ ...prev, modelPositionIds })),
      value: data.modelPositionIds,
      loading: loadingTypicalPositionsList,
    },
    {
      label: t('searchPositions'),
      placeholder: t('placeholderSelect'),
      options: positionOptions,
      onChange: functionNames => setData(prev => ({ ...prev, functionNames })),
      value: data.functionNames,
      loading: isLoading,
    },
  ];

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-txt']}>{t('filters')}</div>
      <Form layout='vertical'>
        <Row type='flex' justify='start' gutter={[16, 0]}>
          {selectsData.map((data, index) => (
            <Col span={12} key={index}>
              <DirectorySelect {...data} />
            </Col>
          ))}
        </Row>

        <Row justify='end' gutter={[16, 0]}>
          <Col>
            <Button onClick={onResetFilters}>{t('reset')}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmitFilters} type='primary'>
              {t('apply')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filters;
