export const ANSWER_TYPES = [
  { id: 1, name: 'answerType.0', value: 1 },
  { id: 2, name: 'answerType.1', value: 2 },
  { id: 3, name: 'answerType.2', value: 3 },
];

export const DEFAULT_FILTERS = {
  name: undefined,
  type: undefined,
  categoryIds: undefined,
};
