/* eslint-disable */
import {
  D360_CREATE_RESPONDENT_FAIL,
  D360_CREATE_RESPONDENT_START,
  D360_CREATE_RESPONDENT_SUCCESS,
  D360_DELETE_RESPONDENT_FAIL,
  D360_DELETE_RESPONDENT_START,
  D360_DELETE_RESPONDENT_SUCCESS,
  D360_GET_RESPONDENTS_FAIL,
  D360_GET_RESPONDENTS_START,
  D360_GET_RESPONDENTS_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  respondents: [],
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    case D360_CREATE_RESPONDENT_START:
    case D360_DELETE_RESPONDENT_START:
    case D360_GET_RESPONDENTS_START:
      return { ...state, loading: true };

    case D360_CREATE_RESPONDENT_FAIL:
    case D360_DELETE_RESPONDENT_FAIL:
    case D360_GET_RESPONDENTS_FAIL:
      return { ...state, loading: false };

    case D360_CREATE_RESPONDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        respondents: state.respondents.concat(response.data),
      };

    case D360_DELETE_RESPONDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        respondents: state.respondents.filter(respondent => respondent.id !== response.data.id),
      };

    case D360_GET_RESPONDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        respondents: response.data,
      };

    default:
      return state;
  }
};
