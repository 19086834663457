/* eslint-disable */
import React, { useState } from 'react';

import { Button, Modal } from 'antd';
import { CoinsIcon } from '@/components/elements';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './ModerateTopicModal.module.scss';

const CreatedCourse = ({ income, coinsIcon }) => {
  const { firstName, lastName } = _.get(income, 'user', {});
  const amount = _.get(income, 'amount', 0);

  return (
    <div className={css['ModerateTopicModal-header-coinsBlock']}>
      <p>
        За добавление материала {firstName} {lastName}{' '}
        <span className={css['ModerateTopicModal-header-coinsCount']}>
          получит {amount}
          <CoinsIcon coinsIcon={coinsIcon} className={css['ModerateTopicModal-header-coinsCount-icon']} />
        </span>
      </p>
    </div>
  );
};

const EndedCourse = ({ income, coinsIcon }) => {
  const [showUsers, setShowUsers] = useState(false);
  const toggleUsers = () => setShowUsers(!showUsers);

  const getSeparator = (length, ind) => (length > 1 && length - 1 !== ind ? '| ' : '');

  const users = _.map(income, ({ user, amount }, ind, users) => (
    <>
      {user.lastName} {user.firstName[0]}. {amount}{' '}
      <CoinsIcon coinsIcon={coinsIcon} className={css['ModerateTopicModal-header-coinsCount-iconSmall']} />{' '}
      {getSeparator(users.length, ind)}
    </>
  ));

  return (
    <div className={css['ModerateTopicModal-header-coinsBlock']}>
      <p>
        <button className={css['ModerateTopicModal-header-showUsers']} onClick={toggleUsers}>
          Пользователи
        </button>
        , завершившие материал, <span className={css['ModerateTopicModal-header-coinsCount']}>получат:</span>
      </p>
      {showUsers && (
        <p className={css['ModerateTopicModal-header-users']}>
          {users.map(user => (
            <span>{user}</span>
          ))}
        </p>
      )}
    </div>
  );
};

export const ModerateTopicModal = ({ open, onClose, onOk, topicIncome, coinsIcon }) => {
  const createdIncome = _.filter(topicIncome, { bonus: 'TOPIC' })[0];
  const endedIncome = _.filter(topicIncome, { bonus: 'TOPIC_COMPLETE' });
  return (
    <Modal open={open} width={550} onCancel={onClose} footer={null}>
      <div className={classNames(css['App-card-item'])}>
        <div className={css['ModerateTopicModal-header']}>
          {createdIncome && <CreatedCourse income={createdIncome} coinsIcon={coinsIcon} />}
          {!!endedIncome.length && <EndedCourse income={endedIncome} coinsIcon={coinsIcon} />}
          <div>Подтвердить модерацию материала?</div>
        </div>

        <div className={css['ModerateTopicModal-btnGroup']}>
          <Button className={css['ModerateTopicModal-btnGroup-submit']} onClick={onOk} type='primary'>
            Да
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </div>
      </div>
    </Modal>
  );
};

ModerateTopicModal.propTypes = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  coins: PropTypes.any,
  topicIncome: PropTypes.array,
};
