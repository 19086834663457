/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Col, Modal, Row, Table } from 'antd';

import {
  PROJECT_STATUS_DRAFT,
  RESPONDENT_TYPE_COLLEAGUE,
  RESPONDENT_TYPE_CROSS,
  RESPONDENT_TYPE_MANAGER,
  RESPONDENT_TYPE_SELF,
  RESPONDENT_TYPE_SUBORDINATE,
} from '../../../constants';
import { extractUsername } from '../../../utils';

import classNames from 'classnames';
import plural from 'plural-ru';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const selectedTemplate = { keys: [], rows: [] };

export const RespondentsTable = props => {
  const { t } = useTranslation('D360_Respondents');

  const { project } = useSelector(state => state.d360Project);

  const [selected, setSelected] = useState(selectedTemplate);
  const [disableSelAction, setDisableSelAction] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const isDraft = project.d360_status_id === PROJECT_STATUS_DRAFT;

  /**
   * Отслеживаем изменения selected
   */
  useEffect(() => {
    selected.keys.length > 0 ? setDisableSelAction(false) : setDisableSelAction(true);
  }, [selected]);

  /**
   * Столбцы таблицы оцениваемых.
   *
   * @returns {*[]}
   */
  const tableColumns = () => {
    return [
      {
        title: t('columnFullname'),
        key: 'fullname',
        dataIndex: 'fullname',
        render: (name, record) => extractUsername(record.student),
      },
      {
        title: t('columnEmail'),
        key: 'email',
        dataIndex: 'email',
        render: (name, record) => record.student.email,
      },
      {
        title: t('columnDepartment'),
        key: 'department',
        dataIndex: 'department',
        render: (name, record) => record.student.department,
      },
      {
        title: t('columnPosition'),
        key: 'position',
        dataIndex: 'position',
        render: (name, record) => record.student.position,
      },
      {
        title: t('columnFunction'),
        key: 'function',
        dataIndex: 'function',
        render: (name, record) => record.student.function,
      },
    ];
  };

  /* Обработка выбора пользователей в таблице Критерии.
   * @returns {{selectedRowKeys: Array, onChange: onChange}}
   */
  const rowSelection = () => {
    if (props.editable) {
      return {
        selectedRowKeys: selected.keys,
        onChange: (selectedKeys, selectedRows) => {
          setSelected({ ...selected, keys: selectedKeys, rows: selectedRows });
        },
      };
    }
  };

  /**
   * Проверка респондентов перед удалением
   */

  const onConfirmDelete = () => {
    if (isDraft) {
      props.onDelete(selected.rows, true);
      setSelected(selectedTemplate);
    } else {
      setConfirmModalOpen(true);
    }
  };

  /**
   * Удаление выбранных респондентов.
   */
  const deleteRespondents = removeScore => {
    props.onDelete(selected.rows, removeScore);
    setSelected(selectedTemplate);
    setConfirmModalOpen(false);
  };

  const FooterConfirm = () => (
    <div className={css.footer}>
      <Button
        className={classNames(css.footerLeft, css.footerButton)}
        size={'small'}
        onClick={() => setConfirmModalOpen(false)}
      >
        {t('cancel')}
      </Button>
      <div className={css.footerRight}>
        <Button
          type='primary'
          danger
          size={'small'}
          className={css.footerButton}
          onClick={() => deleteRespondents(false)}
        >
          {t('deleteSaveScore')}
        </Button>
        <Button
          type='primary'
          danger
          size={'small'}
          className={css.footerButton}
          onClick={() => deleteRespondents(true)}
        >
          {t('deleteСompletely')}
        </Button>
      </div>
    </div>
  );

  const allFullNames =
    selected?.rows?.map(sel => {
      return ' ' + sel.student.last_name + ' ' + sel.student.first_name;
    }) || [];

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <>
        {props.respondentType !== RESPONDENT_TYPE_SELF && (
          <Row>
            <Col span={24} align={'right'} className={css.buttonBar}>
              <Button type='primary' danger size={'small'} disabled={disableSelAction} onClick={onConfirmDelete}>
                {t('delete')}
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <Table
              columns={tableColumns()}
              dataSource={props.data}
              bordered={true}
              size='small'
              loading={props.loading}
              rowSelection={props.respondentType !== RESPONDENT_TYPE_SELF ? rowSelection() : null}
              rowKey={record => record.id}
            />
          </Col>
        </Row>

        <Modal
          title={
            selected?.rows?.length > 1
              ? t('confirmDeleteTitles', { type: props.tabName })
              : t('confirmDeleteTitle', { type: props.tabName })
          }
          open={confirmModalOpen}
          maskClosable
          width={650}
          onCancel={() => setConfirmModalOpen(false)}
          footer={<FooterConfirm />}
        >
          <p>
            <Trans
              i18nKey='confirmDeleteDescription'
              t={t}
              values={{
                name: allFullNames,
                resp: plural(selected?.rows?.length || 0, 'Респондент', 'Респондента', 'Респонденты'),
                rest: plural(selected?.rows?.length || 0, 'оставил', 'оставили'),
              }}
              components={{ bold: <strong /> }}
            />
            <br />
          </p>
        </Modal>
      </>
    );
  };

  return render();
};

RespondentsTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  respondentType: PropTypes.oneOf([
    RESPONDENT_TYPE_MANAGER,
    RESPONDENT_TYPE_COLLEAGUE,
    RESPONDENT_TYPE_SUBORDINATE,
    RESPONDENT_TYPE_CROSS,
    RESPONDENT_TYPE_SELF,
  ]),
  onDelete: PropTypes.func.isRequired,
};

export default RespondentsTable;
