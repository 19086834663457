/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form, Input, Pagination } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import { Card, Content } from '@/components/Layout';

import AuthorCreationDialog from './AuthorCreationDialog';
import AuthorItem from './AuthorItem';

import debounce from '@shared/utils/debounce';

import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { AUTHOR_CREATE } from '@/constants/permissions';

export class AuthorsTable extends Component {
  static propTypes = {
    authors: PropTypes.array,
    authorsPageNumber: PropTypes.number,
    authorsPageSize: PropTypes.number,
    authorsTotal: PropTypes.number,
    createAuthor: PropTypes.func,
    creationError: PropTypes.bool,
    deleteAuthor: PropTypes.func,
    editAuthor: PropTypes.func,
    editingError: PropTypes.bool,
    getAuthors: PropTypes.func,
    isLoading: PropTypes.bool,
    isGlobal: PropTypes.bool,
    shared: PropTypes.bool,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchName: '',
      page: 1,
    };
    this.getAuthorsDebounced = debounce(props.getAuthors, 1000);
    this.props.getAuthors('', 0, null, props.isGlobal);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchString !== this.state.searchString) {
      this.getAuthorsDebounced(
        this.state.searchString,
        0,
        () => {
          this.setState({ searchName: this.state.searchString });
        },
        this.props.isGlobal
      );
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changePage = page => {
    this.setState({ page });
    this.props.getAuthors(this.state.searchString, page - 1, null, this.props.isGlobal);
  };

  showDeleteConfirmation = author => this.setState({ deleteConfirmationVisible: true, activeAuthor: author });

  hideDeleteConfirmation = () => this.setState({ deleteConfirmationVisible: false });

  handleTagDeletion = () =>
    this.props.deleteAuthor(this.state.activeAuthor && this.state.activeAuthor.id, {
      onSuccess: () => {
        this.hideDeleteConfirmation();
        this.props.getAuthors(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
      },
      onError: this.hideDeleteConfirmation,
    });

  showTagCreationDialog = () => this.setState({ authorCreationVisible: true });

  hideTagCreationDialog = () => this.setState({ authorCreationVisible: false });

  handleTagCreation = author => {
    this.props.createAuthor(
      author,
      () => {
        this.hideTagCreationDialog();
        this.props.getAuthors(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
      },
      error => {
        console.error('Error', error);
      }
    );
  };

  showTagEditingDialog = author => this.setState({ authorEditingVisible: true, activeAuthor: author });

  hideTagEditingDialog = () => this.setState({ authorEditingVisible: false, activeAuthor: null });

  handleTagEditing = name => {
    this.props.editAuthor(name, this.state.activeAuthor && this.state.activeAuthor.id, () => {
      this.hideTagEditingDialog();
      this.props.getAuthors(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
    });
  };

  render() {
    const {
      authors,
      authorsPageSize,
      authorsTotal,
      authorsPageNumber,
      creationError,
      editingError,
      shared,
      t,
      currentUser,
    } = this.props;
    const label = shared ? t('titleGlobal') : t('title');
    const authorItems = authors.map(author => (
      <AuthorItem
        key={author.id}
        author={author}
        onEdit={this.showTagEditingDialog}
        onDelete={this.showDeleteConfirmation}
        shared={shared}
        currentUser={currentUser}
        t={t}
      />
    ));
    const pagination =
      authorsPageSize && authorsTotal && authorsTotal > authorsPageSize ? (
        <Pagination
          current={authorsPageNumber + 1}
          total={authorsTotal}
          pageSize={authorsPageSize}
          onChange={this.changePage}
          className={css['pagination']}
          showSizeChanger={false}
          responsive={true}
          hideOnSinglePage={true}
        />
      ) : null;

    return (
      <Content className={css.TagDirectory}>
        <h1>{label}</h1>
        <div className={css['TagItem-container']}>
          <Form className={css['TagItem-form']}>
            <Form.Item
              label={t('search')}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              className={css['TagItem-form-search']}
            >
              <Input
                placeholder={t('name')}
                name='searchString'
                dataqa='searchAuthorInput'
                value={this.state.searchString}
                onChange={this.handleChange}
              />
            </Form.Item>
          </Form>
          {!shared && currentUser.authorities.includes(AUTHOR_CREATE) && (
            <Button
              type='primary'
              className={css['TagItem-button']}
              onClick={this.showTagCreationDialog}
              data-qa='addNewAuthorBtn'
            >
              {t('addName')}
            </Button>
          )}
        </div>
        <Card>
          {authorItems && authorItems.length ? (
            authorItems
          ) : !this.props.isLoading && this.state.searchString ? (
            <div data-qa='authorNotFoundBlock' className={css['TagItem-noFound']}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('findError', { searchName: this.state.searchName }),
                }}
              />
            </div>
          ) : null}
          {pagination}
        </Card>
        {this.state.authorCreationVisible && !shared ? (
          <AuthorCreationDialog
            open
            onOk={this.handleTagCreation}
            onCancel={this.hideTagCreationDialog}
            title={t('addName')}
            descriptionText={t('newOne')}
            okText={t('add')}
            error={creationError}
            errorText={t('exist')}
            require={t('require')}
          />
        ) : null}
        {this.state.authorEditingVisible && !shared ? (
          <AuthorCreationDialog
            open
            onOk={this.handleTagEditing}
            onCancel={this.hideTagEditingDialog}
            title={t('editName')}
            descriptionText={t('newOne')}
            okText={t('edit')}
            error={editingError}
            errorText={t('exist')}
            author={this.state.activeAuthor && this.state.activeAuthor.name}
          />
        ) : null}
        <ConfirmationModal
          open={this.state.deleteConfirmationVisible}
          onOk={this.handleTagDeletion}
          onCancel={this.hideDeleteConfirmation}
          title={t('deleteName')}
          okText={t('delete')}
          confirmationText={t('confirm')}
        />
      </Content>
    );
  }
}

export default withTranslation('authorsTable')(AuthorsTable);
