import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeOrderStatus, postOrdersFilters } from '@/store/order/actions';

import { selectOrder } from '@/store/order/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Input, Modal, Select, Table } from 'antd';
import { HiOutlineEmojiHappy, HiOutlineExclamationCircle, HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../Orders.module.scss';

import { PRODUCT_ADMIN_UPDATE_STATUS } from '@/constants/permissions';

const { Option } = Select;
const { TextArea } = Input;

const ModalOrder = props => {
  const { t } = useTranslation('orders');
  const dispatch = useDispatch();
  const [filtersStatus, setFiltersStatus] = useState();
  const disabledDelivered = props.isOrder?.obj?.statusDto?.id === 5 || props.isOrder?.obj?.statusDto?.id === 2;

  const { ordersCurrentPage, orderFilters, status, orderHistory, isLoadingTable } = useSelector(selectOrder);
  const { currentUser } = useSelector(selectUsers);

  useEffect(() => {
    if (status?.length) {
      switch (props.isOrder.obj.statusDto.id) {
        case 1:
          const filteredPendingStatus = status.filter(i => i.id === 2 || i.id === 3);
          setFiltersStatus(filteredPendingStatus);
          break;

        case 3:
          const filteredActiveStatus = status.filter(i => i.id === 2 || i.id === 4);
          setFiltersStatus(filteredActiveStatus);
          break;

        case 4:
          const filteredSentStatus = status.filter(i => i.id === 2 || i.id === 5);
          setFiltersStatus(filteredSentStatus);
          break;

        default:
          break;
      }
    }
  }, [status, props]);

  const [data, setData] = useState({
    status: {
      id: null,
      name: '',
      value: '',
    },
    comment: '',
  });

  const [isContent, setIsContent] = useState({
    manage: true,
    change: false,
    success: false,
  });

  const [isError, setIsError] = useState(false);

  const titleModal = <div className={css['Modal-header']}>{t('titleModalOrder')}</div>;

  const changeStatusOrder = () => {
    if (!data.status.value) {
      setIsError(!data.status.value);
    } else {
      setIsError(!data.status.value);

      setIsContent({ ...isContent, manage: false, change: true });
    }
  };

  const footerModal = (
    <div className={css['Modal-change-footer']}>
      <div className={css['Modal-change-footer-actions']}>
        <Button onClick={props.onClose} size='large' className={css['Modal-change-footer-actions-cancel']}>
          {t('cancel')}
        </Button>
        {currentUser?.authorities.includes(PRODUCT_ADMIN_UPDATE_STATUS) && (
          <Button onClick={changeStatusOrder} disabled={disabledDelivered} size='large' type='primary'>
            {t('changeStatusOrder')}
          </Button>
        )}
      </div>
    </div>
  );

  const goToOrders = () => {
    dispatch(postOrdersFilters(orderFilters.filters ? orderFilters.filters : {}, ordersCurrentPage));
    props.onClose();
  };

  const onChangeOrderStatus = () => {
    setIsContent({ manage: false, change: false, success: true });

    const params = {
      comment: data.comment,
      orderId: props.isOrder?.obj?.orderId,
      status: data.status.value,
    };
    dispatch(changeOrderStatus(params));
  };

  const optionStatus =
    filtersStatus?.length &&
    filtersStatus.map(item => {
      return (
        <Option value={item.value} key={item.id}>
          {item.name}
        </Option>
      );
    });

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('modalDateTable'),
      dataIndex: 'date',
      key: 1,
    });

    columns.push({
      title: t('modalAuthorTable'),
      dataIndex: 'author',
      key: 2,
      render: (text, obj) => (
        <div>
          <div>{text}</div>
          <div>{obj.email}</div>
          <div>{obj.phone}</div>
        </div>
      ),
    });

    columns.push({
      title: t('modalStatusTable'),
      dataIndex: 'status',
      key: 3,
      width: 150,
      render: (text, obj) => (
        <div className={css['Modal-manage-cell']}>
          <div className={css['Modal-manage-cell-status']}>
            <span
              className={classNames(
                css['Modal-manage-cell-status-circle'],
                css[`Modal-manage-cell-status-circle-${obj.statusDto.value}`]
              )}
            ></span>
            <strong>{text}</strong>
          </div>
        </div>
      ),
    });

    columns.push({
      title: t('modalCommentTable'),
      dataIndex: 'comment',
      key: 4,
    });

    return columns;
  };

  const getData = () => {
    if (orderHistory?.length) {
      return orderHistory.map(item => {
        return {
          date: dayjs(item.date).format('DD.MM.YYYY'),
          author: item.fullName,
          status: item.status.name,
          comment: item.comment,
          email: item.login,
          statusDto: item.status,
          phone: item.phone,
          fullDate: item.date,
        };
      });
    }
  };

  const manageContent = (
    <div className={css['Modal-manage']}>
      <div className={css['Modal-manage-block']}>
        <Select
          className={classNames(css['Modal-manage-block-select'], {
            [css['Modal-manage-block-select-error']]: isError,
          })}
          size='large'
          allowClear
          showSearch
          value={data.status.name || undefined}
          onChange={(e, item) => setData({ ...data, status: { name: e, id: +item?.key, value: item?.value } })}
          disabled={disabledDelivered}
          placeholder={t('modalOrderStatus')}
        >
          {optionStatus}
        </Select>
        {isError && (
          <div className={css['Modal-change-footer-error']}>
            <HiOutlineExclamationCircle className={css['Modal-change-footer-error-icon']} />
            <span className={css['Modal-change-footer-error-desc']}>{t('errorDesc')}</span>
          </div>
        )}
      </div>

      <div className={css['Modal-manage-block']}>
        <TextArea
          className={classNames(css['Modal-manage-block-area'])}
          rows={3}
          onChange={e => setData({ ...data, comment: e?.target?.value })}
          maxLength={255}
          value={data.comment}
          disabled={disabledDelivered}
          placeholder={t('modalORderComment')}
        />
      </div>

      <div className={css['Modal-manage-table']}>
        <div className={css['Modal-manage-table-title']}>{t('modalHeaderTable')}</div>

        <Table
          columns={getColumns()}
          dataSource={getData()}
          pagination={false}
          rowKey={e => `${e.author}--${Symbol('uniq').toString()}--${e.fullDate}`}
          size='small'
          loading={isLoadingTable}
        />
      </div>
    </div>
  );

  const changeContent = (
    <div className={css['Modal-change']}>
      <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
      <div className={css['Modal-change-title']}>{t('modalChangeStatus')}</div>
      <div className={css['Modal-change-desc']}>{t('modalChangeStatusDesc')}</div>
      <div className={css['Modal-change-footer']}>
        <Button onClick={() => setIsContent({ ...isContent, manage: true, change: false })} size='large'>
          {t('backCreate')}
        </Button>
        <Button className={css['Modal-change-footer-btn']} onClick={onChangeOrderStatus} size='large' type='primary'>
          {t('yesChange')}
        </Button>
      </div>
    </div>
  );

  const successContent = (
    <div className={css['Modal-status']}>
      <HiOutlineEmojiHappy color='var(--personalColor)' size={168} />
      <div className={css['Modal-status-title']}>{t('modalSuccesOrder')}</div>
      <div className={css['Modal-status-desc']}>{t('modalSuccesOrderDesc')}</div>
      <div className={css['Modal-status-footer']}>
        <Button onClick={goToOrders} size='large' type='primary'>
          {t('backToShop')}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      className={css['Modal']}
      width={700}
      open={props.isOrder.open}
      onCancel={props.onClose}
      footer={isContent.manage ? footerModal : null}
      title={isContent.manage ? titleModal : null}
    >
      {isContent.manage && manageContent}
      {isContent.change && changeContent}
      {isContent.success && successContent}
    </Modal>
  );
};

ModalOrder.propTypes = {
  onClose: PropTypes.func,
  isOrder: PropTypes.object,
};

export default ModalOrder;
