import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getBlockStatistic } from '@/store/lms/actions';
import { getChecklistPoka, getShortPokaTopic, getStatePoka } from '@/store/poka_validation_user/actions';

import { selectBlocks } from '@/store/lms/selectors';
import { selectPokaValidationUser } from '@/store/poka_validation_user/selectors';

import { Button, Layout, Spin, Tooltip, Typography } from 'antd';
import { Content } from '@/components/Layout';
import { CloseOutlined } from '@ant-design/icons';

import AboutSkill from './Content/AboutSkill';
import ChecklistSkill from './Content/ChecklistSkill';
import GradeSkill from './Content/GradeSkill';

import PieMars from '@shared/components/PieMars';

import css from './PokaValidationUser.module.scss';

// http://localhost:3001/validation/52772/1002/1821565 - для лок теста, после удалить

const { Title } = Typography;

const PokaValidationUser = () => {
  const { t } = useTranslation('pokaValidation');
  const dispatch = useDispatch();
  const { topicId, userId, planId } = useParams();

  const { userInfo } = useSelector(selectBlocks);
  const { shortPokaLoading, statePoka } = useSelector(selectPokaValidationUser);

  const fullName = `${userInfo.lastName || ''} ${userInfo.firstName || ''}`;

  useLayoutEffect(() => {
    if (topicId && userId && planId) {
      const params = { topicId: topicId, userId: userId, planId: planId };
      dispatch(getBlockStatistic(params));
      dispatch(getChecklistPoka(planId));
      dispatch(getStatePoka({ planId: planId }));
      dispatch(getShortPokaTopic(topicId));
    }
  }, [userId, planId, topicId]);

  const closePage = () => {};

  return (
    <Layout>
      <Content>
        <Spin spinning={shortPokaLoading}>
          <div className={css['PokaValidation-header']}>
            <div className={css['PokaValidation-header-user']}>
              <Title>{fullName}</Title>
              {statePoka?.endorsementLevel?.value && statePoka?.endorsementLevel?.label && (
                <div className={css['PokaValidation-header-user-pie']}>
                  <PieMars
                    value={statePoka.endorsementLevel.value}
                    read
                    isActive={statePoka.endorsementLevel.value}
                    pokaStatus={statePoka.pokaPlanStatus.value}
                    size='middle'
                    studyPlanStatus={statePoka?.studyPlanStatus}
                  />
                  <div className={css['PokaValidation-header-user-pie-txt']}>{statePoka.endorsementLevel.label}</div>
                </div>
              )}
            </div>
            <div>
              <Tooltip title={t('closePage')}>
                <Button
                  onClick={closePage}
                  icon={<CloseOutlined type='ghost' className={css['PokaValidation-header-close']} />}
                ></Button>
              </Tooltip>
            </div>
          </div>

          <AboutSkill />

          <ChecklistSkill />

          <GradeSkill />
        </Spin>
      </Content>
    </Layout>
  );
};

export default PokaValidationUser;
