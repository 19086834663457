import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectAccess } from '@/store/access/selectors';

import { Checkbox, Tooltip, Typography } from 'antd';

import css from '../EditRole.module.scss';

const { Text } = Typography;

const PermissionRow = memo(({ localState, onChange, permission }) => {
  const { defaultPermissionsIds } = useSelector(selectAccess);

  const { t } = useTranslation('RolesSettings');

  const checkOnDefault = useMemo(
    () => defaultPermissionsIds.includes(permission.id),
    [permission, defaultPermissionsIds]
  );

  const checkBoxRender = () => {
    return (
      <Checkbox
        checked={localState.includes(permission.id) || checkOnDefault}
        onChange={event => onChange(event, permission.id)}
        disabled={checkOnDefault}
      />
    );
  };

  const render = () => {
    return (
      <div className={css['main__block-checkbox']}>
        {checkOnDefault ? (
          <Tooltip placement='topLeft' title={t('defaultPermission')}>
            {checkBoxRender()}
          </Tooltip>
        ) : (
          checkBoxRender()
        )}
        <Text>{permission.description}</Text>
      </div>
    );
  };

  return render();
});

export default PermissionRow;
