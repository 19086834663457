/* eslint-disable */
import {
  ACTUAL_LOGIN_FAIL,
  ACTUAL_LOGIN_START,
  ACTUAL_LOGIN_SUCCESS,
  GET_INFO_COMPANY_FAIL,
  GET_INFO_COMPANY_START,
  GET_INFO_COMPANY_SUCCESS,
  ILoginState,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  REFRESH_LOGIN_FAIL,
  REFRESH_LOGIN_START,
  REFRESH_LOGIN_SUCCESS,
  UPDATE_LOGIN_EMPTY,
} from './types';

const initialState: ILoginState = {
  isLoading: false,
  isRefreshing: false,
  error: null,
  roles: [],
  authorities: [],
};

export default (state = initialState, action: any): ILoginState => {
  //TODO: Add LoginActionType after login store refactoring
  const { type, response, payload } = action;
  switch (type) {
    case GET_INFO_COMPANY_START:
    case LOGIN_START:
      return { ...state, isLoading: true, error: null };
    case GET_INFO_COMPANY_SUCCESS:
      return { ...state, isLoading: false, error: null, company: response };
    case LOGIN_SUCCESS:
      // return { ...state, isLoading: false, roles: payload };
      return { ...state, isLoading: false, authorities: response.authorities, error: null };

    case LOGIN_FAIL:
      return {
        ...state,
        error: response,
        // error: action.payload,
        isLoading: false,
      };
    case ACTUAL_LOGIN_START:
      return { ...state, isLoading: true, error: null };
    case REFRESH_LOGIN_START:
      return { ...state, isLoading: true, isRefreshing: true, error: null };
    case ACTUAL_LOGIN_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case REFRESH_LOGIN_SUCCESS:
      return { ...state, isLoading: false, isRefreshing: false, error: null };
    case GET_INFO_COMPANY_FAIL:
    case ACTUAL_LOGIN_FAIL:
      return { ...state, isLoading: false, error: action.response };
    case REFRESH_LOGIN_FAIL:
      return { ...state, isLoading: false, isRefreshing: false, error: action.payload };

    case UPDATE_LOGIN_EMPTY:
      return { ...state, error: null };

    default:
      return state;
  }
};
