import { API_D360_URL } from '../service_api';
import { D360_GET_RESULTS_FAIL, D360_GET_RESULTS_START, D360_GET_RESULTS_SUCCESS } from './constants';

import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360GetResults = (projectId, measurableId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/getResults${getQueryString({
      d360_project_id: projectId,
      d360_measurable_id: measurableId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_RESULTS_START, D360_GET_RESULTS_SUCCESS, D360_GET_RESULTS_FAIL],
    onSuccess,
    onError,
  };
};

/*
   Import statement
   ----------------
   import {
    D360GetResults
   } from '@/store/d360_results/actions';

*/
