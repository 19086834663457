/* eslint-disable */
import { ApiService } from './api-service';

export class DepartmentService extends ApiService {
  getDepartments(params: TGetDepartmentParams): Promise<any[]> {
    return this.get('/departmentInternal', params);
  }
}

type TGetDepartmentParams = {
  name?: string;
};

export default new DepartmentService();
