/* eslint-disable */
import React, { Component } from 'react';

import { Button, Form, Input, Modal } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class AuthorCreationDialog extends Component {
  static propTypes = {
    author: PropTypes.string,
    descriptionText: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    okText: PropTypes.string,
    onOk: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      author: props.author || '',
      dirty: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { author } = this.state;
    if (author) {
      this.setState({
        authorError: '',
        dirty: true,
      });
      this.props.onOk(author.trim());
    } else {
      this.setState({ authorError: this.props.require });
    }
  };

  render() {
    const { descriptionText, okText, error, errorText, ...props } = this.props;

    return (
      <Modal {...props} footer={null}>
        <p>{descriptionText}</p>
        <Form>
          <Form.Item
            className={classNames({ error: this.state.authorError || error })}
            help={this.state.authorError || (error && this.state.dirty ? errorText : '')}
            validateStatus={this.state.authorError || (error && this.state.dirty) ? 'error' : ''}
          >
            <Input
              maxLength={200}
              name='author'
              value={this.state.author}
              onChange={this.handleChange}
              dataqa='authorInput'
            />
          </Form.Item>
          <Button type='primary' onClick={this.handleSubmit} htmlType='submit' data-qa='submitBtn'>
            {okText}
          </Button>
        </Form>
      </Modal>
    );
  }
}
