/* eslint-disable */
import {
  CLEAR_STUDYING_STATISTICS,
  GET_MATERIAL_STATISTICS_FAIL,
  GET_MATERIAL_STATISTICS_START,
  GET_MATERIAL_STATISTICS_SUCCESS,
  GET_STUDYING_STATISTICS_FAIL,
  GET_STUDYING_STATISTICS_START,
  GET_STUDYING_STATISTICS_SUCCESS,
  PUT_NOTIFICATION_REMINDER_FAIL,
  PUT_NOTIFICATION_REMINDER_START,
  PUT_NOTIFICATION_REMINDER_SUCCESS,
} from './constants';

const initialState = {
  isStudyingLoading: false,
  studyingStatistics: {},
  isStudyingError: false,
  isMaterialLoading: false,
  materialStatistics: {},
  isMaterialError: false,
  isLoadingNotification: false,
  isErrorNotification: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_STUDYING_STATISTICS_START:
      return { ...state, isStudyingLoading: true, isStudyingError: false };

    case GET_MATERIAL_STATISTICS_START:
      return { ...state, isMaterialLoading: true, isMaterialError: false };

    case PUT_NOTIFICATION_REMINDER_START:
      return { ...state, isLoadingNotification: true };

    case GET_STUDYING_STATISTICS_SUCCESS:
      return { ...state, isStudyingLoading: false, studyingStatistics: response };

    case GET_MATERIAL_STATISTICS_SUCCESS:
      return { ...state, isMaterialLoading: false, materialStatistics: response };

    case PUT_NOTIFICATION_REMINDER_SUCCESS:
      return { ...state, isLoadingNotification: false };

    case GET_STUDYING_STATISTICS_FAIL:
      return { ...state, isStudyingLoading: false, isStudyingError: true };

    case GET_MATERIAL_STATISTICS_FAIL:
      return { ...state, isMaterialLoading: false, isMaterialError: true };

    case PUT_NOTIFICATION_REMINDER_FAIL:
      return { ...state, isLoadingNotification: false, isErrorNotification: true };

    case CLEAR_STUDYING_STATISTICS:
      return { ...state, isStudyingLoading: true, studyingStatistics: {}, materialStatistics: {} };

    default:
      return { ...state };
  }
};
