import {
  DELETE_TYPEFORM_FAIL,
  DELETE_TYPEFORM_START,
  DELETE_TYPEFORM_SUCCESS,
  GET_TYPEFORM_ANSWERS_FAIL,
  GET_TYPEFORM_ANSWERS_START,
  GET_TYPEFORM_ANSWERS_SUCCESS,
  GET_TYPEFORM_EXPORT_FAIL,
  GET_TYPEFORM_EXPORT_START,
  GET_TYPEFORM_EXPORT_SUCCESS,
  GET_TYPEFORM_FAIL,
  GET_TYPEFORM_START,
  GET_TYPEFORM_SUCCESS,
  GET_TYPEFORMS_FAIL,
  GET_TYPEFORMS_START,
  GET_TYPEFORMS_SUCCESS,
  POST_TYPEFORM_ANSWERS_FAIL,
  POST_TYPEFORM_ANSWERS_START,
  POST_TYPEFORM_ANSWERS_SUCCESS,
  POST_TYPEFORM_FAIL,
  POST_TYPEFORM_START,
  POST_TYPEFORM_SUCCESS,
  UPDATE_TYPEFORM_FAIL,
  UPDATE_TYPEFORM_START,
  UPDATE_TYPEFORM_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

// Получение typeform по block id

export const getTypeform = (blockId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/${blockId}/typeform`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPEFORM_START, GET_TYPEFORM_SUCCESS, GET_TYPEFORM_FAIL],
  onSuccess,
  onError,
});

// Создание typeform для block id

export const createTypeform = (blockId, companyId, link, name, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${blockId}/typeform`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    blockId: blockId,
    companyId: companyId,
    link: link,
    name: name,
  },
  types: [POST_TYPEFORM_START, POST_TYPEFORM_SUCCESS, POST_TYPEFORM_FAIL],
  onSuccess,
  onError,
});

export const updateTypeform = (blockId, companyId, typeformId, link, name, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/typeform/${typeformId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    blockId: blockId,
    companyId: companyId,
    id: typeformId,
    link: link,
    name: name,
  },
  types: [UPDATE_TYPEFORM_START, UPDATE_TYPEFORM_SUCCESS, UPDATE_TYPEFORM_FAIL],
  onSuccess,
  onError,
});

// Получение всех typeform с фильтрами и пагинацией

export const getTypeforms = (params, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/typeform${getQueryString({
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPEFORMS_START, GET_TYPEFORMS_SUCCESS, GET_TYPEFORMS_FAIL],
  onSuccess,
  onError,
});

// Удаление тайпформы

export const deleteTypeform = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/typeform/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_TYPEFORM_START, DELETE_TYPEFORM_SUCCESS, DELETE_TYPEFORM_FAIL],
  onSuccess,
  onError,
});

// Получение данных

export const getTypeformAnswers = (typeformid, params, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/typeform/${typeformid}/answers${getQueryString({
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPEFORM_ANSWERS_START, GET_TYPEFORM_ANSWERS_SUCCESS, GET_TYPEFORM_ANSWERS_FAIL],
  onSuccess,
  onError,
});

// Скачивание данных

export const grabTypeformAnswers = (typeformid, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/typeform/${typeformid}/answers/grab`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_TYPEFORM_ANSWERS_START, POST_TYPEFORM_ANSWERS_SUCCESS, POST_TYPEFORM_ANSWERS_FAIL],
  onSuccess,
  onError,
});

// export в csv

export const exportTypeform = (typeformid, typeformName, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/typeform/${typeformid}/answers/export${getQueryString({
    filename: typeformName,
    id: typeformid,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_TYPEFORM_EXPORT_START, GET_TYPEFORM_EXPORT_SUCCESS, GET_TYPEFORM_EXPORT_FAIL],
  onSuccess,
  onError,
});
