export const clearStorage = () => {
  const tips = localStorage.getItem('tips') || 'true';
  const currentRedirect = localStorage.getItem('currentRedirect');
  localStorage.clear();
  localStorage.setItem('tips', tips);
  if (currentRedirect) localStorage.setItem('currentRedirect', currentRedirect);
};

export const clearAllStorage = () => {
  const tips = localStorage.getItem('tips') || 'true';
  localStorage.clear();
  localStorage.setItem('tips', tips);
};

export const removeServiceWorker = () => {
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
};
