import {
  CREATE_ROLE_FAIL,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_START,
  DELETE_ROLE_SUCCESS,
  GET_ALL_ROLES_FAIL,
  GET_ALL_ROLES_START,
  GET_ALL_ROLES_SUCCESS,
  GET_GROUP_PERMISSIONS_ALL_FAIL,
  GET_GROUP_PERMISSIONS_ALL_START,
  GET_GROUP_PERMISSIONS_ALL_SUCCESS,
  GET_ROLE_BY_ID_FAIL,
  GET_ROLE_BY_ID_START,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLES_DEFAULT_FAIL,
  GET_ROLES_DEFAULT_START,
  GET_ROLES_DEFAULT_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getRoles = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/role${getQueryString({ ...params, sort: 'id,asc' })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: params.defaultOnly
    ? [GET_ROLES_DEFAULT_START, GET_ROLES_DEFAULT_SUCCESS, GET_ROLES_DEFAULT_FAIL]
    : [GET_ROLES_START, GET_ROLES_SUCCESS, GET_ROLES_FAIL],
});

export const getAllRoles = companyIds => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/role${getQueryString({
    companyIds,
    size: 500,
    sort: 'id,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ALL_ROLES_START, GET_ALL_ROLES_SUCCESS, GET_ALL_ROLES_FAIL],
});

export const createRole = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/role`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_ROLE_START, CREATE_ROLE_SUCCESS, CREATE_ROLE_FAIL],
  onSuccess,
  onError,
});

export const updateRole = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/role`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [UPDATE_ROLE_START, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAIL],
  onSuccess,
  onError,
});

export const deleteRole = (ids, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/role`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: [ids],
  types: [DELETE_ROLE_START, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAIL],
  onSuccess,
});

export const getRoleById = id => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/role/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ROLE_BY_ID_START, GET_ROLE_BY_ID_SUCCESS, GET_ROLE_BY_ID_FAIL],
});

export const getGroupPermissionAll = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/role/permissions/groups/all`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GROUP_PERMISSIONS_ALL_START, GET_GROUP_PERMISSIONS_ALL_SUCCESS, GET_GROUP_PERMISSIONS_ALL_FAIL],
});
