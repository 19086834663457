/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PROJECT_STATUS_ACTIVE, PROJECT_STATUS_DRAFT, PROJECT_STATUS_INACTIVE } from '../../../constants';

import PropTypes from 'prop-types';

export const ProjectStatus = props => {
  const [text, setText] = useState(null);
  const [color, setColor] = useState('#000');
  const { t } = useTranslation('D360_ProjectEdit');

  useEffect(() => {
    switch (props.statusId) {
      case PROJECT_STATUS_ACTIVE:
        setText(t('statusActive'));
        setColor('#46c563');
        break;

      case PROJECT_STATUS_INACTIVE:
        setText(t('statusInactive'));
        setColor('#ef7e61');
        break;

      case PROJECT_STATUS_DRAFT:
        setText(t('statusDraft'));
        setColor('#555c66');
        break;

      default:
        setText('Неизвестный');
        setColor('#91939e');
        break;
    }
  }, [props.statusId]);

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return <span style={{ color: `${color}` }}>{text}</span>;
  };

  return render();
};

ProjectStatus.propTypes = {
  statusId: PropTypes.number.isRequired,
};

export default ProjectStatus;
