import {
  GET_CHECKLIST_POKA_FAIL,
  GET_CHECKLIST_POKA_START,
  GET_CHECKLIST_POKA_SUCCESS,
  GET_SHORT_POKA_FAIL,
  GET_SHORT_POKA_START,
  GET_SHORT_POKA_SUCCESS,
  GET_STATE_POKA_FAIL,
  GET_STATE_POKA_START,
  GET_STATE_POKA_SUCCESS,
  PUT_CHECKLIST_POKA_FAIL,
  PUT_CHECKLIST_POKA_START,
  PUT_CHECKLIST_POKA_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  shortPokaLoading: false,
  shortPoka: {},
  checklistDetail: {},
  statePoka: {},
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_SHORT_POKA_START:
      return { ...state, shortPokaLoading: true };

    case GET_CHECKLIST_POKA_START:
    case PUT_CHECKLIST_POKA_START:
    case GET_STATE_POKA_START:
      return { ...state, loading: true };

    case GET_SHORT_POKA_SUCCESS:
      return { ...state, shortPokaLoading: false, shortPoka: response };

    case PUT_CHECKLIST_POKA_SUCCESS:
      return { ...state, loading: false };

    case GET_STATE_POKA_SUCCESS:
      return { ...state, loading: false, statePoka: response };

    case GET_CHECKLIST_POKA_SUCCESS:
      return { ...state, loading: false, checklistDetail: response };

    case GET_SHORT_POKA_FAIL:
      return { ...state, shortPokaLoading: false };

    case GET_CHECKLIST_POKA_FAIL:
    case PUT_CHECKLIST_POKA_FAIL:
    case GET_STATE_POKA_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
