import { useEffect, useRef, useState } from 'react';

import { DatePicker } from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './DatePicker.module.scss';

const CustomDateSomePicker = props => {
  const { /*disabledDays,*/ value, onChange, error } = props;
  const [loadedPicker, changeLoadedPicker] = useState(false);
  const modalRef = useRef();

  const onChangeDate = (date, stringDates) => {
    onChange(stringDates);
  };

  useEffect(() => {
    changeLoadedPicker(true);
    return () => changeLoadedPicker(false);
  }, [value]);

  return (
    <div ref={modalRef} className={css.DatePicker}>
      {loadedPicker && (
        <DatePicker
          multiple
          open
          // minDate={dayjs()}
          defaultValue={value?.map(e => dayjs(e))} // value?.filter(date => dayjs().isSameOrBefore(dayjs(date))).map(e => dayjs(e));
          value={value?.map(e => dayjs(e))}
          maxTagCount='responsive'
          // disabledDate={disabledDays.before} // это надо доработать и стандартизировать в каком формате приходят данные их так же можно использовать в аргументах minDate maxDate
          popupClassName={`${css.DatePicker__poppup} ${error && css.error}`}
          className={css.DatePicker__input}
          onCalendarChange={onChangeDate}
          size={'middle'}
          getPopupContainer={() => modalRef.current}
        />
      )}
    </div>
  );
};

CustomDateSomePicker.propTypes = {
  date: PropTypes.array,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default CustomDateSomePicker;
