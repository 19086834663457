/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Input, Modal, Row } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import _ from 'lodash';
import PropTypes from 'prop-types';

export const EditComment = props => {
  const { t } = useTranslation('D360_Comment');
  const [desc, setDesc] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (!_.isNil(props) && _.isObject(props) && _.has(props, 'type')) {
      if (_.has(props, 'criteria') && _.isObject(props.criteria)) {
        if (_.has(props.criteria, 'competence_description') && _.has(props.criteria, 'skill_description')) {
          switch (props.type) {
            case 'competence':
              setType('competence');
              setDesc(props.criteria.competence_description);
              break;

            case 'skill':
              setType('skill');
              setDesc(props.criteria.skill_description);
              break;
          }
        }
      }
    }
  }, []);

  // useEffect(()=>{
  //   if( !_.isNil(props) && _.isObject(props) && _.has(props, 'type') ) {
  //     console.log('criteria', props.criteria);
  //     console.log('type', props.type);
  //
  //     if( _.has(props, 'criteria') && _.isObject(props.criteria) ) {
  //       if( _.has(props.criteria, 'competence_description') && _.has(props.criteria, 'skill_description') ) {
  //         switch ( props.type ) {
  //           case 'competence':
  //             setType('competence');
  //             setDesc(props.criteria.competence_description);
  //             console.log( 'competence_description: ', props.criteria.competence_description);
  //             break;
  //
  //           case 'skill':
  //             setType('skill');
  //             setDesc(props.criteria.skill_description);
  //             console.log( 'skill_description: ', props.criteria.skill_description);
  //             break;
  //         }
  //       }
  //     }
  //   }
  // }, [props]);

  const onCancel = () => {
    if (!_.isNil(props) && _.isObject(props) && _.has(props, 'onCancel') && _.isFunction(props.onCancel)) {
      props.onCancel();
    }
  };

  const onSubmit = () => {
    if (!_.isNil(props) && _.isObject(props) && _.has(props, 'onSubmit') && _.isFunction(props.onSubmit)) {
      props.onSubmit(props.criteria, type, desc);
    }
  };

  const title = (
    <>
      <FormOutlined />
      &nbsp;{type === 'competence' ? t('titleCompetence') : t('titleSkill')}
    </>
  );

  const handleInput = e => {
    setDesc(e.target.value);
  };

  return (
    <Modal
      title={title}
      onCancel={onCancel}
      onOk={onSubmit}
      okText={t('okButton')}
      cancelText={t('cancelButton')}
      open
      centered
      destroyOnClose
    >
      <Row gutter={[16]}>
        <Col span={24}>
          {type === 'competence' ? <span>*&nbsp; Название компетенции</span> : <span>*&nbsp; Название навыка</span>}
        </Col>
        <Col span={24}>
          <Input
            type={'text'}
            readOnly
            value={type === 'competence' ? props.criteria.competence.name : props.criteria.skill.name}
          />
        </Col>
        <Col span={24} style={{ marginTop: '1rem' }}>
          <p>{type === 'competence' ? t('descCompetence') : t('descSkill')}</p>
        </Col>
        <Col span={24}>
          <Input.TextArea rows={4} maxLength={500} onChange={handleInput} value={desc} />
        </Col>
      </Row>
    </Modal>
  );
};
EditComment.propTypes = {
  type: PropTypes.oneOf(['competence', 'skill']).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  criteria: PropTypes.object,
};

export default EditComment;
