/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Input, Modal, Row } from 'antd';
import { CgTrending } from 'react-icons/cg';

import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { TextArea } = Input;

const ScaleEdit = props => {
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const { t } = useTranslation('D360_Scales');

  // Initialize Window
  useEffect(() => {
    setId(null);
    setName(null);
    setDescription(null);

    if (props.scale) {
      // id
      if (props.scale.id && !isNaN(props.scale.id) && parseInt(props.scale.id) > 0) {
        setId(props.scale.id);
      }

      // name
      if (props.scale.name && props.scale.name !== null && props.scale.name.length > 0) {
        setName(props.scale.name);
      }

      // description
      if (props.scale.description && props.scale.description !== null && props.scale.description.length > 0) {
        setDescription(props.scale.description);
      }
    }
  }, [props.scale]);

  // Validate date
  useEffect(() => {
    if (name !== null && name.length > 0) {
      return setDisableSubmitButton(false);
    }

    return setDisableSubmitButton(true);
  }, [name]);

  const onSubmit = () => {
    if (typeof props.onSubmit === 'function') {
      props.onSubmit({ id, name, description });
    }
  };

  const onCancel = () => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  const renderWindowTitle = () => {
    return (
      <>
        <CgTrending />
        &nbsp;{t('editScaleTitle')}
      </>
    );
  };

  const render = () => {
    return (
      <Modal
        title={renderWindowTitle()}
        open={props.open}
        onCancel={onCancel}
        onOk={onSubmit}
        okButtonProps={{ disabled: disableSubmitButton }}
        width={640}
      >
        <Row className={css.Notes}>
          <Col span={24}>
            <CgTrending size='3rem' className={css.NotesIcon} />
            <p className={css.NotesText}>{t('editScaleDescription')}</p>
          </Col>
        </Row>

        <Row className={css.WindowRow}>
          <Col span={24}>*&nbsp;{t('scaleName')}</Col>
          <Col span={24}>
            <Input maxLength={255} onChange={e => setName(e.target.value)} value={name} />
          </Col>
        </Row>

        <Row className={css.WindowRow}>
          <Col span={24}>{t('scaleDescription')}</Col>
          <Col span={24}>
            <TextArea rows={4} maxLength={1024} onChange={e => setDescription(e.target.value)} value={description} />
          </Col>
        </Row>
      </Modal>
    );
  };

  return render();
};

ScaleEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  scale: PropTypes.any,
};

export default ScaleEdit;
