import { API_D360_URL } from '../service_api';
import {
  D360_CREATE_CRITERIA_FAIL,
  D360_CREATE_CRITERIA_START,
  D360_CREATE_CRITERIA_SUCCESS,
  D360_DELETE_CRITERIA_FAIL,
  D360_DELETE_CRITERIA_START,
  D360_DELETE_CRITERIA_SUCCESS,
  D360_GET_CRITERIA_FAIL,
  D360_GET_CRITERIA_START,
  D360_GET_CRITERIA_SUCCESS,
  D360_UPDATE_COMPETENCE_COMMENT_FAIL,
  D360_UPDATE_COMPETENCE_COMMENT_START,
  D360_UPDATE_COMPETENCE_COMMENT_SUCCESS,
  D360_UPDATE_SKILL_COMMENT_FAIL,
  D360_UPDATE_SKILL_COMMENT_START,
  D360_UPDATE_SKILL_COMMENT_SUCCESS,
} from './constants';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360UpdateCompetenceComment = (projectId, criteriaId, competenceId, description, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/comment/competence`,
    body: {
      d360_project_id: projectId,
      criteria_id: criteriaId,
      id: competenceId,
      description: description,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [
      D360_UPDATE_COMPETENCE_COMMENT_START,
      D360_UPDATE_COMPETENCE_COMMENT_SUCCESS,
      D360_UPDATE_COMPETENCE_COMMENT_FAIL,
    ],
    onSuccess,
    onError,
  };
};

export const D360UpdateSkillComment = (projectId, criteriaId, skillId, description, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/comment/skill`,
    body: {
      d360_project_id: projectId,
      criteria_id: criteriaId,
      id: skillId,
      description: description,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_UPDATE_SKILL_COMMENT_START, D360_UPDATE_SKILL_COMMENT_SUCCESS, D360_UPDATE_SKILL_COMMENT_FAIL],
    onSuccess,
    onError,
  };
};

export const D360CreateCriteria = (
  projectId,
  competenceId,
  skillId,
  scaleId,
  competenceDescription,
  skillDescription,
  onSuccess,
  onError
) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/criteria`,
    body: {
      d360_project_id: projectId,
      competence_id: competenceId,
      skill_id: skillId,
      d360_scale_id: scaleId,
      competence_description: competenceDescription,
      skill_description: skillDescription,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_CREATE_CRITERIA_START, D360_CREATE_CRITERIA_SUCCESS, D360_CREATE_CRITERIA_FAIL],
    onSuccess,
    onError,
  };
};

// DELETE
export const D360DeleteCriteria = (projectId, criteriaId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_D360_URL}/criteria${getQueryString({
      d360_project_id: projectId,
      id: criteriaId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_DELETE_CRITERIA_START, D360_DELETE_CRITERIA_SUCCESS, D360_DELETE_CRITERIA_FAIL],
    onSuccess,
    onError,
  };
};

// GET
export const D360GetCriteria = (projectId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/criteria${getQueryString({
      d360_project_id: projectId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_CRITERIA_START, D360_GET_CRITERIA_SUCCESS, D360_GET_CRITERIA_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

   import {
    D360CreateCriteria,
    D360DeleteCriteria,
    D360GetCriteria,
   } from '@/store/d360_criterias/actions';

**************************************************************/
