export const D360_IMPORT_START = 'D360_IMPORT_START';
export const D360_IMPORT_SUCCESS = 'D360_IMPORT_SUCCESS';
export const D360_IMPORT_FAIL = 'D360_IMPORT_FAIL';

export const D360_UPLOAD_START = 'D360_UPLOAD_START';
export const D360_UPLOAD_SUCCESS = 'D360_UPLOAD_SUCCESS';
export const D360_UPLOAD_FAIL = 'D360_UPLOAD_FAIL';

export const D360_IMPORT_STATUS_START = 'D360_IMPORT_STATUS_START';
export const D360_IMPORT_STATUS_SUCCESS = 'D360_IMPORT_STATUS_SUCCESS';
export const D360_IMPORT_STATUS_FAIL = 'D360_IMPORT_STATUS_FAIL';

export const D360_IMPORT_DELETE_START = 'D360_IMPORT_DELETE_START';
export const D360_IMPORT_DELETE_SUCCESS = 'D360_IMPORT_DELETE_SUCCESS';
export const D360_IMPORT_DELETE_FAIL = 'D360_IMPORT_DELETE_FAIL';
