/* eslint-disable */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';

import EditorToolbar, { formats, modules } from './EditorToolbar.jsx';
import ImageBlot from './ImageBlot.js';
import UserKampusEmbed from './UserKampusEmbed.js';

import { useQuill } from '@shared/library/react-quilljs/index.ts';

import ImageResize from '@taoqf/quill-image-resize-module';
import katex from 'katex';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import Quill from 'quill';

import 'quill/dist/quill.snow.css';
import 'katex/dist/katex.min.css';

import css from './Editor.module.scss';

window.katex = katex;

Quill.register('modules/imageResize', ImageResize);

Quill.register(ImageBlot, true);
Quill.register({
  'formats/variable': UserKampusEmbed,
});

Quill.register('modules/maxlength', function (instance, options) {
  if (!('value' in options)) {
    return;
  }
  instance.on('text-change', () => {
    const length = instance.getLength() - 1;
    if (length > options.value) {
      instance.deleteText(options.value, instance.getLength());
    }
  });
});

window.Quill = Quill;

const QuillEditor = ({
  showFooter,
  Content,
  onChange,
  placeholder,
  userName = '{{ user_name }}',
  typeToolbar,
  textMaxChar,
  maxChar,
  hideCodeCount,
}) => {
  const { t } = useTranslation('quillJS');
  const delayMaxLengthWarning = useRef(true);
  const [messageApi, contextHolder] = message.useMessage();

  const [length, setLength] = useState({ text: 0, html: 0 });
  const [nodeToolbar, setNodeToolbar] = useState(null);

  const debounceOnChange = useMemo(() => debounce(onChange, 800));

  const { quill, quillRef } = useQuill({
    modules: modules(nodeToolbar, userName, maxChar),
    formats: formats,
    placeholder: placeholder || t('placeholderDefault'),
  });

  const onTextChange = delta => {
    const html = quill.root.innerHTML;
    const text = quill.getText();

    if (showFooter) {
      setLength({
        text: quill.getLength() - 1,
        html: html.length === 7 ? 0 : html.length,
      });
    }

    if (maxChar) {
      if (quill.getLength() > maxChar && delayMaxLengthWarning.current) {
        delayMaxLengthWarning.current = false;
        messageApi.warning({
          top: 100,
          duration: 2,
          maxCount: 1,
          content: textMaxChar,
          onClose: () => (delayMaxLengthWarning.current = true),
        });
      }
    }

    debounceOnChange(html, text);
  };

  const onTextChangeForShort = delta => {
    const html = quill.getSemanticHTML();
    const text = quill.getText();

    if (maxChar) {
      if (quill.getLength() > maxChar && delayMaxLengthWarning.current) {
        delayMaxLengthWarning.current = false;
        messageApi.warning({
          top: 100,
          duration: 2,
          maxCount: 1,
          content: textMaxChar,
          onClose: () => (delayMaxLengthWarning.current = true),
        });
      }
    }

    if (showFooter) {
      setLength({
        text: quill.getLength() - 1,
        html: html.length === 7 ? 0 : html.length,
      });
    }

    if (!html.includes('userkampusembed')) {
      nodeToolbar.querySelector('.kampus-tag-username').classList.remove('disabled');
    } else {
      nodeToolbar.querySelector('.kampus-tag-username').classList.add('disabled');

      // Костыль чтобы не было возможности наложить два userName один через тулбар и один через cnrl/z который в буфере памяти
      if (delta.ops[1]?.insert?.userkampusembed) quill.history.redo();
    }

    debounceOnChange(html, text);
  };

  useEffect(() => {
    if (quill) {
      quill.on('text-change', typeToolbar === 'short' ? onTextChangeForShort : onTextChange);
      quill.clipboard.dangerouslyPasteHTML(Content);
    }

    return () => quill?.off('text-change', typeToolbar === 'short' ? onTextChangeForShort : onTextChange);
  }, [quill]);

  return (
    <div className={css.EditorWrapper}>
      {contextHolder}
      <EditorToolbar ref={setNodeToolbar} typeToolbar={typeToolbar} />
      <div ref={quillRef}></div>
      {showFooter && (
        <div className={css.EditorCounts}>
          <span>
            {t('text')} <span>{length.text}</span>
          </span>
          {!hideCodeCount && (
            <span>
              {t('code')} <span>{length.html}</span>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

QuillEditor.propTypes = {
  Content: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showFooter: PropTypes.bool,
  userName: PropTypes.string,
  typeToolbar: PropTypes.string,
  textMaxChar: PropTypes.string,
  maxChar: PropTypes.number,
  hideCodeCount: PropTypes.bool,
};

export default QuillEditor;
