/* eslint-disable */
import { UserStorage } from '@shared/services/user-storage';

class AdminStorage extends UserStorage {
  get isDefaultAdmin() {
    return this.getItem('username') === 'admin';
  }
}

export default new AdminStorage();
