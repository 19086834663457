// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-ScoresShop-ScoresShop-module__ql-picker-FdSfEJ.admin-src-components-ScoresShop-ScoresShop-module__ql-font-YQso8a .admin-src-components-ScoresShop-ScoresShop-module__ql-picker-label-nlDzuL[data-value="Montserrat"]:before, .admin-src-components-ScoresShop-ScoresShop-module__ql-picker-FdSfEJ.admin-src-components-ScoresShop-ScoresShop-module__ql-font-YQso8a .admin-src-components-ScoresShop-ScoresShop-module__ql-picker-item-h8XUjo[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-ScoresShop-ScoresShop-module__ql-font-Montserrat-dlXvlO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-Nf01ta {
  background-color: #fff;
  border-radius: 16px;
  min-height: 166px;
  padding: 24px;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-header-lSvu0l {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-header-title-safgn2 {
  color: #222429;
  font-size: 24px;
  font-weight: 800;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-DVyRRr {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-text-Y_aAFb {
  color: #9299a4;
  font-size: 14px;
  font-weight: 500;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-date-NEDrY0 {
  font-size: 16px;
  font-weight: 400;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-desc-vdpwQV {
  justify-content: space-between;
  margin-top: 24px;
  display: flex;
}

.admin-src-components-ScoresShop-ScoresShop-module__Scores-desc-text-NhS78U {
  color: #222429;
  max-width: 550px;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/ScoresShop/ScoresShop.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;AACf;;AAMA;EAHE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAKA;EAFE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAIA;EADE,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;AACf;;AAGA;EAAE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AACA;EAEE,8BAA8B;EAC9B,gBAAgB;EAChB,aAAa;AACf;;AAAA;EAGE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Scores {\n  min-height: 166px;\n  background-color: #fff;\n  border-radius: 16px;\n  padding: 24px;\n}\n.Scores-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.Scores-header-title {\n  color: #222429;\n  font-size: 24px;\n  font-weight: 800;\n}\n.Scores-header-history {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n.Scores-header-history-text {\n  color: #9299a4;\n  font-weight: 500;\n  font-size: 14px;\n}\n.Scores-header-history-date {\n  font-weight: 400;\n  font-size: 16px;\n}\n.Scores-desc {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 24px;\n}\n.Scores-desc-text {\n  max-width: 550px;\n  color: #222429;\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-ScoresShop-ScoresShop-module__ql-picker-FdSfEJ`,
	"qlPicker": `admin-src-components-ScoresShop-ScoresShop-module__ql-picker-FdSfEJ`,
	"ql-font": `admin-src-components-ScoresShop-ScoresShop-module__ql-font-YQso8a`,
	"qlFont": `admin-src-components-ScoresShop-ScoresShop-module__ql-font-YQso8a`,
	"ql-picker-label": `admin-src-components-ScoresShop-ScoresShop-module__ql-picker-label-nlDzuL`,
	"qlPickerLabel": `admin-src-components-ScoresShop-ScoresShop-module__ql-picker-label-nlDzuL`,
	"ql-picker-item": `admin-src-components-ScoresShop-ScoresShop-module__ql-picker-item-h8XUjo`,
	"qlPickerItem": `admin-src-components-ScoresShop-ScoresShop-module__ql-picker-item-h8XUjo`,
	"ql-font-Montserrat": `admin-src-components-ScoresShop-ScoresShop-module__ql-font-Montserrat-dlXvlO`,
	"qlFontMontserrat": `admin-src-components-ScoresShop-ScoresShop-module__ql-font-Montserrat-dlXvlO`,
	"Scores": `admin-src-components-ScoresShop-ScoresShop-module__Scores-Nf01ta`,
	"scores": `admin-src-components-ScoresShop-ScoresShop-module__Scores-Nf01ta`,
	"Scores-header": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-lSvu0l`,
	"scoresHeader": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-lSvu0l`,
	"Scores-header-title": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-title-safgn2`,
	"scoresHeaderTitle": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-title-safgn2`,
	"Scores-header-history": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-DVyRRr`,
	"scoresHeaderHistory": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-DVyRRr`,
	"Scores-header-history-text": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-text-Y_aAFb`,
	"scoresHeaderHistoryText": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-text-Y_aAFb`,
	"Scores-header-history-date": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-date-NEDrY0`,
	"scoresHeaderHistoryDate": `admin-src-components-ScoresShop-ScoresShop-module__Scores-header-history-date-NEDrY0`,
	"Scores-desc": `admin-src-components-ScoresShop-ScoresShop-module__Scores-desc-vdpwQV`,
	"scoresDesc": `admin-src-components-ScoresShop-ScoresShop-module__Scores-desc-vdpwQV`,
	"Scores-desc-text": `admin-src-components-ScoresShop-ScoresShop-module__Scores-desc-text-NhS78U`,
	"scoresDescText": `admin-src-components-ScoresShop-ScoresShop-module__Scores-desc-text-NhS78U`
};
export default ___CSS_LOADER_EXPORT___;
