import { Checkbox, Col, Row, Tooltip } from 'antd';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './UsersAssign.module.scss';

import { ADD_REPEAT, NOT_ADD } from '@/constants/studyPlanStatus';
import defAvatar from '@/assets/images/PortraitPlaceholder.png';

const UserNew = ({ user, selectUser, deletedItems, selectedIds, textBlock, isTopic, canMentor }) => {
  const {
    lastName,
    firstName,
    middleName,
    inPlan,
    trackInPlan,
    hasReward,
    department,
    position,
    id,
    userPlanType,
    mentor,
  } = user;
  const avatarTitle = () => `${lastName?.substr(0, 1)}${firstName.substr(0, 1)}`;

  const [avatar] = useLocalFileByUUID(user.localPhotoUuid, {
    defaultImg: defAvatar,
  });

  const fullName = `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
  const blockedUser =
    (inPlan || trackInPlan || hasReward || userPlanType === NOT_ADD || (canMentor && mentor)) &&
    userPlanType !== ADD_REPEAT;

  const isSelected = selectedIds?.find(i => i === id);

  const clickUser = user => {
    if (isSelected) {
      deletedItems(user.id);
    } else {
      selectUser(user);
    }
  };

  const renderText = txt => {
    if (txt.length > 30) {
      return <Tooltip title={txt}>{Utils.trString(txt, 30)}</Tooltip>;
    } else {
      return txt;
    }
  };

  const renderTextHelp = () => {
    if (userPlanType === ADD_REPEAT && isTopic) {
      return textBlock[1];
    } else if (blockedUser) {
      return textBlock[0];
    }
  };

  return (
    <>
      <Row
        justify='space-between'
        align='middle'
        className={classNames(css['User'], blockedUser && css['User-blocked'])}
        onClick={() => !blockedUser && clickUser(user)}
      >
        <Col xs={0} md={2}>
          <img src={avatar ? avatar : avatarTitle()} alt='photo' className={css['User-photo']} />
        </Col>
        <Col xs={22} sm={21}>
          <Row>
            <Col xs={24} md={12} className={css['User-fullname']}>
              {fullName}
            </Col>

            <Col xs={24} md={12} className={css['User-added']}>
              {renderTextHelp()}
            </Col>

            <Col xs={24} md={12} className={css['User-position']}>
              {position && renderText(position)}
            </Col>

            <Col xs={24} md={12} className={css['User-department']}>
              {department && renderText(department)}
            </Col>
          </Row>
        </Col>

        <Col xs={2} sm={1}>
          {!blockedUser && <Checkbox checked={isSelected} />}
        </Col>
      </Row>
      <div className={css['User-divider']}></div>
    </>
  );
};

UserNew.propTypes = {
  user: PropTypes.object,
  textBlock: PropTypes.array,
  selectUser: PropTypes.func,
  deletedItems: PropTypes.func,
  selectedIds: PropTypes.array,
};

export default UserNew;
