export const POST_ORDERS_FILTERS_START = 'POST_ORDERS_FILTERS_START';
export const POST_ORDERS_FILTERS_SUCCESS = 'POST_ORDERS_FILTERS_SUCCESS';
export const POST_ORDERS_FILTERS_FAIL = 'POST_ORDERS_FILTERS_FAIL';

export const GET_STATUS_START = 'GET_STATUS_START';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL';

export const GET_ORDER_HISTORY_START = 'GET_ORDER_HISTORY_START';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAIL = 'GET_ORDER_HISTORY_FAIL';

export const CHANGE_ORDER_START = 'CHANGE_ORDER_START';
export const CHANGE_ORDER_SUCCESS = 'CHANGE_ORDER_SUCCESS';
export const CHANGE_ORDER_FAIL = 'CHANGE_ORDER_FAIL';

export const EXPORT_ORDERS_START = 'EXPORT_ORDERS_START';
export const EXPORT_ORDERS_SUCCESS = 'EXPORT_ORDERS_SUCCESS';
export const EXPORT_ORDERS_FAIL = 'EXPORT_ORDERS_FAIL';

export const SAVE_ORDER_FILTERS = 'SAVE_ORDER_FILTERS';

export const CLEAR_ORDER_FILTERS = 'CLEAR_ORDER_FILTERS';
