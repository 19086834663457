// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-lje0ib.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Fx1CSL .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-label-SjR4bV[data-value="Montserrat"]:before, .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-lje0ib.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Fx1CSL .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-item-kbtLGD[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Montserrat-GTT9Bp {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-collapse-uXfDmX {
  margin: 35px 0;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-collapse-table-uRky5l {
  min-height: 300px;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-tag-LsP_Om {
  margin-bottom: 6px;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-tag-popover-e5Bgi8 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Topics/TopicDetailsDialog/LMS/Blocks/AddFromBankModal/AddFromBankModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,cAAc;AAChB;;AAMA;EAHE,iBAAiB;AACnB;;AAKA;EAFE,kBAAkB;AACpB;;AAIA;EADE,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.AddFromBankModal-collapse {\n  margin: 35px 0;\n}\n.AddFromBankModal-collapse-table {\n  min-height: 300px;\n}\n.AddFromBankModal-tag {\n  margin-bottom: 6px;\n}\n.AddFromBankModal-tag-popover {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-lje0ib`,
	"qlPicker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-lje0ib`,
	"ql-font": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Fx1CSL`,
	"qlFont": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Fx1CSL`,
	"ql-picker-label": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-label-SjR4bV`,
	"qlPickerLabel": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-label-SjR4bV`,
	"ql-picker-item": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-item-kbtLGD`,
	"qlPickerItem": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-picker-item-kbtLGD`,
	"ql-font-Montserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Montserrat-GTT9Bp`,
	"qlFontMontserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__ql-font-Montserrat-GTT9Bp`,
	"AddFromBankModal-collapse": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-collapse-uXfDmX`,
	"addFromBankModalCollapse": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-collapse-uXfDmX`,
	"AddFromBankModal-collapse-table": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-collapse-table-uRky5l`,
	"addFromBankModalCollapseTable": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-collapse-table-uRky5l`,
	"AddFromBankModal-tag": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-tag-LsP_Om`,
	"addFromBankModalTag": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-tag-LsP_Om`,
	"AddFromBankModal-tag-popover": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-tag-popover-e5Bgi8`,
	"addFromBankModalTagPopover": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-AddFromBankModal-AddFromBankModal-module__AddFromBankModal-tag-popover-e5Bgi8`
};
export default ___CSS_LOADER_EXPORT___;
