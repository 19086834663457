import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearBadgeFilters, getBadges, saveBadgeFilters } from '@/store/badge/actions';

import { selectBadge } from '@/store/badge/selectors';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import css from '../Badge.module.scss';

const { Option } = Select;

const FiltersBadge = () => {
  const { t } = useTranslation('badge');
  const dispatch = useDispatch();

  const { statusBadge, badgeSort } = useSelector(selectBadge);

  const [filters, setFilters] = useState({
    name: undefined,
    status: undefined,
  });

  const onSubmitFilters = () => {
    dispatch(saveBadgeFilters(filters));
    dispatch(getBadges(filters, 0, badgeSort));
  };

  const onResetFilters = () => {
    setFilters({ name: undefined, status: undefined });
    dispatch(getBadges({}, 0));
    dispatch(clearBadgeFilters());
  };

  const renderOption = arr => {
    return (
      arr?.length &&
      arr.map(item => {
        return (
          <Option value={item.value} key={item.id}>
            {item.name}
          </Option>
        );
      })
    );
  };

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-txt']}>{t('filters')}</div>
      <Form layout='vertical'>
        <Row type='flex' justify='start' gutter={[16, 0]}>
          <Col span={6}>
            <Form.Item label={t('status')} colon={false}>
              <Select
                className={css['Filters-block-select']}
                value={filters.status}
                allowClear
                showSearch
                onChange={e => setFilters({ ...filters, status: e })}
                optionFilterProp='children'
                placeholder={t('placeholderSelect')}
              >
                {renderOption(statusBadge)}
              </Select>
            </Form.Item>
          </Col>

          <Col span={18}>
            <Form.Item label={t('searchBadge')} colon={false}>
              <Input
                onChange={e => setFilters({ ...filters, name: e.target.value || undefined })}
                value={filters.name}
                placeholder={t('placeholderSearch')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='end' gutter={[16, 0]}>
          <Col>
            <Button onClick={onResetFilters}>{t('reset')}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmitFilters} type='primary'>
              {t('apply')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FiltersBadge;
