/* eslint-disable */
import {
  GET_BITRIX_WEBHOOK_FAIL,
  GET_BITRIX_WEBHOOK_START,
  GET_BITRIX_WEBHOOK_SUCCESS,
  GET_TYPEFORM_TOKEN_FAIL,
  GET_TYPEFORM_TOKEN_START,
  GET_TYPEFORM_TOKEN_SUCCESS,
  GET_WEBINAR_TOKEN_FAIL,
  GET_WEBINAR_TOKEN_START,
  GET_WEBINAR_TOKEN_SUCCESS,
  UPDATE_BITRIX_WEBHOOK_FAIL,
  UPDATE_BITRIX_WEBHOOK_START,
  UPDATE_BITRIX_WEBHOOK_SUCCESS,
  UPDATE_TYPEFORM_TOKEN_FAIL,
  UPDATE_TYPEFORM_TOKEN_START,
  UPDATE_TYPEFORM_TOKEN_SUCCESS,
  UPDATE_WEBINAR_TOKEN_FAIL,
  UPDATE_WEBINAR_TOKEN_START,
  UPDATE_WEBINAR_TOKEN_SUCCESS,
} from './constants';

const initialState = {
  tokens: {
    Typeform: '',
    WebinarRu: '',
    Bitrix: '',
  },
  isLoadingButtons: {
    Typeform: false,
    WebinarRu: false,
    Bitrix: false,
  },
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // START
    case UPDATE_WEBINAR_TOKEN_START:
    case GET_WEBINAR_TOKEN_START:
      return { ...state, isLoadingButtons: { ...state.isLoadingButtons, WebinarRu: true } };
    case UPDATE_TYPEFORM_TOKEN_START:
    case GET_TYPEFORM_TOKEN_START:
      return { ...state, isLoadingButtons: { ...state.isLoadingButtons, Typeform: true } };
    case GET_BITRIX_WEBHOOK_START:
    case UPDATE_BITRIX_WEBHOOK_START:
      return { ...state, isLoadingButtons: { ...state.isLoadingButtons, Bitrix: true } };

    // SUCCESS
    case UPDATE_WEBINAR_TOKEN_SUCCESS:
      return { ...state, isLoadingButtons: { ...state.isLoadingButtons, WebinarRu: false } };

    case UPDATE_TYPEFORM_TOKEN_SUCCESS:
      return { ...state, isLoadingButtons: { ...state.isLoadingButtons, Typeform: false } };

    case UPDATE_BITRIX_WEBHOOK_SUCCESS:
      return { ...state, isLoadingButtons: { ...state.isLoadingButtons, Bitrix: false } };

    case GET_WEBINAR_TOKEN_SUCCESS:
      state.isLoadingButtons.WebinarRu = false;
      return {
        ...state,
        tokens: { ...state.tokens, WebinarRu: response?.token },
        isLoadingButtons: { ...state.isLoadingButtons, WebinarRu: false },
      };

    case GET_TYPEFORM_TOKEN_SUCCESS:
      state.isLoadingButtons.Typeform = false;
      return {
        ...state,
        tokens: { ...state.tokens, Typeform: response?.token },
        isLoadingButtons: { ...state.isLoadingButtons, Typeform: false },
      };

    case GET_BITRIX_WEBHOOK_SUCCESS:
      state.isLoadingButtons.Bitrix = false;
      return {
        ...state,
        tokens: { ...state.tokens, Bitrix: response?.webhook },
        isLoadingButtons: { ...state.isLoadingButtons, Bitrix: false },
      };

    // FAIL
    case GET_WEBINAR_TOKEN_FAIL:
    case UPDATE_WEBINAR_TOKEN_FAIL:
    case GET_TYPEFORM_TOKEN_FAIL:
    case UPDATE_TYPEFORM_TOKEN_FAIL:
    case GET_BITRIX_WEBHOOK_FAIL:
    case UPDATE_BITRIX_WEBHOOK_FAIL:
      return { ...state };

    default:
      return {
        ...state,
      };
  }
};
