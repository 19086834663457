import {
  CREATE_CFIELD_FAIL,
  CREATE_CFIELD_START,
  CREATE_CFIELD_SUCCESS,
  DELETE_CFIELD_FAIL,
  DELETE_CFIELD_START,
  DELETE_CFIELD_SUCCESS,
  LIST_CFIELD_FAIL,
  LIST_CFIELD_START,
  LIST_CFIELD_SUCCESS,
  SWAP_CFIELD_FAIL,
  SWAP_CFIELD_START,
  SWAP_CFIELD_SUCCESS,
  UPDATE_CFIELD_FAIL,
  UPDATE_CFIELD_START,
  UPDATE_CFIELD_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Загружаем список произвольных полей
/**
 * Флаг availableAsUserFilter нужен был только в модалках,
 * в «видимости» и «назначении», для того,
 * чтобы получить только те поля, в которых хотя бы у одного пользователя проставлено значение
 *  */
export const getProfileCustomFieldList = (isDeleted, availableAsUserFilter, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/custom-field${getQueryString({
    availableAsUserFilter,
    isDeleted,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [LIST_CFIELD_START, LIST_CFIELD_SUCCESS, LIST_CFIELD_FAIL],
  onSuccess,
  onError,
});

// Создаем произвольное поле
export const createProfileCustomField = (
  name,
  description,
  type,
  placeholderValue,
  tab,
  showInProfile,
  sorted,
  filtered,
  enabledAsUserFilter,
  onSuccess,
  onError
) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/custom-field`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
    description,
    placeholderValue,
    typeId: type,
    showInProfile,
    canSorted: sorted,
    canFiltered: filtered,
    tabId: tab,
    enabledAsUserFilter,
  },
  types: [UPDATE_CFIELD_START, UPDATE_CFIELD_SUCCESS, UPDATE_CFIELD_FAIL],
  onSuccess,
  onError,
});

// Обновляем произвольное поле
export const updateProfileCustomField = (
  id,
  name,
  description,
  type,
  placeholderValue,
  position,
  tab,
  showInProfile,
  sorted,
  filtered,
  enabledAsUserFilter,
  onSuccess,
  onError
) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/custom-field/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id,
    name,
    description,
    placeholderValue,
    typeId: type,
    showInProfile,
    canSorted: sorted,
    canFiltered: filtered,
    position,
    tabId: tab,
    enabledAsUserFilter,
  },
  types: [CREATE_CFIELD_START, CREATE_CFIELD_SUCCESS, CREATE_CFIELD_FAIL],
  onSuccess,
  onError,
});

// Смена позиции на уровень ниже/выше
export const swapProfileCustomField = (id, swapId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/custom-field/${id}/swap/${swapId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id,
    swapId,
  },
  types: [SWAP_CFIELD_START, SWAP_CFIELD_SUCCESS, SWAP_CFIELD_FAIL],
  onSuccess,
  onError,
});

export const getAllCustomFields = (userId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/profile-fields/user/${userId}/values`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [LIST_CFIELD_START, LIST_CFIELD_SUCCESS, LIST_CFIELD_FAIL],
  onSuccess,
});

// Удаляем произвольное поле
export const deleteProfileCustomField = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/custom-field/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_CFIELD_START, DELETE_CFIELD_SUCCESS, DELETE_CFIELD_FAIL],
  onSuccess,
  onError,
});
