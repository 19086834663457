export const QUESTION_TYPES = {
  single: '1', // Один вариант ответа
  multiple: '2', // Множество вариантов ответа
  open: '3', // Открытый вопрос
};

export const MAX_CATEGORY_LENGTH = 100;

export const MAX_CATEGORY_TAGS = 10;

export const QUESTIONS_TABLE_SIZE = 20;

export const QUESTIONS_TABLE_SIZE_MODAL = 10;
