/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { checkChapters, getChapters } from '@/store/chapters/actions';
import { setExistingMaterial, updateExistingMaterial } from '@/store/existing-materials/actions';
import { checkTopic } from '@/store/topics/actions';

import { Button, Dropdown, Form, Input, notification, Tooltip } from 'antd';
import { EllipsisOutlined, FileTextOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';

const MAX_TITLE_LENGTH = 65;

export const ExistingMaterial = props => {
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState(props.data.existingTopicId);

  const checkTopicId = () => {
    if (Number(value) !== props.data.topicId) {
      props.checkTopic(
        value,
        res => {
          res.type.id === 25
            ? notification.error({
                message: props.t('error'),
                description: props.t('errorExecutiveTask'),
              })
            : props.checkChapters(value, chapters => {
                chapters.length
                  ? setIsChecked(true)
                  : notification.error({
                      message: props.t('error'),
                      description: props.t('errorChapter'),
                    });
              });
        },
        () => {
          notification.error({
            message: props.t('error'),
            description: props.t('errorBody'),
          });
        }
      );
    } else {
      notification.error({
        message: props.t('error'),
        description: props.t('errorTopicId'),
      });
    }
  };

  const saveTopicId = () => {
    if (props.data.existingTopicId) {
      props.updateExistingMaterial(props.data.id, value, () => {
        props.getChapters(props.data.topicId);
        setIsChecked(false);
      });
    } else {
      props.setExistingMaterial(props.data.id, value, () => {
        props.getChapters(props.data.topicId);
        setIsChecked(false);
      });
    }
  };

  const changeValue = newValue => {
    setValue(newValue);
    isChecked && setIsChecked(false);
  };

  const renderHeader = useMemo(() => (
    <div className={css['Block-header']}>
      <FileTextOutlined className={css['Block-header-item']} />
      <div className={css['Block-header-name']}>
        <Tooltip title={props.data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
          {Utils.trString(props.data.name, MAX_TITLE_LENGTH)}
        </Tooltip>
      </div>
    </div>
  ));

  const dropDownMenu = [
    ...(props.currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (props.topicTypeId === 25 && props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={() => props.editBlock(props.data.id)}>
                {props.t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(props.currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (props.topicTypeId === 25 && props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={() => props.data.id && props.deleteBlock(props.data.id)}>
                {props.t('remove')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const renderExtra = useMemo(() => (
    <div onClick={e => e.stopPropagation()}>
      <Button
        className={css['Block-header-save']}
        type='primary'
        data-qa='saveBlockBtn'
        disabled={!props.canEdit || !isChecked}
        onClick={saveTopicId}
      >
        {props.t('save')}
      </Button>
      {(props.currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
        props.currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
        (props.topicTypeId === 25 &&
          (props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
            props.currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
        <Dropdown menu={{ items: dropDownMenu }} placement='bottomRight' trigger={['click']}>
          <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
        </Dropdown>
      )}
    </div>
  ));

  const render = () => {
    const { data, forceClose, t } = props;

    return (
      <div className={css['Block']}>
        <CollapseLMS
          className={css['Block-layout']}
          header={renderHeader}
          extra={renderExtra}
          data={data}
          key={data.id}
          forceClose={forceClose}
        >
          <Form>
            <Form.Item className={css['ExistingMaterial-block']} label={t('topicId')} labelCol={{ span: 5 }}>
              <Input
                value={value}
                onChange={event => changeValue(event.target.value)}
                type='number'
                placeholder={t('writeTopicId')}
              />
              <Button disabled={isChecked || value === data.existingTopicId} onClick={checkTopicId}>
                {t('check')}
              </Button>
            </Form.Item>
          </Form>
        </CollapseLMS>
      </div>
    );
  };

  return render();
};

ExistingMaterial.propTypes = {
  forceClose: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  editBlock: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  checkTopic: PropTypes.func.isRequired,
  setExistingMaterial: PropTypes.func.isRequired,
  updateExistingMaterial: PropTypes.func.isRequired,
  getChapters: PropTypes.func.isRequired,
  checkChapters: PropTypes.func.isRequired,
};

const mapStateToProps = null;

const mapActionToProps = {
  checkTopic,
  setExistingMaterial,
  updateExistingMaterial,
  getChapters,
  checkChapters,
};

export default connect(mapStateToProps, mapActionToProps)(withTranslation('existingMaterial')(ExistingMaterial));
