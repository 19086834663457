/* eslint-disable */
import { getUserDomainCompanyImages } from '@/store/users/actions';

import { roles } from '@/pages/Companies/CreateModal/Users';

import {
  CHANGE_NOTIFICATION_USER_FAIL,
  CHANGE_NOTIFICATION_USER_START,
  CHANGE_NOTIFICATION_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_START,
  EDIT_USER_SUCCESS,
  GET_ADMIN_FAIL,
  GET_ADMIN_START,
  GET_ADMIN_SUCCESS,
  GET_DIAGNOSTIC_RESULT_FAIL,
  GET_DIAGNOSTIC_RESULT_START,
  GET_DIAGNOSTIC_RESULT_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_USER_FAIL,
  GET_USER_START,
  GET_USER_STAT_FAIL,
  GET_USER_STAT_START,
  GET_USER_STAT_SUCCESS,
  GET_USER_SUCCESS,
  GET_USERRESULTCSV_FAIL,
  GET_USERRESULTCSV_START,
  GET_USERRESULTCSV_SUCCESS,
} from './actions';

import { createReducer, reducerAdapter, updateState } from '@shared/utils/redux-helpers';

import { USER_LOGOUT_SUCCESS } from '@/store/login/types';

const initialState = {
  currentUser: null,
  isLoading: false,
  error: false,
  userSubordinateEmployees: null,
  userStat: null,
  result: null,
  isLoadingResult: false,
};

const actions = {
  [getUserDomainCompanyImages.success]: (state, payload) => updateState(state, payload),
};

const oldReducer = (state = initialState, { type, response, action }) => {
  switch (type) {
    case GET_USERRESULTCSV_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_USERRESULTCSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case GET_USERRESULTCSV_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'GET_USER_STAT_START':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'GET_USER_STAT_SUCCESS':
      return {
        ...state,
      };

    case 'GET_USER_STAT_FAIL':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case GET_ADMIN_START:
    case GET_USER_START:
      return {
        ...state,
        currentUser: null,
        isLoading: true,
        error: false,
      };

    case CHANGE_NOTIFICATION_USER_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_ROLES_START:
    case CHANGE_NOTIFICATION_USER_SUCCESS:
      return { ...state, isLoading: false, error: false };
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: {
          authorities: action.values,
          login: 'admin',
          isGlobalAdmin: true,
        },
      };

    case GET_USER_SUCCESS:
      return { ...state, currentUser: response, isLoading: false };

    case EDIT_USER_START:
      return { ...state, isLoading: true, error: false };

    case EDIT_USER_SUCCESS:
      return { ...state, isLoading: false };

    case GET_ROLES_SUCCESS:
      return { ...state, roles: response, isLoading: false };

    case CHANGE_NOTIFICATION_USER_FAIL:
    case GET_ADMIN_FAIL:
    case EDIT_USER_FAIL:
    case GET_USER_FAIL:
    case GET_ROLES_FAIL:
      return { ...state, error: true, isLoading: false };

    case USER_LOGOUT_SUCCESS:
      return { ...state, currentUser: null };

    case GET_DIAGNOSTIC_RESULT_SUCCESS:
      return {
        ...state,
        isLoadingResult: false,
        error: false,
        result: response,
      };

    case GET_DIAGNOSTIC_RESULT_FAIL:
      return {
        ...state,
        isLoadingResult: false,
        error: true,
      };

    case GET_DIAGNOSTIC_RESULT_START:
      return {
        ...state,
        isLoadingResult: true,
        error: false,
      };

    default:
      return state;
  }
};

const reducer = createReducer(actions, initialState);

export default reducerAdapter(actions, initialState, reducer, oldReducer);
