/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { D360GetFilterCompetencies } from '@/store/d360/d360_filters/actions';

import { Button, Col, Input, Row, Select } from 'antd';
import { FiHexagon } from 'react-icons/fi';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../../../css/index.module.scss';

const { TextArea } = Input;
const { Option } = Select;

export const StepCompetences = props => {
  const [description, setDescription] = useState(null);
  const [enableDescription, setEnableDescription] = useState(false);
  const [selected, setSelected] = useState(null);
  const [competencies, setCompetencies] = useState([]);
  const { t } = useTranslation('D360_StepCompetences');
  const competenceList = useSelector(state => state.d360Criterias);

  useEffect(() => {
    props.D360GetFilterCompetencies(success => {
      if (success && success.data && Array.isArray(success.data)) {
        setCompetencies(success.data);
      }
    });
  }, []);

  useEffect(() => {
    if (props.onSelect && typeof props.onSelect === 'function') {
      props.onSelect(selected, description);
    }
  }, [selected, description]);

  const selectCompetence = id => {
    if (parseInt(id, 10) <= 0) {
      return setSelected(null);
    }

    let competence = competencies.filter(item => {
      return item.id === parseInt(id, 10);
    });

    if (competence && Array.isArray(competence) && competence.length) {
      if (!_.isNil(competenceList) && _.isObject(competenceList) && _.has(competenceList, 'criterias')) {
        if (_.isArray(competenceList.criterias)) {
          const res = competenceList.criterias.filter(item => item.competence_id === id);
          if (_.isArray(res) && _.isObject(res[0]) && _.has(res[0], 'competence_description')) {
            setEnableDescription(true);
            setDescription(res[0].competence_description);
          }
        }
      }

      setSelected(competence[0]);
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div className={css.StepScreenWindow}>
        {/*List: {JSON.stringify(competenceList)}*/}

        <Row className={css.Notes}>
          <Col span={24} className={css.NotesCol}>
            <FiHexagon size='3rem' className={css.NotesIcon} />
            <p className={css.NotesText}>{t('title')}</p>
          </Col>
        </Row>

        <Row className={css.StepScreenRow}>
          <Col span={12}>* {t('competences')}</Col>
          {selected && (
            <Col span={12} align='right'>
              <Button type='link' size='small' onClick={() => setEnableDescription(!enableDescription)}>
                {!enableDescription ? t('addInterpretation') : t('delInterpretation')}
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Select defaultValue={0} style={{ width: '100%' }} onSelect={selectCompetence}>
              <Option key={0} value={0}>
                {t('defaultSelected')}
              </Option>
              {competencies &&
                Array.isArray(competencies) &&
                competencies.map(competence => {
                  return (
                    <Option key={competence.id} value={competence.id}>
                      {competence.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>

        {enableDescription && (
          <Row style={{ marginTop: '1rem' }}>
            <Col span={24}>{t('textAreaLabel')}</Col>
            <Col span={24}>
              <TextArea rows={4} onChange={e => setDescription(e.target.value)} value={description} maxLength={500} />
            </Col>
          </Row>
        )}
      </div>
    );
  };

  return render();
};

StepCompetences.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  D360GetFilterCompetencies,
};

export default connect(null, mapDispatchToProps)(StepCompetences);
