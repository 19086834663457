import {
  GET_EXAMPLE_FAIL,
  GET_EXAMPLE_START,
  GET_EXAMPLE_SUCCESS,
  IMPORT_QUESTION_FAIL,
  IMPORT_QUESTION_START,
  IMPORT_QUESTION_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';

import { headersForImport } from '@/constants/global';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const importQuestionFile = (blob, id, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${id}/import`,
  headers: {
    ...headersForImport,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: blob,
  requestType: 'formData',
  types: [IMPORT_QUESTION_START, IMPORT_QUESTION_SUCCESS, IMPORT_QUESTION_FAIL],
  onSuccess,
  onError,
});

export const getExampleFile = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/service/file/export/block-test-load`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_EXAMPLE_START, GET_EXAMPLE_SUCCESS, GET_EXAMPLE_FAIL],
  onSuccess,
  onError,
});
