/* eslint-disable */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFileSizes } from '@/store/files/selectors';

import { Button, Checkbox, Dropdown, Form, Tooltip } from 'antd';
import ConfirmationModal from '@/components/ConfirmationModal';
import SingleFileInput from '@/components/FileInput/SingleFileInput';
import { EllipsisOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../CollapseLMS';

import Utils from '@/Utils';

import css from '../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';
import scorm from '@/assets/images/scorm.svg';

export const Scorm = ({
  data,
  editBlock,
  deleteBlock,
  forceClose,
  createScorm,
  uploadScorm,
  getBlocks,
  topicId,
  getChapters,
  canEdit,
  currentUser,
  topicTypeId,
  showSaveModal,
}) => {
  const { t } = useTranslation('scorm');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState((data.scorm && data.scorm.fileName) || null);
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(true);
  const [required, setRequired] = useState(data.requiredPassing);
  const [checkTestPassed, setCheckTestPassed] = useState(
    data.scorm ? data.scorm.requiredTestPassing : required ? true : false
  );
  const [checkCompleted, setCheckCompleted] = useState(
    data.scorm ? data.scorm.requiredFullCompletion : required ? true : false
  );
  const [scormID, setScormID] = useState(data.scorm ? data.scorm.id : null);
  const [isLoading, setIsLoading] = useState(false);
  const fileSizes = useSelector(selectFileSizes);
  const scormFileSize = fileSizes.filter(item => item.uploadSizeType === 'SCORM')[0];
  const header = useMemo(
    () => (
      <div className={css['Block-header']}>
        <img className={css['Block-header-item']} src={scorm} alt='' />
        <div className={css['Block-header-name']}>
          <Tooltip title={data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(data.name, 55)}
          </Tooltip>
        </div>
      </div>
    ),
    [data.name]
  );

  const saveButtonHandler = useCallback(
    e => {
      e.stopPropagation();
      const body = {
        id: scormID,
        requiredPassing: required,
        requiredFullCompletion: checkCompleted,
        requiredTestPassing: checkTestPassed,
      };
      createScorm(data.id, body, () => {
        setFile(null);
        setDirty(true);
        getChapters(topicId);
      });
    },
    [data, file, dirty, createScorm, getBlocks, topicId, required, checkCompleted, checkTestPassed]
  );

  const handleEditClick = useCallback(() => {
    editBlock(data.id);
  }, [editBlock]);

  const handleDeleteClick = useCallback(() => {
    deleteBlock(data.id);
  }, [deleteBlock]);

  const saveModal = useCallback(
    e => {
      showSaveModal({
        id: scormID,
        delId: data.id,
        funcSave: () => saveButtonHandler(e),
        funcCancel: () => {
          if (data?.scorm) {
            setScormID(data.scorm.id);
            setFileName(data.scorm.fileName);
          }
          setDirty(true);
          setRequired(false);
        },
      });
    },
    [showSaveModal, scormID, saveButtonHandler, fileName]
  );

  const dropDownMenu = [
    ...(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE))
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={handleEditClick}>
                {t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (topicTypeId === 25 && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE))
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={handleDeleteClick}>
                {t('delete')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const extra = useMemo(
    () => (
      <div onClick={e => e.stopPropagation()}>
        <Button
          onClick={saveModal}
          className={css['Block-header-save']}
          disabled={dirty || !canEdit || isLoading}
          type='primary'
          data-qa='saveBlockBtn'
        >
          {t('save')}
        </Button>
        {(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
          currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
          (topicTypeId === 25 &&
            (currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
              currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
          <Dropdown
            menu={{ items: dropDownMenu }}
            placement='bottomRight'
            overlayClassName={css['Dropdown-menu']}
            trigger={['click']}
          >
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    ),
    [deleteBlock, editBlock, saveButtonHandler, dirty, canEdit, required]
  );

  const changeRequired = event => {
    switch (event.target.name) {
      case 'checkTestPassed':
        setDirty(false);
        if (event.target.checked) {
          setCheckTestPassed(true);
        } else {
          if (checkCompleted) {
            setCheckTestPassed(false);
          } else {
            setCheckTestPassed(false);
            setRequired(false);
          }
        }
        break;
      case 'checkCompleted':
        setDirty(false);
        if (event.target.checked) {
          setCheckCompleted(true);
        } else {
          if (checkTestPassed) {
            setCheckCompleted(false);
          } else {
            setCheckCompleted(false);
            setRequired(false);
          }
        }
        break;
      case 'required':
        setDirty(false);
        if (event.target.checked) {
          setRequired(true);
          setCheckTestPassed(true);
          setCheckCompleted(true);
        } else {
          setRequired(false);
          setCheckTestPassed(false);
          setCheckCompleted(false);
        }
        break;
      default:
        return;
    }
  };

  const onChange = useCallback(
    file => {
      if (!file) {
        setFile(null);
        setFileName(null);
        setDirty(false);
        setScormID(null);
      } else {
        uploadScorm(
          file,
          res => {
            setScormID(res.id);
            setFileName(res.fileName);
            setIsLoading(false);
            setDirty(false);
            setFile(file);
          },
          e => {
            setError(true);
            setFile(null);
            setIsLoading(false);
          }
        );
      }
    },
    [file, dirty, error, scormID]
  );

  return (
    <div className={css.Block}>
      <CollapseLMS header={header} data={data} extra={extra} forceClose={forceClose}>
        <div className={css['Block-layoutScorm']}>
          <Form.Item label={t('file')} labelAlign='left' wrapperCol={{ span: 20 }}>
            <SingleFileInput
              type='file'
              loading={isLoading}
              setLoading={setIsLoading}
              onChange={onChange}
              name={fileName}
              disable={!canEdit}
              error={error}
              scormFileSize={scormFileSize}
            />
          </Form.Item>
          <Form.Item labelCol={{ span: 7 }} labelAlign='left'>
            <Checkbox name='required' checked={required} onChange={changeRequired} disabled={!fileName}>
              {t('required')}
            </Checkbox>
          </Form.Item>
          {required && (
            <div className={css['ml-50']}>
              <Form.Item label={t('checkTestPassed')} labelAlign='left'>
                <Checkbox
                  name='checkTestPassed'
                  checked={checkTestPassed}
                  onChange={changeRequired}
                  disabled={!fileName}
                />
              </Form.Item>
              <Form.Item label={t('checkCompleted')} labelAlign='left'>
                <Checkbox
                  name='checkCompleted'
                  checked={checkCompleted}
                  onChange={changeRequired}
                  disabled={!fileName}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </CollapseLMS>
      {error && (
        <ConfirmationModal
          open
          onOk={() => {
            setError(false);
            setIsLoading(false);
          }}
          onCancel={() => {
            setError(false);
            setIsLoading(false);
          }}
          okText={t('ok')}
          zIndex={1006}
          title={t('error')}
          confirmationText={t('description')}
        />
      )}
    </div>
  );
};
