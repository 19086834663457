/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_CUSTOM_TAGS_START = 'GET_CUSTOM_TAGS_START';
export const GET_CUSTOM_TAGS_SUCCESS = 'GET_CUSTOM_TAGS_SUCCESS';
export const GET_CUSTOM_TAGS_FAIL = 'GET_CUSTOM_TAGS_FAIL';

export const CLEAR_CUSTOM_TAGS = 'CLEAR_CUSTOM_TAGS';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getCustomTags = (path, query, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}${path}${getQueryString(query)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  values: { path },
  types: [GET_CUSTOM_TAGS_START, GET_CUSTOM_TAGS_SUCCESS, GET_CUSTOM_TAGS_FAIL],
  onSuccess,
  onError,
});

export const clearCustomTags = () => ({ type: CLEAR_CUSTOM_TAGS });
