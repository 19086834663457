// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Skills-style-module__ql-picker-W05wjc.admin-src-pages-Skills-style-module__ql-font-EClKPM .admin-src-pages-Skills-style-module__ql-picker-label-fj5j15[data-value="Montserrat"]:before, .admin-src-pages-Skills-style-module__ql-picker-W05wjc.admin-src-pages-Skills-style-module__ql-font-EClKPM .admin-src-pages-Skills-style-module__ql-picker-item-IvJZ4j[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Skills-style-module__ql-font-Montserrat-gqgJqN {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Skills-style-module__skills-TMatob {
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  padding: 40px 56px;
}

.admin-src-pages-Skills-style-module__skills__header-KIjCBU {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.admin-src-pages-Skills-style-module__skills__buttons-_kQUEl {
  gap: 10px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Skills/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAMA;EAHE,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAKA;EAFE,SAAS;EACT,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.skills {\n  padding: 40px 56px;\n  max-width: 1380px;\n  width: 100%;\n  margin: 0 auto;\n}\n.skills__header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 40px;\n}\n.skills__buttons {\n  display: flex;\n  gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Skills-style-module__ql-picker-W05wjc`,
	"qlPicker": `admin-src-pages-Skills-style-module__ql-picker-W05wjc`,
	"ql-font": `admin-src-pages-Skills-style-module__ql-font-EClKPM`,
	"qlFont": `admin-src-pages-Skills-style-module__ql-font-EClKPM`,
	"ql-picker-label": `admin-src-pages-Skills-style-module__ql-picker-label-fj5j15`,
	"qlPickerLabel": `admin-src-pages-Skills-style-module__ql-picker-label-fj5j15`,
	"ql-picker-item": `admin-src-pages-Skills-style-module__ql-picker-item-IvJZ4j`,
	"qlPickerItem": `admin-src-pages-Skills-style-module__ql-picker-item-IvJZ4j`,
	"ql-font-Montserrat": `admin-src-pages-Skills-style-module__ql-font-Montserrat-gqgJqN`,
	"qlFontMontserrat": `admin-src-pages-Skills-style-module__ql-font-Montserrat-gqgJqN`,
	"skills": `admin-src-pages-Skills-style-module__skills-TMatob`,
	"skills__header": `admin-src-pages-Skills-style-module__skills__header-KIjCBU`,
	"skillsHeader": `admin-src-pages-Skills-style-module__skills__header-KIjCBU`,
	"skills__buttons": `admin-src-pages-Skills-style-module__skills__buttons-_kQUEl`,
	"skillsButtons": `admin-src-pages-Skills-style-module__skills__buttons-_kQUEl`
};
export default ___CSS_LOADER_EXPORT___;
