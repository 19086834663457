import './constants';

import {
  EXPORT_TOPIC_FAIL,
  EXPORT_TOPIC_START,
  EXPORT_TOPIC_SUCCESS,
  GET_POKA_DIR_CATEGORY_FAIL,
  GET_POKA_DIR_CATEGORY_START,
  GET_POKA_DIR_CATEGORY_SUCCESS,
  GET_POKA_DIR_LEVEL_FAIL,
  GET_POKA_DIR_LEVEL_START,
  GET_POKA_DIR_LEVEL_SUCCESS,
  GET_POKA_DIR_POSITION_FAIL,
  GET_POKA_DIR_POSITION_START,
  GET_POKA_DIR_POSITION_SUCCESS,
  GET_POKA_DIR_SITE_FAIL,
  GET_POKA_DIR_SITE_START,
  GET_POKA_DIR_SITE_SUCCESS,
  GET_POKA_DIR_STATUS_FAIL,
  GET_POKA_DIR_STATUS_START,
  GET_POKA_DIR_STATUS_SUCCESS,
  GET_POKA_SKILLS_FAIL,
  GET_POKA_SKILLS_START,
  GET_POKA_SKILLS_SUCCESS,
  GET_TOPIC_LABELS_FAIL,
  GET_TOPIC_LABELS_START,
  GET_TOPIC_LABELS_SUCCESS,
} from '@/store/poka_skills/constants';

const mockStatus = [
  {
    label: 'APPROVED',
    name: 'approved',
  },
  {
    label: 'WAITING_FOR_APPROVE',
    name: 'waitingForApprove',
  },
  {
    label: 'NEED_TO_EDIT',
    name: 'needToEdit',
  },
];

const mockApprovedDate = '01.11.2024';

const randomNumber = (min = 0, max = 3) => Math.floor(Math.random() * (min + max) - min);

const initialState = {
  topics: [],
  labels: [],
  categories: [],
  sites: [],
  positions: [],
  levels: [],
  statuses: [],
  loading: {
    getPokaSkills: false,
    getTypeLabels: false,
    exportSkills: false,
    getPokaDirLevel: false,
    getPokaDirCategory: false,
    getPokaDirSite: false,
    getPokaDirPosition: false,
    getPokaDirStatus: false,
  },
  error: {
    getPokaSkills: false,
    getTypeLabels: false,
    exportSkills: false,
    getPokaDirLevel: false,
    getPokaDirCategory: false,
    getPokaDirSite: false,
    getPokaDirPosition: false,
    getPokaDirStatus: false,
  },
  pagination: {
    getPokaSkills: {
      total: 0,
      pageSize: 0,
      pageNumber: 0,
    },
  },
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_POKA_SKILLS_START:
      return {
        ...state,
        loading: { ...state.loading, getPokaSkills: true },
        error: { ...state.error, getPokaSkills: false },
        topics: [],
      };

    case GET_POKA_SKILLS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPokaSkills: false },
        error: { ...state.error, getPokaSkills: false },
        pagination: {
          ...state.pagination,
          getPokaSkills: {
            total: Number(headers.get('X-Total-Elements')) || 0,
            pageSize: Number(headers.get('X-Page-Size')) || 0,
            pageNumber: Number(headers.get('X-Page-Number')) || 0,
          },
        },
        topics: response?.map(item => ({
          ...item,
          poka: { ...item.poka, generalApprovalStatus: mockStatus[randomNumber()], approvedDate: mockApprovedDate },
        })),
      };

    case GET_POKA_SKILLS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getPokaSkills: false },
        error: { ...state.error, getPokaSkills: true },
        topics: [],
      };

    case GET_TOPIC_LABELS_START:
      return {
        ...state,
        loading: { ...state.loading, getTypeLabels: true },
        error: { ...state.error, getTypeLabels: false },
        labels: [],
      };

    case GET_TOPIC_LABELS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTypeLabels: false },
        error: { ...state.error, getTypeLabels: false },
        labels: response,
      };

    case GET_TOPIC_LABELS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getTypeLabels: false },
        error: { ...state.error, getTypeLabels: true },
        labels: [],
      };

    case EXPORT_TOPIC_START:
      return {
        ...state,
        loading: { ...state.loading, exportSkills: true },
        error: { ...state.error, exportSkills: false },
      };

    case EXPORT_TOPIC_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, exportSkills: false },
        error: { ...state.error, exportSkills: false },
      };

    case EXPORT_TOPIC_FAIL:
      return {
        ...state,
        loading: { ...state.loading, exportSkills: false },
        error: { ...state.error, exportSkills: true },
      };

    case GET_POKA_DIR_POSITION_START:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirPosition: true },
        error: { ...state.error, getPokaDirPosition: false },
        positions: [],
      };

    case GET_POKA_DIR_POSITION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirPosition: false },
        error: { ...state.error, getPokaDirPosition: false },
        positions: response,
      };

    case GET_POKA_DIR_POSITION_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirPosition: false },
        error: { ...state.error, getPokaDirPosition: true },
        positions: [],
      };

    case GET_POKA_DIR_SITE_START:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirSite: true },
        error: { ...state.error, getPokaDirSite: false },
        sites: [],
      };

    case GET_POKA_DIR_SITE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirSite: false },
        error: { ...state.error, getPokaDirSite: false },
        sites: response,
      };

    case GET_POKA_DIR_SITE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirSite: false },
        error: { ...state.error, getPokaDirSite: true },
        sites: [],
      };

    case GET_POKA_DIR_CATEGORY_START:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirCategory: true },
        error: { ...state.error, getPokaDirCategory: false },
        categories: [],
      };

    case GET_POKA_DIR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirCategory: false },
        error: { ...state.error, getPokaDirCategory: false },
        categories: response,
      };

    case GET_POKA_DIR_CATEGORY_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirCategory: false },
        error: { ...state.error, getPokaDirCategory: true },
        categories: [],
      };

    case GET_POKA_DIR_LEVEL_START:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirLevel: true },
        error: { ...state.error, getPokaDirLevel: false },
        levels: [],
      };

    case GET_POKA_DIR_LEVEL_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirLevel: false },
        error: { ...state.error, getPokaDirLevel: false },
        levels: response,
      };

    case GET_POKA_DIR_LEVEL_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirLevel: false },
        error: { ...state.error, getPokaDirLevel: true },
        levels: [],
      };

    case GET_POKA_DIR_STATUS_START:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirStatus: true },
        error: { ...state.error, getPokaDirStatus: false },
        statuses: [],
      };

    case GET_POKA_DIR_STATUS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirStatus: false },
        error: { ...state.error, getPokaDirStatus: false },
        statuses: response,
      };

    case GET_POKA_DIR_STATUS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getPokaDirStatus: false },
        error: { ...state.error, getPokaDirStatus: true },
        statuses: [],
      };

    default:
      return state;
  }
};
