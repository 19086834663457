// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-MassSelection-components-Modals-style-module__ql-picker-xDi18W.admin-src-components-MassSelection-components-Modals-style-module__ql-font-asQHwW .admin-src-components-MassSelection-components-Modals-style-module__ql-picker-label-vvXNLh[data-value="Montserrat"]:before, .admin-src-components-MassSelection-components-Modals-style-module__ql-picker-xDi18W.admin-src-components-MassSelection-components-Modals-style-module__ql-font-asQHwW .admin-src-components-MassSelection-components-Modals-style-module__ql-picker-item-F5RoKF[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-MassSelection-components-Modals-style-module__ql-font-Montserrat-H_QfLd {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-MassSelection-components-Modals-style-module__modals__buttons-lvQQgp {
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
}

.admin-src-components-MassSelection-components-Modals-style-module__modals__content-vQEc6H {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.admin-src-components-MassSelection-components-Modals-style-module__modals__alert-jDalqJ {
  padding: 10px;
}

.admin-src-components-MassSelection-components-Modals-style-module__modals__alert-jDalqJ > span {
  color: var(--ant-color-warning);
}

.admin-src-components-MassSelection-components-Modals-style-module__modals__alert-jDalqJ > div > div:first-child {
  margin-bottom: 10px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/MassSelection/components/Modals/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,yBAAyB;EACzB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAMA;EAHE,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAKA;EAFE,aAAa;AACf;;AAIA;EADE,+BAA+B;AACjC;;AAGA;EAAE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.modals__buttons {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  justify-content: flex-end;\n}\n.modals__content {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.modals__alert {\n  padding: 10px;\n}\n.modals__alert > span {\n  color: var(--ant-color-warning);\n}\n.modals__alert > div > div:first-child {\n  font-weight: 600;\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-MassSelection-components-Modals-style-module__ql-picker-xDi18W`,
	"qlPicker": `admin-src-components-MassSelection-components-Modals-style-module__ql-picker-xDi18W`,
	"ql-font": `admin-src-components-MassSelection-components-Modals-style-module__ql-font-asQHwW`,
	"qlFont": `admin-src-components-MassSelection-components-Modals-style-module__ql-font-asQHwW`,
	"ql-picker-label": `admin-src-components-MassSelection-components-Modals-style-module__ql-picker-label-vvXNLh`,
	"qlPickerLabel": `admin-src-components-MassSelection-components-Modals-style-module__ql-picker-label-vvXNLh`,
	"ql-picker-item": `admin-src-components-MassSelection-components-Modals-style-module__ql-picker-item-F5RoKF`,
	"qlPickerItem": `admin-src-components-MassSelection-components-Modals-style-module__ql-picker-item-F5RoKF`,
	"ql-font-Montserrat": `admin-src-components-MassSelection-components-Modals-style-module__ql-font-Montserrat-H_QfLd`,
	"qlFontMontserrat": `admin-src-components-MassSelection-components-Modals-style-module__ql-font-Montserrat-H_QfLd`,
	"modals__buttons": `admin-src-components-MassSelection-components-Modals-style-module__modals__buttons-lvQQgp`,
	"modalsButtons": `admin-src-components-MassSelection-components-Modals-style-module__modals__buttons-lvQQgp`,
	"modals__content": `admin-src-components-MassSelection-components-Modals-style-module__modals__content-vQEc6H`,
	"modalsContent": `admin-src-components-MassSelection-components-Modals-style-module__modals__content-vQEc6H`,
	"modals__alert": `admin-src-components-MassSelection-components-Modals-style-module__modals__alert-jDalqJ`,
	"modalsAlert": `admin-src-components-MassSelection-components-Modals-style-module__modals__alert-jDalqJ`
};
export default ___CSS_LOADER_EXPORT___;
