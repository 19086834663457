import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getFile } from '@/store/files/actions';
import { getMassFilters } from '@/store/massfilter/actions';
import { getStudents } from '@/store/students/actions';
import {
  changeStatusTrack,
  exportMassUsersTrack,
  getTrackCountUsers,
  // clearTrackAppointFilters,
  getTrackDetails,
  getTrackProgress,
  postAppointFilters,
  trackAddToPlan,
  trackAddToPlanMass,
} from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, notification, Spin, Tabs, Tooltip } from 'antd';
import Header from '@/pages/CompilationDetail/Header';
import Assignment from '@/components/Assignment';
import CalendarModal from '@/components/CalendarModal';
import { Content } from '@/components/Layout';
import ModalTrack from '@/components/ModalTrack';
import { CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { HiOutlineEmojiSad } from 'react-icons/hi';

import {
  STATUS_IN_PROCESS,
  STATUS_ON_UPDATE,
  TAB_KEY_APPOINT,
  TAB_KEY_CONTENT_SETTINGS,
  TAB_KEY_DESCRIPTION,
  TYPE_ADD_MANUAL,
  TYPE_ADD_MASS,
} from './constants';
import TabAppointments from './Content/TabAppointments';
import TabContentSettings from './Content/TabContentSettings';
import TabDescription from './Content/TabDescription';

import { tracks } from '@/router/routes';
import Utils from '@/Utils';

import css from './TrackDetail.module.scss';

import { TRACK_UPDATE } from '@/constants/permissions';

const TrackDetail = () => {
  const { t } = useTranslation('trackDetail');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const intervalId = useRef(null);

  const { currentUser } = useSelector(selectUsers);
  const {
    trackDetails,
    trackDetailLoading,
    appointCurrentPage,
    appointFilters,
    trackCount,
    isLoading,
    progress,
    isChangeStatus,
  } = useSelector(selectTracks);

  const [isUsersModal, setIsUsersModal] = useState(false);
  const [isCalendarModal, setIsCalendarModal] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [currentTab, setCurrentTab] = useState(TAB_KEY_DESCRIPTION);
  const [isModalTrack, setIsModalTrack] = useState(false);
  const [trackIdUrl] = useState(location?.state?.tarckId || +location?.pathname.split('/')[2]);

  const isManager = currentUser && Utils.isManagerRole(currentUser);

  const [notif, contextHolder] = notification.useNotification();

  useEffect(() => {
    dispatch(getTrackDetails(trackIdUrl));
    dispatch(getTrackProgress(trackIdUrl));
    dispatch(postAppointFilters(trackIdUrl, appointFilters?.filters ?? {}, appointCurrentPage));
  }, [dispatch]);

  useEffect(() => {
    if (progress?.length === 0) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [progress]);

  useEffect(() => {
    return () => {
      if (location?.state) {
        delete location.state;
      }

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      // dispatch(clearTrackAppointFilters()); // чистим стор от фильтров
    };
  }, []);

  const fullNameAuthor =
    (trackDetails?.createdBy?.lastName || '') +
    ' ' +
    (trackDetails?.createdBy?.firstName || '') +
    ' ' +
    (trackDetails?.createdBy?.middleName || '');

  const imgEmpty = (
    <div className={css['Track-empty']}>
      <HiOutlineEmojiSad size={168} className={css['Track-empty-sad']} />
      <div className={css['Track-empty-text']}>{t('emptyText')}</div>
    </div>
  );

  const showModalUsers = () => {
    dispatch(getStudents({ size: 10, isUserModal: true, byManager: isManager, trackId: trackDetails?.id }, 0, false));
    setIsUsersModal(true);
  };

  const addMassUsersTrack = userIds => {
    if (userIds) {
      const ids = userIds.map(j => j.id);
      setSelectedUsersIds(ids);
      setIsCalendarModal(true);
    }
  };

  const templateInCallback = () => {
    if (currentTab === TAB_KEY_APPOINT) {
      dispatch(postAppointFilters(trackDetails.id, appointFilters?.filters ?? {}, appointCurrentPage));
    }
  };

  const getTimeForProgress = () => {
    setTimeout(() => {
      dispatch(getTrackProgress(trackIdUrl));
    }, 4000);
  };
  const addMassTrack = arrFilters => {
    // массовое
    if (trackDetails?.id && arrFilters) {
      const paramsStatus = { status: STATUS_IN_PROCESS, type: TYPE_ADD_MASS };

      dispatch(
        changeStatusTrack(trackDetails.id, paramsStatus, res => {
          if (res?.processId) {
            setIsUsersModal(false);
            notif.info({
              message: t('infoTitile'),
              placement: 'bottomRight',
            });

            getTimeForProgress();

            const newArrFilters = { ...arrFilters, trackProcessId: res.processId };

            dispatch(
              trackAddToPlanMass(trackDetails.id, newArrFilters, () => {
                templateInCallback();
              })
            );
          }
        })
      );
    }
  };

  const addToPlanTrack = (planningTopicId, selectedMonths) => {
    // вручную
    if (trackDetails?.id && selectedMonths && selectedUsersIds.length > 0) {
      const paramsStatus = { status: STATUS_IN_PROCESS, type: TYPE_ADD_MANUAL };

      dispatch(
        changeStatusTrack(trackDetails.id, paramsStatus, res => {
          if (res?.processId) {
            setIsUsersModal(false);
            setIsCalendarModal(false);
            notif.success({
              message: t('successTitle'),
              description: t('successDesc'),
              placement: 'bottomRight',
            });

            getTimeForProgress();

            const params = {
              trackId: trackDetails.id,
              startDate: selectedMonths.toString(),
              users: selectedUsersIds,
              trackProcessId: res.processId,
            };

            dispatch(
              trackAddToPlan(params, () => {
                templateInCallback();
              })
            );
          }
        })
      );
    }
  };

  const onChangeTab = key => setCurrentTab(key);

  const editTrack = () => {
    if (trackDetails?.id) {
      setIsModalTrack(true);
    }
  };

  const closePage = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(tracks.path, { replace: true });
    }
  };

  const getCountUsers = filters => {
    if (trackDetails?.id && filters) {
      dispatch(getTrackCountUsers(trackDetails.id, filters));
    }
  };

  const exportSelectedUsers = (filters, funcDowload) => {
    if (trackDetails?.id && filters && funcDowload) {
      dispatch(exportMassUsersTrack(trackDetails.id, filters, blob => funcDowload(blob)));
    }
  };

  const getSavedFilters = () => {
    if (trackDetails?.id) {
      dispatch(getMassFilters(trackDetails.id, 'TRACK'));
    }
  };

  const renderOnUpdateTrack = (
    <div className={css['Track-update']}>
      <div>
        <LoadingOutlined className={css['Track-update-loading']} />
      </div>
      <div className={css['Track-update-description']}>{t('trackDetailIsUpdate', { name: trackDetails?.name })}</div>
    </div>
  );

  const tabsItems = [
    {
      key: TAB_KEY_DESCRIPTION,
      label: t('description'),
      children: <TabDescription />,
    },
    {
      key: TAB_KEY_CONTENT_SETTINGS,
      label: t('contentSettings'),
      children: <TabContentSettings />,
    },
    {
      key: TAB_KEY_APPOINT,
      label: t('appointments'),
      children: <TabAppointments />,
    },
  ];
  return (
    <Layout>
      <Content>
        <div className={css['Track']}>
          {trackDetailLoading && !isModalTrack ? (
            <Spin size='large' spinning={trackDetailLoading && !isModalTrack} className={css['Track-spin']}></Spin>
          ) : (
            <>
              {trackDetails.status === STATUS_ON_UPDATE ? (
                renderOnUpdateTrack
              ) : (
                <>
                  {Object.keys(trackDetails).length > 0 ? (
                    <>
                      <div className={css['Track-actions']}>
                        {currentUser?.authorities.includes(TRACK_UPDATE) && (
                          <Tooltip title={t('edit')}>
                            <Button
                              className={css['DetailsModal-btn-edit']}
                              onClick={editTrack}
                              icon={<EditOutlined />}
                            />
                          </Tooltip>
                        )}

                        <Tooltip title={t('close')}>
                          <Button icon={<CloseOutlined />} onClick={closePage} />
                        </Tooltip>
                      </div>
                      <Header
                        dataQa='adminTrackDetail'
                        cover={trackDetails.coverImage}
                        author={fullNameAuthor}
                        getFile={getFile}
                        name={trackDetails.name}
                        shortDescription={trackDetails.shortDescription}
                        addToPlan={showModalUsers}
                        authorLabel={t('author')}
                        isTrack
                        disabled={trackDetails?.status === STATUS_IN_PROCESS || progress.length > 0}
                        tooltipText={t('blokedAdd')}
                      />

                      <div className={css['Track-details']}>
                        <div className={css['Track-details-tabs']}>
                          <Tabs activeKey={currentTab} onChange={onChangeTab} items={tabsItems} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>{imgEmpty}</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {isUsersModal && (
          <Assignment
            open={isUsersModal}
            onCancel={() => setIsUsersModal(false)}
            onOk={addMassUsersTrack}
            trackId={trackDetails.id}
            title={t('titleModalSingle')}
            textBlock={[t('userInTrack')]}
            mass
            getSavedFilters={getSavedFilters}
            onMassOk={addMassTrack}
            getCountUsers={getCountUsers}
            exportSelectedUsers={exportSelectedUsers}
            countSelectedUsers={trackCount}
            titleModalMass={t('titleModalMass')}
            isLoadingCountSelectedUsers={isLoading}
            titleSelectedUser={t('titleSelectedTrackUser')}
            hideInspectors
          />
        )}

        {isCalendarModal && (
          <CalendarModal
            open={isCalendarModal}
            onOk={addToPlanTrack}
            onCancel={() => setIsCalendarModal(false)}
            okText={t('addToPlanTrack')}
            selectMonthEvents
            loadingStatus={isChangeStatus}
          />
        )}

        {isModalTrack && <ModalTrack open={isModalTrack} onClose={() => setIsModalTrack(false)} isEdit isTrackDetail />}
        {contextHolder}
      </Content>
    </Layout>
  );
};

export default TrackDetail;
