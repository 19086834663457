import { API_URL_V3 } from '@shared/constants/config';
import { DELETE, POST, REST_API } from '@shared/constants/rest-api';

import {
  CREATE_CHAT_MENTOR_GROUP_FAIL,
  CREATE_CHAT_MENTOR_GROUP_START,
  CREATE_CHAT_MENTOR_GROUP_SUCCESS,
  DELETE_CHAT_GROUP_BY_ID_FAIL,
  DELETE_CHAT_GROUP_BY_ID_START,
  DELETE_CHAT_GROUP_BY_ID_SUCCESS,
} from '@/store/chat/constants';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const createChatGroupMentor = (requestId, users) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL_V3}/group/mentor/${requestId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    users,
  },
  withoutRefresh: true,
  types: [CREATE_CHAT_MENTOR_GROUP_START, CREATE_CHAT_MENTOR_GROUP_SUCCESS, CREATE_CHAT_MENTOR_GROUP_FAIL],
});

export const deleteChatGroupByRequestId = requestId => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL_V3}/group/mentor/${requestId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [DELETE_CHAT_GROUP_BY_ID_START, DELETE_CHAT_GROUP_BY_ID_SUCCESS, DELETE_CHAT_GROUP_BY_ID_FAIL],
});
