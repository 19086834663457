// Создаем произвольное поле [POST]
export const CREATE_CFIELD_START = 'CREATE_CFIELD_START';
export const CREATE_CFIELD_SUCCESS = 'CREATE_CFIELD_SUCCESS';
export const CREATE_CFIELD_FAIL = 'CREATE_CFIELD_FAIL';

// Обновяем произвольное поле [PUT]
export const UPDATE_CFIELD_START = 'UPDATE_CFIELD_START';
export const UPDATE_CFIELD_SUCCESS = 'UPDATE_CFIELD_SUCCESS';
export const UPDATE_CFIELD_FAIL = 'UPDATE_CFIELD_FAIL';

// Удаляем произвольное поле [DELETE]
export const DELETE_CFIELD_START = 'DELETE_CFIELD_START';
export const DELETE_CFIELD_SUCCESS = 'DELETE_CFIELD_SUCCESS';
export const DELETE_CFIELD_FAIL = 'DELETE_CFIELD_FAIL';

// Возвращаем список произвольных полей [GET]
export const LIST_CFIELD_START = 'LIST_CFIELD_START';
export const LIST_CFIELD_SUCCESS = 'LIST_CFIELD_SUCCESS';
export const LIST_CFIELD_FAIL = 'LIST_CFIELD_FAIL';

//
export const SWAP_CFIELD_START = 'SWAP_CFIELD_START';
export const SWAP_CFIELD_SUCCESS = 'SWAP_CFIELD_SUCCESS';
export const SWAP_CFIELD_FAIL = 'SWAP_CFIELD_FAIL';
