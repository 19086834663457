import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAppointBadges, getExampleBadgeCSV, importBadgeUsers, postAppointBadge } from '@/store/badge/actions';
import { getFile } from '@/store/files/actions';

import { selectBadge } from '@/store/badge/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar, Button, Col, Divider, Form, notification, Row } from 'antd';
import { STATUS_INACTIVE } from '@/pages/Badge/constants';
import Assignment from '@/components/Assignment';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import RenderTableStatus from '@/components/RenderTableStatus';

import PropTypes from 'prop-types';

import css from '../BadgeDetail.module.scss';

const BadgeInfo = ({ refSingle, refImport }) => {
  const { t } = useTranslation('badgeDetail');
  const dispatch = useDispatch();

  const { badgeId, badgeInfoFilters, badgeInfoSort, badgeAppointCurrentPage } = useSelector(selectBadge);
  const { currentUser } = useSelector(selectUsers);

  const [avatar, setAvatar] = useState(null);
  const [isUsersModal, setIsUsersModal] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [notif, contextHolder] = notification.useNotification();

  useEffect(() => {
    badgeId?.imageUuid
      ? dispatch(
          getFile(badgeId.imageUuid, res => {
            setAvatar(URL.createObjectURL(res));
          })
        )
      : setAvatar(null);
  }, [badgeId.imageUuid, dispatch]);

  const showModalUsers = () => {
    setIsUsersModal(true);
  };

  const addBadgeUser = userIds => {
    if (userIds) {
      const ids = userIds.map(j => j.id);
      const params = {
        rewardId: badgeId?.id,
        userIds: ids,
      };

      dispatch(
        postAppointBadge(params, () => {
          setIsUsersModal(false);
          dispatch(getAppointBadges(badgeId?.id, badgeInfoFilters ?? {}, 0, badgeInfoSort));
          notif.success({
            message: t('notifDesc'),
            placement: 'bottomRight',
            style: { height: '60px', width: '446px' },
          });
        })
      );
    }
  };

  const onImportOk = (file, onSuccess) => {
    if (file && badgeId?.id) {
      dispatch(
        importBadgeUsers(badgeId.id, file, response => {
          onSuccess(response);
          dispatch(getAppointBadges(badgeId.id, badgeInfoFilters ?? {}, badgeAppointCurrentPage, badgeInfoSort));
        })
      );
    }
  };

  const renderTopics = () => {
    if (Array.isArray(badgeId?.topicRewardDto) && badgeId.topicRewardDto?.length > 0) {
      return badgeId.topicRewardDto.map(item => {
        return (
          <div key={item.id}>
            <Link to={`/topic/${item?.id}`} target='_blank'>
              {item?.name}
            </Link>
          </div>
        );
      });
    }
  };

  return (
    <div className={css['BadgeInfo']}>
      <Row className={css['BadgeInfo-top']}>
        <Col span={4}>
          <Avatar size={140} src={<img src={avatar} alt='avatar' />} />
        </Col>
        <Col span={13}>
          <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
            {/* Статус */}
            <Form.Item label={t('statusBadgeId')} labelAlign='left' className={css['BadgeInfo-form']}>
              <RenderTableStatus status={badgeId?.statusDto?.value} text={badgeId?.statusDto?.name} />
            </Form.Item>

            {/* Автор */}
            <Form.Item label={t('authorBadgeId')} labelAlign='left' className={css['BadgeInfo-form']}>
              <a
                rel='noreferrer'
                href={`https://${currentUser?.domainCompany?.domain}/collegues/${badgeId.authorDto?.userId}/info`}
                target='_blank'
              >
                {badgeId.authorDto?.fullName}
              </a>
            </Form.Item>

            {/* Дата создания */}
            <Form.Item label={t('createBadgeId')} labelAlign='left' className={css['BadgeInfo-form']}>
              <span>{badgeId.authorDto?.creDate}</span>
            </Form.Item>

            {/* Изменил */}
            {badgeId?.modifyDto?.fullName && badgeId.modifyDto?.userId && (
              <Form.Item label={t('modifBadgeId')} labelAlign='left' className={css['BadgeInfo-form']}>
                <a
                  rel='noreferrer'
                  href={`https://${currentUser?.domainCompany?.domain}/collegues/${badgeId.modifyDto.userId}/info`}
                  target='_blank'
                >
                  {badgeId.modifyDto.fullName}
                </a>
              </Form.Item>
            )}

            {/* Дата изменения */}
            {badgeId.modifyDto?.modifyDate && (
              <Form.Item label={t('modifDateBadgeId')} labelAlign='left' className={css['BadgeInfo-form']}>
                <span>{badgeId.modifyDto.modifyDate}</span>
              </Form.Item>
            )}
          </Form>
        </Col>
        <Col span={1}></Col>

        {/* Присвоение вручную */}
        <Col span={6}>
          <div className={css['BadgeInfo-top-assign']}>{t('assign')}</div>
          <div className={css['BadgeInfo-top-manual']}>
            <Button
              type='primary'
              className={css['BadgeInfo-top-manual-btn']}
              onClick={showModalUsers}
              ref={refSingle}
              disabled={badgeId?.statusDto?.value === STATUS_INACTIVE}
            >
              {t('individual')}
            </Button>
          </div>
          <div>
            <Button
              type='primary'
              className={css['BadgeInfo-top-manual-btn']}
              onClick={() => setImportVisible(true)}
              ref={refImport}
              disabled={badgeId?.statusDto?.value === STATUS_INACTIVE}
            >
              {t('import')}
            </Button>
          </div>
        </Col>
      </Row>

      <Row className={css['BadgeInfo-block']}>
        <Col span={18}>
          {/* Описание */}
          <div className={css['BadgeInfo-block']}>
            <div className={css['BadgeInfo-block-desc']}>
              <div className={css['BadgeInfo-block-desc-title']}>{t('description')}</div>
              <div className={css['BadgeInfo-block-desc-txt']}>{badgeId?.description}</div>
            </div>

            <div>
              <Divider type='vertical' className={css['BadgeInfo-block-divider']} />
            </div>
          </div>
        </Col>
        <Col span={6}>
          {/* Материал присвоения */}
          <div className={css['BadgeInfo-material']}>{t('material')}</div>
          {renderTopics()}
        </Col>
      </Row>

      {isUsersModal && (
        <Assignment
          open={isUsersModal}
          onCancel={() => setIsUsersModal(false)}
          onOk={addBadgeUser}
          textBlock={[t('userHasBadge')]}
          title={t('titleModalSingle')}
          rewardId={badgeId.id}
        />
      )}

      {importVisible && (
        <DictionaryImportDialog
          fileDescription={t('exampleFile')}
          description1={t('badgeDescription1')}
          onOk={onImportOk}
          onCancel={() => setImportVisible(false)}
          open={importVisible}
          exampleFileFunc={blob => dispatch(getExampleBadgeCSV(blob))}
          isBadge
        />
      )}
      {contextHolder}
    </div>
  );
};

BadgeInfo.propTypes = {
  refSingle: PropTypes.object,
  refImport: PropTypes.object,
};

export default BadgeInfo;
