export const GET_CHANNELS_START = 'GET_CHANNELS_START';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_FAIL = 'GET_CHANNELS_FAIL';

export const GET_TEMPLATES_START = 'GET_TEMPLATES_START';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATES_FAIL';

export const GET_THEMES_START = 'GET_THEMES_START';
export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS';
export const GET_THEMES_FAIL = 'GET_THEMES_FAIL';

export const GET_TEMPLATE_BY_ID_START = 'GET_TEMPLATE_BY_ID_START';
export const GET_TEMPLATE_BY_ID_SUCCESS = 'GET_TEMPLATE_BY_ID_SUCCESS';
export const GET_TEMPLATE_BY_ID_FAIL = 'GET_TEMPLATE_BY_ID_FAIL';

export const CHANGE_TEMPLATE_BY_ID_START = 'CHANGE_TEMPLATE_BY_ID_START';
export const CHANGE_TEMPLATE_BY_ID_SUCCESS = 'CHANGE_TEMPLATE_BY_ID_SUCCESS';
export const CHANGE_TEMPLATE_BY_ID_FAIL = 'CHANGE_TEMPLATE_BY_ID_FAIL';

export const CREATE_TEMPLATE_BY_ID_START = 'CREATE_TEMPLATE_BY_ID_START';
export const CREATE_TEMPLATE_BY_ID_SUCCESS = 'CREATE_TEMPLATE_BY_ID_SUCCESS';
export const CREATE_TEMPLATE_BY_ID_FAIL = 'CREATE_TEMPLATE_BY_ID_FAIL';

export const GET_MESSAGE_GROUP_START = 'GET_MESSAGE_GROUP_START';
export const GET_MESSAGE_GROUP_SUCCESS = 'GET_MESSAGE_GROUP_SUCCESS';
export const GET_MESSAGE_GROUP_FAIL = 'GET_MESSAGE_GROUP_FAIL';
