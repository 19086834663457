/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_COMPETENCE_START = 'GET_COMPETENCE_START';
export const GET_COMPETENCE_SUCCESS = 'GET_COMPETENCE_SUCCESS';
export const GET_COMPETENCE_FAIL = 'GET_COMPETENCE_FAIL';
export const CREATE_COMPETENCE_START = 'CREATE_COMPETENCE_START';
export const CREATE_COMPETENCE_SUCCESS = 'CREATE_COMPETENCE_SUCCESS';
export const CREATE_COMPETENCE_FAIL = 'CREATE_COMPETENCE_FAIL';

export const DELETE_COMPETENCE_START = 'DELETE_COMPETENCE_START';
export const DELETE_COMPETENCE_SUCCESS = 'DELETE_COMPETENCE_SUCCESS';
export const DELETE_COMPETENCE_FAIL = 'DELETE_COMPETENCE_FAIL';
export const EDIT_COMPETENCE_START = 'EDIT_COMPETENCE_START';
export const EDIT_COMPETENCE_SUCCESS = 'EDIT_COMPETENCE_SUCCESS';
export const EDIT_COMPETENCE_FAIL = 'EDIT_COMPETENCE_FAIL';

export const GET_ALL_SKILLS_START = 'GET_ALL_SKILLS_START';
export const GET_ALL_SKILLS_SUCCESS = 'GET_ALL_SKILLS_SUCCESS';
export const GET_ALL_SKILLS_FAIL = 'GET_ALL_SKILLS_FAIL';

export const GET_COMPETENCE_WITH_SKILL_START = 'GET_COMPETENCE_WITH_SKILL_START';
export const GET_COMPETENCE_WITH_SKILL_SUCCESS = 'GET_COMPETENCE_WITH_SKILL_SUCCESS';
export const GET_COMPETENCE_WITH_SKILL_FAIL = 'GET_COMPETENCE_WITH_SKILL_FAIL';

export const CLEAR_ERROR_TEXT = 'CLEAR_ERROR_TEXT';

export const SAVE_COMPETENCE_FILTERS = 'SAVE_COMPETENCE_FILTERS';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getCompetence = (name = '', page = 0, withoutRelatedEntities, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence${getQueryString({
    name: name && name.toLowerCase(),
    sort: 'name,asc',
    page: Number(page),
    withoutRelatedEntities,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_START, GET_COMPETENCE_SUCCESS, GET_COMPETENCE_FAIL],
  onSuccess,
});

export const getCompetenceWithSkills = (page, params) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence/skill${getQueryString({
    page,
    size: 20,
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_WITH_SKILL_START, GET_COMPETENCE_WITH_SKILL_SUCCESS, GET_COMPETENCE_WITH_SKILL_FAIL],
});

export const createCompetence = (name, skillIds, isHardSkill, onSuccess, onError, groupIds) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/competence`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
    skillIds,
    isHardSkill,
    groupIds,
  },
  onSuccess,
  onError,
  types: [CREATE_COMPETENCE_START, CREATE_COMPETENCE_SUCCESS, CREATE_COMPETENCE_FAIL],
});

export const clearErrorText = () => ({
  type: CLEAR_ERROR_TEXT,
});

export const deleteCompetence = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/competence/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_COMPETENCE_START, DELETE_COMPETENCE_SUCCESS, DELETE_COMPETENCE_FAIL],
});

export const editCompetence = (id, name, skillIds, isHardSkill, onSuccess, groupIds) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/competence/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id,
    name,
    skillIds,
    isHardSkill,
    groupIds,
  },
  onSuccess,
  types: [EDIT_COMPETENCE_START, EDIT_COMPETENCE_SUCCESS, EDIT_COMPETENCE_FAIL],
});

// Запрос для отображения всех доступных skill'ов для компитенции

export const getAllAvailableSkills = (competenceId, name, withoutTopics, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence/skills-search${getQueryString({ competenceId, name, withoutTopics })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  types: [GET_ALL_SKILLS_START, GET_ALL_SKILLS_SUCCESS, GET_ALL_SKILLS_FAIL],
});

export const saveCompetenceFilters = value => ({
  type: SAVE_COMPETENCE_FILTERS,
  value,
});
