/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createSettingsTQ, getSettingsTQ, updateSettingsTQ } from '@/store/talentq/actions';

import { selectSettingsTQ } from '@/store/talentq/selectors';

import { Button, Form, Modal, notification } from 'antd';
import CustomInput from '@/components/Input';

import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './CompanyCreationDialog.module.scss';

const SettingsTalentq = props => {
  const { t, open, modalTalentqCancel } = props;
  const SUCCESS_NOTIFICATION = 'success';
  const WARNING_NOTIFICATION = 'fail';
  const SUCCESS_NOTIFICATION_UPDATE = 'successUpdate';
  const WARNING_NOTIFICATION_UPDATE = 'failUpdate';

  const [data, setData] = useState({
    url: null,
    login: null,
    password: null,
    project_id: null,
    company_id: props.company?.id || null,
  });

  useEffect(() => {
    if (open === true) {
      props.getSettingsTQ(props.company.id, response => {
        setData({
          url: response.url,
          login: response.login,
          password: response.password,
          project_id: response.project_id,
          company_id: response.company_id,
        });
      });
    }

    if (_.has(data, 'company_id') && !data.company_id) {
      data.company_id = props.company.id;
    }
  }, [props.company.id, open]);

  const onShowNotification = result => {
    switch (result) {
      case SUCCESS_NOTIFICATION:
        notification.success({
          message: t('msgNotificationSuccess'),
        });
        break;
      case SUCCESS_NOTIFICATION_UPDATE:
        notification.success({
          message: t('msgNotificationUpdate'),
        });
        break;
      case WARNING_NOTIFICATION:
        notification.warning({
          message: t('msgNotificationFail'),
        });
        break;
      case WARNING_NOTIFICATION_UPDATE:
        notification.warning({
          message: t('msgNotificationFailUpdate'),
        });
        break;
    }
  };

  const onSubmit = () => {
    if (Object.keys(props.settingsTQget).length === 0) {
      props.createSettingsTQ(
        data,
        () => {
          modalTalentqCancel();
          onShowNotification(SUCCESS_NOTIFICATION);
        },
        () => {
          onShowNotification(WARNING_NOTIFICATION);
        }
      );
    } else {
      props.updateSettingsTQ(
        data,
        () => {
          modalTalentqCancel();
          onShowNotification(SUCCESS_NOTIFICATION_UPDATE);
        },
        () => {
          onShowNotification(WARNING_NOTIFICATION_UPDATE);
        }
      );
    }
  };

  const changeData = event => {
    const { name, value } = event.target || event;
    setData({ ...data, [name]: value });
  };

  const footerButton = () => {
    return (
      <>
        <Button key='back' onClick={modalTalentqCancel}>
          {t('cancel')}
        </Button>
        {props.getSettingsTQ && props.getSettingsTQ !== null && (
          <Button
            key='submit'
            type='primary'
            onClick={onSubmit}
            disabled={!data.url || !data.login || !data.password || !data.project_id}
          >
            {Object.keys(props.settingsTQget).length ? t('update') : t('send')}
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal title={t('settingsTalentq')} open={open} width={800} onCancel={modalTalentqCancel} footer={footerButton()}>
      <Form className={css['Add-modal-form']} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item label={t('urlServerTalentq')}>
          <CustomInput name='url' onChange={changeData} value={data.url} />
        </Form.Item>
        <Form.Item label={t('nameUser')}>
          <CustomInput name='login' onChange={changeData} value={data.login} />
        </Form.Item>
        <Form.Item label={t('namePassword')}>
          <CustomInput type='password' name='password' onChange={changeData} value={data.password} />
        </Form.Item>
        <Form.Item label={t('projectID')}>
          <CustomInput type='number' name='project_id' onChange={changeData} value={data.project_id} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

SettingsTalentq.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
  company: PropTypes.object,
  open: PropTypes.bool,
  modalTalentqCancel: PropTypes.func,
  createSettingsTQ: PropTypes.func,
  getSettingsTQ: PropTypes.func,
  updateSettingsTQ: PropTypes.func,
};

const mapStateToProps = createSelector([selectSettingsTQ], selectSettingsTQ => ({
  settingsTQ: selectSettingsTQ,
  settingsTQget: selectSettingsTQ.settingsTQget,
}));

const mapActionsToProps = {
  createSettingsTQ,
  getSettingsTQ,
  updateSettingsTQ,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('createCompany')(SettingsTalentq));
