import {
  CHANGE_ORDER_FAIL,
  CHANGE_ORDER_START,
  CHANGE_ORDER_SUCCESS,
  CLEAR_ORDER_FILTERS,
  EXPORT_ORDERS_FAIL,
  EXPORT_ORDERS_START,
  EXPORT_ORDERS_SUCCESS,
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_START,
  GET_ORDER_HISTORY_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  POST_ORDERS_FILTERS_FAIL,
  POST_ORDERS_FILTERS_START,
  POST_ORDERS_FILTERS_SUCCESS,
  SAVE_ORDER_FILTERS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const postOrdersFilters = (params, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/admin/order${getQueryString({ size: 20, page, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_ORDERS_FILTERS_START, POST_ORDERS_FILTERS_SUCCESS, POST_ORDERS_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const getOrdersStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product/admin/order/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATUS_START, GET_STATUS_SUCCESS, GET_STATUS_FAIL],
  onSuccess,
  onError,
});

export const getOrdersHistory = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product/admin/order/${id}/statuses`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ORDER_HISTORY_START, GET_ORDER_HISTORY_SUCCESS, GET_ORDER_HISTORY_FAIL],
  onSuccess,
  onError,
});

export const changeOrderStatus = (params, page, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/admin/order/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CHANGE_ORDER_START, CHANGE_ORDER_SUCCESS, CHANGE_ORDER_FAIL],
  onSuccess,
  onError,
});

export const exportOrders = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/order/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_ORDERS_START, EXPORT_ORDERS_SUCCESS, EXPORT_ORDERS_FAIL],
  onSuccess,
  onError,
});

export const saveOrderFilters = value => ({
  type: SAVE_ORDER_FILTERS,
  value,
});

export const clearOrderFilters = value => ({
  type: CLEAR_ORDER_FILTERS,
  value,
});
