/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { Col, DatePicker, Form, Input, InputNumber, Row, Switch, TimePicker, Tooltip } from 'antd';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  FIELD_BOOLEAN,
  FIELD_DATE,
  FIELD_DATETIME,
  FIELD_NUMERIC,
  FIELD_STRING,
  FIELD_TEXT,
  FIELD_TIME,
  SHORT_TIME_FORMAT,
} from '../../VarDirectory/Tabs/Users/Panels/CreateField';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const { TextArea } = Input;

export const CustomFieldItem = props => {
  const [fieldId, setFieldId] = useState(null);
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [description, setDescription] = useState(null);
  const [checkTooltip, setCheckTooltip] = useState(false);
  const [value, setValue] = useState(null);
  const [valueId, setValueId] = useState(null);

  useEffect(() => {
    const { fieldInfo, fieldValue } = props.data;

    if (fieldInfo.hasOwnProperty('id')) {
      setFieldId(fieldInfo.id);
    }

    if (fieldInfo.hasOwnProperty('name')) {
      if (fieldInfo.name.length > 14) {
        setName(`${fieldInfo.name.substr(0, 13)}...`);
        setCheckTooltip(true);
      } else {
        setName(fieldInfo.name);
      }
    }

    if (fieldInfo.hasOwnProperty('description')) {
      const isLongName = fieldInfo.name;
      if (isLongName.length < 18) {
        setDescription(fieldInfo.description);
      } else {
        setDescription(fieldInfo.name);
      }
    }

    if (
      fieldInfo.hasOwnProperty('fieldType') &&
      fieldInfo.fieldType.hasOwnProperty('id') &&
      fieldInfo.fieldType.hasOwnProperty('type')
    ) {
      onChangeType(fieldInfo.fieldType.id);
    }

    if (
      fieldValue &&
      fieldValue.hasOwnProperty('value') &&
      fieldValue.hasOwnProperty('fieldId') &&
      !Number.isNaN(Number.parseInt(fieldValue.fieldId))
    ) {
      setValueId(fieldValue.fieldId);
      setValue(fieldValue.value);
    } else {
      if (fieldInfo.hasOwnProperty('defaultValue')) {
        setValue(fieldInfo.defaultValue);
      }
    }
  }, []);

  const handleChangeValue = value => {
    setValue(value);
    // props.onChange(valueId, fieldId, value);
    props.onChange(value, fieldId);
  };

  /**
   * Установка типа поля.
   *
   * @param eType int
   */
  const onChangeType = eType => {
    if (eType >= FIELD_STRING && eType <= FIELD_BOOLEAN) {
      setType(eType);
    }
  };

  /**
   * Установка значения поля.
   *
   * @param event Значение поля
   * @param eType Тип поля
   */
  const onChangeValue = (event, eType) => {
    switch (eType) {
      case FIELD_STRING:
        handleChangeValue(event.target.value);
        break;

      case FIELD_TEXT:
        handleChangeValue(event.target.value);
        break;

      case FIELD_NUMERIC:
        handleChangeValue(event);
        break;

      case FIELD_DATE:
        event !== null ? handleChangeValue(event.format(DEFAULT_DATE_FORMAT)) : setValue(null);
        break;

      case FIELD_TIME:
        event !== null ? handleChangeValue(event.format(DEFAULT_TIME_FORMAT)) : setValue(null);
        break;

      case FIELD_DATETIME:
        event !== null ? handleChangeValue(event.format(DEFAULT_DATETIME_FORMAT)) : setValue(null);

        break;

      case FIELD_BOOLEAN:
        handleChangeValue(event);
        break;
    }
  };

  /**
   * Рендеринг полей.
   *
   * @returns {*}
   */
  const renderElement = () => {
    const { placeholderValue } = props.data.fieldInfo;
    const { t } = props;
    let validValue = null;

    switch (type) {
      // Строковое поле
      case FIELD_STRING:
        return (
          <Input
            placeholder={placeholderValue || t('enterValuePlaceholder')}
            maxLength={255}
            allowClear={true}
            onChange={event => onChangeValue(event, type)}
            value={value || ''}
          />
        );

      // Текстовое поле
      case FIELD_TEXT:
        return (
          <TextArea
            placeholder={placeholderValue || t('enterValuePlaceholder')}
            rows={4}
            maxLength={1024}
            onChange={event => onChangeValue(event, type)}
            value={value || ''}
          />
        );

      // Цифровое поле
      case FIELD_NUMERIC:
        return (
          <InputNumber
            placeholder={placeholderValue || t('enterValuePlaceholder')}
            defaultValue={0}
            min={0}
            style={{ width: '100%' }}
            value={value || ''}
            onChange={event => onChangeValue(event, type)}
          />
        );

      // Дата
      case FIELD_DATE:
        dayjs(value, DEFAULT_DATE_FORMAT).isValid() ? dayjs(value, DEFAULT_DATE_FORMAT) : dayjs();

        return (
          <DatePicker
            placeholder={placeholderValue || t('chooseDatePlaceholder')}
            style={{ width: '100%' }}
            showNow={true}
            showToday={true}
            showTime={false}
            onChange={event => onChangeValue(event, type)}
            format={DEFAULT_DATE_FORMAT}
            defaultValue={dayjs(value).isValid() ? dayjs(value) : null}
            displayValue={dayjs(value)}
          />
        );

      // Время
      case FIELD_TIME:
        dayjs(value, DEFAULT_TIME_FORMAT, true) ? dayjs(value, DEFAULT_TIME_FORMAT) : dayjs();
        const defValue = dayjs(value, DEFAULT_TIME_FORMAT).isValid()
          ? dayjs(value, DEFAULT_TIME_FORMAT)
          : dayjs(value, SHORT_TIME_FORMAT).isValid()
            ? dayjs(value, SHORT_TIME_FORMAT)
            : null;

        return (
          <TimePicker
            placeholder={placeholderValue || t('chooseTimePlaceholder')}
            style={{ width: '100%' }}
            onChange={event => onChangeValue(event, type)}
            defaultValue={defValue}
          />
        );

      // Дата и время
      case FIELD_DATETIME: {
        const defaultValue = dayjs(value).isValid() ? dayjs(value) : null;

        return (
          <DatePicker
            placeholder={placeholderValue || t('chooseDateAndTimePlaceholder')}
            showTime={true}
            showNow={true}
            style={{ width: '100%' }}
            onChange={event => onChangeValue(event, type)}
            value={defaultValue}
          />
        );
      }

      // Чекбокс
      case FIELD_BOOLEAN:
        value === 'true' || value === true ? (validValue = true) : (validValue = false);

        return <Switch onChange={event => onChangeValue(event, type)} checked={validValue} />;
    }
  };

  const render = () => {
    return (
      <Form.Item
        label={
          <Tooltip title={checkTooltip && props.data?.fieldInfo?.name} trigger='hover'>
            {name}
          </Tooltip>
        }
        labelAlign='left'
      >
        {renderElement()}
      </Form.Item>
    );
  };

  return render();
};

CustomFieldItem.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTranslation('editUserDialog')(CustomFieldItem);
