import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getFile } from '@/store/files/actions';

import { Col, Row, Tooltip } from 'antd';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TrackTopics.module.scss';

const TrackTopics = ({ deleteTopic, topicData, trackIndex, isModalTrack, isProcess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('tracks');

  const [avatar, setStateAvatar] = useState(null);
  const [defaultImg, setDefaultImg] = useState(null);

  const loadAvatar = useCallback(
    thumbFileUuid => {
      if (thumbFileUuid) {
        dispatch(
          getFile(thumbFileUuid, blob => {
            const imageUrl = URL.createObjectURL(blob);
            setStateAvatar(imageUrl);
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (topicData.thumbFileUuid) {
      loadAvatar(topicData.thumbFileUuid);
    }
  }, [topicData.thumbFileUuid, loadAvatar]);

  useEffect(() => {
    if (topicData?.type) {
      setDefaultImg(TOPIC_IMAGE.find(type => type.id === topicData.type.id)?.image);
    }
  }, [topicData.type]);

  const goToTopic = () => navigate(`/topic/${topicData.id}`);

  return (
    <Row type='flex' align='middle' justify={isModalTrack ? 'space-between' : 'start'} className={css['TrackTopic']}>
      {isModalTrack && (
        <Col span={3}>
          <div className={css['TrackTopic-drags']}>
            <EllipsisOutlined />
            <EllipsisOutlined />
          </div>
        </Col>
      )}
      <Col span={2}>{`№ ${trackIndex + 1}`}</Col>
      <Col span={isModalTrack ? 3 : 2} className={css['TrackTopic-image']}>
        {avatar ? <img src={avatar} alt='topicImage' /> : <img src={defaultImg} alt='img' />}
      </Col>
      <Tooltip title={topicData?.name?.length > 30 && topicData.name}>
        <Col span={isModalTrack ? 13 : 18} className={css['TrackTopic-text']}>
          <span onClick={goToTopic}>{topicData.name}</span>
        </Col>
      </Tooltip>
      {isModalTrack && (
        <Col span={2}>
          <Tooltip title={isProcess ? t('inProcessTrack') : t('deleteTopic')}>
            <DeleteOutlined
              className={classNames(
                [css['TrackTopic-actions-delete']],
                isProcess && [css['TrackTopic-actions-delete-cant']]
              )}
              onClick={() => !isProcess && deleteTopic(topicData.id)}
            />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

TrackTopics.propTypes = {
  topicData: PropTypes.object,
  deleteTopic: PropTypes.func,
  trackIndex: PropTypes.number,
  isModalTrack: PropTypes.bool,
  isProcess: PropTypes.bool,
};

export default TrackTopics;
