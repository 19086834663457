const AudioSvg = props => (
  <div>
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='12' viewBox='0 0 9 12'>
      <path d='M1.154 8.527h3.267v-5.12H1.154zM8.171.93l-3.75 2.476v5.12l3.75 2.386z' stroke='#2D3540' fill='none' />
    </svg>
    {props.name}
  </div>
);

export default AudioSvg;
