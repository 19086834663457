/* eslint-disable */
import {
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SHORT_FAIL,
  GET_COMPANIES_SHORT_START,
  GET_COMPANIES_SHORT_SUCCESS,
  GET_COMPANIES_START,
  GET_COMPANIES_SUCCESS,
  GET_SUBCOMPANIES_FAIL,
  GET_SUBCOMPANIES_START,
  GET_SUBCOMPANIES_SUCCESS,
} from './actions';

const initialState = {
  isLoading: false,
  error: false,
  companies: [],
  companiesShort: [],
  subCompanies: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_COMPANIES_START:
    case GET_COMPANIES_SHORT_START:
    case GET_SUBCOMPANIES_START:
      return { ...state, isLoading: true, error: false };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: response,
        isLoading: false,
        error: false,
      };

    case GET_COMPANIES_SHORT_SUCCESS:
      return {
        ...state,
        companiesShort: response,
        isLoading: false,
        error: false,
      };

    case GET_SUBCOMPANIES_SUCCESS:
      return {
        ...state,
        subCompanies: response,
        isLoading: false,
        error: false,
      };
    case GET_COMPANIES_FAIL:
    case GET_COMPANIES_SHORT_FAIL:
    case GET_SUBCOMPANIES_FAIL:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};
