/* eslint-disable */
import {
  D360_CREATE_MEASURABLE_FAIL,
  D360_CREATE_MEASURABLE_START,
  D360_CREATE_MEASURABLE_SUCCESS,
  D360_DELETE_MEASURABLE_FAIL,
  D360_DELETE_MEASURABLE_START,
  D360_DELETE_MEASURABLE_SUCCESS,
  D360_GET_MEASURABLES_FAIL,
  D360_GET_MEASURABLES_START,
  D360_GET_MEASURABLES_SUCCESS,
  D360_PUBLISH_REPORT_FAIL,
  D360_PUBLISH_REPORT_START,
  D360_PUBLISH_REPORT_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  measurables: [],
  pageCurrent: 1,
  pagePer: 10,
  pageTotal: 0,
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    case D360_PUBLISH_REPORT_START:
    case D360_CREATE_MEASURABLE_START:
    case D360_DELETE_MEASURABLE_START:
    case D360_GET_MEASURABLES_START:
      return { ...state, loading: true };

    case D360_PUBLISH_REPORT_FAIL:
    case D360_CREATE_MEASURABLE_FAIL:
    case D360_DELETE_MEASURABLE_FAIL:
    case D360_GET_MEASURABLES_FAIL:
      return { ...state, loading: false };

    case D360_CREATE_MEASURABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        measurables: response.data.data,
        pageCurrent: response.data.current_page,
        pagePer: response.data.per_page,
        pageTotal: response.data.total,
      };

    case D360_DELETE_MEASURABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        measurables: response.data.data,
        pageCurrent: response.data.current_page,
        pagePer: response.data.per_page,
        pageTotal: response.data.total,
      };

    case D360_PUBLISH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        measurables: response.data.data,
      };

    case D360_GET_MEASURABLES_SUCCESS:
      return {
        ...state,
        loading: false,
        measurables: response.data.data,
        pageCurrent: response.data.current_page,
        pagePer: response.data.per_page,
        pageTotal: response.data.total,
      };

    default:
      return state;
  }
};
