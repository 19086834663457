import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Form, Input, Modal } from 'antd';

import { changePassword } from '../../../store/students/actions';

import PropTypes from 'prop-types';

import css from './ChangePassword.module.scss';

export const ChangePassword = props => {
  const [password, setPassword] = useState('');
  const [isMaskError, changeIsMaskError] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation('more');
  const dispatch = useDispatch();

  const setNewPassword = () => {
    if (password.length >= 6 && password.length <= 25) {
      dispatch(changePassword(props.user.id, password));
      props.changePasswordModalVisibility();
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (password) {
      if (/^[a-zA-Z0-9!@#$%^&*]/.test(password)) {
        changeIsMaskError(false);
      } else {
        changeIsMaskError(true);
      }
    }
  }, [password]);

  const render = () => {
    return (
      <Modal footer={null} width='40%' open={props.open} centered onCancel={props.changePasswordModalVisibility}>
        <Form className={css['change-password-modal']}>
          <h2>{t('setNewPassword')}</h2>
          <Form.Item className={css['change-password-modal--item']}>
            <Input
              className={`${error || isMaskError ? css['change-password-modal__input-error'] : ''} ${
                css['change-password-modal__input']
              }`}
              placeholder={t('password')}
              onChange={event => setPassword(event.target.value)}
              value={password}
              type='password'
              autoComplete='password'
            />
            <p
              style={error || isMaskError ? { display: 'block' } : { display: 'none' }}
              className={css['change-password-modal__error']}
            >
              {isMaskError ? t('passwordMask') : t('passwordLength')}
            </p>
          </Form.Item>
          <Button
            type='primary'
            onClick={setNewPassword}
            className={`${css['change-password-modal__btn']} ${isMaskError && css['error']}`}
          >
            {t('editPassword')}
          </Button>
        </Form>
      </Modal>
    );
  };

  return render();
};

ChangePassword.propTypes = {
  user: PropTypes.object,
  changePasswordModalVisibility: PropTypes.func,
  open: PropTypes.bool,
};

export default ChangePassword;
