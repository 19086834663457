/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectUsers } from '@/store/users/selectors';

import { AreaChartOutlined, BarChartOutlined } from '@ant-design/icons';

import AudioSvg from './svg/Audio';
import BookSvg from './svg/Book';
import ConferenceSvg from './svg/Conference';
import CorpCourseSvg from './svg/CorpCourse';
import CourseSvg from './svg/Course';
import EventSvg from './svg/Event';
import ExternalSvg from './svg/External';
import GameSvg from './svg/Game';
import ImageSvg from './svg/Image';
import TextSvg from './svg/Text';
import VideoSvg from './svg/Video';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TypeBadge.module.scss';

export class TypeBadge extends Component {
  static propTypes = {
    className: PropTypes.string,
    domainCompany: PropTypes.object,
    name: PropTypes.string,
    portraitPlaceholder: PropTypes.bool,
    small: PropTypes.bool,
    type: PropTypes.string,
  };

  checkStringLength = string => {
    if (string?.length > 17) {
      return '';
    }
    return string;
  };

  getBadgeContent = type => {
    const { corpCourseIcon, t } = this.props;

    switch (type) {
      case 'audio':
        return <AudioSvg name={this.props.name || t('audio')} />;

      case 'book':
        return <BookSvg name={this.props.name || t('book')} />;

      case 'pokaSkills':
        return (
          <div>
            <AreaChartOutlined style={{ fontSize: '12px' }} />
            {this.checkStringLength(this.props.name) || t('skill')}
          </div>
        );

      case 'executive':
        return (
          <div>
            <AreaChartOutlined style={{ fontSize: '12px' }} />
            {this.checkStringLength(this.props.name) || t('executive')}
          </div>
        );
      case 'course':
        return <CourseSvg name={this.props.name || t('open')} />;

      case 'external':
        return <ExternalSvg name={this.props.name || t('course')} />;

      case 'cost':
        if (corpCourseIcon) {
          return (
            <div>
              <img src={corpCourseIcon} />
              {this.props.name || t('corpCourse')}
            </div>
          );
        }
        return <CorpCourseSvg name={this.props.name || t('corpCourse')} />;

      case 'event':
        return <EventSvg name={this.props.name || t('event')} />;

      case 'game':
        return <GameSvg name={this.props.name || t('game')} />;

      case 'img':
        return <ImageSvg name={this.props.name || t('image')} />;

      case 'presa':
        return <PresentationSvg name={this.props.name || t('presentation')} />;

      case 'text':
        return <TextSvg name={this.props.name || t('text')} />;

      case 'video':
        return <VideoSvg name={this.props.name || t('video')} />;

      case 'conference':
        return <ConferenceSvg name={this.props.name || t('conference')} />;

      default:
        break;
    }
  };

  render() {
    const { type, className, small, portraitPlaceholder } = this.props;
    return (
      <div
        className={classNames(
          className,
          css.TypeBadge,
          { [css.small]: small },
          { [css.portraitPlaceholder]: portraitPlaceholder }
        )}
      >
        {this.getBadgeContent(type) || null}
      </div>
    );
  }
}

const mapStateToProps = createSelector(selectUsers, users => ({
  corpCourseIcon: users.corpCourseIcon,
}));

export default connect(mapStateToProps)(withTranslation('typeBadge')(TypeBadge));
