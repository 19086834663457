/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getAllCustomFields, getProfileCustomFieldList } from '@/store/pcfields/actions';
import { getProfileCustomFieldValue } from '@/store/pcfieldvalue/actions';

import CustomFieldItem from './Item';

import PropTypes from 'prop-types';

const CustomFieldList = props => {
  const [data, setData] = useState([]);
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    setData([]);
    props.getAllCustomFields(props.student.id, fields => {
      props.getProfileCustomFieldList(false, '', fieldsInfo => {
        if (!Array.isArray(fieldsInfo)) return;

        fieldsInfo.forEach(fieldInfo => {
          if (fieldInfo && fieldInfo.hasOwnProperty('id') && !fieldInfo.deleted) {
            const fieldValue = fields.find(field => field.fieldId === fieldInfo.id);

            if (fieldValue && fieldValue.hasOwnProperty('value')) {
              setData(currData => [
                ...currData,
                { key: fieldInfo.position, fieldInfo: fieldInfo, fieldValue: fieldValue },
              ]);
            } else {
              setData(currData => [...currData, { key: fieldInfo.position, fieldInfo: fieldInfo, fieldValue: null }]);
            }
          }
        });
      });
    });
  }, []);

  const onFieldChange = (value, fieldId) => {
    let found = false;
    let buffer = changes;
    buffer.forEach(field => {
      if (field.fieldId === fieldId) {
        field.student = props.student.id;
        field.value = value;
        field.fieldId = fieldId;
        found = true;
      }
    });

    if (!found) {
      buffer.push({ value: value, fieldId: fieldId });
    }

    // Сохраняем изменения
    setChanges(buffer);

    // Поскольку useState операция асинхронная
    // передаем сразу готовый результат в функцию
    props.onChange(buffer);
  };

  const render = () => {
    data.sort((a, b) => a.key - b.key);
    return data.map(field => {
      return <CustomFieldItem key={field.key} onChange={onFieldChange} data={field} />;
    });
  };

  return render();
};

CustomFieldList.propTypes = {
  getProfileCustomFieldList: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  getAllCustomFields: PropTypes.func,
};

const mapActionsToProps = {
  getProfileCustomFieldList,
  getProfileCustomFieldValue,
  getAllCustomFields,
};

export default connect(null, mapActionsToProps)(CustomFieldList);
