const DEFAULT_MAX_HEIGHT = null;

export const toggleCollapsible = (content, arrow, resize = false) => {
  if (!content || !arrow) return;

  if (content.style.maxHeight && !resize) {
    content.style.maxHeight = DEFAULT_MAX_HEIGHT;
    arrow.style.transform = 'rotateZ(0deg)';
  } else {
    requestAnimationFrame(() => {
      content.style.maxHeight = content.scrollHeight + 'px';
      arrow.style.transform = 'rotateZ(180deg)';
    });
  }
};
