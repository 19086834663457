// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-Filter-style-module__ql-picker-WClAo2.admin-src-components-Filter-style-module__ql-font-vIdOrJ .admin-src-components-Filter-style-module__ql-picker-label-HFSGNh[data-value="Montserrat"]:before, .admin-src-components-Filter-style-module__ql-picker-WClAo2.admin-src-components-Filter-style-module__ql-font-vIdOrJ .admin-src-components-Filter-style-module__ql-picker-item-lE3HgW[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-Filter-style-module__ql-font-Montserrat-cK8B_w {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-Filter-style-module__filter-Naetar {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 24px;
}

.admin-src-components-Filter-style-module__filter__header-mr0why {
  gap: 10px;
  display: flex;
}

.admin-src-components-Filter-style-module__filter__caption-Rwyvgj {
  font-size: 28px !important;
  font-weight: 500 !important;
}

.admin-src-components-Filter-style-module__filter__content-zxSLuY, .admin-src-components-Filter-style-module__filter__caption-Rwyvgj {
  margin-bottom: 20px;
}

.admin-src-components-Filter-style-module__filter__footer-b1uy_s {
  justify-content: flex-end;
  gap: 20px;
  display: flex;
}

.admin-src-components-Filter-style-module__inputs__elements-JhW_R3 {
  width: 100%;
}

.admin-src-components-Filter-style-module__inputs__label-o5gCF8 {
  margin-left: 10px;
}

.admin-src-components-Filter-style-module__inputs__label_button-XXa9Bj {
  height: 22px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/Filter/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf;;AAMA;EAHE,SAAS;EACT,aAAa;AACf;;AAKA;EAFE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAIA;EADE,mBAAmB;AACrB;;AAGA;EAAE,yBAAyB;EACzB,SAAS;EACT,aAAa;AACf;;AAGA;EAAE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AACA;EAEE,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.filter {\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 24px;\n  margin-bottom: 20px;\n}\n.filter__header {\n  display: flex;\n  gap: 10px;\n}\n.filter__caption {\n  font-size: 28px !important;\n  font-weight: 500 !important;\n}\n.filter__content, .filter__caption {\n  margin-bottom: 20px;\n}\n.filter__footer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 20px;\n}\n\n.inputs__elements {\n  width: 100%;\n}\n.inputs__label {\n  margin-left: 10px;\n}\n.inputs__label_button {\n  height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-Filter-style-module__ql-picker-WClAo2`,
	"qlPicker": `admin-src-components-Filter-style-module__ql-picker-WClAo2`,
	"ql-font": `admin-src-components-Filter-style-module__ql-font-vIdOrJ`,
	"qlFont": `admin-src-components-Filter-style-module__ql-font-vIdOrJ`,
	"ql-picker-label": `admin-src-components-Filter-style-module__ql-picker-label-HFSGNh`,
	"qlPickerLabel": `admin-src-components-Filter-style-module__ql-picker-label-HFSGNh`,
	"ql-picker-item": `admin-src-components-Filter-style-module__ql-picker-item-lE3HgW`,
	"qlPickerItem": `admin-src-components-Filter-style-module__ql-picker-item-lE3HgW`,
	"ql-font-Montserrat": `admin-src-components-Filter-style-module__ql-font-Montserrat-cK8B_w`,
	"qlFontMontserrat": `admin-src-components-Filter-style-module__ql-font-Montserrat-cK8B_w`,
	"filter": `admin-src-components-Filter-style-module__filter-Naetar`,
	"filter__header": `admin-src-components-Filter-style-module__filter__header-mr0why`,
	"filterHeader": `admin-src-components-Filter-style-module__filter__header-mr0why`,
	"filter__caption": `admin-src-components-Filter-style-module__filter__caption-Rwyvgj`,
	"filterCaption": `admin-src-components-Filter-style-module__filter__caption-Rwyvgj`,
	"filter__content": `admin-src-components-Filter-style-module__filter__content-zxSLuY`,
	"filterContent": `admin-src-components-Filter-style-module__filter__content-zxSLuY`,
	"filter__footer": `admin-src-components-Filter-style-module__filter__footer-b1uy_s`,
	"filterFooter": `admin-src-components-Filter-style-module__filter__footer-b1uy_s`,
	"inputs__elements": `admin-src-components-Filter-style-module__inputs__elements-JhW_R3`,
	"inputsElements": `admin-src-components-Filter-style-module__inputs__elements-JhW_R3`,
	"inputs__label": `admin-src-components-Filter-style-module__inputs__label-o5gCF8`,
	"inputsLabel": `admin-src-components-Filter-style-module__inputs__label-o5gCF8`,
	"inputs__label_button": `admin-src-components-Filter-style-module__inputs__label_button-XXa9Bj`,
	"inputsLabelButton": `admin-src-components-Filter-style-module__inputs__label_button-XXa9Bj`
};
export default ___CSS_LOADER_EXPORT___;
