import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeMentorsStatus, getMenteeForMentor, postMentorsFilters } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Dropdown, message, Table, Tooltip } from 'antd';
import Profile from '@/pages/Users/Profile';
import { DownOutlined } from '@ant-design/icons';
import { HiOutlineBadgeCheck, HiOutlineClipboardCheck, HiOutlineClock } from 'react-icons/hi';

import {
  ACCEPT,
  DESC_ACTIVATED,
  DESC_APPLY,
  EDIT_MENTOR,
  INACTIVE,
  REJECT,
  REJECTED_REASON,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_PENDING,
  STATUS_REJECTED,
} from './constance';
import ModalActions from './Modal/ModalActions';
import ModalMenti from './Modal/ModalMenti';

import css from '../Mentors.module.scss';

import { MENTOR_UPDATE_BY_ADMIN } from '@/constants/permissions';

const TableMentors = () => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();

  const {
    mentorsTable,
    mentorsLoading,
    mentorsFilters,
    mentorsTotal,
    mentorsPageSize,
    mentorsPageNumber,
    mentorsCurrentPage,
    allStat,
  } = useSelector(selectMentors);

  const [isProfile, setIsProfile] = useState(false);
  const [student, setStudent] = useState({});
  const [isMenti, setIsMenti] = useState(false);
  const [isAction, setIsAction] = useState({
    open: false,
    action: '',
    obj: {},
    descAction: '',
  });
  const [isError, setIsError] = useState(false);

  const { currentUser } = useSelector(selectUsers);

  const openProfile = value => {
    setIsProfile(true);
    setStudent(value);
  };

  const showMenti = value => {
    if (value?.mentorId) {
      dispatch(getMenteeForMentor(value.mentorId));
    }
    setIsMenti(true);
  };

  const showActionsModal = (obj, action, descAction) => setIsAction({ open: true, action, obj, descAction });

  const onChangeStatus = comment => {
    if (isAction?.obj?.mentorId && isAction?.action) {
      const params = {
        id: isAction.obj.mentorId,
        status: isAction.action,
      };
      if (comment) params.rejectReason = comment;

      if (!comment && isAction.action === REJECT) {
        setIsError(true);
      } else {
        setIsError(false);
        dispatch(
          changeMentorsStatus(
            params,
            () => {
              dispatch(postMentorsFilters(mentorsFilters.filters ? mentorsFilters.filters : {}, mentorsCurrentPage));
            },
            err => {
              if (err?.message) message.warning(err.message);
            }
          )
        );
        setIsAction({ ...isAction, open: false });
      }
    }
  };

  const onCloseModalActions = () => {
    setIsAction({ ...isAction, open: false });
    setIsError(false);
  };

  const tableAction = item => {
    const inWaiting = item.status === STATUS_PENDING;
    if (inWaiting) {
      return [
        {
          key: '1',
          label: t('btnApply'),
          onClick: () => showActionsModal(item, ACCEPT, DESC_APPLY),
        },
        {
          key: '2',
          label: t('btnReject'),
          onClick: () => showActionsModal(item, REJECT),
        },
        {
          key: '3',
          label: t('editMentor'),
          onClick: () => showActionsModal(item, EDIT_MENTOR),
        },
      ];
    }

    if (item.status === STATUS_ACTIVE) {
      return [
        {
          key: '1',
          label: t('btnDelete'),
          onClick: () => showActionsModal(item, INACTIVE),
        },
        {
          key: '2',
          label: t('btnShowMentee'),
          onClick: () => showMenti(item),
        },
        {
          key: '3',
          label: t('editMentor'),
          onClick: () => showActionsModal(item, EDIT_MENTOR),
        },
      ];
    }

    if (item.status === STATUS_REJECTED) {
      return [
        {
          key: '1',
          label: t('rejectReason'),
          onClick: () => showActionsModal(item, REJECTED_REASON),
        },
      ];
    }

    if (item.status === STATUS_INACTIVE) {
      return [
        {
          key: '1',
          label: t('btnActive'),
          onClick: () => showActionsModal(item, STATUS_ACTIVE, DESC_ACTIVATED),
        },
        {
          key: '2',
          label: t('btnShowMentee'),
          onClick: () => showMenti(item),
        },
      ];
    }
  };

  const renderActionTable = item => {
    return (
      <Dropdown menu={{ items: tableAction(item) }} trigger={['click']}>
        <span className='ant-dropdown-link' onClick={e => e.preventDefault()}>
          {t('action')}
          <DownOutlined />
        </span>
      </Dropdown>
    );
  };

  const getColumns = () => {
    let columns = [];

    columns.push({
      title: t('userName'),
      dataIndex: 'userName',
      key: '1',
      width: 250,
      fixed: 'left',
      render: (text, value) => (
        <span
          data-qa='adminMentorsMentorUserName'
          className={css['TableContent-table-userName']}
          onClick={() => openProfile(value)}
        >
          {text}
        </span>
      ),
    });

    columns.push({
      title: t('position'),
      dataIndex: 'position',
      key: '2',
      width: 200,
    });

    columns.push({
      title: t('company'),
      dataIndex: 'company',
      key: '3',
      width: 200,
    });

    columns.push({
      title: t('department'),
      dataIndex: 'department',
      key: '4',
      width: 200,
    });

    columns.push({
      title: t('function'),
      dataIndex: 'function',
      key: '5',
      width: 200,
    });

    columns.push({
      title: t('status'),
      dataIndex: 'statusText',
      key: '6',
      width: 200,
    });

    columns.push({
      title: t('statistic'),
      dataIndex: 'statistic',
      key: '7',
      width: 150,
    });

    columns.push({
      title: t('action'),
      dataIndex: 'action',
      key: '8',
      align: 'center',
      render: (text, item) =>
        currentUser.authorities.includes(MENTOR_UPDATE_BY_ADMIN) ? renderActionTable(item) : null,
      width: 140,
    });

    return columns;
  };

  const getData = useMemo(() => {
    if (mentorsTable?.length) {
      return mentorsTable.map(item => {
        let username =
          (item.userDto.lastName || '') + ' ' + (item.userDto.firstName || '') + ' ' + (item.userDto.middleName || '');
        return {
          mentorId: item.mentorId,
          userName: username.trim(),
          userId: item.userDto.id,
          position: item.userDto.position,
          company: item.userDto.company,
          department: item.userDto.department,
          function: item.userDto.function,
          status: item.status,
          student: item.userDto,
          rejectReason: item.rejectReason,
          personalInfo: item.personalInfo,
          statistic: (
            <div className={css['TableContent-table-userStatistics']}>
              <Tooltip placement='top' title={t('successfully')}>
                <div className={css['TableContent-table-userStatistics-iconGreen']}>
                  <HiOutlineBadgeCheck />
                  {item.menteeStatisticDto.successfully}
                </div>
              </Tooltip>

              <Tooltip placement='top' title={t('waiting')}>
                <div className={css['TableContent-table-userStatistics-iconBlue']}>
                  <HiOutlineClock />
                  {item.menteeStatisticDto.waiting}
                </div>
              </Tooltip>

              <Tooltip placement='top' title={t('rejection')}>
                <div className={css['TableContent-table-userStatistics-iconRed']}>
                  <HiOutlineClipboardCheck />
                  {item.menteeStatisticDto.rejection}
                </div>
              </Tooltip>
            </div>
          ),
          competence: item.competences,
          statusText: item.statusText,
        };
      });
    }
  }, [mentorsTable]);

  const onChangeTable = pagination => {
    dispatch(postMentorsFilters(mentorsFilters.filters ? mentorsFilters.filters : {}, pagination.current - 1));
  };

  const pagination = {
    total: mentorsTotal,
    pageSize: mentorsPageSize,
    pageNumber: mentorsPageNumber,
    current: mentorsCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  return (
    <div className={css['TableContent']}>
      <div className={css['TableContent-stat']}>
        <span>
          {t('inAll')} {allStat?.countTotal || 0}
        </span>
        <span>
          {t('inActive')} {allStat?.countActive || 0}
        </span>
        <span>
          {t('inDeactivate')} {allStat?.countInactive || 0}
        </span>
        <span>
          {t('inWaiting')} {allStat?.countPending || 0}
        </span>
        <span>
          {t('inReject')} {allStat?.countRejected || 0}
        </span>
      </div>

      <div className={css['TableContent-table']}>
        {mentorsTable?.length ? (
          <Table
            columns={getColumns()}
            dataSource={getData}
            rowKey={e => `${e.mentorId}--${Symbol('uniq').toString()}`}
            bordered
            scroll={{ x: 700, y: 700 }}
            pagination={pagination}
            onChange={onChangeTable}
            loading={mentorsLoading}
          />
        ) : (
          <div className={css['TableContent-table-empty']}>{t('emptyTable')}</div>
        )}
      </div>

      {isProfile && (
        <Profile
          isMentors
          student={student.student}
          t={t}
          open={isProfile}
          onCancel={() => setIsProfile(false)}
          domainCompany={currentUser?.domainCompany}
          mentorCompetence={student.competence}
        />
      )}

      {isMenti && <ModalMenti open={isMenti} onClose={() => setIsMenti(false)} />}

      {isAction.open && (
        <ModalActions
          onClose={onCloseModalActions}
          onChangeStatus={onChangeStatus}
          isError={isError}
          isAction={isAction}
        />
      )}
    </div>
  );
};

export default TableMentors;
