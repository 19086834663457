export const D360_CREATE_MEASURABLE_START = 'D360_CREATE_MEASURABLE_START';
export const D360_CREATE_MEASURABLE_SUCCESS = 'D360_CREATE_MEASURABLE_SUCCESS';
export const D360_CREATE_MEASURABLE_FAIL = 'D360_CREATE_MEASURABLE_FAIL';

export const D360_DELETE_MEASURABLE_START = 'D360_DELETE_MEASURABLE_START';
export const D360_DELETE_MEASURABLE_SUCCESS = 'D360_DELETE_MEASURABLE_SUCCESS';
export const D360_DELETE_MEASURABLE_FAIL = 'D360_DELETE_MEASURABLE_FAIL';

export const D360_GET_MEASURABLES_START = 'D360_GET_MEASURABLES_START';
export const D360_GET_MEASURABLES_SUCCESS = 'D360_GET_MEASURABLES_SUCCESS';
export const D360_GET_MEASURABLES_FAIL = 'D360_GET_MEASURABLES_FAIL';

export const D360_PUBLISH_REPORT_FAIL = 'D360_PUBLISH_REPORT_FAIL';
export const D360_PUBLISH_REPORT_START = 'D360_PUBLISH_REPORT_START';
export const D360_PUBLISH_REPORT_SUCCESS = 'D360_PUBLISH_REPORT_SUCCESS';
