/* eslint-disable */
import React, { Component } from 'react';
import Rldd from 'react-list-drag-and-drop/lib/RLDD';

import DragItem from '../DragItem';

import PropTypes from 'prop-types';

import css from './DragDropList.module.scss';

class DragDropList extends Component {
  static propTypes = {
    addMaterialsToList: PropTypes.func,
    domainCompany: PropTypes.object,
    getFile: PropTypes.func,
    list: PropTypes.array,
    onDelete: PropTypes.func,
  };

  render() {
    const { list, domainCompany, getFile, onDelete, addMaterialsToList } = this.props;
    return (
      <Rldd
        cssClasses={css.dragDropList}
        items={list}
        itemRenderer={item => (
          <DragItem domainCompany={domainCompany} isMaterials item={item} getFile={getFile} onDelete={onDelete} />
        )}
        onChange={addMaterialsToList}
      />
    );
  }
}

export default DragDropList;
