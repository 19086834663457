import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createFileImage, getFile } from '@/store/files/actions';
import { clearNewsId, createNews, editNewsVisibility, postNewsFilters, updateNews } from '@/store/news/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectNews } from '@/store/news/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Alert, Button, Form, Input, Modal, Spin } from 'antd';
import CoverImage from '@/components/CoverImage/CoverImage';

import { MAX_CHAR_NEWS, ON_CREATE } from './constance';

import QuillEditor from '@shared/components/QuillEditor';
import TagsAutocomplete from '@shared/components/TagsAutocomplete';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../News.module.scss';

const ModalNews = ({ open, isCreate, isEdit, onClose, newsPhotoUuid }) => {
  const { t } = useTranslation('news');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearNewsId()); // чистим в сторе newsId
    };
  }, []);

  const { currentUser } = useSelector(selectUsers);
  const { isLoadingNewsId, newsId, newsFilters, newsCurrentPage, sortNews } = useSelector(selectNews);
  const { isLoadingImage } = useSelector(selectFiles);

  const [data, setData] = useState({
    id: null,
    title: '',
    description: '',
    link: '',
    photoUuid: '',
    tagNames: [],
    authorId: null,
    author: '',
  });
  const [fileBlob, setFileBlob] = useState(undefined);
  const [isLoadingPreviwImage, setLoadingPreviwImage] = useState(false);
  const [previewImg, setPreviewImg] = useState('');

  const [errors, setErrors] = useState({
    title: false,
    description: false,
  });

  useEffect(() => {
    if (isEdit && newsId && Object.keys(newsId).length > 0) {
      setData({
        ...data,
        id: newsId.id,
        title: newsId.title,
        description: newsId.description,
        link: newsId.link,
        photoUuid: newsId.photoUuid, // newsPhotoUuid
        tagNames: newsId.tagNames,
        authorId: newsId.authorDto?.id,
        author: newsId.authorDto?.name,
      });
    }
  }, [newsId]);

  useEffect(() => {
    if (isEdit && newsPhotoUuid && !previewImg) {
      setLoadingPreviwImage(true);
      dispatch(
        getFile(newsPhotoUuid, blob => {
          const imageUrl = URL.createObjectURL(blob);
          setPreviewImg(imageUrl);
          setLoadingPreviwImage(false);
        })
      );
    }
  }, []);

  const validData = value => {
    let copyValue = _.cloneDeep(value);

    for (let key in copyValue) {
      if (!copyValue[key]) {
        delete copyValue[key]; // удалил пустые значения
      }

      if (Array.isArray(copyValue[key]) && copyValue[key].length === 0) {
        delete copyValue[key];
      }
    }

    if (copyValue?.tagNames?.length > 0) {
      copyValue.tagNames = copyValue?.tagNames.map(i => {
        const isNewTag = String(i.id).startsWith('new');
        const id = isNewTag ? null : i.id;

        return { name: i.name, id };
      });
    }

    if (copyValue?.id) {
      delete copyValue.id;
    }

    if (copyValue.title) {
      copyValue.title = copyValue.title.trim();
    }

    if (copyValue.description) {
      copyValue.description = copyValue.description.trim();
    }

    return copyValue;
  };

  const updateImage = blob => {
    setFileBlob(blob);
    setPreviewImg(window.URL.createObjectURL(blob));
  };

  const onChangeEditor = (htmlCode, textCode) => {
    if (textCode.length > 0 && htmlCode) {
      setData(prevData => ({ ...prevData, description: htmlCode }));
    } else {
      setData(prevData => ({ ...prevData, description: '' }));
    }
  };

  const pushError = (name, status) => {
    setErrors({ ...errors, [name]: status });
  };

  const checkErrors = () => {
    const parser = new DOMParser();

    const title = data.title.trim();
    const description = parser.parseFromString(data.description.trim(), 'text/html');
    const descriptionInnerText = description.body.innerText.trim();

    if (!title || !descriptionInnerText) {
      setErrors({ title: !title, description: !descriptionInnerText });
      setData(prevData => ({ ...prevData, title, description: descriptionInnerText }));

      return false;
    }

    return true;
  };

  const templateCallback = (res, type) => {
    // скрытие новости
    if (res && type === ON_CREATE) {
      dispatch(editNewsVisibility(res, false));
    }
    dispatch(postNewsFilters(newsFilters.filters ? newsFilters.filters : {}, newsCurrentPage, sortNews));
    onClose();
  };

  const onCreateNews = () => {
    if (!checkErrors()) return;

    const formData = new FormData();
    formData.append('file', fileBlob);

    const sendData = validData(data);

    if (fileBlob) {
      dispatch(
        createFileImage(formData, res => {
          dispatch(createNews({ ...sendData, photoUuid: res.uuid }, res => templateCallback(res, ON_CREATE)));
        })
      );
    } else {
      dispatch(createNews(sendData, res => templateCallback(res, ON_CREATE)));
    }
  };

  const onUpdateNews = () => {
    if (!checkErrors()) return;

    const formData = new FormData();
    formData.append('file', fileBlob);

    const sendData = validData(data);
    if (fileBlob) {
      dispatch(
        createFileImage(formData, res => {
          dispatch(
            updateNews(data.id, { ...sendData, photoUuid: res.uuid }, () => {
              templateCallback();
            })
          );
        })
      );
    } else {
      dispatch(
        updateNews(data.id, sendData, () => {
          templateCallback();
        })
      );
    }
  };

  const onChangeTags = e => {
    if (e?.value) {
      const namesTags = e.value.map(i => ({ name: i.name, id: i.id }));
      setData({ ...data, tagNames: namesTags });
    }
  };

  const onDeleteBlob = () => {
    setFileBlob(undefined);
    setPreviewImg(undefined);
    setData({ ...data, photoUuid: '' });
  };

  const footerModal = (
    <>
      <Button onClick={onClose} key='onClose' size='large'>
        {t('close')}
      </Button>
      <Button onClick={isCreate ? onCreateNews : onUpdateNews} key='onSumbit' size='large' type='primary'>
        {isCreate ? t('create') : t('update')}
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      title={isCreate ? t('titleCreate') : t('titleEdit')}
      className={css['ModalNews']}
      onCancel={onClose}
      footer={(isLoadingNewsId && isEdit) || isLoadingImage ? false : footerModal}
      width={1000}
    >
      <Spin size='large' className={css['ModalNews-spin']} spinning={(isLoadingNewsId && isEdit) || isLoadingImage}>
        <Form className={css['ModalNews-form']} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          {/* Название */}
          <Form.Item
            label={t('nameNews')}
            colon={false}
            validateStatus={errors.title ? 'error' : ''}
            help={errors.title && t('notNameNews')}
            required
          >
            <Input
              size='large'
              onChange={e => setData({ ...data, title: e.target.value })}
              value={data.title}
              placeholder={t('placeholderNews')}
              maxLength={500}
            />
          </Form.Item>

          {/* Автор */}
          {isEdit && (
            <Form.Item label={t('authorNewsText')} colon={false}>
              <a
                rel='noreferrer'
                href={`https://${currentUser?.domainCompany?.domain}/collegues/${data.authorId}/info`}
                target='_blank'
              >
                {data.author}
              </a>
            </Form.Item>
          )}

          {/* Редактор описания */}
          <Form.Item
            label={t('editorNews')}
            colon={false}
            validateStatus={errors.description ? 'error' : ''}
            help={errors.description && t('notEditorNews')}
            required
          >
            {(isCreate || (isEdit && !isLoadingNewsId && newsId?.description)) && (
              <QuillEditor
                onChange={onChangeEditor}
                Content={newsId?.description || data.description}
                textMaxChar={t('maxCharNews')}
                maxChar={MAX_CHAR_NEWS}
                placeholder={t('placeholderNewsEditor')}
                showFooter
                hideCodeCount
              />
            )}
          </Form.Item>

          {/* Изображение */}
          <Form.Item label={t('imageNews')} colon={false}>
            <Alert message={t('infoUploadImage')} className={css['ModalNews-form-alert']} type='info' closable />
            <Spin spinning={!isLoadingNewsId && isLoadingPreviwImage}>
              <CoverImage
                updateImage={updateImage}
                pushError={pushError}
                typeUpload='image/png, image/jpeg,'
                errorSizeMessage={t('errorSizeMessageNews')}
                changeImageText={t('changeImageNews')}
                clearFileBlob={onDeleteBlob}
                fileBlob={fileBlob || previewImg}
                canDelete
              />
              <div className={css['ModalNews-form-upload']}>
                {!isLoadingPreviwImage && (
                  <img
                    className={`${css['ModalNews-form-upload-img']} ${previewImg && css['show']}`}
                    src={previewImg}
                    alt={t('infoPreviewImage')}
                  />
                )}
              </div>
            </Spin>
          </Form.Item>

          {/* Ссылка */}
          <Form.Item label={t('link')} colon={false}>
            <Input
              size='large'
              onChange={e => setData({ ...data, link: e.target.value })}
              value={data.link}
              placeholder={t('placeholderLink')}
              maxLength={500}
            />
          </Form.Item>

          {/* Тэги */}
          <Form.Item label={t('tags')} colon={false}>
            <TagsAutocomplete
              onChange={onChangeTags}
              name='tagNames'
              placeholder={t('placeholderTags')}
              value={data.tagNames}
              // isGlobal={currentUser?.domainCompany?.global}
              t={t}
              isNews
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

ModalNews.propTypes = {
  open: PropTypes.bool,
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.object,
  newsPhotoUuid: PropTypes.string,
};

export default ModalNews;
