import { HomeOutlined } from '@ant-design/icons';

const CorpCourseSvg = props => (
  <div>
    <HomeOutlined style={{ fontSize: '12px' }} />
    {props.name}
  </div>
);

export default CorpCourseSvg;
