import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360ImportDelete, D360ImportStatus } from '@/store/d360/d360_import/actions';

import { Alert, Button, Col, Skeleton } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import _ from 'lodash';
import PropTypes from 'prop-types';

export const ImportAlert = props => {
  const { t } = useTranslation('D360_ImportAlert');
  const dispatch = useDispatch();
  const [closable, setClosable] = useState(false);
  const { status, errorLine, errorMessage, gettingStatus } = useSelector(state => state.d360Import);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(null);
  const [type, setType] = useState('info');

  const onUpdate = () => {
    if (_.isObject(props) && _.has(props, 'projectId') && _.isNumber(props.projectId)) {
      dispatch(D360ImportStatus(props.projectId));
    }
  };

  const onClose = () => {
    if (_.isObject(props) && _.has(props, 'projectId') && _.isNumber(props.projectId)) {
      dispatch(
        D360ImportDelete(props.projectId, () => {
          onUpdate();
        })
      );
    }

    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };

  useEffect(() => {
    if (!_.isNil(status) && _.isString(status)) {
      switch (status.toString().toLowerCase()) {
        case 'new':
        case 'started':
          setTitle(t('started_title'));
          setDescription(
            <>
              <p>{t('started_description')}</p>
              <Button type='primary' icon={<SyncOutlined />} onClick={onUpdate} disabled={gettingStatus}>
                {t('started_button')}
              </Button>
            </>
          );
          setType('info');
          setClosable(false);
          break;
        case 'finished':
          setTitle(t('finished_title'));
          setDescription(t('finished_description'));
          setType('success');
          setClosable(true);
          break;
        case 'failed':
          setTitle(t('failed_title'));
          setDescription(t('failed_description', { line: errorLine, message: t(errorMessage) }));
          setType('warning');
          setClosable(true);
          break;
        default:
          setTitle('');
          setDescription(null);
          setType('info');
          setClosable(false);
      }
    }
  }, [status]);

  return title !== '' ? (
    <Col span={24}>
      <Skeleton loading={gettingStatus} avatar paragraph>
        <Alert message={title} description={description} closable={closable} type={type} onClose={onClose} showIcon />
      </Skeleton>
    </Col>
  ) : null;
};

ImportAlert.propTypes = {
  projectId: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};
