import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { loginGoogle } from '@/store/login/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectLogin } from '@/store/login/selectors';

import { Button, Form, message } from 'antd';
import LogoCompany from '@/components/LogoCompany';

import { clearStorage } from '@/utils/clearStorage';
// import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';

import css from './Login.module.scss';

// import noIcon from '@/assets/icons/cannot-icon.svg';

const LoginOauth = () => {
  const { t } = useTranslation('registrationData');
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const { setEntering, imageLogo } = useOutletContext();

  const { cacheFiles } = useSelector(selectFiles);
  const { company } = useSelector(selectLogin);

  const provider = company.providers.find(provider => provider.type === 'OAUTH');
  // const [currentProviderImg] = useLocalFileByUUID(provider?.icon, { defaultImg: noIcon, publish: true });

  const [isMobile] = useIsMobile(768);

  const handleLogin = () => {
    setEntering(true);
    dispatch(
      loginGoogle(
        provider?.name,
        (_response, headers) => {
          clearStorage();
          window.location.replace(headers.get('Location'));
        },
        () => {
          messageApi.open({
            type: 'error',
            content: t('errorOauth'),
          });
          setEntering(false);
        }
      )
    );
  };

  return (
    <>
      {!isMobile && (
        <img alt='' src={cacheFiles[company?.background]} className={css['LoginPage-header__background']} />
      )}
      <div className={css['App-card-item']}>
        <div className={css['LoginPage-header__failure']}>
          <div className={css['LoginPage-header__logo']}>
            <LogoCompany imageLogo={cacheFiles[company?.logo] || imageLogo} />
          </div>
        </div>
        <Form className={classNames(css['LoginPage-form-google'], css.modif)}>
          <p className={css['LoginPage-header-oauth__subtitle']}>{t('welcome')}</p>
          <Button
            type={'primary'}
            className={css['LoginPage-form-google__googleBtn']}
            htmlType='submit'
            onClick={handleLogin}
            name={provider?.name}
            // icon={<img src={currentProviderImg} alt='' />}
            icon={<img src='/images/oauth_icon.svg' alt='' />}
            size='large'
          >
            {provider?.label}
          </Button>
        </Form>
        <div className={css['LoginPage-label']}>
          <h4
            dangerouslySetInnerHTML={{
              __html: provider?.description,
            }}
          />
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default LoginOauth;
