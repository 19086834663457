import {
  CLEAR_BANNERS_TOP_BODY,
  CREATE_BANNER_TOP_BODY_FAIL,
  CREATE_BANNER_TOP_BODY_START,
  CREATE_BANNER_TOP_BODY_SUCCESS,
  defaultToken,
  DELETE_BANNER_TOP_BODY_FAIL,
  DELETE_BANNER_TOP_BODY_START,
  DELETE_BANNER_TOP_BODY_SUCCESS,
  EDIT_BANNER_TOP_BODY_FAIL,
  EDIT_BANNER_TOP_BODY_START,
  EDIT_BANNER_TOP_BODY_SUCCESS,
  GET_BANNER_TOP_BODY_FAIL,
  GET_BANNER_TOP_BODY_START,
  GET_BANNER_TOP_BODY_SUCCESS,
  GET_BANNERS_TOP_BODY_FAIL,
  GET_BANNERS_TOP_BODY_START,
  GET_BANNERS_TOP_BODY_SUCCESS,
  headers,
  SHOW_BANNER_TOP_BODY_FAIL,
  SHOW_BANNER_TOP_BODY_START,
  SHOW_BANNER_TOP_BODY_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const showBannerTopBody = (id, status, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/banners/${id}/show/${status}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [SHOW_BANNER_TOP_BODY_START, SHOW_BANNER_TOP_BODY_SUCCESS, SHOW_BANNER_TOP_BODY_FAIL],
  onSuccess,
  onError,
});
// eslint-disable-next-line
export const getBannersTopBody = (name, page = 0, size = 100, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/banners${getQueryString({ name })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BANNERS_TOP_BODY_START, GET_BANNERS_TOP_BODY_SUCCESS, GET_BANNERS_TOP_BODY_FAIL],
  onSuccess,
});

export const getBannerTopBody = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/banners${getQueryString({
    id,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BANNER_TOP_BODY_START, GET_BANNER_TOP_BODY_SUCCESS, GET_BANNER_TOP_BODY_FAIL],
  onSuccess,
});

export const createBannerTopBody = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/banners`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_BANNER_TOP_BODY_START, CREATE_BANNER_TOP_BODY_SUCCESS, CREATE_BANNER_TOP_BODY_FAIL],
  onSuccess,
  onError,
});

export const editBannerTopBody = (id, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/banners/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [EDIT_BANNER_TOP_BODY_START, EDIT_BANNER_TOP_BODY_SUCCESS, EDIT_BANNER_TOP_BODY_FAIL],
  onSuccess,
  onError,
});

export const deleteBannerTopBody = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/banners/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_BANNER_TOP_BODY_START, DELETE_BANNER_TOP_BODY_SUCCESS, DELETE_BANNER_TOP_BODY_FAIL],
  onSuccess,
  onError,
});

export const clearBannerTopBody = id => ({
  type: CLEAR_BANNERS_TOP_BODY,
  response: { id },
});
