/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './TagDirectory.module.scss';

import { CAREER_POSITION_DELETE, CAREER_POSITION_UPDATE } from '@/constants/permissions';

export default class TagItem extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
  };

  handleEdit = () => this.props.onEdit(this.props.post);

  handleDelete = () => this.props.onDelete(this.props.post);

  render() {
    const { post, currentUser, t } = this.props;
    return (
      <div className={css.TagItem}>
        <div className={css['TagItem-post']}>
          <div className={css['TagItem-width']} data-qa='postName'>
            {Utils.maxTextValue(post.name) ? (
              <Tooltip title={post.name} placement='topLeft'>
                {post.name}
              </Tooltip>
            ) : (
              post.name
            )}
          </div>
          <div className={css['TagItem-role']} data-qa='postRole'>
            {post.conventusRole.name}
          </div>
        </div>
        <div className={css['TagItem-container']}>
          {currentUser.authorities.includes(CAREER_POSITION_UPDATE) && (
            <Tooltip title={t('editButton')} placement='left'>
              <FormOutlined
                size='small'
                type='primary'
                data-qa='editCompetenceBtn'
                className={css['TagItem-icon-edit']}
                onClick={this.handleEdit}
              />
            </Tooltip>
          )}
          {currentUser.authorities.includes(CAREER_POSITION_DELETE) && (
            <Tooltip title={t('deleteButton')}>
              <DeleteOutlined
                size='small'
                data-qa='deleteCompetenceBtn'
                className={css['TagItem-icon-delete']}
                onClick={this.handleDelete}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}
