/* eslint-disable */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createDepartment, deleteDepartment, editDepartment, getDepartments } from '@/store/department/actions';

import { selectDepartments } from '@/store/department/selectors';
import { selectUsers as userSelect } from '@/store/users/selectors';

import DictionaryTable from '@/components/Dictionary/DictionaryTable';

import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

export class DepartmentDirectory extends Component {
  static propTypes = {
    createData: PropTypes.func,
    creationError: PropTypes.bool,
    deleteData: PropTypes.func,
    editData: PropTypes.func,
    editingError: PropTypes.bool,
    getData: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    dataPageNumber: PropTypes.number,
    dataPageSize: PropTypes.number,
    dataTotal: PropTypes.number,
  };

  dictionary = () => ({
    title: this.props.t('title'),
    importText: this.props.t('importText'),
    sharedTitle: this.props.t('titleGlobal'),
    addData: this.props.t('addName'),
    placeholder: this.props.t('name'),
    addDataModal: {
      title: this.props.t('addName'),
      label: this.props.t('newOne'),
    },
    editDataModal: {
      title: this.props.t('editName'),
      label: this.props.t('newOne'),
    },
    error: this.props.t('exist'),
    deleteData: {
      title: this.props.t('deleteName'),
      confirmation: this.props.t('confirm'),
    },
  });

  render() {
    return <DictionaryTable {...this.props} dictionary={this.dictionary()} limit isFunction={false} />;
  }
}

const mapStateToProps = createSelector([selectDepartments, userSelect], (department, users) => ({
  data: department.departments || [],
  dataTotal: department.departmentsTotal,
  dataPageSize: department.departmentsPageSize,
  dataPageNumber: department.departmentsPageNumber || 0,
  creationError: department.creationError || false,
  editingError: department.editingError || false,
  isLoading: department.isLoading,
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getData: getDepartments,
  createData: createDepartment,
  editData: editDepartment,
  deleteData: deleteDepartment,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('departmentDirectory')(DepartmentDirectory));
