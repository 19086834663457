export const IN_ACTIVE = 'ACTIVE';
export const IN_FINISHED = 'FINISHED';
export const IN_REJECT = 'REJECTED_BY_MENTOR';
export const REJECTED_MENTOR = 'REJECTED_MENTOR';
export const GRATITUDE = 'GRATITUDE';
export const REQUEST_EXPERIENCE = 'REQUEST_EXPERIENCE';
export const GRADE = 'GRADE';

export const STATUS_FINISHED = 'FINISHED';
export const STATUS_WAITING_MENTOR = 'WAITING_MENTOR';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_REJECTED_BY_MENTOR = 'REJECTED_BY_MENTOR';
export const STATUS_NO_ACTION = [
  'REJECTED_BY_MENTEE',
  'ON_WAITING_LIST',
  'WAITING_MENTEE',
  'WAITING_MENTEE_MEETING',
  'WAITING_MENTOR_MEETING',
  'WAITING_MENTEE_DECISION',
  'REJECTED_BY_MENTEE',
  'WAITING_RATING',
  'WAITING_MENTOR_DECISION', // удалить потом
];
export const dateFormatList = ['YYYY-MM-DD'];
export const MENTEE = 'mentee';
export const MENTOR = 'mentor';
