import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearManagerFullname, getStudents, getStudentsList } from '@/store/students/actions';
import { clearTrackAppointFilters, postAppointFilters, saveTrackAppointFilters } from '@/store/tracks/actions';

// import { selectCompanies } from '@/store/companies/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectTracks } from '@/store/tracks/selectors';

import { Button, Col, Form, Row, Select } from 'antd';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../TrackDetail.module.scss';

const { Option } = Select;

const FiltersAppointments = ({ setIsSort, setActiveSort, isSort }) => {
  const { t } = useTranslation('trackDetail');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearManagerFullname());
    };
  }, []);

  const { trackDetails, statuses } = useSelector(selectTracks);

  const [data, setData] = useState({
    userId: { id: undefined, name: undefined },
    company: '',
    department: '',
    function: '',
    status: { id: '', name: '' },
    managerId: { id: undefined, name: '' },
  });

  const [sendData, setSendData] = useState({});

  //   const { subCompanies } = useSelector(selectCompanies);
  const { department, functionStudent, students, fullnameManager, isLoadingFullnameManager } =
    useSelector(selectStudents);

  useEffect(() => {
    // удаляем пустые значения для отправки на бэк
    let copyData = _.cloneDeep(data);
    for (let key in copyData) {
      if (!copyData[key] || (copyData[key].hasOwnProperty('id') && !copyData[key].id)) {
        delete copyData[key];
      } else if (copyData[key]?.id) {
        copyData[key] = copyData[key].id;
      }

      if (_.isString(copyData[key]) && copyData[key].length === 0) {
        delete copyData[key];
      }
    }
    setSendData(copyData);
    dispatch(saveTrackAppointFilters(copyData)); // сохраняем в стор фильтра
  }, [data]);

  const handleGetManagers = e => {
    if (e) {
      let fullName = e.trim();
      fullName.length > 3 && dispatch(getStudentsList({ isManager: true, fullName, size: 100 }));
    }
  };

  const getStudentsListDebounced = useCallback(_.debounce(handleGetManagers, 1000), []);

  const resetFilters = () => {
    setData({
      userId: { id: undefined, name: undefined },
      company: '',
      department: '',
      function: '',
      status: { id: '', name: '' },
      managerId: { id: undefined, name: '' },
    });

    dispatch(clearTrackAppointFilters());
    setIsSort('');
    setActiveSort({
      date: false,
      lastName: {
        aZ: false,
        zA: false,
      },
    });

    if (trackDetails?.id) {
      dispatch(postAppointFilters(trackDetails.id, {}, 0));
    }
  };

  const onSendFilters = () => {
    if (trackDetails?.id) {
      dispatch(postAppointFilters(trackDetails.id, sendData, 0, isSort));
    }
  };

  const renderOption = arr => {
    return (
      arr?.length &&
      arr.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      })
    );
  };

  const optionUsers =
    students?.length &&
    students.map(item => {
      let username = (item.lastName || '') + ' ' + (item.firstName || '') + ' ' + (item.middleName || '');
      return (
        <Option key={item.id} value={username}>
          {username}
        </Option>
      );
    });

  const optionManager =
    fullnameManager?.length &&
    fullnameManager.map(item => {
      return (
        <Option key={item.id} value={item.name} id={item.id}>
          {item.name}
        </Option>
      );
    });

  const optionStatus =
    statuses?.length &&
    statuses.map(item => {
      return (
        <Option key={item.value} value={item.name}>
          {item.name}
        </Option>
      );
    });

  const handleGet = (e, func) => {
    // if (e?.length > 2 || Object.values(e)?.[0].length > 2) {
    // }
    dispatch(func(e));
  };

  const getDebounced = useCallback(_.debounce(handleGet, 1000), []);

  const tempalateFilters = [
    {
      // первая строка фильтров
      type: 'flex',
      justify: 'start',
      classNameRow: css['Track-details-tabs-appoint-filters-block'],
      col: [
        {
          // ФИО
          label: t('fullName'),
          value: data.userId.name,
          onChange: (name, item) => setData({ ...data, userId: { id: +item?.key, name: name } }),
          onSearch: e => getDebounced({ fullName: e }, getStudents),
          span: 8,
          option: optionUsers,
        },
        {
          // Руководитель
          label: t('manager'),
          value: data.managerId.name,
          onChange: (name, item) => setData({ ...data, managerId: { id: item?.props?.id, name } }),
          span: 8,
          option: optionManager,
          onSearch: getStudentsListDebounced,
          loading: isLoadingFullnameManager,
        },
        {
          // отдел
          label: t('department'),
          value: data.department,
          onChange: name => setData({ ...data, department: name }),
          span: 8,
          option: renderOption(department),
        },
      ],
    },
    {
      // вторая строка фильтров
      type: 'flex',
      justify: 'start',
      classNameRow: css['Track-details-tabs-appoint-filters-block'],
      col: [
        // {
        //   // Компания
        //   label: t('company'),
        //   value: data.company,
        //   onChange: name => setData({ ...data, company: name }),
        //   span: 8,
        //   option: renderOption(subCompanies),
        // },

        {
          // функция
          label: t('function'),
          value: data.function,
          onChange: name => setData({ ...data, function: name }),
          span: 8,
          option: renderOption(functionStudent),
        },
        {
          // Статус
          label: t('status'),
          value: data.status.name,
          onChange: (name, item) => setData({ ...data, status: { id: item?.key, name } }),
          span: 8,
          option: optionStatus,
        },
      ],
    },
  ];

  const renderFilters = tempalateFilters.map((item, index) => {
    const customRow = item.col.map((j, index) => {
      return (
        <Col span={j.span} key={index}>
          <Form layout='vertical'>
            <Form.Item label={j.label} colon={false} key={`${j.label}+${-index}`}>
              <Select
                key={index}
                onChange={j.onChange}
                onSearch={j.onSearch}
                notFoundContent={t('noData')}
                value={j.value}
                allowClear={!j.loading}
                showSearch
                loading={j.loading}
              >
                {j.option}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      );
    });

    return (
      <Row type={item.type} justify={item.justify} className={item.classNameRow} key={index} gutter={16}>
        {customRow}
      </Row>
    );
  });

  return (
    <div className={css['Track-details-tabs-appoint-filters']}>
      <div className={css['Track-details-tabs-appoint-filters-buttons']}>
        <span className={css['Track-details-tabs-appoint-filters-buttons-filters']}>{t('filters')}</span>
        <Button type='link' onClick={resetFilters} className={css['Track-details-tabs-appoint-filters-buttons-reset']}>
          {t('reset')}
        </Button>
      </div>

      {renderFilters}

      <div className={css['Track-details-tabs-appoint-filters-actions']}>
        <Button onClick={onSendFilters} type='primary'>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
};

FiltersAppointments.propTypes = {
  isSort: PropTypes.string,
  setIsSort: PropTypes.func,
  setActiveSort: PropTypes.func,
};

export default FiltersAppointments;
