import React from 'react';

import { Table } from 'antd';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';

import { SCORM, TEST } from '@shared/constants/block-type';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../TopicCreationDialog/TopicCreationDialog.module.scss';

const DataTestInfo = ({ data, t, scroll, tableSize, columns, printInnerKeys }) => {
  const types = () => ({
    NOT_STARTED: t('notComplete'),
    FINISHED: t('complete'),
  });

  const result =
    Array.isArray(data.blocksStat) &&
    data.blocksStat.length > 0 &&
    data.blocksStat.map((block, index) => {
      let date = '';

      if (block.status === 'FINISHED') {
        const dayjsDate = dayjs(block.lastModified);
        date = `${dayjsDate.format('DD.MM.YYYY')} ${t('in')} ${dayjsDate.format('HH:mm')}`;
      }

      const translatePassedStatus = status => {
        switch (status) {
          case 'passed':
            return t('successful');
          case 'failed':
            return t('unsuccessful');
          default:
            return '';
        }
      };

      let resultStatus = '';
      if (block.scorm) {
        resultStatus = translatePassedStatus(block.scorm.successStatus);
      }

      const startTime = block.startTime
        ? `${dayjs(block.startTime).format('DD.MM.YYYY')} ${t('in')} ${dayjs(block.startTime).format('HH:mm')}`
        : '';

      const testScore =
        date &&
        block.test &&
        `${block.test.countCorrectUserAnswers} ${t('uotOf')} ${block.test.countQuestions} (${
          block.test.countQuestions
            ? Math.floor((block.test.countCorrectUserAnswers * 100) / block.test.countQuestions)
            : 0
        }%)`;

      const scormDataToLines = currentScormData => {
        const { learner_id, learner_name, suspend_data, interactions, ...currentScormDataJSON } =
          JSON.parse(currentScormData);
        console.log(currentScormDataJSON, interactions);

        const currentScormJsonKeys = Object.keys(currentScormDataJSON);

        const ScormDataComponent = (
          <div className={css['scorm-data-component']}>
            {currentScormJsonKeys?.length > 0 && printInnerKeys(currentScormDataJSON)}
            {interactions &&
              Object.keys(interactions)?.length > 0 &&
              Object.keys(interactions).map((currentKey, index) => (
                <div className={css['scorm-data-component__item']}>
                  <b>{t('interactions', { id: index + 1 })}</b>

                  {Object.keys(interactions[currentKey]).map(e => (
                    <div className={css['scorm-data-component__item']}>
                      <span>{t(`interactions.${e}`)}</span>
                      {equalsElement}
                      <span className={css['scorm-data-component__res']}>
                        {typeof interactions[currentKey][e] !== 'object' ? interactions[currentKey][e] : ''}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        );
        return ScormDataComponent;
      };

      const blockResult = () => {
        switch (block?.blockType?.id) {
          case SCORM:
            return (block.scorm?.percentPassing * 100 || 0) + '%';

          case TEST:
            return !block.test?.poll && testScore;

          default:
            return '';
        }
      };

      return {
        key: index,
        block: block.name,
        status: types()[block.status],
        startDate: startTime,
        endDate: date,
        result: blockResult(),
        outcome: resultStatus,
        description: block?.scorm?.scormData ? scormDataToLines(block.scorm.scormData) : t('descriptionNone'),
      };
    });

  return (
    <Table
      columns={columns}
      dataSource={result}
      pagination={false}
      expandable={{
        expandedRowRender: record => <div>{record.description}</div>,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
          ) : (
            <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
          ),
      }}
      scroll={scroll}
      size={tableSize}
    />
  );
};

DataTestInfo.propTypes = {
  data: PropTypes.object,
  t: PropTypes.func,
  scroll: PropTypes.object,
  tableSize: PropTypes.string,
  columns: PropTypes.array,
  printInnerKeys: PropTypes.func,
};

export default DataTestInfo;
