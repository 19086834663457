/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { editCompanyTimezone } from '@/store/companies_timezone/actions';
import { createCompanies, editCompanies } from '@/store/companies/actions';
import { createFile, createFileImage, createFileImagePublic, getFile } from '@/store/files/actions';
import { getLocales } from '@/store/locales/actions';
import { getTimezones } from '@/store/timezones/actions';
import { getTopicTypes } from '@/store/topics/actions';
import { getUsers } from '@/store/users/actions';

import { selectFiles as filesSelect } from '@/store/files/selectors';
import { selectLocales } from '@/store/locales/selectors';
import { selectTimezones } from '@/store/timezones/selectors';
import { selectTopics } from '@/store/topics/selectors';

import { Divider, Form, Modal } from 'antd';

import { Footer } from './Footer';
import { Mails } from './Mails';
import optionsDiagnostics from './optionsDiagnostics.json';
import { Registration } from './Registration';
import { Sections } from './Sections';
import { Settings } from './Settings';
import { Types } from './Types';
import { roles, Users } from './Users';

import { IMAGE_TYPES } from '@shared/constants/image-types';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './CompanyCreationDialog.module.scss';

class CreateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortName: '',
      fullName: '',
      domain: '',
      adminDomain: '',
      colour: '',
      logo: '',
      departmentSingleNameImenit: 'отдел',
      positionSingleNameImenit: 'должность',
      functionSingleNameImenit: 'функция',
      myDepartmentButtonName: 'Только мой отдел',
      background: '',
      defaultLanguage: 'Русский',
      timezone: {
        id: 459,
        name: 'Europe/Moscow',
        offset: 180,
      },
      supportLink: '',
      helpLink: '',
      telegramBotLink: '',
      tradeLinks: [],
      googleAnalyticId: '',
      hasCaptcha: false,
      autoHideNewTopics: false,
      topicTypes: this.props.topicTypes || [],
      hasTrajectory: false,
      hasUserTrajectory: false,
      trajectoryOffset: '',
      hasCoins: false,
      coinIcon: '',
      hasCompilation: false,
      hasManager: false,
      hasAdditionalInfo: false,
      hasUserPosition: false,
      hasUserDepartment: false,
      hasUserFunction: false,
      hasShare: false,
      eventStartTime: null,
      eventEndTime: null,
      corpCourseBackground: '',
      corpCourseIcon: '',
      adminLogin: undefined,
      defaultRoleRegistration: 'Пользователь',
      lifeTimeUser: '',
      usersLimit: '',
      expirationDate: '',
      mailFeedback: '',
      mailAdmin: '',
      mailLogo: '',
      mailManagerApproveBtn: '',
      mailManagerRejectBtn: '',
      mailApproveBtn: '',
      mailRejectBtn: '',
      mailLearnBtn: '',
      mailMentionBtn: '',
      mailSkipBtn: '',
      hasPinned: true,
      provider: {
        type: 'INTERNAL',
        usernameAlias: 'Email',
        domains: '',
      },
      copyGlobalCategories: true,
      copyGlobalCareers: true,
      copyGlobalFooter: true,
      copyGlobalFaq: true,
      globalShare: true,
      images: {
        logo: '',
        background: '',
        trajectoryOffset: '',
        corpCourseBackground: '',
        corpCourseIcon: '',
        coinIcon: '',
        mailLogo: '',
        mailManagerApproveBtn: '',
        mailManagerRejectBtn: '',
        mailApproveBtn: '',
        mailRejectBtn: '',
        mailLearnBtn: '',
        mailMentionBtn: '',
        mailSkipBtn: '',
      },
      errors: {},
      manager: '',
      hideAlpina: false,
      hideGlobal: false,
      diagnosticType: {
        id: 1,
        type: 'CONVENTUS',
      },
      diagnosticId: 1,
      modalTalentQ: false,
      hasMentors: true,
      hasShops: true,
      hasIpr: true,
      hasGlobalTheme: true,
      hasTelegram: false,
      hasBitrox: false,
    };

    if (this.props.company) {
      const { providers, tradeLinks, timezone, defaultRoleRegistration, status, ...data } = this.props.company;

      this.state = {
        ...data,
        provider: providers[0],
        tradeLinks: tradeLinks || [],
        timezone: timezone,
        defaultRoleRegistration: Object.keys(roles).find(key => roles[key] === defaultRoleRegistration),
        images: this.state.images,
        hasPinned: true,
        errors: {},
      };
      Object.keys(this.state.images).map(name => {
        this.getCurrentFile(this.state[name], name);
      });
    }

    if (this.props.company && this.props.company.adminLogin) {
      this.props.getUsers({ email: this.props.company.adminLogin }, res => {
        if (res.length) {
          this.setState({ manager: `${res[0].lastName} ${res[0].firstName}` });
        }
      });
    }

    if (!this.props.topicTypes) {
      this.props.getTopicTypes(response => (!this.props.company ? this.setState({ topicTypes: response }) : null));
    }

    if (!this.props.locales.length) {
      this.props.getLocales();
    }

    if (!this.props.timezones.length) {
      this.props.getTimezones();
    }
  }
  componentDidMount() {
    if (this.props.company) {
      this.setState({ ...this.state, diagnosticId: this.props.company.diagnosticType.id });
    }
    while (this.state?.tradeLinks?.length < 2) {
      this.state.tradeLinks.push({ name: '', additionalName: '', url: '' });
    }
  }

  getCurrentFile = (photoUuid, name) => {
    if (!photoUuid) {
      return;
    }

    this.props.getFile(photoUuid, blob => {
      const imageUrl = URL.createObjectURL(blob);
      this.setState(state => ({
        images: { ...state.images, [name]: imageUrl },
      }));
    });
  };

  handleFileChange = (file, name, info, cropImageSrc, createFileImage) => {
    if (file && info) {
      if (IMAGE_TYPES.includes(info.type)) {
        createFileImage(file, fileInfo => {
          this.setState(state => ({
            [name]: fileInfo.uuid,
            images: {
              ...state.images,
              [name]: cropImageSrc,
            },
          }));
        });
        return true;
      }
      this.setState(state => ({
        [name]: null,
        errors: {
          ...state.errors,
          [name]: this.props.t('error'),
        },
      }));
    } else {
      this.setState(state => ({
        [name]: file,
        errors: { ...state.errors, [name]: '' },
      }));
    }
  };

  handleImageChange = (file, name, info, cropImageSrc) => {
    this.handleFileChange(file, name, info, cropImageSrc, this.props.createFileImage);
  };

  handlePublicImageChange = (file, name, info, cropImageSrc) => {
    this.handleFileChange(file, name, info, cropImageSrc, this.props.createFileImagePublic);
  };

  handleChange = event => {
    const { name, value } = event.target || event;
    const GPM = 4;

    switch (name) {
      case 'hasAdditionalInfo':
        this.setState({
          [name]: value,
          hasUserPosition: value,
          hasUserDepartment: value,
          hasUserFunction: value,
        });
        break;

      case 'hasTrajectory':
        this.setState({
          [name]: value,
          hasUserTrajectory: value,
        });
        break;

      case 'alpina':
      case 'global':
        this.setState({ [name]: value });
        break;

      case 'timezone':
        const { timezones } = this.props;
        let timeFind = timezones.find(item => item.name === value);
        this.setState({ [name]: timeFind });
        break;

      case 'diagnosticType':
        const typeOptions = optionsDiagnostics.optionsDiagnostics.find(item => item.id == value);
        if (typeOptions) {
          this.setState({
            [name]: {
              id: Number(typeOptions.value),
              type: typeOptions.type,
            },
            diagnosticId: Number(typeOptions.value),
            modalTalentQ: Number(typeOptions.value) === GPM,
          });
        }

        break;
      default:
        if (!name.includes('tradeLinks')) {
          this.setState({ [name]: value });
        }
    }
    if (name.includes('provider')) {
      const keys = name.split('.');
      this.setState({
        [keys[0]]: {
          ...this.state[keys[0]],
          [keys[1]]: value,
        },
      });
    }

    if (name.includes('tradeLinks')) {
      const keys = name.split('.');
      const newTradeLinks = this.state.tradeLinks;
      newTradeLinks.forEach((item, index) => {
        if (index === +keys[1]) {
          newTradeLinks[keys[1]] = { ...item, [keys[2]]: value };
        }
      });
      this.setState({ tradeLinks: newTradeLinks });
    }
  };

  handleChangeTime = (time, name) => {
    this.setState({ [name]: `${time}:00` });
  };

  handleIntChange = event => {
    const { name, value } = event.target || event;
    if (parseInt(value, 10) || value === '' || value === '0') {
      this.setState({ [name]: value ? parseInt(value, 10) : '' });
    }
  };

  handleTopicClose = id => {
    const filteredTopics = this.state.topicTypes.filter(topic => {
      if (topic.id !== id) {
        return topic;
      }
    });
    this.setState({ topicTypes: filteredTopics });
  };

  handleChangeTopics = value => {
    this.setState({ topicTypes: value });
  };

  validateEmail = email => /\S+@\S+\.\S+/.test(email);
  validateDomain = domain => /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(domain);

  handleSubmit = () => {
    if (this.validate()) {
      const { images, provider, tradeLinks, topicTypes, errors, manager, ...data } = this.state;
      const clearedData = _.pickBy(data, _.identity);
      const clearedProvider = _.pickBy(provider, _.identity);
      clearedProvider.name = clearedData.shortName;
      clearedData.providerVMs = [clearedProvider];
      clearedData.defaultLanguage = this.props.locales.find(
        item => item.language === clearedData.defaultLanguage || item.languageDesc === clearedData.defaultLanguage
      ).language;
      clearedData.topicTypes = topicTypes.map(type => type.id);
      if (!_.isEmpty(tradeLinks)) {
        clearedData.tradeLinks = tradeLinks;
      }
      clearedData.timezone = this.state.timezone;
      clearedData.hideAlpina = this.state.hideAlpina;
      clearedData.hideGlobal = this.state.hideGlobal;
      clearedData.diagnosticId = this.state.diagnosticId;
      clearedData.hasMentors = this.state.hasMentors;
      clearedData.hasIpr = this.state.hasIpr;
      clearedData.hasTelegram = this.state.hasTelegram;
      clearedData.hasBitrix = this.state.hasBitrix;
      clearedData.hasGlobalTheme = this.state.hasGlobalTheme;
      clearedData.hasShop = this.state.hasShop;
      clearedData.defaultRoleRegistration = roles[this.state.defaultRoleRegistration];
      clearedData.disableDeletingFromPlan = true;
      if (this.state.expirationDate) {
        clearedData.expirationDate =
          this.state.expirationDate.length > 10
            ? dayjs(this.state.expirationDate).format('YYYY-MM-DD')
            : this.state.expirationDate;
      }
      if (this.props.company) {
        this.props.editCompanies(clearedData, () => {
          this.props.editCompanyTimezone(clearedData, clearedData.timezone.id, () => {
            this.props.getCompanies();
            this.props.onClose();
          });
        });
      } else {
        this.props.createCompanies(clearedData, () => {
          this.props.getCompanies();
          this.props.onClose();
        });
      }
    }
  };

  handleActivate = () => {
    const { providers, topicTypes, ...data } = this.props.company;
    const clearedData = _.pickBy(data, _.identity);
    clearedData.status = clearedData.status === 'BLOCKED' ? 'ACTIVE' : 'BLOCKED';
    clearedData.topicTypes = topicTypes.map(type => type.id);
    clearedData.disableDeletingFromPlan = true;
    this.props.editCompanies(clearedData, () => {
      this.props.getCompanies();
      this.props.onClose();
    });
  };

  validate = () => {
    const isRequire = [
      'shortName',
      'domain',
      'adminDomain',
      'colour',
      'logo',
      'supportLink',
      'mailFeedback',
      'mailAdmin',
      'usernameAlias',
      'departmentSingleNameImenit',
      'positionSingleNameImenit',
      'myDepartmentButtonName',
      'functionSingleNameImenit',
    ];
    const mail = ['mailFeedback', 'mailAdmin'];
    const domain = ['domain', 'adminDomain'];
    const errors = {};
    const data = { ...this.state, ...this.state.provider };
    isRequire.forEach(key => {
      if (!data[key]) {
        errors[key] = this.props.t('require');
      }
    });
    mail.forEach(key => {
      if (data[key] && !this.validateEmail(data[key])) {
        errors[key] = this.props.t('errorMail');
      }
    });
    domain.forEach(key => {
      if (data[key] && !this.validateDomain(data[key])) {
        errors[key] = this.props.t('errorDomain');
      }
    });
    data.tradeLinks.forEach((item, key) => {
      if (!_.isEmpty(item.name || item.additionalName || item.url)) {
        if (!item.name) {
          errors['tradeLinks_' + key + '_name'] = this.props.t('errorLinkName');
        }
        if (!item.additionalName) {
          errors['tradeLinks_' + key + '_additionalName'] = this.props.t('errorLinkName');
        }
        if (!item.url) {
          errors['tradeLinks_' + key + '_url'] = this.props.t('errorLinkUrl');
        }
      }
    });
    this.setState({ errors });
    return _.isEmpty(errors);
  };

  modalTalentqCancel = () => this.setState({ modalTalentQ: false });

  render() {
    const { company, t } = this.props;
    return (
      <>
        <Modal
          title={!company ? t('createCompany') : t('editCompany')}
          open={this.props.open}
          onCancel={this.props.onClose}
          footer={null}
          width={850}
        >
          <Form className={css['Add-modal-form']} colon={false} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
            {/* Общие настройки */}
            <Settings
              company={this.state}
              handleChange={this.handleChange}
              handleImageChange={this.handlePublicImageChange}
              fileInfo={this.props.fileInfo}
              fileLoadingImage={this.props.fileLoadingImage}
              locales={this.props.locales}
              t={t}
              timezones={this.props.timezones}
              open={this.state.modalTalentQ}
              modalTalentqCancel={this.modalTalentqCancel}
              optionsDiagnostics={optionsDiagnostics.optionsDiagnostics}
            />
            <Divider />

            {/* Разделы и функционал */}
            <Sections
              company={this.state}
              handleChange={this.handleChange}
              handleImageChange={this.handleImageChange}
              fileInfo={this.props.fileInfo}
              fileLoadingImage={this.props.fileLoadingImage}
              handleTopicClose={this.handleTopicClose}
              handleChangeTopics={this.handleChangeTopics}
              topicTypes={this.props.topicTypes}
              handleIntChange={this.handleIntChange}
              t={t}
            />
            <Divider />

            {/* Настройки типов материалов */}
            <Types
              company={this.state}
              handleChangeTime={this.handleChangeTime}
              handleImageChange={this.handleImageChange}
              fileInfo={this.props.fileInfo}
              fileLoadingImage={this.props.fileLoadingImage}
              t={t}
            />
            <Divider />

            {/* Пользователи и ограничения */}
            <Users
              company={this.state}
              handleChange={this.handleChange}
              handleIntChange={this.handleIntChange}
              manager={this.state.manager}
              t={t}
            />
            <Divider />

            {/* Письма */}
            <Mails
              company={this.state}
              handleChange={this.handleChange}
              handleImageChange={this.handlePublicImageChange}
              fileInfo={this.props.fileInfo}
              fileLoadingImage={this.props.fileLoadingImage}
              t={t}
            />
            <Divider />

            {/* Регистрация и авторизация */}
            <Registration company={this.state} handleChange={this.handleChange} t={t} />
            <Divider />

            {/* Единоразово при создании */}
            <Footer company={this.props.company} submit={this.handleSubmit} activate={this.handleActivate} t={t} />
          </Form>
        </Modal>
      </>
    );
  }
}

CreateCompany.propTypes = {
  createFile: PropTypes.func,
  getUsers: PropTypes.func,
  createFileImage: PropTypes.func,
  createFileImagePublic: PropTypes.func,
  fileInfo: PropTypes.object,
  fileLoading: PropTypes.bool,
  fileLoadingImage: PropTypes.bool,
  getFile: PropTypes.func,
  getTopicTypes: PropTypes.func,
  onCancel: PropTypes.func,
  topicTypes: PropTypes.array,
  open: PropTypes.bool,
};

const mapStateToProps = createSelector(
  [filesSelect, selectTopics, selectLocales, selectTimezones],
  (files, topics, { locales }, { timezones }) => ({
    fileInfo: files.info,
    fileLoading: files.isLoading,
    fileLoadingImage: files.isLoadingImage,
    topicTypes: topics.types,
    locales,
    timezones,
  })
);

const mapActionsToProps = {
  getUsers,
  getFile,
  createFile,
  createFileImage,
  createFileImagePublic,
  getTopicTypes,
  createCompanies,
  editCompanies,
  editCompanyTimezone,
  getLocales,
  getTimezones,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('createCompany')(CreateCompany));
