/* eslint-disable */
import {
  CREATE_CFIELD_FAIL,
  CREATE_CFIELD_START,
  CREATE_CFIELD_SUCCESS,
  DELETE_CFIELD_FAIL,
  DELETE_CFIELD_START,
  DELETE_CFIELD_SUCCESS,
  LIST_CFIELD_FAIL,
  LIST_CFIELD_START,
  LIST_CFIELD_SUCCESS,
  SWAP_CFIELD_FAIL,
  SWAP_CFIELD_START,
  SWAP_CFIELD_SUCCESS,
  UPDATE_CFIELD_FAIL,
  UPDATE_CFIELD_START,
  UPDATE_CFIELD_SUCCESS,
} from './constants';

const initialState = {
  fields: [],
  loading: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case LIST_CFIELD_START:
      return { ...state, loading: true, fields: [] };

    case LIST_CFIELD_SUCCESS:
      return { ...state, loading: false, fields: response };

    case LIST_CFIELD_FAIL:
      return { ...state, loading: false, fields: [] };

    case CREATE_CFIELD_START:
      return { ...state, loading: true };

    case CREATE_CFIELD_SUCCESS:
      return { ...state, loading: false };

    case CREATE_CFIELD_FAIL:
      return { ...state, loading: false };

    case UPDATE_CFIELD_START:
      return { ...state, loading: true };

    case UPDATE_CFIELD_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_CFIELD_FAIL:
      return { ...state, loading: false };

    case DELETE_CFIELD_START:
      return { ...state, loading: true };

    case DELETE_CFIELD_SUCCESS:
      return { ...state, loading: false };

    case DELETE_CFIELD_FAIL:
      return { ...state, loading: false };

    case SWAP_CFIELD_START:
      return { ...state, loading: true };

    case SWAP_CFIELD_SUCCESS:
      return { ...state, loading: false };

    case SWAP_CFIELD_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
