/* eslint-disable */
import AppLayout, { appLoader } from '@/pages/AppLayout';
import LoginLayout, { loginChildren, loginLoader } from '@/pages/Login';
import LoginThrough from '@/pages/Login/LoginThrough';
import NotFound from '@/pages/NotFound';
import ServerError from '@/pages/ServerError';

const myRoutes = [
  {
    path: '*',
    ErrorBoundary: ServerError,
    children: [
      { path: 'not-found', Component: NotFound },
      { path: 'server-error', Component: ServerError },
      { path: 'login-through', Component: LoginThrough },
      {
        path: 'user-login',
        loader: loginLoader,
        Component: LoginLayout,
        children: loginChildren,
      },
      {
        path: '*',
        loader: appLoader,
        Component: AppLayout,
      },
    ],
  },
];

export default myRoutes;
