/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getWebinarStats } from '@/store/webinar/actions';

import { selectWebinar } from '@/store/webinar/selectors';

import { Col, Pagination, Row, Select, Table } from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './WebinarStatistic.module.scss';

const WebinarStatistic = ({ topic }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('WebinarStatistic');

  const [options, setOptions] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState(null);
  const { stats, isLoading, pagination } = useSelector(selectWebinar);
  const { Option } = Select;

  const columns = [
    {
      title: t('fullname'),
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: t('department'),
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: t('webinarName'),
      dataIndex: 'webinarName',
      key: 'webinarName',
    },
    {
      title: t('startsAt'),
      dataIndex: 'startsAt',
      key: 'startsAt',
    },
    {
      title: t('endsAt'),
      dataIndex: 'endsAt',
      key: 'endsAt',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('actualInvolvement'),
      dataIndex: 'actualInvolvement',
      key: 'actualInvolvement',
      render: val => val + '%',
    },
    {
      title: t('plannedInvolvement'),
      dataIndex: 'plannedInvolvement',
      key: 'plannedInvolvement',
      render: val => val + '%',
    },
  ];

  useEffect(() => {
    data && dispatch(getWebinarStats(JSON.parse(data)));
  }, [data, dispatch]);

  let dataCols = [];

  useEffect(() => {
    !isLoading &&
      stats &&
      Array.isArray(stats) &&
      stats.length > 0 &&
      stats.forEach(item =>
        dataCols.push({
          key: item.id,
          fullname: item.fullName,
          department: item.department,
          webinarName: item.webinarName,
          startsAt: dayjs(item.startsAt).format('lll'),
          endsAt: dayjs(item.endsAt).format('lll'),
          status: item.passed && item.passed ? t('passed') : t('failed'),
          actualInvolvement: item.actualInvolvement,
          plannedInvolvement: item.plannedInvolvement,
        })
      );

    setDataSource(dataCols);
  }, [stats, isLoading, t]);

  useEffect(() => {
    topic &&
      topic.webinarCreateEventResponse &&
      Array.isArray(topic.webinarCreateEventResponse) &&
      topic.webinarCreateEventResponse.length > 0 &&
      setOptions(
        topic.webinarCreateEventResponse
          .filter(item => item.access === 4)
          .map((item, key) => (
            <Option key={key} value={JSON.stringify({ eventId: item.eventId, from: item.startsAt })}>
              {dayjs(item.startsAt).format('LLL')}
            </Option>
          ))
      );
    setDataSource([]);
  }, [topic]);

  const onChangePage = page => {
    dispatch(getWebinarStats(JSON.parse(data), page - 1));
  };

  return (
    <Row>
      <div className={css['WebinarStatistic_table']}>
        <Col className={css['WebinarStatistic_header']}>
          <div className={css['WebinarStatistic_select_container']}>
            <p>{t('selectDate')}</p>
            <Select
              className={css['WebinarStatistic_select']}
              value={data}
              onSelect={e => setData(e)}
              placeholder={t('chooseDate')}
            >
              {options}
            </Select>
          </div>
        </Col>
        <Table loading={isLoading} columns={columns} dataSource={dataSource} pagination={false} />
        {pagination.totalPages > 1 && (
          <Pagination
            current={pagination.number + 1}
            total={pagination.totalPages}
            pageSize={pagination.size}
            showSizeChanger={false}
            onChange={onChangePage}
          />
        )}
      </div>
    </Row>
  );
};

WebinarStatistic.propTypes = {
  topic: PropTypes.object.isRequired,
};

export default WebinarStatistic;
