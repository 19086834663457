import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearBadgeInfoFilters,
  getAppointBadges,
  saveBadgeInfoFilters,
  saveBadgeInfoSort,
} from '@/store/badge/actions';

import { selectBadge } from '@/store/badge/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import _ from 'lodash';

import css from '../BadgeDetail.module.scss';

const { Option } = Select;

const BadgeFilter = () => {
  const { t } = useTranslation('badgeDetail');
  const dispatch = useDispatch();

  const { badgeId, badgeInfoSort, issueBadge } = useSelector(selectBadge);
  const { department, functionStudent } = useSelector(selectStudents);

  const [data, setData] = useState({
    fullName: '',
    departments: [],
    functions: [],
    addType: undefined,
  });

  const deleteEmpty = arr => {
    let copyData = _.cloneDeep(arr);

    for (let key in copyData) {
      if ((Array.isArray(copyData[key]) && copyData[key].length === 0) || !copyData[key]) {
        delete copyData[key];
      }
    }

    return copyData;
  };

  const renderOption = arr => {
    return (
      arr?.length &&
      arr.map((item, index) => {
        return (
          <Option value={item} key={index}>
            {item}
          </Option>
        );
      })
    );
  };

  const optIssue =
    issueBadge?.length &&
    issueBadge.map(item => {
      return (
        <Option value={item.value} key={item.id}>
          {item.name}
        </Option>
      );
    });

  const tempalateFilters = [
    {
      // первая строка фильтров
      justify: 'start',
      col: [
        {
          // ФИО
          label: t('fullName'),
          value: data.fullName,
          onChange: e => setData({ ...data, fullName: e.target.value }),
          span: 8,
          component: Input,
          placeholder: t('placeholderFIO'),
        },
        {
          // отдел
          label: t('department'),
          value: data.departments,
          onChange: dep => setData({ ...data, departments: dep }),
          span: 8,
          option: renderOption(department),
          component: Select,
          placeholder: t('placeholderDepartment'),
          mode: 'multiple',
        },
        {
          // функция
          label: t('function'),
          value: data.functions,
          onChange: fun => setData({ ...data, functions: fun }),
          span: 8,
          option: renderOption(functionStudent),
          component: Select,
          placeholder: t('placeholderFunction'),
          mode: 'multiple',
        },
      ],
    },
    {
      // вторая строка фильтров
      justify: 'start',
      col: [
        {
          // Тип выдачи
          label: t('addType'),
          value: data.addType,
          onChange: is => setData({ ...data, addType: is }),
          span: 8,
          option: optIssue,
          component: Select,
          placeholder: t('placeholderIssue'),
          optionFilterProp: 'children',
        },
      ],
    },
  ];

  const renderFilters = tempalateFilters.map((item, index) => {
    const customRow = item.col.map((j, index) => {
      const MY_COMP = j.component;
      return (
        <Col span={j.span} key={index}>
          <Form layout='vertical'>
            <Form.Item label={j.label} colon={false} key={`${j.label}+${-index}`}>
              {MY_COMP === Select ? (
                <Select
                  mode={j.mode}
                  key={index}
                  onChange={j.onChange}
                  notFoundContent={t('noData')}
                  value={j.value}
                  showSearch
                  allowClear
                  placeholder={j.placeholder}
                  optionFilterProp={j.optionFilterProp}
                >
                  {j.option}
                </Select>
              ) : (
                <Input key={index} placeholder={j.placeholder} value={data.fullName} onChange={j.onChange} />
              )}
            </Form.Item>
          </Form>
        </Col>
      );
    });

    return (
      <Row justify={item.justify} key={index} gutter={16}>
        {customRow}
      </Row>
    );
  });

  const resetFilters = () => {
    setData({
      fullName: '',
      departments: [],
      functions: [],
      addType: undefined,
    });
    dispatch(clearBadgeInfoFilters());
    dispatch(saveBadgeInfoSort(''));
    dispatch(getAppointBadges(badgeId?.id, {}, 0));
  };

  const onSubmit = () => {
    let sendData = deleteEmpty(data);
    dispatch(saveBadgeInfoFilters(sendData));
    dispatch(getAppointBadges(badgeId?.id, sendData, 0, badgeInfoSort));
  };

  return (
    <div className={css['BadgeFilter']}>
      <div className={css['BadgeFilter-title']}>{t('filterTitle')}</div>
      {renderFilters}
      <Row justify='end' gutter={[16, 0]}>
        <Col>
          <Button onClick={resetFilters}>{t('reset')}</Button>
        </Col>
        <Col>
          <Button onClick={onSubmit} type='primary'>
            {t('apply')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default BadgeFilter;
