/* eslint-disable */
import Quill from 'quill';

const Embed = Quill.import('blots/embed');

class UserKampusEmbed extends Embed {
  static blotName = 'userkampusembed';
  static tagName = 'span';
  static className = 'userkampusembed';

  static create(data) {
    const node = super.create();
    node.setAttribute('keyValue', data.key);
    node.setAttribute('keyPrinted', data.value);
    node.innerHTML = data.value;
    node.setAttribute('contenteditable', false);

    return node;
  }

  static value(node) {
    return {
      key: node.getAttribute('keyValue'),
      value: node.getAttribute('keyPrinted'),
    };
  }
}

export default UserKampusEmbed;
