import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Typography } from 'antd';

import cn from 'classnames';

import style from '../style.module.scss';

const SORT_VALUES = {
  TOPIC_NAME_ASC: 'topicName,asc',
  TOPIC_NAME_DESC: 'topicName,desc',
  GENERAL_STATUS_DESC: 'generalStatus,desc',
};

const Sort = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('inspectors');

  const activeSort = useMemo(() => {
    return searchParams.get('sort');
  }, [searchParams]);

  const setSort = sortValue => {
    const params = new URLSearchParams();

    for (const [key, value] of searchParams.entries()) {
      if (value !== undefined && value?.length) {
        if (value.includes(',')) {
          params.set(key, value.split(','));
        } else {
          params.set(key, value);
        }
      }
    }

    const currentSort = searchParams.get('sort');

    if (currentSort === sortValue) {
      params.delete('sort');
    } else {
      params.set('sort', sortValue);
    }

    setSearchParams(params);
  };

  return (
    <div className={style['sort']}>
      <Typography.Text className={style['sort__caption']}>{t('sort')}</Typography.Text>
      <Typography.Text className={style['sort__element-caption']}>{t('name')}</Typography.Text>
      <Typography.Text
        className={cn(style['sort__element'], {
          [style['sort__element--active']]: activeSort === SORT_VALUES.TOPIC_NAME_ASC,
        })}
        onClick={() => setSort(SORT_VALUES.TOPIC_NAME_ASC)}
      >
        {t('aZ')}
      </Typography.Text>
      <Typography.Text
        className={cn(style['sort__element'], {
          [style['sort__element--active']]: activeSort === SORT_VALUES.TOPIC_NAME_DESC,
        })}
        onClick={() => setSort(SORT_VALUES.TOPIC_NAME_DESC)}
      >
        {t('zA')}
      </Typography.Text>
      <span className={style['sort__vertical-line']}></span>
      <Typography.Text
        className={cn(style['sort__element'], {
          [style['sort__element--active']]: activeSort === SORT_VALUES.GENERAL_STATUS_DESC,
        })}
        onClick={() => setSort(SORT_VALUES.GENERAL_STATUS_DESC)}
      >
        {t('status')}
      </Typography.Text>
    </div>
  );
};

export default Sort;
