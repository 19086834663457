/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { Tooltip } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Tag.module.scss';

export default class Input extends Component {
  static propTypes = {
    noDel: PropTypes.bool,
    noUpper: PropTypes.bool,
    onDelete: PropTypes.func,
    orange: PropTypes.bool,
    short: PropTypes.bool,
    stringTag: PropTypes.bool,
    tag: PropTypes.object,
  };

  static defaultProps = {
    onDelete: () => {},
    tag: {},
  };

  handleTagDelete = event => {
    const { tag, onDelete } = this.props;
    onDelete(event, tag.id);
  };

  render() {
    return (
      <div
        className={classNames(css.Tag, {
          [css.short]: this.props.short,
          [css.stringTag]: this.props.stringTag,
          [css.noDel]: this.props.noDel,
          [css.noUpper]: this.props.noUpper,
          [css.orange]: this.props.orange,
        })}
      >
        {!this.props.noDel && (
          <button className={css['delete-button']} onClick={this.handleTagDelete}>
            <svg xmlns='http://www.w3.org/2000/svg' width='9' height='8' viewBox='0 0 18 17'>
              <path d='m2.143 1.143 13.903 13.903m-.189-13.903L1.954 15.046' stroke='currentColor' fill='none' />
            </svg>
          </button>
        )}
        {this.props.tag.name.length >= 55 ? (
          <Tooltip title={this.props.tag.name || this.props.tag.tag.name}>
            {`${this.props.tag.name?.substr(0, 55)}...` || this.props.tag.tag.name}
          </Tooltip>
        ) : (
          this.props.tag.name || this.props.tag.tag.name
        )}
      </div>
    );
  }
}
