import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Switch, Typography } from 'antd';
import { CompleteMass, SaveFilter } from '@/components/MassSelection/components/Modals';

import style from './style.module.scss';

const Footer = ({
  getCountUsers,
  onMassOk,
  formValuesRef,
  isLoadingMass,
  setSaveFilter,
  countSelectedUsers,
  isInspectors,
  exportUsers,
  isPeriodicAssignment,
  setIsError,
  saveFilter,
  openSave,
  setOpenSave,
}) => {
  const { t } = useTranslation('newMassSelection');

  const [openComplete, setOpenComplete] = useState(false);

  const [withSave, setWithSave] = useState(false);
  const [localSaveFilter, setLocalSaveFilter] = useState(false);

  useEffect(() => {
    if (isPeriodicAssignment) {
      handleSwitch();
    } else {
      handleCloseSave();
    }
  }, [isPeriodicAssignment]);

  const handleSwitch = () => {
    setWithSave(true);
    setOpenSave(true);
  };

  const handleCloseSave = () => {
    setWithSave(false);
    setOpenSave(false);
  };

  const scheduleTraining = () => {
    if (isPeriodicAssignment && formValuesRef.current?.periodInMonths) {
      setOpenComplete(true);
    } else if (isPeriodicAssignment) {
      setIsError(true);
    } else {
      setOpenComplete(true);
      setIsError(false);
    }
  };

  return (
    <div className={style['footer']}>
      <div className={style['footer__switch']}>
        <Switch
          onClick={handleSwitch}
          value={withSave || saveFilter || localSaveFilter}
          disabled={isPeriodicAssignment}
        />
        <Typography.Text>{t('everyDay')}</Typography.Text>
      </div>
      <div className={style['footer__buttons']}>
        <Button size='large' type='primary' onClick={() => setOpenSave(true)}>
          {t('saveFilter')}
        </Button>
        <Button
          disabled={isInspectors.visible && (!isInspectors.inspectors.length || !isInspectors.deadlineDate)}
          onClick={scheduleTraining}
          size='large'
          type='primary'
        >
          {t('addPlan')}
        </Button>
      </div>
      <CompleteMass
        formValuesRef={formValuesRef}
        getCountUsers={getCountUsers}
        onMassOk={onMassOk}
        open={openComplete}
        onClose={() => setOpenComplete(false)}
        isLoadingMass={isLoadingMass}
        countSelectedUsers={countSelectedUsers}
        isInspectors={isInspectors}
        exportUsers={exportUsers}
      />
      <SaveFilter
        formValuesRef={formValuesRef}
        getCountUsers={getCountUsers}
        open={openSave}
        onClose={handleCloseSave}
        withSave={withSave}
        setSaveFilter={setSaveFilter}
        countSelectedUsers={countSelectedUsers}
        exportUsers={exportUsers}
        isPeriodicAssignment={isPeriodicAssignment}
        setLocalSaveFilter={setLocalSaveFilter}
      />
    </div>
  );
};

export default Footer;
