/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';

import { Collapse } from 'antd';

export const CollapseLMS = ({ data, header, extra, children, forceClose, keepOpen = false }) => {
  const [open, setOpen] = useState(keepOpen ? [data.id] : []);

  useEffect(() => {
    setOpen([]);
  }, [forceClose]);

  useEffect(() => {
    setOpen(keepOpen ? [data.id] : [...open]);
  }, [keepOpen, data]);

  const handleClose = useCallback(
    event => {
      if (event.type !== 'change') {
        setOpen(open.length ? [] : [data.id]);
      }
    },
    [open]
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const collapseItems = [
    {
      id: 'blockItem',
      key: data.id,
      label: header,
      extra: extra,
      children: children,
      onChange: handleClose,
    },
  ];

  return <Collapse expandIconPosition='end' activeKey={open} onChange={handleClose} items={collapseItems} />;
};
