import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Input, Modal, Row } from 'antd';

import PropTypes from 'prop-types';

const ModalGroup = ({ open, onCancel, title, onOk, group, onOkText }) => {
  const { t } = useTranslation('CompetenceGroupsDirectory');
  const [form] = Form.useForm();

  useEffect(() => {
    if (group && Object.keys(group)?.length > 0) {
      form.setFieldValue('name', group?.name);
    }
  }, [open, group]);

  return (
    <Modal open={open} onCancel={onCancel} title={title} footer={null}>
      <Form layout='vertical' form={form} onFinish={data => onOk({ name: data?.name.trim() })}>
        <Form.Item
          label={t('groupName')}
          colon={false}
          required
          name='name'
          rules={[
            {
              required: true,
              message: t('groupNameRequired'),
            },
          ]}
        >
          <Input placeholder={t('placeholderCreateGroup')} maxLength={50} showCount />
        </Form.Item>

        <Row justify='end'>
          <Col>
            <Button htmlType='submit' type='primary'>
              {onOkText}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalGroup.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  onOkText: PropTypes.string,
  group: PropTypes.object,
};

export default ModalGroup;
