import _ from 'lodash';

export const deleteEmpty = arr => {
  let copyData = _.cloneDeep(arr);
  for (let key in copyData) {
    if ((Array.isArray(copyData[key]) && copyData[key].length === 0) || !copyData[key]) {
      delete copyData[key];
    }
  }
  return copyData;
};
