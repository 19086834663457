import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeStatusTrack, deleteTrack, postTracksFilters } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';

import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { HiOutlineEmojiHappy, HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import { STATUS_DELETED, TYPE_DELETED, TYPE_UPDATE } from '../constance';

import PropTypes from 'prop-types';

import css from '../../Tracks.module.scss';

const ModalActionTrack = ({ onClose, open, trackId, action, nameTrack }) => {
  const { t } = useTranslation('tracks');
  const dispatch = useDispatch();

  const { tracksFilters, tracksCurrentPage, isChangeStatus } = useSelector(selectTracks);

  const [isDelete, setIsDelete] = useState(false);

  const deleteProduct = () => {
    // из-за долгой операции удаления треков со стади планом мы сначала меняем статус на Deleted,
    // чтобы не вовзвращал нам бэк его и после отправляем запрос на удаление
    const paramsStatus = { status: STATUS_DELETED, type: TYPE_DELETED };
    dispatch(
      changeStatusTrack(trackId, paramsStatus, res => {
        if (res?.processId) {
          dispatch(deleteTrack(trackId, res.processId));
          setIsDelete(true);
        }
      })
    );
  };

  const goToTrack = () => {
    dispatch(postTracksFilters(tracksFilters.filters ? tracksFilters.filters : {}, tracksCurrentPage));
    onClose();
  };

  const content = (
    <>
      {isDelete ? (
        <HiOutlineEmojiHappy color='var(--personalColor)' size={168} />
      ) : (
        <HiOutlineQuestionMarkCircle color='var(--personalColor)' size={168} />
      )}
      <div className={css['ModalAction-isDelete-title']}>{isDelete ? t('modalDeleted') : t('modalIsDelete')}</div>
      <div className={css['ModalAction-isDelete-footer']}>
        {!isDelete ? (
          <>
            <Button onClick={onClose} key='cancel' size='large'>
              {t('cancel')}
            </Button>
            <Button
              className={css['ModalAction-isDelete-footer-delete']}
              onClick={deleteProduct}
              key='delete'
              size='large'
              type='primary'
            >
              {t('delete')}
            </Button>
          </>
        ) : (
          <Button onClick={goToTrack} key='backTracks' size='large' type='primary'>
            {t('backTracks')}
          </Button>
        )}
      </div>
    </>
  );

  const info = (
    <div className={css['ModalAction-update']}>
      <div>
        <LoadingOutlined className={css['ModalAction-update-loading']} />
      </div>
      <div className={css['ModalAction-update-description']}>{t('trackIsUpdate', { name: nameTrack })}</div>
    </div>
  );

  const renderDelete = isChangeStatus ? <Spin spinning={isChangeStatus} size='large' /> : content;

  return (
    <Modal
      className={css['ModalAction']}
      width={600}
      open={open}
      onCancel={onClose}
      footer={null}
      closable={action === TYPE_UPDATE || isDelete}
      maskClosable={action === TYPE_UPDATE || isDelete}
    >
      <div className={css['ModalAction-isDelete']}>
        {action === TYPE_DELETED && renderDelete}
        {action === TYPE_UPDATE && info}
      </div>
    </Modal>
  );
};

ModalActionTrack.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  trackId: PropTypes.number,
  action: PropTypes.string,
  nameTrack: PropTypes.string,
};

export default ModalActionTrack;
