import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearNewsFilters, postNewsFilters, saveNewsFilters, saveSortNews } from '@/store/news/actions';

import { selectNews } from '@/store/news/selectors';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import { STATUS_DELETED, typeOpt } from './constance';

import classNames from 'classnames';

import css from '../News.module.scss';

const { Option } = Select;

const Filters = () => {
  const { t } = useTranslation('news');
  const dispatch = useDispatch();

  const { statuses } = useSelector(selectNews);

  const [filters, setFilters] = useState({
    type: 'TITLE',
    value: undefined,
    status: undefined,
  });
  const [sort, setSort] = useState('title,asc');

  useEffect(() => {
    dispatch(saveNewsFilters(filters));
    dispatch(saveSortNews(sort));
    dispatch(postNewsFilters(filters, 0, sort));
  }, [sort]);

  const resetFilters = () => {
    setFilters({
      type: 'TITLE',
      value: undefined,
      status: undefined,
    });
    setSort('title,asc');
    dispatch(clearNewsFilters());
    dispatch(saveSortNews('title,asc'));
    dispatch(postNewsFilters({}, 0, sort));
  };

  const onSubmitFilters = () => {
    dispatch(saveNewsFilters(filters));
    dispatch(postNewsFilters(filters, 0, sort));
  };

  const optionsType = () => {
    let opt = [];
    let arr = typeOpt(t);

    if (arr.length > 0) {
      opt = arr.map(item => (
        <Option key={item.id} value={item.type}>
          {item.name}
        </Option>
      ));
    }

    return opt;
  };

  const sortDate = () => {
    setSort('createdDate,desc');
  };

  const sortLastName = value => {
    setSort(value);
  };

  const optionsStatus =
    statuses?.length &&
    statuses
      .filter(o => o.status !== STATUS_DELETED)
      .map((item, index) => {
        return (
          <Option key={index} value={item.status}>
            {item.text}
          </Option>
        );
      });

  const selectBefore = (
    <Select
      className={css['Filters-block-selectBefore']}
      value={filters.type}
      allowClear
      showSearch
      onChange={e => setFilters({ ...filters, type: e })}
      optionFilterProp='children'
      placeholder={t('placeholderSelect')}
    >
      {optionsType()}
    </Select>
  );

  const sortBlock = (
    <div className={css['Filters-sort']}>
      <span className={css['Filters-sort-text']}>{t('sort')}</span>
      <span>{t('sortLastName')}</span>
      <span
        className={classNames(
          css['Filters-sort-action'],
          css['Filters-sort-action-az'],
          sort === 'title,asc' && css['Filters-sort-active']
        )}
        onClick={() => sortLastName(`title,asc`)}
      >
        {t('aZ')}
      </span>
      <span
        className={classNames(css['Filters-sort-action'], sort === 'title,desc' && css['Filters-sort-active'])}
        onClick={() => sortLastName(`title,desc`)}
      >
        {t('zA')}
      </span>

      <span className={css['Filters-sort-vertical']}></span>
      <span
        className={classNames(css['Filters-sort-action'], sort === 'createdDate,desc' && css['Filters-sort-active'])}
        onClick={() => sortDate()}
      >
        {t('sortDate')}
      </span>
    </div>
  );

  return (
    <>
      <div className={css['Filters']}>
        <div className={css['Filters-buttons']}>
          <span className={css['Filters-buttons-filters']}>{t('filters')}</span>
          <Button type='link' onClick={resetFilters} className={css['Filters-buttons-reset']}>
            {t('reset')}
          </Button>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={18}>
            <Form layout='vertical'>
              <Form.Item label={t('type')} colon={false}>
                <Input
                  addonBefore={selectBefore}
                  value={filters.value}
                  onChange={e => setFilters({ ...filters, value: e?.target?.value })}
                  placeholder={t('placeholderName')}
                  maxLength={500}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={6}>
            <Form layout='vertical'>
              <Form.Item label={t('status')} colon={false}>
                <Select
                  className={css['Filters-block-select']}
                  value={filters.status}
                  allowClear
                  showSearch
                  onChange={e => setFilters({ ...filters, status: e })}
                  optionFilterProp='children'
                  placeholder={t('placeholderStatus')}
                >
                  {optionsStatus}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <div className={css['Filters-apply']}>
          <Button onClick={onSubmitFilters} type='primary'>
            {t('apply')}
          </Button>
        </div>
      </div>
      {sortBlock}
    </>
  );
};

export default Filters;
