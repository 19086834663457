export const D360_CREATE_FILTERS_START = 'D360_CREATE_FILTERS_START';
export const D360_CREATE_FILTERS_SUCCESS = 'D360_CREATE_FILTERS_SUCCESS';
export const D360_CREATE_FILTERS_FAIL = 'D360_CREATE_FILTERS_FAIL';

export const D360_GET_FILTERTIPS_START = 'D360_GET_FILTERTIPS_START';
export const D360_GET_FILTERTIPS_SUCCESS = 'D360_GET_FILTERTIPS_SUCCESS';
export const D360_GET_FILTERTIPS_FAIL = 'D360_GET_FILTERTIPS_FAIL';

export const D360_GET_FILTERUSERS_START = 'D360_GET_FILTERUSERS_START';
export const D360_GET_FILTERUSERS_SUCCESS = 'D360_GET_FILTERUSERS_SUCCESS';
export const D360_GET_FILTERUSERS_FAIL = 'D360_GET_FILTERUSERS_FAIL';

export const D360_GET_FILTERCOMPETENCIES_START = 'D360_GET_FILTERCOMPETENCIES_START';
export const D360_GET_FILTERCOMPETENCIES_SUCCESS = 'D360_GET_FILTERCOMPETENCIES_SUCCESS';
export const D360_GET_FILTERCOMPETENCIES_FAIL = 'D360_GET_FILTERCOMPETENCIES_FAIL';

export const D360_GET_FILTERSKILLS_START = 'D360_GET_FILTERSKILLS_START';
export const D360_GET_FILTERSKILLS_SUCCESS = 'D360_GET_FILTERSKILLS_SUCCESS';
export const D360_GET_FILTERSKILLS_FAIL = 'D360_GET_FILTERSKILLS_FAIL';
