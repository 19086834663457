/* eslint-disable */
import React, { Component } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './DayCard.module.scss';

export default class DayCard extends Component {
  static propTypes = {
    disabledSelect: PropTypes.bool,
    displayFormat: PropTypes.string,
    isCurrent: PropTypes.bool,
    isPast: PropTypes.bool,
    onClick: PropTypes.func,
    outputFormat: PropTypes.string,
    selectedDate: PropTypes.array,
    open: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDay: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({
        selectedDay: dayjs(this.props.selectedDate.toString()).format('D'),
      });
    }
  }

  isSelectedMonthIsCurrent() {
    dayjs(this.state.selectedMonths.toString()).format('M') === dayjs().format('M');
  }

  getDaysCountByDate() {
    const _date = new Date(this.props.selectedDate);
    const _year = _date.getFullYear();
    const _month = _date.getMonth() + 1;

    return new Date(_year, _month, 0).getDate();
  }

  onDayClick(el, day) {
    this.setState({ selectedDay: day });
    if (this.props.onClick) {
      const _date = new Date(this.props.selectedDate);
      const _year = _date.getFullYear();
      const _month = _date.getMonth() + 1;

      const selectedDate = dayjs(new Date(_year + '-' + _month + '-' + day)).format('YYYY-MM-DD');
      this.props.onClick(selectedDate);
    }
  }

  render() {
    const days = this.getDaysCountByDate();
    let content = [];

    if (!isNaN(days) && days > 0 && days < 32) {
      const isCurrentDateIsSelected =
        dayjs(this.props.selectedDate.toString()).format('YYYYMM') === dayjs().format('YYYYMM');

      for (let day = 1; day <= days; day++) {
        const beforeDate =
          isCurrentDateIsSelected &&
          dayjs(dayjs().set('date', day).format('YYYY-MM-DD')).isBefore(dayjs().format('YYYY-MM-DD'));

        const currectSelected =
          this.state.selectedDay == day ? classNames(css.DayListItem, css.DayListItemActive) : css.DayListItem;

        content.push(
          <div
            key={day}
            className={beforeDate ? classNames(css.DayListItem, css.DayListItemDisable) : currectSelected}
            onClick={e => {
              this.onDayClick(e.target, day);
            }}
          >
            {day}
          </div>
        );
      }
    }

    const isVisible = this.props.open ? 'flex' : 'none';
    return <div className={css.DayList}>{content}</div>;
  }
}
