/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_FUNCTIONS_START = 'GET_FUNCTIONS_START';
export const GET_FUNCTIONS_SUCCESS = 'GET_FUNCTIONS_SUCCESS';
export const GET_FUNCTIONS_FAIL = 'GET_FUNCTIONS_FAIL';
export const GET_GLOBAL_FUNCTIONS_START = 'GET_GLOBAL_FUNCTIONS_START';
export const GET_GLOBAL_FUNCTIONS_SUCCESS = 'GET_GLOBAL_FUNCTIONS_SUCCESS';
export const GET_GLOBAL_FUNCTIONS_FAIL = 'GET_GLOBAL_FUNCTIONS_FAIL';
export const CREATE_FUNCTION_START = 'CREATE_FUNCTION_START';
export const CREATE_FUNCTION_SUCCESS = 'CREATE_FUNCTION_SUCCESS';
export const CREATE_FUNCTION_FAIL = 'CREATE_FUNCTION_FAIL';
export const IMPORT_FUNCTION_START = 'IMPORT_FUNCTION_START';
export const IMPORT_FUNCTION_SUCCESS = 'IMPORT_FUNCTION_SUCCESS';
export const IMPORT_FUNCTION_FAIL = 'IMPORT_FUNCTION_FAIL';
export const DELETE_FUNCTION_START = 'DELETE_FUNCTION_START';
export const DELETE_FUNCTION_SUCCESS = 'DELETE_FUNCTION_SUCCESS';
export const DELETE_FUNCTION_FAIL = 'DELETE_FUNCTION_FAIL';
export const EDIT_FUNCTION_START = 'EDIT_FUNCTION_START';
export const EDIT_FUNCTION_SUCCESS = 'EDIT_FUNCTION_SUCCESS';
export const EDIT_FUNCTION_FAIL = 'EDIT_FUNCTION_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getFunctions = (name = '', page = 0, onSuccess, isGlobal = false, params = {}) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/functionInternal${getQueryString({
    name: name,
    isGlobal,
    isSharing: isGlobal ? '' : false,
    sort: 'name,asc',
    page,
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FUNCTIONS_START, GET_FUNCTIONS_SUCCESS, GET_FUNCTIONS_FAIL],
  onSuccess,
});

export const getFunctionsGlobal = (name = '', page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/functionInternal${getQueryString({
    name: name,
    isGlobal: true,
    isSharing: true,
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GLOBAL_FUNCTIONS_START, GET_GLOBAL_FUNCTIONS_SUCCESS, GET_GLOBAL_FUNCTIONS_FAIL],
  onSuccess,
});

export const createFunction = (data, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/functionInternal`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [CREATE_FUNCTION_START, CREATE_FUNCTION_SUCCESS, CREATE_FUNCTION_FAIL],
});

export const importCSV = (file, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/functionInternal/import`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  types: [IMPORT_FUNCTION_START, IMPORT_FUNCTION_SUCCESS, IMPORT_FUNCTION_FAIL],
});

export const deleteFunction = (id, { onSuccess, onError }) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/functionInternal${getQueryString({ id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_FUNCTION_START, DELETE_FUNCTION_SUCCESS, DELETE_FUNCTION_FAIL],
});

export const editFunction = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/functionInternal${getQueryString({ id: data.id })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [EDIT_FUNCTION_START, EDIT_FUNCTION_SUCCESS, EDIT_FUNCTION_FAIL],
});
