/* eslint-disable */
import React, { useRef, useState } from 'react';
// import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, redirect, useNavigate } from 'react-router-dom';

import { getPublicFile } from '@/store/files/actions';
import { login, updateLoginEmpty } from '@/store/login/actions';

import { selectLogin } from '@/store/login/selectors';

import { Flex, Spin } from 'antd';

import AuthorizationPage from './AuthorizationPage';
import AuthorizationPageFail from './AuthorizationPageFail';
import LoginFailure from './LoginFailure';
import LoginOauth from './LoginOauth';
import OAUTHSuccess from './OAUTHSuccess';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';

import store from '@/store';
import classNames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';

import css from './Login.module.scss';

import { ACCESS_TO_ADMIN_FROM_USERS } from '@/constants/permissions';
import loginImage from '@/assets/images/login_image.png';
import loginImageAbsolut from '@/assets/images/loginImageAbsolut.png';
import loginImageMob from '@/assets/images/loginImageMobBooks_255x180.svg';
import logoDefault from '@/assets/images/logo_default.png';

const getCurrentProvider = company => {
  let currentCompany = company.providers.find(provider => provider.type === 'OAUTH');
  if (currentCompany) return currentCompany;

  if (!company?.hasIpRestriction) {
    return company.providers.find(provider => !provider.external);
  }

  return company.providers.find(provider => provider.external);
};

const ABSOLUTE_ID = 5451;

const isDevMode =
  window.location.origin === import.meta.env?.PUBLIC_APP_API_BASE || window.location.hostname === 'localhost';

const loaderAuthorizationPage = async () => {
  if (store.getState().login?.company) {
    const currentProvider = getCurrentProvider(store.getState().login.company);
    if (currentProvider.type === 'OAUTH' && !isDevMode) {
      return redirect('/user-login/oauth');
    }
  }
  return null;
};

const loaderOAUTHAuthorizationPage = async () => {
  if (store.getState().login?.company) {
    const currentProvider = getCurrentProvider(store.getState().login?.company);

    if (currentProvider.type !== 'OAUTH' && !isDevMode) {
      return redirect('/user-login');
    }
  }

  return null;
};

export const loginChildren = [
  {
    index: true,
    Component: AuthorizationPage,
    loader: loaderAuthorizationPage,
  },
  {
    path: 'auth',
    Component: AuthorizationPage,
    loader: loaderAuthorizationPage,
  },
  {
    path: 'oauth',
    Component: document.location.search.includes('root') ? AuthorizationPage : LoginOauth,
    loader: loaderOAUTHAuthorizationPage,
  },
  {
    path: 'success',
    Component: OAUTHSuccess,
    loader: loaderOAUTHAuthorizationPage,
  },
  {
    path: 'auth-fail',
    Component: AuthorizationPageFail,
    // loader: loaderAuthorizationPage,
  },
  {
    path: 'failure',
    Component: LoginFailure,
    // loader: loaderAuthorizationPage,
  },
  {
    path: '*',
    loader: async () => {
      if (store.getState().login?.company) {
        const currentProvider = getCurrentProvider(store.getState().login.company);

        if (currentProvider.type === 'OAUTH') {
          redirect('/user-login/oauth');
        }
      }
      return redirect('/user-login');
    },
  },
];

export const loginLoader = async ({ request }) => {
  const hasToken = localStorage.getItem('authorizationToken');

  if (hasToken || store.getState().users.currentUser) {
    return redirect('/');
  }

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const registerParam = searchParams.get('register');

  if (registerParam) {
    searchParams.delete('register');
    return redirect(`/user-login/registration-page-data?token=${registerParam}`);
  }

  const restoreParam = searchParams.get('restore');

  if (restoreParam) {
    searchParams.delete('restore');
    return redirect(`/user-login/recovery-page-data?token=${restoreParam}`);
  }

  return null;
};

export default function LoginLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const captchaDemo = useRef(null);

  const [isEntering, setEntering] = useState(false);
  const { company } = useSelector(selectLogin);

  const isAbsolute = company?.id === ABSOLUTE_ID;

  const [state, changeState] = useState({
    password: '',
    username: '',
    readInfo: false,
    render: true,
    verifyCaptcha: false,
    isAuthStarted: JSON.parse(localStorage.getItem('isAuthStarted')),
    typeRecaptcha: null,
  });

  const setState = item => changeState({ ...state, ...item });

  const toLogin = (password, username, verifyCaptcha) => {
    setEntering(true);
    dispatch(
      login(
        password,
        username,
        verifyCaptcha,
        response => {
          localStorage.setItem('authorizationToken', response.accessToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('expiresIn', response.expiresIn);
          localStorage.setItem('expiresTime', response.expiresIn * 1000 + Date.now());

          if (username === 'admin') {
            localStorage.setItem('isDefaultAdmin', true);
            localStorage.setItem('authorities', response.authorities);
          }

          if (response?.authorities.includes(ACCESS_TO_ADMIN_FROM_USERS)) {
            const redirect = localStorage.getItem('currentRedirect') ? localStorage.getItem('currentRedirect') : '/';
            navigate(redirect);
          } else {
            setEntering(false);
          }
        },
        () => setEntering(false)
      )
    );
  };

  const loginPageImage = () => {
    switch (company?.id) {
      case ABSOLUTE_ID:
        return loginImageAbsolut;

      default:
        return isMobile ? loginImageMob : loginImage;
    }
  };

  const onLoadRecaptcha = () => {
    if (captchaDemo.current) {
      if (window.grecaptcha) {
        // костыль, т.к. у невидимой капчи нет события по закрытию фрейма с картинками
        const callback = mutationsList => {
          if (!state.verifyCaptcha) {
            return;
          }
          for (const mutation of mutationsList) {
            const isIframe = includes(
              get(mutation, 'target.children[1].children[0].src', ''),
              'https://www.google.com/recaptcha/api2/bframe'
            );
            const oldVisible = includes(get(mutation, 'oldValue', ''), 'visibility: visible');
            const newVisible = get(mutation, 'target.style.visibility', '') === 'hidden';
            if (isIframe && oldVisible && newVisible) {
              setState({ verifyCaptcha: false });
              return;
            }
          }
        };

        const observer = new MutationObserver(callback);
        observer.observe(document.body, {
          attributes: true,
          attributeOldValue: true,
          subtree: true,
        });
      }
      captchaDemo.current.reset();
      captchaDemo.current.execute();
    }
  };

  const verifyCallback = recaptchaToken => {
    switch (state.typeRecaptcha && state.typeRecaptcha.type) {
      case 'login':
        toLogin(state.typeRecaptcha.password, state.typeRecaptcha.username, recaptchaToken);
        setState({ verifyCaptcha: false });
        break;
      default:
        break;
    }
  };

  const loginUserRecaptcha = (password, username) => {
    setState({ verifyCaptcha: true });
    setState({
      typeRecaptcha: { type: 'login', password, username },
    });
    localStorage.setItem('isAuthStarted', 'true');
    if (!company.providers[0].external && company.hasCaptcha) {
      // loadReCaptcha();
      captchaDemo.current.reset();
      captchaDemo.current.execute();
    } else {
      toLogin(password, username);
      setState({ verifyCaptcha: false });
    }
  };

  const captcha = el => {
    captchaDemo.current = el;
  };

  const [imageLogo, isLoadingImageLogo] = useLocalFileByUUID(company.logo, {
    publish: true,
    defaultImg: logoDefault,
  });

  const [imageBackgroundLogin, isLoadingBackgroundLogin] = useLocalFileByUUID(company.background, {
    publish: true,
    isDefaultNull: true,
  });

  const [isMobile] = useIsMobile(769);

  return (
    <Spin spinning={isEntering} size='large' className={css['LoginPage']}>
      {company.hasCaptcha && (
        <div className={css['LoginPage-captcha']}>
          {/* <ReCaptcha
            ref={captcha}
            size='invisible'
            hl='ru'
            render='explicit'
            sitekey='6Lfd7LMUAAAAAP8O6maJjJj4c09wPyk6INImt9-i'
            onloadCallback={onLoadRecaptcha}
            verifyCallback={verifyCallback}
          /> */}
        </div>
      )}
      <>
        <Flex
          vertical={isMobile}
          align='center'
          justify='center'
          gap={!isAbsolute ? 20 : 0}
          className={css.LoginContent}
        >
          <Flex
            vertical
            align='center'
            justify='center'
            className={classNames(css['LoginPage-card'], {
              [css['LoginPage-card-abs']]: isAbsolute,
            })}
          >
            <Outlet
              context={{
                getPublicFile: getPublicFile,
                isAuthStarted: state.isAuthStarted,
                navigate: navigate,
                company: company,
                login: login,
                updateLoginEmpty: updateLoginEmpty,
                setEntering: setEntering,

                loginUserRecaptcha,
                imageBackgroundLogin,
                isLoadingBackgroundLogin,
                imageLogo,
                isLoadingImageLogo,
              }}
            />
          </Flex>
          <Flex className={css['LoginPage-image-box']} align='center' justify='center'>
            <img
              className={classNames(css['LoginPage-image'], {
                [css['LoginPage-image-abs']]: isAbsolute,
              })}
              src={loginPageImage()}
              alt='login'
            />
          </Flex>
        </Flex>
      </>
    </Spin>
  );
}
