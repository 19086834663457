/* eslint-disable */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { redirect, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { getFileSizes } from '@/store/files/actions';
import { logoutStart } from '@/store/login/actions';
import { getAdmin, getUser } from '@/store/users/actions';

import { selectLogin } from '@/store/login/selectors';
import { selectNotifications } from '@/store/notifications/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Layout } from 'antd';
import Header from '@/pages/Header';
import Sider from '@/pages/Sider';

import NotFound from '../NotFound';

import { clearStorage } from '@/utils/clearStorage';
import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import adminStorage from '@shared/services/admin-storage';
import { changeLanguage } from '@shared/utils/changeLanguage';
import { goToLoginPage } from '@shared/utils/location';

import allChildren, { accessSettings } from '@/router/routes';
import store from '@/store';
import dayjs from 'dayjs';
import i18n from 'i18next';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import logoDefault from '@/assets/images/logo_default.png';

import '@shared/controllers/registerAllCharts.js';

import Utils from '@/Utils';

import css from './appLayout.module.scss';

const SEVERSTAL_COMPANY_ID = 6953;

const { Content } = Layout;

export const appLoader = async () => {
  const hasToken = localStorage.getItem('authorizationToken');
  const isDefaultAdmin = localStorage.getItem('isDefaultAdmin');
  const isDefaultAdminAuthorities = localStorage.getItem('authorities');

  if (hasToken && !store.getState().users.currentUser) {
    await store.dispatch(
      isDefaultAdmin
        ? getAdmin(isDefaultAdminAuthorities || [])
        : getUser(
            response => {
              if (Utils.isAdminOrSuperAdmin(response) && document.querySelector('#jsd-widget')?.style) {
                document.querySelector('#jsd-widget').style.opacity = '1';
              }

              changeLanguage(i18n, dayjs, response.language);
            },
            error => {
              if (error.status === 401) clearStorage();
              return null;
            }
          )
    );
  } else if (!hasToken) {
    return redirect('/user-login/');
  }

  return null;
};

const AppLayout = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notifications = useSelector(selectNotifications);
  const { currentUser } = useSelector(selectUsers);
  const { company } = useSelector(selectLogin);

  const [currentAppRoutes, setCurrentAppRoutes] = useState([]);

  const [imageLogo] = useLocalFileByUUID(company.logo, {
    publish: true,
    defaultImg: logoDefault,
    isDefaultNull: true,
  });

  const addScript = ({ src, id, onLoad }) => {
    const existing = document.getElementById(id);
    if (existing) {
      return existing;
    } else {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        if (onLoad) {
          onLoad();
        }
      };
      script.onerror = () => console.log('Error Jiva');

      document.head.appendChild(script);
      return script;
    }
  };

  const createNotification = ({ type, message }) => {
    switch (type) {
      case 'success':
        return NotificationManager.success(message);
      case 'warning':
        return NotificationManager.warning(message);
      case 'error':
        return NotificationManager.error(message);
      default:
        return NotificationManager.info(message);
    }
  };

  const handleLogout = () => {
    if (adminStorage.isAuth) {
      dispatch(logoutStart());
    } else {
      adminStorage.removeData();
      goToLoginPage();
    }
  };

  useEffect(() => {
    if (currentUser?.domainCompany?.id === SEVERSTAL_COMPANY_ID) {
      addScript({
        src: `//code.jivo.ru/widget/OGfsNzLK9b`,
        id: 'severstal-script',
        onLoad: () => console.log('SeverStal Jivo script loaded!'),
      });
    }
  }, [currentUser?.domainCompany?.id]);

  const flatAccessArrayRoutes = arrayRoutes => {
    let bufArray = [];

    const flattenRoutes = routes => {
      routes.forEach(({ roles, path, Component, children }) => {
        if (currentUser?.authorities?.includes(roles) && path) {
          if (path !== '/coin-settings' || currentUser.domainCompany.hasCoins) {
            bufArray.push({ path, Component, key: path });
          }
        }

        if (children) {
          flattenRoutes(children);
        }
      });
    };

    if (localStorage.getItem('isDefaultAdmin')) {
      bufArray.push({ path: '/', Component: accessSettings.Component, key: 'isAdminRoot' });
    }

    flattenRoutes(arrayRoutes);

    return bufArray;
  };

  useLayoutEffect(() => {
    setCurrentAppRoutes(flatAccessArrayRoutes(allChildren));
  }, []);

  useEffect(() => {
    if (!adminStorage.isAuth) {
      if (location.pathname.indexOf('user-login') === -1) {
        navigate('/user-login');
      }
      return;
    }

    dispatch(getFileSizes());
  }, []);

  useEffect(() => {
    if (notifications.type || notifications.message) {
      createNotification(
        {
          type: notifications.type,
          message: t(`notifications:${notifications.message}`, {
            data: notifications.data,
          }),
        } || {}
      );
    }
  }, [notifications]);

  return (
    <Layout hasSider>
      <Sider imageLogo={imageLogo} pathname={location.pathname} currentUser={currentUser} />
      <Layout>
        <Header onLogout={handleLogout} />
        <Content className={css['content']}>
          <Routes>
            {currentAppRoutes.map(({ key, ...route }) => {
              return <Route key={key} {...route} />;
            })}
            <Route path='/*' Component={NotFound} />
          </Routes>
          <NotificationContainer />
        </Content>
      </Layout>
    </Layout>
  );
};

AppLayout.propTypes = {
  currentUser: PropTypes.object,
  getAdmin: PropTypes.func,
  getRoles: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  logout: PropTypes.func,
  notifications: PropTypes.object,
  refreshLogin: PropTypes.func,
  roles: PropTypes.object,
  imageLogo: PropTypes.any,
  getFileSizes: PropTypes.func,
};

export default AppLayout;
