// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-ImageBlock-ImageTrack-module__ql-picker-WtLi2C.admin-src-components-ImageBlock-ImageTrack-module__ql-font-K0r_H_ .admin-src-components-ImageBlock-ImageTrack-module__ql-picker-label-D7zvdI[data-value="Montserrat"]:before, .admin-src-components-ImageBlock-ImageTrack-module__ql-picker-WtLi2C.admin-src-components-ImageBlock-ImageTrack-module__ql-font-K0r_H_ .admin-src-components-ImageBlock-ImageTrack-module__ql-picker-item-nl_ZCe[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-ImageBlock-ImageTrack-module__ql-font-Montserrat-aos3Ef {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-nuWh8A {
  object-fit: contain;
  cursor: pointer;
  width: 108px;
  height: 108px;
}

.admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-noPhoto-0MHwpM {
  font-size: 56px;
}

.admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-badge-S06w8t {
  object-fit: cover;
  border-radius: 50%;
  width: 88px;
  height: 88px;
}

.admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-image-_OCIVe {
  width: 100%;
  max-width: 500px;
}

.admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-onOk-TLaLh6 {
  background-color: var(--personalColor) !important;
  border-color: var(--personalColor) !important;
  color: #fff !important;
}

.admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-onOk-TLaLh6:hover {
  background-color: var(--personalColorDark) !important;
  border-color: var(--personalColorDark) !important;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/ImageBlock/ImageTrack.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAIA;EADE,WAAW;EACX,gBAAgB;AAClB;;AAGA;EAAE,iDAAiD;EACjD,6CAA6C;EAC7C,sBAAsB;AACxB;;AAEA;EACE,qDAAqD;EACrD,iDAAiD;AACnD","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ImageBlock-photo {\n  height: 108px;\n  width: 108px;\n  object-fit: contain;\n  cursor: pointer;\n}\n.ImageBlock-photo-noPhoto {\n  font-size: 56px;\n}\n.ImageBlock-photo-badge {\n  width: 88px;\n  height: 88px;\n  border-radius: 50%;\n  object-fit: cover;\n}\n.ImageBlock-modal-image {\n  max-width: 500px;\n  width: 100%;\n}\n.ImageBlock-modal-onOk {\n  background-color: var(--personalColor) !important;\n  border-color: var(--personalColor) !important;\n  color: #fff !important;\n}\n.ImageBlock-modal-onOk:hover {\n  background-color: var(--personalColorDark) !important;\n  border-color: var(--personalColorDark) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-ImageBlock-ImageTrack-module__ql-picker-WtLi2C`,
	"qlPicker": `admin-src-components-ImageBlock-ImageTrack-module__ql-picker-WtLi2C`,
	"ql-font": `admin-src-components-ImageBlock-ImageTrack-module__ql-font-K0r_H_`,
	"qlFont": `admin-src-components-ImageBlock-ImageTrack-module__ql-font-K0r_H_`,
	"ql-picker-label": `admin-src-components-ImageBlock-ImageTrack-module__ql-picker-label-D7zvdI`,
	"qlPickerLabel": `admin-src-components-ImageBlock-ImageTrack-module__ql-picker-label-D7zvdI`,
	"ql-picker-item": `admin-src-components-ImageBlock-ImageTrack-module__ql-picker-item-nl_ZCe`,
	"qlPickerItem": `admin-src-components-ImageBlock-ImageTrack-module__ql-picker-item-nl_ZCe`,
	"ql-font-Montserrat": `admin-src-components-ImageBlock-ImageTrack-module__ql-font-Montserrat-aos3Ef`,
	"qlFontMontserrat": `admin-src-components-ImageBlock-ImageTrack-module__ql-font-Montserrat-aos3Ef`,
	"ImageBlock-photo": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-nuWh8A`,
	"imageBlockPhoto": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-nuWh8A`,
	"ImageBlock-photo-noPhoto": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-noPhoto-0MHwpM`,
	"imageBlockPhotoNoPhoto": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-noPhoto-0MHwpM`,
	"ImageBlock-photo-badge": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-badge-S06w8t`,
	"imageBlockPhotoBadge": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-photo-badge-S06w8t`,
	"ImageBlock-modal-image": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-image-_OCIVe`,
	"imageBlockModalImage": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-image-_OCIVe`,
	"ImageBlock-modal-onOk": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-onOk-TLaLh6`,
	"imageBlockModalOnOk": `admin-src-components-ImageBlock-ImageTrack-module__ImageBlock-modal-onOk-TLaLh6`
};
export default ___CSS_LOADER_EXPORT___;
