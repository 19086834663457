/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProfileCustomFieldList } from '@/store/pcfields/actions';

import { selectFields } from '@/store/pcfields/selectors';
import { selectSettingsVisibility } from '@/store/settings_visibility/selectors';

import { Collapse, Typography } from 'antd';
import { FiPlus } from 'react-icons/fi';

import Filter from './parts/Filter';

import PropTypes from 'prop-types';

import css from './CustomFieldFilter.module.scss';

const { Title, Text } = Typography;

function CustomFieldFilter({ state, onChange, visibleCustomModal, noPanel }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileCustomFieldList(false, true));
  }, [visibleCustomModal]);

  const { fields } = useSelector(selectFields);
  const { settingsVisibility } = useSelector(selectSettingsVisibility);

  const { t } = useTranslation('massSelection');

  const addFilter = () => {
    onChange(null, 'addCustomField', {});
  };

  const headerCollapse = <Title level={4}>{t('recordCustomField')}</Title>;

  const filterCustomField = (
    <>
      {state &&
        state.length &&
        state.map((filter, index) => (
          <Filter key={index} id={index} state={state[index]} onChange={onChange} customFieldLength={state.length} />
        ))}
      <Text className={css['Filter__add-filter']} onClick={addFilter}>
        <FiPlus size={24} color={'var(--personalColor)'} />
        {t('addFilter')}
      </Text>
    </>
  );

  const items = [
    {
      key: '1',
      label: headerCollapse,
      children: filterCustomField,
    },
  ];
  return (
    <>
      {fields.length !== 0 &&
        (noPanel ? (
          filterCustomField
        ) : (
          <Collapse
            className={css['CustomFieldFilter__main']}
            expandIconPosition='end'
            defaultActiveKey={
              settingsVisibility?.customFields && settingsVisibility.customFields.length > 0 ? ['1'] : ''
            }
            items={items}
          />
        ))}
    </>
  );
}

CustomFieldFilter.propTypes = {
  state: PropTypes.any,
  onChange: PropTypes.func,
  visibleCustomModal: PropTypes.bool,
  noPanel: PropTypes.bool,
};

export default CustomFieldFilter;
