/* eslint-disable */
import { middlewares } from '@/store/middlewares';
import { rootReducer } from '@/store/root-reducer';
import { rootSaga } from '@/store/root-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

const isDevelopment = () => import.meta.env.DEV;

const composeEnhancers =
  isDevelopment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(applyMiddleware(...middlewares, sagaMiddleware));

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
