export const GET_SETTINGSTQ_START = 'GET_SETTINGSTQ_START';
export const GET_SETTINGSTQ_SUCCESS = 'GET_SETTINGSTQ_SUCCESS';
export const GET_SETTINGSTQ_FAIL = 'GET_SETTINGSTQ_FAIL';

export const POST_SETTINGSTQ_START = 'POST_SETTINGSTQ_START';
export const POST_SETTINGSTQ_SUCCESS = 'POST_SETTINGSTQ_SUCCESS';
export const POST_SETTINGSTQ_FAIL = 'POST_SETTINGSTQ_FAIL';

export const PUT_SETTINGSTQ_START = 'PUT_SETTINGSTQ_START';
export const PUT_SETTINGSTQ_SUCCESS = 'PUT_SETTINGSTQ_SUCCESS';
export const PUT_SETTINGSTQ_FAIL = 'PUT_SETTINGSTQ_FAIL';

export const GET_REPORT_PDF_START = 'GET_REPORT_PDF_START';
export const GET_REPORT_PDF_SUCCESS = 'GET_REPORT_PDF_SUCCESS';
export const GET_REPORT_PDF_FAIL = 'GET_REPORT_PDF_FAIL';

export const GET_SCORE_START = 'GET_SCORE_START';
export const GET_SCORE_SUCCESS = 'GET_SCORE_SUCCESS';
export const GET_SCORE_FAIL = 'GET_SCORE_FAIL';
