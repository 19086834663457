import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { createChatGroupMentor, deleteChatGroupByRequestId } from '@/store/chat/actions';

import { Button, Divider, Input, Modal } from 'antd';
import Rate from '@/components/Rate';
import { HiOutlineExclamationCircle, HiOutlineThumbUp } from 'react-icons/hi';

import { GRADE, GRATITUDE, IN_ACTIVE, IN_FINISHED, IN_REJECT, REJECTED_MENTOR, REQUEST_EXPERIENCE } from '../constance';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../../Mentee.module.scss';

const { TextArea } = Input;

const BTN_CLOSE = [REJECTED_MENTOR, GRATITUDE, REQUEST_EXPERIENCE, GRADE];

const ModalActions = props => {
  const { t } = useTranslation('mentee');
  const [descRejected, setDescRejected] = useState('');

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (props.isAction.action === 'FINISHED') {
      dispatch(deleteChatGroupByRequestId(props.isAction.obj.menteeId));
    }
    if (props.isAction.action === 'ACTIVE') {
      dispatch(createChatGroupMentor(props.isAction.obj.menteeId, [props.isAction.obj.userMentorId]));
    }

    props.onChangeStatus(descRejected);
  };

  const footerModal = BTN_CLOSE.includes(props.isAction.action) ? (
    <Button onClick={props.onClose} size='large'>
      {t('close')}
    </Button>
  ) : (
    <div className={css['ModalActions-setReject-footer']}>
      {props.isError && (
        <div className={css['ModalActions-setReject-footer-error']}>
          <HiOutlineExclamationCircle className={css['ModalActions-setReject-footer-error-icon']} />
          <span className={css['ModalActions-setReject-footer-error-desc']}>{t('errorDesc')}</span>
        </div>
      )}
      <Button onClick={props.onClose} size='large'>
        {t('no')}
      </Button>
      <Button
        type='primary'
        // onClick={() => props.onChangeStatus(descRejected)}
        onClick={onSubmit}
        size='large'
        className={css['ModalActions-setReject-footer-yes']}
      >
        {t('yes')}
      </Button>
    </div>
  );

  const description = (
    <div className={css['ModalActions-reject']}>
      <div className={css['ModalActions-reject-title']}>
        {props.isAction.action === REJECTED_MENTOR ? t('rejectReason') : t('gratitude')}
      </div>
      <div className={css['ModalActions-reject-desc']}>
        {props.isAction.action === REJECTED_MENTOR
          ? props.isAction.obj.rejectReasonOther || props.isAction.obj.rejectReason || ''
          : props.isAction.obj.menteeText || t('noGratitude')}
      </div>
    </div>
  );

  const rejected = (
    <div className={css['ModalActions-setReject']}>
      <div className={css['ModalActions-setReject-title']}>{t('rejectedStatus')}</div>
      <div>
        <TextArea
          rows={6}
          placeholder={t('placeHolderReject')}
          maxLength={255}
          allowClear
          onChange={e => setDescRejected(e?.target?.value)}
          className={classNames([css['ModalActions-setReject-area']], {
            [css['ModalActions-setReject-area-error']]: props.isError,
          })}
        />
      </div>
    </div>
  );

  const reqExp = (
    <div className={css['ModalActions-reqExp']}>
      <div className={css['ModalActions-reqExp-request']}>
        <div className={css['ModalActions-reqExp-request-title']}>{t('reqMentor')}</div>
        <div className={css['ModalActions-reqExp-request-desc']}>{props.isAction.obj.request || ''}</div>
      </div>
      <Divider />
      <div className={css['ModalActions-reqExp-experience']}>
        <div className={css['ModalActions-reqExp-experience-title']}>{t('expMentor')}</div>
        <div className={css['ModalActions-reqExp-experience-desc']}>{props.isAction.obj.experience || ''}</div>
      </div>

      {props.isAction.obj.contact && (
        <>
          <Divider />
          <div className={css['ModalActions-reqExp-experience']}>
            <div className={css['ModalActions-reqExp-experience-title']}>{t('contactMentor')}</div>
            <div className={css['ModalActions-reqExp-experience-desc']}>{props.isAction.obj.contact}</div>
          </div>
        </>
      )}
    </div>
  );

  const gradeBlock = (
    <div className={css['ModalActions-grade']}>
      <HiOutlineThumbUp className={css['ModalActions-grade-image']} />
      <div className={css['ModalActions-grade-rate']}>
        <div className={css['ModalActions-grade-rate-assesment']}>{t('assesment')}</div>
        <Rate noChange size='lg' disabled value={props.isAction.obj.mentorRating.rating} />
      </div>
      <div className={css['ModalActions-grade-text']}>{props.isAction.obj.mentorRating.review || t('noGradeText')}</div>
    </div>
  );

  return (
    <Modal open={props.isAction.open} onCancel={props.onClose} footer={footerModal}>
      {props.isAction.action === IN_FINISHED && t('inFinishStatus')}

      {props.isAction.action === IN_ACTIVE && t('activeStatus')}

      {props.isAction.action === IN_REJECT && rejected}

      {props.isAction.action === REQUEST_EXPERIENCE && reqExp}

      {(props.isAction.action === REJECTED_MENTOR || props.isAction.action === GRATITUDE) && description}

      {props.isAction.action === GRADE && gradeBlock}
    </Modal>
  );
};

ModalActions.propTypes = {
  onChangeStatus: PropTypes.func,
  onClose: PropTypes.func,
  isAction: PropTypes.object,
  isError: PropTypes.bool,
};

export default ModalActions;
