/* eslint-disable */
import React, { useCallback } from 'react';

import { Input } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Input.module.scss';

const CustomInput = ({ className, dark, noCursor, onPressEnter, onChange, isNumeric, dataQa, large, ...rest }) => {
  const isNum = v => {
    const re = /^[0-9\b]+$/;
    return v === '' || re.test(v);
  };

  const change = useCallback(
    event => {
      const { value } = event.target;
      if ((isNumeric && isNum(value)) || !isNumeric) {
        onChange(event);
      }
    },
    [isNumeric, onChange, isNum]
  );

  return (
    <Input
      type='text'
      onChange={change}
      {...rest}
      data-qa={dataQa}
      onPressEnter={onPressEnter}
      className={classNames(
        className,
        css.Input,
        { [css.dark]: dark },
        { [css.noCursor]: noCursor },
        { [css.large]: large }
      )}
    />
  );
};

CustomInput.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  dark: PropTypes.bool,
  noCursor: PropTypes.bool,
  onPressEnter: PropTypes.func,
};

export default CustomInput;
