/* eslint-disable */
import React, { Component } from 'react';

import { Button, Form, Input, Modal, Select } from 'antd';

import classNames from 'classnames';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

export default class PostCreationDialog extends Component {
  static propTypes = {
    descriptionText: PropTypes.string,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    okText: PropTypes.string,
    onOk: PropTypes.func,
    post: PropTypes.object,
    role: PropTypes.string,
    roles: PropTypes.array,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      post: _get(props, 'post.name', ''),
      role: _get(props, 'post.conventusRole.id', undefined),
      roleName: _get(props, 'post.conventusRole.name'),
      dirty: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleRoleChange = (value, option) => {
    this.setState({ role: option.id, roleName: value });
  };

  handleSubmit = () => {
    const { post, role } = this.state;
    this.setState({
      postError: '',
      roleError: '',
    });
    if (post && role) {
      this.setState({
        postError: '',
        roleError: '',
        dirty: true,
      });
      this.props.onOk({ name: post.trim(), roleId: +role });
    } else {
      !post && this.setState({ postError: this.props.t('require') });
      !role && this.setState({ roleError: this.props.t('require') });
    }
  };

  render() {
    const { descriptionText, okText, error, errorText, role, roles, ...props } = this.props;

    const options = roles.map(item => ({
      id: item.id,
      value: item.name,
      name: item.name,
    }));

    return (
      <Modal {...props} footer={null}>
        <p>{descriptionText}</p>
        <Form>
          <Form.Item
            className={classNames({ error: this.state.postError || error })}
            help={this.state.postError || (error && this.state.dirty ? errorText : '')}
            validateStatus={this.state.postError || (error && this.state.dirty) ? 'error' : ''}
          >
            <Input
              maxLength={200}
              name='post'
              value={this.state.post}
              onChange={this.handleChange}
              dataqa='postInput'
            />
          </Form.Item>
          <Form.Item
            className={classNames({ error: this.state.roleError })}
            help={this.state.roleError}
            validateStatus={this.state.roleError || (error && this.state.dirty) ? 'error' : ''}
          >
            <Select
              dataqa='roleSelect'
              placeholder={this.props.t('role')}
              onChange={this.handleRoleChange}
              value={this.state.roleName}
              options={options}
            />
          </Form.Item>
          <Button type='primary' onClick={this.handleSubmit} htmlType='submit' data-qa='submitBtn'>
            {okText}
          </Button>
        </Form>
      </Modal>
    );
  }
}
