import { GET_TIMEZONES_FAIL, GET_TIMEZONES_START, GET_TIMEZONES_SUCCESS } from './constance';

const initialState = {
  loading: false,
  timezones: [],
};

/* eslint-disable */
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_TIMEZONES_START:
      return { ...state, loading: true };
    case GET_TIMEZONES_SUCCESS:
      return { ...state, loading: false, timezones: response };
    case GET_TIMEZONES_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
