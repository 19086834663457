export const GET_ALL_ROLES_START = 'GET_ALL_ROLES_START';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAIL = 'GET_ALL_ROLES_FAIL';

export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const GET_ROLES_DEFAULT_START = 'GET_ROLES_DEFAULT_START';
export const GET_ROLES_DEFAULT_SUCCESS = 'GET_ROLES_DEFAULT_SUCCESS';
export const GET_ROLES_DEFAULT_FAIL = 'GET_ROLES_DEFAULT_FAIL';

export const CREATE_ROLE_START = 'CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';

export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';

export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';

export const GET_ROLE_BY_ID_START = 'GET_ROLE_BY_ID_START';
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS';
export const GET_ROLE_BY_ID_FAIL = 'GET_ROLE_BY_ID_FAIL';

export const GET_GROUP_PERMISSIONS_ALL_START = 'GET_GROUP_PERMISSIONS_ALL_START';
export const GET_GROUP_PERMISSIONS_ALL_SUCCESS = 'GET_GROUP_PERMISSIONS_ALL_SUCCESS';
export const GET_GROUP_PERMISSIONS_ALL_FAIL = 'GET_GROUP_PERMISSIONS_ALL_FAIL';
