import { useTranslation } from 'react-i18next';

import { Button, Drawer, Form, Input } from 'antd';

import QuillEditor from '@shared/components/QuillEditor';

import style from './style.module.scss';

const CustomMail = props => {
  const [form] = Form.useForm();

  const { t } = useTranslation('newMassSelection');

  return (
    <Drawer title={t('templateEmail')} width={500} open={props.open} onClose={props.onClose}>
      <Form onFinish={props.onSubmit} form={form}>
        <Form.Item name='mailSubject'>
          <Input placeholder={t('placeholderNameEmail')} />
        </Form.Item>
        <Form.Item name='mailBody'>
          <QuillEditor />
        </Form.Item>
        <div className={style['custom-mail__buttons']}>
          <Button onClick={props.onClose}>{t('cancel')}</Button>
          <Button type='primary' htmlType='submit'>
            {t('save')}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CustomMail;
