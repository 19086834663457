/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Tooltip } from 'antd';
import { Card, Content, Layout } from '@/components/Layout';
import ThemesEditor from '@/components/ThemesEditor';
import { CloseOutlined } from '@ant-design/icons';

import css from './ThemesEditorPage.module.scss';

const ThemesEditorPage = () => {
  const { t } = useTranslation('themesEditor');
  const navigation = useNavigate();

  const closePage = () => {
    navigation('/compilations');
  };

  return (
    <Layout>
      <Content>
        <div className={css.ThemesEditor__header}>
          <h1>{t('edit')}</h1>
          <div className={css.ThemesEditor__editBtns}>
            <Tooltip title={t('exit')}>
              <Button className={css['ThemesEditor-btn-close']} onClick={closePage}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
        <Card>
          <ThemesEditor isThemeEditor /> {/*KAM-3985*/}
        </Card>
      </Content>
    </Layout>
  );
};

export default ThemesEditorPage;
