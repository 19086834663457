/* eslint-disable */
import {
  D360_CREATE_CRITERIA_FAIL,
  D360_CREATE_CRITERIA_START,
  D360_CREATE_CRITERIA_SUCCESS,
  D360_DELETE_CRITERIA_FAIL,
  D360_DELETE_CRITERIA_START,
  D360_DELETE_CRITERIA_SUCCESS,
  D360_GET_CRITERIA_FAIL,
  D360_GET_CRITERIA_START,
  D360_GET_CRITERIA_SUCCESS,
  D360_UPDATE_COMPETENCE_COMMENT_FAIL,
  D360_UPDATE_COMPETENCE_COMMENT_START,
  D360_UPDATE_COMPETENCE_COMMENT_SUCCESS,
  D360_UPDATE_SKILL_COMMENT_FAIL,
  D360_UPDATE_SKILL_COMMENT_START,
  D360_UPDATE_SKILL_COMMENT_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  criterias: [],
};

export default (state = initialState, { type, response, headers, info, action }) => {
  switch (type) {
    // Query SUCCESS
    case D360_UPDATE_COMPETENCE_COMMENT_START:
    case D360_UPDATE_SKILL_COMMENT_START:
    case D360_CREATE_CRITERIA_START:
    case D360_DELETE_CRITERIA_START:
    case D360_GET_CRITERIA_START:
      return { ...state, loading: true };

    // Query ERROR
    case D360_UPDATE_COMPETENCE_COMMENT_FAIL:
    case D360_UPDATE_SKILL_COMMENT_FAIL:
    case D360_CREATE_CRITERIA_FAIL:
    case D360_DELETE_CRITERIA_FAIL:
    case D360_GET_CRITERIA_FAIL:
      return { ...state, loading: false };

    case D360_CREATE_CRITERIA_SUCCESS:
      return {
        ...state,
        loading: false,
        criterias: state.criterias.concat(response.data),
      };

    case D360_DELETE_CRITERIA_SUCCESS:
      return {
        ...state,
        loading: false,
        criterias: state.criterias.filter(criteria => criteria.id !== response.data.id),
      };

    case D360_GET_CRITERIA_SUCCESS:
      return {
        ...state,
        loading: false,
        criterias: response.data,
      };

    case D360_UPDATE_SKILL_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        criterias: response.data,
      };
      break;

    case D360_UPDATE_COMPETENCE_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        criterias: response.data,
      };

    default:
      return state;
  }
};
