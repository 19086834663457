import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getStudents, updateUserPermission } from '@/store/students/actions';

import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Spin, Table, Typography } from 'antd';

import HeaderFilter from './components/HeaderFilter';
import RoleSelect from './components/RoleSelect';

import PropTypes from 'prop-types';

import css from './UserSettings.module.scss';

import { USER_UPDATE } from '@/constants/permissions';

const { Text } = Typography;

// Таблица пользователей для изменения роли
const UserSettings = memo(({ roles }) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [massRoleId, setMassRoleId] = useState(4);

  const initialMount_1 = useRef(true);
  const initialMount_2 = useRef(true);

  const { t } = useTranslation('RolesSettings');

  const activeMass = useMemo(() => {
    return selectedUser.length;
  }, [selectedUser]);

  const dispatch = useDispatch();

  const {
    students,
    authoritiesUser,
    studentsPageSize,
    studentsPageNumber,
    studentsTotal,
    userFilter,
    isLoadingStudents,
  } = useSelector(selectStudents);
  const { currentUser, isLoading: isLoadingRoles } = useSelector(selectUsers);
  const loadingRoles = isLoadingRoles;

  const authorities = currentUser?.authorities;

  useEffect(() => {
    if (initialMount_1.current) {
      initialMount_1.current = false;
    } else {
      dispatch(getStudents({ ...userFilter }, pagination.current - 1, false));
    }
  }, [authoritiesUser]);

  useEffect(() => {
    if (initialMount_2.current) {
      initialMount_2.current = false;
    } else {
      dispatch(getStudents({ ...userFilter }, 0, false));
    }
  }, [userFilter]);

  useEffect(() => {
    if (students?.length) {
      let arr = [];
      students.forEach((student, index) => {
        selectedUser.forEach(selectedStudentId => {
          if (selectedStudentId === student.id) {
            arr.push(index);
          }
        });
      });
      setSelectedRowKeys(arr);
    } else {
      setSelectedRowKeys([]);
    }
  }, [students]);

  const pagination = {
    current: studentsPageNumber + 1,
    pageSize: studentsPageSize,
    total: studentsTotal,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const onChangeTable = pagination => {
    dispatch(getStudents({ ...userFilter }, pagination.current - 1, false));
  };

  const changeRole = (ids, roleId) => {
    if (ids?.length) {
      let object = {
        idRole: {},
      };
      ids.forEach(id => {
        object['idRole'][id] = roleId;
      });
      dispatch(updateUserPermission(object));
      setSelectedUser([]);
    }
  };

  const columns = [
    {
      title: t('firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      render: (text, { id, roleId }) => (
        <RoleSelect
          disabled={!authorities.includes(USER_UPDATE)}
          roles={roles}
          userId={id}
          roleId={roleId}
          changeRole={changeRole}
        />
      ),
    },
  ];

  const render = () => {
    return (
      <>
        <Spin spinning={isLoadingStudents || loadingRoles}>
          <div className={css['main']}>
            <HeaderFilter roles={roles} setSelectedUser={setSelectedUser} setSelectedRowKeys={setSelectedRowKeys} />
            <div className={css['main__main']}>
              <div className={css['main__mass']}>
                <Text>{t('selectedUser', { count: selectedUser.length })}</Text>
                <RoleSelect
                  changeRole={(userIds, roleId) => setMassRoleId(roleId)}
                  roles={roles}
                  roleId={massRoleId}
                  disabled={!activeMass || !authorities.includes(USER_UPDATE)}
                />
                <Button
                  disabled={!activeMass || !authorities.includes(USER_UPDATE)}
                  onClick={() => changeRole(selectedUser, massRoleId)}
                  type='primary'
                >
                  {t('submitMass')}
                </Button>
              </div>
              <Table
                columns={columns}
                dataSource={students}
                rowSelection={{
                  selectedRowKeys,
                  type: 'checkbox',
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedUser(prevSelectedUser => {
                      const users = selectedRows.map(row => row.id);
                      return [...new Set([...prevSelectedUser, ...users])];
                    });
                    setSelectedRowKeys(selectedRowKeys);
                  },
                }}
                pagination={pagination}
                onChange={onChangeTable}
                rowKey={e => e.id}
              />
            </div>
          </div>
        </Spin>
      </>
    );
  };

  return render();
});

UserSettings.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default UserSettings;
