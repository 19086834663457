/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import Tag from '@/components/Tag';
import { LoadingOutlined } from '@ant-design/icons';

import { Resources } from '@shared/resources';

import css from '../../Select/Select.module.scss';
import styles from './styles.module.scss';

const { Option } = Select;

export default ({
  name,
  onSearch,
  value,
  options = [],
  onChange,
  showSearch,
  notFoundPlaceholder,
  filterOption,
  forceUpdate,
  oldStyle,
  multiple,
  data,
  dataQa,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState();
  const [currentValue, setCurrentValue] = useState(value || undefined);
  const [values, setValues] = useState((data && data.map(({ id, name }) => ({ value: id, children: name }))) || []);
  const [searchOptions, setSearchOptions] = useState(multiple ? [] : options);

  useEffect(() => {
    if (forceUpdate) {
      setSearchOptions(options);
    }
  }, [options]);

  const _onChange = (value, data) => {
    const props = (data && data.props) || undefined;
    onSearch && setSearchOptions([]);
    setQuery('');
    if (multiple) {
      const ids = values.map(({ value }) => value);
      if (!ids.includes(props.value)) {
        setValues([...values, props]);
        onChange({ name, value: [...ids, props.value] });
      }
    } else {
      onChange({ name, value });
      setCurrentValue(props && props.children);
    }
  };

  const _filterOption =
    !onSearch &&
    (filterOption ||
      (showSearch ? (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true));

  const _onSearch =
    onSearch &&
    (query => {
      setQuery(query);

      if (!query || (query && query.length < 3)) {
        searchOptions.length && setSearchOptions([]);
        return;
      }

      setLoading(true);

      onSearch(query)
        .then(response => setSearchOptions(response))
        .finally(() => setLoading(false));
    });

  const _options = onSearch ? searchOptions : options;

  const _notFoundContent =
    query && query.length > 2 && !_options.length && !loading ? (
      'Ничего не найдено'
    ) : loading ? (
      <LoadingOutlined />
    ) : (
      notFoundPlaceholder || 'Начни вводить запрос'
    );

  const handleClose = removedValue => {
    const updatedValues = values.filter(({ value }) => value !== removedValue);
    setValues(updatedValues);
    onChange({ name, value: updatedValues.map(({ value }) => value) });
  };

  const elements = values.map(({ children, value }) => (
    <Tag
      key={value}
      stringTag
      onDelete={() => handleClose(value)}
      tag={{ id: value, name: children.length > 50 ? `${children.substring(0, 50)}...` : children }}
    />
  ));

  const select = (
    <>
      <Select
        data-qa={dataQa}
        showSearch={showSearch}
        allowClear={{ clearIcon: <img src={Resources.Icons.ResetSearch} /> }}
        // @ts-ignore // todo fix
        defaultValue={value}
        notFoundContent={_notFoundContent}
        defaultActiveFirstOption={false}
        value={multiple ? undefined : currentValue}
        {...props}
        onSearch={_onSearch}
        onChange={_onChange}
        filterOption={_filterOption}
        loading={loading}
        dropdownRender={optionElements => <span data-qa={`${dataQa}`}>{optionElements}</span>}
      >
        {_options.map(option => (
          <Option data-qa={`${dataQa}-item`} value={option.value} key={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      {multiple && elements}
    </>
  );
  return oldStyle ? <div>{select}</div> : select;
};
