import {
  EXPORT_POKA_CORRECT_AREA_FAIL,
  EXPORT_POKA_CORRECT_AREA_START,
  EXPORT_POKA_CORRECT_AREA_SUCCESS,
  GET_POKA_CORRECT_AREAS_FAIL,
  GET_POKA_CORRECT_AREAS_START,
  GET_POKA_CORRECT_AREAS_SUCCESS,
  GET_POKA_SOUR_AREAS_FAIL,
  GET_POKA_SOUR_AREAS_START,
  GET_POKA_SOUR_AREAS_SUCCESS,
  LOAD_POKA_AREAS_FAIL,
  LOAD_POKA_AREAS_START,
  LOAD_POKA_AREAS_SUCCESS,
  SAVE_POKA_AREAS_FILTERS,
  SAVE_POKA_AREAS_SORT,
} from '@/store/poka_areas/constants';

const initialState = {
  pokaProperAreas: [],
  pokaDirSourAreas: [],
  pokaCorrectAreas: [],
  loadingPokaDirSourAreas: false,
  errorPokaDirSourAreas: false,
  loadingPokaProperAreas: false,
  errorPokaProperAreas: false,
  loadingPokaCorrectAreas: false,
  errorPokaCorrectAreas: false,
  pokaProperAreasTotal: 0,
  pokaProperAreasPageSize: 0,
  pokaProperAreasPageNumber: 0,
  pokaProperAreasCurrentPage: 0,
  pokaProperAreasFilters: {},
  pokaProperAreasSort: '',
  isErrorExport: false,
  exportLoading: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case LOAD_POKA_AREAS_START:
      return {
        ...state,
        loadingPokaProperAreas: true,
        errorPokaProperAreas: false,
        pokaProperAreas: [],
      };

    case LOAD_POKA_AREAS_SUCCESS:
      return {
        ...state,
        loadingPokaProperAreas: false,
        errorPokaProperAreas: false,
        pokaProperAreasTotal: response.totalElements,
        pokaProperAreasPageSize: response.pageable.pageSize,
        pokaProperAreasPageNumber: response.pageable.pageNumber,
        pokaProperAreasCurrentPage: response.number,
        pokaProperAreas: response.content,
      };

    case LOAD_POKA_AREAS_FAIL:
      return {
        ...state,
        loadingPokaProperAreas: false,
        errorPokaProperAreas: true,
        pokaProperAreas: [],
      };

    case SAVE_POKA_AREAS_FILTERS:
      return { ...state, pokaProperAreasFilters: value };

    case SAVE_POKA_AREAS_SORT:
      return { ...state, pokaProperAreasSort: value };

    case GET_POKA_SOUR_AREAS_START:
      return {
        ...state,
        loadingPokaDirSourAreas: true,
        errorPokaDirSourAreas: false,
        pokaDirSourAreas: response,
      };

    case GET_POKA_SOUR_AREAS_SUCCESS:
      return {
        ...state,
        loadingPokaDirSourAreas: false,
        errorPokaDirSourAreas: false,
        pokaDirSourAreas: response,
      };

    case GET_POKA_SOUR_AREAS_FAIL:
      return {
        ...state,
        loadingPokaDirSourAreas: false,
        errorPokaDirSourAreas: true,
        pokaDirSourAreas: [],
      };

    case GET_POKA_CORRECT_AREAS_START:
      return {
        ...state,
        loadingPokaCorrectAreas: true,
        errorPokaCorrectAreas: false,
        pokaCorrectAreas: response,
      };

    case GET_POKA_CORRECT_AREAS_SUCCESS:
      return {
        ...state,
        loadingPokaCorrectAreas: false,
        errorPokaCorrectAreas: false,
        pokaCorrectAreas: response,
      };

    case GET_POKA_CORRECT_AREAS_FAIL:
      return {
        ...state,
        loadingPokaCorrectAreas: false,
        errorPokaCorrectAreas: true,
        pokaCorrectAreas: [],
      };

    case EXPORT_POKA_CORRECT_AREA_START:
      return { ...state, exportLoading: true };

    case EXPORT_POKA_CORRECT_AREA_SUCCESS:
      return { ...state, exportLoading: false };

    case EXPORT_POKA_CORRECT_AREA_FAIL:
      return { ...state, exportLoading: false, isErrorExport: true };

    default:
      return state;
  }
};
