import { useSelector } from 'react-redux';

import { selectUsers } from '@/store/users/selectors';

import {
  AreaChartOutlined,
  AudioFilled,
  CalendarOutlined,
  CustomerServiceOutlined,
  GlobalOutlined,
  HomeOutlined,
  ReadFilled,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { HiOutlineViewGrid } from 'react-icons/hi';

import PropTypes from 'prop-types';

import css from './TypeBadgeNew.module.scss';

const TypeBadgeNew = ({ type }) => {
  const { corpCourseIcon } = useSelector(selectUsers);

  const renderBadge = () => {
    switch (type) {
      case 'audio':
        return <CustomerServiceOutlined />;
      case 'book':
        return <ReadFilled />;

      case 'executive':
        return <AreaChartOutlined />;

      case 'course':
        return <GlobalOutlined />;

      case 'external':
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10'>
            <g stroke='#2D3540' fill='none'>
              <path d='M8.812 4.185v3.953c0 .7-1.412 1.268-3.153 1.268s-3.153-.567-3.153-1.268V4.185' />
              <path d='M5.659 6.108.672 3.34 5.659.572l4.987 2.768z' />
            </g>
          </svg>
        );

      case 'cost':
        if (corpCourseIcon) {
          return <img src={corpCourseIcon} />;
        } else {
          return <HomeOutlined />;
        }

      case 'event':
        return <CalendarOutlined />;

      case 'game':
        return <HiOutlineViewGrid />;

      case 'img':
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'>
            <g stroke='#2D3540' fill='none'>
              <path d='M1.142.5h10v8h-10z' />
              <path d='m1.642 8 9-7m0 7-9-7z' opacity='.6' />
            </g>
          </svg>
        );

      case 'text':
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7'>
            <path d='M.5.5h10m-10 3h10m-10 3h6' stroke='#2D3540' fill='none' />
          </svg>
        );

      case 'video':
        return <VideoCameraOutlined />;

      case 'conference':
        return <AudioFilled />;

      default:
        break;
    }
  };

  return <div className={css['TypeBadgeNew']}>{renderBadge()}</div>;
};

TypeBadgeNew.propTypes = {
  type: PropTypes.string,
};

export default TypeBadgeNew;
