/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import {
  clearCompilation,
  compilationAddToPlanMass,
  compilationAddToPlanRedux,
  getCompilation,
  getCompilationComments,
} from '@/store/compilations/actions';
import { getFile } from '@/store/files/actions';
import { getMassFilters } from '@/store/massfilter/actions';
import { setSearchParams } from '@/store/search/actions';
import { clearTopicsCompilation, getTopicsCompilation } from '@/store/topics/actions';

import { selectCompilations as compilationList } from '@/store/compilations/selectors';
import { selectSearch } from '@/store/search/selectors';
import { selectTopics as topicsList } from '@/store/topics/selectors';
import { selectUsers as userSelect } from '@/store/users/selectors';

import { Button, Layout, notification, Tabs, Tooltip } from 'antd';
import AddCompilationCareerModal from '@/components/AddCompilationCareerModal';
import AddCompilationModal from '@/components/AddCompilationModal';
import CalendarModal from '@/components/AddToPlan/CalendarModal';
import Assignment from '@/components/Assignment';
import { Content } from '@/components/Layout';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import Utils from '../../Utils';
import Comments from './Comments';
import Header from './Header';
import Info from './Info';
import MaterialCard from './MaterialCard';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

import { COMPIL_SUBTYPES } from '@/constants/compilation-detail';
import { COMPILATION_DELETE, COMPILATION_UPDATE } from '@/constants/permissions';

class CompilationDetail extends Component {
  static propTypes = {
    clearCompilation: PropTypes.func,
    clearTopicsCompilation: PropTypes.func,
    compilation: PropTypes.object,
    compilationComments: PropTypes.array,
    compilationIsUpdate: PropTypes.bool,
    domainCompany: PropTypes.object,
    getCompilation: PropTypes.func,
    getCompilationComments: PropTypes.func,
    getFile: PropTypes.func,
    getTopicsCompilation: PropTypes.func,
    history: PropTypes.any,
    match: PropTypes.any,
    setSearchParams: PropTypes.func,
    topics: PropTypes.array,
    topicsCompilation: PropTypes.array,
    topicsLoading: PropTypes.bool,
    topicsPageNumber: PropTypes.number,
    topicsTotal: PropTypes.number,
    roles: PropTypes.array,
    isGlobal: PropTypes.bool,
    currentUser: PropTypes.object,
    compilationAddToPlanRedux: PropTypes.func,
    compilationAddToPlanMass: PropTypes.func,
    getMassFilters: PropTypes.func,
    isLoadingMassCompilation: PropTypes.bool,
    searchParams: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isVisibleCareer: false,
      editable: null,
      addToPlanModalVisible: false,
      calendarModalVisible: false,
      users: [],
      subCompanies: Utils.isGPMValid(props.currentUser),
    };
    this.withEvents = true;
  }

  componentDidMount() {
    if (this.props.params) {
      this.props.getTopicsCompilation({
        compilationId: this.props.params.id,
        page: 0,
        size: 10,
        sort: 'compilations.position,asc',
        forCollection: true,
      });
      this.props.getCompilation(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match &&
      prevProps.compilationIsUpdate &&
      prevProps.compilationIsUpdate !== this.props.compilationIsUpdate
    ) {
      this.props.getTopicsCompilation({
        compilationId: prevProps.match.params.id,
        page: 0,
        size: 10,
        sort: 'compilations.position,asc',
        forCollection: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearTopicsCompilation();
  }

  findMaterialsOnTag = id => {
    this.props.setSearchParams({
      themeId: [id],
      page: 0,
    });
    this.props.navigate('/compilations');
  };

  toggleGlobalModal = id => {
    if (id && !this.state.isVisibleCareer) {
      this.props.getCompilation(id, res => {
        const { topics, career } = res;
        this.setState({
          isVisibleCareer: true,
          editable: {
            id,
            career,
            topics,
          },
        });
      });
    } else {
      this.setState({
        isVisibleCareer: false,
        editable: this.state.isVisibleCareer && null,
      });
    }
  };

  toggleModal = () => {
    this.setState({
      isVisible: !this.state.isVisible,
    });
    if (this.state.isVisible) {
      this.props.getTopicsCompilation({
        compilationId: this.props.params.id,
        page: 0,
        size: 10,
        sort: 'compilations.position,asc',
        forCollection: true,
      });
    }
  };

  closePage = () => {
    this.props.navigate({
      pathname: '/compilations',
      search: this.props.searchParams?.searchResult ? `?search=${this.props.searchParams.searchResult}` : undefined,
    });
  };

  loadMore = () => {
    if (!this.props.topicsLoading) {
      this.props.getTopicsCompilation({
        compilationId: this.props.params.id,
        page: this.props.topicsPageNumber + 1,
        size: 10,
        sort: 'compilations.position,asc',
        forCollection: true,
      });
    }
  };

  addToPlan = () => {
    this.setState({
      addToPlanModalVisible: true,
    });
  };

  handleRecommendPost = users => {
    if (Array.isArray(users) && users.length) {
      this.setState({
        addToPlanModalVisible: false,
        calendarModalVisible: true,
        users: users.map(item => item.id),
      });
    }
  };

  hideUsersModal = () => this.setState({ addToPlanModalVisible: false });

  handleMassFilter = sendData => {
    if (this.props.compilation?.id && sendData) {
      this.props.compilationAddToPlanMass(this.props.compilation.id, sendData, () => {
        this.hideUsersModal();
      });
    }
  };

  handleAddToPlan = (startDate, endDate) => {
    const { users } = this.state;
    const { t } = this.props;

    this.setState({ calendarModalVisible: false });

    this.props.compilationAddToPlanRedux(
      this.props.params.id,
      startDate,
      endDate,
      users,
      response => {
        if (response && response.added && response.added > 0) {
          notification.success({
            placement: 'bottomRight',
            bottom: 50,
            duration: 5,
            description: t('addToPlanSuccessMesg'),
            message: t('addToPlanSuccessTitle'),
          });
        } else {
          notification.error({
            placement: 'bottomRight',
            bottom: 50,
            duration: 5,
            description: t('addToPlanErrorMesg'),
            message: t('addToPlanErrorTitle'),
          });
        }
      },
      error => {
        console.error('Error:', error);
      }
    );
  };

  getSavedFilters = () => {
    if (this.props.compilation?.id) {
      this.props.getMassFilters(this.props.compilation.id, 'COMPILATION');
    }
  };

  render() {
    if (!this.props.compilation) {
      return null;
    }
    const {
      id,
      cover,
      manager,
      commentCount,
      mentionCount,
      name,
      rate,
      fullDescription,
      skills,
      themes,
      shortDescription,
    } = this.props.compilation;
    const { compilationComments, topicsCompilation, t } = this.props;

    const { addToPlanModalVisible, calendarModalVisible, processModalVisible, users } = this.state;

    const renderThemes = (
      <div className={css.themes}>
        <div className={css.themes__title}>{t('theme')}</div>
        <div>
          {themes.map((theme, i) => (
            <div key={theme.id}>
              <a data-qa={i} onClick={() => this.findMaterialsOnTag(theme.id)}>
                {theme.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
    const tabs = COMPIL_SUBTYPES(t)
      .map(tab => {
        if (tab.panname === 'materials') {
          return {
            label: tab.name,
            key: tab.panname,
            'data-qa': tab.dataQa,
            children: (
              <div className={css.TabPane__content}>
                <InfiniteScroll
                  className={css.MaterialsTabsAboutBlock}
                  initialLoad={false}
                  loadMore={this.loadMore}
                  hasMore={topicsCompilation && this.props.topicsTotal > topicsCompilation.length}
                  threshold={300}
                  useWindow
                >
                  {topicsCompilation.map(topic => (
                    <MaterialCard
                      roles={this.props.roles}
                      key={topic.id}
                      coverFileUuid={topic.coverFileUuid}
                      coverColor={topic.coverColor}
                      thumbFileUuid={topic.thumbFileUuid}
                      getFile={this.props.getFile}
                      type={topic.type}
                      topic={topic}
                      id={topic.id}
                      name={topic.name}
                      startDate={topic.startDate}
                      startTime={topic.startTime}
                      domainCompany={this.props.domainCompany}
                      corpCourseBackground={this.props.corpCourseBackground}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            ),
          };
        }
        if (tab.panname === 'info') {
          return {
            label: tab.name,
            key: tab.panname,
            children: (
              <div className={css.TabPane__content}>
                <div className={css.TabPane__content__text}>
                  <Info skills={skills} fullDescription={fullDescription} />
                </div>
                {renderThemes}
              </div>
            ),
          };
        }
        if (tab.panname === 'feedback') {
          const totalCount = commentCount + mentionCount;
          return {
            label: (
              <div>
                {tab.name}
                {totalCount ? <span className={css.Comment__count}>{totalCount}</span> : null}
              </div>
            ),
            key: tab.panname,
            children: (
              <div className={css.TabPane__content}>
                <div className={css.TabPane__content__text}>
                  <Comments
                    compId={id}
                    getFile={this.props.getFile}
                    getComments={this.props.getCompilationComments}
                    comments={compilationComments}
                  />
                </div>
                {renderThemes}
              </div>
            ),
          };
        }
      })
      .filter(tab => !!tab);

    const hasAuthorities =
      this.props.currentUser.authorities.includes(COMPILATION_UPDATE) ||
      this.props.currentUser.authorities.includes(COMPILATION_DELETE);

    const editCompilation = (
      <Tooltip title={t('edit')}>
        <Button className={css['DetailsModal-btn-edit']} onClick={this.toggleModal}>
          <EditOutlined />
        </Button>
      </Tooltip>
    );

    return (
      <Layout>
        <Content className={css.Compilation}>
          <div>
            <div className={css.Detail__editBtns}>
              {this.props.compilation?.global
                ? this.props.currentUser?.domainCompany?.global && hasAuthorities && editCompilation
                : hasAuthorities && editCompilation}

              <Tooltip title={t('close')}>
                <Button className={css['DetailsModal-btn-close']} onClick={this.closePage}>
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </div>
            <Header
              dataQa='adminCompilationDetail'
              cover={this.props.compilation && cover}
              manager={manager}
              getFile={getFile}
              name={name}
              rate={rate}
              shortDescription={shortDescription}
              addToPlan={this.addToPlan}
            />
            <div className={css.Detail}>
              <div className={css.Detail__content}>
                <Tabs
                  animated={false}
                  defaultActiveKey='materials'
                  className={css['Add-modal-tabs']}
                  onChange={this.handleTabChange}
                  items={tabs}
                />
              </div>
            </div>
          </div>
        </Content>
        {this.state.isVisible && (
          <AddCompilationModal
            editable={this.props.compilation}
            open={this.state.isVisible}
            toggleModal={this.toggleModal}
            navigate={this.props.navigate}
            isDetile
            domainCompany={this.props.domainCompany}
          />
        )}
        {this.state.isVisibleCareer && (
          <AddCompilationCareerModal
            editable={this.state.editable}
            isGlobal={this.props.isGlobal}
            toggleModal={this.toggleGlobalModal}
          />
        )}
        {this.state.addToPlanModalVisible && (
          <Assignment
            open={this.state.addToPlanModalVisible}
            onCancel={this.hideUsersModal}
            onOk={this.handleRecommendPost}
            title={t('titleModalSingleCompilation')}
            getSavedFilters={this.getSavedFilters}
            onMassOk={this.handleMassFilter}
            mass
            isLoadingMass={this.props.isLoadingMassCompilation}
            titleModalMass={t('titleModal')}
            hideInspectors
          />
        )}
        {calendarModalVisible && (
          <CalendarModal
            onOk={this.handleAddToPlan}
            onCancel={() => {
              this.setState({ calendarModalVisible: false });
            }}
          />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = createSelector(
  [compilationList, topicsList, userSelect, selectSearch],
  (compilations, topics, users, search) => ({
    currentUser: users.currentUser,
    compilation: compilations.compilation,
    compilationIsUpdate: compilations.isLoading,
    compilationComments: compilations.compilationComments,
    topics: topics.topics,
    topicsLoading: topics.isLoading,
    topicsPageNumber: topics.topicsCompilationPageNumber,
    topicsTotal: topics.topicsCompilationTotal,
    topicsCompilation: topics.topicsCompilation,
    roles: users.currentUser && users.currentUser.authorities,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    corpCourseBackground: users.corpCourseBackground,
    isGlobal: users && users.currentUser.domainCompany.global,
    isLoadingMassCompilation: compilations.isLoadingMassCompilation,
    searchParams: search.searchParams,
  })
);

const mapActionsToProps = {
  clearCompilation,
  clearTopicsCompilation,
  getCompilation,
  getFile,
  setSearchParams,
  getCompilationComments,
  getTopicsCompilation,
  compilationAddToPlanRedux,
  compilationAddToPlanMass,
  getMassFilters,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['compilationHeader', 'constants'])(CompilationDetail));
