import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeStatusBadgeId, deleteBadgeId, getBadgeId, getBadges, saveBadgeSort } from '@/store/badge/actions';

import { selectBadge } from '@/store/badge/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Modal, Table, Tooltip } from 'antd';
import ImageBlock from '@/components/ImageBlock';
import ModalBadge from '@/components/ModalBadge';
import RenderTableStatus from '@/components/RenderTableStatus';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import { STATUS_ACTIVE } from '../constants';

import classNames from 'classnames';

import css from '../Badge.module.scss';

const TableBadge = () => {
  const { t } = useTranslation('badge');
  const dispatch = useDispatch();

  const [isModalBadge, setIsModalBadge] = useState({
    open: false,
    imageUuid: '',
  });
  const [activeSort, setActiveSort] = useState({ name: false, date: false, modif: false });
  const [modal, contextHolder] = Modal.useModal();

  const { currentUser } = useSelector(selectUsers);
  const { badgeLoading, badgeTable, badgeTotal, badgePageSize, badgePageNumber, badgeCurrentPage, badgeFilters } =
    useSelector(selectBadge);

  const deleteBadge = item => {
    if (item?.id) {
      dispatch(deleteBadgeId(item.id, () => dispatch(getBadges({}, 0))));
    }
  };

  const getBadgeIdForEdit = item => {
    if (item) {
      setIsModalBadge({ open: true, imageUuid: item.badgePhoto });
      dispatch(getBadgeId(item.id));
    }
  };

  const modalDeleteBadge = item => {
    modal.warning({
      icon: (
        <HiOutlineQuestionMarkCircle
          color='var(--personalColor)'
          className={css['TableContent-modal-icon']}
          size={24}
        />
      ),
      content: (
        <div className={css['TableContent-modal-info']}>
          <div>{t('titleDeleteBadge')}</div>
          <div>{t('txtDeleteBadge')}</div>
        </div>
      ),
      okText: t('delete'),
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      closable: true,
      onOk() {
        deleteBadge(item);
      },
      maskClosable: true,
    });
  };

  const renderActions = item => {
    const isActive = item.badgeStatus.value === STATUS_ACTIVE;

    return (
      <>
        <Tooltip placement='top' title={t('editBadge')}>
          <FormOutlined className={css['TableContent-table-actions-edit']} onClick={() => getBadgeIdForEdit(item)} />
        </Tooltip>
        <Tooltip placement='top' title={isActive ? t('inactive') : t('active')}>
          {isActive ? (
            <EyeOutlined
              className={css['TableContent-table-actions-active']}
              onClick={() => dispatch(changeStatusBadgeId(item.id, false))}
            />
          ) : (
            <EyeInvisibleOutlined
              className={css['TableContent-table-actions-active']}
              onClick={() => dispatch(changeStatusBadgeId(item.id, true))}
            />
          )}
        </Tooltip>
        <Tooltip placement='top' title={!item.bindToTopic ? t('deleteBadge') : t('cantDeleteBadge')}>
          <DeleteOutlined
            className={classNames(
              css['TableContent-table-actions-delete'],
              item.bindToTopic && css['TableContent-table-actions-delete-disabled']
            )}
            onClick={() => !item.bindToTopic && modalDeleteBadge(item)}
          />
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      title: t('badgePhoto'),
      dataIndex: 'badgePhoto',
      key: '1',
      width: 100,
      align: 'center',
      render: photoUuid => <ImageBlock photoUuid={photoUuid} isBadge />,
    },
    {
      title: t('badgeName'),
      dataIndex: 'badgeName',
      key: '2',
      width: 500,

      render: (i, item) => (
        <div>
          <Link to={`/badge/${item.id}`}>{i}</Link>
        </div>
      ),
    },
    {
      title: t('badgeStatus'),
      dataIndex: 'badgeStatus',
      key: '3',
      render: item => <RenderTableStatus status={item.value} text={item.name} />,
      width: 100,
    },
    {
      title: t('badgeAuthor'),
      dataIndex: 'badgeAuthor',
      key: '4',
      width: 300,
      render: value => (
        <a
          rel='noreferrer'
          href={`https://${currentUser?.domainCompany?.domain}/collegues/${value.userId}`}
          target='_blank'
        >
          {value.fullName}
        </a>
      ),
    },
    {
      title: t('badgeAction'),
      dataIndex: 'badgeAction',
      key: '5',
      align: 'center',
      render: (_, item) => <div className={css['TableContent-table-actions']}>{renderActions(item)}</div>,
    },
  ];

  const dataSource = useMemo(
    () =>
      badgeTable?.length > 0 &&
      badgeTable.map(item => {
        return {
          badgePhoto: item.imageUuid,
          badgeName: item.name,
          badgeStatus: item.statusDto,
          badgeAuthor: item.authorDto,
          bindToTopic: item.bindToTopic,
          id: item.id,
        };
      }),
    [badgeTable]
  );

  const pagination = {
    total: badgeTotal,
    pageSize: badgePageSize,
    pageNumber: badgePageNumber,
    current: badgeCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const onChangeTable = pagination => dispatch(getBadges(badgeFilters ?? {}, pagination.current - 1));

  const sortName = () => {
    setActiveSort({ name: !activeSort.name, date: false, modif: false });

    if (!activeSort.name) {
      dispatch(saveBadgeSort('name,asc'));
      dispatch(getBadges(badgeFilters ?? {}, 0, 'name,asc'));
    } else {
      dispatch(saveBadgeSort(''));
      dispatch(getBadges(badgeFilters ?? {}, 0));
    }
  };

  const sortDate = () => {
    setActiveSort({ name: false, date: !activeSort.date, modif: false });

    if (!activeSort.date) {
      dispatch(saveBadgeSort('createdDate,desc'));
      dispatch(getBadges(badgeFilters ?? {}, 0, 'createdDate,desc'));
    } else {
      dispatch(saveBadgeSort(''));
      dispatch(getBadges(badgeFilters ?? {}, 0));
    }
  };

  const sortModif = () => {
    setActiveSort({ name: false, date: false, modif: !activeSort.modif });

    if (!activeSort.modif) {
      dispatch(saveBadgeSort('lastModifiedDate,desc'));
      dispatch(getBadges(badgeFilters ?? {}, 0, 'lastModifiedDate,desc'));
    } else {
      dispatch(saveBadgeSort(''));
      dispatch(getBadges(badgeFilters ?? {}, 0));
    }
  };

  return (
    <div>
      <div className={css['Sort']}>
        <div>{t('sort')}</div>
        <div className={classNames(css['Sort-name'], activeSort.name && css['Sort-active'])} onClick={sortName}>
          {t('sortName')}
        </div>
        <div className={classNames(css['Sort-date'], activeSort.date && css['Sort-active'])} onClick={sortDate}>
          {t('sortDate')}
        </div>
        <div className={classNames(css['Sort-modif'], activeSort.modif && css['Sort-active'])} onClick={sortModif}>
          {t('sortModif')}
        </div>
      </div>
      <div className={css['TableContent']}>
        <div className={css['TableContent-table']}>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={e => e?.id}
            pagination={pagination}
            onChange={onChangeTable}
            loading={badgeLoading}
          />
        </div>
      </div>

      {isModalBadge.open && (
        <ModalBadge
          open={isModalBadge.open}
          onCancel={() => setIsModalBadge({ open: false, imageUuid: '' })}
          isEdit
          badgePhotoUuid={isModalBadge.imageUuid}
          clearBadgeId
        />
      )}

      {contextHolder}
    </div>
  );
};

export default TableBadge;
