/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Input } from 'antd';
import Modal from '@/components/Modal/index';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './RejectCommentModal.module.scss';

const { TextArea } = Input;

export class ExecutiveRejectCommentModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    visible: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: null,
      btnClick: false,
    };
  }

  handleOnCancel = () => {
    this.props.onCancel();
  };

  changeComment = event => {
    this.setState({
      comment: event.target.value,
    });
  };

  handleOnSubmit = () => {
    this.props.onSubmit(this.state.comment);
  };

  render() {
    const { t } = this.props;
    return (
      <Modal open={this.props.visible} onCancel={this.handleOnCancel} width={1000} hideFooter zIndex={1005}>
        <div className={classNames(css['App-card-item'], css.RejectCommentModal)}>
          <div className={css['RejectCommentModal-header']}>
            <p>{t('title')}</p>
          </div>
          <TextArea
            className={css['RejectCommentModal-addComment']}
            rows={4}
            placeholder={t('commentText')}
            onChange={this.changeComment}
            value={this.state.comment}
            maxLength={500}
          />
          <Button
            ghost
            disabled={this.state.btnClick}
            className={css['RejectCommentModal-addComment-submit']}
            onClick={this.handleOnSubmit}
          >
            {t('leaveComment')}
          </Button>

          <div className={css.RejectCommentModal}>
            <div className={css['RejectCommentModal-labelBlock']}>
              <div className={css['RejectCommentModal-commentBlock-date']}>{t('date')}</div>
              <div className={css['RejectCommentModal-commentBlock-author']}>{t('author')}</div>
              <div className={css['RejectCommentModal-commentBlock-label']}>{t('action')}</div>
              <div className={css['RejectCommentModal-commentBlock-comment']}>{t('comment')}</div>
            </div>
            <div className={css['RejectCommentModal-comment']}>dfgd</div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('rejectCommentModal')(ExecutiveRejectCommentModal);
