/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Calendar, Modal } from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModalAdd.module.scss';

export class CalendarModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      disabledOkButton: true,
      isMonth: true,
    };
  }

  handleCancel() {
    if (this.props.onCancel && typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  }

  handleOk = () => {
    const { startDate, endDate } = this.state;
    if (this.props.onOk && typeof this.props.onOk === 'function') {
      this.props.onOk(startDate, endDate);
    }
  };

  handleSelectDate = value => {
    const { isMonth } = this.state;
    let startDate = dayjs(value).format('YYYY-MM-DD');
    let endDate = dayjs(value).format('YYYY-MM-DD');

    if (!isMonth) {
      startDate = dayjs(value).format('YYYY-MM-DD');
      endDate = dayjs(startDate).format('YYYY-MM-DD');
    }

    this.setState({
      disabledOkButton: false,
      startDate: startDate,
      endDate: endDate,
    });
  };

  handlePanelChange = (value, mode) => {
    mode === 'year' ? this.setState({ isMonth: false }) : this.setState({ isMonth: true });
  };

  getValidRange = () => {
    const date = new Date();

    return [
      dayjs().set({
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDay(),
      }),
      dayjs().add(10, 'years'),
    ];
  };

  render() {
    const { disabledOkButton } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t('description7')}
        cancelText={t('cancel')}
        okText={t('Ok')}
        centered={true}
        closable={true}
        destroyOnClose={true}
        keyboard={true}
        open={true}
        onCancel={() => this.handleCancel()}
        onOk={this.handleOk}
        okButtonProps={{
          disabled: disabledOkButton,
        }}
      >
        <Calendar
          fullscreen={false}
          onSelect={this.handleSelectDate}
          onPanelChange={this.handlePanelChange}
          validRange={this.getValidRange()}
          className={css['Calendar']}
        />
      </Modal>
    );
  }
}

export default withTranslation(['calendarModal'])(CalendarModal);
