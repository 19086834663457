/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Col, Row, Typography } from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const { Text } = Typography;

export class Metrics extends Component {
  static propTypes = {
    t: PropTypes.func,
    student: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { student, t } = this.props;

    let registered = dayjs(student.createdDate);
    let lastLogin = dayjs(student.lastLogin);
    let daysInSystem = lastLogin.diff(registered, 'days');

    return (
      <>
        {/* <Row>
          <Col span={8}>
            <Text>{t('tabMetricsTotalCoins')}</Text>
          </Col>
          <Col span={16}>
            <Text>{student.account || 0}</Text>
          </Col>
        </Row> */}
        <Row>
          <Col span={8}>
            <Text>{t('tabMetricsInSystem')}</Text>
          </Col>
          <Col span={16}>
            <Text>{daysInSystem || 0}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>{t('tabMetricsRegisterDate') || '-'}</Text>
          </Col>
          <Col span={16}>
            <Text>{registered.format('DD-MM-YYYY') || '-'}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>{t('tabMetricsLastLogin')}</Text>
          </Col>
          <Col span={16}>
            <Text>{lastLogin.format('DD-MM-YYYY') || '-'}</Text>
          </Col>
        </Row>
        {/* <Row>
          <Col span={8}>
            <Text>{t('tabMetricsTotalCreated')}</Text>
          </Col>
          <Col span={16}>
            <Text>{student.created || 0}</Text>
          </Col>
        </Row> */}
      </>
    );
  }
}

export default withTranslation('studentProfileDialog')(Metrics);
