/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getPinnedTopicsCount } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';

import { Popover } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';

import { isCorporateCourseWithoutApprove, isCorpOrExtCourse } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './FixMaterial.module.scss';

class FixMaterial extends Component {
  static propTypes = {
    changePositionTopic: PropTypes.func,
    detailsTopic: PropTypes.bool,
    getTopics: PropTypes.func,
    pinned: PropTypes.any,
    scopeTopics: PropTypes.string,
    searchString: PropTypes.string,
    statusTopics: PropTypes.string,
    topic: PropTypes.object,
    topicType: PropTypes.string,
    topicTypeId: PropTypes.string,
    topicsPageNumber: PropTypes.number,
    updateTopic: PropTypes.func,
    pinnedTopicsCount: PropTypes.number,
    getPinnedTopicsCount: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      select: false,
    };
  }

  handleVisibleChange = select => {
    if (this.props.topic.position) {
      this.setState({ select });
    } else {
      this.chooseCount(this.props.pinnedTopicsCount + 1);
    }
  };

  chooseCount = count => {
    this.props.changePositionTopic(this.props.topic.id, count, () => {
      this.setState({ select: false });
      this.props.getPinnedTopicsCount();
      if (this.props.detailsTopic) {
        this.props.updateTopic();
      } else {
        this.props.getTopics({
          name: this.props.searchString,
          status: this.props.statusTopics,
          scope: this.props.scopeTopics,
          topicTypeId: this.props.topicTypeId,
          pinned: this.props.pinned,
          page: this.props.topicsPageNumber,
        });
      }
    });
  };

  render() {
    const { topic, pinnedTopicsCount, t } = this.props;
    const number = pinnedTopicsCount;
    const select = [];
    for (let i = 0; i <= number; i++) {
      select.push(
        <div className={css['FixMaterial-popover']} key={i} value={i} onClick={() => this.chooseCount(i)}>
          {i || t('unattach')}
        </div>
      );
    }
    return (
      <div className={css['FixMaterial-block']}>
        <Popover
          content={select}
          trigger='click'
          placement='bottom'
          open={this.state.select}
          onOpenChange={this.handleVisibleChange}
        >
          <PaperClipOutlined data-qa='unpunBtn' className={css['FixMaterial-icon']} />
          {!!topic.position && (
            <div
              className={classNames(
                {
                  [css['FixMaterial-badge-position']]:
                    isCorpOrExtCourse(topic.type.id) || isCorporateCourseWithoutApprove(topic.type.id),
                },
                css['FixMaterial-badge']
              )}
            >
              {topic.position}
            </div>
          )}
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = createSelector([selectTopics], topics => ({
  pinnedTopicsCount: topics.pinnedTopicsCount,
}));

const MapActionsToProps = {
  getPinnedTopicsCount,
};

export default connect(mapStateToProps, MapActionsToProps)(withTranslation('fixMaterial')(FixMaterial));
