import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeMenteeStatus, postMenteeFilters } from '@/store/mentee/actions';

import { selectMentee } from '@/store/mentee/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Dropdown, message, Table } from 'antd';
import Profile from '@/pages/Users/Profile';
import { DownOutlined } from '@ant-design/icons';

import { MENTEE_STATUS_UPDATE_BY_ADMIN } from '../../../constants/permissions';
import {
  GRADE,
  GRATITUDE,
  IN_ACTIVE,
  IN_FINISHED,
  IN_REJECT,
  MENTEE,
  MENTOR,
  REJECTED_MENTOR,
  REQUEST_EXPERIENCE,
  STATUS_ACTIVE,
  STATUS_FINISHED,
  STATUS_NO_ACTION,
  STATUS_REJECTED_BY_MENTOR,
  STATUS_WAITING_MENTOR,
} from './constance';
import ModalActions from './Modal/ModalActions';

import css from '../Mentee.module.scss';

const TableMentee = () => {
  const { t } = useTranslation('mentee');
  const dispatch = useDispatch();

  const {
    menteeFilters,
    menteeLoading,
    menteeTable,
    menteeTotal,
    menteePageSize,
    menteePageNumber,
    menteeCurrentPage,
  } = useSelector(selectMentee);

  const [isProfile, setIsProfile] = useState(false);
  const [student, setStudent] = useState({
    student: {},
    isMentor: false,
    mentorCompetences: [],
  });
  const [isAction, setIsAction] = useState({
    open: false,
    action: '',
    obj: {},
  });
  const [isError, setIsError] = useState(false);

  const { currentUser } = useSelector(selectUsers);

  const openProfile = (value, who) => {
    setIsProfile(true);
    if (who === MENTEE) {
      setStudent({ isMentor: false, student: value.menteeUserDto, mentorCompetences: [] });
    } else if (who === MENTOR) {
      setStudent({ isMentor: true, student: value.mentorUserDto, mentorCompetences: value.mentorCompetences });
    }
  };

  const showActionsModal = (obj, action) => setIsAction({ open: true, action, obj });

  const onChangeStatus = descRejected => {
    if (isAction?.obj?.menteeId && isAction?.action) {
      const params = {
        menteeId: isAction.obj.menteeId,
        status: isAction.action,
        contact: isAction.obj.contact,
      };

      if (descRejected) params.rejectReasonOther = descRejected;

      if (!descRejected && isAction?.action === IN_REJECT) {
        setIsError(true);
      } else {
        setIsError(false);
        dispatch(
          changeMenteeStatus(
            params,
            () => {
              dispatch(postMenteeFilters(menteeFilters.filters ? menteeFilters.filters : {}, menteeCurrentPage));
            },
            err => {
              if (err?.message) message.warning(err.message);
            }
          )
        );
        setIsAction({ ...isAction, open: false });
      }
    }
  };

  const onCloseModalActions = () => {
    setIsAction({ ...isAction, open: false });
    setIsError(false);
  };

  const tableAction = item => {
    const reqExp = [
      {
        key: '10',
        label: t('btnReqExp'),
        onClick: () => showActionsModal(item, REQUEST_EXPERIENCE),
      },
    ];

    if (item.status === STATUS_FINISHED) {
      return [
        {
          key: '1',
          label: t('btnGratitude'),
          onClick: () => showActionsModal(item, GRATITUDE),
        },
        {
          key: '2',
          label: t('btnGrade'),
          onClick: () => showActionsModal(item, GRADE),
        },
        ...reqExp,
      ];
    }

    if (item.status === STATUS_REJECTED_BY_MENTOR) {
      return [
        {
          key: '1',
          label: t('btnRejectReason'),
          onClick: () => showActionsModal(item, REJECTED_MENTOR),
        },
      ];
    }

    if (item.status === STATUS_WAITING_MENTOR) {
      return [
        {
          key: '1',
          label: t('btnApply'),
          onClick: () => showActionsModal(item, IN_ACTIVE),
        },
        {
          key: '2',
          label: t('btnReject'),
          onClick: () => showActionsModal(item, IN_REJECT),
        },
        ...reqExp,
      ];
    }

    if (item.status === STATUS_ACTIVE) {
      return [
        {
          key: '1',
          label: t('btnInFinished'),
          onClick: () => showActionsModal(item, IN_FINISHED),
        },
      ];
    }
  };

  const renderActionTable = item => {
    if (STATUS_NO_ACTION.includes(item.status)) return;

    return (
      <Dropdown menu={{ items: tableAction(item) }} trigger={['click']}>
        <span className='ant-dropdown-link' onClick={e => e.preventDefault()}>
          {t('action')}
          <DownOutlined />
        </span>
      </Dropdown>
    );
  };

  const getColumns = () => {
    let columns = [
      {
        title: t('userNameMentee'),
        dataIndex: 'userNameMentee',
        key: '1',
        width: 300,
        render: (text, value) => (
          <span className={css['TableContent-table-userName']} onClick={() => openProfile(value, MENTEE)}>
            {text}
          </span>
        ),
      },

      {
        title: t('userNameMentor'),
        dataIndex: 'userNameMentor',
        key: '2',
        width: 300,
        render: (text, value) => (
          <span className={css['TableContent-table-userName']} onClick={() => openProfile(value, MENTOR)}>
            {text}
          </span>
        ),
      },
      {
        title: t('status'),
        dataIndex: 'statusText',
        key: '3',
        width: 200,
      },
      {
        title: t('dateStart'),
        dataIndex: 'startDate',
        key: '4',
        width: 130,
      },
      {
        title: t('dateEnd'),
        dataIndex: 'endDate',
        key: '5',
        width: 130,
      },
      {
        title: t('action'),
        dataIndex: 'action',
        key: '9',
        render: (text, item) =>
          currentUser.authorities.includes(MENTEE_STATUS_UPDATE_BY_ADMIN) ? renderActionTable(item) : null,
        width: 150,
        align: 'center',
      },
    ];

    return columns;
  };

  const getData = () => {
    if (menteeTable?.length) {
      return menteeTable.map(item => {
        let userNameMentee = (item.menteeUserDto.lastName || '') + ' ' + (item.menteeUserDto.firstName || '');
        let userNameMentor = (item.mentorUserDto.lastName || '') + ' ' + (item.mentorUserDto.firstName || '');

        return {
          userNameMentee: userNameMentee.trim(),
          userMenteeId: item.menteeUserDto.id,
          userMentorId: item.mentorUserDto.id,
          userNameMentor: userNameMentor.trim(),
          status: item.status,
          startDate: item.startDate,
          endDate: item.endDate,
          menteeUserDto: item.menteeUserDto,
          mentorUserDto: item.mentorUserDto,
          mentorCompetences: item.competences,
          menteeText: item.menteeText,
          rejectReason: item.rejectReason,
          menteeId: item.menteeId,
          statusText: item.statusText,
          experience: item.experience,
          request: item.request,
          rejectReasonOther: item.rejectReasonOther,
          mentorRating: item.mentorRating,
          contact: item.contact,
        };
      });
    }
  };

  const onChangeTable = pagination => {
    dispatch(postMenteeFilters(menteeFilters.filters ? menteeFilters.filters : {}, pagination.current - 1));
  };

  const pagination = {
    total: menteeTotal,
    pageSize: menteePageSize,
    pageNumber: menteePageNumber,
    current: menteeCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  return (
    <div className={css['TableContent']}>
      <div className={css['TableContent-table']}>
        {menteeTable?.length ? (
          <Table
            columns={getColumns()}
            dataSource={getData()}
            rowKey={e => e.menteeId}
            bordered
            scroll={{ x: 700, y: 700 }}
            pagination={pagination}
            onChange={onChangeTable}
            loading={menteeLoading}
          />
        ) : (
          <div className={css['TableContent-table-empty']}>{t('emptyTable')}</div>
        )}
      </div>

      {isProfile && (
        <Profile
          isMentors={student.isMentor}
          student={student.student}
          t={t}
          open={isProfile}
          onCancel={() => setIsProfile(false)}
          domainCompany={currentUser?.domainCompany}
          mentorCompetence={student.mentorCompetences}
        />
      )}

      {isAction.open && (
        <ModalActions
          onClose={onCloseModalActions}
          onChangeStatus={onChangeStatus}
          isAction={isAction}
          isError={isError}
        />
      )}
    </div>
  );
};

export default TableMentee;
