export const GET_GROUPS_START = 'GET_GROUPS_START';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL';

export const POST_GROUPS_START = 'POST_GROUPS_START';
export const POST_GROUPS_SUCCESS = 'POST_GROUPS_SUCCESS';
export const POST_GROUPS_FAIL = 'POST_GROUPS_FAIL';

export const PUT_GROUPS_START = 'PUT_GROUPS_START';
export const PUT_GROUPS_SUCCESS = 'PUT_GROUPS_SUCCESS';
export const PUT_GROUPS_FAIL = 'PUT_GROUPS_FAIL';

export const DELETE_GROUP_START = 'DELETE_GROUP_START';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

export const SAVE_GROUPS_FILTERS = 'SAVE_GROUPS_FILTERS';
