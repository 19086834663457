// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-DCSnxl.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-h3DErG .admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-label-e3uqgK[data-value="Montserrat"]:before, .admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-DCSnxl.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-h3DErG .admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-item-G6p0x3[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-Montserrat-fsgBqB {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-r7cpSY, .admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-customFields-Z4DN1h {
  margin-top: 17px;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-customFields-Z4DN1h .ant-card .ant-card-body {
  padding: 20px 20px auto;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-card-x5KyPu {
  border-radius: 8px;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-content-hnfgPh {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__Tabs-list-w_NhO5 .ant-tabs-tab-btn {
  font-size: .8em;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__Tabs-list-w_NhO5 .ant-tabs-tab-icon {
  margin-right: 8px !important;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__VarDirectory-head-KgA8gK {
  align-items: center;
  display: flex;
}

.admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__VarDirectory-txt-ECbXOu {
  margin: 0 0 24px 16px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/VarDirectory/Tabs/TabsVarDirectory.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AASA;EANE,uBAAuB;AACzB;;AAQA;EALE,kBAAkB;AACpB;;AAOA;EAJE,8BAA8B;EAC9B,WAAW;EACX,aAAa;AACf;;AAOA;EAJE,eAAe;AACjB;;AAMA;EAHE,4BAA4B;AAC9B;;AAMA;EAHE,mBAAmB;EACnB,aAAa;AACf;;AAKA;EAFE,qBAAqB;AACvB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.TabMargin {\n  margin-top: 17px;\n}\n.TabMargin-customFields {\n  margin-top: 17px;\n}\n.TabMargin-customFields :global .ant-card .ant-card-body {\n  padding: 20px 20px auto;\n}\n.TabMargin-card {\n  border-radius: 8px;\n}\n.TabMargin-content {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.Tabs-list :global .ant-tabs-tab-btn {\n  font-size: 0.8em;\n}\n.Tabs-list :global .ant-tabs-tab-icon {\n  margin-right: 8px !important;\n}\n\n.VarDirectory-head {\n  display: flex;\n  align-items: center;\n}\n.VarDirectory-txt {\n  margin: 0 0 24px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-DCSnxl`,
	"qlPicker": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-DCSnxl`,
	"ql-font": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-h3DErG`,
	"qlFont": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-h3DErG`,
	"ql-picker-label": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-label-e3uqgK`,
	"qlPickerLabel": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-label-e3uqgK`,
	"ql-picker-item": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-item-G6p0x3`,
	"qlPickerItem": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-picker-item-G6p0x3`,
	"ql-font-Montserrat": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-Montserrat-fsgBqB`,
	"qlFontMontserrat": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__ql-font-Montserrat-fsgBqB`,
	"TabMargin": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-r7cpSY`,
	"tabMargin": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-r7cpSY`,
	"TabMargin-customFields": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-customFields-Z4DN1h`,
	"tabMarginCustomFields": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-customFields-Z4DN1h`,
	"TabMargin-card": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-card-x5KyPu`,
	"tabMarginCard": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-card-x5KyPu`,
	"TabMargin-content": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-content-hnfgPh`,
	"tabMarginContent": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__TabMargin-content-hnfgPh`,
	"Tabs-list": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__Tabs-list-w_NhO5`,
	"tabsList": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__Tabs-list-w_NhO5`,
	"VarDirectory-head": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__VarDirectory-head-KgA8gK`,
	"varDirectoryHead": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__VarDirectory-head-KgA8gK`,
	"VarDirectory-txt": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__VarDirectory-txt-ECbXOu`,
	"varDirectoryTxt": `admin-src-pages-VarDirectory-Tabs-TabsVarDirectory-module__VarDirectory-txt-ECbXOu`
};
export default ___CSS_LOADER_EXPORT___;
