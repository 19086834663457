import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addQuestionsToTest } from '@/store/lms/actions';
import { getQuestionsList, setDefaultSort } from '@/store/questionBank/actions';

import { selectQuestionBank } from '@/store/questionBank/selectors';

import { Button, Collapse, message, Modal, Popover, Table, Tag, Tooltip } from 'antd';
import BankFilter from '@/pages/QuestionBank/Content/BankFilter';
import { DEFAULT_FILTERS } from '@/pages/QuestionBank/Content/BankFilter/constants';
import BankTable from '@/pages/QuestionBank/Content/BankTable';

import Utils from '@/Utils';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import css from './AddFromBankModal.module.scss';

const { Panel } = Collapse;

const MAX_QUESTION_LENGTH = 30;
const MAX_TAGS_RENDER = 3;

const AddFromBankModal = ({ data, setData }) => {
  const { t } = useTranslation('AddFromBankModal');
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const { isSort } = useSelector(selectQuestionBank);

  useEffect(() => {
    return () => {
      dispatch(setDefaultSort()); // при размонтирование приводим к начальному состоянию сортировки
    };
  }, []);

  // Подготовка данных для таблицы, отвечает только за ключи
  useEffect(() => {
    const questions = _cloneDeep(data.questions);

    if (Array.isArray(questions) && questions.length > 0) {
      setSelectedRowKeys(questions);
    }
  }, [data.questions]);

  // Инициализация запроса при открытии + очистка данных при закрытии модального окна
  useEffect(() => {
    if (data.visible) {
      dispatch(getQuestionsList(filters, 0, isSort, 10));
    } else {
      setFilters(DEFAULT_FILTERS);
      setData(prevData => ({ ...prevData, questions: [] }));
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  }, [data.visible]);

  // Закрытие модального окна
  const onClose = () => setData(prevData => ({ ...prevData, visible: false }));

  // Отображения категорий в таблице
  const renderCategories = categories => {
    if (Array.isArray(categories) && !_isEmpty(categories)) {
      // Вплывающее окно с остальными тегами
      const popoverContent = categories.slice(MAX_TAGS_RENDER, categories.length).map(category => (
        <Tag key={category.id} className={css['AddFromBankModal-tag']}>
          {category.name}
        </Tag>
      ));

      return (
        <>
          {categories.map(
            (category, index) =>
              MAX_TAGS_RENDER > index && (
                <Tooltip key={category.id} title={category.name.length > MAX_QUESTION_LENGTH && category.name}>
                  <Tag className={css['AddFromBankModal-tag']}>
                    {Utils.trString(category.name, MAX_QUESTION_LENGTH)}
                  </Tag>
                </Tooltip>
              )
          )}

          {MAX_TAGS_RENDER < categories.length && (
            <Popover content={<div className={css['AddFromBankModal-tag-popover']}>{popoverContent}</div>}>
              <Button type='link' size='small'>
                {t('more', { count: categories.length - MAX_TAGS_RENDER })}
              </Button>
            </Popover>
          )}
        </>
      );
    }
  };
  const deleteQuestions = key => {
    // удаление чекбоксов ключей из таблицы
    const filteredSelectedKeys = selectedRowKeys.filter(i => i !== key);
    setSelectedRowKeys(filteredSelectedKeys);
    // удаление записей из таблицы
    const filteredSelectedItems = selectedRows.filter(j => j.key !== key && !data.questions.includes(j.key));
    setSelectedRows(filteredSelectedItems);
  };

  const columns = [
    {
      title: t('question'),
      dataIndex: 'question',
      key: 'question',
      width: '50%',
      render: text => (
        <Tooltip title={text.length > MAX_QUESTION_LENGTH && text}>{Utils.trString(text, MAX_QUESTION_LENGTH)}</Tooltip>
      ),
    },
    {
      title: t('answerType'),
      dataIndex: 'answerType',
      key: 'answerType',
      width: '25%',
      render: (text, { actions }) => {
        switch (actions.multipleAnswers) {
          case true:
            return t('answerTypes.1');

          case false:
            if (actions.type === 0 || actions.type === 1) {
              return t('answerTypes.0');
            } else {
              return t('answerTypes.2');
            }

          default:
            return '';
        }
      },
    },
    {
      title: t('category'),
      dataIndex: 'questionCategories',
      key: 'questionCategories',
      width: '25%',
      render: (text, record) => renderCategories(record.category),
    },
    {
      title: '',
      key: 'actions',
      render: (text, record) => (
        <Button type='link' onClick={() => deleteQuestions(record?.key)}>
          {t('deleteNewQuestion')}
        </Button>
      ),
    },
  ];

  const onSelectChange = (keys, rows, { type }) => {
    if (type === 'all') {
      if (keys.length === 0) {
        setSelectedRows([]);
      } else {
        setSelectedRows(rows);
      }
    }
    setSelectedRowKeys(keys);
  };

  const onSelect = newSelectedRow => {
    const arr = selectedRows;
    const index = arr.findIndex(item => item.key === newSelectedRow.key);

    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(newSelectedRow);
    }
    setSelectedRows(arr);
  };

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: onSelectChange,
      getCheckboxProps: record => ({
        disabled: data.questions.includes(record.key),
      }),
      onSelect: onSelect,
    }),
    [selectedRowKeys]
  );

  const onlyNewQuestions = useMemo(() => {
    return selectedRows.filter(item => !data.questions.includes(item.key));
  }, [selectedRows?.length]);

  const onAddQuestionsInTest = () => {
    const correctData = onlyNewQuestions.map(item => item.key);

    dispatch(
      addQuestionsToTest(
        data.testId,
        correctData,
        () => setData(prevData => ({ ...prevData, visible: false })),
        error => message.error(error?.message)
      )
    );
  };

  return (
    <Modal
      title={t('title')}
      open={data.visible}
      width={1200}
      onCancel={onClose}
      okText={t('addToTest')}
      onOk={onAddQuestionsInTest}
      okButtonProps={{ disabled: onlyNewQuestions.length <= 0 }}
    >
      <BankFilter filters={filters} setFilters={setFilters} inModal={true} />
      <Collapse className={css['AddFromBankModal-collapse']}>
        <Panel header={t('selectedQuestions')} key='1'>
          <Table
            className={css['AddFromBankModal-collapse-table']}
            key='selected'
            columns={columns}
            dataSource={onlyNewQuestions}
            pagination={false}
          />
        </Panel>
      </Collapse>
      <BankTable canSelect={true} filters={filters} rowSelection={rowSelection} />
    </Modal>
  );
};

AddFromBankModal.propTypes = {
  data: PropTypes.shape({
    questions: PropTypes.array,
    testId: PropTypes.number,
    visible: PropTypes.bool,
  }),
  setData: PropTypes.func,
};

export default AddFromBankModal;
