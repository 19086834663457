/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Button, Form, Input, TimePicker } from 'antd';
import Tag from '@/components/Tag';
import { CloseOutlined } from '@ant-design/icons';

import CustomDateSomePicker from '@shared/components/DatePicker/DateSomePicker';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../Topics/TopicCreationDialog/TopicCreationDialog.module.scss';

import { EVENT_PERIOD_FIELD } from '@/constants/topic-types';

const { TextArea } = Input;

const formatTime = 'HH:mm';

export class EventPeriod extends Component {
  static propTypes = {
    alone: PropTypes.bool,
    date: PropTypes.array,
    deletePeriod: PropTypes.func,
    endTime: PropTypes.string,
    errors: PropTypes.object,
    formValidation: PropTypes.func,
    handleChangePeriod: PropTypes.func,
    handleValidationPeriod: PropTypes.func,
    id: PropTypes.number,
    isChangeCheck: PropTypes.func,
    isSaving: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isValidationStart: PropTypes.string,
    place: PropTypes.string,
    startTime: PropTypes.string,
    requiredPlace: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      timeTag: [],
      date: null,
      startTime: this.props.startTime || '',
      endTime: this.props.endTime || '',
      errors: {},
      errorsMain: this.props.errors || {},
      place: this.props.place || '',
    };
  }

  componentDidMount() {
    if (this.props.date.length) {
      this.setState({
        date: this.props.date,
      });
      this.showTimeTag(this.props.date);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.isSaving !== prevProps.isSaving && this.props.isSaving) ||
      (this.props.isUpdating !== prevProps.isUpdating && this.props.isUpdating)
    ) {
      this.props.handleChangePeriod(
        this.state.date,
        this.state.startTime,
        this.state.endTime,
        this.props.id,
        this.state.place
      );
    }

    if (this.props.isValidationStart !== prevProps.isValidationStart && this.props.isValidationStart !== '') {
      this.props.handleValidationPeriod(this.props.id, this.formValidation());
    }
  }

  isFieldEmpty = fieldName => {
    if (typeof this.state[fieldName] === 'boolean') {
      return false;
    }
    if (typeof this.state[fieldName] === 'number') {
      return this.state[fieldName] <= 0;
    }

    if (Array.isArray(this.state[fieldName])) {
      return !this.state[fieldName].length;
    }
    return !this.state[fieldName];
  };

  formValidation = () => {
    let errors = {};
    for (let i = 0; i < EVENT_PERIOD_FIELD.length; i++) {
      const field = EVENT_PERIOD_FIELD[i];

      if (field.required && this.isFieldEmpty(field.apiField)) {
        errors[field.apiField] = this.props.t('mandatory');
      }
    }

    if (this.props.requiredPlace && !this.state.place) {
      errors = { ...errors, place: this.props.t('mandatory') };
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  deleteDate = (event, id) => {
    const arr = [...this.state.date];
    arr.splice(id, 1);
    this.setState({ date: arr });
    this.showTimeTag(arr);
    this.props.isChangeCheck();
  };

  showTimeTag = time => {
    this.setState({
      timeTag:
        time &&
        time.map((tt, key) => ({
          id: key,
          name: dayjs(tt).format('DD.MM.YYYY'),
        })),
    });
  };

  handleDateTimeChange = (currentTime, type) => {
    this.setState({ [type]: currentTime ? dayjs(currentTime, formatTime).format(formatTime) : '' });
    this.props.isChangeCheck();
  };

  deletePeriod = () => {
    this.props.deletePeriod(this.props.id);
  };

  handlePlaceChange = event => {
    this.setState({ place: event.target.value });
    this.props.isChangeCheck();
  };

  render() {
    const { t } = this.props;

    const timeTag = this.state?.timeTag?.map(tt => <Tag key={tt.id} tag={tt} onDelete={this.deleteDate} />);
    return (
      <Form.Item
        label={t('dates')}
        help={this.state.errors.date}
        validateStatus={this.state.errors.date ? 'error' : ''}
        required
      >
        <div className={css['Add-modal-block']}>
          <CustomDateSomePicker
            value={this.state.date}
            onChange={e => {
              this.showTimeTag(e);
              this.setState({ date: e });
              this.props.isChangeCheck();
            }}
            error={!!this.state?.errors?.date}
          />
          <span className={css['Add-modal-body-event']}>
            <Form.Item
              label={t('start')}
              help={this.state.errors.startTime}
              validateStatus={this.state.errors.startTime ? 'error' : ''}
              required
              labelAlign='left'
              labelCol={{ span: 14 }}
            >
              <TimePicker
                onChange={(obj, time) => this.handleDateTimeChange(time, 'startTime')}
                placeholder='10:00'
                value={this.state.startTime ? dayjs(this.state.startTime, formatTime) : null}
                format={formatTime}
              />
            </Form.Item>
            <Form.Item
              label={t('end')}
              help={this.state.errors.endTime}
              validateStatus={this.state.errors.endTime ? 'error' : ''}
              required
              labelAlign='left'
              labelCol={{ span: 14 }}
            >
              <TimePicker
                onChange={(obj, time) => this.handleDateTimeChange(time, 'endTime')}
                placeholder='23:59'
                value={this.state.endTime ? dayjs(this.state.endTime, formatTime) : null}
                format={formatTime}
              />
            </Form.Item>
            <Form.Item
              required={this.props.requiredPlace}
              help={this.state.errors?.place}
              validateStatus={this.state.errors.place ? 'error' : ''}
              label={t('place')}
            >
              <TextArea
                maxLength='500'
                rows={3}
                placeholder={t('location')}
                className={css['Add-modal-textarea']}
                name='place'
                value={this.state.place}
                onChange={this.handlePlaceChange}
              />
            </Form.Item>
            <span className={css['Add-modal-body-time']}>{timeTag}</span>
          </span>
          {!this.props.alone ? (
            <Button className={css['Add-modal-block-close']} ghost onClick={this.deletePeriod}>
              <CloseOutlined />
            </Button>
          ) : null}
        </div>
      </Form.Item>
    );
  }
}

export default connect()(withTranslation('eventPeriod')(EventPeriod));
