/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import css from './LogoCompany.module.scss';

class LogoCompany extends Component {
  static propTypes = {
    className: PropTypes.string,
    imageLogo: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageLogo: this.props.imageLogo,
    };
  }

  render() {
    return <img className={css.Logo} src={this.props.imageLogo || this.state.imageLogo} alt='' />;
  }
}

export default LogoCompany;
