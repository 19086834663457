/* eslint-disable */
import { UPDATE_EVENT_SUCCESS } from '@/store/courses/actions';

import {
  ACTIVE_STATUS_FAIL,
  ACTIVE_STATUS_START,
  ACTIVE_STATUS_SUCCESS,
  CHANGE_DEPEND_FAIL,
  CHANGE_DEPEND_START,
  CHANGE_DEPEND_SUCCESS,
  CHANGE_LOGGING_ADMIN_FILTER,
  CHANGE_LOGGING_FILTER,
  CHANGE_POSITION_TOPIC_FAIL,
  CHANGE_POSITION_TOPIC_START,
  CHANGE_POSITION_TOPIC_SUCCESS,
  CHANGE_SCOPE_FAIL,
  CHANGE_SCOPE_START,
  CHANGE_SCOPE_SUCCESS,
  CHANGE_TOPIC_FILTER,
  CHECK_DELETE_TOPIC_FAIL,
  CHECK_DELETE_TOPIC_START,
  CHECK_DELETE_TOPIC_SUCCESS,
  CLEAR_LOGGING_FILTER,
  CLEAR_TOPIC_FILTER,
  CLEAR_TOPICS,
  CLEAR_TOPICS_COMPILATION,
  DELETE_AUTHORS_FAIL,
  DELETE_AUTHORS_START,
  DELETE_AUTHORS_SUCCESS,
  DELETE_TOPIC_FAIL,
  DELETE_TOPIC_START,
  DELETE_TOPIC_SUCCESS,
  GET_ACTIONS_FAIL,
  GET_ACTIONS_START,
  GET_ACTIONS_SUCCESS,
  GET_ADMIN_LOGGING_FAIL,
  GET_ADMIN_LOGGING_START,
  GET_ADMIN_LOGGING_SUCCESS,
  GET_COURSES_EXPORT_FAIL,
  GET_COURSES_EXPORT_START,
  GET_COURSES_EXPORT_SUCCESS,
  GET_LOGGING_ADMIN_EXPORT_FAIL,
  GET_LOGGING_ADMIN_EXPORT_START,
  GET_LOGGING_ADMIN_EXPORT_SUCCESS,
  GET_LOGGING_EXPORT_FAIL,
  GET_LOGGING_EXPORT_START,
  GET_LOGGING_EXPORT_SUCCESS,
  GET_LOGGING_FAIL,
  GET_LOGGING_START,
  GET_LOGGING_SUCCESS,
  GET_MATERIAL_EXPORT_FAIL,
  GET_MATERIAL_EXPORT_START,
  GET_MATERIAL_EXPORT_SUCCESS,
  GET_MERITS_FAIL,
  GET_MERITS_START,
  GET_MERITS_SUCCESS,
  GET_SISTEM_ACTION_FAIL,
  GET_SISTEM_ACTION_START,
  GET_SISTEM_ACTION_SUCCESS,
  GET_TOPIC_BY_ID_FAIL,
  GET_TOPIC_BY_ID_START,
  GET_TOPIC_BY_ID_SUCCESS,
  GET_TOPIC_CATEGORY_FAIL,
  GET_TOPIC_CATEGORY_START,
  GET_TOPIC_CATEGORY_SUCCESS,
  GET_TOPIC_CLASSES_FAIL,
  GET_TOPIC_CLASSES_START,
  GET_TOPIC_CLASSES_SUCCESS,
  GET_TOPIC_COMPETENCIES_FAIL,
  GET_TOPIC_COMPETENCIES_START,
  GET_TOPIC_COMPETENCIES_SUCCESS,
  GET_TOPIC_JOB_LEVEL_FAIL,
  GET_TOPIC_JOB_LEVEL_START,
  GET_TOPIC_JOB_LEVEL_SUCCESS,
  GET_TOPIC_LEVEL_FAIL,
  GET_TOPIC_LEVEL_START,
  GET_TOPIC_LEVEL_SUCCESS,
  GET_TOPIC_MANDATORY_FAIL,
  GET_TOPIC_MANDATORY_START,
  GET_TOPIC_MANDATORY_SUCCESS,
  GET_TOPIC_PINNED_COUNT_FAIL,
  GET_TOPIC_PINNED_COUNT_START,
  GET_TOPIC_PINNED_COUNT_SUCCESS,
  GET_TOPIC_SECTION_SUCCESS,
  GET_TOPIC_SUBTYPES_FAIL,
  GET_TOPIC_SUBTYPES_START,
  GET_TOPIC_SUBTYPES_SUCCESS,
  GET_TOPIC_TYPES_AUTO_START,
  GET_TOPIC_TYPES_AUTO_SUCCESS,
  GET_TOPIC_TYPES_FAIL,
  GET_TOPIC_TYPES_START,
  GET_TOPIC_TYPES_SUCCESS,
  GET_TOPIC_VIDEO_TYPES_FAIL,
  GET_TOPIC_VIDEO_TYPES_START,
  GET_TOPIC_VIDEO_TYPES_SUCCESS,
  GET_TOPICS_COMPILATION_FAIL,
  GET_TOPICS_COMPILATION_START,
  GET_TOPICS_COMPILATION_SUCCESS,
  GET_TOPICS_COUNT_FAIL,
  GET_TOPICS_COUNT_START,
  GET_TOPICS_COUNT_SUCCESS,
  GET_TOPICS_FAIL,
  GET_TOPICS_START,
  GET_TOPICS_SUCCESS,
  POST_TOPIC_FAIL,
  POST_TOPIC_START,
  POST_TOPIC_SUCCESS,
  UPDATE_TOPIC_FAIL,
  UPDATE_TOPIC_SEQUENCE_START,
  UPDATE_TOPIC_SEQUENCE_SUCCESS,
  UPDATE_TOPIC_START,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_VISIBILITY_START,
  UPDATE_TOPIC_VISIBILITY_SUCCESS,
} from './actions';

const initialState = {
  topics: [],
  topicsForColect: [],
  topicsCompilation: [],
  topicsTotal: null,
  topicsPageSize: null,
  topicsPageNumber: null,
  topicsCount: null,
  topic: null,
  topicFilter: {},
  loggingFilter: {},
  loggingAdminFilter: {},
  logging: [],
  loggingTotal: null,
  loggingPageSize: null,
  loggingPageNumber: null,
  types: null,
  typesAuto: [],
  subtypes: null,
  categories: null,
  topicVideoTypes: null,
  topicClasses: null,
  level: null,
  isLoading: false,
  isLoadingTopics: false,
  error: false,
  loggingAdmin: [],
  loggingAdminTotal: null,
  loggingAdminPageSize: null,
  loggingAdminPageNumber: null,
  topicSection: null,
  merits: null,
  pinnedTopicsCount: null,
  isLoadingExport: false,
  isLogSystem: false,
  isExportMaterial: false,
  topicCategoryCompetencies: [],
  topicMandatories: [],
  jobLevel: [],
  isLoadingExportCourses: false,
};

export default (state = initialState, { type, response, info, headers, action }) => {
  switch (type) {
    case GET_TOPIC_TYPES_START:
      return { ...state, types: null, isLoading: true, error: false };

    case GET_TOPIC_TYPES_SUCCESS:
      return { ...state, types: response, isLoading: false };

    case GET_TOPIC_TYPES_AUTO_START:
      return { ...state, typesAuto: [], error: false };

    case GET_TOPIC_TYPES_AUTO_SUCCESS:
      return { ...state, typesAuto: response, isLoading: false };

    case GET_TOPIC_SUBTYPES_START:
      return { ...state, subtypes: null, isLoading: true, error: false };

    case GET_TOPIC_SUBTYPES_SUCCESS:
      return { ...state, subtypes: response, isLoading: false };

    case DELETE_AUTHORS_START:
      return { ...state, isLoading: true, error: false };

    case GET_MERITS_START:
      return { ...state, merits: null, isLoading: true, error: false };

    case GET_MATERIAL_EXPORT_START:
      return { ...state, isExportMaterial: true, error: false };

    case GET_MERITS_SUCCESS:
      return { ...state, merits: response, isLoading: false };

    case GET_MATERIAL_EXPORT_SUCCESS:
      return { ...state, isExportMaterial: false };

    case GET_MERITS_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_MATERIAL_EXPORT_FAIL:
      return { ...state, isExportMaterial: false, error: true };

    case GET_TOPICS_COMPILATION_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPIC_COMPETENCIES_START:
    case GET_TOPIC_MANDATORY_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPICS_COMPILATION_FAIL:
      return {
        ...state,
        error: true,
        topicsCompilation: [],
        topicsCompilationTotal: null,
        topicsCompilationPageSize: null,
        topicsCompilationPageNumber: null,
        isLoading: false,
      };

    case CLEAR_TOPICS_COMPILATION:
      return {
        ...state,
        error: false,
        topicsCompilation: [],
        topicsCompilationTotal: null,
        topicsCompilationPageSize: null,
        topicsCompilationPageNumber: null,
        isLoading: false,
      };

    case GET_TOPICS_COMPILATION_SUCCESS:
      return {
        ...state,
        topicsCompilation:
          Number(headers.get('X-Page-Number')) === 0 ? response : [...state.topicsCompilation, ...response],
        topicsCompilationTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsCompilationPageSize: Number(headers.get('X-Page-Size')) || 0,
        topicsCompilationPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_TOPICS_START: {
      const needClear =
        !action.page && (action.name || action.topicTypeLabelId || action.topicTypeId || !action.isUserModal);
      return {
        ...state,
        topics: needClear ? [] : state.topics,
        topicsForColect: !action.forCollection ? [] : state.topicsForColect,
        isLoadingTopics: true,
        topicsTotal: null,
        topicsPageSize: null,
        topicsPageNumber: null,
        error: false,
      };
    }

    case GET_TOPICS_SUCCESS: {
      // const needClear =
      //   action.withClear ||
      //   (!action.page && (action.name || action.topicTypeLabelId || action.topicTypeId || !action.isUserModal));
      // const topics = needClear
      const topics =
        action.page === 0
          ? response
          : [...state.topics, ...response.filter(topic => !state.topicsForColect.some(t => t.id === topic.id))];
      const topicsForColect = !action.forCollection
        ? response
        : [...state.topicsForColect, ...response.filter(topic => !state.topicsForColect.some(t => t.id === topic.id))];
      return {
        ...state,
        topics,
        topicsForColect,
        topicsTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsPageSize: Number(headers.get('X-Page-Size')) || 0,
        topicsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoadingTopics: false,
      };
    }

    case CHANGE_DEPEND_START:
    case GET_SISTEM_ACTION_START:
    case CHECK_DELETE_TOPIC_START:
    case GET_TOPICS_COUNT_START:
      return { ...state, isLoading: true, error: false };

    case GET_LOGGING_EXPORT_START:
      return { ...state, isLoadingExport: true, error: false };

    case GET_COURSES_EXPORT_START:
      return { ...state, isLoadingExportCourses: true, error: false };

    case GET_LOGGING_ADMIN_EXPORT_START:
      return { ...state, isLogSystem: true, error: false };

    case CHANGE_DEPEND_SUCCESS:
    case DELETE_AUTHORS_SUCCESS:
    case CHECK_DELETE_TOPIC_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case GET_LOGGING_ADMIN_EXPORT_SUCCESS:
      return { ...state, isLogSystem: false, error: false };

    case GET_LOGGING_EXPORT_SUCCESS:
      return { ...state, isLoadingExport: false, error: false };

    case GET_COURSES_EXPORT_SUCCESS:
      return { ...state, isLoadingExportCourses: false, error: false };

    case GET_TOPICS_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        topicsCount: response,
      };

    case GET_SISTEM_ACTION_SUCCESS:
      return {
        ...state,
        sistemActionsList: response,
        error: false,
        isLoading: false,
      };

    case GET_TOPICS_COUNT_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_TOPIC_BY_ID_START:
      return { ...state, topic: null, isLoading: true, error: false };

    case GET_TOPIC_BY_ID_SUCCESS:
      return { ...state, topic: response, isLoading: false };

    case GET_TOPIC_CATEGORY_START:
      return { ...state, categories: null, isLoading: true, error: false };

    case GET_TOPIC_CATEGORY_SUCCESS:
      return { ...state, categories: response, isLoading: false };

    case GET_TOPIC_VIDEO_TYPES_START:
      return { ...state, topicVideoTypes: null, isLoading: true, error: false };

    case GET_TOPIC_VIDEO_TYPES_SUCCESS:
      return { ...state, topicVideoTypes: response, isLoading: false };

    case GET_TOPIC_LEVEL_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPIC_LEVEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        level: response.sort(function (a, b) {
          if (a.id < b.id) return 1;
          if (a.id > b.id) return -1;
          return 0;
        }),
      };
    case CHANGE_DEPEND_FAIL:
    case DELETE_AUTHORS_FAIL:
    case GET_TOPIC_LEVEL_FAIL:
      return { ...state, isLoading: false, error: true };

    case ACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        topics: [...state.topics.map(item => (item.id === action.topicId ? { ...item, status: action.status } : item))],
      };

    case GET_TOPIC_CLASSES_START:
      return { ...state, topicClasses: null, isLoading: true, error: false };

    case GET_TOPIC_CLASSES_SUCCESS:
      return { ...state, topicClasses: response, isLoading: false };

    case GET_ACTIONS_START:
      return { ...state, isLoading: true, error: false, actionsLog: null };

    case GET_ACTIONS_SUCCESS:
      return { ...state, isLoading: false, error: false, actionsLog: response };

    case GET_ACTIONS_FAIL:
      return { ...state, isLoading: false, error: true };

    case POST_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case GET_LOGGING_START:
    case GET_ADMIN_LOGGING_START:
      return { ...state, isLoading: true, error: false };

    case GET_LOGGING_SUCCESS:
      return {
        ...state,
        logging: response.content,
        isLoading: false,
        loggingTotal: response.totalElements || 0,
        loggingPageSize: response.size || 0,
        loggingPageNumber: response.number || 0,
        error: false,
      };

    case GET_ADMIN_LOGGING_SUCCESS:
      return {
        ...state,
        loggingAdmin: response.content,
        isLoading: false,
        loggingAdminTotal: response.totalElements || 0,
        loggingAdminPageSize: response.size || 0,
        loggingAdminPageNumber: response.number || 0,
        error: false,
      };

    case GET_LOGGING_FAIL:
      return { ...state, isLoading: false, error: true };

    case POST_TOPIC_SUCCESS:
      return { ...state, topic: response, isLoading: false };

    case UPDATE_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_TOPIC_SUCCESS:
      return { ...state, topic: response, isLoading: false };

    case UPDATE_TOPIC_VISIBILITY_START:
    case UPDATE_TOPIC_SEQUENCE_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_TOPIC_VISIBILITY_SUCCESS:
      return {
        ...state,
        topic: { ...state.topic, visible: action.isVisible },
        isLoading: false,
      };

    case UPDATE_TOPIC_SEQUENCE_SUCCESS:
      return {
        ...state,
        topic: { ...state.topic, followSequence: !state.topic.followSequence },
        isLoading: false,
      };

    case UPDATE_EVENT_SUCCESS:
      return { ...state, topic: response.topic, isLoading: false };

    case DELETE_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case DELETE_TOPIC_SUCCESS:
      return { ...state, isLoading: false };

    case CHANGE_POSITION_TOPIC_START:
      return { ...state, isLoadingTopics: true };

    case CHANGE_POSITION_TOPIC_SUCCESS:
      const updatedTopics = state.topics.map(item => {
        return item.id === action.topicId ? { ...item, position: action.position } : item;
      });

      let updatedPinnedCount = state.pinnedTopicsCount;

      if (action.position === 0 && updatedPinnedCount !== 0) updatedPinnedCount -= 1;
      if (action.position > state.pinnedTopicsCount) updatedPinnedCount += 1;

      return {
        ...state,
        isLoadingTopics: false,
        topics: [...updatedTopics],
        pinnedTopicsCount: updatedPinnedCount,
      };
    case CHANGE_SCOPE_START:
      return { ...state, isLoading: true };

    case CHANGE_SCOPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        topic: { ...state.topic, scope: response[0].scope },
        error: false,
      };

    case CHANGE_SCOPE_FAIL:
      return { ...state, isLoading: false, error: true };

    case CHANGE_LOGGING_FILTER:
      return { ...state, loggingFilter: info };

    case CHANGE_LOGGING_ADMIN_FILTER:
      return { ...state, loggingAdminFilter: info };

    case CHANGE_TOPIC_FILTER:
      const topicFilter = { ...state.topicFilter, [info.name]: info.value };
      return { ...state, topicFilter };

    case CLEAR_LOGGING_FILTER:
      return { ...state, loggingFilter: initialState.loggingFilter, loggingPageNumber: initialState.loggingPageNumber };
    case CLEAR_TOPIC_FILTER:
      return { ...state, topicFilter: initialState.topicFilter, topicsPageNumber: initialState.topicsPageNumber };
    case CLEAR_TOPICS:
      return { ...state, topicsForColect: [] };

    case GET_TOPIC_COMPETENCIES_SUCCESS:
      return { ...state, isLoading: false, topicCategoryCompetencies: response };

    case GET_TOPIC_MANDATORY_SUCCESS:
      return { ...state, isLoading: false, topicMandatories: response };

    case GET_TOPIC_JOB_LEVEL_START:
      return { ...state, isLoading: true, jobLevel: [] };

    case GET_TOPIC_JOB_LEVEL_SUCCESS:
      return { ...state, isLoading: false, jobLevel: response };

    case GET_SISTEM_ACTION_FAIL:
    case GET_ADMIN_LOGGING_FAIL:
    case CHANGE_POSITION_TOPIC_FAIL:
    case CHECK_DELETE_TOPIC_FAIL:
    case POST_TOPIC_FAIL:
    case GET_TOPIC_TYPES_FAIL:
    case GET_TOPIC_CATEGORY_FAIL:
    case GET_TOPIC_VIDEO_TYPES_FAIL:
    case GET_TOPIC_BY_ID_FAIL:
    case UPDATE_TOPIC_FAIL:
    case DELETE_TOPIC_FAIL:
    case GET_TOPIC_SUBTYPES_FAIL:
    case GET_TOPIC_CLASSES_FAIL:
    case GET_TOPIC_PINNED_COUNT_FAIL:
    case GET_TOPIC_COMPETENCIES_FAIL:
    case GET_TOPIC_MANDATORY_FAIL:
    case GET_TOPIC_JOB_LEVEL_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_TOPICS_FAIL:
      return { ...state, error: true, isLoadingTopics: false };

    case GET_LOGGING_EXPORT_FAIL:
      return { ...state, error: true, isLoadingExport: false };

    case GET_COURSES_EXPORT_FAIL:
      return { ...state, error: true, isLoadingExportCourses: false };

    case GET_LOGGING_ADMIN_EXPORT_FAIL:
      return { ...state, error: true, isLogSystem: false };

    case GET_TOPIC_SECTION_SUCCESS:
      return { ...state, topicSection: response };
    case GET_TOPIC_PINNED_COUNT_START:
      return { ...state, isLoading: true };

    case GET_TOPIC_PINNED_COUNT_SUCCESS:
      return { ...state, isLoading: false, pinnedTopicsCount: response };

    default:
      return state;
  }
};
