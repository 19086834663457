export const POST_COMPILATION_START = 'POST_COMPILATION_START';
export const POST_COMPILATION_SUCCESS = 'POST_COMPILATION_SUCCESS';
export const POST_COMPILATION_FAIL = 'POST_COMPILATION_FAIL';

export const CHANGE_VISIBILITY_COMPILATION_START = 'CHANGE_VISIBILITY_COMPILATION_START';
export const CHANGE_VISIBILITY_COMPILATION_SUCCESS = 'CHANGE_VISIBILITY_COMPILATION_SUCCESS';
export const CHANGE_VISIBILITY_COMPILATION_FAIL = 'CHANGE_VISIBILITY_COMPILATION_FAIL';

export const GET_THEMES_START = 'GET_THEMES_START';
export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS';
export const GET_THEMES_FAIL = 'GET_THEMES_FAIL';

export const UPDATE_THEME_START = 'UPDATE_THEME_START';
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS';
export const UPDATE_THEME_FAIL = 'UPDATE_THEME_FAIL';

export const POST_THEME_START = 'POST_THEME_START';
export const POST_THEME_SUCCESS = 'POST_THEME_SUCCESS';
export const POST_THEME_FAIL = 'POST_THEME_FAIL';

export const DELETE_THEME_START = 'DELETE_THEME_START';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_THEME_FAIL = 'DELETE_THEME_FAIL';

export const GET_COMPILATIONS_START = 'GET_COMPILATIONS_START';
export const GET_COMPILATIONS_SUCCESS = 'GET_COMPILATIONS_SUCCESS';
export const GET_COMPILATIONS_FAIL = 'GET_COMPILATIONS_FAIL';

export const CLEAR_COMPILATION = 'CLEAR_COMPILATION';
export const CLEAR_PAGE = 'CLEAR_PAGE';
export const GET_COMPILATION_START = 'GET_COMPILATION_START';
export const GET_COMPILATION_SUCCESS = 'GET_COMPILATION_SUCCESS';
export const GET_COMPILATION_FAIL = 'GET_COMPILATION_FAIL';

export const UPDATE_COMPILATION_START = 'UPDATE_COMPILATION_START';
export const UPDATE_COMPILATION_SUCCESS = 'UPDATE_COMPILATION_SUCCESS';
export const UPDATE_COMPILATION_FAIL = 'UPDATE_COMPILATION_FAIL';

export const DELETE_COMPILATION_START = 'DELETE_COMPILATION_START';
export const DELETE_COMPILATION_SUCCESS = 'DELETE_COMPILATION_SUCCESS';
export const DELETE_COMPILATION_FAIL = 'DELETE_COMPILATION_FAIL';

export const GET_COMPILATIONS_COMMENTS_START = 'GET_COMPILATIONS_COMMENTS_START';
export const GET_COMPILATIONS_COMMENTS_SUCCESS = 'GET_COMPILATIONS_COMMENTS_SUCCESS';
export const GET_COMPILATIONS_COMMENTS_FAIL = 'GET_COMPILATIONS_COMMENTS_FAIL';

export const UPDATE_CAREER_START = 'UPDATE_CAREER_START';
export const UPDATE_CAREER_SUCCESS = 'UPDATE_CAREER_SUCCESS';
export const UPDATE_CAREER_FAIL = 'UPDATE_CAREER_FAIL';

export const DELETE_CAREER_START = 'DELETE_CAREER_START';
export const DELETE_CAREER_SUCCESS = 'DELETE_CAREER_SUCCESS';
export const DELETE_CAREER_FAIL = 'DELETE_CAREER_FAIL';

export const ADD_COMPILATIONTOPLAN_START = 'ADD_COMPILATIONTOPLAN_START';
export const ADD_COMPILATIONTOPLAN_SUCCESS = 'ADD_COMPILATIONTOPLAN_SUCCESS';
export const ADD_COMPILATIONTOPLAN_FAIL = 'ADD_COMPILATIONTOPLAN_FAIL';

export const ADD_MASS_COMPILATIONTOPLAN_START = 'ADD_MASS_COMPILATIONTOPLAN_START';
export const ADD_MASS_COMPILATIONTOPLAN_SUCCESS = 'ADD_MASS_COMPILATIONTOPLAN_SUCCESS';
export const ADD_MASS_COMPILATIONTOPLAN_FAIL = 'ADD_MASS_COMPILATIONTOPLAN_FAIL';

export const REORDER_THEMES_REQUEST_START = 'REORDER_THEMES_REQUEST_START';
export const REORDER_THEMES_REQUEST_SUCCESS = 'REORDER_THEMES_REQUEST_SUCCESS';
export const REORDER_THEMES_REQUEST_FAIL = 'REORDER_THEMES_REQUEST_FAIL';

export const REORDER_THEMES = 'REORDER_THEMES';
