// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-sUipXP.admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-watNdg .admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-label-IvltzZ[data-value="Montserrat"]:before, .admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-sUipXP.admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-watNdg .admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-item-DwHV44[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-Montserrat-dO3Cks {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-ldZu3R {
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px 24px;
  display: flex;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-input-U_JZpC {
  width: 100%;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-title-nNEwyW {
  margin-bottom: 12px;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-export-Tii9LL {
  margin-bottom: 20px;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-table-dEEw4_ {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-body-ZVQGPW {
  border-top: 1px solid #f2f2f2;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 59px;
  display: flex;
}

.admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-body-overflow-AT8_AE {
  text-overflow: ellipsis;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/LoggingAdmin/LoggingAdmin.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;AACf;;AAMA;EAHE,WAAW;AACb;;AAKA;EAFE,mBAAmB;AACrB;;AAIA;EADE,mBAAmB;AACrB;;AAGA;EAAE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,aAAa;AACf;;AACA;EAEE,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.LoggingAdmin-filters {\n  background: #fff;\n  border-radius: 8px;\n  padding: 20px 24px;\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: column;\n}\n.LoggingAdmin-filters-input {\n  width: 100%;\n}\n.LoggingAdmin-filters-title {\n  margin-bottom: 12px;\n}\n.LoggingAdmin-export {\n  margin-bottom: 20px;\n}\n.LoggingAdmin-table {\n  background: #fff;\n  padding: 20px;\n  border-radius: 8px;\n}\n.LoggingAdmin-body {\n  display: flex;\n  width: 100%;\n  min-height: 59px;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n  border-top: 1px solid #f2f2f2;\n}\n.LoggingAdmin-body-overflow {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-sUipXP`,
	"qlPicker": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-sUipXP`,
	"ql-font": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-watNdg`,
	"qlFont": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-watNdg`,
	"ql-picker-label": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-label-IvltzZ`,
	"qlPickerLabel": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-label-IvltzZ`,
	"ql-picker-item": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-item-DwHV44`,
	"qlPickerItem": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-picker-item-DwHV44`,
	"ql-font-Montserrat": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-Montserrat-dO3Cks`,
	"qlFontMontserrat": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__ql-font-Montserrat-dO3Cks`,
	"LoggingAdmin-filters": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-ldZu3R`,
	"loggingAdminFilters": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-ldZu3R`,
	"LoggingAdmin-filters-input": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-input-U_JZpC`,
	"loggingAdminFiltersInput": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-input-U_JZpC`,
	"LoggingAdmin-filters-title": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-title-nNEwyW`,
	"loggingAdminFiltersTitle": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-filters-title-nNEwyW`,
	"LoggingAdmin-export": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-export-Tii9LL`,
	"loggingAdminExport": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-export-Tii9LL`,
	"LoggingAdmin-table": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-table-dEEw4_`,
	"loggingAdminTable": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-table-dEEw4_`,
	"LoggingAdmin-body": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-body-ZVQGPW`,
	"loggingAdminBody": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-body-ZVQGPW`,
	"LoggingAdmin-body-overflow": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-body-overflow-AT8_AE`,
	"loggingAdminBodyOverflow": `admin-src-pages-LoggingAdmin-LoggingAdmin-module__LoggingAdmin-body-overflow-AT8_AE`
};
export default ___CSS_LOADER_EXPORT___;
