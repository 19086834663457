/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getChapters } from '@/store/chapters/actions';
import { getExampleFile, importQuestionFile } from '@/store/import_question/actions';
import { getBlocks } from '@/store/lms/actions';
import {
  addBankCategories,
  getBankCategories,
  getCountCatQuestions,
  getRandomizerInfo,
  saveRandomizerInfo,
  setCountQuestions,
} from '@/store/questionBank/actions';

import { selectQuestionBank } from '@/store/questionBank/selectors';

import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Form,
  Input,
  message,
  Select,
  Spin,
  TimePicker,
  Tooltip,
} from 'antd';
import DictionaryImportDialog from '@/components/Dictionary/DictionaryImportDialog';
import { EllipsisOutlined, FileDoneOutlined } from '@ant-design/icons';

import { CollapseLMS } from '../../CollapseLMS';
import { Question } from '../Question/Question';
import {
  booleanToNumber,
  emptyAnswer,
  emptyMatchingAnswer,
  emptyQuestion,
  emptyTest,
  MATCHING,
  MATCHING_ID,
  MIN_MATCHING_ANSWERS,
  MULTIPLE,
  MULTIPLE_ID,
  OPEN,
  OPEN_ID,
  reorder,
  setPosition,
} from './helpers';

import { IMAGE_TYPES } from '@shared/constants/image-types';
import { isExecutiveTask } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import dayjs from 'dayjs';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import css from '../../style/blocksStyle.module.scss';

import {
  TOPIC_BLOCK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_DELETE,
  TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE,
  TOPIC_BLOCK_UPDATE,
} from '@/constants/permissions';

const { TextArea } = Input;
const { Option } = Select;

const ANSWERS_ARR = [
  {
    answer: '',
    correct: false,
    imgUuid: null,
    position: 0,
  },
  {
    answer: '',
    correct: false,
    imgUuid: null,
    position: 1,
  },
];

const MATCHING_ANSWERS_ARR = [
  {
    left: {
      isText: true,
      content: '',
    },
    right: {
      isText: true,
      content: '',
    },
    position: 0,
  },
  {
    left: {
      isText: true,
      content: '',
    },
    right: {
      isText: true,
      content: '',
    },
    position: 1,
  },
];

export const Test = ({
  data,
  editBlock,
  deleteBlock,
  saveBlock,
  getFile,
  createFileImage,
  files,
  forceClose,
  canEdit,
  currentUser,
  topicTypeId,
  handleAddToBank,
  handleAddFromBank,
}) => {
  const MATCHING_ANSWERS = 'matchingAnswers';
  const ANSWERS = 'answers';
  const dispatch = useDispatch();
  const { t } = useTranslation('test');

  const [block, setBlock] = useState(data.test || emptyTest);
  const [dirty, setDirty] = useState(true);
  const [keepOpen, setKeepOpen] = useState([]);
  const [importVisible, setImportVisible] = useState(false);
  const [randomizerInfo, setRandomizerInfo] = useState({
    questionCategoriesIds: [],
    questionCategories: [],
    randomizerCount: null,
  });

  const { categories, isLoadingRandomizer, isLoadingCategories, countQuestions, isLoadingCount, randomizerInfoSave } =
    useSelector(selectQuestionBank);

  const topicId = data.topicId;

  useEffect(() => {
    const newBlock = booleanToNumber(data.test || emptyTest);

    newBlock.questions.forEach(question => {
      if (question.matchingAnswers?.length) {
        let answerIndex = 0;
        question.matchingAnswers.sort((a, b) => {
          const aPosition = a.position !== null && a.position >= 0 ? a.position : answerIndex;
          const bPosition = b.position !== null && b.position >= 0 ? b.position : answerIndex;

          answerIndex++;

          return aPosition - bPosition;
        });
      }
    });

    setBlock(newBlock);
    getImages(newBlock);
  }, [data]);

  // Инициализация рандомайзера
  useEffect(() => {
    if (block.randomizer) {
      dispatch(getBankCategories('', 0, 500));
      dispatch(
        getRandomizerInfo(block.id, res => {
          if (res) {
            let idsCat = res?.questionCategories.map(c => c.id);
            let namesCat = res?.questionCategories.map(c => c.name);
            let questionCategoriesOpt = res?.questionCategories.map(c => ({
              value: c.id,
              label: c.name,
            }));

            setRandomizerInfo({
              ...randomizerInfo,
              questionCategoriesIds: idsCat,
              questionCategories: namesCat,
              randomizerCount: +res?.randomizerCount !== 0 ? +res.randomizerCount : null,
            });

            dispatch(saveRandomizerInfo(questionCategoriesOpt));
            dispatch(setCountQuestions(res?.totalQuestionsCount));
            dispatch(addBankCategories(res?.questionCategories));
          }
        })
      );
    }
  }, [block.randomizer]);

  const getImages = (currentBlock = block) => {
    currentBlock.questions.forEach((question, questionIndex) => {
      getCurrentFile({ photoUuid: question.imgUuid, index: questionIndex });

      if (question.type === MATCHING_ID) {
        question.matchingAnswers?.forEach((answer, answerIndex) => {
          const position = answer.position >= 0 ? answer.position : answerIndex;
          const fileName = answer.fileName || '';

          if (!answer.left.isText) {
            getCurrentFile({
              photoUuid: answer.left.content,
              index: questionIndex,
              answerIndex: position,
              name: 'imgUuid-left',
              fileName,
            });
          }
          if (!answer.right.isText) {
            getCurrentFile({
              photoUuid: answer.right.content,
              index: questionIndex,
              answerIndex: position,
              name: 'imgUuid-right',
              fileName,
            });
          }
        });
      } else {
        question.answers.forEach((answer, answerIndex) => {
          getCurrentFile({ photoUuid: answer.imgUuid, index: questionIndex, answerIndex });
        });
      }
    });
  };

  const getCurrentFile = ({ photoUuid, index, answerIndex, name, fileName }) => {
    if (!photoUuid) {
      return;
    }

    getFile(photoUuid, blob => {
      const imageUrl = URL.createObjectURL(blob);

      const imageName = name ? name : 'imgUuid';

      if (answerIndex !== undefined) {
        handleChangeAnswer({
          event: { name: imageName, value: photoUuid },
          index,
          indexAnswer: answerIndex,
          cropImageSrc: imageUrl,
          fileName,
        });
      } else {
        handleChangeQuestion({ name: imageName, value: photoUuid }, index, imageUrl);
      }

      setDirty(true);
    });
  };

  const header = useMemo(
    () => (
      <div className={css['Block-header']}>
        <FileDoneOutlined className={css['Block-header-item']} />
        <div className={css['Block-header-name']}>
          <Tooltip title={data.name} overlayInnerStyle={{ wordBreak: 'break-all' }}>
            {Utils.trString(data.name, 55)}
          </Tooltip>
        </div>
      </div>
    ),
    [data.name]
  );

  const saveButtonHandler = useCallback(
    e => {
      e.stopPropagation();
      const openArray = [];

      block.questions.forEach((item, index) => {
        if (item.id.toString().includes('fake')) {
          openArray.push(index);
        }

        if (item.matchingAnswers) {
          item.matchingAnswers.forEach((answer, position) => {
            answer.position = position;
          });
        }
      });

      setKeepOpen(openArray);

      const test = setPosition(booleanToNumber(_cloneDeep(block), false));

      if (block.randomizer) {
        test.randomizerCount = randomizerInfo.randomizerCount;
        test.questionCategoryIds = randomizerInfo.questionCategoriesIds;
      }
      if (test.questions.length === 0) {
        test.questions = [];
      }

      saveBlock({ ...data, test }, () => dispatch(getChapters(topicId)));
      // saveBlock({ ...data, test: test.questions.length ? test : null }, () => dispatch(getChapters(topicId)));

      setDirty(true);
    },
    [data, saveBlock, block, topicId, block.randomizer, randomizerInfo]
  );

  const handleEditClick = useCallback(() => {
    editBlock(data.id);
  }, [editBlock]);

  // const getCorrectQuestionAnswersCount = () => {
  //   let cqac = 0;
  //   /*
  //       block.questions.forEach(q=>{
  //           q.answers.forEach(a=>{
  //               if( a.correct === true ) {
  //                   cqac++;
  //               }
  //           });
  //       });*/

  //   if (block && block.questions && Array.isArray(block.questions)) {
  //     cqac = block.questions.length;
  //   }

  //   return cqac;
  // };

  // Удаление блока
  const handleDeleteClick = useCallback(() => {
    deleteBlock(data.id);
  }, [deleteBlock]);

  // Изменение в поле со временем
  const handleChangeTime = useCallback(
    (dayjs, time) => {
      setBlock(prevBlock => ({ ...prevBlock, time }));
      setDirty(false);
    },
    [block, setBlock]
  );

  // Изменение в текстовых полях
  const handleChange = useCallback(
    event => {
      const { name, value } = event.target || event;

      switch (name) {
        case 'pass':
          const summQuestions = +block.questions.length + +randomizerInfo.randomizerCount;
          if (+value <= summQuestions) {
            setBlock({ ...block, [name]: +value });
          }
          setDirty(false);
          break;

        case 'text':
          setBlock({ ...block, [name]: value });
          setDirty(false);
          break;

        case 'allowedCount':
          setBlock({ ...block, [name]: value });
          setDirty(false);
          break;
      }
    },
    [setBlock, block, randomizerInfo.randomizerCount]
  );

  const handleChangeCheck = useCallback(
    event => {
      const { name, checked } = event.target || event;

      switch (name) {
        case 'poll':
          if (checked) {
            block.allowedCount = 1;
            block.pass = 0;
            block.extendedResults = false;
            block.randomizer = false;
            block.text = '';
            block.time = null;
          }
          setBlock({ ...block, [name]: checked });
          setDirty(false);
          break;

        case 'mixQuestions':
        case 'mixAnswers':
          setBlock({ ...block, [name]: checked });
          setDirty(false);
          break;

        case 'review':
        case 'extendedResults':
          setBlock({ ...block, [name]: checked });
          setDirty(false);
          break;

        case 'randomizer':
          block.poll = false;

          if (!checked) {
            setRandomizerInfo({ questionCategoriesIds: [], questionCategories: [], randomizerCount: null });
            block.pass = 0;
            block.randomizer = false;
          }

          setBlock({ ...block, [name]: checked });

          if (checked && !randomizerInfo.randomizerCount) {
            setDirty(true);
          } else {
            setDirty(false);
          }
          setDirty(false);
          break;
      }
    },
    [setBlock, block, randomizerInfo.randomizerCount]
  );

  //  Изменение вопроса

  const handleChangeQuestion = useCallback(
    (event, index, cropImageSrc, coverColor) => {
      const { name, value } = event.target || event;

      setBlock(prevBlock => {
        const questions = [...prevBlock.questions];
        const currentQuestion = questions[index];

        // Если текущий answerType === OPEN, то возвращаем ответы
        if (name === 'answerType' && (currentQuestion.answerType === OPEN || currentQuestion.answerType === MATCHING)) {
          currentQuestion.answers = ANSWERS_ARR;
        }

        currentQuestion[name] = value;
        questions[index][name] = value;

        const isMatching = currentQuestion.answerType === MATCHING;

        // 'answerType' это имя элемента с выпадающим списком типа вопроса
        if (name === 'answerType') {
          if (isMatching) {
            currentQuestion['type'] = MATCHING_ID;
            currentQuestion.matchingAnswers = MATCHING_ANSWERS_ARR;
            currentQuestion.answers = [];
          }

          if (!isMatching && currentQuestion.answerType !== OPEN) {
            currentQuestion.answers.forEach(answer => (answer.correct = false));
            currentQuestion.type = MULTIPLE_ID; // У одиночного варианта ответа и у matching у них id 1;
            currentQuestion.multipleAnswers = false;
          }

          if (currentQuestion.answerType === MULTIPLE) {
            currentQuestion.type = MULTIPLE_ID;
            currentQuestion.multipleAnswers = true;
          }

          if (currentQuestion.answerType === OPEN) {
            handleChangeOpenQuestion(event, index, cropImageSrc, coverColor);
            return { ...prevBlock };
          }
        }

        if (cropImageSrc) {
          currentQuestion['image'] = cropImageSrc;
        }

        if (coverColor) {
          currentQuestion['color'] = coverColor;
        }

        return { ...prevBlock, questions };
      });
      setDirty(false);
    },
    [setBlock, block]
  );

  // Изменение параметра открытого вопроса

  const handleChangeOpenQuestion = (event, index, cropImageSrc, coverColor) => {
    const { name, value } = event.target || event;
    const questions = [...block.questions];
    const currentQuestion = questions[index];

    currentQuestion.answers = [];

    currentQuestion.type = OPEN_ID;

    if (name === 'hasAttachment') {
      currentQuestion.hasAttachment = value;
    }
    if (cropImageSrc) {
      currentQuestion['image'] = cropImageSrc;
    }

    if (coverColor) {
      currentQuestion['color'] = coverColor;
    }

    setBlock({ ...block, questions });
    setDirty(false);
  };

  const showImportQuestions = () => setImportVisible(true);

  const hideImportQuestions = () => {
    setImportVisible(false);
    dispatch(getBlocks(topicId));
  };

  // Изменение ответа
  const handleChangeAnswer = useCallback(
    ({ event, index, indexAnswer, cropImageSrc, coverColor, fileName }) => {
      const { name, value, checked } = event?.target || event;

      setBlock(prevBlock => {
        const questions = _cloneDeep(prevBlock.questions);

        const currentQuestion = questions[index];

        const isMatching = currentQuestion.type === MATCHING_ID || currentQuestion.answerType === MATCHING;

        if (isMatching) {
          const side = name.split('-')[1];
          const isText = name.toLowerCase().includes('answer');

          const currentAnswer = currentQuestion[MATCHING_ANSWERS][indexAnswer];

          currentAnswer[side].content = value;
          currentAnswer[side].isText = value ? isText : true;

          if (!value && !isText) {
            delete currentAnswer[side].image;
            delete currentAnswer[side].fileName;
          }

          if (cropImageSrc) {
            currentAnswer[side].image = cropImageSrc;
            currentAnswer[side].fileName = fileName;
          }

          if (coverColor) {
            currentAnswer[side].color = coverColor;
          }
        } else {
          const currentAnswer = currentQuestion[ANSWERS][indexAnswer];

          if (name === 'correct' && currentQuestion.type === MULTIPLE_ID && !currentQuestion.multipleAnswers) {
            currentQuestion.answers.forEach(answer => (answer.correct = false));
          }

          if (event.target) {
            currentAnswer[name] = value?.replace(';', '') ?? checked;
          } else {
            currentAnswer[name] = value ?? checked;
          }

          if (cropImageSrc) {
            currentAnswer.image = cropImageSrc;
          }

          if (coverColor) {
            currentAnswer.color = coverColor;
          }
        }
        return { ...prevBlock, questions };
      });
      setDirty(false);
    },

    [setBlock, block]
  );

  // Добавление вопроса
  const addQuestion = useCallback(() => {
    const questions = _get(block, 'questions', []);
    const id = 'fake' + questions.length;

    setBlock(prevBlock => ({
      ...prevBlock,
      questions: [
        ...questions,
        {
          ...emptyQuestion,
          id,
          answers: [{ ...emptyAnswer }, { ...emptyAnswer }],
        },
      ],
    }));

    setKeepOpen([questions.length]);
  }, [block, setBlock]);

  // Добавление ответа
  const addAnswer = useCallback(
    (index, isMatching) => {
      setBlock(prevBlock => {
        const questions = _get(prevBlock, 'questions', []);

        if (isMatching) {
          const matchingAnswersCount = questions[index][MATCHING_ANSWERS].length;

          questions[index][MATCHING_ANSWERS].push(
            _cloneDeep({ ...emptyMatchingAnswer, position: matchingAnswersCount })
          );
        } else {
          questions[index][ANSWERS].push({ ...emptyAnswer });
        }

        return { ...prevBlock, questions: [...questions] };
      });
    },
    [block, setBlock]
  );

  // Удаление вопроса
  const handleDeleteQuestion = useCallback(
    index => {
      const questions = _get(block, 'questions', []);
      if (!questions[index].id.toString().includes('fake')) {
        setDirty(false);
      }

      questions.splice(index, 1);
      setBlock(prevBlock => ({ ...prevBlock, questions: [...questions] }));
    },
    [setBlock, block]
  );

  // Удаление ответа
  const handleDeleteAnswer = useCallback(
    (index, answerIndex) => {
      const questions = _get(block, 'questions', []);
      const currentQuestion = questions[index];

      const answersType = currentQuestion.type === MATCHING_ID ? MATCHING_ANSWERS : ANSWERS;
      const currentAnswers = currentQuestion[answersType];

      if (currentAnswers[answerIndex].id) {
        setDirty(false);
      }

      currentAnswers.splice(answerIndex, 1);
      currentAnswers.forEach((answer, index) => ({ ...answer, position: index }));

      setBlock(prevBlock => ({ ...prevBlock, questions: [...questions] }));
    },
    [setBlock, block]
  );

  const handleImageChange = useCallback(
    (file, name, info, cropImageSrc, coverColor, index, answerIndex) => {
      if (file && info) {
        if (IMAGE_TYPES.includes(info.type)) {
          createFileImage(file, fileInfo => {
            const fileName = fileInfo.name;

            if (answerIndex !== undefined) {
              handleChangeAnswer({
                event: { name, value: fileInfo.uuid },
                index,
                indexAnswer: answerIndex,
                cropImageSrc: URL.createObjectURL(cropImageSrc),
                coverColor,
                fileName,
              });
            } else {
              handleChangeQuestion(
                { name, value: fileInfo.uuid },
                index,
                URL.createObjectURL(cropImageSrc),
                coverColor
              );
            }
          });
          return true;
        }
        if (cropImageSrc !== undefined || answerIndex !== undefined) {
          handleChangeAnswer({
            event: { name, value: null },
            index,
            indexAnswer: answerIndex,
            cropImageSrc,
            coverColor: null,
          });
        } else {
          handleChangeQuestion({ name, value: null }, index, info, null);
        }
      } else {
        if (cropImageSrc !== undefined || answerIndex !== undefined) {
          handleChangeAnswer({
            event: { name, value: file },
            index,
            indexAnswer: answerIndex,
            cropImageSrc,
            coverColor: null,
          });
        } else {
          handleChangeQuestion({ name, value: file }, index, info, null);
        }
      }
    },
    [handleChangeAnswer, handleChangeQuestion, block]
  );

  const valid = useMemo(() => {
    let validation = true;

    block.questions.forEach(question => {
      if (!question.question && !question.imgUuid) {
        validation = false;
        return validation;
      }

      if (question.type === MATCHING_ID) {
        validation = question.matchingAnswers?.length >= MIN_MATCHING_ANSWERS;
        question.matchingAnswers?.forEach(answer => {
          if (!answer.left?.content || !answer.right?.content) {
            validation = false;
          }
        });
      } else {
        question.answers?.forEach(answer => {
          if (!answer.answer && !answer.imgUuid) {
            validation = false;
            return validation;
          }
        });

        const answer = question.answers.find(answer => answer.correct === true) || block.poll;

        if (!answer && question.type !== OPEN_ID) {
          validation = false;
          return validation;
        }
      }
    });

    return validation;
  }, [block]);

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const questions = reorder(block.questions, result.source.index, result.destination.index);

      setBlock(prevBlock => ({ ...prevBlock, questions }));
      setDirty(false);
    },
    [block, setBlock]
  );

  const checkDropMenuUpdate =
    currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
    (isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE));

  const checkDropMenuDelete =
    currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
    (isExecutiveTask(topicTypeId) && currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE));

  const dropDownMenu = [
    ...(checkDropMenuUpdate
      ? [
          {
            key: '1',
            label: (
              <div data-qa='editBlockBtn' onClick={handleEditClick}>
                {t('edit')}
              </div>
            ),
          },
        ]
      : []),
    ...(checkDropMenuDelete
      ? [
          {
            key: '2',
            label: (
              <div data-qa='deleteBlockBtn' onClick={handleDeleteClick}>
                {t('delete')}
              </div>
            ),
          },
        ]
      : []),
  ];

  const extra = useMemo(
    () => (
      <div onClick={e => e.stopPropagation()}>
        <Button
          onClick={saveButtonHandler}
          className={css['Block-header-save']}
          disabled={dirty || !valid || !canEdit}
          type='primary'
          data-qa='saveBlockBtn'
        >
          {t('save')}
        </Button>
        {(currentUser.authorities.includes(TOPIC_BLOCK_UPDATE) ||
          currentUser.authorities.includes(TOPIC_BLOCK_DELETE) ||
          (isExecutiveTask(topicTypeId) &&
            (currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_UPDATE) ||
              currentUser.authorities.includes(TOPIC_BLOCK_EXECUTIVE_TASK_DELETE)))) && (
          <Dropdown
            id='blockOptionsList'
            menu={{ items: dropDownMenu }}
            placement='bottomRight'
            overlayClassName={css['Dropdown-menu']}
            trigger={['click']}
          >
            <EllipsisOutlined data-qa='blockOptionsList' className={css.More} />
          </Dropdown>
        )}
      </div>
    ),
    [deleteBlock, editBlock, saveButtonHandler, dirty, canEdit]
  );

  const importOk = (file, f) => {
    if (file && data?.id) {
      dispatch(importQuestionFile(file, data.id, response => f(response)));
    }
  };

  const addQuestionFromBank = () => {
    // Вопросы, которые уже есть в данном блоке
    const includedQuestionInBank = block.questions.filter(question => question.inBank);

    handleAddFromBank(includedQuestionInBank, block.id);
  };

  // Отдельная функция для получения кол-во вопросов
  const handleGetCountQuestions = ids => dispatch(getCountCatQuestions(ids));

  // Запрос с debounce на получение кол-во вопросов
  const handleGetCountQuestionsDebounced = useCallback(_debounce(handleGetCountQuestions, 500), []);

  // Изменения полей рандомайзера
  const handleChangeRandomizer = (event, customName, a) => {
    const name = customName || event.target.name;

    switch (name) {
      case 'randomizerCount':
        const value = +event?.target?.value;
        if (value <= countQuestions && !Number.isNaN(+event.target.value) && value !== 0) {
          setRandomizerInfo({ ...randomizerInfo, [name]: +event.target.value });
          setDirty(false);
        } else {
          setRandomizerInfo({ ...randomizerInfo, [name]: null });
          setDirty(true);
          message.warning(value !== 0 ? t('maxValueRandom') : t('notZeroValue'));
        }
        break;

      case 'questionCategories':
        let catNames = a?.map(catName => catName?.props?.children?.[0]);

        setRandomizerInfo({ [name]: catNames, questionCategoriesIds: event, randomizerCount: null });
        setDirty(true); // всегда true т.к. очищаем randomizerCount
        setBlock(prevBlock => ({ ...prevBlock, pass: 0 }));

        if (event?.length > 0) {
          handleGetCountQuestionsDebounced(event);
        } else {
          dispatch(setCountQuestions(0));
          setRandomizerInfo({ questionCategoriesIds: [], questionCategories: [], randomizerCount: null });
        }
        break;

      default:
        break;
    }
  };

  const renderCategories = useMemo(
    () =>
      Array.isArray(categories) &&
      categories.map(question => (
        <Option value={question.id} key={question.id}>
          {question.name}
          <span>{` [${question?.count || 0}]`}</span>
        </Option>
      )),
    [categories]
  );

  // Отдельная функция для получения категорий через debounce
  const handleGetBankCategories = category => dispatch(getBankCategories(category, 0, 500));

  // Запрос с debounce на получение категорий
  const getBankCategoriesDebounced = useCallback(_debounce(handleGetBankCategories, 500), []);

  // Поиск категорий
  const onSearchCategory = val => {
    getBankCategoriesDebounced(val);

    if (randomizerInfo.questionCategories?.length === 0) {
      dispatch(setCountQuestions());
    }
  };

  // Рендер категорий в информации по рандомайзеру
  const infoCategories = useMemo(() => {
    if (Array.isArray(randomizerInfo.questionCategories) && randomizerInfo.questionCategories.length > 0) {
      return randomizerInfo.questionCategories.map((question, index) => (
        <span key={index} className={css['Block-randomizer-value']}>
          {question}
        </span>
      ));
    } else {
      return t('emptyCategories');
    }
  }, [randomizerInfo.questionCategories]);

  const collapseItem = [
    {
      key: '1',
      label: t('randomizerQuestions'),
      children: (
        <>
          <div>
            <span className={css['Block-randomizer-label']}>{t('totalQuestionsInfo')}</span>
            <span>{countQuestions}</span>
          </div>
          <div>
            <span className={css['Block-randomizer-label']}>{t('randomizerCountInfo')}</span>
            <span>{randomizerInfo.randomizerCount}</span>
          </div>
          <div>
            <span className={css['Block-randomizer-label']}>{t('choosedCategories')}</span>
            <span>{infoCategories}</span>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={css.Block}>
      <CollapseLMS header={header} data={data} extra={extra} forceClose={forceClose}>
        {block.randomizer && (
          <Alert message={t('infoMsgRandomizer')} type='warning' closable className={css['Block-alert']} />
        )}
        <div className={css['Block-layoutTest']}>
          <Form.Item labelAlign='left' label={t('count')} className={css['Block-layoutTest-formItem']}>
            <Input
              data-qa='allowedCount'
              name='allowedCount'
              onChange={handleChange}
              value={block.allowedCount}
              maxLength={50}
              disabled={!block.randomizer && (!canEdit || !block.questions.length || block.poll)}
            />
          </Form.Item>
          <Form.Item labelAlign='left' label={t('timeLimit')} className={css['Block-layoutTest-formItem']}>
            <TimePicker
              name='time'
              className={css['Block-layoutTest-formItem-time']}
              onChange={handleChangeTime}
              value={!block['time'] ? dayjs('00:00:00', 'HH:mm:ss') : dayjs(block.time, 'HH:mm:ss')}
              disabled={!block.randomizer && (!canEdit || !block.questions.length || block.poll)}
              showNow={false}
            />
          </Form.Item>
        </div>
        <div className={css['Block-layoutTest']}>
          <Form.Item labelAlign='left' label={t('pass')} className={css['Block-layoutTest-formItem']}>
            <Input
              name='pass'
              onChange={handleChange}
              value={block.pass}
              maxLength={3}
              disabled={!block.randomizer && (!canEdit || !block.questions.length || block.poll)}
            />
          </Form.Item>
          <Form.Item labelAlign='left' className={css['Block-layoutTest-formItem']}>
            <Checkbox
              data-qa='mixQuestions'
              name='mixQuestions'
              onChange={handleChangeCheck}
              checked={block.mixQuestions || false}
              disabled={!canEdit || block.randomizer}
            >
              {t('mixQuestions')}
            </Checkbox>
          </Form.Item>
        </div>
        <div className={css['Block-layoutTest']}>
          <Form.Item labelAlign='left' className={css['Block-layoutTest-formItem']}>
            <Checkbox
              data-qa='needReviewCheckbox'
              name='review'
              onChange={handleChangeCheck}
              checked={block.review || false}
              disabled={!canEdit}
            >
              {t('requiresReview')}{' '}
            </Checkbox>
          </Form.Item>
          <Form.Item labelAlign='left' className={css['Block-layoutTest-formItem']}>
            <Checkbox
              data-qa='mixAnswers'
              name='mixAnswers'
              onChange={handleChangeCheck}
              checked={block.mixAnswers || false}
              disabled={!canEdit}
            >
              {t('mixAnswers')}
            </Checkbox>
          </Form.Item>
        </div>
        <div className={css['Block-layoutTest']}>
          <Form.Item labelAlign='left' className={css['Block-layoutTest-formItem']}>
            <Checkbox
              data-qa='pollCheckbox'
              name='poll'
              onChange={handleChangeCheck}
              checked={block.poll || false}
              disabled={!canEdit || block.randomizer}
            >
              {t('poll')}
            </Checkbox>
          </Form.Item>
          {/* Рандомайзер */}
          <Form.Item labelAlign='left' className={css['Block-layoutTest-formItem']}>
            <Checkbox
              name='randomizer'
              onChange={handleChangeCheck}
              checked={block.randomizer || false}
              disabled={block.poll || block.mixQuestions || !canEdit}
            >
              {t('randomizer')}
            </Checkbox>
          </Form.Item>
        </div>
        <div className={css['Block-layoutTest']}>
          <Form.Item labelAlign='left' className={css['Block-layoutTest-formItem']}>
            <Checkbox
              data-qa='hideExtendedResultsCheckbox'
              name='extendedResults'
              onChange={handleChangeCheck}
              checked={block.extendedResults || false}
              disabled={!canEdit || block.poll}
            >
              {t('extendedResults')}
            </Checkbox>
          </Form.Item>
          {/* Категория для рандомайзера */}
          {block.randomizer && (
            <Form.Item labelAlign='left' label={t('category')} className={css['Block-layoutTest-formItem']}>
              <Select
                id='questionCategories'
                className={css['Block-layoutTest-select']}
                placeholder={t('selectCategory')}
                mode='multiple'
                onChange={(e, a) => handleChangeRandomizer(e, 'questionCategories', a)}
                onSearch={onSearchCategory}
                optionFilterProp='children'
                onBlur={() => onSearchCategory('')}
                loading={isLoadingRandomizer || isLoadingCategories}
                showSearch
                defaultValue={randomizerInfoSave}
                // showArrow
              >
                {renderCategories}
              </Select>
            </Form.Item>
          )}
        </div>
        {/* Всего вопросов по категориям */}
        <div className={css['Block-layoutTest-randz']}>
          {block.randomizer && (
            <div className={css['Block-layoutTest-randz-item']}>
              <Form.Item labelAlign='left' label={t('totalQuestionsCount')}>
                <Spin spinning={isLoadingCount} size='small'>
                  <span>{countQuestions || 0}</span>
                </Spin>
              </Form.Item>
            </div>
          )}
          {block.randomizer && (
            <div className={css['Block-layoutTest-randz-item']}>
              <Form.Item labelAlign='left' label={t('randomizerCount')}>
                <Input
                  name='randomizerCount'
                  onChange={handleChangeRandomizer}
                  value={randomizerInfo.randomizerCount}
                  disabled={!canEdit}
                />
              </Form.Item>
            </div>
          )}
        </div>
        {block.time && block.time !== '00:00:00' && (
          <div className={css['Block-layoutTest-textArea']}>
            <Form.Item labelAlign='left' label={t('textForStartBlock')}>
              <TextArea
                data-qa='textForStartBlock'
                name='text'
                onChange={handleChange}
                value={block?.text}
                disabled={!canEdit || block.poll}
                className={css['Block-textArea']}
              />
            </Form.Item>
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`droppable${block.id}`}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {block.questions.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={!canEdit}>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Question
                          key={index}
                          data={item}
                          index={index}
                          changeQuestionValue={handleChangeQuestion}
                          changeAnswerValue={handleChangeAnswer}
                          changeOpenQuestion={handleChangeOpenQuestion}
                          addAnswer={addAnswer}
                          deleteQuestion={handleDeleteQuestion}
                          handleDeleteAnswer={handleDeleteAnswer}
                          handleImageChange={handleImageChange}
                          files={files}
                          forceClose={forceClose}
                          keepOpen={keepOpen.includes(index)}
                          canEdit={canEdit}
                          handleAddToBank={handleAddToBank}
                          isCanAddToBank={dirty || valid}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* Информация рандомайзера */}
        {block.randomizer && (
          <div className={css['Block-footer']}>
            <Collapse
              className={css['Block-randomizer']}
              expandIconPosition='end'
              items={collapseItem}
              expandIcon={panelProps => {
                const ExpandIcon = Utils.changeActiveStatusCollapse(panelProps);
                return <ExpandIcon />;
              }}
            />
          </div>
        )}
        {canEdit && (
          <div className={css['Block-footer']}>
            <Button id='addQuestionBtn' type='link' onClick={addQuestion} data-qa='linkActionBtn'>
              {t('addQuestion')}
            </Button>
            <Button id='addQuestionBtn' type='link' onClick={showImportQuestions} data-qa='linkActionBtn'>
              {t('importQuestions')}
            </Button>
            <Button id='addQuestionFromBankBtn' type='link' onClick={addQuestionFromBank} data-qa='linkActionBtn'>
              {t('addQuestionFromBank')}
            </Button>
          </div>
        )}

        {importVisible && (
          <DictionaryImportDialog
            onOk={importOk}
            onCancel={hideImportQuestions}
            fileDescription={t('download')}
            description1={t('description1')}
            exampleFileFunc={b => dispatch(getExampleFile(b))}
            open={importVisible}
          />
        )}
      </CollapseLMS>
    </div>
  );
};
