/* eslint-disable */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Form, Input, Pagination } from 'antd';

import ConfirmationModal from '../ConfirmationModal';
import { Card, Content } from '../Layout';
import DictionaryCreationDialog from './DictionaryCreationDialog';
import DictionaryItem from './DictionaryItem';

import debounce from '@shared/utils/debounce';

import PropTypes from 'prop-types';

import css from './Dictionary.module.scss';

import { DEPARTMENT_INTERNAL_CREATE, FUNCTION_INTERNAL_CREATE } from '@/constants/permissions';

export class DictionaryTable extends Component {
  static propTypes = {
    createData: PropTypes.func,
    creationError: PropTypes.bool,
    deleteData: PropTypes.func,
    editData: PropTypes.func,
    editingError: PropTypes.bool,
    getData: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    dataPageNumber: PropTypes.number,
    dataPageSize: PropTypes.number,
    dataTotal: PropTypes.number,
    isGlobal: PropTypes.bool,
    shared: PropTypes.bool,
    dictionary: PropTypes.object,
    limit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchName: '',
      page: 1,
    };
    this.getDataDebounced = debounce(props.getData, 1000);
    props.getData('', 0, null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchString !== this.state.searchString) {
      this.getDataDebounced(
        this.state.searchString,
        0,
        () => {
          this.setState({ searchName: this.state.searchString });
        },
        this.props.isGlobal
      );
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changePage = page => {
    this.setState({ page });
    this.props.getData(this.state.searchString, page - 1, null, this.props.isGlobal);
  };

  showDeleteConfirmation = data => this.setState({ deleteConfirmationVisible: true, activeData: data });

  hideDeleteConfirmation = () => this.setState({ deleteConfirmationVisible: false, activeData: null });

  handleDataDeletion = () =>
    this.props.deleteData(this.state.activeData && this.state.activeData.id, {
      onSuccess: () => {
        this.hideDeleteConfirmation();
        this.props.getData(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
      },
      onError: this.hideDeleteConfirmation,
    });

  showDataDialog = () => this.setState({ dataVisible: true });

  hideDataDialog = () => this.setState({ dataVisible: false });

  handleOk = data => {
    this.state.activeData ? this.handleDataEditing(data) : this.handleDataCreation(data);
  };

  handleCancel = () => {
    this.state.activeData ? this.hideDataEditingDialog() : this.hideDataDialog();
  };

  handleDataCreation = data => {
    this.props.createData(data, () => {
      this.hideDataDialog();
      this.props.getData(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
    });
  };

  showDataEditingDialog = data => this.setState({ dataVisible: true, activeData: data });

  hideDataEditingDialog = () => this.setState({ dataVisible: false, activeData: null });

  handleDataEditing = data => {
    this.props.editData({ ...data, id: this.state.activeData && this.state.activeData.id }, () => {
      this.hideDataEditingDialog();
      this.props.getData(this.state.searchString, this.state.page - 1, null, this.props.isGlobal);
    });
  };

  render() {
    const {
      data,
      dataPageSize,
      dataTotal,
      dataPageNumber,
      creationError,
      editingError,
      shared,
      dictionary,
      t,
      currentUser,
      isFunction,
    } = this.props;
    const label = shared ? dictionary.sharedTitle : dictionary.title;
    const dataItems = data.map(data => (
      <DictionaryItem
        key={data.id}
        data={data}
        onEdit={this.showDataEditingDialog}
        onDelete={this.showDeleteConfirmation}
        shared={shared}
        currentUser={currentUser}
        isFunction={isFunction}
        t={t}
      />
    ));
    const pagination = (
      <Pagination
        current={dataPageNumber + 1}
        total={dataTotal}
        pageSize={dataPageSize}
        onChange={this.changePage}
        className={css['pagination']}
        showSizeChanger={false}
        responsive={true}
        hideOnSinglePage={true}
      />
    );

    const modalDictionary = this.state.activeData ? dictionary.editDataModal : dictionary.addDataModal;

    return (
      <Content className={css.DictionaryDirectory}>
        <h1>{label}</h1>
        <div className={css['DictionaryItem-container']}>
          <Form className={css['DictionaryItem-form']}>
            <Form.Item
              label={t('search')}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              className={css['DictionaryItem-form-search']}
            >
              <Input
                placeholder={dictionary.placeholder}
                dataqa='dictionaryItemSearchInput'
                name='searchString'
                value={this.state.searchString}
                onChange={this.handleChange}
              />
            </Form.Item>
          </Form>
          {!shared &&
            (isFunction
              ? currentUser?.authorities.includes(FUNCTION_INTERNAL_CREATE)
              : currentUser?.authorities.includes(DEPARTMENT_INTERNAL_CREATE)) && (
              <>
                <Button
                  type='primary'
                  className={css['DictionaryItem-button']}
                  onClick={this.showDataDialog}
                  data-qa='addDictionaryItemBtn'
                >
                  {dictionary.addData}
                </Button>
              </>
            )}
        </div>
        <Card data-qa='dictionary-card'>
          {dataItems && dataItems.length ? (
            dataItems
          ) : !this.props.isLoading && this.state.searchString ? (
            <div className={css['DictionaryItem-noFound']}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('findError', { searchName: this.state.searchName }),
                }}
              />
            </div>
          ) : null}
          {pagination}
        </Card>
        {this.state.dataVisible && !shared ? (
          <DictionaryCreationDialog
            open
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            title={modalDictionary.title}
            descriptionText={modalDictionary.label}
            okText={this.state.activeData ? t('edit') : t('add')}
            error={creationError || editingError}
            errorText={dictionary.error}
            data={this.state.activeData}
            limit={this.props.limit}
            require={t('require')}
            limitText={t('limit')}
          />
        ) : null}
        <ConfirmationModal
          open={this.state.deleteConfirmationVisible}
          onOk={this.handleDataDeletion}
          onCancel={this.hideDeleteConfirmation}
          title={dictionary.deleteData.title}
          okText={t('delete')}
          confirmationText={dictionary.deleteData.confirmation}
        />
      </Content>
    );
  }
}

export default withTranslation('dictionaryTable')(DictionaryTable);
