/* eslint-disable */
import React, { Component } from 'react';

import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Modal.module.scss';

export default class CustomModal extends Component {
  static propTypes = {
    cancelBtn: PropTypes.bool,
    fileModal: PropTypes.bool,
    cancelBtnText: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    editable: PropTypes.bool,
    headerFixToModal: PropTypes.bool,
    modalCategory: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.string,
    hideFooter: PropTypes.bool,
    disable: PropTypes.bool,
    width: PropTypes.number,
    commentModal: PropTypes.bool,
    massAddUser: PropTypes.bool,
    singleAddUser: PropTypes.bool,
  };

  render() {
    const {
      className,
      children,
      title,
      contentClassName,
      editable,
      onEdit,
      onOk,
      modalCategory,
      hideFooter,
      okText,
      okBtnDataQa,
      headerFixToModal,
      cancelBtn,
      onCancel,
      cancelBtnText,
      fileModal,
      disable,
      width,
      dataQa,
      commentModal,
      massAddUser,
      singleAddUser,
      ...rest
    } = this.props;

    const footer =
      modalCategory && !hideFooter ? (
        <div className={css['Modal-modalCategory-footer']}>
          <div
            className={classNames({
              [css['Modal-modalCategory-btn']]: modalCategory,
              [css['Modal-fileModal-btn']]: fileModal,
            })}
          >
            <Button
              // className={classNames({ [css['Modal-fileModal-fileModal']]: fileModal })}
              className={css['Modal-modalCategory-confirm']}
              onClick={onOk}
              disabled={disable}
              data-qa='saveCategoryBtn'
              type='primary'
            >
              {okText}
            </Button>
          </div>
          {cancelBtn && (
            <div className={css['Modal-modalCategory-btn']}>
              <Button onClick={onCancel}>{cancelBtnText}</Button>
            </div>
          )}
        </div>
      ) : null;

    const editButton = editable ? (
      <button className={css['Modal-edit']} onClick={onEdit}>
        <EditOutlined />
      </button>
    ) : null;

    return (
      <Modal
        {...rest}
        className={classNames(className, css.Modal, {
          [css['Modal-modalCategory']]: modalCategory,
          [css['Modal-fileModal']]: fileModal,
        })}
        closable={false}
        footer={null}
        width={width}
        onCancel={onCancel}
        data-qa={dataQa}
      >
        <div
          className={classNames(css['Modal-header'], {
            [css['Modal-close-headerFixToModal']]: headerFixToModal,
          })}
        >
          <div className={css['Modal-title']}>{title}</div>
          <div className={css['Modal-buttons']}>
            {editButton}
            <button
              className={classNames(css['Modal-close'], {
                [css['Modal-fileModal-close']]: fileModal,
                [css['Modal-fileModal-close-comment']]: commentModal,
                [css['Modal-fileModal-close-massAdd']]: massAddUser,
                [css['Modal-fileModal-close-singAdd']]: singleAddUser,
                [css['Modal-fileModal-close-modalCategory']]: modalCategory,
              })}
              onClick={this.props.onCancel}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='12' height='16' viewBox='0 0 18 17'>
                <path d='m2.143 1.143 13.903 13.903m-.189-13.903L1.954 15.046' stroke='#000' fill='none' />
              </svg>
            </button>
          </div>
        </div>
        <div
          className={classNames(css['Modal-content'], contentClassName, {
            [css['Modal-fileModal-content']]: fileModal,
          })}
        >
          {children}
          {footer}
        </div>
      </Modal>
    );
  }
}
