/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  createProfileCustomField,
  deleteProfileCustomField,
  getProfileCustomFieldList,
  swapProfileCustomField,
  updateProfileCustomField,
} from '@/store/pcfields/actions';

import { selectFields } from '@/store/pcfields/selectors';

import { Button, Col, Empty, Modal, Row, Table, Tooltip } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import CreateField, {
  FIELD_BOOLEAN,
  FIELD_DATE,
  FIELD_DATETIME,
  FIELD_NUMERIC,
  FIELD_STRING,
  FIELD_TEXT,
  FIELD_TIME,
  MODE_CREATE,
  MODE_EDIT,
} from './CreateField';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import './css/AdditionalFields.css';

import { useTranslation } from 'react-i18next';

import { selectUsers } from '@/store/users/selectors';

import { CUSTOM_FIELD_CREATE, CUSTOM_FIELD_DELETE, CUSTOM_FIELD_UPDATE } from '@/constants/permissions';

//const ButtonGroup = Button.Group;
const { confirm } = Modal;
const ButtonGroup = Button.Group;

export const AdditionalFields = props => {
  const [showFieldWindow, setShowFieldWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [field, setField] = useState({});
  const [mode, setMode] = useState(MODE_CREATE);

  useEffect(() => {
    props.getProfileCustomFieldList(false);
  }, []);

  useEffect(() => {
    setFields(props.fields);
    setLoading(props.loading);
  }, [props.fields, props.loading]);

  const { t } = useTranslation('varDirectory');

  // -- Столбы таблицы
  const getColumns = () => {
    return [
      {
        title: `${t('columnsHeaders.0')}`,
        dataIndex: 'name',
        key: 'name',
        width: 600,
        render: (name, record) => (
          <div className={'fieldName'}>
            <Tooltip title={record.description}>{name}</Tooltip>
          </div>
        ),
      },
      {
        title: `${t('columnsHeaders.1')}`,
        dataIndex: 'fieldType',
        key: 'type',
        render: type => printFieldType(type.id),
      },
      {
        title: `${t('columnsHeaders.2')}`,
        key: 'order',
        render: record =>
          props.currentUser.authorities.includes(CUSTOM_FIELD_UPDATE) && (
            <ButtonGroup>
              <Button
                disabled={getFields()[0] == record}
                icon={<CaretUpOutlined />}
                onClick={() => move(true, record.id, getFields())}
              />
              <Button
                disabled={getFields()[getFields().length - 1] == record}
                icon={<CaretDownOutlined />}
                onClick={() => move(false, record.id, getFields())}
              />
            </ButtonGroup>
          ),
      },
      {
        title: `${t('columnsHeaders.3')}`,
        dataIndex: 'action',
        key: 'action',
        width: 20,
        render: (name, record) => (
          <>
            {props.currentUser.authorities.includes(CUSTOM_FIELD_UPDATE) && (
              <Button className={'actionBtn'} type={'link'} onClick={() => editField(record)}>
                <Tooltip title={t('editCustomField')}>
                  <EditOutlined />
                </Tooltip>
              </Button>
            )}
            {props.currentUser.authorities.includes(CUSTOM_FIELD_DELETE) && (
              <Button className={'actionBtn'} type={'link'} onClick={() => confirmDeleteField(record)}>
                <Tooltip title={t('deleteCustomField')}>
                  <DeleteOutlined style={{ color: 'red' }} />
                </Tooltip>
              </Button>
            )}
          </>
        ),
      },
    ];
  };

  // -- Поднять/Опустить уровень кастомного поля
  const move = (up, id, swapId) => {
    function indexId(swapIndex) {
      return swapIndex.id === id;
    }

    if (up === true) {
      const swapedId = swapId[swapId.findIndex(indexId) - 1].id;
      props.swapProfileCustomField(
        id,
        swapedId,
        success => {
          props.getProfileCustomFieldList(false);
        },
        fail => {
          console.error('Error', fail);
        }
      );
    } else {
      const swapedId = swapId[swapId.findIndex(indexId) + 1].id;
      props.swapProfileCustomField(
        id,
        swapedId,
        success => {
          props.getProfileCustomFieldList(false);
        },
        fail => {
          console.error('Error', fail);
        }
      );
    }
  };

  // -- Вывод названий типов полей
  const printFieldType = type => {
    switch (type) {
      case FIELD_STRING:
        return <>{t('fieldType.0')}</>;
      case FIELD_TEXT:
        return <>{t('fieldType.1')}</>;
      case FIELD_NUMERIC:
        return <>{t('fieldType.2')}</>;
      case FIELD_DATE:
        return <>{t('fieldType.3')}</>;
      case FIELD_TIME:
        return <>{t('fieldType.4')}</>;
      case FIELD_DATETIME:
        return <>{t('fieldType.5')}</>;
      case FIELD_BOOLEAN:
        return <>{t('fieldType.6')}</>;
      default:
        return <>{t('fieldType.7')}</>;
    }
  };

  // -- Пользователь закрыл окно создания/редактирования произвольного поля
  const onCancelField = () => {
    setShowFieldWindow(false);
  };

  // -- Пользователь сохраняет данные произвольного поля
  const onSubmitField = (
    id,
    name,
    description,
    type,
    placeholderValue,
    position,
    tab,
    showInProfile,
    sorted,
    filtered,
    enabledAsUserFilter,
    mode
  ) => {
    if (mode === MODE_CREATE) {
      props.createProfileCustomField(
        name,
        description,
        type,
        placeholderValue,
        tab,
        showInProfile,
        sorted,
        filtered,
        enabledAsUserFilter,
        success => {
          props.getProfileCustomFieldList(false);
        },
        failure => {}
      );
    } else if (mode === MODE_EDIT) {
      props.updateProfileCustomField(
        id,
        name,
        description,
        type,
        placeholderValue,
        position,
        tab,
        showInProfile,
        sorted,
        filtered,
        enabledAsUserFilter,
        success => {
          props.getProfileCustomFieldList(false);
        },
        failure => {}
      );
    }

    setShowFieldWindow(false);
  };

  // --
  const confirmDeleteField = record => {
    confirm({
      title: <span style={{ fontSize: '16px' }}>{t('deleteMessageTitle')}</span>,
      content: (
        <>
          {t('deleteMessage.0')}«{record.name}»{t('deleteMessage.1')}
        </>
      ),
      okText: `${t('yes')}`,
      cancelText: `${t('no')}`,
      okButtonProps: {
        style: { width: '180px' },
      },
      cancelButtonProps: {
        style: { width: '180px', marginRight: '18px' },
      },
      width: 800,
      icon: null,
      onOk() {
        // Удаляем проивзольное поле
        deleteField(record);

        // Обновляем список произвольных полей
        props.getProfileCustomFieldList(false);
      },
      closable: true,
    });
  };

  // -- Удаление произвольного поля
  const deleteField = record => {
    // Удаляем проивзольное поле
    props.deleteProfileCustomField(record.id);

    // Обновляем список произвольных полей
    props.getProfileCustomFieldList(false);
  };

  // -- Редактирование произвольного поля
  const editField = record => {
    setField(record);
    setMode(MODE_EDIT);
    setShowFieldWindow(true);

    // Обновляем список произвольных полей
    props.getProfileCustomFieldList(false);
  };

  // -- Создание произвольного поля
  const createField = () => {
    setField({});
    setMode(MODE_CREATE);
    setShowFieldWindow(true);
  };

  // -- Нет данных для отображения в таблице
  const noData = () => {
    return {
      emptyText: (
        <>
          <Empty />
          <br />
        </>
      ),
    };
  };

  // -- Возвращает список полей
  const getFields = () => {
    let filtered = [];
    if (Array.isArray(fields)) {
      fields.map(item => {
        if (item.hasOwnProperty('deleted') && item.deleted !== true) {
          filtered.push(item);
        }
      });
    }

    return filtered;
  };

  const renderFooter = () => {
    return (
      props.currentUser.authorities.includes(CUSTOM_FIELD_CREATE) && (
        <Button type={'primary'} onClick={createField} className={'createFieldButton'}>
          {t('create')}
        </Button>
      )
    );
  };

  // -- Рендер
  return (
    <>
      <Row className={'createField-table'}>
        <Col span={24}>
          <Table
            columns={getColumns()}
            dataSource={getFields()}
            locale={noData()}
            loading={loading}
            bordered={true}
            rowKey={record => record.id}
            footer={() => renderFooter()}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>

      <CreateField
        visible={showFieldWindow}
        onCancel={onCancelField}
        onSubmit={onSubmitField}
        mode={mode}
        field={field}
      />
    </>
  );
};

AdditionalFields.propTypes = {
  getProfileCustomFieldList: PropTypes.func.isRequired,
  createProfileCustomField: PropTypes.func.isRequired,
  updateProfileCustomField: PropTypes.func.isRequired,
  deleteProfileCustomField: PropTypes.func.isRequired,
  swapProfileCustomField: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createSelector([selectFields, selectUsers], (pcfields, users) => ({
  fields: pcfields.fields,
  loading: pcfields.loading,
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getProfileCustomFieldList,
  createProfileCustomField,
  updateProfileCustomField,
  deleteProfileCustomField,
  swapProfileCustomField,
};

export default connect(mapStateToProps, mapActionsToProps)(AdditionalFields);
