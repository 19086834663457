import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_POSTS_START = 'GET_POSTS_START';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAIL = 'GET_POSTS_FAIL';
export const GET_CONVENTUS_START = 'GET_CONVENTUS_START';
export const GET_CONVENTUS_SUCCESS = 'GET_CONVENTUS_SUCCESS';
export const GET_CONVENTUS_FAIL = 'GET_CONVENTUS_FAIL';
export const CREATE_POST_START = 'CREATE_POST_START';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAIL = 'CREATE_POST_FAIL';
export const DELETE_POST_START = 'DELETE_POST_START';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';
export const EDIT_POST_START = 'EDIT_POST_START';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const EDIT_POST_FAIL = 'EDIT_POST_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPosts = (name = '', page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/trajectory/career${getQueryString({
    name: name && name.toLowerCase(),
    sort: 'name,asc',
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSTS_START, GET_POSTS_SUCCESS, GET_POSTS_FAIL],
  onSuccess,
});

export const getConventusRoles = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/trajectory/role`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CONVENTUS_START, GET_CONVENTUS_SUCCESS, GET_CONVENTUS_FAIL],
  onSuccess,
});

export const createPost = (name, conventusRoleId, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/trajectory/career`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
    conventusRoleId,
  },
  onSuccess,
  types: [CREATE_POST_START, CREATE_POST_SUCCESS, CREATE_POST_FAIL],
});

export const deletePost = (id, { onSuccess, onError }) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/trajectory/career/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_POST_START, DELETE_POST_SUCCESS, DELETE_POST_FAIL],
});

export const editPost = (name, conventusRoleId, id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/trajectory/career/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name,
    conventusRoleId,
  },
  onSuccess,
  types: [EDIT_POST_START, EDIT_POST_SUCCESS, EDIT_POST_FAIL],
});
