import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearBannerTopBody,
  createBannerTopBody,
  deleteBannerTopBody,
  editBannerTopBody,
} from '@/store/banner_top_body/actions';
import { createFileBannerImage, getFile } from '@/store/files/actions';

import { selectFiles } from '@/store/files/selectors';

import { Alert, Button, Form, Input, Modal, Spin } from 'antd';
import CoverImage from '@/components/CoverImage/CoverImage';

import { CREATE, DELETE, EDIT } from './constance';

import PropTypes from 'prop-types';

import css from './Banners.module.scss';

const BannerModal = ({ open, onClose, obj, action }) => {
  const { t } = useTranslation('bannersTab');
  const dispatch = useDispatch();

  const { isLoadingImage } = useSelector(selectFiles);

  const [data, setData] = useState({
    name: obj ? obj.nameBanner : '',
    link: obj ? obj.link : '',
    uuid: obj ? obj.uuid : '',
  });

  const [errors, setErrors] = useState({
    name: false,
    link: false,
    image: false,
  });

  const [previewImg, setPreviewImg] = useState('');

  const [fileBlob, setFileBlob] = useState(undefined);
  const [isLoadingPreviwImage, setLoadingPreviwImage] = useState(false);
  const [isProcess, setProcess] = useState(false);

  const updateImage = blob => {
    setData({ ...data });
    setFileBlob(blob);
    setPreviewImg(window.URL.createObjectURL(blob));
  };

  useEffect(() => {
    if (action === EDIT) {
      setLoadingPreviwImage(true);
      dispatch(
        getFile(obj.uuid, blob => {
          const imageUrl = URL.createObjectURL(blob);
          setPreviewImg(imageUrl);
          setLoadingPreviwImage(false);
        })
      );
    }
  }, []);

  useEffect(() => {
    setErrors({
      name: false,
      link: false,
      image: false,
    });
  }, [data]);

  const isValidUrl = string => {
    try {
      new URL(string);
      return true;
    } catch {
      return false;
    }
  };

  const pushError = (name, status) => {
    setErrors({ ...errors, [name]: status });
  };

  const checkErrors = () => {
    if (data.name === '') {
      setErrors({ ...errors, name: true });
      return true;
    }

    if (data.link === '' || !isValidUrl(data.link)) {
      setErrors({ ...errors, link: true });
      return true;
    }

    if (!previewImg && !fileBlob) {
      setErrors({ ...errors, image: true });
      return true;
    }

    return false;
  };

  const onCreateBanner = () => {
    if (checkErrors()) return;

    const formData = new FormData();
    formData.append('file', fileBlob);

    // // const link = document.createElement('a');

    // // // Set link's href to point to the Blob URL
    // // link.href = previewImg;
    // // link.download = '2222';

    // // document.body.appendChild(link);

    // // link.dispatchEvent(
    // //   new MouseEvent('click', {
    // //     bubbles: true,
    // //     cancelable: true,
    // //     view: window,
    // //   })
    // // );

    // // Remove link from body
    // document.body.removeChild(link);

    setProcess(true);
    dispatch(
      createFileBannerImage(formData, res => {
        dispatch(createBannerTopBody({ ...data, name: data.name.replace(/\s+/g, ' ').trim(), uuid: res.uuid }));
        setProcess(false);
        onClose();
      })
    );
  };

  const onUpdateBanner = () => {
    if (checkErrors()) return;

    if (fileBlob) {
      const formData = new FormData();
      formData.append('file', fileBlob);

      setProcess(true);

      dispatch(
        createFileBannerImage(formData, res => {
          dispatch(
            editBannerTopBody(
              obj.id,
              { ...data, name: data.name.replace(/\s+/g, ' ').trim(), uuid: res.uuid, showBanner: obj.showBanner },
              () => {
                setProcess(false);
                onClose();
              }
            )
          );
        })
      );
    } else {
      dispatch(
        editBannerTopBody(
          obj.id,
          { ...data, name: data.name.replace(/\s+/g, ' ').trim(), uuid: obj.uuid, showBanner: obj.showBanner },
          () => {
            setProcess(false);
            onClose();
          }
        )
      );
    }
  };

  const onDeleteBanner = () => {
    setProcess(true);
    dispatch(
      deleteBannerTopBody(obj.id, () => {
        setProcess(false);
        dispatch(clearBannerTopBody(obj.id));
        onClose();
      })
    );
  };

  const titleModal = action === EDIT ? t('editModal') : t('createModal');

  const footerModal = [
    <Button key={'Banner-modal-back'} onClick={onClose}>
      {t('cancel')}
    </Button>,
    <Button
      type='primary'
      key={'Banner-modal-action'}
      disabled={isLoadingPreviwImage || isProcess}
      onClick={
        action === CREATE ? onCreateBanner : action === EDIT ? onUpdateBanner : action === DELETE && onDeleteBanner
      }
    >
      {action === CREATE
        ? t('createBanner')
        : action === EDIT
          ? t('updateBanner')
          : action === DELETE && t('deleteBanner')}
    </Button>,
  ];

  const renderMain = (
    <Form className={css['BannerModal-form']} layout='vertical'>
      {/* Наименование */}
      <Form.Item
        label={t('name')}
        colon={false}
        validateStatus={errors.name ? 'error' : ''}
        help={errors.name && t('notName')}
        required
      >
        <Input
          size='large'
          onChange={e => setData({ ...data, name: e.target.value.trimStart() })}
          value={data.name}
          placeholder={t('placeholderName')}
          maxLength={255}
          disabled={isLoadingPreviwImage}
        />
      </Form.Item>

      {/* Ссылка */}
      <Form.Item
        label={t('link')}
        colon={false}
        validateStatus={errors.link ? 'error' : ''}
        help={errors.link && t('notLink')}
        required
      >
        <Input
          size='large'
          onChange={e => setData({ ...data, link: e.target.value.trim() })}
          value={data.link}
          placeholder={t('placeholderLink')}
          maxLength={2048}
          disabled={isLoadingPreviwImage}
        />
      </Form.Item>

      {/* Картинка */}
      <Form.Item label={t('image')} colon={false} validateStatus={errors.image ? 'error' : ''} required>
        <div>
          {isLoadingImage || isLoadingPreviwImage ? (
            <div className={css['ModalTrack-form-spin']}>
              <Spin size='default' />
            </div>
          ) : (
            <>
              <Alert
                message={t('infoUploadImage')}
                className={css['ModalTrack-form-upload--alert']}
                type='info'
                closable
              />
              <CoverImage
                updateImage={updateImage}
                errorImage={errors.image}
                pushError={pushError}
                typeUpload='image/png, image/jpeg, image/jpg, image/gif'
                aspect={12}
                errorSizeMessage={t('changeImage')}
                errorMaxParamsMessage={t('changeImageMaxParams')}
                maxSizeInfo={t('maxSize')}
                changeImageText={t('errorSizeMessage')}
                maxParams={{ maxWidth: 1439, maxHeigth: 119 }}
              />
              <div className={css['ModalTrack-form-input--error']}> {errors.image && t('notImage')}</div>
            </>
          )}
        </div>
        <div className={css['ModalTrack-form-upload']}>
          {!isLoadingPreviwImage && (
            <img
              className={`${css['ModalTrack-form-upload-img']} ${previewImg && css['show']}`}
              src={previewImg}
              alt={t('infoPreviewImage')}
            />
          )}
        </div>
      </Form.Item>
    </Form>
  );

  const deleteBlock = <div>{t('deleteBannerBtn')}</div>;

  return (
    <Modal
      title={action !== DELETE && titleModal}
      open={open}
      width={action === DELETE ? 500 : 700}
      onCancel={onClose}
      footer={footerModal}
      className={css['BannerModal']}
      centered={true}
      disabled={isProcess}
    >
      {action === DELETE ? deleteBlock : renderMain}
    </Modal>
  );
};

BannerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  obj: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  action: PropTypes.string,
};

export default BannerModal;
