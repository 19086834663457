/* eslint-disable */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Input } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TimeInput.module.scss';

class TimeInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    startWith: PropTypes.string,
    value: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    if (props.value) {
      let { value } = props;
      const weeks = props.startWith === 'week' ? Math.floor(value / (24 * 60 * 7)) : 0;
      value -= weeks * 24 * 60 * 7;

      const days = props.startWith === 'day' || props.startWith === 'week' ? Math.floor(value / (24 * 60)) : 0;
      value -= days * 24 * 60;

      const hours = Math.floor(value / 60);
      value -= hours * 60;

      const minutes = value;

      this.state = {
        weeks: weeks || undefined,
        days: days || undefined,
        hours: hours || undefined,
        minutes: minutes || undefined,

        value: props.value,
      };
    } else {
      this.state = {
        value: undefined,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let { value } = nextProps;
    const weeks = nextProps.startWith === 'week' ? Math.floor(value / (24 * 60 * 7)) : 0;
    value -= weeks * 24 * 60 * 7;

    const days = nextProps.startWith === 'day' || nextProps.startWith === 'week' ? Math.floor(value / (24 * 60)) : 0;

    value -= days * 24 * 60;

    const hours = Math.floor(value / 60);
    value -= hours * 60;

    const minutes = value;

    this.setState({
      weeks: weeks || undefined,
      days: days || undefined,
      hours: hours || undefined,
      minutes: minutes || undefined,
      value: nextProps.value,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Number.isNaN(Number(this.state.value)) && this.state.value !== prevState.value) {
      this.setState({ value: this.state.value });
    }
  }

  handleChange = event => {
    const { name } = event.target;
    const value = parseInt(event.target.value);

    if (value < 0) {
      return;
    }

    const newValue = isNaN(value) ? undefined : value;
    const { name: inputName, onChange } = this.props;

    this.setState(state => {
      const newState = { ...state, [name]: newValue };
      newState.value =
        (newState.minutes || 0) +
        60 * (newState.hours || 0) +
        24 * 60 * (newState.days || 0) +
        24 * 60 * 7 * (newState.weeks || 0);

      onChange(newState.value, inputName);
      return newState;
    });
  };

  render() {
    const { className, startWith, t } = this.props;
    return (
      <div className={classNames(className, css.TimeInput)}>
        {startWith === 'week' ? (
          <Input
            placeholder={t('weeks')}
            name='weeks'
            data-qa='weeks'
            value={this.state.weeks}
            onChange={this.handleChange}
            type='text'
            className={css['TimeInput-input']}
          />
        ) : null}

        {startWith === 'day' || startWith === 'week' ? (
          <Input
            placeholder={t('days')}
            data-qa='days'
            name='days'
            value={this.state.days}
            onChange={this.handleChange}
            type='text'
            className={css['TimeInput-input']}
          />
        ) : null}

        <Input
          placeholder={t('hours')}
          name='hours'
          data-qa='hoursInput'
          value={this.state.hours}
          onChange={this.handleChange}
          type='text'
          className={css['TimeInput-input']}
          maxLength={3}
          disabled={this.props.disabled}
        />

        <Input
          placeholder={t('minutes')}
          name='minutes'
          data-qa='minutesInput'
          value={this.state.minutes}
          onChange={this.handleChange}
          type='text'
          className={css['TimeInput-input']}
          maxLength={2}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default withTranslation('timeInput')(TimeInput);
