/* eslint-disable */
import {
  CLEAR_SEARCH_PARAMS,
  CLEAR_SEARCH_RESULT,
  CLEAR_SEARCH_TEXT,
  CLEAR_SEARCH_USER_TEXT,
  GET_SEARCH_FAIL,
  GET_SEARCH_START,
  GET_SEARCH_SUCCESS,
  SET_FIND_SEARCH_TEXT,
  SET_SEARCH_PARAMS,
  SET_SEARCH_TEXT,
  SET_USER_SEARCH_TEXT,
} from './actions';

const initialState = {
  search: null,
  searchParams: null,
  searchUserText: null,
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, text, params, response }) => {
  switch (type) {
    case GET_SEARCH_START:
      return { ...state, isLoading: true, error: false };

    case GET_SEARCH_SUCCESS:
      return { ...state, search: response, isLoading: false, error: false };

    case GET_SEARCH_FAIL:
      return { ...state, isLoading: false, error: true };

    case SET_SEARCH_TEXT:
      return { ...state, searchText: text };

    case SET_FIND_SEARCH_TEXT:
      return { ...state, searchFindSearchText: { ...text } };

    case SET_USER_SEARCH_TEXT:
      return { ...state, searchUserText: text };

    case CLEAR_SEARCH_RESULT:
      return { ...state, search: null, searchFindSearchText: null };

    case CLEAR_SEARCH_TEXT:
      return { ...state, searchText: null };

    case SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...params,
        },
      };

    case CLEAR_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {},
        searchFindSearchText: null,
        searchText: null,
      };

    case CLEAR_SEARCH_USER_TEXT:
      return { ...state, searchUserText: null };

    default:
      return state;
  }
};
