import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './MultiplySelect.module.scss';

const { Option } = Select;

const MultiplySelect = ({
  value,
  onChange,
  name,
  options,
  long,
  highLong,
  highLongSelect,
  dark,
  dataQa,
  medium,
  isDefaultSearch,
  onSearch,
  notFoundContent,
  size,
  white,
  className,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [isOptionsFound, setIsOptionsFound] = useState(false);

  const handleChange = val => {
    onChange({ name, value: val });
  };

  const handleSearch = searchString => {
    const filteredOptions = options.filter(option => {
      return option.name.toLowerCase().includes(searchString.toLowerCase());
    });
    setIsOptionsFound(!!filteredOptions.length);
  };

  const handleFocus = () => {
    setIsOptionsFound(true);
  };

  const createOptions = () => {
    const optionSelect =
      options &&
      options.map(option => (
        <Option key={`option-${option.id}`} value={option.value}>
          {option.name}
        </Option>
      ));

    return optionSelect;
  };

  return (
    <div
      className={classNames(
        className,
        css.Select,
        { [css.dark]: dark },
        { [css.long]: long },
        { [css.highLong]: highLong },
        { [css.highLongSelect]: highLongSelect },
        { [css.noOptionsFound]: !isOptionsFound },
        { [css.medium]: medium },
        { [css.isValue]: value?.length > 0 && medium }
      )}
    >
      <Select
        data-qa={dataQa}
        removeIcon={<CloseOutlined />}
        mode='multiple'
        placeholder={placeholder ?? t('all')}
        defaultValue={value}
        value={value}
        onChange={handleChange}
        onSearch={isDefaultSearch ? onSearch : handleSearch}
        onBlur={handleFocus}
        onFocus={handleFocus}
        filterOption={(input, option) =>
          !!option.props.children && option.props.children.toString().toLowerCase().includes(input.toLowerCase())
        }
        popupClassName={classNames(css['Select-dropdown'], {
          [css.dark]: dark,
          [css.white]: white,
        })}
        notFoundContent={notFoundContent}
        size={size}
      >
        {createOptions()}
      </Select>
    </div>
  );
};

MultiplySelect.propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  highLong: PropTypes.bool,
  highLongSelect: PropTypes.bool,
  long: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  showSearch: PropTypes.bool,
  topicTypeId: PropTypes.bool,
  value: PropTypes.any,
  medium: PropTypes.bool,
  isDefaultSearch: PropTypes.bool,
  notFoundContent: PropTypes.string,
  onSearch: PropTypes.func,
  size: PropTypes.string,
  white: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default MultiplySelect;
