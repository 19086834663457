import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createFileImage, getFile } from '@/store/files/actions';
import { addQuestion, getBankCategories, updateQuestion } from '@/store/questionBank/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectQuestionBank } from '@/store/questionBank/selectors';

import { Button, Form, message, Modal, Select, Spin } from 'antd';
import { MAX_CATEGORY_LENGTH, MAX_CATEGORY_TAGS, QUESTION_TYPES } from '@/pages/QuestionBank/constants';
import { Question } from '@/pages/Topics/TopicDetailsDialog/LMS/Blocks/test/Question/Question';
import {
  emptyAnswer,
  emptyMatchingAnswer,
  MATCHING,
  MATCHING_ID,
  MULTIPLE,
  MULTIPLE_ID,
  OPEN,
  OPEN_ID,
  SINGLE_ID,
} from '@/pages/Topics/TopicDetailsDialog/LMS/Blocks/test/Test/helpers';

import { IMAGE_TYPES } from '@shared/constants/image-types';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './QuestionModal.module.scss';

const ANSWERS = [
  {
    answer: '',
    correct: false,
    imgUuid: null,
    position: 0,
  },
  {
    answer: '',
    correct: false,
    imgUuid: null,
    position: 1,
  },
];

const MATCHING_ANSWERS = [
  {
    left: {
      isText: true,
      content: '',
    },
    right: {
      isText: true,
      content: '',
    },
    position: 0,
  },
  {
    left: {
      isText: true,
      content: '',
    },
    right: {
      isText: true,
      content: '',
    },
    position: 1,
  },
];

const DEFAULT_QUESTION = {
  imgUuid: null,
  // multipleAnswers: QUESTION_TYPES.single, // Работает как тип вопроса. Не спрашивайте почему. TODO: mixmaxmix спрашивает VariKX - как он вообще должен был работать
  answerType: QUESTION_TYPES.single, // Вот это работает как тип вопроса! НЕ ТРОГАТЬ! ПИСАТЬ СРАЗУ @VariKX
  question: '',
  matchingAnswers: [],
  multipleAnswers: false,
  id: null,
  answers: ANSWERS,
  type: 1,
};

const { Option } = Select;

// TODO:
// В данном файле нужно отказаться от функций передаваемые пропсами в компонент Question,
// но для того чтоб это сделать нужно перенести все эти функции в сам компонент Question
// и сделать его независимым от внешних функий.
// Из-за того что сейчас делаются 2 задачи затрагивающие этот файл, было принято решение
// не редактировать файл компонента Question внутри.
// Надеемся на рефактор тестов 🙏

const QuestionModal = ({ questionModal, changeQuestionModal, getQuestionsWithFilter }) => {
  const { t } = useTranslation('QuestionBank');
  const dispatch = useDispatch();
  const files = useSelector(selectFiles);
  const { categories, isLoadingCategories, isLoading } = useSelector(selectQuestionBank);

  const [question, setQuestion] = useState(_.cloneDeep(DEFAULT_QUESTION));
  const [category, setCategory] = useState([]);
  const [searchCategory, setSearchCategory] = useState('');

  // Получение типа вопроса текущего вопроса
  const currentQuestionType = question => {
    switch (question.answerType) {
      case true:
        return QUESTION_TYPES.multiple;

      case false:
        if (question.type === 0 || question.type === +QUESTION_TYPES.single) {
          return QUESTION_TYPES.single;
        } else {
          return QUESTION_TYPES.open;
        }

      default:
        return '';
    }
  };

  // Очистка данных при закрытии модального окна или заполнение данных, если это редактирование
  useEffect(() => {
    if (!questionModal.open) {
      setQuestion(_.cloneDeep(DEFAULT_QUESTION));
      setSearchCategory('');
      setCategory([]);
    } else if (!_.isEmpty(questionModal.question)) {
      // Подставка данных существующего вопроса
      const currentQuestion = _.cloneDeep(questionModal.question);

      // Текущие категории
      const currentCategories = currentQuestion.questionCategories.map(category => ({
        key: `${category.id}`,
        label: category.name,
        value: category.name,
      }));

      // Получение картинки вопроса
      if (currentQuestion.imgUuid) {
        dispatch(
          getFile(currentQuestion.imgUuid, blob => {
            setQuestion(prevState => {
              const imageUrl = URL.createObjectURL(blob);

              return {
                ...prevState,
                image: imageUrl,
                inBank: false,
              };
            });
          })
        );
      }

      // Получение картинок ответов
      if (_.some(currentQuestion.answers, 'imgUuid')) {
        currentQuestion.answers.forEach(answer => {
          if (answer.imgUuid) {
            dispatch(
              getFile(answer.imgUuid, blob => {
                answer.image = URL.createObjectURL(blob);

                setQuestion({
                  ...currentQuestion,
                  answerType: currentQuestionType(currentQuestion),
                  inBank: false,
                });
              })
            );
          }
        });
      }

      if (currentQuestion.type === MATCHING_ID) {
        currentQuestion.matchingAnswers?.forEach(answer => {
          if (!answer.left.isText) {
            dispatch(
              getFile(answer.left.content, blob => {
                answer.left.image = URL.createObjectURL(blob);

                setQuestion({
                  ...currentQuestion,
                  answerType: currentQuestionType(currentQuestion),
                  inBank: false,
                });
              })
            );
          }
          if (!answer.right.isText) {
            dispatch(
              getFile(answer.right.content, blob => {
                answer.right.image = URL.createObjectURL(blob);

                setQuestion({
                  ...currentQuestion,
                  answerType: currentQuestionType(currentQuestion),
                  inBank: false,
                });
              })
            );
          }
        });
      }

      setQuestion({
        ...currentQuestion,
        answerType: currentQuestionType(currentQuestion),
        questionCategories: [],
        inBank: false,
      });
      setCategory(currentCategories);
    }
  }, [questionModal.open]);

  // Добавление ответа
  const addAnswer = useCallback(
    (index, isMatching) => {
      setQuestion(() => {
        if (isMatching) {
          const matchingAnswersCount = question['matchingAnswers'].length;

          question['matchingAnswers'].push(_.cloneDeep({ ...emptyMatchingAnswer, position: matchingAnswersCount }));
        } else {
          question['answers'].push({ ...emptyAnswer });
        }

        return { ...question };
      });
    },
    [question, setQuestion]
  );

  // Изменение вопроса
  const handleChangeQuestion = useCallback(
    (event, index = 0, cropImageSrc, coverColor) => {
      const { name, value } = event.target || event;
      const questionData = Object.assign({}, question);

      // Если текущий answerType === OPEN, то возвращаем ответы
      if (name === 'answerType' && question.answerType === OPEN) {
        questionData.answers = ANSWERS;
      }

      questionData[name] = value;

      const isMatching = questionData.answerType === MATCHING;

      // 'answerType' это имя элемента с выпадающим списком типа вопроса
      if (name === 'answerType') {
        if (isMatching) {
          questionData.type = MATCHING_ID;
          questionData.matchingAnswers = MATCHING_ANSWERS;
          questionData.answers = [];
        }

        if (!isMatching && questionData.answerType !== OPEN && questionData.answerType !== MULTIPLE) {
          questionData.answers.forEach(answer => (answer.correct = false));
          questionData.type = MULTIPLE_ID; // У одиночного варианта ответа и у matching у них id 1;
          questionData.multipleAnswers = false;
        }

        if (questionData.answerType === MULTIPLE) {
          questionData.type = MULTIPLE_ID;
          questionData.multipleAnswers = true;
        }

        if (questionData.answerType === OPEN) {
          handleChangeOpenQuestion(event, index, cropImageSrc, coverColor);
          return;
        }
      }

      if (cropImageSrc) {
        questionData.image = cropImageSrc;
      }

      if (coverColor) {
        questionData.color = coverColor;
      }

      setQuestion(questionData);
    },
    [question, setQuestion]
  );

  // Изменение параметра открытого вопроса
  // eslint-disable-next-line
  const handleChangeOpenQuestion = (event, index = 0, cropImageSrc, coverColor) => {
    const { name, value } = event.target || event;
    const questionData = Object.assign({}, question);

    questionData.answers = [];
    questionData.type = OPEN_ID;

    if (name === 'hasAttachment') {
      questionData.hasAttachment = value;
    }
    if (cropImageSrc) {
      questionData.image = cropImageSrc;
    }

    if (coverColor) {
      questionData.color = coverColor;
    }

    setQuestion(questionData);
  };

  // Изменение ответа
  const handleChangeAnswer = useCallback(
    // eslint-disable-next-line
    ({ event, index, indexAnswer, cropImageSrc, coverColor, fileName }) => {
      const { name, value, checked } = event?.target || event;

      setQuestion(prevQuestion => {
        const questions = _.cloneDeep(prevQuestion);

        const currentQuestion = questions;

        const isMatching = currentQuestion.type === MATCHING_ID || currentQuestion.answerType === MATCHING;

        if (isMatching) {
          const side = name.split('-')[1];
          const isText = name.toLowerCase().includes('answer');

          const currentAnswer = currentQuestion['matchingAnswers'][indexAnswer];

          currentAnswer[side].content = value;
          currentAnswer[side].isText = value ? isText : true;

          if (!value && !isText) {
            delete currentAnswer[side].image;
            delete currentAnswer[side].fileName;
          }

          if (cropImageSrc) {
            currentAnswer[side].image = cropImageSrc;
            currentAnswer[side].fileName = fileName;
          }

          if (coverColor) {
            currentAnswer[side].color = coverColor;
          }
        } else {
          const currentAnswer = currentQuestion['answers'][indexAnswer];

          if (name === 'correct' && currentQuestion.type === MULTIPLE_ID && !currentQuestion.multipleAnswers) {
            currentQuestion.answers.forEach(answer => (answer.correct = false));
          }

          if (event.target) {
            currentAnswer[name] = value?.replace(';', '') ?? checked;
          } else {
            currentAnswer[name] = value ?? checked;
          }

          if (cropImageSrc) {
            currentAnswer.image = cropImageSrc;
          }

          if (coverColor) {
            currentAnswer.color = coverColor;
          }
        }
        return questions;
      });
    },

    [question, setQuestion]
  );

  // Удаление ответа
  const handleDeleteAnswer = useCallback(
    (index, answerIndex) => {
      const currentQuestion = question;

      const answersType = currentQuestion.type === MATCHING_ID ? 'matchingAnswers' : 'answers';
      const currentAnswers = currentQuestion[answersType];

      currentAnswers.splice(answerIndex, 1);
      currentAnswers.forEach((answer, index) => ({ ...answer, position: index }));

      setQuestion(() => ({ ...currentQuestion }));
    },
    [question, setQuestion]
  );

  // Изменение и добавление изображения
  const handleImageChange = useCallback(
    (file, name, info, cropImageSrc, coverColor, index, answerIndex) => {
      if (file && info) {
        if (IMAGE_TYPES.includes(info.type)) {
          dispatch(
            createFileImage(file, fileInfo => {
              const fileName = fileInfo.name;

              if (answerIndex !== undefined) {
                handleChangeAnswer({
                  event: { name, value: fileInfo.uuid },
                  index,
                  indexAnswer: answerIndex,
                  cropImageSrc: URL.createObjectURL(cropImageSrc),
                  coverColor,
                  fileName,
                });
              } else {
                handleChangeQuestion(
                  { name, value: fileInfo.uuid },
                  index,
                  URL.createObjectURL(cropImageSrc),
                  coverColor
                );
              }
            })
          );
          return true;
        }
        if (cropImageSrc !== undefined || answerIndex !== undefined) {
          handleChangeAnswer({
            event: { name, value: null },
            index,
            indexAnswer: answerIndex,
            cropImageSrc,
            coverColor: null,
          });
        } else {
          handleChangeQuestion({ name, value: null }, index, info, null);
        }
      } else {
        if (cropImageSrc !== undefined || answerIndex !== undefined) {
          handleChangeAnswer({
            event: { name, value: file },
            index,
            indexAnswer: answerIndex,
            cropImageSrc,
            coverColor: null,
          });
        } else {
          handleChangeQuestion({ name, value: file }, index, info, null);
        }
      }
    },
    [handleChangeAnswer, handleChangeQuestion, question]
  );

  // Генерация категорий
  const optionsCetegory = useMemo(
    () =>
      categories.map(category => (
        <Option key={category.id} label={category.name} value={category.name}>
          {category.name}
        </Option>
      )),
    [categories, searchCategory]
  );

  // Отдельная функция для получения категорий через debounce
  const handleGetBankCategories = category => dispatch(getBankCategories(category));

  // Запрос с debounce на получение категорий
  const getBankCategoriesDebounced = useCallback(_.debounce(handleGetBankCategories, 500), []);

  // Поиск категорий
  const onSearchCategory = val => {
    const trimVal = val?.trim();
    if (trimVal?.length <= MAX_CATEGORY_LENGTH) {
      setSearchCategory(trimVal);
      getBankCategoriesDebounced(trimVal);
    }
  };

  // При создании новой категории
  const onCreateNewCategory = () => {
    getBankCategoriesDebounced('');
    setSearchCategory('');
  };

  // Запись данных
  const onChangeCategory = value => {
    if (Array.isArray(value) && value.length <= MAX_CATEGORY_TAGS) {
      setCategory(value);
    } else {
      message.error(t('maxTags', { max: MAX_CATEGORY_TAGS }));
    }
  };
  // Проверка валидности ответов
  const checkValidAnswer = () => {
    let invalidCount = 0;
    if (!_.every(question.answers, 'answer')) {
      for (const answer of question.answers) {
        if (!answer.answer && !answer.imgUuid) {
          invalidCount++;
        }
      }
    }
    if (invalidCount > 0) {
      return false;
    }
    return true;
  };
  // Проверка можно ли создать вопрос
  const isCanCreate = useMemo(() => {
    switch (question.type) {
      case SINGLE_ID: // Один вариант ответа
      case MULTIPLE_ID: // Несколько вариантов ответа
        if (
          Array.isArray(category) &&
          category.length > 0 &&
          question.question &&
          Array.isArray(question.answers) &&
          !_.isEmpty(question.answers) &&
          checkValidAnswer() &&
          _.some(question.answers, { correct: true })
        ) {
          return true;
        } else {
          return false;
        }

      case OPEN_ID: // Открытый ответ
        return Array.isArray(category) && category.length > 0 && question.question;

      case MATCHING_ID:
        const findNull = question?.matchingAnswers?.filter(
          element => !element.right.content?.length || !element.left.content?.length
        );
        return Array.isArray(category) && category?.length > 0 && !findNull?.length && question?.question;

      default:
        return false;
    }
  }, [question, category]);

  // При добавлении или редактировании вопроса
  const onSaveQuestion = isEdit => {
    // Корректные данные для категорий
    const data = _.cloneDeep(question);

    const questionCategory = category.map(category => ({
      id: category.key === category.label ? null : +category.key,
      name: category.label,
    }));

    delete data.questionCategories;
    data.answers = data.answers.map((answ, index) => ({ ...answ, position: index }));

    // Корректные данные для бэка
    const correctQuestion = {
      ...data,
      answerType: data.answerType === QUESTION_TYPES.multiple, // булевое === строка ???
      inBank: true,
      questionCategoryDtos: questionCategory,
    };

    const onSuccess = () => {
      changeQuestionModal(false);
      message.success(t(isEdit ? 'onSuccessEdit' : 'onSuccessCreate'));
      getQuestionsWithFilter();
    };

    if (isEdit) {
      dispatch(updateQuestion(correctQuestion, onSuccess));
    } else {
      dispatch(addQuestion(correctQuestion, onSuccess));
    }
  };

  const modalFooter = (
    <>
      <Button onClick={() => changeQuestionModal(false)} ghost>
        {t('cancel')}
      </Button>
      <Button
        type={'primary'}
        disabled={!isCanCreate}
        onClick={() => onSaveQuestion(questionModal.isEdit)}
        loading={isLoading}
      >
        {t(questionModal.isEdit ? 'editQuestionBtn' : 'createQuestionBtn')}
      </Button>
    </>
  );

  return (
    <Modal
      width={770}
      open={questionModal.open}
      onCancel={() => changeQuestionModal(false)}
      title={t(questionModal.isEdit ? 'editQuestion' : 'addQuestion')}
      footer={modalFooter}
    >
      <Spin spinning={files.isLoading || files.isLoadingImage} tip={t('loadingTip')}>
        <Form labelCol={{ span: 4 }} className={css['QuestionModal_form']}>
          <Form.Item label={t('category')}>
            <Select
              className={css['QuestionModal_form_select']}
              mode='multiple'
              onSearch={onSearchCategory}
              showSearch
              placeholder={t('selectCategory')}
              optionFilterProp='children'
              optionLabelProp='label'
              autoClearSearchValue
              labelInValue
              value={category}
              onChange={onChangeCategory}
              onBlur={() => onSearchCategory('')}
              loading={isLoadingCategories}
              maxTagTextLength={40} // Влияет только на отображение в select'e
              notFoundContent={category.length === MAX_CATEGORY_TAGS && t('maxTags', { max: MAX_CATEGORY_TAGS })}
            >
              {/* Дополнительный option для создания новой категории */}
              {searchCategory &&
                Array.isArray(category) &&
                category.length < MAX_CATEGORY_TAGS &&
                !_.some(categories, { name: searchCategory }) &&
                !isLoadingCategories && (
                  <Option
                    key={searchCategory}
                    value={searchCategory.trim()}
                    onClick={() => onCreateNewCategory()}
                    label={searchCategory}
                  >
                    {searchCategory}
                    {t('newCategory')}
                  </Option>
                )}
              {optionsCetegory}
            </Select>
          </Form.Item>
        </Form>
        {questionModal.open && (
          <Question
            data={question}
            index={0}
            changeQuestionValue={handleChangeQuestion}
            changeAnswerValue={handleChangeAnswer}
            changeOpenQuestion={handleChangeOpenQuestion}
            addAnswer={addAnswer}
            handleDeleteAnswer={handleDeleteAnswer}
            handleImageChange={handleImageChange}
            files={files}
            canEdit={true}
            withoutCollapse
            questionBank
          />
        )}
      </Spin>
    </Modal>
  );
};

QuestionModal.propTypes = {
  changeQuestionModal: PropTypes.func,
  questionModal: PropTypes.shape({
    open: PropTypes.bool,
    isEdit: PropTypes.bool,
  }),
  getQuestionsWithFilter: PropTypes.func,
};

export default QuestionModal;
