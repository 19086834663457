/* eslint-disable */
import {
  CREATE_CFIELDVALUE_FAIL,
  CREATE_CFIELDVALUE_START,
  CREATE_CFIELDVALUE_SUCCESS,
  GET_CFIELDVALUE_FAIL,
  GET_CFIELDVALUE_START,
  GET_CFIELDVALUE_SUCCESS,
  UPDATE_CFIELDVALUE_FAIL,
  UPDATE_CFIELDVALUE_START,
  UPDATE_CFIELDVALUE_SUCCESS,
} from './constants';

const initialState = {
  field: [],
  loading: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case CREATE_CFIELDVALUE_START:
    case CREATE_CFIELDVALUE_SUCCESS:
    case CREATE_CFIELDVALUE_FAIL:

    case UPDATE_CFIELDVALUE_START:
    case UPDATE_CFIELDVALUE_SUCCESS:
    case UPDATE_CFIELDVALUE_FAIL:

    case GET_CFIELDVALUE_START:
    case GET_CFIELDVALUE_SUCCESS:
    case GET_CFIELDVALUE_FAIL:

    default:
      return state;
  }
};
