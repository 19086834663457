/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { AccountCircle, Bookmarks, Home } from '@/resources/icons';
import classNames from 'classnames';

import css from './DomainSpinner.module.scss';

export const DomainSpinner = () => {
  const [isMounted, setMounted] = useState(false); //TODO: костыль из-за долгой загрузки стилей

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      {isMounted && (
        <div className={classNames(css['no-freeze-spinner-wrapper'], css.DomainSpinner)}>
          <div className={css['no-freeze-spinner']}>
            <div>
              <i className={css['material-icons']}>
                <AccountCircle />
              </i>
              <i className={css['material-icons']}>
                {' '}
                <Bookmarks />
              </i>
              <i className={css['material-icons']}>
                {' '}
                <Home />
              </i>
              <i className={css['material-icons']}></i>
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
