import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import _ from 'lodash';
import PropTypes from 'prop-types';

const VALUE_MIN_LENGTH = 2;

const Search = props => {
  const { t } = useTranslation('D360_IndividualReports');
  const [column, setColumn] = useState(null);
  const [value, setValue] = useState(props.defaultValue || '');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!_.isNil(props) && _.isObject(props) && _.has(props, 'column')) {
      setColumn(props.column);
    }
  }, [props]);

  useEffect(() => {
    !_.isNil(column) &&
    _.isString(column) &&
    column.toString().length > 0 &&
    !_.isNil(value) &&
    _.isString(value) &&
    value.toString().length > VALUE_MIN_LENGTH
      ? setIsValid(true)
      : setIsValid(false);
  }, [value, column]);

  const onReset = () => {
    setValue(null);
    if (_.isFunction(props.fnClearFilters)) props.fnClearFilters();
    if (_.isFunction(props.fnConfirm)) props.fnConfirm();
    if (_.isFunction(props.onReset)) props.onReset();
  };

  const onSubmit = () => {
    if (_.isFunction(props.fnConfirm)) props.fnConfirm();
    if (_.isFunction(props.onSubmit)) props.onSubmit(column, value);
  };

  return (
    <Card size='small' hoverable>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            placeholder={t('employeeName')}
            value={value}
            onChange={e => setValue(e.target.value)}
            onPressEnter={onSubmit}
          />
        </Col>
        <Col span={24} align='right'>
          <Button
            type='primary'
            onClick={onSubmit}
            disabled={!isValid}
            icon={<SearchOutlined />}
            size='small'
            style={{ marginRight: 10 }}
          >
            Поиск
          </Button>
          <Button onClick={onReset} size='small' disabled={!isValid}>
            Сброс
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

Search.propTypes = {
  fnClearFilters: PropTypes.func.isRequired,
  fnConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

export default Search;
