/* eslint-disable */
import React from 'react';

import { Form, TimePicker } from 'antd';
import ImageInput from '@/components/ImageInputCrop';

import dayjs from 'dayjs';

import css from './CompanyCreationDialog.module.scss';

const format = 'HH:mm';

export const Types = ({ company, handleChangeTime, handleImageChange, fileInfo, fileLoadingImage, t }) => {
  const eventStart = company?.eventStartTime ? dayjs(company.eventStartTime, format) : null;
  const eventEnd = company?.eventEndTime ? dayjs(company.eventEndTime, format) : null;

  return (
    <div className={css['Add-modal']}>
      <div className={css['Add-modal-title']}>{t('settings')}</div>

      {/* Начало мероприятия / события */}
      <Form.Item label={t('eventStart')} labelAlign='left'>
        <TimePicker
          placeholder='10:00'
          format={format}
          onChange={(obj, time) => handleChangeTime(time, 'eventStartTime')}
          name='eventStartTime'
          defaultValue={eventStart}
        />
      </Form.Item>

      {/* Конец мероприятия / события */}
      <Form.Item label={t('eventEnd')} labelAlign='left'>
        <TimePicker
          placeholder='10:00'
          format={format}
          onChange={(obj, time) => handleChangeTime(time, 'eventEndTime')}
          name='eventEndTime'
          defaultValue={eventEnd}
        />
      </Form.Item>

      {/* Обложка корп. курса */}
      <Form.Item
        label={
          <div>
            <div>{t('logoCorporate')}</div>
            <span className={css['Add-modal-description']}>PNG/JPG 282x155</span>
          </div>
        }
        labelAlign='left'
      >
        <ImageInput
          reverse
          company
          noCrop
          id='corpCourseBackground'
          onChange={handleImageChange}
          name='corpCourseBackground'
          imageSrc={company.images.corpCourseBackground}
          fileInfo={fileInfo}
          isLoadingFileImage={fileLoadingImage}
        />
      </Form.Item>

      {/* Иконка корп. курса */}
      <Form.Item
        label={
          <div>
            <div>{t('iconCorporate')}</div>
            <span className={css['Add-modal-description']}>SVG 20x20</span>
          </div>
        }
        labelAlign='left'
      >
        <ImageInput
          reverse
          company
          noCrop
          id='corpCourseIcon'
          onChange={handleImageChange}
          name='corpCourseIcon'
          imageSrc={company.images.corpCourseIcon}
          fileInfo={fileInfo}
          isLoadingFileImage={fileLoadingImage}
        />
      </Form.Item>
    </div>
  );
};
