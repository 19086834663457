import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCompetence } from '@/store/competence/actions';
import { exportMentors, getMentorsStatus } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Layout, Typography } from 'antd';
import { Content } from '@/components/Layout';
import { FileExcelOutlined } from '@ant-design/icons';

import Filters from './Content/Filters';
import ModalAddMentor from './Content/Modal/ModalAddMentor';
import ModalSettings from './Content/Modal/ModalSettings';
import ModalVisibilitySettings from './Content/Modal/ModalVisibilitySettings';
import TableMentors from './Content/TableMentors';

import css from './Mentors.module.scss';

import { EXPORT_MENTOR, MENTOR_CREATE_BY_ADMIN, MENTOR_SETTINGS_BY_ADMIN } from '@/constants/permissions';

const { Title } = Typography;

const Mentors = () => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMentorsStatus());
    dispatch(getCompetence());
  }, [dispatch]);

  const { mentorsFilters, exportMentorsLoading } = useSelector(selectMentors);
  const { currentUser } = useSelector(selectUsers);

  const [isSettings, setIsSettings] = useState(false);
  const [isVisibility, setIsVisibility] = useState(false);
  const [isModalMentor, setIsModalMentor] = useState(false);

  const exportTable = () => {
    const date = new Date();
    const fileNameNotSplit = `mentors(${date.toLocaleDateString()}).csv`;

    dispatch(
      exportMentors(mentorsFilters.filters ? mentorsFilters.filters : {}, (blob, headers) => {
        const fileName = headers.get('Content-Disposition').split(['"'])[1] || fileNameNotSplit;
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  const showModalSettings = () => {
    setIsSettings(true);
  };

  const modalVisibilitySettings = () => {
    setIsVisibility(true);
  };

  const showModalAddMentor = () => {
    setIsModalMentor(true);
  };

  const closeAddModalMentor = () => {
    setIsModalMentor(false);
  };

  return (
    <Layout>
      <Content>
        <div className={css['Content-header']}>
          <Title>{t('title')}</Title>
          <div className={css['Content-header-actions']}>
            {currentUser.authorities.includes(MENTOR_SETTINGS_BY_ADMIN) && (
              <Button
                type='primary'
                onClick={showModalSettings}
                size='large'
                className={css['Content-header-actions-settings']}
              >
                {t('settingsMentors')}
              </Button>
            )}
            {currentUser.authorities.includes(MENTOR_SETTINGS_BY_ADMIN) && (
              <Button type='primary' onClick={modalVisibilitySettings} size='large'>
                {t('settingsVisibleMentors')}
              </Button>
            )}
            {currentUser.authorities.includes(MENTOR_CREATE_BY_ADMIN) && (
              <Button
                type='primary'
                className={css['Content-header-actions-addMentor']}
                onClick={showModalAddMentor}
                data-qa='adminAddMentorBtn'
                size='large'
              >
                {t('addMentor')}
              </Button>
            )}
            {currentUser.authorities.includes(EXPORT_MENTOR) && (
              <Button
                className={css['Content-header-actions-export']}
                type='primary'
                onClick={exportTable}
                icon={<FileExcelOutlined />}
                loading={exportMentorsLoading}
                size='large'
              >
                {t('exportBtn')}
              </Button>
            )}
          </div>
        </div>

        <Filters />
        <TableMentors />

        {isSettings && <ModalSettings open={isSettings} onCloseModal={() => setIsSettings(false)} />}
        {isModalMentor && <ModalAddMentor open={isModalMentor} onCancel={closeAddModalMentor} />}
        {isVisibility && <ModalVisibilitySettings open={isVisibility} onCloseModal={() => setIsVisibility(false)} />}
      </Content>
    </Layout>
  );
};

export default Mentors;
