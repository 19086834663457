import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getTrackDetails, postTracksFilters } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Table, Tooltip } from 'antd';
import ImageBlock from '@/components/ImageBlock';
import ModalTrack from '@/components/ModalTrack';
import { DeleteOutlined, FormOutlined, LoadingOutlined } from '@ant-design/icons';

import {
  DATE_FORMAT_LIST,
  STATUS_ACTIVE,
  STATUS_IN_PROCESS,
  STATUS_ON_UPDATE,
  TYPE_DELETED,
  TYPE_UPDATE,
} from './constance';
import ModalActionTrack from './Modal/ModalActionTrack';

import classNames from 'classnames';
import dayjs from 'dayjs';

import css from '../Tracks.module.scss';

import { ACCESS_TO_TRACKS, TRACK_DELETE, TRACK_UPDATE } from '@/constants/permissions';

const TableTracks = () => {
  const { t } = useTranslation('tracks');
  const dispatch = useDispatch();

  const {
    tracksFilters,
    tracksLoading,
    tracksTable,
    tracksTotal,
    tracksPageSize,
    tracksPageNumber,
    tracksCurrentPage,
  } = useSelector(selectTracks);
  const { currentUser } = useSelector(selectUsers);

  const [isAction, setIsAction] = useState({
    open: false,
    id: null,
    type: '',
  });
  const [isModalTrack, setIsModalTrack] = useState(false);

  const deletedTrack = val => {
    if (val) setIsAction({ open: true, id: val.trackId, type: TYPE_DELETED });
  };

  const editTrack = val => {
    if (val) {
      setIsModalTrack(true);
      dispatch(getTrackDetails(val.trackId));
    }
  };

  const renderEditDelete = item => {
    return (
      <>
        {currentUser?.authorities.includes(TRACK_UPDATE) && (
          <Tooltip placement='top' title={t('editTrack')}>
            <FormOutlined className={css['TableContent-table-actions-edit']} onClick={() => editTrack(item)} />
          </Tooltip>
        )}
        {currentUser?.authorities.includes(TRACK_DELETE) && (
          <Tooltip
            placement='top'
            title={item.trackStatus !== STATUS_IN_PROCESS ? t('deleteTrack') : t('inProcessTrack')}
          >
            <DeleteOutlined
              className={classNames(
                [css['TableContent-table-actions-delete']],
                item.trackStatus === STATUS_IN_PROCESS && [css['TableContent-table-actions-inProcess']]
              )}
              onClick={() => item.trackStatus !== STATUS_IN_PROCESS && deletedTrack(item)}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const renderLoading = (
    <Tooltip placement='top' title={t('updateTrack')}>
      <LoadingOutlined />
    </Tooltip>
  );

  const trackPath = value => {
    switch (value.trackStatus) {
      case STATUS_ACTIVE:
      case STATUS_IN_PROCESS:
        return { pathname: `/track/${value.trackId}`, state: { tarckId: value.trackId } };

      case STATUS_ON_UPDATE:
        return {};

      default:
        break;
    }
  };

  const showUpdateModal = text => setIsAction({ open: true, id: null, type: TYPE_UPDATE, nameTrack: text });

  const columns = [
    {
      title: t('trackPhoto'),
      dataIndex: 'trackPhoto',
      key: '1',
      width: 100,
      align: 'center',
      render: photoUuid => <ImageBlock photoUuid={photoUuid} />,
    },
    {
      title: t('trackName'),
      dataIndex: 'trackName',
      key: '2',
      width: 400,
      render: (text, value) => (
        <>
          <Tooltip title={text?.length > 35 && text}>
            {currentUser?.authorities.includes(ACCESS_TO_TRACKS) || true ? (
              <Link
                className={css['TableContent-table-cell-href']}
                to={trackPath(value)}
                onClick={() => value.trackStatus === STATUS_ON_UPDATE && showUpdateModal(text)}
              >
                {text}
              </Link>
            ) : (
              <span onClick={() => value.trackStatus === STATUS_ON_UPDATE && showUpdateModal(text)}>{text}</span>
            )}
          </Tooltip>
        </>
      ),
    },
    {
      title: t('trackCreatedBy'),
      dataIndex: 'trackCreatedBy',
      key: '3',
    },
    {
      title: t('trackDate'),
      dataIndex: 'trackDate',
      key: '4',
    },
    {
      title: t('trackAction'),
      dataIndex: 'trackAction',
      key: '5',
      align: 'center',
      render: (value, item) => (
        <div className={css['TableContent-table-actions']}>
          {item.trackStatus === STATUS_ON_UPDATE ? renderLoading : renderEditDelete(item)}
        </div>
      ),
    },
  ];

  const getData = () => {
    if (tracksTable?.length) {
      return tracksTable.map(item => {
        let username =
          (item.createdBy.lastName || '') +
          ' ' +
          (item.createdBy.firstName || '') +
          ' ' +
          (item.createdBy.middleName || '');

        return {
          trackName: item.name,
          trackDate: item.createdAt && dayjs(item.createdAt).format(DATE_FORMAT_LIST),
          trackCreatedBy: username,
          trackId: item.id,
          trackPhoto: item.coverImage,
          trackStatus: item.status,
        };
      });
    }
  };

  const onChangeTable = pagination => {
    dispatch(postTracksFilters(tracksFilters.filters ? tracksFilters.filters : {}, pagination.current - 1));
  };

  const pagination = {
    total: tracksTotal,
    pageSize: tracksPageSize,
    pageNumber: tracksPageNumber,
    current: tracksCurrentPage + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  return (
    <div className={css['TableContent']}>
      <div className={css['TableContent-table']}>
        <Table
          columns={columns}
          dataSource={getData()}
          rowKey={e => e.trackId}
          pagination={pagination}
          onChange={onChangeTable}
          loading={tracksLoading}
        />
      </div>

      {isAction.open && (
        <ModalActionTrack
          open={isAction.open}
          onClose={() => setIsAction({ open: false, id: null, type: '' })}
          trackId={isAction.id}
          action={isAction.type}
          nameTrack={isAction.nameTrack}
        />
      )}

      {isModalTrack && <ModalTrack open={isModalTrack} onClose={() => setIsModalTrack(false)} isEdit />}
    </div>
  );
};

export default TableTracks;
