export const D360_CREATE_PROJECT_START = 'D360_CREATE_PROJECT_START';
export const D360_CREATE_PROJECT_SUCCESS = 'D360_CREATE_PROJECT_SUCCESS';
export const D360_CREATE_PROJECT_FAIL = 'D360_CREATE_PROJECT_FAIL';

export const D360_UPDATE_PROJECT_START = 'D360_UPDATE_PROJECT_START';
export const D360_UPDATE_PROJECT_SUCCESS = 'D360_UPDATE_PROJECT_SUCCESS';
export const D360_UPDATE_PROJECT_FAIL = 'D360_UPDATE_PROJECT_FAIL';

export const D360_COPY_PROJECT_START = 'D360_COPY_PROJECT_START';
export const D360_COPY_PROJECT_SUCCESS = 'D360_COPY_PROJECT_SUCCESS';
export const D360_COPY_PROJECT_FAIL = 'D360_COPY_PROJECT_FAIL';

export const D360_DELETE_PROJECT_START = 'D360_DELETE_PROJECT_START';
export const D360_DELETE_PROJECT_SUCCESS = 'D360_DELETE_PROJECT_SUCCESS';
export const D360_DELETE_PROJECT_FAIL = 'D360_DELETE_PROJECT_FAIL';

export const D360_GET_PROJECTS_START = 'D360_GET_PROJECTS_START';
export const D360_GET_PROJECTS_SUCCESS = 'D360_GET_PROJECTS_SUCCESS';
export const D360_GET_PROJECTS_FAIL = 'D360_GET_PROJECTS_FAIL';

export const D360_HEALTH_PROJECT_START = 'D360_HEALTH_PROJECT_START';
export const D360_HEALTH_PROJECT_SUCCESS = 'D360_HEALTH_PROJECT_SUCCESS';
export const D360_HEALTH_PROJECT_FAIL = 'D360_HEALTH_PROJECT_FAIL';

export const D360_ACTIVE_PROJECT_START = 'D360_ACTIVE_PROJECT_START';
export const D360_ACTIVE_PROJECT_SUCCESS = 'D360_ACTIVE_PROJECT_SUCCESS';
export const D360_ACTIVE_PROJECT_FAIL = 'D360_ACTIVE_PROJECT_FAIL';

export const D360_DEACTIVATE_PROJECT_START = 'D360_DEACTIVATE_PROJECT_START';
export const D360_DEACTIVATE_PROJECT_SUCCESS = 'D360_DEACTIVATE_PROJECT_SUCCESS';
export const D360_DEACTIVATE_PROJECT_FAIL = 'D360_DEACTIVATE_PROJECT_FAIL';
