/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createTag, deleteTag, editTag, getTags, getTagsGlobal } from '@/store/tags/actions';

import { selectTags } from '@/store/tags/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import TagsTable from './TagsTable';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

export class TagDirectory extends Component {
  static propTypes = {
    tagsGlobal: PropTypes.object,
    createTag: PropTypes.func,
    creationError: PropTypes.bool,
    deleteTag: PropTypes.func,
    editTag: PropTypes.func,
    editingError: PropTypes.bool,
    getTags: PropTypes.func,
    getTagsGlobal: PropTypes.func,
    isLoading: PropTypes.bool,
    tags: PropTypes.array,
    tagsPageNumber: PropTypes.number,
    tagsPageSize: PropTypes.number,
    tagsTotal: PropTypes.number,
    isGlobal: PropTypes.bool,
  };

  render() {
    return (
      <>
        <TagsTable {...this.props} />
        {!this.props.isGlobal && (
          <TagsTable
            {...this.props}
            {...this.props.tagsGlobal}
            tags={this.props.tagsGlobal.tags || []}
            getTags={this.props.getTagsGlobal}
            shared={true}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createSelector([selectTags, usersSelect], (tags, users) => ({
  tags: tags.tags || [],
  tagsGlobal: tags.globalTags,
  tagsTotal: tags.tagsTotal,
  tagsPageSize: tags.tagsPageSize,
  tagsPageNumber: tags.tagsPageNumber || 0,
  creationError: tags.creationError || false,
  editingError: tags.editingError || false,
  isLoading: tags.isLoading,
  isGlobal: users.currentUser.domainCompany.global,
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getTags,
  getTagsGlobal,
  createTag,
  editTag,
  deleteTag,
};

export default connect(mapStateToProps, mapActionsToProps)(TagDirectory);
