import React, { useState } from 'react';

import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import _ from 'lodash';
import PropTypes from 'prop-types';

const Search = props => {
  const [text, setText] = useState(props.value || null);

  const onReset = () => {
    if (_.isFunction(props.fnClearFilters)) {
      props.fnClearFilters();
    }

    if (_.isFunction(props.onReset)) {
      props.onReset();
    }
  };

  const onSubmit = () => {
    if (_.isFunction(props.fnFilterConfirm)) {
      props.fnFilterConfirm();
    }

    if (_.isFunction(props.onSubmit)) {
      props.onSubmit(text);
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Input
        placeholder='Введите имя сотрудника'
        value={text}
        onChange={e => setText(e.target.value)}
        onPressEnter={onSubmit}
      />
      <Button
        type='primary'
        onClick={onSubmit}
        icon={<SearchOutlined />}
        size='small'
        style={{ marginTop: 10, marginRight: 10 }}
      >
        Поиск
      </Button>
      <Button onClick={onReset} size='small'>
        Сброс
      </Button>
    </div>
  );
};

Search.propTypes = {
  value: PropTypes.string,
  fnClearFilters: PropTypes.func.isRequired,
  fnFilterConfirm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default Search;
