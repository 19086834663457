export const IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/pjpeg', 'image/png', 'image/svg+xml'];
export const COVER_IMAGE_TYPES = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/svg+xml'];
export const DOC_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/msword',
  'application/x-zip-compressed',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/epub+zip',
  'video/quicktime',
  'video/avi',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  '',
  'video/x-msvideo',
  'video/avi',
  'video/msvideo',
  'application/vnd.ms-wordml',
  'application/msword2',
  'application/msword5',
  'application/vnd.ms-word',
  'application/x-tika-msoffice',
  'application/x-tika-ooxml',
  'application/mspowerpoint',
  'application/x-mobipocket-ebook',
  'application/zip',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
  'video/mp4',
  'application/quicktime',
  'application/x-pdf',
  'application/x-fictionbook+xml',
];
