/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ScrollArrow.module.scss';

const icons = {
  left: (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12'>
      <path stroke='#FFF' fill='none' d='M5.864 10.728 1 5.864 5.864 1' />
    </svg>
  ),
  right: (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12'>
      <path stroke='#FFF' fill='none' d='m1 10.728 4.864-4.864L1 1' />
    </svg>
  ),
};

export default class ScrollArrow extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    scrollDirection: PropTypes.string,
  };

  handleClick = event => {
    const { onClick, disabled } = this.props;
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  render() {
    const { className, disabled, scrollDirection } = this.props;

    return (
      <div
        onClick={this.handleClick}
        className={classNames(className, css.ScrollArrow, {
          [css.disabled]: disabled,
        })}
      >
        {icons[scrollDirection]}
      </div>
    );
  }
}
