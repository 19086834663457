/* eslint-disable */
import {
  GET_EXAMPLE_FAIL,
  GET_EXAMPLE_START,
  GET_EXAMPLE_SUCCESS,
  IMPORT_QUESTION_FAIL,
  IMPORT_QUESTION_START,
  IMPORT_QUESTION_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case GET_EXAMPLE_START:
    case IMPORT_QUESTION_START:
      return { ...state, isLoading: true };

    case GET_EXAMPLE_SUCCESS:
    case IMPORT_QUESTION_SUCCESS:
      return { ...state, isLoading: false };

    case GET_EXAMPLE_FAIL:
    case IMPORT_QUESTION_FAIL:
      return { ...state, isLoading: false, isError: true };

    default:
      return { ...state };
  }
};
