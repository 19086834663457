import { GlobalOutlined } from '@ant-design/icons';

const CourseSvg = props => (
  <div>
    <GlobalOutlined style={{ fontSize: '12px' }} />
    {props.name}
  </div>
);

export default CourseSvg;
