import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  addUserEvent,
  courseRequestFinish,
  createEvent,
  deleteRequestCourses,
  getCommentRejected,
  getCoursesCostManagerRequest,
  getCoursesCostRequest,
  getCoursesRequest,
  getCoursesRequestExport,
  getEventsGrours,
  getEventTopic,
  getYear,
  postCommentRejected,
  updateCoursesRequest,
  updateStudyPlanDate,
} from '@/store/courses/actions';
import { getDepartmentsLimitCost, getTotalDepartmentsLimitCost } from '@/store/department/actions';
import { getTypeLabels } from '@/store/label_topic/actions';
import { getBlockStatistic } from '@/store/lms/actions';
import { getDepartment, getFunction, getStudentsList } from '@/store/students/actions';

import { selectCourses } from '@/store/courses/selectors';
import { selectDepartments } from '@/store/department/selectors';
import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectStudents } from '@/store/students/selectors';
import { selectUsers } from '@/store/users/selectors';

import {
  Button,
  Col,
  Dropdown,
  Flex,
  Form,
  Modal,
  Row,
  Select,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Filter from '@/components/Filter';
import { Content } from '@/components/Layout';
import { CurrencySymbol } from '@/components/MaterialCard/CurrencySymbol';
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  DownOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

import RejectCommentModal from '../RejectCommentModal';
import TestsInfoModal from '../Topics/TopicDetailsDialog/TestsInfoModal';
import ActionButton from './ActionButton';
import EventSelectModal from './components/EventSelectModal';
import { SortBar } from './components/SortBar';
import {
  actionStatuses,
  DEFAULT_MODALS_DATA,
  DEFAULT_SELECTED_ROWS,
  defaultStatuses,
  DELETED,
  DISABLE_COURSE_STATUSES,
  filters,
  getStatusTag,
  massStatusConditions,
  MAX_TOPIC_NAME,
  NOT_ADMIN_DISABLE_COURSE_STATUSES,
  scheduledStatuses,
  statusCourse,
} from './constants';
import CreateEventModal from './CreateEventModal';
import { Modals } from './Modals';

import useDebounce from '@shared/hooks/useDebounce';
import { isConference, isConfOrAllCourse } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import noun from 'plural-ru';

import css from './ManageCourse.module.scss';

import {
  FEE_BASED_COURSE_EVENT_CREATE,
  FEE_BASED_COURSE_EVENT_UPDATE,
  FEE_BASED_COURSE_REQUEST_EXPORT,
  STUDY_PLAN_UPDATE_BY_ADMIN,
} from '@/constants/permissions';
import {
  APPROVED,
  APPROVED_BY_MANAGER,
  APPROVED_BY_USER,
  REJECTED,
  REJECTED_BY_MANAGER,
  REJECTED_BY_USER,
  WAITING,
} from '@/constants/statuses';

const { Title, Link } = Typography;
const { Item: FormItem } = Form;

const ManageCourse = () => {
  const { t } = useTranslation('manageCourse');
  const {
    yearRequest,
    courseCost,
    courses,
    isLoading,
    coursesTotal,
    coursesPageSize,
    coursesPageNumber,
    isLoadingUsers,
    coursesRequestComment,
    coursesRequestCommentNumber,
    coursesRequestCommentPageSize,
    coursesRequestCommentTotal,
    eventList,
    courseManagerCost,
    courseManagerAdminCost,
  } = useSelector(selectCourses);
  const { currentUser } = useSelector(selectUsers);
  const { departmentCost, departmentsTotalCost } = useSelector(selectDepartments);
  const { typeLabelApproved, isLoading: labelsLoading } = useSelector(selectTypeLabel);
  const { fullname, fullnameManager, department, functionStudent } = useSelector(selectStudents);

  const [modal, modalHolder] = Modal.useModal();

  const [transferParams, setTransferParams] = useState(null);

  // Стейты фильтров
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState({
    value: undefined,
    type: undefined,
  });

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [event, setEvent] = useState({});

  const [activeSort, setActiveSort] = useState('id,desc');

  const isAdmin = Utils.isAdminRole(currentUser);
  const isAdminPlanMan = currentUser.authorities.includes(STUDY_PLAN_UPDATE_BY_ADMIN);
  const dispatch = useDispatch();

  const subCompanies = Utils.isGPMValid(currentUser);

  // Фильтр
  const currentSearchParams = useMemo(() => {
    const initialValues = {};

    for (const [key, value] of searchParams.entries()) {
      initialValues[key] = value;
    }

    return initialValues;
  }, [searchParams]);

  // Универсальный запрос для таблицы
  const getCourses = () => dispatch(getCoursesRequest(currentSearchParams?.page, currentSearchParams, subCompanies));

  const debounceSearchValue = useDebounce(search.value, 800);

  const yearsOptions = useMemo(
    () =>
      yearRequest?.map(year => ({
        key: year,
        label: year,
        value: year,
      })) ?? [],
    [yearRequest]
  );

  const typesOptions = useMemo(
    () =>
      typeLabelApproved?.map(type => ({
        key: `${type?.value}`,
        label: type?.name,
        value: `${type?.value}`,
      })),
    [typeLabelApproved]
  );

  const usersOptions = useMemo(
    () =>
      fullname?.map(student => ({
        key: `${student?.id}`,
        label: student?.name,
        value: `${student?.id}`,
      })) ?? [],
    [fullname]
  );

  const managersOptions = useMemo(() => {
    const defaultManagers =
      fullnameManager?.map(student => ({
        key: `${student?.id}`,
        label: student?.name,
        value: `${student?.id}`,
      })) ?? [];

    if (currentUser?.haveEmployees && !fullnameManager) {
      defaultManagers.push({
        key: `${currentUser?.id}`,
        label: `${currentUser.lastName} ${currentUser.firstName}`,
        value: `${currentUser?.id}`,
      });
    }

    return defaultManagers;
  }, [fullnameManager, currentUser]);

  const departmentsOptions = useMemo(
    () =>
      department?.map(department => ({
        key: department,
        label: department,
        value: department,
      })) ?? [],
    [department]
  );

  const functionsOptions = useMemo(
    () =>
      functionStudent?.map(item => ({
        key: item,
        label: item,
        value: item,
      })) ?? [],
    [functionStudent]
  );

  const changeTypeSearch = (value, type) => {
    setSearch({
      value,
      type,
    });
  };

  const onSelectAllStatuses = () => {
    const params = new URLSearchParams(searchParams);
    params.set(
      'status',
      statusCourse(t).map(status => status.value)
    );
    setSearchParams(params);
  };

  // Модалки
  const [modalsData, setModalsData] = useState({ ...DEFAULT_MODALS_DATA });

  // Функции для action button и модалок
  const getCoursesAndHideModals = () => {
    getCourses();
    setSelectedRows({ ...DEFAULT_SELECTED_ROWS });
    setModalsData({ ...DEFAULT_MODALS_DATA });
    setSelectedCourse(null);
  };

  const hideRejectedCourseRequest = comment => {
    if (comment && comment.length > 0) {
      modal.confirm({
        title: t('closeModalTitle'),
        content: t('closeModalDescription'),
        onOk: () => setModalsData({ ...DEFAULT_MODALS_DATA }),
      });
    } else {
      setModalsData({ ...DEFAULT_MODALS_DATA });
    }
  };

  const handleRejectedCourseComment = comment => {
    const status = isAdmin ? REJECTED : REJECTED_BY_MANAGER;

    if (selectedCourse?.id) {
      return submitCourseStatus(status, comment, [selectedCourse?.id], getCoursesAndHideModals);
    }

    if (status === REJECTED) {
      const ids = selectedRows?.data
        .filter(
          user =>
            user.status === WAITING ||
            user.status === APPROVED_BY_MANAGER ||
            user.status === APPROVED ||
            user.status === REJECTED_BY_USER
        )
        .map(user => user?.key);
      return submitCourseStatus(status, comment, ids, getCoursesAndHideModals);
    }

    if (status === REJECTED_BY_MANAGER) {
      const ids = selectedRows?.data
        .filter(user => user.status === WAITING || user.status === APPROVED_BY_MANAGER)
        .map(user => user?.key);
      return submitCourseStatus(status, comment, ids, getCoursesAndHideModals);
    }
  };

  const scheduleStudyPlanClick = comment => {
    const ids = selectedRows?.data
      .filter(
        user =>
          scheduledStatuses.some(status => user?.status === status) &&
          !isConference(user?.action?.plan?.topic?.type?.id)
      )
      .map(item => `${item?.action.plan?.id || item.key}`);

    dispatch(
      updateStudyPlanDate(
        ids,
        transferParams?.selectedMonths[0],
        comment,
        () => {
          getCoursesAndHideModals();
          setTransferParams(null);
          setSelectedCourse(null);
        },
        transferParams?.withoutSchedule
      )
    );
  };

  const toggleModalReasonTransfer = (planningTopicId, selectedMonths, comment, withoutSchedule) => {
    setTransferParams(() => ({
      planningTopicId,
      selectedMonths,
      comment,
      withoutSchedule,
    }));

    if (!selectedCourse?.plan && !modalsData?.isUpdate) {
      return scheduleStudyPlanClick('');
    }

    setModalsData(prevState => ({
      ...prevState,
      reasonTransfer: true,
    }));
  };

  const coursesRequestFinish = () => {
    const ids = selectedRows?.data?.map(item => `${item?.action?.plan?.id}`);
    dispatch(
      courseRequestFinish(ids, false, () => {
        setSelectedCourse(null);
        getCoursesAndHideModals();
      })
    );
  };

  const coursesDelete = () => {
    const ids = selectedRows?.data.map(item => ({
      topicId: item?.action?.plan?.topic?.id,
      userId: item?.action?.plan?.user?.id,
    }));

    dispatch(
      deleteRequestCourses(ids, true, () => {
        setSelectedCourse(null);
        getCoursesAndHideModals();
      })
    );
  };

  const changeCoursesStatus = (event, course, autocomplete = false) => {
    setSelectedCourse(course);

    if (event && (event === REJECTED || event === REJECTED_BY_MANAGER)) {
      setModalsData(prevState => ({
        ...prevState,
        rejectedComment: true,
      }));
    } else if (!autocomplete) {
      submitCourseStatus(event, '', [course?.id], getCourses);
    }

    if (autocomplete) {
      dispatch(
        getEventsGrours({ topicId: course.plan.topic.id }, res => {
          setEvent(res);
          setModalsData(prevState => ({
            ...prevState,
            selectEventModal: Object.keys(res).length !== 0,
            createEventModal: Object.keys(res).length === 0,
          }));
        })
      );
    }
  };

  const handleSelectEvent = async id => {
    await submitCourseStatus(APPROVED, '', [selectedCourse?.id]);
    dispatch(addUserEvent(id, [selectedCourse.id]));
    getCoursesAndHideModals();
  };

  const handleCreateEvent = async (data, callback) => {
    await submitCourseStatus(APPROVED, '', [selectedCourse?.id]);

    const callbackFunc = res => {
      if (selectedCourse.id) {
        dispatch(addUserEvent(res.id, [selectedCourse.id]));
      }
      callback();
      getCoursesAndHideModals();
    };
    dispatch(createEvent(data, callbackFunc));
  };

  // Бывшее название функции submitRejectedCourseRequest
  const submitCourseStatus = (status, comment, ids, callback) => {
    dispatch(
      updateCoursesRequest(status, comment, ids, () => {
        if (callback) callback();

        setModalsData({ ...DEFAULT_MODALS_DATA });
      })
    );
  };

  const finishCourses = course => {
    modal.confirm({
      title: t('finishModalTitle'),
      content: t('finishModalDescription'),
      onOk: () => dispatch(courseRequestFinish(course?.plan?.id, false, () => getCoursesAndHideModals())),
    });
  };

  const deleteCourses = () => {
    setModalsData(prevState => ({
      ...prevState,
      deleteCourseModal: true,
    }));
  };

  const toggleModalCalendars = course => {
    if (course) {
      setSelectedCourse(course);
      dispatch(
        getEventTopic(course.plan.topic.id, { isEnded: false }, () => {
          setModalsData(prevProps => ({
            ...prevProps,
            dateModal: true,
            isUpdate: false,
          }));
        })
      );
    } else {
      setSelectedCourse({});
      setModalsData(prevProps => ({
        ...prevProps,
        dateModal: true,
        isUpdate: true,
      }));
    }
  };

  const showOrganized =
    currentUser?.authorities?.includes(FEE_BASED_COURSE_EVENT_CREATE) &&
    currentUser?.authorities?.includes(FEE_BASED_COURSE_EVENT_UPDATE);

  const toggleModalTest = (userInfo, courses) => {
    setSelectedCourse(courses);
    setModalsData(prevProps => ({
      ...prevProps,
      visibleTestInfoModal: true,
    }));
  };

  const isDisabledAction = course =>
    DISABLE_COURSE_STATUSES.includes(course.plan.status) ||
    course.plan?.user.status === DELETED ||
    (!isAdmin && NOT_ADMIN_DISABLE_COURSE_STATUSES.includes(course.plan.status)) ||
    (!isAdmin && course.plan.topic.status !== 'ACTIVE');

  const handleOpenComment = course => {
    setSelectedCourse(course);
    setModalsData(prevState => ({
      ...prevState,
      commentModal: true,
    }));
  };

  const handleCloseComment = () => {
    setModalsData(prevState => ({
      ...prevState,
      commentModal: false,
    }));
  };

  // Всё для таблицы
  const columns = [
    {
      title: t('labelTabs.0'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left',
    },
    {
      title: t('labelTabs.1'),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      fixed: 'left',
      render: status => {
        const { label, color } = getStatusTag(status);

        return (
          <Tag color={color} className={css['ManageCourse-tag']}>
            {t(label)}
          </Tag>
        );
      },
    },
    {
      title: t('labelTabs.2'),
      dataIndex: 'manager',
      key: 'manager',
      width: 200,
      render: manager => `${manager?.fullName ?? ''}`,
    },
    {
      title: t('labelTabs.3'),
      dataIndex: 'dates',
      key: 'dates',
      width: 120,
    },
    {
      title: t('labelTabs.4'),
      dataIndex: 'materialName',
      key: 'materialName',
      width: 150,
      render: material => (
        <Tooltip title={material.name.length > MAX_TOPIC_NAME && material.name}>
          <Link href={`/topic/${material?.id}`}>{Utils.trString(material.name, MAX_TOPIC_NAME)}</Link>
        </Tooltip>
      ),
    },
    {
      title: t('labelTabs.5'),
      dataIndex: 'materialType',
      key: 'materialType',
      width: 150,
    },
    {
      title: t('labelTabs.6'),
      dataIndex: 'cost',
      key: 'cost',
      width: 150,
      render: cost => (
        <>
          {cost ?? 0} <CurrencySymbol currentUser={currentUser} />
        </>
      ),
    },
    {
      title: t('labelTabs.7'),
      dataIndex: 'duration',
      key: 'duration',
      width: 150,
    },
    {
      title: t('labelTabs.8'),
      dataIndex: 'function',
      key: 'function',
      width: 150,
    },
    {
      title: t('labelTabs.9'),
      dataIndex: 'department',
      key: 'department',
      width: 150,
    },
    {
      title: t('labelTabs.10'),
      dataIndex: 'approval',
      key: 'approval',
      align: 'center',
      width: 150,
      render: status => {
        switch (status) {
          case WAITING:
            return <QuestionOutlined className={css['ManageCourse-aproval-yellow']} />;

          case REJECTED_BY_MANAGER:
          case REJECTED:
            return <CloseOutlined className={css['ManageCourse-aproval-red']} />;

          default:
            return <CheckOutlined className={css['ManageCourse-aproval-green']} />;
        }
      },
    },
    {
      title: t('labelTabs.11'),
      dataIndex: 'approvedYear',
      key: 'approvedYear',
      width: 110,
      render: price => (
        <>
          {price ?? 0} <CurrencySymbol currentUser={currentUser} />
        </>
      ),
    },
    {
      title: t('labelTabs.12'),
      dataIndex: 'budget',
      key: 'budget',
      width: 150,
      render: price => (
        <>
          {price ?? 0} <CurrencySymbol currentUser={currentUser} />
        </>
      ),
    },
    {
      title: <CommentOutlined />,
      dataIndex: 'comment',
      key: 'comment',
      align: 'center',
      width: 50,
      render: course => (
        <Button type='link' size='small' onClick={() => handleOpenComment(course)}>
          <CommentOutlined
            className={classNames(css['ManageCourse-commentBtn'], {
              [css['ManageCourse-commentBtn-active']]: course?.plan?.commentCount > 0,
            })}
          />
        </Button>
      ),
    },
    {
      title: <ArrowRightOutlined />,
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 50,
      fixed: 'right',
      render: course => {
        const menu = (
          <ActionButton
            status={course.plan.status}
            currentUser={currentUser}
            withTransfer={currentUser.authorities.includes(STUDY_PLAN_UPDATE_BY_ADMIN)}
            changeCoursesStatus={changeCoursesStatus}
            finishCourses={finishCourses}
            deleteCourses={deleteCourses}
            courses={course}
            toggleModalCalendars={toggleModalCalendars}
            showOrganized={showOrganized}
            toggleModalTest={toggleModalTest}
            onClose={() => setSelectedCourse(null)}
          />
        );

        return (
          <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
            <Button
              type='link'
              className={classNames(css['ManageCourse-actionBtn'], {
                [css['ManageCourse-actionBtn-disabled']]: isDisabledAction(course),
              })}
              disabled={isDisabledAction(course)}
              size='small'
            >
              <ArrowRightOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const convertDurationTime = mins => {
    const hours = Math.trunc(mins / 60);
    const minutes = mins % 60;
    if (hours === 0 && hours === 0) {
      return `${minutes} ${t('time.min')}`;
    }
    if (minutes > 0 && hours !== 0) {
      return `${hours} ${noun(hours, t('time.hours.0'), t('time.hours.1'), t('time.hours.2'))} ${minutes} мин.`;
    }
    return `${hours} ${noun(hours, t('time.hours.0'), t('time.hours.1'), t('time.hours.2'))}`;
  };

  const dataSource = useMemo(
    () =>
      Array.isArray(courses) && courses.length > 0
        ? courses?.map(course => {
            return {
              key: course.id,
              name: `${course.plan?.user?.firstName} ${course.plan?.user?.lastName}`,
              status: course.plan?.status,
              manager: course?.plan?.user?.managers?.[0] ?? {},
              dates: dayjs(course.plan.startDate).format('DD.MM.YYYY'),
              materialName: course.plan.topic,
              materialType: course.plan.topic.typeLabel.name,
              cost: isConfOrAllCourse(course?.plan?.topic?.type?.id)
                ? course?.costCourseEvent
                : course?.plan?.topic?.cost,
              duration: convertDurationTime(course?.plan?.topic?.duration),
              function: course.plan.user.function,
              department: course.plan.user.department,
              approval: course.plan.status,
              approvedYear: course.cost,
              budget: course.approved,
              comment: course,
              action: course,
            };
          })
        : [],
    [courses]
  );

  const onChangePage = page => {
    const params = new URLSearchParams(searchParams);
    params.set('page', (page - 1).toString());
    setSearchParams(params);
  };

  const pageNumber = parseInt(searchParams.get('page') ?? coursesPageNumber);

  const pagination = {
    current: pageNumber + 1,
    pageSize: coursesPageSize,
    total: coursesTotal,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
    onChange: onChangePage,
  };

  // Экспорт таблицы
  const exportCourses = () => {
    const fileName = `manageCourse-${dayjs().format()}.csv`;
    dispatch(
      getCoursesRequestExport(fileName, currentSearchParams, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  // Бюджет
  const onChangeCostYear = year => {
    if (isAdmin) {
      dispatch(getCoursesCostRequest(year));
    }

    if (currentUser?.departmentInternalDto) {
      dispatch(getDepartmentsLimitCost(currentUser?.departmentInternalDto?.id));
      dispatch(getTotalDepartmentsLimitCost());
    }

    if (currentUser.haveEmployees) {
      if (!isAdmin) {
        dispatch(getCoursesCostManagerRequest(year, currentUser.id));
      }
      dispatch(getCoursesCostManagerRequest(year, 'admin'));
    }
  };

  const onChangeSort = sort => {
    setActiveSort(sort);
    const params = new URLSearchParams(searchParams);
    params.set('sort', sort);

    setSearchParams(params);
  };

  const [selectedRows, setSelectedRows] = useState({ ...DEFAULT_SELECTED_ROWS });

  const onSelectChange = (keys, data) => {
    const statuses = data?.map(item => item?.status ?? '');

    setSelectedRows({
      keys,
      statuses,
      data,
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRows?.keys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: isDisabledAction(record?.action),
    }),
  };

  const changeCoursesStatusMass = stat => {
    const status = stat.target ? stat.target.value : stat;

    if (status === REJECTED || status === REJECTED_BY_MANAGER) {
      setModalsData(prevState => ({
        ...prevState,
        rejectedComment: true,
      }));
      return;
    }

    const getIdsForStatuses = statuses =>
      selectedRows?.data.filter(user => statuses.includes(user.status)).map(user => user?.key);

    const ids = massStatusConditions[status] ? getIdsForStatuses(massStatusConditions[status]) : [];

    // Если ids не пустой массив, вызываем submitCourseStatus
    if (ids.length > 0) {
      submitCourseStatus(status, '', ids, getCoursesAndHideModals);
    }
  };

  const massActionItems = useMemo(() => {
    const actions = [];

    // Перенести
    if (isAdminPlanMan && selectedRows.statuses.some(statuses => actionStatuses.reschedule.includes(statuses))) {
      actions.push({
        key: 'reschedule',
        label: t('reschedule'),
        onClick: () => toggleModalCalendars(false),
      });
    }

    // Соглосовать
    if (
      selectedRows.statuses.some(statuses => actionStatuses.approve.includes(statuses)) ||
      (isAdminPlanMan && selectedRows.statuses.some(statuses => actionStatuses.approveAdmin.includes(statuses)))
    ) {
      actions.push({
        key: 'approve',
        label: t('approve'),
        onClick: () => changeCoursesStatusMass(isAdminPlanMan ? APPROVED : APPROVED_BY_MANAGER),
      });
    }

    // Отклонить
    if (
      selectedRows.statuses.some(statuses => actionStatuses.deny.includes(statuses)) ||
      (isAdminPlanMan && selectedRows.statuses.some(statuses => actionStatuses.denyAdmin.includes(statuses)))
    ) {
      actions.push({
        key: 'deny',
        label: t('deny'),
        onClick: () => changeCoursesStatusMass(isAdminPlanMan ? REJECTED : REJECTED_BY_MANAGER),
      });
    }

    // Соглосовать регистрацию
    if (
      isAdminPlanMan &&
      selectedRows.statuses.some(statuses => actionStatuses.confirmRegistration.includes(statuses))
    ) {
      actions.push({
        key: 'confirmRegistration',
        label: t('confirmRegistration'),
        onClick: () => changeCoursesStatusMass(APPROVED_BY_USER),
      });
    }

    // Отколнить в мероприятии
    if (isAdminPlanMan && selectedRows.statuses.some(statuses => actionStatuses.didntGo.includes(statuses))) {
      actions.push({
        key: 'didntGo',
        label: t('didntGo'),
        onClick: () => setModalsData(prevState => ({ ...prevState, rejectUsersModal: true })),
      });
    }

    // Удалить
    if (isAdminPlanMan && selectedRows.statuses.some(statuses => actionStatuses.delete.includes(statuses))) {
      actions.push({
        key: 'delete',
        label: t('delete'),
        onClick: () => setModalsData(prevState => ({ ...prevState, deleteCourseModal: true })),
      });
    }

    return actions;
  }, [selectedRows]);

  // Первоначальные запросы
  useLayoutEffect(() => {
    dispatch(getYear(() => {}, { events: true, requests: true }));
    onChangeCostYear(dayjs().format('YYYY'));
    dispatch(getTypeLabels(true));
    dispatch(getFunction());
    dispatch(getDepartment());
  }, []);

  // Дефолтные статусы и типы, сортировки
  useEffect(() => {
    if (searchParams.size === 0 && typeLabelApproved.length > 0 && currentUser) {
      const params = new URLSearchParams(searchParams);
      const defaultTypes = typeLabelApproved?.map(type => type?.value);

      params.set('status', defaultStatuses(isAdmin));
      params.set('topicTypeLabelIds', defaultTypes);
      params.set('sort', activeSort);

      if (currentUser?.haveEmployees) {
        params.set('managerId', currentUser?.id);
      }

      setSearchParams(params);
    }
  }, [typeLabelApproved, currentUser]);

  useEffect(() => {
    if (searchParams?.size !== 0) {
      getCourses();
    }
  }, [searchParams]);

  // Поиск в фильтрах
  useEffect(() => {
    if (debounceSearchValue) {
      switch (search.type) {
        case 'managerId':
        case 'userId':
          const params = {
            fullName: debounceSearchValue,
            isManager: search.type === 'managerId',
          };

          dispatch(getStudentsList(params));
          break;

        default:
          break;
      }
    }
  }, [debounceSearchValue]);

  return (
    <Content>
      {modalHolder}

      <Row>
        <Col span={24}>
          <h1>{t('approved')}</h1>
        </Col>
      </Row>

      {/* Бюджет */}
      <Row className={css['ManageCourse-container']}>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <Title className={css['ManageCourse-title']} level={3}>
                {t('budget')}
              </Title>
            </Col>
            <Col span={6}>
              <FormItem label={t('chooseYear')}>
                <Select defaultValue={dayjs().format('YYYY')} options={yearsOptions} onChange={onChangeCostYear} />
              </FormItem>
            </Col>
          </Row>
          <Row className={css['ManageCourse-stats']}>
            {isAdmin && (
              <>
                <Col span={8}>
                  <Statistic
                    title={t('labelStats.0')}
                    value={courseCost ?? 0}
                    groupSeparator=' '
                    valueStyle={{ color: 'var(--personalColor)' }}
                    suffix={<CurrencySymbol currentUser={currentUser} />}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title={t('labelStats.1')}
                    value={departmentsTotalCost ?? 0}
                    groupSeparator=' '
                    valueStyle={{ color: 'var(--personalColor)' }}
                    suffix={<CurrencySymbol currentUser={currentUser} />}
                  />
                </Col>
              </>
            )}
            {currentUser?.haveEmployees && (
              <Col span={8}>
                <Statistic
                  title={t('labelStats.2')}
                  value={courseManagerAdminCost ?? 0}
                  groupSeparator=' '
                  valueStyle={{ color: 'var(--personalColor)' }}
                  suffix={<CurrencySymbol currentUser={currentUser} />}
                />
              </Col>
            )}
            {!isAdmin && currentUser?.haveEmployees && (
              <Col span={8}>
                <Statistic
                  title={t('labelStats.3')}
                  value={courseManagerCost ?? 0}
                  groupSeparator=' '
                  valueStyle={{ color: 'var(--personalColor)' }}
                  suffix={<CurrencySymbol currentUser={currentUser} />}
                />
              </Col>
            )}
            {currentUser?.department && (
              <Col span={8}>
                <Statistic
                  title={t('labelStats.4', { department: currentUser?.department })}
                  value={departmentCost ?? 0}
                  groupSeparator=' '
                  valueStyle={{ color: 'var(--personalColor)' }}
                  suffix={<CurrencySymbol currentUser={currentUser} />}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {/* Фильтр */}
      <Row>
        <Col span={24}>
          <Spin spinning={labelsLoading}>
            <Filter
              filters={filters(
                t,
                onSelectAllStatuses,
                yearsOptions,
                typesOptions,
                usersOptions,
                managersOptions,
                departmentsOptions,
                functionsOptions,
                currentUser?.domainCompany,
                changeTypeSearch
              )}
            />
          </Spin>
        </Col>
      </Row>

      {/* Сортировка и экспорт */}
      <Row className={css['ManageCourse-sort']} align='middle'>
        <Col span={20}>
          <SortBar activeSort={activeSort} handleChangeSort={onChangeSort} />
        </Col>
        <Col span={4} className={css['ManageCourse-export']}>
          {currentUser.authorities.includes(FEE_BASED_COURSE_REQUEST_EXPORT) && (
            <Button type='primary' size='large' onClick={exportCourses} loading={isLoading}>
              {t('exportBtn')}
            </Button>
          )}
        </Col>
      </Row>

      {/* Таблица */}
      <Row className={css['ManageCourse-container']}>
        <Flex gap={20} align='center' className={css['ManageCourse-table-controls']}>
          <Row className={css['ManageCourse-table-info']}>
            <span>{t('selected')}</span>
            <span className={css['ManageCourse-table-info-selected']}>{selectedRows?.keys?.length ?? 0}</span>/
            <span>{coursesTotal ?? 0}</span>
          </Row>
          <Dropdown trigger={['click']} menu={{ items: massActionItems }}>
            <Button disabled={!massActionItems?.length} icon={<DownOutlined />} iconPosition='end'>
              {t('massAction')}
            </Button>
          </Dropdown>
        </Flex>
        <Col span={24}>
          <Table
            className={css['ManageCourse-table']}
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 2020 }}
            pagination={pagination}
            loading={isLoadingUsers}
            rowSelection={rowSelection}
            size='small'
          />
        </Col>
      </Row>

      {/* Все модальные окна */}
      <Modals
        openedModals={modalsData}
        commentSubmitRejectedCourseRequest={handleRejectedCourseComment}
        hideRejectedCourseRequest={hideRejectedCourseRequest}
        topic={selectedCourse?.plan?.topic ? { ...selectedCourse?.plan?.topic, futureEvents: eventList?.length } : {}}
        currentPlan={selectedCourse?.plan}
        scheduleStudyPlanClick={scheduleStudyPlanClick}
        isUpdate={false}
        toggleModalReasonTransfer={toggleModalReasonTransfer}
        coursesRequestFinish={coursesRequestFinish}
        coursesDelete={coursesDelete}
        hideConfirmRejectUser={getCoursesAndHideModals}
        hideConfirmDeleteCourse={getCoursesAndHideModals}
        confirmCloseCourse={getCoursesAndHideModals}
        confirmCourse={getCoursesAndHideModals}
        onCloseCalendarModal={getCoursesAndHideModals}
        onCloseSendCommentModal={getCoursesAndHideModals}
      />

      {modalsData?.selectEventModal && (
        <EventSelectModal
          events={event}
          onCancel={() => setModalsData(prevState => ({ ...prevState, selectEventModal: false }))}
          onCreateNew={() =>
            setModalsData(prevState => ({ ...prevState, createEventModal: true, selectEventModal: false }))
          }
          onSelect={handleSelectEvent}
          startDate={selectedCourse?.plan?.startDate}
        />
      )}

      {modalsData?.createEventModal && (
        <CreateEventModal
          open
          createEvent={handleCreateEvent}
          topic={selectedCourse?.plan?.topic}
          event={event}
          onCancel={() => setModalsData(prevState => ({ ...prevState, createEventModal: false }))}
          void
        />
      )}

      {modalsData?.visibleTestInfoModal && (
        <TestsInfoModal
          onCancel={() => setModalsData(prevState => ({ ...prevState, visibleTestInfoModal: false }))}
          user={selectedCourse.plan.user || selectedCourse.courses.plan}
          topicId={selectedCourse?.plan?.topic?.id}
          getBlockStatistic={(topicId, userId, onSuccess) => dispatch(getBlockStatistic(topicId, userId, onSuccess))}
          topicTypeId={selectedCourse?.plan?.topic?.type?.id}
          open={modalsData.visibleTestInfoModal}
        />
      )}

      <RejectCommentModal
        manageCourse
        key={selectedCourse?.id}
        open={modalsData?.commentModal}
        cancelRejectCommentModal={handleCloseComment}
        coursesId={selectedCourse?.id}
        courses={selectedCourse}
        handleGetCourseRequest={getCourses}
        getCommentRejected={(id, page) => dispatch(getCommentRejected(id, page))}
        postCommentRejected={(comment, onSuccess) => dispatch(postCommentRejected(comment, onSuccess))}
        coursesRequestComment={coursesRequestComment}
        coursesRequestCommentNumber={coursesRequestCommentNumber}
        coursesRequestCommentPageSize={coursesRequestCommentPageSize}
        coursesRequestCommentTotal={coursesRequestCommentTotal}
      />
    </Content>
  );
};

export default ManageCourse;
