/* eslint-disable */
import {
  ADD_COMPILATIONTOPLAN_FAIL,
  ADD_COMPILATIONTOPLAN_START,
  ADD_COMPILATIONTOPLAN_SUCCESS,
  ADD_MASS_COMPILATIONTOPLAN_FAIL,
  ADD_MASS_COMPILATIONTOPLAN_START,
  ADD_MASS_COMPILATIONTOPLAN_SUCCESS,
  CHANGE_VISIBILITY_COMPILATION_SUCCESS,
  CLEAR_COMPILATION,
  CLEAR_PAGE,
  DELETE_COMPILATION_FAIL,
  DELETE_COMPILATION_START,
  DELETE_COMPILATION_SUCCESS,
  DELETE_THEME_FAIL,
  DELETE_THEME_START,
  DELETE_THEME_SUCCESS,
  GET_COMPILATION_FAIL,
  GET_COMPILATION_START,
  GET_COMPILATION_SUCCESS,
  GET_COMPILATIONS_COMMENTS_FAIL,
  GET_COMPILATIONS_COMMENTS_START,
  GET_COMPILATIONS_COMMENTS_SUCCESS,
  GET_COMPILATIONS_FAIL,
  GET_COMPILATIONS_START,
  GET_COMPILATIONS_SUCCESS,
  GET_THEMES_FAIL,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
  POST_COMPILATION_FAIL,
  POST_COMPILATION_START,
  POST_COMPILATION_SUCCESS,
  POST_THEME_FAIL,
  POST_THEME_START,
  POST_THEME_SUCCESS,
  REORDER_THEMES,
  REORDER_THEMES_REQUEST_FAIL,
  REORDER_THEMES_REQUEST_START,
  REORDER_THEMES_REQUEST_SUCCESS,
  UPDATE_COMPILATION_FAIL,
  UPDATE_COMPILATION_START,
  UPDATE_COMPILATION_SUCCESS,
  UPDATE_THEME_FAIL,
  UPDATE_THEME_START,
  UPDATE_THEME_SUCCESS,
} from './constants';

const initialState = {
  /* compilations: {
    elements: [],
    hasMore: false,
    page: 0
  }, */
  compilations: [],
  compilationsTotal: 0,
  compilationsPageNumber: 0,
  compilationsPageSize: 0,
  compilationComments: [],
  themes: [],
  theme: {},
  success: null,
  compilation: {
    themes: [],
    creBy: {},
  },
  isLoadingMassCompilation: false,
  isLoading: false,
};

const changeCompilation = (arr, value) => {
  return arr.map(item => {
    if (item.id === value.id) {
      return value;
    }
    return item;
  });
};

export default (state = initialState, { type, response, info, headers }) => {
  switch (type) {
    case CLEAR_COMPILATION:
      return {
        ...state,
        compilation: {
          themes: [],
          creBy: {},
        },
        isLoading: false,
        error: false,
      };

    case CLEAR_PAGE:
      return { ...state, compilationsPageNumber: initialState.compilationsPageNumber };

    case CHANGE_VISIBILITY_COMPILATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        compilations: changeCompilation(state.compilations, response),
      };

    case GET_COMPILATIONS_COMMENTS_START:
    case DELETE_COMPILATION_START:
    case UPDATE_COMPILATION_START:
    case GET_COMPILATION_START:
    case POST_COMPILATION_START:
    case REORDER_THEMES_REQUEST_START:
    case GET_COMPILATIONS_START:
    case DELETE_THEME_START:
    case GET_THEMES_START:
    case UPDATE_THEME_START:
      return { ...state, isLoading: true, error: false };

    case GET_COMPILATIONS_COMMENTS_SUCCESS:
      return { ...state, compilationComments: response, isLoading: false };

    case DELETE_COMPILATION_SUCCESS:
    case UPDATE_COMPILATION_SUCCESS:
    case GET_COMPILATION_SUCCESS:
    case POST_COMPILATION_SUCCESS:
      return { ...state, compilation: response, isLoading: false };

    case GET_COMPILATIONS_COMMENTS_FAIL:
    case DELETE_COMPILATION_FAIL:
    case UPDATE_COMPILATION_FAIL:
    case GET_COMPILATION_FAIL:
    case POST_COMPILATION_FAIL:
    case REORDER_THEMES_REQUEST_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_COMPILATIONS_SUCCESS:
      return {
        ...state,
        /* compilations: {
        elements: Number(headers.get('x-page-number')) === 0 ? response : state.compilations.elements.concat(response),
        total: Number(headers.get('x-total-elements')),
        hasMore: (Number(headers.get('x-total-elements')) > state.compilations.elements.length) && response.length !== 0,
        page: Number(headers.get('x-page-number')) || 0
      }, */
        compilations: response,
        compilationsTotal: Number(headers.get('X-Total-Elements')) || 0,
        compilationsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        compilationsPageSize: Number(headers.get('X-Page-Size')) || 0,
        isLoading: false,
      };

    case GET_COMPILATIONS_FAIL:
      return { ...state, error: true, isLoading: false };

    case DELETE_THEME_SUCCESS:
    case REORDER_THEMES_REQUEST_SUCCESS:
      return { ...state, error: false, isLoading: false };

    case DELETE_THEME_FAIL:
      return { ...state, error: true, isLoading: false };

    case REORDER_THEMES:
      return { ...state, themes: info };

    case GET_THEMES_SUCCESS:
      return { ...state, themes: response, isLoading: false };

    case GET_THEMES_FAIL:
      return { ...state, error: true, isLoading: false };

    case UPDATE_THEME_SUCCESS:
      return {
        ...state,
        theme: response,
        themes: state.themes.map(theme => (theme.id === response.id ? response : theme)),
        isLoading: false,
      };

    case UPDATE_THEME_FAIL:
      return { ...state, error: true, isLoading: false };

    case POST_THEME_START:
      return { ...state, isLoading: true, error: false };

    case POST_THEME_SUCCESS:
      return { ...state, theme: response, isLoading: false };

    case POST_THEME_FAIL:
      return { ...state, error: true, isLoading: false };

    case ADD_COMPILATIONTOPLAN_START:
      return { ...state, isLoading: true, error: false };
    case ADD_COMPILATIONTOPLAN_SUCCESS:
      return { ...state, compilations: response, isLoading: false };
    case ADD_COMPILATIONTOPLAN_FAIL:
      return { ...state, error: true, isLoading: false };

    case ADD_MASS_COMPILATIONTOPLAN_START:
      return { ...state, isLoadingMassCompilation: true, error: false };

    case ADD_MASS_COMPILATIONTOPLAN_SUCCESS:
      return { ...state, compilations: response, isLoadingMassCompilation: false };

    case ADD_MASS_COMPILATIONTOPLAN_FAIL:
      return { ...state, error: true, isLoadingMassCompilation: false };

    default:
      return state;
  }
};
