/* eslint-disable */
import {
  ADD_CUSTOM_QUESTION_FAIL,
  ADD_CUSTOM_QUESTION_START,
  ADD_CUSTOM_QUESTION_SUCCESS,
  ADD_LOADING_FILE,
  ADD_QUESTIONS_TO_TEST_FAIL,
  ADD_QUESTIONS_TO_TEST_START,
  ADD_QUESTIONS_TO_TEST_SUCCESS,
  CREATE_BLOCK_START,
  CREATE_BLOCK_SUCCESS,
  CREATE_PRESENTATION_SUCCESS,
  CREATE_SCORM_SUCCESS,
  CREATE_VIDEO_FAIL,
  CREATE_VIDEO_START,
  CREATE_VIDEO_SUCCESS,
  EDIT_BLOCK_CONTENT_SUCCESS,
  EDIT_BLOCK_SUCCESS,
  GET_BLOCK_STATISTIC_FAIL,
  GET_BLOCK_STATISTIC_START,
  GET_BLOCK_STATISTIC_SUCCESS,
  GET_BLOCK_STATISTICS_EXPORT_FAIL,
  GET_BLOCK_STATISTICS_EXPORT_START,
  GET_BLOCK_STATISTICS_EXPORT_SUCCESS,
  GET_BLOCK_TYPES_FAIL,
  GET_BLOCK_TYPES_START,
  GET_BLOCK_TYPES_SUCCESS,
  GET_BLOCKS_FAIL,
  GET_BLOCKS_START,
  GET_BLOCKS_SUCCESS,
  GET_BLOCKS_TOPIC_FAIL,
  GET_BLOCKS_TOPIC_START,
  GET_BLOCKS_TOPIC_SUCCESS,
  GET_RESULT_STATISTIC_FAIL,
  GET_RESULT_STATISTIC_START,
  GET_RESULT_STATISTIC_SUCCESS,
  GET_STATISTICS_STATUS_FAIL,
  GET_STATISTICS_STATUS_START,
  GET_STATISTICS_STATUS_SUCCESS,
  GET_STUDY_PLAN_STATUS_FAIL,
  GET_STUDY_PLAN_STATUS_START,
  GET_STUDY_PLAN_STATUS_SUCCESS,
  REMOVE_LOADING_FILE,
  UPDATE_CHECKLIST_FAIL,
  UPDATE_CHECKLIST_START,
  UPDATE_CHECKLIST_SUCCESS,
  UPDATE_FILE_FAIL,
  UPDATE_FILE_START,
  UPDATE_FILE_SUCCESS,
} from './actions';

import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';

const initialState = {
  blocks: [],
  isLoading: true,
  error: false,
  statistic: [],
  types: {
    blockTypes: [],
    isLoading: true,
    error: false,
  },
  statisticTotal: null,
  statisticPageSize: null,
  statisticPageNumber: null,
  statisticCurrentPage: null,
  statisticsStatus: [],
  studyPlanStatus: [],
  blocksTopic: [],
  loading: false,
  isExporting: false,
  isLoadingTable: false,
  isLoadingCustom: false,
  blockStat: {},
  userInfo: {},
  loadingFilesBlockId: [],
};

export default (state = initialState, { type, response, action, blockId }) => {
  switch (type) {
    case ADD_CUSTOM_QUESTION_START:
      return { ...state, isLoadingCustom: true };

    case GET_BLOCK_TYPES_START:
      return { ...state, types: { ...state.types, isLoading: true, error: false } };

    case GET_BLOCK_STATISTICS_EXPORT_START:
      return { ...state, isExporting: true, error: false };

    case GET_BLOCK_TYPES_SUCCESS:
      return { ...state, types: { blockTypes: response, isLoading: false, error: false } };

    case GET_BLOCK_TYPES_FAIL:
      return { ...state, types: { ...state.types, isLoading: false, error: true } };

    case GET_BLOCKS_START:
      return { ...state, blocks: [], isLoading: true, error: false };

    case CREATE_VIDEO_START:
      return { ...state, loading: true, error: false };

    case GET_RESULT_STATISTIC_START:
      return { ...state, isLoadingTable: true, error: false };

    case GET_BLOCKS_SUCCESS:
      return { ...state, blocks: response, isLoading: false, error: false };

    case GET_BLOCKS_TOPIC_START:
      return { ...state, loading: true, blocksTopic: [] };

    case GET_STATISTICS_STATUS_SUCCESS:
      return { ...state, isLoading: false, error: false, statisticsStatus: response };

    case GET_STUDY_PLAN_STATUS_SUCCESS:
      return { ...state, isLoading: false, error: false, studyPlanStatus: response };

    case CREATE_VIDEO_SUCCESS:
      return {
        ...state,
        blocks: state.blocks.map(block => (block.id === response.id ? response : block)),
        isLoading: false,
        error: false,
      };

    case GET_BLOCKS_TOPIC_SUCCESS:
      return { ...state, loading: false, blocksTopic: response };

    case GET_BLOCKS_FAIL:
      return { ...state, blocks: [], isLoading: false, error: true };

    case ADD_QUESTIONS_TO_TEST_START:
    case CREATE_BLOCK_START:
    case GET_STATISTICS_STATUS_START:
    case GET_STUDY_PLAN_STATUS_START:
    case GET_BLOCK_STATISTIC_START:
      return { ...state, isLoading: true, error: false };

    case CREATE_BLOCK_SUCCESS:
      return { ...state, blocks: [...state.blocks, response], isLoading: false, error: false };

    case EDIT_BLOCK_CONTENT_SUCCESS:
    case EDIT_BLOCK_SUCCESS:
    case CREATE_SCORM_SUCCESS:
    case CREATE_PRESENTATION_SUCCESS:
      return {
        ...state,
        blocks: _sortBy([...state.blocks.filter(data => data.id !== response.id), response], ['position']),
        isLoading: false,
        error: false,
      };

    case ADD_CUSTOM_QUESTION_SUCCESS:
      // Клонируем блоки
      const updatedBlocks = _cloneDeep(state.blocks);
      // Получаем индекс изменяемого блока
      const blockIndex = updatedBlocks.findIndex(block => block.id === action.blockId);
      // Получаем индекс изменяемого вопроса
      const questionIndex = updatedBlocks[blockIndex].test.questions.findIndex(question => question.id === response.id);

      // Заменяем нужный нам блок на ответ из бэка
      updatedBlocks[blockIndex].test.questions[questionIndex] = response;

      return {
        ...state,
        isLoadingCustom: false,
        blocks: updatedBlocks,
      };

    case ADD_QUESTIONS_TO_TEST_SUCCESS:
      return {
        ...state,
        blocks: state.blocks.map(block => {
          if (block.id === response.blockId) {
            return {
              ...block,
              test: { ...block.test, ...response },
            };
          } else {
            return block;
          }
        }),
        isLoading: false,
      };

    case GET_RESULT_STATISTIC_SUCCESS:
      return {
        ...state,
        statistic: response.content,
        statisticTotal: response.totalElements,
        statisticPageSize: response.size,
        statisticPageNumber: response.totalPages,
        statisticCurrentPage: response.number,
        isLoadingTable: false,
      };

    case GET_BLOCK_STATISTICS_EXPORT_SUCCESS:
      return { ...state, isExporting: false, error: false };

    case GET_BLOCK_STATISTIC_SUCCESS:
      return { ...state, isLoading: false, error: false, blockStat: response, userInfo: response.user };

    case GET_BLOCK_STATISTICS_EXPORT_FAIL:
      return { ...state, isExporting: false, error: true };

    case CREATE_VIDEO_FAIL:
    case GET_STATISTICS_STATUS_FAIL:
    case GET_STUDY_PLAN_STATUS_FAIL:
    case GET_BLOCK_STATISTIC_FAIL:
      return { ...state, loading: false, error: true };

    case UPDATE_FILE_FAIL:
    case UPDATE_CHECKLIST_FAIL:
    case ADD_QUESTIONS_TO_TEST_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_RESULT_STATISTIC_FAIL:
      return { ...state, isLoadingTable: false, error: true };

    case GET_BLOCKS_TOPIC_FAIL:
      return { ...state, loading: false, blocksTopic: [] };

    case ADD_CUSTOM_QUESTION_FAIL:
      return { ...state, isLoadingCustom: false };

    case UPDATE_FILE_START:
    case UPDATE_CHECKLIST_START:
      return { ...state, isLoading: true };

    case UPDATE_CHECKLIST_SUCCESS: {
      // заменяем нужный нам блок и новые данные, которые приходят с бека
      const updatedBlocks = state.blocks.map(block => {
        if (block.id === action.blockId) {
          return {
            ...block,
            checklist: response?.checklist,
            description: response?.description,
          };
        } else {
          return block;
        }
      });

      return {
        ...state,
        isLoading: false,
        blocks: updatedBlocks,
      };
    }

    case UPDATE_FILE_SUCCESS: {
      const updatedBlocks = state.blocks.map(block => {
        if (block.id === action.blockId) {
          return {
            ...block,
            pokaFiles: {
              description: response?.description,
              files: response?.files,
            },
          };
        } else {
          return block;
        }
      });

      return {
        ...state,
        isLoading: false,
        blocks: updatedBlocks,
      };
    }

    case ADD_LOADING_FILE:
      return {
        ...state,
        loadingFilesBlockId: [...state.loadingFilesBlockId, blockId],
      };

    case REMOVE_LOADING_FILE:
      const currentIndex = state.loadingFilesBlockId.findIndex(id => id === blockId);
      const newLoadings = state.loadingFilesBlockId.filter((_, index) => index !== currentIndex);

      return {
        ...state,
        loadingFilesBlockId: newLoadings,
      };

    default:
      return state;
  }
};
