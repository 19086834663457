import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCompetenceWithSkills, saveCompetenceFilters } from '@/store/competence/actions';
import { getGroups } from '@/store/groups/actions';

import { selectGroups } from '@/store/groups/selectors';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import _ from 'lodash';

import css from '../index.module.scss';

const Filters = () => {
  const { t } = useTranslation('CompetenceDirectory');
  const dispatch = useDispatch();

  const { groupsTable, groupsLoading } = useSelector(selectGroups);

  const [groupName, setGroupName] = useState([]);
  const [competenceName, setCompetenceName] = useState('');

  const searchGroup = value => {
    dispatch(getGroups(value, 0, 500));
  };

  const getGroupsDebounced = useCallback(_.debounce(searchGroup, 1000), []);

  const onSubmitFilters = () => {
    dispatch(saveCompetenceFilters({ name: competenceName, groupIds: groupName }));
    dispatch(getCompetenceWithSkills(0, { name: competenceName, groupIds: groupName }));
  };

  const onResetFilters = () => {
    setGroupName([]);
    setCompetenceName('');
    dispatch(saveCompetenceFilters({}));
    dispatch(getCompetenceWithSkills(0, { name: '', groupIds: undefined }));
  };

  const renderOptions = arr => {
    return (
      arr?.length &&
      arr.map(item => {
        return (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        );
      })
    );
  };

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-txt']}>{t('filters')}</div>
      <Form layout='vertical'>
        <Row type='flex' justify='start' gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={t('searchCompetence')} colon={false}>
              <Input
                onChange={e => setCompetenceName(e?.target?.value)}
                value={competenceName}
                placeholder={t('placeholderSearchCompetence')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('searchGroups')} colon={false}>
              <Select
                showSearch
                onSearch={getGroupsDebounced}
                onChange={e => setGroupName(e)}
                value={groupName}
                optionFilterProp='children'
                placeholder={t('placeholderSearchGroups')}
                loading={groupsLoading}
                mode='multiple'
                allowClear
              >
                {renderOptions(groupsTable)}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify='end' gutter={[16, 0]}>
          <Col>
            <Button onClick={onResetFilters}>{t('reset')}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmitFilters} type='primary'>
              {t('apply')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filters;
