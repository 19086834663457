/* eslint-disable */
import React from 'react';

import { Button, Modal, Spin } from 'antd';

import PropTypes from 'prop-types';

import css from './ConfirmationModal.module.scss';

function ConfirmationModal({
  confirmationText,
  onOk,
  okText,
  cancelText,
  onCancelButton,
  disabled,
  isLoadingDeleteMass,
  deleteBtn,
  applyAll,
  ...props
}) {
  return (
    <Modal {...props} footer={null}>
      {applyAll && isLoadingDeleteMass ? (
        <div className={css['spin']}>
          <Spin spinning={isLoadingDeleteMass} size='large' />
        </div>
      ) : (
        <>
          <p className={css['modal-title']}>{confirmationText}</p>
          <div className={css['footer-btn']}>
            <Button type='primary' danger={deleteBtn} onClick={onOk} disabled={disabled}>
              {okText}
            </Button>
            {cancelText && (
              <Button onClick={onCancelButton} disabled={disabled} style={{ marginLeft: '10px' }}>
                {cancelText}
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  cancelText: PropTypes.string,
  confirmationText: PropTypes.string,
  disabled: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onCancelButton: PropTypes.func,
  onOk: PropTypes.func,
  isLoadingDeleteMass: PropTypes.bool,
  applyAll: PropTypes.bool,
  deleteBtn: PropTypes.bool,
};

export default ConfirmationModal;
