/* eslint-disable */
import {
  D360_CREATE_SCORE_FAIL,
  D360_CREATE_SCORE_START,
  D360_CREATE_SCORE_SUCCESS,
  D360_DELETE_SCORE_FAIL,
  D360_DELETE_SCORE_START,
  D360_DELETE_SCORE_SUCCESS,
  D360_GET_SCORE_FAIL,
  D360_GET_SCORE_START,
  D360_GET_SCORE_SUCCESS,
  D360_GET_SCORES_FAIL,
  D360_GET_SCORES_START,
  D360_GET_SCORES_SUCCESS,
  D360_UPDATE_SCORE_FAIL,
  D360_UPDATE_SCORE_START,
  D360_UPDATE_SCORE_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  scores: [],
};

export default (state = initialState, { type, response, headers, info, action }) => {
  let scores = null;

  switch (type) {
    case D360_CREATE_SCORE_START:
    case D360_UPDATE_SCORE_START:
    case D360_DELETE_SCORE_START:
    case D360_GET_SCORE_START:
    case D360_GET_SCORES_START:
      return { ...state, loading: true };

    case D360_DELETE_SCORE_SUCCESS:
      return { ...state, loading: false, scores: state.scores.filter(score => score.id !== response.data.id) };

    case D360_CREATE_SCORE_SUCCESS:
      return { ...state, loading: false, scores: state.scores.concat(response.data) };

    case D360_UPDATE_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        scores: state.scores.map(score => {
          if (score.id === response.data.id) {
            score = response.data;
          }

          return score;
        }),
      };

    case D360_GET_SCORE_SUCCESS:
      return { ...state, loading: false };

    case D360_GET_SCORES_SUCCESS:
      return { ...state, loading: false, scores: response.data };

    case D360_CREATE_SCORE_FAIL:
    case D360_UPDATE_SCORE_FAIL:
    case D360_DELETE_SCORE_FAIL:
    case D360_GET_SCORE_FAIL:
    case D360_GET_SCORES_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
