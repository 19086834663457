import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Tabs } from 'antd';
import MassSelection from '@/components/MassSelection';

import UsersAssign from '../UsersAssign';
import { MASS, SINGLE } from './constants';

import PropTypes from 'prop-types';

const Assignment = props => {
  const { t } = useTranslation('assignment');
  const [footer, setFooter] = useState();
  const [tab, setTab] = useState();

  const UsersAssisgnment = (
    <UsersAssign
      title={props.title}
      onOk={props.onOk}
      textBlock={props.textBlock}
      rewardId={props.rewardId}
      setFooter={setFooter}
      onCancel={props.onCancel}
      topicId={props.topicId}
      trackId={props.trackId}
      isTab={tab}
    />
  );

  const MassAssisgnment = (
    <MassSelection
      setFooter={setFooter}
      getSavedFilters={props.getSavedFilters}
      getCountUsers={props.getCountUsers}
      onMassOk={props.onMassOk}
      exportSelectedUsers={props.exportSelectedUsers}
      isLoadingCountSelectedUsers={props.isLoadingCountSelectedUsers}
      isLoadingMass={props.isLoadingMass}
      titleModalMass={props.titleModalMass}
      countSelectedUsers={props.countSelectedUsers}
      titleSelectedUser={props.titleSelectedUser}
      isTopic={!!props.topicId} // Периодические
      hideInspectors={props.hideInspectors}
      isTab={tab}
    />
  );

  const itemsTabs = [
    { label: t('single'), key: SINGLE, children: UsersAssisgnment },
    { label: t('many'), key: MASS, children: MassAssisgnment },
  ];

  return (
    <Modal title={null} footer={footer} open={props.open} width={800} onCancel={props.onCancel}>
      {props.mass ? <Tabs items={itemsTabs} onChange={e => setTab(e)} /> : UsersAssisgnment}
    </Modal>
  );
};

Assignment.propTypes = {
  hasInspectors: PropTypes.bool, // Проверящие блок
  title: PropTypes.string, // Заголовок ручного/массового назначения
  mass: PropTypes.bool, // Массовое назначение
  onCancel: PropTypes.func, // Закрыть мод окно
  onOk: PropTypes.func, // Назначить
  textBlock: PropTypes.array, // Текст для блокировки
  rewardId: PropTypes.number, // ИД награды
  topicId: PropTypes.number, // ИД топика
  trackId: PropTypes.number, // ИД трэка
  getSavedFilters: PropTypes.func, // MASS получение сохраненных фильтров
  getCountUsers: PropTypes.func, // MASS Получение кол-во пользователей, кроме подборок
  onMassOk: PropTypes.func, // MASS назначение массового назначения
  exportSelectedUsers: PropTypes.func, // MASS выгрузка пользователей
  isLoadingCountSelectedUsers: PropTypes.any, // MASS загрузка кол-во пользователей
  isLoadingMass: PropTypes.any, // MASS лоадер назначения планов
  titleModalMass: PropTypes.string, // MASS заголовок
  countSelectedUsers: PropTypes.number, // MASS кол-во пользователей кому будет назначено
  titleSelectedUser: PropTypes.string, // MASS текст для пользователей кому будет назначено
};

export default Assignment;
