/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Content } from '@/components/Layout';

import Projects from './components/Projects/Projects';

import './css/logo360.css';

import css from './css/index.module.scss';

// import {
//   D360_MODE_EXPERIMENTAL,
//   D360_MODE_ALPHA,
//   D360_MODE_BETA,
//   D360_MODE_RELEASE,
//   D360_MODE_DEFAULT,
//   D360_MODE,
// } from './constants';

export const D360 = () => {
  const [versionName, setVersionName] = useState(null);
  const [versionClass, setVersionClass] = useState(null);
  const { t } = useTranslation('D360_Version');

  // useEffect(() => {
  //   switch (D360_MODE) {
  //     case D360_MODE_EXPERIMENTAL:
  //       setVersionName(D360_MODE_EXPERIMENTAL);
  //       setVersionClass(css.VersionExperimental);
  //       break;
  //
  //     case D360_MODE_ALPHA:
  //       setVersionName(D360_MODE_ALPHA);
  //       setVersionClass(css.VersionAlpha);
  //       break;
  //
  //     case D360_MODE_BETA:
  //       setVersionName(D360_MODE_BETA);
  //       setVersionClass(css.VersionBeta);
  //       break;
  //
  //     case D360_MODE_RELEASE:
  //       setVersionName(D360_MODE_RELEASE);
  //       setVersionClass(css.VersionRelease);
  //       break;
  //
  //     default:
  //       setVersionName(D360_MODE_DEFAULT);
  //       setVersionClass(css.VersionExperimental);
  //       break;
  //   }
  // }, []);

  const render = () => {
    return (
      <Content>
        <h1 className={css.VersionTitle}>
          {t('moduleName')} <span className='Logo360'>360°</span>
          <span className={versionClass}>{versionName}</span>
        </h1>
        <Projects />
      </Content>
    );
  };

  return render();
};

export default D360;
