/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createSkills, deleteSkills, editSkills, getSkills, getSkillsGlobal } from '@/store/skills/actions';

import { selectSkills } from '@/store/skills/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import SkillsTable from './SkillsTable';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

export class SkillsDirectory extends Component {
  static propTypes = {
    createSkills: PropTypes.func,
    creationError: PropTypes.bool,
    deleteSkills: PropTypes.func,
    editSkills: PropTypes.func,
    editingError: PropTypes.bool,
    getSkills: PropTypes.func,
    getSkillsGlobal: PropTypes.func,
    isLoading: PropTypes.bool,
    skillsGlobal: PropTypes.object,
    skills: PropTypes.array,
    skillsPageNumber: PropTypes.number,
    skillsPageSize: PropTypes.number,
    skillsTotal: PropTypes.number,
    isGlobal: PropTypes.bool,
  };

  render() {
    return (
      <>
        <SkillsTable {...this.props} />
        {!this.props.isGlobal && (
          <SkillsTable
            {...this.props}
            {...this.props.skillsGlobal}
            skills={this.props.skillsGlobal.skills || []}
            getSkills={this.props.getSkillsGlobal}
            shared={true}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createSelector([selectSkills, usersSelect], (skills, users) => ({
  skills: skills.skills || [],
  skillsGlobal: skills.globalSkills,
  skillsTotal: skills.skillsTotal,
  skillsPageSize: skills.skillsPageSize,
  skillsPageNumber: skills.skillsPageNumber || 0,
  creationError: skills.creationError || false,
  editingError: skills.editingError || false,
  isLoading: skills.isLoading,
  isGlobal: users && users.currentUser.domainCompany.global,
  currentUser: users?.currentUser,
}));

const mapActionsToProps = {
  getSkills,
  getSkillsGlobal,
  createSkills,
  editSkills,
  deleteSkills,
};

export default connect(mapStateToProps, mapActionsToProps)(SkillsDirectory);
