/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_COURSES_REQUEST_START = 'GET_COURSES_REQUEST_START';
export const GET_COURSES_REQUEST_SUCCESS = 'GET_COURSES_REQUEST_SUCCESS';
export const GET_COURSES_REQUEST_FAIL = 'GET_COURSES_REQUEST_FAIL';
export const GET_COURSES_REQUEST_TOPIC_START = 'GET_COURSES_REQUEST_TOPIC_START';
export const GET_COURSES_REQUEST_TOPIC_SUCCESS = 'GET_COURSES_REQUEST_TOPIC_SUCCESS';
export const GET_COURSES_REQUEST_TOPIC_FAIL = 'GET_COURSES_REQUEST_TOPIC_FAIL';
export const UPDATE_COURSES_REQUEST_START = 'UPDATE_COURSES_REQUEST_START';
export const UPDATE_COURSES_REQUEST_SUCCESS = 'UPDATE_COURSES_REQUEST_SUCCESS';
export const UPDATE_COURSES_REQUEST_FAIL = 'UPDATE_COURSES_REQUEST_FAIL';
export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';
export const GET_EVENT_TOPIC_START = 'GET_EVENT_TOPIC_START';
export const GET_EVENT_TOPIC_SUCCESS = 'GET_EVENT_TOPIC_SUCCESS';
export const GET_EVENT_TOPIC_FAIL = 'GET_EVENT_TOPIC_';
export const ADD_USER_EVENT_START = 'ADD_USER_EVENT_START';
export const ADD_USER_EVENT_SUCCESS = 'ADD_USER_EVENT_SUCCESS';
export const ADD_USER_EVENT_FAIL = 'ADD_USER_EVENT_FAIL';
export const DELETE_USER_EVENT_START = 'DELETE_USER_EVENT_START';
export const DELETE_USER_EVENT_SUCCESS = 'DELETE_USER_EVENT_SUCCESS';
export const DELETE_USER_EVENT_FAIL = 'DELETE_USER_EVENT_FAIL';
export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';
export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';
export const ACTIVE_STATUS_START = 'ACTIVE_STATUS_START';
export const ACTIVE_STATUS_SUCCESS = 'ACTIVE_STATUS_SUCCESS';
export const ACTIVE_STATUS_FAIL = 'ACTIVE_STATUS_FAIL';
export const GET_COURSES_REQUEST_EXPORT_START = 'GET_COURSES_REQUEST_EXPORT_START';
export const GET_COURSES_REQUEST_EXPORT_SUCCESS = 'GET_COURSES_REQUEST_EXPORT_SUCCESS';
export const GET_COURSES_REQUEST_EXPORT_FAIL = 'GET_COURSES_REQUEST_EXPORT_FAIL';
export const CHANGE_COURSES_FILTER = 'CHANGE_COURSES_FILTER';
export const CLEAR_COURSES_FILTER = 'CLEAR_COURSES_FILTER';
export const CLEAR_COURSES_REQUEST = 'CLEAR_COURSES_REQUEST';
export const GET_YEAR_START = 'GET_YEAR_START';
export const GET_YEAR_SUCCESS = 'GET_YEAR_SUCCESS';
export const GET_YEAR_FAIL = 'GET_YEAR_FAIL';
export const SEND_MAIL_START = 'SEND_MAIL_START';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAIL = 'SEND_MAIL_FAIL';
export const EVENT_VALIDATION_FAIL = 'EVENT_VALIDATION_FAIL';
export const GET_EVENT_USERS_START = 'GET_EVENT_USERS_START';
export const GET_EVENT_USERS_SUCCESS = 'GET_EVENT_USERS_SUCCESS';
export const GET_EVENT_USERS_FAIL = 'GET_EVENT_USERS_FAIL';

export const GET_EVENT_PARTICIPANTS_START = 'GET_EVENT_PARTICIPANTS_START';
export const GET_EVENT_PARTICIPANTS_SUCCESS = 'GET_EVENT_PARTICIPANTS_SUCCESS';
export const GET_EVENT_PARTICIPANTS_FAIL = 'GET_EVENT_PARTICIPANTS_FAIL';

export const GET_COURSES_REQUEST_COMMENT_START = 'GET_COURSES_REQUEST_COMMENT_START';
export const GET_COURSES_REQUEST_COMMENT_SUCCESS = 'GET_COURSES_REQUEST_COMMENT_SUCCESS';
export const GET_COURSES_REQUEST_COMMENT_FAIL = 'GET_COURSES_REQUEST_COMMENT_FAIL';
export const POST_COURSES_REQUEST_COMMENT_START = 'POST_COURSES_REQUEST_COMMENT_START';
export const POST_COURSES_REQUEST_COMMENT_SUCCESS = 'POST_COURSES_REQUEST_COMMENT_SUCCESS';
export const POST_COURSES_REQUEST_COMMENT_FAIL = 'POST_COURSES_REQUEST_COMMENT_FAIL';

export const SET_REGISTRATION_STARTDATE_START = 'SET_REGISTRATION_STARTDATE_START';
export const SET_REGISTRATION_STARTDATE_SUCCESS = 'SET_REGISTRATION_STARTDATE_SUCCESS';
export const SET_REGISTRATION_STARTDATE_FAIL = 'SET_REGISTRATION_STARTDATE_FAIL';

export const GET_REGISTRATION_STARTDATE_START = 'GET_REGISTRATION_STARTDATE_START';
export const GET_REGISTRATION_STARTDATE_SUCCESS = 'GET_REGISTRATION_STARTDATE_SUCCESS';
export const GET_REGISTRATION_STARTDATE_FAIL = 'GET_REGISTRATION_STARTDATE_FAIL';

export const GET_COURSES_REQUESTS_START = 'GET_COURSES_REQUESTS_START';
export const GET_COURSES_REQUESTS_SUCCESS = 'GET_COURSES_REQUESTS_SUCCESS';
export const GET_COURSES_REQUESTS_FAIL = 'GET_COURSES_REQUESTS_FAIL';

export const GET_YEAR_COST_START = 'GET_YEAR_COST_START';
export const GET_YEAR_COST_SUCCESS = 'GET_YEAR_COST_SUCCESS';
export const GET_YEAR_COST_FAIL = 'GET_YEAR_COST_FAIL';

export const GET_EVENTS_IN_GROUPS_COURSE_START = 'GET_EVENTS_IN_GROUPS_COURSE_START';
export const GET_EVENTS_IN_GROUPS_COURSE_SUCCESS = 'GET_EVENTS_IN_GROUPS_COURSE_SUCCESS';
export const GET_EVENTS_IN_GROUPS_COURSE_FAIL = 'GET_EVENTS_IN_GROUPS_COURSE_FAIL';

export const CHECK_DELETE_EVENT_START = 'CHECK_DELETE_EVENT_START';
export const CHECK_DELETE_EVENT_SUCCESS = 'CHECK_DELETE_EVENT_SUCCESS';
export const CHECK_DELETE_EVENT_FAIL = 'CHECK_DELETE_EVENT_FAIL';

export const CHECK_DELETE_USER_EVENT_START = 'CHECK_DELETE_USER_EVENT_START';
export const CHECK_DELETE_USER_EVENT_SUCCESS = 'CHECK_DELETE_USER_EVENT_SUCCESS';
export const CHECK_DELETE_USER_EVENT_FAIL = 'CHECK_DELETE_USER_EVENT_FAIL';

export const UPDATE_STUDY_PLAN_DATE_START = 'UPDATE_STUDY_PLAN_DATE_START';
export const UPDATE_STUDY_PLAN_DATE_SUCCESS = 'UPDATE_STUDY_PLAN_DATE_SUCCESS';
export const UPDATE_STUDY_PLAN_DATE_FAIL = 'UPDATE_STUDY_PLAN_DATE_FAIL';

export const COURSE_REQUEST_FINISH_START = 'COURSE_REQUEST_FINISH_START';
export const COURSE_REQUEST_FINISH_SUCCESS = 'COURSE_REQUEST_FINISH_SUCCESS';
export const COURSE_REQUEST_FINISH_FAIL = 'COURSE_REQUEST_FINISH_FAIL';

export const DELETE_REQUEST_COURSES_START = 'DELETE_REQUEST_COURSES_START';
export const DELETE_REQUEST_COURSES_SUCCESS = 'DELETE_REQUEST_COURSES_SUCCESS';
export const DELETE_REQUEST_COURSES_FAIL = 'DELETE_REQUEST_COURSES_FAIL';

export const GET_COURSES_COST_START = 'GET_COURSES_COST_START';
export const GET_COURSES_COST_SUCCESS = 'GET_COURSES_COST_SUCCESS';
export const GET_COURSES_COST_FAIL = 'GET_COURSES_COST_FAIL';

export const PUT_REJECT_TEST_START = 'PUT_REJECT_TEST_START';
export const PUT_REJECT_TEST_SUCCESS = 'PUT_REJECT_TEST_SUCCESS';
export const PUT_REJECT_TEST_FAIL = 'PUT_REJECT_TEST_FAIL';

export const PUT_RESCHEDULE_ALL_START = 'PUT_RESCHEDULE_ALL_START';
export const PUT_RESCHEDULE_ALL_SUCCESS = 'PUT_RESCHEDULE_ALL_SUCCESS';
export const PUT_RESCHEDULE_ALL_FAIL = 'PUT_RESCHEDULE_ALL_FAIL';

export const PUT_STATUS_ALL_START = 'PUT_STATUS_ALL_START';
export const PUT_STATUS_ALL_SUCCESS = 'PUT_STATUS_ALL_SUCCESS';
export const PUT_STATUS_ALL_FAIL = 'PUT_STATUS_ALL_FAIL';

export const PUT_MASS_EVENT_START = 'PUT_MASS_EVENT_START';
export const PUT_MASS_EVENT_SUCCESS = 'PUT_MASS_EVENT_SUCCESS';
export const PUT_MASS_EVENT_FAIL = 'PUT_MASS_EVENT_FAIL';

export const DELETE_MASS_START = 'DELETE_MASS_START';
export const DELETE_MASS_SUCCESS = 'DELETE_MASS_SUCCESS';
export const DELETE_MASS_FAIL = 'DELETE_MASS_FAIL';

export const GET_MASS_SELECTION_COUNT_START = 'GET_MASS_SELECTION_COUNT_START';
export const GET_MASS_SELECTION_COUNT_SUCCESS = 'GET_MASS_SELECTION_COUNT_SUCCESS';
export const GET_MASS_SELECTION_COUNT_FAIL = 'GET_MASS_SELECTION_COUNT_FAIL';

export const GET_MASS_SELECT_USERS_START = 'GET_MASS_SELECT_USERS_START';
export const GET_MASS_SELECT_USERS_SUCCESS = 'GET_MASS_SELECT_USERS_SUCCESS';
export const GET_MASS_SELECT_USERS_FAIL = 'GET_MASS_SELECT_USERS_FAIL';

export const POST_CHANGE_COMPLITED_START = 'POST_CHANGE_COMPLITED_START';
export const POST_CHANGE_COMPLITED_SUCCESS = 'POST_CHANGE_COMPLITED_SUCCESS';
export const POST_CHANGE_COMPLITED_FAIL = 'POST_CHANGE_COMPLITED_FAIL';

export const GET_COURSES_MANAGER_COST_START = 'GET_COURSES_MANAGER_COST_START';
export const GET_COURSES_COST_MANAGER_SUCCESS = 'GET_COURSES_COST_MANAGER_SUCCESS';
export const GET_COURSES_MANAGER_COST_FAIL = 'GET_COURSES_MANAGER_COST_FAIL';

export const GET_COURSES_ADMIN_MANAGER_COST_START = 'GET_COURSES_ADMIN_MANAGER_COST_START';
export const GET_COURSES_ADMIN_MANAGER_COST_SUCCESS = 'GET_COURSES_ADMIN_MANAGER_COST_SUCCESS';
export const GET_COURSES_ADMIN_MANAGER_COST_FAIL = 'GET_COURSES_ADMIN_MANAGER_COST_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

const getTopicFilterApiValues = filter => ({ ...filter });

export const getCoursesCostRequest = (year, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/cost/${year}/company`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COURSES_COST_START, GET_COURSES_COST_SUCCESS, GET_COURSES_COST_FAIL],
    onSuccess,
  };
};

export const getCoursesCostManagerRequest = (year, managerId = 'admin', onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/cost/${year}/${managerId}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types:
      managerId === 'admin'
        ? [
            GET_COURSES_ADMIN_MANAGER_COST_START,
            GET_COURSES_ADMIN_MANAGER_COST_SUCCESS,
            GET_COURSES_ADMIN_MANAGER_COST_FAIL,
          ]
        : [GET_COURSES_MANAGER_COST_START, GET_COURSES_COST_MANAGER_SUCCESS, GET_COURSES_MANAGER_COST_FAIL],
    onSuccess,
  };
};

export const getCoursesRequest = (page = 0, filter, onSuccess, onError) => {
  const apiFilter = getTopicFilterApiValues(filter);
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/request${getQueryString({
      page,
      ...apiFilter,
      size: 20,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COURSES_REQUEST_START, GET_COURSES_REQUEST_SUCCESS, GET_COURSES_REQUEST_FAIL],
    onSuccess,
    onError,
  };
};

export const getCoursesRequestTopic = (id, page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/request/topic/${id}${getQueryString({
    page,
    sort: 'id,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COURSES_REQUEST_TOPIC_START, GET_COURSES_REQUEST_TOPIC_SUCCESS, GET_COURSES_REQUEST_TOPIC_FAIL],
  onSuccess,
});

export const getCoursesRequestExport = (filename, filter, onSuccess) => {
  const apiFilter = getTopicFilterApiValues(filter);
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/request/export${getQueryString({
      filename,
      ...apiFilter,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    responseType: 'blob',
    onSuccess,
    types: [GET_COURSES_REQUEST_EXPORT_START, GET_COURSES_REQUEST_EXPORT_SUCCESS, GET_COURSES_REQUEST_EXPORT_FAIL],
  };
};
export const updateCoursesRequest = (status, comment, requestIds, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/request/update`,
  body: {
    comment,
    ids: requestIds,
    status,
  },
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [UPDATE_COURSES_REQUEST_START, UPDATE_COURSES_REQUEST_SUCCESS, UPDATE_COURSES_REQUEST_FAIL],
  onSuccess,
  onError,
});

export const createEvent = (courseEventVM, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/courses/event/create`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: courseEventVM,
  types: [CREATE_EVENT_START, CREATE_EVENT_SUCCESS, CREATE_EVENT_FAIL],
  onSuccess,
});

export const getYear = (onSuccess, params) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/request/year${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_YEAR_START, GET_YEAR_SUCCESS, GET_YEAR_FAIL],
  onSuccess,
});

export const getEventTopic = (id, params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/event/topic/${id}${getQueryString({
    ...params,
    isEnded: typeof params.isEnded !== 'undefined' ? (!params.isEnded ? `${params.isEnded}` : '') : '',
    sort: 'startTime,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_EVENT_TOPIC_START, GET_EVENT_TOPIC_SUCCESS, GET_EVENT_TOPIC_FAIL],
  onSuccess,
});

export const addUserEvent = (id, requestIds, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/event/${id}/request/add`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: requestIds,
  types: [ADD_USER_EVENT_START, ADD_USER_EVENT_SUCCESS, ADD_USER_EVENT_FAIL],
  onSuccess,
});

export const deleteUserEvent = (requestIds, comment, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/courses/event/remove`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { comment, requestIds },
  types: [DELETE_USER_EVENT_START, DELETE_USER_EVENT_SUCCESS, DELETE_USER_EVENT_FAIL],
  onSuccess,
});

export const updateEvent = (id, courseEventVM, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/event/update/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: courseEventVM,
  types: [UPDATE_EVENT_START, UPDATE_EVENT_SUCCESS, UPDATE_EVENT_FAIL],
  onSuccess,
});

export const courseRequestFinish = (id, complete, onSuccess, onError) => {
  const completeStr = !complete && '&complete=false';
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/courses/request/finish${getQueryString({
      id,
    })}${completeStr}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    body: {},
    types: [COURSE_REQUEST_FINISH_START, COURSE_REQUEST_FINISH_SUCCESS, COURSE_REQUEST_FINISH_FAIL],
    onSuccess,
    onError,
  };
};

export const deleteEvent = (id, comment, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/courses/event/delete/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { comment },
  types: [DELETE_EVENT_START, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAIL],
  onSuccess,
});

export const setRegistrationStartDate = (openDate, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/param`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    openDate,
  },
  types: [SET_REGISTRATION_STARTDATE_START, SET_REGISTRATION_STARTDATE_SUCCESS, SET_REGISTRATION_STARTDATE_FAIL],
  onSuccess,
});

export const getRegistrationOpenDate = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/param`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_REGISTRATION_STARTDATE_START, GET_REGISTRATION_STARTDATE_SUCCESS, GET_REGISTRATION_STARTDATE_FAIL],
  onSuccess,
});

// TODO: Нужно будет удалить, после полного отказала от компонента MaterialCard
export const activeStatus = (id, status, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/topic/${id}/${status}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [ACTIVE_STATUS_START, ACTIVE_STATUS_SUCCESS, ACTIVE_STATUS_FAIL],
  onSuccess,
});

export const sendMail = id => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/event/${encodeURI(id)}/mail`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [SEND_MAIL_START, SEND_MAIL_SUCCESS, SEND_MAIL_FAIL],
});

export const getEventUsers = (eventId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/request${getQueryString({ eventId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_EVENT_USERS_START, GET_EVENT_USERS_SUCCESS, GET_EVENT_USERS_FAIL],
  onSuccess,
});

export const getEventsGrours = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/topic/event/${params.topicId}${getQueryString({
      sort: 'endTime,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [GET_EVENTS_IN_GROUPS_COURSE_START, GET_EVENTS_IN_GROUPS_COURSE_SUCCESS, GET_EVENTS_IN_GROUPS_COURSE_FAIL],
    onSuccess,
  };
};

export const failEventValidation = () => ({ type: EVENT_VALIDATION_FAIL });

export const getCoursesRequests = (
  { topicId, ...params } = params,
  subCompanies = [],
  page = 0,
  isNoCourse,
  onSuccess
) => {
  const noCourseParams = getQueryString({ ...params, sort: params.sort || 'id,desc', size: 20, page, subCompanies });
  const courseParams = getQueryString({ ...params, topicId, size: 20, page, subCompanies });

  return {
    type: REST_API,
    method: GET,
    url:
      API_URL +
      (isNoCourse ? `/user/topic/plan/participant/${topicId}${noCourseParams}` : `/courses/request${courseParams}`), // /courses/request этот путь для топикТайпАЙди 17 и 20
    party: isNoCourse,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COURSES_REQUESTS_START, GET_COURSES_REQUESTS_SUCCESS, GET_COURSES_REQUESTS_FAIL],
    onSuccess,
  };
};

export const getEventParty = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/participant/${params.topicId}${getQueryString({
    ...params,
    sort: 'id,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  page: params.page,
  party: true,
  types: [GET_COURSES_REQUESTS_START, GET_COURSES_REQUESTS_SUCCESS, GET_COURSES_REQUESTS_FAIL],
  onSuccess,
});

export const deleteRequestCourses = (requestInfo, documented, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/user/topic/plan/manual/remove${getQueryString({
    documented,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: requestInfo,
  types: [DELETE_REQUEST_COURSES_START, DELETE_REQUEST_COURSES_SUCCESS, DELETE_REQUEST_COURSES_FAIL],
  onSuccess,
  onError,
});

export const updateStudyPlanDate = (ids, planDate, comment, onSuccess, withoutSchedule, eventId) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/admin/date`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    comment,
    ids,
    startDate: planDate,
    withoutSchedule,
    eventId,
  },
  onSuccess,
  withoutSchedule,
  month: planDate,
  types: [UPDATE_STUDY_PLAN_DATE_START, UPDATE_STUDY_PLAN_DATE_SUCCESS, UPDATE_STUDY_PLAN_DATE_FAIL],
});

export const getYearCost = (params, onSuccess) => {
  const { year } = params;
  const url = `${API_URL}/courses/cost/${year}/${params.managerId}`;
  return {
    url,
    type: REST_API,
    method: GET,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_YEAR_COST_START, GET_YEAR_COST_SUCCESS, GET_YEAR_COST_FAIL],
    onSuccess,
  };
};

export const getCommentRejected = (requestIds, page, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/comment${getQueryString({
    planIds: requestIds,
    page,
    sort: 'createdDate,desc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COURSES_REQUEST_COMMENT_START, GET_COURSES_REQUEST_COMMENT_SUCCESS, GET_COURSES_REQUEST_COMMENT_FAIL],
  onSuccess,
});

export const postCommentRejected = (requestCommentVM, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/topic/plan/comment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: requestCommentVM,
  types: [POST_COURSES_REQUEST_COMMENT_START, POST_COURSES_REQUEST_COMMENT_SUCCESS, POST_COURSES_REQUEST_COMMENT_FAIL],
  onSuccess,
});

export const checkDeleteEvent = (eventId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/request/event/active${getQueryString({ eventId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_DELETE_EVENT_START, CHECK_DELETE_EVENT_SUCCESS, CHECK_DELETE_EVENT_FAIL],
  onSuccess,
});

export const checkDeleteUserEvent = (requestId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/request/${requestId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_DELETE_USER_EVENT_START, CHECK_DELETE_USER_EVENT_SUCCESS, CHECK_DELETE_USER_EVENT_FAIL],
  onSuccess,
});

export const getEventParticipants = (id, filename, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/courses/event/${id}/export${getQueryString({ filename })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'blob',
  types: [GET_EVENT_PARTICIPANTS_START, GET_EVENT_PARTICIPANTS_SUCCESS, GET_EVENT_PARTICIPANTS_FAIL],
  onSuccess,
});

export const changeCoursesFilter = info => ({
  type: CHANGE_COURSES_FILTER,
  info,
});

export const clearCoursesFilter = () => ({
  type: CLEAR_COURSES_FILTER,
});

export const clearCoursesRequest = () => ({
  type: CLEAR_COURSES_REQUEST,
});

export const putRescheduleAll = (topicId, filter, body, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/admin/plan/reschedule/all${getQueryString({
    topicId,
    ...filter,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_RESCHEDULE_ALL_START, PUT_RESCHEDULE_ALL_SUCCESS, PUT_RESCHEDULE_ALL_FAIL],
  onSuccess,
});
export const updateStatusAll = (topicId, filter, body, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/request/update/all${getQueryString({
    topicId,
    ...filter,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [PUT_STATUS_ALL_START, PUT_STATUS_ALL_SUCCESS, PUT_STATUS_ALL_FAIL],
  onSuccess,
});

export const addMassEvent = (eventId, topicId, filter, useFilter, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/event/request/add/mass${getQueryString({
    eventId,
    topicId,
    ...filter,
    useFilter,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [PUT_MASS_EVENT_START, PUT_MASS_EVENT_SUCCESS, PUT_MASS_EVENT_FAIL],
  onSuccess,
  onError,
});

export const deleteMass = (topicId, filter, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/user/topic/plan/remove/all${getQueryString({ topicId, ...filter, documented: true })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [DELETE_MASS_START, DELETE_MASS_SUCCESS, DELETE_MASS_FAIL],
  onSuccess,
});

export const getMassSelectionCount = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/${topicId}/count`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MASS_SELECTION_COUNT_START, GET_MASS_SELECTION_COUNT_SUCCESS, GET_MASS_SELECTION_COUNT_FAIL],
  onSuccess,
  onError,
});

export const getMassSelectionUsers = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mass/export/users${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MASS_SELECT_USERS_START, GET_MASS_SELECT_USERS_SUCCESS, GET_MASS_SELECT_USERS_FAIL],
  responseType: 'blob',
  onSuccess,
  onError,
});

export const changeToComplited = (params, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/admin/plan/confirmation`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_CHANGE_COMPLITED_START, POST_CHANGE_COMPLITED_SUCCESS, POST_CHANGE_COMPLITED_FAIL],
  onSuccess,
});
