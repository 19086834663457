// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-IHiHXS.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-nMFwWR .admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-label-yy8txU[data-value="Montserrat"]:before, .admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-IHiHXS.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-nMFwWR .admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-item-ULgkbY[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-Montserrat-gZwDP_ {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-HPAOe0 {
  box-sizing: border-box;
  background-color: #ebe6f6;
  border-radius: 16px;
  height: 140px;
  margin-top: 24px;
  padding: 24px;
}

.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-count-Lk_vSP {
  align-items: center;
  gap: 40px;
  display: flex;
}

.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-count-Lk_vSP > h1 {
  margin: 0;
}

.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-download-Qgl0R_ {
  cursor: pointer;
  align-items: center;
  gap: 6px;
  display: flex;
}

.admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-download-text-AizSrf {
  color: var(--personalColor);
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/TemplateBlockFilters/UsersMaterial/UsersMaterial.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,aAAa;AACf;;AAMA;EAHE,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAKA;EAFE,SAAS;AACX;;AAIA;EADE,eAAe;EACf,mBAAmB;EACnB,QAAQ;EACR,aAAa;AACf;;AAGA;EAAE,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.UsersMaterial {\n  height: 140px;\n  background-color: #ebe6f6;\n  padding: 24px;\n  box-sizing: border-box;\n  border-radius: 16px;\n  margin-top: 24px;\n}\n.UsersMaterial-count {\n  display: flex;\n  gap: 40px;\n  align-items: center;\n}\n.UsersMaterial-count > h1 {\n  margin: 0;\n}\n.UsersMaterial-download {\n  gap: 6px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.UsersMaterial-download-text {\n  color: var(--personalColor);\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-IHiHXS`,
	"qlPicker": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-IHiHXS`,
	"ql-font": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-nMFwWR`,
	"qlFont": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-nMFwWR`,
	"ql-picker-label": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-label-yy8txU`,
	"qlPickerLabel": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-label-yy8txU`,
	"ql-picker-item": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-item-ULgkbY`,
	"qlPickerItem": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-picker-item-ULgkbY`,
	"ql-font-Montserrat": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-Montserrat-gZwDP_`,
	"qlFontMontserrat": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__ql-font-Montserrat-gZwDP_`,
	"UsersMaterial": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-HPAOe0`,
	"usersMaterial": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-HPAOe0`,
	"UsersMaterial-count": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-count-Lk_vSP`,
	"usersMaterialCount": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-count-Lk_vSP`,
	"UsersMaterial-download": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-download-Qgl0R_`,
	"usersMaterialDownload": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-download-Qgl0R_`,
	"UsersMaterial-download-text": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-download-text-AizSrf`,
	"usersMaterialDownloadText": `admin-src-components-TemplateBlockFilters-UsersMaterial-UsersMaterial-module__UsersMaterial-download-text-AizSrf`
};
export default ___CSS_LOADER_EXPORT___;
