/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { addTopicCareer, deleteTopicCareer, editTopic, getMerits, getTopicById } from '@/store/topics/actions';

import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Col, Divider, Form, Modal, Row, Select } from 'antd';
import MeritsInput from '@/components/MeritsInput';

import { ITOD_FIELDS } from '../../../constants/topic-types';

import TagsAutocomplete from '@shared/components/TagsAutocomplete';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './TopicCreationDialog.module.scss';

export const HARD = 'Hard';
export const SOFT = 'Soft';

export class ITODDialog extends Component {
  constructor(props) {
    super(props);
    if (props.topic) {
      const {
        careers,
        awareness,
        mentalFlexibility,
        involvement,
        minAwareness,
        maxAwareness,
        minInvolvement,
        maxInvolvement,
      } = this.props.topic;
      const uniqMerits = _.uniq([
        awareness,
        mentalFlexibility,
        involvement,
        minAwareness,
        maxAwareness,
        minInvolvement,
        maxInvolvement,
      ]);
      const isSoft = careers.length === 0 && (uniqMerits.length > 1 || uniqMerits[0] !== 0);
      const isHard = careers.length > 0;
      this.state = {
        editBtnClick: false,
        careers,
        errors: {},
        typeTrajectory: isSoft ? SOFT : isHard ? HARD : undefined,
        awareness,
        mentalFlexibility,
        involvement,
        minAwareness,
        maxAwareness,
        minInvolvement,
        maxInvolvement,
      };
    }

    if (!this.props.merits) {
      this.props.getMerits();
    }
    this.subcompanyGlobalSkill = !this.props.isGlobal && this.props.topic.global;
  }

  topicSkillList = [
    { id: 1, name: SOFT },
    { id: 2, name: HARD },
  ];

  defaultTrajectoryValues = {
    awareness: 0,
    involvement: 0,
    mentalFlexibility: 0,
    minAwareness: 0,
    maxAwareness: 0,
    minInvolvement: 0,
    maxInvolvement: 0,
  };

  clearTrajectoryType = () => {
    this.setState({
      ...this.defaultTrajectoryValues,
      careers: [],
      typeTrajectory: undefined,
    });
  };

  isFieldEmpty = fieldName => {
    if (Array.isArray(this.state[fieldName])) {
      return !this.state[fieldName].length;
    }
    if (fieldName === 'merits') {
      let val = [
        this.state.maxInvolvement,
        this.state.minAwareness,
        this.state.maxAwareness,
        this.state.minInvolvement,
      ];
      if (!this.state.careers.length) {
        val = [...val, this.state.awareness, this.state.mentalFlexibility, this.state.involvement];
      }
      return val.filter(item => !_.isNaN(parseInt(item, 10))).length !== val.length;
    }
    return !this.state[fieldName];
  };

  handleTrajectoryTypeChange = value => {
    this.setState({ typeTrajectory: value });
    if (value === SOFT) {
      this.setState({
        careers: [],
        ...this.defaultTrajectoryValues,
        maxInvolvement: 100,
        maxAwareness: 100,
      });
    } else if (value === HARD) {
      this.setState({
        ...this.defaultTrajectoryValues,
        maxInvolvement: 100,
        maxAwareness: 100,
      });
    } else {
      this.setState({
        ...this.defaultTrajectoryValues,
        careers: [],
      });
    }
  };

  handleMeritChange = value => {
    const meritItem = this.props.merits.find(item => item.name === value);
    this.setState({
      awareness: meritItem.awareness,
      involvement: meritItem.involvement,
      mentalFlexibility: meritItem.mentalFlexibility,
      selectedMerit: value,
    });
  };

  handleMeritValuesChange = value => {
    const { selectedMerit } = this.state;
    const { awareness, involvement, mentalFlexibility } = value;
    if (selectedMerit && (awareness || involvement || mentalFlexibility)) {
      this.setState({ selectedMerit: undefined });
    }
    this.setState({ ...value });
  };

  formValidation = () => {
    const errors = {};
    let haveErrors = false;
    for (let i = 0; i < ITOD_FIELDS.length; i++) {
      const field = ITOD_FIELDS[i];
      if (field.required && this.isFieldEmpty(field.apiField)) {
        if ((field.option && this.state.typeTrajectory === field.option) || !field.option) {
          errors[field.apiField] = this.props.t('required');
          haveErrors = true;
        }
      }
    }
    this.setState({ errors });
    return !haveErrors;
  };

  handleEditGlobalTopicClick = () => {
    this.setState({ editBtnClick: true });
    const stateIds = this.state.careers.map(({ id }) => id);
    const topicIds = this.props.topic.careers.map(({ id }) => id);
    const all = [
      ..._.without([...stateIds], ...topicIds).map(id =>
        this.props.addTopicCareer({ id: this.props.topic.id, careerId: id })
      ),
      ..._.without([...topicIds], ...stateIds).map(id =>
        this.props.deleteTopicCareer({ id: this.props.topic.id, careerId: id })
      ),
    ];
    Promise.all(all).then(() => {
      this.setState({ editBtnClick: false });
      this.props.getTopicById(this.props.topic.id);
      this.props.onCancel();
    });
  };

  handleEditTopicClick = () => {
    const { editBtnClick, errors, ...data } = this.state;
    const topic = { ...this.props.topic };
    topic.categoryIds =
      topic.categories &&
      topic.categories.map(cat => {
        return cat.id;
      });
    const resultDates = [];
    topic.dates.map(shed => {
      shed.dates.map(date => {
        resultDates.push({
          startDate: dayjs(date, 'YYYY-MM-DD'),
          startTime: dayjs(shed.startTime, 'HH:mm:ss'),
          endTime: dayjs(shed.endTime, 'HH:mm:ss'),
          place: shed.place,
        });
      });
    });
    topic.dates = resultDates;
    topic.typeId = topic.type && +topic.type.id;
    topic.subTypeId = topic.subType && +topic.subType.id;
    if (this.formValidation()) {
      this.setState({ editBtnClick: true });
      this.props.editTopic(
        { ...topic, ...data },
        () => {
          this.props.onTopicCreationSuccess();
          this.setState({ editBtnClick: false });
          this.props.onCancel();
        },
        () => {
          this.setState({ editBtnClick: false });
        }
      );
    }
  };

  handleChange = event => {
    const { name, value } = event.target || event;
    this.setState({ [name]: value });
  };

  render() {
    const { open, onCancel, merits, t } = this.props;

    const meritsList = [
      {
        id: 'awareness',
        name: t('mindfulness'),
        value: this.state.awareness,
      },
      {
        id: 'involvement',
        name: t('consistency'),
        value: this.state.involvement,
      },
      {
        id: 'mentalFlexibility',
        name: t('flexibility'),
        value: this.state.mentalFlexibility,
      },
    ];

    const meritsReq = [
      {
        id: 'minAwareness',
        name: t('minMindfulness'),
        value: this.state.minAwareness,
      },
      {
        id: 'maxAwareness',
        name: t('maxMindfulness'),
        value: this.state.maxAwareness,
      },
      {
        id: 'minInvolvement',
        name: t('minConsistency'),
        value: this.state.minInvolvement,
      },
      {
        id: 'maxInvolvement',
        name: t('maxConsistency'),
        value: this.state.maxInvolvement,
      },
    ];

    const submitButton = (
      <Button
        disabled={this.state.editBtnClick || (this.subcompanyGlobalSkill && !this.state.careers.length)}
        onClick={this.subcompanyGlobalSkill ? this.handleEditGlobalTopicClick : this.handleEditTopicClick}
        type='primary'
      >
        {t('edit')}
      </Button>
    );

    return (
      <Modal open={open} onCancel={onCancel} footer={null} width={1150}>
        <Form className={css['Add-modal-form']}>
          <div className={css['Add-modal']} data-qa='topicCreationModal'>
            <div className={css['Add-modal-header']}>{t('settings')}</div>
            <Row>
              <Col span={9}>
                <Form.Item label={t('type')}>
                  <div className={css['Add-modal-select-container']}>
                    <Select
                      placeholder={t('notSelected')}
                      className={css.TopicCreate}
                      onChange={this.handleTrajectoryTypeChange}
                      name='typeTrajectory'
                      options={this.topicSkillList?.map(option => ({ value: option.name, label: option.name }))}
                      value={this.state.typeTrajectory}
                      disabled={this.subcompanyGlobalSkill}
                      allowClear
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={15}>
                {this.state.typeTrajectory === SOFT && (
                  <Form.Item label={t('presets')}>
                    <Select
                      placeholder={t('manually')}
                      className={css.TopicCreate}
                      onChange={this.handleMeritChange}
                      name='presetTrajectory'
                      options={merits?.map(option => ({ value: option.name, label: option.name }))}
                      value={this.state.selectedMerit}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            {this.state.typeTrajectory === SOFT && (
              <>
                <Form.Item
                  label={t('characteristics')}
                  help={this.state.errors.merits}
                  className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.merits })}
                  labelCol={{ span: 5 }}
                  required
                >
                  <MeritsInput merits={meritsList} onChange={this.handleMeritValuesChange} name='merits' />
                </Form.Item>
                <Form.Item
                  label={t('requirements')}
                  help={this.state.errors.merits}
                  className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.merits })}
                  labelCol={{ span: 5 }}
                  required
                >
                  <MeritsInput merits={meritsReq} onChange={this.handleMeritValuesChange} name='merits' />
                </Form.Item>
              </>
            )}
            {this.state.typeTrajectory === HARD && (
              <>
                <Form.Item
                  label={t('specialization')}
                  help={this.state.errors.careers}
                  className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.careers })}
                  labelCol={{ span: 5 }}
                  required
                >
                  {/* доработать теги */}
                  <TagsAutocomplete
                    onChange={this.handleChange}
                    name='careers'
                    placeholder={t('key')}
                    value={this.state.careers}
                    isCareers
                    // isGlobal={this.props.isGlobal}
                    t={t}
                  />
                </Form.Item>
                <Form.Item
                  label={t('requirements')}
                  help={this.state.errors.merits}
                  className={classNames({ [css['Add-modal-fromItem-error']]: this.state.errors.merits })}
                  labelCol={{ span: 4 }}
                  required
                >
                  <MeritsInput
                    merits={meritsReq}
                    onChange={this.handleMeritValuesChange}
                    name='merits'
                    disabled={this.subcompanyGlobalSkill}
                  />
                </Form.Item>
              </>
            )}
          </div>
          <Divider />
          <div className={css['Add-modal-footerBtn']}>{submitButton}</div>
        </Form>
      </Modal>
    );
  }
}

ITODDialog.propTypes = {
  merits: PropTypes.array,
  editTopic: PropTypes.func,
  getMerits: PropTypes.func,
  isGlobal: PropTypes.bool,
  getTopicById: PropTypes.func,
};

const mapStateToProps = createSelector([topicsSelect, usersSelect], (topics, users) => ({
  merits: topics.merits,
  isGlobal: users && users.currentUser.domainCompany.global,
}));

const mapActionsToProps = {
  getMerits,
  editTopic,
  addTopicCareer,
  deleteTopicCareer,
  getTopicById,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('ITODDialog')(ITODDialog));
