import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Checkbox, Col, Row, Tooltip } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { CHECK_APPROVED, FINISH_IPR, FINISHED_TOPICS_TASKS, VIEW_IPR } from '../constants';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';
import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import css from '../../Career.module.scss';

const CareerTopic = ({ data, deleteTopic, type }) => {
  const { t } = useTranslation('career');

  const [avatar] = useLocalFileByUUID(data.thumbFileUuid, {
    isDefaultNull: false,
    defaultImg: TOPIC_IMAGE.find(type => type.id === data?.type?.id)?.image,
  });

  return (
    <Row align='middle' justify='space-between'>
      <Col span={3} className={css['ModalCheckApprove-main-tabs-material-block-image']}>
        <img src={avatar} alt='topicImage' />
      </Col>
      <Tooltip title={data?.name?.length > 30 && data.name}>
        <Col span={17} className={css['ModalCheckApprove-main-tabs-material-block-text']}>
          <Link to={`/topic/${data?.id}`} target='_blank'>
            {data.name}
          </Link>
        </Col>
      </Tooltip>
      <Col span={3}>
        <div className={css['ModalCheckApprove-main-tabs-material-block-actions']}>
          {type === CHECK_APPROVED && (
            <>
              <Tooltip title={data.status ? t('materialInPlan') : t('materialNotPlan')}>
                {data.status ? (
                  <CheckCircleOutlined className={css['ModalCheckApprove-main-tabs-material-block-actions-inplan']} />
                ) : (
                  <CloseCircleOutlined className={css['ModalCheckApprove-main-tabs-material-block-actions-notplan']} />
                )}
              </Tooltip>

              <Tooltip title={t('deleteTopic')}>
                <DeleteOutlined
                  onClick={() => deleteTopic(data.id, data.type.id)}
                  className={css['ModalCheckApprove-main-tabs-material-block-actions-delete']}
                />
              </Tooltip>
            </>
          )}

          {(type === FINISH_IPR || type === VIEW_IPR) && (
            <Checkbox checked={FINISHED_TOPICS_TASKS.includes(data.status)} />
          )}
        </div>
      </Col>
    </Row>
  );
};

CareerTopic.propTypes = {
  data: PropTypes.object,
  deleteTopic: PropTypes.func,
  type: PropTypes.string,
};

export default CareerTopic;
