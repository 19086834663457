// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-DragDropList-DragDropList-module__ql-picker-YDEj_b.admin-src-components-DragDropList-DragDropList-module__ql-font-Lo9u0f .admin-src-components-DragDropList-DragDropList-module__ql-picker-label-gi1CzX[data-value="Montserrat"]:before, .admin-src-components-DragDropList-DragDropList-module__ql-picker-YDEj_b.admin-src-components-DragDropList-DragDropList-module__ql-font-Lo9u0f .admin-src-components-DragDropList-DragDropList-module__ql-picker-item-Ha5cIG[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-DragDropList-DragDropList-module__ql-font-Montserrat-nE7lll {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-DragDropList-DragDropList-module__dragDropList-oybVjp {
  margin: 0 -40px 0 -250px;
  padding: 0 40px 0 250px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/DragDropList/DragDropList.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,wBAAwB;EACxB,uBAAuB;AACzB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.dragDropList {\n  margin: 0 -40px 0 -250px;\n  padding: 0 40px 0 250px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-DragDropList-DragDropList-module__ql-picker-YDEj_b`,
	"qlPicker": `admin-src-components-DragDropList-DragDropList-module__ql-picker-YDEj_b`,
	"ql-font": `admin-src-components-DragDropList-DragDropList-module__ql-font-Lo9u0f`,
	"qlFont": `admin-src-components-DragDropList-DragDropList-module__ql-font-Lo9u0f`,
	"ql-picker-label": `admin-src-components-DragDropList-DragDropList-module__ql-picker-label-gi1CzX`,
	"qlPickerLabel": `admin-src-components-DragDropList-DragDropList-module__ql-picker-label-gi1CzX`,
	"ql-picker-item": `admin-src-components-DragDropList-DragDropList-module__ql-picker-item-Ha5cIG`,
	"qlPickerItem": `admin-src-components-DragDropList-DragDropList-module__ql-picker-item-Ha5cIG`,
	"ql-font-Montserrat": `admin-src-components-DragDropList-DragDropList-module__ql-font-Montserrat-nE7lll`,
	"qlFontMontserrat": `admin-src-components-DragDropList-DragDropList-module__ql-font-Montserrat-nE7lll`,
	"dragDropList": `admin-src-components-DragDropList-DragDropList-module__dragDropList-oybVjp`
};
export default ___CSS_LOADER_EXPORT___;
