import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import { DEFAULT_FILTERS, ANSWER_TYPES } from './constants';
import { useDispatch, useSelector } from 'react-redux';

import { getBankCategories, getQuestionsList, setActiveSort, setIsSort } from '@/store/questionBank/actions';

import { selectQuestionBank } from '@/store/questionBank/selectors';

import { Button, Form, Input, Select } from 'antd';
import { QUESTIONS_TABLE_SIZE, QUESTIONS_TABLE_SIZE_MODAL } from '@/pages/QuestionBank/constants';

import { DEFAULT_FILTERS } from './constants';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './BankFilter.module.scss';

const { Option } = Select;

const BankFilter = ({ filters, setFilters, inModal }) => {
  const { t } = useTranslation('QuestionBank');
  const dispatch = useDispatch();
  const { isLoadingCategories, categories, isSort } = useSelector(selectQuestionBank);

  // Сброс фильтров
  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS);
    setIsSort('id,desc');
    dispatch(setActiveSort({ id: true, aZ: false, zA: false }));
    dispatch(getQuestionsList({}, '', isSort, inModal ? QUESTIONS_TABLE_SIZE_MODAL : QUESTIONS_TABLE_SIZE));
  };

  // При применении фильтра
  const onSubmitFilters = () => {
    dispatch(getQuestionsList(filters, '', isSort, inModal ? QUESTIONS_TABLE_SIZE_MODAL : QUESTIONS_TABLE_SIZE));
  };

  // Генерация типов вопроса
  // const optionsType = useMemo(
  //   () =>
  //     ANSWER_TYPES.map(type => (
  //       <Option key={type.id} value={type.id}>
  //         {t(type.name)}
  //       </Option>
  //     )),
  //   [ANSWER_TYPES]
  // );

  // Генерация категорий
  const optionsCetegory = useMemo(() =>
    categories.map(category => (
      <Option key={category.id} value={category.id}>
        {category.name}
      </Option>
    ))
  );

  useEffect(() => {
    dispatch(getBankCategories(filters.categoryIds));
  }, []);

  // Отдельная функция для получения категорий через debounce
  const handleGetBankCategories = category => dispatch(getBankCategories(category));

  // Запрос с debounce на получение категорий
  const getBankCategoriesDebounced = useCallback(_.debounce(handleGetBankCategories, 500), []);

  // Поиск категорий
  const onSearchCategories = val => {
    getBankCategoriesDebounced(val);
  };

  return (
    <div className={classNames(!inModal && css['BankFilter'])}>
      <div className={css['BankFilter-buttons']}>
        <span className={css['BankFilter-buttons-title']}>{t('filters')}</span>
        <Button type='link' onClick={resetFilters} className={css['BankFilter-buttons-reset']}>
          {t('reset')}
        </Button>
      </div>
      <Form layout={'vertical'} className={css['BankFilter-block-mainform']}>
        <Form.Item
          label={t('category')}
          colon={false}
          className={classNames(css['Filters-block-form'], css['BankFilter-block-form'])}
        >
          <Select
            className={css['BankFilter-block-select']}
            value={filters.categoryIds}
            allowClear
            autoClearSearchValue
            onChange={e => setFilters({ ...filters, categoryIds: e?.length > 0 ? e : undefined })}
            optionFilterProp='children'
            placeholder={t('placeholderCategory')}
            loading={isLoadingCategories}
            onSearch={onSearchCategories}
            onBlur={() => onSearchCategories()}
            mode='multiple'
          >
            {optionsCetegory}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('name')}
          colon={false}
          className={classNames(css['BankFilter-block-form'], css['BankFilter-block-form__name'])}
        >
          <Input
            value={filters.name}
            className={css['BankFilter-block-input']}
            onChange={e => setFilters({ ...filters, name: e?.target?.value })}
            placeholder={t('placeholderName')}
            allowClear
            maxLength={500}
          />
        </Form.Item>
        {/*
          <Form.Item label={t('type')} colon={false} className={css['BankFilter-block-form']}>
            <Select
              className={css['BankFilter-block-select']}
              value={filters.type}
              allowClear
              showSearch
              onChange={e => setFilters({ ...filters, type: e })}
              optionFilterProp='children'
              placeholder={t('placeholderType')}
            >
              {optionsType}
            </Select>
          </Form.Item>
        */}
      </Form>
      <div className={css['BankFilter-apply']}>
        <Button type='primary' onClick={onSubmitFilters}>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
};

BankFilter.propTypes = {
  filters: PropTypes.shape({
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  inModal: PropTypes.bool,
  setFilters: PropTypes.func,
};

export default BankFilter;
