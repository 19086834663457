export const SINGLE_ID = 0;
export const MULTIPLE_ID = 1;
export const OPEN_ID = 2;
export const MATCHING_ID = 3;

export const SINGLE = '1';
export const MULTIPLE = '2';
export const OPEN = '3';
export const MATCHING = '4';

export const MIN_MATCHING_ANSWERS = 2;
export const MAX_MATCHING_ANSWERS = 10;

export const MAX_TEXT_LEN = 10000;

export const MAX_MATCH_TEXT_LEN = 150;

export const emptyAnswer = {
  answer: '',
  correct: false,
  imgUuid: null,
};

export const emptyMatchingAnswer = {
  left: {
    isText: true,
    content: '',
    image: '',
  },
  right: {
    isText: true,
    content: '',
    image: '',
  },
  position: undefined,
};

export const emptyQuestion = {
  imgUuid: null,
  answerType: SINGLE,
  question: '',
  type: 1,
  multipleAnswers: false,
};

export const emptyTest = {
  allowedCount: 1,
  pass: 0,
  poll: false,
  questions: [],
  review: false,
  extendedResults: true,
};

export const booleanToNumber = (data, forward = true) => {
  return {
    ...data,
    questions: data.questions.map(item => {
      if (forward) {
        item.answerType = item.multipleAnswers ? MULTIPLE : String(item.type + 1);
      }

      return item;
    }),
  };
};

export const setPosition = data => {
  return {
    ...data,
    questions: data.questions.map(({ id, ...item }, index) => {
      const question = {
        ...item,
        position: index,
        answers: item.answers?.map((answer, answerIndex) => ({
          ...answer,
          position: answerIndex,
        })),
      };

      if (item.matchingAnswers?.length) {
        question.matchingAnswers = item.matchingAnswers.map((answer, index) => {
          const position = answer.position >= 0 ? answer.position : index;

          return {
            ...answer,
            left: { ...answer.left },
            right: { ...answer.right },
            position,
          };
        });
      }

      if (!id.toString().includes('fake')) {
        question.id = id;
      }
      return question;
    }),
  };
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
