import { useEffect, useState } from 'react';

import { DatePicker } from 'antd';

import CalendarIcon from './assets/calendar-icon.svg?react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './DatePicker.module.scss';

const CustomDatePicker = ({
  value,
  onChange,
  name,
  outputFormat,
  placeholder,
  monthCalendar,
  dark,
  longFilter,
  newStyle,
  initEmpty,
}) => {
  const [currentValue, setCurrentValue] = useState(!initEmpty && dayjs());

  const handleChange = date => {
    onChange({ name, value: date?.format('YYYY-MM-DD') });
    setCurrentValue(date);
  };

  const handleChangeMonth = date => {
    onChange({ name, value: date?.format('YYYY-MM-DD') });
    setCurrentValue(date);
  };

  useEffect(() => {
    if (value) {
      if (monthCalendar) {
        setCurrentValue(dayjs(value, outputFormat || 'YYYY-MM-DD'));
      } else {
        setCurrentValue(dayjs(value, outputFormat || 'YYYY-MM-DD'));
      }
    }
  }, [value]);

  return (
    <div
      className={classNames(
        css.DatePicker,
        { [css.dark]: dark },
        { [css.newStyle]: newStyle },
        { [css.longFilter]: longFilter }
      )}
    >
      {monthCalendar ? (
        <div className={css.DatePicker__months}>
          <DatePicker
            picker='month'
            value={currentValue}
            placeholder={dayjs(placeholder, 'DD.MM.YYYY')?.format('MM.YYYY')}
            onChange={handleChangeMonth}
            minDate={dayjs().subtract(5, 'year')} // ранее было 30 лет уменьшил до 5ти
            maxDate={dayjs().add(5, 'year')} // ранее было 30 лет уменьшил до 5ти
            format={'MM.YYYY'}
            allowClear
          />
        </div>
      ) : (
        <DatePicker
          picker='date'
          suffixIcon={<CalendarIcon />}
          placeholder={placeholder}
          value={currentValue}
          onChange={handleChange}
          format={'DD.MM.YYYY'}
        />
      )}
    </div>
  );
};

CustomDatePicker.propTypes = {
  dark: PropTypes.bool,
  newStyle: PropTypes.bool,
  longFilter: PropTypes.bool,
  monthCalendar: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  outputFormat: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  t: PropTypes.func,
  initEmpty: PropTypes.bool,
};

export default CustomDatePicker;
