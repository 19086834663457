/* eslint-disable */
import { ApiService } from './api-service';

export class FunctionService extends ApiService {
  getFunctions(params: TGetFunctionParams): Promise<any[]> {
    return this.get('/functionInternal', params);
  }
}

type TGetFunctionParams = {
  name?: string;
};

export default new FunctionService();
