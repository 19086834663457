// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-EZ2Txx.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-lsaz54 .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-label-C0HhS5[data-value="Montserrat"]:before, .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-EZ2Txx.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-lsaz54 .admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-item-KozJNO[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-Montserrat-uqIQel {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-BVyVyA {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2.8%;
  width: 100%;
  padding: 15px 0;
  display: flex;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-closeAll-WkwUdP {
  text-align: right;
  width: 40%;
  right: 0;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-consistent-Z0XaEX {
  color: #b6b6b6;
  align-items: center;
  margin-left: 20px;
  font-size: 11px;
  display: flex;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-btn-gFCp9E {
  color: #05b;
  margin: 0 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-collapsibleContainer-_6Yn2R {
  flex-flow: column;
  display: flex;
}

.admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-BVyVyA .ant-collapse-header {
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/Topics/TopicDetailsDialog/LMS/Blocks/Blocks.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,iBAAiB;EACjB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,eAAe;EACf,aAAa;AACf;;AAOA;EAJE,iBAAiB;EACjB,UAAU;EACV,QAAQ;AACV;;AAMA;EAHE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,aAAa;AACf;;AAKA;EAFE,WAAW;EACX,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAIA;EADE,iBAAiB;EACjB,aAAa;AACf;;AAGA;EAAE,mBAAmB;AACrB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.Blocks {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 2.8%;\n  padding: 15px 0;\n  flex-wrap: nowrap;\n}\n.Blocks-closeAll {\n  right: 0;\n  width: 40%;\n  text-align: right;\n}\n.Blocks-consistent {\n  margin-left: 20px;\n  display: flex;\n  align-items: center;\n  color: #b6b6b6;\n  font-size: 11px;\n}\n.Blocks-btn {\n  margin: 0 5px;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 1.43;\n  color: #05b;\n}\n.Blocks-collapsibleContainer {\n  display: flex;\n  flex-flow: column;\n}\n.Blocks :global .ant-collapse-header {\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-EZ2Txx`,
	"qlPicker": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-EZ2Txx`,
	"ql-font": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-lsaz54`,
	"qlFont": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-lsaz54`,
	"ql-picker-label": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-label-C0HhS5`,
	"qlPickerLabel": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-label-C0HhS5`,
	"ql-picker-item": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-item-KozJNO`,
	"qlPickerItem": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-picker-item-KozJNO`,
	"ql-font-Montserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-Montserrat-uqIQel`,
	"qlFontMontserrat": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__ql-font-Montserrat-uqIQel`,
	"Blocks": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-BVyVyA`,
	"blocks": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-BVyVyA`,
	"Blocks-closeAll": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-closeAll-WkwUdP`,
	"blocksCloseAll": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-closeAll-WkwUdP`,
	"Blocks-consistent": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-consistent-Z0XaEX`,
	"blocksConsistent": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-consistent-Z0XaEX`,
	"Blocks-btn": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-btn-gFCp9E`,
	"blocksBtn": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-btn-gFCp9E`,
	"Blocks-collapsibleContainer": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-collapsibleContainer-_6Yn2R`,
	"blocksCollapsibleContainer": `admin-src-pages-Topics-TopicDetailsDialog-LMS-Blocks-Blocks-module__Blocks-collapsibleContainer-_6Yn2R`
};
export default ___CSS_LOADER_EXPORT___;
