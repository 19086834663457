import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCoursesExport, getLogging, getLoggingExport } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Flex, Table, Tooltip } from 'antd';
import { Content } from '@/components/Layout';
import TypeBadge from '@/components/TypeBadge';
import { ArrowRightOutlined, DownloadOutlined, PieChartOutlined } from '@ant-design/icons';

import Filters from './parts/Filters/Filters';

import Utils from '@/Utils';
import dayjs from 'dayjs';
import _ from 'lodash';

import css from './Logging.module.scss';

import { DATALENS_READ, EXPORT_LOG_MATERIAL } from '@/constants/permissions';
import { TOPIC_TYPES } from '@/constants/topic-types';
import lock from '@/assets/images/lock.svg';

const DANONE_ID = 6901;

const MAX_ACTION_LENGTH = 24;
const MAX_LOGIN_LENGTH = 20;

const DEFAULT_FILTERS = {
  customFields: [{}],
  from: dayjs().subtract(2, 'months').format('YYYY-MM-DD'),
  to: dayjs().format('YYYY-MM-DD'),
};

const Logging = () => {
  const { currentUser } = useSelector(selectUsers);
  const { t } = useTranslation('logging');
  const [linkDataLens, setLinkDataLens] = useState('');
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });

  const [submitedFilters, setSubmitedFilters] = useState({ ...DEFAULT_FILTERS });

  const [filterIsEdited, setFilterIsEdited] = useState(false);

  const {
    isLoadingExport,
    logging,
    loggingPageNumber,
    loggingPageSize,
    loggingTotal,
    isLoading,
    isLoadingExportCourses,
  } = useSelector(selectTopics);
  const { actionsLog } = useSelector(selectTopics);

  const validData = value => {
    let copyValue = _.cloneDeep(value);
    const filteredCf = Utils.handleCustomFields(copyValue?.customFields);
    const result = { ...copyValue, customFields: filteredCf };

    for (let key in result) {
      if (!result[key]) {
        delete result[key]; // удалил пустые значения
      }

      if (Array.isArray(result[key]) && result[key].length === 0) {
        delete result[key]; // удалить пустые массивы
      }
    }

    return result;
  };

  useEffect(() => {
    const sendData = validData(filters);
    dispatch(getLogging(sendData, 0));
  }, []);

  const columns = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      width: '54px',
      render: val => dayjs(val).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: t('respond2'),
      dataIndex: 'action',
      key: 'action',
      width: '200px',
      render: item => (
        <Tooltip title={item?.value && item?.value.length >= MAX_ACTION_LENGTH && item?.value}>
          {item?.value && Utils.trString(item?.value, MAX_ACTION_LENGTH)}
        </Tooltip>
      ),
    },
    {
      title: t('material2'),
      dataIndex: 'materialType',
      key: 'materialType',
      width: '150px',
      render: type => <TypeBadge type={type?.code} />,
    },
    {
      title: t('materialName'),
      dataIndex: 'materialName',
      key: 'materialName',
      width: '240px',
    },
    {
      title: t('event2'),
      dataIndex: 'eventName',
      key: 'eventName',
      width: '200px',
    },
    {
      title: t('goal'),
      dataIndex: 'recipient',
      key: 'recipient',
      width: '200px',
      render: item => {
        const DELETED_REC =
          item.recipient &&
          (item.recipient.status === 'DELETED' ? (
            <>
              <Tooltip title={t('deleted')}>
                <img key='deleteUser' src={lock} alt='' />
              </Tooltip>
              <br />
            </>
          ) : (
            item.recipient.moved && (
              <>
                <Tooltip title={t('newCompany')}>
                  <ArrowRightOutlined />
                </Tooltip>
                <br />
              </>
            )
          ));

        const recipient = item.target ? (
          <div className={css['Logging-body-overflow']}>
            <Tooltip title={item.target}>{item.target}</Tooltip>
          </div>
        ) : (
          item.recipient && (
            <div className={css['Logging-body-overflow']}>
              {`${item.recipient.lastName} ${item.recipient.firstName}`}
              <br />
              {DELETED_REC}
              <Tooltip title={item.recipient.login.length > MAX_LOGIN_LENGTH && item.recipient.login}>
                {Utils.trString(item.recipient.login, MAX_LOGIN_LENGTH)}
              </Tooltip>
            </div>
          )
        );

        return recipient;
      },
    },
    {
      title: t('respondent'),
      dataIndex: 'sender',
      key: 'sender',
      render: item => {
        const DELETED_USER = item.senderDeleted ? (
          <Tooltip title={t('deleted')}>
            <img key='deleteUser' src={lock} alt='' />
          </Tooltip>
        ) : (
          item.moved && (
            <Tooltip title={t('newCompany')}>
              <ArrowRightOutlined />
            </Tooltip>
          )
        );

        const senderName = item.senderName ? (item.senderName === t('admin') ? t('panel') : item.senderName) : '';

        const senderLogin = item.senderLogin ? (
          <Tooltip title={item.senderLogin.length > MAX_LOGIN_LENGTH && item.senderLogin}>
            {Utils.trString(item.senderLogin, MAX_LOGIN_LENGTH)}
          </Tooltip>
        ) : (
          ''
        );

        return (
          <div className={css['Logging-body-overflow']}>
            {senderName}
            <br />
            {DELETED_USER} {senderLogin}
          </div>
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    return logging.map(log => {
      const actionType =
        log.type && actionsLog ? _.find(actionsLog && actionsLog, action => action.name === log.type) : '';

      const topicType = log.topic.type && _.find(TOPIC_TYPES(t), type => type.id === log.topic.type.id);

      return {
        key: log?.id,
        date: log?.creationTime,
        action: actionType,
        materialType: topicType,
        materialName: <a href={`/topic/${log?.topic?.id}`}>{log?.topic?.name ? log.topic.name : ''}</a>,
        eventName: log?.eventName,
        recipient: log,
        sender: log,
      };
    });
  }, [logging, actionsLog]);

  useEffect(() => {
    if (currentUser?.domainCompany?.domain) {
      let currDom = currentUser.domainCompany.domain;
      const authToken = localStorage.getItem('authorizationToken').split(' ')[1];
      const linkDataLens = `https://${currDom}/api/v1/report/datalens?access_token=${authToken}`;
      setLinkDataLens(linkDataLens);
    }
  }, [currentUser]);

  const exportLogging = () => {
    const date = new Date();
    const fileName = `logging-${date.toISOString()}.csv`;
    const sendData = validData(filters);

    dispatch(
      getLoggingExport(fileName, sendData, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  const exportCourses = () => {
    const date = new Date();
    const fileName = `TopicEvents_${date.toISOString()}.csv`;
    const sendData = validData(filters);

    dispatch(
      getCoursesExport(fileName, sendData, blob => {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
      })
    );
  };

  const onChangeFilter = (name, value) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value,
    }));

    setFilterIsEdited(true);
  };

  const onSubmitFilters = () => {
    setSubmitedFilters(filters);
    setFilterIsEdited(false);
  };

  useEffect(() => {
    const sendData = validData(submitedFilters);
    dispatch(getLogging(sendData, 0));
  }, [submitedFilters]);

  const onClearFilter = () => {
    setSubmitedFilters({ ...DEFAULT_FILTERS });
    setFilters({ ...DEFAULT_FILTERS });
  };

  const pagination = {
    total: loggingTotal,
    pageSize: loggingPageSize,
    current: loggingPageNumber + 1,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const onChangePagination = ({ current }) => {
    const sendData = validData(submitedFilters);
    dispatch(getLogging(sendData, current - 1));
  };

  return (
    <Content>
      <Flex justify='space-between' align='top' className={css['Logging-blockHeader']}>
        <div>
          <h1>{t('logs')}</h1>
        </div>
        <div>
          {currentUser?.domainCompany?.id === DANONE_ID && currentUser?.authorities?.includes(DATALENS_READ) && (
            <Button
              href={linkDataLens + '&report_index=1'}
              className={css['Logging-danoneDashboard']}
              type='primary'
              icon={<PieChartOutlined />}
            >
              {t('descIconDanone')}
            </Button>
          )}

          {currentUser?.authorities?.includes(DATALENS_READ) && (
            <Button href={linkDataLens} type='primary' icon={<PieChartOutlined />}>
              {t('descIcon')}
            </Button>
          )}
        </div>
      </Flex>

      <Filters
        filters={filters}
        onChangeFilter={onChangeFilter}
        onClearFilter={onClearFilter}
        onSubmitFilters={onSubmitFilters}
      />

      {currentUser.authorities.includes(EXPORT_LOG_MATERIAL) && (
        <Flex className={css['Logging-export']} justify='flex-end'>
          <Tooltip
            title={filterIsEdited ? t('submitFilterTooltip') : (!filters.from || !filters.to) && t('helpFilterTooltip')}
          >
            <Button
              onClick={exportCourses}
              disabled={filterIsEdited || !filters.from || !filters.to}
              loading={isLoadingExportCourses}
              type='primary'
              size='large'
            >
              {t('downloadCourses')}
            </Button>
          </Tooltip>
          <Tooltip title={filterIsEdited && t('submitFilterTooltip')}>
            <Button
              onClick={exportLogging}
              disabled={filterIsEdited}
              loading={isLoadingExport}
              type='primary'
              size='large'
              icon={<DownloadOutlined />}
            >
              {t('download')}
            </Button>
          </Tooltip>
        </Flex>
      )}

      <div className={css['Logging-table']}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
          onChange={onChangePagination}
          loading={isLoading}
        />
      </div>
    </Content>
  );
};

export default Logging;
