/* eslint-disable */
import { ERROR_CHANGE_SUCCESS } from './actions';

import { REST_API_ERROR } from '@shared/constants/rest-api';

const initialState = {
  error: '',
};

export default (state = initialState, { type, action }) => {
  switch (type) {
    case REST_API_ERROR:
      return { ...state, error: action };
    case ERROR_CHANGE_SUCCESS:
      return { ...state, error: '' };
    default:
      return state;
  }
};
