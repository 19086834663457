/* eslint-disable */
import React, { Component } from 'react';

import { Button, Input } from 'antd';

import Checkbox from '../Checkbox';

import DatePicker from '@shared/components/DatePicker';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

import Arrow from '@/assets/images/blue-arrow-up.svg';
import ExtLink from '@/assets/images/ext-linkl.svg';

const { TextArea } = Input;

export default class CalendarModalData extends Component {
  static propTypes = {
    dateEvent: PropTypes.string,
    disabledSelect: PropTypes.bool,
    eventTypeId: PropTypes.any,
    hasEvents: PropTypes.number,
    hasEventsFlag: PropTypes.any,
    headerFixToModal: PropTypes.bool,
    isLoadingPlan: PropTypes.bool,
    isUpdate: PropTypes.bool,
    noComment: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    openDate: PropTypes.any,
    planInfo: PropTypes.object,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    title: PropTypes.string,
    toPrev: PropTypes.func,
    topicInfo: PropTypes.object,
    open: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.currentYear = Number(dayjs().format('YYYY'));
    this.openDate = dayjs(props.openDate);
    this.state = {
      selectedYear: this.currentYear,
      noDate: false,
      selectedMonths: !props.disabledSelect ? [] : [dayjs(props.dateEvent).format('YYYY-MM-DD')],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({
        selectedYear: this.currentYear,
        comment: '',
        selectedMonths: !this.props.disabledSelect ? [] : [dayjs(this.props.dateEvent).format('YYYY-MM-DD')],
      });
    }
  }

  handleOkClick = () => {
    const { onOk, planningTopicId, planInfo } = this.props;
    if (onOk) {
      onOk(planningTopicId, this.state.selectedMonths, this.state.comment, this.state.noDate, planInfo);
    }
  };

  changeComment = event => {
    const text = event.target.value;
    this.setState({ comment: text });
  };

  handleDateClick = date => {
    const arr = [];
    arr.push(dayjs(date.value).format('YYYY-MM-DD'));
    this.setState({ selectedMonths: arr });
  };

  handleChangeCheckbox = () => {
    this.setState({
      noDate: !this.state.noDate,
      selectedMonths: [],
    });
  };

  renderPrev = (hasEventsFlag, hasEvents, toPrev) => {
    if (!toPrev) {
      return null;
    }
    if (!hasEvents || !hasEventsFlag) {
      return null;
    }
    return (
      <div className={css['Calendar-modal-toPrev']} onClick={() => toPrev({ hasEventsFlag: true })}>
        <img src={Arrow} alt='' /> Назад
      </div>
    );
  };

  render() {
    const { okText, eventTypeId, topicInfo, noComment, title, toPrev, hasEvents, isUpdate, hasEventsFlag } = this.props;
    const { noDate, selectedMonths, comment } = this.state;
    return (
      <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
        {this.renderPrev(hasEventsFlag, hasEvents, toPrev)}
        <div className={classNames(css['Calendar-modal-header'], css['Calendar-modal-header--ext'])}>
          <div>{title}</div>
        </div>
        <div className={css['Calendar-modal__row']}>
          <div className={css['Calendar-modal__row__label']}>Расписание</div>
          <div className={css['Calendar-modal__row__field']}>
            {topicInfo && topicInfo.link ? (
              <a href={topicInfo.link} target='_blank'>
                <img className={css['Calendar-modal__row__field__img']} src={ExtLink} alt='' />
                {topicInfo.link.length < 40 ? topicInfo.link : `${topicInfo.link.slice(0, 40)}...`}
              </a>
            ) : (
              'Не указано'
            )}
            <Checkbox
              className={css['Calendar-modal__row__field__checkbox']}
              onChange={this.handleChangeCheckbox}
              name='date'
              value={noDate}
              label='Расписание ещё неизвестно'
            />
          </div>
        </div>
        <div className={css['Calendar-modal__row']}>
          <div className={classNames(css['Calendar-modal__row__label'], css.paddingAlign)}>Дата</div>
          <div className={css['Calendar-modal__row__field']} style={{ maxWidth: `${130}px` }}>
            <DatePicker
              modificate
              readOnly
              monthCalendar={noDate}
              typeId={eventTypeId}
              value={selectedMonths[0]}
              placeholder={dayjs().format('DD.MM.YYYY')}
              onChange={this.handleDateClick}
              name='startDate'
              disabledAfterAndCurrent
            />
          </div>
        </div>
        {!noComment && (
          <div className={css['Calendar-modal__row']}>
            <div className={classNames(css['Calendar-modal__row__label'], css.paddingAlign)}>
              <div>Комментарий</div>
              {!isUpdate && <div className={css['Calendar-modal__row__label__info']}>Необязательно</div>}
            </div>
            <div className={css['Calendar-modal__row__field']}>
              <TextArea
                className={css['Comment-form-textarea']}
                placeholder='Дополнительная информация о внешнем курсе: время, тип обучения, преподаватель и т.д.'
                rows={3}
                onChange={this.changeComment}
                value={comment}
                maxLength={500}
              />
            </div>
          </div>
        )}
        <div className={css['Calendar-modal__row__btn-box']}>
          <Button
            disabled={
              this.props.isLoadingPlan || !selectedMonths || selectedMonths.length === 0 || (isUpdate && !comment)
            }
            className={css['Calendar-modal-btn__orange']}
            // orange
            type='primary'
            onClick={this.handleOkClick}
          >
            {okText}
          </Button>
        </div>
      </div>
    );
  }
}
