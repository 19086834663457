import React from 'react';

import { Popover, Typography } from 'antd';

import style from './style.module.scss';

const CustomList = ({ data, objectKey }) => {
  const MAX_VISIBLE_ITEMS = 3;

  // Получаем первые три элемента для отображения
  const visibleItems = data.slice(0, MAX_VISIBLE_ITEMS);
  const hiddenItems = data.length > MAX_VISIBLE_ITEMS ? data.slice(MAX_VISIBLE_ITEMS) : [];

  const displayText = visibleItems
    .map((item, index) => {
      if (index === visibleItems.length - 1 && hiddenItems.length > 0) {
        return `${item[objectKey]}...`;
      }
      return item[objectKey];
    })
    .join(', ');

  const popoverContent = (
    <div className={style['Items-list']}>
      {data?.map((item, idx) => (
        <Typography.Text key={idx}>{item[objectKey]}</Typography.Text>
      ))}
    </div>
  );

  return (
    <div>
      {hiddenItems.length > 0 ? (
        <Popover content={popoverContent}>
          <span className={style['Items-more']}>{displayText}</span>
        </Popover>
      ) : (
        <span>{displayText}</span>
      )}
    </div>
  );
};

export default CustomList;
