export const D360_CREATE_SCALE_START = 'D360_CREATE_SCALE_START';
export const D360_CREATE_SCALE_SUCCESS = 'D360_CREATE_SCALE_SUCCESS';
export const D360_CREATE_SCALE_FAIL = 'D360_CREATE_SCALE_FAIL';

export const D360_UPDATE_SCALE_START = 'D360_UPDATE_SCALE_START';
export const D360_UPDATE_SCALE_SUCCESS = 'D360_UPDATE_SCALE_SUCCESS';
export const D360_UPDATE_SCALE_FAIL = 'D360_UPDATE_SCALE_FAIL';

export const D360_COPY_SCALE_START = 'D360_COPY_SCALE_START';
export const D360_COPY_SCALE_SUCCESS = 'D360_COPY_SCALE_SUCCESS';
export const D360_COPY_SCALE_FAIL = 'D360_COPY_SCALE_FAIL';

export const D360_DELETE_SCALE_START = 'D360_DELETE_SCALE_START';
export const D360_DELETE_SCALE_SUCCESS = 'D360_DELETE_SCALE_SUCCESS';
export const D360_DELETE_SCALE_FAIL = 'D360_DELETE_SCALE_FAIL';

export const D360_GET_SCALE_START = 'D360_GET_SCALE_START';
export const D360_GET_SCALE_SUCCESS = 'D360_GET_SCALE_SUCCESS';
export const D360_GET_SCALE_FAIL = 'D360_GET_SCALE_FAIL';

export const D360_GET_SCALES_START = 'D360_GET_SCALES_START';
export const D360_GET_SCALES_SUCCESS = 'D360_GET_SCALES_SUCCESS';
export const D360_GET_SCALES_FAIL = 'D360_GET_SCALES_FAIL';

export const D360_SCALE_CAN_SKIP_START = 'D360_SCALE_CAN_SKIP_START';
export const D360_SCALE_CAN_SKIP_SUCCESS = 'D360_SCALE_CAN_SKIP_SUCCESS';
export const D360_SCALE_CAN_SKIP_FAIL = 'D360_SCALE_CAN_SKIP_FAIL';
