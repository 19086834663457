/* eslint-disable */
/*

 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Tag from '@/components/Tag';

import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

const Info = ({ fullDescription, skills }) => {
  const { t } = useTranslation('compilationHeader');
  return (
    <div className={css.Info}>
      {!!(skills && skills.length) && (
        <div className={css.Info__tags}>
          <h2 data-qa='adminCompilationDetailSkills'>{t('skills')}</h2>
          {skills.map(skill => (
            <Tag data-qa='adminCompilationDetailSkillsItem' orange noDel noUpper key={skill.id} tag={skill} />
          ))}
        </div>
      )}
      <div>
        <h2 data-qa='adminCompilationDetailLearnH2'>{t('learn')}</h2>
        <p className={css.Info__desc} data-qa='adminCompilationDetailFullDescription'>
          {fullDescription}
        </p>
      </div>
    </div>
  );
};

Info.propTypes = {
  fullDescription: PropTypes.string,
  skills: PropTypes.array,
};

export default Info;
