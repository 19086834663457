import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearOrderFilters, postOrdersFilters, saveOrderFilters } from '@/store/order/actions';
import { getProductsByName } from '@/store/product/actions';
import { getStudents } from '@/store/students/actions';

import { selectCompanies } from '@/store/companies/selectors';
import { selectOrder } from '@/store/order/selectors';
import { selectShop } from '@/store/product/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Col, Form, Row, Select } from 'antd';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../Orders.module.scss';

const { Option } = Select;

const Filters = ({ data, setData, setIsSort, setActiveSort, isSort }) => {
  const { t } = useTranslation('orders');
  const dispatch = useDispatch();

  const { subCompanies } = useSelector(selectCompanies);
  const { position, students } = useSelector(selectStudents);
  const { products, delivery } = useSelector(selectShop);
  const { status } = useSelector(selectOrder);

  useEffect(() => {
    dispatch(postOrdersFilters({}, 0));
  }, [dispatch]);

  const [sendData, setSendData] = useState({});

  useEffect(() => {
    // обрабатываю фильтр
    let copyData = _.cloneDeep(data);

    for (let key in copyData) {
      if (!copyData[key] || (copyData[key].hasOwnProperty('ids') && !copyData[key].ids)) {
        delete copyData[key];
      } else if (copyData[key]?.ids) {
        copyData[key] = copyData[key].ids;
      }

      if (Array.isArray(copyData[key]) && copyData[key].length === 0) {
        delete copyData[key];
      }
    }

    if (copyData.login) delete copyData.login;

    if (copyData?.status?.value) {
      copyData.status = copyData.status.value;
    } else {
      delete copyData.status;
    }
    setSendData(copyData);

    dispatch(saveOrderFilters(copyData));
  }, [data, dispatch]);

  const resetFilters = () => {
    setData({
      subCompanyName: '',
      deliveryIds: {
        ids: [],
        name: '',
      },
      positionName: '',
      productId: {
        ids: [],
        name: '',
      },
      userId: {
        ids: [],
        name: '',
      },
      login: '',
      status: {
        value: '',
        name: '',
      },
    });

    dispatch(clearOrderFilters({}));
    dispatch(postOrdersFilters({}, 0));

    setIsSort('');
    setActiveSort({ new: false, old: false });
  };

  const onSendFilters = () => {
    dispatch(postOrdersFilters(sendData, 0, isSort));
  };

  const renderOptions = arr => {
    let opt =
      arr?.length &&
      arr.map(item => {
        return (
          <Option value={item} key={item}>
            {item}
          </Option>
        );
      });

    return opt;
  };

  const optionStatus =
    status?.length &&
    status.map(item => {
      return (
        <Option value={item.name} key={item.value} name={item.value}>
          {item.name}
        </Option>
      );
    });

  const optionLogin =
    students?.length &&
    students.map(item => {
      return (
        <Option value={item.login} key={item.id}>
          {item.login}
        </Option>
      );
    });

  const optionUsers =
    students?.length &&
    students.map(item => {
      let username = (item.lastName || '') + ' ' + (item.firstName || '') + ' ' + (item.middleName || '');
      return (
        <Option value={item.id} key={item.id}>
          {username}
        </Option>
      );
    });

  const renderOptionsWithName = arr => {
    return (
      arr?.length &&
      arr.map(item => {
        return (
          <Option value={item.name} key={item.id}>
            {item.name}
          </Option>
        );
      })
    );
  };

  const handleGet = (e, func) => {
    if (e?.length > 2 || Object.values(e)?.[0].length > 2) {
      dispatch(func(e));
    }
  };

  const getDebounced = useCallback(_.debounce(handleGet, 1000), []);

  const tempalateFilters = [
    {
      col: [
        {
          label: t('company'),
          valueLabel: data.subCompanyName,
          onChange: name => setData({ ...data, subCompanyName: name }),
          span: 8,
          option: renderOptions(subCompanies),
          component: Select,
        },

        {
          label: t('position'),
          valueLabel: data.positionName || undefined,
          onChange: name => setData({ ...data, positionName: name }),
          span: 8,
          option: renderOptions(position),
          component: Select,
        },
        {
          label: t('searchName'),
          valueLabel: data.productId.name,
          onChange: (name, item) => setData({ ...data, productId: { ids: +item?.key, name } }),
          span: 8,
          option: renderOptionsWithName(products),
          onSearch: e => getDebounced(e, getProductsByName),
          component: Select,
        },
      ],
    },

    {
      type: 'flex',
      justify: 'start',
      classNameRow: css['Filters-block'],
      col: [
        {
          label: t('searcUser'),
          valueLabel: data.userId.name,
          onChange: (name, item) => setData({ ...data, userId: { ids: +item?.key, name } }),
          span: 8,
          option: optionUsers,
          onSearch: e => getDebounced({ fullName: e, hasProductOrder: true }, getStudents),
          component: Select,
        },
        {
          label: t('searcLogin'),
          valueLabel: data.login,
          onChange: (name, item) => setData({ ...data, login: name, userId: { ids: +item?.key } }),
          span: 8,
          option: optionLogin,
          onSearch: e => getDebounced({ login: e, hasProductOrder: true }, getStudents),
          component: Select,
        },
        {
          label: t('delivery'),
          valueLabel: data.deliveryIds.name || undefined,
          onChange: (name, item) => {
            let ids = item.map(i => +i.key);
            setData({ ...data, deliveryIds: { ids, name } });
          },
          span: 8,
          option: renderOptionsWithName(delivery),
          mode: 'multiple',
          component: Select,
        },
      ],
    },
    {
      type: 'flex',
      justify: 'start',
      classNameRow: css['Filters-block'],
      col: [
        {
          label: t('status'),
          valueLabel: data.status.name,
          onChange: (name, item) => setData({ ...data, status: { name, value: item?.key } }),
          span: 8,
          option: optionStatus,
          component: Select,
        },
        {
          label: t('apply'),
          span: 16,
          component: Button,
          onClick: onSendFilters,
          className: css['Filters-apply-btn'],
        },
      ],
    },
  ];

  const renderFilters = tempalateFilters.map((item, index) => {
    const customRow = item.col.map((col, index) => {
      const MY_COMP = col.component;
      return (
        <Col span={col.span} key={index} className={Button && css['Filters-block-colBtn']}>
          {MY_COMP !== Button ? (
            <Form layout='vertical'>
              <Form.Item label={col.label} colon={false}>
                <Select
                  onChange={col.onChange}
                  onSearch={col.onSearch}
                  className={col.className}
                  notFoundContent={t('noData')}
                  value={col.valueLabel}
                  mode={col.mode}
                  allowClear
                  showSearch
                >
                  {col.option}
                </Select>
              </Form.Item>
            </Form>
          ) : (
            <div className={css['Filters-apply']}>
              <MY_COMP className={col.className} onClick={col.onClick} data-qa='adminOrdersFiltersApply' type='primary'>
                {MY_COMP !== Button ? col.option : col.label}
              </MY_COMP>
            </div>
          )}
        </Col>
      );
    });

    return (
      <Row type={item.type} justify={item.justify} className={item.classNameRow} key={index} gutter={[16, 0]}>
        {customRow}
      </Row>
    );
  });

  return (
    <div className={css['Filters']}>
      <div className={css['Filters-buttons']}>
        <span className={css['Filters-buttons-filters']}>{t('filters')}</span>
        <Button type='link' onClick={resetFilters}>
          {t('reset')}
        </Button>
      </div>
      {renderFilters}
    </div>
  );
};

Filters.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.object,
  setIsSort: PropTypes.func,
  setActiveSort: PropTypes.func,
  isSort: PropTypes.string,
};

export default Filters;
