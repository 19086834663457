import {
  CHANGE_STATUS_FAIL,
  CHANGE_STATUS_START,
  CHANGE_STATUS_SUCCESS,
  CLEAR_MENTEE_FILTERS,
  EXPORT_MENTEE_FAIL,
  EXPORT_MENTEE_START,
  EXPORT_MENTEE_SUCCESS,
  GET_MENTEE_FAIL,
  GET_MENTEE_START,
  GET_MENTEE_SUCCESS,
  GET_STATUS_FAIL,
  GET_STATUS_START,
  GET_STATUS_SUCCESS,
  POST_MENTEE_FILTERS_FAIL,
  POST_MENTEE_FILTERS_START,
  POST_MENTEE_FILTERS_SUCCESS,
  SAVE_MENTEE_FILTERS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const saveMenteeFilters = value => ({
  type: SAVE_MENTEE_FILTERS,
  value,
});

export const clearMenteeFilters = value => ({
  type: CLEAR_MENTEE_FILTERS,
  value,
});

export const getMentee = (fullName, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentee/admin/name${getQueryString({ fullName })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTEE_START, GET_MENTEE_SUCCESS, GET_MENTEE_FAIL],
  onSuccess,
  onError,
});

export const getStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentee/admin/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_STATUS_START, GET_STATUS_SUCCESS, GET_STATUS_FAIL],
  onSuccess,
  onError,
});

export const postMenteeFilters = (params, page, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentee/admin${getQueryString({ size: 20, page })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_MENTEE_FILTERS_START, POST_MENTEE_FILTERS_SUCCESS, POST_MENTEE_FILTERS_FAIL],
  onSuccess,
  onError,
});

export const changeMenteeStatus = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentee/admin/status/update`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CHANGE_STATUS_START, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAIL],
  onSuccess,
  onError,
});

export const exportMentee = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentee/admin/export`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  responseType: 'blob',
  types: [EXPORT_MENTEE_START, EXPORT_MENTEE_SUCCESS, EXPORT_MENTEE_FAIL],
  onSuccess,
  onError,
});
