// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-dSV4mf.admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-r8tdU4 .admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-label-BnI9DJ[data-value="Montserrat"]:before, .admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-dSV4mf.admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-r8tdU4 .admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-item-DFrlBl[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-Montserrat-mDFqxc {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-pages-ProfileSettings-ProfileSettings-module__input-pTN8co {
  width: 400px;
}

.admin-src-pages-ProfileSettings-ProfileSettings-module__Label-card-n2znCQ {
  margin-top: 32px !important;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/ProfileSettings/ProfileSettings.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,YAAY;AACd;;AAQA;EALE,2BAA2B;AAC7B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.input {\n  width: 400px;\n}\n\n.Label-card {\n  margin-top: 32px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-dSV4mf`,
	"qlPicker": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-dSV4mf`,
	"ql-font": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-r8tdU4`,
	"qlFont": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-r8tdU4`,
	"ql-picker-label": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-label-BnI9DJ`,
	"qlPickerLabel": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-label-BnI9DJ`,
	"ql-picker-item": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-item-DFrlBl`,
	"qlPickerItem": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-picker-item-DFrlBl`,
	"ql-font-Montserrat": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-Montserrat-mDFqxc`,
	"qlFontMontserrat": `admin-src-pages-ProfileSettings-ProfileSettings-module__ql-font-Montserrat-mDFqxc`,
	"input": `admin-src-pages-ProfileSettings-ProfileSettings-module__input-pTN8co`,
	"Label-card": `admin-src-pages-ProfileSettings-ProfileSettings-module__Label-card-n2znCQ`,
	"labelCard": `admin-src-pages-ProfileSettings-ProfileSettings-module__Label-card-n2znCQ`
};
export default ___CSS_LOADER_EXPORT___;
