export const GET_RANGE_COST_START = 'GET_RANGE_COST_START';
export const GET_RANGE_COST_SUCCESS = 'GET_RANGE_COST_SUCCESS';
export const GET_RANGE_COST_FAIL = 'GET_RANGE_COST_FAIL';

export const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const GET_DELIVERY_START = 'GET_DELIVERY_START';
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS';
export const GET_DELIVERY_FAIL = 'GET_DELIVERY_FAIL';

export const POST_PRODUCTS_FILTERS_START = 'POST_PRODUCTS_FILTERS_START';
export const POST_PRODUCTS_FILTERS_SUCCESS = 'POST_PRODUCTS_FILTERS_SUCCESS';
export const POST_PRODUCTS_FILTERS_FAIL = 'POST_PRODUCTS_FILTERS_FAIL';

export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const CHANGE_STATUS_PRODUCT_START = 'CHANGE_STATUS_PRODUCT_START';
export const CHANGE_STATUS_PRODUCT_SUCCESS = 'CHANGE_STATUS_PRODUCT_SUCCESS';
export const CHANGE_STATUS_PRODUCT_FAIL = 'CHANGE_STATUS_PRODUCT_FAIL';

export const POST_PRODUCTS_VISIBILITY_START = 'POST_PRODUCTS_VISIBILITY_START';
export const POST_PRODUCTS_VISIBILITY_SUCCESS = 'POST_PRODUCTS_VISIBILITY_SUCCESS';
export const POST_PRODUCTS_VISIBILITY_FAIL = 'POST_PRODUCTS_VISIBILITY_FAIL';

export const GET_PRODUCTS_VISIBILITY_START = 'GET_PRODUCTS_VISIBILITY_START';
export const GET_PRODUCTS_VISIBILITY_SUCCESS = 'GET_PRODUCTS_VISIBILITY_SUCCESS';
export const GET_PRODUCTS_VISIBILITY_FAIL = 'GET_PRODUCTS_VISIBILITY_FAIL';

export const EXPORT_PRODUCTS_START = 'EXPORT_PRODUCTS_START';
export const EXPORT_PRODUCTS_SUCCESS = 'EXPORT_PRODUCTS_SUCCESS';
export const EXPORT_PRODUCTS_FAIL = 'EXPORT_PRODUCTS_FAIL';

export const SAVE_SHOP_FILTERS = 'SAVE_SHOP_FILTERS';

export const CLEAR_SHOP_FILTERS = 'CLEAR_SHOP_FILTERS';

export const CLEAR_PRODUCTS_VISIBILITY = 'CLEAR_PRODUCTS_VISIBILITY';
