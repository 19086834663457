import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createMentor, getLimitSettings, postMentorsFilters } from '@/store/mentors/actions';

import { selectCompetence } from '@/store/competence/selectors';
import { selectMentors } from '@/store/mentors/selectors';

import { Button, Col, Input, message, Modal, Row, Select } from 'antd';
import UsersAssign from '@/components/UsersAssign';

import PropTypes from 'prop-types';

import css from '../../Mentors.module.scss';

const { TextArea } = Input;
const { Option } = Select;

const ModalAddMentor = props => {
  const { t } = useTranslation('mentors');

  // store
  const dispatch = useDispatch();
  const { competence } = useSelector(selectCompetence); // default size: 1000
  const { mentorsFilters, mentorsCurrentPage } = useSelector(selectMentors);
  const { limit } = useSelector(selectMentors);
  // ------------------------------------------
  const [data, setData] = useState({ userId: null, competenceIds: [], personalInfo: '', photoUuid: '' });
  const [selectUserStep, setSelectUserStep] = useState(true);
  //-------------------------------------------

  useEffect(() => {
    dispatch(getLimitSettings());
  }, []);

  const handleSelect = competenceIds => {
    if (competenceIds.length <= limit.maxMentorCompetences) {
      setData({ ...data, competenceIds });
    } else {
      message.error(t('errorComp'));
      return;
    }
  };

  const createSuccess = () => {
    message.success(t('mentorCreate'));
    props.onCancel();
    dispatch(postMentorsFilters(mentorsFilters.filters ? mentorsFilters.filters : {}, mentorsCurrentPage));
  };

  const createError = () => {
    message.error(t('errorMentor'));
  };

  const createMentorSubmit = () => {
    dispatch(createMentor(data, createSuccess, createError));
  };

  // ------------------------------

  const disabledSubmit = !data.userId || !data.competenceIds.length || !data.personalInfo;
  const footerModal = selectUserStep ? (
    <Button disabled={!data?.userId} onClick={() => setSelectUserStep(false)} size='large' type='primary'>
      {t('next')}
    </Button>
  ) : (
    <>
      <Button
        onClick={() => {
          setSelectUserStep(true);
          setData({ ...data, competenceIds: [], personalInfo: '' });
        }}
        size='large'
      >
        {t('back')}
      </Button>
      {/* // шаг назад, сброс полей на 2 шаге */}
      <Button disabled={disabledSubmit} onClick={() => createMentorSubmit()} size='large' type='primary'>
        {t('addMentorPostBtn')}
      </Button>
    </>
  );

  const competenceOptions =
    competence &&
    competence.map(c => {
      return (
        <Option key={c.id} value={c.id}>
          {c.name}
        </Option>
      );
    });

  const selectUserContent = (
    <UsersAssign
      textBlock={[t('isMentor')]}
      title={t('addMentor')}
      canMentor
      ids={data.userId}
      setData={items => {
        const mentor = items?.find(i => i.id);
        setData({ ...data, userId: mentor?.id ?? null });
      }}
    />
  );

  const submitUserContent = (
    <Row gutter={[0, 16]}>
      <div className={css['ModalAddMentor-selectUser-choose']}>
        {t('selectCompetencies', { total: data.competenceIds.length })}
      </div>
      <Col span={24}>
        <Select
          mode='multiple'
          name='competenceIds'
          placeholder={t('competencies', { total: limit.maxMentorCompetences })}
          onChange={handleSelect}
          filterOption={(input, option) => option.props.children.includes(input)}
          size='large'
          notFoundContent={t('noData')}
          allowClear
          value={data.competenceIds}
          className={css['ModalAddMentor-selectUser-customSelect']}
          data-qa='adminMentorAddMentorCompetenceSelect'
        >
          {competenceOptions}
        </Select>
      </Col>
      <Col span={24}>
        <TextArea
          maxLength={1500}
          rows={10}
          placeholder={t('aboutText')}
          value={data.personalInfo}
          onChange={e => setData({ ...data, personalInfo: e?.target?.value })}
          data-qa='adminMentorAddMentorAboutTextArea'
        />
      </Col>
    </Row>
  );

  const modalContent = selectUserStep ? selectUserContent : submitUserContent;

  return (
    <Modal
      className={css['ModalAddMentor']}
      open={props.open}
      onCancel={props.onCancel}
      footer={footerModal}
      width={750}
      destroyOnClose
    >
      {modalContent}
    </Modal>
  );
};

ModalAddMentor.propTypes = {
  onCancel: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalAddMentor;
