import { DELETED, DISABLED } from '@shared/constants/topic-statuses';

const topicTypes = t => [
  { key: 1, value: 'EXTERNAL', label: t('external') },
  { key: 2, value: 'INTERNAL', label: t('internal') },
  { key: 3, value: 'UNMODERATE', label: t('unmoderate') },
];

const topicStatus = t => [
  { key: 1, value: DELETED, label: t('deleted') },
  { key: 2, value: DISABLED, label: t('inactive') },
];

const topicMaterial = t => [
  { key: 1, value: 'false', label: t('company') },
  { key: 2, value: 'true', label: t('general') },
];

const topicVisibility = t => [
  { key: 1, value: 'true', label: t('visible') },
  { key: 2, value: 'false', label: t('notVisible') },
];

const topicApprove = t => [
  { key: 1, value: 'true', label: t('yes') },
  { key: 2, value: 'false', label: t('no') },
];

const topicEvents = t => [
  { key: 1, value: 'true', label: t('yes') },
  { key: 2, value: 'false', label: t('no') },
];

const topicTrajectory = t => [
  { key: 1, label: t('withoutPDP'), value: 'NONE' },
  { key: 2, label: 'Soft', value: 'SOFT' },
  { key: 3, label: 'Hard', value: 'HARD' },
];

export const filters = (
  t,
  topicTypeLabels,
  topicTags,
  topicSkills,
  topicAuthors,
  // topicCategories,
  studentsOptions,
  changeTypeSearch
) => [
  { label: t('labelFilters.0'), placeholder: t('placeholderFilters.0'), name: 'name', inputs: 'input' },
  {
    label: t('labelFilters.1'),
    placeholder: t('placeholderFilters.1'),
    mode: 'multiple',
    name: 'topicTypeLabelId',
    inputs: 'select',
    options: topicTypeLabels,
  },
  {
    label: t('labelFilters.2'),
    placeholder: t('placeholderFilters.1'),
    name: 'hasApprove',
    inputs: 'select',
    options: topicApprove(t),
  },
  {
    label: t('labelFilters.3'),
    placeholder: t('placeholderFilters.1'),
    name: 'hasEvent',
    inputs: 'select',
    options: topicEvents(t),
  },
  {
    label: t('labelFilters.4'),
    placeholder: t('placeholderFilters.1'),
    name: 'isGlobal',
    inputs: 'select',
    options: topicMaterial(t),
  },
  {
    label: t('labelFilters.5'),
    placeholder: t('placeholderFilters.1'),
    name: 'isVisible',
    inputs: 'select',
    options: topicVisibility(t),
  },
  {
    label: t('labelFilters.6'),
    placeholder: t('placeholderFilters.1'),
    name: 'status',
    inputs: 'select',
    options: topicStatus(t),
  },
  {
    label: t('labelFilters.7'),
    placeholder: t('placeholderFilters.1'),
    name: 'scope',
    inputs: 'select',
    options: topicTypes(t),
  },
  {
    label: t('labelFilters.8'),
    placeholder: t('placeholderFilters.1'),
    name: 'personal',
    inputs: 'select',
    options: topicTrajectory(t),
  },
  {
    label: t('labelFilters.9'),
    placeholder: t('placeholderFilters.2'),
    min: 0,
    name: 'ids',
    inputs: 'input',
    type: 'number',
  },
  {
    label: t('labelFilters.13'),
    placeholder: t('placeholderFilters.3'),
    mode: 'multiple',
    name: 'tagIds',
    inputs: 'select',
    options: topicTags,
    showSearch: true,
    optionFilterProp: 'label',
    onSearch: e => changeTypeSearch(e, 'tagIds'),
  },
  {
    label: t('labelFilters.14'),
    placeholder: t('placeholderFilters.4'),
    mode: 'multiple',
    name: 'skillIds',
    inputs: 'select',
    options: topicSkills,
    showSearch: true,
    optionFilterProp: 'label',
    onSearch: e => changeTypeSearch(e, 'skillIds'),
  },
  {
    label: t('labelFilters.15'),
    placeholder: t('placeholderFilters.5'),
    mode: 'multiple',
    name: 'authorIds',
    inputs: 'select',
    options: topicAuthors,
    showSearch: true,
    optionFilterProp: 'label',
    onSearch: e => changeTypeSearch(e, 'authorIds'),
  },
  // {
  //   label: t('labelFilters.16'),
  //   placeholder: t('placeholderFilters.6'),
  //   mode: 'multiple',
  //   name: 'topicCategoryIds',
  //   inputs: 'select',
  //   options: topicCategories,
  //   showSearch: true,
  //   optionFilterProp: 'label',
  // },
  {
    label: t('labelFilters.17'),
    placeholder: t('placeholderFilters.7'),
    name: 'creBy',
    inputs: 'select',
    options: studentsOptions,
    showSearch: true,
    optionFilterProp: 'label',
    onSearch: e => changeTypeSearch(e, 'creBy'),
  },
  { label: t('labelFilters.10'), name: 'pinned', inputs: 'checkbox' },
  { label: t('labelFilters.11'), name: 'hasBlock', inputs: 'checkbox' },
];
