/* eslint-disable */
import { Component } from 'react';
import { connect } from 'react-redux';

import { getUserStat } from '@/store/users/actions';

import { Table } from 'antd';

import PropTypes from 'prop-types';

const { Column } = Table;

export class CompetenceList extends Component {
  static propTypes = {
    getUserStat: PropTypes.func,
    onSelectCompetence: PropTypes.func,
    match: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hasErrors: false,
      competences: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true, hasError: false });
    this.props.getUserStat(
      this.props.match.params.id,
      res => {
        if (res && res.user) {
          this.loadCompetencies(res.user.id);
        }
      },
      err => {
        this.setState({ isLoading: false, hasErrors: true });
        console.error('error:', err);
      }
    );
  }

  loadCompetencies = uid => {};

  getTableData = () => {
    const [competences] = this.state;
    let data = [];

    if (Array.isArray(competences) && competences.length) {
      for (let i = 0; i < competences.length; i++) {
        let competence = competences[i];

        if (typeof competence === 'object') {
          let object = {
            key: competence.id,
            name: competence.name,
            skills: competence.skills || [],
            isHardSkill: competence.isHardSkill || false,
          };

          data.push(object);
        }
      }
    }

    return data;
  };

  handleChange = row => {
    if (typeof row === 'object' && typeof this.props.onSelectCompetence === 'function') {
      this.props.onSelectCompetence(row);
    }
  };

  render() {
    const { isLoading, competences } = this.state;
    const { t } = this.props;

    return (
      <>
        <Table
          ellipsis={true}
          bordered={false}
          style={{ width: '100%' }}
          loading={isLoading}
          dataSource={competences}
          pagination={{
            pageSize: 6,
            style: { padding: '1rem' },
          }}
          onSelect={this.handleChange}
          rowSelection={{
            type: 'radio',
            onSelect: this.handleChange,
          }}
        >
          <Column title={t('competence')} dataIndex='name' key='competencies' filterSearch={false} />
        </Table>
      </>
    );
  }
}

const mapActionsToProps = { getUserStat };

export default connect(null, mapActionsToProps)(CompetenceList);
