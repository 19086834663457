import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360Upload } from '@/store/d360/d360_import/actions';

import { Alert, Button, Col, Modal, Result, Row, Spin } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

import { API_D360_URL } from '@/store/d360/service_api';
import _ from 'lodash';
import PropTypes from 'prop-types';

const footerStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const footerBtn = {
  marginRight: '10px',
};

export const ImportModal = props => {
  const { t, i18n } = useTranslation('D360_Import');
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [csvFile, setCsvFile] = useState(null);
  const { uploading } = useSelector(state => state.d360Import);
  const title = (
    <>
      <FileExcelOutlined />
      &nbsp;{t('title')}
    </>
  );

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!_.isNil(csvFile)) {
      const formData = new FormData();
      formData.append('file', csvFile);
      formData.append('project_id', props.projectId);
      dispatch(
        D360Upload(formData, () => {
          setSuccess(true);
        })
      );
    }
  }, [csvFile]);

  const footerImportFile = (
    <div style={footerStyles}>
      <div>
        <Button style={footerBtn} type='primary' onClick={() => inputRef.current.click()} disabled={uploading}>
          {t('importButton')}
        </Button>
        <Button onClick={() => props?.onCancel()} disabled={uploading}>
          {t('cancelButton')}
        </Button>
      </div>
      <div>
        <a href={`${API_D360_URL}/import?lang=${i18n.language}`}>{t('downloadButton')}</a>
      </div>
    </div>
  );

  const renderImportTable = (
    <>
      <Row>
        <Col span={24} align='center'>
          <Spin size='large' style={{ marginTop: 30, marginBottom: 30 }} />
          <h2>Загрузка файла на сервер</h2>
          <p>Пожалуйста, подождите...</p>
        </Col>
      </Row>
    </>
  );

  const uploadFile = e => {
    if (!_.isNil(e) && _.isObject(e) && _.has(e, 'target')) {
      if (_.isObject(e.target.files) && e.target.files.length) {
        setCsvFile(e.target.files[0]);
        e.target.value = null;
      }
    }
  };

  const renderImportFile = (
    <>
      <div style={{ filter: uploading === true ? 'opacity(0.2)' : 'opacity(1)' }}>
        <p>{t('content')}</p>
        <Alert style={{ marginTop: '1rem' }} type='info' message={t('notice')} showIcon />
        <input ref={inputRef} type='file' name='file' accept='.csv' onChange={uploadFile} hidden />
      </div>
    </>
  );

  const footerImportSuccess = (
    <Button type='primary' onClick={() => props?.onCancel()}>
      {t('finishButton')}
    </Button>
  );

  const renderImportSuccess = <Result status='success' title={t('successTitle')} subTitle={t('successContent')} />;

  const render = () => {
    return (
      <Modal
        title={title}
        footer={!success ? (!csvFile ? footerImportFile : null) : footerImportSuccess}
        onCancel={() => (typeof props?.onCancel === 'function' ? props.onCancel() : null)}
        maskClosable={false}
        width={600}
        destroyOnClose
        mask
        open
        centered
        closable
      >
        {!success ? (!csvFile ? renderImportFile : renderImportTable) : renderImportSuccess}
      </Modal>
    );
  };

  return render();
};

ImportModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default ImportModal;
