import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createPokaApprover, loadPokaApprovers } from '@/store/poka_approvers/actions';
import { getPokaDirCategory } from '@/store/poka_categories/actions';
import { getPokaDirSite } from '@/store/poka_sites/actions';
import { clearStudents, getPosition } from '@/store/students/actions';

import { selectPokaApprovers } from '@/store/poka_approvers/selectors';
import { selectPokaCategories } from '@/store/poka_categories/selectors';
import { selectPokaSites } from '@/store/poka_sites/selectors';
import { selectStudents } from '@/store/students/selectors';

import { Button, Layout, message, Typography } from 'antd';
import { Content } from '@/components/Layout';

import { DEFAULT_PARAMS } from './constants';
import FilterApproversDirectory from './Content/FilterApproversDirectory';
import ModalApproversDirectory from './Content/ModalApproversDirectory';
import TableApproversDirectory from './Content/TableApproversDirectory';

import css from './ApproversDirectory.module.scss';

const { Title } = Typography;

const ApproversDirectory = () => {
  const { t } = useTranslation('ApproversDirectory');
  const dispatch = useDispatch();

  const { position } = useSelector(selectStudents);
  const { pokaCategories } = useSelector(selectPokaCategories);
  const { pokaApproversCurrentPage, pokaApproversSort, pokaApproversFilters, pokaApprovers } =
    useSelector(selectPokaApprovers);
  const { pokaSites } = useSelector(selectPokaSites);

  const [isModal, setIsModal] = useState(false);

  const onCreateApprover = data => {
    if (data?.userId) {
      const queryParams = { size: DEFAULT_PARAMS.size, page: pokaApproversCurrentPage, sort: pokaApproversSort };
      dispatch(
        createPokaApprover(data, () => {
          setIsModal(false);
          dispatch(loadPokaApprovers(queryParams, pokaApproversFilters));
        })
      );
    } else {
      message.warning(t('warningCreateApprover'));
    }
  };

  useEffect(() => {
    if (!position?.length) dispatch(getPosition());
    if (!pokaCategories?.length) dispatch(getPokaDirCategory());
    if (!pokaApprovers?.length) dispatch(loadPokaApprovers(DEFAULT_PARAMS));
    if (!pokaSites?.length) dispatch(getPokaDirSite());

    return () => dispatch(clearStudents());
  }, []);

  return (
    <Layout>
      <Content>
        <div className={css['ApproversDirectory']}>
          <div className={css['ApproversDirectory-header']}>
            <Title>{t('title')}</Title>
            <div>
              <Button
                onClick={() => {
                  dispatch(clearStudents());
                  setIsModal(true);
                }}
                size='large'
                type='primary'
              >
                {t('addApprover')}
              </Button>
            </div>
          </div>

          <FilterApproversDirectory />

          <TableApproversDirectory />
        </div>
      </Content>

      {isModal && (
        <ModalApproversDirectory
          open={isModal}
          onCancel={() => setIsModal(false)}
          title={t('createTitle')}
          onOk={onCreateApprover}
          onOkText={t('createBtn')}
        />
      )}
    </Layout>
  );
};

export default ApproversDirectory;
