/* eslint-disable */

/* шаблоны сертификатов  */
import {
  CLEAR_CERTIFICATES,
  CREATE_CERTIFICATE_FAIL,
  CREATE_CERTIFICATE_START,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_START,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_CERTIFICATE_FAIL,
  DELETE_CERTIFICATE_START,
  DELETE_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_FAIL,
  EDIT_CERTIFICATE_START,
  EDIT_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_FAIL,
  GET_CERTIFICATE_START,
  GET_CERTIFICATE_SUCCESS,
  GET_TEMPLATES_FAIL,
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
} from './constants';

const initialState = {
  templates: [],
  certificates: [],
  total: null,
  pageTotal: null,
  pageSize: null,
  pageNumber: null,
  isLoading: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  isLoadingTemplate: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_CERTIFICATE_START:
      return {
        ...state,
        certificates: [],
        total: null,
        pageSize: null,
        pageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificates: response,
        total: Number(headers.get('X-Total-Elements')) || 0,
        pageSize: Number(headers.get('X-Page-Size')) || 0,
        pageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_CERTIFICATE_FAIL:
    case CREATE_CERTIFICATE_FAIL:
    case CREATE_TEMPLATE_FAIL:
    case EDIT_CERTIFICATE_FAIL:
    case DELETE_CERTIFICATE_FAIL:
    case GET_TEMPLATES_FAIL:
      return { ...state, error: true, isLoadingTemplate: false };

    case CREATE_CERTIFICATE_START:
    case DELETE_CERTIFICATE_START:
      return { ...state, isLoading: true, error: false };

    case CREATE_CERTIFICATE_SUCCESS:
    case CREATE_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case EDIT_CERTIFICATE_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_CERTIFICATE_SUCCESS:
    case DELETE_CERTIFICATE_SUCCESS:
      return { ...state, isLoading: false };

    case GET_TEMPLATES_START:
    case CREATE_TEMPLATE_START:
      return { ...state, isLoadingTemplate: true };

    case GET_TEMPLATES_SUCCESS:
      return { ...state, templates: response, isLoadingTemplate: false, error: false };

    case CLEAR_CERTIFICATES:
      return { ...state, certificates: [] };

    default:
      return state;
  }
};
