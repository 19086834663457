/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Badge, Checkbox, Tooltip } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Checkbox.module.scss';

class CustomCheckbox extends Component {
  static propTypes = {
    badgeCount: PropTypes.number,
    className: PropTypes.string,
    dark: PropTypes.bool,
    label: PropTypes.string,
    light: PropTypes.bool,
    modalCategory: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    smallFont: PropTypes.bool,
    textValue: PropTypes.bool,
    value: PropTypes.any,
    textLabel: PropTypes.string,
    errorsQiwiLibrary: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && !this.props.errorsQiwiLibrary) {
      this.setState({ value: this.props.value || false });
    }
  }

  handleChange = event => {
    const { onChange, name, errorsQiwiLibrary } = this.props;
    const value = event.target.checked;

    if (!errorsQiwiLibrary) {
      this.setState({ value });
    }

    onChange({ name, value });
  };

  render() {
    const { className, textValue, label, light, dark, smallFont, modalCategory, textLabel, t, ...rest } = this.props;
    const valueLabel = textLabel ? textLabel : this.state.value ? t('yes') : t('no');
    const valueLabelSpan = textValue ? (
      <span className={classNames(css['Checkbox-value-label'], className)}>{valueLabel}</span>
    ) : null;
    const labelSpan = label ? <span className={css['Checkbox-label']}>{label}</span> : null;
    return (
      <div className={css['Checkbox-wrapper']}>
        <Checkbox
          // className={classNames(css.Checkbox, className, {
          //   [css['Checkbox-light']]: light,
          //   [css.dark]: dark,
          //   [css.modalCategory]: modalCategory,
          //   [css.smallFont]: smallFont,
          //   [css.disable]: rest.disabled,
          // })}
          disabled={this.props.disabled}
          data-qa={this.props['data-qa']}
          name={this.props.name}
          onChange={this.handleChange}
          checked={this.state.value}
        >
          <div className={css['Checkbox-container']}>
            {label?.length > 20 ? <Tooltip title={label}>{labelSpan}</Tooltip> : labelSpan}
            {this.props.badgeCount ? (
              <Badge className={css['Checkbox-badge']} count={this.props.badgeCount} showZero />
            ) : null}
          </div>
        </Checkbox>
        {valueLabelSpan}
      </div>
    );
  }
}

export default withTranslation('customCheckbox')(CustomCheckbox);
