import { Tag } from 'antd';

import { IPR, STATUS_ACTIVE, TRACK } from './constants';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

const RenderTableStatus = ({ status, text }) => {
  switch (status) {
    case STATUS_ACTIVE:
    case TRACK.IN_PROGRESS:
      return <Tag color='blue'>{text}</Tag>;

    case IPR.APPROVED_BY_USER:
      return (
        <Tag color='warning' className={css['Status-tag']}>
          {text}
        </Tag>
      );

    case IPR.APPROVED_BY_ADMIN:
    case IPR.APPROVED_BY_MANAGER:
      return (
        <Tag color='processing' className={css['Status-tag']}>
          {text}
        </Tag>
      );

    case IPR.REJECTED:
    case TRACK.EXPIRED:
      return (
        <Tag color='error' className={css['Status-tag']}>
          {text}
        </Tag>
      );

    case IPR.FINISHED:
    case TRACK.FINISHED:
      return (
        <Tag color='success' className={css['Status-tag']}>
          {text}
        </Tag>
      );

    default:
      return <Tag>{text}</Tag>;
  }
};

RenderTableStatus.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
};

export default RenderTableStatus;
