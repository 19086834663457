import {
  DELETE_TYPEFORM_FAIL,
  DELETE_TYPEFORM_START,
  DELETE_TYPEFORM_SUCCESS,
  GET_TYPEFORM_ANSWERS_FAIL,
  GET_TYPEFORM_ANSWERS_START,
  GET_TYPEFORM_ANSWERS_SUCCESS,
  GET_TYPEFORM_EXPORT_FAIL,
  GET_TYPEFORM_EXPORT_START,
  GET_TYPEFORM_EXPORT_SUCCESS,
  GET_TYPEFORM_FAIL,
  GET_TYPEFORM_START,
  GET_TYPEFORM_SUCCESS,
  GET_TYPEFORMS_FAIL,
  GET_TYPEFORMS_START,
  GET_TYPEFORMS_SUCCESS,
  POST_TYPEFORM_ANSWERS_FAIL,
  POST_TYPEFORM_ANSWERS_START,
  POST_TYPEFORM_ANSWERS_SUCCESS,
  POST_TYPEFORM_FAIL,
  POST_TYPEFORM_START,
  POST_TYPEFORM_SUCCESS,
  UPDATE_TYPEFORM_FAIL,
  UPDATE_TYPEFORM_START,
  UPDATE_TYPEFORM_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  typeforms: [],
  answers: {
    answersResponse: null,
    form: null,
  },
  answersTotal: null,
  sizeTotal: null,
  answersPage: null,
};

/* eslint-disable */
export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    // START
    case GET_TYPEFORMS_START:
    case GET_TYPEFORM_START:
    case POST_TYPEFORM_START:
    case DELETE_TYPEFORM_START:
    case UPDATE_TYPEFORM_START:
    case POST_TYPEFORM_ANSWERS_START:
    case GET_TYPEFORM_EXPORT_START:
      return { ...state, loading: true };
    case GET_TYPEFORM_ANSWERS_START:
      return { ...state, loading: true, answersTotal: null, answersSize: null, answersPage: null };

    // FAIL
    case GET_TYPEFORMS_FAIL:
    case GET_TYPEFORM_FAIL:
    case POST_TYPEFORM_FAIL:
    case DELETE_TYPEFORM_FAIL:
    case UPDATE_TYPEFORM_FAIL:
    case GET_TYPEFORM_ANSWERS_FAIL:
    case POST_TYPEFORM_ANSWERS_FAIL:
    case GET_TYPEFORM_EXPORT_FAIL:
      return { ...state, loading: false };

    //  SUCCESS

    case GET_TYPEFORMS_SUCCESS:
      return {
        ...state,
        loading: false,
        typeforms: response.content || [], // typeforms.content[]
      };

    case GET_TYPEFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        typeforms: response,
      };

    case POST_TYPEFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        typeforms: state.typeforms.concat(response), // typeforms={}
      };

    case DELETE_TYPEFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        typeforms: state.typeforms.filter(typeform => typeform.id !== response.id),
      };

    case UPDATE_TYPEFORM_SUCCESS:
      return {
        ...state,
        loading: false,
        typeforms: response,
      };

    case GET_TYPEFORM_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        answers: response,
        answersTotal: Number(headers.get('X-Total-Elements')) || response.answersResponse.totalElements || 0,
        answersSize: Number(headers.get('X-Total-Elements')) || response.answersResponse.size || 0,
        answersPage: Number(headers.get('X-Total-Elements')) || response.answersResponse.totalPages || 0,
      };

    case POST_TYPEFORM_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case GET_TYPEFORM_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        link: response,
      };

    default:
      return state;
  }
};
