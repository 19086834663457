/* eslint-disable */
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import PropTypes from 'prop-types';

import css from './TopicThumbnail.module.scss';

export class TopicThumbnail extends Component {
  static propTypes = {
    thumbnailFileUuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: '',
    };
  }

  componentDidMount() {
    if (this.props.thumbnailFileUuid && this.props.thumbnailFileUuid.length > 0) {
      this.props.getFile(this.props.thumbnailFileUuid, res => {
        this.setState({
          imageSrc: URL.createObjectURL(res),
        });
      });
    }
  }

  render() {
    return <img src={this.state.imageSrc} className={css.topicThumbnail} alt='' />;
  }
}

const mapActionsToProps = {
  getFile,
};

export default connect(null, mapActionsToProps)(TopicThumbnail);
