import {
  DELETE_MASSFILTER_FAIL,
  DELETE_MASSFILTER_START,
  DELETE_MASSFILTER_SUCCESS,
  DISABLE_MASSFILTER_FAIL,
  DISABLE_MASSFILTER_START,
  DISABLE_MASSFILTER_SUCCESS,
  ENABLE_MASSFILTER_FAIL,
  ENABLE_MASSFILTER_START,
  ENABLE_MASSFILTER_SUCCESS,
  GET_MASSFILTERS_FAIL,
  GET_MASSFILTERS_START,
  GET_MASSFILTERS_SUCCESS,
} from './constants';

const initialState = {
  filters: [],
  loading: false,
};

/* eslint-disable */
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_MASSFILTERS_START:
      return { ...state, loading: true, massFilters: [] };

    case GET_MASSFILTERS_SUCCESS:
      return { ...state, loading: false, massFilters: response.content };

    case GET_MASSFILTERS_FAIL:
      return { ...state, loading: false, massFilters: [] };

    case DELETE_MASSFILTER_START:
      return { ...state, loading: true };

    case DELETE_MASSFILTER_SUCCESS:
      return { ...state, loading: false };

    case DELETE_MASSFILTER_FAIL:
      return { ...state, loading: false };

    case ENABLE_MASSFILTER_START:
      return { ...state, loading: true };

    case ENABLE_MASSFILTER_SUCCESS:
      return { ...state, loading: false };

    case ENABLE_MASSFILTER_FAIL:
      return { ...state, loading: false };

    case DISABLE_MASSFILTER_START:
      return { ...state, loading: true };

    case DISABLE_MASSFILTER_SUCCESS:
      return { ...state, loading: false };

    case DISABLE_MASSFILTER_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
