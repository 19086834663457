// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-src-components-TagsInput-TagsInput-module__ql-picker-x7BjLo.admin-src-components-TagsInput-TagsInput-module__ql-font-mCKA4W .admin-src-components-TagsInput-TagsInput-module__ql-picker-label-Ve6Kr8[data-value="Montserrat"]:before, .admin-src-components-TagsInput-TagsInput-module__ql-picker-x7BjLo.admin-src-components-TagsInput-TagsInput-module__ql-font-mCKA4W .admin-src-components-TagsInput-TagsInput-module__ql-picker-item-Ye_rDm[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.admin-src-components-TagsInput-TagsInput-module__ql-font-Montserrat-tHPRyh {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.admin-src-components-TagsInput-TagsInput-module__tag-q7btG0 {
  color: #000;
  background-color: #f2f2f2;
  border: 1px solid #dadada;
  border-radius: 11px;
  max-width: 650px;
  padding: 0 10px;
  font-size: 13px;
}

.admin-src-components-TagsInput-TagsInput-module__tag-q7btG0:hover, .admin-src-components-TagsInput-TagsInput-module__tag-q7btG0:focus {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./admin/src/components/TagsInput/TagsInput.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;;AAOA;EAJE,UAAU;AACZ","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.tag {\n  font-size: 13px;\n  color: #000;\n  background-color: #f2f2f2;\n  border: 1px solid #dadada;\n  border-radius: 11px;\n  padding: 0 10px;\n  max-width: 650px;\n}\n.tag:hover, .tag:focus {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `admin-src-components-TagsInput-TagsInput-module__ql-picker-x7BjLo`,
	"qlPicker": `admin-src-components-TagsInput-TagsInput-module__ql-picker-x7BjLo`,
	"ql-font": `admin-src-components-TagsInput-TagsInput-module__ql-font-mCKA4W`,
	"qlFont": `admin-src-components-TagsInput-TagsInput-module__ql-font-mCKA4W`,
	"ql-picker-label": `admin-src-components-TagsInput-TagsInput-module__ql-picker-label-Ve6Kr8`,
	"qlPickerLabel": `admin-src-components-TagsInput-TagsInput-module__ql-picker-label-Ve6Kr8`,
	"ql-picker-item": `admin-src-components-TagsInput-TagsInput-module__ql-picker-item-Ye_rDm`,
	"qlPickerItem": `admin-src-components-TagsInput-TagsInput-module__ql-picker-item-Ye_rDm`,
	"ql-font-Montserrat": `admin-src-components-TagsInput-TagsInput-module__ql-font-Montserrat-tHPRyh`,
	"qlFontMontserrat": `admin-src-components-TagsInput-TagsInput-module__ql-font-Montserrat-tHPRyh`,
	"tag": `admin-src-components-TagsInput-TagsInput-module__tag-q7btG0`
};
export default ___CSS_LOADER_EXPORT___;
