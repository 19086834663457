import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteRole, getRoles } from '@/store/access/actions';

import { selectAccess } from '@/store/access/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Form, Input, Modal, Table, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

import useDebounce from '../../hooks/useDebounce';
import ExpandedRoleRow from './components/ExpandedRoleRow';

import css from './RoleSettings.module.scss';

import { ROLE_CREATE, ROLE_DELETE, ROLE_UPDATE } from '@/constants/permissions';

const { Text } = Typography;
const { confirm } = Modal;

const RoleSettings = memo(({ setVisibleTabPane, setSelectedRole, setIsCopy }) => {
  const [searchValue, setSearchValue] = useState(undefined);

  const debounceSearchValue = useDebounce(searchValue, 800);

  const dispatch = useDispatch();

  const { paginationRole, roles } = useSelector(selectAccess);
  const { currentUser } = useSelector(selectUsers);

  const { t } = useTranslation('RolesSettings');

  const authorities = currentUser?.authorities;

  useEffect(() => {
    allRoles();
  }, [debounceSearchValue]);

  const allRoles = () => {
    dispatch(getRoles({ size: 20, name: searchValue }));
  };

  const warningModal = role => {
    confirm({
      title: t('deleteRole', { name: role.name }),
      content: <Text>{t('deleteRoleContent')}</Text>,
      onOk: () => dispatch(deleteRole(role.id, allRoles)),
    });
  };

  const columns = [
    {
      title: t('roleName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('action'),
      key: 'action',
      render: (text, record) => (
        <>
          {record.id !== 1 &&
            record.id !== 2 &&
            record.id !== 3 &&
            record.id !== 4 &&
            authorities.includes(ROLE_UPDATE) && (
              <Button
                shape='circle'
                icon={<EditOutlined />}
                style={{ marginRight: 10 }}
                size='small'
                type='primary'
                data-qa='editCompetenceBtn'
                onClick={() => {
                  setVisibleTabPane();
                  setSelectedRole(record);
                }}
              />
            )}
          {authorities.includes(ROLE_CREATE) && (
            <Button
              shape='circle'
              icon={<CopyOutlined />}
              style={{ marginRight: 10 }}
              size='small'
              type='primary'
              data-qa='editCompetenceBtn'
              onClick={() => {
                setVisibleTabPane();
                setSelectedRole(record);
                setIsCopy();
              }}
            />
          )}
          {record.id !== 1 &&
            record.id !== 2 &&
            record.id !== 3 &&
            record.id !== 4 &&
            authorities.includes(ROLE_DELETE) && (
              <Button
                shape='circle'
                icon={<DeleteOutlined />}
                size='small'
                data-qa='deleteCompetenceBtn'
                style={{
                  backgroundColor: '#ff4d4f',
                  color: '#fff',
                }}
                onClick={() => warningModal(record)}
              />
            )}
        </>
      ),
    },
  ];

  const pagination = {
    current: paginationRole.currentPage + 1,
    pageSize: paginationRole.size,
    total: paginationRole.totalElements,
    showSizeChanger: false,
    responsive: true,
    hideOnSinglePage: true,
  };

  const changeTable = pagination => {
    dispatch(getRoles({ size: 20, name: debounceSearchValue, page: pagination.current - 1 }));
  };

  const render = () => {
    return (
      <div className={css['main']}>
        <div className={css['main__filter']}>
          <Form layout='vertical'>
            <Form.Item label={t('searchLabel')}>
              <Input
                placeholder={t('placeholderFilter')}
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                allowClear
                className={css['main__input']}
              />
            </Form.Item>
          </Form>
          {authorities.includes(ROLE_CREATE) && (
            <Button onClick={setVisibleTabPane} type='primary' className={css['main__button']}>
              {t('createRole')}
            </Button>
          )}
        </div>
        <div className={css['main__role']}>
          <Table
            className={css['main__table']}
            columns={columns}
            dataSource={roles}
            expandable={{
              expandedRowRender: record => {
                return <ExpandedRoleRow role={record} />;
              },
              rowExpandable: record => record.permission !== '',
              expandIcon: ({ expanded, onExpand, record }) => {
                return expanded ? (
                  <MinusSquareOutlined onClick={e => onExpand(record, e)} />
                ) : (
                  <PlusSquareOutlined onClick={e => onExpand(record, e)} />
                );
              },
            }}
            pagination={pagination}
            onChange={changeTable}
            rowKey={e => e.id}
          />
        </div>
      </div>
    );
  };

  return render();
});

export default RoleSettings;
