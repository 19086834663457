// import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Divider, Row, Tooltip } from 'antd';

// import TypeBadgeNew from '../TypeBadgeNew';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';
import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './MaterialModal.module.scss';

// import { TOPIC_TYPES } from '@/constants/topic-types';

const MaterialItem = ({ material, selectedItems, selectedIds, deletedItems }) => {
  // const { t } = useTranslation('materialModal');
  const [avatar] = useLocalFileByUUID(material.thumbFileUuid, {
    defaultImg: TOPIC_IMAGE.find(type => type.id === material?.type?.id)?.image,
  });

  const isSelected = selectedIds?.find(i => i === material?.id);

  const clickMaterial = material => {
    if (isSelected) {
      deletedItems(material.id);
    } else {
      selectedItems(material);
    }
  };

  // const itemType = TOPIC_TYPES(t).find(type => type.id === material?.type?.id);

  return (
    <>
      <Row justify='space-between' align='middle' onClick={() => clickMaterial(material)}>
        <Col span={3}>
          <div>
            {material?.cost > 0 && (
              <div className={css['MaterialItem-price']}>
                <span className={css['MaterialItem-price-txt']}>{material.cost} P</span>
              </div>
            )}
            <img src={avatar} alt='topicImage' className={css['MaterialItem-image']} />
          </div>
        </Col>
        <Col span={20}>
          <Tooltip title={material.name}>{Utils.trString(material.name, 70)}</Tooltip>
          {/* <div className={css['MaterialItem-info']}>
            <TypeBadgeNew type={itemType?.code} />
            <span className={css['MaterialItem-info-text']}>{material?.type?.name}</span>
          </div> */}
        </Col>
        <Col span={1}>
          <Checkbox checked={isSelected} />
        </Col>
      </Row>
      <Divider />
    </>
  );
};

MaterialItem.propTypes = {
  material: PropTypes.object,
  selectedItems: PropTypes.func,
  selectedIds: PropTypes.array,
  deletedItems: PropTypes.func,
};

export default MaterialItem;
