import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectTracks } from '@/store/tracks/selectors';

import { Col, Form, Row, Tag } from 'antd';

import css from '../TrackDetail.module.scss';

const TabDescription = () => {
  const { t } = useTranslation('trackDetail');

  const { trackDetails } = useSelector(selectTracks);

  const funcText = arr => {
    let result;
    if (arr && arr.length > 0) {
      result = arr.map(i => {
        return (
          <div className={css['Track-details-tabs-desc-form-text']} key={i.id}>
            {i.name}
          </div>
        );
      });
    }
    return result;
  };

  const renderComptencies = arr => {
    let result;
    if (arr && arr.length > 0) {
      result = arr.map(i => {
        return (
          <Tag color='var(--personalColor)' key={i.id} className={css['Track-details-tabs-desc-comps-tags']}>
            {i.name}
          </Tag>
        );
      });
    }
    return result;
  };

  return (
    <div className={css['Track-details-tabs-desc']}>
      <Row>
        <Col span={18}>
          {trackDetails?.fullDescription && (
            <div className={css['Track-details-tabs-desc-full']}>
              <h2 className={css['Track-details-tabs-desc-full-titleDesc']}>{t('titleDesc')}</h2>
              <div className={css['Track-details-tabs-desc-full-text']}>{trackDetails?.fullDescription}</div>
            </div>
          )}

          <div className={css['Track-details-tabs-desc-comps']}>
            <h2>{t('titleComp')}</h2>
            <div>{renderComptencies(trackDetails?.competences)}</div>
          </div>
        </Col>

        <Col span={6}>
          <Form className={css['Track-details-tabs-desc-form']} layout='vertical'>
            <Form.Item label={`${t('durationDays')}:`}>{trackDetails?.duration}</Form.Item>
          </Form>

          <Form className={css['Track-details-tabs-desc-form']} layout='vertical'>
            <Form.Item label={`${t('categories')}:`}>{funcText(trackDetails?.categories)}</Form.Item>
          </Form>

          <Form className={css['Track-details-tabs-desc-form']} layout='vertical'>
            <Form.Item label={`${t('skills')}:`}>{funcText(trackDetails?.skills)}</Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default TabDescription;
