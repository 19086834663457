import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettingsIpr } from '@/store/ipr/actions';

import { selectIpr } from '@/store/ipr/selectors';

import { Alert, Button, Col, Modal, Radio, Row } from 'antd';

import PropTypes from 'prop-types';

import css from '../../Career.module.scss';

const ModalSettingsIpr = ({ open, onCancel }) => {
  const { t } = useTranslation('career');
  const dispatch = useDispatch();
  const { isLoadingSettingsIpr, settingsIpr } = useSelector(selectIpr);
  const [isRequiredIpr, setIsRequiredIpr] = useState(false);

  const onChangeRadio = e => setIsRequiredIpr(e?.target?.value);

  const onSaveSettingsIpr = () => {
    dispatch(updateSettingsIpr({ requiredIpr: isRequiredIpr }, () => onCancel()));
  };

  useEffect(() => {
    if (Object.keys(settingsIpr).length > 0) {
      setIsRequiredIpr(settingsIpr?.requiredIpr);
    }
  }, [settingsIpr]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={800}
      className={css['ModalSettings']}
      loading={isLoadingSettingsIpr}
    >
      <h2>{t('titleSettingsIpr')}</h2>
      <Row className={css['ModalSettings-desc']}>
        <Alert message={t('descSettingsIpr')} type='info' closable className={css['ModalSettings-desc-alert']} />
      </Row>

      <Row className={css['ModalSettings-check']} align='middle'>
        <span className={css['ModalSettings-check-text']}>{t('descRadio')}</span>
        <Radio.Group onChange={onChangeRadio} value={isRequiredIpr}>
          <Radio value={true}>{t('yesCheck')}</Radio>
          <Radio value={false}>{t('noCheck')}</Radio>
        </Radio.Group>
      </Row>

      <Row className={css['ModalSettings-footer']} justify='end' gutter={[20]}>
        <Col>
          <Button onClick={onCancel}>{t('cancelSettingsIpr')}</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={onSaveSettingsIpr}>
            {t('saveSettingsIpr')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ModalSettingsIpr.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ModalSettingsIpr;
