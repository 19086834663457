import Application from '../assets/icons/application-icon.svg?react';
import Article from '../assets/icons/article-icon.svg?react';
import Bars from '../assets/icons/bars-icon.svg?react';
import Book from '../assets/icons/book-icon.svg?react';
import Conference from '../assets/icons/conference-icon.svg?react';
import Course from '../assets/icons/course-icon.svg?react';
import DefaultCorp from '../assets/icons/default-corp-icon.svg?react';
import Event from '../assets/icons/event-icon.svg?react';
import External from '../assets/icons/external-icon.svg?react';
import ResetSearch from '../assets/icons/reset-search.svg?react';
import Video from '../assets/icons/video-icon.svg?react';

export { Conference, Video, External, Article, Application, Event, Course, Book, DefaultCorp, ResetSearch, Bars };
