/* eslint-disable */
import AccessSettings from '@/pages/AccessSettings/AccessSettings';
import ApproversDirectory from '@/pages/ApproversDirectory';
import AreasDirectory from '@/pages/AreasDirectory‎';
import AuthorsDirectory from '@/pages/AuthorsDirectory';
import Badge from '@/pages/Badge';
import BadgeDetail from '@/pages/BadgeDetail';
import Career from '@/pages/Career';
import Companies from '@/pages/Companies';
import CompetenceDirectory from '@/pages/CompetenceDirectory';
import CompetenceGroupsDirectory from '@/pages/CompetenceGroupsDirectory';
import Compilation from '@/pages/Compilation';
import CompilationDetail from '@/pages/CompilationDetail';
import D360 from '@/pages/D360';
import D360Project from '@/pages/D360/components/Project/Project';
import DepartmentDirectory from '@/pages/DepartmentDirectory';
import DownloadFile from '@/pages/DownloadFile/DownloadFile';
import Events from '@/pages/Events';
import FunctionDirectory from '@/pages/FunctionDirectory';
import Gamification from '@/pages/Gamification';
import Inspector from '@/pages/Inspector';
import Logging from '@/pages/Logging';
import LoggingAdmin from '@/pages/LoggingAdmin';
import ManageCourse from '@/pages/ManageCourse';
import Mentee from '@/pages/Mentee';
import Mentors from '@/pages/Mentor';
import Messages from '@/pages/Messages';
import News from '@/pages/News';
import Topics from '@/pages/NewTopics';
import Notifications from '@/pages/Notifications';
import Orders from '@/pages/Orders';
import PokaValidationUser from '@/pages/PokaValidationUser';
import Polus from '@/pages/Polus';
import PositionAssignmentDirectory from '@/pages/PositionAssignmentDirectory';
import PostDirectory from '@/pages/PostDirectory';
import Products from '@/pages/Products';
import ProfileSetting from '@/pages/ProfileSetting';
import ProfileSettings from '@/pages/ProfileSettings';
import QuestionBank from '@/pages/QuestionBank';
import { withRouter } from '@/pages/Root/withRouter';
import Skills from '@/pages/Skills';
import SkillsDirectory from '@/pages/SkillsDirectory';
import TagDirectory from '@/pages/TagDirectory';
import ThemesEditorPage from '@/pages/ThemesEditor';
import TopicDetailsDialog from '@/pages/Topics/TopicDetailsDialog';
import TrackDetail from '@/pages/TrackDetail';
import Tracks from '@/pages/Tracks';
import Users from '@/pages/Users';
import VarDirectory from '@/pages/VarDirectory';

import {
  ACCESS_TO_ADMIN_360,
  ACCESS_TO_ADMIN_ACCESS_SETTINGS,
  ACCESS_TO_ADMIN_APPROVE,
  ACCESS_TO_ADMIN_COLLECTIONS,
  ACCESS_TO_ADMIN_DIRECTORY,
  ACCESS_TO_ADMIN_EVENT_CALENDAR,
  ACCESS_TO_ADMIN_MATERIALS,
  ACCESS_TO_ADMIN_MATERIALS_LOGGING,
  ACCESS_TO_ADMIN_MENTORING,
  ACCESS_TO_ADMIN_PROFILE,
  ACCESS_TO_ADMIN_SHOP,
  ACCESS_TO_ADMIN_SYSTEM_LOGGING,
  ACCESS_TO_ADMIN_SYSTEM_SETTINGS,
  ACCESS_TO_ADMIN_TOPIC_INSPECTION,
  ACCESS_TO_ADMIN_USERS,
  ACCESS_TO_COMPANY_MESSAGES_ADMIN,
  ACCESS_TO_NEWS,
  ACCESS_TO_NOTIFICATION_ADMIN,
  ACCESS_TO_QUESTION_BANK,
  ACCESS_TO_TRACKS,
  AUTHOR_READ,
  BONUS_READ,
  CAREER_PLAN_ACCESS,
  CAREER_POSITION_READ,
  COMPANY_READ,
  COMPETENCE_GROUP_READ,
  COMPETENCE_READ,
  DEPARTMENT_INTERNAL_READ,
  FILE_READ,
  FUNCTION_INTERNAL_READ,
  MENTEE_READ_BY_ADMIN,
  MENTOR_READ_BY_ADMIN,
  REWARD_ACCESS,
  ROOT_CHANGE_PASSWORD,
  SKILL_READ,
  TAG_READ,
  THEME_READ,
} from '@/constants/permissions';

const rootRoute = {
  path: '/',
  name: 'root',
  Component: ManageCourse,
  roles: ACCESS_TO_ADMIN_APPROVE,
};

const d360 = {
  path: '/d360',
  Component: D360,
  name: 'd360',
  roles: ACCESS_TO_ADMIN_360,
};

const d360Project = {
  path: '/d360/:id',
  Component: D360Project,
  name: 'd360Project',
  roles: ACCESS_TO_ADMIN_360,
};

const topics = {
  path: '/topics',
  Component: Topics,
  name: 'materials',
  roles: ACCESS_TO_ADMIN_MATERIALS,
};

const inspector = {
  path: '/inspector',
  params: '?generalStatus=WAITING',
  Component: Inspector,
  name: 'inspector',
  roles: ACCESS_TO_ADMIN_TOPIC_INSPECTION,
};

const topicID = {
  path: '/topic/:id',
  Component: withRouter(TopicDetailsDialog),
  name: 'materials',
  roles: ACCESS_TO_ADMIN_MATERIALS,
};

const polus = {
  path: '/polus/:id',
  Component: Polus,
  name: 'polus',
};

// const manageCourse = {
//   path: '/manage-course',
//   Component: withRouter(ManageCourse),
//   name: 'requests',
//   roles: ACCESS_TO_ADMIN_APPROVE,
// };

const manageCourse = {
  path: '/manage-course',
  Component: ManageCourse,
  name: 'requests',
  roles: ACCESS_TO_ADMIN_APPROVE,
};

const compilation = {
  path: '/compilations',
  Component: Compilation,
  name: 'collections',
  roles: ACCESS_TO_ADMIN_COLLECTIONS,
  companyFlag: 'hasCompilation',
};

const CompilationDetailPage = {
  path: '/compilations/:id',
  Component: withRouter(CompilationDetail),
  name: 'collections',
  roles: ACCESS_TO_ADMIN_COLLECTIONS,
};

const themesEditor = {
  path: '/themes-editor',
  Component: ThemesEditorPage,
  name: 'topics',
  roles: THEME_READ,
};

const users = {
  path: '/users',
  Component: Users,
  name: 'users',
  roles: ACCESS_TO_ADMIN_USERS,
};

const profileSettings = {
  path: '/profile-settings',
  Component: ProfileSettings,
  name: 'profile',
  roles: ROOT_CHANGE_PASSWORD,
};

const profileSetting = {
  path: '/profile-setting',
  Component: ProfileSetting,
  name: 'profile',
  roles: ACCESS_TO_ADMIN_PROFILE,
};

const directory = {
  name: 'directories',
  roles: ACCESS_TO_ADMIN_DIRECTORY,
  children: [
    {
      path: '/tag-directory',
      Component: TagDirectory,
      name: 'tags',
      roles: TAG_READ,
    },
    {
      path: '/skills-directory',
      Component: SkillsDirectory,
      name: 'skills',
      roles: SKILL_READ,
    },
    {
      path: '/competence-directory',
      Component: CompetenceDirectory,
      name: 'competence',
      roles: COMPETENCE_READ,
    },
    {
      path: '/authors-directory',
      Component: AuthorsDirectory,
      name: 'authors',
      roles: AUTHOR_READ,
    },
    {
      path: '/post-directory',
      Component: PostDirectory,
      name: 'specializations',
      roles: CAREER_POSITION_READ,
    },
    {
      path: '/department-directory',
      Component: DepartmentDirectory,
      name: 'departments',
      roles: DEPARTMENT_INTERNAL_READ,
      hasAdditionalInfo: true,
    },
    {
      path: '/function-directory',
      Component: FunctionDirectory,
      name: 'positions',
      roles: FUNCTION_INTERNAL_READ,
      hasAdditionalInfo: true,
    },
    {
      path: '/position-assignment',
      Component: PositionAssignmentDirectory,
      name: 'positionAssignmentDirectory',
      roles: ACCESS_TO_ADMIN_DIRECTORY,
      hasAdditionalInfo: true,
    },
    {
      path: '/approver-directory',
      Component: ApproversDirectory,
      name: 'approvers',
      roles: ACCESS_TO_ADMIN_DIRECTORY,
    },
    {
      path: '/groups-directory',
      Component: CompetenceGroupsDirectory,
      name: 'groups',
      roles: COMPETENCE_GROUP_READ,
    },
    {
      path: '/area-directory',
      Component: AreasDirectory,
      name: 'properAreas',
      roles: ACCESS_TO_ADMIN_DIRECTORY,
    },
  ],
};

const accessSettings = {
  path: '/access',
  Component: AccessSettings,
  name: 'access',
  roles: ACCESS_TO_ADMIN_ACCESS_SETTINGS,
};

const companies = {
  path: '/companies',
  Component: Companies,
  name: 'companies',
  roles: COMPANY_READ,
};

const varDirectory = {
  path: '/var-directory',
  Component: VarDirectory,
  name: 'system',
  roles: ACCESS_TO_ADMIN_SYSTEM_SETTINGS,
};

const newsLetters = {
  name: 'newsLetters',
  roles: ACCESS_TO_COMPANY_MESSAGES_ADMIN,
  children: [
    {
      path: '/notifications',
      Component: Notifications,
      name: 'notifications',
      roles: ACCESS_TO_NOTIFICATION_ADMIN,
    },
    {
      path: '/mailing',
      Component: Messages,
      name: 'newsLetters',
      roles: ACCESS_TO_COMPANY_MESSAGES_ADMIN,
    },
  ],
};

const logging = {
  name: 'activity',
  roles: ['ACCESS_TO_ADMIN_MATERIALS_LOGGING', 'ACCESS_TO_ADMIN_SYSTEM_LOGGING'],
  children: [
    {
      path: '/logging',
      Component: Logging,
      name: 'materials',
      roles: ACCESS_TO_ADMIN_MATERIALS_LOGGING,
    },
    {
      path: '/logging-admin',
      Component: LoggingAdmin,
      name: 'system',
      roles: ACCESS_TO_ADMIN_SYSTEM_LOGGING,
    },
  ],
};

const events = {
  path: '/events',
  Component: Events,
  name: 'calendar',
  roles: ACCESS_TO_ADMIN_EVENT_CALENDAR,
};

const downloadFile = {
  path: '/download/:uuid/:format',
  Component: DownloadFile,
  name: 'downloadFile',
  roles: FILE_READ,
};

const mentor = {
  name: 'mentor',
  roles: ACCESS_TO_ADMIN_MENTORING,
  children: [
    {
      path: '/mentors',
      Component: Mentors,
      name: 'mentors',
      roles: MENTOR_READ_BY_ADMIN,
    },
    {
      path: '/mentee',
      Component: Mentee,
      name: 'mentee',
      roles: MENTEE_READ_BY_ADMIN,
    },
  ],
};

const shop = {
  name: 'shop',
  roles: ACCESS_TO_ADMIN_SHOP,
  children: [
    {
      path: '/products',
      Component: Products,
      name: 'products',
      roles: ACCESS_TO_ADMIN_SHOP,
    },
    {
      path: '/orders',
      Component: Orders,
      name: 'orders',
      roles: ACCESS_TO_ADMIN_SHOP,
    },
  ],
};

const tracks = {
  path: '/tracks',
  Component: Tracks,
  name: 'tracks',
  roles: ACCESS_TO_TRACKS,
};

const trackDetail = {
  path: '/track/:id',
  Component: TrackDetail,
  name: 'trackDetail',
  roles: ACCESS_TO_TRACKS,
};

const news = {
  path: '/news',
  Component: News,
  name: 'news',
  roles: ACCESS_TO_NEWS,
};

const questionBank = {
  path: '/question-bank',
  Component: QuestionBank,
  name: 'questionBank',
  roles: ACCESS_TO_QUESTION_BANK,
};

const badge = {
  path: '/badge',
  Component: Badge,
  name: 'badge',
  roles: REWARD_ACCESS,
};

const badgeDetail = {
  path: '/badge/:id',
  Component: BadgeDetail,
  name: 'badgeDetail',
  roles: REWARD_ACCESS,
};

const career = {
  path: '/manage-career',
  Component: Career,
  name: 'career',
  roles: CAREER_PLAN_ACCESS,
};

const gamefication = {
  name: 'gamefication',
  roles: [BONUS_READ, REWARD_ACCESS],
  children: [
    {
      path: '/coin-settings',
      Component: Gamification,
      name: 'coinSettings',
      roles: BONUS_READ,
    },
    {
      path: '/badge',
      Component: Badge,
      name: 'badge',
      roles: REWARD_ACCESS,
    },
  ],
};

const pokaSkills = {
  name: 'poka',
  roles: ACCESS_TO_ADMIN_MATERIALS,
  children: [
    {
      path: '/skills',
      Component: Skills,
      name: 'skills-list',
      roles: ACCESS_TO_ADMIN_MATERIALS,
    },
  ],
};

const pokaValidationSkill = {
  path: '/validation/:topicId/:userId/:planId',
  Component: PokaValidationUser,
  name: 'pokaValidationUser',
  roles: ACCESS_TO_QUESTION_BANK,
};

const defaultRoute = '/manage-course';

export {
  d360,
  d360Project,
  polus,
  topics,
  topicID,
  users,
  profileSettings,
  profileSetting,
  directory,
  varDirectory,
  manageCourse,
  defaultRoute,
  accessSettings,
  themesEditor,
  compilation,
  CompilationDetailPage,
  logging,
  events,
  companies,
  downloadFile,
  mentor,
  shop,
  tracks,
  trackDetail,
  news,
  newsLetters,
  questionBank,
  badge,
  badgeDetail,
  career,
  gamefication,
  inspector,
  pokaSkills,
  pokaValidationSkill,
};

export default [
  rootRoute,
  d360,
  d360Project,
  polus,
  topics,
  topicID,
  users,
  profileSettings,
  profileSetting,
  directory,
  varDirectory,
  manageCourse,
  accessSettings,
  themesEditor,
  compilation,
  CompilationDetailPage,
  logging,
  events,
  companies,
  downloadFile,
  mentor,
  shop,
  tracks,
  trackDetail,
  news,
  newsLetters,
  questionBank,
  badge,
  badgeDetail,
  career,
  gamefication,
  inspector,
  pokaSkills,
  pokaValidationSkill,
];
