/* eslint-disable */

import {
  CLEAR_CHAPTERS_TOPIC,
  CREATE_CHAPTER_FAIL,
  CREATE_CHAPTER_START,
  CREATE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAIL,
  DELETE_CHAPTER_START,
  DELETE_CHAPTER_SUCCESS,
  GET_CHAPTERS_FAIL,
  GET_CHAPTERS_START,
  GET_CHAPTERS_SUCCESS,
} from './actions';

const initialState = {
  chapters: [],
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_CHAPTERS_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_CHAPTERS_SUCCESS:
      return {
        ...state,
        chapters: response,
        isLoading: false,
        error: false,
      };

    case GET_CHAPTERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case CREATE_CHAPTER_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case CREATE_CHAPTER_SUCCESS:
      return {
        ...state,
        chapters: [...state.chapters, response],
        isLoading: false,
        error: false,
      };

    case CREATE_CHAPTER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case DELETE_CHAPTER_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case DELETE_CHAPTER_SUCCESS:
      return {
        ...state,
        chapters: [...state.chapters, response],
        isLoading: false,
        error: false,
      };

    case DELETE_CHAPTER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case CLEAR_CHAPTERS_TOPIC:
      return { ...state, chapters: [] };

    default:
      return state;
  }
};
