/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import { D360CreateCriteria, D360DeleteCriteria, D360GetCriteria } from '@/store/d360/d360_criterias/actions';

import { selectD360Criterias } from '@/store/d360/d360_criterias/selectors';

import { Button, Col, message, Row, Table } from 'antd';
import { FiSlack } from 'react-icons/fi';

import { D360UpdateCompetenceComment, D360UpdateSkillComment } from '../../../../store/d360/d360_criterias/actions';
import EditComment from './parts/EditComment';
import EditCriteria from './parts/EditCriteria';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from '../../css/index.module.scss';

const selectedTemplate = { keys: [], rows: [] };
const ButtonGroup = Button.Group;

const pageSizeOptions = ['10', '50', '100', '500', '1000', '10000'];

export const Criteria = props => {
  const [showEditCriteria, setShowEditCriteria] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState(selectedTemplate);
  const [disableSelAction, setDisableSelAction] = useState(true);
  const { t } = useTranslation('D360_Criteria');
  const [editCommentVisible, setEditCommentVisible] = useState(false);
  const [editCommentType, setEditCommentType] = useState('competence');
  const [currentCriteria, setCurrentCriteria] = useState(null);
  const dispatch = useDispatch();
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [messageApi, contextHolder] = message.useMessage();

  /**
   *
   */
  useEffect(() => {
    if (props.project && props.project.id) {
      props.D360GetCriteria(props.project.id);
    }
  }, [props.project]);

  useEffect(() => {
    let data = [];
    if (props.criterias && Array.isArray(props.criterias)) {
      props.criterias.forEach(criteria => {
        data.push({ ...criteria, key: criteria.id });
      });
    }

    setTableData(data);
  }, [props.criterias]);

  /**
   * Отслеживаем изменения selected
   */
  useEffect(() => {
    if (selected.keys.length) {
      return setDisableSelAction(false);
    }

    setDisableSelAction(true);
  }, [selected]);

  const onCompetenceChangeDescription = criteria => {
    setEditCommentType('competence');
    setEditCommentVisible(true);
    setCurrentCriteria(criteria);
  };

  const onSkillChangeDescription = criteria => {
    setEditCommentType('skill');
    setEditCommentVisible(true);
    setCurrentCriteria(criteria);
  };

  /**
   *
   * @returns {*[]}
   */
  const tableColumns = () => {
    return [
      {
        title: t('columnCompetence'),
        key: 'competence',
        dataIndex: 'competencies',
        render: (name, record) => {
          return (
            <Button type='link' onClick={() => onCompetenceChangeDescription(record)} className={css.ButtonLink}>
              {record.competence.name}
            </Button>
          );
        },
      },
      {
        title: t('columnSkills'),
        key: 'skills',
        dataIndex: 'skills',
        render: (name, record) => {
          return (
            <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => onSkillChangeDescription(record)}>
              {record.skill.name}
            </span>
          );
        },
      },
      {
        title: t('columnScale'),
        key: 'scale',
        dataIndex: 'scale',
        render: (name, record) => {
          return <>{record.scale.name}</>;
        },
      },
    ];
  };

  /**
   *
   * @param criteria
   */
  const handleEditCriteriaSubmit = criteria => {
    if (criteria && typeof criteria === 'object') {
      if (criteria.skill && criteria.skill.id) {
        props.D360CreateCriteria(
          props.project.id,
          criteria.competence.id,
          criteria.skill.id,
          criteria.scale.id,
          criteria.competenceDescription,
          criteria.skillDescription
        );
      } else {
        props.D360CreateCriteria(
          props.project.id,
          criteria.competence.id,
          null,
          criteria.scale.id,
          criteria.competenceDescription,
          criteria.skillDescription
        );
      }

      setShowEditCriteria(false);
    }

    props.D360GetCriteria(props.project.id);
  };

  /**
   * Обработка выбора пользователей в таблице Критерии.
   * @returns {{selectedRowKeys: Array, onChange: onChange}}
   */
  const rowSelection = () => {
    if (props.editable) {
      return {
        selectedRowKeys: selected.keys,
        onChange: (selectedKeys, selectedRows) => {
          setSelected({ ...selected, keys: selectedKeys, rows: selectedRows });
        },
      };
    }
  };

  /**
   *
   */
  const onAddCriteria = () => {
    setShowEditCriteria(true);
  };

  /**
   *
   */
  const onDeleteCriteria = () => {
    if (selected && selected.rows && Array.isArray(selected.rows)) {
      for (let criteria of selected.rows) {
        if (criteria && criteria.id) {
          props.D360DeleteCriteria(props.project.id, criteria.id);
        }
      }
    }
  };

  const onSubmitComment = (criteria, type, comment) => {
    setEditCommentVisible(false);

    switch (type) {
      case 'competence':
        dispatch(
          D360UpdateCompetenceComment(
            props.project.id,
            criteria.id,
            criteria.competence_id,
            comment,
            () => {
              messageApi.success(t('saveCompetenceCommentSuccess'));
            },
            () => {
              messageApi.error(t('saveCompetenceCommentError'));
            }
          )
        );
        break;

      case 'skill':
        dispatch(
          D360UpdateSkillComment(
            props.project.id,
            criteria.id,
            criteria.skill_id,
            comment,
            () => {
              messageApi.success(t('saveSkillCommentSuccess'));
            },
            () => {
              messageApi.success(t('saveSkillCommentError'));
            }
          )
        );
        break;
    }
  };

  /**
   * Генерация представления.
   *
   * @returns {*}
   */
  const render = () => {
    return (
      <div>
        <Row className={css.Header}>
          <Col span={12}>
            <h2 className={css.HeaderTitle}>
              <FiSlack />
              &nbsp; {t('title')}
            </h2>
          </Col>

          {props.editable && (
            <Col span={12} align='right'>
              <ButtonGroup>
                <Button type='primary' onClick={onAddCriteria}>
                  {t('add')}
                </Button>
                <Button type='primary' danger onClick={onDeleteCriteria} disabled={disableSelAction}>
                  {t('delete')}
                </Button>
              </ButtonGroup>
            </Col>
          )}
        </Row>

        <Row>
          <Col span={24}>
            <Table
              columns={tableColumns()}
              dataSource={tableData}
              bordered={true}
              rowSelection={rowSelection()}
              loading={props.loading}
              size='middle'
              pagination={{
                defaultPageSize: 10,
                pageSize: paginationPageSize,
                pageSizeOptions: pageSizeOptions,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onShowSizeChange: (c, s) => {
                  setPaginationPageSize(s);
                },
                responsive: true,
              }}
            />
          </Col>
        </Row>

        {showEditCriteria && (
          <EditCriteria
            open={showEditCriteria}
            onCancel={() => setShowEditCriteria(false)}
            onSubmit={handleEditCriteriaSubmit}
            project={props.project}
          />
        )}

        {editCommentVisible && (
          <EditComment
            type={editCommentType}
            criteria={currentCriteria}
            onCancel={() => setEditCommentVisible(false)}
            onSubmit={onSubmitComment}
          />
        )}
        {contextHolder}
      </div>
    );
  };

  /**
   * Запуск компонента.
   */
  return render();
};

/**
 *
 * @type {{project: *}}
 */
Criteria.propTypes = {
  project: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
};

/**
 *
 */
const mapStateToProps = createSelector([selectD360Criterias], D360Criterias => ({
  criterias: D360Criterias.criterias,
  loading: D360Criterias.loading,
}));

/**
 *
 */
const mapDispatchToProps = {
  D360CreateCriteria,
  D360DeleteCriteria,
  D360GetCriteria,
};

/**
 * Экспорт компонента.
 */
export default connect(mapStateToProps, mapDispatchToProps)(Criteria);
