// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateField-alert {
  align-items: baseline;
  margin-bottom: 40px;
  display: flex;
}

.CreateField-alert-text {
  margin: 12px 0;
}

.CreateField-tooltip {
  margin-left: 5px;
}

.CreateField-alert-icon {
  color: var(--personalColor);
}

.CreateField-alert-icon > svg {
  width: 16px;
  height: 16px;
}
`, "",{"version":3,"sources":["webpack://./admin/src/pages/VarDirectory/Tabs/Users/Panels/css/CreateField.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".CreateField-alert {\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 40px;\n}\n\n.CreateField-alert-text {\n  margin: 12px 0;\n}\n\n.CreateField-tooltip {\n  margin-left: 5px;\n}\n\n.CreateField-alert-icon {\n  color: var(--personalColor);\n}\n\n.CreateField-alert-icon > svg {\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
