/* eslint-disable */
import {
  CREATE_TILDA_FAIL,
  CREATE_TILDA_START,
  CREATE_TILDA_SUCCESS,
  DELETE_TILDA_FAIL,
  DELETE_TILDA_START,
  DELETE_TILDA_SUCCESS,
  GET_TILDA_FAIL,
  GET_TILDA_START,
  GET_TILDA_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const createTilda = (blockId, uploadLink, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/block/${blockId}/tilda`,
    body: {
      uploadLink: uploadLink,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [CREATE_TILDA_START, CREATE_TILDA_SUCCESS, CREATE_TILDA_FAIL],
    onSuccess,
    onError,
  };
};

export const deleteTilda = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_URL}/tilda/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [DELETE_TILDA_START, DELETE_TILDA_SUCCESS, DELETE_TILDA_FAIL],
    onSuccess,
    onError,
  };
};

export const getTilda = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/tilda/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TILDA_START, GET_TILDA_SUCCESS, GET_TILDA_FAIL],
    onSuccess,
    onError,
  };
};

/**************************************************************
 * Import statement
 **************************************************************

 import {
    createTilda,
    deleteTilda,
    getTilda,
 } from '@/store/tilda/actions';

**************************************************************/
